import _ from 'lodash'
import { ReactNode, Ref, UIEventHandler } from 'react'
import { colors } from 'siteline-common-web'

/** A column may have a fixed set width or grow to fill available space */
export enum TableColumnWidthType {
  FIXED = 'fixed',
  GROW = 'grow',
}

interface TableColumnBaseWidth {
  type: TableColumnWidthType
}

/** A fixed column with a static width */
export interface TableColumnFixedWidth extends TableColumnBaseWidth {
  type: TableColumnWidthType.FIXED
  width: number
}

/**
 * Available space after allotting fixed width columns is evenly distributed
 * among grow columns
 */
export interface TableColumnGrowWidth extends TableColumnBaseWidth {
  type: TableColumnWidthType.GROW
  minWidth?: number
}

export type TableColumnWidth = TableColumnFixedWidth | TableColumnGrowWidth

/** Corresponds to a header cell on the table  */
export interface TableHeader<T> {
  key: string

  label: ReactNode

  /** The alignment of the header will be used on the entire column */
  align?: 'left' | 'center' | 'right'

  /** If supporting sorting by columns, will use this instead of the label as a column identifier */
  sortKey?: T

  /** If sorting by this column, use this tooltip to describe the sort logic */
  sortLabels?: { asc: string; desc: string }

  /** Padding for cells in the column. Default is 8px/16px. */
  columnCellPadding?: 'default' | 'vertical-only' | 'none'

  className?: string
}

interface TableCell {
  content: ReactNode

  /**
   * Optional color to apply to this cell only. Overrides row color if provided. If undefined, cell
   * has no background color and defined and defaults to the color of the row or table.
   */
  backgroundColor?: string
}

/** A row of cells, with an optional click handler for the whole row */
export interface TableRow {
  key: string

  cells: TableCell[]
  onClick?: () => void

  /** The vertical alignment of the content */
  align?: 'top' | 'center' | 'bottom'

  /** A color to apply to the entire row */
  backgroundColor?: string

  /** An overlay to apply to the entire row */
  overlayColor?: string

  /**
   * By default, show an arrow on hover only when the row has an onClick defined.
   * If true, will always show an arrow on hover. If false, will never show an arrow on hover.
   */
  showArrowOnHover?: boolean

  /**
   * By default, all rows have a border on the bottom. If false, will be no border between this row
   * and the next consecutive row.
   */
  includeBottomBorder?: boolean

  /** Ref to apply to the row element */
  ref?: Ref<HTMLDivElement>

  /** Scroll handler for `onScroll` events on the row */
  onScroll?: UIEventHandler<HTMLDivElement>

  /**
   * If any special rendering is needed within the row, this function provides the cells to be
   * rendered within the row and allows the caller to provide a custom inner row element
   */
  onRenderRowCells?: (cells: JSX.Element[]) => JSX.Element
}

/** Structured data to fill a table */
export interface TableContent<T> {
  headers: TableHeader<T | undefined>[]
  rows: TableRow[]

  /**
   * May provide fixed widths for the table columns. Length of this array must be the
   * same as the length of the headers array or it will not be used. If not provided,
   * will evenly distribute columns.
   */
  columnWidths?: TableColumnWidth[]

  /** If true, will show skeleton rows instead of whatever is past in `rows` */
  loading?: boolean

  /** If true, will show a skeleton row at the end of the list */
  showLoadingMoreRow?: boolean
}

/** A column that grows to fill available space */
export const GROW_TABLE_COLUMN: TableColumnGrowWidth = { type: TableColumnWidthType.GROW }

/** Creates a column with a fixed width */
export function makeFixedTableColumn(width: number): TableColumnFixedWidth {
  return {
    type: TableColumnWidthType.FIXED,
    width,
  }
}

/** Creates a column width that grows to fill space, with a min width */
export function makeGrowTableColumn(minWidth: number): TableColumnGrowWidth {
  return {
    type: TableColumnWidthType.GROW,
    minWidth,
  }
}

/** Creates a table header with a label and optional alignment */
export function makeTableHeader<T>({
  key,
  label,
  align,
  sortKey,
  sortLabels,
  className,
  columnCellPadding,
}: {
  key: string
  label: ReactNode
  align?: TableHeader<T>['align']
  // Undefined is explicitly added to the typing because otherwise typecheck has trouble inferring
  // the generic type of T when sortKey is not provided
  sortKey?: T | undefined
  sortLabels?: TableHeader<T>['sortLabels']
  className?: string
  columnCellPadding?: TableHeader<T>['columnCellPadding']
}): TableHeader<T> {
  return {
    key,
    label,
    align,
    sortKey,
    sortLabels,
    className,
    columnCellPadding,
  }
}

/** The column to the right of the table, which may contain an arrow when the row is hovered */
export const END_COLUMN_WIDTH = 32

/** Calculate the width of a column based on the properties of the table */
export function calculateColumnWidth(
  columnIndex: number,
  numCols: number,
  includeArrowColumn: boolean,
  columnWidths?: TableColumnWidth[]
) {
  const endColumnWidth = includeArrowColumn ? END_COLUMN_WIDTH : 0
  // Evenly spaced columns, excluding the empty side columns
  let width = `calc((100% - ${endColumnWidth}px) / ${numCols})`
  // Use fixed column widths if appropriately provided
  if (columnWidths && columnWidths.length === numCols) {
    const colWidth = columnWidths[columnIndex]
    const fixedCols = columnWidths.filter(
      (w): w is TableColumnFixedWidth => w.type === TableColumnWidthType.FIXED
    )
    const growCols = columnWidths.filter((w) => w.type === TableColumnWidthType.GROW)
    const totalWidths = _.sumBy(fixedCols, (col) => col.width)
    if (colWidth.type === TableColumnWidthType.FIXED) {
      width = `${colWidth.width}px`
    } else {
      width = `calc((100% - (${totalWidths}px + ${endColumnWidth}px)) / ${growCols.length})`
    }
  }
  return width
}

/** Returns the justify content property corresponding to a cell align value */
export function alignToJustifyContent(align?: 'left' | 'center' | 'right') {
  if (!align) {
    return undefined
  }
  switch (align) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
  }
}

/** Returns the color corresponding to a cell's text color */
export function cellTextColor(color?: 'grey90' | 'grey50' | 'grey70' | 'red50') {
  if (!color) {
    return colors.grey90
  }
  switch (color) {
    case 'grey90':
      return colors.grey90
    case 'grey50':
      return colors.grey50
    case 'grey70':
      return colors.grey70
    case 'red50':
      return colors.red50
  }
}

/** Returns the color corresponding to a cell's background color */
export function cellBackgroundColor(color?: 'green10' | 'red10' | 'none') {
  if (!color || color === 'none') {
    return undefined
  }
  switch (color) {
    case 'green10':
      return colors.green10
    case 'red10':
      return colors.red10
  }
}

/** Creates a table cell with given content */
export function makeTableCell(
  content: React.ReactNode,
  { backgroundColor }: { backgroundColor?: string } = {}
): TableCell {
  return { content, backgroundColor }
}
