import { gql } from '@apollo/client'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  AgingIntervalType,
  DEFAULT_AGING_INTERVAL_TYPE,
  DEFAULT_CONTRACT_RETENTION_PERCENT,
  DEFAULT_DAYS_BEFORE_PAY_APP_DUE,
  DEFAULT_DUE_TO_GC_DAY_OF_MONTH,
  Permission,
  UserStatus,
} from 'siteline-common-all'
import { NoCompany } from '../../components/auth/NoCompany'
import { SwitchCompanyDialog } from '../../components/navigation/SwitchCompanyDialog'
import { Loader } from '../components/Loader'
import * as fragments from '../graphql/Fragments'
import {
  CurrentUserForCompanyContextQuery,
  useCurrentUserForCompanyContextQuery,
} from '../graphql/apollo-operations'
import { trackCompanySwitch } from '../util/MetricsTracking'
import { useLocalStorage } from '../util/SafeLocalStorage'
import { useUserContext } from './UserContext'

gql`
  query currentUserForCompanyContext {
    currentUser {
      id
      firstName
      lastName
      jobTitle
      email
      policiesAcceptedAt
      blockedNotifications
      blockedEmails
      tutorialsShown
      companyUsers {
        id
        role
        permissions
        status
        emailAlias {
          ...CompanyUserEmailAliasProperties
        }
        company {
          ...CompanyProperties
          integrationMappings
          metadata {
            monthlyCreatePayAppReminderDate
          }
        }
      }
      defaultSignature {
        ...SignatureProperties
      }
    }
  }
  ${fragments.companyUserEmailAlias}
  ${fragments.user}
  ${fragments.company}
`

type CompanyUserForCompanyContext =
  CurrentUserForCompanyContextQuery['currentUser']['companyUsers'][number]
export type CompanyForCompanyContext = CompanyUserForCompanyContext['company']

export type WrongCompanyMetadata = {
  companyId: string | null
  pageType: 'project' | 'vendor' | 'rateTable'
}

type AllCompanyProps = {
  companyUsers: CompanyUserForCompanyContext[]
  companies: CompanyForCompanyContext[]
  company: CompanyForCompanyContext | null
  companyId: string | null
  companyName: string
  permissions: Permission[]
  defaultRetentionPercent: number
  defaultPayAppDueOnDayOfMonth: number
  defaultDaysBeforePayAppDue: number
  companyAgingIntervalType: AgingIntervalType
  enableBillingWorksheets: boolean
  onSwitchCompanyId: (
    companyId: string | null,
    source: 'companySwitcherMenu' | 'newlyInvitedUser'
  ) => void
  onViewingWrongCompany: (metadata: WrongCompanyMetadata) => void
}

export type CompanyProps = Pick<
  AllCompanyProps,
  | 'company'
  | 'companyName'
  | 'defaultRetentionPercent'
  | 'defaultPayAppDueOnDayOfMonth'
  | 'defaultDaysBeforePayAppDue'
  | 'companyAgingIntervalType'
  | 'enableBillingWorksheets'
  | 'permissions'
> & {
  // Company ID is non-nullable in the context of a single company
  companyId: string
}

export type MultiCompanyProps = Pick<
  AllCompanyProps,
  | 'companies'
  | 'company'
  | 'companyId'
  | 'onSwitchCompanyId'
  | 'companyUsers'
  | 'onViewingWrongCompany'
>

const CompanyContext = createContext<AllCompanyProps>({
  companyUsers: [],
  companies: [],
  company: null,
  companyId: '',
  companyName: '',
  permissions: [],
  defaultRetentionPercent: DEFAULT_CONTRACT_RETENTION_PERCENT,
  defaultPayAppDueOnDayOfMonth: DEFAULT_DUE_TO_GC_DAY_OF_MONTH,
  defaultDaysBeforePayAppDue: DEFAULT_DAYS_BEFORE_PAY_APP_DUE,
  companyAgingIntervalType: DEFAULT_AGING_INTERVAL_TYPE,
  enableBillingWorksheets: false,
  onSwitchCompanyId: () => {
    // No-op
  },
  onViewingWrongCompany: () => {
    // No-op
  },
})

/**
 * Local storage key for the company ID currently being viewed. We include the user ID in this key
 * to avoid a case where someone is logged into multiple accounts in different tabs all using
 * the same storage key to persist the company. This could cause a fatal loop with tabs alternately
 * updating the company ID in local storage for different accounts authenticated in each tab.
 */
export function userCompanyStorageKey(userId: string) {
  return `currentCompanyId-${userId}`
}

// Company ID stored in local storage when viewing all companies.
export const ALL_COMPANIES_VALUE = 'allCompanies'

// Company ID stored in local storage when loading companies. We need a special value for this case
// to distinguish it from the null case where companies have loaded and the user is in the
// "All companies" view.
const LOADING_COMPANIES_VALUE = 'loadingCompanies'

type StoredCompanyValue =
  | { type: 'loading' }
  | { type: 'company'; companyId: string }
  | { type: 'allCompanies' }

function companyValueToStorageValue(value: StoredCompanyValue): string {
  switch (value.type) {
    case 'loading':
      return LOADING_COMPANIES_VALUE
    case 'allCompanies':
      return ALL_COMPANIES_VALUE
    case 'company':
      return value.companyId
  }
}

function storageValueToCompanyValue(value: string): StoredCompanyValue {
  switch (value) {
    case ALL_COMPANIES_VALUE:
      return { type: 'allCompanies' }
    case LOADING_COMPANIES_VALUE:
      return { type: 'loading' }

    // Value is a generic string
    // eslint-disable-next-line no-restricted-syntax
    default:
      return { type: 'company', companyId: value }
  }
}

// Hook for returning and updating the company currently being viewed, and persisting it in local
// storage. If a string is provided or returned, it should correspond to a company ID the current
// user has access to. A null value corresponds to viewing all companies, for users who have access
// to more than one company. An undefined value means companies are still loading.
function useCurrentCompanyId({
  initialCompanyValue,
  userId,
}: {
  initialCompanyValue: StoredCompanyValue
  userId: string
}) {
  const [currentCompanyId, setCurrentCompanyId] = useLocalStorage<string>(
    userCompanyStorageKey(userId),
    companyValueToStorageValue(initialCompanyValue)
  )

  return useMemo(() => {
    const onCurrentValueChange = (newCurrentCompanyValue: StoredCompanyValue) => {
      setCurrentCompanyId(companyValueToStorageValue(newCurrentCompanyValue))
    }
    const currentId = storageValueToCompanyValue(currentCompanyId)
    return [currentId, onCurrentValueChange] as const
  }, [currentCompanyId, setCurrentCompanyId])
}

export function CompanyProvider({ children }: { children: ReactNode }) {
  const { id: userId } = useUserContext()

  const { data } = useCurrentUserForCompanyContextQuery()
  const user = data?.currentUser

  const companyUsers = useMemo(() => {
    const companyUsers = user?.companyUsers ?? []
    return companyUsers.filter((companyUser) => companyUser.status === UserStatus.ACTIVE)
  }, [user?.companyUsers])

  const companies = useMemo(
    () => companyUsers.map((companyUser) => companyUser.company),
    [companyUsers]
  )
  const inferredCompanyValue: StoredCompanyValue = useMemo(
    () =>
      companies.length === 1
        ? { type: 'company', companyId: companies[0].id }
        : { type: 'allCompanies' },
    [companies]
  )
  const initialCompanyValue: StoredCompanyValue = useMemo(() => {
    if (!user) {
      return { type: 'loading' }
    }
    return inferredCompanyValue
  }, [inferredCompanyValue, user])
  const [companyValue, setCompanyValue] = useCurrentCompanyId({
    initialCompanyValue,
    userId,
  })
  const companyId = companyValue.type === 'company' ? companyValue.companyId : null
  const company = useMemo(
    () => companies.find((company) => company.id === companyId) ?? null,
    [companies, companyId]
  )
  const [showWrongCompanyDialog, setShowWrongCompanyDialog] = useState<WrongCompanyMetadata | null>(
    null
  )

  const permissions = useMemo(() => {
    const companyUser = companyUsers.find((companyUser) => companyUser.company.id === companyId)
    return [...(companyUser?.permissions ?? [])]
  }, [companyId, companyUsers])

  const onViewingWrongCompany = useCallback((metadata: WrongCompanyMetadata) => {
    setShowWrongCompanyDialog(metadata)
  }, [])

  const viewingCompany = useMemo(
    () =>
      showWrongCompanyDialog
        ? companies.find((company) => company.id === showWrongCompanyDialog.companyId)
        : undefined,
    [companies, showWrongCompanyDialog]
  )

  const handleCloseDialog = useCallback(() => setShowWrongCompanyDialog(null), [])

  // If there is no company ID stored in local storage yet, set it to the initial company ID
  // when the companies first load
  useEffect(() => {
    if (user && companyValue.type === 'loading') {
      setCompanyValue(inferredCompanyValue)
    }
  }, [user, companyValue.type, setCompanyValue, inferredCompanyValue])

  // If the company ID selected is not a company the user has access to (i.e. not in the user's
  // companies list), switch to the initial company
  useEffect(() => {
    if (user && companyValue.type === 'company' && !company) {
      setCompanyValue(inferredCompanyValue)
    }
  }, [user, companyValue.type, company, setCompanyValue, inferredCompanyValue])

  // If "All companies" is somehow selected but the user only belongs to a single company, switch to
  // that company
  useEffect(() => {
    if (user && companyValue.type === 'allCompanies' && inferredCompanyValue.type === 'company') {
      setCompanyValue(inferredCompanyValue)
    }
  }, [user, companyId, inferredCompanyValue, setCompanyValue, companyValue.type])

  const handleSwitchCompanyId = useCallback(
    (
      companyId: string | null,
      source: 'wrongCompanyDialog' | 'companySwitcherMenu' | 'newlyInvitedUser'
    ) => {
      const oldCompanyId =
        companyValue.type === 'company' ? companyValue.companyId : companyValue.type
      trackCompanySwitch({ oldCompanyId, newCompanyId: companyId, source })
      if (companyId) {
        setCompanyValue({ type: 'company', companyId })
      } else {
        setCompanyValue({ type: 'allCompanies' })
      }
    },
    [companyValue, setCompanyValue]
  )

  if (!user || companyValue.type === 'loading') {
    return <Loader />
  }

  // If user doesn't belong to a company, show the No Company screen
  if (companies.length === 0) {
    return <NoCompany />
  }

  const contextValue: AllCompanyProps = {
    companyUsers,
    companies,
    company,
    companyId,
    companyName: company?.name ?? '',
    permissions,
    defaultRetentionPercent: company?.defaultRetentionPercent ?? DEFAULT_CONTRACT_RETENTION_PERCENT,
    defaultDaysBeforePayAppDue:
      company?.defaultDaysBeforePayAppDue ?? DEFAULT_DAYS_BEFORE_PAY_APP_DUE,
    defaultPayAppDueOnDayOfMonth:
      company?.defaultPayAppDueOnDayOfMonth ?? DEFAULT_DUE_TO_GC_DAY_OF_MONTH,
    companyAgingIntervalType: company?.agingIntervalType ?? DEFAULT_AGING_INTERVAL_TYPE,
    enableBillingWorksheets: company?.enableBillingWorksheets ?? false,
    onSwitchCompanyId: handleSwitchCompanyId,
    onViewingWrongCompany,
  }

  return (
    <>
      <CompanyContext.Provider value={contextValue}>{children}</CompanyContext.Provider>
      {viewingCompany && showWrongCompanyDialog && (
        <SwitchCompanyDialog
          open
          onClose={handleCloseDialog}
          viewingCompany={viewingCompany}
          currentCompany={company}
          onSwitchCompanyId={(companyId) => handleSwitchCompanyId(companyId, 'wrongCompanyDialog')}
          pageType={showWrongCompanyDialog.pageType}
        />
      )}
    </>
  )
}

/**
 * This context should be used in components that are not guaranteed to have a single company
 * in view, i.e. where "All companies" may be selected. The company returned is nullable, with
 * null corresponding to the "All companies" view.
 */
export function useMultiCompanyContext(): MultiCompanyProps {
  const { companyUsers, companies, company, companyId, onSwitchCompanyId, onViewingWrongCompany } =
    useContext(CompanyContext)
  return {
    /** The `companyUsers` array corresponds to every company account that the single user belongs to */
    companyUsers,
    companies,
    company,
    companyId,
    onSwitchCompanyId,
    onViewingWrongCompany,
  }
}

/**
 * This context is intended only for components that are used with a specified company
 */
export function useCompanyContext(): CompanyProps {
  const {
    company,
    companyId,
    companyName,
    permissions,
    defaultRetentionPercent,
    defaultPayAppDueOnDayOfMonth,
    defaultDaysBeforePayAppDue,
    companyAgingIntervalType,
    enableBillingWorksheets,
  } = useContext(CompanyContext)

  return {
    companyId: companyId ?? '',
    company,
    companyName,
    permissions,
    defaultRetentionPercent,
    defaultPayAppDueOnDayOfMonth,
    defaultDaysBeforePayAppDue,
    companyAgingIntervalType,
    enableBillingWorksheets,
  }
}
