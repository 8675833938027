import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import {
  Dispatch,
  ReactNode,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
  useEffect,
  useReducer,
} from 'react'
import { launchImmediateStateUpdate } from '../../util/State'
import { ConfirmationType } from '../SitelineConfirmationDialog'
import { useSpreadsheetContext } from './SpreadsheetContext'
import { SpreadsheetReducerState } from './SpreadsheetReducer'

export enum SpreadsheetDataType {
  DOLLAR,
  NUMBER,
  PERCENT,
  DATE,
  SELECT,
  OTHER,
}

/**
 * Columns with these data types should be sized to fit their content. Other data type columns
 * are sized based on the table width.
 */
export const numericDataTypes = [
  SpreadsheetDataType.DOLLAR,
  SpreadsheetDataType.NUMBER,
  SpreadsheetDataType.PERCENT,
]

/** Style and presentation info for a single column in the spreadsheet */
interface BaseSpreadsheetColumn {
  id: string
  heading: ReactNode

  /** If true, all cells in this column will be editable */
  isEditable: boolean

  /** The type of content determines how the data is formatted and displayed */
  dataType: SpreadsheetDataType

  /**
   * Whether the column's width should be calculated based on the cells' contents.
   * If false, will expand or shrink to fit the widest cell in the column. Non-fitted
   * columns are distributed the rest of the table width.
   */
  grow?: boolean

  /** A minimum column width in pixels (px); overrides any width calculated from its content */
  minWidth?: number
  /** A maximum column width in pixels (px); overrides any width calculated from its content */
  maxWidth?: number

  /** The alignment of the header will be used on the entire column */
  align?: 'left' | 'center' | 'right'

  /** Whether to wrap only at word breaks or anywhere. Only applies to grow columns. */
  wordBreak?: 'break-all' | 'break-word'

  /** The width of the skeleton text, if provided. */
  skeletonWidth?: number

  /** Additional padding added to every cell in the column. Applied only to non-grow column. */
  extraWidth?: number

  /** If true, the header label won't be used as a min width when sizing the column */
  allowHeaderWrap?: boolean

  /** If none, will remove all default internal padding on the cell */
  padding?: 'default' | 'none'
}

interface SpreadsheetDateColumn extends BaseSpreadsheetColumn {
  dataType: SpreadsheetDataType.DATE
  timeZone: string
}

interface SpreadsheetNumberColumn extends BaseSpreadsheetColumn {
  dataType: SpreadsheetDataType.NUMBER

  /** If provided, will render numbers in this column using `.toFixed(n)` */
  fixedDecimals?: number
}

interface SpreadsheetDollarColumn extends BaseSpreadsheetColumn {
  dataType: SpreadsheetDataType.DOLLAR

  /**
   * If provided, input and display will use this many decimal places.
   * Min decimals is 2. Max decimals defaults to 2.
   */
  maxDecimals?: number
}

export interface SpreadsheetSelectColumnOption {
  id: string
  label: string
}

export interface SpreadsheetSelectColumnAddOption {
  label: string
  onClick: (rowId: string) => void
}

interface SpreadsheetSelectColumn extends BaseSpreadsheetColumn {
  dataType: SpreadsheetDataType.SELECT

  /** The options to show in the dropdown menu */
  options: SpreadsheetSelectColumnOption[]

  /** If provided, the dropdown will include a search input with the given placeholder */
  searchPlaceholder?: string

  /** If provided, the dropdown will include a row for adding a new option */
  addOption?: SpreadsheetSelectColumnAddOption

  /** If true, will include a "None" option in the menu with an empty string ID */
  allowEmpty?: boolean
}

export type SpreadsheetInputDataType =
  | SpreadsheetDataType.DATE
  | SpreadsheetDataType.DOLLAR
  | SpreadsheetDataType.NUMBER
  | SpreadsheetDataType.PERCENT
  | SpreadsheetDataType.OTHER

export type SpreadsheetColumn =
  | SpreadsheetDateColumn
  | SpreadsheetNumberColumn
  | SpreadsheetSelectColumn
  | SpreadsheetDollarColumn
  | (BaseSpreadsheetColumn & {
      dataType: Exclude<
        SpreadsheetDataType,
        | SpreadsheetDataType.DATE
        | SpreadsheetDataType.NUMBER
        | SpreadsheetDataType.SELECT
        | SpreadsheetDataType.DOLLAR
      >
    })

export enum SpreadsheetRowType {
  DEFAULT = 'DEFAULT',
  DIVIDER = 'DIVIDER',
  AUXILIARY = 'AUXILIARY',
  FOOTER = 'FOOTER',
}

/** A row of cells. Should be kept in sync with the comparator in `DraggableSpreadsheetRow.tsx`. */
export interface SpreadsheetRow {
  type: SpreadsheetRowType
  /** A unique ID for this row in the spreadsheet */
  id: string
  cells: SpreadsheetCell[]

  /** Group header rows have a darker background color and bold text */
  isGroupHeaderRow?: boolean

  /** A row that never allows editing */
  isNonEditableRow?: boolean

  /** Background color of the row */
  backgroundColor?: 'default' | 'white' | 'blue'

  /** If true, will add a border above the row to delineate the new grouping */
  isFirstInUngroupedBlock?: boolean

  /** If true, show the drag handle for reordering the row */
  allowDragging?: boolean

  /** If true, will disable dragging for the row and show the given message in a tooltip */
  isDragDisabled?: string

  /** Additional rows to render immediately following this one */
  auxiliaryRows?: SpreadsheetAuxiliaryRow[]

  /** Called when the row goes in or out of view on the screen */
  onVisibilityChange?: (visible: boolean) => void

  isFixed?: boolean

  /** Attach click event to the entire row (should only be used on header rows) */
  onClick?: () => void
}

export interface SpreadsheetDividerRow extends SpreadsheetRow {
  type: SpreadsheetRowType.DIVIDER
  cells: []
  isGroupHeaderRow: false
  isNonEditableRow: true
  isHighlighted: false
  isFirstInUngroupedBlock: false
}

export interface SpreadsheetAuxiliaryRow extends SpreadsheetRow {
  type: SpreadsheetRowType.AUXILIARY
  isNonEditableRow: true
}

export interface SpreadsheetFooterRow extends SpreadsheetRow {
  type: SpreadsheetRowType.FOOTER
  isNonEditableRow: true

  /** If true, will be fixed on the page to always be visible */
  isFixed: boolean
}

type CellBorders = {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

interface BaseCell {
  /** How many columns the cell should span. By default, spans a single column. */
  colSpan?: number

  /**
   * How many columns to offset the content by. Only used if less than the cell's `colSpan`.
   * By default, the offset is zero.
   */
  colOffset?: number

  /**
   * The default border is gray, or black when the cell is selected. A red border indicates that
   * a cell has an error.
   */
  borderColor?: 'default' | 'error'

  /**
   * Default background color. Undefined is transparent. Pass in 'none' for a transparent background color
   * with no hover styles applied.
   */
  backgroundColor?: 'green10' | 'red10' | 'none'

  /** Tooltip text to show on hover */
  tooltipTitle?: string

  /**
   * If the cell is editable, passing a character limit will enforce a max number
   * of characters that the user is allowed to type into the input
   */
  characterLimit?: number

  /** Used to apply additional styles to the inner cell */
  className?: string

  /**
   * This allows us to apply custom borders to the cell. If undefined, default cell borders will be
   * applied. If certain cell sides are omitted from the overrides object, they will also have
   * default borders applied.
   *
   * @example { top: '1px solid green', left: 'none' }
   */
  cellBorderOverrides?: CellBorders
}

export interface SpreadsheetCellPercentDataTypeOverrides {
  dataType: SpreadsheetDataType.PERCENT
  fixedDecimals: number
}

export interface SpreadsheetCellNumberDataTypeOverrides {
  dataType: SpreadsheetDataType.NUMBER
  fixedDecimals: number
}

export interface SpreadsheetCellDateDataTypeOverrides {
  dataType: SpreadsheetDataType.DATE
  timeZone: string
}

export type SpreadsheetValue = string | number | Moment

export interface DataCell extends BaseCell {
  type: 'DATA'

  /** For SELECT columns, this value is the ID of the selected option */
  value: SpreadsheetValue

  /**
   * If provided, will be called to validate an input before it is saved; if a message is
   * returned, the value will be reset and a toast will display the message.
   */
  validate?: ValidationFunction

  /** If true, uses a heavier font weight for the cell value */
  bold?: boolean

  /** If true, whatever content is shown when viewing has strikethrough styling */
  strikethrough?: boolean

  /** If true, whatever content is shown when viewing has italic styling */
  italic?: boolean

  /** Default text color is grey90 */
  color?: 'grey90' | 'grey50' | 'grey70' | 'red50'

  /** Content shown to the left of the cell */
  leftContent?: SpreadsheetCellLeftContent

  /** Content shown to the right of the cell */
  rightContent?: SpreadsheetElement

  /** Whether the cell is editable. By default it is inferred from the column. */
  isEditable?: boolean

  /**
   * If provided, this function will be called when the user attempts to edit the cell.
   * Instead of focusing the cell input, this function is called and no edit will occur.
   * Note that if onBeforeEdit prop is passed to both the cell and the spreadsheet, the
   * prop passed directly to the cell will be prioritized.
   */
  onBeforeEdit?: () => void

  /**
   * Overriding the datatype is an edge case where the column is of one type and you
   * need the spreadsheet cell to be treated as a different type. E.g., the column data
   * type is number and the override is percent.
   */
  dataTypeOverride?:
    | SpreadsheetCellPercentDataTypeOverrides
    | SpreadsheetCellDateDataTypeOverrides
    | SpreadsheetCellNumberDataTypeOverrides
}

export type SpreadsheetCellLeftContent = {
  align: 'space-between' | 'right'
} & SpreadsheetElement

export interface SpreadsheetElement {
  content: JSX.Element | null

  /** If any dependency changes, the content will be re-rendered */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[]
}

export interface ContentCell extends BaseCell, SpreadsheetElement {
  type: 'CONTENT'

  /** Whether to treat the cell as an editable data cell. False by default. */
  isEditable?: boolean
}

/** A single cell in the spreadsheet */
export type SpreadsheetCell = DataCell | ContentCell

/** Structured data to fill a table */
export interface SpreadsheetContent {
  rows: SpreadsheetRow[]

  /** Rows that is fixed at the bottom of the spreadsheet and excluded from reordering */
  footerRows?: SpreadsheetFooterRow[]

  /** Whether to show drag handles and enable reordering the spreadsheet rows */
  enableReorderRows?: boolean
}

export interface CellPosition {
  rowIndex: number
  cellIndex: number
}

export type ValidationFunctionResult =
  // Value is valid
  | null

  // Value is invalid, message is shown to the user
  | { type: 'error'; message: string }

  // Value must be confirmed by the user
  | {
      type: 'confirm'
      title: string
      details?: string
      confirmationType?: ConfirmationType
      confirmLabel?: string
      cancelLabel?: string
    }

  // A different value must be used in the cell.
  // We use this for rounding numbers when the precision of the current value is too high (eg: bid quantities)
  | {
      type: 'override'
      value: SpreadsheetValue
    }
export type ValidationFunction = (value: SpreadsheetValue) => ValidationFunctionResult

/**
 * Convenience function for creating a cell with type `DATA`. A data cell should be used for
 * numbers and string content.
 */
export function makeDataCell(
  value: SpreadsheetValue,
  {
    validate,
    colSpan,
    colOffset,
    color,
    backgroundColor,
    borderColor,
    bold,
    italic,
    strikethrough,
    leftContent,
    rightContent,
    tooltipTitle,
    characterLimit,
    isEditable,
    className,
    cellBorderOverrides,
    onBeforeEdit,
    dataTypeOverride,
  }: {
    validate?: ValidationFunction
    colSpan?: number
    colOffset?: number
    color?: 'grey90' | 'grey50' | 'grey70' | 'red50'
    backgroundColor?: BaseCell['backgroundColor']
    borderColor?: BaseCell['borderColor']
    bold?: boolean
    italic?: boolean
    strikethrough?: boolean
    leftContent?: SpreadsheetCellLeftContent
    rightContent?: SpreadsheetElement
    tooltipTitle?: string
    characterLimit?: number
    isEditable?: boolean
    className?: string
    cellBorderOverrides?: CellBorders
    onBeforeEdit?: () => void
    dataTypeOverride?:
      | SpreadsheetCellPercentDataTypeOverrides
      | SpreadsheetCellDateDataTypeOverrides
      | SpreadsheetCellNumberDataTypeOverrides
  } = {}
): DataCell {
  return {
    type: 'DATA',
    value,
    validate,
    colSpan,
    colOffset,
    color,
    borderColor,
    backgroundColor,
    bold,
    italic,
    strikethrough,
    leftContent,
    rightContent,
    tooltipTitle,
    characterLimit,
    isEditable,
    className,
    cellBorderOverrides,
    onBeforeEdit,
    dataTypeOverride,
  }
}

/**
 * Convenience function for creating a cell with type `CONTENT`. A content cell should be used
 * for arbitrary content/JSX. You should use `DATA` cells for numbers and strings.
 */
export function makeContentCell(
  content: JSX.Element | null,
  dependencies: ContentCell['dependencies'],
  {
    colSpan,
    colOffset,
    borderColor,
    backgroundColor,
    isEditable,
  }: {
    colSpan?: number
    colOffset?: number
    borderColor?: BaseCell['borderColor']
    backgroundColor?: BaseCell['backgroundColor']
    isEditable?: boolean
  } = {}
): ContentCell {
  return {
    type: 'CONTENT',
    dependencies,
    content,
    colSpan,
    colOffset,
    borderColor,
    backgroundColor,
    isEditable,
  }
}

/** Create a divider row */
export function makeDividerRow(id: string): SpreadsheetDividerRow {
  return {
    type: SpreadsheetRowType.DIVIDER,
    id,
    cells: [],
    isNonEditableRow: true,

    isFirstInUngroupedBlock: false,
    isGroupHeaderRow: false,
    isHighlighted: false,
  }
}

/** Returns the cell at a position in the spreadsheet */
export function cellAtPosition(position: CellPosition, content: SpreadsheetContent) {
  const { rowIndex, cellIndex } = position
  return content.rows[rowIndex].cells[cellIndex]
}

/** Returns true if the column for a given cell is editable */
export function isCellEditable(
  position: CellPosition,
  columns: SpreadsheetColumn[],
  content: SpreadsheetContent
) {
  const row = content.rows[position.rowIndex]
  if (row.isNonEditableRow) {
    return false
  }
  const columnIndex = columnIndexForCell(position, content)
  if (columnIndex >= columns.length || !columns[columnIndex].isEditable) {
    return false
  }
  const cell = cellAtPosition(position, content)
  // Only data cells are editable
  return cell.type === 'DATA' && cell.isEditable !== false
}

/** Returns the previous cell in the spreadsheet */
export function previousCell(position: CellPosition, content: SpreadsheetContent) {
  const { rowIndex, cellIndex } = position
  if (cellIndex - 1 >= 0) {
    return { rowIndex, cellIndex: cellIndex - 1 }
  }
  if (rowIndex - 1 >= 0) {
    const prevRowIndex = rowIndex - 1
    return { rowIndex: prevRowIndex, cellIndex: content.rows[prevRowIndex].cells.length - 1 }
  }
  return position
}

/** Returns the next cell in the spreadsheet */
export function nextCell(position: CellPosition, content: SpreadsheetContent) {
  const { rowIndex, cellIndex } = position
  const row = content.rows[rowIndex]
  if (cellIndex + 1 < row.cells.length) {
    return { rowIndex, cellIndex: cellIndex + 1 }
  }
  if (rowIndex + 1 < content.rows.length) {
    return { rowIndex: rowIndex + 1, cellIndex: 0 }
  }
  return position
}

/** Returns the cell above a given cell in the spreadsheet */
export function previousRow(position: CellPosition, content: SpreadsheetContent) {
  const { rowIndex } = position
  let toRowIndex = rowIndex
  if (rowIndex > 0) {
    do {
      toRowIndex = toRowIndex - 1
    } while (toRowIndex > 0 && content.rows[toRowIndex].isNonEditableRow)
    if (content.rows[toRowIndex].isNonEditableRow) {
      toRowIndex = rowIndex
    }
  }
  const cellColumnIndex = columnIndexForCell(position, content)
  return {
    rowIndex: toRowIndex,
    cellIndex: cellIndexForColumnIndex(toRowIndex, cellColumnIndex, content),
  }
}

/** Returns the cell below a given cell in the spreadsheet */
export function nextRow(position: CellPosition, content: SpreadsheetContent) {
  const { rowIndex } = position
  let toRowIndex = rowIndex
  if (rowIndex < content.rows.length - 1) {
    do {
      toRowIndex = toRowIndex + 1
    } while (toRowIndex < content.rows.length - 1 && content.rows[toRowIndex].isNonEditableRow)
    if (content.rows[toRowIndex].isNonEditableRow) {
      toRowIndex = rowIndex
    }
  }
  const cellColumnIndex = columnIndexForCell(position, content)
  return {
    rowIndex: toRowIndex,
    cellIndex: cellIndexForColumnIndex(toRowIndex, cellColumnIndex, content),
  }
}

/** Whether or not the next cell in a row is editable */
export function isNextCellEditable(
  cellIndex: number,
  row: SpreadsheetRow,
  rowIndex: number,
  columns: SpreadsheetColumn[]
) {
  const nextCellIndex = !row.isNonEditableRow ? cellIndex + 1 : undefined
  const nextColumnIndex =
    nextCellIndex !== undefined
      ? columnIndexForRowCell({ rowIndex, cellIndex: nextCellIndex }, row)
      : undefined
  const nextColumn =
    nextColumnIndex !== undefined && nextColumnIndex < row.cells.length
      ? columns[nextColumnIndex]
      : undefined
  const isNextEditable =
    nextColumnIndex !== undefined &&
    nextColumn !== undefined &&
    nextColumn.isEditable &&
    row.cells[nextColumnIndex].type === 'DATA'
  return isNextEditable
}

/** Whether or not the previous cell in a row is editable */
export function isPreviousCellEditable(
  cellIndex: number,
  row: SpreadsheetRow,
  rowIndex: number,
  columns: SpreadsheetColumn[]
) {
  if (cellIndex === 0) {
    return false
  }
  const prevCellIndex = row.isNonEditableRow ? undefined : cellIndex - 1
  const prevColumnIndex =
    prevCellIndex !== undefined
      ? columnIndexForRowCell({ rowIndex, cellIndex: prevCellIndex }, row)
      : undefined
  const prevColumn =
    prevColumnIndex !== undefined && prevColumnIndex < row.cells.length
      ? columns[prevColumnIndex]
      : undefined
  if (prevColumnIndex === undefined || prevColumn === undefined) {
    return false
  }
  const isPrevDataEditable = prevColumn.isEditable && row.cells[prevColumnIndex].type === 'DATA'
  const isPrevContentEditable =
    prevColumn.isEditable &&
    row.cells[prevColumnIndex].type === 'CONTENT' &&
    row.cells[prevColumnIndex].isEditable === true
  return isPrevDataEditable || isPrevContentEditable
}

/** Returns the previous or next editable cell in a spreadsheet */
export function moveToEditableCell(
  fromCell: CellPosition,
  direction: 'next' | 'previous',
  content: SpreadsheetContent,
  columns: SpreadsheetColumn[]
) {
  let currentCell = fromCell

  do {
    const next =
      direction === 'next' ? nextCell(currentCell, content) : previousCell(currentCell, content)
    if (_.isEqual(next, currentCell)) {
      currentCell = fromCell
      break
    }
    currentCell = next
  } while (!isCellEditable(currentCell, columns, content) && !_.isEqual(currentCell, fromCell))

  return isCellEditable(currentCell, columns, content) ? currentCell : undefined
}

/**
 * Reorder the rows in a spreadsheet, based on a given row moving to a new index. If
 * the row is a group header, the whole group will be moved to the new position.
 */
export function reorderSpreadsheet(rowId: string, toRowIndex: number, content: SpreadsheetContent) {
  const reorderedRowIndex = content.rows.findIndex((row) => row.id === rowId)
  if (reorderedRowIndex === -1) {
    return content
  }
  const hasGroups = content.rows.some((row) => row.isGroupHeaderRow)
  const reorderedRow = content.rows[reorderedRowIndex]
  const newRows = [...content.rows]
  if (hasGroups && reorderedRow.isGroupHeaderRow) {
    let numRowsToMove = 1
    // If moving a group header, the whole group should be moved together
    let index = reorderedRowIndex + 1
    while (
      index < content.rows.length &&
      !content.rows[index].isGroupHeaderRow &&
      content.rows[index].allowDragging
    ) {
      // If attempting to move between rows that are part of the group, do nothing
      if (index === toRowIndex) {
        return content
      }
      numRowsToMove++
      index++
    }
    const rowsToMove = newRows.splice(reorderedRowIndex, numRowsToMove)
    // If moving multiple rows, need to account for the extra rows in the final index
    let toAdjustedRowIndex = toRowIndex
    if (toRowIndex > reorderedRowIndex) {
      toAdjustedRowIndex -= numRowsToMove - 1
    }
    newRows.splice(toAdjustedRowIndex, 0, ...rowsToMove)
  } else {
    const [rowToMove] = newRows.splice(reorderedRowIndex, 1)
    newRows.splice(toRowIndex, 0, rowToMove)
  }
  return { ...content, rows: newRows }
}

/** Returns the spreadsheet column corresponding to an individual cell */
export function columnIndexForCell(
  position: CellPosition,
  content: SpreadsheetContent,
  isFooterRow?: boolean
) {
  const { rowIndex } = position
  let row = undefined
  if (isFooterRow && content.footerRows) {
    row = content.footerRows[rowIndex]
  } else if (!isFooterRow) {
    row = content.rows[rowIndex]
  }
  if (!row) {
    throw new Error("This spreadsheet's content is not formatted correctly")
  }
  return columnIndexForRowCell(position, row)
}

/** Returns the spreadsheet column corresponding to a cell in a row */
export function columnIndexForRowCell(position: CellPosition, row: SpreadsheetRow) {
  const { cellIndex } = position
  let columnIndex = 0
  let index = 0
  while (index < cellIndex && index < row.cells.length) {
    columnIndex += row.cells[index].colSpan ?? 1
    index++
  }
  return columnIndex
}

/** Returns the cell corresponding to a given column in a spreadsheet */
export function cellIndexForColumnIndex(
  rowIndex: number,
  columnIndex: number,
  content: SpreadsheetContent
) {
  let cellIndex = 0
  let cell = cellAtPosition({ rowIndex, cellIndex }, content)
  // Iterate through cells in the row to find a cell that spans the given columnIndex
  let index = cell.colSpan ?? 1
  while (index <= columnIndex && cellIndex < content.rows[rowIndex].cells.length) {
    cellIndex++
    cell = cellAtPosition({ rowIndex, cellIndex }, content)
    index += cell.colSpan ?? 1
  }
  return cellIndex
}

/** Validates that the content of the spreadsheet is consistent and can be rendered */
export function validateSpreadsheet(columns: SpreadsheetColumn[], content: SpreadsheetContent) {
  const numCols = columns.length
  function validateRowLength(row: SpreadsheetRow) {
    // Rows with no cells are rendered as dividers
    if (row.cells.length === 0) {
      return true
    }
    // The cells in each row should span the full set of columns in the table
    const colsSpanned = _.sumBy(row.cells, (cell) => cell.colSpan ?? 1)
    if (colsSpanned !== numCols) {
      console.error(`A row in this spreadsheet spans ${colsSpanned} of ${numCols} columns`, row)
      return false
    }
    return true
  }
  for (let rowIndex = 0; rowIndex < content.rows.length; rowIndex++) {
    const row = content.rows[rowIndex]
    if (!validateRowLength(row)) {
      return false
    }
    // Validate that the data in the cells matches the expect data type for the column
    for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
      const cell = row.cells[cellIndex]
      if (cell.type === 'DATA') {
        const value = cell.value
        const columnIndex = columnIndexForCell({ rowIndex, cellIndex }, content)
        const column = columns[columnIndex]
        if (numericDataTypes.includes(column.dataType)) {
          // If expecting a number, fail if unable to parse value as a number
          if (isNaN(Number(value))) {
            console.error('Expected a number in this spreadsheet cell, found:', value)
            return false
          }
        } else if (column.dataType === SpreadsheetDataType.DATE) {
          // If expecting a date, fail if not a valid date (accept empty value if no date)
          const timeZone = column.timeZone
          if (value && !moment.tz(value, timeZone).isValid()) {
            console.error('Expected a date in this spreadsheet cell, found: ', value)
            return false
          }
        }
      }
    }
  }
  if (content.footerRows) {
    for (let rowIndex = 0; rowIndex < content.footerRows.length; rowIndex++) {
      const row = content.footerRows[rowIndex]
      if (!validateRowLength(row)) {
        return false
      }
    }
  }
  // Check that every row has a unique ID
  if (_.uniqBy(content.rows, (row) => row.id).length !== content.rows.length) {
    console.error('Multiple rows found in this spreadsheet with the same ID')
    return false
  }
  return true
}

/** Preserves the position of a cell in a spreadsheet if the rows change */
export function preserveCellPosition(
  position: CellPosition | undefined,
  fromContent: SpreadsheetContent,
  toContent: SpreadsheetContent,
  columns: SpreadsheetColumn[]
): CellPosition | undefined {
  if (!position) {
    return undefined
  }
  const fromRow = fromContent.rows[position.rowIndex]
  const toRowIndex = toContent.rows.findIndex((row) => fromRow.id === row.id)
  if (toRowIndex === -1) {
    return undefined
  }
  const newCellPosition = { rowIndex: toRowIndex, cellIndex: position.cellIndex }
  if (!isCellEditable(newCellPosition, columns, toContent)) {
    return moveToEditableCell(newCellPosition, 'next', toContent, columns)
  }
  return newCellPosition
}

/**
 * Hook for managing whether one spreadsheet is in focus, meaning its hot keys are active
 * and will be routed to this spreadsheet's handlers. This is used within the `Spreadsheet`
 * component, users of that component shouldn't need to use this hook.
 */
export function useSpreadsheetFocus(spreadsheetId: string, focusOnMount: boolean) {
  const { focusedSpreadsheetId, focusSpreadsheet, removeSpreadsheet } = useSpreadsheetContext()
  useEffect(() => {
    if (focusOnMount) {
      // When a spreadsheet mounts, push it to the top of the stack so it's in focus
      focusSpreadsheet(spreadsheetId)
    }

    // When the spreadsheet unmounts, remove it from the stack so the subsequent spreadsheet gains focus
    return () => removeSpreadsheet(spreadsheetId)
  }, [spreadsheetId, focusSpreadsheet, removeSpreadsheet, focusOnMount])

  // Return whether this spreadsheet is focused
  return focusedSpreadsheetId === spreadsheetId
}

/** Include a column for the drag handle if the spreadsheet is reorderable and some row allows dragging */
export function includeDragHandleColumn(content: SpreadsheetContent) {
  return content.enableReorderRows && content.rows.some((row) => row.allowDragging)
}

/** Returns the empty cell value corresponding to each column data type */
export function emptyValueForColumn(column: SpreadsheetColumn) {
  switch (column.dataType) {
    case SpreadsheetDataType.DOLLAR:
    case SpreadsheetDataType.NUMBER:
    case SpreadsheetDataType.PERCENT:
      return 0
    case SpreadsheetDataType.OTHER:
    case SpreadsheetDataType.SELECT:
      return ''
    case SpreadsheetDataType.DATE:
      // Reset the date to today's date (since a date cell can't be empty)
      return moment.tz(column.timeZone)
  }
}

export async function pasteContentToSpreadsheet({
  onChange,
  selectedCell,
  columns,
  spreadsheetContent,
}: {
  onChange: SpreadsheetReducerState['onChange']
  selectedCell: CellPosition
  columns: SpreadsheetColumn[]
  spreadsheetContent: SpreadsheetContent
}) {
  if (onChange === undefined) {
    return
  }

  try {
    const rowIndex = selectedCell.rowIndex
    const row = spreadsheetContent.rows[rowIndex]
    const columnIndex = columnIndexForCell(selectedCell, spreadsheetContent)
    const column = columns[columnIndex]

    const text = await navigator.clipboard.readText()
    const valueToPaste = text.trim()

    const destinationDataType = column.dataType
    const valueWithoutDollarSigns = valueToPaste.replace(/\$/g, '')
    const asNumber = Number(valueWithoutDollarSigns)
    const isNumber = !_.isNaN(asNumber)

    switch (destinationDataType) {
      case SpreadsheetDataType.DOLLAR:
      case SpreadsheetDataType.PERCENT:
      case SpreadsheetDataType.NUMBER:
        if (isNumber) {
          onChange(row.id, column.id, asNumber)
        }
        break
      case SpreadsheetDataType.OTHER:
        onChange(row.id, column.id, valueToPaste)
        break
      case SpreadsheetDataType.SELECT: {
        const option = column.options.find((option) => option.label === valueToPaste)
        if (option) {
          onChange(row.id, column.id, option.id)
        }
        break
      }
      case SpreadsheetDataType.DATE:
        break
    }
  } catch (error) {
    console.error(`Unable to paste clipboard content: ${error.message}`)
  }
}

export function getSpreadsheetCellId(rowId: string, columnId: string) {
  return `${rowId}-${columnId}`
}

/**
 * Returns a reducer that doesn't use automatic batching (introduced in React 18).
 * The spreadsheet reducers were built before automatic batching and depends on assumptions
 * about call order, so we need to disable batching for reliable sizing and action handling.
 * https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
 */
export function useReducerWithoutBatching<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Reducer<any, any>,
>(reducer: R, initialState: ReducerState<R>) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const dispatchWithoutBatching: Dispatch<ReducerAction<R>> = useCallback(
    (args: ReducerAction<R>) => {
      return launchImmediateStateUpdate(() => dispatch(args))
    },
    []
  )

  return [state, dispatchWithoutBatching] as const
}
