import { Grid, Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { colors, makeStylesFast } from 'siteline-common-web'
import { Z_INDEX } from '../../../common/themes/Main'

const useStyles = makeStylesFast((theme: Theme) => ({
  thumbnail: {
    overflow: 'hidden',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${colors.grey20}`,
    height: 160,
    '&.clickable': {
      cursor: 'pointer',
    },
    '&.loadingMask': {
      position: 'absolute',
      zIndex: Z_INDEX.mask,
      transform: 'scaleX(1.01)',
      marginLeft: 2,
    },
  },
}))

interface InlineFormsPreviewLoadingThumbnailsProps {
  thumbnailWidth?: number
}

/** Skeleton component for the inline form preview */
export function InlineFormsPreviewLoadingThumbnails({
  thumbnailWidth,
}: InlineFormsPreviewLoadingThumbnailsProps) {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      {_.range(4).map((pageIndex) => {
        return (
          <Grid item xs={3} key={pageIndex}>
            <Skeleton
              variant="rectangular"
              width={thumbnailWidth ?? '100%'}
              className={clsx(classes.thumbnail, { loadingMask: thumbnailWidth !== undefined })}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
