import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { addIntegration } from '@sentry/react'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { Suspense, useMemo } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import 'reset-css'
import {
  MonthlyBillingType,
  ProjectOnboardingTask,
  RetentionTrackingLevel,
} from 'siteline-common-all'
import {
  SitelineSnackbarProvider,
  tanstackRouterBrowserTracingIntegration,
} from 'siteline-common-web'
import { apolloClient } from './client'
import { NotFound } from './common/components/404'
import { Loader } from './common/components/Loader'
import { Maintenance } from './common/components/Maintenance'
import { SitelineAlert } from './common/components/SitelineAlert'
import {
  SitelineAuthProvider,
  useSitelineAuthContext,
} from './common/components/SitelineAuthProvider'
import { SitelineConfirmationProvider } from './common/components/SitelineConfirmation'
import { IS_MAINTENANCE_ENABLED } from './common/config/constants'
import {
  DashboardFiltersInput,
  ImportProjectOnboardingMetadataProperties,
} from './common/graphql/apollo-operations'
import { MainTheme } from './common/themes/Main'
import { routeTree } from './routeTree.gen'
import { RouterContext } from './routes/__root'

export type PayAppNavigationState = {
  payAppName: string
}

export type ProjectNotesNavigationState = {
  isEdit: boolean
}

export type OnboardingNavigationState = {
  onboardingStep: ProjectOnboardingTask
}

export type CreateProjectNavigationState = {
  gcProject: ImportProjectOnboardingMetadataProperties | null
  erpProject: ImportProjectOnboardingMetadataProperties | null
  billingType: MonthlyBillingType

  // Forces the retention tracking level with whatever is selected in the CreateProjectDialog.
  // For unit-price and T&M contracts, we ask whether retention is enabled and pass 'NONE' if disabled.
  // We pass undefined in all other cases, which allows the user to select a level.
  retentionTrackingLevel?: RetentionTrackingLevel
}

export type PreSitelinePayAppListNavigationState = {
  openPreSitelinePayAppId: string
}

export type SovNavigationState = {
  isEditing?: boolean
}

export type RateTableNavigationState = {
  isEdit?: boolean
  isDuplicate?: boolean
  previousLocation?: string
}

export type CompanyUsersNavigationState = {
  officeIdFilter?: string
}

export type VendorsTrackerNavigationState = {
  searchProjectName?: string
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }

  // TanStack Router doesn't have a way to specify state types at the route level, so we need to
  // define states globally instead.
  // See https://github.com/TanStack/router/discussions/1342#discussioncomment-8848490
  interface HistoryState {
    reportingQueryFilters?: DashboardFiltersInput
    rateTable?: RateTableNavigationState
    onboarding?: OnboardingNavigationState
    sov?: SovNavigationState
    createProject?: CreateProjectNavigationState
    projectNotes?: ProjectNotesNavigationState
    payApp?: PayAppNavigationState
    preSitelinePayAppList?: PreSitelinePayAppListNavigationState
    vendorsTracker?: VendorsTrackerNavigationState
    companyUsers?: CompanyUsersNavigationState
  }
}

// Create a new router instance
const router = createRouter({
  routeTree,
  context: { isAuthenticated: false },
  defaultNotFoundComponent: NotFound,
})

// Add router integration to Sentry
addIntegration(tanstackRouterBrowserTracingIntegration(router))

function InnerApp() {
  const { userId, email } = useSitelineAuthContext()
  const context = useMemo((): RouterContext => ({ isAuthenticated: userId !== null }), [userId])

  // If maintenance, skip the router and show the maintenance page instead
  const shouldShowMaintenance = IS_MAINTENANCE_ENABLED && (!email || !email.match(/@siteline.com/))
  if (shouldShowMaintenance) {
    return <Maintenance />
  }

  return <RouterProvider router={router} context={context} />
}

// Wraps the app with the current Material theme and CssBaseline (applies a basic level of styles
// to all of our components)
export function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={MainTheme}>
              <CssBaseline />
              <Suspense fallback={<Loader />}>
                <SitelineSnackbarProvider AlertComponent={SitelineAlert}>
                  <SitelineConfirmationProvider>
                    <SitelineAuthProvider>
                      <InnerApp />
                    </SitelineAuthProvider>
                  </SitelineConfirmationProvider>
                </SitelineSnackbarProvider>
              </Suspense>
            </ThemeProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </LocalizationProvider>
    </ApolloProvider>
  )
}
