import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'
import { integrationTypes } from 'siteline-common-all'
import { colors, getIntegrationWebsite } from 'siteline-common-web'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'
import {
  WriteSyncResultScreen,
  WriteSyncResultScreenAction,
  WriteSyncResultScreenText,
} from './WriteSyncResultScreen'

interface WriteSyncSuccessScreenProps {
  integration: MinimalIntegrationProperties
  projectId: string
  payload: integrationTypes.WriteSyncPayload
  result: integrationTypes.WriteSyncResultSuccess
  onClose: () => void
}

const i18nBase = 'integrations.success'

/**
 * Screen shown when a sync request has completed and was successful.
 */
export function WriteSyncSuccessScreen({
  integration,
  projectId,
  payload,
  result,
  onClose,
}: WriteSyncSuccessScreenProps) {
  const { t } = useTranslation()
  const integrationName = integration.shortName
  const thirdPartyUrl = getIntegrationWebsite(integration)

  let text: WriteSyncResultScreenText

  let extra: JSX.Element | undefined
  if (result.message) {
    extra = <SitelineAlert severity="info">{result.message}</SitelineAlert>
  } else if (result.warning) {
    extra = <SitelineAlert severity="warning">{result.warning}</SitelineAlert>
  }

  if (payload.type === 'legalRequirement') {
    // The legal requirement sync call succeeded with no errors
    // There's no "action" defined here, so we just show the "close" button
    text = {
      actions: [],
      title: t('integrations.success_legal_requirement.title'),
      description: t('integrations.success_legal_requirement.description', { integrationName }),
      icon: <CheckIcon className="image" style={{ color: colors.green40, fontSize: 72 }} />,
      closeLabel: t('common.actions.close'),
      extra,
    }
  } else {
    // The pay app (invoice and/or LTLW) sync call succeeded with no errors
    let description = t(`${i18nBase}.description.synced`, { integrationName })
    if (thirdPartyUrl) {
      description = t(`${i18nBase}.description.submit`, { integrationName })
    }

    // If there's a thirdPartyUrl, allow the click action, otherwise don't have one. If there is
    // no onClickAction, then we won't show the button in the dialog
    const actions: WriteSyncResultScreenAction[] = []
    if (thirdPartyUrl) {
      actions.push({
        title: t(`${i18nBase}.action`, { integrationName }),
        onClick: () => window.open(thirdPartyUrl, '_blank'),
      })
    }

    text = {
      actions,
      title: t(`${i18nBase}.title`),
      description,
      icon: <CheckIcon className="image" style={{ color: colors.green40, fontSize: 60 }} />,
      closeLabel: t('common.actions.close'),
      extra,
    }
  }

  return (
    <WriteSyncResultScreen
      text={text}
      integration={integration}
      payload={payload}
      projectId={projectId}
      onClose={onClose}
    />
  )
}
