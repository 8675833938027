import { TFunction } from 'i18next'
import { BillingType } from 'siteline-common-all'
import { RetentionView, TaxesView } from '../../components/billing/invoice/InvoiceReducer'
import { RetentionViewToggle } from '../../components/billing/invoice/retention/RetentionViewToggle'
import { TaxesViewToggle } from '../../components/billing/invoice/taxes/TaxesViewToggle'
import { SpreadsheetColumn, SpreadsheetDataType } from '../components/Spreadsheet/Spreadsheet.lib'
import { BaseInvoiceColumn, InvoiceColumnsProps } from './Invoice'
import { NUM_FIXED_UNIT_DECIMALS } from './ManageUnitPriceSovColumn'

export enum UnitPriceInvoiceColumn {
  QUANTITY_CONTRACTED = 'quantityContracted',
  UNIT_NAME = 'unitName',
  UNIT_PRICE = 'unitPrice',
  PREVIOUS_UNITS_BILLED = 'previousUnitsBilled',
  PROGRESS_UNITS_BILLED = 'progressUnitsBilled',
  TOTAL_UNITS_BILLED = 'totalUnitsBilled',
  TOTAL_AMOUNT_BILLED = 'totalAmountBilled',
  UNITS_TO_FINISH = 'unitsToFinish',
}

/** Returns the list of columns to show on the pay app invoice table */
export function getUnitPriceInvoiceColumns(
  t: TFunction,
  {
    disableEditing,
    isRetentionOnly,
    retentionView,
    trackingType,
    onRetentionViewChange,
    taxesView,
    onTaxesViewChange,
    shouldIncludeCostCode,
  }: InvoiceColumnsProps
): SpreadsheetColumn[] {
  const headingI18nBase = 'projects.subcontractors.pay_app.invoice.headers'

  const lineItemColumns: SpreadsheetColumn[] = [
    {
      id: BaseInvoiceColumn.CODE,
      heading: t(`${headingI18nBase}.number`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      // This column holds the date in expanded event rows
      minWidth: 165,
      maxWidth: 200,
      wordBreak: 'break-all',
    },
    {
      id: BaseInvoiceColumn.NAME,
      heading: t(`${headingI18nBase}.description`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
      grow: true,
      skeletonWidth: 150,
      minWidth: 150,
    },
    ...(shouldIncludeCostCode
      ? [
          {
            id: BaseInvoiceColumn.COST_CODE,
            heading: t(`${headingI18nBase}.cost_code`),
            isEditable: false,
            dataType: SpreadsheetDataType.OTHER as const,
            align: 'left' as const,
          },
        ]
      : []),
    {
      id: UnitPriceInvoiceColumn.QUANTITY_CONTRACTED,
      heading: t(`${headingI18nBase}.units_contracted`),
      isEditable: false,
      dataType: SpreadsheetDataType.NUMBER,
      align: 'right',
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
      // Add padding to fit edit icon on hover
      extraWidth: 30,
    },
    {
      id: UnitPriceInvoiceColumn.UNIT_NAME,
      heading: t(`${headingI18nBase}.unit_of_measure`),
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'left',
    },
    {
      id: UnitPriceInvoiceColumn.UNIT_PRICE,
      heading: t(`${headingI18nBase}.unit_price`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      maxDecimals: 4,
    },
    {
      id: UnitPriceInvoiceColumn.PREVIOUS_UNITS_BILLED,
      heading: t(`${headingI18nBase}.previous_units_billed`),
      isEditable: false,
      dataType: SpreadsheetDataType.NUMBER,
      align: 'right',
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
    },
  ]

  const progressColumns: SpreadsheetColumn[] = [
    {
      id: UnitPriceInvoiceColumn.PROGRESS_UNITS_BILLED,
      heading: t(`${headingI18nBase}.current_quantity`),
      isEditable: !disableEditing && !isRetentionOnly,
      dataType: SpreadsheetDataType.NUMBER,
      align: 'right',
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
    },
    {
      id: BaseInvoiceColumn.PROGRESS_BILLED,
      heading: t(`${headingI18nBase}.current_amount`),
      isEditable: !disableEditing && !isRetentionOnly,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
      minWidth: 116,
    },
    {
      id: UnitPriceInvoiceColumn.TOTAL_UNITS_BILLED,
      heading: t(`${headingI18nBase}.quantity_to_date`),
      isEditable: false,
      dataType: SpreadsheetDataType.NUMBER,
      align: 'right',
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
    },
    {
      id: UnitPriceInvoiceColumn.TOTAL_AMOUNT_BILLED,
      heading: t(`${headingI18nBase}.amount_to_date`),
      isEditable: false,
      dataType: SpreadsheetDataType.DOLLAR,
      align: 'right',
    },
    {
      id: UnitPriceInvoiceColumn.UNITS_TO_FINISH,
      heading: t(`${headingI18nBase}.units_to_finish`),
      isEditable: false,
      dataType: SpreadsheetDataType.NUMBER,
      align: 'right',
      fixedDecimals: NUM_FIXED_UNIT_DECIMALS,
    },
  ]

  if (retentionView) {
    const dataType =
      retentionView === RetentionView.HELD_CURRENT_PERCENT ||
      retentionView === RetentionView.HELD_TO_DATE_PERCENT
        ? SpreadsheetDataType.PERCENT
        : SpreadsheetDataType.DOLLAR
    progressColumns.push({
      id: BaseInvoiceColumn.RETENTION_PERCENT,
      heading: (
        <RetentionViewToggle
          retentionView={retentionView}
          onViewChange={(newView: RetentionView) => {
            if (onRetentionViewChange) {
              onRetentionViewChange(newView)
            }
          }}
          trackingType={trackingType}
        />
      ),
      isEditable: false,
      dataType,
      align: 'right',
      // Add padding to fit edit icon on hover
      extraWidth: 90,
      minWidth: 100,
    })
  }

  if (taxesView) {
    const dataType =
      taxesView === TaxesView.TAX_RATE ? SpreadsheetDataType.PERCENT : SpreadsheetDataType.DOLLAR
    progressColumns.push({
      id: BaseInvoiceColumn.TAXES,
      heading: (
        <TaxesViewToggle
          taxesView={taxesView}
          onViewChange={(newView: TaxesView) => {
            if (onTaxesViewChange) {
              onTaxesViewChange(newView)
            }
          }}
          billingType={BillingType.LUMP_SUM}
        />
      ),
      isEditable: false,
      dataType,
      align: 'right',
      // Add padding to fit edit icon on hover
      extraWidth: 90,
      minWidth: 100,
    })
  }

  const columns: SpreadsheetColumn[] = [
    ...lineItemColumns,
    ...progressColumns,
    {
      id: BaseInvoiceColumn.HISTORY,
      heading: '',
      isEditable: false,
      dataType: SpreadsheetDataType.OTHER,
      align: 'right',
      minWidth: 55,
      maxWidth: 55,
      skeletonWidth: 0,
      grow: true,
    },
  ]

  return columns
}
