import { FormControlLabel } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { SitelineCheckbox } from '../../../common/components/SitelineCheckbox'
import { Contact } from './NewContactForm'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .heading': {
      marginBottom: theme.spacing(2),
    },
  },
}))

interface SelectContactsFormProps {
  onAddContact: (id: string) => void
  onRemoveContact: (id: string) => void
  contactIds: string[]
  availableContacts: Contact[]
}

/** A dialog view for selecting contacts from a given list */
export function SelectContactsForm({
  onAddContact,
  onRemoveContact,
  contactIds,
  availableContacts,
}: SelectContactsFormProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const sortedContacts = _.orderBy(availableContacts, (contact) => contact.fullName, 'asc')

  return (
    <div className={classes.root}>
      <SitelineText variant="h4" color="grey50" className="heading">
        {t('projects.subcontractors.pay_app.submit.email_dialog.select_recipients')}
      </SitelineText>
      {sortedContacts.map((contact) => (
        <div key={contact.id}>
          <FormControlLabel
            control={
              <SitelineCheckbox
                name="select-contact-form"
                value={contactIds.includes(contact.id)}
                checked={contactIds.includes(contact.id)}
                onChange={(ev, checked) => {
                  if (checked) {
                    onAddContact(contact.id)
                  } else {
                    onRemoveContact(contact.id)
                  }
                }}
              />
            }
            label={`${contact.fullName} (${contact.email})`}
          />
        </div>
      ))}
    </div>
  )
}
