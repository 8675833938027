import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import type { CellObject, ColInfo } from 'xlsx-js-style'
import { apolloClient } from '../../client'
import { UserProps } from '../contexts/UserContext'
import {
  AgingAmountType,
  BillingType,
  CashForecastPeriodType,
  ContractMonthlyStatusFilter,
  DashboardFiltersInput,
  GetGeneralContractorsDocument,
  GetGeneralContractorsQuery,
  GetGeneralContractorsQueryVariables,
  GetLeadPMsDocument,
  GetLeadPMsQuery,
  GetLeadPMsQueryVariables,
  LocationProperties,
} from '../graphql/apollo-operations'
import { getOfficeName } from './Office'

export type ExportContext = {
  t: TFunction
  filters: DashboardFiltersInput
  search: string
  user: UserProps
  companyId: string | null
  locations: LocationProperties[]
  month: number
  year: number
  cashForecastPeriod: CashForecastPeriodType
}

/**
 * Returns a cell with the provided text as bold.
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */
export function bold(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      font: { bold: true },
    },
  })
}

/**
 * Returns a cell with the provided text as italic.
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */
export function italic(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      font: { italic: true },
    },
  })
}

/**
 * Returns a cell with the provided text right-aligned
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */
export function rightAlign(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      alignment: { horizontal: 'right' },
    },
  })
}

/**
 * Returns a cell with the provided text left-aligned
 * Note that this only works with the `xlsx-js-style` fork.
 * See https://github.com/gitbrent/xlsx-js-style#cell-style-properties
 */
export function leftAlign(cell: CellObject): CellObject {
  return _.merge({}, cell, {
    s: {
      alignment: { horizontal: 'left' },
    },
  })
}

/**
 * Returns a blank cell.
 * See https://docs.sheetjs.com/docs/csf/cell
 */
export function blank(): CellObject {
  return { t: 'z' }
}

/**
 * Returns a simple string cell.
 * See https://docs.sheetjs.com/docs/csf/cell
 */
export function string(str: string): CellObject {
  return {
    t: 's',
    v: str,
    s: {
      alignment: { vertical: 'top' },
    },
  }
}

/**
 * Returns a simple string cell that wraps its text.
 * See https://docs.sheetjs.com/docs/csf/cell
 */
export function wrap(str: string): CellObject {
  return {
    t: 's',
    v: str,
    s: {
      alignment: { wrapText: true, vertical: 'top' },
    },
  }
}

/**
 * Returns a simple number cell
 * See https://docs.sheetjs.com/docs/csf/cell
 */
export function number(num: number): CellObject {
  return {
    t: 'n',
    v: num,
    s: {
      alignment: { vertical: 'top' },
    },
  }
}

/**
 * Returns a formatted amount cell
 * See https://docs.sheetjs.com/docs/csf/cell
 */
export function amount(amount: number | null): CellObject {
  if (amount === null) {
    return blank()
  }
  return {
    t: 'n',
    v: amount,
    z: '$#,##0.00',
    s: {
      alignment: { vertical: 'top' },
    },
  }
}

/**
 * Returns a formatted percent cell.
 * See https://docs.sheetjs.com/docs/csf/cell
 */
export function percent(percent: number | null): CellObject {
  if (percent === null) {
    return blank()
  }
  return {
    t: 'n',
    v: percent,
    z: '0.0%',
    s: {
      alignment: { vertical: 'top' },
    },
  }
}

/**
 * Takes an array of rows and returns an array of column sizes, extracted by looking at the longest
 * value per column.
 */
export function getColumnSizesFromRows(rows: CellObject[][]): ColInfo[] {
  const colCount = _.max(rows.map((row) => row.length))
  const colSizes = _.range(colCount ?? 0).map((colIndex) => {
    // Other columns depend on the longest value in the column
    const maxLength = _.max(
      rows.map((row) => {
        const value = row[colIndex] ?? ''

        // Extract string or number value from cell
        const cellValue = _.isString(value) || _.isNumber(value) ? value : value.v
        if (!cellValue) {
          return 0
        }

        // If cell is a string, return its length
        if (_.isString(cellValue)) {
          return cellValue.length

          // If cell is a number, add extra characters to account for currency formatting
        } else {
          return String(cellValue).length + 8
        }
      })
    )

    const maxColSize = 60
    const max = Math.min(maxLength ?? 0, maxColSize)

    // First column has a minimum width of 20 and max of 60
    if (colIndex === 0) {
      const firstColMinSize = 20
      return { wch: Math.max(max, firstColMinSize) }

      // Other columns have no minimum width, but they have a max of 60
    } else {
      return { wch: max }
    }
  })
  return colSizes
}

/**
 * Returns a list of all lead PMs to be used in an export.
 */
export async function getLeadPMs(companyId: string | null): Promise<GetLeadPMsQuery['leadPMs']> {
  const query = await apolloClient.query<GetLeadPMsQuery, GetLeadPMsQueryVariables>({
    query: GetLeadPMsDocument,
    variables: { companyId },
  })
  return query.data.leadPMs
}

/**
 * Returns a list of all GCs to be used in an export.
 */
export async function getGeneralContractors(
  companyId: string | null
): Promise<GetGeneralContractorsQuery['generalContractors']> {
  const query = await apolloClient.query<
    GetGeneralContractorsQuery,
    GetGeneralContractorsQueryVariables
  >({
    query: GetGeneralContractorsDocument,
    variables: { companyId },
  })
  return query.data.generalContractors
}

interface GetFilterRowsParams {
  ctx: ExportContext
  leadPMs: GetLeadPMsQuery['leadPMs']
  generalContractors: GetGeneralContractorsQuery['generalContractors']
  /** Forecast maps to the billing forecast */
  exportFrom: 'aging' | 'billing' | 'forecast' | 'cashForecast' | 'overview'
}

/**
 * Returns a list of rows for each filter used in the export.
 */
export function getFilterRows({
  ctx,
  leadPMs,
  generalContractors,
  exportFrom,
}: GetFilterRowsParams): CellObject[][] {
  const rows: CellObject[][] = []
  const filterAll = ctx.t(`reporting_home.filters.all`)

  // Aging amount type filter
  if (exportFrom === 'aging') {
    const agingAmountType =
      ctx.filters.agingAmountType ?? AgingAmountType.PROGRESS_MINUS_RETENTION_HELD
    let agingAmountTypeName: string
    switch (agingAmountType) {
      case AgingAmountType.PROGRESS_MINUS_RETENTION_HELD:
        agingAmountTypeName = ctx.t(
          `reporting_home.filters.aging_amount_types.progress_minus_retention_held`
        )
        break
      case AgingAmountType.RETENTION_RELEASED:
        agingAmountTypeName = ctx.t(`reporting_home.filters.aging_amount_types.retention_released`)
        break
      case AgingAmountType.AMOUNT_DUE:
        agingAmountTypeName = ctx.t(`reporting_home.filters.aging_amount_types.amount_due`)
        break
    }
    rows.push([
      bold(string(ctx.t(`reporting_home.filters.aging_amount_type`))),
      string(agingAmountTypeName),
    ])
  }

  // Billing type filter
  if (exportFrom !== 'forecast' && exportFrom !== 'overview') {
    const billingTypes = ctx.filters.billingType ?? []

    // Switch is exhaustive, but eslint doesn't know that
    const billingTypeNames = billingTypes.map((billingType) => {
      switch (billingType) {
        case BillingType.LUMP_SUM:
          return ctx.t(`reporting_home.filters.project_types.lump_sum`)
        case BillingType.UNIT_PRICE:
          return ctx.t(`reporting_home.filters.project_types.unit_price`)
        case BillingType.TIME_AND_MATERIALS:
          return ctx.t('reporting_home.filters.project_types.time_and_materials')
        case BillingType.QUICK:
          return ctx.t(`reporting_home.filters.project_types.quick_bill`)
      }
    })
    rows.push([
      bold(string(ctx.t(`reporting_home.filters.project_type`))),
      string(billingTypes.length === 0 ? filterAll : billingTypeNames.join(', ')),
    ])
  }

  // Lead PM filter
  const leadPMIds = ctx.filters.leadPMIds ?? []
  const leadPMNames = leadPMIds.map((id) => {
    const found = leadPMs.find((pm) => pm.user.id === id)
    if (!found) {
      return ''
    }
    return `${found.user.firstName} ${found.user.lastName}`
  })
  rows.push([
    bold(string(ctx.t(`reporting_home.filters.lead_pm`))),
    string(leadPMIds.length === 0 ? filterAll : leadPMNames.join(', ')),
  ])

  // Office filter
  const officeIds = ctx.filters.officeIds ?? []
  const locations = ctx.locations
  const officeNames = officeIds.map((id) => {
    const found = locations.find((location) => location.id === id)
    if (!found) {
      return ''
    }
    return getOfficeName(found)
  })
  rows.push([
    bold(string(ctx.t(`reporting_home.filters.office`))),
    string(officeIds.length === 0 ? filterAll : officeNames.join(', ')),
  ])

  // GC filter
  const generalContractorIds = ctx.filters.generalContractorIds ?? []
  const gcNames = generalContractorIds.map((id) => {
    const found = generalContractors.find((gc) => gc.id === id)
    if (!found) {
      return ''
    }
    return found.name
  })
  rows.push([
    bold(string(ctx.t(`reporting_home.filters.gc`))),
    string(generalContractorIds.length === 0 ? filterAll : gcNames.join(', ')),
  ])

  // Project status filter
  if (exportFrom === 'billing') {
    const projectStatuses = ctx.filters.projectStatus ?? []
    // Switch is exhaustive, but eslint doesn't know that
    const projectStatusNames = projectStatuses.map((projectStatus) => {
      switch (projectStatus) {
        case ContractMonthlyStatusFilter.DRAFT_ON_TIME:
          return ctx.t('reporting_home.filters.project_statuses.draft_on_time')
        case ContractMonthlyStatusFilter.NOT_BILLING:
          return ctx.t('reporting_home.filters.project_statuses.not_billing')
        case ContractMonthlyStatusFilter.PAST_DUE:
          return ctx.t('reporting_home.filters.project_statuses.past_due')
        case ContractMonthlyStatusFilter.SUBMITTED:
          return ctx.t('reporting_home.filters.project_statuses.submitted')
      }
    })
    rows.push([
      bold(string(ctx.t('reporting_home.filters.project_status'))),
      string(projectStatuses.length === 0 ? filterAll : projectStatusNames.join(', ')),
    ])
  }

  // Search
  if (ctx.search) {
    rows.push([
      bold(string(ctx.t(`reporting_home.filters.search_term`))),
      italic(string(ctx.search)),
    ])
  }

  return rows
}

/**
 * Returns a moment representing the month for which the report is being generated.
 */
export function getViewingDate(ctx: Pick<ExportContext, 'month' | 'year'>): Moment {
  return moment
    .tz(moment.tz.guess())
    .set({
      month: ctx.month,
      year: ctx.year,
    })
    .startOf('month')
}
