import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_AGING_INTERVAL_TYPE,
  DEFAULT_CONTRACT_RETENTION_PERCENT,
  DEFAULT_DAYS_BEFORE_PAY_APP_DUE,
  DEFAULT_DUE_TO_GC_DAY_OF_MONTH,
  DEFAULT_VENDOR_LIEN_WAIVER_REMINDER_FREQUENCY,
  MAX_DAYS_BEFORE_PAY_APP_DUE,
  MIN_COMPANY_NICKNAME_LENGTH,
  VendorLienWaiverReminderFrequency,
  decimalToPercent,
} from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { useSitelineConfirmation } from '../../common/components/SitelineConfirmation'
import { useCompanyContext } from '../../common/contexts/CompanyContext'
import {
  AgingIntervalType,
  useUpdateCompanyRequestMutation,
} from '../../common/graphql/apollo-operations'
import {
  trackCompanyAgingIntervalTypeUpdated,
  trackCompanyAuditTrailEnabledUpdated,
  trackCompanyDefaultRetentionPercentUpdated,
  trackCompanyNameUpdated,
  trackCompanyNicknameUpdated,
  trackVendorLienWaiverReminderFrequencyUpdated,
} from '../../common/util/MetricsTracking'
import { MAX_DUE_TO_GC_DAY_OF_MONTH } from '../../common/util/PayApp'
import { RetentionPercentInput } from '../billing/invoice/retention/RetentionPercentInput'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .flex': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .editButton': {
      color: colors.grey50,
      margin: theme.spacing(-1, -1, 0, 0),
    },
    '& .saveButton': {
      margin: theme.spacing(-1, 0, 0, 2),
    },
    '& .title': {
      marginBottom: theme.spacing(2),
    },
    '& .heading': {
      alignItems: 'center',
      '& .Siteline-h4': {
        margin: theme.spacing(1, 0),
      },
    },
    '& .section': {
      marginBottom: theme.spacing(4),
      padding: theme.spacing(1, 0),
      '& .row': {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(3),
        '& .label': {
          minWidth: 300,
          marginRight: theme.spacing(3),
        },
        '& .value': {
          marginTop: theme.spacing(-1),
          marginBottom: theme.spacing(-1),
        },
        '& .companyName': {
          width: 400,
        },
        '& .reminders': {
          marginTop: theme.spacing(-1),
          marginBottom: -10, // Set to 2px more than spacing so layout doesn't bounce
        },
        '& .select': {
          marginRight: theme.spacing(2),
        },
        '& .tooltipIcon': {
          fontSize: 16,
        },
        '&:last-of-type': {
          paddingBottom: 0,
        },
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
  },
  dueDateRow: {
    display: 'flex',
    alignItems: 'center',
    '& .dropdown': {
      minWidth: 85,
      marginRight: theme.spacing(1.5),
    },
  },
  dueDateText: {
    whiteSpace: 'pre',
  },
  input: {
    border: `1px solid ${colors.grey30}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: 85,
  },
  editing: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(-1, 0),
    '& .select': {
      marginRight: theme.spacing(2),
      minWidth: 85,
    },
    '& .editInSettings': {
      marginLeft: theme.spacing(1),
    },
  },
}))

interface ProfileState {
  name: string
  nickname: string | null
  startBillingReminder: number
  defaultShowAuditItemOnSignedPackages: boolean
  agingIntervalType: AgingIntervalType
  defaultRetentionPercent: number | null
  defaultDueToGc: number | null
  defaultDaysBeforePayAppDue: number
  vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
}

export function Company() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { confirm } = useSitelineConfirmation()
  const snackbar = useSitelineSnackbar()
  const [isEditing, setIsEditing] = useState(false)
  const { company } = useCompanyContext()

  const defaultState: ProfileState = useMemo(
    () => ({
      name: company?.name ?? '',
      nickname: company?.nickname ?? null,
      startBillingReminder: company?.metadata.monthlyCreatePayAppReminderDate ?? 0,
      defaultShowAuditItemOnSignedPackages: company?.defaultShowAuditItemOnSignedPackages ?? false,
      vendorLienWaiverReminderFrequency:
        company?.vendorLienWaiverReminderFrequency ?? DEFAULT_VENDOR_LIEN_WAIVER_REMINDER_FREQUENCY,
      agingIntervalType: company?.agingIntervalType ?? DEFAULT_AGING_INTERVAL_TYPE,
      defaultRetentionPercent: company?.defaultRetentionPercent ?? null,
      defaultDueToGc: company?.defaultPayAppDueOnDayOfMonth ?? null,
      defaultDaysBeforePayAppDue:
        company?.defaultDaysBeforePayAppDue ?? DEFAULT_DAYS_BEFORE_PAY_APP_DUE,
    }),
    [
      company?.agingIntervalType,
      company?.defaultDaysBeforePayAppDue,
      company?.defaultPayAppDueOnDayOfMonth,
      company?.defaultRetentionPercent,
      company?.defaultShowAuditItemOnSignedPackages,
      company?.metadata.monthlyCreatePayAppReminderDate,
      company?.name,
      company?.nickname,
      company?.vendorLienWaiverReminderFrequency,
    ]
  )
  const [companyState, setCompanyState] = useState<ProfileState>(defaultState)
  const [updateCompany] = useUpdateCompanyRequestMutation()

  // If the company changes, update the company state
  useEffect(() => {
    setCompanyState(defaultState)
  }, [defaultState])

  if (!company) {
    return null
  }

  const onEditOrCancel = () => {
    // If we were editing already and we cancel, then we should reset the state back to default
    isEditing && setCompanyState(defaultState)
    setIsEditing(!isEditing)
  }

  const canSave = () => {
    return companyState.name !== ''
  }

  const save = async () => {
    if (companyState.nickname && companyState.nickname.length < MIN_COMPANY_NICKNAME_LENGTH) {
      snackbar.showError(
        t('settings.company.nickname_too_short', { minChars: MIN_COMPANY_NICKNAME_LENGTH })
      )
      return
    }
    setIsEditing(false)
    const monthlyCreatePayAppReminderDate =
      companyState.startBillingReminder === 0 ? null : companyState.startBillingReminder
    try {
      const newRetentionPercent = companyState.defaultRetentionPercent ?? undefined
      await updateCompany({
        variables: {
          input: {
            id: company.id,
            name: companyState.name,
            nickname: companyState.nickname || null,
            monthlyCreatePayAppReminderDate,
            defaultShowAuditItemOnSignedPackages: companyState.defaultShowAuditItemOnSignedPackages,
            agingIntervalType: companyState.agingIntervalType,
            defaultRetentionPercent: newRetentionPercent,
            defaultDaysBeforePayAppDue: companyState.defaultDaysBeforePayAppDue,
            defaultPayAppDueOnDayOfMonth: companyState.defaultDueToGc ?? undefined,
            vendorLienWaiverReminderFrequency: companyState.vendorLienWaiverReminderFrequency,
          },
        },
      })
      if (defaultState.name !== companyState.name) {
        trackCompanyNameUpdated({
          oldCompanyName: defaultState.name,
          newCompanyName: companyState.name,
        })
      }
      if (defaultState.nickname !== companyState.nickname) {
        trackCompanyNicknameUpdated({
          oldCompanyNickname: defaultState.nickname ?? '',
          newCompanyNickname: companyState.nickname ?? '',
        })
      }
      if (
        defaultState.defaultShowAuditItemOnSignedPackages !==
        companyState.defaultShowAuditItemOnSignedPackages
      ) {
        trackCompanyAuditTrailEnabledUpdated({
          oldAuditTrailEnabled: defaultState.defaultShowAuditItemOnSignedPackages,
          newAuditTrailEnabled: companyState.defaultShowAuditItemOnSignedPackages,
        })
      }
      if (defaultState.agingIntervalType !== companyState.agingIntervalType) {
        trackCompanyAgingIntervalTypeUpdated({
          oldAgingIntervalType: defaultState.agingIntervalType,
          newAgingIntervalType: companyState.agingIntervalType,
        })
      }
      if (
        defaultState.vendorLienWaiverReminderFrequency !==
        companyState.vendorLienWaiverReminderFrequency
      ) {
        trackVendorLienWaiverReminderFrequencyUpdated({
          oldFrequency: defaultState.vendorLienWaiverReminderFrequency,
          newFrequency: companyState.vendorLienWaiverReminderFrequency,
        })
      }
      if (newRetentionPercent !== undefined) {
        trackCompanyDefaultRetentionPercentUpdated({
          oldDefaultRetentionPercent:
            defaultState.defaultRetentionPercent ?? DEFAULT_CONTRACT_RETENTION_PERCENT,
          newDefaultRetentionPercent:
            companyState.defaultRetentionPercent ?? DEFAULT_CONTRACT_RETENTION_PERCENT,
        })
      }
      snackbar.showSuccess()
    } catch (err) {
      snackbar.showError(err.message)
    }
  }

  // If changing the setting for signature audit trials, show a confirmation to double check the user understands
  // this will affect all existing projects
  const maybeConfirmSave = () => {
    if (
      company.defaultShowAuditItemOnSignedPackages ===
      companyState.defaultShowAuditItemOnSignedPackages
    ) {
      save()
      return
    }
    confirm({
      title: t('settings.company.are_you_sure'),
      details: companyState.defaultShowAuditItemOnSignedPackages
        ? t('settings.company.confirm_enable_audit')
        : t('settings.company.confirm_disable_audit'),
      callback: (confirmed) => {
        if (confirmed) {
          save()
        }
      },
    })
  }

  return (
    <>
      <div className={classes.root}>
        <div className="heading flex">
          <SitelineText variant="h3" bold color="grey90" className="title">
            {t('settings.navigation.company')}
          </SitelineText>
          <div>
            <Button
              variant="text"
              color="secondary"
              onClick={onEditOrCancel}
              className="editButton"
            >
              {isEditing ? t('common.actions.cancel') : t('common.actions.edit')}
            </Button>
            {isEditing && (
              <Button
                variant="contained"
                color="primary"
                onClick={maybeConfirmSave}
                disabled={!canSave()}
                className="saveButton"
              >
                {t('common.actions.save')}
              </Button>
            )}
          </div>
        </div>
        <div className="section">
          <div className="row">
            <SitelineText variant="body1" color="grey50" className="label">
              {t('settings.company.name')}
            </SitelineText>
            {isEditing ? (
              <TextField
                variant="outlined"
                value={companyState.name}
                onChange={(event) => setCompanyState({ ...companyState, name: event.target.value })}
                className={clsx('value', 'companyName')}
              ></TextField>
            ) : (
              <SitelineText variant="body1">{companyState.name}</SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText
              variant="body1"
              color="grey50"
              className="label"
              endIcon={
                <Tooltip
                  title={t('settings.company.short_name_info')}
                  placement="bottom-start"
                  className="tooltipIcon"
                >
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </Tooltip>
              }
            >
              {t('settings.company.short_name')}
            </SitelineText>
            {isEditing ? (
              <TextField
                variant="outlined"
                value={companyState.nickname}
                onChange={(event) =>
                  setCompanyState({ ...companyState, nickname: event.target.value })
                }
                className={clsx('value', 'companyName')}
              />
            ) : (
              <SitelineText variant="body1" color={company.nickname ? 'grey90' : 'grey50'}>
                {companyState.nickname || '–'}
              </SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText variant="body1" color="grey50" className="label">
              {t('settings.company.start_billing_reminder')}
            </SitelineText>
            {isEditing ? (
              <>
                <Select
                  variant="outlined"
                  value={companyState.startBillingReminder}
                  onChange={(event) =>
                    setCompanyState({
                      ...companyState,
                      startBillingReminder: Number(event.target.value),
                    })
                  }
                  className="select value"
                  disabled={companyState.startBillingReminder === 0}
                >
                  {companyState.startBillingReminder === 0 && (
                    <MenuItem hidden disabled value={0}>
                      —
                    </MenuItem>
                  )}
                  {_.range(1, 29).map((dayOfMonth) => (
                    <MenuItem key={dayOfMonth} value={dayOfMonth}>
                      {moment.localeData().ordinal(dayOfMonth)}
                    </MenuItem>
                  ))}
                </Select>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={companyState.startBillingReminder === 0}
                      onChange={(ev, checked) => {
                        const newValue = checked ? 0 : 5
                        setCompanyState({ ...companyState, startBillingReminder: newValue })
                      }}
                    />
                  }
                  label={t('projects.subcontractors.settings.reminders.second_monday')}
                  className="reminders"
                />
              </>
            ) : (
              <SitelineText variant="body1">
                {companyState.startBillingReminder
                  ? moment.localeData().ordinal(companyState.startBillingReminder)
                  : t('projects.subcontractors.settings.reminders.second_monday')}
              </SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText
              variant="body1"
              color="grey50"
              className="label"
              endIcon={
                <Tooltip
                  title={t('settings.company.audit_trail_info')}
                  placement="bottom-start"
                  className="tooltipIcon"
                >
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </Tooltip>
              }
            >
              {t('settings.company.generate_audit_trail')}
            </SitelineText>
            {isEditing ? (
              <div className={classes.switch}>
                <Switch
                  checked={companyState.defaultShowAuditItemOnSignedPackages}
                  onChange={(event) => {
                    setCompanyState({
                      ...companyState,
                      defaultShowAuditItemOnSignedPackages: event.target.checked,
                    })
                  }}
                />
              </div>
            ) : (
              <SitelineText
                variant="body1"
                color={companyState.defaultShowAuditItemOnSignedPackages ? 'grey90' : 'grey50'}
              >
                {companyState.defaultShowAuditItemOnSignedPackages
                  ? t('settings.company.enabled')
                  : t('settings.company.disabled')}
              </SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText variant="body1" color="grey50" className="label">
              {t('settings.company.aging_calculation')}
            </SitelineText>
            {isEditing ? (
              <>
                <Select
                  variant="outlined"
                  value={companyState.agingIntervalType}
                  onChange={(event) =>
                    setCompanyState({
                      ...companyState,
                      agingIntervalType: event.target.value as AgingIntervalType,
                    })
                  }
                  className="select value"
                >
                  <MenuItem value={AgingIntervalType.BILLING_END}>
                    {t('settings.company.BILLING_END')}
                  </MenuItem>
                  <MenuItem value={AgingIntervalType.DATE_SUBMITTED}>
                    {t('settings.company.DATE_SUBMITTED')}
                  </MenuItem>
                </Select>
              </>
            ) : (
              <SitelineText variant="body1">
                {t(`settings.company.${companyState.agingIntervalType}`)}
              </SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText
              variant="body1"
              color="grey50"
              className="label"
              endIcon={
                <Tooltip
                  title={t('settings.company.retention_percent_info')}
                  placement="bottom-start"
                  className="tooltipIcon"
                >
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </Tooltip>
              }
            >
              {t('settings.company.default_contract_retention')}
            </SitelineText>
            {isEditing ? (
              <RetentionPercentInput
                className={classes.input}
                percent={companyState.defaultRetentionPercent ?? DEFAULT_CONTRACT_RETENTION_PERCENT}
                onPercentChange={(percent) =>
                  setCompanyState({ ...companyState, defaultRetentionPercent: percent })
                }
                maxAllowedPercent={1}
              />
            ) : (
              <SitelineText variant="body1">
                {decimalToPercent(
                  companyState.defaultRetentionPercent ?? DEFAULT_CONTRACT_RETENTION_PERCENT,
                  1
                )}
                %
              </SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText
              variant="body1"
              color="grey50"
              className="label"
              endIcon={
                <Tooltip
                  title={t('settings.company.default_days_before_pay_app_due_info')}
                  placement="bottom-start"
                  className="tooltipIcon"
                >
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </Tooltip>
              }
            >
              {t('settings.company.default_days_before_pay_app_due')}
            </SitelineText>
            {isEditing ? (
              <div className={classes.editing}>
                <Select
                  variant="outlined"
                  value={companyState.defaultDaysBeforePayAppDue}
                  onChange={(event) => {
                    setCompanyState({
                      ...companyState,
                      defaultDaysBeforePayAppDue: event.target.value as number,
                    })
                  }}
                  className="select"
                >
                  {_.range(1, MAX_DAYS_BEFORE_PAY_APP_DUE).map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
                <SitelineText variant="body1" color="grey90">
                  {t(`settings.company.before_pay_app`, {
                    count: companyState.defaultDaysBeforePayAppDue,
                  })}
                </SitelineText>
              </div>
            ) : (
              <SitelineText variant="body1" color="grey90">
                {t(`settings.company.before_pay_app_with_number`, {
                  count: companyState.defaultDaysBeforePayAppDue,
                })}
              </SitelineText>
            )}
          </div>
          <div className="row">
            <SitelineText
              variant="body1"
              color="grey50"
              className="label"
              endIcon={
                <Tooltip
                  title={t('settings.company.due_date_info')}
                  placement="bottom-start"
                  className="tooltipIcon"
                >
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </Tooltip>
              }
            >
              {t('settings.company.default_due_date')}
            </SitelineText>
            {isEditing ? (
              <div className={classes.dueDateRow}>
                <FormControl variant="outlined" className="dropdown">
                  <Select
                    value={companyState.defaultDueToGc ?? DEFAULT_DUE_TO_GC_DAY_OF_MONTH}
                    onChange={(ev) => {
                      setCompanyState({
                        ...companyState,
                        defaultDueToGc: Number(ev.target.value),
                      })
                    }}
                  >
                    {_.range(1, MAX_DUE_TO_GC_DAY_OF_MONTH + 1).map((date) => (
                      <MenuItem key={date} value={date}>
                        {moment.utc().month(0).date(date).format('Do')}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <SitelineText variant="body1" className={classes.dueDateText}>
                {moment
                  .utc()
                  .month(0)
                  .date(companyState.defaultDueToGc ?? DEFAULT_DUE_TO_GC_DAY_OF_MONTH)
                  .format('Do')}{' '}
              </SitelineText>
            )}
            <SitelineText variant="body1">{t('settings.company.of_the_month')}</SitelineText>
          </div>
          <div className="row">
            <SitelineText
              variant="body1"
              color="grey50"
              className="label"
              endIcon={
                <Tooltip
                  title={t('settings.company.lien_waiver_reminder_frequency_info')}
                  placement="bottom-start"
                  className="tooltipIcon"
                >
                  <HelpOutlineOutlinedIcon fontSize="small" />
                </Tooltip>
              }
            >
              {t('settings.company.lien_waiver_reminder_frequency')}
            </SitelineText>
            {isEditing ? (
              <div>
                <FormControl variant="outlined">
                  <Select
                    value={companyState.vendorLienWaiverReminderFrequency}
                    onChange={(ev) => {
                      setCompanyState({
                        ...companyState,
                        vendorLienWaiverReminderFrequency: ev.target
                          .value as VendorLienWaiverReminderFrequency,
                      })
                    }}
                  >
                    <MenuItem value={VendorLienWaiverReminderFrequency.DAILY}>
                      {t('settings.company.DAILY')}
                    </MenuItem>
                    <MenuItem value={VendorLienWaiverReminderFrequency.WEEKLY}>
                      {t('settings.company.WEEKLY')}
                    </MenuItem>
                    <MenuItem value={VendorLienWaiverReminderFrequency.NEVER}>
                      {t('settings.company.NEVER')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            ) : (
              <SitelineText variant="body1">
                {t(`settings.company.${companyState.vendorLienWaiverReminderFrequency}`)}
              </SitelineText>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
