import _ from 'lodash'
import { pdfTypes } from 'siteline-common-all'
import { FormTemplateFont } from '../types'
import { saveAs } from '../utils/File'

export type PdfServiceConfiguration = {
  baseUrl: string
  getAuth: () => Promise<string>
}

let config: PdfServiceConfiguration | null = null
export function configurePdfService(configuration: PdfServiceConfiguration) {
  config = configuration
}

export function savePdf(blob: Blob, fileName: string) {
  // If the file name already has the pdf suffix, remove it
  // so it doesn't appear twice
  let name = fileName
  if (_.endsWith(fileName, '.pdf')) {
    name = fileName.substring(0, fileName.length - 4)
  }
  saveAs(blob, `${name}.pdf`)
}

export function getFormTemplateFontFamily(font: FormTemplateFont): React.CSSProperties {
  switch (font) {
    case FormTemplateFont.ARIAL_NORMAL:
      return { fontFamily: 'Arial' }
    case FormTemplateFont.CARLITO_NORMAL:
      return { fontFamily: 'Carlito' }
    case FormTemplateFont.CARLITO_BOLD:
      return { fontFamily: 'Carlito', fontWeight: 700 }
    case FormTemplateFont.CARLITO_ITALIC:
      return { fontFamily: 'Carlito', fontStyle: 'italic' }
    case FormTemplateFont.HELVETICA_BOLD:
      return { fontFamily: 'Helvetica', fontWeight: 700 }
    case FormTemplateFont.HELVETICA_ITALIC:
      return { fontFamily: 'Helvetica', fontStyle: 'italic' }
    case FormTemplateFont.HELVETICA_NORMAL:
      return { fontFamily: 'Helvetica' }
    case FormTemplateFont.TAHOMA_NORMAL:
      return { fontFamily: 'Tahoma' }
    case FormTemplateFont.TIMES_ROMAN_BOLD:
      return { fontFamily: 'Times', fontWeight: 700 }
    case FormTemplateFont.TIMES_ROMAN_ITALIC:
      return { fontFamily: 'Times', fontStyle: 'italic' }
    case FormTemplateFont.TIMES_ROMAN_NORMAL:
      return { fontFamily: 'Times' }
  }
}

async function assertValidResponse(response: Response, defaultError: string): Promise<void> {
  if (response.status === 200) {
    return
  }
  let body: { error: string }
  try {
    body = await response.json()
  } catch (err) {
    throw new Error(defaultError)
  }
  throw new Error(body.error)
}

export async function generatePdf(pkg: pdfTypes.PayloadPackage): Promise<Blob> {
  const out = await generatePdfWithMetadata(pkg)
  return out.blob
}

export async function generatePdfWithMetadata(
  pkg: pdfTypes.PayloadPackage
): Promise<{ blob: Blob; metadata: pdfTypes.PageMetadata[] }> {
  if (!config) {
    throw new Error('PDF Service is not configured, please call configurePdfService() first')
  }

  const auth = await config.getAuth()
  const generateUrl = new URL(config.baseUrl)
  generateUrl.pathname = '/generate'
  const response = await fetch(generateUrl.toString(), {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/zip',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      package: pkg,
    }),
  })
  await assertValidResponse(response, 'Could not generate PDF')

  const buffer = await response.arrayBuffer()
  const { default: JSZip } = await import('jszip')
  const zip = await JSZip.loadAsync(buffer)
  const pdfFile = zip.file('file.pdf')
  const metadataFile = zip.file('metadata.json')
  if (!pdfFile || !metadataFile) {
    throw new Error('Could not generate PDF')
  }

  const blob = await pdfFile.async('blob')
  const metadata: pdfTypes.PageMetadata[] = JSON.parse(await metadataFile.async('string'))

  return {
    blob,
    metadata,
  }
}

export async function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result) {
        // Strip type information to only have the base64 encoded string
        const base64result = reader.result.toString().split(',')[1]
        resolve(base64result)
      } else {
        reject(new Error('There was no result found'))
      }
    }
    reader.onabort = () => {
      reject(new Error('The action was aborted'))
    }
    reader.onerror = () => {
      reject(new Error('The action errored'))
    }
  })
}

export async function loadTemplateVariables(
  payAppId?: string
): Promise<pdfTypes.TemplateVariables> {
  if (!config) {
    throw new Error('PDF Service is not configured, please call configurePdfService() first')
  }
  const url = new URL(config.baseUrl)
  url.pathname = '/template-variables'
  if (payAppId) {
    url.searchParams.set('payAppId', payAppId)
  }
  const response = await fetch(url.toString(), {
    headers: {
      Authorization: await config.getAuth(),
    },
  })
  await assertValidResponse(response, 'Could not load template variables')
  return response.json()
}

export async function lookupTemplateVariable(
  templateVariable: string
): Promise<pdfTypes.TemplateVariableLookup[]> {
  if (!config) {
    throw new Error('PDF Service is not configured, please call configurePdfService() first')
  }
  const url = new URL(config.baseUrl)
  url.pathname = '/template-variable-lookup'
  url.searchParams.set('templateVariable', templateVariable)
  const response = await fetch(url.toString(), {
    headers: {
      Authorization: await config.getAuth(),
    },
  })
  await assertValidResponse(response, 'Could not lookup template variable')
  return response.json()
}

export async function getDynamicFieldTags(formTemplateVersionId: string): Promise<string[]> {
  if (!config) {
    throw new Error('PDF Service is not configured, please call configurePdfService() first')
  }
  const url = new URL(config.baseUrl)
  url.pathname = '/dynamicFieldTags'
  url.searchParams.set('formTemplateVersionId', formTemplateVersionId)
  const response = await fetch(url.toString(), {
    headers: {
      Authorization: await config.getAuth(),
    },
  })
  await assertValidResponse(response, 'Could get dynamic field tags')
  return response.json()
}
