import { ApolloCache } from '@apollo/client'
import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  ContractPaymentTermsType,
  ContractStatus,
  DEFAULT_CONTRACT_RETENTION_PERCENT,
  IntegrationTypeFamily,
  ProjectOnboardingFormType,
  TaxCalculationType,
  areNormalizedStringsEqual,
  formatCentsToDollars,
  getIntegrationTypeFamily,
  integrationTypes,
  replaceAllWhitespaces,
  roundCents,
} from 'siteline-common-all'
import {
  OnboardedProjectContractStatus,
  evictWithGc,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { SovOnboardingStep } from '../../components/billing/Billing.lib'
import { PendingFile } from '../../components/billing/backup/attachments/FileDragUpload'
import { ContractForAddChangeOrderRequestToSov } from '../../components/billing/change-order-requests/AddChangeOrderRequestToSov'
import { ContractForProjectHome } from '../../components/billing/home/ProjectHome'
import { ContractForProjectOnboarding } from '../../components/billing/onboarding/OnboardingTaskList'
import { CompanyForProjectOnboarding } from '../../components/billing/onboarding/ProjectInfoForm'
import { ContractForEditingSov } from '../../components/billing/onboarding/SovOnboarding'
import { locationInputFromLocation } from '../components/LocationAutocomplete'
import {
  GetContractsForFormsDocument,
  GetContractsForFormsQuery,
  GetProjectLienWaiversByMonthDocument,
  ImportProjectOnboardingMetadataProperties,
  IntegrationType,
  LocationInput,
  LumpSumProjectCompanyInput,
  OnboardedProjectContractStatusProperties,
  RetentionTrackingLevel,
  SelectProjectFormsInput,
  SovLineItemGroupProperties,
  SovLineItemWithTotalsProperties,
  UploadFormTemplatesInput,
  useSelectProjectFormsMutation,
  useUploadFormTemplatesMutation,
} from '../graphql/apollo-operations'
import { SelectFormsRefetchQuery } from './Forms'
import { isEmptyDollarAmount, parseDollarAmount, parseQuantityAmount } from './ImportFromTemplate'
import { isIncompleteLocation, isValidLocation } from './Location'
import { EditingSov, EditingSovLineItem, EditingSovLineItemGroup } from './ManageSov'
import { isNewBilledInRange } from './PayApp'
import { getMostRecentPayApp } from './Project'
import { usesStandardOrLineItemTracking } from './Retention'

export type LienWaiverFormTemplateType =
  | 'conditionalFinalId'
  | 'conditionalProgressId'
  | 'unconditionalFinalId'
  | 'unconditionalProgressId'

/** Returns a list of required form fields that have not been completed */
export function validateProjectOnboardingForm(projectInfo: ProjectOnboardingInfo): {
  missingFields: string[]
  invalidFields: string[]
} {
  const missingFields: string[] = []
  const invalidFields: string[] = []

  const {
    projectName,
    projectAddress,
    gcProjectNumber,
    generalContractor: { name: gcName, address: gcAddress },
    owner: { address: ownerAddress },
    architect,
    pastPayAppCount,
  } = projectInfo.projectInfo
  if (!projectName) {
    missingFields.push('projectName')
  }
  if (!projectAddress || isIncompleteLocation(projectAddress)) {
    missingFields.push('projectAddress')
  }
  if (!gcProjectNumber || gcProjectNumber.trim() === '') {
    missingFields.push('gcProjectNumber')
  }
  if (!gcName) {
    missingFields.push('gcName')
    // We don't require a GC address, but if an incomplete one is provided we flag it
  } else if (gcAddress && isIncompleteLocation(gcAddress)) {
    invalidFields.push('gcAddress')
  }
  // We don't require an owner, but if an incomplete address is provided we flag it
  if (ownerAddress && isIncompleteLocation(ownerAddress)) {
    invalidFields.push('ownerAddress')
  }
  if (architect?.name && (!architect.address || isIncompleteLocation(architect.address))) {
    missingFields.push('architectAddress')
  }
  if (pastPayAppCount === null) {
    missingFields.push('pastPayAppCount')
  }

  const { contractDate, paymentTermsType, paymentTerms } = projectInfo.contract
  // Contract date is optional, but if it is set, it must be valid
  if (contractDate && !contractDate.isValid()) {
    invalidFields.push('contractDate')
  }
  // If the address isn't present we'll display a "missing field" message (see above).
  // If it is otherwise invalid, we'll display an "invalid input" message (note: an address is valid if it contains a valid US/CA zip)
  if (projectAddress && !isValidLocation(projectAddress)) {
    invalidFields.push('projectAddress')
  }
  if (gcAddress && !isValidLocation(gcAddress)) {
    invalidFields.push('gcAddress')
  }
  if (ownerAddress && !isValidLocation(ownerAddress)) {
    invalidFields.push('ownerAddress')
  }
  if (architect?.address && !isValidLocation(architect.address)) {
    invalidFields.push('architectAddress')
  }
  if (paymentTermsType === ContractPaymentTermsType.NET_PAYMENT && paymentTerms === null) {
    missingFields.push('paymentTerms')
  }

  return { missingFields, invalidFields }
}

export type ContractForForms = GetContractsForFormsQuery['paginatedContracts']['contracts'][number]

/** Returns true if all onboarding steps have been completed for a project */
export function isProjectOnboardingComplete(
  onboardedStatus: OnboardedProjectContractStatusProperties
): boolean {
  return (
    onboardedStatus.selectedPayAppForms &&
    onboardedStatus.selectedPrimaryLienWaivers &&
    onboardedStatus.selectedVendorLienWaivers &&
    onboardedStatus.selectedChangeOrderRequestForms &&
    (onboardedStatus.addedSov || onboardedStatus.selectedRateTable) &&
    onboardedStatus.addedTeammates &&
    onboardedStatus.addedGcContacts &&
    onboardedStatus.startedBilling
  )
}

/** Returns the number of incomplete steps in project onboarding */
export function incompleteProjectOnboardingTasks(
  onboardedStatus: OnboardedProjectContractStatusProperties,
  billingType: BillingType
): number {
  const tasks: (keyof OnboardedProjectContractStatusProperties)[] = [
    'selectedPayAppForms',
    'selectedPrimaryLienWaivers',
    'selectedVendorLienWaivers',
    'selectedChangeOrderRequestForms',
    'addedTeammates',
    'addedGcContacts',
  ]
  if (billingType === BillingType.TIME_AND_MATERIALS) {
    tasks.push('selectedRateTable')
  } else {
    tasks.push('addedSov')
  }
  return _.sumBy(tasks, (task) => (onboardedStatus[task] ? 0 : 1))
}

/** Returns true if enough onboarding steps have been completed to submit pay apps */
export function hasOnboardedForPayAppSubmit(
  onboardedStatus: Pick<OnboardedProjectContractStatus, 'isReadyForPayAppSubmit'>,
  hasSov: boolean
): boolean {
  return hasSov || onboardedStatus.isReadyForPayAppSubmit
}

/** Returns true if any pay app forms are still being processed by the admin team */
export function isProcessingPayAppForms(status: OnboardedProjectContractStatusProperties) {
  if (status.selectedPayAppForms && !status.onboardedPayAppForms) {
    return true
  }
  // Primary lien waivers are included in the pay app package, so check that they've been onboarded too
  if (status.selectedPrimaryLienWaivers && !status.onboardedPrimaryLienWaiverForms) {
    return true
  }

  return false
}

/** Returns true if any pay app forms are still being processed by the admin team */
export function isProcessingChangeOrderLogForms(
  status: OnboardedProjectContractStatusProperties,
  includeChangeOrderLogOnPayApps: boolean
) {
  // Change order log forms are also included in the pay app package (if the preference is enabled), so
  // check that they've been onboarded as well
  return (
    status.selectedChangeOrderLogForms &&
    !status.onboardedChangeOrderLogForms &&
    includeChangeOrderLogOnPayApps
  )
}

/** Returns true if vendor lien waiver forms are still being processed by the admin team */
export function isProcessingVendorLienWaiverForms(
  status: OnboardedProjectContractStatusProperties
) {
  return status.selectedVendorLienWaivers && !status.onboardedVendorLienWaiverForms
}

export const REQUIRED_ONBOARDING_FORMS: ContractOnboardingFormType[] = [
  ProjectOnboardingFormType.PAY_APP,
]

/**
 * Returns the list of forms required during project onboarding based on the following:
 * 1. Projects with GC portal integrations (pay app, change order, & primary lien waivers not required)
 * 2. Project type (unit price projects do not require change order forms)
 */
export function getOnboardingFormRequirements(
  integrations: IntegrationType[],
  billingType?: BillingType
) {
  const forms: ContractOnboardingFormType[] = []
  const hasGcPortalIntegration = integrations.some(
    (integration) => getIntegrationTypeFamily(integration) === IntegrationTypeFamily.GC_PORTAL
  )
  const isChangeOrderProjectType = billingType !== BillingType.TIME_AND_MATERIALS

  if (!hasGcPortalIntegration) {
    forms.push(ProjectOnboardingFormType.PAY_APP)
    forms.push(ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER)
  }
  forms.push(ProjectOnboardingFormType.VENDOR_LIEN_WAIVER)
  if (isChangeOrderProjectType) {
    forms.push(ProjectOnboardingFormType.CHANGE_ORDER_REQUEST)
    forms.push(ProjectOnboardingFormType.CHANGE_ORDER_LOG)
  }
  return forms
}

const importSovI18nBase = 'projects.onboarding.sov_onboarding.import_error'

enum SovTemplateHeader {
  ITEM_NUMBER = 'Item number',
  DESCRIPTION_OF_WORK = 'Description of work',
  DESCRIPTION = 'Description',
  COST_CODE = 'Cost code (optional)',
  UNIT_OF_MEASURE = 'Unit of measure',
  UNIT_PRICE = 'Unit price',
  SCHEDULED_VALUE = 'Scheduled value',
  PREVIOUSLY_BILLED = 'Previously billed',
  CO_APPROVAL_DATE = '(If CO) Approval date',
  BID_AMOUNT = 'Bid amount ($)',
  REVISED_CONTRACT_AMOUNT = 'Revised contract amount ($)',
  PREVIOUS_AMOUNT_BILLED = 'Previous amount billed ($)',
  // This is no longer in the template, but is preserved in case old templates are used
  PREVIOUS_QUANTITY_BILLED = 'Previous quantity billed',
}

function isValidLineItem(lineItem: Partial<EditingSovLineItem>): lineItem is EditingSovLineItem {
  if (!_.isString(lineItem.id)) {
    console.error('Missing ID for line item', lineItem)
    throw new Error('Unable to import a line item, please check your template and try again')
  }
  if (!_.isString(lineItem.code)) {
    console.error('Missing number for line item', lineItem)
    throw new Error('Unable to import a line item due to missing number')
  }
  if (!_.isString(lineItem.name)) {
    console.error('Missing name for line item', lineItem)
    throw new Error('Unable to import a line item due to missing name')
  }
  if (!_.isNumber(lineItem.originalTotalValue)) {
    console.error('Missing original total value for line item', lineItem)
    throw new Error('Unable to import a line item due to missing bid amount')
  }
  if (!_.isNumber(lineItem.latestTotalValue)) {
    console.error('Missing latest total value for line item', lineItem)
    throw new Error('Unable to import a line item due to missing contract amount')
  }
  if (lineItem.groupId === undefined) {
    console.error('Missing group for line item', lineItem)
    throw new Error('Unable to import a line item, please check your template and try again')
  }
  return true
}

function isValidGroup(group: Partial<EditingSovLineItemGroup>): group is EditingSovLineItemGroup {
  if (!_.isString(group.id)) {
    console.error('Missing ID for group', group)
    throw new Error('Unable to import a line item, please check your template and try again')
  }
  if (!_.isString(group.code)) {
    console.error('Missing number for group', group)
    throw new Error('Unable to import a group due to missing number')
  }
  if (!_.isString(group.name)) {
    console.error('Missing name for group', group)
    throw new Error('Unable to import a group due to missing name')
  }
  return true
}

export type ExcelJson = (string | number | undefined)[][]

/** Converts a JSON object to an SOV input */
export function jsonToSov({
  json,
  contract,
  t,
  hasPreviousBilled,
  timeZone,
  defaultRetentionPercent,
}: {
  json: ExcelJson
  contract: ContractForEditingSov
  t: TFunction
  hasPreviousBilled: boolean
  timeZone: string
  defaultRetentionPercent: number
}): EditingSov {
  // Skip all lines until we find the header row (based on the "Item number" header)
  let index = 0
  while (index < json.length && json[index][0] !== SovTemplateHeader.ITEM_NUMBER) {
    index++
  }
  // If a header row is never found, throw an error
  if (index === json.length) {
    throw new Error(t(`${importSovI18nBase}.match_template`))
  }
  const defaultContractRetentionPercent =
    contract.defaultRetentionPercent ?? defaultRetentionPercent
  const headers = json[index]
  const rows = json.slice(index + 1)
  const lineItems: EditingSovLineItem[] = []
  const groups: EditingSovLineItemGroup[] = []
  let currentGroupId = null
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index]
    // Skip empty lines
    if (row.every((element) => String(element).trim() === '')) {
      continue
    }
    const lineItem: Partial<EditingSovLineItem> = { id: uuidv4(), changeOrderRequests: [] }
    const group: Partial<EditingSovLineItemGroup> = { id: uuidv4() }
    let isGroupRow = false
    let isNewGroupRow = false
    for (let i = 0; i < row.length; i++) {
      const unsanitized = String(row[i]).trim()
      const value = replaceAllWhitespaces(unsanitized)
      switch (headers[i] as SovTemplateHeader) {
        case SovTemplateHeader.ITEM_NUMBER: {
          if (value === '*') {
            currentGroupId = null
            isNewGroupRow = true
            continue
          } else if (value.substring(0, 1) === '*') {
            group['code'] = value.substring(1)
            isGroupRow = true
          } else {
            lineItem['code'] = value
          }
          break
        }
        case SovTemplateHeader.DESCRIPTION:
        case SovTemplateHeader.DESCRIPTION_OF_WORK: {
          if (value === '*') {
            currentGroupId = null
            isNewGroupRow = true
            continue
          } else if (value.substring(0, 1) === '*') {
            group['name'] = value.substring(1)
            isGroupRow = true
          } else if (isGroupRow) {
            group['name'] = value
          } else {
            lineItem['name'] = value
          }
          break
        }
        case SovTemplateHeader.COST_CODE: {
          lineItem['costCode'] = value
          break
        }
        case SovTemplateHeader.SCHEDULED_VALUE: {
          const scheduledValue = parseDollarAmount(value, SovTemplateHeader.SCHEDULED_VALUE, t)
          lineItem['originalTotalValue'] = scheduledValue
          lineItem['latestTotalValue'] = scheduledValue
          break
        }
        case SovTemplateHeader.PREVIOUSLY_BILLED: {
          if (!hasPreviousBilled) {
            break
          }
          const preSitelineBilling = parseDollarAmount(
            value,
            SovTemplateHeader.PREVIOUSLY_BILLED,
            t
          )
          if (
            lineItem.originalTotalValue !== undefined &&
            !isNewBilledInRange(preSitelineBilling, lineItem.originalTotalValue)
          ) {
            throw new Error(
              t(`${importSovI18nBase}.previously_billed_too_high`, {
                previousBilled: formatCentsToDollars(preSitelineBilling, true),
                scheduledValue: formatCentsToDollars(lineItem.originalTotalValue, true),
              })
            )
          }
          lineItem['preSitelineBilling'] = preSitelineBilling
          break
        }
        case SovTemplateHeader.CO_APPROVAL_DATE: {
          const date = moment.tz(value, 'MM/DD/YYYY', timeZone)
          if (date.isValid()) {
            lineItem['isChangeOrder'] = true
            lineItem['changeOrderApprovedAt'] = date
          } else {
            lineItem['isChangeOrder'] = false
            lineItem['changeOrderApprovedAt'] = null
          }
          break
        }
        case SovTemplateHeader.UNIT_OF_MEASURE: {
          lineItem['unitName'] = value
          break
        }
        case SovTemplateHeader.UNIT_PRICE: {
          const unitPrice = parseDollarAmount(value, SovTemplateHeader.UNIT_PRICE, t)
          lineItem['unitPrice'] = unitPrice
          break
        }
        case SovTemplateHeader.BID_AMOUNT: {
          const totalValue = parseDollarAmount(value, SovTemplateHeader.BID_AMOUNT, t)
          lineItem['originalTotalValue'] = totalValue
          if (!hasPreviousBilled) {
            lineItem['latestTotalValue'] = totalValue
          }
          break
        }
        case SovTemplateHeader.REVISED_CONTRACT_AMOUNT: {
          if (!isEmptyDollarAmount(value)) {
            const totalValue = parseDollarAmount(
              value,
              SovTemplateHeader.REVISED_CONTRACT_AMOUNT,
              t
            )
            lineItem['latestTotalValue'] = totalValue
          } else {
            // If this column is empty, use the original total value (assume no change)
            lineItem['latestTotalValue'] = lineItem.originalTotalValue
          }
          break
        }
        case SovTemplateHeader.PREVIOUS_QUANTITY_BILLED: {
          const quantity = parseQuantityAmount(value, SovTemplateHeader.PREVIOUS_QUANTITY_BILLED, t)
          const preSitelineBilling = roundCents(quantity * (lineItem.unitPrice ?? 0))
          lineItem['preSitelineBilling'] = preSitelineBilling
          break
        }
        case SovTemplateHeader.PREVIOUS_AMOUNT_BILLED: {
          const previousAmount = parseDollarAmount(
            value,
            SovTemplateHeader.PREVIOUS_AMOUNT_BILLED,
            t
          )
          lineItem['preSitelineBilling'] = previousAmount
        }
      }
    }
    if (isGroupRow) {
      // If a group only has text in the code column, use it as the name. If the code was empty,
      // use an empty string.
      if (group.code && !group.name) {
        group['name'] = group.code
        group['code'] = ''
      } else if (!group.code) {
        group['code'] = ''
      }
      if (isValidGroup(group)) {
        groups.push(group)
        currentGroupId = group.id
      }
    } else if (!isNewGroupRow) {
      // Pre-siteline retention is the previous billing multipled by the initial retention %
      const preSitelineRetentionAmount = roundCents(
        defaultContractRetentionPercent * (lineItem.preSitelineBilling ?? 0),
        contract.roundRetention
      )
      const fullLineItem = {
        ...lineItem,
        groupId: currentGroupId,
        taxGroupId: contract.defaultTaxGroup?.id ?? null,
        ...(usesStandardOrLineItemTracking(contract.retentionTrackingLevel) && {
          defaultRetentionPercent: defaultContractRetentionPercent,
          retentionToDate: preSitelineRetentionAmount,
          preSitelineRetentionAmount,
        }),
        // Initially, the only progress billed will be the pre-Siteline billing
        billedToDate: lineItem.preSitelineBilling ?? 0,
        // There are never worksheet items on an SOV when first imported, even if worksheets are
        // enabled
        worksheetLineItems: [],
      }
      if (isValidLineItem(fullLineItem)) {
        lineItems.push(fullLineItem)
      }
    }
  }

  // Calculate pre-siteline retention values
  const preSitelineBilling = _.sumBy(lineItems, (lineItem) => lineItem.preSitelineBilling ?? 0)
  const totalRetentionHeld = _.sumBy(
    lineItems,
    (lineItem) => lineItem.preSitelineRetentionAmount ?? 0
  )
  let preSitelineRetentionHeldOverride: number | null
  if (usesStandardOrLineItemTracking(contract.retentionTrackingLevel)) {
    preSitelineRetentionHeldOverride = null
  } else {
    preSitelineRetentionHeldOverride = roundCents(
      preSitelineBilling * defaultContractRetentionPercent,
      contract.roundRetention
    )
  }

  return {
    lineItems,
    groups,
    preSitelineRetentionHeldOverride,
    totalRetentionHeld,
    defaultRetentionPercent: defaultContractRetentionPercent,
  }
}

/** Creates an empty onboarding line item */
export function makeEmptyEditingSovLineItem({
  includePreviousBilled,
  retentionTrackingLevel,
  billingType,
  groupId,
  defaultRetentionPercent,
  defaultTaxGroupId,
}: {
  includePreviousBilled: boolean
  retentionTrackingLevel: RetentionTrackingLevel
  billingType: BillingType
  groupId?: string | null
  defaultRetentionPercent: number
  defaultTaxGroupId: string | null
}): EditingSovLineItem {
  return {
    id: uuidv4(),
    code: '',
    name: '',
    costCode: '',
    originalTotalValue: 0,
    latestTotalValue: 0,
    preSitelineBilling: includePreviousBilled ? 0 : undefined,
    groupId: groupId ?? null,
    defaultRetentionPercent,
    latestRetentionPercent: null,
    preSitelineRetentionAmount: usesStandardOrLineItemTracking(retentionTrackingLevel)
      ? 0
      : undefined,
    billedToDate: 0,
    retentionToDate: usesStandardOrLineItemTracking(retentionTrackingLevel) ? 0 : undefined,
    unitName: billingType === BillingType.UNIT_PRICE ? '' : undefined,
    unitPrice: billingType === BillingType.UNIT_PRICE ? 0 : undefined,
    worksheetLineItems: [],
    changeOrderRequests: [],
    taxGroupId: defaultTaxGroupId,
  }
}

/** Creates an empty onboarding line item group */
export function makeEmptySovLineItemGroup(): EditingSovLineItemGroup {
  return {
    // Use a unix timestamp as the ID, since it will be unique and groups will be shown
    // in the SOV in the order they're created
    id: moment.utc().unix().toString(),
    code: '',
    name: '',
  }
}

/** Creates a default SOV with a single empty line item */
export function makeDefaultEditingSov(
  includePreviousBilled: boolean,
  retentionTrackingLevel: RetentionTrackingLevel,
  billingType: BillingType,
  defaultRetentionPercent: number,
  defaultTaxGroupId: string | null
): EditingSov {
  let preSitelineRetentionHeldOverride: number | null
  if (usesStandardOrLineItemTracking(retentionTrackingLevel)) {
    preSitelineRetentionHeldOverride = null
  } else {
    preSitelineRetentionHeldOverride = 0
  }

  return {
    lineItems: [
      {
        ...makeEmptyEditingSovLineItem({
          includePreviousBilled,
          retentionTrackingLevel,
          billingType,
          defaultRetentionPercent,
          defaultTaxGroupId,
        }),
        // Start the first line item with a code of 1, so we can
        // automatically number the line items if the customer doesn't
        // customize the codes
        code: '1',
      },
    ],
    groups: [],
    preSitelineRetentionHeldOverride,
    totalRetentionHeld: preSitelineRetentionHeldOverride ?? 0,
    defaultRetentionPercent,
  }
}

type SovLineItemForEditing = Pick<
  SovLineItemWithTotalsProperties,
  | 'id'
  | 'code'
  | 'name'
  | 'costCode'
  | 'originalTotalValue'
  | 'latestTotalValue'
  | 'previousBilled'
  | 'defaultRetentionPercent'
  | 'sortOrder'
  | 'isChangeOrder'
  | 'changeOrderApprovedDate'
  | 'changeOrderEffectiveDate'
  | 'changeOrderRequests'
  | 'unitName'
  | 'unitPrice'
  | 'worksheetLineItems'
  | 'taxGroup'

  // Note: we use this instead of `preSitelineRetentionOverride` because on some old contracts it
  // will be null because purely based on percentages.
  | 'preSitelineRetention'
> & { sovLineItemGroup: Pick<SovLineItemGroupProperties, 'id' | 'code' | 'name'> | null }
type SovLineItemWithTotalsForEditing = SovLineItemForEditing &
  Pick<SovLineItemWithTotalsProperties, 'billedToDate' | 'totalRetention'>

/** Converts an `SovLineItem` to the client-side `EditingSovLineItem` type */
export function sovLineItemToEditingSovLineItem<
  T extends SovLineItemForEditing | SovLineItemWithTotalsForEditing,
>(
  lineItem: T,
  latestProgress: { progressRetentionHeldPercent: number | null } | null,
  includePreviousBilled: boolean,
  timeZone: string
): EditingSovLineItem {
  const changeOrderApprovedAt =
    lineItem.isChangeOrder && lineItem.changeOrderApprovedDate
      ? moment.tz(lineItem.changeOrderApprovedDate, timeZone)
      : null
  const changeOrderEffectiveAt =
    lineItem.isChangeOrder && lineItem.changeOrderEffectiveDate
      ? moment.tz(lineItem.changeOrderEffectiveDate, timeZone)
      : null
  const worksheetLineItems = lineItem.worksheetLineItems.map((worksheetLineItem) => ({
    ...worksheetLineItem,
    unitName: worksheetLineItem.unitName ?? undefined,
    unitPrice: worksheetLineItem.unitPrice ?? undefined,
  }))
  const minimalEditingSovLineItem: EditingSovLineItem = {
    id: lineItem.id,
    sortOrder: lineItem.sortOrder,
    code: lineItem.code,
    name: lineItem.name,
    costCode: lineItem.costCode,
    originalTotalValue: lineItem.originalTotalValue,
    latestTotalValue: lineItem.latestTotalValue,
    groupId: lineItem.sovLineItemGroup?.id ?? null,
    preSitelineBilling: includePreviousBilled ? lineItem.previousBilled : undefined,
    defaultRetentionPercent: lineItem.defaultRetentionPercent,
    latestRetentionPercent: latestProgress?.progressRetentionHeldPercent ?? null,
    preSitelineRetentionAmount: lineItem.preSitelineRetention,
    isChangeOrder: lineItem.isChangeOrder,
    changeOrderApprovedAt,
    changeOrderEffectiveAt,
    billedToDate: 0,
    unitName: lineItem.unitName ?? undefined,
    unitPrice: lineItem.unitPrice ?? undefined,
    worksheetLineItems,
    changeOrderRequests: [...lineItem.changeOrderRequests],
    taxGroupId: lineItem.taxGroup?.id ?? null,
  }
  if ('billedToDate' in lineItem) {
    minimalEditingSovLineItem.billedToDate = lineItem.billedToDate
    minimalEditingSovLineItem.retentionToDate = lineItem.totalRetention
  }
  return minimalEditingSovLineItem
}

/** Converts an existing `SovLineItemGroup` to the client-side `EditingSovLineItemGroup` type */
export function sovLineItemGroupToEditingSovLineItemGroup(
  group: Pick<SovLineItemGroupProperties, 'id' | 'code' | 'name'>
): EditingSovLineItemGroup {
  return {
    id: group.id,
    code: group.code ?? '',
    name: group.name,
  }
}

/** Converts an existing `SovProperties` from the server to the client-side `EditingSov` type */
export function sovToEditingSov<T extends SovLineItemForEditing | SovLineItemWithTotalsForEditing>(
  lineItems: T[],
  includePreviousBilled: boolean,
  timeZone: string,
  totalRetentionHeld?: number,
  contract?: ContractForEditingSov | ContractForAddChangeOrderRequestToSov
): EditingSov {
  const groups = _.chain(lineItems)
    .map((lineItem) => lineItem.sovLineItemGroup)
    .compact()
    .uniqBy((group) => group.id)
    .value()

  const formattedLineItems = _.chain(lineItems)
    .orderBy((lineItem) => lineItem.sortOrder)
    .map((lineItem) => {
      const latestPayApp = contract ? getMostRecentPayApp(contract) : null
      const latestProgress =
        latestPayApp?.progress.find(({ sovLineItem }) => sovLineItem.id === lineItem.id) ?? null
      return sovLineItemToEditingSovLineItem(
        lineItem,
        latestProgress,
        includePreviousBilled,
        timeZone
      )
    })
    .value()

  const defaultRetentionPercent =
    contract?.defaultRetentionPercent ?? DEFAULT_CONTRACT_RETENTION_PERCENT

  return {
    lineItems: formattedLineItems,
    groups: groups.map(sovLineItemGroupToEditingSovLineItemGroup),
    preSitelineRetentionHeldOverride: contract?.preSitelineRetentionHeldOverride ?? null,
    totalRetentionHeld: totalRetentionHeld ?? 0,
    defaultRetentionPercent,
  }
}

/** Ensures we don't break Joi validation by passing empty strings where not allowed */
export function cleanLocationInput(location: LocationInput) {
  return {
    ...location,
    county: cleanData(location.county),
    nickname: cleanData(location.nickname),
    postalCode: cleanData(location.postalCode),
    street1: cleanData(location.street1),
    street2: cleanData(location.street2),
  }
}

export interface ProjectOnboardingCompanyInfo {
  id: string | null
  name: string | null
  locationId: string | null
  address: LocationInput | null
}

export interface ProjectOnboardingProjectInfo {
  projectName: string | null
  projectAddress: LocationInput | null
  gcProjectNumber: string | null
  internalProjectNumber: string | null
  generalContractor: ProjectOnboardingCompanyInfo
  owner: ProjectOnboardingCompanyInfo
  architect: ProjectOnboardingCompanyInfo | null
  dueToGc: number
  pastPayAppCount: number | null
  preSitelineBilled: number | null
  leadPMIDs: string[] | null
}

export interface ProjectOnboardingContract {
  contractNumber: string | null
  contractDate: Moment | null
  selectedAddressId?: string
  paymentTermsType: ContractPaymentTermsType | null
  paymentTerms: number | null
  vendorNumber: string | null
}

export interface ProjectOnboardingRetention {
  retentionTrackingLevel: RetentionTrackingLevel
  defaultRetentionPercent: number | null
  roundRetention: boolean
}

export interface ProjectOnboardingTaxes {
  taxCalculationType: TaxCalculationType
  defaultTaxGroupId: string | null
}

export interface ProjectOnboardingIntegration {
  integrationProjectId: string
  // A contract ID is not required for certain integrations. In those cases, we allow linking
  // a project to the integration with project ID only.
  integrationContractId?: string
  integrationAssociatedCompanyId?: string
  companyIntegrationId: string

  // Currently used only with a Quickbooks company integration
  metadata?: integrationTypes.IntegrationMetadata
}

export type MonthlyBillingType =
  | BillingType.LUMP_SUM
  | BillingType.UNIT_PRICE
  | BillingType.TIME_AND_MATERIALS

export interface ProjectOnboardingInfo {
  billingType: MonthlyBillingType
  projectInfo: ProjectOnboardingProjectInfo
  contract: ProjectOnboardingContract
  retention: ProjectOnboardingRetention
  taxes: ProjectOnboardingTaxes
  integrations: ProjectOnboardingIntegration[]
}

export function makeEmptyCompanyInfo() {
  return {
    id: null,
    name: null,
    locationId: null,
    address: null,
  }
}

export function makeEmptyProjectOnboardingInfo({
  defaultRetentionPercent,
  defaultPayAppDueOnDayOfMonth,
}: {
  defaultRetentionPercent: number
  defaultPayAppDueOnDayOfMonth: number
}): ProjectOnboardingInfo {
  return {
    billingType: BillingType.LUMP_SUM,
    projectInfo: {
      projectName: null,
      projectAddress: null,
      gcProjectNumber: null,
      internalProjectNumber: null,
      generalContractor: makeEmptyCompanyInfo(),
      owner: makeEmptyCompanyInfo(),
      architect: null,
      dueToGc: defaultPayAppDueOnDayOfMonth,
      pastPayAppCount: 0,
      preSitelineBilled: null,
      leadPMIDs: null,
    },
    contract: {
      contractNumber: null,
      contractDate: null,
      paymentTermsType: null,
      paymentTerms: null,
      vendorNumber: null,
    },
    retention: {
      retentionTrackingLevel: RetentionTrackingLevel.STANDARD,
      defaultRetentionPercent,
      roundRetention: false,
    },
    taxes: {
      taxCalculationType: TaxCalculationType.NONE,
      defaultTaxGroupId: null,
    },
    integrations: [],
  }
}

export function cleanData(data: string | undefined | null) {
  if (!data) {
    // If the data is undefined/null or an empty string, return undefined
    return undefined
  }
  const trimmed = _.trim(replaceAllWhitespaces(data))
  return trimmed.length > 0 ? trimmed : undefined
}

/** Creates the company input object for creating or updating a project */
export function makeProjectCompanyInput(
  companyInfo: ProjectOnboardingCompanyInfo
): LumpSumProjectCompanyInput {
  return {
    ...(companyInfo.id && { companyId: companyInfo.id }),
    ...(companyInfo.name && { companyName: cleanData(companyInfo.name) }),
    companyLocation: {
      ...(companyInfo.locationId && { locationId: companyInfo.locationId }),
      ...(companyInfo.address && { location: cleanLocationInput(companyInfo.address) }),
    },
  }
}

type MinimalCompany = Pick<CompanyForProjectOnboarding, 'id' | 'name' | 'locations'>

/**
 * This function handles merging company data between two sources of metadata. If a matching company
 * is found in `existingCompanies`, all metadata fields are overwritten with the existing company record
 * to avoid creating duplicate entries.
 */
function fillCompanyInfoFromMetadataOrExistingRecord({
  companyMetadata,
  initialCompanyMetadata,
  existingCompanies,
}: {
  companyMetadata: ImportProjectOnboardingMetadataProperties[
    | 'generalContractor'
    | 'owner'
    | 'architect']
  initialCompanyMetadata: ProjectOnboardingCompanyInfo | null
  existingCompanies: MinimalCompany[]
}): ProjectOnboardingCompanyInfo {
  // If there is already non-empty metadata set, don't overwrite it
  const hasInitialCompanyMetadata =
    initialCompanyMetadata && !_.isEqual(initialCompanyMetadata, makeEmptyCompanyInfo())
  const companyFromMetadata = hasInitialCompanyMetadata
    ? initialCompanyMetadata
    : {
        ...makeEmptyCompanyInfo(),
        ...(companyMetadata?.companyName && { name: companyMetadata.companyName }),
        ...(companyMetadata?.companyAddress && {
          address: locationInputFromLocation(companyMetadata.companyAddress),
        }),
      }

  // If `companyFromMetadata` already has an id, it means it already exists in the database and we won't
  // be creating a new entry, so we can use it without checking matching companies
  if (companyFromMetadata.id !== null) {
    return companyFromMetadata
  }

  // If the company metadata doesn't have a name, the user will have to fill out the info manually and the
  // duplicate check does not need to be performed here
  const onboardingCompanyName = companyFromMetadata.name
  if (onboardingCompanyName === null) {
    return companyFromMetadata
  }

  // If no matching company entry is found, creating a new entry will not result in duplicates
  const matchingCompany = existingCompanies.find((company) =>
    areNormalizedStringsEqual(company.name, onboardingCompanyName)
  )
  if (matchingCompany === undefined) {
    return companyFromMetadata
  }

  // If we've made it to this point it means a matching company does exist. We should return that
  // existing entry instead.
  const companyLocation = matchingCompany.locations.length ? matchingCompany.locations[0] : null
  return {
    id: matchingCompany.id,
    name: matchingCompany.name,
    locationId: companyLocation?.id ?? null,
    address: companyLocation ? locationInputFromLocation(companyLocation) : null,
  }
}

type StoredCompanyData = {
  generalContractors: MinimalCompany[]
  owners: MinimalCompany[]
  architects: MinimalCompany[]
}

/**
 * Converts server-side project onboarding metadata to client-side `ProjectOnboardingInfo`. Supports
 * any subset of fields provided, since different integration types provide different data. If an initial
 * set of project info is given, only updates fields that are empty in the given object.
 */
export function fillProjectOnboardingInfoFromMetadata({
  projectMetadata,
  defaultPayAppDueOnDayOfMonth,
  defaultRetentionPercent,
  initialInfo,
  existingCompanies,
}: {
  projectMetadata: ImportProjectOnboardingMetadataProperties
  defaultRetentionPercent: number
  defaultPayAppDueOnDayOfMonth: number
  /**
    `initialInfo` is project info that exists before filling in the remaining blanks with `projectMetadata`.
    This can happen if we're collecting project metadata from 2 integrations.
   */
  initialInfo?: ProjectOnboardingInfo
  /** We check for existing GCs, architects and owners before creating new ones to avoid duplicate records */
  existingCompanies: StoredCompanyData
}): ProjectOnboardingInfo {
  const {
    integrationAssociatedCompanyId,
    project: {
      integrationProjectId,
      projectName,
      projectAddress,
      projectNumber,
      internalProjectNumber,
      sitelineLeadPMIds,
    },
    contract: {
      integrationContractId,
      contractNumber,
      contractDate,
      latestPayAppNumber,
      payAppDueDate,
      retentionPercent,
      retentionTrackingLevel,
      roundRetention,
      paymentTermsType,
      paymentTerms,
      preSitelineBilled,
    },
    generalContractor,
    owner,
    architect,
    companyIntegrationId,
    taxCalculationType,
  } = projectMetadata

  const timeZone = projectAddress?.timeZone

  const initialOnboardingInfo =
    initialInfo ??
    makeEmptyProjectOnboardingInfo({ defaultRetentionPercent, defaultPayAppDueOnDayOfMonth })

  const initialProjectInfo = initialOnboardingInfo.projectInfo

  const projectInfo: ProjectOnboardingInfo['projectInfo'] = {
    ...initialProjectInfo,
    ...(!initialProjectInfo.projectName && { projectName }),
    ...(!initialProjectInfo.gcProjectNumber && { gcProjectNumber: projectNumber }),
    ...(!initialProjectInfo.internalProjectNumber && { internalProjectNumber }),
    ...(!initialProjectInfo.projectAddress && {
      projectAddress: projectAddress && locationInputFromLocation(projectAddress),
    }),
    ...(initialProjectInfo.pastPayAppCount === 0 && { pastPayAppCount: latestPayAppNumber ?? 0 }),
    ...(initialProjectInfo.preSitelineBilled === null && { preSitelineBilled }),
    ...(!initialProjectInfo.dueToGc && payAppDueDate && { dueToGc: payAppDueDate }),
    ...(!initialProjectInfo.leadPMIDs &&
      sitelineLeadPMIds && { leadPMIDs: [...sitelineLeadPMIds] }),
    generalContractor: fillCompanyInfoFromMetadataOrExistingRecord({
      companyMetadata: generalContractor,
      initialCompanyMetadata: initialProjectInfo.generalContractor,
      existingCompanies: existingCompanies.generalContractors,
    }),
    owner: fillCompanyInfoFromMetadataOrExistingRecord({
      companyMetadata: owner,
      initialCompanyMetadata: initialProjectInfo.owner,
      existingCompanies: existingCompanies.owners,
    }),
    architect: architect
      ? fillCompanyInfoFromMetadataOrExistingRecord({
          companyMetadata: architect,
          initialCompanyMetadata: initialProjectInfo.architect,
          existingCompanies: existingCompanies.architects,
        })
      : null,
  }

  const initialContractInfo = initialOnboardingInfo.contract
  const contract: ProjectOnboardingInfo['contract'] = {
    ...initialContractInfo,
    ...(!initialContractInfo.contractNumber && { contractNumber }),
    ...(!initialContractInfo.contractDate &&
      contractDate && {
        contractDate: timeZone ? moment.tz(contractDate, timeZone) : moment.utc(contractDate),
      }),
    ...(initialContractInfo.paymentTermsType === null && { paymentTermsType }),
    ...(initialContractInfo.paymentTerms === null && { paymentTerms }),
  }

  const initialRetentionInfo = initialOnboardingInfo.retention
  const retention: ProjectOnboardingInfo['retention'] = {
    ...initialRetentionInfo,
    ...((!initialInfo || initialInfo.retention.defaultRetentionPercent === null) && {
      defaultRetentionPercent: retentionPercent,
    }),
    ...(!initialInfo && retentionTrackingLevel && { retentionTrackingLevel }),
    ...(!initialInfo && roundRetention !== null && { roundRetention }),
  }

  const integrations = initialInfo ? [...initialInfo.integrations] : []
  integrations.push({
    companyIntegrationId,
    integrationProjectId,
    integrationContractId: integrationContractId ?? undefined,
    integrationAssociatedCompanyId: integrationAssociatedCompanyId ?? undefined,
  })

  const initialTaxesInfo = initialOnboardingInfo.taxes
  const taxes: ProjectOnboardingInfo['taxes'] = {
    ...initialTaxesInfo,
    taxCalculationType,
  }

  return {
    ...initialOnboardingInfo,
    projectInfo,
    contract,
    retention,
    integrations,
    taxes,
  }
}

/** Returns true if currently editing an SOV in the SOV onboarding phase */
export function isOnboardingSov() {
  return window.location.href.includes(`step=${SovOnboardingStep.EDIT}`)
}

/** Returns the link to the appropriate SOV template */
export function getSovTemplate(hasPreviousBilled: boolean, billingType: BillingType | undefined) {
  const SOV_TEMPLATE_LINK = '/sov-template.xlsx'
  const SOV_TEMPLATE_PREVIOUS_BILLED_LINK = '/sov-template-previous-billed.xlsx'
  const UNIT_PRICE_TEMPLATE_PREVIOUS_BILLED_LINK = '/unit-price-template-previous-billed.xlsx'
  const UNIT_PRICE_TEMPLATE_LINK = '/unit-price-template.xlsx'

  if (billingType === BillingType.UNIT_PRICE) {
    if (hasPreviousBilled) {
      return UNIT_PRICE_TEMPLATE_PREVIOUS_BILLED_LINK
    } else {
      return UNIT_PRICE_TEMPLATE_LINK
    }
  } else {
    if (hasPreviousBilled) {
      return SOV_TEMPLATE_PREVIOUS_BILLED_LINK
    } else {
      return SOV_TEMPLATE_LINK
    }
  }
}

interface PendingUploadFile {
  file: File
  name: string
}

interface UseUploadOnboardingFormsParams {
  formType: ProjectOnboardingFormType
  contractId: string
  refetchQuery?: SelectFormsRefetchQuery
}

export type OnboardingFormsInstructions = Pick<
  UploadFormTemplatesInput,
  'signatureTypes' | 'repeatingValuesNote' | 'formValuesNote' | 'specialFormsNote' | 'note'
>

export const useUploadOnboardingForms = ({
  formType,
  contractId,
  refetchQuery,
}: UseUploadOnboardingFormsParams) => {
  const [uploadFormTemplateDocuments] = useUploadFormTemplatesMutation()

  return useCallback(
    async (
      pendingFiles: PendingFile[],
      instructions: OnboardingFormsInstructions,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      if (pendingFiles.length === 0) {
        return
      }

      const forms = pendingFiles
        .map(({ file, name }) => ({ file, name }))
        .filter((pendingFile) => pendingFile.file !== undefined) as PendingUploadFile[]

      await uploadFormTemplateDocuments({
        variables: {
          input: {
            contractId,
            formType,
            forms,
            includeChangeOrderLogOnPayApps,
            ...instructions,
          },
        },
        update: (cache) => {
          invalidateContractsAfterOnboardingStatusChange(cache)
        },
        ...refetchQuery,
      })
    },
    [contractId, formType, refetchQuery, uploadFormTemplateDocuments]
  )
}

interface UseDefaultFormsParams {
  contract: ContractForProjectOnboarding
  projectId: string
}

/** Hook handles selecting and clearing company forms during onboarding */
export function useOnboardingForms({ contract, projectId }: UseDefaultFormsParams) {
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()

  const vendorFormsRefetchQuery = useMemo(
    () => ({
      refetchQueries: [
        {
          query: GetProjectLienWaiversByMonthDocument,
          variables: {
            input: {
              projectIds: [projectId],
              companyId: contract.company.id,
            },
          },
        },
      ],
    }),
    [projectId, contract.company.id]
  )

  const [selectProjectForms] = useSelectProjectFormsMutation({
    ...vendorFormsRefetchQuery,
    update: (cache) => {
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
  })

  const handleSelectForms = useCallback(
    async (selectFormsInput: SelectProjectFormsInput, shouldDisplayConfirmationToast: boolean) => {
      try {
        await selectProjectForms({ variables: { input: selectFormsInput } })
        if (shouldDisplayConfirmationToast) {
          snackbar.showSuccess(t('projects.onboarding.checklist.added_forms'))
        }
      } catch (error) {
        snackbar.showError(t('common.errors.snackbar.generic'))
      }
    },
    [selectProjectForms, snackbar, t]
  )

  // User selects the company's default forms as templates
  const handleSelectDefaultForms = useCallback(
    async (formTypes: ProjectOnboardingFormType[], includeChangeOrderLogOnPayApps?: boolean) => {
      handleSelectForms(
        {
          contractId: contract.id,
          formTypes,
          useDefaultForms: true,
          includeChangeOrderLogOnPayApps,
        },
        true
      )
    },
    [contract.id, handleSelectForms]
  )

  // User selects form templates from another project
  const handleSelectFormsFromProject = useCallback(
    async (
      formTypes: ProjectOnboardingFormType[],
      contractId: string,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      handleSelectForms(
        {
          contractId: contract.id,
          formTypes,
          useDefaultForms: false,
          copyFromContractId: contractId,
          includeChangeOrderLogOnPayApps,
        },
        true
      )
    },
    [contract.id, handleSelectForms]
  )

  // User chooses to move on in project onboarding without selecting one or some
  // optional form types. In this case, we store the selection as "not required"
  const handleSelectFormsNotRequired = useCallback(
    async (formTypes: ProjectOnboardingFormType[]) => {
      handleSelectForms(
        {
          contractId: contract.id,
          formTypes,
          useDefaultForms: false,
        },
        false
      )
    },
    [contract.id, handleSelectForms]
  )

  return { handleSelectDefaultForms, handleSelectFormsFromProject, handleSelectFormsNotRequired }
}

interface FormUploadStatus {
  /**
   * An action has been performed. The forms were either marked as default, copied,
   * uploaded & processing, uploaded & finished processing, or marked as not required.
   */
  hasMadeSelection: boolean
  /**
   * Forms have been selected. This includes all actions/ statuses except marked as
   * not required (forms were selected or are processing)
   */
  hasFormTemplates: boolean
  /** Forms were uploaded and are still processing */
  isProcessingForms: boolean
}

export type ContractOnboardingFormType =
  | ProjectOnboardingFormType.PAY_APP
  | ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER
  | ProjectOnboardingFormType.VENDOR_LIEN_WAIVER
  | ProjectOnboardingFormType.CHANGE_ORDER_REQUEST
  | ProjectOnboardingFormType.CHANGE_ORDER_LOG

/**
 * There are several actions (and resulting statuses) relevant to form onboarding:
 *
 * • no actions have been performed
 * • selected default templates
 * • copied templates from another project
 * • uploaded new templates that are still being processed
 * • uploaded new templates that have finished being processed
 * • marked as "not required"
 *
 * These fields are not all included on the contract, so we must derive the status
 * based on combinations of the following fields:
 *
 * • _template (e.g. changeOrderRequestTemplate) - null if still processing or marked as "not required"
 * • onboardedStatus.onboarded_ (e.g. onboardedStatus.onboardedChangeOrderRequestForms) - false if:
 *    1) no actions have been performed
 *    2) forms were uploaded but still processing
 * • onboardedStatus.selected_ (e.g., onboardedStatus.selectedChangeOrderRequestForms) - only false if no actions performed
 *
 */
export function deriveFormSelectionStatusFromContract(
  formType: ContractOnboardingFormType,
  contract: ContractForProjectOnboarding | ContractForForms | ContractForProjectHome
): FormUploadStatus {
  switch (formType) {
    case ProjectOnboardingFormType.PAY_APP: {
      const hasMadeSelection = contract.onboardedStatus.selectedPayAppForms
      const isProcessingForms = hasMadeSelection && !contract.onboardedStatus.onboardedPayAppForms
      return {
        hasMadeSelection,
        hasFormTemplates: isProcessingForms || !!contract.payAppRequirementGroups.length,
        isProcessingForms,
      }
    }
    case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER: {
      const hasMadeSelection = contract.onboardedStatus.selectedPrimaryLienWaivers
      const isProcessingForms =
        hasMadeSelection && !contract.onboardedStatus.onboardedPrimaryLienWaiverForms
      return {
        hasMadeSelection,
        isProcessingForms,
        hasFormTemplates: isProcessingForms || !!contract.lienWaiverTemplates,
      }
    }
    case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER: {
      const hasMadeSelection = contract.onboardedStatus.selectedVendorLienWaivers
      const isProcessingForms =
        hasMadeSelection && !contract.onboardedStatus.onboardedVendorLienWaiverForms
      return {
        hasMadeSelection,
        isProcessingForms,
        hasFormTemplates: isProcessingForms || !!contract.lowerTierLienWaiverTemplates,
      }
    }
    case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST: {
      const hasMadeSelection = contract.onboardedStatus.selectedChangeOrderRequestForms
      const isProcessingForms =
        hasMadeSelection && !contract.onboardedStatus.onboardedChangeOrderRequestForms
      return {
        hasMadeSelection,
        isProcessingForms,
        hasFormTemplates: isProcessingForms || !!contract.changeOrderRequestTemplate,
      }
    }
    case ProjectOnboardingFormType.CHANGE_ORDER_LOG: {
      const hasMadeSelection = contract.onboardedStatus.selectedChangeOrderLogForms
      const isProcessingForms =
        hasMadeSelection && !contract.onboardedStatus.onboardedChangeOrderLogForms
      return {
        hasMadeSelection,
        isProcessingForms,
        hasFormTemplates: isProcessingForms || !!contract.changeOrderLogTemplate,
      }
    }
  }
}

/** Input for fetching all contracts with forms that can be copied to a new project */
export function getContractsQueryInputForProjectCopy(companyId: string) {
  return {
    variables: {
      input: {
        status: [ContractStatus.ACTIVE, ContractStatus.ARCHIVED],
        companyId,
      },
    },
  }
}

/** Same as above, but does not include `contractStatus` filter because we want to include all projects */
function getPaginatedContractsQueryInputForProjectCopy(companyId: string) {
  return {
    variables: {
      input: {
        companyId,
      },
    },
  }
}

/**
 * Called when forms are cleared from the current project so that when we present options
 * for copying forms, we don't end up presenting the current project as one to copy from
 */
export function getClearOnboardingFormsRefetchQuery(companyId: string) {
  const input = getPaginatedContractsQueryInputForProjectCopy(companyId)
  return {
    refetchQueries: [
      {
        query: GetContractsForFormsDocument,
        variables: input.variables,
      },
    ],
  }
}

/**
 * Invalidates `onboardingContracts` and `contractOverview` after a mutation that might affect
 * the onboarding status of a contract.
 */
export function invalidateContractsAfterOnboardingStatusChange(cache: ApolloCache<unknown>) {
  evictWithGc(cache, (evict) => {
    evict({ id: 'ROOT_QUERY', fieldName: 'onboardingContracts' })
    evict({ id: 'ROOT_QUERY', fieldName: 'contractsOverview' })
  })
}
