import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { colors, colorStyles, makeStylesFast } from 'siteline-common-web'
import { UserProperties } from '../graphql/apollo-operations'
import { getInitials } from '../util/User'

const useStyles = makeStylesFast((theme: Theme) => ({
  avatar: {
    height: '1em',
    width: '1em',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .initials': {
      ...theme.typography.h4,
      fontWeight: 600,
      fontSize: '0.5rem', // Fits long initials like WW or MM better than 16px
    },
    '&.md': {
      height: 28,
      width: 28,
      '& .initials': {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    '&.lg': {
      width: 32,
      height: 32,
      '& .initials': {
        fontSize: theme.typography.subtitle1.fontSize,
      },
    },
    '&.xl': {
      width: 40,
      height: 40,
      '& .initials': {
        fontSize: '0.85rem',
      },
    },
  },
}))

type AvatarFromInitialsProps = {
  initials: string
  color: keyof typeof colors
  textColor: keyof typeof colorStyles
  size: 'sm' | 'md' | 'lg' | 'xl'
}

export function AvatarFromInitials({ initials, color, textColor, size }: AvatarFromInitialsProps) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.avatar, size)} style={{ backgroundColor: colors[color] }}>
      <div className="initials" style={{ color: colors[textColor] }}>
        {initials}
      </div>
    </div>
  )
}

type AvatarProps = {
  user?: Pick<UserProperties, 'firstName' | 'lastName'>
  isAdmin?: boolean
  color: keyof typeof colors
  textColor: keyof typeof colorStyles
  size: 'sm' | 'md' | 'lg' | 'xl'
}

export function Avatar({ user, isAdmin, color, textColor, size }: AvatarProps) {
  let initials = ''
  if (isAdmin) {
    initials = 'SA'
  } else if (user) {
    initials = getInitials(user)
  }
  return <AvatarFromInitials initials={initials} color={color} textColor={textColor} size={size} />
}
