/**
 * The minimum number of days at which we consider a pending integration sync overdue.
 * Should be kept in sync with the copy in the Slack notification from the analytics event.
 */
export const MIN_DAYS_PENDING_SYNC_OVERDUE = 8

// This is a limit imposed by Sage, see https://github.com/siteline/siteline/issues/9034
export const MAX_SAGE_INVOICE_CODE_LENGTH = 15

// This is a limit imposed by Spectrum (per error received when attempting to sync longer #)
export const MAX_SPECTRUM_INVOICE_CODE_LENGTH = 10

// This is a limit imposed by Spectrum (per error received when attempting to sync longer #)
export const MAX_SPECTRUM_BATCH_NUMBER_LENGTH = 10

// This is a limit imposed by Sage (seems to be the max by trial and error)
export const MAX_SAGE_300_DRAW_NUMBER_LENGTH = 15

// The maximum number of characters supported for a Sage Intacct reference number
// the reference number will be cut off after this number of characters
export const MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH = 120

// This is a limit imposed by Foundation (per error received when attempting to sync longer #)
export const MAX_FOUNDATION_INVOICE_NUMBER_LENGTH = 10
