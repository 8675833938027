import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import _ from 'lodash'
import {
  AgingAmountType,
  AgingIntervalType,
  CashForecastPeriodType,
  CompanyUserRole,
  ContractMonthlyStatusFilter,
  CreateForecastCurveOption,
  IntegrationType,
  integrationTypes,
  LegalDocumentStatus,
  LienWaiverCategory,
  pdfTypes,
  PreSitelinePayAppStatus,
  ProjectOnboardingFormType,
  RequiredFieldForForms,
  TaxCalculationType,
  VendorContractCommitmentType,
  VendorLienWaiverReminderFrequency,
} from 'siteline-common-all'
import { OnboardedProjectVendorsStatus } from 'siteline-common-web'
import { Segment } from '../../Analytics'
import { BillingTab } from '../../components/billing/Billing.lib'
import { PricingToolFilterType } from '../../components/billing/change-order-requests/ChangeOrderRequestPricingToolHeader'
import { AddTaxRateLocation } from '../../components/billing/invoice/taxes/AddOrEditTaxGroupDialog'
import { MissingFieldsFormType } from '../../components/billing/onboarding/MissingFieldsDialog'
import { ProjectFilters } from '../../components/project-home/ProjectList.lib'
import { ProjectSort } from '../../components/project-home/ProjectListTable'
import { AmountAgedPeriod } from '../../components/reporting/aging-dashboard/AmountAgedOverPeriodDropdown'
import { ReportingOverviewCard, ReportingTab } from '../../components/reporting/Reporting.lib'
import { CompanySettingsTab } from '../../components/settings/Settings.lib'
import {
  VendorProjectOnboardingStep,
  VendorsTrackerView,
} from '../../components/vendors/Vendors.lib'
import { userCompanyStorageKey } from '../contexts/CompanyContext'
import {
  BillingType,
  CreateChangeOrderRequestRateTableItemInput,
  CreatePayAppRateTableItemInput,
  LienWaiverType,
  PayAppStatus,
  RetentionTrackingLevel,
  UpdateChangeOrderRequestRateTableItemInput,
  UpdatePayAppRateTableItemInput,
} from '../graphql/apollo-operations'
import { ForecastAmountType } from './BillingForecast'
import { ComplianceRequirementQuickAddType } from './LegalRequirement'
import { SubmitHistoryActivityFilter } from './PayApp'
import { SitelineModule } from './Project'
import { safeLocalStorage } from './SafeLocalStorage'

function track(event: string, properties: Record<string, unknown> = {}, createSlackAlert = false) {
  // By default, events should not be sent to Slack
  let integrations = { Slack: false }
  const context: Record<string, unknown> = {}
  if (createSlackAlert) {
    integrations = { Slack: true }
    // Get the Segment identity cookie https://cookiedatabase.org/cookie/segment/ajs_user_traits
    const traitsString = safeLocalStorage.getItem('ajs_user_traits')
    const traits = traitsString ? JSON.parse(traitsString) : {}
    // Explicitly pass in the user identity traits for use in the slack notification
    context.slack = traits
  }

  // Add `companyId` to all events, if available in local storage.
  // This is hacky, but it avoids refactoring hundreds of track calls.
  const propertiesWithCompany = _.cloneDeep(properties)
  const auth = getAuth(getApp())
  if (!('companyId' in propertiesWithCompany) && auth.currentUser) {
    try {
      const companyId = window.localStorage.getItem(userCompanyStorageKey(auth.currentUser.uid))
      if (_.isString(companyId)) {
        propertiesWithCompany.companyId = companyId
      }
    } catch {
      // Ignore
    }
  }

  Segment.analytics?.track(event, propertiesWithCompany, { integrations, context })
}

/**
 * ------------------------ PAGE VIEWS ------------------------
 */
export function trackMaintenanceViewed() {
  track('Maintenance Page Viewed')
}
export function trackHelpViewed() {
  track('Help Page Viewed')
}
export function trackNotificationViewed() {
  track('Notifications Viewed')
}
export function trackPayAppListViewed(props: {
  projectId: string
  projectName: string
  userRole: CompanyUserRole | null
}) {
  track('Pay App List Viewed', props)
}
export function trackLegalRequirementPageViewed(props: { projectId: string; projectName: string }) {
  track('Compliance Page Viewed', props)
}
export function trackLegalDocumentViewed(props: {
  projectId: string
  projectName: string
  itemName: string | undefined
  fileName: string | undefined
  fileType: string | undefined
  periodEnd: string | undefined
  createdDate: string | undefined
}) {
  track('Compliance Attachment Viewed', props)
}
export function trackBackupPageViewed(props: MetricsForPayApp) {
  track('Backup Page Viewed', props)
}
export function trackInvoicePageViewed(props: MetricsForPayApp) {
  track('Invoice Page Viewed', props)
}
export function trackWorksheetViewed(props: {
  payAppMetrics?: MetricsForPayApp
  projectId: string
  projectName: string
  billingType: BillingType
  userRole: CompanyUserRole | null
  isSidesheet: boolean
  sidesheetType: 'sov' | 'invoice' | null
}) {
  track('Worksheet Page Viewed', props)
}
export function trackViewSovPageViewed(props: {
  projectId: string
  projectName: string
  companyId: string
}) {
  track('View SOV Page Viewed', props)
}
export function trackEditSovPageViewed(props: {
  projectId: string
  projectName: string
  companyId: string
  isOnboarding: boolean
}) {
  track('Edit Sov Page Viewed', props)
}
export function trackUnconditionalViewed(
  props: (MetricsForPayApp | MetricsForPreSitelinePayApp) & {
    billingStartDate: string | null
    billingEndDate: string | null
    value: number
    preSiteline: boolean
  }
) {
  track('Unconditional Lien Waiver Page Viewed', props)
}
export function trackSignPayAppViewed(props: MetricsForPayApp) {
  track('Sign Page Viewed', props)
}
export function trackPayAppViewed(props: MetricsForPayApp) {
  track('Pay App Overview Viewed', props)
}
export function trackManageUsersViewed() {
  track('Manage Users Settings Page Viewed')
}
export function trackProjectCreation(props: { billingType: BillingType }) {
  track('Create Project Form Viewed', props)
}
export function trackChangeOrderRequestPageViewed(props: {
  fromLocation: 'linkedCOR' | 'SOV' | 'changeOrderLog'
}) {
  track('Change Order Request Page Viewed', props)
}
export function trackChangeOrderLogPageViewed() {
  track('Change Order Log Page Viewed')
}
export function trackOpenHelpCenter() {
  track('Open Help Center')
}
export function trackReportingPageViewed() {
  track('Reporting Page Viewed')
}

/**
 * ------------------------ USER ------------------------
 */
export function trackSignedIn() {
  track('Account Signed In')
}
export function trackSignedOut() {
  track('Account Signed Out')
}
export function trackAccountCreated(props: {
  signUpChannel: string
  companyId: string | undefined
  title: string | null | undefined
}) {
  track('Account Created', props)
}
export function trackNotificationClicked(props: {
  actionButton: string | undefined
  listPosition: number
  notifIcon: number
  notifCategory: string
  notifItem: string
  projectName: string | undefined
  projectId: string | undefined
  link: string
}) {
  track('Notification Item Clicked', props)
}
export function trackAccountUpdated(props: {
  oldName: string
  newName: string
  oldTitle: string
  newTitle: string
}) {
  track('Settings Account Updated', props)
}
export function trackCompanyNameUpdated(props: { oldCompanyName: string; newCompanyName: string }) {
  track('Settings Company Profile Updated', props)
}
export function trackCompanyNicknameUpdated(props: {
  oldCompanyNickname: string
  newCompanyNickname: string
}) {
  track('Settings Company Nickname Updated', props)
}
export function trackCompanyAuditTrailEnabledUpdated(props: {
  oldAuditTrailEnabled: boolean
  newAuditTrailEnabled: boolean
}) {
  track('Settings Company Audit Trail Setting Updated', props)
}
export function trackCompanyAgingIntervalTypeUpdated(props: {
  oldAgingIntervalType: AgingIntervalType
  newAgingIntervalType: AgingIntervalType
}) {
  track('Settings Company Aging Interval Type Updated', props)
}
export function trackVendorLienWaiverReminderFrequencyUpdated(props: {
  oldFrequency: VendorLienWaiverReminderFrequency
  newFrequency: VendorLienWaiverReminderFrequency
}) {
  track('Settings Company Vendor Lien Waiver Reminder Frequency Updated', props)
}
export function trackCompanyDefaultRetentionPercentUpdated(props: {
  oldDefaultRetentionPercent: number
  newDefaultRetentionPercent: number
}) {
  track('Settings Company Default Retention Percent Updated', props)
}
export function trackCompanyAddressAdded(props: { newAddress: string }) {
  track('Settings Company Address Created', props)
}
export function trackCompanyAddressUpdated(props: { oldAddress: string; newAddress: string }) {
  track('Settings Company Address Updated', props)
}
export function trackPrimaryOfficeUpdated(props: { oldOfficeName: string; newOfficeName: string }) {
  track('Settings Primary Office Updated', props)
}
export function trackEmailPreferenceChange(props: {
  emailCategory: string
  emailItem?: string
  emailDeliveryOn: boolean
  turnOffButton: 'category' | 'individual'
  userRole: CompanyUserRole | null
}) {
  track('Settings Email Preference Updated', props)
}
export function trackNotificationPreferenceChange(props: {
  notifCategory: string
  notifItem?: string
  notifDeliveryOn: boolean
  turnOffButton: 'category' | 'individual'
  userRole: CompanyUserRole | null
}) {
  track('Settings Notif Preference Updated', props)
}
export function trackManageUsersChange(props: {
  changeType: 'updateUser' | 'inviteUser' | 'deactivateUser' | 'reactivateUser'
  affectedUserId: string
  managedUserRole?: CompanyUserRole | null
}) {
  track('Settings Manage Users Updated', props)
}
export function trackUserEducationCampaignShown(props: { userId: string; campaignName: string }) {
  track('User Education Campaign Shown', props)
}
export function trackUserEducationCampaignDismissed(props: {
  userId: string
  campaignName: string
}) {
  track('User Education Campaign Dismissed', props)
}
export function trackDownloadBillingReport(props: {
  companyId: string
  companyName: string
  month: string
}) {
  track('Download Monthly Billing Report', props)
}
export function trackSettingsTabClick(props: { tab: CompanySettingsTab }) {
  track('Settings Tab Clicked', props)
}
export function trackCompanySwitch(props: {
  oldCompanyId: string | null
  newCompanyId: string | null
  source: 'companySwitcherMenu' | 'wrongCompanyDialog' | 'newlyInvitedUser'
}) {
  track('Switched Company', props)
}

/**
 * ------------------------ PROJECT ------------------------
 */
export function trackProjectListSort(props: { sortBy: ProjectSort }) {
  track('Project List Sorted', props)
}
export function trackProjectListSearch(props: { search: string | boolean | undefined }) {
  track('Project List Searched', props)
}
export function trackProjectListFilter(props: { filters: ProjectFilters }) {
  // We renamed pms to leadPMs and pluralized some of our filters
  const backwardsCompatibleAttributes = {
    filters: {
      gcPortalIntegration: props.filters.gcPortalIntegrations,
      billingType: props.filters.billingTypes,
      payAppStatus: props.filters.payAppStatuses,
      offices: props.filters.offices,
      pms: props.filters.leadPMs,
    },
  }
  track('Project List Filtered', backwardsCompatibleAttributes)
}
export function trackProjectListMonthFilter(props: { previousMonth: string; newMonth: string }) {
  track('Project List Month Updated', props)
}
export function trackViewActiveProjectList() {
  track('View Active Projects Clicked')
}
export function trackViewArchivedProjectListClick() {
  track('View Archived Projects Clicked')
}
export function trackBackToActiveProjectsClick() {
  track('Active Projects Back Button Clicked')
}
export function trackProjectSovImported(props: { projectId: string; projectName: string }) {
  track('Project SOV Imported', props)
}
export function trackProjectSovCreated(props: { projectId: string; projectName: string }) {
  track('Project SOV Created', props)
}
export function trackBulkUploadFormsButtonClicked(props: {
  actionType: 'copyFromProject' | 'default'
  contractId: string
  projectName: string
}) {
  track('Bulk upload forms button clicked', props)
}
export function trackUploadFormsButtonClicked(props: {
  contractId: string
  projectName: string
  formsType: ProjectOnboardingFormType
  location: string
}) {
  track('Upload forms button clicked', props)
}
export function trackUploadMoreFormsButtonClicked(props: {
  contractId: string
  projectName: string
  formsType: ProjectOnboardingFormType
  location: string
}) {
  track('Upload more forms button clicked', props)
}
export function trackClearFormsButtonClicked(props: {
  contractId: string
  projectName: string
  formsType: ProjectOnboardingFormType
  location: string | 'vendors-setup' | 'vendors-settings'
}) {
  track('Clear forms button clicked', props)
}
export function trackDeleteFormTemplateButtonClicked(props: {
  contractId: string
  projectName: string
  formsType: ProjectOnboardingFormType
  location: string | 'vendors-setup' | 'vendors-settings'
}) {
  track('Remove form template button clicked', props)
}
const getBackwardsCompatibleFormsType = (formsType: ProjectOnboardingFormType) => {
  switch (formsType) {
    case ProjectOnboardingFormType.PAY_APP:
      return 'payApp'
    case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
      return 'primaryLienWaivers'
    case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
      return 'vendorLienWaivers'
    case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
      return 'changeOrderRequest'
    case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
      return 'changeOrderLog'
    case ProjectOnboardingFormType.COMPLIANCE:
      return 'compliance'
  }
}
export function trackSelectedProjectForms(props: {
  contractId: string
  projectName: string
  // Array if multiple forms were selected through bulk upload
  formsType: ProjectOnboardingFormType | ProjectOnboardingFormType[]
  selectType: 'defaultForms' | 'copyFromProject' | 'newForms'
  location: string
  includeChangeOrderLogOnPayApps?: boolean
}) {
  const backwardsCompatibleFormsType = _.isArray(props.formsType)
    ? props.formsType.map(getBackwardsCompatibleFormsType)
    : getBackwardsCompatibleFormsType(props.formsType)
  track('Selected Project Forms', { ...props, formsType: backwardsCompatibleFormsType })
}
export function trackConfirmedFormSelections(props: {
  contractId: string
  projectName: string
  outstandingFormsTypes: ProjectOnboardingFormType[]
}) {
  track('Confirmed form selections in project onboarding', props)
}
export function trackEditedSov(props: {
  projectId: string
  projectName: string
  companyId: string
}) {
  track('Edited Project SOV', props, true)
}
export function trackEditedWorksheet(props: {
  projectId: string
  projectName: string
  companyId: string
}) {
  track('Edited Project SOV Worksheet', props)
}
export function trackOpenRecentlyViewedProject(props: {
  module: SitelineModule
  projectId: string
  projectName: string
  companyId: string
}) {
  track('Opened Recently Viewed Project', props)
}
export function trackEnterMissingFormFields(props: {
  projectId: string
  projectName: string
  companyId: string
  formType: MissingFieldsFormType
  fields: RequiredFieldForForms[]
}) {
  track('Added Missing Fields Required For Forms', props)
}
export function trackBillingProjectTabClick(props: { tab: BillingTab }) {
  track('Billing Subtab Click', props)
}
export function trackProjectTeamCardToggle(props: {
  projectId: string
  action: 'expand' | 'collapse'
}) {
  track('Project Team Card Expanded', props)
}
export function trackProjectInfoCardToggle(props: {
  projectId?: string
  action: 'expand' | 'collapse'
}) {
  track('Project Info Card Expanded', props)
}
export function trackProjectNotesEdit(props: {
  location: 'pay app overview' | 'pay app list' | 'vendors'
  projectId: string
}) {
  track('Project Notes Edit Clicked', props)
}
export function trackProjectNotesSaved(props: { projectId: string; projectName: string }) {
  track('Project Notes Saved', props)
}
export function trackActivityFeedNavigation(props: {
  projectId: string
  actionTitle: string
  navigateTo: 'ar aging' | 'pay app' | 'cash forecast'
}) {
  track('Activity Feed Navigation Row Clicked', props)
}
export function trackRateTableSelection(props: {
  projectId: string
  projectName: string
  rateTableId: string
  rateTableName: string
  location: 'projectHome' | 'projectOnboarding' | 'changeOrderLog' | 'changeOrderDetails'
}) {
  track('Selected Project Rate Table', props)
}
export function trackPricingFeeAddition(props: {
  projectId: string
  projectName: string
  location: 'projectOnboarding' | 'projectSettings'
  groupName: string | null
  description: string
  percent: number
  type: 'timeAndMaterials' | 'corPricingTool'
}) {
  track('Added T&M Or COR Fee', props)
}
export function trackPricingFeeEdit(props: {
  projectId: string
  projectName: string
  location: 'projectOnboarding' | 'projectSettings'
  groupName: string | null
  description: string
  /** Percent will be 0 if it's an override deletion */
  percent: number
  type: 'timeAndMaterials' | 'corPricingTool'
}) {
  track('Edited T&M Or COR Fee', props)
}
export function trackBulkImportOverUnderBillingOpened() {
  track('Opened Job Costs Page')
}
export function trackBulkImportOverUnderBillingTemplateDownloaded() {
  track('Downloaded Job Costs Template')
}
export function trackBulkImportOverUnderBillingTemplateUploaded() {
  track('Uploaded Job Costs Template')
}
export function trackInsertSovLineItemFromContextMenu(props: {
  projectId: string
  projectName: string
  type: 'lineItem' | 'changeOrder'
  direction: 'up' | 'down'
}) {
  track('Insert SOV Line Item From Context Menu', props)
}
export function trackShowRenumberSovLineItemsHint(props: {
  projectId: string
  projectName: string
  type: 'reorder' | 'edit'
}) {
  track('Show Renumber SOV Line Items Hint', props)
}
export function trackAcceptRenumberSovLineItemsHint(props: {
  projectId: string
  projectName: string
  type: 'reorder' | 'edit'
}) {
  track('Accept Renumber SOV Line Items Hint', props)
}
export function trackDismissRenumberSovLineItemsHint(props: {
  projectId: string
  projectName: string
  type: 'reorder' | 'edit'
}) {
  track('Dismiss Renumber SOV Line Items Hint', props)
}

/**
 * ------------------------ PAY APP ------------------------
 */
export type MetricsForPayApp = {
  projectId: string
  payAppId: string
  payAppType: 'retention' | 'progress'
  payAppStatus: PayAppStatus
  payAppNumber: number
  projectName: string | undefined
  billingType: BillingType
}
export type MetricsForPreSitelinePayApp = {
  projectId: string
  payAppId: string | null
  payAppType: 'retention' | 'progress'
  payAppStatus: PreSitelinePayAppStatus | null
  payAppNumber: number
  projectName: string | undefined
}
export function trackGeneralContractorContactAdded(props: {
  fullName: string
  email: string
  companyName: string
}) {
  track('General Contractor Contact Added', props)
}
export function trackOptInBilling(props: {
  projectId: string
  projectName: string
  month: string
}) {
  track('Project opted in to billing', props)
}
export function trackOptOutBilling(props: {
  projectId: string
  projectName: string
  month: string
  reason: string
}) {
  track('Project opted out to billing', props)
}
export function trackPayAppCreated(
  props: MetricsForPayApp & {
    billingStartDate: string
    billingEndDate: string
    billingDueDate: string
    createdInBatch: boolean
  }
) {
  track('Pay App Created', props)
}

export function trackPreSitelinePayAppUploaded(
  props: MetricsForPreSitelinePayApp & {
    billingStartDate: string | null
    billingEndDate: string | null
    origin: 'payAppList' | 'payAppCreate' | 'payAppUpdate'
  }
) {
  track('Pre Siteline Pay App Uploaded', props)
}

export function trackViewedPreSitelinePayApp(props: MetricsForPreSitelinePayApp) {
  track('Viewed Pre Siteline Pay App', props)
}

export function trackBulkPayAppsCreated(props: { numCreated: number; numFailed: number }) {
  track('Bulk Create Pay Apps', props)
}
export function trackEditRetentionViewed(
  props: MetricsForPayApp & {
    retentionTrackingLevel: RetentionTrackingLevel
  }
) {
  track('Edit Retention Viewed', props)
}
export function trackUpdatedRetention(
  props: MetricsForPayApp & {
    retentionTrackingLevel: RetentionTrackingLevel
  }
) {
  track('Retention Updated', props)
}
export function trackOverrideRetention(
  props: MetricsForPayApp & {
    previousAmount: number
    previousAmountFormatted: string
    newAmount: number
    newAmountFormatted: string
    retentionTrackingLevel: RetentionTrackingLevel
  }
) {
  track('Override Retention Amount', props, true)
}
export function trackBillForAllRetention(
  props: MetricsForPayApp & {
    retentionTrackingLevel: RetentionTrackingLevel
  }
) {
  track('Bill For All Retention', props)
}
export function trackPayAppResetToDraft(
  props: MetricsForPayApp & {
    resetDraftDate: string
  }
) {
  track('Reset to Draft', props)
}
export function trackSignUnconditional(
  props: MetricsForPayApp & {
    billingStartDate: string
    billingEndDate: string
    value: number
    formattedValue: string
    generalContractorCompany?: string
    electronicRecordUniqueIdentifier: string
    company: string
  }
) {
  track('Unconditional Lien Waiver Signed', props, true)
}
export function trackSubmitUnconditional(
  props: MetricsForPayApp & {
    billingStartDate: string
    billingEndDate: string
    value: number
    formattedValue: string
    generalContractorCompany?: string
    electronicRecordUniqueIdentifier: string
    company: string
    message?: string
  }
) {
  track('Unconditional Lien Waiver Submitted', props, true)
}
export function trackPayAppPdfDownloaded(
  props: MetricsForPayApp & {
    billingStartDate: string
    billingEndDate: string
    billingDueDate: string
    subCompany: string
    generalContractorCompany?: string
    isNotary: boolean | undefined
  }
) {
  track('Pay App PDF Downloaded', props)
}
export function trackPayAppPrinted(
  props: MetricsForPayApp & {
    billingStartDate: string
    billingEndDate: string
    billingDueDate: string
    subCompany: string
    isNotary: boolean | undefined
  }
) {
  track('Pay App Printed', props)
}
export function trackPdfDownloaded(props: { package: pdfTypes.PayloadPackage; fileName: string }) {
  track('PDF Downloaded', props)
}
export function trackPdfPrinted(props: { package: pdfTypes.PayloadPackage; fileName: string }) {
  track('PDF Printed', props)
}
export function trackPayAppFullscreen(
  props: MetricsForPayApp & {
    fullscreen: boolean
  }
) {
  track('PDF Fullscreen', props)
}
export function trackMarkAsSubmitted(props: MetricsForPayApp & { action: 'synced' | 'submitted' }) {
  track('Pay App Marked As Submitted', props)
}

export function trackSubmitHistoryFilter(props: {
  filterBy: SubmitHistoryActivityFilter
  /** Provided if event triggered from pay app overview */
  payAppId?: string
  /** Provided if event triggered from project home/ pay app list */
  projectId?: string
  /** Provided if event triggered from change order request page */
  changeOrderRequestId?: string
  location: string
}) {
  track('Activity Log Filter Updated', props)
}

/** Used to track both pay app- and project-level comments */
export function trackCommentAdded(props: {
  location: string
  /** Provided if it's a payapp-level comment */
  payAppId?: string
  /** Provided if it's a project-level comment */
  projectId?: string
  /** Provided if it's a change order request-level comment */
  changeOrderRequestId?: string
  projectName: string
  comment: string
  mentions: string[]
}) {
  track('Pay App Comment Added', props, true)
}

export function trackCommentsPanelClicked(props: {
  location: string
  /** Provided if clicked on pay app comments panel */
  payAppId?: string
  /** Provided if clicked on project (aging) comments panel */
  projectId?: string
}) {
  track('Comments Opened', props)
}
export function trackPayAppRateTableReset(props: {
  projectId: string
  projectName: string
  reason: 'swapped' | 'updated'
  payAppId: string
}) {
  track('Reset Pay App Rate Table', props)
}
export function trackTimeAndMaterialsPayAppFeesReset(props: {
  projectId: string
  projectName: string
  payAppId: string
}) {
  track('Reset Pay App T&M Fees', props)
}
export function trackPaymentDatePredicted(props: {
  projectId: string
  projectName: string
  payAppId: string
  isSitelineSuggestion: boolean
}) {
  track('Predicted Pay App Payment Date', props)
}

/**
 * ------------------------ INVOICE ------------------------
 */
export function trackLineItemSearch(props: { search: string }) {
  track('Line Items Searched', props)
}
export function trackLineItemFilter(props: { filterBy: 'allItems' | 'thisMonth' }) {
  track('Line Items Filtered', props)
}
export function trackInvoiceReadyForReview(
  props: MetricsForPayApp & {
    message?: string
    userRole: CompanyUserRole | null
  }
) {
  track('Invoice Ready for Review', props)
}
export function trackLineItemHistoryView(props: {
  lineItemNumber: string
  lineItemName: string
  numEditors: number
}) {
  track('Line Item History Viewed', props)
}
export type ActionButtonActions =
  | 'submit draft'
  | 'adjust retention'
  | 'sign'
  | 'download CSV'
  | 'ready for review'
  | 'round values'
  | 'export Quickbooks'
  | 'export Foundation'
  | 'export Viewpoint Vista'
  | 'export ComputerEase'
export function trackActionButtonClicked(props: {
  projectId: string
  projectName: string
  payAppId: string
  payAppNumber: number
  buttonAction: ActionButtonActions
}) {
  track('Invoice Actions Button Clicked', props)
}
export function trackQuickBillActionButtonClicked(props: {
  projectId: string
  projectName: string
  payAppId: string
  buttonAction: 'download CSV' | 'round line items'
}) {
  track('Quick Bill Actions Button Clicked', props)
}
export function trackOpenPayAppMetricsDropdown(props: {
  projectId: string
  projectName: string
  payAppId: string
}) {
  track('Open Pay App Metrics Dropdown', props)
}
export function trackOpenCostAndProjectionDropdown(props: {
  projectId: string
  projectName: string
  payAppId: string
}) {
  track('Open Costs And Projections Dropdown', props)
}
export function trackAdjustCostManually(props: {
  projectId: string
  projectName: string
  actionTaken: 'cancel' | 'save'
  costToDate: number | null
  additionalCommittedCost: number | null
  totalEstimatedCost: number | null
}) {
  track('Adjust Costs Manually', props)
}
export function trackImportCostFromErp(props: {
  projectId: string
  projectName: string
  integrationType: IntegrationType
}) {
  track('Import Costs from ERP', props)
}
export function trackClearCost(props: { projectId: string; projectName: string }) {
  track('Clear Costs', props)
}
export function trackAddedLineItemToTMPayAppInvoice(props: {
  projectId: string
  projectName: string
  payAppId: string
  groupName: string
  lineItem: CreatePayAppRateTableItemInput
}) {
  track('Added Line Item to T&M Invoice', props)
}
export function trackEditedTMPayAppInvoiceLineItem(props: {
  projectId: string
  projectName: string
  payAppId: string
  groupName: string
  lineItem: UpdatePayAppRateTableItemInput
}) {
  track('Edited T&M Invoice Line Item', props)
}

/**
 * ------------------------ BACKUP ------------------------
 */
export function trackBackupAdded(
  props: MetricsForPayApp & {
    section: string
    fileName: string
    fileType: string
    description: string | null
    lineItem: string | undefined
    isRecurring: boolean
    /** T&M only */
    rateTableLineItems?: string[]
  }
) {
  track('Backup Added', props)
}
export function trackBackupRemoved(
  props: MetricsForPayApp & {
    section: string
    fileName: string
    fileType: string
    description: string | null | undefined
    lineItem: string | undefined
    deleteRecurringOption: 'SINGLE' | 'ALL' | undefined
  }
) {
  track('Backup Removed', props)
}

export function trackToggleIsBackupRecurring(props: {
  fileName: string
  fileType: string
  description: string
  isRecurring: boolean
}) {
  track('Recurring Backup Checkbox Toggled', props)
}

/**
 * ------------------------ LTLW ------------------------
 */
export function trackLienWaiverTrackerViewed(props: {
  type: string
  unconditionalsOnly: boolean | undefined
  granularity: VendorsTrackerView
  trackerDate: string
}) {
  track('Lien Waiver Tracker Viewed', props)
}
export function trackLienWaiverTrackerTypeChanged(props: {
  fromDetailType: VendorsTrackerView
  toDetailType: VendorsTrackerView
}) {
  track('Lien Waiver Tracker Type Changed', props)
}
export function trackLienWaiverDetailPageNavigation(props: { projectId: string }) {
  track('Navigate to Lien Waivers Detail Page', props)
}
export function trackLienWaiverHomeProjectExpand(props: { projectId: string }) {
  track('Expand lien waiver home project row', props)
}
export function trackLienWaiverHomeVendorExpand(props: { vendorId: string }) {
  track('Expand lien waiver home vendor row', props)
}
export function trackLienWaiverRequestDelete(props: {
  vendorId: string
  vendorName: string
  lowerTierToVendorName?: string
  lowerTierLienWaiverType: LienWaiverType
  lowerTierLienWaiverStatus: LegalDocumentStatus
}) {
  track('Lower-Tier Lien Waiver Request Deleted', props)
}
export function trackLienWaiverCreated(props: {
  vendorId?: string
  batchCreateVendorNames?: string[]
  contractId: string
  month: string
  lienWaiverType: LienWaiverType
  createType: 'single' | 'batch'
  createMethod?: 'manual' | 'ERP' | 'excel'
}) {
  track('Lower-Tier Lien Waiver Created', props)
}
type LegalDocumentRecipient = {
  fullName: string
  vendorContactEmail: string
}
type SingleLienWaiverRequestProps = {
  requestType: 'single'
  recipients?: LegalDocumentRecipient[]
  lienWaiverId?: string
  vendorName?: string
  lienWaiverType: LienWaiverType
  lowerTierToVendorName?: string
  isJointCheck: boolean
  defaultAmount?: number
  defaultAmountText?: string
  defaultExceptionAmounts?: string
  defaultExceptionDates?: string
  message?: string
}
type BatchRequestProps = {
  requestType: 'batch'
  lienWaiverIds?: string[]
}
export function trackLienWaiverRequested(
  props: {
    requestorName: string
    requestorEmail: string
    projectName: string
    subCompanyName: string
  } & (SingleLienWaiverRequestProps | BatchRequestProps)
) {
  track('Lower-Tier Lien Waiver Requested', props, true)
}
export function trackLowerTierLienWaiverDownloaded(props: {
  projectId: string
  vendorName: string
  lowerTierToVendorName?: string
  lowerTierLienWaiverType: LienWaiverType
}) {
  track('Completed Lower-Tier Lien Waiver Downloaded', props)
}
export function trackLowerTierLienWaiverUploaded(props: {
  projectId: string
  vendorName: string
  lowerTierToVendorName?: string
  lowerTierLienWaiverType: LienWaiverType
}) {
  track('Uploaded Lower-Tier Lien Waiver File', props)
}
export function trackLowerTierLienWaiversSubmitted(props: {
  fullName: string
  email: string
  projectId: string
  projectName: string
  vendorNames: string[]
  lowerTierToVendorNames: string[]
  contactEmails: string[]
  numSubmitted: number
  message?: string
}) {
  track('Submitted Lower-Tier Lien Waivers', props, true)
}
export function trackPayAppRecipientsAddedAsVendorLienWaiverRecipients(props: {
  contractId: string
  projectName: string
  payAppRecipientNames: string[]
}) {
  track('Use Pay App Recipients Clicked', props)
}
export function trackVendorSubmitToAdded(props: {
  contractId: string
  projectName: string
  recipientName: string
  location: 'vendors-setup' | 'vendors-settings' | string
}) {
  track('Added GC Recipient For Vendor Materials', props)
}

/**
 * ------------------------ TAX GROUPS ------------------------
 */
export function trackCreateTaxGroup(props: {
  taxGroupName: string
  taxRatePercent: number
  location: AddTaxRateLocation
}) {
  track('Tax Group Created', props)
}
export function trackEditTaxGroup(props: {
  taxGroupId: string
  newTaxGroupName: string
  newTaxRatePercent: number
  newIntegrations: string[]
}) {
  track('Tax Group Edited', props)
}
export function trackUpdateTaxCalculationType(props: {
  contractId: string
  projectName: string
  fromTaxCalculationType: TaxCalculationType
  toTaxCalculationType: TaxCalculationType
}) {
  track('Update Project Tax Calculation Type', props)
}

/**
 * ------------------------ LEGAL_DOCUMENT ------------------------
 */
export function trackLegalRequirementDownloaded(props: {
  projectId: string
  projectName: string
  itemNames: string[]
  date: string
}) {
  track('Compliance Downloaded', props)
}
export function trackLegalRequirementSubmitted(props: {
  complianceItems: number
  projectId: string
  projectName: string
  userName: string
  companyId: string | undefined
  companyName: string | undefined
  company: string | undefined
  formTemplateIds: string[]
  formTemplateNames: string[]
  generalContractorCompany: string[]
  generalContractorRecipientName: string[]
  generalContractorRecipientEmail: string[]
  message?: string
}) {
  track('Compliance Submitted', props, true)
}
export function trackStartLegalRequirementForm(props: {
  companyId: string | undefined
  companyName: string | undefined
  userName: string
  projectId: string
  projectName: string
  formTemplateId: string | undefined
  formTemplateName: string | undefined
}) {
  track('Started Compliance Form', props)
}
export function trackLegalDocumentUpdated(props: {
  projectId: string
  projectName: string
  itemName: string
  fileName: string
  fileType: string
  oldPeriodStart: string | null | undefined
  newPeriodStart: string | undefined
  oldPeriodEnd: string | null | undefined
  newPeriodEnd: string
}) {
  track('Compliance Item Updated', props)
}
export function trackComplianceLegalRequirementAdded(props: {
  projectId: string
  projectName: string
  requirementName: string
  quickAddType: ComplianceRequirementQuickAddType | null
  frequency: string
  startDate: string | null
  uploadedTemplate: boolean
  location: 'onboarding' | 'requirement-home'
}) {
  track('Compliance Legal Requirement Added', props)
}
export function trackComplianceLegalRequirementUpdated(props: {
  projectId: string
  projectName: string
  requirementName: string
  frequency: string
  startDate: string | null
  uploadedNewTemplate: boolean
  deletedTemplate: boolean
  location: 'onboarding' | 'requirement-home'
}) {
  track('Compliance Legal Requirement Updated', props)
}
export function trackLegalRequirementBulkUpload(props: {
  projectId: string
  projectName: string
  itemNames: string
  numFiles: number
}) {
  track('Compliance Bulk Uploaded', props)
}
export function trackVendorDocumentsTabViewed(props: { vendorId: string }) {
  track('Vendor Documents Tab Viewed', props)
}
export function trackVendorProjectDocumentsTabViewed(props: {
  projectId: string
  projectName: string
  requirementName: string
  requirementType: string
}) {
  track('Vendor Project Documents Tab Viewed', props)
}
export function trackVendorLegalDocumentRequested(props: {
  requestorName: string
  requestorEmail: string
  projectId: string
  projectName: string
  subCompanyName: string
  vendorName: string
  lowerTierToVendorName?: string
  requirementType: string
  requirementName: string
  recipients: LegalDocumentRecipient[]
  message?: string
}) {
  track('Vendor Legal Document Requested', props, true)
}
export function trackVendorLegalDocumentUploaded(props: {
  projectId: string
  projectName: string
  vendorName: string
  lowerTierToVendorName?: string
  requirementType: string
  requirementName: string
}) {
  track('Vendor Legal Document Uploaded File', props)
}
export function trackVendorLegalDocumentCompleted(props: {
  projectId: string
  projectName: string
  subCompanyName: string
  vendorName: string
  lowerTierToVendorName?: string
  vendorContactEmail: string
  requirementType: string
  requirementName: string
}) {
  track('Vendor Legal Document Completed', props, true)
}
export function trackVendorLegalRequirementPeriodSkipped(props: {
  projectId: string
  projectName: string
  vendorName: string
  lowerTierToVendorName?: string
  requirementType: string
  requirementName: string
}) {
  track('Vendor Legal Requirement Period Skipped', props)
}
export function trackVendorLegalDocumentsDownloaded(props: {
  projectId: string
  projectName: string
  itemNames: string[]
}) {
  track('Vendor Legal Documents Downloaded', props)
}
export function trackVendorLegalDocumentsSubmitted(props: {
  fullName: string
  email: string
  documentIds: string[]
  numDocuments: number
  vendorNames: string[]
  lowerTierToVendorNames: string[]
  projectId: string
  projectName: string
  requirementTypes: string[]
  requirementNames: string[]
  emails: string[]
  message?: string
}) {
  track('Vendor Legal Documents Submitted', props, true)
}
export function trackVendorRemovedFromProject(props: {
  projectId: string
  vendorId: string
  vendorName: string
  lowerTierToVendorName?: string
  location: 'vendors-setup' | 'vendors-settings'
}) {
  track('Vendor Removed From Project', props)
}
export function trackVendorLegalRequirementAdded(props: {
  projectId: string
  projectName: string
  requirementType: string
  requirementName: string
  email: string
  fullName: string
  location: 'vendors-setup' | 'vendors-settings'
}) {
  track('Vendor Legal Requirement Added', props, true)
}
export function trackVendorLegalRequirementDeleted(props: {
  projectId: string
  projectName: string
  requirementType: string
  requirementName: string
  location: 'vendors-setup' | 'vendors-settings'
}) {
  track('Vendor Legal Requirement Deleted', props)
}

/**
 * ---------------------- VENDOR ------------------------
 */
export function trackVendorNameChanged(props: { fromName: string; toName: string }) {
  track('Vendor Name Changed', props)
}
export function trackVendorContactRemoved(props: {
  vendorName: string
  contactName: string
  contactEmail: string
}) {
  track('Vendor Contact Removed', props)
}
export function trackVendorOnboardingStep(props: {
  fromStep: VendorProjectOnboardingStep | null
  toStep: VendorProjectOnboardingStep | null
  contractId: string
  projectName: string
}) {
  track('Vendors Project Onboarding Step Updated', props)
}
export function trackVendorOnboardingCompletion(props: {
  onboardingStatus: OnboardedProjectVendorsStatus
  contractId: string
  projectName: string
}) {
  track('Vendors Project Onboarding Completed', props)
}
export function trackVendorsPageViewLienWaiverType(props: {
  lienWaiverType: LienWaiverCategory | 'ALL'
}) {
  track('Vendors Tracker View Lien Waiver Type', props)
}
export function trackVendorsPageLeadPMFilter(props: { pmIds: string[] | null }) {
  track('Vendors Tracker PM Filter Applied', props)
}
export function trackVendorsPageGcFilter(props: { gcIds: string[] | null }) {
  track('Vendors Tracker GC Filter Applied', props)
}
export function trackVendorsProjectClick(props: {
  projectId: string
  location: string
  component?: 'vendorContractLienWaiversMonthCell' | 'nameCell'
}) {
  track('Vendors Project Clicked', props)
}
export function trackVendorClicked(props: {
  vendorId: string
  location: string
  component?: 'selectIntegrationInvoicesDialog'
}) {
  track('Vendor Clicked', props)
}
export function trackVendorsTrackerRowExpand(props: {
  vendorId?: string
  projectId?: string
  action: 'expand' | 'collapse'
}) {
  track('Vendors Tracker Row Toggled', props)
}
export function trackCreateCommitment(props: {
  projectId: string
  vendorName: string
  commitmentType: VendorContractCommitmentType
  amount: number
  date: string
  number: string
  terms?: number
  payWhenPaid: boolean
  withAttachment: boolean
  location: 'billing' | 'vendors'
}) {
  track('Commitment Created', props)
}
export function trackUpdateCommitment(props: {
  commitmentId: string
  projectId: string
  commitmentType?: VendorContractCommitmentType
  amount?: number
  date?: string
  number?: string
  terms?: number | null
  payWhenPaid?: boolean
  updatedAttachment: boolean
  location: 'billing' | 'vendors'
}) {
  track('Commitment Updated', props)
}
export function trackCommitmentStatusUpdated(props: {
  commitmentId: string
  status: 'open' | 'closed'
}) {
  track('Commitment Status Updated', props)
}
export function trackCommitmentDeleted(props: {
  commitmentId: string
  location: 'billing' | 'vendors'
}) {
  track('Commitment Deleted', props)
}

/**
 * ------------------------ INTEGRATIONS ------------------------
 */
export function trackActionButtonClickedAfterIntegrationSync(props: {
  integrationName: string
  projectId: string
  buttonAction: string
  payload: integrationTypes.WriteSyncPayload
  projectName?: string
}) {
  track('Action Button Clicked After Integration Sync', props)
}

export function trackIntegrationSyncDialogReset(props: {
  projectId: string
  payAppId: string
  integrationLongName: string
}) {
  track(`${props.integrationLongName} Dialog Reset`, props)
}

export function trackIntegrationSyncAutoDistribute(props: {
  projectId: string
  payAppId: string
  integrationLongName: string
  type: 'LINE_ITEMS' | 'FREEFORM'
}) {
  track(`${props.integrationLongName} Auto Distribute`, props)
}

export function trackIntegrationSyncEnterManually(props: {
  projectId: string
  payAppId: string
  integrationLongName: string
}) {
  track(`${props.integrationLongName} Enter Manually`, props)
}
export function trackOpenCalculateLienWaiverAmountFromErp(props: {
  projectId: string
  projectName: string
  lienWaiverId: string
  integrationName: string
}) {
  track('Open Calculate Lien Waiver Amount from ERP', props)
}
export function trackAdjustCalculateLienWaiverAmountFromErpDate(props: {
  projectId: string
  projectName: string
  lienWaiverId: string
  integrationName: string
  dateRange: string
}) {
  track('Adjust Date Range For Lien Waiver Amount Calculation from ERP', props)
}
export function trackSelectCalculateLienWaiverAmountItemFromErp(props: {
  projectId: string
  projectName: string
  lienWaiverId: string
  integrationName: string
  integrationInvoiceId: string
  isChecked: boolean
}) {
  track('Toggle Item In Lien Waiver Amount Calculation from ERP', props)
}
export function trackUpdateLienWaiverAmountFromErp(props: {
  projectId: string
  projectName: string
  lienWaiverId: string
}) {
  track('Update Lien Waiver Amount from ERP', props)
}
export function trackCancelCalculateLienWaiverAmountFromErp(props: {
  projectId: string
  projectName: string
  lienWaiverId: string
}) {
  track('Cancel Calculate Lien Waiver Amount from ERP', props)
}

/**
 * ------------------------ PDFs ------------------------
 */
export type AnnotationMetricsType =
  | 'required'
  | 'optional'
  | 'notary'
  | 'signature'
  | 'wet'
  | 'witness'
export function trackAnnotationArrowClicked(props: { type: AnnotationMetricsType }) {
  track('PDF Annotation Arrow Clicked', props)
}

export function trackPdfThumbnailClicked(props: {
  pageNumber: number
  userVisibleName?: string
  payAppId?: string
  lienWaiverId?: string
}) {
  track('PDF Thumbnail Clicked', props)
}

export function trackPdfTabForward() {
  track('PDF Tab Forward')
}

export function trackPdfTabBackward() {
  track('PDF Tab Backward')
}

export function trackPdfScrollToAnnotation(props: { projectId: string }) {
  track('PDF Scroll To Annotation', props)
}

/**
 * ------------------------ Reporting/ Dashboards ------------------------
 */
export function trackReportingTabClick(props: { tab: ReportingTab }) {
  track('Reporting Subtab Click', props)
}
export function trackDashboardDateFilter(props: {
  tab: ReportingTab
  month: number
  year: number
}) {
  // Offset month by 1 because programmatically, months start at 0
  track('Reporting Page Date Filter Applied', { ...props, month: props.month + 1 })
}
export function trackDashboardProjectSearch(props: { tab: ReportingTab; search: string }) {
  track('Reporting Page Searched', props)
}
export function trackDashboardOfficeFilter(props: {
  tab: ReportingTab
  officeIds: string[] | null
}) {
  track('Reporting Page Office Filter Applied', props)
}
export function trackDashboardGcFilter(props: { tab: ReportingTab; gcIds: string[] | null }) {
  track('Reporting Page GC Filter Applied', props)
}
export function trackDashboardLeadPMFilter(props: { tab: ReportingTab; PMIds: string[] | null }) {
  track('Reporting Page Lead PM Filter Applied', props)
}
export function trackDashboardProjectTypeFilter(props: {
  tab: ReportingTab
  projectTypes: BillingType[] | null
}) {
  track('Reporting Page Project Type Filter Applied', props)
}
export function trackDashboardAgingAmountTypeFilter(props: {
  tab: ReportingTab
  agingAmountType: AgingAmountType | null
}) {
  track('Reporting Page Aging Amount Type Filter Applied', props)
}
export function trackDashboardProjectStatusFilter(props: {
  tab: ReportingTab
  projectStatuses: ContractMonthlyStatusFilter[] | null
}) {
  track('Reporting Page Project Status Filter Applied', props)
}
export function trackAgingDashboardAmountPeriodChanged(props: { days: AmountAgedPeriod }) {
  track('Reporting Page Updated Total Days Aging Card', props)
}
export function trackAgingSidesheetOpened() {
  track('Reporting Page Aging Sidesheet Opened')
}
export function trackAgingSidesheetSwitchProject(props: { direction: 'next' | 'previous' }) {
  track('Reporting Page Aging Sidesheet Switch Project', props)
}
export function trackAgingSidesheetPayAppClick(props: {
  projectId: string
  projectName: string
  payAppId: string
  isPreSitelinePayApp: boolean
}) {
  track('Reporting Page Aging Sidesheet Pay App Clicked', props)
}
export function trackAgingSidesheetShowGcContacts(props: {
  projectId: string
  projectName: string
  numContacts: number
}) {
  track('Reporting Page Aging Sidesheet Show GC Contacts', props)
}
export function trackAgingSidesheetHideGcContacts(props: {
  projectId: string
  projectName: string
  numContacts: number
}) {
  track('Reporting Page Aging Sidesheet Hide GC Contacts', props)
}
export function trackDashboardToggleIncludeDrafts(props: {
  tab: ReportingTab
  includeDraftBilling: boolean
}) {
  track('Reporting Page Toggle Include Draft Billings', props)
}
export function trackDashboardPeriodChange(props: { periodType: CashForecastPeriodType }) {
  track('Reporting Page Cash Forecast Period Updated', props)
}
export function trackDashboardDownloadPdfReport(props: { tab: ReportingTab }) {
  track('Reporting Page PDF Download Report Clicked', props)
}
export function trackOpenBulkFoundationExport() {
  track('Foundation Bulk Export Dialog Opened')
}
export function trackOpenBulkQuickbooksExport() {
  track('Quickbooks Bulk Export Dialog Opened')
}
export function trackOpenBulkComputerEaseExport() {
  track('Computer Ease Bulk Export Dialog Opened')
}
export function trackReportingPageExcelExport(props: {
  tab: ReportingTab | 'pendingCoSummary'
  granularity: 'contract' | 'lineItems'
}) {
  track('Reporting Page Export Excel Clicked', props)
}
export function trackBulkExportFoundationInvoices(props: { numInvoices: number }) {
  track('Bulk Export Invoices to Foundation', props)
}
export function trackBulkExportQuickbooksInvoices(props: { numInvoices: number }) {
  track('Bulk Export Invoices to Quickbooks Enterprise', props)
}
export function trackBulkExportComputerEaseInvoices(props: { numInvoices: number }) {
  track('Bulk Export Invoices to Computer Ease', props)
}
export function trackDashboardDownloadPrintPreviewPdf(props: { tab: ReportingTab }) {
  track('Reporting Page PDF Download Current Clicked', props)
}
export function trackDashboardCardNavigate(props: {
  cardClicked: ReportingOverviewCard
  navigateTo?: string
}) {
  track('Reporting Page Overview Card Link Clicked', props)
}
export function trackDashboardBillingTableRowClicked(props: { navigateTo: string }) {
  track('Reporting Page Billing Table Row Clicked', props)
}
export function trackDashboardAgingTableRowClicked(props: { navigateTo: string }) {
  track('Reporting Page Aging Table Row Clicked', props)
}
export function trackDashboardCashForecastingTableRowClicked(props: {
  projectId: string
  projectName: string
}) {
  track('Reporting Project Drilldown Clicked', props)
}
export function trackDashboardSearchQueryCleared() {
  track('Reporting Page Search Cleared From No Results Page')
}
export function trackDashboardFiltersCleared() {
  track('Reporting Page Filters Cleared From No Results Page')
}
export function trackDashboardEmptyLienWaiversNavigate(props: {
  emptyLienWaiversType: 'allSigned' | 'noneRequested'
}) {
  track('Reporting Page Empty Lien Waivers Card Tracker Link Clicked', props)
}
export function trackDashboardHelpClicked(props: { tab: ReportingTab }) {
  track('Reporting Page Help Link Clicked', props)
}
export function trackDownloadCashFlowForecast(props: { projectId: string }) {
  track('Downloaded Cash Flow Forecast', props)
}
export function trackReportingToPayAppNavigation(props: {
  payAppId?: string
  projectId: string
  projectName: string
  isQuickBill: boolean
  fromLocation: string
  isPreSiteline: boolean
}) {
  track('Reporting to Pay App Navigation', props)
}

/**
 * ------------------------ Billing Projections ------------------------
 */
export function trackCreateForecastClicked(props: {
  projectId: string
  location: 'projectForecast' | 'payAppList' | 'reporting'
}) {
  track('Create Forecast Button Clicked', props)
}
export function trackProjectForecastNavigation(props: {
  projectId: string
  navigateFrom: 'payAppListLink' | 'reportingLink' | 'invoice'
}) {
  track('Forecast Tab Opened', props)
}
export function trackRemainderAllocation(props: {
  projectId: string
  remainder: string
  allocationType: 'nextMonth' | 'lastMonth' | 'distribute' | 'presetCurve'
}) {
  track('Forecast Remainder Distribution Selected', props)
}
export function trackApplyPresetCurveClick(props: {
  projectId: string
  curve: CreateForecastCurveOption
}) {
  track('Apply Preset Curve Button Clicked', props)
}
export function trackForecastHelpArticleClick(props: { projectId: string }) {
  track('Forecast Help Center Link Clicked', props)
}
export function trackForecastToBillingAdjustment(props: {
  projectId: string
  adjustmentType: 'shift' | 'preserve'
}) {
  track('Forecast Start Date Adjusted To Billing Start Date', props)
}
export function trackForecastEditCancelation() {
  track('Forecast Edits Canceled')
}
export function trackForecastEditReset() {
  track('Forecast Edits Reset')
}
export function trackForecastSovToggle(props: { visible: boolean; projectId: string }) {
  track('Forecast SOV Toggled', props)
}
export function trackForecastSave(props: {
  projectId: string
  dragged: boolean
  editedSpreadsheet: boolean
}) {
  track('Project Forecast Edited - Frontend', props)
}
export function trackToggledForecastAmountType(props: {
  projectId: string
  amountType: ForecastAmountType
}) {
  track('Toggled Billing Forecast Amount Type', props)
}

/**
 * ------------------------ Project onboarding status page ------------------------
 */
export function trackOpenOnboardingStatusPage() {
  track('Project Onboarding Status Page Viewed')
}
export function trackClickedOnboardingStatusPageProject(props: { projectId: string }) {
  track('Clicked Onboarding Status Page Project', props)
}
export function trackOpenedOnboardingStatusPageHelpArticle() {
  track('Opened Onboarding Status Page Help Center Article')
}

/**
 * ------------------------ Fully billed projects page ------------------------
 */
export function trackOpenFullyBilledProjectsPage() {
  track('Fully Billed Projects Page Viewed')
}
export function trackClickedBillForRetentionOnFullyBilled(props: { projectId: string }) {
  track('Clicked Bill For Retention On Fully Billed Projects Page', props)
}
export function trackClickedArchiveOnFullyBilled(props: { projectId: string }) {
  track('Clicked Archive Project On Fully Billed Projects Page', props)
}

/**
 * ------------------------ Rate tables ------------------------
 */
export function trackCreatedRateTable(props: {
  name: string
  id: string
  effectiveDate?: string
  expirationDate?: string
  createMethod: 'manual' | 'erp' | 'excel'
  location: string
}) {
  track('Created Rate Table', props)
}
export function trackDeletedRateTable(props: {
  name: string
  id: string
  location: 'rateTableList' | 'rateTableHomePage'
}) {
  track('Deleted Rate Table', props)
}
export function trackDuplicatedRateTable(props: {
  originalRateTableName: string
  originalRateTableId: string
  newRateTableId: string
  location:
    | 'rateTableList'
    | 'rateTableHomePage'
    | 'projectOnboarding'
    | 'projectHome'
    | 'changeOrderLog'
    | 'changeOrderDetails'
}) {
  track('Duplicated Rate Table', props)
}
export function trackRateTableArchivedStatus(props: {
  name: string
  id: string
  actionTaken: 'archived' | 'unarchived'
  location: 'rateTableList' | 'rateTableHomePage'
  eventTarget: 'menu' | 'archivedBanner' | 'deleteConfirmation'
}) {
  track('Rate Table Archive Status', props)
}
export function trackExportedRateTable(props: { name: string; id: string }) {
  track('Exported Rate Table', props)
}
export function trackUpdatedRateTable(props: { name: string; id: string }) {
  track('Updated Rate Table', props)
}
export function trackRateTableLibraryPageView() {
  track('Rate Table Library Page Viewed')
}
export function trackRateTableDetailsPageView(props: {
  rateTableId: string
  rateTableName: string
  previousLocation: string
}) {
  track('Rate Table Details Page Viewed', props)
}

/**
 * ------------------------ CHANGE ORDER REQUESTS ------------------------
 */
type ChangeOrderRequestForTracking = {
  id: string
  internalNumber: string | null
  name: string
}
export function trackChangeOrderRequestLinkedSovLineItems(props: {
  companyId: string
  companyName: string
  projectId: string
  projectName: string
  location: 'addToSovPage' | 'changeOrderDetailsPage'
  changeOrderRequest: ChangeOrderRequestForTracking
  previousSovLineItemIds: string[]
  newSovLineItemIds: string[]
  amount: number
}) {
  track('Change Order Request Linked to SOV', props)
}
export function trackChangeOrderRequestLinkedToChangeOrderRequest(props: {
  companyId: string
  companyName: string
  projectId: string
  projectName: string
  location: 'addToSovPage' | 'changeOrderDetailsPage'
  viewingChangeOrderRequest: ChangeOrderRequestForTracking
  previouslyLinkedChangeOrderRequestIds: string[]
  updatedChangeOrderRequestIds: string[]
}) {
  track('Change Order Request Linked Change Order Requests Updated', props)
}
export function trackChangeOrderLogExported(props: {
  exportType: 'pdf' | 'excel'
  includeInternalOnly: boolean
  companyId: string
  projectId: string
  projectName: string
}) {
  track('Exported Change Order Log', props)
}
export function trackChangeOrderRequestVisibleToGcUpdated(props: {
  changeOrderRequestId: string | null
  projectId: string
  projectName: string
  visibleToGc: boolean
}) {
  track('Updated Change Order Request Visible To GC', props)
}
export function trackIncludeChangeOrderLogOnPayAppsUpdated(props: {
  companyId: string
  projectId: string
  projectName: string
  shouldInclude: boolean
}) {
  track('Project Settings Include Change Order Log On Pay Apps Updated', props)
}
export function trackChangeOrderRequestRateTableReset(props: {
  projectId: string
  projectName: string
  reason: 'swapped' | 'updated'
  changeOrderRequestId: string
}) {
  track('Reset Change Order Request Rate Table', props)
}
export function trackChangeOrderRequestFeesReset(props: {
  projectId: string
  projectName: string
  changeOrderRequestId: string
}) {
  track('Reset Change Order Request Fees', props)
}
export function trackExportedPricingTool(props: {
  projectId: string
  projectName: string
  changeOrderRequestId: string
}) {
  track('Exported Change Order Request Pricing Tool', props)
}
export function trackCorClearPricesClick(props: {
  projectId: string
  projectName: string
  changeOrderRequestId: string
  previousAmount: number | null
  /**
   * This event covers the initial "clear amount" click and the
   * "confirm" click via confirmation dialog
   */
  confirmed: boolean
}) {
  track('Cleared Change Order Request Pricing Tool Amount', props)
}
export function trackViewOrEditPricingToolClick(props: {
  projectId: string
  projectName: string
  changeOrderRequestId: string
  rateTableId: string | null
  previousPricingToolAmount: number | null
  previousManuallyEnteredAmount: number | null
  buttonType: 'view' | 'edit'
}) {
  track('Go to Pricing Tool Clicked', props)
}
export function trackCorPricingToolFilter(props: {
  projectId: string
  changeOrderRequestId: string
  filterBy: PricingToolFilterType
}) {
  track('COR Pricing Tool Line Items Filtered', props)
}
export function trackAddedLineItemToCorPricingTool(props: {
  projectId: string
  projectName: string
  changeOrderRequestId: string
  groupName: string
  lineItem: CreateChangeOrderRequestRateTableItemInput
}) {
  track('Added Line Item to COR Pricing Tool', props)
}
export function trackEditedCorPricingToolLineItem(props: {
  projectId: string
  projectName: string
  changeOrderRequestId: string
  groupName: string
  lineItem: UpdateChangeOrderRequestRateTableItemInput
}) {
  track('Edited COR Pricing Tool Line Item', props)
}

/**
 * ------------------------ OTHER ------------------------
 */

export function trackProjectHomeSearchBarUsed(props: { search: string }) {
  track('Project Home Search Bar Used', props)
}
