import { NavigateOptions, RegisteredRouter, RouterState } from '@tanstack/react-router'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { BillingType, LienWaiverProgressType, OPT_OUT_FORMAT } from 'siteline-common-all'
import { ContractListContractStatusFilter } from 'siteline-common-web'
import { useLocalStorage } from '../../common/util/SafeLocalStorage'
import { ProjectHomeSearch } from '../../routes/_a.billing.$projectId._p.$tab'
import { ProjectListContractStatusFilter } from '../project-home/ProjectListSwitcher'
import { BillingSettingsSection } from './settings/BillingSettings'

export enum SovOnboardingStep {
  IMPORT = 'import',
  EDIT = 'edit',
}

export enum BillingTab {
  PAY_APPS = 'payApps',
  SETTINGS = 'settings',
  SETUP = 'setup',
  SOV = 'sov',
  FORECAST = 'forecast',
  CHANGE_ORDER_LOG = 'changeOrderLog',
}

export enum PayAppTab {
  OVERVIEW = 'overview',
  INVOICE = 'invoice',
  BACKUP = 'backup',
  FORMS = 'forms',
  SETTINGS = 'settings',
}

export enum BillingPathType {
  Home,
  OnboardingProjects,
  BulkImportProjects,
  BulkImportOverUnderBilling,
  NewProject,
  ProjectBilling,
  OptedOutMonthUnconditional,
  ProjectSovOnboarding,
  PayApp,
  PayAppUnconditional,
  PreSitelinePayApp,
  ImportWorksheet,
}

const DEFAULT_BILLING_TAB = BillingTab.PAY_APPS
export const DEFAULT_PAY_APP_TAB = PayAppTab.OVERVIEW
export const BILLING_TAB_PADDING = 20

export const PRE_SITELINE_PAY_APPS_LINK_ID = 'pre-siteline-pay-apps'

type GetBillingPathParams =
  | {
      pathType: BillingPathType.Home
      statusFilter?: ProjectListContractStatusFilter
    }
  | { pathType: BillingPathType.BulkImportProjects }
  | { pathType: BillingPathType.BulkImportOverUnderBilling }
  | { pathType: BillingPathType.NewProject }
  | {
      pathType: BillingPathType.ProjectBilling
      projectId: string
      billingTab?:
        | BillingTab.SETUP
        | BillingTab.SOV
        | BillingTab.CHANGE_ORDER_LOG
        | BillingTab.FORECAST
    }
  | {
      pathType: BillingPathType.ProjectBilling
      projectId: string
      billingTab: BillingTab.SETTINGS
      scrollTo?: BillingSettingsSection
    }
  | {
      pathType: BillingPathType.ProjectBilling
      projectId: string
      billingTab: BillingTab.PAY_APPS
      scrollToPreSitelinePayApps?: boolean
    }
  | {
      pathType: BillingPathType.OptedOutMonthUnconditional
      projectId: string
      month: moment.Moment
    }
  | {
      pathType: BillingPathType.ProjectSovOnboarding
      projectId: string
      sovOnboardingStep: SovOnboardingStep
    }
  | {
      pathType: BillingPathType.ImportWorksheet
      projectId: string
    }
  | {
      pathType: BillingPathType.PayApp
      projectId: string
      payAppId: string
      payAppTab?: PayAppTab | 'lienWaivers'
    }
  | {
      pathType: BillingPathType.PayAppUnconditional
      projectId: string
      payAppId: string
      payAppTab: 'unconditional'
      progressType?: LienWaiverProgressType
    }
  | {
      pathType: BillingPathType.PreSitelinePayApp
      projectId: string
      payAppId: string
      payAppTab: 'unconditional'
    }

/** Returns navigation options to use in navigate() / <Navigate />, for a specific billing page */
export function getBillingPath(params: GetBillingPathParams): NavigateOptions {
  switch (params.pathType) {
    case BillingPathType.Home: {
      if (!params.statusFilter || params.statusFilter === ContractListContractStatusFilter.ACTIVE) {
        return { to: '/' }
      }
      return { to: '/', search: { type: params.statusFilter } }
    }
    case BillingPathType.BulkImportProjects:
      return { to: '/billing/bulkImport' }
    case BillingPathType.BulkImportOverUnderBilling:
      return { to: '/billing/bulkCosts' }
    case BillingPathType.NewProject:
      return { to: '/billing/new' }
    case BillingPathType.ProjectBilling: {
      let search: ProjectHomeSearch | undefined = undefined
      if (params.billingTab === BillingTab.PAY_APPS && params.scrollToPreSitelinePayApps) {
        search = { section: PRE_SITELINE_PAY_APPS_LINK_ID }
      }
      if (params.billingTab === BillingTab.SETTINGS && params.scrollTo) {
        search = { section: params.scrollTo }
      }
      return {
        to: '/billing/$projectId/$tab',
        params: {
          projectId: params.projectId,
          tab: params.billingTab ?? DEFAULT_BILLING_TAB,
        },
        search,
      }
    }
    case BillingPathType.ImportWorksheet:
      return {
        to: '/billing/$projectId/importWorksheet',
        params: { projectId: params.projectId },
      }
    case BillingPathType.OptedOutMonthUnconditional:
      return {
        to: '/billing/$projectId/unconditional/$date',
        params: { projectId: params.projectId, date: params.month.format(OPT_OUT_FORMAT) },
      }
    case BillingPathType.ProjectSovOnboarding:
      return {
        to: '/billing/$projectId/newSov',
        search: { step: params.sovOnboardingStep },
      }
    case BillingPathType.PayApp:
      return {
        to: '/billing/$projectId/payApps/$payAppId/$tab',
        params: {
          projectId: params.projectId,
          payAppId: params.payAppId,
          tab: params.payAppTab || DEFAULT_PAY_APP_TAB,
        },
      }
    case BillingPathType.PayAppUnconditional: {
      return {
        to: '/billing/$projectId/payApps/$payAppId/unconditional',
        params: {
          projectId: params.projectId,
          payAppId: params.payAppId,
        },
        search: params.progressType ? { type: params.progressType } : undefined,
      }
    }
    case BillingPathType.PreSitelinePayApp:
      return {
        to: '/billing/$projectId/preSitelinePayApps/$payAppId/unconditional',
        params: {
          projectId: params.projectId,
          payAppId: params.payAppId,
        },
      }
  }
}

/**
 * Checks for:
 * 1) pathname matching "billing", and
 * 2) pathname missing "reporting" (ensures we're not in /reporting/billing)
 */
export function isBillingPath(location: RouterState<RegisteredRouter['routeTree']>['location']) {
  return (
    location.pathname.match(/billing/) !== null && location.pathname.match(/reporting/) === null
  )
}

type BaseChangeOrderPathParams = {
  projectId: string
}
type ChangeOrdersPathParams =
  | BaseChangeOrderPathParams
  | (BaseChangeOrderPathParams & { importChangeOrderLog: true })
  | (BaseChangeOrderPathParams & { viewForms: true })
  | (BaseChangeOrderPathParams & { changeOrderRequestId: string })
  | (BaseChangeOrderPathParams & { changeOrderRequestId: string; addToSov: true })
  | (BaseChangeOrderPathParams & { changeOrderRequestId: string; pricingTool: true })

/** Returns a complete URL to navigate into the change orders module */
export function getChangeOrdersPath(params: ChangeOrdersPathParams): NavigateOptions {
  if ('importChangeOrderLog' in params) {
    return {
      to: '/billing/$projectId/changeOrderLog/import',
      params: { projectId: params.projectId },
    }
  }

  if ('changeOrderRequestId' in params) {
    if ('pricingTool' in params) {
      return {
        to: '/billing/$projectId/changeOrderLog/$changeOrderRequestId/pricing',
        params: { projectId: params.projectId, changeOrderRequestId: params.changeOrderRequestId },
      }
    }

    if ('addToSov' in params) {
      return {
        to: '/billing/$projectId/changeOrderLog/$changeOrderRequestId/sov',
        params: { projectId: params.projectId, changeOrderRequestId: params.changeOrderRequestId },
      }
    }

    if ('viewForms' in params) {
      return {
        to: '/billing/$projectId/changeOrderLog/$changeOrderRequestId/forms',
        params: { projectId: params.projectId, changeOrderRequestId: params.changeOrderRequestId },
      }
    }

    return {
      to: '/billing/$projectId/changeOrderLog/$changeOrderRequestId',
      params: { projectId: params.projectId, changeOrderRequestId: params.changeOrderRequestId },
    }
  }

  return {
    to: '/billing/$projectId/$tab',
    params: { projectId: params.projectId, tab: BillingTab.CHANGE_ORDER_LOG },
  }
}

interface UseBillingFiltersStoreParams<T> {
  storageId: string
  defaultValue: T
}

/**
 * Hook encapsulates interaction with local storage and exposes the same getters
 * and setters as useState. This allows us to easily store selected filters on the
 * Billing Page, which is applicable to users who only deal with/care about
 * subsets of data within the project list
 */
export function useBillingFiltersStore<T>({
  storageId,
  defaultValue,
}: UseBillingFiltersStoreParams<T>): [T, (update: T) => void] {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    storageId,
    JSON.stringify(defaultValue)
  )

  return useMemo(() => {
    const value = JSON.parse(localStorageValue) as T
    const setValue = (update: T) => {
      setLocalStorageValue(JSON.stringify(update))
    }
    return [value, setValue]
  }, [localStorageValue, setLocalStorageValue])
}

export const NON_QUICK_BILL_BILLING_TYPES = [
  BillingType.LUMP_SUM,
  BillingType.UNIT_PRICE,
  BillingType.TIME_AND_MATERIALS,
]
