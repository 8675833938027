import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Autocomplete, Link, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  IntegrationTypeFamily,
  getIntegrationTypeFamily,
  isHh2Integration,
  normalizeString,
  supportsWriteSync,
} from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { config } from '../../../common/config/constants'
import {
  IntegrationProjectSummaryProperties,
  IntegrationType,
} from '../../../common/graphql/apollo-operations'
import { CompanyIntegrationForCreateProject } from './CreateProjectDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    height: 14,
    width: 14,
    marginRight: theme.spacing(0.5),
    color: colors.grey50,
  },
  description: {
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-wrap',
  },
  autocomplete: {
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  alreadyOnSiteline: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: colors.yellow10,
    border: `1px solid ${colors.yellow30}`,
    marginTop: theme.spacing(2),
    display: 'flex',
    '& .MuiSvgIcon-root': {
      color: colors.yellow40,
      fontSize: 20,
      marginRight: theme.spacing(1.5),
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '& .alreadyLinked': {
        marginBottom: theme.spacing(0.5),
      },
      '& > *': {
        fontWeight: 500,
      },
    },
  },
  procoreWarning: {
    marginTop: theme.spacing(2),
  },
}))

interface LinkIntegrationProjectProps {
  integration: CompanyIntegrationForCreateProject
  projects: IntegrationProjectSummaryProperties[]
  selectedProject: IntegrationProjectSummaryProperties | null
  onSelectedProjectChange: (project: IntegrationProjectSummaryProperties | null) => void
  loadingProjects: boolean
  hasImportError: boolean
  className?: string

  /** If project info is already imported from another integration, use different body text */
  useLimitedImportDescription?: boolean

  /** Remove the description altogether if we don't want to display it */
  hideDescription?: boolean

  /** Set if you want the <Autocomplete> to have the fullWidth property */
  fullWidth?: boolean
}

const i18nBase = 'projects.onboarding.project_info'

function areProjectsEqual(
  a: IntegrationProjectSummaryProperties,
  b: IntegrationProjectSummaryProperties
) {
  return (
    a.integrationProjectId === b.integrationProjectId &&
    a.integrationContractId === b.integrationContractId
  )
}

/** Interface for selecting a project to link from a third-party integration */
export function LinkIntegrationProject({
  integration,
  projects,
  selectedProject,
  onSelectedProjectChange,
  loadingProjects,
  hasImportError,
  className,
  useLimitedImportDescription,
  hideDescription,
  fullWidth,
}: LinkIntegrationProjectProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [onSiteline, notOnSiteline] = _.partition(projects, (project) =>
    _.isString(project.sitelineProjectId)
  )
  const projectOptions = useMemo(
    () =>
      [...notOnSiteline, ...onSiteline].map((project) => ({
        ...project,
        // The label is used for autocomplete, so include all fields that we want the user to be able to search on.
        // It won't be exposed to the user, since we use a custom rendering in the menu and override the value
        // of the text field.
        label: `${project.projectName} ${project.contractName ?? ''} ${
          project.generalContractorName ?? ''
        } ${project.projectNumber ?? ''}`,
      })),
    [onSiteline, notOnSiteline]
  )

  // If new projects are loaded and the selected project isn't in the list, de-select it
  useEffect(() => {
    if (
      selectedProject &&
      !projectOptions.some((option) => areProjectsEqual(option, selectedProject))
    ) {
      onSelectedProjectChange(null)
    }
  }, [selectedProject, projectOptions, onSelectedProjectChange])

  const integrationName = integration.shortName
  const supportsInvoiceSync =
    supportsWriteSync(integration.type, 'payApp') ||
    supportsWriteSync(integration.type, 'payAppLineItems')
  const showSageWarning = [
    IntegrationType.SAGE_100_CONTRACTOR,
    IntegrationType.SAGE_300_CRE,
  ].includes(integration.type)

  const isGcPortal = getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.GC_PORTAL
  const isHh2 = isHh2Integration(integration.type)

  const howToImport = useMemo(() => {
    if (isGcPortal) {
      return t(`${i18nBase}.how_to_import_gc_portal`, { integration: integrationName })
    }
    if (isHh2) {
      return t(`${i18nBase}.how_to_import_hh2`, { integration: integrationName })
    }
    return t(`${i18nBase}.how_to_import_erp`, { integration: integrationName })
  }, [integrationName, isGcPortal, isHh2, t])

  const noImportText = useMemo(() => {
    if (isGcPortal) {
      return t(`${i18nBase}.no_import_projects_gc_portal`, { integration: integrationName })
    }
    if (isHh2) {
      return t(`${i18nBase}.no_import_projects_hh2`, { integration: integrationName })
    }
    return t(`${i18nBase}.no_import_projects_erp`, { integration: integrationName })
  }, [integrationName, isGcPortal, isHh2, t])

  return (
    <div className={className}>
      {!hasImportError && (
        <>
          {loadingProjects && (
            <div className={classes.loading}>
              <SitelineText variant="body1" color="grey50">
                {t(`${i18nBase}.looking_for_projects`)}
              </SitelineText>
            </div>
          )}
          {!loadingProjects && (
            <>
              {projects.length === 0 && (
                <SitelineText variant="body1" color="grey50" className={classes.description}>
                  {noImportText}
                </SitelineText>
              )}
              {projects.length > 0 && (
                <div>
                  {!hideDescription && (
                    <SitelineText variant="body1" color="grey50" className={classes.description}>
                      {useLimitedImportDescription
                        ? t(`${i18nBase}.import_erp_body`, { integration: integrationName })
                        : t(`${i18nBase}.import_body`, { integration: integrationName })}
                      {supportsInvoiceSync &&
                        t(`${i18nBase}.sync_pay_apps`, { integration: integrationName })}
                      {showSageWarning && t(`${i18nBase}.sage_delay_warning`)}
                    </SitelineText>
                  )}
                  <Autocomplete
                    value={
                      projectOptions.find((option) =>
                        selectedProject ? areProjectsEqual(option, selectedProject) : false
                      ) ?? null
                    }
                    fullWidth={fullWidth}
                    freeSolo={false}
                    options={projectOptions}
                    autoHighlight
                    openOnFocus
                    onChange={(ev, option) => {
                      if (!option) {
                        onSelectedProjectChange(null)
                        return
                      }
                      onSelectedProjectChange(option)
                    }}
                    className={classes.autocomplete}
                    renderInput={(params) => {
                      // Need to override the value since otherwise it will use the label
                      // from the autocomplete option, which isn't meant to be readable
                      let value = (params.inputProps as { value: string | null }).value
                      const project = projectOptions.find((option) => option.label === value)
                      if (project) {
                        value = `${project.projectName}${
                          project.contractName &&
                          normalizeString(project.projectName) !==
                            normalizeString(project.contractName)
                            ? ` (${project.contractName})`
                            : ''
                        }`
                      }
                      return (
                        <TextField
                          variant="outlined"
                          placeholder={t(`${i18nBase}.search_projects`)}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            value,
                          }}
                        />
                      )
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        // Some integrations have multiple separate contracts per project ID
                        // Each contract becomes a separate siteline project
                        key={`${option.integrationProjectId}-${option.integrationContractId}`}
                      >
                        <SitelineText variant="secondary">
                          {option.projectName}
                          {option.contractName &&
                            normalizeString(option.projectName) !==
                              normalizeString(option.contractName) &&
                            ` (${option.contractName})`}
                          <SitelineText variant="secondary" color="grey50">
                            {option.generalContractorName && ` · ${option.generalContractorName}`}
                            {option.projectNumber && ` · ${option.projectNumber}`}
                          </SitelineText>
                        </SitelineText>
                      </li>
                    )}
                    getOptionLabel={(option) => option.label}
                    noOptionsText={<SitelineText variant="secondary">{howToImport}</SitelineText>}
                    isOptionEqualToValue={areProjectsEqual}
                    groupBy={(option) =>
                      _.isString(option.sitelineProjectId)
                        ? t(`${i18nBase}.already_on_siteline`)
                        : ''
                    }
                  />
                </div>
              )}
              {integration.type === IntegrationType.PROCORE && (
                <SitelineAlert severity="info" className={classes.procoreWarning}>
                  <Trans
                    i18nKey={`${i18nBase}.procore_app_warning`}
                    components={{
                      Link: (
                        <Link
                          href="https://support.siteline.com/hc/en-us/articles/24848411511316-Set-up-the-Procore-integration-for-your-company"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </SitelineAlert>
              )}
            </>
          )}
        </>
      )}
      {hasImportError && (
        <SitelineText variant="body1" color="grey50">
          {t(`${i18nBase}.integration_error`, {
            integration: integrationName,
            email: config.support.email,
          })}
        </SitelineText>
      )}
      {selectedProject && _.isString(selectedProject.sitelineProjectId) && (
        <div className={classes.alreadyOnSiteline}>
          <WarningAmberOutlinedIcon />
          <div>
            <SitelineText variant="secondary" bold color="grey90" className="alreadyLinked">
              {t(`${i18nBase}.already_linked`, { integration: integrationName })}
            </SitelineText>
            <SitelineText variant="secondary" color="yellow70">
              {t(`${i18nBase}.already_linked_details`, {
                integration: integrationName,
                projectName: selectedProject.projectName,
              })}
            </SitelineText>
          </div>
        </div>
      )}
    </div>
  )
}
