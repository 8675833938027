import CloseIcon from '@mui/icons-material/Close'
import { Grid, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'
import { pdfTypes } from 'siteline-common-all'
import { colors, generatePdfWithMetadata, makeStylesFast, useToggle } from 'siteline-common-web'
import { PdfPageMemoized } from '../../../common/components/Pdf/PdfPageMemoized'
import { PdfPreviewDialog } from '../../../common/components/Pdf/PdfPreviewDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { Z_INDEX } from '../../../common/themes/Main'
import { VariantAndVersion } from '../../../common/util/Forms'
import { Document } from '../../../pdf'
import { InlineFormsPreviewLoadingThumbnails } from './InlineFormsPreviewLoadingThumbnails'

const THUMBNAIL_PADDING = 8

const useStyles = makeStylesFast((theme: Theme) => ({
  thumbnailContainer: {
    position: 'relative',
  },
  thumbnail: {
    overflow: 'hidden',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${colors.grey20}`,
    height: 160,
    '&.clickable': {
      cursor: 'pointer',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(-0.5),
    top: theme.spacing(-0.5),
    backgroundColor: colors.grey20,
    borderRadius: '1em',
    padding: theme.spacing(0.25),
    display: 'flex',
    cursor: 'pointer',
    zIndex: Z_INDEX.defaultPriority,
    '&.disabled': {
      cursor: 'default',
      opacity: 0.6,
    },
  },
}))

interface InlineFormsPreviewProps {
  formTemplates: VariantAndVersion[]
  onDeleteForm?: (templateId: string) => void
  disableRemove?: boolean
}

/** Grid of form thumbnails for previewing a set of templates */
export const InlineFormsPreview = memo(
  function InlineFormsPreview({
    formTemplates,
    onDeleteForm,
    disableRemove,
  }: InlineFormsPreviewProps) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [ref, { width }] = useMeasure<HTMLDivElement>()

    const [formsPdf, setFormsPdf] = useState<Blob | null>(null)
    const [metadata, setMetadata] = useState<pdfTypes.PageMetadata[]>()
    const [formsPageCount, setFormsPageCount] = useState<number>(0)
    const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false)

    // React pdf preview flickers before render success. This happens on first mount and whenever the
    // page is resized. The way to get around this is by holding rendering status in state,
    // and masking the flickering component https://github.com/wojtekmaj/react-pdf/issues/418
    const [hasFinishedRender, handleFinishRender, handleReload] = useToggle()

    const { contract } = useProjectContext()

    // Load the existing forms if they've already been uploaded
    useEffect(() => {
      async function generateFormsPdf() {
        if (formTemplates.length === 0) {
          return
        }
        const formsPdf = await generatePdfWithMetadata({
          type: 'templatesPreview',
          formTemplateVersionIds: _.uniq(formTemplates.map((template) => template.versionId)),
          formTemplateVariantIds: _.uniq(formTemplates.map((template) => template.variantId)),
          contractId: contract?.id,
        })
        setFormsPdf(formsPdf.blob)
        setMetadata(formsPdf.metadata)
      }

      generateFormsPdf()
    }, [formTemplates, contract?.id])

    // Trigger a loading state when there is a change to form template ids
    useEffect(() => {
      handleReload()
    }, [handleReload, formTemplates.length])

    const thumbnailWidth = useMemo(() => {
      // Four thumbnails should fit in a row, subtract for each side of padding
      return Math.max(width / 4 - THUMBNAIL_PADDING * 2, 0)
    }, [width])

    const handleDeleteForm = useCallback(
      (pageNumber: number) => {
        if (!onDeleteForm || !metadata) {
          return
        }
        const { formTemplateId } = metadata[pageNumber]
        if (formTemplateId) {
          onDeleteForm(formTemplateId)
        }
      },
      [metadata, onDeleteForm]
    )

    if (!formsPdf) {
      return <InlineFormsPreviewLoadingThumbnails />
    }

    return (
      <>
        <div ref={ref}>
          <Document
            file={formsPdf}
            loading={<InlineFormsPreviewLoadingThumbnails />}
            // It's possible that this success callback is triggered with a null value
            // see https://github.com/siteline/siteline/issues/12036 for more details
            onLoadSuccess={(document: { numPages: number } | null) => {
              if (!document) {
                return
              }
              setFormsPageCount(document.numPages)
              handleFinishRender()
            }}
          >
            {!hasFinishedRender && (
              <InlineFormsPreviewLoadingThumbnails thumbnailWidth={thumbnailWidth} />
            )}
            <Grid container spacing={2}>
              {_.range(formsPageCount).map((pageIndex) => {
                const hasMetadata = metadata?.[pageIndex] !== undefined
                const userVisibleName = metadata?.[pageIndex]?.userVisibleName
                return (
                  <Grid item xs={3} key={pageIndex}>
                    <div className={classes.thumbnailContainer}>
                      {onDeleteForm && metadata?.[pageIndex] && (
                        <Tooltip
                          title={
                            hasMetadata && !disableRemove
                              ? t('forms.remove_form', {
                                  userVisibleName,
                                })
                              : ''
                          }
                          placement="top"
                        >
                          <CloseIcon
                            fontSize="small"
                            onClick={disableRemove ? undefined : () => handleDeleteForm(pageIndex)}
                            className={clsx(classes.closeIcon, { disabled: disableRemove })}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div
                      className={clsx(classes.thumbnail, 'clickable')}
                      onClick={() => setPreviewDialogOpen(true)}
                    >
                      <PdfPageMemoized
                        thumbnailWidth={thumbnailWidth}
                        pageNumber={pageIndex + 1}
                        onRenderSuccess={handleFinishRender}
                      />
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </Document>
        </div>
        <PdfPreviewDialog
          open={previewDialogOpen}
          onClose={() => setPreviewDialogOpen(false)}
          title=""
          file={formsPdf}
          metadata={metadata}
          isProcessingForms={false}
          loading={!formsPdf}
        />
      </>
    )
  },
  (prev, next) => _.isEqual(prev, next)
)
