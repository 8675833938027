import { Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { SitelineText, makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
    '& .label': {
      minWidth: 200,
      maxWidth: 200,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))

interface SettingsRowProps {
  isLoading: boolean
  label: string
  labelEndIcon?: ReactNode
  value: string | number | ReactNode | null
  editingValue: React.ReactNode
  isEditing: boolean
  className?: string
  innerClassName?: string
  labelWidth?: number
}

/** Displays a single row on the project settings page. */
export function SettingsRow({
  isLoading,
  label,
  labelEndIcon,
  value,
  editingValue,
  isEditing,
  className,
  innerClassName,
  labelWidth,
}: SettingsRowProps) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <SitelineText
        variant="body1"
        color="grey50"
        className="label"
        style={{ minWidth: labelWidth, maxWidth: labelWidth }}
        endIcon={labelEndIcon}
      >
        {label}
      </SitelineText>
      <div className={innerClassName}>
        {isLoading && <Skeleton variant="rectangular" width={150} height={20} />}
        {!isLoading && isEditing && editingValue}
        {!isLoading && !isEditing && (
          <SitelineText variant="body1" color={value ? 'grey90' : 'grey50'}>
            {value ?? '—'}
          </SitelineText>
        )}
      </div>
    </div>
  )
}
