import AddIcon from '@mui/icons-material/Add'
import ArchiveIcon from '@mui/icons-material/Archive'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  PopoverOrigin,
  Skeleton,
  useMediaQuery,
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import { Navigate, useNavigate, useParams } from '@tanstack/react-router'
import { clsx } from 'clsx'
import moment from 'moment-timezone'
import { ChangeEvent, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import { SitelineHeader } from '../../../common/components/SitelineHeader'
import { HorizontalTab, HorizontalTabs } from '../../../common/components/SitelineTabs'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useUserContext } from '../../../common/contexts/UserContext'
import {
  BillingType,
  CashForecastPeriodType,
  ContractStatus,
  MinimalChangeOrderRequestProperties,
  Permission,
} from '../../../common/graphql/apollo-operations'
import { MINIMUM_SUPPORTED_SCREEN_WIDTH } from '../../../common/themes/Main'
import {
  trackBillingProjectTabClick,
  trackDownloadCashFlowForecast,
} from '../../../common/util/MetricsTracking'
import {
  useArchiveContractMutationWithCache,
  useUnarchiveContractMutationWithCache,
} from '../../../common/util/Project'
import {
  incompleteProjectOnboardingTasks,
  isProjectOnboardingComplete,
} from '../../../common/util/ProjectOnboarding'
import { useExportCashForecastDataToXlsx } from '../../reporting/export/CashForecastExport'
import { BILLING_TAB_PADDING, BillingPathType, BillingTab, getBillingPath } from '../Billing.lib'
import { ChangeOrderLogAddtiionalOptionsMenu } from '../change-order-requests/ChangeOrderLogAdditionalOptionsMenu'
import { ChangeOrderRequestReviewDialog } from '../change-order-requests/ChangeOrderRequestReviewDialog'
import { CreateChangeOrderRequestDialog } from '../change-order-requests/CreateChangeOrderRequestDialog'
import { ContractForProjectHome } from './ProjectHome'

const useStyles = makeStylesFast((theme: Theme) => ({
  listHeader: {
    '& .page': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& .tabsContainer': {
        display: 'flex',
        alignItems: 'center',
        '& .tab': {
          whiteSpace: 'nowrap',
          [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1),
          },
          '& .label': {
            padding: theme.spacing(0.5, 0),
          },
          '& .betaPill': {
            padding: theme.spacing(0, 0.5),
            borderRadius: theme.spacing(0.5),
            backgroundColor: colors.grey30,
            color: colors.grey70,
            '&.active': {
              color: colors.white,
              backgroundColor: colors.brandOrange,
            },
          },
        },
      },
      '& .flexEnd': {
        '& > *': {
          marginLeft: theme.spacing(2),
          whiteSpace: 'nowrap',
          [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1),
          },
        },
      },
    },
    '& .createPayAppButton': {
      whiteSpace: 'nowrap',
    },
  },
  loadingHeader: {
    '& > *': {
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
  },
  setupTasks: {
    backgroundColor: colors.yellow30,
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginLeft: theme.spacing(1.5),
  },
}))

export const BILLING_TAB_WIDTHS = {
  [BillingTab.PAY_APPS]: 72,
  [BillingTab.SETTINGS]: 65,
  [BillingTab.SETUP]: 45,
  [BillingTab.SOV]: 35,
  [BillingTab.FORECAST]: 67,
  [BillingTab.CHANGE_ORDER_LOG]: 140,
}

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }

export function ProjectHomeHeaderLoadingSkeleton() {
  const classes = useStyles()

  return (
    <SitelineHeader
      className={clsx(classes.listHeader, classes.loadingHeader)}
      backgroundColor={colors.white}
    >
      <Skeleton variant="rectangular" width={BILLING_TAB_WIDTHS[BillingTab.PAY_APPS]} height={32} />
      <Skeleton
        variant="rectangular"
        width={BILLING_TAB_WIDTHS[BillingTab.CHANGE_ORDER_LOG]}
        height={32}
      />
      <Skeleton variant="rectangular" width={BILLING_TAB_WIDTHS[BillingTab.SETTINGS]} height={32} />
    </SitelineHeader>
  )
}

interface ProjectHomeHeaderProps {
  selectedTab: BillingTab
  contract: ContractForProjectHome
  canEdit: boolean
  onCreatePayApp?: () => void
  /** Header buttons specific to project page */
  children?: ReactNode
  changeOrderRequests?: MinimalChangeOrderRequestProperties[]
  loadingChangeOrderRequests?: boolean
}

/** Header for billing project home. */
export function ProjectHomeHeader({
  selectedTab,
  contract,
  canEdit,
  onCreatePayApp,
  children,
  changeOrderRequests,
  loadingChangeOrderRequests,
}: ProjectHomeHeaderProps) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme: Theme = useTheme()
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down(MINIMUM_SUPPORTED_SCREEN_WIDTH))
  const { isContractActive } = useProjectContext()
  const user = useUserContext()
  const { company, companyId, permissions } = useCompanyContext()
  const { projectId } = useParams({ from: '/_a/billing/$projectId' })
  const { confirm } = useSitelineConfirmation()
  const snackbar = useSitelineSnackbar()

  const [reviewDialogOpen, setReviewDialogOpen] = useState<boolean>(false)
  const [cashForecastAnchorEl, setCashForecastAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isCreateCorDialogOpen, handleOpenCorDialog, handleCloseCorDialog] = useToggle()

  const cashForecastExportContext = useMemo(() => {
    const now = moment.tz(moment.tz.guess())
    return {
      t,
      user,
      locations: [...(company?.locations ?? [])],
      companyId,
      filters: {},
      month: now.month(),
      year: now.year(),
      search: '',
      cashForecastPeriod: CashForecastPeriodType.WEEK,
    }
  }, [t, user, company, companyId])

  const [exportCashForecastToXlsx] = useExportCashForecastDataToXlsx(cashForecastExportContext)
  const [archiveContract] = useArchiveContractMutationWithCache(projectId)
  const [unarchiveContract] = useUnarchiveContractMutationWithCache(projectId)

  const hasEditInvoicePermission = permissions.includes(Permission.EDIT_INVOICE)
  const onboardedStatus = contract.onboardedStatus
  const isOnboardingComplete = isProjectOnboardingComplete(onboardedStatus)
  const isTimeAndMaterials = contract.billingType === BillingType.TIME_AND_MATERIALS

  // Allow the user to access the forecast tab if 1) they have the permission or 2) a
  // forecast already exists (which enables them to view a read-only forecast)
  const canEditForecast = hasEditInvoicePermission && isContractActive
  const canAccessForecast = !!contract.billingForecast || canEditForecast
  const canCreatePayApps = hasEditInvoicePermission

  const tabs = useMemo(() => {
    const tabsByOnboardingStatus = [BillingTab.SETTINGS]

    // Show the Change Orders tab if an SOV has been added. Don't show the CO log for t&m projects.
    if (contract.onboardedStatus.addedSov && !isTimeAndMaterials) {
      tabsByOnboardingStatus.unshift(BillingTab.CHANGE_ORDER_LOG)
    }

    // Once an SOV exists, allow the user to create a forecast. Don't show the forecast
    // tab for t&m projects.
    if (contract.onboardedStatus.addedSov && !isTimeAndMaterials && canAccessForecast) {
      tabsByOnboardingStatus.unshift(BillingTab.FORECAST)
    }

    // Once an SOV exists, show an SOV tab to view and edit it. Note: Time and materials
    // projects use a rate table, so we should not display an SOV tab
    if (contract.onboardedStatus.addedSov && !isTimeAndMaterials) {
      tabsByOnboardingStatus.unshift(BillingTab.SOV)
    }

    // Show the Setup tab if there are onboarding steps remaining
    if (!isOnboardingComplete && isContractActive) {
      tabsByOnboardingStatus.unshift(BillingTab.SETUP)
    }

    // Show the Pay Apps if either all onboarding steps are completed (e.g. if a new project was created on admin)
    // or the billing onboarding task is complete (i.e. a pay app has been created)
    if (isOnboardingComplete || contract.onboardedStatus.startedBilling || !isContractActive) {
      tabsByOnboardingStatus.unshift(BillingTab.PAY_APPS)
    }

    return tabsByOnboardingStatus
  }, [
    canAccessForecast,
    contract.onboardedStatus.addedSov,
    contract.onboardedStatus.startedBilling,
    isContractActive,
    isOnboardingComplete,
    isTimeAndMaterials,
  ])

  const definedChangeOrderRequests = useMemo(() => changeOrderRequests ?? [], [changeOrderRequests])

  const handleExportCashForecast = useCallback(() => {
    exportCashForecastToXlsx({ contractId: contract.id })
    trackDownloadCashFlowForecast({ projectId })
    setCashForecastAnchorEl(null)
  }, [exportCashForecastToXlsx, projectId, contract.id])

  const handleChange = useCallback(
    (_event: ChangeEvent<unknown>, tab: BillingTab) => {
      const path = getBillingPath({
        pathType: BillingPathType.ProjectBilling,
        projectId,
        billingTab: tab,
      })
      trackBillingProjectTabClick({ tab })
      navigate(path)
    },
    [navigate, projectId]
  )

  const incompeteOnboardingTasks = incompleteProjectOnboardingTasks(
    onboardedStatus,
    contract.billingType
  )

  const showSetupTasksRemaining =
    tabs.includes(BillingTab.SETUP) &&
    tabs.includes(BillingTab.PAY_APPS) &&
    incompeteOnboardingTasks > 0

  // We don't display COR header actions when there aren't any change order requests, since
  // these CTAs are presented on the COR table empty state
  const hasChangeOrderRequests =
    !loadingChangeOrderRequests &&
    changeOrderRequests !== undefined &&
    changeOrderRequests.length > 0

  const showChangeOrderButtons =
    selectedTab === BillingTab.CHANGE_ORDER_LOG &&
    permissions.includes(Permission.EDIT_CHANGE_ORDER) &&
    hasChangeOrderRequests
  const showArchive =
    selectedTab === BillingTab.SETTINGS &&
    isContractActive &&
    permissions.includes(Permission.EDIT_PROJECT_SETTINGS)
  const showUnarchive =
    selectedTab === BillingTab.SETTINGS &&
    !isContractActive &&
    permissions.includes(Permission.EDIT_PROJECT_SETTINGS)
  // On the pay apps tab, show a button to create a new pay app
  const showCreatePayApp =
    selectedTab === BillingTab.PAY_APPS &&
    !!onCreatePayApp &&
    contract.payApps.length > 0 &&
    isContractActive
  const showDownloadCashForecast = selectedTab === BillingTab.PAY_APPS && isContractActive

  const handleOpenCashForecastMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setCashForecastAnchorEl(event.currentTarget)
  }, [])

  const handleCloseCashForecastMenu = useCallback(() => {
    setCashForecastAnchorEl(null)
  }, [])

  const handleArchive = useCallback(() => {
    // Indicate that the project is not fully billed if applicable
    const isProgressComplete = contract.percentComplete === 1
    const isRetentionComplete = contract.sov?.totalRetention === 0
    const isContractFullyBilled = isProgressComplete && isRetentionComplete

    confirm({
      title: t('project_home.confirm_archive.title', { projectName: contract.project.name }),
      details: isContractFullyBilled
        ? t('project_home.confirm_archive.body')
        : t('project_home.confirm_archive.outstanding_progress_body'),
      confirmationType: isContractFullyBilled ? 'confirm' : 'delete',
      confirmLabel: t('common.actions.confirm'),
      callback: async (confirmed: boolean) => {
        if (!confirmed) {
          return
        }
        const updatedContract = { ...contract, status: ContractStatus.ARCHIVED }
        try {
          await archiveContract({
            variables: {
              id: contract.id,
            },
            optimisticResponse: {
              __typename: 'Mutation',
              archiveContract: {
                ...contract.project,
                contracts: [updatedContract],
              },
            },
          })
          snackbar.showSuccess(t('project_home.confirm_archive.archived_project'))
        } catch (err) {
          snackbar.showError(err.message)
        }
      },
    })
  }, [archiveContract, confirm, contract, snackbar, t])

  const handleUnarchive = useCallback(() => {
    confirm({
      title: t('project_home.confirm_unarchive.title', { projectName: contract.project.name }),
      details: t('project_home.confirm_unarchive.body'),
      callback: (confirmed: boolean) => {
        if (!confirmed) {
          return
        }
        const updatedContract = { ...contract, status: ContractStatus.ACTIVE }
        unarchiveContract({
          variables: {
            id: contract.id,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            unarchiveContract: {
              ...contract.project,
              contracts: [updatedContract],
            },
          },
        })
        snackbar.showSuccess(t('project_home.confirm_unarchive.restored_project'))
      },
    })
  }, [confirm, contract, snackbar, t, unarchiveContract])

  const shouldShowChangeOrderLogExportButton =
    loadingChangeOrderRequests ||
    (changeOrderRequests !== undefined && changeOrderRequests.length > 0)

  if (!tabs.includes(selectedTab)) {
    return (
      <Navigate
        {...getBillingPath({
          pathType: BillingPathType.ProjectBilling,
          projectId,
          billingTab: tabs[0],
        })}
        replace
      />
    )
  }

  return (
    <SitelineHeader className={classes.listHeader} backgroundColor={colors.white}>
      <div className="page">
        <div className="tabsContainer">
          <HorizontalTabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            {tabs.map((tab) => {
              const showTasksRemaining = tab === BillingTab.SETUP && showSetupTasksRemaining
              const tasksRemainingWidth = showTasksRemaining ? 44 : 0
              const additionalTabPadding = isXSmallScreen
                ? BILLING_TAB_PADDING / 2
                : BILLING_TAB_PADDING
              return (
                <HorizontalTab
                  key={tab}
                  value={tab}
                  label={
                    <>
                      <SitelineText variant="button" className="label">
                        {t(`projects.subcontractors.pay_app.navigation.${tab}`)}
                      </SitelineText>
                      {showTasksRemaining && (
                        <div className={classes.setupTasks}>
                          <SitelineText variant="h4" color="grey90">
                            {incompeteOnboardingTasks}
                          </SitelineText>
                        </div>
                      )}
                    </>
                  }
                  style={{
                    minWidth: BILLING_TAB_WIDTHS[tab] + additionalTabPadding + tasksRemainingWidth,
                  }}
                  className="tab"
                />
              )
            })}
          </HorizontalTabs>
        </div>
        <div className="flexEnd">
          {showArchive && (
            <Button
              startIcon={<ArchiveIcon />}
              variant="outlined"
              color="secondary"
              disabled={!canEdit}
              onClick={handleArchive}
            >
              {t('projects.subcontractors.settings.archive')}
            </Button>
          )}
          {showUnarchive && (
            <Button
              startIcon={<UnarchiveIcon />}
              variant="outlined"
              color="secondary"
              disabled={!canEdit}
              onClick={handleUnarchive}
            >
              {t('projects.subcontractors.settings.unarchive')}
            </Button>
          )}
          {showCreatePayApp && (
            <Button
              startIcon={isXSmallScreen ? undefined : <AddIcon />}
              variant="outlined"
              color="secondary"
              disabled={!canCreatePayApps}
              onClick={onCreatePayApp}
              className="createPayAppButton"
            >
              {isXSmallScreen ? (
                <AddIcon />
              ) : (
                t('projects.subcontractors.pay_app_list.create_pay_app')
              )}
            </Button>
          )}
          {showChangeOrderButtons && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setReviewDialogOpen(true)}
              >
                {t('projects.subcontractors.change_order_requests.details.request_review')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenCorDialog}
                className="createButton"
              >
                {t('projects.subcontractors.change_order_requests.create_change_order_request')}
              </Button>
              {shouldShowChangeOrderLogExportButton && (
                <ChangeOrderLogAddtiionalOptionsMenu
                  contract={contract}
                  changeOrderRequests={changeOrderRequests}
                  loadingChangeOrderRequests={loadingChangeOrderRequests}
                />
              )}
            </>
          )}
          {showDownloadCashForecast && (
            <>
              <IconButton
                onClick={handleOpenCashForecastMenu}
                key="actions"
                color="secondary"
                size="small"
              >
                <MoreVertIcon className="kebab" />
              </IconButton>
              <Menu
                anchorEl={cashForecastAnchorEl}
                open={Boolean(cashForecastAnchorEl)}
                onClose={handleCloseCashForecastMenu}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                elevation={1}
              >
                <MenuItem onClick={handleExportCashForecast}>
                  {t('projects.subcontractors.pay_app_list.export_cash_flow_forecast')}
                </MenuItem>
              </Menu>
            </>
          )}
          {children}
        </div>
      </div>
      <ChangeOrderRequestReviewDialog
        open={reviewDialogOpen}
        onClose={() => setReviewDialogOpen(false)}
      />
      <CreateChangeOrderRequestDialog
        open={isCreateCorDialogOpen}
        onClose={handleCloseCorDialog}
        changeOrderRequests={definedChangeOrderRequests}
      />
    </SitelineHeader>
  )
}
