import { TFunction } from 'i18next'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useState } from 'react'
import { isPreSitelineRetentionLinked, roundCents } from 'siteline-common-all'
import { evictWithGc, toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import { PayAppForProgress } from '../../components/billing/invoice/LumpSumPayAppInvoice'
import { ContractForEditingSov } from '../../components/billing/onboarding/SovOnboarding'
import { getLineItemGroupHeaderRow } from '../../components/billing/sov/ManageSovRow'
import { useSitelineConfirmation } from '../components/SitelineConfirmation'
import {
  SpreadsheetColumn,
  SpreadsheetRow,
  SpreadsheetRowType,
  makeDividerRow,
} from '../components/Spreadsheet/Spreadsheet.lib'
import { useCompanyContext } from '../contexts/CompanyContext'
import { useProjectContext } from '../contexts/ProjectContext'
import * as fragments from '../graphql/Fragments'
import {
  BillingType,
  ChangeOrderRequestProperties,
  ChangeOrderRequestsDocument,
  GetPayAppForProgressDocument,
  OnboardedProjectContractStatusProperties,
  Query,
  RetentionTrackingLevel,
  SovChangeSetCreateLineItem,
  SovChangeSetProperties,
  SovLineItemGroupProperties,
  SovLineItemInput,
  SovLineItemProgressProperties,
  TaxGroupProperties,
  UpdateSovMutation,
  useCreateSovLineItemGroupMutation,
  useDeleteSovLineItemGroupMutation,
  useGetContractForEditingSovQuery,
  useUpdateSovLineItemGroupMutation,
  useUpdateSovMutation,
} from '../graphql/apollo-operations'
import { EditingWorksheetLineItem } from './BillingWorksheet'
import { ManageLumpSumSovColumn, getLumpSumSovColumns } from './ManageLumpSumSovColumn'
import { ManageUnitPriceSovColumn, getUnitPriceSovColumns } from './ManageUnitPriceSovColumn'
import { invalidateContractsAfterOnboardingStatusChange } from './ProjectOnboarding'
import { usesStandardOrLineItemTracking } from './Retention'

export interface EditingSovLineItemGroup {
  id: string
  code: string
  name: string
}

export interface EditingSovLineItem {
  id: string
  sortOrder?: number
  code: string
  name: string
  costCode?: string | null
  originalTotalValue: number
  latestTotalValue: number
  preSitelineBilling?: number
  groupId: string | null
  isChangeOrder?: boolean
  changeOrderApprovedAt?: Moment | null
  changeOrderEffectiveAt?: Moment | null
  billedToDate: number
  retentionToDate?: number
  unitName?: string
  unitPrice?: number
  defaultRetentionPercent?: number | null
  preSitelineRetentionAmount?: number | null
  taxGroupId?: string | null
  readonly latestRetentionPercent: number | null
  worksheetLineItems: EditingWorksheetLineItem[]
  changeOrderRequests: Pick<ChangeOrderRequestProperties, 'name' | 'id' | 'internalNumber'>[]
}

export interface EditingSov {
  lineItems: EditingSovLineItem[]
  groups: EditingSovLineItemGroup[]

  // For standard/line-item tracking: the pre-siteline retention $ override
  // For pay-app/project tacking: the actual pre-siteline retention $ held
  preSitelineRetentionHeldOverride: number | null

  // Default retention percent to use for new line items. Null if not tracking retention.
  defaultRetentionPercent: number | null

  // Total retention held to date on the SOV
  totalRetentionHeld: number
}

export enum BaseManageSovColumn {
  CODE = 'code',
  NAME = 'name',
  COST_CODE = 'costCode',

  /**
   * When line item is already part of a pay app, this is the latest retention %.
   * When line item is not yet part of a pay app, this is the default retention % to use
   * when added to the its first pay app.
   */
  RETENTION_PERCENT = 'retentionPercent',

  PRE_SITELINE_RETENTION_PERCENT = 'preSitelineRetentionPercent', // Read-only
  PRE_SITELINE_RETENTION_AMOUNT = 'preSitelineRetentionAmount',

  CHANGE_ORDER = 'changeOrder',
  CHANGE_ORDER_DATE = 'changeOrderDate',
  RETAINAGE = 'retainage',

  TAX_GROUP = 'taxGroup',
}

export type ManageSovColumn =
  | BaseManageSovColumn
  | ManageLumpSumSovColumn
  | ManageUnitPriceSovColumn

export function sovColumnToEditingSovLineItemField(
  column: ManageSovColumn
): keyof EditingSovLineItem {
  switch (column) {
    case BaseManageSovColumn.CODE:
      return 'code'
    case BaseManageSovColumn.NAME:
      return 'name'
    case BaseManageSovColumn.COST_CODE:
      return 'costCode'
    case BaseManageSovColumn.RETENTION_PERCENT:
      return 'defaultRetentionPercent'
    case BaseManageSovColumn.PRE_SITELINE_RETENTION_AMOUNT:
      return 'preSitelineRetentionAmount'
    case BaseManageSovColumn.CHANGE_ORDER:
      return 'isChangeOrder'
    case BaseManageSovColumn.CHANGE_ORDER_DATE:
      return 'changeOrderApprovedAt'
    case BaseManageSovColumn.TAX_GROUP:
      return 'taxGroupId'
    case ManageLumpSumSovColumn.SCHEDULED_VALUE:
    case ManageUnitPriceSovColumn.BID_QUANTITY:
      return 'originalTotalValue'
    case ManageLumpSumSovColumn.PRE_SITELINE_BILLING:
      return 'preSitelineBilling'
    case ManageUnitPriceSovColumn.UNIT_NAME:
      return 'unitName'
    case ManageUnitPriceSovColumn.UNIT_PRICE:
      return 'unitPrice'
    case ManageUnitPriceSovColumn.REVISED_QUANTITY:
      return 'latestTotalValue'
    case ManageLumpSumSovColumn.BILLED_TO_DATE:
    case ManageUnitPriceSovColumn.QUANTITY_TO_DATE:
    case ManageUnitPriceSovColumn.BID_AMOUNT:
    case ManageUnitPriceSovColumn.REVISED_AMOUNT:
    case ManageUnitPriceSovColumn.AMOUNT_TO_DATE:
    case ManageUnitPriceSovColumn.UNITS_TO_FINISH:
    case ManageLumpSumSovColumn.BALANCE_TO_FINISH:
    case BaseManageSovColumn.RETAINAGE:
    case BaseManageSovColumn.PRE_SITELINE_RETENTION_PERCENT:
      throw new Error('SOV column should not be editable')
  }
}

export function getContractBillingType(contract: {
  billingType: BillingType
}): BillingType.LUMP_SUM | BillingType.UNIT_PRICE {
  const { billingType } = contract
  switch (billingType) {
    case BillingType.QUICK:
    case BillingType.TIME_AND_MATERIALS:
      throw new Error('Quick bill and T&M contracts not supported for this operation')
    case BillingType.LUMP_SUM:
    case BillingType.UNIT_PRICE:
      return billingType
  }
}

export type GetSovColumnsForViewOrEditSovProps = {
  /** If there were pay apps billed prior to onboarding, show a pre-Siteline billing column */
  includePreSitelineBillingColumn: boolean
  /**
   * If tracking retention on the line-item level, and there are no pay apps billed yet,
   * show the default retention % column
   */
  includeRetentionPercentColumn: boolean
  /**
   * If there were pay apps billed prior to onboarding, and retention is tracked on the line-item level,
   * show pre-siteline retention % and $
   */
  includePreSitelineRetentionColumns: boolean
  /** Whether to include total billed to date and balance to finish columns */
  includeTotalBillingColumns: boolean
  /**
   * Once the SOV has been onboarded (i.e. any pay apps exist), show total retainage on the SOV. These
   * columns are not shown while editing the SOV.
   */
  includeTotalRetainageColumn: boolean
  /** Whether to include the change order checkbox column */
  includeChangeOrderColumn: boolean
  /** Whether to include the change order approval date column */
  includeChangeOrderApprovalColumn: boolean
  /** Whether to include revised contract columns, for a unit price SOV */
  includeRevisedContractColumns: boolean
  /** If non-null, will include a column for selecting a tax group for each SOV line item */
  taxGroups: TaxGroupProperties[] | null
  onAddTaxGroupForLineItemId: ((lineItemId: string) => void) | null
  isEditable: boolean
  timeZone: string
}

/** Columns that may be shown when viewing or editing an SOV */
export function getSovColumns(
  billingType: BillingType.LUMP_SUM | BillingType.UNIT_PRICE,
  t: TFunction,
  props: GetSovColumnsForViewOrEditSovProps
): SpreadsheetColumn[] {
  switch (billingType) {
    case BillingType.LUMP_SUM:
      return getLumpSumSovColumns(t, props)
    case BillingType.UNIT_PRICE:
      return getUnitPriceSovColumns(t, props)
  }
}

/** Reorders an SOV based on a single line item row being moved to a new position */
export function reorderLineItem(
  fromRowIndex: number,
  toRowIndex: number,
  lineItem: EditingSovLineItem,
  lineItemIndex: number,
  rows: SpreadsheetRow[],
  lineItems: EditingSovLineItem[]
) {
  const newContentRows = [...rows]
  newContentRows.splice(fromRowIndex, 1)

  // Remove the line item from the line items list
  const newLineItems = [...lineItems]
  newLineItems.splice(lineItemIndex, 1)

  // Find the last line item before the row we're moving this line item to,
  // so we can move the line item to the right position in the line items list
  let previousLineItemRowIndex = toRowIndex - 1
  while (
    previousLineItemRowIndex >= 0 &&
    (newContentRows[previousLineItemRowIndex].cells.length === 0 ||
      newContentRows[previousLineItemRowIndex].isGroupHeaderRow)
  ) {
    previousLineItemRowIndex--
  }
  let previousLineItemIndex = -1
  if (previousLineItemRowIndex >= 0) {
    const previousLineItemId = newContentRows[previousLineItemRowIndex].id
    previousLineItemIndex = newLineItems.findIndex((lineItem) => lineItem.id === previousLineItemId)
  }

  // Starting at the destination row, iterate backward to determine which grouping
  // this line item should be added to (either an SOV line item group or no group)
  let inGroupIndex = toRowIndex - 1
  while (
    inGroupIndex >= 0 &&
    inGroupIndex < newContentRows.length &&
    !newContentRows[inGroupIndex].isGroupHeaderRow &&
    newContentRows[inGroupIndex].cells.length !== 0
  ) {
    inGroupIndex--
  }
  const inGroupRow = inGroupIndex >= 0 ? newContentRows[inGroupIndex] : undefined
  const groupId = inGroupRow && inGroupRow.isGroupHeaderRow ? inGroupRow.id : null

  newLineItems.splice(previousLineItemIndex + 1, 0, { ...lineItem, groupId })
  return {
    fromLineItemIndex: lineItemIndex,
    toLineItemIndex: previousLineItemIndex + 1,
    newLineItems,
  }
}

function findNextNonDividerRowIndex(rows: SpreadsheetRow[], rowIndex: number): number {
  let nextRowIndex = rowIndex + 1
  while (nextRowIndex < rows.length && rows[nextRowIndex].type === SpreadsheetRowType.DIVIDER) {
    nextRowIndex++
  }
  return nextRowIndex
}

/** Reorders an SOV based on a whole group of line items being moved */
export function reorderGroup(
  fromRowIndex: number,
  toRowIndex: number,
  group: EditingSovLineItemGroup,
  rows: SpreadsheetRow[],
  lineItems: EditingSovLineItem[]
) {
  const lineItemsToMove = lineItems.filter((lineItem) => lineItem.groupId === group.id)
  const lineItemIdsToMove = lineItemsToMove.map((lineItem) => lineItem.id)
  const firstLineItemIndex = lineItems.findIndex((lineItem) => lineItem.groupId === group.id)

  // Find the line item row that the group will be moved after
  let previousLineItemRowIndex = toRowIndex < fromRowIndex ? toRowIndex - 1 : toRowIndex
  let previousLineItemRow = rows[previousLineItemRowIndex]
  while (
    previousLineItemRowIndex >= 0 &&
    previousLineItemRowIndex < rows.length &&
    (rows[previousLineItemRowIndex].cells.length === 0 ||
      rows[previousLineItemRowIndex].isGroupHeaderRow ||
      lineItemIdsToMove.includes(previousLineItemRow.id))
  ) {
    previousLineItemRowIndex--
    previousLineItemRow = rows[previousLineItemRowIndex]
  }

  // Remove all grouped line items from the line item list, since they're being moved
  const newLineItems = [...lineItems]
  newLineItems.splice(firstLineItemIndex, lineItemsToMove.length)

  const previousLineItemRowId =
    previousLineItemRowIndex >= 0 ? rows[previousLineItemRowIndex].id : undefined
  const previousLineItemIndex = newLineItems.findIndex(
    (lineItem) => lineItem.id === previousLineItemRowId
  )

  // Check if inserting the group between line items
  const previousLineItemNextRowIndex = findNextNonDividerRowIndex(rows, previousLineItemRowIndex)
  const previousLineItemNextRow =
    previousLineItemNextRowIndex <= rows.length && rows[previousLineItemNextRowIndex]

  if (previousLineItemNextRow) {
    // If inserting between line items, need to break them;
    // previous line items don't change but subsequent line items are moved into this group
    const lineItemsToTransfer = []
    let fromLineItemIndex = lineItems.findIndex(
      (lineItem) => lineItem.id === previousLineItemNextRow.id
    )
    // If the next row is a line item, move consecutive line items into this group;
    // if not a line item (e.g. a group header), don't need to move any line items into this group
    if (fromLineItemIndex >= 0) {
      const fromGroupId = lineItems[fromLineItemIndex].groupId
      while (
        fromLineItemIndex < lineItems.length &&
        lineItems[fromLineItemIndex].groupId === fromGroupId
      ) {
        lineItemsToTransfer.push(lineItems[fromLineItemIndex])
        fromLineItemIndex++
      }
    }

    newLineItems.splice(
      previousLineItemIndex + 1,
      lineItemsToTransfer.length,
      ...lineItemsToMove,
      ...lineItemsToTransfer.map((lineItem) => ({
        ...lineItem,
        groupId: group.id,
      }))
    )
    return {
      fromLineItemIndex: firstLineItemIndex,
      toLineItemIndex: previousLineItemIndex + 1,
      newLineItems,
    }
  }

  if (!previousLineItemRowId && toRowIndex > 0) {
    // Something is wrong if there is no previous line item in the list and we're not
    // dropping into the first index in the list
    throw new Error('Attempting to move group to an invalid position')
  }

  // Add all moved line items back to the line item list
  newLineItems.splice(previousLineItemIndex + 1, 0, ...lineItemsToMove)

  return {
    fromLineItemIndex: firstLineItemIndex,
    toLineItemIndex: previousLineItemIndex + 1,
    newLineItems,
  }
}

/** Returns false if the SOV shouldn't be saved because of an invalid line item or group value */
export function isValidSov(
  sov: EditingSov,
  previousSov: NonNullable<ContractForEditingSov['sov']>
) {
  const failedLineItemCheck = sov.lineItems.some((lineItem) => {
    if (lineItem.name === '') {
      // Prevent submitting if the line item name is empty
      return true
    }
    if (lineItem.code === '') {
      const previousLineItem = previousSov.lineItems.find(
        (previousLineItem) => previousLineItem.id === lineItem.id
      )
      if (!previousLineItem || previousLineItem.code !== lineItem.code) {
        // Prevent submitting if the line item code is empty, unless it was previously empty (since
        // we do support empty line item codes when imported from a GC portal)
        return true
      }
    }
    return false
  })
  const failedGroupPrecheck = sov.groups.some((group) => {
    if (group.name === '') {
      // Prevent submitting if group name is empty
      return true
    }
    return false
  })
  if (failedLineItemCheck || failedGroupPrecheck) {
    return false
  }
  if (
    sov.lineItems.some(
      (lineItem) => lineItem.isChangeOrder && lineItem.changeOrderApprovedAt === null
    )
  ) {
    // Prevent submitting if missing a change order approval date
    return false
  }
  return true
}

/**
 * Returns updated total billed to date for a line item, based on changes to the editable
 * line item fields. Even though the total billed to date isn't shown while editing, it's still used
 * to validate changes to the total value and pre-Siteline billing.
 */
export function getUpdatedBilledToDate(
  oldLineItem: EditingSovLineItem,
  newLineItem: EditingSovLineItem
) {
  const oldPreSitelineBilling = oldLineItem.preSitelineBilling ?? 0
  const newPreSitelineBilling = newLineItem.preSitelineBilling ?? 0
  // The new billed to date is the old billed to date plus the difference between the new and old pre-Siteline billing
  const newBilledToDate = oldLineItem.billedToDate + (newPreSitelineBilling - oldPreSitelineBilling)
  return newBilledToDate
}

/**
 * Returns an updated previous billed and current retention for a progress item
 * based on changes to the line item
 */
export function getUpdatedProgressFields(
  progress: SovLineItemProgressProperties,
  newLineItemPreviousBilled: number,
  newLineItemRetentionPercent: number,
  roundRetention: boolean
) {
  const oldLineItemPreviousBilled = progress.sovLineItem.previousBilled
  const newPreviousBilled =
    progress.previousBilled - oldLineItemPreviousBilled + newLineItemPreviousBilled
  const oldRetentionPercent = progress.sovLineItem.defaultRetentionPercent ?? 0
  const newCurrentRetention =
    progress.currentRetention -
    roundCents(oldRetentionPercent * progress.previousBilled, roundRetention) +
    roundCents(newLineItemRetentionPercent * newPreviousBilled, roundRetention)
  return { previousBilled: newPreviousBilled, currentRetention: newCurrentRetention }
}

/**
 * Given a SOV with pending changes, this returns the total pre-siteline retention amount for all
 * line items. If there is an override, that is returned, otherwise the sum of the line items is returned.
 */
export function getContractPreSitelineRetentionAmount(sov: EditingSov): number {
  if (_.isNumber(sov.preSitelineRetentionHeldOverride)) {
    return sov.preSitelineRetentionHeldOverride
  }
  return _.sumBy(sov.lineItems, (lineItem) => lineItem.preSitelineRetentionAmount ?? 0)
}

type AutomaticPreSitelineRetentionUpdate = {
  defaultRetentionPercent: number
  preSitelineBilling: number
  preSitelineRetentionAmount: number
  roundRetention: boolean
  includePreSitelineBillingColumn: boolean
  includePreSitelineRetentionColumns: boolean
}

export function isPreSitelineRetentionColumnLinked(
  props: AutomaticPreSitelineRetentionUpdate
): boolean {
  if (!props.includePreSitelineBillingColumn || !props.includePreSitelineRetentionColumns) {
    return false
  }
  return isPreSitelineRetentionLinked({
    defaultRetentionPercent: props.defaultRetentionPercent,
    preSitelineBilling: props.preSitelineBilling,
    preSitelineRetentionAmount: props.preSitelineRetentionAmount,
    roundRetention: props.roundRetention,
  })
}

/**
 * Whether the "pre-siteline retention unlinked" icon should be shown in the pre-Siteline retention
 * column.
 * If any of the relevant columns is hidden, this is false.
 * See `isPreSitelineRetentionLinked` for an example.
 */
export function shouldShowPreSitelineRetentionUnlinked(
  props: AutomaticPreSitelineRetentionUpdate
): boolean {
  if (!props.includePreSitelineBillingColumn || !props.includePreSitelineRetentionColumns) {
    return false
  }
  return !isPreSitelineRetentionColumnLinked(props)
}

/** Returns true if an SOV has no content */
export function isSovEmpty(sov: EditingSov): boolean {
  if (sov.lineItems.some((lineItem) => lineItem.code || lineItem.name)) {
    return false
  }
  if (sov.groups.some((group) => group.code || group.code)) {
    return false
  }
  return true
}

/**
 * When creating a new change order, add it to an existing group if every existing change
 * order in the SOV belongs to one group. Otherwise, add it ungrouped to the end of the SOV.
 */
export function getNewChangeOrderGroupId<
  T extends {
    isChangeOrder?: boolean
    groupId?: string | null
  },
>(lineItems: T[]): string | null {
  // We intentionally don't compact the list of IDs so we don't
  // exclude change orders that are ungrouped.
  const changeOrderGroupIds = _.chain(lineItems)
    .filter((sovLineItem) => sovLineItem.isChangeOrder === true)
    .map((sovLineItem) => sovLineItem.groupId ?? null)
    .uniq()
    .value()
  return changeOrderGroupIds.length === 1 && changeOrderGroupIds[0] !== null
    ? changeOrderGroupIds[0]
    : null
}

type UseQuerySovForEditingParams = {
  projectId: string
  companyId: string
}

/** Single hook for querying the SOV line item and group data needed to edit an SOV */
export function useQuerySovForEditing({ projectId, companyId }: UseQuerySovForEditingParams) {
  const { data, refetch, loading } = useGetContractForEditingSovQuery({
    variables: {
      input: {
        projectId,
        companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
    // Sets loading to true when calling refetch on error
    notifyOnNetworkStatusChange: true,
  })
  const contract = data?.contractByProjectId
  return { contract, refetch, loading }
}

export type ContractForUpdatingSov = UpdateSovMutation['updateSov']

/** Shared handler for saving an SOV, including mutations and cache updates */
export function useSaveSov({
  sovId,
  payApp,
  contractForOnboarding,
}: {
  sovId: string
  payApp?: PayAppForProgress
  contractForOnboarding?: { onboardedStatus: OnboardedProjectContractStatusProperties; id: string }
}) {
  const { contract: minimalContract } = useProjectContext()
  const { defaultRetentionPercent } = useCompanyContext()
  const { confirm } = useSitelineConfirmation()
  const snackbar = useSitelineSnackbar()
  const i18nBase = 'projects.subcontractors.sov'
  const [deleteGroup] = useDeleteSovLineItemGroupMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }
      cache.modify<WritableDeep<Query>>({
        id: 'ROOT_QUERY',
        fields: {
          sovLineItemGroups(existingGroupRefs, { readField, toReference, storeFieldName }) {
            // Don't modify the cached query for another SOV's groups
            if (!storeFieldName.includes(sovId)) {
              return existingGroupRefs
            }
            const refs = toReferences(existingGroupRefs, toReference)
            return refs.filter((ref) => readField('id', ref) !== data.deleteSovLineItemGroup.id)
          },
        },
      })
      if (minimalContract) {
        evictWithGc(cache, (evict) => {
          evict({ id: cache.identify(minimalContract), fieldName: 'progressRemaining' })
        })
      }
    },
  })
  const [updateGroup] = useUpdateSovLineItemGroupMutation()
  const [createGroup] = useCreateSovLineItemGroupMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }
      const newGroupRef = cache.writeFragment({
        data: data.createSovLineItemGroup,
        fragment: fragments.sovLineItemGroup,
        fragmentName: 'SovLineItemGroupProperties',
      })
      cache.modify<WritableDeep<Query>>({
        id: 'ROOT_QUERY',
        fields: {
          sovLineItemGroups(existingGroupRefs, { toReference, storeFieldName }) {
            // Don't modify the cached query for another SOV's groups
            if (!storeFieldName.includes(sovId)) {
              return existingGroupRefs
            }
            const refs = toReferences(existingGroupRefs, toReference)
            return _.compact([...refs, newGroupRef])
          },
        },
      })
    },
  })
  const [saving, setSaving] = useState<boolean>(false)

  const isProjectHoldingRetention =
    minimalContract?.retentionTrackingLevel !== RetentionTrackingLevel.NONE
  const [updateSov] = useUpdateSovMutation({
    update(cache, { data }) {
      if (minimalContract) {
        evictWithGc(cache, (evict) => {
          evict({ id: cache.identify(minimalContract), fieldName: 'progressRemaining' })
        })
      }
      if (!data || !payApp) {
        return
      }
      if (contractForOnboarding) {
        cache.modify({
          id: cache.identify(contractForOnboarding),
          fields: {
            onboardedStatus() {
              return { ...contractForOnboarding.onboardedStatus, addedSov: true }
            },
          },
        })
      }
      const lineItems = _.keyBy(data.updateSov.sov?.lineItems, (lineItem) => lineItem.id)
      const updatedProgress = payApp.progress
        .filter((progress) => progress.sovLineItem.id in lineItems)
        .map((progress) => {
          const updatedLineItem = lineItems[progress.sovLineItem.id]
          return {
            ...progress,
            sovLineItem: {
              ...progress.sovLineItem,
              code: updatedLineItem.code,
              name: updatedLineItem.name,
              costCode: updatedLineItem.costCode,
              originalTotalValue: updatedLineItem.originalTotalValue,
              latestTotalValue: updatedLineItem.latestTotalValue,
              previousBilled: updatedLineItem.previousBilled,
              sortOrder: updatedLineItem.sortOrder,
              sovLineItemGroup: updatedLineItem.sovLineItemGroup,
              unitName: updatedLineItem.unitName || null,
              unitPrice: updatedLineItem.unitPrice,
              preSitelineRetention: isProjectHoldingRetention
                ? updatedLineItem.preSitelineRetention
                : null,
              taxGroup: updatedLineItem.taxGroup,
            },
          }
        })
      const existingIds = payApp.progress.map((progress) => progress.sovLineItem.id)
      const updatedIds = data.updateSov.sov?.lineItems.map((lineItem) => lineItem.id)
      const newIds = _.difference(updatedIds, existingIds)
      const newProgress = newIds.map((newId) => {
        const newLineItem = lineItems[newId]
        const lineItemProgress: SovLineItemProgressProperties = {
          __typename: 'SovLineItemProgress',
          id: uuidv4(),
          currentBilled: 0,
          progressBilled: 0,
          currentRetention: 0,
          storedMaterialBilled: 0,
          previousBilled: 0,
          futureBilled: 0,
          previousRetention: 0,
          previousRetentionBilled: 0,
          sovLineItem: newLineItem,
          totalValue: 0,
          retentionHeldPercent: isProjectHoldingRetention
            ? (newLineItem.defaultRetentionPercent ?? defaultRetentionPercent)
            : 0,
          retentionHeldOverride: null,
          retentionReleased: null,
          lastProgressWithRetentionHeldOverride: null,
          worksheetLineItemProgress: [],
          amountDuePostTax: 0,
          amountDuePreTax: 0,
          amountDueTaxAmount: 0,
        }
        return lineItemProgress
      })
      const allProgress = [...updatedProgress, ...newProgress]
      cache.modify({
        id: cache.identify(payApp),
        fields: {
          progress() {
            const newProgressRefs = allProgress.map((progress) =>
              cache.writeFragment({
                data: progress,
                fragment: fragments.sovLineItemProgress,
                fragmentName: 'SovLineItemProgressProperties',
              })
            )
            return newProgressRefs
          },
        },
      })
      const roundRetention = minimalContract?.roundRetention ?? false
      updatedProgress.forEach((progress) => {
        const oldProgress = payApp.progress.find((someProgress) => someProgress.id === progress.id)
        if (oldProgress) {
          const { previousBilled: newPreviousBilled, currentRetention: newCurrentRetention } =
            getUpdatedProgressFields(
              oldProgress,
              progress.sovLineItem.previousBilled,
              progress.sovLineItem.defaultRetentionPercent ?? 0,
              roundRetention
            )
          cache.modify({
            id: cache.identify(progress),
            fields: {
              previousBilled() {
                return newPreviousBilled
              },
              currentRetention() {
                return newCurrentRetention
              },
            },
          })
        }
      })

      // OnboardedStatus.addedSov might change
      invalidateContractsAfterOnboardingStatusChange(cache)
    },
    refetchQueries: [
      ...(payApp
        ? [
            {
              query: GetPayAppForProgressDocument,
              variables: {
                payAppId: payApp.id,
              },
            },
          ]
        : []),
      ...(minimalContract
        ? [
            {
              query: ChangeOrderRequestsDocument,
              variables: { contractId: minimalContract.id },
            },
          ]
        : []),
    ],
  })

  type LineItemForInput = Pick<
    SovLineItemProgressProperties['sovLineItem'],
    | 'id'
    | 'code'
    | 'name'
    | 'costCode'
    | 'sortOrder'
    | 'sovLineItemGroup'
    | 'originalTotalValue'
    | 'previousBilled'
    | 'latestTotalValue'
    | 'isChangeOrder'
    | 'changeOrderApprovedDate'
    | 'changeOrderEffectiveDate'
    | 'defaultRetentionPercent'
    | 'preSitelineRetentionHeldOverride'
    | 'unitName'
    | 'unitPrice'
    | 'taxGroup'
  >

  async function saveSov<T>({
    newLineItems,
    newGroups,
    oldContract,
    lineItemToSovLineItem,
    t,
    onSaved,
    preSitelineRetentionHeldOverride,
    defaultRetentionPercent,
  }: {
    newLineItems: T[]
    newGroups: Pick<SovLineItemGroupProperties, 'id' | 'code' | 'name'>[]
    oldContract: ContractForUpdatingSov
    lineItemToSovLineItem: (lineItem: T) => LineItemForInput
    t: TFunction
    // Called when a save request is made; not called if a confirmation was shown and dismissed.
    // If provided, the callback should be called as the last thing in the function.
    onSaved?: (callback: () => void) => void
    preSitelineRetentionHeldOverride?: number | null
    defaultRetentionPercent?: number | null
  }) {
    const oldGroups = _.chain(oldContract.sov?.lineItems)
      .map((lineItem) => lineItem.sovLineItemGroup)
      .compact()
      .uniqBy((group) => group.id)
      .value()
    // Before calling this save function, we first check below if any empty groups will be deleted
    // by the update; if so, we confirm with the user before saving
    async function save() {
      // Make sure we don't include empty groups in the list
      const groupsToSave = newGroups.filter((group) =>
        newLineItems.some(
          (lineItem) => lineItemToSovLineItem(lineItem).sovLineItemGroup?.id === group.id
        )
      )

      setSaving(true)
      try {
        // Update the SOV groups
        const groupsToDelete = oldGroups.filter(
          (group) => !groupsToSave.some((newGroup) => newGroup.id === group.id)
        )
        for (const group of groupsToDelete) {
          await deleteGroup({ variables: { id: group.id } })
        }

        const groupsToUpdate = groupsToSave.filter((group) => {
          const initialGroup = oldGroups.find((lineItemGroup) => lineItemGroup.id === group.id)
          if (!initialGroup) {
            return false
          }
          // Since we support empty codes, consider null and the empty string equivalent
          if ((initialGroup.code || null) !== (group.code || null)) {
            return true
          }
          return initialGroup.name !== group.name
        })
        for (const group of groupsToUpdate) {
          await updateGroup({
            variables: {
              input: {
                id: group.id,
                groupName: group.name,
                groupCode: group.code || null,
              },
            },
          })
        }

        const groupsToCreate = groupsToSave.filter(
          (group) => !oldGroups.some((existingGroup) => existingGroup.id === group.id)
        )
        const createGroupsResult = []
        for (const group of groupsToCreate) {
          const result = await createGroup({
            variables: {
              input: {
                contractId: contractForOnboarding?.id ?? '',
                groupName: group.name,
                groupCode: group.code || null,
              },
            },
          })
          createGroupsResult.push(result)
        }
        const createdGroups = createGroupsResult
          .map((newGroupResult) => newGroupResult.data?.createSovLineItemGroup)
          .filter((group): group is SovLineItemGroupProperties => !!group)
        const newGroupsByTempId = _.zipObject(
          groupsToCreate.map((group) => group.id),
          createdGroups
        )

        // Update the SOV
        const sortedLineItems = _.orderBy(
          newLineItems,
          (lineItem) => lineItemToSovLineItem(lineItem).sortOrder
        )
        const sovLineItemInputs: SovLineItemInput[] = sortedLineItems.map((lineItem, index) => {
          const sovLineItem = lineItemToSovLineItem(lineItem)
          let sovLineItemGroupId = sovLineItem.sovLineItemGroup?.id ?? null
          if (sovLineItemGroupId && sovLineItemGroupId in newGroupsByTempId) {
            sovLineItemGroupId = newGroupsByTempId[sovLineItemGroupId].id
          }
          const includeRetentionFields = usesStandardOrLineItemTracking(
            oldContract.retentionTrackingLevel
          )
          return {
            id: sovLineItem.id,
            code: sovLineItem.code,
            sortOrder: index + 1,
            name: sovLineItem.name,
            costCode: sovLineItem.costCode,
            originalTotalValue: sovLineItem.originalTotalValue,
            latestTotalValue: sovLineItem.latestTotalValue,
            previousBilled: sovLineItem.previousBilled,
            isChangeOrder: sovLineItem.isChangeOrder,
            changeOrderApprovedDate: sovLineItem.changeOrderApprovedDate,
            changeOrderEffectiveDate: sovLineItem.changeOrderEffectiveDate,
            sovLineItemGroupId,
            defaultRetentionPercent: includeRetentionFields
              ? sovLineItem.defaultRetentionPercent
              : null,
            preSitelineRetentionHeldOverride: includeRetentionFields
              ? sovLineItem.preSitelineRetentionHeldOverride
              : null,
            unitName: sovLineItem.unitName,
            unitPrice: sovLineItem.unitPrice,
            taxGroupId: sovLineItem.taxGroup?.id ?? null,
          }
        })
        await updateSov({
          variables: {
            input: {
              sovId: oldContract.sov?.id ?? '',
              lineItems: sovLineItemInputs,
              ...(isProjectHoldingRetention && { preSitelineRetentionHeldOverride }),
              defaultRetentionPercent,
            },
          },
        })

        snackbar.showSuccess(t(`${i18nBase}.sov_saved`))
        if (onSaved) {
          onSaved(() => {
            setSaving(false)
          })
        } else {
          setSaving(false)
        }
      } catch (err) {
        snackbar.showError(err.message)
        setSaving(false)
      }
    }

    // Check if there are empty groups that will be deleted before saving
    const emptyGroups = newGroups.filter(
      (group) =>
        !newLineItems.some(
          (lineItem) => lineItemToSovLineItem(lineItem).sovLineItemGroup?.id === group.id
        )
    )
    if (emptyGroups.length > 0) {
      confirm({
        title: t(`${i18nBase}.confirm_empty_groups`, { count: emptyGroups.length }),
        details: t(`${i18nBase}.confirm_empty_groups_description`),
        confirmLabel: t('common.actions.continue'),
        maxWidth: 'sm',
        callback: async (confirmed: boolean) => {
          if (confirmed) {
            await save()
          }
        },
      })
    } else {
      await save()
    }
  }

  return [saveSov, saving] as const
}

export type SovChangeSetUpdateLineItem = SovChangeSetProperties['updates'][number]

type GetChangeSetLineItemUpdates = {
  lineItem: EditingSovLineItem
  update: SovChangeSetUpdateLineItem | undefined
  timeZone: string
  includePreSitelineBillingColumn: boolean
  includeRetentionPercentColumn: boolean
  includePreSitelineRetentionColumns: boolean
}

/** Compares a line item to the update in a change set and returns which fields changed */
export function getChangeSetLineItemUpdates({
  lineItem,
  update,
  timeZone,
  includeRetentionPercentColumn,
  includePreSitelineRetentionColumns,
  includePreSitelineBillingColumn,
}: GetChangeSetLineItemUpdates) {
  const updatedLineItem = update?.new

  // Sort order
  const newSortOrder =
    updatedLineItem && _.isNumber(updatedLineItem.sortOrder)
      ? updatedLineItem.sortOrder
      : lineItem.sortOrder
  const isSortOrderUpdated = newSortOrder !== lineItem.sortOrder

  // Code
  const newCode =
    updatedLineItem && _.isString(updatedLineItem.code) ? updatedLineItem.code : lineItem.code
  const isCodeUpdated = newCode !== lineItem.code

  // Name
  const newName =
    updatedLineItem && _.isString(updatedLineItem.name) ? updatedLineItem.name : lineItem.name
  const isNameUpdated = newName !== lineItem.name

  // Cost code
  const newCostCode = updatedLineItem?.costCode ?? lineItem.costCode
  const isCostCodeUpdated = newCostCode !== lineItem.costCode

  // Original total value
  const newOriginalTotalValue =
    updatedLineItem && _.isNumber(updatedLineItem.originalTotalValue)
      ? updatedLineItem.originalTotalValue
      : lineItem.originalTotalValue
  const isOriginalTotalValueUpdated = newOriginalTotalValue !== lineItem.originalTotalValue

  // Latest total value
  const newLatestTotalValue =
    updatedLineItem && _.isNumber(updatedLineItem.latestTotalValue)
      ? updatedLineItem.latestTotalValue
      : lineItem.latestTotalValue
  const isLatestTotalValueUpdated = newLatestTotalValue !== lineItem.latestTotalValue

  // Pre-Siteline billing
  let newPreSitelineBilling =
    updatedLineItem && _.isNumber(updatedLineItem.previousBilled)
      ? updatedLineItem.previousBilled
      : lineItem.preSitelineBilling
  if (newPreSitelineBilling === undefined) {
    // If no pre-Siteline billing in the update or original line item, show it as $0
    newPreSitelineBilling = 0
  }
  const isPreSitelineBillingUpdated =
    includePreSitelineBillingColumn && newPreSitelineBilling !== (lineItem.preSitelineBilling ?? 0)

  // Pre-Siteline retention $
  let newPreSitelineRetentionAmount =
    updatedLineItem && _.isNumber(updatedLineItem.preSitelineRetentionHeldOverride)
      ? updatedLineItem.preSitelineRetentionHeldOverride
      : lineItem.preSitelineRetentionAmount
  if (newPreSitelineRetentionAmount === undefined) {
    // If no pre-Siteline billing in the update or original line item, show it as $0
    newPreSitelineRetentionAmount = 0
  }
  const isPreSitelineRetentionAmountUpdated =
    includePreSitelineRetentionColumns &&
    newPreSitelineRetentionAmount !== (lineItem.preSitelineRetentionAmount ?? 0)

  // Default retention %
  const newDefaultRetentionPercent =
    updatedLineItem && _.isNumber(updatedLineItem.defaultRetentionPercent)
      ? updatedLineItem.defaultRetentionPercent
      : lineItem.defaultRetentionPercent
  const isDefaultRetentionPercentUpdated =
    includeRetentionPercentColumn &&
    (newDefaultRetentionPercent ?? 0) !== (lineItem.defaultRetentionPercent ?? 0)

  // Change order approved date
  let newChangeOrderApprovedAt: Moment | undefined | null = null
  const oldChangeOrderApprovedAt = lineItem.changeOrderApprovedAt
    ? moment.tz(lineItem.changeOrderApprovedAt, timeZone)
    : null
  let newChangeOrderEffectiveAt: Moment | undefined | null = null
  const oldChangeOrderEffectiveAt = lineItem.changeOrderEffectiveAt
    ? moment.tz(lineItem.changeOrderEffectiveAt, timeZone)
    : null
  const newIsChangeOrder =
    updatedLineItem && _.isBoolean(updatedLineItem.isChangeOrder)
      ? updatedLineItem.isChangeOrder
      : lineItem.isChangeOrder
  if (newIsChangeOrder) {
    newChangeOrderApprovedAt = updatedLineItem?.changeOrderApprovedDate
      ? moment.tz(updatedLineItem.changeOrderApprovedDate, timeZone)
      : (oldChangeOrderApprovedAt ?? moment.tz(timeZone))
    newChangeOrderEffectiveAt = updatedLineItem?.changeOrderEffectiveDate
      ? moment.tz(updatedLineItem.changeOrderEffectiveDate, timeZone)
      : (oldChangeOrderEffectiveAt ?? null)
  } else if (!updatedLineItem) {
    newChangeOrderApprovedAt = oldChangeOrderApprovedAt
    newChangeOrderEffectiveAt = oldChangeOrderEffectiveAt
  }
  let isChangeOrderApprovedAtUpdated = false
  if (updatedLineItem) {
    isChangeOrderApprovedAtUpdated = _.isNil(newChangeOrderApprovedAt)
      ? !_.isNil(oldChangeOrderApprovedAt)
      : !newChangeOrderApprovedAt.isSame(oldChangeOrderApprovedAt, 'date')
  }
  let isChangeOrderEffectiveAtUpdated = false
  if (updatedLineItem) {
    isChangeOrderEffectiveAtUpdated = _.isNil(newChangeOrderEffectiveAt)
      ? !_.isNil(oldChangeOrderEffectiveAt)
      : !newChangeOrderEffectiveAt.isSame(oldChangeOrderEffectiveAt, 'date')
  }

  // Unit name
  const newUnitName =
    updatedLineItem && _.isString(updatedLineItem.unitName)
      ? updatedLineItem.unitName
      : (lineItem.unitName ?? undefined)
  const isUnitNameUpdated = newUnitName !== lineItem.unitName

  // Unit price
  const newUnitPrice =
    updatedLineItem && _.isNumber(updatedLineItem.unitPrice)
      ? updatedLineItem.unitPrice
      : (lineItem.unitPrice ?? undefined)
  const isUnitPriceUpdated = newUnitPrice !== lineItem.unitPrice

  // Group
  const newGroupId = updatedLineItem?.sovLineItemGroupId ?? lineItem.groupId
  const isGroupUpdated = newGroupId !== lineItem.groupId

  return {
    sortOrder: newSortOrder,
    isSortOrderUpdated,
    code: newCode,
    isCodeUpdated,
    name: newName,
    costCode: newCostCode,
    isCostCodeUpdated,
    isNameUpdated,
    originalTotalValue: newOriginalTotalValue,
    isOriginalTotalValueUpdated,
    latestTotalValue: newLatestTotalValue,
    isLatestTotalValueUpdated,
    defaultRetentionPercent: newDefaultRetentionPercent,
    isDefaultRetentionPercentUpdated,
    preSitelineRetentionAmount: newPreSitelineRetentionAmount,
    isPreSitelineRetentionAmountUpdated,
    preSitelineBilling: newPreSitelineBilling,
    isPreSitelineBillingUpdated,
    changeOrderApprovedAt: newChangeOrderApprovedAt,
    isChangeOrderApprovedAtUpdated,
    changeOrderEffectiveAt: newChangeOrderEffectiveAt,
    isChangeOrderEffectiveAtUpdated,
    unitName: newUnitName ?? '',
    isUnitNameUpdated,
    unitPrice: newUnitPrice ?? 0,
    isUnitPriceUpdated,
    // True if any of the above fields were updated
    isLineItemUpdated:
      isSortOrderUpdated ||
      isCodeUpdated ||
      isNameUpdated ||
      isCostCodeUpdated ||
      isOriginalTotalValueUpdated ||
      isLatestTotalValueUpdated ||
      isPreSitelineBillingUpdated ||
      isChangeOrderApprovedAtUpdated ||
      isChangeOrderEffectiveAtUpdated ||
      isDefaultRetentionPercentUpdated ||
      isPreSitelineRetentionAmountUpdated ||
      isUnitNameUpdated ||
      isUnitPriceUpdated ||
      isGroupUpdated,
  }
}

export type SovChangeSetUpdateLineItemGroup = SovChangeSetProperties['groupUpdates'][number]

type GetChangeSetLineItemGroupUpdates = {
  group: EditingSovLineItemGroup
  update: SovChangeSetUpdateLineItemGroup | null
}

/** Compares a line item group to the update in a change set and returns which fields changed */
export function getChangeSetLineItemGroupUpdates({
  group,
  update,
}: GetChangeSetLineItemGroupUpdates) {
  const newName = _.isString(update?.new.name) ? update.new.name : group.name
  const isNameUpdated = newName !== group.name

  const newCode = _.isString(update?.new.code) ? update.new.code : group.code
  const isCodeUpdated = newCode !== group.code

  return {
    code: newCode,
    isCodeUpdated,
    name: newName,
    isNameUpdated,
    // True if any of the above fields were updated
    isGroupUpdated: isCodeUpdated || isNameUpdated,
  }
}

// Temporary type until we remove deprecated fields from SovChangeSetCreateLineItem
type SovChangeSetCreateLineItemWithoutDeprecatedFields = Omit<
  SovChangeSetCreateLineItem,
  'progressRetentionPercent' | 'totalValue'
>

/** Check the list of unadded line items to find one with a given sort order */
function addLineItemIndexForSortOrder(
  unaddedLineItems: SovChangeSetCreateLineItemWithoutDeprecatedFields[],
  sortOrder: number
) {
  return unaddedLineItems.findIndex(
    (addLineItem) => addLineItem.sortOrder && addLineItem.sortOrder === sortOrder
  )
}

function changeSetLineItemToEditingSovLineItem(
  lineItem: SovChangeSetCreateLineItemWithoutDeprecatedFields,
  timeZone: string
) {
  const editingSovLineItem: EditingSovLineItem = {
    id: uuidv4(),
    sortOrder: lineItem.sortOrder ?? undefined,
    code: lineItem.code,
    name: lineItem.name,
    costCode: lineItem.costCode,
    originalTotalValue: lineItem.originalTotalValue,
    latestTotalValue: lineItem.latestTotalValue,
    preSitelineBilling: lineItem.previousBilled,
    preSitelineRetentionAmount: lineItem.preSitelineRetentionHeldOverride,
    defaultRetentionPercent: lineItem.defaultRetentionPercent,
    latestRetentionPercent: lineItem.defaultRetentionPercent,
    billedToDate: lineItem.previousBilled,
    groupId: lineItem.sovLineItemGroupId,
    isChangeOrder: lineItem.isChangeOrder,
    changeOrderApprovedAt: lineItem.changeOrderApprovedDate
      ? moment.tz(lineItem.changeOrderApprovedDate, timeZone)
      : null,
    unitName: lineItem.unitName ?? undefined,
    unitPrice: lineItem.unitPrice ?? undefined,
    worksheetLineItems: [],
    changeOrderRequests: [],
  }
  return editingSovLineItem
}

/**
 * Given a set of unadded line items and the sort order of an SOV,
 * return the subset of unadded line items that should be added at this sort order
 * and the new list of unadded line items
 */
export function insertUnaddedLineItems(
  unaddedLineItems: SovChangeSetCreateLineItemWithoutDeprecatedFields[],
  sortOrder: number,
  timeZone: string
) {
  const lineItemsToAdd = [...unaddedLineItems]
  const insertLineItems = []
  // Add any new line items from the change set that belong in this position, according to
  // their sort order. Each added line item increments the sort order, so we check again each
  // time in case multiple line items should be added.
  let checkSortOrder = sortOrder
  let insertLineItemIndex = addLineItemIndexForSortOrder(lineItemsToAdd, checkSortOrder)
  while (insertLineItemIndex >= 0) {
    const insertLineItem = lineItemsToAdd[insertLineItemIndex]
    lineItemsToAdd.splice(insertLineItemIndex, 1)
    insertLineItems.push(changeSetLineItemToEditingSovLineItem(insertLineItem, timeZone))

    checkSortOrder++
    insertLineItemIndex = addLineItemIndexForSortOrder(lineItemsToAdd, checkSortOrder)
  }
  return { insertLineItems, remainingUnaddedLineItems: lineItemsToAdd }
}

/**
 * Returns rows to add to a particular position in an SOV if a group header or divider
 * should be shown before the next row, based on whether the next row and preceding
 * row are in different groupings.
 */
export function getInitialGroupingRows<T extends { id: string; groupId: string | null }>(
  /** The group the next line item belongs to, or null if ungrouped */
  nextLineItemGroup: EditingSovLineItemGroup | null,
  nextRowIndex: number,
  lineItems: T[],
  changeSetUpdate: SovChangeSetProperties['groupUpdates'][number] | null,
  {
    numColumns,
    showWarningIfGroupEmpty,
    isGroupEditable,
    isGroupReorderable,
    onDeleteGroup,
    numPreCodeColumns,
  }: {
    numColumns: number
    showWarningIfGroupEmpty: boolean
    isGroupEditable: boolean
    isGroupReorderable: boolean
    onDeleteGroup?: (groupId: string) => void
    numPreCodeColumns?: number
  }
) {
  const rows: SpreadsheetRow[] = []

  // Show a group header row iff the line item belongs to a group and
  // a header for that group hasn't already been shown
  const showGroupHeader =
    nextLineItemGroup &&
    (nextRowIndex === 0 || lineItems[nextRowIndex - 1].groupId !== nextLineItemGroup.id)
  if (showGroupHeader) {
    const groupHeaderRow = getLineItemGroupHeaderRow(nextLineItemGroup, changeSetUpdate, {
      numColumns,
      showWarningIfEmpty: showWarningIfGroupEmpty,
      isEditable: isGroupEditable,
      isReorderable: isGroupReorderable,
      onDelete: onDeleteGroup,
      numPreCodeColumns,
    })
    // Add a divider above the group if not at the very top of the SOV
    if (nextRowIndex > 0) {
      const dividerRow = makeDividerRow(`${nextLineItemGroup.id}-divider`)
      rows.push(dividerRow)
    }
    // Add the group header row.
    rows.push(groupHeaderRow)

    return { rows, isFirstInUngroupedBlock: false }
  }

  // If the next row isn't first in the SOV and is in a different grouping than the
  // preceding line item, add a divider between the two groups
  const nextLineItem = lineItems[nextRowIndex]
  if (nextRowIndex > 0 && lineItems[nextRowIndex - 1].groupId !== nextLineItem.groupId) {
    rows.push(makeDividerRow(`${nextLineItem.id}-divider`))
    return { rows, isFirstInUngroupedBlock: true }
  }

  return { rows: [], isFirstInUngroupedBlock: false }
}

export enum PreviewLineItemChange {
  UNCHANGED = 'UNCHANGED',
  ADDED = 'ADDED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED',
}

// Same as PreviewLineItemChange but it would be error prone to share the same type
export enum PreviewLineItemGroupChange {
  UNCHANGED = 'UNCHANGED',
  ADDED = 'ADDED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED',
}

/**
 * Given an SOV and a change set, return the line items that should be shown to preview
 * the final SOV. This includes deleted line items, updated line items, and added line items,
 * and will be sorted in the order line items should be shown.
 */
export function getPreviewLineItems(
  sovLineItems: EditingSovLineItem[],
  changeSet: SovChangeSetProperties,
  {
    includePreSitelineBillingColumn,
    includeRetentionPercentColumn,
    includePreSitelineRetentionColumns,
    includeGroups,
    timeZone,
  }: {
    includePreSitelineBillingColumn: boolean
    includeRetentionPercentColumn: boolean
    includePreSitelineRetentionColumns: boolean
    // If not including groups (e.g. importing a full SOV from an integration), group IDs
    // will be removed from line items
    includeGroups: boolean
    timeZone: string
  }
) {
  // Create a list of line items to be added from the change set. Line items will
  // be pulled out of this list as they're added to the final list of line items.
  let unaddedLineItems: SovChangeSetCreateLineItemWithoutDeprecatedFields[] = _.chain(
    changeSet.additions
  )
    .map((addition) => ({ ...addition.new, totalValue: addition.new.originalTotalValue }))
    .orderBy((lineItem) => lineItem.sortOrder)
    .value()

  // Keep track of the sort order of the latest line item added to the final SOV, i.e.
  // the "after" SOV once the incoming changes are applied. This is used to insert new
  // incoming line items in the right place.
  let currentSortOrder = 1

  const lineItemsWithChange = sovLineItems.flatMap((lineItem) => {
    const lineItems: { lineItem: EditingSovLineItem; change: PreviewLineItemChange }[] = []

    const { insertLineItems, remainingUnaddedLineItems } = insertUnaddedLineItems(
      unaddedLineItems,
      currentSortOrder,
      timeZone
    )
    // Add all line items from the change set that are being newly inserted into the SOV
    lineItems.push(
      ...insertLineItems.map((lineItem) => ({ lineItem, change: PreviewLineItemChange.ADDED }))
    )
    unaddedLineItems = [...remainingUnaddedLineItems]
    currentSortOrder += insertLineItems.length

    const isLineItemDeleted = changeSet.deletions.some((deletion) => deletion.oldId === lineItem.id)
    const changeSetUpdate = changeSet.updates.find((update) => update.oldId === lineItem.id)
    const { isLineItemUpdated } = getChangeSetLineItemUpdates({
      lineItem,
      update: changeSetUpdate,
      timeZone,
      includePreSitelineBillingColumn,
      includeRetentionPercentColumn,
      includePreSitelineRetentionColumns,
    })
    let change = PreviewLineItemChange.UNCHANGED
    if (isLineItemDeleted) {
      change = PreviewLineItemChange.DELETED
    } else if (isLineItemUpdated) {
      change = PreviewLineItemChange.UPDATED
    }
    // Add the current line item, and tag it with the appropriate change type
    lineItems.push({ lineItem, change })

    if (!isLineItemDeleted) {
      currentSortOrder++
    }

    return lineItems
  })

  // If there are any remaining line items to add from the change set, add them at the end
  const remainingUnaddedLineItemRows = unaddedLineItems.map((newLineItem) => ({
    lineItem: changeSetLineItemToEditingSovLineItem(newLineItem, timeZone),
    change: PreviewLineItemChange.ADDED,
  }))
  lineItemsWithChange.push(...remainingUnaddedLineItemRows)

  return lineItemsWithChange.map(({ lineItem, change }) => ({
    lineItem: {
      ...lineItem,
      groupId: includeGroups ? lineItem.groupId : null,
    },
    change,
  }))
}

type GetPreviewGroupsProps = {
  existingGroups: EditingSovLineItemGroup[]
  changeSet: SovChangeSetProperties
}

type GroupWithChange = { group: EditingSovLineItemGroup; change: PreviewLineItemGroupChange | null }

/**
 * Returns a final list of groups given the original SOV groups and a changeset, alonside the change
 * that is being made to each group (if any).
 */
export function getPreviewGroups({
  existingGroups,
  changeSet,
}: GetPreviewGroupsProps): GroupWithChange[] {
  const existing = existingGroups.map((group): GroupWithChange => {
    const update = changeSet.groupUpdates.find((update) => update.oldId === group.id)
    if (update) {
      const { isGroupUpdated, name, code } = getChangeSetLineItemGroupUpdates({ group, update })
      return {
        group: { id: group.id, name, code },
        change: isGroupUpdated
          ? PreviewLineItemGroupChange.UPDATED
          : PreviewLineItemGroupChange.UNCHANGED,
      }
    }

    const deletion = changeSet.groupDeletions.find((deletion) => deletion.oldId === group.id)
    if (deletion) {
      return {
        group,
        change: PreviewLineItemGroupChange.DELETED,
      }
    }

    return { group, change: null }
  })
  const additions = changeSet.groupAdditions.map((addition): GroupWithChange => {
    return {
      group: {
        id: addition.newId,
        name: addition.new.name,
        code: addition.new.code ?? '',
      },
      change: PreviewLineItemGroupChange.ADDED,
    }
  })

  return [...existing, ...additions]
}
