import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { Dispatch, memo, ReactNode } from 'react'
import { colors, makeStylesFast } from 'siteline-common-web'
import { clampToLines } from '../../util/CSS'
import { SpreadsheetSizingAction } from './SpreadsheetSizingReducer'

const useStyles = makeStylesFast((theme: Theme) => ({
  hidden: {
    visibility: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  cellText: {
    // Match smallText style on SitelineText
    ...theme.typography.h6,
    // Until the correct column widths are calculated, narrow columns may cause
    // text to wrap too much and the header to appear much too tall while loading.
    // We assume header text will never wrap beyond 2 lines, which is how much fits
    // within the default header height.
    ...clampToLines(2),
    color: colors.grey50,
    '&.isBold': {
      fontWeight: 600,
    },
  },
}))

interface SpreadsheetHeaderCellProps {
  heading: ReactNode
  columnIndex: number
  isColumnEditable?: boolean
  dispatchSizing?: Dispatch<SpreadsheetSizingAction>
}

/** A header cell in a spreadsheet */
export const SpreadsheetHeaderCell = memo(function SpreadsheetHeaderCell({
  heading,
  columnIndex,
  isColumnEditable,
  dispatchSizing,
}: SpreadsheetHeaderCellProps) {
  const classes = useStyles()

  const cellSizing = (element: HTMLDivElement | null) => {
    if (element && dispatchSizing) {
      dispatchSizing({
        type: 'UPDATE_HEADER_WIDTH',
        columnIndex,
        width: element.clientWidth,
      })
    }
  }

  let header = heading
  if (typeof header === 'string') {
    header = <span className={clsx(classes.cellText, { isBold: isColumnEditable })}>{heading}</span>
  }

  return (
    <>
      {/* Hidden content for sizing the cell */}
      <div ref={cellSizing} className={classes.hidden}>
        {header}
      </div>
      {header}
    </>
  )
})
