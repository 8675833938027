import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { TextField, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import {
  CompanyIntegrationMetadataQuickbooks,
  QUICKBOOKS_ITEM_MAX_CHAR_COUNT,
} from 'siteline-common-all'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { QUICKBOOKS_SETTINGS_LABEL_WIDTH } from '../billing/settings/QuickbooksProjectSettings'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 0),
      '&.editing': {
        padding: theme.spacing(0.5, 0),
      },
      '& .label': {
        minWidth: QUICKBOOKS_SETTINGS_LABEL_WIDTH,
        maxWidth: QUICKBOOKS_SETTINGS_LABEL_WIDTH,
      },
      '& .input': {
        width: 275,
      },
      '& .tooltipIcon': {
        fontSize: 16,
      },
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
}))

const i18nBase = 'settings.quickbooks'

interface QuickbooksItemSettingsProps {
  metadata: CompanyIntegrationMetadataQuickbooks
  onMetadataChange: (metadata: CompanyIntegrationMetadataQuickbooks) => void
  isEditing: boolean

  /** If editing overrides, we show the global metadata as placeholders */
  placeholderMetadata?: CompanyIntegrationMetadataQuickbooks
}

/** Inputs for editing Quickbooks Item/Account settings */
export function QuickbooksItemSettings({
  metadata,
  onMetadataChange,
  isEditing,
  placeholderMetadata,
}: QuickbooksItemSettingsProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.ar_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.ar_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.accountsReceivableAccount}
            onChange={(event) =>
              onMetadataChange({ ...metadata, accountsReceivableAccount: event.target.value })
            }
            className="input"
            placeholder={placeholderMetadata?.accountsReceivableAccount}
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.accountsReceivableAccount ? 'grey90' : 'grey50'}
          >
            {metadata.accountsReceivableAccount || placeholderMetadata?.accountsReceivableAccount}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_ar_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_ar_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.retentionAccountsReceivableAccount}
            onChange={(event) =>
              onMetadataChange({
                ...metadata,
                retentionAccountsReceivableAccount: event.target.value,
              })
            }
            className="input"
            placeholder={placeholderMetadata?.retentionAccountsReceivableAccount}
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.retentionAccountsReceivableAccount ? 'grey90' : 'grey50'}
          >
            {metadata.retentionAccountsReceivableAccount ||
              placeholderMetadata?.retentionAccountsReceivableAccount}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.invoice_item_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.invoice_item`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.progressItemName}
            onChange={(event) =>
              onMetadataChange({ ...metadata, progressItemName: event.target.value })
            }
            inputProps={{ maxLength: QUICKBOOKS_ITEM_MAX_CHAR_COUNT }}
            className="input"
            placeholder={placeholderMetadata?.progressItemName}
          />
        ) : (
          <SitelineText variant="body1" color={metadata.progressItemName ? 'grey90' : 'grey50'}>
            {metadata.progressItemName || placeholderMetadata?.progressItemName}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.income_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.income_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.progressItemIncomeAccount}
            onChange={(event) =>
              onMetadataChange({ ...metadata, progressItemIncomeAccount: event.target.value })
            }
            className="input"
            placeholder={placeholderMetadata?.progressItemIncomeAccount}
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.progressItemIncomeAccount ? 'grey90' : 'grey50'}
          >
            {metadata.progressItemIncomeAccount || placeholderMetadata?.progressItemIncomeAccount}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_item_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_item`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.retentionItemName}
            onChange={(event) =>
              onMetadataChange({ ...metadata, retentionItemName: event.target.value })
            }
            inputProps={{ maxLength: QUICKBOOKS_ITEM_MAX_CHAR_COUNT }}
            className="input"
            placeholder={placeholderMetadata?.retentionItemName}
          />
        ) : (
          <SitelineText variant="body1" color={metadata.retentionItemName ? 'grey90' : 'grey50'}>
            {metadata.retentionItemName || placeholderMetadata?.retentionItemName}
          </SitelineText>
        )}
      </div>
      <div className={clsx('row', { editing: isEditing })}>
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            <Tooltip
              title={t(`${i18nBase}.retention_account_description`)}
              placement="bottom-start"
              className="tooltipIcon"
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          }
        >
          {t(`${i18nBase}.retention_account`)}
        </SitelineText>
        {isEditing ? (
          <TextField
            variant="outlined"
            value={metadata.retentionItemIncomeAccount}
            onChange={(event) =>
              onMetadataChange({ ...metadata, retentionItemIncomeAccount: event.target.value })
            }
            className="input"
            placeholder={placeholderMetadata?.retentionItemIncomeAccount}
          />
        ) : (
          <SitelineText
            variant="body1"
            color={metadata.retentionItemIncomeAccount ? 'grey90' : 'grey50'}
          >
            {metadata.retentionItemIncomeAccount || placeholderMetadata?.retentionItemIncomeAccount}
          </SitelineText>
        )}
      </div>
    </div>
  )
}
