/** Calculates the equivalent of the CSS "vh" function */
export function vh(v: number) {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  return (v * h) / 100
}

/** Calculates the equivalent of the CSS "vw" function */
export function vw(v: number) {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (v * w) / 100
}
