import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Collapse, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CompanyIntegrationMetadataQuickbooks,
  IntegrationType,
  QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT,
  QUICKBOOKS_JOB_MAX_CHAR_COUNT,
} from 'siteline-common-all'
import { QuickbooksIntegrationMetadata } from 'siteline-common-all/src/types/integration'
import { SitelineTooltip, colors, makeStylesFast } from 'siteline-common-web'
import { TextFieldWithLabel } from '../../../common/components/TextFieldWithLabel'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { findCompanyIntegrationOfType } from '../../../common/util/Integration'
import { QuickbooksItemSettings } from '../../settings/QuickbooksItemSettings'
import { SettingsRow } from './SettingsRow'

export const QUICKBOOKS_SETTINGS_LABEL_WIDTH = 275
const useStyles = makeStylesFast((theme: Theme) => ({
  section: {
    marginBottom: theme.spacing(2),
  },
  input: {
    margin: theme.spacing(-1, 0),
    width: 250,
  },
  infoIcon: {
    fontSize: 16,
    color: colors.grey50,
  },
}))

const i18nBase = 'projects.subcontractors.pay_app.invoice.export.quickbooks_enterprise'

export type QuickbooksProjectMetadata = Pick<
  QuickbooksIntegrationMetadata,
  | 'projectName'
  | 'customerName'
  | 'accountsReceivableAccount'
  | 'retentionAccountsReceivableAccount'
  | 'progressItemName'
  | 'progressItemIncomeAccount'
  | 'retentionItemName'
  | 'retentionItemIncomeAccount'
>

interface QuickbooksProjectSettingsProps {
  /**
   * Compact settings includes only customer and job in stacked fields.
   * Full settings include customer and job in inline fields along with a collapsible section
   * for overriding the global defaults for progress and retention Item and Account settings.
   */
  type: 'compact' | 'full'
  isEditing: boolean
  metadata: QuickbooksProjectMetadata
  onMetadataChange: (metadata: QuickbooksProjectMetadata) => void
  className?: string

  customerPlaceholder?: string
  jobPlaceholder?: string
}

/** Quickbooks integration view for editing job and customer name */
export function QuickbooksProjectSettings({
  type,
  isEditing,
  metadata,
  onMetadataChange,
  customerPlaceholder,
  jobPlaceholder,
  className,
}: QuickbooksProjectSettingsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { company } = useCompanyContext()
  const globalMetadata = useMemo(() => {
    const companyIntegration = findCompanyIntegrationOfType(
      company,
      IntegrationType.QUICKBOOKS_ENTERPRISE_FILE
    )
    if (!companyIntegration) {
      return undefined
    }
    return companyIntegration.metadata as CompanyIntegrationMetadataQuickbooks
  }, [company])

  const hasNonDefaultItemSettings = useMemo(() => {
    if (!globalMetadata) {
      return false
    }
    return (
      (_.isString(metadata.accountsReceivableAccount) &&
        metadata.accountsReceivableAccount !== globalMetadata.accountsReceivableAccount) ||
      (_.isString(metadata.retentionAccountsReceivableAccount) &&
        metadata.retentionAccountsReceivableAccount !==
          globalMetadata.retentionAccountsReceivableAccount) ||
      (_.isString(metadata.progressItemName) &&
        metadata.progressItemName !== globalMetadata.progressItemName) ||
      (_.isString(metadata.progressItemIncomeAccount) &&
        metadata.progressItemIncomeAccount !== globalMetadata.progressItemIncomeAccount) ||
      (_.isString(metadata.retentionItemName) &&
        metadata.retentionItemName !== globalMetadata.retentionItemName) ||
      (_.isString(metadata.retentionItemIncomeAccount) &&
        metadata.retentionItemIncomeAccount !== globalMetadata.retentionItemIncomeAccount)
    )
  }, [
    globalMetadata,
    metadata.accountsReceivableAccount,
    metadata.progressItemIncomeAccount,
    metadata.progressItemName,
    metadata.retentionAccountsReceivableAccount,
    metadata.retentionItemIncomeAccount,
    metadata.retentionItemName,
  ])

  const itemMetadata: CompanyIntegrationMetadataQuickbooks = useMemo(
    () => ({
      accountsReceivableAccount: metadata.accountsReceivableAccount ?? '',
      retentionAccountsReceivableAccount: metadata.retentionAccountsReceivableAccount ?? '',
      progressItemName: metadata.progressItemName ?? '',
      progressItemIncomeAccount: metadata.progressItemIncomeAccount ?? '',
      retentionItemName: metadata.retentionItemName ?? '',
      retentionItemIncomeAccount: metadata.retentionItemIncomeAccount ?? '',
    }),
    [
      metadata.accountsReceivableAccount,
      metadata.progressItemIncomeAccount,
      metadata.progressItemName,
      metadata.retentionAccountsReceivableAccount,
      metadata.retentionItemIncomeAccount,
      metadata.retentionItemName,
    ]
  )

  const handleItemMetadataChange = useCallback(
    (itemMetadata: CompanyIntegrationMetadataQuickbooks) => {
      onMetadataChange({
        ...metadata,
        accountsReceivableAccount: itemMetadata.accountsReceivableAccount || undefined,
        retentionAccountsReceivableAccount:
          itemMetadata.retentionAccountsReceivableAccount || undefined,
        progressItemName: itemMetadata.progressItemName || undefined,
        progressItemIncomeAccount: itemMetadata.progressItemIncomeAccount || undefined,
        retentionItemName: itemMetadata.retentionItemName || undefined,
        retentionItemIncomeAccount: itemMetadata.retentionItemIncomeAccount || undefined,
      })
    },
    [metadata, onMetadataChange]
  )

  const handleCustomerNameChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onMetadataChange({ ...metadata, customerName: evt.target.value })
    },
    [metadata, onMetadataChange]
  )

  const handleJobNameChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onMetadataChange({ ...metadata, projectName: evt.target.value })
    },
    [metadata, onMetadataChange]
  )

  return (
    <>
      {type === 'compact' && (
        <>
          <div className={classes.section}>
            <TextFieldWithLabel
              label={t(`${i18nBase}.customer_name`)}
              subtitle={t(`${i18nBase}.customer_subtitle`)}
              value={metadata.customerName}
              onChange={handleCustomerNameChange}
              inputProps={{ maxLength: QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT }}
              placeholder={customerPlaceholder}
            />
          </div>
          <div className={classes.section}>
            <TextFieldWithLabel
              label={t(`${i18nBase}.job_name`)}
              subtitle={t(`${i18nBase}.job_subtitle`)}
              value={metadata.projectName}
              onChange={handleJobNameChange}
              inputProps={{ maxLength: QUICKBOOKS_JOB_MAX_CHAR_COUNT }}
              placeholder={jobPlaceholder}
            />
          </div>
        </>
      )}
      {type === 'full' && (
        <div className={className}>
          <div>
            <SettingsRow
              label={t(`${i18nBase}.customer_name`)}
              labelEndIcon={
                <SitelineTooltip title={t(`${i18nBase}.customer_subtitle`)} placement="top">
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </SitelineTooltip>
              }
              labelWidth={QUICKBOOKS_SETTINGS_LABEL_WIDTH}
              value={metadata.customerName}
              isLoading={false}
              editingValue={
                <TextField
                  fullWidth
                  variant="outlined"
                  value={metadata.customerName}
                  onChange={handleCustomerNameChange}
                  inputProps={{ maxLength: QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT }}
                  className={classes.input}
                  placeholder={customerPlaceholder}
                />
              }
              isEditing={isEditing}
            />
            <SettingsRow
              label={t(`${i18nBase}.job_name`)}
              labelEndIcon={
                <SitelineTooltip title={t(`${i18nBase}.job_subtitle`)} placement="top">
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </SitelineTooltip>
              }
              labelWidth={QUICKBOOKS_SETTINGS_LABEL_WIDTH}
              value={metadata.projectName}
              isLoading={false}
              editingValue={
                <TextField
                  fullWidth
                  variant="outlined"
                  value={metadata.projectName}
                  onChange={handleJobNameChange}
                  inputProps={{ maxLength: QUICKBOOKS_JOB_MAX_CHAR_COUNT }}
                  className={classes.input}
                  placeholder={jobPlaceholder}
                />
              }
              isEditing={isEditing}
            />
          </div>
          <Collapse in={isEditing || hasNonDefaultItemSettings}>
            <QuickbooksItemSettings
              metadata={itemMetadata}
              onMetadataChange={handleItemMetadataChange}
              isEditing={isEditing}
              placeholderMetadata={globalMetadata}
            />
          </Collapse>
        </div>
      )}
    </>
  )
}
