import { LinearProgress } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { integrationTypes } from 'siteline-common-all'
import { SitelineText, makeStylesFast } from 'siteline-common-web'
import { MinimalIntegrationProperties } from '../../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  loadingDialog: {
    '& .animation': {
      marginBottom: theme.spacing(6),
    },
    '& .description': {
      padding: theme.spacing(2, 3),
      whiteSpace: 'pre-wrap',
    },
  },
}))

const i18nBase = `integrations.loading`

type WriteSyncInProgressProps = {
  payload: integrationTypes.WriteSyncPayload
  integration: MinimalIntegrationProperties
}

/**
 * Dialog shown while the syncing is in flight and we're waiting for a response from the server.
 */
export function WriteSyncInProgress({ integration, payload }: WriteSyncInProgressProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const integrationName = integration.shortName

  const title = useMemo(() => {
    switch (payload.type) {
      case 'payApp':
        return t(`${i18nBase}.title.pay_app`, { integrationName })
      case 'payAppLineItems':
        return t(`${i18nBase}.title.pay_app_line_items`, { integrationName })
      case 'legalRequirement':
        return t(`${i18nBase}.title.legal_requirement`, { integrationName })
      case 'lienWaivers':
        return t(`${i18nBase}.title.lien_waivers`, { integrationName })
    }
  }, [integrationName, payload.type, t])

  return (
    <div className={classes.loadingDialog}>
      <LinearProgress className="animation" />
      <SitelineText variant="h1" bold align="center">
        {title}
      </SitelineText>
      <SitelineText variant="body1" color="grey50" align="center" className="description">
        {t(`${i18nBase}.description`, { integrationName })}
      </SitelineText>
    </div>
  )
}
