import CheckIcon from '@mui/icons-material/Check'
import { Button, Menu, MenuItem } from '@mui/material'
import { clsx } from 'clsx'
import { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colorStyles, colors, makeStylesFast } from 'siteline-common-web'
import { clampToLines } from '../util/CSS'
import { AnimatedArrowDropdownIcon } from './AnimatedArrowDropdownIcon'

export const FILTER_DROPDOWN_BUTTON_CLASS = 'filterDropdownMenuButton'
export const FILTER_DROPDOWN_OPTION_CLASS = 'filterDropdownMenuOption'

const useStyles = makeStylesFast(() => ({
  button: {
    height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .heading': {
      whiteSpace: 'nowrap',
      flexShrink: 0,
    },
    '& .clampWidth': {
      textAlign: 'left',
      ...clampToLines(1),
    },
    '& .arrowIcon.disabled': {
      color: colors.grey50,
    },
  },
  menu: {
    '& .MuiMenuItem-root': {
      maxWidth: 'none',
    },
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .checkIcon': {
        opacity: 0,
      },
      '&.isSelected': {
        fontWeight: 600,
        '& .checkIcon': {
          opacity: 1,
        },
      },
    },
  },
}))

export interface FilterOption<T> {
  label: string
  value: T
}

export const NONE_OPTION = 'NONE'

// When omitting `required` or passing `required={false}`,
// the dropdown will have a "None" option that clears the selection.
// Note that this affects the type of `selectedOption` and `onSelectedOptionChange`.
type FilterDropdownMenuProps<T> = {
  required?: false
  title: string
  options: FilterOption<T>[]
  selectedOption: T | typeof NONE_OPTION | null
  onSelectedOptionChange: (option: T | null) => void
  defaultLabel?: ReactNode
  startIcon?: ReactNode
  labelColor?: keyof typeof colorStyles
  className?: string
  maxWidth?: number | string
  disabled?: boolean
  selectedLabel?: string
}

// When passing `required={true}`, the dropdown will enforce that there is always a selection.
// Note that this affects the type of `selectedOption` and `onSelectedOptionChange`.
type FilterDropdownMenuPropsRequired<T> = {
  required: true
  title: string
  options: FilterOption<T>[]
  selectedOption: T
  onSelectedOptionChange: (option: T) => void
  defaultLabel?: ReactNode
  startIcon?: ReactNode
  labelColor?: keyof typeof colorStyles
  className?: string
  maxWidth?: number | string
  disabled?: boolean
  selectedLabel?: string
}

/** Dropdown menu for filtering a data set by a single property */
export function FilterDropdownMenu<T>({
  required,
  title,
  options,
  selectedOption,
  onSelectedOptionChange,
  defaultLabel,
  startIcon,
  labelColor = 'grey70',
  className,
  maxWidth,
  disabled = false,
  selectedLabel,
}: FilterDropdownMenuProps<T> | FilterDropdownMenuPropsRequired<T>) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const selected = options.find((option) => option.value === selectedOption)
  const allLabel = defaultLabel ?? t('project_home.header.all')

  const menuSelectionLabel = useMemo(() => {
    if (selectedLabel) {
      return selectedLabel
    }
    return selected?.label ?? allLabel
  }, [allLabel, selected?.label, selectedLabel])

  return (
    <>
      <div className={className}>
        <Button
          variant="text"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={classes.button}
          disabled={disabled}
        >
          <SitelineText variant="smallText" color="grey50" className="heading">
            {title}
          </SitelineText>
          <SitelineText
            variant="secondary"
            bold
            color={selectedOption !== null ? labelColor : 'grey50'}
            endIcon={
              <AnimatedArrowDropdownIcon
                isMenuOpen={Boolean(anchorEl)}
                className={clsx('arrowIcon', { disabled })}
              />
            }
            startIcon={startIcon}
          >
            <div
              className={clsx(FILTER_DROPDOWN_BUTTON_CLASS, { clampWidth: maxWidth !== undefined })}
              style={{ maxWidth, textAlign: 'left' }}
            >
              {menuSelectionLabel}
            </div>
          </SitelineText>
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className={classes.menu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{ maxWidth }}
      >
        {!required && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              onSelectedOptionChange(null)
            }}
            className={clsx(FILTER_DROPDOWN_OPTION_CLASS, { isSelected: selectedOption === null })}
          >
            <SitelineText variant="body1">{allLabel}</SitelineText>
            <CheckIcon className="checkIcon" />
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              setAnchorEl(null)
              onSelectedOptionChange(option.value)
            }}
            className={clsx(FILTER_DROPDOWN_OPTION_CLASS, {
              isSelected: selectedOption === option.value,
            })}
          >
            <SitelineText variant="body1" noWrap={maxWidth ? true : undefined}>
              {option.label}
            </SitelineText>
            <CheckIcon className="checkIcon" />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
