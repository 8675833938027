import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { Button, Theme, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IntegrationTypeFamily,
  Permission,
  getIntegrationTypeFamily,
  supportsReadSync,
} from 'siteline-common-all'
import { colors } from 'siteline-common-web'
import { ButtonVariant } from '../../../common/components/DropdownButton'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { MINIMUM_SUPPORTED_SCREEN_WIDTH } from '../../../common/themes/Main'
import { hasIntegrationOfFamily } from '../../../common/util/Integration'
import { canPreviewSovImport, isPayAppDraftOrSyncFailed } from '../../../common/util/PayApp'
import { BillingPathType, getBillingPath } from '../Billing.lib'
import { ReadSyncButtonInput, SyncButton, WriteSyncButtonInput } from '../sync/SyncButton'
import { ContractForProjectHome } from './ProjectHome'

const iconStyle = { color: colors.grey50 }

interface ProjectHomeHeaderSovButtonsProps {
  contract: ContractForProjectHome
  /** Switches the SOV to be editable; can add and focus a change order row */
  onEditSov: (addChangeOrder: boolean) => void
}

function readIntegrationsOfType(
  readType: 'sov' | 'changeOrders',
  contract: ContractForProjectHome
) {
  const hasGcPortalIntegration = hasIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL)
  return contract.integrations.filter((integration) => {
    if (!supportsReadSync(integration.type, readType)) {
      return false
    }
    const integrationTypeFamily = getIntegrationTypeFamily(integration.type)
    // If there's a GC portal integration, only show import options for the GC portal
    return !hasGcPortalIntegration || integrationTypeFamily === IntegrationTypeFamily.GC_PORTAL
  })
}

const i18nBase = 'projects.subcontractors.pay_app.header'
const emptyWriteInputs: WriteSyncButtonInput[] = []

/** Buttons shown to the right of the project header tabs while on the SOV tab */
export function ProjectHomeHeaderSovButtons({
  contract,
  onEditSov,
}: ProjectHomeHeaderSovButtonsProps) {
  const { t } = useTranslation()
  const { permissions, enableBillingWorksheets } = useCompanyContext()
  const { id: projectId } = useProjectContext()
  const navigate = useNavigate()
  const theme: Theme = useTheme()
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down(MINIMUM_SUPPORTED_SCREEN_WIDTH))

  const canEditChangeOrders = permissions.includes(Permission.EDIT_CHANGE_ORDER)

  const hasGcPortalIntegration = hasIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL)
  // Only allow importing the full SOV until any pay apps are signed, or if the contract has a
  // GC portal integration
  const includeReadSovIntegrations =
    canPreviewSovImport(contract, permissions) || hasGcPortalIntegration
  const readSovIntegrations = useMemo(
    () => (includeReadSovIntegrations ? readIntegrationsOfType('sov', contract) : []),
    [includeReadSovIntegrations, contract]
  )
  const readChangeOrderIntegrations = useMemo(
    () => (hasGcPortalIntegration ? [] : readIntegrationsOfType('changeOrders', contract)),
    [contract, hasGcPortalIntegration]
  )

  const allIntegrations = useMemo(
    () => [...readSovIntegrations, ...readChangeOrderIntegrations],
    [readSovIntegrations, readChangeOrderIntegrations]
  )
  const integrationNames = useMemo(
    () =>
      _.chain(allIntegrations)
        .map((integration) => integration.shortName)
        .uniq()
        .value(),
    [allIntegrations]
  )
  // Use default input labels, which include the integration name, if either:
  // 1. There are multiple integrations, so we need to clarify which input is for which integration
  // 2. There is only one input, so the input label will be the button label (instead of a dropdown item)
  // Otherwise, the dropdown button's label will include the integration name so we don't need to repeat it
  const useDefaultInputLabels = integrationNames.length > 1 || allIntegrations.length === 1
  const dropdownButtonLabel = t(`${i18nBase}.import_from`, {
    count: integrationNames.length,
    integration: integrationNames.length > 0 ? integrationNames[0] : '',
  })

  const readSyncInputs: ReadSyncButtonInput[] = useMemo(() => {
    const inputs: ReadSyncButtonInput[] = []
    if (canEditChangeOrders) {
      inputs.push(
        ...readChangeOrderIntegrations.map((integration) => ({
          integration,
          numExistingLineItems: contract.sov?.lineItems.length ?? 0,
          variant: ButtonVariant.SECONDARY,
          onlyChangeOrders: true,
          label: useDefaultInputLabels ? undefined : t(`${i18nBase}.change_orders`),
        }))
      )
    }
    inputs.push(
      ...readSovIntegrations.map((integration) => ({
        integration,
        numExistingLineItems: contract.sov?.lineItems.length ?? 0,
        variant: ButtonVariant.SECONDARY,
        onlyChangeOrders: false,
        label: useDefaultInputLabels ? undefined : t(`${i18nBase}.full_sov`),
      }))
    )
    return inputs
  }, [
    readChangeOrderIntegrations,
    readSovIntegrations,
    contract,
    t,
    useDefaultInputLabels,
    canEditChangeOrders,
  ])

  const payApps = useMemo(() => [...contract.payApps], [contract.payApps])
  const hasSignedPayApp = payApps.some((payApp) => !isPayAppDraftOrSyncFailed(payApp.status))
  const enableEditSov = !hasSignedPayApp || canEditChangeOrders

  const addProps = useMemo(() => {
    if (isXSmallScreen) {
      return {
        tooltipTitle: t(`${i18nBase}.add_change_order`),
        content: <AddIcon style={iconStyle} />,
      }
    }
    return {
      tooltipTitle: '',
      content: t(`${i18nBase}.add_change_order`),
    }
  }, [isXSmallScreen, t])

  const editProps = useMemo(() => {
    if (isXSmallScreen) {
      return {
        tooltipTitle: enableEditSov
          ? t(`${i18nBase}.edit_sov`)
          : t(`${i18nBase}.change_order_permission`),
        content: <EditIcon style={iconStyle} />,
      }
    }
    return {
      tooltipTitle: enableEditSov ? '' : t(`${i18nBase}.change_order_permission`),
      content: t(`${i18nBase}.edit_sov`),
    }
  }, [enableEditSov, isXSmallScreen, t])

  const handleNavigateToImportWorksheet = useCallback(() => {
    navigate(getBillingPath({ pathType: BillingPathType.ImportWorksheet, projectId }))
  }, [navigate, projectId])

  return (
    <>
      {enableBillingWorksheets && !hasSignedPayApp && (
        <Button variant="outlined" color="secondary" onClick={handleNavigateToImportWorksheet}>
          {t(`${i18nBase}.import_worksheet`)}
        </Button>
      )}
      {readSyncInputs.length > 0 && (
        <SyncButton
          writeSyncInputs={emptyWriteInputs}
          readSyncInputs={readSyncInputs}
          projectId={projectId}
          dropdownLabel={dropdownButtonLabel}
          smallScreenDropdownLabel={<ImportExportIcon style={iconStyle} />}
          shouldShrinkOnSmallScreen
        />
      )}
      {!hasGcPortalIntegration && (
        <>
          {readSyncInputs.length === 0 && canEditChangeOrders && (
            <Tooltip title={addProps.tooltipTitle}>
              <Button variant="outlined" color="secondary" onClick={() => onEditSov(true)}>
                {addProps.content}
              </Button>
            </Tooltip>
          )}
          <Tooltip title={editProps.tooltipTitle}>
            <div>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!enableEditSov}
                onClick={() => onEditSov(false)}
              >
                {editProps.content}
              </Button>
            </div>
          </Tooltip>
        </>
      )}
    </>
  )
}
