import { gql } from '@apollo/client'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckIcon from '@mui/icons-material/Check'
import HelpIcon from '@mui/icons-material/Help'
import LaunchIcon from '@mui/icons-material/Launch'
import { LoadingButton } from '@mui/lab'
import {
  Breakpoint,
  Button,
  FormControl,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  CompanyIntegrationMetadataSageIntacct,
  CompanyIntegrationMetadataSpectrum,
  DAY_FORMAT,
  ImportIntegrationChangeOrdersMethod,
  IntegrationType,
  MAX_SAGE_300_DRAW_NUMBER_LENGTH,
  MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH,
  MAX_SAGE_INVOICE_CODE_LENGTH,
  MAX_SPECTRUM_BATCH_NUMBER_LENGTH,
  MAX_SPECTRUM_INVOICE_CODE_LENGTH,
  RetentionTrackingLevel,
  TaxCalculationType,
  centsToDollars,
  dollarsToCents,
  generateDefaultCode,
  getDefaultInvoiceCode,
  getDefaultInvoiceDates,
  integrationTypes,
  safeDivide,
  supportsGeneratingInvoiceCode,
  supportsInvoiceAutoCode,
  supportsManualInvoiceCode,
  supportsWriteSync,
} from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import acumaticaIcon from '../../../assets/icons/acumatica.png'
import foundationIcon from '../../../assets/icons/foundation.png'
import sageIntacctIcon from '../../../assets/icons/sage-intacct.svg'
import sageIcon from '../../../assets/icons/sage.png'
import spectrumIcon from '../../../assets/icons/spectrum.png'
import vistaIcon from '../../../assets/icons/vista.png'
import sitelineIcon from '../../../assets/images/logo/icon_white.svg'
import {
  DatePickerInput,
  DatePickerValue,
  isMissingDate,
  makeDatePickerValue,
} from '../../../common/components/DatePickerInput'
import { DollarNumberFormat } from '../../../common/components/NumberFormat'
import { SitelineDialog, SitelineDialogProps } from '../../../common/components/SitelineDialog'
import { Spreadsheet } from '../../../common/components/Spreadsheet/Spreadsheet'
import {
  SpreadsheetContent,
  SpreadsheetFooterRow,
  SpreadsheetValue,
} from '../../../common/components/Spreadsheet/Spreadsheet.lib'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useUserContext } from '../../../common/contexts/UserContext'
import {
  MinimalIntegrationProperties,
  useGenerateIntegrationInvoiceCodeLazyQuery,
  useGetCompanyForTaxGroupsQuery,
  useGetPayAppQuery,
  useReadIntegrationSovQuery,
} from '../../../common/graphql/apollo-operations'
import { themeSpacing } from '../../../common/themes/Main'
import { isWriteSyncInProgress, useWriteSync } from '../../../common/util/Integration'
import {
  trackIntegrationSyncAutoDistribute,
  trackIntegrationSyncDialogReset,
  trackIntegrationSyncEnterManually,
} from '../../../common/util/MetricsTracking'
import { usesStandardOrLineItemTracking } from '../../../common/util/Retention'
import {
  BilledLineItem,
  BilledLineItemMap,
  EMPTY_BILLED_LINE_ITEM,
  PAY_APP_LINE_ITEMS_MIN_SCORE,
  allocateAnywhereFreeform,
  allocateAnywhereLineItems,
  allocateMatchingLineItems,
  getErpIntegrationLineItemMatchResult,
} from '../../../common/util/export/ErpIntegrations'
import {
  ColumnOptions,
  PayAppLineItemColumn,
  getSovColumns,
  getSovLineItemRow,
  getSovLineItemTotalsRow,
} from './SyncPayAppLineItemsRow'
import { WriteSyncDialogContent } from './WriteSyncDialogContent'

const MIN_LABEL_WIDTH = 100
const useStyles = makeStylesFast((theme: Theme) => ({
  loading: {
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& .icons': {
        display: 'flex',
        margin: theme.spacing(6),
        '& .siteline': {
          height: 56,
          width: 56,
          padding: theme.spacing(2),
          backgroundColor: colors.orange40,
          borderRadius: theme.spacing(1),
        },
        '& .progress': {
          alignSelf: 'center',
          margin: theme.spacing(0, 2),
          width: 200,
        },
        '& .erpIcon': {
          height: 56,
          width: 56,
        },
      },
      '& .matching': {
        marginBottom: theme.spacing(1),
      },
      '& .moment': {
        marginBottom: theme.spacing(4),
      },
    },
  },
  help: {
    marginTop: theme.spacing(1),
  },
  root: {
    '& .banner': {
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 3),
      // Same height as with buttons, but clicking buttons removes them, so this way the element
      // doesn't shrink and the layout doesn't change
      minHeight: 82,
      marginBottom: theme.spacing(2),
      '&.blue': {
        backgroundColor: colors.blue10,
        border: `1px solid ${colors.blue30}`,
      },
      '&.green': {
        backgroundColor: colors.green10,
        border: `1px solid ${colors.green30}`,
      },
      '& .remainderContainer': {
        display: 'flex',
        '& > *': {
          marginRight: theme.spacing(3),
        },
      },
      '& .actionsContainer': {
        display: 'flex',
        alignItems: 'center',
        '& .autoAllocate': {
          margin: theme.spacing(0, 2),
        },
        '& .helpIcon': {
          color: colors.grey50,
        },
      },
    },
    '& .heading': {
      display: 'flex',
      minWidth: MIN_LABEL_WIDTH,
      '&.batchNumber': {
        marginLeft: theme.spacing(2),
      },
    },
    '& .invoiceSettingsContainer': {
      display: 'flex',
      gap: theme.spacing(1.5),
      flexDirection: 'column',
      marginBottom: theme.spacing(3),
      '& .settingContainer': {
        display: 'flex',
        alignItems: 'center',
        '& .heading': {
          marginRight: theme.spacing(3),
        },
        '& .useAutoCode': {
          marginRight: theme.spacing(1),
        },
      },
    },
    '& .noDefaultCode': {
      marginTop: theme.spacing(-1),
      // Match the width of the labels
      marginLeft: MIN_LABEL_WIDTH + themeSpacing(3),
    },
    '& .dateInput': {
      maxWidth: 160,
    },
    '& .invoiceCode': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiInputBase-root': {
        maxWidth: 160,
      },
      '& .MuiFormHelperText-root': {
        margin: theme.spacing(0, 1),
      },
      '&.withGeneratingCode': {
        marginRight: theme.spacing(1),
      },
    },
  },
  syncSuccess: {
    '& .MuiDialogContent-root': {
      margin: theme.spacing(-2.5),
    },
  },
}))

gql`
  query readIntegrationSov($input: ReadIntegrationSovInput!) {
    readIntegrationSov(input: $input) {
      lineItems {
        integrationLineItemId
        code
        description
        scheduledValue
        billedToDate
        retentionToDate
        unitPrice
      }
    }
  }
`

const i18nBase = 'integrations.pay_app_line_items_sync_dialog'

interface SyncPayAppLineItemsDialogProps {
  open: boolean
  onClose: () => void
  integration: MinimalIntegrationProperties
  payAppId: string
}

export function supportedIntegrations() {
  return Object.values(IntegrationType).filter((integrationType) =>
    supportsWriteSync(integrationType, 'payAppLineItems')
  )
}

/**
 * Content that goes inside a dialog while we are fetching the ERP Contract and SOV from the server
 */
function SyncPayAppLineItemsLoadingDialogContent({
  integration,
}: {
  integration: MinimalIntegrationProperties
}) {
  const { t } = useTranslation()
  const integrationName = integration.shortName

  let icon
  switch (integration.type) {
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_300_CRE:
      icon = sageIcon
      break
    case IntegrationType.VISTA:
      icon = vistaIcon
      break
    case IntegrationType.SPECTRUM:
      icon = spectrumIcon
      break
    case IntegrationType.ACUMATICA:
      icon = acumaticaIcon
      break
    case IntegrationType.FOUNDATION:
      icon = foundationIcon
      break
    case IntegrationType.SAGE_INTACCT:
      icon = sageIntacctIcon
      break
    case IntegrationType.GC_PAY:
    case IntegrationType.TEST:
    case IntegrationType.TEXTURA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.PROCORE:
      break
  }

  return (
    <div className="content">
      <div className="icons">
        <div className="siteline">
          <img src={sitelineIcon} alt="Siteline" />
        </div>
        <div className="progress">
          <LinearProgress />
        </div>
        <img src={icon} alt={integrationName} className="erpIcon" />
      </div>
      <SitelineText variant="h1" bold className="matching">
        {t(`${i18nBase}.matching_sov`, { integrationName })}
      </SitelineText>
      <SitelineText variant="body1" color="grey50" className="moment">
        {t(`${i18nBase}.moment`)}
      </SitelineText>
    </div>
  )
}

/**
 * Dialog that shows a <Spreadsheet> to the user so they can allocate the billed invoice to match
 * whatever is in their ERP Contract.
 */
export function SyncPayAppLineItemsDialog({
  open,
  onClose,
  integration,
  payAppId,
}: SyncPayAppLineItemsDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { id: userId } = useUserContext()
  const { id: projectId, projectNumber, contract, timeZone } = useProjectContext()
  const { companyAgingIntervalType, companyId, company } = useCompanyContext()

  const {
    data: payAppData,
    loading: loadingPayApp,
    error: errorPayApp,
  } = useGetPayAppQuery({ variables: { payAppId } })

  const { data: companyData } = useGetCompanyForTaxGroupsQuery({ variables: { id: companyId } })
  const taxGroups = useMemo(() => [...(companyData?.company.taxGroups ?? [])], [companyData])

  // We don't do line item allocation for T&M
  const showAllocation = useMemo(() => {
    if (!payAppData) {
      return false
    }
    return payAppData.payApp.billingType !== BillingType.TIME_AND_MATERIALS
  }, [payAppData])

  const {
    data: sovData,
    loading: loadingSov,
    error: errorSov,
    refetch: refetchSov,
  } = useReadIntegrationSovQuery({
    variables: {
      input: {
        integrationId: integration.id,
        payAppId,
        // The line items returned to the sync dialog must match the integration exactly
        // because we need to sync to what's actually in the ERP. If change orders are merged
        // to line items in the ERP, we will not be able to sync back to separate change order
        // line items
        forceChangeOrderStrategy: ImportIntegrationChangeOrdersMethod.MERGE_ORIGINAL_LINE_ITEMS,
      },
    },
    // Sets loading to true when calling refetch on error
    notifyOnNetworkStatusChange: true,
    skip: !showAllocation,
  })

  const [generateInvoiceCode, { loading: generatingInvoiceCode }] =
    useGenerateIntegrationInvoiceCodeLazyQuery({
      variables: { input: { integrationId: integration.id } },
      // Always go to the network, in case a new invoice code has been posted since the last fetch
      fetchPolicy: 'network-only',
    })

  const initialBilledMap = useMemo(() => {
    if (!payAppData || !sovData) {
      return null
    }
    const progress = payAppData.payApp.progress
    const lineItems = sovData.readIntegrationSov.lineItems
    return allocateMatchingLineItems(integration.type, progress, lineItems)
  }, [integration.type, payAppData, sovData])
  const [billedMap, setBilledMap] = useState<BilledLineItemMap | null>(initialBilledMap)

  const [isManualMode, setIsManualMode] = useState<boolean>(false)
  const [invoiceCode, setInvoiceCode] = useState<string>('')
  // For some integrations, an invoice code is required but Siteline can help by generating a code
  // based on past invoices
  const doesIntegrationSupportGeneratingCode = useMemo(
    () => supportsGeneratingInvoiceCode(integration.type),
    [integration.type]
  )

  // We pull the company integration metadata from the company rather than the integration prop so that
  // we always have the latest metadata from the server, since the integration prop is stored in state
  const companyIntegrationMetadata = useMemo(() => {
    const companyIntegration = company?.companyIntegrations.find(
      (companyIntegration) => companyIntegration.id === integration.companyIntegration.id
    )
    return companyIntegration?.metadata
  }, [company?.companyIntegrations, integration.companyIntegration.id])

  // Some integrations do not require an invoice code and will generate one automatically if empty
  const doesIntegrationSupportAutoCode = useMemo(() => {
    return supportsInvoiceAutoCode(integration.type, companyIntegrationMetadata)
  }, [companyIntegrationMetadata, integration.type])

  const doesIntegrationSupportManualCode = useMemo(() => {
    if (integration.type !== IntegrationType.SAGE_INTACCT) {
      return supportsManualInvoiceCode(integration.type)
    }
    return supportsManualInvoiceCode(
      integration.type,
      companyIntegrationMetadata as CompanyIntegrationMetadataSageIntacct
    )
  }, [companyIntegrationMetadata, integration.type])
  const [useAutoCode, setUseAutoCode] = useState<boolean>(doesIntegrationSupportAutoCode)

  // If support for auto code on the integration changes, update the `useAutoCode`
  // state variable
  useEffect(() => {
    setUseAutoCode(doesIntegrationSupportAutoCode)
  }, [doesIntegrationSupportAutoCode])

  const initialDates = useMemo((): {
    dueDate: DatePickerValue
    invoiceDate: DatePickerValue
  } => {
    if (!payAppData) {
      return {
        dueDate: { type: 'valid', date: null },
        invoiceDate: { type: 'valid', date: null },
      }
    }
    const paymentTerms = _.isNumber(contract?.paymentTerms) ? contract.paymentTerms : null
    const { invoiceDate, dueDate } = getDefaultInvoiceDates({
      agingIntervalType: companyAgingIntervalType,
      timeZone,
      paymentTerms,
      billingEnd: moment.tz(payAppData.payApp.billingEnd, timeZone),
      submittedAt: payAppData.payApp.lastSubmitted
        ? moment.tz(payAppData.payApp.lastSubmitted.statusUpdatedAt, timeZone)
        : null,
    })
    return { invoiceDate: makeDatePickerValue(invoiceDate), dueDate: makeDatePickerValue(dueDate) }
  }, [companyAgingIntervalType, contract?.paymentTerms, payAppData, timeZone])
  const [invoiceDate, setInvoiceDate] = useState<DatePickerValue>(initialDates.invoiceDate)
  const [dueDate, setDueDate] = useState<DatePickerValue>(initialDates.dueDate)

  // Batch number is required for Spectrum syncs
  const doesIntegrationRequireBatchNumber = integration.type === IntegrationType.SPECTRUM
  // Draw number is required for Sage 300 syncs
  const doesIntegrationRequireDrawNumber = integration.type === IntegrationType.SAGE_300_CRE
  // Reference number is helpful for Sage Intacct syncs
  const doesIntegrationSupportReferenceNumber = integration.type === IntegrationType.SAGE_INTACCT
  // Sage Intacct and 300 both require due date
  const doesIntegrationRequireDueDate =
    integration.type === IntegrationType.SAGE_INTACCT ||
    integration.type === IntegrationType.SAGE_300_CRE

  const initialBatchNumber = useMemo(() => {
    switch (integration.type) {
      case IntegrationType.SPECTRUM: {
        const metadata = integration.companyIntegration
          .metadata as CompanyIntegrationMetadataSpectrum
        return metadata.batches?.[userId] ?? ''
      }
      case IntegrationType.ACUMATICA:
      case IntegrationType.COMPUTER_EASE_FILE:
      case IntegrationType.FOUNDATION:
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.GC_PAY:
      case IntegrationType.PROCORE:
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.SAGE_100_CONTRACTOR:
      case IntegrationType.SAGE_300_CRE:
      case IntegrationType.TEST:
      case IntegrationType.TEXTURA:
      case IntegrationType.VISTA:
      case IntegrationType.SAGE_INTACCT:
        return ''
    }
  }, [integration.companyIntegration.metadata, integration.type, userId])
  const [batchNumber, setBatchNumber] = useState<string>(initialBatchNumber)
  const shouldAutoFocusBatchNumber = doesIntegrationRequireBatchNumber && !initialBatchNumber

  const initialDraw = useMemo(
    () => (payAppData ? payAppData.payApp.payAppNumber.toFixed(0) : ''),
    [payAppData]
  )
  const [draw, setDraw] = useState<string>(initialDraw)

  const initialReferenceNumber = useMemo(() => {
    const gcProjectNumber = contract?.project.projectNumber
    const payAppNumber = payAppData?.payApp.payAppNumber
    const generatedCode = generateDefaultCode({
      projectNumber: gcProjectNumber ?? null,
      payAppNumber,
      maxInvoiceCodeLength: MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH,
      billingType: contract?.billingType,
    })
    return generatedCode
  }, [contract?.billingType, contract?.project.projectNumber, payAppData?.payApp.payAppNumber])
  const [referenceNumber, setReferenceNumber] = useState<string>(initialReferenceNumber)

  const totalBilledAllocated = useMemo(
    () => _.sum(Object.values(billedMap ?? {}).map((map) => map.progressBilled)),
    [billedMap]
  )
  const totalRetentionAllocated = useMemo(
    () => _.sum(Object.values(billedMap ?? {}).map((map) => map.retention)),
    [billedMap]
  )

  const syncLineItems = useMemo(() => {
    return _.map(billedMap, (value, key) => ({
      ...value,
      integrationLineItemId: key,
    })).filter((lineItem) => lineItem.progressBilled !== 0)
  }, [billedMap])

  const payload = useMemo((): integrationTypes.WriteSyncPayloadPayAppLineItems | null => {
    if (!invoiceDate.date) {
      return null
    }
    return {
      type: 'payAppLineItems',
      payAppId,
      payAppLineItems: syncLineItems,
      invoiceDate: invoiceDate.date.format(DAY_FORMAT),
      ...(doesIntegrationRequireDueDate &&
        dueDate.date && { dueDate: dueDate.date.format(DAY_FORMAT) }),
      // If auto-code is selected, we don't send an invoice code because the integration will
      // generate one (this is only supported for some ERPs)
      invoiceCode: useAutoCode ? undefined : invoiceCode,
      ...(doesIntegrationRequireBatchNumber && { batchNumber }),
      ...(doesIntegrationRequireDrawNumber && { draw }),
      ...(doesIntegrationSupportReferenceNumber && referenceNumber && { referenceNumber }),
    }
  }, [
    batchNumber,
    doesIntegrationRequireBatchNumber,
    doesIntegrationRequireDrawNumber,
    doesIntegrationRequireDueDate,
    doesIntegrationSupportReferenceNumber,
    draw,
    dueDate.date,
    invoiceCode,
    invoiceDate.date,
    payAppId,
    referenceNumber,
    syncLineItems,
    useAutoCode,
  ])

  const { sync, status, reset: resetSync } = useWriteSync({ integration })
  const integrationName = integration.shortName
  const integrationLongName = integration.longName

  const isLoading = loadingSov || loadingPayApp
  const isError = errorSov || errorPayApp

  const totalBilled = useMemo(() => payAppData?.payApp.currentBilled ?? 0, [payAppData])
  const hasAnyBilling = useMemo(() => {
    if (
      payAppData?.payApp.billingType === BillingType.LUMP_SUM ||
      payAppData?.payApp.billingType === BillingType.UNIT_PRICE
    ) {
      const linesBilled = payAppData.payApp.progress.map(
        (progressLine) => progressLine.currentBilled !== 0
      )
      return linesBilled.length > 0
    }

    if (payAppData?.payApp.billingType === BillingType.TIME_AND_MATERIALS) {
      const rateTableItems = payAppData.payApp.rateTableItems.map(
        (item) => item.currentUnitsBilled !== 0
      )
      return rateTableItems.length > 0
    }

    return false
  }, [
    payAppData?.payApp.billingType,
    payAppData?.payApp.progress,
    payAppData?.payApp.rateTableItems,
  ])

  const payAppRetention = useMemo(() => payAppData?.payApp.currentRetention ?? 0, [payAppData])
  const amountToAllocate = totalBilled - totalBilledAllocated
  const retentionToAllocate = payAppRetention - totalRetentionAllocated
  const allAllocated = !showAllocation || (amountToAllocate === 0 && retentionToAllocate === 0)
  const missingRequiredDueDate = doesIntegrationRequireDueDate && !dueDate.date

  const onSubmit = useCallback(() => {
    if (!payload) {
      return
    }
    if (showAllocation && syncLineItems.length === 0) {
      snackbar.showError(t(`${i18nBase}.errors.need_billing`))
      return
    }
    sync(payload)
  }, [payload, showAllocation, snackbar, sync, syncLineItems.length, t])

  // Whenever the default billed map / invoice date are resolved, set the state.
  // In the unlikely case that the state is already set, ignore the new default value.
  useEffect(() => {
    if (billedMap === null) {
      setBilledMap(initialBilledMap)
    }
    if (invoiceDate.date === null) {
      setInvoiceDate(initialDates.invoiceDate)
    }
    if (dueDate.date === null) {
      setDueDate(initialDates.dueDate)
    }
  }, [
    billedMap,
    dueDate.date,
    initialBilledMap,
    initialDates.dueDate,
    initialDates.invoiceDate,
    invoiceDate,
  ])

  const resetDialog = useCallback(() => {
    setBilledMap(initialBilledMap)
    setIsManualMode(false)
    setInvoiceDate(initialDates.invoiceDate)
    setDueDate(initialDates.dueDate)
  }, [initialBilledMap, initialDates.dueDate, initialDates.invoiceDate])

  const handleResetButtonClick = useCallback(() => {
    resetDialog()
    trackIntegrationSyncDialogReset({
      projectId,
      payAppId,
      integrationLongName,
    })
  }, [integrationLongName, payAppId, projectId, resetDialog])

  // Update the draw number when the pay app loads
  useEffect(() => setDraw(initialDraw), [initialDraw])

  // Update the reference number when the pay app loads
  useEffect(() => setReferenceNumber(initialReferenceNumber), [initialReferenceNumber])

  const handleGenerateInvoiceCode = useCallback(async () => {
    try {
      const { data } = await generateInvoiceCode()
      const generatedInvoiceCode = data?.generateIntegrationInvoiceCode
      if (!generatedInvoiceCode) {
        snackbar.showError(t(`${i18nBase}.no_generated_code`))
        return
      }
      setInvoiceCode(generatedInvoiceCode)
    } catch (err) {
      console.error(err.message)
      snackbar.showError(t(`${i18nBase}.no_generated_code`))
    }
  }, [generateInvoiceCode, snackbar, t])

  const maxInvoiceCodeLength = useMemo(() => {
    switch (integration.type) {
      case IntegrationType.SAGE_100_CONTRACTOR:
      case IntegrationType.SAGE_300_CRE:
        return MAX_SAGE_INVOICE_CODE_LENGTH
      case IntegrationType.SPECTRUM:
        return MAX_SPECTRUM_INVOICE_CODE_LENGTH
      case IntegrationType.ACUMATICA:
      case IntegrationType.COMPUTER_EASE_FILE:
      case IntegrationType.FOUNDATION:
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.GC_PAY:
      case IntegrationType.PROCORE:
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.TEST:
      case IntegrationType.TEXTURA:
      case IntegrationType.VISTA:
      case IntegrationType.SAGE_INTACCT:
        return undefined
    }
  }, [integration.type])

  const defaultInvoiceCode = useMemo(() => {
    return getDefaultInvoiceCode({
      internalProjectNumber: contract?.internalProjectNumber,
      projectNumber,
      payAppNumber: payAppData?.payApp.payAppNumber,
      maxInvoiceCodeLength,
      billingType: payAppData?.payApp.billingType,
    })
  }, [
    contract?.internalProjectNumber,
    maxInvoiceCodeLength,
    payAppData?.payApp.billingType,
    payAppData?.payApp.payAppNumber,
    projectNumber,
  ])

  // Assign the invoice code based on project number and pay app number. This essentially only gets
  // called once when the dialog is first rendered.
  useEffect(() => {
    setInvoiceCode(defaultInvoiceCode)
  }, [defaultInvoiceCode])

  const matches = useMemo(() => {
    if (!sovData || !payAppData) {
      return []
    }
    const progress = payAppData.payApp.progress
    const lineItems = sovData.readIntegrationSov.lineItems
    const matchResult = getErpIntegrationLineItemMatchResult(integration.type, progress, lineItems)
    return matchResult.matches
  }, [integration.type, payAppData, sovData])

  const numMatchesMissing = useMemo(() => {
    if (!payAppData) {
      return 0
    }
    const progress = payAppData.payApp.progress
    const billedLineItems = progress.filter((payAppLineItem) => {
      return payAppLineItem.currentBilled !== 0 || payAppLineItem.previousRetentionBilled !== 0
    })
    const matchesAboveScore = matches.filter((match) => match.score >= PAY_APP_LINE_ITEMS_MIN_SCORE)
    const unmatchedBilledLineItems = billedLineItems.filter((lineItem) => {
      const foundMatch = matchesAboveScore.find(
        (match) => match.source.sovLineItemId === lineItem.sovLineItem.id
      )
      return !foundMatch
    })
    return unmatchedBilledLineItems.length
  }, [matches, payAppData])

  // Only distribute line items if retention tracking is standard
  const distributeFreely = useCallback(() => {
    if (!payAppData || !sovData) {
      return
    }
    const progress = payAppData.payApp.progress
    const lineItems = sovData.readIntegrationSov.lineItems
    const retentionTrackingLevel =
      contract?.retentionTrackingLevel ?? RetentionTrackingLevel.STANDARD
    if (usesStandardOrLineItemTracking(retentionTrackingLevel)) {
      setBilledMap(allocateAnywhereLineItems(integration.type, progress, lineItems))
      trackIntegrationSyncAutoDistribute({
        projectId,
        payAppId,
        type: 'LINE_ITEMS',
        integrationLongName,
      })
    } else {
      setBilledMap(
        allocateAnywhereFreeform(totalBilled, payAppRetention, lineItems, billedMap ?? {})
      )
      trackIntegrationSyncAutoDistribute({
        projectId,
        payAppId,
        type: 'FREEFORM',
        integrationLongName,
      })
    }
    setIsManualMode(true)
  }, [
    billedMap,
    contract?.retentionTrackingLevel,
    integration.type,
    integrationLongName,
    payAppData,
    payAppId,
    payAppRetention,
    totalBilled,
    projectId,
    sovData,
  ])

  const enterManually = useCallback(() => {
    setIsManualMode(true)
    trackIntegrationSyncEnterManually({ projectId, payAppId, integrationLongName })
  }, [integrationLongName, payAppId, projectId])

  const handleChange = useCallback(
    (rowId: string, columnId: string, toValue: SpreadsheetValue) => {
      if (!sovData) {
        return
      }
      const lineItems = sovData.readIntegrationSov.lineItems
      const sovLineItem = lineItems.find((lineItem) => lineItem.integrationLineItemId === rowId)
      if (!sovLineItem?.integrationLineItemId) {
        snackbar.showError(t('common.errors.snackbar.generic'))
        return
      }

      const newBilledMap = _.cloneDeep(billedMap ?? {})
      const item: BilledLineItem = _.get(newBilledMap, sovLineItem.integrationLineItemId, {
        ...EMPTY_BILLED_LINE_ITEM,
      })

      if (columnId === PayAppLineItemColumn.AMOUNT) {
        item.progressBilled = dollarsToCents(toValue as number)
        const ratio = safeDivide(sovLineItem.retentionToDate, sovLineItem.billedToDate, 0)
        item.retention = dollarsToCents((toValue as number) * ratio)
      } else if (columnId === PayAppLineItemColumn.RETAINAGE) {
        item.retention = dollarsToCents(toValue as number)
      } else if (columnId === PayAppLineItemColumn.TAX_GROUP) {
        const taxGroupId = toValue as string
        item.sitelineTaxGroupId = taxGroupId.length > 0 ? taxGroupId : null
      }

      newBilledMap[sovLineItem.integrationLineItemId] = item
      setBilledMap(newBilledMap)
    },
    [sovData, billedMap, snackbar, t]
  )

  const sovColumnOptions: ColumnOptions = useMemo(
    () => ({
      includeRetentionColumn: contract?.retentionTrackingLevel !== RetentionTrackingLevel.NONE,
      includeUnitsColumn: contract?.billingType === BillingType.UNIT_PRICE,
      includeTaxGroupColumn:
        payAppData?.payApp.contract.taxCalculationType !== TaxCalculationType.NONE,
    }),
    [
      contract?.billingType,
      contract?.retentionTrackingLevel,
      payAppData?.payApp.contract.taxCalculationType,
    ]
  )
  const columns = useMemo(
    () => getSovColumns(t, isManualMode, sovColumnOptions, taxGroups),
    [t, isManualMode, sovColumnOptions, taxGroups]
  )
  const totalsRow: SpreadsheetFooterRow | null = useMemo(() => {
    const lineItems = sovData?.readIntegrationSov.lineItems ?? []
    return getSovLineItemTotalsRow(
      lineItems,
      centsToDollars(totalBilledAllocated),
      centsToDollars(totalRetentionAllocated),
      t,
      sovColumnOptions
    )
  }, [
    sovData?.readIntegrationSov.lineItems,
    t,
    totalBilledAllocated,
    totalRetentionAllocated,
    sovColumnOptions,
  ])

  const content: SpreadsheetContent = useMemo(() => {
    const lineItems = sovData?.readIntegrationSov.lineItems ?? []
    const lineItemRows = lineItems.map((sovLineItem) => {
      let initialValue = 0
      let initialRetention = 0
      let initialTaxGroupId = null
      if (sovLineItem.integrationLineItemId) {
        const item: BilledLineItem = _.get(billedMap, sovLineItem.integrationLineItemId, {
          ...EMPTY_BILLED_LINE_ITEM,
        })
        initialValue = item.progressBilled
        initialRetention = item.retention
        initialTaxGroupId = item.sitelineTaxGroupId
      }
      return getSovLineItemRow({
        sovLineItem,
        billedAmount: centsToDollars(initialValue),
        retentionAmount: centsToDollars(initialRetention),
        sitelineTaxGroupId: initialTaxGroupId,
        options: sovColumnOptions,
      })
    })
    return {
      rows: _.compact(lineItemRows).concat(totalsRow),
      enableReorderRows: false,
    }
  }, [sovData?.readIntegrationSov.lineItems, totalsRow, billedMap, sovColumnOptions])

  let dialogTitle = t(`${i18nBase}.header`, { integrationName })
  let maxWidth: Breakpoint = 'lg'
  let fullWidth: boolean | undefined
  let cancelLabel: string | undefined
  let handleClose: (() => void) | undefined
  let handleSubmit: (() => void) | undefined
  let className: string | undefined
  let actionsLayout: SitelineDialogProps['actionsLayout'] = 'actionsRow'
  let simpleErrorMessage: string | undefined
  let subscript: JSX.Element | undefined

  if (isLoading) {
    maxWidth = 'sm'
    dialogTitle = ''
    fullWidth = true
    className = classes.loading
  } else if (status.type !== 'notCreated') {
    dialogTitle = ''
    actionsLayout = 'closeIcon'
    className = isWriteSyncInProgress(status) ? undefined : classes.syncSuccess
  } else if (isError) {
    // The SOV occasionally errors while loading from HH2, if this happens, show a button to retry
    maxWidth = 'sm'
    handleClose = (isRetryClick?: boolean) => {
      if (isRetryClick) {
        refetchSov({ input: { integrationId: integration.id, payAppId } })
      } else {
        onClose()
      }
    }
    cancelLabel = t('common.actions.retry')
    simpleErrorMessage = t(`${i18nBase}.errors.refetch_sov`, { integrationName })
  } else if (payAppData?.payApp.previousRetentionBilled !== 0) {
    // We don't support this right now
    maxWidth = 'sm'
    handleClose = onClose
    dialogTitle = t(`${i18nBase}.create_in_integration`, { integrationName })
    simpleErrorMessage = t(`${i18nBase}.errors.billing_retention`, { integrationName })
  } else if (!showAllocation) {
    fullWidth = true
    maxWidth = 'sm'
    className = classes.root
    handleClose = onClose
    handleSubmit = onSubmit
  } else if (sovData?.readIntegrationSov.lineItems.length === 0) {
    // Edge case where there are no line items on the integration's contract/SOV
    maxWidth = 'sm'
    handleClose = onClose
    simpleErrorMessage = t(`${i18nBase}.errors.no_contract`, { integrationName })
  } else if (!hasAnyBilling) {
    maxWidth = 'sm'
    handleClose = onClose
    simpleErrorMessage = t(`${i18nBase}.errors.no_billing`)
  } else {
    fullWidth = true
    className = classes.root
    handleClose = onClose
    handleSubmit = onSubmit
    subscript = (
      <SitelineText variant="secondary" bold color="grey90">
        {t(`${i18nBase}.bold`, { integrationName })}
      </SitelineText>
    )
  }

  const invoiceCodeTooLong =
    _.isNumber(maxInvoiceCodeLength) && invoiceCode.length > maxInvoiceCodeLength
  let invoiceCodeHelperText: string | undefined
  if (invoiceCodeTooLong) {
    invoiceCodeHelperText = t(`${i18nBase}.code_length_long`, {
      maxLength: maxInvoiceCodeLength,
    })
  } else if (!defaultInvoiceCode) {
    invoiceCodeHelperText = doesIntegrationSupportGeneratingCode
      ? t(`${i18nBase}.no_default_invoice_code_generate`, { integrationName })
      : t(`${i18nBase}.no_default_invoice_code`)
  }
  const invoiceCodeError = invoiceCodeTooLong || !invoiceCode

  const batchNumberTooLong = batchNumber.length > MAX_SPECTRUM_BATCH_NUMBER_LENGTH
  const batchNumberError = doesIntegrationRequireBatchNumber && batchNumberTooLong
  const batchNumberHelperText = batchNumberError
    ? t(`${i18nBase}.code_length_long`, { maxLength: MAX_SPECTRUM_BATCH_NUMBER_LENGTH })
    : undefined

  const isMissingBatchNumber = doesIntegrationRequireBatchNumber && !batchNumber

  const drawTooLong = batchNumber.length > MAX_SAGE_300_DRAW_NUMBER_LENGTH
  const drawError = doesIntegrationRequireDrawNumber && drawTooLong
  const drawHelperText = drawError
    ? t(`${i18nBase}.code_length_long`, { maxLength: MAX_SAGE_300_DRAW_NUMBER_LENGTH })
    : undefined

  const referenceNumberTooLong =
    referenceNumber.length > 0 && referenceNumber.length > MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH
  const referenceNumberError =
    doesIntegrationSupportReferenceNumber && referenceNumber.length > 0 && referenceNumberTooLong
  const referenceNumberHelperText = referenceNumberError
    ? t(`${i18nBase}.code_length_long`, { maxLength: MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH })
    : undefined

  const isMissingDraw = doesIntegrationRequireDrawNumber && !draw

  let helpCenterUrl = null
  if (integration.type === IntegrationType.SAGE_300_CRE) {
    helpCenterUrl =
      'https://support.siteline.com/hc/en-us/articles/13084793960340-Sage-300-CRE-Integration-Details'
  }
  const showMainContent =
    !isLoading && !isError && status.type === 'notCreated' && !simpleErrorMessage

  return (
    <SitelineDialog
      title={dialogTitle}
      subscript={subscript}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onResetDialog={resetDialog}
      open={open}
      onClose={handleClose}
      cancelLabel={cancelLabel}
      onSubmit={handleSubmit}
      submitLabel={t('integrations.button.sync')}
      disableSubmit={
        !allAllocated ||
        missingRequiredDueDate ||
        invoiceCodeError ||
        isMissingDate(invoiceDate) ||
        isMissingBatchNumber ||
        batchNumberError ||
        isMissingDraw ||
        drawError
      }
      className={className}
      actionsLayout={actionsLayout}
      disableEscapeKeyDown
      subtitle={
        helpCenterUrl &&
        showMainContent && (
          <Link target="_blank" href={helpCenterUrl} underline="none">
            <SitelineText
              variant="h4"
              color="blue50"
              endIcon={<LaunchIcon fontSize="small" />}
              className={classes.help}
            >
              {t(`${i18nBase}.help_center`)}
            </SitelineText>
          </Link>
        )
      }
    >
      {payload && (
        <WriteSyncDialogContent
          integration={integration}
          projectId={projectId}
          onClose={handleClose ?? onClose}
          onBack={resetSync}
          onSyncAgain={() => sync(payload)}
          payload={payload}
          status={status}
        />
      )}

      {isLoading && <SyncPayAppLineItemsLoadingDialogContent integration={integration} />}

      {simpleErrorMessage && <SitelineText variant="secondary">{simpleErrorMessage}</SitelineText>}

      {showMainContent && (
        <>
          <div className="invoiceSettingsContainer">
            <div className="settingContainer">
              {/* Don't show the invoice code if it cannot be manually entered */}
              {doesIntegrationSupportManualCode && (
                <>
                  <SitelineText variant="secondary" bold color="grey50" className="heading">
                    {t(`${i18nBase}.invoice_code`)}
                  </SitelineText>
                  {doesIntegrationSupportAutoCode && (
                    <FormControl variant="outlined" className="useAutoCode">
                      <Select
                        value={useAutoCode}
                        onChange={(evt) => setUseAutoCode(evt.target.value === 'true')}
                      >
                        <MenuItem value="true">
                          {t(`${i18nBase}.generate_code`, { integrationName })}
                        </MenuItem>
                        <MenuItem value="false">{t(`${i18nBase}.enter_manually`)}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </>
              )}
              {!useAutoCode && (
                <>
                  <TextField
                    variant="outlined"
                    error={invoiceCodeTooLong}
                    value={invoiceCode}
                    onChange={(ev) => setInvoiceCode(ev.target.value)}
                    className={clsx('invoiceCode', {
                      withGeneratingCode: doesIntegrationSupportGeneratingCode,
                    })}
                    autoFocus={!doesIntegrationSupportGeneratingCode && !shouldAutoFocusBatchNumber}
                    disabled={generatingInvoiceCode}
                  />
                  {doesIntegrationSupportGeneratingCode && (
                    <LoadingButton
                      variant="outlined"
                      color="secondary"
                      onClick={handleGenerateInvoiceCode}
                      startIcon={<AutoAwesomeIcon />}
                      loading={generatingInvoiceCode}
                    >
                      {t(`${i18nBase}.generate_code`, { integrationName: integration.shortName })}
                    </LoadingButton>
                  )}
                </>
              )}
            </div>
            {!useAutoCode && (!defaultInvoiceCode || invoiceCodeHelperText) && (
              <div className="noDefaultCode">
                <SitelineText variant="smallText" color={invoiceCodeTooLong ? 'red50' : 'grey50'}>
                  {invoiceCodeHelperText}
                </SitelineText>
              </div>
            )}
            {doesIntegrationRequireBatchNumber && (
              <div className="settingContainer">
                <SitelineText variant="secondary" bold color="grey50" className="heading">
                  {t(`${i18nBase}.batch`)}
                </SitelineText>
                <TextField
                  error={batchNumberError}
                  variant="outlined"
                  value={batchNumber}
                  onChange={(ev) => setBatchNumber(ev.target.value)}
                  className="invoiceCode"
                  helperText={batchNumberHelperText}
                  autoFocus={shouldAutoFocusBatchNumber}
                />
              </div>
            )}
            {doesIntegrationRequireDrawNumber && (
              <div className="settingContainer">
                <SitelineText variant="secondary" bold color="grey50" className="heading">
                  {t(`${i18nBase}.draw`)}
                </SitelineText>
                <TextField
                  error={drawError}
                  variant="outlined"
                  value={draw}
                  onChange={(ev) => setDraw(ev.target.value)}
                  className="invoiceCode"
                  helperText={drawHelperText}
                  inputProps={{ maxLength: MAX_SAGE_300_DRAW_NUMBER_LENGTH }}
                />
              </div>
            )}
            <div className="settingContainer">
              <SitelineText variant="secondary" bold color="grey50" className="heading">
                {t(`${i18nBase}.invoice_date`)}
              </SitelineText>
              <DatePickerInput
                value={invoiceDate}
                onChange={(value) =>
                  setInvoiceDate({
                    ...value,
                    date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                  })
                }
                timeZone={timeZone}
                className="dateInput"
              />
            </div>
            {doesIntegrationRequireDueDate && (
              <div className="settingContainer">
                <SitelineText variant="secondary" bold color="grey50" className="heading">
                  {t(`${i18nBase}.due_date`)}
                </SitelineText>
                <DatePickerInput
                  value={dueDate}
                  onChange={(value) =>
                    setDueDate({
                      ...value,
                      date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                    })
                  }
                  timeZone={timeZone}
                  className="dateInput"
                />
              </div>
            )}
            {doesIntegrationSupportReferenceNumber && (
              <div className="settingContainer">
                <SitelineText variant="secondary" bold color="grey50" className="heading">
                  {t(`${i18nBase}.reference`)}
                </SitelineText>
                <TextField
                  variant="outlined"
                  onChange={(ev) => setReferenceNumber(ev.target.value)}
                  className="invoiceCode"
                  helperText={referenceNumberHelperText}
                  error={referenceNumberError}
                  value={referenceNumber}
                  inputProps={{ maxLength: MAX_SAGE_INTACCT_REFERENCE_NUMBER_LENGTH }}
                />
              </div>
            )}
          </div>
          {showAllocation && (
            <>
              {allAllocated && (
                <div className="banner green">
                  <SitelineText variant="h4" color="green50" startIcon={<CheckIcon />}>
                    {t(`${i18nBase}.all_allocated`, { integrationName })}
                  </SitelineText>
                  {numMatchesMissing > 0 && (
                    <Button variant="outlined" color="secondary" onClick={handleResetButtonClick}>
                      {t(`${i18nBase}.reset`)}
                    </Button>
                  )}
                  {!isManualMode && numMatchesMissing === 0 && (
                    <Button
                      onClick={() => setIsManualMode(true)}
                      variant="outlined"
                      color="secondary"
                    >
                      {t(`${i18nBase}.edit_manually`)}
                    </Button>
                  )}
                </div>
              )}
              {!allAllocated && (
                <div className="banner blue">
                  {isManualMode && (
                    <div className="remainderContainer">
                      <SitelineText variant="h4" color="blue50">
                        {t(`${i18nBase}.remainder`)}
                      </SitelineText>
                      <div>
                        <SitelineText variant="h3" bold color="grey90">
                          <DollarNumberFormat value={amountToAllocate} />
                        </SitelineText>
                        <SitelineText variant="caption" color="grey50">
                          {t(`${i18nBase}.amount`)}
                        </SitelineText>
                      </div>
                      <div>
                        <SitelineText variant="h3" bold color="grey90">
                          <DollarNumberFormat value={retentionToAllocate} />
                        </SitelineText>
                        <SitelineText variant="caption" color="grey50">
                          {t(`${i18nBase}.retainage`)}
                        </SitelineText>
                      </div>
                    </div>
                  )}
                  {!isManualMode && (
                    <div>
                      <SitelineText variant="h4" color="blue50">
                        {t(`${i18nBase}.did_not_match`, { count: numMatchesMissing })}
                      </SitelineText>
                      <SitelineText variant="body1" color="blue50">
                        {t(`${i18nBase}.allocate`, { integrationName })}
                      </SitelineText>
                    </div>
                  )}
                  <div className="actionsContainer">
                    {!isManualMode && (
                      <Button onClick={enterManually} variant="outlined" color="secondary">
                        {t(`${i18nBase}.enter_manually`)}
                      </Button>
                    )}
                    <Button
                      onClick={distributeFreely}
                      variant="outlined"
                      color="secondary"
                      className="autoAllocate"
                    >
                      {t(`${i18nBase}.auto_allocate`)}
                    </Button>
                    <Tooltip placement="top" title={t(`${i18nBase}.allocate_help`)}>
                      <HelpIcon className="helpIcon" />
                    </Tooltip>
                  </div>
                </div>
              )}
              <Spreadsheet
                columns={columns}
                content={content}
                onChange={handleChange}
                blurOnClickAway
              />
            </>
          )}
        </>
      )}
    </SitelineDialog>
  )
}
