import { Breakpoint, Button, Container } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useNavigate, useParams, useRouterState } from '@tanstack/react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_CONTRACT_RETENTION_PERCENT,
  IntegrationTypeFamily,
  getIntegrationTypeFamily,
} from 'siteline-common-all'
import { BillingType, ButtonLabelSpinner, colors, makeStylesFast } from 'siteline-common-web'
import { Prompt } from '../../../common/components/Prompt'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import { SitelineFooter } from '../../../common/components/SitelineFooter'
import { SpreadsheetProps } from '../../../common/components/Spreadsheet/Spreadsheet'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { HEADER_HEIGHT, TOP_HEADER_HEIGHT } from '../../../common/themes/Main'
import {
  EditingSov,
  isValidSov,
  useQuerySovForEditing,
  useSaveSov,
} from '../../../common/util/ManageSov'
import { trackEditedSov } from '../../../common/util/MetricsTracking'
import { canPreviewSovImport } from '../../../common/util/PayApp'
import { sovToEditingSov } from '../../../common/util/ProjectOnboarding'
import { BillingPathType, BillingTab, SovOnboardingStep, getBillingPath } from '../Billing.lib'
import { ContractForProjectHome } from '../home/ProjectHome'
import { ProjectHomeHeader } from '../home/ProjectHomeHeader'
import { ProjectHomeHeaderSovButtons } from '../home/ProjectHomeHeaderSovButtons'
import { ManageSov, ManageSovState } from './ManageSov'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 0, 4),
    backgroundColor: colors.white,
    minHeight: 'calc(100vh)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .buttons': {
      display: 'flex',
      alignItems: 'center',
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
}))

export const EMPTY_SOV: EditingSov = {
  lineItems: [],
  groups: [],
  preSitelineRetentionHeldOverride: null,
  defaultRetentionPercent: DEFAULT_CONTRACT_RETENTION_PERCENT,
  totalRetentionHeld: 0,
}

const i18nBase = 'projects.subcontractors.sov'

interface ProjectSovProps
  extends Pick<SpreadsheetProps, 'initialSpreadsheetWidth' | 'onSpreadsheetWidthChange'> {
  contract: ContractForProjectHome
  canEdit: boolean
}

/** Project-level tab for viewing and editing the contract SOV */
export function ProjectSov({ contract, canEdit, ...props }: ProjectSovProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { confirm } = useSitelineConfirmation()
  const navigate = useNavigate()
  const { projectId } = useParams({ from: '/_a/billing/$projectId' })
  const { timeZone, name: projectName, isContractActive } = useProjectContext()
  const { companyId, permissions } = useCompanyContext()
  const state = useRouterState({ select: (state) => state.location.state.sov })

  const {
    contract: contractForSov,
    refetch,
    loading,
  } = useQuerySovForEditing({ projectId, companyId })
  const [saveSov, savingSov] = useSaveSov({
    contractForOnboarding: contract,
    sovId: contractForSov?.sov?.id ?? '',
  })

  const initialSovState = useMemo(() => {
    if (state?.isEditing) {
      return ManageSovState.EDIT
    }
    return ManageSovState.VIEW
  }, [state?.isEditing])

  const initialSov = useMemo(() => {
    if (!contractForSov || !contractForSov.sov) {
      return EMPTY_SOV
    }
    return sovToEditingSov(
      [...contractForSov.sov.lineItems],
      contractForSov.pastPayAppCount > 0,
      timeZone,
      contractForSov.sov.totalRetention,
      contractForSov
    )
  }, [contractForSov, timeZone])

  const [manageSovState, setManageSovState] = useState<ManageSovState>(initialSovState)

  const [editingSov, setEditingSov] = useState<EditingSov>(initialSov)
  const [showEditSovAlert, setShowEditSovAlert] = useState<boolean>(false)
  const [shouldWarnOnExit, setShouldWarnOnExit] = useState<boolean>(false)

  const hasGcPortalIntegration = contract.integrations.some(
    (integration) => getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.GC_PORTAL
  )
  // Show an import button if:
  // 1. The SOV can be modified
  // 2. There is no GC portal integration
  const shouldShowImportButton =
    canPreviewSovImport(contract, permissions) && !hasGcPortalIntegration

  // If navigating directly to the edit view, switch the SOV state
  useEffect(() => {
    if (state?.isEditing) {
      setManageSovState(ManageSovState.EDIT)
    }
  }, [state?.isEditing])

  // Update the SOV when the query fetches data from the backend
  useEffect(() => {
    setEditingSov(initialSov)
  }, [initialSov])

  // Remove the invalid SOV alert if all fields are corrected
  useEffect(() => {
    if (showEditSovAlert && contractForSov?.sov) {
      setShowEditSovAlert(!isValidSov(editingSov, contractForSov.sov))
    }
  }, [showEditSovAlert, editingSov, contractForSov?.sov])

  // When done editing the SOV, refresh with the latest from the server (this may be resetting
  // the SOV if canceling changes, or pulling the latest after mutating it)
  const handleEndEditing = useCallback(
    async (withSave: boolean) => {
      setManageSovState(ManageSovState.VIEW)
      await refetch()

      // If changes to the SOV weren't saved, reset the SOV to the initial SOV.
      // (If changes were saved, refetching the new SOV will trigger an update as well.)
      if (!withSave) {
        setEditingSov(initialSov)
      }
    },
    [refetch, initialSov]
  )

  // If editing, show a warning when leaving the page if the SOV has been edited
  const showNavigateAwayWarning = manageSovState !== ManageSovState.VIEW && shouldWarnOnExit

  const handleSave = useCallback(
    async (overrideSov?: EditingSov) => {
      if (!contractForSov?.sov) {
        return
      }
      if (!isValidSov(editingSov, contractForSov.sov)) {
        setShowEditSovAlert(true)
        return
      }
      const sovToSave = overrideSov ?? editingSov
      await saveSov({
        preSitelineRetentionHeldOverride: sovToSave.preSitelineRetentionHeldOverride,
        defaultRetentionPercent: sovToSave.defaultRetentionPercent,
        newLineItems: sovToSave.lineItems,
        newGroups: sovToSave.groups,
        oldContract: contractForSov,
        lineItemToSovLineItem: (lineItem) => {
          const sortedIndex = sovToSave.lineItems.findIndex(
            (sovLineItem) => sovLineItem.id === lineItem.id
          )
          const lineItemGroup = sovToSave.groups.find((group) => group.id === lineItem.groupId)
          const lineItemTaxGroup = contract.company.taxGroups.find(
            (taxGroup) => taxGroup.id === lineItem.taxGroupId
          )
          return {
            __typename: 'SovLineItem',
            id: lineItem.id,
            code: lineItem.code,
            sortOrder: sortedIndex + 1,
            name: lineItem.name,
            costCode: lineItem.costCode || null,
            isChangeOrder: lineItem.isChangeOrder ?? false,
            changeOrderApprovedDate:
              lineItem.isChangeOrder && lineItem.changeOrderApprovedAt
                ? lineItem.changeOrderApprovedAt.toISOString()
                : null,
            changeOrderEffectiveDate:
              lineItem.isChangeOrder && lineItem.changeOrderEffectiveAt
                ? lineItem.changeOrderEffectiveAt.toISOString()
                : null,
            originalTotalValue: lineItem.originalTotalValue,
            latestTotalValue: lineItem.latestTotalValue,
            previousBilled: lineItem.preSitelineBilling ?? 0,
            sovLineItemGroup: lineItemGroup
              ? {
                  __typename: 'SovLineItemGroup',
                  id: lineItemGroup.id,
                  code: lineItemGroup.code,
                  name: lineItemGroup.name,
                }
              : null,
            billedToDate: lineItem.billedToDate,
            totalRetention: lineItem.retentionToDate ?? 0,
            unitName: lineItem.unitName || null,
            unitPrice: lineItem.unitPrice ?? null,
            defaultRetentionPercent: lineItem.defaultRetentionPercent ?? null,
            preSitelineRetentionHeldOverride: lineItem.preSitelineRetentionAmount ?? null,
            taxGroup: lineItemTaxGroup ?? null,
          }
        },
        t,
        onSaved: (callback: () => void) => {
          setShouldWarnOnExit(false)

          // Metric
          trackEditedSov({ projectId, projectName, companyId })

          // Wrap this in a timeout so the `shouldWarnOnExit` state updates and we don't show the navigate away
          // warning when leaving
          setTimeout(() => {
            handleEndEditing(true)
            callback()
          })
        },
      })
    },
    [
      contractForSov,
      editingSov,
      saveSov,
      t,
      contract.company.taxGroups,
      projectId,
      projectName,
      companyId,
      handleEndEditing,
    ]
  )

  const handleSaveCurrentSov = useCallback(() => handleSave(), [handleSave])

  const handleCancel = useCallback(() => {
    if (!shouldWarnOnExit) {
      handleEndEditing(false)
      return
    }
    confirm({
      title: t('projects.onboarding.sov_onboarding.cancel_edit_dialog.title'),
      details: t('projects.onboarding.sov_onboarding.cancel_edit_dialog.description'),
      confirmLabel: t('projects.onboarding.sov_onboarding.cancel_edit_dialog.confirm'),
      cancelLabel: t('projects.onboarding.sov_onboarding.cancel_edit_dialog.cancel'),
      callback: (confirmed: boolean) => {
        if (!confirmed) {
          return
        }
        handleEndEditing(false)
      },
      confirmationType: 'delete',
    })
  }, [confirm, t, handleEndEditing, shouldWarnOnExit])

  const handleImport = useCallback(
    () =>
      navigate(
        getBillingPath({
          pathType: BillingPathType.ProjectSovOnboarding,
          projectId,
          sovOnboardingStep: SovOnboardingStep.IMPORT,
        })
      ),
    [navigate, projectId]
  )

  // Don't max out the SOV for unit price contracts, since there are more columns shown
  const maxWidth: Breakpoint | false =
    contractForSov?.billingType === BillingType.UNIT_PRICE ? false : 'xl'

  const shouldShowEditButtons = isContractActive && canEdit

  return (
    <>
      <Prompt
        when={showNavigateAwayWarning}
        message={t('projects.onboarding.sov_onboarding.navigate_away')}
      />
      <div className={classes.root}>
        <ProjectHomeHeader contract={contract} canEdit={canEdit} selectedTab={BillingTab.SOV}>
          <>
            {manageSovState === ManageSovState.VIEW ? (
              <>
                {shouldShowEditButtons && (
                  <ProjectHomeHeaderSovButtons
                    onEditSov={(addChangeOrder) =>
                      setManageSovState(
                        addChangeOrder ? ManageSovState.ADD_CHANGE_ORDER : ManageSovState.EDIT
                      )
                    }
                    contract={contract}
                  />
                )}
              </>
            ) : (
              <>
                {shouldShowImportButton && (
                  <Button variant="outlined" color="secondary" onClick={handleImport}>
                    {t(`${i18nBase}.import`)}
                  </Button>
                )}
                <Button variant="outlined" color="secondary" onClick={handleCancel}>
                  {t('common.actions.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveCurrentSov}
                  disabled={savingSov}
                  startIcon={savingSov ? <ButtonLabelSpinner /> : undefined}
                >
                  {t('common.actions.save')}
                </Button>
              </>
            )}
          </>
        </ProjectHomeHeader>
        <Container maxWidth={maxWidth}>
          <ManageSov
            sov={editingSov}
            contract={contractForSov}
            onSovChange={({ updateSov, shouldWarnOnExit, shouldSaveImmediately }) => {
              setEditingSov(updateSov)
              if (shouldWarnOnExit) {
                setShouldWarnOnExit(true)
              }
              if (shouldSaveImmediately) {
                const newSov = updateSov(editingSov)
                handleSave(newSov)
              }
            }}
            showEditSovAlert={showEditSovAlert}
            manageSovState={manageSovState}
            stickyHeaderTopOffset={TOP_HEADER_HEIGHT + HEADER_HEIGHT}
            loading={loading || savingSov}
            {...props}
          />
        </Container>
      </div>
      <SitelineFooter />
    </>
  )
}
