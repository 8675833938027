import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Theme } from '@mui/material/styles'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { TAX_RATE_PERCENT_PRECISION, decimalToPercent } from 'siteline-common-all'
import { SitelineTooltip, colors, makeStylesFast } from 'siteline-common-web'
import { SpreadsheetColumn, SpreadsheetDataType } from '../components/Spreadsheet/Spreadsheet.lib'
import { TaxGroupProperties } from '../graphql/apollo-operations'
import { ManageLumpSumSovColumn } from './ManageLumpSumSovColumn'
import { BaseManageSovColumn } from './ManageSov'

const i18nBase = 'projects.subcontractors.sov'

type MakeReadOnlyColumnProps = { t: TFunction }
type MakeColumnProps = MakeReadOnlyColumnProps & { isEditable: boolean }

const useStyles = makeStylesFast((theme: Theme) => ({
  defaultPercentHeading: {
    // Match cellText in SpreadsheetHeaderCell.tsx
    ...theme.typography.h6,
    color: colors.grey50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoIcon: {
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
  },
  tooltip: {
    maxWidth: 'unset',
  },
}))

export function makePreSitelineBillingColumn({
  isEditable,
  t,
}: MakeColumnProps): SpreadsheetColumn {
  return {
    id: ManageLumpSumSovColumn.PRE_SITELINE_BILLING,
    heading: t(`${i18nBase}.headers.pre_siteline_billing`),
    isEditable,
    dataType: SpreadsheetDataType.DOLLAR as const,
    align: 'right' as const,
  }
}

/** Default retention percent header with info icon */
function DefaultRetentionColumnHeading() {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <span className={classes.defaultPercentHeading}>
      <span>{t(`${i18nBase}.headers.retention_percent`)}</span>
      <SitelineTooltip
        classes={{ tooltip: classes.tooltip }}
        title={t(`${i18nBase}.headers.retention_percent_tooltip`)}
        placement="top"
      >
        <InfoOutlinedIcon className={classes.infoIcon} />
      </SitelineTooltip>
    </span>
  )
}

export function makeDefaultRetentionPercentColumn({
  isEditable,
}: MakeColumnProps): SpreadsheetColumn {
  return {
    id: BaseManageSovColumn.RETENTION_PERCENT,
    heading: <DefaultRetentionColumnHeading />,
    isEditable,
    dataType: SpreadsheetDataType.PERCENT as const,
    align: 'right' as const,
  }
}

export function makePreSitelineRetentionAmountColumn({
  isEditable,
  t,
}: MakeColumnProps): SpreadsheetColumn {
  return {
    id: BaseManageSovColumn.PRE_SITELINE_RETENTION_AMOUNT,
    heading: t(`${i18nBase}.headers.pre_siteline_retention_amount`),
    isEditable,
    dataType: SpreadsheetDataType.DOLLAR as const,
    align: 'right' as const,
  }
}

export function makeChangeOrderColumn({ isEditable, t }: MakeColumnProps): SpreadsheetColumn {
  return {
    id: BaseManageSovColumn.CHANGE_ORDER,
    heading: t(`${i18nBase}.headers.change_order`),
    isEditable,
    dataType: SpreadsheetDataType.OTHER as const,
    // Max out the width at the size of the header, since the only content in this column will be
    // the checkbox or checkmark
    maxWidth: 112,
  }
}

export function makeChangeOrderApprovalColumn({
  isEditable,
  t,
}: MakeColumnProps): SpreadsheetColumn {
  return {
    id: BaseManageSovColumn.CHANGE_ORDER_DATE,
    heading: t(`${i18nBase}.headers.change_order_date`),
    isEditable,
    dataType: SpreadsheetDataType.OTHER as const,
    minWidth: 140,
    maxWidth: 140,
    padding: 'none',
  }
}

export function makeTotalRetainageColumn({ t }: MakeReadOnlyColumnProps): SpreadsheetColumn {
  return {
    id: BaseManageSovColumn.RETAINAGE,
    heading: t(`${i18nBase}.headers.retainage`),
    isEditable: false,
    dataType: SpreadsheetDataType.DOLLAR as const,
    align: 'right' as const,
  }
}

type MakeTaxGroupColumnProps = MakeColumnProps & {
  taxGroups: TaxGroupProperties[]
  onAddTaxGroupForLineItemId: (lineItemId: string) => void
}

export function makeTaxGroupColumn({
  t,
  isEditable,
  taxGroups,
  onAddTaxGroupForLineItemId,
}: MakeTaxGroupColumnProps): SpreadsheetColumn {
  const taxGroupOptions = _.chain(taxGroups)
    .map((taxGroup) => ({
      id: taxGroup.id,
      label: `${taxGroup.name} (${decimalToPercent(taxGroup.taxPercent, TAX_RATE_PERCENT_PRECISION)}%)`,
    }))
    .orderBy((taxGroup) => taxGroup.label)
    .value()
  return {
    id: BaseManageSovColumn.TAX_GROUP,
    heading: t(`${i18nBase}.headers.tax_group`),
    isEditable,
    dataType: SpreadsheetDataType.SELECT,
    options: taxGroupOptions,
    addOption: {
      label: t(`${i18nBase}.add_tax_group`),
      onClick: onAddTaxGroupForLineItemId,
    },
    searchPlaceholder: t(`${i18nBase}.search_tax_groups`),
    allowEmpty: true,
  }
}
