import { ClickAwayListener, Skeleton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import {
  MouseEvent,
  Reducer,
  UIEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DragDropContext, DragStart, DragUpdate, DropResult, Droppable } from 'react-beautiful-dnd'
import { isDesktop, isMacOs } from 'react-device-detect'
import { GlobalHotKeys, configure as configureHotKeys } from 'react-hotkeys'
import { colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { HEADER_HEIGHT, Z_INDEX } from '../../themes/Main'
import { vh } from '../../util/Browser'
import { DEFAULT_SKELETON_COLUMN_WIDTH, getSkeletonRow } from '../../util/InvoiceRow'
import { LumpSumInvoiceColumn } from '../../util/LumpSumInvoice'
import { useSitelineConfirmation } from '../SitelineConfirmation'
import {
  HIDDEN_ON_ROW_HOVER_CLASS,
  VISIBLE_ON_CELL_HOVER_CLASS,
  VISIBLE_ON_ROW_HOVER_CLASS,
} from '../SitelineTable'
import { DraggableSpreadsheetRow } from './DraggableSpreadsheetRow'
import {
  CellPosition,
  SpreadsheetRow as Row,
  SpreadsheetColumn,
  SpreadsheetContent,
  SpreadsheetValue,
  cellAtPosition,
  cellIndexForColumnIndex,
  columnIndexForCell,
  emptyValueForColumn,
  getSpreadsheetCellId,
  includeDragHandleColumn,
  useReducerWithoutBatching,
  useSpreadsheetFocus,
  validateSpreadsheet,
} from './Spreadsheet.lib'
import { useSpreadsheetContext } from './SpreadsheetContext'
import { SpreadsheetHeaderCell } from './SpreadsheetHeaderCell'
import { SpreadsheetHint, SpreadsheetHintContent } from './SpreadsheetHint'
import {
  SpreadsheetAction,
  SpreadsheetReducerState,
  spreadsheetReducer,
} from './SpreadsheetReducer'
import SpreadsheetRow from './SpreadsheetRow'
import {
  ContextMenuAnchorPosition,
  ContextMenuLabels,
  SpreadsheetRowContextMenu,
} from './SpreadsheetRowContextMenu'
import {
  CellWidthUpdate,
  DRAG_HANDLE_COLUMN_WIDTH,
  SpreadsheetSizingAction,
  SpreadsheetSizingReducerState,
  calculateCellLayout,
  emptySizing,
  getSpreadsheetColumnSizing,
  spreadsheetSizingReducer,
} from './SpreadsheetSizingReducer'

export const SPREADSHEET_MIN_ROW_HEIGHT = 40
export const SPREADSHEET_CELL_SIDE_PADDING = 8
export const SPREADSHEET_EDGE_CELLS_SIDE_PADDING = 16
export const SPREADSHEET_BORDER_PADDING = 2
const DEFAULT_NUM_SKELETON_ROWS = 3

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    fontFeatureSettings: 'tnum',
    overflow: 'hidden',
  },
  table: {
    minWidth: '100%',
    overflowX: 'auto',
    '& .tableInner': {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      '& .isFirstInUngroupedBlock': {
        borderTop: `1px solid ${colors.grey30}`,
      },
      '& .row': {
        display: 'flex',
        flexWrap: 'nowrap',
        width: '100%',
        '&.clickable': {
          cursor: 'pointer',
        },
        '& .cell': {
          boxSizing: 'border-box',
          flexGrow: 1,
          overflow: 'hidden',
          listStyle: 'none',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: colors.grey10,
          minHeight: SPREADSHEET_MIN_ROW_HEIGHT,
          borderBottom: `1px solid ${colors.grey30}`,
          '&:first-of-type': {
            '& .cellInner': {
              paddingLeft: SPREADSHEET_EDGE_CELLS_SIDE_PADDING,
            },
          },
          '&:last-of-type': {
            '& .cellInner': {
              paddingRight: SPREADSHEET_EDGE_CELLS_SIDE_PADDING,
            },
          },
          '&.blueBackground': {
            backgroundColor: colors.blue10,
          },
          '&.whiteBackground': {
            backgroundColor: colors.white,
          },
          '&.redBorder': {
            borderWidth: 1,
            borderStyle: 'solid',
            // Use !important to override all default border styles
            borderColor: `${colors.red50} !important`,
          },
          '& .clear': {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2, 3),
            '& .message': {
              fontStyle: 'italic',
              marginRight: theme.spacing(3),
            },
          },
          '&.isEditable': {
            // Avoid jump when adding extra border for selected cells
            padding: 2,
            paddingBottom: 1,
            backgroundColor: colors.white,
            cursor: 'pointer',
            // If cell to the left is selected, border will be applied by that cell. If cell
            // to the left is NOT selected, apply a left border to the cell.
            '&:not(.isSelected)': {
              '&.isPreviousCellNotSelected': {
                // Don't apply left-border/padding to the cell all the way to the left
                '&:not(:first-of-type)': {
                  borderLeft: `1px solid ${colors.grey30}`,
                  paddingLeft: 1,
                },
              },
            },
            '&.CONTENT': {
              cursor: 'default',
            },
          },
          '&:not(.isEditable)': {
            '&.isPreviousCellEditable': {
              borderLeft: `1px solid ${colors.grey30}`,
            },
          },
          '&.isDragging': {
            backgroundColor: colors.grey20,
            '&.blueBackground': {
              backgroundColor: colors.blue20,
            },
            '&.whiteBackground': {
              backgroundColor: colors.white,
            },
            '&.isEditable': {
              backgroundColor: colors.grey10,
              '&.blueBackground': {
                backgroundColor: colors.blue10,
              },
              '&.whiteBackground': {
                backgroundColor: colors.white,
              },
            },
          },
          '&.isDragging, &.draggingTo': {
            borderTop: `1px solid ${colors.grey30}`,
          },
          '&.isSelected': {
            // This prioritizes focus styling over the border overrides that may be passed
            // to this component as a prop and intended for styling in an un-focused state
            border: `2px solid ${colors.grey90} !important`,
            padding: 0,
          },
          '&.isGroupHeader': {
            backgroundColor: colors.grey20,
            fontWeight: 600,
          },
          '&.wordBreakAll': {
            wordBreak: 'break-all',
          },
          [`& .${HIDDEN_ON_ROW_HOVER_CLASS}`]: {
            visibility: 'visible',
            whiteSpace: 'nowrap',
          },
          [`& .${VISIBLE_ON_ROW_HOVER_CLASS}`]: {
            visibility: isDesktop ? 'hidden' : 'visible',
          },
          [`& .${VISIBLE_ON_CELL_HOVER_CLASS}`]: {
            display: isDesktop ? 'none' : 'block',
          },
          [`&:hover .${VISIBLE_ON_CELL_HOVER_CLASS}`]: {
            display: 'block',
          },
          '& > .cellInner': {
            display: 'flex',
            width: '100%',
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: SPREADSHEET_CELL_SIDE_PADDING,
            paddingRight: SPREADSHEET_CELL_SIDE_PADDING,
            '&.left': {
              justifyContent: 'flex-start',
            },
            '&.center': {
              justifyContent: 'center',
              textAlign: 'center',
            },
            '&.right': {
              justifyContent: 'flex-end',
              textAlign: 'right',
            },
            '& > .hasRightContent': {
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
            '& > .hasLeftContent': {
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              '&.isLeftContentRightAligned': {
                justifyContent: 'flex-end',
              },
            },
            '&.noPadding': {
              padding: 0,
              height: '100%',
            },
          },
          '&.CONTENT > .cellInner': {
            paddingTop: 0,
            paddingBottom: 0,
            alignItems: 'center',
          },
        },
        '& .delete': {
          '& .MuiButtonBase-root': {
            padding: 3,
            '& .MuiSvgIcon-root': {
              color: colors.red50,
            },
          },
          '&.isDisabled': {
            cursor: 'default',
            '& .MuiSvgIcon-root': {
              color: colors.grey40,
            },
          },
        },
        '& .headerCell': {
          borderTop: `1px solid ${colors.grey30}`,
        },
        '& .dragHandleColumn': {
          width: DRAG_HANDLE_COLUMN_WIDTH,
          minWidth: DRAG_HANDLE_COLUMN_WIDTH,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiSvgIcon-root': {
            transition: theme.transitions.create('color'),
            color: colors.grey50,
          },
          '&.disableReordering .MuiSvgIcon-root': {
            color: colors.grey30,
          },
          '&:focus-visible': {
            outline: 'none',
          },
        },
        '&.groupDivider': {
          height: SPREADSHEET_MIN_ROW_HEIGHT / 2,
          backgroundColor: 'transparent',
        },
      },
      '&:not(.isDragging)': {
        '& .row:hover:not(.headerRow):not(.isNonEditableRow) .cell:not(.isEditing)': {
          // Do not apply hover styles to cells with the class "noBackground"
          '&:not(.noBackground)': {
            backgroundColor: colors.grey20,
          },
          '&.blueBackground': {
            backgroundColor: colors.blue20,
          },
          '&.whiteBackground': {
            backgroundColor: colors.white,
          },
          '&.isEditable': {
            backgroundColor: colors.grey10,
            '&.blueBackground': {
              backgroundColor: colors.grey10,
            },
            '&.whiteBackground': {
              backgroundColor: colors.white,
            },
          },
          [`& .${HIDDEN_ON_ROW_HOVER_CLASS}`]: {
            visibility: 'hidden',
          },
          [`& .${VISIBLE_ON_ROW_HOVER_CLASS}`]: {
            visibility: 'visible',
            whiteSpace: 'nowrap',
          },
        },
      },
      '& .isDragging .cell': {
        backgroundColor: colors.grey20,
        '&.blueBackground': {
          backgroundColor: colors.blue20,
        },
        '&.whiteBackground': {
          backgroundColor: colors.white,
        },
        '&.isEditable': {
          backgroundColor: colors.grey10,
          '&.blueBackground': {
            backgroundColor: colors.grey10,
          },
          '&.whiteBackground': {
            backgroundColor: colors.white,
          },
        },
      },
      '& .isDragging .cell, &.draggingToAbove .cell': {
        borderTop: `1px solid ${colors.grey30}`,
      },
      '& .isDraggingGroup .cell:not(.isGroupHeader)': {
        opacity: 0,
      },
    },
  },
  sizing: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    visibility: 'hidden',
  },
  stickyHeader: {
    position: 'sticky',
    left: 0,
    overflow: 'hidden',
    opacity: 0,
    transition: theme.transitions.create('opacity', { duration: 250 }),
    height: 0,
    width: 0,
    minWidth: 0,
    zIndex: Z_INDEX.stickyHeader,
    '&.showStickyHeader': {
      marginBottom: -SPREADSHEET_MIN_ROW_HEIGHT,
      height: SPREADSHEET_MIN_ROW_HEIGHT,
      width: '100%',
      opacity: 1,
      '& .row .cell': {
        // Since the sticky header has a fixed height, the cells need
        // to have the same fixed height or the border won't show correctly
        maxHeight: SPREADSHEET_MIN_ROW_HEIGHT,
      },
    },
  },
  stickyFooter: {
    position: 'sticky',
    left: 0,
    bottom: 0,
    overflow: 'hidden',
    opacity: 0,
    transition: theme.transitions.create('opacity', { duration: 250 }),
    width: 0,
    minWidth: 0,
    zIndex: Z_INDEX.stickyFooter,
    '&.showStickyFooter': {
      width: '100%',
      opacity: 1,
      borderTop: `1px solid ${colors.grey30}`,
    },
  },
  skeletonTableRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: SPREADSHEET_MIN_ROW_HEIGHT,
    backgroundColor: colors.grey10,
    borderBottom: `1px solid ${colors.grey30}`,
    padding: theme.spacing(0, 2),
  },
  skeletonTableHeaderRow: {
    backgroundColor: colors.grey10,
    borderTop: `1px solid ${colors.grey30}`,
    '& > *': {
      ...theme.typography.h6,
      color: colors.grey50,
    },
  },
}))

export interface SpreadsheetProps {
  /**
   * The columns to show on the spreadsheet. Be sure to memoize this prop (i.e. the parent should wrap the object
   * in a `useMemo` hook) or the spreadsheet will try to re-render on every change.
   */
  columns: SpreadsheetColumn[]
  content: SpreadsheetContent

  /**
   * If true, will de-select cells when clicking outside the table. Expected to be
   * true by default, but should be disabled when a dialog or other interstitial is open.
   */
  blurOnClickAway: boolean

  onChange?: (rowId: string, columnId: string, toValue: SpreadsheetValue) => void
  /** Returns true if the reorder is valid and should be reflected in the table */
  onReorder?: (rowId: string, toRowIndex: number) => boolean
  onBeforeReorder?: () => void

  /**
   * If provided, a context menu will be attached to the spreadsshet row allowing
   * the user to insert a row above or below the row that was right clicked. Note:
   * this callback should handle the actual insertion of the row.
   */
  onInsertRow?: (anchorRowIndex: number, direction: 'up' | 'down') => void

  /**
   * If provided, this function will be called when the user attempts
   * to edit a cell. Instead of focusing the cell input, this function is called
   * and no edit will occur.
   */
  onBeforeEdit?: () => void

  /**
   * If provided, this function will be called when the user attempts
   * to save a cell. Saving will only occur if this returns true.
   */
  onBeforeSave?: (params: {
    onSave: () => void
    onCancel: () => void
    rowId: string
    columnId: string
    fromValue: SpreadsheetValue
    toValue: SpreadsheetValue
  }) => void

  /** If true, will show a loading skeleton */
  loading?: boolean

  /** Initial width to use for the spreadsheet before its sizing is measured */
  initialSpreadsheetWidth?: number

  /** Called whenever a new width is measured for the spreadsheet table */
  onSpreadsheetWidthChange?: (width: number) => void

  /**
   * If provided, will show a sticky header with this `top` value. If undefined,
   * the sticky header is disabled.
   */
  stickyHeaderTopOffset?: number
  /**
   * The spreadsheet component houses all logic surrounding which element should be in focus.
   * It does this using an ID which is assigned internally to the wrapping div. This ID should
   * only be passed in when two spreadsheets are mounted at the same time, and external logic
   * is required to determine which spreadsheet should be in focus.
   */
  spreadsheetFocusId?: string
  /**
   * By default, a spreadsheet takes focus when it mounts. If this prop is false, the spreadsheet
   * will not take focus when mounted and will need to be given focus explicitly if meant to be
   * in focus (using the functions from `SpreadsheetContext`).
   */
  focusOnMount?: boolean
  /**
   * `contextMenuLabels` are optional overrides for the default context menu labels. Note that this is
   * only relevant for spreadsheets where the context menu is turned on (determined by the context menu
   * callbacks that are passed in).
   * Example: if `contextMenuLabels.insertAbove` is NOT provided, the default label will read "add row above".
   */
  contextMenuLabels?: ContextMenuLabels
}

configureHotKeys({
  // Allows user to hold down a key and it will continue to fire events. This is most useful for
  // holding down any of the arrow keys so that you can continue to scroll up, down, etc.
  ignoreRepeatedEventsWhenKeyHeldDown: false,
  // Allows user to touch other keys and it will continue to match the original key you pressed.
  // This is really helpful for navigating around between press and long-press (above config
  // option) and the browser won't receive the event. This prevents weird scrolling artifacts.
  allowCombinationSubmatches: true,
})

const keyMap = {
  DELETE: ['Del', 'Backspace'],
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  NUMBER: '0123456789'.split(''),
  LETTER: 'abcdefghijklmnopqrstuvwxyz'.split(''),
  DASH: ['-'],
  PERIOD: ['.'],
  MOVE_UP: 'ArrowUp',
  MOVE_DOWN: 'ArrowDown',
  MOVE_LEFT: ['ArrowLeft', 'Shift+Tab'],
  MOVE_RIGHT: ['ArrowRight', 'Tab'],
  COPY_ACTION: isMacOs ? 'command+c' : 'ctrl+c',
  CUT_ACTION: isMacOs ? 'command+x' : 'ctrl+x',
  PASTE_ACTION: isMacOs ? 'command+v' : 'ctrl+v',
  // All command+number/letter actions should just do their default actions
  COMMAND_ACTION: '0123456789abcdefghijklmnopqrstuvwxyz'
    .split('')
    .map((letter) => `command+${letter}`),
  CONTROL_ACTION: '0123456789abcdefghijklmnopqrstuvwxyz'
    .split('')
    .map((letter) => `ctrl+${letter}`),
}

const emptyRows: Row[] = []
const emptyCellWidths: CellWidthUpdate[] = []

export type SpreadsheetHandle = {
  focusCell: (rowId: string, columnId: string) => void
  /** Shows a hint above the given cell */
  showHintAtCell: (
    rowId: string,
    columnId: string,
    hintId: string,
    hint: SpreadsheetHintContent
  ) => void
  /** If a hint with this ID is currently shown, it will be closed */
  closeHint: (hintId: string) => void
}

/** A generic component that takes table data and renders it in an editable spreadsheet */
export const Spreadsheet = forwardRef<SpreadsheetHandle, SpreadsheetProps>(
  function Spreadsheet(props, ref) {
    const {
      columns,
      content,
      blurOnClickAway,
      onChange,
      onReorder,
      onBeforeReorder,
      onInsertRow,
      onBeforeEdit,
      onBeforeSave,
      loading,
      initialSpreadsheetWidth,
      onSpreadsheetWidthChange,
      stickyHeaderTopOffset,
      spreadsheetFocusId,
      focusOnMount = true,
      contextMenuLabels,
    } = props

    const classes = useStyles()
    const spreadsheetId = useRef<string>(spreadsheetFocusId ?? uuidv4())
    const spreadsheetSizingId = useRef<string>(uuidv4())
    const spreadsheetRef = useRef<HTMLDivElement>(null)
    const snackbar = useSitelineSnackbar()
    const { confirm } = useSitelineConfirmation()

    const initialState = useMemo(
      () => ({
        columns,
        content,
        onChange,
        onReorder,
        onInsertRow,
        isFocused: focusOnMount,
        isFocusPaused: false,
      }),
      [columns, content, focusOnMount, onChange, onInsertRow, onReorder]
    )

    const [state, dispatch] = useReducerWithoutBatching<
      Reducer<SpreadsheetReducerState, SpreadsheetAction>
    >(spreadsheetReducer, initialState)

    const initialSizing = useMemo(
      () => emptySizing(columns.length, content.rows, initialSpreadsheetWidth),
      [columns.length, content.rows, initialSpreadsheetWidth]
    )
    const initialColumnSizing = useMemo(
      () => getSpreadsheetColumnSizing(initialSizing, columns),
      [initialSizing, columns]
    )
    const initialSizingState: SpreadsheetSizingReducerState = useMemo(
      () => ({
        columns,
        content,
        sizing: initialSizing,
        columnSizing: initialColumnSizing,
      }),
      [columns, content, initialSizing, initialColumnSizing]
    )
    const [sizingState, dispatchSizing] = useReducerWithoutBatching<
      Reducer<SpreadsheetSizingReducerState, SpreadsheetSizingAction>
    >(spreadsheetSizingReducer, initialSizingState)

    const [contextMenu, setContextMenu] = useState<ContextMenuAnchorPosition | null>(null)
    const [draggingRowId, setDraggingRowId] = useState<string | null>(null)
    const [draggingToRowIndex, setDraggingToRowIndex] = useState<number | null>(null)
    const [showStickyHeader, setShowStickyHeader] = useState<boolean>(false)
    const [showStickyFooter, setShowStickyFooter] = useState<boolean>(false)
    const spreadsheetTop = useRef<number>()
    const { pauseFocus, resumeFocus, isFocusPaused } = useSpreadsheetContext()
    const isFocused = useSpreadsheetFocus(spreadsheetId.current, focusOnMount)
    const hasActiveFocus = isFocused && !isFocusPaused
    const [spreadsheetHint, setSpreadsheetHint] = useState<SpreadsheetHint | null>(null)
    const [isHintOpen, setIsHintOpen] = useState<boolean>(false)

    // This handle allows parent components to access internal functions on this component via ref.
    // We expose `focusCell` so callers can directly focus a cell, e.g. when a new row is added.
    useImperativeHandle(
      ref,
      () => ({
        focusCell: (rowId: string, columnId: string) => {
          const rowIndex = content.rows.findIndex((row) => row.id === rowId)
          const columnIndex = columns.findIndex((column) => column.id === columnId)
          if (rowIndex === -1 || columnIndex === -1) {
            return
          }
          const cellIndex = cellIndexForColumnIndex(rowIndex, columnIndex, content)
          dispatch({ type: 'SELECT_AND_EDIT_CELL', cell: { rowIndex, cellIndex } })
        },
        showHintAtCell: (
          rowId: string,
          columnId: string,
          hintId: string,
          hint: SpreadsheetHintContent
        ) => {
          if (isHintOpen && spreadsheetHint?.hintId === hintId) {
            return
          }
          const rowIndex = content.rows.findIndex((row) => row.id === rowId)
          const columnIndex = columns.findIndex((column) => column.id === columnId)
          if (rowIndex === -1 || columnIndex === -1) {
            return
          }

          const cellElement = document.getElementById(
            getSpreadsheetCellId(rowId, columnId)
          ) as HTMLElement | null
          if (cellElement) {
            setSpreadsheetHint({ ...hint, hintId, anchorEl: cellElement })
            setIsHintOpen(true)
          }
        },
        closeHint: (hintId: string) => {
          if (isHintOpen && spreadsheetHint?.hintId === hintId) {
            setIsHintOpen(false)
          }
        },
      }),
      [columns, content, dispatch, isHintOpen, spreadsheetHint?.hintId]
    )

    // If the spreadsheet focus changes, update the reducer
    useEffect(() => {
      dispatch({ type: 'FOCUS_CHANGED', isFocused, isFocusPaused })
    }, [dispatch, isFocused, isFocusPaused])

    const handleResize = useMemo(
      () =>
        _.debounce(() => {
          const spreadsheet = document.getElementById(spreadsheetSizingId.current)
          if (spreadsheet) {
            const { width, top } = spreadsheet.getBoundingClientRect()
            dispatchSizing({ type: 'UPDATE_SPREADSHEET_WIDTH', width })
            if (onSpreadsheetWidthChange) {
              onSpreadsheetWidthChange(width)
            }
            const scrollTop = window.scrollY || document.documentElement.scrollTop
            // Get the spreadsheet's offset from the top of the document
            spreadsheetTop.current = top + scrollTop
          }
        }),
      [onSpreadsheetWidthChange, dispatchSizing]
    )

    // Recalculate table layout whenever the browser is resized
    useEffect(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [handleResize])

    // Update the spreadsheet content in the reducer state if new content is passed in
    useEffect(() => {
      dispatch({ type: 'CONTENT_CHANGE', content })
      // If the rows have changed, also resize the spreadsheet
      const prevRowIds = sizingState.content.rows.map((row) => row.id)
      const newRowIds = content.rows.map((row) => row.id)
      const shouldResize = _.isEqual(prevRowIds, newRowIds)
      dispatchSizing({ type: 'UPDATE_SPREADSHEET_CONTENT', content, shouldResize })
      // Exclude `sizingState.content` from the dependencies so we don't create
      // an infinite loop (since the state's content is set based on the content prop)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])

    // Update the columns in the reducer state if they change, but only after first loading
    // so we don't unnecessarily show an extra loading state on first mount
    const hasInitialColumns = useRef<boolean>(false)
    useEffect(() => {
      dispatch({ type: 'COLUMNS_CHANGE', columns })
      if (hasInitialColumns.current) {
        dispatchSizing({ type: 'UPDATE_SPREADSHEET_COLUMNS', columns })
        handleResize()
      }
      hasInitialColumns.current = true
      // Not necessary to trigger the column change callbacks when the resize function changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns])

    // Update the change handler in state if the prop changes
    useEffect(() => {
      dispatch({
        type: 'CHANGE_HANDLER_CHANGED',
        onChange,
      })
    }, [onChange, dispatch])

    // Update the reorder handler in state if the prop changes
    useEffect(() => {
      dispatch({
        type: 'REORDER_HANDLER_CHANGED',
        onReorder,
      })
    }, [onReorder, dispatch])

    // Auto scrolls the window if you are navigating to the top or bottom
    const lastSelectedCell = useRef<CellPosition>()
    useEffect(() => {
      if (!state.selectedCell) {
        lastSelectedCell.current = undefined
        return
      }

      const classSelector = '.cell.isSelected'
      const element = spreadsheetRef.current?.querySelector(classSelector)
      if (!element) {
        return
      }

      // The amount to scroll the page should be proportional to how many rows away
      // the new selected cell is
      let rowChange =
        lastSelectedCell.current !== undefined
          ? Math.abs(state.selectedCell.rowIndex - lastSelectedCell.current.rowIndex)
          : 1

      // May need to add extra height to jump over auxiliary rows
      const lastSelectedRow =
        lastSelectedCell.current !== undefined
          ? content.rows[lastSelectedCell.current.rowIndex]
          : undefined
      const selectedRow = content.rows[state.selectedCell.rowIndex]
      if (lastSelectedRow && lastSelectedCell.current) {
        if (lastSelectedCell.current.rowIndex < state.selectedCell.rowIndex) {
          // If moving down the table, may need to add height for aux rows on the last row
          rowChange += lastSelectedRow.auxiliaryRows?.length ?? 0
        } else {
          rowChange += selectedRow.auxiliaryRows?.length ?? 0
        }
      }

      lastSelectedCell.current = state.selectedCell

      const rect = element.getBoundingClientRect()
      // Sets a threshold for how much we should be missing before scrolling. This sets it to the
      // height of the selected element, minus 1 meaning that if 1px is not visible, we'll scroll
      const minThreshold = rect.height - 1
      // Sets the amount we scroll by. At minimum, scroll the height of two cells.
      const scrollAmount = Math.max(2, rect.height * rowChange)
      const windowPositionY = window.innerHeight || document.documentElement.clientHeight
      if (rect.y < minThreshold) {
        // At the top of the screen, move the scroll position up 1
        window.scrollBy(0, scrollAmount * -1)
      } else if (rect.y > windowPositionY - minThreshold) {
        // At the bottom of the screen, move the scroll position down 1
        window.scrollBy(0, scrollAmount)
      }
    }, [state.selectedCell, content.rows])

    // Returns the current selected cell's `onBeforeEdit` handler, if defined. Otherwise returns
    // the spreadsheet's `onBeforeEdit` handler. If neither are defined, returns undefined.
    const beforeEditFunction = useMemo(() => {
      if (!state.selectedCell) {
        return onBeforeEdit
      }
      const cell = cellAtPosition(state.selectedCell, state.content)
      if (cell.type === 'DATA' && cell.onBeforeEdit) {
        return cell.onBeforeEdit
      }
      return onBeforeEdit
    }, [onBeforeEdit, state.content, state.selectedCell])

    const handleDragStart = useCallback(
      (initial: DragStart) => {
        if (onBeforeReorder) {
          onBeforeReorder()
        }
        setDraggingRowId(initial.draggableId)
      },
      [onBeforeReorder]
    )

    const handleDragUpdate = useCallback((initial: DragUpdate) => {
      if (initial.destination) {
        setDraggingToRowIndex(initial.destination.index)
      } else {
        setDraggingToRowIndex(null)
      }
    }, [])

    const handleDragEnd = useCallback(
      (result: DropResult) => {
        if (result.reason === 'DROP' && result.destination) {
          dispatch({
            type: 'REORDER',
            rowId: result.draggableId,
            toRowIndex: result.destination.index,
          })
        }
        setDraggingRowId(null)
        setDraggingToRowIndex(null)
      },
      [dispatch]
    )

    const handleRightClick = useCallback(
      (event: MouseEvent) => {
        if (!onInsertRow) {
          return
        }
        setContextMenu({ top: event.clientY, left: event.clientX })
      },
      [onInsertRow]
    )

    const handleCloseContextMenu = useCallback(() => {
      setContextMenu(null)
    }, [])

    const handleInsertRow = useCallback(
      (direction: 'up' | 'down') => {
        if (!onInsertRow || !state.selectedCell) {
          return
        }
        onInsertRow(state.selectedCell.rowIndex, direction)
      },
      [onInsertRow, state.selectedCell]
    )

    const handleClickAway = useCallback(() => {
      if (!state.selectedCell || !blurOnClickAway || !hasActiveFocus) {
        return
      }
      dispatch({ type: 'CLICKED_AWAY' })
    }, [state.selectedCell, blurOnClickAway, hasActiveFocus, dispatch])

    const directEditHandler = useCallback(
      (event?: KeyboardEvent) => {
        if (beforeEditFunction) {
          beforeEditFunction()
          return
        }
        dispatch({ type: 'DIRECTLY_EDIT_CELL', event })
      },
      [beforeEditFunction, dispatch]
    )

    const handlers = useMemo(
      () => ({
        DELETE: () => {
          if (beforeEditFunction) {
            beforeEditFunction()
            return
          }
          if (!state.selectedCell) {
            return
          }
          const cell = cellAtPosition(state.selectedCell, state.content)
          const columnIndex = columnIndexForCell(state.selectedCell, state.content)
          const column = state.columns[columnIndex]
          // Percent complete doesn't need to be validated because the delete code brings it down to
          // the lowest possible value rather than 0. See the reducer code.
          if (
            cell.type === 'DATA' &&
            cell.validate &&
            column.id !== LumpSumInvoiceColumn.PERCENT_COMPLETE
          ) {
            const emptyValue = emptyValueForColumn(column)
            const validated = cell.validate(emptyValue)
            if (validated !== null) {
              switch (validated.type) {
                case 'error':
                  snackbar.showError(validated.message)
                  return
                case 'confirm': {
                  const { title, details, confirmationType, confirmLabel } = validated
                  pauseFocus()
                  confirm({
                    title,
                    details,
                    confirmationType,
                    confirmLabel,
                    callback: (confirmed) => {
                      if (confirmed) {
                        dispatch({ type: 'DELETE' })
                      }
                      resumeFocus()
                    },
                  })
                  return
                }
                case 'override':
                  break
              }
            }
          }
          dispatch({ type: 'DELETE' })
        },
        NUMBER: directEditHandler,
        LETTER: directEditHandler,
        DASH: directEditHandler,
        PERIOD: directEditHandler,
        MOVE_UP: (event?: KeyboardEvent) => {
          dispatch({ type: 'PREVIOUS_ROW', event })
        },
        MOVE_DOWN: (event?: KeyboardEvent) => {
          dispatch({ type: 'NEXT_ROW', event })
        },
        MOVE_LEFT: (event?: KeyboardEvent) => {
          dispatch({ type: 'PREVIOUS_CELL', event })
        },
        MOVE_RIGHT: (event?: KeyboardEvent) => {
          dispatch({ type: 'NEXT_CELL', event })
        },
        ENTER: (event?: KeyboardEvent) => {
          if (beforeEditFunction) {
            beforeEditFunction()
            return
          }
          dispatch({ type: 'EDIT_CELL', event })
        },
        ESCAPE: () => dispatch({ type: 'RESET' }),
        COPY_ACTION: (event?: KeyboardEvent) => dispatch({ type: 'COPY_CELL_CONTENT', event }),
        CUT_ACTION: (event?: KeyboardEvent) => {
          dispatch({ type: 'COPY_CELL_CONTENT', event })
          dispatch({ type: 'DELETE' })
        },
        PASTE_ACTION: (event?: KeyboardEvent) => dispatch({ type: 'PASTE_CELL_CONTENT', event }),
        // All command+number/letter or control+number/letter actions should just act as default
        COMMAND_ACTION: () => undefined,
        CONTROL_ACTION: () => undefined,
      }),
      [
        directEditHandler,
        beforeEditFunction,
        state.selectedCell,
        state.content,
        state.columns,
        dispatch,
        snackbar,
        pauseFocus,
        confirm,
        resumeFocus,
      ]
    )

    // Only render based on the spreadsheet held in state; rendering the content passed through
    // the prop may lead to errors if it is not yet in sync with the reducer state
    const spreadsheetContent = state.content
    const includeDragHandles = includeDragHandleColumn(spreadsheetContent)
    const disableReordering = onReorder === undefined

    const draggingRowIndex = spreadsheetContent.rows.findIndex((row) => row.id === draggingRowId)
    const draggingGroupRows = useMemo(() => {
      if (draggingRowIndex < 0 || !spreadsheetContent.rows[draggingRowIndex].isGroupHeaderRow) {
        return emptyRows
      }
      const groupRows = []
      let index = draggingRowIndex + 1
      while (
        index < spreadsheetContent.rows.length &&
        !spreadsheetContent.rows[index].isFirstInUngroupedBlock &&
        spreadsheetContent.rows[index].allowDragging
      ) {
        groupRows.push(spreadsheetContent.rows[index])
        index++
      }
      return groupRows
    }, [spreadsheetContent.rows, draggingRowIndex])
    let draggingAboveIndex = -1
    if (draggingToRowIndex !== null && draggingRowIndex >= 0) {
      draggingAboveIndex =
        draggingToRowIndex > draggingRowIndex ? draggingToRowIndex + 1 : draggingToRowIndex
    }

    const showSkeleton = useMemo(() => {
      if (loading) {
        return true
      }
      // If there is a state update to the number of columns being displayed (i.e., change to
      // column count due to screen size-based rendering logic), show loading state
      if (state.columns.length !== columns.length) {
        return true
      }

      // The state of the spreadsheet might be inconsistent while processing
      // new changes, so show a loading state until the spreadsheet is valid
      return !validateSpreadsheet(state.columns, state.content)
    }, [columns.length, loading, state.columns, state.content])

    const numSkeletonRows =
      content.rows.length > 0 ? content.rows.length : DEFAULT_NUM_SKELETON_ROWS
    const skeletonRows = useMemo(
      () => _.times(numSkeletonRows, () => getSkeletonRow(columns)),
      [columns, numSkeletonRows]
    )

    const headerRow = (
      <div className={clsx('row', 'headerRow')}>
        {includeDragHandles && (
          <div className={clsx('cell', 'headerCell', 'dragHandleColumn')}>&nbsp;</div>
        )}
        {columns.map(({ id, heading, align, isEditable }, columnIndex) => {
          const { width } = calculateCellLayout(columnIndex, sizingState.columnSizing)
          const widthStyle = {
            width,
            minWidth: width,
            maxWidth: width,
          }
          return (
            <div style={widthStyle} className={clsx('cell', 'headerCell')} key={id}>
              <div className={clsx('cellInner', align)}>
                <SpreadsheetHeaderCell
                  columnIndex={columnIndex}
                  heading={heading}
                  isColumnEditable={isEditable}
                  dispatchSizing={dispatchSizing}
                />
              </div>
            </div>
          )
        })}
      </div>
    )

    const stickyHeaderRowRef = useRef<HTMLDivElement>(null)
    const stickyFooterRowRef = useRef<HTMLDivElement>(null)
    const handleScroll = (event: UIEvent<HTMLDivElement>) => {
      stickyHeaderRowRef.current?.scrollTo({ left: event.currentTarget.scrollLeft })
      stickyFooterRowRef.current?.scrollTo({ left: event.currentTarget.scrollLeft })
    }

    const fixedFooterRows = _.filter(spreadsheetContent.footerRows ?? [], (row) => row.isFixed)

    const enableStickyHeader = stickyHeaderTopOffset !== undefined
    const enableStickyFooter = fixedFooterRows.length > 0
    // Show sticky header when the table header is scrolled out of view
    useEffect(() => {
      const updateStickyHeader = _.debounce(() => {
        const windowOffset = window.scrollY + SPREADSHEET_MIN_ROW_HEIGHT + HEADER_HEIGHT
        const shouldShowStickyHeader =
          spreadsheetTop.current && windowOffset >= spreadsheetTop.current
        setShowStickyHeader(shouldShowStickyHeader === true)
      })

      if (enableStickyHeader) {
        window.addEventListener('scroll', updateStickyHeader)
      }
      return () => window.removeEventListener('scroll', updateStickyHeader)
    }, [enableStickyHeader])

    // Show sticky footer when a fixed table footer isn't in view
    useEffect(() => {
      const updateStickyFooter = _.debounce(() => {
        const spreadsheetRect = spreadsheetRef.current?.getBoundingClientRect()
        // Show the sticky footer when the very bottom of the spreadsheet is scrolled out of view,
        // i.e. when the amount scroll off the screen is less than the amount of spreadsheet
        // that doesn't fit in the browser window
        const shouldShowStickyFooter =
          spreadsheetTop.current &&
          spreadsheetRect &&
          window.scrollY < spreadsheetRect.height - (vh(100) - spreadsheetTop.current)
        setShowStickyFooter(shouldShowStickyFooter === true)
      })
      if (enableStickyFooter) {
        updateStickyFooter()
        window.addEventListener('resize', updateStickyFooter)
        window.addEventListener('scroll', updateStickyFooter)
      }
      return () => {
        window.removeEventListener('scroll', updateStickyFooter)
        window.removeEventListener('resize', updateStickyFooter)
      }
    }, [enableStickyFooter, state.content])

    // Batch cell updates and dispatch to the sizing reducer
    const [cellWidths, setCellWidths] = useState<CellWidthUpdate[]>(emptyCellWidths)
    // Trigger a (debounced) cell sizing dispatch every time the cell widths array changes
    useEffect(() => {
      const dispatchCellSizingUpdates = _.debounce(() => {
        dispatchSizing({ type: 'UPDATE_CELL_WIDTHS', cells: cellWidths })
        // Clear cell width array if all updates were dispatched
        setCellWidths((prevCellWidths) => {
          if (_.isEqual(prevCellWidths, cellWidths)) {
            return []
          }
          return prevCellWidths
        })
      })
      if (cellWidths.length) {
        dispatchCellSizingUpdates()
      }
    }, [cellWidths, dispatchSizing])
    const handleCellWidthChange = useCallback(
      (cellWidth: CellWidthUpdate) => {
        setCellWidths((prevCellWidths) => {
          const otherCellWidths = prevCellWidths.filter(
            (prevCellWidth) =>
              prevCellWidth.rowId !== cellWidth.rowId ||
              prevCellWidth.columnId !== cellWidth.columnId
          )
          const newWidths = [...otherCellWidths, cellWidth]
          return newWidths
        })
        handleResize()
      },
      [handleResize]
    )
    const handleCloseHint = useCallback(() => setIsHintOpen(false), [])

    return (
      <>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragUpdate={handleDragUpdate}
          onDragEnd={handleDragEnd}
        >
          {enableStickyHeader && (
            <div
              ref={stickyHeaderRowRef}
              className={clsx(classes.table, classes.stickyHeader, {
                showStickyHeader,
              })}
              style={{ top: stickyHeaderTopOffset }}
            >
              <div className="tableInner">{headerRow}</div>
            </div>
          )}
          <div id={spreadsheetId.current} className={classes.root}>
            {hasActiveFocus && <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges />}
            {
              // If the content has been provided but the table has not yet reported a total
              // width, show a minimal skeleton until the width is set and the spreadsheet can render
            }
            {sizingState.sizing.width === 0 && (
              <div>
                <div className={clsx(classes.skeletonTableRow, classes.skeletonTableHeaderRow)} />
                {_.times(numSkeletonRows, (index) => (
                  <div key={index} className={clsx(classes.skeletonTableRow)}>
                    {columns.map((column) => (
                      <Skeleton
                        key={column.id}
                        variant="text"
                        width={DEFAULT_SKELETON_COLUMN_WIDTH}
                      />
                    ))}
                  </div>
                ))}
              </div>
            )}
            {sizingState.sizing.width > 0 && (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div
                  ref={spreadsheetRef}
                  className={clsx(classes.table, {
                    isDragging: draggingRowId !== null,
                  })}
                  onScroll={handleScroll}
                >
                  <div className="tableInner">
                    {headerRow}
                    {showSkeleton &&
                      skeletonRows.map((row, rowIndex) => (
                        <SpreadsheetRow
                          key={row.id}
                          row={row}
                          rowIndex={rowIndex}
                          columns={columns}
                          dispatch={dispatch}
                          columnSizing={sizingState.columnSizing}
                          onCellWidthChange={handleCellWidthChange}
                          includeDragHandles={includeDragHandles}
                          isSkeletonRow
                        />
                      ))}
                    {!showSkeleton && (
                      <>
                        <Droppable droppableId="spreadsheet">
                          {(droppableProvided) => (
                            <div
                              ref={droppableProvided.innerRef}
                              {...droppableProvided.droppableProps}
                            >
                              {spreadsheetContent.rows.flatMap((row, rowIndex) => {
                                const selectedCellIndex =
                                  state.selectedCell && state.selectedCell.rowIndex === rowIndex
                                    ? state.selectedCell.cellIndex
                                    : undefined
                                const editingCellIndex =
                                  state.editingCell &&
                                  state.editingCell.position.rowIndex === rowIndex
                                    ? state.editingCell.position.cellIndex
                                    : undefined
                                const editingCellInitialValue =
                                  editingCellIndex !== undefined
                                    ? state.editingCell?.initialValue
                                    : undefined
                                const rows = [
                                  <DraggableSpreadsheetRow
                                    key={row.id}
                                    row={row}
                                    rowIndex={rowIndex}
                                    columns={columns}
                                    dispatch={dispatch}
                                    columnSizing={sizingState.columnSizing}
                                    onCellWidthChange={handleCellWidthChange}
                                    includeDragHandles={includeDragHandles}
                                    disableReordering={disableReordering}
                                    selectedCellIndex={selectedCellIndex}
                                    editingCellIndex={editingCellIndex}
                                    editingCellInitialValue={editingCellInitialValue}
                                    draggingRowId={draggingRowId}
                                    draggingGroupRows={draggingGroupRows}
                                    draggingAboveIndex={draggingAboveIndex}
                                    onBeforeEdit={beforeEditFunction}
                                    onBeforeSave={onBeforeSave}
                                    onContextMenu={onInsertRow ? handleRightClick : undefined}
                                  />,
                                ]

                                if (row.auxiliaryRows) {
                                  const auxiliaryRows = row.auxiliaryRows.map(
                                    (auxiliaryRow, auxiliaryRowIndex) => (
                                      <SpreadsheetRow
                                        key={auxiliaryRow.id}
                                        row={auxiliaryRow}
                                        rowIndex={auxiliaryRowIndex}
                                        columns={columns}
                                        dispatch={dispatch}
                                        columnSizing={sizingState.columnSizing}
                                        onCellWidthChange={handleCellWidthChange}
                                        includeDragHandles={includeDragHandles}
                                      />
                                    )
                                  )
                                  rows.push(...auxiliaryRows)
                                }

                                return rows
                              })}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {spreadsheetContent.footerRows?.map((row, rowIndex) => (
                          <div
                            key={row.id}
                            className={clsx({
                              isFirstInUngroupedBlock: row.isFirstInUngroupedBlock,
                            })}
                          >
                            <SpreadsheetRow
                              row={row}
                              rowIndex={rowIndex}
                              columns={columns}
                              dispatch={dispatch}
                              columnSizing={sizingState.columnSizing}
                              onCellWidthChange={handleCellWidthChange}
                              includeDragHandles={includeDragHandles}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </ClickAwayListener>
            )}
            <div id={spreadsheetSizingId.current} className={classes.sizing} />
          </div>
          {enableStickyFooter && (
            <div
              ref={stickyFooterRowRef}
              className={clsx(classes.table, classes.stickyFooter, {
                showStickyFooter,
              })}
              style={{
                // The height of the sticky footer is the height of all the fixed rows, plus 1px for the border
                height: showStickyFooter
                  ? fixedFooterRows.length * SPREADSHEET_MIN_ROW_HEIGHT + 1
                  : 0,
              }}
            >
              <div className="tableInner">
                {fixedFooterRows.map((row, rowIndex) => (
                  <div
                    key={row.id}
                    className={clsx({ isFirstInUngroupedBlock: row.isFirstInUngroupedBlock })}
                  >
                    <SpreadsheetRow
                      row={row}
                      rowIndex={rowIndex}
                      columns={columns}
                      dispatch={dispatch}
                      columnSizing={sizingState.columnSizing}
                      onCellWidthChange={handleCellWidthChange}
                      includeDragHandles={includeDragHandles}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </DragDropContext>
        <SpreadsheetRowContextMenu
          anchorPosition={contextMenu}
          isOpen={contextMenu !== null}
          onClose={handleCloseContextMenu}
          onInsertRow={handleInsertRow}
          menuLabels={contextMenuLabels}
        />
        {spreadsheetHint && (
          <SpreadsheetHint isOpen={isHintOpen} onClose={handleCloseHint} hint={spreadsheetHint} />
        )}
      </>
    )
  }
)
