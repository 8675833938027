import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  dropdownIcon: {
    // Animate the arrow icon flipping around when the menu opens
    transition: theme.transitions.create('transform'),
    '&.expand-collapse': {
      // The expand-collapse arrow points right when closed
      transform: 'rotate(-90deg)',
    },
    '&.open-close': {
      // The dropdown arrow points down when closed
      transform: 'rotate(0deg)',
    },
    '&.expand-collapse.menuOpen': {
      // Point the expand-collapse arrow down when the menu opens
      transform: 'rotate(0deg)',
    },
    '&.open-close.menuOpen': {
      // Point the dropdown arrow up when the menu opens
      transform: 'rotate(-180deg)',
    },
  },
}))

interface AnimatedArrowDropdownIconProps {
  className?: string
  /** Determines which way the arrow will point */
  isMenuOpen: boolean
  /** @default 'small' */
  fontSize?: 'large' | 'medium' | 'small'
  /** @default 'filled' */
  iconType?: 'filled' | 'outlined'
  /**
   * Siteline has two types of animated "expanding section" arrows:
   * - 'open-close' dropdown arrows point down when closed and point up when open
   * - 'expand-collapse' section arrows point right when closed and point down when open
   * @default 'open-close'
   **/
  sectionType?: 'open-close' | 'expand-collapse'
}

/** Simple component used for animating the MUI `ArrowDropdownIcon` based on whether the dropdown is open or closed */
export function AnimatedArrowDropdownIcon({
  isMenuOpen,
  className,
  fontSize = 'small',
  iconType = 'filled',
  sectionType = 'open-close',
}: AnimatedArrowDropdownIconProps) {
  const classes = useStyles()
  if (iconType === 'outlined') {
    return (
      <KeyboardArrowDownIcon
        className={clsx(classes.dropdownIcon, className, sectionType, { menuOpen: isMenuOpen })}
        fontSize={fontSize}
      />
    )
  }
  return (
    <ArrowDropDownIcon
      className={clsx(classes.dropdownIcon, className, sectionType, { menuOpen: isMenuOpen })}
      fontSize={fontSize}
    />
  )
}
