import { gql } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationTypeFamily } from 'siteline-common-all'
import { evictWithGc, useSitelineSnackbar } from 'siteline-common-web'
import { ConfirmResetToDraftDialog } from '../../../common/components/ConfirmResetToDraftDialog'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  BillingType,
  LienWaiverForMonthDocument,
  PayAppActivityDocument,
  PayAppProperties,
  PayAppStatus,
  ResetPayAppToDraftMutation,
  useResetPayAppToDraftMutation,
} from '../../../common/graphql/apollo-operations'
import { hasIntegrationOfFamily } from '../../../common/util/Integration'
import { unconditionalLienWaiverTypes } from '../../../common/util/LienWaiver'
import { trackPayAppResetToDraft } from '../../../common/util/MetricsTracking'
import { getMetricsForPayApp } from '../../../common/util/PayApp'
import { getTimeNoTimeZone } from '../../../common/util/Time'

gql`
  mutation resetPayAppToDraft($input: ResetPayAppToDraftInput!) {
    resetPayAppToDraft(input: $input) {
      id
      status
      statusChangedAt
      revertToDraftEvents {
        id
      }
      uploadedFile {
        id
      }
      lienWaivers {
        id
        type
        vendorContract {
          id
          vendor {
            id
          }
        }
        lienWaiverRequests {
          id
          lienWaiver {
            id
          }
        }
        formValues {
          ...FormTemplateAnnotationValueProperties
        }
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
    }
  }
  ${fragments.formTemplateAnnotationValue}
`

export type PayAppForReset = ResetPayAppToDraftMutation['resetPayAppToDraft']

const i18nBase = 'projects.subcontractors.pay_app.invoice.reset_to_draft'

interface ResetPayAppToDraftDialogProps {
  open: boolean
  onClose: (success: boolean) => void
  onResetComplete?: () => void
  successMessage?: string
  payApp: Pick<
    PayAppProperties,
    'id' | 'retentionOnly' | 'status' | 'payAppNumber' | 'billingType' | 'billingEnd'
  >
}

export function ResetPayAppToDraftDialog({
  open,
  onClose,
  payApp,
  onResetComplete,
  successMessage,
}: ResetPayAppToDraftDialogProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { id: projectId, name: projectName, contract, timeZone } = useProjectContext()

  const hasGcPortal = contract
    ? hasIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL)
    : false

  const [retainAnnotations, setRetainAnnotations] = useState(!hasGcPortal)
  const [revertReason, setRevertReason] = useState<string>('')

  const [resetPayApp, { loading }] = useResetPayAppToDraftMutation({
    refetchQueries: [
      {
        query: PayAppActivityDocument,
        variables: {
          payAppId: payApp.id,
        },
      },
      // If the unconditional lien waiver has already been loaded for the pay app,
      // refresh the request since the lien waiver should have been deleted on the backend
      {
        query: LienWaiverForMonthDocument,
        variables: {
          input: {
            contractId: contract?.id ?? '',
            payAppId: payApp.id,
            month: moment.tz(payApp.billingEnd, timeZone).toISOString(),
            types: unconditionalLienWaiverTypes,
          },
        },
      },
    ],
    update(cache, { data }) {
      if (!data || !contract) {
        return
      }
      evictWithGc(cache, (evict) => {
        evict({ id: cache.identify(contract), fieldName: 'invoiceAmountOutstanding' })
        evict({ id: 'ROOT_QUERY', fieldName: 'paginatedCashForecastContracts' })
        evict({ id: 'ROOT_QUERY', fieldName: 'aggregateCashForecast' })
      })
    },
  })

  const handleResetToDraft = useCallback(async () => {
    try {
      await resetPayApp({
        variables: {
          input: {
            id: payApp.id,
            retainAnnotations,
            reason: revertReason,
          },
        },
      })
      if (onResetComplete) {
        onResetComplete()
      }
      trackPayAppResetToDraft({
        ...getMetricsForPayApp(payApp, projectId, projectName),
        resetDraftDate: getTimeNoTimeZone().toISOString(),
      })
      snackbar.showSuccess(successMessage ?? t(`${i18nBase}.success`))
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [
    onResetComplete,
    payApp,
    projectId,
    projectName,
    resetPayApp,
    retainAnnotations,
    revertReason,
    snackbar,
    successMessage,
    t,
  ])

  let description = ''
  const isPaid = payApp.status === PayAppStatus.PAID
  switch (payApp.billingType) {
    case BillingType.UNIT_PRICE:
    case BillingType.TIME_AND_MATERIALS:
    case BillingType.LUMP_SUM:
      if (hasGcPortal) {
        if (payApp.status === PayAppStatus.SYNC_PENDING) {
          description = t(`${i18nBase}.body_pending`)
        } else {
          description = isPaid ? t(`${i18nBase}.body_paid_sync`) : t(`${i18nBase}.body_sync`)
        }
      } else {
        description = isPaid ? t(`${i18nBase}.body_paid`) : t(`${i18nBase}.body`)
      }
      break
    case BillingType.QUICK:
      description = t(`${i18nBase}.body_quick`)
      break
  }

  return (
    <ConfirmResetToDraftDialog
      open={open}
      onClose={onClose}
      onResetToDraft={handleResetToDraft}
      retainAnnotations={retainAnnotations}
      onRetainAnnotationsChange={setRetainAnnotations}
      showRetainAnnotations={!hasGcPortal}
      description={description}
      submitting={loading}
      revertReason={revertReason}
      onRevertReasonChange={setRevertReason}
    />
  )
}
