import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button, Menu, MenuItem } from '@mui/material'
import { MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RetentionTrackingLevel, SitelineText, makeStylesFast } from 'siteline-common-web'
import { themeSpacing } from '../../../../common/themes/Main'
import { RetentionView } from '../InvoiceReducer'

const useStyles = makeStylesFast(() => ({
  button: {
    height: 24,
    '& .endIcon': {
      // Use a smaller margin to save space
      marginLeft: `${themeSpacing(0.5)}px !important`,
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
    marginRight: 46,
  },
  menu: {
    '& .MuiSvgIcon-root': {
      opacity: 0,
      '&.active': {
        opacity: 1,
      },
    },
  },
}))

export const RETENTION_VIEW_STORAGE_KEY = 'invoiceRetentionView'

interface RetentionViewToggleProps {
  retentionView: RetentionView
  onViewChange: (view: RetentionView) => void
  trackingType: RetentionTrackingLevel
}

/** Dropdown toggle for switching the retention view on a pay app invoice */
export function RetentionViewToggle({
  retentionView,
  onViewChange,
  trackingType,
}: RetentionViewToggleProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLSpanElement>(null)

  const label = t(`projects.subcontractors.pay_app.invoice.headers.${retentionView}`)

  const openMenu = useCallback((evt: MouseEvent<HTMLSpanElement>) => {
    setMenuAnchorEl(evt.currentTarget)
  }, [])

  const handleClick = useCallback(
    (retentionView: RetentionView) => {
      onViewChange(retentionView)
      setMenuAnchorEl(null)
    },
    [onViewChange]
  )

  return (
    <>
      <Button variant="text" onClick={openMenu} className={classes.button}>
        <SitelineText
          variant="smallText"
          color="grey50"
          endIcon={<ArrowDropDownIcon fontSize="small" />}
        >
          {label}
        </SitelineText>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        className={classes.menu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key="currentAmount"
          onClick={() => handleClick(RetentionView.HELD_CURRENT_AMOUNT)}
          selected={retentionView === RetentionView.HELD_CURRENT_AMOUNT}
        >
          {t('projects.subcontractors.pay_app.invoice.headers.show_retention_current')}
        </MenuItem>
        {trackingType === RetentionTrackingLevel.STANDARD && (
          <MenuItem
            key="currentPercent"
            onClick={() => handleClick(RetentionView.HELD_CURRENT_PERCENT)}
            selected={retentionView === RetentionView.HELD_CURRENT_PERCENT}
          >
            {t('projects.subcontractors.pay_app.invoice.headers.current_retention_percent')}
          </MenuItem>
        )}
        <MenuItem
          key="amountToDate"
          onClick={() => handleClick(RetentionView.HELD_TO_DATE_AMOUNT)}
          selected={retentionView === RetentionView.HELD_TO_DATE_AMOUNT}
        >
          {t('projects.subcontractors.pay_app.invoice.headers.show_retention_to_date')}
        </MenuItem>
        {trackingType === RetentionTrackingLevel.LINE_ITEM && (
          <MenuItem
            key="currentPercent"
            onClick={() => handleClick(RetentionView.HELD_TO_DATE_PERCENT)}
            selected={retentionView === RetentionView.HELD_TO_DATE_PERCENT}
          >
            {t('projects.subcontractors.pay_app.invoice.headers.total_retention_percent')}
          </MenuItem>
        )}
        <MenuItem
          key="amountReleased"
          onClick={() => handleClick(RetentionView.RELEASED_CURRENT_AMOUNT)}
          selected={retentionView === RetentionView.RELEASED_CURRENT_AMOUNT}
        >
          {t('projects.subcontractors.pay_app.invoice.headers.show_retention_released')}
        </MenuItem>
      </Menu>
    </>
  )
}
