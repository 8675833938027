import DeleteIcon from '@mui/icons-material/Delete'
import GetAppIcon from '@mui/icons-material/GetApp'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Grid } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStylesFast, saveAs, useSitelineSnackbar } from 'siteline-common-web'
import { SitelineFileCard } from '../../../common/components/SitelineFileCard'
import {
  StoredFileProperties,
  useDeleteContractFileMutation,
} from '../../../common/graphql/apollo-operations'
import {
  AttachmentForViewer,
  AttachmentViewerDialog,
} from '../backup/attachments/AttachmentViewerDialog'
import { ContractForProjectHome } from '../home/ProjectHome'
import { ContractFileThumbnail, MEDIA_CARD_HEIGHT } from './ContractFileThumbnail'

const useStyles = makeStylesFast(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
  },
}))

const i18nBase = 'projects.subcontractors.settings.contract'

interface InlineContractFilesPrevewProps {
  contract: ContractForProjectHome
  canEdit: boolean
}

/** A grid of file previews corresponding to uploaded contract files in project settings */
export function InlineContractFilesPreview({ contract, canEdit }: InlineContractFilesPrevewProps) {
  const styles = useStyles()
  const snackbar = useSitelineSnackbar()
  const { t } = useTranslation()

  const [viewingFile, setViewingFile] = useState<StoredFileProperties | null>(null)

  const [deleteContractFile] = useDeleteContractFileMutation()

  const viewingAttachments: AttachmentForViewer[] = useMemo(() => {
    if (viewingFile === null) {
      return []
    }
    return [
      {
        name: viewingFile.name,
        description: null,
        file: viewingFile,
      },
    ]
  }, [viewingFile])

  const handleCloseViewerDialog = useCallback(() => {
    setViewingFile(null)
  }, [])

  const handleDeleteContractFile = useCallback(
    (file: StoredFileProperties) => {
      try {
        deleteContractFile({ variables: { input: { contractId: contract.id, fileId: file.id } } })
        snackbar.showSuccess(t(`${i18nBase}.deleted_contract_file`, { fileName: file.name }))
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [contract.id, deleteContractFile, snackbar, t]
  )

  const getCardActions = useCallback(
    (file: StoredFileProperties) => {
      const actions = [
        {
          title: t('common.actions.view'),
          icon: <VisibilityIcon />,
          onClick: () => setViewingFile(file),
        },
        {
          title: t('common.actions.download'),
          icon: <GetAppIcon />,
          onClick: async () => {
            snackbar.showInfo(t('projects.subcontractors.pay_app.attachments.prepare_download'))
            const response = await fetch(file.url)
            // Saving the blob rather than the URL directly causes the browser to
            // prompt the save dialog rather than directly open the file in a tab
            saveAs(await response.blob(), file.name)
          },
        },
      ]

      if (canEdit) {
        actions.push({
          title: t('common.actions.delete'),
          icon: <DeleteIcon />,
          onClick: () => handleDeleteContractFile(file),
        })
      }

      return actions
    },
    [canEdit, handleDeleteContractFile, snackbar, t]
  )

  return (
    <>
      <Grid container spacing={1} className={styles.root}>
        {contract.files.map((contractFile) => (
          <Grid item key={contractFile.id} xs={4}>
            <SitelineFileCard
              title={contractFile.name}
              totalSize={contractFile.size}
              media={
                <ContractFileThumbnail
                  file={contractFile}
                  onClick={() => setViewingFile(contractFile)}
                />
              }
              actions={getCardActions(contractFile)}
              isUploaded
              height={MEDIA_CARD_HEIGHT}
            />
          </Grid>
        ))}
      </Grid>
      <AttachmentViewerDialog
        open={viewingFile !== null}
        onClose={handleCloseViewerDialog}
        attachments={viewingAttachments}
      />
    </>
  )
}
