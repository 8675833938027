/* eslint-disable @typescript-eslint/ban-types */
import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import {
  AggregateLienWaiverStatus,
  AgingAmountType,
  AgingIntervalType,
  AuthProviderType,
  BillingType,
  CacheType,
  CashForecastPeriodType,
  ChangeOrderRequestEventType,
  ChangeOrderRequestStatus,
  CompanyListSortCriteria,
  CompanyProjectRole,
  CompanyType,
  CompanyUserRole,
  ComplianceStatus,
  ContractCommentType,
  ContractEventType,
  ContractListContractStatusFilter,
  ContractListPayAppStatusFilter,
  ContractListSortCriteria,
  ContractListSubmitViaFilter,
  ContractMonthlyStatusFilter,
  ContractPaymentTermsType,
  ContractStatus,
  ContractUserRole,
  DashboardBillingStatus,
  DocumentExpirationFrequency,
  DocumentRequestStatus,
  DocumentType,
  DueToType,
  EmailType,
  ForecastCurve,
  FormTemplateAnnotationImageType,
  FormTemplateAnnotationMetadataFieldType,
  FormTemplateAnnotationType,
  FormTemplateFont,
  FormTemplateOnboardingSignatureType,
  FormTemplateStatus,
  FormTemplateTag,
  FormTemplateType,
  FormWorkerRole,
  ImportIntegrationChangeOrdersMethod,
  ImportOperationStatus,
  IntegrationSyncRequestStatus,
  IntegrationSyncRequestType,
  IntegrationSyncResultCode,
  IntegrationSyncStatus,
  IntegrationType,
  LienWaiverCategory,
  LienWaiverType,
  LineItemType,
  NotarySignatureStatus,
  NotaryTag,
  NotificationType,
  PaginatedListSortOrder,
  PayAppEventType,
  PayAppRequirementCondition,
  PayAppStatus,
  PayAppStatusFilter,
  PaymentDateEstimationType,
  Permission,
  PhysicalSignatureRequired,
  PreSitelinePayAppStatus,
  ProjectOnboardingFormType,
  RateTableStatus,
  RequiredFieldForForms,
  RetentionTrackingLevel,
  Sage100InvoiceTaxType,
  SearchResultType,
  SignatureAnnotationType,
  SignatureFont,
  SovLineItemProgressEventType,
  StoredFileType,
  TaxCalculationType,
  TemplateListSortCriteria,
  TemplateListStatusFilter,
  TextAlignment,
  Tutorial,
  UserStatus,
  VendorComplianceStatus,
  VendorContractCommitmentType,
  VendorLienWaiverReminderFrequency,
  VendorListSortCriteria,
  WriteSyncOperationStatus,
} from 'siteline-common-all'
import {
  Boolean,
  DateTime,
  Decimal,
  EmailAddress,
  Float,
  ID,
  Int,
  JSONObject,
  LatLng,
  PhoneNumber,
  SafeInt,
  String,
  Upload,
  URL,
} from 'siteline-common-web/src/scalars.js'
export type Maybe<T> = T | null
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: ID['input']; output: ID['output'] }
  String: { input: String['input']; output: String['output'] }
  Boolean: { input: Boolean['input']; output: Boolean['output'] }
  Int: { input: Int['input']; output: Int['output'] }
  Float: { input: Float['input']; output: Float['output'] }
  DateTime: { input: DateTime['input']; output: DateTime['output'] }
  Decimal: { input: Decimal['input']; output: Decimal['output'] }
  EmailAddress: { input: EmailAddress['input']; output: EmailAddress['output'] }
  JSONObject: { input: JSONObject['input']; output: JSONObject['output'] }
  LatLng: { input: LatLng['input']; output: LatLng['output'] }
  PhoneNumber: { input: PhoneNumber['input']; output: PhoneNumber['output'] }
  SafeInt: { input: SafeInt['input']; output: SafeInt['output'] }
  URL: { input: URL['input']; output: URL['output'] }
  Upload: { input: Upload['input']; output: Upload['output'] }
}

export type AbandonedPayAppsData = {
  readonly __typename: 'AbandonedPayAppsData'
  readonly abandonedPayAppsCount: Scalars['SafeInt']['output']
  readonly abandonedPayAppsIds: ReadonlyArray<Scalars['ID']['output']>
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type AddChangeOrderRequestCommentInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly message: Scalars['String']['input']
}

export type AddChangeOrderRequestToSovInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly lineItems: ReadonlyArray<ChangeOrderRequestLineItemInput>
}

export type AddContractCommentInput = {
  readonly contractId: Scalars['ID']['input']
  readonly message: Scalars['String']['input']
  readonly type: ContractCommentType
}

export type AddContractFileInput = {
  readonly contractId: Scalars['ID']['input']
  readonly files: ReadonlyArray<Scalars['Upload']['input']>
}

export type AddIntegrationToContractInput = {
  readonly id: Scalars['ID']['input']
  readonly integration: LumpSumProjectIntegrationInput
}

export type AddIntegrationVendorToContractInput = {
  /**
   * A vendor ID from the integration. If a vendorId is provided, will link it to this integration
   * vendor. If not, a new vendor will be created in Siteline (unless one is found that is already
   * linked to this integration vendor).
   */
  readonly integrationVendorId: Scalars['ID']['input']
  /** A Siteline vendor ID. If provided, will add this vendor to the project. */
  readonly vendorId?: InputMaybe<Scalars['ID']['input']>
}

export type AddIntegrationVendorsToContractInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly integrationVendors: ReadonlyArray<AddIntegrationVendorToContractInput>
}

export type AddLegalDocumentsToPayAppInput = {
  readonly legalDocumentIds: ReadonlyArray<Scalars['ID']['input']>
  readonly payAppId: Scalars['ID']['input']
}

export type AddLegalRequirementInput = {
  readonly contractId: Scalars['ID']['input']
  readonly documentType: DocumentType
  /** File to be built as a form template so the compliance form can be signed in Siteline */
  readonly formTemplate?: InputMaybe<Scalars['Upload']['input']>
  readonly frequency: DocumentExpirationFrequency
  readonly name: Scalars['String']['input']
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type AddPayAppCommentInput = {
  readonly message: Scalars['String']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type AddUserTutorialShownInput = {
  readonly tutorial: Tutorial
}

export type AddVendorLegalRequirementInput = {
  readonly contractId: Scalars['ID']['input']
  readonly documentType: DocumentType
  readonly frequency: DocumentExpirationFrequency
  readonly name: Scalars['String']['input']
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly vendorTemplate?: InputMaybe<Scalars['Upload']['input']>
}

export type AddVendorLienWaiversToPayAppInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly vendorLienWaiverIds: ReadonlyArray<Scalars['ID']['input']>
}

export type AddVendorsToProjectInput = {
  readonly contractId: Scalars['ID']['input']
  /** If these vendors should be added as lower-tiers to another vendor, pass that vendor contract ID */
  readonly lowerTierToVendorContractId?: InputMaybe<Scalars['ID']['input']>
  readonly vendorIds: ReadonlyArray<Scalars['ID']['input']>
}

export type AdjustRetentionProjectInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly retentionPercent: Scalars['Float']['input']
}

export type AggregateBillingForecast = {
  readonly __typename: 'AggregateBillingForecast'
  readonly forecastByMonth: ReadonlyArray<AggregateForecastMonth>
  readonly numContractsWithForecast: Scalars['SafeInt']['output']
  readonly projectedAmountThisMonth: Scalars['SafeInt']['output']
}

export type AggregateBillingForecastInput = {
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly filters: DashboardFiltersInput
}

export type AggregateCashForecast = {
  readonly __typename: 'AggregateCashForecast'
  /** The amount that has been billed, not yet paid, and is predicted to be paid in the current period */
  readonly amountProjectedThisPeriod: Scalars['SafeInt']['output']
  readonly forecast: ReadonlyArray<CashForecastPeriod>
  /** The total amount outstanding across all periods */
  readonly totalOpenInvoiceAmount: Scalars['SafeInt']['output']
  /** Same as `totalUnknownPredictedInvoiceAmount`, but excluding pre-Siteline pay apps */
  readonly totalPayAppUnknownPredictedInvoiceAmount: Scalars['SafeInt']['output']
  /** The total amount outstanding in pay apps with an unknown predicted payment date. */
  readonly totalUnknownPredictedInvoiceAmount: Scalars['SafeInt']['output']
}

export type AggregateCashForecastInput = {
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  /**
   * Current date, in YYYY-MM-DD format.
   * This is not a point in time because it would be timezone dependent.
   * Clients should pass in the current day in their own timezone.
   */
  readonly currentDate: Scalars['String']['input']
  readonly filters: DashboardFiltersInput
  readonly periodType: CashForecastPeriodType
}

export type AggregateChangeOrderData = {
  readonly __typename: 'AggregateChangeOrderData'
  readonly totalAmountApproved: Scalars['SafeInt']['output']
  readonly totalAmountAuthorizedToProceed: Scalars['SafeInt']['output']
  readonly totalAmountProceedingWithWork: Scalars['SafeInt']['output']
  readonly totalAmountUnapproved: Scalars['SafeInt']['output']
  readonly totalOriginalContractAmount: Scalars['SafeInt']['output']
  readonly totalRevisedContractAmount: Scalars['SafeInt']['output']
}

export type AggregateChangeOrderDataByContract = {
  readonly __typename: 'AggregateChangeOrderDataByContract'
  readonly aggregateChangeOrderData: AggregateChangeOrderData
  readonly contract: Contract
}

export type AggregateForecastMonth = {
  readonly __typename: 'AggregateForecastMonth'
  readonly amountBilled: Maybe<Scalars['SafeInt']['output']>
  /** Zero-indexed month, from 0 to 11 */
  readonly month: Scalars['SafeInt']['output']
  readonly projectedAmount: Maybe<Scalars['SafeInt']['output']>
  /** Year in YYYY format */
  readonly year: Scalars['SafeInt']['output']
}

export { AggregateLienWaiverStatus }

export { AgingAmountType }

export type AgingBreakdown = {
  readonly __typename: 'AgingBreakdown'
  readonly amountAged30Days: Scalars['SafeInt']['output']
  readonly amountAged60Days: Scalars['SafeInt']['output']
  readonly amountAged90Days: Scalars['SafeInt']['output']
  readonly amountAged120Days: Scalars['SafeInt']['output']
  readonly amountAgedCurrent: Scalars['SafeInt']['output']
  readonly amountAgedTotal: Scalars['SafeInt']['output']
  readonly hasPayAppsAgedOver120Days: Scalars['Boolean']['output']
  readonly numAged30Days: Scalars['SafeInt']['output']
  readonly numAged60Days: Scalars['SafeInt']['output']
  readonly numAged90Days: Scalars['SafeInt']['output']
  readonly numAged120Days: Scalars['SafeInt']['output']
  readonly numCurrent: Scalars['SafeInt']['output']
}

export { AgingIntervalType }

/**
 * A line item that only contains an amount and a fee.
 * Used for Quick Billing only.
 * Current billed = amount * (1 + feePercent)
 * Eg: "Campbell #A1245, amount $12,345, fee 10%"
 * Proof of material cost is stored in item attachments.
 */
export type AmountLineItem = {
  readonly __typename: 'AmountLineItem'
  /** Current amount billed for this line item */
  readonly amount: Scalars['SafeInt']['output']
  /** Amount due, post tax. This is amountDuePreTax + amountDueTaxAmount */
  readonly amountDuePostTax: Scalars['SafeInt']['output']
  /** Amount + fee. This is pre-tax. */
  readonly amountDuePreTax: Scalars['SafeInt']['output']
  /** Tax amount on the amount due. Tax rate is available on `taxGroup` */
  readonly amountDueTaxAmount: Scalars['SafeInt']['output']
  /**
   * Pay-app attachments that act as backup for this line item.
   * Typically this would be a vendor invoice.
   */
  readonly attachments: ReadonlyArray<PayAppAttachment>
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  /**
   * Fee percentage to apply on top of `amount`.
   * For MATERIALS line item types, this is initialized to `contract.materialFeePercent`
   * but it can be adjusted manually.
   */
  readonly feePercent: Scalars['Float']['output']
  readonly id: Scalars['ID']['output']
  readonly payApp: PayApp
  /** Order in which to display the amount line items. First line item starts at 1. */
  readonly sortOrder: Scalars['SafeInt']['output']
  /** Optional tax rate to apply to the line item. It must be a tax group from the subcontractor company. */
  readonly taxGroup: Maybe<TaxGroup>
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

/**
 * Describes how to override the field of a template annotation.
 * This belongs to a template variant and only affects contracts that use this variant.
 */
export type AnnotationOverride = {
  readonly __typename: 'AnnotationOverride'
  readonly annotationPermanentId: Scalars['ID']['output']
  readonly copyDefaultValueFromPreviousAnnotationValue: Maybe<Scalars['Boolean']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly defaultValueKey: Maybe<Scalars['String']['output']>
  readonly doNotRetainOnReset: Maybe<Scalars['Boolean']['output']>
  readonly fieldType: Maybe<FormTemplateAnnotationMetadataFieldType>
  readonly fontColor: Maybe<Scalars['String']['output']>
  readonly fontFamily: Maybe<FormTemplateFont>
  readonly id: Scalars['ID']['output']
  readonly imageType: Maybe<FormTemplateAnnotationImageType>
  readonly isOptional: Maybe<Scalars['Boolean']['output']>
  readonly prefix: Maybe<Scalars['String']['output']>
  readonly selectedKey: Maybe<Scalars['String']['output']>
  readonly signatureType: Maybe<SignatureAnnotationType>
  readonly suffix: Maybe<Scalars['String']['output']>
  readonly syncTag: Maybe<Scalars['String']['output']>
  readonly textAlignment: Maybe<TextAlignment>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly userVisibleName: Maybe<Scalars['String']['output']>
  readonly variant: FormTemplateVariant
  readonly wrapText: Maybe<Scalars['Boolean']['output']>
}

export type ApproveChangeOrderRequestInput = {
  readonly amount: Scalars['SafeInt']['input']
  readonly approvedAt: Scalars['DateTime']['input']
  readonly generalContractorNumber: Scalars['String']['input']
  readonly id: Scalars['ID']['input']
  readonly scoFile?: InputMaybe<Scalars['Upload']['input']>
}

/**
 * An auth provider is a way to authenticate a user via Firebase.
 * Currently, we only support email and SAML.
 */
export type AuthProvider = {
  readonly __typename: 'AuthProvider'
  /** Only provided for type == SAML */
  readonly samlProviderId: Maybe<Scalars['String']['output']>
  readonly type: AuthProviderType
}

export { AuthProviderType }

export type BatchLienWaiverRequestsInput = {
  readonly lienWaiverId: Scalars['ID']['input']
  readonly vendorContactIds: ReadonlyArray<Scalars['ID']['input']>
}

export type BatchSendLienWaiverRequestsInput = {
  readonly contractId: Scalars['ID']['input']
  readonly lienWaivers: ReadonlyArray<BatchLienWaiverRequestsInput>
}

export type BillingDashboard = {
  readonly __typename: 'BillingDashboard'
  readonly billingSummary: BillingDashboardSummary
  readonly contracts: ReadonlyArray<BillingDashboardContract>
}

export type BillingDashboardContract = {
  readonly __typename: 'BillingDashboardContract'
  readonly billingStatus: DashboardBillingStatus
  readonly contract: Contract
  readonly projectMetrics: ProjectMetrics
}

export type BillingDashboardContractWithLineItems = {
  readonly __typename: 'BillingDashboardContractWithLineItems'
  readonly billingStatus: DashboardBillingStatus
  readonly contract: Contract
  readonly lineItems: ReadonlyArray<LineItemMetrics>
  readonly projectMetrics: ProjectMetrics
}

export type BillingDashboardSummary = {
  readonly __typename: 'BillingDashboardSummary'
  /** If undefined, the previous month had no amount billed and no amount in draft */
  readonly amountBilledAndDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Amount of progress billed across all projects this month, including pay apps in draft. Does
   * not include retention billed and does not subtract retention held.
   */
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /** If undefined, no amount of money was submitted for billing in the previous month */
  readonly amountBilledMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Amount of progress billed across all projects this month, excluding pay apps in draft. Does not
   * include retention billed and does not subtract retention held.
   */
  readonly amountBilledThisMonth: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no draft money outstanding */
  readonly amountInDraftMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Amount of progress billing still in draft as of this month. Does not include retention billed and
   * does not subtract retention held.
   */
  readonly amountInDraftThisMonth: Scalars['SafeInt']['output']
  /** Amount billed each month over the last n months */
  readonly monthlyBillingBreakdown: ReadonlyArray<MonthlyBillingBreakdown>
  readonly payAppStatusBreakdown: PayAppStatusBreakdown
  /** If undefined, the previous month had no project billed */
  readonly projectsBilledMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  readonly projectsBilledThisMonth: Scalars['SafeInt']['output']
  /** Percent change in retentionHeldThisMonth. If undefined, the previous month held no retention. */
  readonly retentionHeldMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /**
   * Retention held, including only submitted billing in the given month. This is a gross amount and
   * does not subtract retention billed.
   */
  readonly retentionHeldThisMonth: Scalars['SafeInt']['output']
  /**
   * Total retention held through a given month, from all draft and submitted pay apps. This is net
   * retention and subtracts retention billed, i.e. it is the total amount of retention held at the
   * end of the month.
   */
  readonly retentionHeldThruMonth: Scalars['SafeInt']['output']
  /**
   * Total number of active projects the current user has access to. This includes:
   * 1. All projects billing this month, including quick bills submitted this month
   * 2. All lump sum and unit price projects that did not bill this month
   */
  readonly totalProjectsThisMonth: Scalars['SafeInt']['output']
}

export type BillingTotalsData = {
  readonly __typename: 'BillingTotalsData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly month: Scalars['DateTime']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
  readonly submittedPayAppCount: Scalars['SafeInt']['output']
  readonly totalBilledThisMonth: Scalars['Float']['output']
}

export { BillingType }

export type BulkAddPredictedPaymentDatesInput = {
  readonly companyId: Scalars['ID']['input']
}

export type BulkCreateVendorContactInput = {
  readonly email: Scalars['EmailAddress']['input']
  readonly fullName: Scalars['String']['input']
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

export type BulkCreateVendorInput = {
  readonly address?: InputMaybe<Scalars['String']['input']>
  readonly contacts: ReadonlyArray<BulkCreateVendorContactInput>
  readonly integrationVendor?: InputMaybe<VendorIntegrationInput>
  readonly linkToVendorId?: InputMaybe<Scalars['ID']['input']>
  readonly name: Scalars['String']['input']
}

export type BulkUpdateContractCostsInput = {
  readonly contracts: ReadonlyArray<UpdateContractCostInput>
}

export { CacheType }

export type CalculateRetentionChangeInput = {
  /**
   * True if billing for retention held on past pay apps. False if adjusting the current retention
   * percent.
   */
  readonly isReleasingRetention: Scalars['Boolean']['input']
  readonly payAppId: Scalars['ID']['input']
  /** If billing directly on a line item, provide the line item progress ID */
  readonly progressId?: InputMaybe<Scalars['ID']['input']>
  /**
   * This retention percent is applied to all previous pay apps. If a line item or pay app (depending
   * on retention tracking type) is holding retention that would be decreased with this new percent,
   * the difference in retention would be billed.
   */
  readonly retentionPercent: Scalars['Float']['input']
}

export type CashForecastContract = {
  readonly __typename: 'CashForecastContract'
  readonly contract: Contract
  readonly hasInvoicePredictedPaymentPassed: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  readonly numComments: Scalars['Int']['output']
  readonly paymentPeriods: ReadonlyArray<ContractPaymentPeriod>
}

export type CashForecastPeriod = {
  readonly __typename: 'CashForecastPeriod'
  /**
   * The total amount that has already been billed, is not yet paid, and is predicted to be paid in
   * this period
   */
  readonly amountOutstanding: Scalars['SafeInt']['output']
  readonly period: CashForecastPeriodType
  /**
   * The first day of the period that this represents.
   * If the forecast is monthly and this represents May, startDate will be "<year>-05-01".
   * If the forecast is weekly, start date will be the Monday of the week that this represents.
   * Format: YYYY-MM-DD
   */
  readonly startDate: Scalars['String']['output']
}

export { CashForecastPeriodType }

export type ChangeOrderLogUseData = {
  readonly __typename: 'ChangeOrderLogUseData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly percentProjectsWithChangeOrder: Scalars['Float']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type ChangeOrderReport = {
  readonly __typename: 'ChangeOrderReport'
  readonly changeOrderSummaryReport: ChangeOrderSummaryReport
  readonly pendingChangeOrderReport: PendingChangeOrderReport
}

export type ChangeOrderReportInput = {
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly filters: DashboardFiltersInput
}

/** Represents a change order request, managed by a subcontractor for submitting to the project's GC for review */
export type ChangeOrderRequest = {
  readonly __typename: 'ChangeOrderRequest'
  /** The current or final amount of the change order request, including any GC adjustments */
  readonly amount: Maybe<Scalars['SafeInt']['output']>
  /** Once approved, the subcontractor may upload the final completed SCO form */
  readonly approvedScoFile: Maybe<StoredFile>
  readonly attachments: ReadonlyArray<ChangeOrderRequestAttachment>
  /** A list of comments on the change order request */
  readonly comments: ReadonlyArray<ChangeOrderRequestComment>
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly emailedContacts: ReadonlyArray<CompanyContact>
  readonly events: ReadonlyArray<ChangeOrderRequestEvent>
  /** Form template version is locked in when the request is sent */
  readonly formTemplateVersion: Maybe<FormTemplateVersion>
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  readonly generalContractorNumber: Maybe<Scalars['String']['output']>
  /** Whether the contract rate table was swapped or updated since the COR was created */
  readonly hasContractRateTableChanged: Scalars['Boolean']['output']
  /** Whether fees have changed on the contract since the COR was created */
  readonly haveContractFeesChanged: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /** If the COR was imported from a change order log, mark the time it was imported */
  readonly importedAt: Maybe<Scalars['DateTime']['output']>
  readonly internalNotes: Maybe<Scalars['String']['output']>
  readonly internalNumber: Maybe<Scalars['String']['output']>
  /**
   * Change order requests that the user has linked to this one. COR links are bidirectional and all
   * linked change order requests should have the same set of SOV line items.
   */
  readonly linkedChangeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  /** Return fields missing from the contract or project that are required on the COR form template */
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly name: Scalars['String']['output']
  readonly proceedingWithWork: Scalars['Boolean']['output']
  /** Once the COR is submitted, this is the amount at the time of the most recent submission */
  readonly proposedAmount: Maybe<Scalars['SafeInt']['output']>
  /** Once the SOR is submitted, this is the time of the most recent submission */
  readonly proposedAt: Maybe<Scalars['DateTime']['output']>
  /** Link back to rate table that is used to price the change order request. */
  readonly rateTable: Maybe<RateTable>
  /**
   * Only if pricing the change order.
   * Warning: this includes *all* fees, including group fees.
   */
  readonly rateTableFees: ReadonlyArray<ChangeOrderRequestRateTableFee>
  /** Only used for pricing the change order */
  readonly rateTableGroups: ReadonlyArray<ChangeOrderRequestRateTableGroup>
  /** Used for pricing the change order */
  readonly rateTableItems: ReadonlyArray<ChangeOrderRequestRateTableItem>
  readonly reason: Maybe<Scalars['String']['output']>
  /** Amount of time in days (eg. 1 day or 1.5 days). Only used for template variables on forms */
  readonly scheduleImpact: Maybe<Scalars['Float']['output']>
  /** Change order requests do not support forms with physical signatures */
  readonly signature: Maybe<Signature>
  readonly sovLineItems: ReadonlyArray<SovLineItem>
  readonly status: ChangeOrderRequestStatus
  readonly statusChangedAt: Scalars['DateTime']['output']
  readonly statusLogs: ReadonlyArray<ChangeOrderRequestStatusLog>
  /** The human-readable unit of measurement for each unit, e.g. LF, EA, HR for UNIT_PRICE contract. */
  readonly unitName: Maybe<Scalars['String']['output']>
  /** A price per unit, in cents. This is used for UNIT_PRICE contracts. */
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  /** Should this COR be included in a log of change orders submitted to GC */
  readonly visibleToGc: Scalars['Boolean']['output']
}

/** Represents a backup document uploaded to a change order request that can be accessed via a URL */
export type ChangeOrderRequestAttachment = {
  readonly __typename: 'ChangeOrderRequestAttachment'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Maybe<Scalars['String']['output']>
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  /** Internal-only attachments will never get included in the generated PDF package for the COR */
  readonly isInternalOnly: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly uploadId: Scalars['ID']['output']
}

export type ChangeOrderRequestAttachmentInput = {
  readonly description?: InputMaybe<Scalars['String']['input']>
  readonly file: Scalars['Upload']['input']
  readonly isInternalOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly name: Scalars['String']['input']
  readonly uploadId: Scalars['ID']['input']
}

/** Represents a comment from a user on a change order request */
export type ChangeOrderRequestComment = {
  readonly __typename: 'ChangeOrderRequestComment'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Maybe<Scalars['String']['output']>
  readonly user: User
}

/** Represents a single event that occurred on a change order request */
export type ChangeOrderRequestEvent = {
  readonly __typename: 'ChangeOrderRequestEvent'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  /** Values in this dictionary depend on the event type */
  readonly metadata: Scalars['JSONObject']['output']
  readonly type: ChangeOrderRequestEventType
}

/**
 * Feed of change order request events returned in paginated lists. Cursor is the latest created time
 * and hasNext indicates if there are more events to be returned.
 */
export type ChangeOrderRequestEventFeed = {
  readonly __typename: 'ChangeOrderRequestEventFeed'
  readonly changeOrderRequestEvents: ReadonlyArray<ChangeOrderRequestEvent>
  readonly cursor: Scalars['DateTime']['output']
  readonly hasNext: Scalars['Boolean']['output']
}

export { ChangeOrderRequestEventType }

export type ChangeOrderRequestInput = {
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly approvedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly createdDate: Scalars['DateTime']['input']
  readonly generalContractorNumber?: InputMaybe<Scalars['String']['input']>
  readonly internalNumber?: InputMaybe<Scalars['String']['input']>
  readonly name: Scalars['String']['input']
  readonly proposedAmount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly reason?: InputMaybe<Scalars['String']['input']>
  readonly scheduleImpact?: InputMaybe<Scalars['Float']['input']>
  readonly submittedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
  readonly visibleToGc?: InputMaybe<Scalars['Boolean']['input']>
}

export type ChangeOrderRequestLineItemInput = {
  readonly approvedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly code: Scalars['String']['input']
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly name: Scalars['String']['input']
  readonly sovLineItemGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly taxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly totalValue: Scalars['SafeInt']['input']
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
}

/** A COR-level or group-level fee on a priced change order request. */
export type ChangeOrderRequestRateTableFee = {
  readonly __typename: 'ChangeOrderRequestRateTableFee'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  /** If provided, this fee applies to a specific group */
  readonly group: Maybe<ChangeOrderRequestRateTableGroup>
  readonly id: Scalars['ID']['output']
  /** If provided, this fee overrides another fee at the COR (global) level. It must be a group fee in this case. */
  readonly overridesFee: Maybe<ChangeOrderRequestRateTableFee>
  readonly percent: Scalars['Float']['output']
  /** Link back to the contract rate table fee that was used to create this fee, if it still exists. */
  readonly rateTableFee: Maybe<ContractRateTableFee>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A rate table item group on a change order request rate table */
export type ChangeOrderRequestRateTableGroup = {
  readonly __typename: 'ChangeOrderRequestRateTableGroup'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly fees: ReadonlyArray<ChangeOrderRequestRateTableFee>
  readonly id: Scalars['ID']['output']
  readonly items: ReadonlyArray<ChangeOrderRequestRateTableItem>
  readonly name: Scalars['String']['output']
  /** Link back to the rate table group that was used to create the pay app item, if it still exists. */
  readonly rateTableGroup: Maybe<RateTableGroup>
  /** Sub-total amount before any fee applies */
  readonly subtotalAmount: Scalars['SafeInt']['output']
  /** Total amount after all fees */
  readonly totalAmount: Scalars['SafeInt']['output']
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A line item on a change order request rate table */
export type ChangeOrderRequestRateTableItem = {
  readonly __typename: 'ChangeOrderRequestRateTableItem'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly currentPriced: Scalars['SafeInt']['output']
  readonly currentUnitsPriced: Scalars['Float']['output']
  readonly description: Scalars['String']['output']
  /** Group that the line item belongs to */
  readonly group: ChangeOrderRequestRateTableGroup
  readonly id: Scalars['ID']['output']
  /** Whether this item was manually added to the COR and doesn't have a matching rate table item */
  readonly isOneOffItem: Scalars['Boolean']['output']
  /** Link back to the rate table item that was used to create the COR item, if it still exists. */
  readonly rateTableItem: Maybe<RateTableItem>
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitRate: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export { ChangeOrderRequestStatus }

/** Represents a log of all statuses for a change order request and when the transition occurred. */
export type ChangeOrderRequestStatusLog = {
  readonly __typename: 'ChangeOrderRequestStatusLog'
  readonly changeOrderRequest: ChangeOrderRequest
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly status: ChangeOrderRequestStatus
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  readonly user: User
}

export type ChangeOrderSummaryReport = {
  readonly __typename: 'ChangeOrderSummaryReport'
  readonly aggregateChangeOrderDataByContract: ReadonlyArray<AggregateChangeOrderDataByContract>
  readonly aggregateChangeOrderTotals: AggregateChangeOrderData
}

export type ClearChangeOrderRequestPricingInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
}

/** Represents a single company that can either be a general contractor or subcontractor. */
export type Company = {
  readonly __typename: 'Company'
  /** Setting for determining what reference date to use to calculate A/R aging for this company */
  readonly agingIntervalType: AgingIntervalType
  /**
   * If true, allow over-billing on lump sum projects. By default, over-billing is not allowed. Note
   * that it is allowed for all companies on unit price projects.
   */
  readonly allowLumpSumOverbilling: Scalars['Boolean']['output']
  /** Integrations for this company. Some of them might be archived. */
  readonly companyIntegrations: ReadonlyArray<CompanyIntegration>
  readonly contacts: ReadonlyArray<CompanyContact>
  readonly createdAt: Scalars['DateTime']['output']
  /** Default change order request templates to use on a new project. */
  readonly defaultChangeOrderLogTemplate: Maybe<FormTemplate>
  /** Default change order request templates to use on a new project. */
  readonly defaultChangeOrderRequestTemplate: Maybe<FormTemplate>
  /**
   * Default number of days prior to actual deadline that will be shown as the internal deadline.
   * Used as the default for new contracts. If not set, defaults to 3 days.
   */
  readonly defaultDaysBeforePayAppDue: Maybe<Scalars['Int']['output']>
  /**
   * Default pay app requirements to use on a new lump sum project. If the list is undefined, it hasn't
   * been set up yet. If it is empty, it has been set up to have 0 requirements by default.
   */
  readonly defaultLumpSumRequirements: Maybe<ReadonlyArray<PayAppRequirementGroup>>
  /** If the company sets a custom due date for pay apps, it will be the default for new contracts */
  readonly defaultPayAppDueOnDayOfMonth: Maybe<Scalars['Int']['output']>
  /** Default primary lien waiver templates to use on a new project. */
  readonly defaultPrimaryLienWaivers: Maybe<SubcontractorLienWaiverTemplateSet>
  /**
   * Default template to use when creating QUICK pay apps.
   * When creating quick projects, the contract will be set up with this template in its
   * pay app requirements.
   */
  readonly defaultQuickBillingTemplate: Maybe<FormTemplate>
  /** If the company sets a custom default retention percent, it will be the default for new contracts */
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  /** Whether new contracts are created with the setting enabled to show audit trail on signed forms */
  readonly defaultShowAuditItemOnSignedPackages: Scalars['Boolean']['output']
  /**
   * Default pay app requirements to use on a new T&M project. If the list is undefined, it
   * hasn't been set up yet. If it is empty, it has been set up to have 0 requirements by default.
   */
  readonly defaultTimeAndMaterialsRequirements: Maybe<ReadonlyArray<PayAppRequirementGroup>>
  /**
   * Default pay app requirements to use on a new unit price project. If the list is undefined, it
   * hasn't been set up yet. If it is empty, it has been set up to have 0 requirements by default.
   */
  readonly defaultUnitPriceRequirements: Maybe<ReadonlyArray<PayAppRequirementGroup>>
  /** Default vendor lien waiver templates to use on a new project. */
  readonly defaultVendorLienWaivers: Maybe<SubcontractorLienWaiverTemplateSet>
  /**
   * If true, the billing worksheet tool is enabled for all projects under this company. This means
   * users will have the ability to add worksheet line items for line items on those projects' SOVs.
   */
  readonly enableBillingWorksheets: Scalars['Boolean']['output']
  readonly expectedNumberOfProjects: Maybe<Scalars['SafeInt']['output']>
  readonly id: Scalars['ID']['output']
  /**
   * Integration metadata for this company, with mappings of different types for different integration
   * types. Used for storing mappings for related companies, e.g. a sub's general contractors.
   */
  readonly integrationMappings: Scalars['JSONObject']['output']
  readonly leadPMs: ReadonlyArray<User>
  readonly locations: ReadonlyArray<Location>
  readonly metadata: CompanyMetadata
  readonly name: Scalars['String']['output']
  /** Shorter version of the formal company name, shown in the nav bar and included in searches */
  readonly nickname: Maybe<Scalars['String']['output']>
  readonly notarySignature: Maybe<StoredFile>
  readonly notaryStamp: Maybe<StoredFile>
  /** Used for admin users to tag companies with notary type requirements */
  readonly notaryTags: ReadonlyArray<NotaryTag>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly picture: Maybe<StoredFile>
  readonly projectImportOperations: ReadonlyArray<ProjectImportOperation>
  readonly secondarySignature: Maybe<StoredFile>
  /** Siteline CSM team member assigned to this company */
  readonly sitelinePointOfContact: Maybe<User>
  /** Tax groups that are used by this company. Tax-aware line items must link to one of these tax groups. */
  readonly taxGroups: ReadonlyArray<TaxGroup>
  readonly type: CompanyType
  readonly updatedAt: Scalars['DateTime']['output']
  readonly users: ReadonlyArray<CompanyUser>
  /** The frequency at which to send automatic vendor lien waiver reminders */
  readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
  readonly vendors: ReadonlyArray<Vendor>
}

/** Represents a single company that can either be a general contractor or subcontractor. */
export type CompanyProjectImportOperationsArgs = {
  status: ImportOperationStatus
}

/** Represents a contact at another company who may receive pay apps or primary lien waivers */
export type CompanyContact = {
  readonly __typename: 'CompanyContact'
  /** An archived company contact should not be surfaced to the user, except relating to past documents */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly company: Company
  readonly companyName: Scalars['String']['output']
  readonly createdAt: Scalars['DateTime']['output']
  readonly email: Scalars['EmailAddress']['output']
  readonly fullName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  readonly jobTitle: Maybe<Scalars['String']['output']>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
}

export type CompanyContactValuesInput = {
  readonly companyName?: InputMaybe<Scalars['String']['input']>
  readonly email?: InputMaybe<Scalars['EmailAddress']['input']>
  readonly fullName?: InputMaybe<Scalars['String']['input']>
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

/**
 * A company-level integration.
 * Each contract integration must reference a company integration.
 * Companies can have multiple integrations of the same type.
 */
export type CompanyIntegration = {
  readonly __typename: 'CompanyIntegration'
  /**
   * When archived, the integration no longer shows up in admin/web
   * and contract integrations linked to it are considered inactive
   */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  /** Whether the credentials are ready be auto-rotated */
  readonly credentialsReadyForAutoRotation: Scalars['Boolean']['output']
  /** Alias for credentials.updatedAt, necessary because credentials are not exposed to the API */
  readonly credentialsUpdatedAt: Maybe<Scalars['DateTime']['output']>
  readonly id: Scalars['ID']['output']
  /** How change orders should be imported on the SOV, when reading the SOV line items into Siteline */
  readonly importChangeOrdersMethod: ImportIntegrationChangeOrdersMethod
  /** Optional label to differentiate this integration from others of the same type */
  readonly label: Maybe<Scalars['String']['output']>
  /** Indicates that this integration is specific to a company office. If null, it applies to all offices */
  readonly location: Maybe<Location>
  readonly longName: Scalars['String']['output']
  /** Integration specific metadata that applies across projects. Fields depend on the integration type. */
  readonly metadata: Scalars['JSONObject']['output']
  readonly shortName: Scalars['String']['output']
  /**
   * Whether to automatically import the SOV for projects when initially onboarded, if supported by
   * this integration type
   */
  readonly shouldImportNewProjectSov: Scalars['Boolean']['output']
  readonly type: IntegrationType
  readonly updatedAt: Scalars['DateTime']['output']
}

export { CompanyListSortCriteria }

/** Represents unstructured metadata about a given company. */
export type CompanyMetadata = {
  readonly __typename: 'CompanyMetadata'
  /** If true, restricts users from signing up unless their email is in the list of domains. */
  readonly domainSignup: Scalars['Boolean']['output']
  /** This is a list of email domains for a company, which restricts who can sign up by email. */
  readonly domains: ReadonlyArray<Scalars['String']['output']>
  /**
   * Represents what day of the month (up to 28) to remind users to create pay apps. If not set, the
   * email will go out on the 2nd Monday of the month by default.
   */
  readonly monthlyCreatePayAppReminderDate: Maybe<Scalars['Int']['output']>
  /** Represents the specialty subtrade of the contractor, if one exists. */
  readonly subtrade: Maybe<Scalars['String']['output']>
}

export type CompanyMetadataInput = {
  readonly domainSignup: Scalars['Boolean']['input']
  readonly domains: ReadonlyArray<Scalars['String']['input']>
  readonly subtrade?: InputMaybe<Scalars['String']['input']>
}

/** Represents metadata around a company that is working on a given project. */
export type CompanyProjectMetadata = {
  readonly __typename: 'CompanyProjectMetadata'
  readonly company: Company
  /**
   * Used if the subcontractor uses a distinct name for the company on this project. If not set, company name
   * will always default to the name on the company model.
   */
  readonly companyName: Maybe<Scalars['String']['output']>
  readonly role: CompanyProjectRole
  readonly selectedAddress: Maybe<Location>
}

export { CompanyProjectRole }

export { CompanyType }

/** Represents a user that exists at a company, along with their role. */
export type CompanyUser = {
  readonly __typename: 'CompanyUser'
  readonly company: Company
  /** The user's office location. Must be one of the company's locations. */
  readonly companyLocation: Location
  readonly createdAt: Scalars['DateTime']['output']
  /** If provided, emails the user sends from this company will be sent from this email address */
  readonly emailAlias: Maybe<CompanyUserEmailAlias>
  readonly id: Scalars['ID']['output']
  readonly isSitelineAdmin: Scalars['Boolean']['output']
  readonly permissions: ReadonlyArray<Permission>
  readonly role: CompanyUserRole
  readonly status: UserStatus
  readonly updatedAt: Scalars['DateTime']['output']
  readonly user: User
}

/**
 * Represents an email alias used by a user who belongs to multiple companies. Used when sending or
 * receiving emails under that company, while their primary email address is still used for login.
 */
export type CompanyUserEmailAlias = {
  readonly __typename: 'CompanyUserEmailAlias'
  readonly companyUser: CompanyUser
  readonly emailAddress: Scalars['EmailAddress']['output']
  readonly id: Scalars['ID']['output']
  readonly isVerified: Scalars['Boolean']['output']
  readonly verificationCodeExpiresAt: Scalars['DateTime']['output']
}

export type CompanyUserInput = {
  readonly companyLocationId?: InputMaybe<Scalars['ID']['input']>
  readonly permissions: ReadonlyArray<Permission>
  readonly projectIds: ReadonlyArray<Scalars['ID']['input']>
  readonly role: CompanyUserRole
  readonly user: UserInput
}

export { CompanyUserRole }

export { ComplianceStatus }

export type ComplianceUseData = {
  readonly __typename: 'ComplianceUseData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly percentProjectsWithComplianceRequirements: Scalars['Float']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type Contract = {
  readonly __typename: 'Contract'
  /**
   * Whether taxes are applied to retention held/released.
   * Most ERPs apply tax on the current billed only (not deducting retention), so this is false by default.
   */
  readonly appliesTaxToRetention: Scalars['Boolean']['output']
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  /** Form templates that are associated with this contract */
  readonly associatedFormTemplates: ReadonlyArray<FormTemplateAssociatedContract>
  /** Rolling average number of days from pay app submission to paid, over the last 6 months */
  readonly averageDaysToPaid: Scalars['Float']['output']
  /** The forecast, or billing projection, for this contract */
  readonly billingForecast: Maybe<ContractBillingForecast>
  readonly billingType: BillingType
  /** Optional form template to use for keeping track of change order logs */
  readonly changeOrderLogTemplate: Maybe<FormTemplate>
  /** Template to use for creating and submitting change order requests */
  readonly changeOrderRequestTemplate: Maybe<FormTemplate>
  /** List of change order requests for this contract. */
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  readonly comments: ReadonlyArray<ContractComment>
  readonly company: Company
  readonly complianceStatus: Maybe<ComplianceStatus>
  /** Start date of the contract */
  readonly contractDate: Maybe<Scalars['DateTime']['output']>
  readonly contractNumber: Maybe<Scalars['String']['output']>
  /** Custom template variables, accessible in the `contract` key. Default to {}. */
  readonly contractTemplateVariables: Scalars['JSONObject']['output']
  /** Cost analysis derived from ERP or manually entered by user */
  readonly cost: Maybe<ContractCost>
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * Represents the number of days before the pay app is due. Used to notify sub PMs to update their
   * invoice before the deadline.
   */
  readonly daysBeforePayAppDue: Scalars['Int']['output']
  /** Contains the last list of Users notified for requesting review on a COR or the COR log */
  readonly defaultChangeOrderRequestReviewContacts: ReadonlyArray<User>
  /** Team members who are notified when forms have been partially completed */
  readonly defaultFormsReviewContacts: ReadonlyArray<User>
  /** Containts a default list of contacts to submit pay apps to */
  readonly defaultGcContacts: ReadonlyArray<CompanyContact>
  /** Contains the last list of User references that were notified that the invoice is ready for review */
  readonly defaultInvoiceReviewContacts: ReadonlyArray<User>
  /** Tracks what initial retention % to use on the first pay app. Range 0-1. */
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  /**
   * Default tax group to use for all new line items on this contract.
   * Tax groups can be later updated on individual line items.
   */
  readonly defaultTaxGroup: Maybe<TaxGroup>
  /**
   * The billing end of the earliest pay app to be submitted on Siteline, or null if no pay apps are
   * submitted. Note that the earliest pay app may not be Pay App #1, since there may be pre-Siteline
   * billing. It's possible for this to be null but `hasStartedBilling` to be true (if there is
   * pre-Siteline billing but there are no pay apps or pre-Siteline pay apps), so this shouldn't be
   * used as a proxy for whether billing has started on a contract.
   */
  readonly earliestPayAppBillingEnd: Maybe<Scalars['DateTime']['output']>
  readonly events: ReadonlyArray<ContractEvent>
  /** Files stored on the contract */
  readonly files: ReadonlyArray<StoredFile>
  /**
   * The billing end of Pay App #1, which may be a Siteline pay app or pre-Siteline pay app. Null
   * if no pay apps or the first pay app's date is not known.
   */
  readonly firstPayAppBillingEnd: Maybe<Scalars['DateTime']['output']>
  /** True if there is a past pay app count or any pay apps have been submitted on Siteline */
  readonly hasStartedBilling: Scalars['Boolean']['output']
  /**
   * If any of the pay app requirement forms on this contract are tagged with SWORN_STATEMENT,
   * regardless of whether they have finished processing, this will be true.
   */
  readonly hasSwornStatementForms: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /** Whether to generate the change order log as part of the pay app package for every pay app */
  readonly includeChangeOrderLogInPayAppPackage: Maybe<Scalars['Boolean']['output']>
  readonly integrations: ReadonlyArray<Integration>
  /** Internal project billing notes and reminders added by users on the project */
  readonly internalNotes: Maybe<Scalars['String']['output']>
  /** If this number is set, it will take precedence over the main projectNumber on the project list page. */
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  /** The total amount due across pay apps that have been submitted but not yet paid out */
  readonly invoiceAmountOutstanding: Scalars['SafeInt']['output']
  /**
   * Whether the subcontractor is receiving payment via joint check. Inferred from joint check settings
   * on the contract's vendor lien waivers.
   */
  readonly isJointCheck: Scalars['Boolean']['output']
  readonly lastEditedAt: Maybe<Scalars['DateTime']['output']>
  readonly lastEditedBy: Maybe<User>
  readonly leadPMs: ReadonlyArray<User>
  /**
   * Contains a list of CompanyContact references that were emailed the legal documents for a
   * contract on the project.
   */
  readonly legalRequirementContacts: ReadonlyArray<CompanyContact>
  /** Contains a list of all Legal requirements for a contract on the project. */
  readonly legalRequirements: ReadonlyArray<LegalRequirement>
  readonly lienWaiverTemplates: Maybe<SubcontractorLienWaiverTemplateSet>
  /**
   * The day of the month to use as thru date for all lien waiver requests on this project.
   * If undefined, will use the last day of the month. If defined, max date is 28.
   */
  readonly lienWaiverThroughDate: Maybe<Scalars['SafeInt']['output']>
  /** All lien waivers associated with this contract */
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /** The sum of amounts on all conditional lien waivers requested for this contract */
  readonly lowerTierConditionalAmountWaived: Scalars['SafeInt']['output']
  readonly lowerTierLienWaiverTemplates: Maybe<SubcontractorLienWaiverTemplateSet>
  /** The sum of amounts on all unconditional lien waivers requested for this contract */
  readonly lowerTierUnconditionalAmountWaived: Scalars['SafeInt']['output']
  /** Fields missing that are required to generate this contract's vendor lien waiver forms */
  readonly missingRequiredVendorLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
  /** The next pay app number, if you were to create a new pay app. */
  readonly nextPayAppNumber: Scalars['Int']['output']
  /** Tracks the status of onboarding legal requirements for a project from the Compliance module */
  readonly onboardedProjectComplianceStatus: OnboardedProjectComplianceStatus
  /** Tracks the status of onboarding vendors/lien waivers for a project from the Vendor module */
  readonly onboardedProjectVendorsStatus: OnboardedProjectVendorsStatus
  /** Tracks the status of the onboarding steps for a new contract. */
  readonly onboardedStatus: OnboardedProjectContractStatus
  /** Skipped pay app months, including reasons for not billing */
  readonly optedOutMonths: ReadonlyArray<ContractOptedOutMonth>
  /**
   * The total amount requested across conditional vendor lien waivers on this contract minus the total
   * amount requested in unconditional vendor lien waivers on this contract
   */
  readonly outstandingVendorLienWaiverAmount: Scalars['SafeInt']['output']
  /**
   * If a project is onboarded midway through, this number indicates how many pay apps have
   * already been processed. i.e. If the project has 6 completed pay apps and will start using
   * Siteline on its 7th pay app, this number will be set to 6. For new projects, the number is
   * left as null. For new projects, this number is set to 0.
   */
  readonly pastPayAppCount: Scalars['Int']['output']
  /** Contains a list of all PayApp requirements for a contract on the project. */
  readonly payAppRequirementGroups: ReadonlyArray<PayAppRequirementGroup>
  readonly payApps: ReadonlyArray<PayApp>
  /**
   * The number of days corresponding to the type of payment terms. If `paymentTermsType` is:
   * - NetPayment, then this is the contractual number of days to be paid (i.e. if NET30, this is 30)
   * - PayWhenPaid, then this is the expected number of days to receive payment
   */
  readonly paymentTerms: Maybe<Scalars['SafeInt']['output']>
  readonly paymentTermsType: Maybe<ContractPaymentTermsType>
  /**
   * The percent complete for this contract. Will return null if billingType is equal to QUICK.
   * Range 0-1.
   */
  readonly percentComplete: Maybe<Scalars['Float']['output']>
  /**
   * Amount billed pre-siteline.
   * This is only for T&M contracts. Other contracts must use the SOV to indicate pre-siteline billed.
   */
  readonly preSitelineBilled: Maybe<Scalars['SafeInt']['output']>
  readonly preSitelinePayApps: ReadonlyArray<PreSitelinePayApp>
  /** The computed total amount of retention held pre-siteline in cents. */
  readonly preSitelineRetention: Scalars['SafeInt']['output']
  /**
   * Pre-siteline retention override amount in cents.
   * This is required for standard/line-item tracking.
   */
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /** Primary lien waivers for a contract. */
  readonly primaryLienWaivers: ReadonlyArray<LienWaiver>
  /** The amount billed in progress to date for this contract, but not yet paid out */
  readonly progressAmountOutstanding: Scalars['SafeInt']['output']
  /**
   * Progress remaining to bill after the last submitted pay app. If no pay apps are submitted,
   * this is the progress remaining after any pre-Siteline progress completed.
   */
  readonly progressRemaining: Scalars['SafeInt']['output']
  readonly project: Project
  /**
   * For T&M contracts, the rate table to use when creating new pay apps. For lump sum and unit price
   * contracts, the rate table to use for pricing change order requests.
   */
  readonly rateTable: Maybe<RateTable>
  /**
   * Contract-level fees that apply to the current rate table.
   * These are copied when creating a new pay app or pricing a new change order request.
   * Warning: this includes *all* fees, including group fees.
   */
  readonly rateTableFees: ReadonlyArray<ContractRateTableFee>
  /** Attachments that should be copied to pay apps as backup when they're created */
  readonly recurringPayAppAttachments: ReadonlyArray<ContractPayAppAttachment>
  /** The amount billed in retention to date for this contract, but not yet paid out */
  readonly retentionAmountOutstanding: Scalars['SafeInt']['output']
  /** Total amount of retention held on the SOV to date, derived only from submitted pay apps */
  readonly retentionHeldOnBilledPayApps: Scalars['SafeInt']['output']
  /** Track retention at the pay app level instead of at the line item progress level */
  readonly retentionTrackingLevel: RetentionTrackingLevel
  readonly role: CompanyProjectRole
  /** Whether to round all retention values for each line item to the nearest dollar. */
  readonly roundRetention: Scalars['Boolean']['output']
  /** One of the addresses on the subcontractor */
  readonly selectedAddress: Location
  /** Whether signed packages generated from this contract should contain an audit page */
  readonly showAuditItemOnSignedPackages: Scalars['Boolean']['output']
  /** A list of months in MM-YYYY format for months that are not being billed for. */
  readonly skippedPayAppMonths: ReadonlyArray<Scalars['String']['output']>
  /** Not provided if billingType is QUICK */
  readonly sov: Maybe<Sov>
  readonly status: ContractStatus
  /** How this contract calculates taxes */
  readonly taxCalculationType: TaxCalculationType
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  /** List of all users who are working on this contract */
  readonly users: ReadonlyArray<ContractUser>
  readonly vendorComplianceStatus: Maybe<VendorComplianceStatus>
  readonly vendorContracts: ReadonlyArray<VendorContract>
  readonly vendorNumPendingLegalDocuments: Scalars['Int']['output']
  readonly vendorNumPendingLienWaivers: Scalars['Int']['output']
  /** Vendor number for this contract, as defined by the General Contractor */
  readonly vendorNumber: Maybe<Scalars['String']['output']>
  /** List of GC recipients who will receive vendor materials, e.g., lien waivers & legal docs */
  readonly vendorSubmitToContacts: ReadonlyArray<CompanyContact>
  /**
   * Internal project vendors module ('lower tier') bulletin board. Notes and reminders are added
   * here by users on the project.
   */
  readonly vendorsInternalNotes: Maybe<Scalars['String']['output']>
}

export type ContractPayAppsArgs = {
  months?: InputMaybe<ReadonlyArray<Scalars['DateTime']['input']>>
}

/** A billing projection over a series of months for a single contract */
export type ContractBillingForecast = {
  readonly __typename: 'ContractBillingForecast'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * The curve used to generate the projection amounts. If the user edits the amounts after
   * generating the curve, it will be saved as a CUSTOM curve.
   */
  readonly curve: ForecastCurve
  /**
   * The first month that was editable when the forecast was created. This is used for clients to
   * determine when the user started projecting monthly values, as opposed to values that shouldn't be
   * included in the projection because they weren't editable at the time of creation (those values
   * may still be included as zeroes in `projectedAmountPerMonth` because the total forecast starts
   * at the contract's first billing month. Note that this may not be the same as the `createdAt`
   * month, since current or future pay apps could have been submitted already when the forecast was
   * created.
   */
  readonly firstEditableMonthWhenCreated: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lastEditedBy: User
  /**
   * The projected billing amounts for each month. Each element in the array corresponds to a
   * consecutive month, starting with `startingMonth`.
   */
  readonly projectedAmountPerMonth: ReadonlyArray<Scalars['SafeInt']['output']>
  /**
   * The amount that is considered unallocated in the billing at the current time.
   * Equivalent to the total contract balance remaining (from the last pay app submitted)
   * minus the amount projected from this month forward.
   */
  readonly remainingAmount: Scalars['SafeInt']['output']
  /**
   * The first month to include in the forecast. The first element in the `amountsProjected` array
   * corresponds to this month.
   */
  readonly startingMonth: Scalars['DateTime']['output']
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type ContractComment = {
  readonly __typename: 'ContractComment'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Scalars['String']['output']
  readonly type: ContractCommentType
  readonly user: User
}

export { ContractCommentType }

/**
 * Cost analysis for a single contract.
 * Can be read from ERP or manually entered.
 */
export type ContractCost = {
  readonly __typename: 'ContractCost'
  readonly additionalCommittedCost: Scalars['SafeInt']['output']
  readonly billingsInExcessOfCost: Scalars['SafeInt']['output']
  readonly contract: Contract
  readonly contractTotalBilled: Scalars['SafeInt']['output']
  readonly contractTotalValue: Scalars['SafeInt']['output']
  readonly costPercentageCompleted: Scalars['Float']['output']
  readonly costToDate: Scalars['SafeInt']['output']
  readonly createdAt: Scalars['DateTime']['output']
  readonly earnedRevenue: Scalars['SafeInt']['output']
  readonly id: Scalars['ID']['output']
  readonly manuallyUpdatedBy: Maybe<User>
  readonly totalEstimatedCost: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly updatedFromIntegrationType: Maybe<IntegrationType>
}

export type ContractEvent = {
  readonly __typename: 'ContractEvent'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  readonly isAdmin: Scalars['Boolean']['output']
  readonly metadata: Scalars['JSONObject']['output']
  readonly type: ContractEventType
}

export { ContractEventType }

export { ContractListContractStatusFilter }

export type ContractListOverview = {
  readonly __typename: 'ContractListOverview'
  readonly activeCount: Scalars['Int']['output']
  readonly archivedCount: Scalars['Int']['output']
  readonly fullyBilledCount: Scalars['Int']['output']
  readonly onboardingCount: Scalars['Int']['output']
}

export { ContractListPayAppStatusFilter }

export type ContractListSort = {
  readonly compareWithContractId?: InputMaybe<Scalars['ID']['input']>
  readonly criteria: ContractListSortCriteria
  readonly order?: InputMaybe<PaginatedListSortOrder>
}

export { ContractListSortCriteria }

export { ContractListSubmitViaFilter }

export { ContractMonthlyStatusFilter }

export type ContractOptedOutMonth = {
  readonly __typename: 'ContractOptedOutMonth'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** Month in MM-YYYY format that is not being billed for */
  readonly month: Scalars['String']['output']
  readonly reason: Maybe<Scalars['String']['output']>
  /** The user who opted out of billing for the month */
  readonly user: Maybe<User>
}

/** Represents an attachment that will be copied as backup on every pay app created on a contract */
export type ContractPayAppAttachment = {
  readonly __typename: 'ContractPayAppAttachment'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Maybe<Scalars['String']['output']>
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  readonly isEmailAttachment: Scalars['Boolean']['output']
  readonly isInternalOnly: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type ContractPayAppFormInput = {
  readonly file: Scalars['Upload']['input']
  readonly name: Scalars['String']['input']
}

export type ContractPaymentPeriod = {
  readonly __typename: 'ContractPaymentPeriod'
  readonly dateRange: Maybe<PaymentPeriodDateRange>
  readonly hasRetentionOnlyPayApps: Scalars['Boolean']['output']
  readonly payApps: ReadonlyArray<PayApp>
  readonly predictedPaymentAmount: Scalars['SafeInt']['output']
}

export { ContractPaymentTermsType }

export type ContractRateTableFee = {
  readonly __typename: 'ContractRateTableFee'
  readonly contract: Contract
  /** The name of the fee, e.g. Overhead, Markup, Profit */
  readonly description: Scalars['String']['output']
  /** If provided, this fee applies to a specific group */
  readonly group: Maybe<RateTableGroup>
  readonly id: Scalars['ID']['output']
  /** If provided, this fee overrides another fee at the contract (global) level. It must be a group fee in this case. */
  readonly overridesFee: Maybe<ContractRateTableFee>
  /** This fee is added to the amount billed on a line item */
  readonly percent: Scalars['Float']['output']
}

export { ContractStatus }

/** Users who are members of the provided contract */
export type ContractUser = {
  readonly __typename: 'ContractUser'
  readonly contract: Contract
  readonly id: Scalars['ID']['output']
  readonly role: ContractUserRole
  readonly user: User
}

export type ContractUserInput = {
  readonly contractRole: ContractUserRole
  readonly userId: Scalars['ID']['input']
}

export { ContractUserRole }

export type CopyContractLegalRequirementsInput = {
  readonly copyFromContractId: Scalars['ID']['input']
  readonly copyToContractId: Scalars['ID']['input']
}

export type CopyContractVendorsInput = {
  readonly copyFromContractId: Scalars['ID']['input']
  readonly copyToContractId: Scalars['ID']['input']
}

export type CreateAmountLineItemInput = {
  readonly amount: Scalars['SafeInt']['input']
  readonly description: Scalars['String']['input']
  readonly feePercent: Scalars['Float']['input']
  readonly payAppId: Scalars['ID']['input']
  readonly taxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly type: LineItemType
}

export type CreateChangeOrderInput = {
  readonly approvedDate: Scalars['DateTime']['input']
  readonly code: Scalars['String']['input']
  /** For line items that have retention managed per-progress, an initial value must be provided */
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly name: Scalars['String']['input']
  readonly payAppId: Scalars['ID']['input']
  readonly sovLineItemGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly taxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly totalValue: Scalars['SafeInt']['input']
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
}

export type CreateChangeOrderRequestAttachmentInput = {
  readonly attachment: ChangeOrderRequestAttachmentInput
  readonly changeOrderRequestId: Scalars['ID']['input']
}

export type CreateChangeOrderRequestInput = {
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly attachments: ReadonlyArray<ChangeOrderRequestAttachmentInput>
  readonly contractId: Scalars['ID']['input']
  readonly internalNumber?: InputMaybe<Scalars['String']['input']>
  readonly name: Scalars['String']['input']
  readonly proceedingWithWork?: InputMaybe<Scalars['Boolean']['input']>
  readonly reason?: InputMaybe<Scalars['String']['input']>
  readonly scheduleImpact?: InputMaybe<Scalars['Float']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
  readonly visibleToGc?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateChangeOrderRequestRateTableItemInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly description: Scalars['String']['input']
  /** When not provided, the line item will be added to the UNCATEGORIZED group */
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitRate: Scalars['SafeInt']['input']
}

export type CreateChangeOrderRequestsInput = {
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequestInput>
  readonly contractId: Scalars['ID']['input']
}

export type CreateCompanyContactInput = {
  readonly companyId: Scalars['ID']['input']
  readonly companyName: Scalars['String']['input']
  readonly email: Scalars['EmailAddress']['input']
  readonly fullName: Scalars['String']['input']
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
}

export type CreateContractBillingForecastInput = {
  readonly contractId: Scalars['ID']['input']
  readonly curve: ForecastCurve
  readonly projectedAmountPerMonth: ReadonlyArray<Scalars['SafeInt']['input']>
  /** Zero-indexed month, from 0 to 11 */
  readonly startingMonth: Scalars['SafeInt']['input']
  /** Year in YYYY format */
  readonly startingYear: Scalars['SafeInt']['input']
}

export type CreateContractRateTableFeeInput = {
  readonly contractId: Scalars['ID']['input']
  readonly description: Scalars['String']['input']
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  readonly overridesFeeId?: InputMaybe<Scalars['ID']['input']>
  readonly percent: Scalars['Float']['input']
}

export type CreateIntegrationInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly contractId: Scalars['ID']['input']
  readonly metadata?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateLienWaiverRequestsInput = {
  readonly defaultAmount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly defaultAmountText?: InputMaybe<Scalars['String']['input']>
  readonly defaultExceptionAmounts?: InputMaybe<Scalars['String']['input']>
  readonly defaultExceptionDates?: InputMaybe<Scalars['String']['input']>
  readonly isJointCheck: Scalars['Boolean']['input']
  readonly lienWaiverId: Scalars['ID']['input']
  readonly type: LienWaiverType
  readonly vendorContactIds: ReadonlyArray<Scalars['ID']['input']>
}

export type CreateLocationInput = {
  readonly companyId: Scalars['ID']['input']
  readonly location: LocationInput
}

export type CreateMonthlyBillingProjectInput = {
  readonly billingType: BillingType
  readonly companyId: Scalars['ID']['input']
  readonly contract: LumpSumProjectContractInput
  readonly integrations: ReadonlyArray<LumpSumProjectIntegrationInput>
  readonly project: LumpSumProjectInput
  readonly sov?: InputMaybe<LumpSumProjectSovInput>
}

export type CreatePayAppInput = {
  readonly billingEnd: Scalars['DateTime']['input']
  readonly billingStart: Scalars['DateTime']['input']
  readonly contractId: Scalars['ID']['input']
  readonly payAppDueDate: Scalars['DateTime']['input']
  readonly retentionOnly: Scalars['Boolean']['input']
}

export type CreatePayAppRateTableItemInput = {
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly description: Scalars['String']['input']
  /** When not provided, the line item will be added to the UNCATEGORIZED group */
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  readonly payAppId: Scalars['ID']['input']
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitRate: Scalars['SafeInt']['input']
}

export type CreatePreSitelinePayAppInput = {
  readonly amountPaid?: InputMaybe<Scalars['SafeInt']['input']>
  readonly billingEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly billingStart?: InputMaybe<Scalars['DateTime']['input']>
  readonly contractId: Scalars['ID']['input']
  readonly currentBilled?: InputMaybe<Scalars['SafeInt']['input']>
  readonly isBalanceManuallyClosed?: InputMaybe<Scalars['Boolean']['input']>
  readonly paidAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly payAppFile?: InputMaybe<Scalars['Upload']['input']>
  readonly payAppNumber: Scalars['Int']['input']
  readonly paymentDue?: InputMaybe<Scalars['SafeInt']['input']>
  readonly retentionOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly status?: InputMaybe<PreSitelinePayAppStatus>
  readonly submittedAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly submittedUnconditionalOutsideOfSiteline?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateQuickBillingProjectInput = {
  readonly companyId: Scalars['ID']['input']
  readonly location: LocationInput
  readonly name: Scalars['String']['input']
  readonly projectNumber: Scalars['String']['input']
  /**
   * If set, will create the quick bill with tax calculation type SINGLE_TAX_GROUP and this tax group
   * as the `defaultTaxGroup`
   */
  readonly taxGroupId?: InputMaybe<Scalars['String']['input']>
}

export type CreateRateTableGroupInput = {
  readonly linkGroupId: Scalars['String']['input']
  readonly name: Scalars['String']['input']
  readonly type: LineItemType
}

export type CreateRateTableInput = {
  readonly companyId: Scalars['ID']['input']
  readonly effectiveAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly expiresAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly name: Scalars['String']['input']
}

export type CreateSovLineItemGroupInput = {
  readonly contractId: Scalars['ID']['input']
  readonly groupCode?: InputMaybe<Scalars['String']['input']>
  readonly groupName: Scalars['String']['input']
}

export type CreateTaxGroupInput = {
  readonly companyId: Scalars['ID']['input']
  readonly integrationMappings?: InputMaybe<ReadonlyArray<TaxGroupIntegrationMappingInput>>
  readonly name: Scalars['String']['input']
  readonly taxPercent: Scalars['Float']['input']
}

export type CreateVendorContactInput = {
  readonly email: Scalars['EmailAddress']['input']
  readonly fullName: Scalars['String']['input']
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly lowerTierToVendorId?: InputMaybe<Scalars['ID']['input']>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  readonly vendorId: Scalars['ID']['input']
}

export type CreateVendorContractCommitmentInput = {
  readonly amount: Scalars['SafeInt']['input']
  readonly commitmentType: VendorContractCommitmentType
  readonly date: Scalars['DateTime']['input']
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  readonly includeVendorInSwornStatement?: InputMaybe<Scalars['Boolean']['input']>
  readonly number: Scalars['String']['input']
  readonly payWhenPaid: Scalars['Boolean']['input']
  readonly paymentTerms?: InputMaybe<Scalars['SafeInt']['input']>
  readonly vendorContractId: Scalars['ID']['input']
}

export type CreateVendorInput = {
  readonly address?: InputMaybe<LocationInput>
  readonly companyId: Scalars['ID']['input']
  readonly integrationVendor?: InputMaybe<VendorIntegrationInput>
  readonly internalNotes?: InputMaybe<Scalars['String']['input']>
  readonly vendorName: Scalars['String']['input']
}

export type CreateVendorLienWaiverInput = {
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly contractId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly month: Scalars['SafeInt']['input']
  readonly type?: InputMaybe<LienWaiverType>
  readonly vendorContractId: Scalars['ID']['input']
  readonly year: Scalars['SafeInt']['input']
}

export type CreateVendorsInput = {
  readonly companyId: Scalars['ID']['input']
  readonly vendors: ReadonlyArray<BulkCreateVendorInput>
}

export type CreateWriteSyncOperationInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly payload: Scalars['JSONObject']['input']
}

export { DashboardBillingStatus }

export type DashboardFiltersInput = {
  /** For aging only, which type of amount to consider (progress or retention released) */
  readonly agingAmountType?: InputMaybe<AgingAmountType>
  /** List of billing types to consider */
  readonly billingType?: InputMaybe<ReadonlyArray<BillingType>>
  /** Restricts the list to only the provided contracts */
  readonly contractIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  /** General contractor company IDs */
  readonly generalContractorIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  /** Lead project manager user IDs */
  readonly leadPMIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  /** Location IDs for which company office is working on the project */
  readonly officeIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  /** For billing only. Whether to include draft billing or only billing on submitted pay apps. */
  readonly payAppStatusFilter?: InputMaybe<PayAppStatusFilter>
  /** For billing only. List of project statuses to consider, (e.g. 'past due'). */
  readonly projectStatus?: InputMaybe<ReadonlyArray<ContractMonthlyStatusFilter>>
}

export type DashboardInput = {
  /** Null to get dashboard data for all companies that the user has access to */
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  /** Filters for the project */
  readonly filters: DashboardFiltersInput
  /** Zero-indexed month, from 0 to 11 */
  readonly month: Scalars['SafeInt']['input']
  /** Year in YYYY format */
  readonly year: Scalars['SafeInt']['input']
}

export type DeactivateUserInput = {
  readonly companyId: Scalars['ID']['input']
  readonly userId: Scalars['ID']['input']
}

export type DeleteChangeOrderInput = {
  readonly id: Scalars['ID']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type DeleteChangeOrderRequestRateTableItemInput = {
  readonly changeOrderRequestRateTableItemId: Scalars['ID']['input']
}

export type DeleteContractFileInput = {
  readonly contractId: Scalars['ID']['input']
  readonly fileId: Scalars['ID']['input']
}

export type DeleteContractRateTableFeeInput = {
  readonly feeId: Scalars['ID']['input']
}

export type DeleteContractRateTableInput = {
  readonly contractId: Scalars['ID']['input']
}

export type DeletePayAppAttachmentInput = {
  /**
   * True if this should be removed from the contract's recurring backup. It will
   * also be removed from all future pay apps.
   */
  readonly deleteRecurring?: InputMaybe<Scalars['Boolean']['input']>
  readonly id: Scalars['ID']['input']
}

export type DeletePayAppRateTableItemInput = {
  readonly payAppRateTableItemId: Scalars['ID']['input']
}

export type DeletePayAppSwornStatementVendorInput = {
  readonly id: Scalars['ID']['input']
}

export type DeleteVendorContractCommitmentInput = {
  readonly id: Scalars['ID']['input']
}

export type DeleteVendorInput = {
  readonly id: Scalars['ID']['input']
}

export type DeletionResult = {
  readonly __typename: 'DeletionResult'
  /** The ID of the entity that was deleted */
  readonly id: Scalars['ID']['output']
}

export type DigitalSignatureInput = {
  readonly signatureFont: SignatureFont
  /** Signature name and font are used if the user signs using siteline */
  readonly signatureName: Scalars['String']['input']
}

export { DocumentExpirationFrequency }

export { DocumentRequestStatus }

/** Represents a single send action of a legal document to a contact at a GC */
export type DocumentSendLog = {
  readonly __typename: 'DocumentSendLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly legalDocument: LegalDocument
  readonly recipients: ReadonlyArray<CompanyContact>
  readonly sender: User
}

export { DocumentType }

/** Represents an unsaved location object that was imported. */
export type DraftLocation = {
  readonly __typename: 'DraftLocation'
  readonly city: Scalars['String']['output']
  readonly coordinates: Scalars['LatLng']['output']
  readonly country: Scalars['String']['output']
  readonly county: Maybe<Scalars['String']['output']>
  readonly postalCode: Maybe<Scalars['String']['output']>
  readonly state: Scalars['String']['output']
  readonly street1: Maybe<Scalars['String']['output']>
  readonly street2: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
}

export { DueToType }

export { EmailType }

export type EstimatePayAppPaymentDateInput = {
  readonly payAppId: Scalars['ID']['input']
}

export type EstimatedPayAppPaymentDate = {
  readonly __typename: 'EstimatedPayAppPaymentDate'
  readonly estimationMethod: PaymentDateEstimationType
  readonly paymentDate: Scalars['DateTime']['output']
}

export { ForecastCurve }

/**
 * Represents a single template that can be used on many pay apps across many companies.
 * One template has one version or more. The latest version is used when rendering entities that don't
 * have pinned templates. For instance, a legal requirement will render using the latest version
 * of its template, unless the document has a pinned `formTemplateVersion` field.
 */
export type FormTemplate = {
  readonly __typename: 'FormTemplate'
  /**
   * The subcontractor, GC, or owner that created the original form. For example, the AGA G702's
   * associated company is AGA while the Webcor Cover Sheet's associated company would be Webcor (not
   * the sub that sent us the form to onboard). If no company is associated (i.e. the Base G702), this
   * would be undefined.
   */
  readonly associatedCompany: Maybe<Company>
  /** Contracts that this template is being built for */
  readonly associatedContracts: ReadonlyArray<FormTemplateAssociatedContract>
  /** User who will build the FormTemplate */
  readonly builder: User
  /** Free form comments on the form template. */
  readonly comments: ReadonlyArray<FormTemplateComment>
  readonly createdAt: Scalars['DateTime']['output']
  /** Target date to have the form built by */
  readonly dueDate: Maybe<Scalars['DateTime']['output']>
  /** If this template is a duplicate, this is a reference to the built duplicate template. */
  readonly duplicateTemplate: Maybe<FormTemplate>
  readonly id: Scalars['String']['output']
  /**
   * Whether the form template is ready to be shown to customers and used when generating forms.
   * True if the form template status is COMPLETED or if `skippedValidation` is true.
   */
  readonly isCustomerReady: Scalars['Boolean']['output']
  readonly latestVersion: Maybe<FormTemplateVersion>
  /** The original template that was submitted by the customer */
  readonly originalFile: Maybe<StoredFile>
  /** Form manager during the build/validator process. Usually a CSM. */
  readonly owner: User
  /** Which company, if any, requested this form template to be built */
  readonly requestingCompany: Maybe<Company>
  /**
   * Sometimes a customer needs a form onboarded ASAP before we have time to validate. In that case,
   * the owner is allowed to skip validation on the form and add it to a project immediately. However,
   * the form remains in the same state it was before so that it can be finished. i.e. if it was in
   * validation state, it will stay in that state until it is validated. Once the form is completed,
   * this boolean will revert to false.
   */
  readonly skippedValidation: Scalars['Boolean']['output']
  /** Latest status in FormTemplateStatusLog */
  readonly status: FormTemplateStatus
  /** Insertion time of the latest status in FormTemplateStatusLog */
  readonly statusChangedAt: Scalars['DateTime']['output']
  /** The current status of the template. Only COMPLETED forms should be added to live projects. */
  readonly statusLogs: ReadonlyArray<FormTemplateStatusLog>
  /** Tags for a template. i.e. a template could have NOTARIZED and G702. */
  readonly tags: ReadonlyArray<FormTemplateTag>
  readonly type: FormTemplateType
  readonly updatedAt: Scalars['DateTime']['output']
  /** This is the name of the form under the thumbnail on the sign page */
  readonly userVisibleName: Scalars['String']['output']
  /** User who will validate the FormTemplate */
  readonly validator: Maybe<User>
  readonly variants: ReadonlyArray<FormTemplateVariant>
  readonly versions: ReadonlyArray<FormTemplateVersion>
}

/** Represents a single annotation that will be rendered on top of a PDF FormTemplate. */
export type FormTemplateAnnotation = {
  readonly __typename: 'FormTemplateAnnotation'
  /**
   * Only for type USER_ENTERED_FIELD. Indicates whether the default value of this annotation
   * Should be copied from the previous annotation value (using the permanent ID).
   */
  readonly copyDefaultValueFromPreviousAnnotationValue: Scalars['Boolean']['output']
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * Only for type USER_ENTERED_FIELD. Indicates the template variable path/key to use as a
   * default annotation value.
   */
  readonly defaultValueKey: Maybe<Scalars['String']['output']>
  /**
   * Only for type USER_ENTERED_FIELD. Must also have defaultValueKey set. Indicates whether
   * this value should always be cleared when retaining annotations.
   */
  readonly doNotRetainOnReset: Scalars['Boolean']['output']
  /**
   * Used for dynamic templates to match annotations to template tags.
   * Usage in XLSX/DOCX: {$field}
   * Usage in annotation metadata: field
   */
  readonly dynamicFieldTag: Maybe<Scalars['String']['output']>
  /**
   * Only for type USER_ENTERED_FIELD. Known type of an annotation.
   * Annotations are opaque by default and we don't know what they mean. It makes it difficult to
   * make sense of what an annotation value represents – which is fine for most annotations but
   * not when we need to extract things like "lien-waiver amount".
   * To workaround that, `metadata.fieldType` allows to tag specific annotations for meaningful
   * processing of annotation values.
   */
  readonly fieldType: Maybe<FormTemplateAnnotationMetadataFieldType>
  /**
   * This is the 6 digit hex code of the color, which will get transformed to the rgb() equivalent
   * when we actually render in the PDF because that's what the library requires.
   */
  readonly fontColor: Scalars['String']['output']
  readonly fontFamily: FormTemplateFont
  readonly formTemplateVersion: FormTemplateVersion
  readonly height: Scalars['Float']['output']
  readonly id: Scalars['ID']['output']
  /** Only for type IMAGE: type of image to render. */
  readonly imageType: Maybe<FormTemplateAnnotationImageType>
  /** Whether the annotation must have a value. */
  readonly isOptional: Scalars['Boolean']['output']
  /**
   * If a form has more than one page, this is the page number that the annotation is on. This
   * number is NOT zero-indexed, it begins at 1 because that is how pdf.js handles it.
   */
  readonly pageNumber: Scalars['Int']['output']
  /**
   * An identifier that persists across versions of the template, used to match annotations together
   * after a template version was cloned.
   */
  readonly permanentId: Scalars['ID']['output']
  /** Only for type TEMPLATE_VARIABLE. Text to insert before the template variable. */
  readonly prefix: Maybe<Scalars['String']['output']>
  /** Only for type TEMPLATE_VARIABLE. Indicates the template variable path/key. */
  readonly selectedKey: Maybe<Scalars['String']['output']>
  /** Only for type SIGNATURE: type of signature to render. */
  readonly signatureType: Maybe<SignatureAnnotationType>
  /** Only for type TEMPLATE_VARIABLE. Text to insert after the template variable. */
  readonly suffix: Maybe<Scalars['String']['output']>
  /**
   * Used to sync annotations on the same template and across different templates.
   * An annotation changes its value automatically when the user types in another annotation with
   * the same sync tag.
   */
  readonly syncTag: Maybe<Scalars['String']['output']>
  /** Horizontal alignment of text within the annotation bounds. Defaults to LEFT */
  readonly textAlignment: TextAlignment
  readonly type: FormTemplateAnnotationType
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * When we ask the user to type something into the annotation for the FormTemplateAnnotationValue, we
   * need to tell them what specifically they're typing in (eg. invoice number, etc).
   */
  readonly userVisibleName: Scalars['String']['output']
  readonly width: Scalars['Float']['output']
  /** Whether text should wrap within the annotation bounds. Defaults to false. Font size will not be reduced to fit. */
  readonly wrapText: Scalars['Boolean']['output']
  readonly xStart: Scalars['Float']['output']
  readonly yStart: Scalars['Float']['output']
}

export { FormTemplateAnnotationImageType }

export { FormTemplateAnnotationMetadataFieldType }

export { FormTemplateAnnotationType }

/**
 * Represents a user-entered value for any FormTemplateAnnotation that is of type
 * FormTemplateAnnotationType.USER_ENTERED_FIELD.
 */
export type FormTemplateAnnotationValue = {
  readonly __typename: 'FormTemplateAnnotationValue'
  readonly annotation: FormTemplateAnnotation
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: Maybe<User>
  readonly id: Scalars['ID']['output']
  readonly metadata: Scalars['JSONObject']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly value: Scalars['String']['output']
}

export type FormTemplateAnnotationValueInput = {
  readonly formTemplateAnnotationId: Scalars['ID']['input']
  readonly metadata: Scalars['JSONObject']['input']
  readonly value: Scalars['String']['input']
}

export type FormTemplateAssociatedContract = {
  readonly __typename: 'FormTemplateAssociatedContract'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly formTemplate: FormTemplate
  readonly id: Scalars['String']['output']
  /**
   * If the form template will not be used in the way it was provided, this field can be flipped to
   * true. When flipped to true, this form template will no longer block the contract's onboarding
   * status from flipping to "done", since this form template is not expected to be used for the type
   * it was provided.
   */
  readonly ignoreProvidedAsFormType: Scalars['Boolean']['output']
  readonly providedAsFormType: Maybe<ProjectOnboardingFormType>
}

/** Represents a comment from a user on a FormTemplate. */
export type FormTemplateComment = {
  readonly __typename: 'FormTemplateComment'
  readonly createdAt: Scalars['DateTime']['output']
  readonly formTemplate: FormTemplate
  readonly id: Scalars['ID']['output']
  readonly isSystemGenerated: Scalars['Boolean']['output']
  readonly message: Scalars['String']['output']
  readonly user: User
}

export { FormTemplateFont }

export { FormTemplateOnboardingSignatureType }

export { FormTemplateStatus }

/** Represents a log of all statuses for a form template and when the transition occurred. */
export type FormTemplateStatusLog = {
  readonly __typename: 'FormTemplateStatusLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly formTemplate: FormTemplate
  readonly id: Scalars['ID']['output']
  readonly status: FormTemplateStatus
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  readonly user: User
}

export { FormTemplateTag }

export { FormTemplateType }

/**
 * A template variant.
 * A single template has 1 or more variants, and each variant can override the annotations of the
 * the original template.
 */
export type FormTemplateVariant = {
  readonly __typename: 'FormTemplateVariant'
  readonly annotationOverrides: ReadonlyArray<AnnotationOverride>
  /** Whether $0 amounts should render as empty strings */
  readonly hidesZeroDollarAmounts: Maybe<Scalars['Boolean']['output']>
  readonly id: Scalars['ID']['output']
  readonly internalName: Scalars['String']['output']
  readonly isDefaultVariant: Scalars['Boolean']['output']
  /**
   * Whether percentages should be rounded.
   * By default, percentages are displayed with 2 decimal places.
   * Note that this does not affect tax and fee percentages, which have their own precision.
   */
  readonly roundPercentages: Maybe<Scalars['Boolean']['output']>
  readonly template: FormTemplate
  /** If true, use the company's notary signature image if available and skip the notary flow (download + upload). */
  readonly useCompanyNotarySignatureIfAvailable: Maybe<Scalars['Boolean']['output']>
  readonly userVisibleName: Maybe<Scalars['String']['output']>
}

/**
 * Version of a form template. Used by pay-apps/lien-waivers/legal-documents to pin templates to
 * one version in time.
 */
export type FormTemplateVersion = {
  readonly __typename: 'FormTemplateVersion'
  /** This is only used with static PDF templates, or when type equals StoredFileType.PDF */
  readonly annotations: ReadonlyArray<FormTemplateAnnotation>
  readonly createdAt: Scalars['DateTime']['output']
  readonly file: StoredFile
  readonly formTemplate: FormTemplate
  readonly id: Scalars['ID']['output']
  /** Text extracted from this version */
  readonly text: Maybe<FormTemplateVersionText>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly versionNumber: Scalars['Int']['output']
}

export type FormTemplateVersionEntities = {
  readonly __typename: 'FormTemplateVersionEntities'
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly payApps: ReadonlyArray<PayApp>
}

/**
 * Text extracted from a form template version file, via OCR.
 * All lines of text and pages are concatenated together, with a newline character separating line or page.
 * We use this to run form matching on the whole set of templates.
 * The text field is indexed with a gin index, so it can be searched for text efficiently.
 */
export type FormTemplateVersionText = {
  readonly __typename: 'FormTemplateVersionText'
  readonly id: Scalars['ID']['output']
  readonly text: Scalars['String']['output']
  readonly version: FormTemplateVersion
}

export type FormTrackerData = {
  readonly __typename: 'FormTrackerData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly completedFormIds: ReadonlyArray<Scalars['ID']['output']>
  readonly completedFormsCount: Scalars['SafeInt']['output']
  readonly formsCompletedRatio: Scalars['Float']['output']
  readonly requestedFormIds: ReadonlyArray<Scalars['ID']['output']>
  readonly requestedFormsCount: Scalars['SafeInt']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export type FormTurnaroundData = {
  readonly __typename: 'FormTurnaroundData'
  /** Basic info for all companies whose IDs are referenced in the forms list */
  readonly companies: ReadonlyArray<MinimalFormTurnaroundCompany>
  readonly forms: ReadonlyArray<FormTurnaroundFormData>
  /** Basic info for all users whose IDs are referenced in the forms list */
  readonly users: ReadonlyArray<MinimalFormTurnaroundUser>
}

/** Data about a form template and the dates it was processed */
export type FormTurnaroundFormData = {
  readonly __typename: 'FormTurnaroundFormData'
  readonly endDate: Scalars['DateTime']['output']
  readonly formTemplate: MinimalFormTurnaroundFormTemplate
  readonly startDate: Scalars['DateTime']['output']
}

export { FormWorkerRole }

export type G702Values = {
  readonly __typename: 'G702Values'
  readonly balanceToFinish: Maybe<Scalars['SafeInt']['output']>
  readonly balanceToFinishWithRetention: Maybe<Scalars['SafeInt']['output']>
  readonly contractSumToDate: Scalars['SafeInt']['output']
  readonly currentPaymentDue: Scalars['SafeInt']['output']
  readonly materialsRetentionAmount: Scalars['SafeInt']['output']
  readonly materialsRetentionPercent: Scalars['Float']['output']
  readonly netChangeByChangeOrders: Scalars['SafeInt']['output']
  readonly originalContractSum: Scalars['SafeInt']['output']
  readonly previousPayments: Scalars['SafeInt']['output']
  readonly progressRetentionAmount: Scalars['SafeInt']['output']
  readonly progressRetentionPercent: Scalars['Float']['output']
  readonly totalCompletedToDate: Scalars['SafeInt']['output']
  readonly totalLessRetainage: Scalars['SafeInt']['output']
  readonly totalRetention: Scalars['SafeInt']['output']
}

export type GeneralContractorChangeOrderRequestContactInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly companyContactId: Scalars['ID']['input']
}

export type GeneralContractorContactPayAppInput = {
  readonly companyContactId: Scalars['ID']['input']
  readonly companyId: Scalars['ID']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type GeneralContractorForDedupe = {
  readonly __typename: 'GeneralContractorForDedupe'
  readonly company: Company
  readonly contractCount: Scalars['SafeInt']['output']
}

export type GeneralContractorLegalRequirementContactInput = {
  readonly companyContactId: Scalars['ID']['input']
  readonly contractId: Scalars['ID']['input']
}

export type GeneralContractorVendorSubmitToContactInput = {
  readonly companyContactId: Scalars['ID']['input']
  readonly contractId: Scalars['ID']['input']
}

export type GenerateIntegrationInvoiceCodeInput = {
  readonly integrationId?: InputMaybe<Scalars['ID']['input']>
}

export type GetAuthProviderInput = {
  readonly email: Scalars['String']['input']
}

export type GetCompanyContactsInput = {
  readonly companyId: Scalars['ID']['input']
}

export type GetCompanyIntegrationTaxGroupsInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
}

export type GetContractByProjectIdInput = {
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly projectId: Scalars['ID']['input']
}

export type GetContractsInput = {
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly status?: InputMaybe<ReadonlyArray<ContractStatus>>
}

export type GetCurrentProjectsInput = {
  readonly companyId: Scalars['ID']['input']
  readonly status?: InputMaybe<ReadonlyArray<ContractStatus>>
}

export type GetIntegrationCustomersInput = {
  readonly integrationId: Scalars['ID']['input']
}

export type GetIntegrationTaxGroupsInput = {
  readonly integrationId: Scalars['ID']['input']
}

export type GetIntegrationVendorInvoicesInput = {
  readonly endDate?: InputMaybe<Scalars['String']['input']>
  readonly integrationId: Scalars['ID']['input']
  /** Dates are in YYYY-MM-DD format */
  readonly startDate?: InputMaybe<Scalars['String']['input']>
  readonly vendorId?: InputMaybe<Scalars['ID']['input']>
}

export type GetLegalDocumentsByPeriodInput = {
  readonly companyId: Scalars['ID']['input']
  /** Types are associated with a frequency */
  readonly documentType: DocumentType
  readonly numPeriods: Scalars['SafeInt']['input']
  readonly periodStart?: InputMaybe<Scalars['DateTime']['input']>
}

export type GetLienWaiverForMonthInput = {
  readonly contractId: Scalars['ID']['input']
  readonly month: Scalars['DateTime']['input']
  /** Pay app should be provided if one exists for the month */
  readonly payAppId?: InputMaybe<Scalars['ID']['input']>
  /** Only get lien waivers of a specific type(s) */
  readonly types?: InputMaybe<ReadonlyArray<LienWaiverType>>
}

/** DEPRECATED */
export type GetLienWaiversByMonthInput = {
  readonly companyId: Scalars['ID']['input']
  readonly includeConditionals: Scalars['Boolean']['input']
  readonly month: Scalars['SafeInt']['input']
  readonly numMonths: Scalars['SafeInt']['input']
  /** This *must* be provided but is kept optional for backwards compatibility. */
  readonly projectId?: InputMaybe<Scalars['String']['input']>
  readonly year: Scalars['SafeInt']['input']
}

/** DEPRECATED */
export type GetLienWaiversSummaryByMonthInput = {
  readonly companyId: Scalars['ID']['input']
  readonly month: Scalars['SafeInt']['input']
  readonly numMonths: Scalars['SafeInt']['input']
  readonly year: Scalars['SafeInt']['input']
}

export type GetPaginatedCashForecastContractsInput = {
  readonly billingTypes?: InputMaybe<ReadonlyArray<BillingType>>
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly contractIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly currentDate: Scalars['String']['input']
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  readonly generalContractorIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly leadPMIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  readonly officeIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly periodType: CashForecastPeriodType
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly sort?: InputMaybe<ReadonlyArray<ContractListSort>>
}

export type GetPaginatedContractsInput = {
  readonly billingType?: InputMaybe<BillingType>
  readonly billingTypes?: InputMaybe<ReadonlyArray<BillingType>>
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly complianceStatus?: InputMaybe<ComplianceStatus>
  readonly contractStatus?: InputMaybe<ContractListContractStatusFilter>
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  /**
   * Informs which due date should be referenced when filtering by past due pay apps.
   * Note that this is not actually a filter, but a reference point for the pay app due filter.
   * If the pay app past due filter is applied and `dueToType` is not provided, will default to GC.
   */
  readonly dueToType?: InputMaybe<DueToType>
  readonly generalContractorIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  /** Filter returned contracts based on whether or not they have a billing forecast */
  readonly hasBillingForecast?: InputMaybe<Scalars['Boolean']['input']>
  /** Includes all contracts for the company, not limited to the user's projects */
  readonly includeAllCompanyContracts?: InputMaybe<Scalars['Boolean']['input']>
  readonly leadPMIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  readonly month?: InputMaybe<Scalars['String']['input']>
  readonly officeIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly payAppStatus?: InputMaybe<ContractListPayAppStatusFilter>
  readonly payAppStatuses?: InputMaybe<ReadonlyArray<ContractListPayAppStatusFilter>>
  readonly projectIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly retentionPayAppsOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly search?: InputMaybe<Scalars['String']['input']>
  readonly sort?: InputMaybe<ReadonlyArray<ContractListSort>>
  readonly submitVia?: InputMaybe<ContractListSubmitViaFilter>
  readonly submitViaOptions?: InputMaybe<ReadonlyArray<ContractListSubmitViaFilter>>
  readonly templateIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
}

export type GetPaginatedVendorsInput = {
  /** ID of the subcontractor company for which the vendors should be fetched. */
  readonly companyId: Scalars['ID']['input']
  /** ID of the last vendor that was loaded. For the first query, this isn't specified. */
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  /** Defaults to 50. Pass 0 to get all vendors. */
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  /** This will filter results by the provided search term. */
  readonly search?: InputMaybe<Scalars['String']['input']>
  /** This will determine the order in which vendors are returned. */
  readonly sort?: InputMaybe<VendorListSort>
}

export type GetProjectLegalRequirementsInput = {
  readonly companyId: Scalars['ID']['input']
  readonly projectIds: ReadonlyArray<Scalars['ID']['input']>
}

export type GetProjectLienWaiversByMonthInput = {
  readonly companyId: Scalars['ID']['input']
  readonly projectIds: ReadonlyArray<Scalars['ID']['input']>
}

export type GetVendorLienWaiversByMonthInput = {
  readonly companyId: Scalars['ID']['input']
  readonly vendorIds: ReadonlyArray<Scalars['ID']['input']>
}

export type GetVendorsInput = {
  readonly companyId: Scalars['ID']['input']
}

export type GetWithTokenInput = {
  readonly token: Scalars['String']['input']
}

export { ImportIntegrationChangeOrdersMethod }

export type ImportIntegrationTaxGroupInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly integrationTaxGroupId: Scalars['ID']['input']
}

export { ImportOperationStatus }

/** All relevant metadata returned about a project that was imported from an integration. */
export type ImportProjectOnboardingMetadata = {
  readonly __typename: 'ImportProjectOnboardingMetadata'
  readonly architect: Maybe<IntegrationCompany>
  readonly companyIntegrationId: Scalars['ID']['output']
  readonly contract: IntegrationContract
  readonly generalContractor: Maybe<IntegrationCompany>
  readonly integrationAssociatedCompanyId: Maybe<Scalars['String']['output']>
  readonly owner: Maybe<IntegrationCompany>
  readonly project: IntegrationProjectDetails
  readonly taxCalculationType: TaxCalculationType
  readonly taxGroup: Maybe<IntegrationTaxGroup>
  readonly type: IntegrationType
}

export type ImportSovInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly newProject: Scalars['Boolean']['input']
  readonly onlyChangeOrders?: InputMaybe<Scalars['Boolean']['input']>
}

/** A contract-level integration (textura, gcpay, etc) */
export type Integration = {
  readonly __typename: 'Integration'
  readonly companyIntegration: CompanyIntegration
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly credentialsUpdatedAt: Maybe<Scalars['DateTime']['output']>
  readonly id: Scalars['ID']['output']
  readonly isActive: Scalars['Boolean']['output']
  readonly longName: Scalars['String']['output']
  /** Mappings of Siteline entities to Integration entities. Fields depend on the integration type. */
  readonly mappings: Scalars['JSONObject']['output']
  /**
   * Integration specific metadata, primarly for file-based integrations. Fields depend on the
   * integration type. Kept separate from mappings because this can be updated by the frontend.
   */
  readonly metadata: Scalars['JSONObject']['output']
  readonly shortName: Scalars['String']['output']
  readonly syncs: ReadonlyArray<IntegrationSync>
  readonly type: IntegrationType
  readonly writeSyncOperations: ReadonlyArray<WriteSyncOperation>
}

export type IntegrationCompany = {
  readonly __typename: 'IntegrationCompany'
  readonly companyAddress: Maybe<DraftLocation>
  readonly companyName: Maybe<Scalars['String']['output']>
  readonly sitelineCompanyId: Maybe<Scalars['String']['output']>
  readonly sitelineLocation: Maybe<Location>
}

export type IntegrationContract = {
  readonly __typename: 'IntegrationContract'
  readonly billingType: Maybe<BillingType>
  readonly contractDate: Maybe<Scalars['DateTime']['output']>
  readonly contractNumber: Maybe<Scalars['String']['output']>
  /**
   * Some integrations (e.g. Foundation) will not have a contract for every project. We still
   * use this contract object, as some contract data may be available from the job setup, but do
   * not require a contract ID.
   */
  readonly integrationContractId: Maybe<Scalars['String']['output']>
  readonly latestPayAppNumber: Maybe<Scalars['SafeInt']['output']>
  readonly payAppDueDate: Maybe<Scalars['SafeInt']['output']>
  readonly paymentTerms: Maybe<Scalars['SafeInt']['output']>
  readonly paymentTermsType: Maybe<ContractPaymentTermsType>
  /**
   * Amount billed pre-siteline.
   * This is only for T&M contracts. Other contracts must use the SOV to indicate pre-siteline billed.
   */
  readonly preSitelineBilled: Maybe<Scalars['SafeInt']['output']>
  readonly retentionPercent: Maybe<Scalars['Float']['output']>
  readonly retentionTrackingLevel: Maybe<RetentionTrackingLevel>
  readonly roundRetention: Maybe<Scalars['Boolean']['output']>
}

export type IntegrationCustomer = {
  readonly __typename: 'IntegrationCustomer'
  readonly code: Maybe<Scalars['String']['output']>
  readonly integrationCustomerId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
}

export type IntegrationGeneralLedgerAccount = {
  readonly __typename: 'IntegrationGeneralLedgerAccount'
  readonly code: Scalars['String']['output']
  readonly integrationAccountId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
  readonly parentCode: Maybe<Scalars['String']['output']>
  readonly parentName: Maybe<Scalars['String']['output']>
}

export type IntegrationIncomeTypeNumber = {
  readonly __typename: 'IntegrationIncomeTypeNumber'
  readonly description: Scalars['String']['output']
  readonly incomeTypeNumber: Scalars['String']['output']
  readonly integrationIncomeTypeNumberId: Scalars['String']['output']
}

export type IntegrationIncomeTypeNumbersInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
}

export type IntegrationInvoiceType = {
  readonly __typename: 'IntegrationInvoiceType'
  readonly hasAutoNumberingEnabled: Scalars['Boolean']['output']
  readonly invoiceType: Scalars['String']['output']
}

export type IntegrationOnePasswordItem = {
  readonly __typename: 'IntegrationOnePasswordItem'
  readonly itemId: Scalars['ID']['output']
  readonly title: Scalars['String']['output']
  readonly vaultId: Scalars['ID']['output']
}

export type IntegrationProjectDetails = {
  readonly __typename: 'IntegrationProjectDetails'
  readonly integrationProjectId: Scalars['String']['output']
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly projectAddress: Maybe<DraftLocation>
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Maybe<Scalars['String']['output']>
  /** The Siteline User IDs of users who should be added to the project as lead PM */
  readonly sitelineLeadPMIds: Maybe<ReadonlyArray<Scalars['String']['output']>>
}

export type IntegrationProjectSummary = {
  readonly __typename: 'IntegrationProjectSummary'
  readonly contractName: Maybe<Scalars['String']['output']>
  readonly generalContractorName: Maybe<Scalars['String']['output']>
  readonly integrationAssociatedCompanyId: Maybe<Scalars['String']['output']>
  /**
   * "
   * If a contract ID is not provided, the integration may not require contracts for every job and
   * we will still allow onboarding the project with the information we have
   */
  readonly integrationContractId: Maybe<Scalars['String']['output']>
  readonly integrationProjectId: Scalars['String']['output']
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Maybe<Scalars['String']['output']>
  readonly sitelineContractId: Maybe<Scalars['String']['output']>
  /** Provided if already onboarded to Siteline */
  readonly sitelineProjectId: Maybe<Scalars['String']['output']>
}

export type IntegrationSov = {
  readonly __typename: 'IntegrationSov'
  readonly lineItems: ReadonlyArray<IntegrationSovLineItem>
}

export type IntegrationSovLineItem = {
  readonly __typename: 'IntegrationSovLineItem'
  /** Total value in cents billed to date */
  readonly billedToDate: Scalars['Float']['output']
  readonly code: Scalars['String']['output']
  readonly description: Scalars['String']['output']
  /** Could be null if the integration doesn't have IDs */
  readonly integrationLineItemId: Maybe<Scalars['String']['output']>
  /** Total value of retention in cents to date */
  readonly retentionToDate: Scalars['Float']['output']
  readonly scheduledValue: Scalars['Float']['output']
  /** Total unit price in cents, same as scheduledValue if not a unit price contract */
  readonly unitPrice: Scalars['Decimal']['output']
}

/** A sync attempt for a single contract integration. */
export type IntegrationSync = {
  readonly __typename: 'IntegrationSync'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: Maybe<User>
  /** Only provided if status == GLOBAL_ERROR. */
  readonly error: Maybe<Scalars['String']['output']>
  readonly id: Scalars['ID']['output']
  readonly integration: Integration
  /**
   * List of entities to sync, and their sync status.
   * If status == GLOBAL_ERROR, `error` is provided and all requests have status == NOT_PROCESSED.
   * If status == REQUEST_ERRORS, at least one request has status == ERROR.
   * If status == SUCCESS, all requests have status == SUCCESS.
   */
  readonly requests: ReadonlyArray<IntegrationSyncRequest>
  readonly resultCode: IntegrationSyncResultCode
  readonly status: IntegrationSyncStatus
}

/** One sync can have multiple requests, which are entities to be synced to the 3rd party service */
export type IntegrationSyncRequest = {
  readonly __typename: 'IntegrationSyncRequest'
  readonly createdAt: Scalars['DateTime']['output']
  /** Only provided when status == ERROR */
  readonly error: Maybe<Scalars['String']['output']>
  readonly id: Scalars['ID']['output']
  readonly integrationSync: IntegrationSync
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  readonly legalRequirement: Maybe<LegalRequirement>
  readonly lienWaiver: Maybe<LienWaiver>
  readonly name: Scalars['String']['output']
  /** Entities that are being synced. Provided depending on type field. */
  readonly payApp: Maybe<PayApp>
  readonly resultCode: IntegrationSyncResultCode
  readonly status: IntegrationSyncRequestStatus
  readonly type: IntegrationSyncRequestType
}

export { IntegrationSyncRequestStatus }

export { IntegrationSyncRequestType }

export { IntegrationSyncResultCode }

export { IntegrationSyncStatus }

export type IntegrationTaxGroup = {
  readonly __typename: 'IntegrationTaxGroup'
  readonly integrationTaxGroupId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
  /** Tax percentage for this group, between 0 and 1 */
  readonly taxPercent: Scalars['Float']['output']
}

export { IntegrationType }

export type IntegrationVendor = {
  readonly __typename: 'IntegrationVendor'
  readonly address: Maybe<Scalars['String']['output']>
  readonly contacts: ReadonlyArray<IntegrationVendorContact>
  readonly integrationVendorId: Scalars['String']['output']
  readonly name: Scalars['String']['output']
}

export type IntegrationVendorContact = {
  readonly __typename: 'IntegrationVendorContact'
  readonly email: Maybe<Scalars['EmailAddress']['output']>
  readonly integrationVendorContactId: Scalars['String']['output']
  readonly name: Maybe<Scalars['String']['output']>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly title: Maybe<Scalars['String']['output']>
}

export type IntegrationVendorInvoice = {
  readonly __typename: 'IntegrationVendorInvoice'
  readonly amount: Scalars['Float']['output']
  /** May be a partial amount if the full invoice has not been paid */
  readonly amountPaid: Scalars['Float']['output']
  readonly code: Scalars['String']['output']
  readonly description: Scalars['String']['output']
  readonly integrationInvoiceId: Scalars['String']['output']
  readonly integrationVendorCode: Scalars['String']['output']
  readonly integrationVendorId: Scalars['String']['output']
  /** Format is YYYY-MM-DD */
  readonly invoiceDate: Scalars['String']['output']
  readonly isPaid: Scalars['Boolean']['output']
  /** Format is YYYY-MM-DD */
  readonly paymentDate: Maybe<Scalars['String']['output']>
  readonly retentionAmount: Scalars['Float']['output']
  /**
   * If a vendor in Siteline (belonging to the current user's company) is linked to this vendor in the
   * integration, this is the vendor's Siteline ID
   */
  readonly sitelineVendorId: Maybe<Scalars['ID']['output']>
  readonly vendorName: Scalars['String']['output']
}

export type InviteCompanyUserInput = {
  readonly companyId: Scalars['String']['input']
  readonly companyUser: CompanyUserInput
}

export type LatestPayAppInput = {
  /** Returns the latest pay app for the given contract. */
  readonly contractId: Scalars['ID']['input']
  /** If provided, only checks pay apps that are older than the given date. */
  readonly maxMonth?: InputMaybe<Scalars['DateTime']['input']>
}

/** Represents a document for a legal requirement that can be accessed by a URL */
export type LegalDocument = {
  readonly __typename: 'LegalDocument'
  readonly createdAt: Scalars['DateTime']['output']
  /** A list of each time the document has been sent. */
  readonly documentSentLog: Maybe<ReadonlyArray<DocumentSendLog>>
  /** Files can be uploaded by users or by lower-tier subs, who are not users */
  readonly file: Maybe<StoredFile>
  /**
   * Version of a template that this document must be rendered with. If null, the legal requirement
   * form template will be used instead. This cannot be provided if isUploaded is true.
   */
  readonly formTemplateVersion: Maybe<FormTemplateVersion>
  /** A list of all user-entered values for the form on the LegalRequirement */
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  readonly id: Scalars['ID']['output']
  readonly isUploaded: Scalars['Boolean']['output']
  /** The time that this document was last sent in an email, if relevant */
  readonly lastSendTime: Maybe<Scalars['DateTime']['output']>
  /** The last write sync for this entity. Null if not successful */
  readonly lastSyncIfSuccessful: Maybe<WriteSyncOperation>
  /** All requests that exist for this legal document */
  readonly legalDocumentRequests: ReadonlyArray<LegalDocumentRequest>
  readonly legalRequirement: LegalRequirement
  readonly name: Scalars['String']['output']
  /**
   * This represents the end of the recurring period or the user-input expiration
   * date. This will be set unless the expiration frequency is set to NEVER.
   */
  readonly periodEnd: Maybe<Scalars['DateTime']['output']>
  /** For recurring documents, this is the start of the recurring period. */
  readonly periodStart: Maybe<Scalars['DateTime']['output']>
  /** Signature of the user who signed. This is also used for when the signature was added. */
  readonly signature: Maybe<Signature>
  readonly updatedAt: Scalars['DateTime']['output']
  /** Files can be uploaded by users or by lower-tier subs, who are not users */
  readonly uploadedBy: Maybe<User>
  /** If this legal document is for a vendor legal requirement, the vendor contract will be specified here. */
  readonly vendorContract: Maybe<VendorContract>
}

/** Request of a legal document from a vendor contact. */
export type LegalDocumentRequest = {
  readonly __typename: 'LegalDocumentRequest'
  readonly actionTakenAt: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly legalDocument: LegalDocument
  readonly legalRequirement: LegalRequirement
  /** List of users who have created or modified this legal document request. */
  readonly modifiedBy: ReadonlyArray<User>
  readonly status: DocumentRequestStatus
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContact: VendorContact
  readonly vendorContract: VendorContract
}

/** Represents a single legal requirement for a project */
export type LegalRequirement = {
  readonly __typename: 'LegalRequirement'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly documents: ReadonlyArray<LegalDocument>
  /** [Optional] The last date to get a recurring requirement. */
  readonly endDate: Maybe<Scalars['DateTime']['output']>
  readonly expirationFrequency: DocumentExpirationFrequency
  /** An optional template that can be filled out on Siteline */
  readonly formTemplate: Maybe<FormTemplate>
  readonly id: Scalars['ID']['output']
  /** Whether this is a requirement from the subcontractor to lower-tier vendors */
  readonly isVendorRequirement: Scalars['Boolean']['output']
  /** Fields missing that are required to generate this legal requirement's forms */
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly name: Scalars['String']['output']
  readonly skippedPeriods: ReadonlyArray<Scalars['String']['output']>
  /** The first date for a recurring requirement. */
  readonly startDate: Maybe<Scalars['DateTime']['output']>
  readonly timeZone: Scalars['String']['output']
  /** Type of legal document */
  readonly type: DocumentType
  readonly updatedAt: Scalars['DateTime']['output']
  /** For vendor legal requirements, a blank form template may be provided for the vendor to download */
  readonly vendorTemplate: Maybe<StoredFile>
}

/** Represents a single lien waiver on a pay app. */
export type LienWaiver = {
  readonly __typename: 'LienWaiver'
  /** Actual check amount entered on a vendor lien waiver form. */
  readonly amount: Maybe<Scalars['SafeInt']['output']>
  /**
   * This is intentionally a string as the claimant could be a sub-sub who is not a user of our system.
   * We will auto-populate with the subcontractor information but if this is a sub-sub, we will ask
   * them to type in their legal name.
   */
  readonly claimant: Scalars['String']['output']
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly customer: Scalars['String']['output']
  readonly date: Scalars['DateTime']['output']
  /**
   * Default check amount to fill lower-tier lien waivers with.
   * We use these values to populate the LIEN_WAIVER_AMOUNT form annotation values.
   * The value of this annotation depends on whehether the amount / text / both are provided by the sub.
   * This is irrelevant for primary lien waivers.
   */
  readonly defaultAmount: Maybe<Scalars['SafeInt']['output']>
  readonly defaultAmountText: Maybe<Scalars['String']['output']>
  /** Default exceptions to fill lower-tier lien waivers with. This is irrelevant for primary lien waivers. */
  readonly defaultExceptionAmounts: Maybe<Scalars['String']['output']>
  readonly defaultExceptionDates: Maybe<Scalars['String']['output']>
  /**
   * Default vendor contacts to send lien waiver requests to. We allow saving the default list so users
   * can edit and persist the recipients prior to sending the requests. If empty, the frontend will
   * defer to `hasSelectedDefaultVendorContacts`: if that field is true, the frontend will show no
   * recipients, and if that field is false, it will show a default list of recipients based on the
   * vendor contract and past lien waivers.
   */
  readonly defaultVendorContacts: ReadonlyArray<VendorContact>
  /** Form template variant that is used to generate the lien waiver. Can be null if lien waiver is uploaded manually. */
  readonly formTemplateVariant: Maybe<FormTemplateVariant>
  /** Form template version that is used to generate the lien waiver. Can be null if lien waiver is uploaded manually. */
  readonly formTemplateVersion: Maybe<FormTemplateVersion>
  /** A list of all user-entered values for this lien waiver. */
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  /**
   * See above. This field is necessary because we can't distinguish between `defaultVendorContracts`
   * being unset vs empty (since null and an empty array are indistinguishable for @manyToMany fields).
   */
  readonly hasSelectedDefaultVendorContacts: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /** Refers to whether or not a lien waiver is for a joint check */
  readonly isJointCheck: Scalars['Boolean']['output']
  readonly jobLocation: Location
  /** The time that this document was last sent in an email. */
  readonly lastSendTime: Maybe<Scalars['DateTime']['output']>
  /** The last write sync for this entity. Null if not successful */
  readonly lastSyncIfSuccessful: Maybe<WriteSyncOperation>
  /** List of LienWaiverRequests from lower-tier companies */
  readonly lienWaiverRequests: ReadonlyArray<LienWaiverRequest>
  /**
   * Name of who makes the check. Generally this is the same as the customer, but for vendor joint
   * checks, this will be the general contractor instead of the subcontractor.
   */
  readonly makerOfCheck: Scalars['String']['output']
  /** A note the subcontractor writes to include the email request body */
  readonly message: Maybe<Scalars['String']['output']>
  readonly owner: Scalars['String']['output']
  readonly payApps: ReadonlyArray<PayApp>
  /**
   * Name of who the check is made to. Generally this is the same as the claimant, but for vendor
   * joint checks, this will be both the subcontractor and the vendor instead of just the vendor.
   */
  readonly payableTo: Scalars['String']['output']
  /** Includes information about the physical signatures required on this lien waiver. */
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly preSitelinePayApp: Maybe<PreSitelinePayApp>
  /** File(s) that will be attached to vendor requests for this lien waiver */
  readonly requestAttachments: ReadonlyArray<StoredFile>
  /** A list of each time the signed lien waiver has been sent. */
  readonly sentLog: ReadonlyArray<LienWaiverSendLog>
  readonly signature: Maybe<Signature>
  /** The email subject of the latest lien waiver request */
  readonly subject: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
  readonly type: LienWaiverType
  readonly updatedAt: Scalars['DateTime']['output']
  /** If provided, we'll use this file instead of generating it on the fly */
  readonly uploadedFile: Maybe<StoredFile>
  /**
   * VendorContract is optional because a LW can be for a primary subcontractor or for a lower-tier. Only the
   * lower-tier is associated with a VendorContract.
   */
  readonly vendorContract: Maybe<VendorContract>
}

export { LienWaiverCategory }

export type LienWaiverFormTemplateInput = {
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly signature: SignatureInput
}

/** Represents a request to have a Lien Waiver signed by a lower-tier company. */
export type LienWaiverRequest = {
  readonly __typename: 'LienWaiverRequest'
  /**
   * This date is displayed on the UI when a user checks their request status. We intentionally
   * don't use the updatedAt field because if the LienWaiverRequest document metadata isn't modified
   * by a user-driven action then we wouldn't want the date to change. Therefore we explicitly update
   * the actionTakenAt field.
   */
  readonly actionTakenAt: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lienWaiver: LienWaiver
  readonly modifiedBy: ReadonlyArray<User>
  /** The most recent user to send this request */
  readonly requestedBy: Maybe<User>
  readonly status: DocumentRequestStatus
  readonly statusLogs: ReadonlyArray<LienWaiverRequestStatusLog>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContact: VendorContact
}

export type LienWaiverRequestAttachment = {
  /** If this is a new attachment, send the file to be uploaded */
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  /** If this attachment has already been uploaded as a StoredFile, send the ID so we don't re-upload it */
  readonly uploadId?: InputMaybe<Scalars['String']['input']>
}

export type LienWaiverRequestData = {
  readonly __typename: 'LienWaiverRequestData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly lienWaiversRequested: Scalars['SafeInt']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

/** Represents a log of all statuses for a lien waiver request  and when the transition occurred. */
export type LienWaiverRequestStatusLog = {
  readonly __typename: 'LienWaiverRequestStatusLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly lienWaiverRequest: LienWaiverRequest
  readonly status: DocumentRequestStatus
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  /** Person who made the change. If no user or contact is provided, change was made by the system. */
  readonly user: Maybe<User>
  /** Vendor contact who made the change. If no user or contact is provided, change was made by the system. */
  readonly vendorContact: Maybe<VendorContact>
}

export type LienWaiverRequestWithToken = {
  readonly __typename: 'LienWaiverRequestWithToken'
  readonly lienWaiverRequest: LienWaiverRequest
  readonly token: Scalars['String']['output']
}

/**
 * Represents a single send action of a lien waiver to a contact at a GC. This will be updated any time
 * a lien waiver is sent as part of a PayApp package or independently outside of it.
 */
export type LienWaiverSendLog = {
  readonly __typename: 'LienWaiverSendLog'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lienWaiver: LienWaiver
  readonly recipients: ReadonlyArray<CompanyContact>
  readonly sender: User
}

export { LienWaiverType }

export type LienWaiverVendorContractsInput = {
  readonly contractId?: InputMaybe<Scalars['ID']['input']>
  readonly vendorId?: InputMaybe<Scalars['ID']['input']>
}

/** DEPRECATED */
export type LienWaiversByMonth = {
  readonly __typename: 'LienWaiversByMonth'
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /**
   * This is the month that the lien waiver through date corresponds to
   * in the timezone of the lien waiver's project
   */
  readonly month: Scalars['SafeInt']['output']
  readonly year: Scalars['SafeInt']['output']
}

/** DEPRECATED */
export type LienWaiversByProject = {
  readonly __typename: 'LienWaiversByProject'
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly lowerTierTo: Maybe<LowerTierToVendorContract>
  readonly projectId: Scalars['ID']['output']
  readonly projectName: Scalars['ID']['output']
  readonly projectNumber: Scalars['String']['output']
  /** Checks if this month was skipped for the given project */
  readonly skippedMonth: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly vendorContractId: Scalars['ID']['output']
}

/** DEPRECATED */
export type LienWaiversByVendorContract = {
  readonly __typename: 'LienWaiversByVendorContract'
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly lowerTierTo: Maybe<LowerTierToVendorContract>
  readonly vendorContractId: Scalars['ID']['output']
  readonly vendorId: Scalars['String']['output']
  readonly vendorName: Scalars['String']['output']
}

/**
 * The data for a single cell in the vendor lien waivers tracker, corresponding to a vendor contract
 * for a single month in a given category. There may be zero or one lien waiver for that month in the
 * category, and we also return whether the final lien waiver was previously collected.
 */
export type LienWaiversMonth = {
  readonly __typename: 'LienWaiversMonth'
  /** True if a final LW was requested in a prior month and no LWs have been created since */
  readonly hasCollectedFinalLienWaiver: Scalars['Boolean']['output']
  readonly lienWaiver: Maybe<LienWaiver>
}

export type LienWaiversMonthInput = {
  readonly category: LienWaiverCategory
  readonly month: Scalars['SafeInt']['input']
  readonly vendorContractId: Scalars['ID']['input']
  readonly year: Scalars['SafeInt']['input']
}

/**
 * A summary of lien waivers for tracking in a given month. Depending on the query, this may correspond
 * to a contract or a vendor, and will encompass lien waivers in a certain category.
 */
export type LienWaiversMonthSummary = {
  readonly __typename: 'LienWaiversMonthSummary'
  readonly aggregateStatus: AggregateLienWaiverStatus
  /** True if the given month was skipped for billing and no lien waivers exist */
  readonly isMonthSkipped: Scalars['Boolean']['output']
  /** Total number of lien waivers */
  readonly numLienWaivers: Scalars['Int']['output']
  /** The number of lien waivers that have not yet been signed */
  readonly numPendingLienWaivers: Scalars['Int']['output']
}

export type LienWaiversMonthSummaryInput = {
  readonly category: LienWaiverCategory
  /** Either contractId or vendorId must be provided */
  readonly contractId?: InputMaybe<Scalars['ID']['input']>
  readonly month: Scalars['SafeInt']['input']
  readonly vendorId?: InputMaybe<Scalars['ID']['input']>
  readonly year: Scalars['SafeInt']['input']
}

export type LineItemAddition = {
  readonly __typename: 'LineItemAddition'
  readonly new: SovChangeSetCreateLineItem
}

export type LineItemAdditionInput = {
  readonly new: SovChangeSetCreateLineItemInput
}

export type LineItemDeletion = {
  readonly __typename: 'LineItemDeletion'
  readonly oldId: Scalars['ID']['output']
}

export type LineItemDeletionInput = {
  readonly oldId: Scalars['String']['input']
}

export type LineItemGroupAddition = {
  readonly __typename: 'LineItemGroupAddition'
  readonly new: SovChangeSetCreateLineItemGroup
  readonly newId: Scalars['ID']['output']
}

export type LineItemGroupAdditionInput = {
  readonly new: SovChangeSetCreateLineItemGroupInput
  readonly newId: Scalars['ID']['input']
}

export type LineItemGroupDeletion = {
  readonly __typename: 'LineItemGroupDeletion'
  readonly oldId: Scalars['ID']['output']
}

export type LineItemGroupDeletionInput = {
  readonly oldId: Scalars['ID']['input']
}

export type LineItemGroupUpdate = {
  readonly __typename: 'LineItemGroupUpdate'
  readonly new: SovChangeSetUpdateLineItemGroup
  readonly oldId: Scalars['ID']['output']
}

export type LineItemGroupUpdateInput = {
  readonly new: SovChangeSetUpdateLineItemGroupInput
  readonly oldId: Scalars['ID']['input']
}

/**
 * Similar to `ProjectMetrics`, but per SOV line item or amount line item on a contract. Every field
 * should be provided for lump sum and unit price projects but only amountBilledAndDraftThisMonth will
 * be provided for quick bills (which is why its the only required field), and only
 * amountBilledAndDraftThisMonth and completedThruMonth will be provided for T&M line items.
 */
export type LineItemMetrics = {
  readonly __typename: 'LineItemMetrics'
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  readonly balanceToFinish: Maybe<Scalars['SafeInt']['output']>
  readonly completedThruMonth: Maybe<Scalars['SafeInt']['output']>
  /** The name for SOV line items or the description for quick bill amount line items */
  readonly name: Scalars['String']['output']
  /** The code for SOV line items or the sort order for quick bill amount line items */
  readonly number: Scalars['String']['output']
  readonly percentCompletedThruMonth: Maybe<Scalars['Float']['output']>
  readonly totalValue: Maybe<Scalars['SafeInt']['output']>
}

export type LineItemSortOrderInput = {
  readonly lineItemId: Scalars['String']['input']
  readonly sortOrder: Scalars['SafeInt']['input']
}

export { LineItemType }

export type LineItemUpdate = {
  readonly __typename: 'LineItemUpdate'
  readonly new: SovChangeSetUpdateLineItem
  readonly oldId: Scalars['ID']['output']
}

export type LineItemUpdateInput = {
  readonly new: SovChangeSetUpdateLineItemInput
  readonly oldId: Scalars['String']['input']
}

export type LinkAmountLineItemToAttachmentInput = {
  readonly attachmentId: Scalars['ID']['input']
  readonly lineItemId: Scalars['ID']['input']
}

export type LinkChangeOrderRequestToSovInput = {
  readonly id: Scalars['ID']['input']
  readonly sovLineItemIds: ReadonlyArray<Scalars['ID']['input']>
}

/** Represents a location in the real world. Currently used by a company or a project. */
export type Location = {
  readonly __typename: 'Location'
  readonly city: Scalars['String']['output']
  readonly coordinates: Scalars['LatLng']['output']
  readonly country: Scalars['String']['output']
  readonly county: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** Optional nickname for this location. */
  readonly nickname: Maybe<Scalars['String']['output']>
  readonly postalCode: Maybe<Scalars['String']['output']>
  readonly state: Scalars['String']['output']
  readonly street1: Maybe<Scalars['String']['output']>
  readonly street2: Maybe<Scalars['String']['output']>
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type LocationInput = {
  readonly city: Scalars['String']['input']
  readonly coordinates: Scalars['LatLng']['input']
  readonly country: Scalars['String']['input']
  readonly county?: InputMaybe<Scalars['String']['input']>
  readonly nickname?: InputMaybe<Scalars['String']['input']>
  readonly postalCode?: InputMaybe<Scalars['String']['input']>
  readonly state: Scalars['String']['input']
  readonly street1?: InputMaybe<Scalars['String']['input']>
  readonly street2?: InputMaybe<Scalars['String']['input']>
}

export type LowerTierLienWaiverPath = {
  readonly lienWaiverRequestId: Scalars['ID']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type LowerTierToVendorContract = {
  readonly __typename: 'LowerTierToVendorContract'
  readonly vendorContractId: Scalars['ID']['output']
  readonly vendorId: Scalars['ID']['output']
  readonly vendorName: Scalars['ID']['output']
}

export type LumpSumProjectCompanyInput = {
  /**
   * For new companies, a name and location should be provided. For existing
   * companies, their ID and either a location ID or a new location should be
   *  provided.
   */
  readonly companyId?: InputMaybe<Scalars['ID']['input']>
  readonly companyLocation: LumpSumProjectLocationInput
  readonly companyName?: InputMaybe<Scalars['String']['input']>
}

export type LumpSumProjectContractInput = {
  readonly contractDate?: InputMaybe<Scalars['String']['input']>
  readonly contractNumber?: InputMaybe<Scalars['String']['input']>
  readonly defaultRetentionPercent: Scalars['Float']['input']
  readonly defaultTaxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly internalProjectNumber?: InputMaybe<Scalars['String']['input']>
  readonly paymentTerms?: InputMaybe<Scalars['SafeInt']['input']>
  readonly paymentTermsType?: InputMaybe<ContractPaymentTermsType>
  readonly retentionTrackingLevel: RetentionTrackingLevel
  readonly roundRetention: Scalars['Boolean']['input']
  /** This value is selected by the user from company locations. The ID should correspond to a company location ID */
  readonly selectedAddressId?: InputMaybe<Scalars['ID']['input']>
  readonly taxCalculationType?: InputMaybe<TaxCalculationType>
  readonly vendorNumber?: InputMaybe<Scalars['String']['input']>
}

export type LumpSumProjectInput = {
  readonly architect?: InputMaybe<LumpSumProjectCompanyInput>
  readonly generalContractor: LumpSumProjectCompanyInput
  readonly leadPMIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly location: LocationInput
  readonly name: Scalars['String']['input']
  readonly owner: LumpSumProjectCompanyInput
  readonly pastPayAppCount: Scalars['Int']['input']
  readonly payAppDueOnDayOfMonth: Scalars['Int']['input']
  /** Only for T&M */
  readonly preSitelineBilled?: InputMaybe<Scalars['SafeInt']['input']>
  readonly projectNumber: Scalars['String']['input']
}

export type LumpSumProjectIntegrationInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly integrationAssociatedCompanyId?: InputMaybe<Scalars['String']['input']>
  readonly integrationContractId?: InputMaybe<Scalars['String']['input']>
  /**
   * The ID of the project in the corresponding integration. For file-based integrations, this isn't
   * used and can be set to "file".
   */
  readonly integrationProjectId: Scalars['String']['input']
  readonly integrationTaxGroupId?: InputMaybe<Scalars['String']['input']>
  /** May be provided for certain integrations, such as Quickbooks, to set up project metadata */
  readonly metadata?: InputMaybe<Scalars['JSONObject']['input']>
}

export type LumpSumProjectLocationInput = {
  readonly location?: InputMaybe<LocationInput>
  /**
   * If re-using an existing location, pass in an ID. Else, pass a new location.
   * If neither an ID nor a location are provided, the location will be cleared.
   */
  readonly locationId?: InputMaybe<Scalars['ID']['input']>
}

export type LumpSumProjectSovInput = {}

export type MarkChangeOrderRequestAuthorizedToProceedInput = {
  readonly id: Scalars['ID']['input']
}

export type MarkChangeOrderRequestSubmittedInput = {
  readonly id: Scalars['ID']['input']
  readonly proposedAmount: Scalars['SafeInt']['input']
  readonly proposedAt: Scalars['DateTime']['input']
}

export type MarkLienWaiverRequestViewedInput = {
  readonly token: Scalars['String']['input']
}

export type MarkPayAppAsPaidInput = {
  /** Amount paid by GC, as reported by the customer, in cents. Automatically set if read from ERP. */
  readonly amountPaid?: InputMaybe<Scalars['SafeInt']['input']>
  readonly isBalanceManuallyClosed?: InputMaybe<Scalars['Boolean']['input']>
  readonly paidAt: Scalars['DateTime']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type MarkPayAppAsSubmittedInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly submittedAt: Scalars['DateTime']['input']
}

export type MarkPayAppAsSyncedInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly syncedAt: Scalars['DateTime']['input']
}

/** A company linked to a form template as the requesting company */
export type MinimalFormTurnaroundCompany = {
  readonly __typename: 'MinimalFormTurnaroundCompany'
  readonly id: Scalars['ID']['output']
  readonly name: Scalars['String']['output']
}

export type MinimalFormTurnaroundFormTemplate = {
  readonly __typename: 'MinimalFormTurnaroundFormTemplate'
  readonly builderId: Maybe<Scalars['ID']['output']>
  readonly id: Scalars['ID']['output']
  readonly ownerId: Maybe<Scalars['ID']['output']>
  readonly requestingCompanyId: Maybe<Scalars['ID']['output']>
  readonly userVisibleName: Scalars['String']['output']
  readonly validatorId: Maybe<Scalars['ID']['output']>
}

/** A user attached to a form template, either as owner, validator, or builder */
export type MinimalFormTurnaroundUser = {
  readonly __typename: 'MinimalFormTurnaroundUser'
  readonly firstName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  readonly lastName: Scalars['String']['output']
}

export type ModifyEmailPreferencesInput = {
  readonly block: Scalars['Boolean']['input']
  readonly emailTypes: ReadonlyArray<EmailType>
}

export type ModifyNotificationPreferencesInput = {
  readonly block: Scalars['Boolean']['input']
  readonly notificationTypes: ReadonlyArray<NotificationType>
}

/** Breakdown of amount billed and amount billed plus draft across all projects in a single month */
export type MonthlyBillingBreakdown = {
  readonly __typename: 'MonthlyBillingBreakdown'
  /**
   * Amount of progress billed across all projects that have pay apps submitted or in draft in a
   * single month. Does not include retention billed and does not subtract retention held.
   */
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  /**
   * Amount of progress billed across all projects in a single month, excluding projects in draft. Does
   * not include retention billed and does not subtract retention held.
   */
  readonly amountBilledThisMonth: Scalars['SafeInt']['output']
  readonly month: Scalars['DateTime']['output']
}

export type Mutation = {
  readonly __typename: 'Mutation'
  /** Adds a comment on a change order request and tags the relevant parties */
  readonly addChangeOrderRequestComment: ChangeOrderRequestComment
  /** Adds a change order request to an SOV */
  readonly addChangeOrderRequestToSov: ChangeOrderRequest
  /** Add a new comment on a contract */
  readonly addContractComment: ContractComment
  /** Adds file(s) to a contract */
  readonly addContractFiles: Contract
  /** Adds a CompanyContact to email for a ChangeOrderRequest */
  readonly addGeneralContractorContactToChangeOrderRequest: ChangeOrderRequest
  /** Adds a CompanyContact to legalRequirementContacts, the list of contacts to be emailed legal requirement docs. */
  readonly addGeneralContractorContactToLegalRequirementContacts: Contract
  /** Adds a CompanyContact to contactsEmailed, the list of contacts to be emailed the pay app. */
  readonly addGeneralContractorContactToPayApp: PayApp
  /** Adds a CompanyContact to vendorSubmitToContacts, used in the vendors module */
  readonly addGeneralContractorContactToVendorSubmitToContacts: Contract
  /** Adds an integration to a contract. */
  readonly addIntegrationToContract: Contract
  /** Adds vendors to a project from an integration, creating vendors as needed in the process */
  readonly addIntegrationVendorsToContract: Contract
  /** Adds multiple legal documents to a pay app as backup. */
  readonly addLegalDocumentsToPayApp: PayApp
  /** Adds a legal requirement to a sub's compliance requirements */
  readonly addLegalRequirement: LegalRequirement
  /** Adds a comment on a pay app and tags the relevant parties */
  readonly addPayAppComment: PayAppComment
  /** Adds a tutorial to the list of tutorials shown for a user */
  readonly addUserTutorialShown: User
  /** Add a vendor legal requirement */
  readonly addVendorLegalRequirement: LegalRequirement
  /** Adds multiple vendor lien waivers to a pay app as backup. */
  readonly addVendorLienWaiversToPayApp: PayApp
  /** Assigns new vendors to a given project, creating vendor contracts for each vendor */
  readonly addVendorsToProject: ReadonlyArray<VendorContract>
  /** Approves a Change Order Request */
  readonly approveChangeOrderRequest: ChangeOrderRequest
  /** Archives all notifications for the current user. */
  readonly archiveAllNotifications: Scalars['Boolean']['output']
  /** Archives an active contract */
  readonly archiveContract: Project
  /** Archives a single notification for the user. */
  readonly archiveNotification: Notification
  /** Archives a rate table. Use this when there are contracts using the rate table. */
  readonly archiveRateTable: RateTable
  /**
   * Send lien waiver request emails for the given lien waivers. Each lien waiver input includes a list
   * of contacts to send the lien waiver request to. If a request already exists for a contact, the
   * existing request will be used; otherwise, a new request will be created.
   */
  readonly batchSendLienWaiverRequests: ReadonlyArray<LienWaiver>
  /** Accept all predicted payment dates on all pay apps associated with a company */
  readonly bulkAddPredictedPaymentDates: ReadonlyArray<PayApp>
  /** Update cost information for multiple contracts */
  readonly bulkUpdateContractCosts: ReadonlyArray<Contract>
  /** Clear the change order log forms for a project */
  readonly clearChangeOrderLogForms: Contract
  /** Clear the change order request forms for a project */
  readonly clearChangeOrderRequestForms: Contract
  /** Resets any user-entered pricing on a change order request's rate table */
  readonly clearChangeOrderRequestPricing: ChangeOrderRequest
  /** Clears all contract cost information on a contract */
  readonly clearContractCost: Contract
  /** Clears form annotation values on a draft pay app */
  readonly clearPayAppFormValues: PayApp
  /** Clear the current pay app forms for a project */
  readonly clearPayAppForms: Contract
  /** Clear the primary lien waiver forms for a project */
  readonly clearPrimaryLienWaiverForms: Contract
  /** Removes an unconditional lien waiver. Used to clear form values or remove an uploaded file. */
  readonly clearUnconditionalLienWaiver: DeletionResult
  /** Removes an unconditional lien waiver. Used to clear form values or remove an uploaded file. */
  readonly clearUnconditionalLienWaiverForPreSitelinePayApp: PreSitelinePayApp
  /** Clear the vendor lien waiver forms for a project */
  readonly clearVendorLienWaiverForms: Contract
  /** Copys legal requirements from one project to another */
  readonly copyContractLegalRequirements: Contract
  /** Copys vendors from one project to another */
  readonly copyContractVendors: Contract
  /**
   * Creates an amount line item.
   *  - This does not apply to LUMP_SUM or UNIT_PRICE pay apps.
   *  - For QUICK pay apps, amount line items must have type ADDITIONAL and a 0% fee.
   */
  readonly createAmountLineItem: AmountLineItem
  /** Creates a change order. */
  readonly createChangeOrder: SovLineItemProgress
  /** Creates a Change Order Request */
  readonly createChangeOrderRequest: ChangeOrderRequest
  /** Creates a new change order request attachment */
  readonly createChangeOrderRequestAttachment: ChangeOrderRequestAttachment
  /** Adds a one-off rate line item on a change order request, not tied to a rate table */
  readonly createChangeOrderRequestRateTableItem: ChangeOrderRequestRateTableItem
  /** Creates multiple Change Order Requests from an uploaded log */
  readonly createChangeOrderRequestsFromLog: ReadonlyArray<ChangeOrderRequest>
  /** Creates a company contact. If the contact already exists, it is returned without error. */
  readonly createCompanyContact: CompanyContact
  readonly createContractBillingForecast: Contract
  /** Create a new rate table fee on a contract */
  readonly createContractRateTableFee: ContractRateTableFee
  /** Creates a new integration */
  readonly createIntegration: Integration
  /** Creates a new location in the database, specific to a company. */
  readonly createLocation: Location
  /** Creates lump sum or unit price project, blank sov, and contract. Also creates gc/owner/architect if needed */
  readonly createMonthlyBillingProject: Project
  /** Creates a new PayApp for a customer. */
  readonly createPayApp: PayApp
  /** Creates a new PayAppAttachment for a given PayApp. */
  readonly createPayAppAttachment: PayAppAttachment
  /** Adds a one-off rate line item on a T&M pay app, not tied to a rate table */
  readonly createPayAppRateTableItem: PayAppRateTableItem
  /** Creates a pre-siteline pay app */
  readonly createPreSitelinePayApp: PreSitelinePayApp
  /** Creates operations for projects to be imported asynchronously */
  readonly createProjectImportOperations: ReadonlyArray<ProjectImportOperation>
  /** Creates a quick billing project */
  readonly createQuickBillingProject: Project
  /** Creates a new rate table */
  readonly createRateTable: RateTable
  /** Creates a new SOV line item group */
  readonly createSovLineItemGroup: SovLineItemGroup
  /** Creates a company tax group */
  readonly createTaxGroup: TaxGroup
  /** Creates a vendor */
  readonly createVendor: Vendor
  /** Creates a vendor contact */
  readonly createVendorContact: VendorContact
  /** Creates an open commitment on a vendor contract */
  readonly createVendorContractCommitment: VendorContractCommitment
  /** Creates a vendor LienWaiver */
  readonly createVendorLienWaiver: LienWaiver
  /** Creates/links multiple vendors */
  readonly createVendors: ReadonlyArray<Vendor>
  /** Queues a new write sync operation and immediately returns it for polling. */
  readonly createWriteSyncOperation: WriteSyncOperation
  /** Suspends a user by removing their access and disabling their account */
  readonly deactivateUser: User
  /** Deletes an amount line item from a pay app */
  readonly deleteAmountLineItem: DeletionResult
  /** Deletes a change order. */
  readonly deleteChangeOrder: PayApp
  /** Deletes a Change Order Request */
  readonly deleteChangeOrderRequest: DeletionResult
  /** Deletes a change order request attachment */
  readonly deleteChangeOrderRequestAttachment: DeletionResult
  /** Deletes a one-off rate line item on a change order request. Cannot be done for items that are tied to rate tables. */
  readonly deleteChangeOrderRequestRateTableItem: DeletionResult
  /** Deletes a company contact. */
  readonly deleteCompanyContact: DeletionResult
  /** Deletes a contract */
  readonly deleteContract: DeletionResult
  readonly deleteContractBillingForecast: DeletionResult
  /** Deletes a file from a contract */
  readonly deleteContractFile: Contract
  /** Deletes the rate table attached to a contract. There must be no pay app on the contract. */
  readonly deleteContractRateTable: Contract
  /** Deletes a contract rate table fee */
  readonly deleteContractRateTableFee: DeletionResult
  /** Deletes a CompanyContact to email for a ChangeOrderRequest */
  readonly deleteGeneralContractorContactFromChangeOrderRequest: ChangeOrderRequest
  /** Deletes a CompanyContact from legalRequirementContacts, the list of contacts to be emailed legal requirement docs. */
  readonly deleteGeneralContractorContactFromLegalRequirementContacts: Contract
  /** Deletes a CompanyContact from contactsEmailed, the list of contacts to be emailed the pay app. */
  readonly deleteGeneralContractorContactFromPayApp: PayApp
  /** Removes a CompanyContact from vendorSubmitToContacts */
  readonly deleteGeneralContractorContactFromVendorSubmitToContacts: Contract
  /** Deletes an integration from a contract */
  readonly deleteIntegration: DeletionResult
  /** Deletes a legal document. */
  readonly deleteLegalDocument: DeletionResult
  /** Deletes a compliance legal requirement and any uploaded or signed forms */
  readonly deleteLegalRequirement: DeletionResult
  /** Disassociates location from current user's company. */
  readonly deleteLocation: Company
  /** Deletes a PayAppAttachment given its id. */
  readonly deletePayAppAttachment: DeletionResult
  /** Deletes a one-off rate line item on a T&M pay app. Cannot be done for items that are tied to rate tables. */
  readonly deletePayAppRateTableItem: DeletionResult
  /**
   * Removes a vendor from a pay app's sworn statement. This will also remove the vendor from the
   * sworn statement on any other draft pay app on the contract.
   */
  readonly deletePayAppSwornStatementVendor: DeletionResult
  /** Deletes a pre-siteline pay app. */
  readonly deletePreSitelinePayApp: DeletionResult
  /** Deletes the pay app file on a pre-siteline pay app */
  readonly deletePreSitelinePayAppFile: PreSitelinePayApp
  /** Deletes a rate table. Cannot be done if there are contracts using the rate table. */
  readonly deleteRateTable: DeletionResult
  /** Deletes an SOV line item group */
  readonly deleteSovLineItemGroup: DeletionResult
  /** Deletes a vendor */
  readonly deleteVendor: DeletionResult
  /** Archives a vendor contact so they are no longer surfaced to the user as a contact */
  readonly deleteVendorContact: DeletionResult
  /** Deletes a vendor contract and all associated lien waivers and documents */
  readonly deleteVendorContract: DeletionResult
  /** Deletes a commitment */
  readonly deleteVendorContractCommitment: DeletionResult
  /**
   * Deletes a vendor legal requirement if its unused. If it is being used, also marks associated
   * requests as canceled and deletes files. If its attached to any submitted pay apps, prevent
   * deleting.
   */
  readonly deleteVendorLegalRequirement: DeletionResult
  /** Deletes a vendor LienWaiver and any associated LienWaiverRequests */
  readonly deleteVendorLienWaiver: DeletionResult
  /** Mark a user education campaign as dismissed for the current user */
  readonly dismissUserEducationCampaign: UserEducationCampaign
  /** Duplicates a rate table and its items */
  readonly duplicateRateTable: RateTable
  /** Links, creates, or returns an existing Siteline tax group from an integration tax group */
  readonly importIntegrationTaxGroup: Maybe<TaxGroup>
  /** Pulls in the SOV or updates the SOV during onboarding from the given integration. */
  readonly importSovFromIntegration: Sov
  /** Creates a user, associates it with the provided company, and send them an invite email */
  readonly inviteCompanyUser: CompanyUser
  /** Links an amount line item to a pay app attachment (many-to-many) */
  readonly linkAmountLineItemToAttachment: AmountLineItem
  /** Link a change order request to line items already on the SOV */
  readonly linkChangeOrderRequestToSov: ChangeOrderRequest
  /** Marks a change order request authorized to proceed */
  readonly markChangeOrderRequestAuthorizedToProceed: ChangeOrderRequest
  /** Marks a change order request as submitted */
  readonly markChangeOrderRequestSubmitted: ChangeOrderRequest
  /** Using a token as input, marks a lien waiver request as viewed. */
  readonly markLienWaiverRequestViewed: LienWaiverRequest
  /** Given a list of notification ids, mark them all as read. */
  readonly markNotificationsRead: ReadonlyArray<Notification>
  /** Mark pay app as paid */
  readonly markPayAppAsPaid: PayApp
  /** Mark pay app as submitted */
  readonly markPayAppAsSubmitted: PayApp
  /** Mark pay app as synced */
  readonly markPayAppAsSynced: PayApp
  /** Mark a vendor lien waiver as submitted (e.g. if it was uploaded to GCPay) */
  readonly markVendorLienWaiverSubmitted: LienWaiver
  /** Blocks email notifications for a given type. */
  readonly modifyEmailPreferences: User
  /** Blocks push notifications for a given type. */
  readonly modifyNotificationPreferences: User
  /** Opts in to billing for a specific month and project. */
  readonly optInToBillingForMonth: Contract
  /**
   * Opts out of billing for a specific month and project. If an existing pay app is found for that
   * month, then it will be deleted and all progress removed.
   */
  readonly optOutOfBillingForMonth: Contract
  /** Opts out a vendor contact from receiving lien waiver requests. */
  readonly optOutVendorFromDigitalSignatures: VendorContact
  /** Saves partial form annotation values and notifies recipients */
  readonly payAppFormsReadyForReview: PayApp
  /** Creates a monthly billing project based on an async operation ID. */
  readonly processProjectImportOperation: ProjectImportOperation
  /** Reactivates the account for a disabled user */
  readonly reactivateUser: User
  /** Refreshes the vendor mappings for an integration */
  readonly refreshIntegrationVendors: Integration
  /** Reject (delete) the last request and create a new request for a document from a vendor */
  readonly rejectAndRequestLegalDocumentFromVendor: LegalDocument
  /** Rejects a Change Order Request */
  readonly rejectChangeOrderRequest: ChangeOrderRequest
  /** Removes a legal document from a pay app as backup. */
  readonly removeLegalDocumentFromPayApp: PayApp
  /** Removes a pay app requirement from a project */
  readonly removePayAppRequirement: Contract
  /** Removes the specified integrationId off of the vendor mappings */
  readonly removeVendorIntegration: Vendor
  /** Removes the specified integrationIds off of the vendor mappings */
  readonly removeVendorIntegrations: Vendor
  /** Removes a vendor lien waiver from a pay app as backup. */
  readonly removeVendorLienWaiverFromPayApp: PayApp
  /** Request teammates' review on a change order request or COR log */
  readonly requestChangeOrderReview: Maybe<ChangeOrderRequest>
  /** Create a new legal document request from a vendor */
  readonly requestLegalDocumentFromVendor: LegalDocument
  /** Resends an email verification email for a company email alias */
  readonly resendEmailAliasVerification: CompanyUserEmailAlias
  /** Resends an invite email to the specified user */
  readonly resendUserInvite: User
  /** Resets a Change Order Request to DRAFT */
  readonly resetChangeOrderRequestToDraft: ChangeOrderRequest
  /** Resets a change order request using the current contract rate table. This resets all pricing to zero. */
  readonly resetChangeOrderRequestWithContractRateTable: ChangeOrderRequest
  /** Resets a change order request using the current contract fees. Pricing values are preserved. */
  readonly resetChangeOrderRequestWithContractRateTableFees: ChangeOrderRequest
  /** Resets any retention override on a given pay app or line item */
  readonly resetPayAppRetentionHeldOverride: PayApp
  /** Resets any retention released on a pay app or line item back to zero */
  readonly resetPayAppRetentionReleased: PayApp
  /** Resets the status of a pay app to draft and removes signatures. */
  readonly resetPayAppToDraft: PayApp
  /** Resets a pay app using the current contract rate table. This resets all billings to zero. */
  readonly resetPayAppWithContractRateTable: PayApp
  /** Resets a pay app using the current contract fees. Billing values are preserved. */
  readonly resetPayAppWithContractRateTableFees: PayApp
  /**
   * Rounds all SOV Line Item progress billing values to the nearest dollar. Note: this does not round
   * the stored material values because those aren't impacted by the % complete so the user would have
   * directly input those values to the correct rounded value (if it needed to be rounded).
   */
  readonly roundProgressBillings: PayApp
  readonly saveLowerTierLienWaiverFormValues: LienWaiverRequest
  /** Saves partial form annotation values without marking a form as signed */
  readonly savePayAppFormValues: PayApp
  /** Selects pay app, lien waiver, and/or change order request forms for a project */
  readonly selectProjectForms: Contract
  /** Sends a completed LienWaiverRequest to the GC if the pay app was already submitted. */
  readonly sendCompleteLienWaiverRequest: LienWaiverRequest
  /** Sends an email to the legal requirement contacts with an attached pdf of relevant legal documents. */
  readonly sendLegalDocuments: ReadonlyArray<LegalDocument>
  /**
   * Sends a Firebase password reset link to a specific user email.
   * This mutation always returns true, whether the user exists or not, and whether the email is sent
   * successfully of not.
   */
  readonly sendPasswordResetLinkEmail: Scalars['Boolean']['output']
  /**
   * Sends a Firebase sign-in link to a specific user email.
   * The link points to `/processInvite?step=handleSigninLink` and cannot be altered in this mutation
   * for security purposes.
   * This mutation always returns true, whether the user exists or not, and whether the email is sent
   * successfully of not.
   */
  readonly sendSigninLinkAfterInvite: Scalars['Boolean']['output']
  /** Set the amount value for an amount line item */
  readonly setAmountLineItemAmount: AmountLineItem
  /** Set the description of an amount line item */
  readonly setAmountLineItemDescription: AmountLineItem
  /**
   * Set the fee percent value for an amount line item.
   * This must be 0 for QUICK pay apps.
   */
  readonly setAmountLineItemFeePercent: AmountLineItem
  /** Set the tax group for an amount line item. */
  readonly setAmountLineItemTaxGroup: AmountLineItem
  /**
   * Sets the current billed amount on a T&M pay app rate item.
   * Units billed are inferred from this amount.
   */
  readonly setCurrentBilled: PayAppRateTableItem
  /**
   * Sets the current pricing amount on a change order request rate item.
   * Units billed are inferred from this amount.
   */
  readonly setCurrentPricing: ChangeOrderRequestRateTableItem
  /** Updates the setting on a contract to include the change order log in all pay apps */
  readonly setIncludeChangeOrderLogIncludedInPayAppPackage: Contract
  /** Updates the customer for an integration */
  readonly setIntegrationCustomer: Integration
  /** Updates the tax group for an integration */
  readonly setIntegrationTaxGroup: Integration
  /** Sets the sort orders of a QUICK pay-app line items */
  readonly setPayAppLineItemSortOrders: PayApp
  /**
   * Sets the progress billed for a line item progress.
   * This can only be done by subcontractors, and it invalidates any previous review (field or full).
   * Pay-app must be in DRAFT state to perform this operation.
   */
  readonly setProgressBilled: SovLineItemProgress
  /**
   * Sets the stored material billed for a line item progress.
   * This can only be done by subcontractors, and it invalidates any previous review (field or full).
   * Pay-app must be in DRAFT state to perform this operation.
   */
  readonly setStoredMaterialBilled: SovLineItemProgress
  /** Signs and updates the status of a ChangeOrderRequest. */
  readonly signChangeOrderRequest: ChangeOrderRequest
  /** Signs a single legal document that is backed by a form template */
  readonly signLegalDocument: LegalDocument
  /** Signs lower tier lien waiver received from LienWaiverRequest. */
  readonly signLowerTierLienWaiver: LienWaiverRequest
  /** Signs and updates the status of a PayApp on a project. */
  readonly signPayApp: PayApp
  /** Signs an unconditional lien waiver, saving form value inputs or an uploaded file. */
  readonly signUnconditionalLienWaiver: LienWaiver
  /** Signs an unconditional for a pre-siteline pay app */
  readonly signUnconditionalLienWaiverForPreSitelinePayApp: LienWaiver
  /** Skips a period for a legal document */
  readonly skipLegalRequirementPeriod: LegalRequirement
  /** Submits the backup on a pay app. */
  readonly submitBackup: PayApp
  /** Submits a Change Order Request */
  readonly submitChangeOrderRequest: ChangeOrderRequest
  /** Submits a draft pay app. */
  readonly submitDraftPayApp: PayApp
  /**
   * Submits a pay-app for full review.
   * The pay-app state gets updated to PROPOSED, and its full-review status to PENDING.
   */
  readonly submitForFullReview: PayApp
  /** Submits an unconditional lien waiver. */
  readonly submitUnconditionalLienWaiver: LienWaiver
  /** Submits an unconditional from a pre-siteline pay app */
  readonly submitUnconditionalLienWaiverForPreSitelinePayApp: LienWaiver
  /** Submits a vendor LienWaiver */
  readonly submitVendorLienWaivers: ReadonlyArray<LienWaiver>
  /** Changes an archived contract's status to active */
  readonly unarchiveContract: Project
  /** Restores a rate table after archiving */
  readonly unarchiveRateTable: RateTable
  /** Unlinks an amount line item from a pay app attachment (many-to-many) */
  readonly unlinkAmountLineItemFromAttachment: AmountLineItem
  /** Removes a period as skipped for a legal document */
  readonly unskipLegalRequirementPeriod: LegalRequirement
  /** Updates a change order. */
  readonly updateChangeOrder: SovLineItemProgress
  /** Updates a Change Order Request */
  readonly updateChangeOrderRequest: ChangeOrderRequest
  /** Updates the metadata for a change order request attachment */
  readonly updateChangeOrderRequestAttachment: ChangeOrderRequestAttachment
  /**
   * Link a change order request to other chnage order requests. All linked CORs should have the same
   * set of linked SOV line items.
   */
  readonly updateChangeOrderRequestLinkedChangeOrderRequests: ChangeOrderRequest
  /** Updates a one-off rate line item on a change order request. Cannot be done for items that are tied to rate tables. */
  readonly updateChangeOrderRequestRateTableItem: ChangeOrderRequestRateTableItem
  /** Updates an existing company in the database. */
  readonly updateCompany: Company
  /** Updates a company contact */
  readonly updateCompanyContact: CompanyContact
  /** Updates the metadata for a company integration */
  readonly updateCompanyIntegrationMetadata: CompanyIntegration
  /** Updates a company user's permissions, projects, and contact information */
  readonly updateCompanyUser: CompanyUser
  /** Updates metadata about a contract */
  readonly updateContract: Contract
  readonly updateContractBillingForecast: ContractBillingForecast
  /** Updates contract cost information manually */
  readonly updateContractCost: Contract
  /** Reads and stores contract cost information from an integration */
  readonly updateContractCostFromIntegration: Contract
  /** Updates the default lien waiver through date on a contract */
  readonly updateContractLienWaiverThroughDate: Contract
  /** Sets the rate table of a contract. This does not affect existing pay apps. */
  readonly updateContractRateTable: Contract
  /** Updates a contract rate table fee */
  readonly updateContractRateTableFee: ContractRateTableFee
  /** Update users on a contract */
  readonly updateContractUsers: Contract
  /** Updates the metadata on a given integration */
  readonly updateIntegrationMetadata: Integration
  /** Updates the information about a legal document. */
  readonly updateLegalDocument: LegalDocument
  /** Updates metadata or template for a sub's compliance requirement */
  readonly updateLegalRequirement: LegalRequirement
  /** Updates the metadata on a lien waiver */
  readonly updateLienWaiver: LienWaiver
  /** Updates an existing location in the database. User must be a member of company to update. */
  readonly updateLocation: Location
  /**
   * Updates the user's progress within the compliance module project onboarding flow. This method should be called when
   * the user advances from one onboarding step to the next, or when the user completes the onboarding flow.
   */
  readonly updateOnboardedProjectComplianceStatus: Contract
  /**
   * Updates the user's progress within the vendors module project onboarding flow. This method should be called when
   * the user advances from one onboarding step to the next, or when the user completes the onboarding flow.
   */
  readonly updateOnboardedProjectVendorsStatus: Contract
  /** Updates an existing PayApp. */
  readonly updatePayApp: PayApp
  /** Updates an existing PayAppAttachment for a given PayApp. */
  readonly updatePayAppAttachment: PayAppAttachment
  /** Marks a pay app as 'ready' by the sub project manager and notifies others on the project. */
  readonly updatePayAppInvoiceReady: PayApp
  /** Updates the statusChangedAt for the paid status log on a PayApp */
  readonly updatePayAppPaidAt: PayApp
  /** Updates a one-off rate line item on a T&M pay app. Cannot be done for items that are tied to rate tables. */
  readonly updatePayAppRateTableItem: PayAppRateTableItem
  /** Updates the retention held or released on the provided pay app or a specific line item progress. */
  readonly updatePayAppRetention: PayApp
  /** Changes the status of a PayApp. */
  readonly updatePayAppStatus: PayApp
  /** Updates the statusChangedAt for the submitted status log on a PayApp */
  readonly updatePayAppSubmittedAt: PayApp
  /**
   * Given a pay app and vendor in Siteline, update the amounts for the vendor on the pay app's sworn
   * statement. If the vendor does not yet exist on the sworn statement, it will be added. If it does
   * already exist, the corresponding amounts will be updated. This will only add or update vendors
   * on the sworn statements; existing vendors not included in the list will not be affected.
   */
  readonly updatePayAppSwornStatementVendors: PayApp
  /** Updates the statusChangedAt for the synced status log on a PayApp */
  readonly updatePayAppSyncedAt: PayApp
  /** Updates a pre-siteline pay app. You can use this to mark as paid. */
  readonly updatePreSitelinePayApp: PreSitelinePayApp
  /** Updates primary lien waiver templates on a project and when appropriate, updates onboarding status */
  readonly updatePrimaryLienWaiverTemplates: Contract
  /** Updates metadata about a project */
  readonly updateProject: Project
  /** Updates a quick billing project */
  readonly updateQuickBillingProject: Project
  /** Updates the contents of a rate table */
  readonly updateRateTable: RateTable
  /** Updates whether retention is rounded on the contract. */
  readonly updateRoundRetention: Contract
  /**
   * Updates the entire SOV for a project. This includes adding new line items, deleting existing
   * line items, and updating existing line items' names, codes, and total values. Note: this can
   * only be done on the first pay app of the project. The updated sov is returned.
   */
  readonly updateSov: Contract
  /** Updates an SOV line item group */
  readonly updateSovLineItemGroup: SovLineItemGroup
  /** Update an SOV line item's total value from a pay app progress */
  readonly updateSovLineItemTotalValue: SovLineItemProgress
  /** Updates the SOV for a project using the given change set. */
  readonly updateSovWithChangeSet: Sov
  /** Updates how tax is calculated on a contract */
  readonly updateTaxCalculationType: Contract
  /** Updates a company tax group */
  readonly updateTaxGroup: TaxGroup
  /**
   * Updates the upload or expiration of a legal document. This is used for editing
   * documents in the vendors module with an expiration frequency of type USER_INPUT.
   */
  readonly updateUploadedLegalDocument: LegalDocument
  /** Updates an existing user in the database. */
  readonly updateUser: User
  /** Update a user's email aliases for their companies, if they belong to multiple companies */
  readonly updateUserEmailAliases: ReadonlyArray<CompanyUser>
  /** Update a user's primary office company location */
  readonly updateUserOfficeLocation: CompanyUser
  /** Update the name or integration mappings of a vendor */
  readonly updateVendor: Vendor
  /** Update details for a vendor contact */
  readonly updateVendorContact: VendorContact
  /** Update settings for a vendor contract */
  readonly updateVendorContract: VendorContract
  /** Updates a commitment on a vendor contract */
  readonly updateVendorContractCommitment: VendorContractCommitment
  /** Marks a commitment open or closed */
  readonly updateVendorContractCommitmentStatus: VendorContractCommitment
  /** Updates the list of preliminary notices for a vendor contract */
  readonly updateVendorContractPreliminaryNotices: VendorContract
  /** Updates metadata or template for vendor legal requirement */
  readonly updateVendorLegalRequirement: LegalRequirement
  /** Updates lower tier lien waiver templates on a project and when appropriate, updates onboarding status */
  readonly updateVendorLienWaiverTemplates: Contract
  readonly updateWorksheetLineItems: Sov
  readonly updateWorksheetProgressBilled: WorksheetLineItemProgress
  /** Uploads an SCO file for an approved change order request */
  readonly uploadChangeOrderScoFile: ChangeOrderRequest
  /** Uploads form templates for building to be used on the contract */
  readonly uploadFormTemplates: Contract
  /** Vendor contact upload of a legal document via token */
  readonly uploadLegalDocumentWithToken: LegalDocumentRequest
  /** Uploads new document(s) for a legal requirement */
  readonly uploadLegalDocuments: LegalRequirement
  /** Uploads a lien waiver manually. This will be used instead of generating one through Siteline. */
  readonly uploadLienWaiverFile: LienWaiver
  /** Uploads a pay app manually. This will be used instead of generating one through Siteline. */
  readonly uploadPayAppFile: PayApp
  /** Verifies an email address used as an email alias for a certain company */
  readonly verifyEmailAlias: CompanyUserEmailAlias
}

export type MutationAddChangeOrderRequestCommentArgs = {
  input: AddChangeOrderRequestCommentInput
}

export type MutationAddChangeOrderRequestToSovArgs = {
  input: AddChangeOrderRequestToSovInput
}

export type MutationAddContractCommentArgs = {
  input: AddContractCommentInput
}

export type MutationAddContractFilesArgs = {
  input: AddContractFileInput
}

export type MutationAddGeneralContractorContactToChangeOrderRequestArgs = {
  input: GeneralContractorChangeOrderRequestContactInput
}

export type MutationAddGeneralContractorContactToLegalRequirementContactsArgs = {
  input: GeneralContractorLegalRequirementContactInput
}

export type MutationAddGeneralContractorContactToPayAppArgs = {
  input: GeneralContractorContactPayAppInput
}

export type MutationAddGeneralContractorContactToVendorSubmitToContactsArgs = {
  input: GeneralContractorVendorSubmitToContactInput
}

export type MutationAddIntegrationToContractArgs = {
  input: AddIntegrationToContractInput
}

export type MutationAddIntegrationVendorsToContractArgs = {
  input: AddIntegrationVendorsToContractInput
}

export type MutationAddLegalDocumentsToPayAppArgs = {
  input: AddLegalDocumentsToPayAppInput
}

export type MutationAddLegalRequirementArgs = {
  input: AddLegalRequirementInput
}

export type MutationAddPayAppCommentArgs = {
  input: AddPayAppCommentInput
}

export type MutationAddUserTutorialShownArgs = {
  input: AddUserTutorialShownInput
}

export type MutationAddVendorLegalRequirementArgs = {
  input: AddVendorLegalRequirementInput
}

export type MutationAddVendorLienWaiversToPayAppArgs = {
  input: AddVendorLienWaiversToPayAppInput
}

export type MutationAddVendorsToProjectArgs = {
  input: AddVendorsToProjectInput
}

export type MutationApproveChangeOrderRequestArgs = {
  input: ApproveChangeOrderRequestInput
}

export type MutationArchiveContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationArchiveNotificationArgs = {
  id: Scalars['ID']['input']
}

export type MutationArchiveRateTableArgs = {
  id: Scalars['ID']['input']
}

export type MutationBatchSendLienWaiverRequestsArgs = {
  input: BatchSendLienWaiverRequestsInput
}

export type MutationBulkAddPredictedPaymentDatesArgs = {
  input: BulkAddPredictedPaymentDatesInput
}

export type MutationBulkUpdateContractCostsArgs = {
  input: BulkUpdateContractCostsInput
}

export type MutationClearChangeOrderLogFormsArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationClearChangeOrderRequestFormsArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationClearChangeOrderRequestPricingArgs = {
  input: ClearChangeOrderRequestPricingInput
}

export type MutationClearContractCostArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationClearPayAppFormValuesArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationClearPayAppFormsArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationClearPrimaryLienWaiverFormsArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationClearUnconditionalLienWaiverArgs = {
  id: Scalars['ID']['input']
}

export type MutationClearUnconditionalLienWaiverForPreSitelinePayAppArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationClearVendorLienWaiverFormsArgs = {
  contractId: Scalars['ID']['input']
}

export type MutationCopyContractLegalRequirementsArgs = {
  input: CopyContractLegalRequirementsInput
}

export type MutationCopyContractVendorsArgs = {
  input: CopyContractVendorsInput
}

export type MutationCreateAmountLineItemArgs = {
  input: CreateAmountLineItemInput
}

export type MutationCreateChangeOrderArgs = {
  input: CreateChangeOrderInput
}

export type MutationCreateChangeOrderRequestArgs = {
  input: CreateChangeOrderRequestInput
}

export type MutationCreateChangeOrderRequestAttachmentArgs = {
  input: CreateChangeOrderRequestAttachmentInput
}

export type MutationCreateChangeOrderRequestRateTableItemArgs = {
  input: CreateChangeOrderRequestRateTableItemInput
}

export type MutationCreateChangeOrderRequestsFromLogArgs = {
  input: CreateChangeOrderRequestsInput
}

export type MutationCreateCompanyContactArgs = {
  input: CreateCompanyContactInput
}

export type MutationCreateContractBillingForecastArgs = {
  input: CreateContractBillingForecastInput
}

export type MutationCreateContractRateTableFeeArgs = {
  input: CreateContractRateTableFeeInput
}

export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput
}

export type MutationCreateLienWaiverRequestsArgs = {
  input: CreateLienWaiverRequestsInput
}

export type MutationCreateLocationArgs = {
  input: CreateLocationInput
}

export type MutationCreateMonthlyBillingProjectArgs = {
  input: CreateMonthlyBillingProjectInput
}

export type MutationCreatePayAppArgs = {
  input: CreatePayAppInput
}

export type MutationCreatePayAppAttachmentArgs = {
  input: PayAppAttachmentInput
  payAppId: Scalars['ID']['input']
}

export type MutationCreatePayAppRateTableItemArgs = {
  input: CreatePayAppRateTableItemInput
}

export type MutationCreatePreSitelinePayAppArgs = {
  input: CreatePreSitelinePayAppInput
}

export type MutationCreateProjectImportOperationsArgs = {
  inputs: ReadonlyArray<ProjectImportOperationInput>
}

export type MutationCreateQuickBillingProjectArgs = {
  input: CreateQuickBillingProjectInput
}

export type MutationCreateRateTableArgs = {
  input: CreateRateTableInput
}

export type MutationCreateSovLineItemGroupArgs = {
  input: CreateSovLineItemGroupInput
}

export type MutationCreateTaxGroupArgs = {
  input: CreateTaxGroupInput
}

export type MutationCreateVendorArgs = {
  input: CreateVendorInput
}

export type MutationCreateVendorContactArgs = {
  input: CreateVendorContactInput
}

export type MutationCreateVendorContractCommitmentArgs = {
  input: CreateVendorContractCommitmentInput
}

export type MutationCreateVendorLienWaiverArgs = {
  input: CreateVendorLienWaiverInput
}

export type MutationCreateVendorsArgs = {
  input: CreateVendorsInput
}

export type MutationCreateWriteSyncOperationArgs = {
  input: CreateWriteSyncOperationInput
}

export type MutationDeactivateUserArgs = {
  input: DeactivateUserInput
}

export type MutationDeleteAmountLineItemArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteChangeOrderArgs = {
  input: DeleteChangeOrderInput
}

export type MutationDeleteChangeOrderRequestArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteChangeOrderRequestAttachmentArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteChangeOrderRequestRateTableItemArgs = {
  input: DeleteChangeOrderRequestRateTableItemInput
}

export type MutationDeleteCompanyContactArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteContractBillingForecastArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteContractFileArgs = {
  input: DeleteContractFileInput
}

export type MutationDeleteContractRateTableArgs = {
  input: DeleteContractRateTableInput
}

export type MutationDeleteContractRateTableFeeArgs = {
  input: DeleteContractRateTableFeeInput
}

export type MutationDeleteGeneralContractorContactFromChangeOrderRequestArgs = {
  input: GeneralContractorChangeOrderRequestContactInput
}

export type MutationDeleteGeneralContractorContactFromLegalRequirementContactsArgs = {
  input: GeneralContractorLegalRequirementContactInput
}

export type MutationDeleteGeneralContractorContactFromPayAppArgs = {
  input: GeneralContractorContactPayAppInput
}

export type MutationDeleteGeneralContractorContactFromVendorSubmitToContactsArgs = {
  input: GeneralContractorVendorSubmitToContactInput
}

export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteLegalDocumentArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteLegalRequirementArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteLocationArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeletePayAppAttachmentArgs = {
  input: DeletePayAppAttachmentInput
}

export type MutationDeletePayAppRateTableItemArgs = {
  input: DeletePayAppRateTableItemInput
}

export type MutationDeletePayAppSwornStatementVendorArgs = {
  input: DeletePayAppSwornStatementVendorInput
}

export type MutationDeletePreSitelinePayAppArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeletePreSitelinePayAppFileArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationDeleteRateTableArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSovLineItemGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVendorArgs = {
  input: DeleteVendorInput
}

export type MutationDeleteVendorContactArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVendorContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVendorContractCommitmentArgs = {
  input: DeleteVendorContractCommitmentInput
}

export type MutationDeleteVendorLegalRequirementArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVendorLienWaiverArgs = {
  id: Scalars['ID']['input']
}

export type MutationDismissUserEducationCampaignArgs = {
  id: Scalars['ID']['input']
}

export type MutationDuplicateRateTableArgs = {
  id: Scalars['ID']['input']
}

export type MutationImportIntegrationTaxGroupArgs = {
  input: ImportIntegrationTaxGroupInput
}

export type MutationImportSovFromIntegrationArgs = {
  input: ImportSovInput
}

export type MutationInviteCompanyUserArgs = {
  input: InviteCompanyUserInput
}

export type MutationLinkAmountLineItemToAttachmentArgs = {
  input: LinkAmountLineItemToAttachmentInput
}

export type MutationLinkChangeOrderRequestToSovArgs = {
  input: LinkChangeOrderRequestToSovInput
}

export type MutationMarkChangeOrderRequestAuthorizedToProceedArgs = {
  input: MarkChangeOrderRequestAuthorizedToProceedInput
}

export type MutationMarkChangeOrderRequestSubmittedArgs = {
  input: MarkChangeOrderRequestSubmittedInput
}

export type MutationMarkLienWaiverRequestViewedArgs = {
  input: MarkLienWaiverRequestViewedInput
}

export type MutationMarkNotificationsReadArgs = {
  notificationIds: ReadonlyArray<Scalars['ID']['input']>
}

export type MutationMarkPayAppAsPaidArgs = {
  input: MarkPayAppAsPaidInput
}

export type MutationMarkPayAppAsSubmittedArgs = {
  input: MarkPayAppAsSubmittedInput
}

export type MutationMarkPayAppAsSyncedArgs = {
  input: MarkPayAppAsSyncedInput
}

export type MutationMarkVendorLienWaiverSubmittedArgs = {
  id: Scalars['ID']['input']
}

export type MutationModifyEmailPreferencesArgs = {
  input: ModifyEmailPreferencesInput
}

export type MutationModifyNotificationPreferencesArgs = {
  input: ModifyNotificationPreferencesInput
}

export type MutationOptInToBillingForMonthArgs = {
  input: OptInToBillingInput
}

export type MutationOptOutOfBillingForMonthArgs = {
  input: OptOutOfBillingInput
}

export type MutationOptOutVendorFromDigitalSignaturesArgs = {
  input: OptOutVendorFromDigitalSignaturesInput
}

export type MutationPayAppFormsReadyForReviewArgs = {
  input: PayAppFormsReadyForReviewInput
}

export type MutationProcessProjectImportOperationArgs = {
  jwt: Scalars['String']['input']
}

export type MutationReactivateUserArgs = {
  input: ReactivateUserInput
}

export type MutationRefreshIntegrationVendorsArgs = {
  integrationId: Scalars['ID']['input']
}

export type MutationRejectAndRequestLegalDocumentFromVendorArgs = {
  input: RejectAndRequestLegalDocumentFromVendorInput
}

export type MutationRejectChangeOrderRequestArgs = {
  id: Scalars['ID']['input']
}

export type MutationRemoveLegalDocumentFromPayAppArgs = {
  input: RemoveLegalDocumentFromPayAppInput
}

export type MutationRemovePayAppRequirementArgs = {
  input: RemovePayAppRequirementInput
}

export type MutationRemoveVendorIntegrationArgs = {
  input: RemoveVendorIntegrationInput
}

export type MutationRemoveVendorIntegrationsArgs = {
  input: RemoveVendorIntegrationsInput
}

export type MutationRemoveVendorLienWaiverFromPayAppArgs = {
  input: RemoveVendorLienWaiverFromPayAppInput
}

export type MutationRequestChangeOrderReviewArgs = {
  input: RequestChangeOrderReviewInput
}

export type MutationRequestLegalDocumentFromVendorArgs = {
  input: RequestLegalDocumentsFromVendorInput
}

export type MutationResendEmailAliasVerificationArgs = {
  companyUserEmailAliasId: Scalars['ID']['input']
}

export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput
}

export type MutationResetChangeOrderRequestToDraftArgs = {
  input: ResetChangeOrderRequestToDraftInput
}

export type MutationResetChangeOrderRequestWithContractRateTableArgs = {
  input: ResetChangeOrderRequestWithContractRateTableInput
}

export type MutationResetChangeOrderRequestWithContractRateTableFeesArgs = {
  input: ResetChangeOrderRequestWithContractRateTableFeesInput
}

export type MutationResetPayAppRetentionHeldOverrideArgs = {
  input: ResetPayAppRetentionHeldOverrideInput
}

export type MutationResetPayAppRetentionReleasedArgs = {
  input: ResetPayAppRetentionReleasedInput
}

export type MutationResetPayAppToDraftArgs = {
  input: ResetPayAppToDraftInput
}

export type MutationResetPayAppWithContractRateTableArgs = {
  input: ResetPayAppWithContractRateTableInput
}

export type MutationResetPayAppWithContractRateTableFeesArgs = {
  input: ResetPayAppWithContractRateTableFeesInput
}

export type MutationRoundProgressBillingsArgs = {
  payAppId: Scalars['ID']['input']
}

export type MutationSaveLowerTierLienWaiverFormValuesArgs = {
  input: SaveLowerTierLienWaiverFormValuesInput
}

export type MutationSavePayAppFormValuesArgs = {
  input: SavePayAppFormValuesInput
}

export type MutationSelectProjectFormsArgs = {
  input: SelectProjectFormsInput
}

export type MutationSendCompleteLienWaiverRequestArgs = {
  input: GetWithTokenInput
}

export type MutationSendLegalDocumentsArgs = {
  input: SendLegalDocumentsInput
}

export type MutationSendLienWaiverRequestsArgs = {
  input: SendLienWaiverRequestsInput
}

export type MutationSendPasswordResetLinkEmailArgs = {
  email: Scalars['String']['input']
}

export type MutationSendSigninLinkAfterInviteArgs = {
  email: Scalars['String']['input']
}

export type MutationSetAmountLineItemAmountArgs = {
  input: SetAmountLineItemAmountInput
}

export type MutationSetAmountLineItemDescriptionArgs = {
  input: SetAmountLineItemDescriptionInput
}

export type MutationSetAmountLineItemFeePercentArgs = {
  input: SetAmountLineItemFeePercentInput
}

export type MutationSetAmountLineItemTaxGroupArgs = {
  input: SetAmountLineItemTaxGroupInput
}

export type MutationSetCurrentBilledArgs = {
  input: SetCurrentBilledInput
}

export type MutationSetCurrentPricingArgs = {
  input: SetCurrentPricingInput
}

export type MutationSetIncludeChangeOrderLogIncludedInPayAppPackageArgs = {
  input: SetIncludeChangeOrderLogInPayAppPackageInput
}

export type MutationSetIntegrationCustomerArgs = {
  input: SetIntegrationCustomerInput
}

export type MutationSetIntegrationTaxGroupArgs = {
  input: SetIntegrationTaxGroupInput
}

export type MutationSetPayAppLineItemSortOrdersArgs = {
  input: SetPayAppLineItemSortOrdersInput
}

export type MutationSetProgressBilledArgs = {
  progressBilled: Scalars['SafeInt']['input']
  sovLineItemProgressId: Scalars['ID']['input']
}

export type MutationSetStoredMaterialBilledArgs = {
  sovLineItemProgressId: Scalars['ID']['input']
  storedMaterialBilled: Scalars['SafeInt']['input']
}

export type MutationSignChangeOrderRequestArgs = {
  input: SignChangeOrderRequestInput
}

export type MutationSignLegalDocumentArgs = {
  input: SignLegalDocumentInput
}

export type MutationSignLowerTierLienWaiverArgs = {
  input: SignLowerTierLienWaiverInput
}

export type MutationSignPayAppArgs = {
  input: SignPayAppInput
}

export type MutationSignUnconditionalLienWaiverArgs = {
  input: SignUnconditionalLienWaiverInput
}

export type MutationSignUnconditionalLienWaiverForPreSitelinePayAppArgs = {
  input: SignUnconditionalLienWaiverForPreSitelinePayAppInput
}

export type MutationSkipLegalRequirementPeriodArgs = {
  input: SkipLegalRequirementPeriodInput
}

export type MutationSubmitBackupArgs = {
  input: SubmitBackupInput
}

export type MutationSubmitChangeOrderRequestArgs = {
  input: SubmitChangeOrderRequestInput
}

export type MutationSubmitDraftPayAppArgs = {
  input: SubmitDraftPayAppInput
}

export type MutationSubmitForFullReviewArgs = {
  input: SubmitPayAppInput
}

export type MutationSubmitUnconditionalLienWaiverArgs = {
  input: SubmitUnconditionalLienWaiverInput
}

export type MutationSubmitUnconditionalLienWaiverForPreSitelinePayAppArgs = {
  input: SubmitUnconditionalLienWaiverForPreSitelinePayAppInput
}

export type MutationSubmitVendorLienWaiversArgs = {
  input: SubmitVendorLienWaiversInput
}

export type MutationUnarchiveContractArgs = {
  id: Scalars['ID']['input']
}

export type MutationUnarchiveRateTableArgs = {
  id: Scalars['ID']['input']
}

export type MutationUnlinkAmountLineItemFromAttachmentArgs = {
  input: UnlinkAmountLineItemFromAttachmentInput
}

export type MutationUnskipLegalRequirementPeriodArgs = {
  input: SkipLegalRequirementPeriodInput
}

export type MutationUpdateChangeOrderArgs = {
  input: UpdateChangeOrderInput
}

export type MutationUpdateChangeOrderRequestArgs = {
  input: UpdateChangeOrderRequestInput
}

export type MutationUpdateChangeOrderRequestAttachmentArgs = {
  input: UpdateChangeOrderRequestAttachmentInput
}

export type MutationUpdateChangeOrderRequestLinkedChangeOrderRequestsArgs = {
  input: UpdateChangeOrderRequestLinkedChangeOrderRequestsInput
}

export type MutationUpdateChangeOrderRequestRateTableItemArgs = {
  input: UpdateChangeOrderRequestRateTableItemInput
}

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput
}

export type MutationUpdateCompanyContactArgs = {
  input: UpdateCompanyContactInput
}

export type MutationUpdateCompanyIntegrationMetadataArgs = {
  input: UpdateCompanyIntegrationMetadataInput
}

export type MutationUpdateCompanyUserArgs = {
  input: UpdateCompanyUserInput
}

export type MutationUpdateContractArgs = {
  input: UpdateContractInput
}

export type MutationUpdateContractBillingForecastArgs = {
  input: UpdateContractBillingForecastInput
}

export type MutationUpdateContractCostArgs = {
  input: UpdateContractCostInput
}

export type MutationUpdateContractCostFromIntegrationArgs = {
  input: UpdateContractCostFromIntegrationInput
}

export type MutationUpdateContractLienWaiverThroughDateArgs = {
  input: UpdateContractLienWaiverThroughDateInput
}

export type MutationUpdateContractRateTableArgs = {
  input: UpdateContractRateTableInput
}

export type MutationUpdateContractRateTableFeeArgs = {
  input: UpdateContractRateTableFeeInput
}

export type MutationUpdateContractUsersArgs = {
  input: UpdateContractUsersInput
}

export type MutationUpdateIntegrationMetadataArgs = {
  input: UpdateIntegrationMetadataInput
}

export type MutationUpdateLegalDocumentArgs = {
  input: UpdateLegalDocumentInput
}

export type MutationUpdateLegalRequirementArgs = {
  input: UpdateLegalRequirementInput
}

export type MutationUpdateLienWaiverArgs = {
  input: UpdateLienWaiverInput
}

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput
}

export type MutationUpdateOnboardedProjectComplianceStatusArgs = {
  input: OnboardedProjectComplianceStatusInput
}

export type MutationUpdateOnboardedProjectVendorsStatusArgs = {
  input: OnboardedProjectVendorsStatusInput
}

export type MutationUpdatePayAppArgs = {
  input: UpdatePayAppInput
}

export type MutationUpdatePayAppAttachmentArgs = {
  input: UpdatePayAppAttachmentInput
}

export type MutationUpdatePayAppInvoiceReadyArgs = {
  input: PayAppInvoiceReadyInput
}

export type MutationUpdatePayAppPaidAtArgs = {
  input: UpdatePayAppPaidAtInput
}

export type MutationUpdatePayAppRateTableItemArgs = {
  input: UpdatePayAppRateTableItemInput
}

export type MutationUpdatePayAppRetentionArgs = {
  input: UpdatePayAppRetentionInput
}

export type MutationUpdatePayAppStatusArgs = {
  input: PayAppStatusInput
}

export type MutationUpdatePayAppSubmittedAtArgs = {
  input: UpdatePayAppSubmittedAtInput
}

export type MutationUpdatePayAppSwornStatementVendorsArgs = {
  input: UpdatePayAppSwornStatementVendorsInput
}

export type MutationUpdatePayAppSyncedAtArgs = {
  input: UpdatePayAppSyncedAtInput
}

export type MutationUpdatePreSitelinePayAppArgs = {
  input: UpdatePreSitelinePayAppInput
}

export type MutationUpdatePrimaryLienWaiverTemplatesArgs = {
  input: UpdateLienWaiverTemplatesInput
}

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput
}

export type MutationUpdateQuickBillingProjectArgs = {
  input: UpdateQuickBillingProjectInput
}

export type MutationUpdateRateTableArgs = {
  input: UpdateRateTableInput
}

export type MutationUpdateRoundRetentionArgs = {
  input: UpdateRoundRetentionInput
}

export type MutationUpdateSovArgs = {
  input: UpdateSovInput
}

export type MutationUpdateSovLineItemGroupArgs = {
  input: UpdateSovLineItemGroupInput
}

export type MutationUpdateSovLineItemTotalValueArgs = {
  input: UpdateSovLineItemTotalValueInput
}

export type MutationUpdateSovWithChangeSetArgs = {
  input: UpdateSovWithChangeSetInput
}

export type MutationUpdateTaxCalculationTypeArgs = {
  input: UpdateTaxCalculationTypeInput
}

export type MutationUpdateTaxGroupArgs = {
  input: UpdateTaxGroupInput
}

export type MutationUpdateUploadedLegalDocumentArgs = {
  input: UpdateUploadedLegalDocumentInput
}

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input']
  user: UpdateUserInput
}

export type MutationUpdateUserEmailAliasesArgs = {
  input: UpdateUserEmailAliasesInput
}

export type MutationUpdateUserOfficeLocationArgs = {
  input: UpdateUserOfficeLocationInput
}

export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput
}

export type MutationUpdateVendorContactArgs = {
  input: UpdateVendorContactInput
}

export type MutationUpdateVendorContractArgs = {
  input: UpdateVendorContractInput
}

export type MutationUpdateVendorContractCommitmentArgs = {
  input: UpdateVendorContractCommitmentInput
}

export type MutationUpdateVendorContractCommitmentStatusArgs = {
  input: UpdateVendorContractCommitmentStatusInput
}

export type MutationUpdateVendorContractPreliminaryNoticesArgs = {
  input: UpdateVendorContractPreliminaryNoticesInput
}

export type MutationUpdateVendorLegalRequirementArgs = {
  input: UpdateVendorLegalRequirementInput
}

export type MutationUpdateVendorLienWaiverTemplatesArgs = {
  input: UpdateLienWaiverTemplatesInput
}

export type MutationUpdateWorksheetLineItemsArgs = {
  input: UpdateWorksheetLineItemsInput
}

export type MutationUpdateWorksheetProgressBilledArgs = {
  input: UpdateWorksheetProgressBilledInput
}

export type MutationUploadChangeOrderScoFileArgs = {
  input: UploadChangeOrderScoFileInput
}

export type MutationUploadFormTemplatesArgs = {
  input: UploadFormTemplatesInput
}

export type MutationUploadLegalDocumentWithTokenArgs = {
  input: UploadLegalDocumentWithTokenInput
}

export type MutationUploadLegalDocumentsArgs = {
  input: UploadLegalDocumentsInput
}

export type MutationUploadLienWaiverFileArgs = {
  input: UploadLienWaiverInput
}

export type MutationUploadPayAppFileArgs = {
  input: UploadPayAppInput
}

export type MutationVerifyEmailAliasArgs = {
  input: VerifyEmailAliasInput
}

export { NotarySignatureStatus }

export { NotaryTag }

export type Notification = {
  readonly __typename: 'Notification'
  /** Contract for which this notification is. */
  readonly contract: Maybe<Contract>
  /** Used to show time of notification. */
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * Initials of the creator of the notification. Empty if created by the system. We only store
   * the initals of the creator, rather than a user ID, because of cases where an unauthenticated
   * user creates a notification (i.e. LTLW Signed). These initials are used to show an avatar
   * for that user on the notification.
   */
  readonly createdByUserInitials: Maybe<Scalars['String']['output']>
  readonly id: Scalars['ID']['output']
  /** Link to redirect to if the notification is clicked. */
  readonly link: Scalars['String']['output']
  /** Actual text of notification. */
  readonly message: Scalars['String']['output']
  /** Whether the notification has been read yet by the user. */
  readonly read: Scalars['Boolean']['output']
  /** Indicates which notification this is. */
  readonly type: NotificationType
  readonly updatedAt: Scalars['DateTime']['output']
  /** User for whom this notification is. */
  readonly user: User
}

/**
 * Feed of notifications returned in paginated lists. Cursor is the latest notification created time
 * and hasNext indicates if there are more notifications to be returned.
 */
export type NotificationFeed = {
  readonly __typename: 'NotificationFeed'
  readonly cursor: Scalars['DateTime']['output']
  readonly hasNext: Scalars['Boolean']['output']
  readonly notifications: ReadonlyArray<Notification>
}

export { NotificationType }

/** Tracks the status of onboarding legal requirements for a project from the Compliance module */
export type OnboardedProjectComplianceStatus = {
  readonly __typename: 'OnboardedProjectComplianceStatus'
  /** The user finished adding general contractor compliance recipients */
  readonly addedGcRecipients: Scalars['Boolean']['output']
  /** The user completed the step to set up legal requirements */
  readonly addedLegalRequirements: Scalars['Boolean']['output']
  /**
   * The user has completed onboarding. This step determines if on subsequent visits, we direct the
   * user to the onboarding page or we direct the user to the compliance overview page.
   */
  readonly completedOnboarding: Scalars['Boolean']['output']
}

export type OnboardedProjectComplianceStatusInput = {
  /** User confirms that they are done adding compliance GC recipients */
  readonly addedGcRecipients?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * This field is updated via updateOnboardedProjectComplianceStatus when the user
   * confirms that they are done adding compliance requirements and advances
   * to the next section.
   */
  readonly addedLegalRequirements?: InputMaybe<Scalars['Boolean']['input']>
  /** User confirms that they are done with project setup from the compliance module */
  readonly completedOnboarding?: InputMaybe<Scalars['Boolean']['input']>
  readonly contractId: Scalars['ID']['input']
}

/** Represents the status of each of the onboarding steps for a new project contract. */
export type OnboardedProjectContractStatus = {
  readonly __typename: 'OnboardedProjectContractStatus'
  /** Whether the subcontractor has already added GC contact emails. */
  readonly addedGcContacts: Scalars['Boolean']['output']
  /** Whether the subcontractor has already added their SOV. */
  readonly addedSov: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their teammates. */
  readonly addedTeammates: Scalars['Boolean']['output']
  /** Computed fields to simplify frontend loads */
  readonly isComplete: Scalars['Boolean']['output']
  readonly isReadyForPayAppSubmit: Scalars['Boolean']['output']
  /**
   * Whether the admin has notified the subcontractor that the forms are onboarded, or no notification is
   * required because no forms were processed for the project
   */
  readonly notifiedOnboardedForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the change order log forms. */
  readonly onboardedChangeOrderLogForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the change order request forms. */
  readonly onboardedChangeOrderRequestForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the pay app forms. */
  readonly onboardedPayAppForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the primary lien waiver forms. */
  readonly onboardedPrimaryLienWaiverForms: Scalars['Boolean']['output']
  /** If false, an admin user is currently processing the vendor lien waiver forms. */
  readonly onboardedVendorLienWaiverForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has uploaded change order log forms/sent them to admin */
  readonly selectedChangeOrderLogForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their change order request forms/sent them to admin. */
  readonly selectedChangeOrderRequestForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their pay app forms/sent them to admin. */
  readonly selectedPayAppForms: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their primary lien waivers/sent them to admin. */
  readonly selectedPrimaryLienWaivers: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected a rate table. */
  readonly selectedRateTable: Scalars['Boolean']['output']
  /** Whether the subcontractor has already selected their vendor lien waivers/sent them to admin. */
  readonly selectedVendorLienWaivers: Scalars['Boolean']['output']
  /** Whether the subcontractor has created the project's first pay app. */
  readonly startedBilling: Scalars['Boolean']['output']
}

/** Tracks the status of onboarding vendors/lien waivers for a project from the Vendor module */
export type OnboardedProjectVendorsStatus = {
  readonly __typename: 'OnboardedProjectVendorsStatus'
  /** The user finished adding general contractor lien waiver recipients */
  readonly addedGcRecipients: Scalars['Boolean']['output']
  /** The user completed the vendor documents step of onboarding */
  readonly addedLegalRequirements: Scalars['Boolean']['output']
  /**
   * The first onboarding step is to select lien waiver templates. If this was done during project onboarding,
   * this key will be set to true. If during project onboarding, the user did not select vendor lien waivers,
   * this step will be outstanding (set to false), and only flipped to true when the user either selects
   * lien waivers and confirms their selections.
   */
  readonly addedVendorLienWaivers: Scalars['Boolean']['output']
  /** The user finished adding vendors */
  readonly addedVendors: Scalars['Boolean']['output']
  /**
   * The user has completed onboarding. This step determines if on subsequent visits, we direct the user
   * to the onboarding page or we direct the user to the project details section.
   */
  readonly completedOnboarding: Scalars['Boolean']['output']
}

export type OnboardedProjectVendorsStatusInput = {
  /** User confirms that they are done adding lien waiver GC recipients */
  readonly addedGcRecipients?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * This field will only be updated via updateOnboardedProjectVendorsStatus when the user
   * confirms that they are done with the requirements section - either by confirming that
   * there are no requirements, or by having added requirements and clicking "next"
   */
  readonly addedLegalRequirements?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * This field is updated via updateOnboardedProjectVendorsStatus when the user
   * confirms that they are done adding vendor lien waiver forms and advances
   * to the next section.
   */
  readonly addedVendorLienWaivers?: InputMaybe<Scalars['Boolean']['input']>
  /** User confirms that they are done choosing project vendors */
  readonly addedVendors?: InputMaybe<Scalars['Boolean']['input']>
  /** User confirms that they are done with project setup from the vendors module */
  readonly completedOnboarding?: InputMaybe<Scalars['Boolean']['input']>
  readonly contractId: Scalars['ID']['input']
}

export type OptInToBillingInput = {
  readonly contractId: Scalars['ID']['input']
  /** This is in MM-YYYY format. */
  readonly month: Scalars['String']['input']
}

export type OptOutOfBillingInput = {
  readonly contractId: Scalars['ID']['input']
  /** This is in MM-YYYY format. */
  readonly month: Scalars['String']['input']
  readonly optOutOnIntegrationIds: ReadonlyArray<Scalars['ID']['input']>
  readonly payAppId?: InputMaybe<Scalars['ID']['input']>
  readonly reason?: InputMaybe<Scalars['String']['input']>
}

export type OptOutVendorFromDigitalSignaturesInput = {
  /** Contains the vendor contact id */
  readonly token: Scalars['String']['input']
}

/** Number of vendor lien waivers outstanding across all projects in a single month */
export type OutstandingLienWaiversByMonth = {
  readonly __typename: 'OutstandingLienWaiversByMonth'
  readonly month: Scalars['DateTime']['output']
  readonly numOutstandingLienWaiversThisMonth: Scalars['SafeInt']['output']
}

export type OverviewDashboard = {
  readonly __typename: 'OverviewDashboard'
  readonly billingSummary: BillingDashboardSummary
  readonly payAppAgingSummary: PayAppAgingDashboardSummary
  readonly vendorSummary: VendorLienWaiverDashboardSummary
}

/**
 * A list of contracts that can be paginated through, each including a list of predicted payment date periods.
 * The cursor is the ID of the last contract in the list.
 */
export type PaginatedCashForecastContractList = {
  readonly __typename: 'PaginatedCashForecastContractList'
  readonly contracts: ReadonlyArray<CashForecastContract>
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
}

export type PaginatedChangeOrderRequestEventInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly paginatedInput: PaginatedInput
}

/** A list of companies that can be paginated through. The cursor is the ID of the last company in the list. */
export type PaginatedCompanyList = {
  readonly __typename: 'PaginatedCompanyList'
  readonly companies: ReadonlyArray<Company>
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
}

/** A list of contracts that can be paginated through. The cursor is the ID of the last contract in the list. */
export type PaginatedContractList = {
  readonly __typename: 'PaginatedContractList'
  readonly contracts: ReadonlyArray<Contract>
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
}

export type PaginatedInput = {
  /** Pointer to the last time returned already. For the first query, this isn't specified. */
  readonly cursor?: InputMaybe<Scalars['DateTime']['input']>
  /** Returns up to the limit number of paginated results. */
  readonly limit: Scalars['Int']['input']
}

export { PaginatedListSortOrder }

/** A list of templates that can be paginated through. The cursor is the ID of the last template in the list. */
export type PaginatedTemplateList = {
  readonly __typename: 'PaginatedTemplateList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly templates: ReadonlyArray<FormTemplate>
  readonly totalCount: Scalars['Int']['output']
}

export type PaginatedVendorContractsInput = {
  /**
   * If provided, check for vendor contracts that are not done collecting in the given category. If not
   * provided, check both categories.
   */
  readonly category?: InputMaybe<LienWaiverCategory>
  /** Either contractId or vendorId must be provided */
  readonly contractId?: InputMaybe<Scalars['ID']['input']>
  readonly cursor?: InputMaybe<Scalars['ID']['input']>
  readonly limit?: InputMaybe<Scalars['Int']['input']>
  /** If a vendor contract has collected its final lien waiver by this date, it will not be included */
  readonly month?: InputMaybe<Scalars['SafeInt']['input']>
  readonly vendorId?: InputMaybe<Scalars['ID']['input']>
  readonly year?: InputMaybe<Scalars['SafeInt']['input']>
}

/**
 * A list of vendor contracts that can be paginated through.
 * The cursor is the ID of the last vendor contract in the list.
 */
export type PaginatedVendorContractsList = {
  readonly __typename: 'PaginatedVendorContractsList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
  readonly vendorContracts: ReadonlyArray<VendorContract>
}

/**
 * A list of vendors that can be paginated through.
 * The cursor is the ID of the last vendor in the list.
 */
export type PaginatedVendorsList = {
  readonly __typename: 'PaginatedVendorsList'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly totalCount: Scalars['Int']['output']
  readonly vendors: ReadonlyArray<Vendor>
}

/** A list of write sync operations that can be paginated through. The cursor is the ID of the last operation in the list. */
export type PaginatedWriteSyncOperations = {
  readonly __typename: 'PaginatedWriteSyncOperations'
  readonly cursor: Maybe<Scalars['ID']['output']>
  readonly hasNext: Scalars['Boolean']['output']
  readonly operations: ReadonlyArray<WriteSyncOperation>
  readonly totalCount: Scalars['Int']['output']
}

/** Represents a single (monthly) pay app for a single company on a single project. */
export type PayApp = {
  readonly __typename: 'PayApp'
  /** Amount due, post tax. This is amountDuePreTax + amountDueTaxAmount */
  readonly amountDuePostTax: Scalars['SafeInt']['output']
  /** Current billed - retention held + retention released. This is pre-tax. */
  readonly amountDuePreTax: Scalars['SafeInt']['output']
  /** Tax amount on the amount due. This is the sum of all tax amounts on the progresses/amount items. */
  readonly amountDueTaxAmount: Scalars['SafeInt']['output']
  /**
   * A list of amount line items for the pay app.
   * Only used for QUICK pay apps.
   * This array is empty for all other types of pay apps.
   */
  readonly amountLineItems: ReadonlyArray<AmountLineItem>
  /**
   * The amount that was paid on this pay app. This may or may not be the payAppNet amount, depending
   * on what the end user recorded. If the pay app has not been paid, then this returns null/undefined.
   * This amount is post-tax.
   */
  readonly amountPaid: Maybe<Scalars['SafeInt']['output']>
  /** List of attachments to include as backup in the pay app */
  readonly attachments: ReadonlyArray<PayAppAttachment>
  /**
   * Indicates whether the latest updates to the progress values was done by an automatic read sync
   * from an integration. For example, if the GC modifies a sub's billing directly on Textura, this
   * bit will be set to true once we have pulled the billing data back into Siteline.
   */
  readonly automaticallyReadBilling: Scalars['Boolean']['output']
  /** Total balance left to finish after this pay app. */
  readonly balanceToFinish: Scalars['SafeInt']['output']
  readonly billingEnd: Scalars['DateTime']['output']
  readonly billingStart: Scalars['DateTime']['output']
  /**
   * Billing type extracted from the contract billing type when pay app is created.
   * - For LUMP_SUM/UNIT_PRICE billing, only `progress` items are provided
   * - For QUICK billing, only `amountLineItems` are provided, and they all have `type = ADDITIONAL`
   */
  readonly billingType: BillingType
  /** Whether the forms for this pay app can be generated, i.e. they've been selected or provided and fully processed */
  readonly canGenerateForms: Scalars['Boolean']['output']
  /** A list of comments on the pay app. */
  readonly comments: ReadonlyArray<PayAppComment>
  /** Contract that this pay app belongs to */
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * This is the current amount billed for this PayApp. This includes both retention and stored
   * materials.
   */
  readonly currentBilled: Scalars['SafeInt']['output']
  /** The total amount of progress and retention billed. Does not subtract retention held. */
  readonly currentProgressAndRetentionBilled: Scalars['SafeInt']['output']
  /** This is the current retention for this PayApp. */
  readonly currentRetention: Scalars['SafeInt']['output']
  /**
   * This is the time at which a pay app draft was submitted. It is cleared when a pay app is edited
   * and won't ever be set if the pay app was never submitted while it was a draft.
   */
  readonly draftSubmittedAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Array of CompanyContact references that were emailed a PDF version of this PayApp. This is only
   * used when the general contractor is not using Siteline to administer PayApps.
   */
  readonly emailedContacts: ReadonlyArray<CompanyContact>
  /** A list of events related to this pay app, like `PAY_APP_SUBMITTED`. */
  readonly events: ReadonlyArray<PayAppEvent>
  /**
   * Generated when the pay app is signed so any future submissions and downloads include the exact
   * invoice that was signed. This file includes only the "invoice" forms, i.e. pay app requirements
   * and primary lien waiver. Other attachments will still be re-generated.
   */
  readonly finalInvoice: Maybe<StoredFile>
  readonly formTemplates: ReadonlyArray<PinnedPayAppTemplate>
  /** A list of all user-entered values for the forms in this pay app. */
  readonly formValues: ReadonlyArray<FormTemplateAnnotationValue>
  /** Gets a base set of G702 values to preview for a pay app */
  readonly g702Values: G702Values
  /** Whether the contract rate table was swapped or updated since the pay app was created */
  readonly hasContractRateTableChanged: Scalars['Boolean']['output']
  /**
   * Templates that are pinned to this pay-app. Since @oneToMany cannot be nullable, formTemplates can
   * only be an array with 0 or more elements. This is why we use a flag to determine whether to get
   * templates from the array, or from the contract that this pay-app belongs to.
   */
  readonly hasPinnedTemplates: Scalars['Boolean']['output']
  /** Whether fees have changed on the contract since the pay app was created */
  readonly haveContractFeesChanged: Scalars['Boolean']['output']
  readonly id: Scalars['ID']['output']
  /**
   * Iff a pay app has an event of type PAY_APP_PAID_IN_INTEGRATION, returns the date it was marked as
   * paid based on the date in the PayAppEvent logs. Otherwise returns null.
   */
  readonly integrationPaidAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Date before which invoice must be submitted by the sub project manager to the sub accountant
   * (internal deadline).
   */
  readonly internalDueDate: Scalars['DateTime']['output']
  /** Optional invoice number for this pay app, to be used with template variables, if it exists. */
  readonly invoiceNumber: Maybe<Scalars['String']['output']>
  /** A single bit to describe if an invoice has been marked as completed by a sub project manager. */
  readonly invoiceReady: Scalars['Boolean']['output']
  /**
   * If the amount paid on the pay app doesn't match the amount due, we allow the customer to manually
   * mark the pay app balance as closed so the remaining amount isn't considered due and doesn't appear
   * in aging reports.
   */
  readonly isBalanceManuallyClosed: Scalars['Boolean']['output']
  /** If a past pay app had a retention override at the pay app level, it's returned here */
  readonly lastPayAppWithRetentionHeldOverride: Maybe<PayApp>
  /**
   * Iff the pay app is in a submitted or paid state, returns the most recent status log from a
   * submission or sync. We return the entire log, rather than just the date, so the client can tell
   * whether the latest submit event was a submission or a sync.
   */
  readonly lastSubmitted: Maybe<PayAppStatusLog>
  /** The last write sync for this entity, completed or not */
  readonly lastSync: Maybe<WriteSyncOperation>
  /** The last write sync for this entity. Null if not successful */
  readonly lastSyncIfSuccessful: Maybe<WriteSyncOperation>
  /** List of legal documents to include as backup in the pay app */
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  /** List of LienWaivers for the top-tier sub and all lower-tier subs */
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /**
   * Iff a pay app has an event of type PAY_APP_PAID_IN_INTEGRATION, returns the type of integration
   * that was used to mark it paid. Otherwise returns null.
   */
  readonly markedAsPaidIntegration: Maybe<Integration>
  /** Retention held percent on the stored materials billing */
  readonly materialsRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  /**
   * If there are fields missing on the contract or project that are required to generate this
   * pay app's forms, they're returned here
   */
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  /**
   * If there are fields missing on the contract or project that are required to generate this pay
   * app's unconditional lien waiver, they're returned here
   */
  readonly missingRequiredUnconditionalLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
  /** Date before which the entire PayApp must be submitted to the GC (external deadline). */
  readonly payAppDueDate: Scalars['DateTime']['output']
  /** Computes the pay app's number */
  readonly payAppNumber: Scalars['SafeInt']['output']
  /** Includes information about the physical signatures required on this pay app. */
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly pinnedChangeOrderLogFormTemplateVersion: Maybe<FormTemplateVersion>
  /**
   * The date payment is expected or predicted, to be used for cash forecasting. This date is entered
   * by the customer, but may be suggested to them based on past payment history.
   */
  readonly predictedPaymentDate: Maybe<Scalars['DateTime']['output']>
  /** The amount of previous retention billed for this period. */
  readonly previousRetentionBilled: Scalars['SafeInt']['output']
  /**
   * A list of progress values for their respective SOV line items.
   * Not used for QUICK pay apps.
   * This array is empty for all other types of pay apps.
   */
  readonly progress: ReadonlyArray<SovLineItemProgress>
  /** Retention held percent on the progress billing */
  readonly progressRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  /**
   * Link back to rate table that was used to create a T&M pay app.
   * It will always exist for T&M pay apps, although it might be archived.
   * Always null for pay apps that are not T&M.
   */
  readonly rateTable: Maybe<RateTable>
  /**
   * Only for T&M.
   * Warning: this includes *all* fees, including group fees.
   */
  readonly rateTableFees: ReadonlyArray<PayAppRateTableFee>
  /** Only for T&M */
  readonly rateTableGroups: ReadonlyArray<PayAppRateTableGroup>
  /** Only for T&M */
  readonly rateTableItems: ReadonlyArray<PayAppRateTableItem>
  /**
   * Retention override amount on the pay app on both progress and material billing. Takes precedence
   * over the progress and materials percents if set on the pay app. If set, also takes precedence over
   * retention values tracked on the individual progresses.
   */
  readonly retentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /** The overall retention held percent as of this payApp */
  readonly retentionHeldPercent: Scalars['Float']['output']
  /** Used to determine whether the type of pay app is 'normal' or 'retention-only'. */
  readonly retentionOnly: Scalars['Boolean']['output']
  /** Total retention being released for this project on this pay app in cents */
  readonly retentionReleased: Maybe<Scalars['SafeInt']['output']>
  /** Event logs from each time the pay app was reverted to draft */
  readonly revertToDraftEvents: ReadonlyArray<PayAppEvent>
  readonly signature: Maybe<Signature>
  /**
   * Status of the pay app. Use this as the source of truth for statuses instead of status logs.
   * We denormalized statuses for performance reasons.
   */
  readonly status: PayAppStatus
  /** Last time the status was updated */
  readonly statusChangedAt: Scalars['DateTime']['output']
  /** List of all status state transitions for the pay app. */
  readonly statusLogs: ReadonlyArray<PayAppStatusLog>
  /** Vendors to include on sworn statement forms, with their corresponding metrics */
  readonly swornStatementVendors: Maybe<ReadonlyArray<PayAppSwornStatementVendor>>
  readonly timeZone: Scalars['String']['output']
  /** This is the total retention held for this PayApp including the current period. */
  readonly totalRetention: Scalars['SafeInt']['output']
  /** The SOV total value as of this pay app */
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  /** If provided, we'll use this file instead of generating it on the fly */
  readonly uploadedFile: Maybe<StoredFile>
  readonly worksheetProgress: ReadonlyArray<WorksheetLineItemProgress>
}

export type PayAppAgingDashboard = {
  readonly __typename: 'PayAppAgingDashboard'
  readonly contracts: ReadonlyArray<PayAppAgingDashboardContract>
  readonly payAppAgingSummary: PayAppAgingDashboardSummary
}

export type PayAppAgingDashboardContract = {
  readonly __typename: 'PayAppAgingDashboardContract'
  readonly agingBreakdown: AgingBreakdown
  readonly billingStatus: DashboardBillingStatus
  readonly contract: Contract
  /**
   * If aging is calculated from submission date and there are unpaid pre-Siteline pay apps with no
   * submitted date, this will be true so the user knows that pay apps are missing from the data
   */
  readonly hasMissingPreSitelinePayApps: Scalars['Boolean']['output']
  readonly numComments: Scalars['SafeInt']['output']
}

export type PayAppAgingDashboardSummary = {
  readonly __typename: 'PayAppAgingDashboardSummary'
  /** Total amount across all unpaid pay apps over 30 days aged */
  readonly amountAged30Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 30 days */
  readonly amountAged30DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Total amount across all unpaid pay apps over 60 days aged */
  readonly amountAged60Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 60 days */
  readonly amountAged60DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Total amount across all unpaid pay apps over 90 days aged */
  readonly amountAged90Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 90 days */
  readonly amountAged90DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Total amount across all unpaid pay apps over 120 days aged */
  readonly amountAged120Days: Scalars['SafeInt']['output']
  /** If undefined, the previous month had no amount aged over 120 days */
  readonly amountAged120DaysMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** If undefined, the previous month had no amount outstanding */
  readonly amountOutstandingMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  /** Amount due across all unpaid pay apps in a month */
  readonly amountOutstandingThisMonth: Scalars['SafeInt']['output']
  /** Average days to paid across all pay apps */
  readonly averageDaysToPaid: Scalars['Float']['output']
  /** If undefined, the previous month had no paid pay apps */
  readonly averageDaysToPaidMonthOverMonthPercent: Maybe<Scalars['Float']['output']>
  readonly payAppAgingBreakdown: AgingBreakdown
}

/** Represents an attachment, image, or binary file that can be accessed via a URL. */
export type PayAppAttachment = {
  readonly __typename: 'PayAppAttachment'
  /** If this attachment was created from a recurring contract attachment, link to that attachment */
  readonly contractPayAppAttachment: Maybe<ContractPayAppAttachment>
  readonly createdAt: Scalars['DateTime']['output']
  /** Optional description of the attachment */
  readonly description: Maybe<Scalars['String']['output']>
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  /** The attachment is not included in the pay app PDF package, but is attached to submission emails */
  readonly isEmailAttachment: Scalars['Boolean']['output']
  /** Internal-only attachments will never get included in the generated PDF package for the pay app */
  readonly isInternalOnly: Scalars['Boolean']['output']
  readonly name: Scalars['String']['output']
  readonly payApp: PayApp
  /** Rate table items that are associated with this attachment */
  readonly rateTableItems: ReadonlyArray<PayAppRateTableItem>
  /** Optional reference to a SOV Line Item Progress that the attachment is associated with */
  readonly sovLineItemProgress: Maybe<SovLineItemProgress>
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * Unique upload identifier provided by clients when adding an attachment.
   * This allows clients to optimistically render attachments while uploading, then re-render them
   * when uploads complete.
   */
  readonly uploadId: Scalars['ID']['output']
}

export type PayAppAttachmentInput = {
  readonly description?: InputMaybe<Scalars['String']['input']>
  readonly file: Scalars['Upload']['input']
  readonly isEmailAttachment?: InputMaybe<Scalars['Boolean']['input']>
  readonly isInternalOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly isRecurring?: InputMaybe<Scalars['Boolean']['input']>
  readonly name: Scalars['String']['input']
  readonly rateTableItemIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly sovLineItemProgressId?: InputMaybe<Scalars['ID']['input']>
  readonly uploadId: Scalars['ID']['input']
}

/** Represents a comment from a user on a pay app. */
export type PayAppComment = {
  readonly __typename: 'PayAppComment'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Scalars['String']['output']
  readonly payApp: PayApp
  readonly user: User
}

/** Represents a single event that occurred on a pay app object. */
export type PayAppEvent = {
  readonly __typename: 'PayAppEvent'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  /** Whether the event was inserted via the admin API */
  readonly isAdmin: Scalars['Boolean']['output']
  /** Values in this dictionary depend on the event type */
  readonly metadata: Scalars['JSONObject']['output']
  readonly payApp: PayApp
  readonly type: PayAppEventType
}

export { PayAppEventType }

export type PayAppFormsReadyForReviewInput = {
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly payAppId: Scalars['ID']['input']
  readonly recipientUserIds: ReadonlyArray<Scalars['ID']['input']>
  readonly subject: Scalars['String']['input']
}

export type PayAppInvoiceReadyInput = {
  readonly id: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly recipientUserIds: ReadonlyArray<Scalars['ID']['input']>
  readonly subject: Scalars['String']['input']
}

/** A pay-app level or group-level fee on a T&M pay app. */
export type PayAppRateTableFee = {
  readonly __typename: 'PayAppRateTableFee'
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  /** If provided, this fee applies to a specific group */
  readonly group: Maybe<PayAppRateTableGroup>
  readonly id: Scalars['ID']['output']
  /** If provided, this fee overrides another fee at the pay app (global) level. It must be a group fee in this case. */
  readonly overridesFee: Maybe<PayAppRateTableFee>
  readonly payApp: PayApp
  readonly percent: Scalars['Float']['output']
  /** Link back to the contract rate table fee that was used to create this fee, if it still exists. */
  readonly rateTableFee: Maybe<ContractRateTableFee>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A line item group on a T&M pay app. */
export type PayAppRateTableGroup = {
  readonly __typename: 'PayAppRateTableGroup'
  readonly createdAt: Scalars['DateTime']['output']
  readonly fees: ReadonlyArray<PayAppRateTableFee>
  readonly id: Scalars['ID']['output']
  readonly items: ReadonlyArray<PayAppRateTableItem>
  readonly name: Scalars['String']['output']
  readonly payApp: PayApp
  /** Link back to the rate table group that was used to create the pay app item, if it still exists. */
  readonly rateTableGroup: Maybe<RateTableGroup>
  /** Sub-total amount before any fee applies */
  readonly subtotalAmount: Scalars['SafeInt']['output']
  /** Total amount after all fees */
  readonly totalAmount: Scalars['SafeInt']['output']
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A line item on a T&M pay app. */
export type PayAppRateTableItem = {
  readonly __typename: 'PayAppRateTableItem'
  /** Attachments that are associated with this item */
  readonly attachments: ReadonlyArray<PayAppAttachment>
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly currentBilled: Scalars['SafeInt']['output']
  readonly currentUnitsBilled: Scalars['Float']['output']
  readonly description: Scalars['String']['output']
  /** Group that the line item belongs to */
  readonly group: PayAppRateTableGroup
  readonly id: Scalars['ID']['output']
  /** Whether this item was manually added to the pay app and doesn't have a matching rate table item */
  readonly isOneOffItem: Scalars['Boolean']['output']
  readonly payApp: PayApp
  /** Link back to the rate table item that was used to create the pay app item, if it still exists. */
  readonly rateTableItem: Maybe<RateTableItem>
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitRate: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Represents a single requirement for a document that goes into a PayApp. */
export type PayAppRequirement = {
  readonly __typename: 'PayAppRequirement'
  /**
   * Conditions that must be fulfilled for this requirement to appear in a PDF package.
   * If no condition is provided (empty array), the requirement always appears.
   * If there are one or more conditions, the requirement only appears if all conditions are fulfilled.
   */
  readonly conditions: ReadonlyArray<PayAppRequirementCondition>
  readonly createdAt: Scalars['DateTime']['output']
  /** Order of the requirement in its own group. */
  readonly groupOrder: Scalars['Int']['output']
  readonly id: Scalars['ID']['output']
  readonly payAppRequirementGroup: PayAppRequirementGroup
  /**
   * When null, this pay app requirement shows no template.
   * This is used to skip a template altogether for a specific condition (eg: for retention pay apps)
   */
  readonly templateVariant: Maybe<FormTemplateVariant>
  readonly updatedAt: Scalars['DateTime']['output']
}

export { PayAppRequirementCondition }

/**
 * Pay-apps determine which requirements to show by iterating over each requirement group and picking
 * one requirement from the group. Requirements are selected by picking the first one in their group
 * which meets all its conditions. It is possible that a requirement group has no eligible requirement
 * to show, in which case the group is ignored altogether.
 * Group order is determined by `PayAppRequirementGroup.order`.
 * Example:
 * - Group (order = 1)
 *   - Requirement (groupOrder = 1)
 *   - Requirement (groupOrder = 2)
 * - Group (order = 2)
 *   - Requirement (groupOrder = 1)
 *   - Requirement (groupOrder = 2)
 *   - Requirement (groupOrder = 3)
 */
export type PayAppRequirementGroup = {
  readonly __typename: 'PayAppRequirementGroup'
  readonly contract: Maybe<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** The order of the group as displayed in the pay-app */
  readonly order: Scalars['Int']['output']
  readonly payAppRequirements: ReadonlyArray<PayAppRequirement>
  readonly updatedAt: Scalars['DateTime']['output']
}

export type PayAppRetentionPercent = {
  readonly lineItemId?: InputMaybe<Scalars['ID']['input']>
  readonly payAppRetentionAdjustedOnId?: InputMaybe<Scalars['ID']['input']>
  readonly preSiteline: Scalars['Boolean']['input']
  readonly retentionPercent: Scalars['Float']['input']
}

export { PayAppStatus }

/** Breakdown of statuses for all pay apps in a month */
export type PayAppStatusBreakdown = {
  readonly __typename: 'PayAppStatusBreakdown'
  readonly draftOnTime: Scalars['SafeInt']['output']
  readonly notBillingThisMonth: Scalars['SafeInt']['output']
  readonly pastDue: Scalars['SafeInt']['output']
  readonly submitted: Scalars['SafeInt']['output']
}

export { PayAppStatusFilter }

export type PayAppStatusInput = {
  readonly id: Scalars['ID']['input']
  readonly status: PayAppStatus
}

/** Represents a log of all statuses for a pay app and when the transition occurred. */
export type PayAppStatusLog = {
  readonly __typename: 'PayAppStatusLog'
  /** Time when the log was created. This should be used to determine the latest status on a pay app. */
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly payApp: PayApp
  readonly status: PayAppStatus
  /**
   * This is a user-visible time for when the status was changed. For example, the pay app may be
   * marked as paid on the UI at one point (which would be the createdAt) but the user may enter a
   * different time for when the money actually came in (which would be the statusUpdatedAt).
   */
  readonly statusUpdatedAt: Scalars['DateTime']['output']
  /** This is a required field for all new log entries, but its optional for backwards compatibility. */
  readonly user: Maybe<User>
}

/**
 * The vendor metrics that may be shown on a pay app's sworn statement, based on current commitments on
 * the contract. If this object exists for a VendorContract and PayApp, then that vendor will appear on
 * the pay app's sworn statement form.
 */
export type PayAppSwornStatementVendor = {
  readonly __typename: 'PayAppSwornStatementVendor'
  /**
   * The total amount outstanding in open invoices on this vendor's commitments, to date. This may be
   *  calculated from ERP invoices, or manually entered or edited by the user. Note that this is not the
   *  same as the total commitment amount minus the amount paid, since this includes only what has been
   *  billed.
   */
  readonly amountOutstandingToDate: Maybe<Scalars['SafeInt']['output']>
  /**
   * The total amount paid on this vendor's commitments, to date. This may be calculated from ERP
   *  invoices, or manually entered or edited by the user.
   */
  readonly amountPaidToDate: Maybe<Scalars['SafeInt']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /**
   * When the amounts are updated, either manually or via integration, this field is updated to reflect
   * the details of that update. When this entity is first created, this field should be null.
   */
  readonly lastUpdate: Maybe<PayAppSwornStatementVendorUpdate>
  readonly payApp: PayApp
  /** The sum total of all open commitments whose date is on or before the pay app's billing end date */
  readonly totalCommitmentAmount: Scalars['SafeInt']['output']
  /** The last time the sworn statement metrics were updated */
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export type PayAppSwornStatementVendorUpdate = {
  readonly __typename: 'PayAppSwornStatementVendorUpdate'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** If updated via integration, this is the through date used for fetching vendor invoices */
  readonly integrationInvoicesThroughDate: Maybe<Scalars['DateTime']['output']>
  /**
   * The user who last manually edited the amounts. If the last edit was from an update via
   * integration, or the amounts have not yet been edited, this should be null.
   */
  readonly manuallyUpdatedBy: Maybe<User>
  readonly swornStatementVendor: PayAppSwornStatementVendor
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * The integration from which the amounts were last updated. If the last edit was manual, or the
   * amounts have not yet been edited, this should be null.
   */
  readonly updatedFromIntegrationType: Maybe<IntegrationType>
}

export type PayAppsSubmittedData = {
  readonly __typename: 'PayAppsSubmittedData'
  readonly companyCreatedAt: Scalars['DateTime']['output']
  readonly companyId: Scalars['ID']['output']
  readonly companyName: Scalars['String']['output']
  readonly payAppsSubmittedThisMonth: Scalars['SafeInt']['output']
  readonly sitelinePointOfContactId: Maybe<Scalars['ID']['output']>
  readonly sitelinePointOfContactName: Maybe<Scalars['String']['output']>
}

export { PaymentDateEstimationType }

export type PaymentPeriodDateRange = {
  readonly __typename: 'PaymentPeriodDateRange'
  readonly endDate: Scalars['String']['output']
  readonly startDate: Scalars['String']['output']
}

export type PendingChangeOrderReport = {
  readonly __typename: 'PendingChangeOrderReport'
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  readonly totalAmountApproved: Scalars['SafeInt']['output']
  readonly totalAmountAuthorizedToProceed: Scalars['SafeInt']['output']
  readonly totalAmountInDraft: Scalars['SafeInt']['output']
  readonly totalAmountProceedingWithWork: Scalars['SafeInt']['output']
  readonly totalAmountRequested: Scalars['SafeInt']['output']
  readonly totalOriginalContractAmount: Scalars['SafeInt']['output']
  readonly totalRevisedContractAmount: Scalars['SafeInt']['output']
}

export { Permission }

export { PhysicalSignatureRequired }

/** A template version pinned on a pay-app. Templates are pinned at signing time. */
export type PinnedPayAppTemplate = {
  readonly __typename: 'PinnedPayAppTemplate'
  readonly formTemplateVariant: FormTemplateVariant
  readonly formTemplateVersion: FormTemplateVersion
  readonly order: Scalars['Int']['output']
  readonly payApp: PayApp
}

/**
 * A pay app that was created before the contract was onboarded onto Siteline.
 * It is used purely for aging reports & unconditional lien waivers.
 * It does not act as the source of truth for pre-siteline billing (that's on the contract itself).
 */
export type PreSitelinePayApp = {
  readonly __typename: 'PreSitelinePayApp'
  readonly amountPaid: Maybe<Scalars['SafeInt']['output']>
  readonly billingEnd: Maybe<Scalars['DateTime']['output']>
  readonly billingStart: Maybe<Scalars['DateTime']['output']>
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly currentBilled: Maybe<Scalars['SafeInt']['output']>
  readonly id: Scalars['ID']['output']
  /**
   * If the amount paid on the pay app doesn't match the amount due, we allow the customer to manually
   * mark the pay app balance as closed so the remaining amount isn't considered due and doesn't appear
   * in aging reports.
   */
  readonly isBalanceManuallyClosed: Scalars['Boolean']['output']
  readonly isFinal: Scalars['Boolean']['output']
  readonly isNotarizingUnconditional: Scalars['Boolean']['output']
  readonly paidAt: Maybe<Scalars['DateTime']['output']>
  readonly payAppFile: Maybe<StoredFile>
  readonly payAppNumber: Scalars['Int']['output']
  readonly paymentDue: Maybe<Scalars['SafeInt']['output']>
  readonly percentComplete: Scalars['Float']['output']
  readonly retentionOnly: Scalars['Boolean']['output']
  readonly status: Maybe<PreSitelinePayAppStatus>
  readonly submittedAt: Maybe<Scalars['DateTime']['output']>
  readonly submittedUnconditionalOutsideOfSiteline: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly unconditionalLienWaiver: Maybe<LienWaiver>
}

export { PreSitelinePayAppStatus }

/** DEPRECATED */
export type PreliminaryNoticesInput = {
  readonly contractId?: InputMaybe<Scalars['ID']['input']>
  readonly vendorId?: InputMaybe<Scalars['ID']['input']>
}

/** Represents a single project that produces payment applications on a regular schedule. */
export type Project = {
  readonly __typename: 'Project'
  readonly architect: Maybe<CompanyProjectMetadata>
  readonly contracts: ReadonlyArray<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly generalContractor: Maybe<CompanyProjectMetadata>
  readonly id: Scalars['ID']['output']
  readonly location: Location
  readonly metadata: ProjectMetadata
  readonly name: Scalars['String']['output']
  readonly owner: Maybe<CompanyProjectMetadata>
  readonly projectNumber: Scalars['String']['output']
  readonly timeZone: Scalars['String']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type ProjectCompanies = {
  readonly __typename: 'ProjectCompanies'
  readonly architects: ReadonlyArray<Company>
  readonly generalContractors: ReadonlyArray<Company>
  readonly owners: ReadonlyArray<Company>
}

export type ProjectDocumentsByVendor = {
  readonly __typename: 'ProjectDocumentsByVendor'
  readonly periodStart: Maybe<Scalars['DateTime']['output']>
  readonly vendor: Vendor
  /** List of documents for different projects of a vendor for a specific period. */
  readonly vendorDocuments: ReadonlyArray<VendorLegalDocument>
}

/** A single project to be asynchronously imported from an integration */
export type ProjectImportOperation = {
  readonly __typename: 'ProjectImportOperation'
  readonly company: Company
  /** If null, the project import was triggered from a manual import (i.e. from Excel or CSV) */
  readonly companyIntegration: Maybe<CompanyIntegration>
  /** Set if the import was successful */
  readonly contract: Maybe<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly error: Maybe<Scalars['String']['output']>
  readonly group: ProjectImportOperationGroup
  readonly groupId: Scalars['ID']['output']
  readonly id: Scalars['ID']['output']
  readonly integrationProjectName: Scalars['String']['output']
  readonly integrationProjectNumber: Maybe<Scalars['String']['output']>
  readonly metadata: Scalars['JSONObject']['output']
  readonly status: ImportOperationStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Contains some stats about a particular import group. Computed for each ProjectImportOperation. */
export type ProjectImportOperationGroup = {
  readonly __typename: 'ProjectImportOperationGroup'
  readonly numFailed: Scalars['SafeInt']['output']
  readonly numSuccessful: Scalars['SafeInt']['output']
  readonly status: ImportOperationStatus
  readonly totalOperations: Scalars['SafeInt']['output']
}

/**
 * Used for bulk importing projects. Identical to `CreateIntegrationInput` except contractId
 * is optional.
 */
export type ProjectImportOperationInput = {
  readonly companyId: Scalars['ID']['input']
  /** If no integration is provided, the import is from a manual Excel or CSV upload */
  readonly companyIntegrationId?: InputMaybe<Scalars['ID']['input']>
  /** Set if we are linking to an existing contract */
  readonly contractId?: InputMaybe<Scalars['ID']['input']>
  readonly integrationProjectName: Scalars['String']['input']
  readonly integrationProjectNumber?: InputMaybe<Scalars['String']['input']>
  /** This is the same object that `CreateIntegrationInput` receives */
  readonly metadata: Scalars['JSONObject']['input']
}

/** DEPRECATED */
export type ProjectLienWaiversByMonth = {
  readonly __typename: 'ProjectLienWaiversByMonth'
  readonly lienWaiversByMonth: ReadonlyArray<LienWaiversByMonth>
  readonly project: Project
  readonly requiresLowerTierLienWaivers: Scalars['Boolean']['output']
}

/** Represents metadata around a project. */
export type ProjectMetadata = {
  readonly __typename: 'ProjectMetadata'
  readonly fieldProgressDueOnDayOfMonth: Scalars['Int']['output']
  readonly payAppDueOnDayOfMonth: Scalars['Int']['output']
}

/**
 * Every field should be provided for lump sum and unit price projects but only amountBilledAndDraftThisMonth
 * will be provided for quick bills (which is why its the only required field)
 */
export type ProjectMetrics = {
  readonly __typename: 'ProjectMetrics'
  readonly amountBilledAndDraftThisMonth: Scalars['SafeInt']['output']
  readonly amountInDraftThisMonth: Maybe<Scalars['SafeInt']['output']>
  readonly balanceToFinish: Maybe<Scalars['SafeInt']['output']>
  readonly billedAndDraftRetentionHeldThisMonth: Maybe<Scalars['SafeInt']['output']>
  readonly completedThruMonth: Maybe<Scalars['SafeInt']['output']>
  readonly percentCompletedThruMonth: Maybe<Scalars['Float']['output']>
  /** Retention held on only submitted pay apps */
  readonly retentionHeldThisMonth: Maybe<Scalars['SafeInt']['output']>
  /** All retention held to date, including draft and submitted pay apps */
  readonly retentionHeldThruMonth: Maybe<Scalars['SafeInt']['output']>
  readonly totalValue: Maybe<Scalars['SafeInt']['output']>
}

export { ProjectOnboardingFormType }

/** DEPRECATED */
export type ProjectsByVendor = {
  readonly __typename: 'ProjectsByVendor'
  readonly month: Scalars['SafeInt']['output']
  readonly projectLienWaivers: ReadonlyArray<LienWaiversByProject>
  readonly vendorId: Scalars['ID']['output']
  readonly vendorName: Scalars['String']['output']
  readonly year: Scalars['SafeInt']['output']
}

export type Query = {
  readonly __typename: 'Query'
  /**
   * Returns an aggregate forecast across all the current user's contracts. If none of the user's
   * contracts has a billing forecast, returns null.
   */
  readonly aggregateBillingForecast: Maybe<AggregateBillingForecast>
  /** Returns an aggregate forecast of cash flow across all the current user's contracts */
  readonly aggregateCashForecast: AggregateCashForecast
  /** Returns data needed for the aging dashboard, with a project breakdown */
  readonly agingDashboard: PayAppAgingDashboard
  /** Returns the auth provider to use for a specific email address */
  readonly authProvider: AuthProvider
  /** Returns data needed for the billing dashboard, with a project breakdown */
  readonly billingDashboard: BillingDashboard
  /** Returns data needed for generating a billing dashboard report with line item breakdown */
  readonly billingLineItemReport: ReadonlyArray<BillingDashboardContractWithLineItems>
  /** Calculates the result of making a retention change, without making a database update */
  readonly calculateRetentionChange: RetentionBillingResult
  /** Gets all change orders for a company. Used for exporting to excel from reporting. */
  readonly changeOrderReport: ChangeOrderReport
  /** Returns the change order request for the given ID */
  readonly changeOrderRequest: ChangeOrderRequest
  /** Returns a paginated list of change order request events for the given change order request. */
  readonly changeOrderRequestEvents: ChangeOrderRequestEventFeed
  /** Returns all change order requests on a given contract */
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  /** Returns a single company that the user has access to */
  readonly company: Company
  /** Gets a list of contacts given a company ID and type of contact we're requesting. */
  readonly companyContacts: ReadonlyArray<CompanyContact>
  /** Returns available tax groups for the given company integration */
  readonly companyIntegrationTaxGroups: ReadonlyArray<IntegrationTaxGroup>
  /** Query to get the current list of all projects at the logged in user's company */
  readonly companyProjects: ReadonlyArray<Project>
  /** Returns all users at a company */
  readonly companyUsers: ReadonlyArray<CompanyUser>
  /** Gets a contract by ID */
  readonly contract: Contract
  /**
   * Returns a single contract from a project by project ID.
   * Added for legacy reasons because URLs rely on project IDs, but we want to load the contract first.
   */
  readonly contractByProjectId: Contract
  /** Query to get the current list of contracts for the logged in user with a list of statuses to include. */
  readonly contracts: ReadonlyArray<Contract>
  /** Counts of contracts by status */
  readonly contractsOverview: ContractListOverview
  /** Query to get the current logged in user's associated company user object.. */
  readonly currentCompanyUser: CompanyUser
  /** Query to get the current list of projects for the logged in user with a list of statuses to include. */
  readonly currentProjects: ReadonlyArray<Project>
  /** Query to get the current logged in user. Must pass a JWT bearer token in Authentication headers. */
  readonly currentUser: User
  /** Query to get the current list of projects for the logged in user that have vendor lien waiver templates */
  readonly currentVendorLienWaiverProjects: ReadonlyArray<Project>
  /** Returns all document types for which a vendor legal requirement exists */
  readonly documentTypes: ReadonlyArray<DocumentType>
  /**
   * Returns an estimated payment date for an outstanding pay app, as well as the method used to
   * come up with that estimate
   */
  readonly estimatePayAppPaymentDate: EstimatedPayAppPaymentDate
  /** Gets a list of all general contractors for a specific company or all companies the user has access to */
  readonly generalContractors: ReadonlyArray<Company>
  /** Generates an invoice code for a given integration based on invoices that already exist */
  readonly generateIntegrationInvoiceCode: Maybe<Scalars['String']['output']>
  /**
   * Gets all the legal document requests by project by vendor for 6 periods of time (depending on
   * the frequency of the document type) starting from the provided periodStart. If the requirement
   * is not recurring or recurs weekly, only 1 period of time is returned since a status is all that
   * is shown on the tracker.
   */
  readonly getLegalDocumentsByProject: ReadonlyArray<VendorDocumentsByProject>
  /**
   * Gets all the legal document requests by vendor by project for 6 periods of time (depending on
   * the frequency of the document type) starting from the provided periodStart. If the requirement
   * is not recurring or recurs weekly, only 1 period of time is returned since a status is all that
   * is shown on the tracker.
   */
  readonly getLegalDocumentsByVendor: ReadonlyArray<ProjectDocumentsByVendor>
  /** Gets the last 3 or 6 months of Lien Waivers by project by vendor */
  readonly getLienWaiversByProject: ReadonlyArray<VendorContractsByProject>
  readonly getLienWaiversByProjectSummary: ReadonlyArray<VendorContractsByProjectSummary>
  /** Gets the last 3 or 6 months of Lien Waivers by vendor by project */
  readonly getLienWaiversByVendor: ReadonlyArray<ProjectsByVendor>
  /** Gets all lien waivers for a project across all of the project's vendors, grouped by month */
  readonly getProjectLienWaiversByMonth: ReadonlyArray<ProjectLienWaiversByMonth>
  /** Gets all lien waivers for a vendor across all of a company's projects, grouped by month */
  readonly getVendorLienWaiversByMonth: ReadonlyArray<VendorLienWaiversByMonth>
  /** Returns available customers for the given integration */
  readonly integrationCustomers: ReadonlyArray<IntegrationCustomer>
  /** Returns a list of general ledger accounts from the given company. */
  readonly integrationGeneralLedgerAccounts: ReadonlyArray<IntegrationGeneralLedgerAccount>
  /** Returns a list of income type numbers for a given integration from the integration service */
  readonly integrationIncomeTypeNumbers: ReadonlyArray<IntegrationIncomeTypeNumber>
  /** Returns a list of projects from the given company that haven't yet been onboarded. */
  readonly integrationProjectsForOnboarding: ReadonlyArray<IntegrationProjectSummary>
  /** Returns vendor invoices for the given integration */
  readonly integrationVendorInvoices: ReadonlyArray<IntegrationVendorInvoice>
  /** Returns a list of vendors from the given company. */
  readonly integrationVendors: ReadonlyArray<IntegrationVendor>
  /** Gets the latest pay app for a specific contract if one exists. */
  readonly latestPayApp: Maybe<PayApp>
  /** Gets a list of all lead PMs on active projects for a specific company or all companies the user has access to */
  readonly leadPMs: ReadonlyArray<ContractUser>
  /** Returns a legal document. */
  readonly legalDocument: LegalDocument
  /** Returns a legal document request by token for the vendor contact to access. */
  readonly legalDocumentRequestWithToken: Maybe<LegalDocumentRequest>
  /** Returns a legal requirement. */
  readonly legalRequirement: LegalRequirement
  /** Gets the lien waiver for a specific month for a contract if one exists. */
  readonly lienWaiverForMonth: Maybe<LienWaiver>
  /** Returns a lien waiver request by ID */
  readonly lienWaiverRequest: LienWaiverRequest
  /** Returns a LienWaiverRequest using an encoded JWT token. */
  readonly lienWaiverRequestWithToken: Maybe<LienWaiverRequest>
  /**
   * When a vendor receives an email prompting them to sign a lien waiver, we take them to a
   * page that lists all of their outstanding lien waivers, organized by company. Here, the
   * passed in encoded JWT token will correspond to the clicked lien waiver request, and
   * all lien waiver requests for that vendor will be returned along with their corresponding
   * tokens.
   */
  readonly lienWaiverRequestsWithToken: ReadonlyArray<LienWaiverRequestWithToken>
  /** Returns a lien waiver associated with a lien waiver request token. */
  readonly lienWaiverWithToken: LienWaiver
  /**
   * Returns the lien waiver details for a single month for a vendor contract
   * This function is whitelisted to skip frontend query throttling, so its name should not be changed
   * without also editing the whitelist in `client.ts`.
   */
  readonly lienWaiversMonth: LienWaiversMonth
  /**
   * Returns the lien waivers summary for a single month for a contract or vendor.
   * This function is whitelisted to skip frontend query throttling, so its name should not be changed
   * without also editing the whitelist in `client.ts`.
   */
  readonly lienWaiversMonthSummary: LienWaiversMonthSummary
  /** Returns a paginated list of notifications for the currently logged in user. */
  readonly notifications: NotificationFeed
  /** Returns all the data needed to display the top-level overview dashboard */
  readonly overviewDashboard: OverviewDashboard
  /** List of paginated contracts according to specific filters, including a payment period breakdown */
  readonly paginatedCashForecastContracts: PaginatedCashForecastContractList
  /** List of paginated contracts according to specific filters */
  readonly paginatedContracts: PaginatedContractList
  /**
   * Returns a list of vendor contracts associated with a given contract or vendor, optionally filtered
   * for only vendor contracts that have not finished collecting lien waivers by the given month
   */
  readonly paginatedVendorContracts: PaginatedVendorContractsList
  /** List of paginated vendors according to specific filters */
  readonly paginatedVendors: PaginatedVendorsList
  /**
   * Returns information about a single pay application for a single project. Logged in user must be a
   * member of the project that this pay app is a part of. User must be a general contractor or member
   * of that contract in order to view this pay app.
   */
  readonly payApp: PayApp
  /** All GraphQL servers need a root query type. This one is empty. */
  readonly placeholder: Scalars['Boolean']['output']
  /** Gets a pre-siteline pay app by ID */
  readonly preSitelinePayApp: PreSitelinePayApp
  /** Returns information about a specific project. Logged in user must be a member of this project. */
  readonly project: Project
  /** Returns all general contractor, owner, and architect companies a subcontractor has worked with */
  readonly projectCompaniesForSubcontractor: ProjectCompanies
  /** Returns all legal requirements for a set of project across all vendors */
  readonly projectLegalRequirements: ReadonlyArray<VendorLegalRequirement>
  readonly rateTable: RateTable
  readonly rateTables: ReadonlyArray<RateTable>
  /** Reads information about a specific project from an integration */
  readonly readIntegrationProject: ImportProjectOnboardingMetadata
  /** Reads information about a specific integration's schedule of values. */
  readonly readIntegrationSov: IntegrationSov
  /**
   * Returns the change set between a contract's current SOV and the SOV from a given integration.
   * If there is no difference between the SOVs, returns null.
   */
  readonly sovChangeSetFromIntegration: Maybe<SovChangeSet>
  /** Gets a list of all SOV line item groups for a provided contract. */
  readonly sovLineItemGroups: ReadonlyArray<SovLineItemGroup>
  /** Returns a single line-item progress */
  readonly sovLineItemProgress: SovLineItemProgress
  /** Returns a user education campaign to present to the user, if a new one exists to target to this user */
  readonly userEducationCampaign: Maybe<UserEducationCampaign>
  /** Returns a single vendor */
  readonly vendor: Vendor
  /** Returns all commitments for a vendor contract, optionally filtered by open/closed status */
  readonly vendorContractCommitments: ReadonlyArray<VendorContractCommitment>
  /** Returns all legal requirements for a vendor across all projects */
  readonly vendorLegalRequirements: ReadonlyArray<VendorLegalRequirement>
  /** Derives a suggested vendor number by searching for a vendor number on projects that have the same GC<>subcontractor combination */
  readonly vendorNumberForGeneralContractor: Maybe<Scalars['String']['output']>
  /** Gets a list of vendors and their contacts given a company ID. */
  readonly vendors: ReadonlyArray<Vendor>
  /** Returns a single write sync operation and its status */
  readonly writeSyncOperation: WriteSyncOperation
  /** Returns a Zendesk SSO JWT token to authenticate users on Zendesk via Siteline */
  readonly zendeskSsoJwt: Scalars['String']['output']
}

export type QueryAggregateBillingForecastArgs = {
  input: AggregateBillingForecastInput
}

export type QueryAggregateCashForecastArgs = {
  input: AggregateCashForecastInput
}

export type QueryAgingDashboardArgs = {
  input: DashboardInput
}

export type QueryAuthProviderArgs = {
  input: GetAuthProviderInput
}

export type QueryBillingDashboardArgs = {
  input: DashboardInput
}

export type QueryBillingLineItemReportArgs = {
  input: DashboardInput
}

export type QueryCalculateRetentionChangeArgs = {
  input: CalculateRetentionChangeInput
}

export type QueryChangeOrderReportArgs = {
  input: ChangeOrderReportInput
}

export type QueryChangeOrderRequestArgs = {
  id: Scalars['ID']['input']
}

export type QueryChangeOrderRequestEventsArgs = {
  input: PaginatedChangeOrderRequestEventInput
}

export type QueryChangeOrderRequestsArgs = {
  contractId: Scalars['ID']['input']
}

export type QueryCompanyArgs = {
  id: Scalars['ID']['input']
}

export type QueryCompanyContactsArgs = {
  input: GetCompanyContactsInput
}

export type QueryCompanyIntegrationTaxGroupsArgs = {
  input: GetCompanyIntegrationTaxGroupsInput
}

export type QueryCompanyProjectsArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryCompanyUsersArgs = {
  id: Scalars['ID']['input']
}

export type QueryContractArgs = {
  id: Scalars['ID']['input']
}

export type QueryContractByProjectIdArgs = {
  input: GetContractByProjectIdInput
}

export type QueryContractsArgs = {
  input: GetContractsInput
}

export type QueryContractsOverviewArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryCurrentCompanyUserArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryCurrentProjectsArgs = {
  input: GetCurrentProjectsInput
}

export type QueryCurrentVendorLienWaiverProjectsArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryDocumentTypesArgs = {
  companyId: Scalars['ID']['input']
}

export type QueryEstimatePayAppPaymentDateArgs = {
  input: EstimatePayAppPaymentDateInput
}

export type QueryGeneralContractorsArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryGenerateIntegrationInvoiceCodeArgs = {
  input: GenerateIntegrationInvoiceCodeInput
}

export type QueryGetLegalDocumentsByProjectArgs = {
  input: GetLegalDocumentsByPeriodInput
}

export type QueryGetLegalDocumentsByVendorArgs = {
  input: GetLegalDocumentsByPeriodInput
}

export type QueryGetLienWaiversByProjectArgs = {
  input: GetLienWaiversByMonthInput
}

export type QueryGetLienWaiversByProjectSummaryArgs = {
  input: GetLienWaiversSummaryByMonthInput
}

export type QueryGetLienWaiversByVendorArgs = {
  input: GetLienWaiversByMonthInput
}

export type QueryGetProjectLienWaiversByMonthArgs = {
  input: GetProjectLienWaiversByMonthInput
}

export type QueryGetVendorLienWaiversByMonthArgs = {
  input: GetVendorLienWaiversByMonthInput
}

export type QueryIntegrationCustomersArgs = {
  input: GetIntegrationCustomersInput
}

export type QueryIntegrationGeneralLedgerAccountsArgs = {
  companyIntegrationId: Scalars['ID']['input']
}

export type QueryIntegrationIncomeTypeNumbersArgs = {
  input: IntegrationIncomeTypeNumbersInput
}

export type QueryIntegrationProjectsForOnboardingArgs = {
  companyIntegrationId: Scalars['ID']['input']
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryIntegrationTaxGroupsArgs = {
  input: GetIntegrationTaxGroupsInput
}

export type QueryIntegrationVendorInvoicesArgs = {
  input: GetIntegrationVendorInvoicesInput
}

export type QueryIntegrationVendorsArgs = {
  companyIntegrationId: Scalars['ID']['input']
}

export type QueryLatestPayAppArgs = {
  input: LatestPayAppInput
}

export type QueryLeadPMsArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryLegalDocumentArgs = {
  id: Scalars['ID']['input']
}

export type QueryLegalDocumentRequestWithTokenArgs = {
  input: GetWithTokenInput
}

export type QueryLegalRequirementArgs = {
  id: Scalars['ID']['input']
}

export type QueryLienWaiverForMonthArgs = {
  input: GetLienWaiverForMonthInput
}

export type QueryLienWaiverRequestArgs = {
  id: Scalars['ID']['input']
}

export type QueryLienWaiverRequestWithTokenArgs = {
  input: GetWithTokenInput
}

export type QueryLienWaiverRequestsWithTokenArgs = {
  input: GetWithTokenInput
}

export type QueryLienWaiverVendorContractsArgs = {
  input: LienWaiverVendorContractsInput
}

export type QueryLienWaiverWithTokenArgs = {
  input: GetWithTokenInput
}

export type QueryLienWaiversMonthArgs = {
  input: LienWaiversMonthInput
}

export type QueryLienWaiversMonthSummaryArgs = {
  input: LienWaiversMonthSummaryInput
}

export type QueryNotificationsArgs = {
  input: PaginatedInput
}

export type QueryOverviewDashboardArgs = {
  input: DashboardInput
}

export type QueryPaginatedCashForecastContractsArgs = {
  input: GetPaginatedCashForecastContractsInput
}

export type QueryPaginatedContractsArgs = {
  input: GetPaginatedContractsInput
}

export type QueryPaginatedVendorContractsArgs = {
  input: PaginatedVendorContractsInput
}

export type QueryPaginatedVendorsArgs = {
  input: GetPaginatedVendorsInput
}

export type QueryPayAppArgs = {
  id: Scalars['ID']['input']
}

export type QueryPreSitelinePayAppArgs = {
  id: Scalars['ID']['input']
}

export type QueryPreliminaryNoticesArgs = {
  input: PreliminaryNoticesInput
}

export type QueryProjectArgs = {
  id: Scalars['ID']['input']
}

export type QueryProjectCompaniesForSubcontractorArgs = {
  subcontractorCompanyId: Scalars['ID']['input']
}

export type QueryProjectLegalRequirementsArgs = {
  input: GetProjectLegalRequirementsInput
}

export type QueryRateTableArgs = {
  id: Scalars['ID']['input']
}

export type QueryRateTablesArgs = {
  input: RateTablesInput
}

export type QueryReadIntegrationProjectArgs = {
  input: ReadIntegrationProjectInput
}

export type QueryReadIntegrationSovArgs = {
  input: ReadIntegrationSovInput
}

export type QuerySovChangeSetFromIntegrationArgs = {
  input: SovChangeSetFromIntegrationInput
}

export type QuerySovLineItemGroupsArgs = {
  contractId: Scalars['ID']['input']
}

export type QuerySovLineItemProgressArgs = {
  id: Scalars['ID']['input']
}

export type QueryVendorArgs = {
  id: Scalars['ID']['input']
}

export type QueryVendorContractCommitmentsArgs = {
  input: VendorContractCommitmentsInput
}

export type QueryVendorLegalRequirementsArgs = {
  vendorIds: ReadonlyArray<Scalars['ID']['input']>
}

export type QueryVendorNumberForGeneralContractorArgs = {
  input: VendorNumberForGeneralContractorInput
}

export type QueryVendorsArgs = {
  input: GetVendorsInput
}

export type QueryWriteSyncOperationArgs = {
  id: Scalars['ID']['input']
}

export type RateTable = {
  readonly __typename: 'RateTable'
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  /**
   * Returns all contracts associated with the rate table. This includes contracts that are currently
   * linked to the rate table, as well as contracts with a pay app linked to the rate table or a
   * change order request linked to the rate table. This may not be the current rate table associated
   * with all associated contracts, as a newer rate table may have been linked since the pay app or
   * COR was billed or priced.
   */
  readonly associatedContracts: ReadonlyArray<Contract>
  readonly company: Company
  /** List of contracts that are currently linked to this rate table */
  readonly contracts: ReadonlyArray<Contract>
  readonly createdAt: Scalars['DateTime']['output']
  /** If the rate table becomes effective as of a certain date, that date may be set here */
  readonly effectiveAt: Maybe<Scalars['DateTime']['output']>
  /** If the rate table expires as of a certain date, that date may be set here */
  readonly expiresAt: Maybe<Scalars['DateTime']['output']>
  readonly groups: ReadonlyArray<RateTableGroup>
  readonly id: Scalars['ID']['output']
  /** Mappings of Siteline entity to Integration entity. Fields depend on the integration type. */
  readonly integrationMappings: Scalars['JSONObject']['output']
  readonly items: ReadonlyArray<RateTableItem>
  readonly lastEditedAt: Scalars['DateTime']['output']
  readonly lastEditedBy: User
  readonly name: Scalars['String']['output']
  readonly status: RateTableStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

export type RateTableGroup = {
  readonly __typename: 'RateTableGroup'
  readonly contractRateTableFees: ReadonlyArray<ContractRateTableFee>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly items: ReadonlyArray<RateTableItem>
  readonly name: Scalars['String']['output']
  readonly rateTable: RateTable
  readonly type: LineItemType
  readonly updatedAt: Scalars['DateTime']['output']
}

export type RateTableItem = {
  readonly __typename: 'RateTableItem'
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly description: Scalars['String']['output']
  readonly group: RateTableGroup
  readonly id: Scalars['ID']['output']
  readonly rateTable: RateTable
  /** Order in which to display the rate table line items. First line item starts at 1. */
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitRate: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type RateTableItemInput = {
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly description: Scalars['String']['input']
  /** If the line item belongs to a rate table group that already exists, this is the ID of that group */
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  readonly id?: InputMaybe<Scalars['String']['input']>
  /** If the line item belongs to a newly created group, this client-side ID is the link to that group */
  readonly linkGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly sortOrder: Scalars['SafeInt']['input']
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitRate: Scalars['SafeInt']['input']
}

export { RateTableStatus }

export type RateTablesInput = {
  readonly companyId: Scalars['ID']['input']
  readonly status?: InputMaybe<ReadonlyArray<RateTableStatus>>
}

export type ReactivateUserInput = {
  readonly companyId: Scalars['ID']['input']
  readonly userId: Scalars['ID']['input']
}

export type ReadIntegrationProjectInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly integrationAssociatedCompanyId?: InputMaybe<Scalars['String']['input']>
  readonly integrationContractId?: InputMaybe<Scalars['String']['input']>
  readonly integrationProjectId: Scalars['String']['input']
}

export type ReadIntegrationSovInput = {
  readonly forceChangeOrderStrategy?: InputMaybe<ImportIntegrationChangeOrdersMethod>
  readonly integrationId: Scalars['ID']['input']
  readonly payAppId?: InputMaybe<Scalars['ID']['input']>
  readonly referenceDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type RejectAndRequestLegalDocumentFromVendorInput = {
  readonly legalDocumentId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
  readonly vendorContactIds: ReadonlyArray<Scalars['ID']['input']>
  readonly vendorContractId: Scalars['String']['input']
}

export type RemoveLegalDocumentFromPayAppInput = {
  readonly legalDocumentId: Scalars['ID']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type RemovePayAppRequirementInput = {
  readonly contractId: Scalars['ID']['input']
  readonly formTemplateId: Scalars['ID']['input']
}

export type RemoveVendorIntegrationInput = {
  readonly companyIntegrationId?: InputMaybe<Scalars['ID']['input']>
  readonly vendorId: Scalars['ID']['input']
}

export type RemoveVendorIntegrationsInput = {
  readonly companyIntegrationIds: ReadonlyArray<Scalars['ID']['input']>
  readonly vendorId: Scalars['ID']['input']
}

export type RemoveVendorLienWaiverFromPayAppInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly vendorLienWaiverId: Scalars['ID']['input']
}

export type RequestChangeOrderReviewInput = {
  readonly changeOrderRequestId?: InputMaybe<Scalars['ID']['input']>
  readonly contractId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly recipientUserIds: ReadonlyArray<Scalars['ID']['input']>
  readonly subject: Scalars['String']['input']
}

export type RequestLegalDocumentsFromVendorInput = {
  readonly legalRequirementId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly periodEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly periodStart?: InputMaybe<Scalars['DateTime']['input']>
  readonly subject: Scalars['String']['input']
  readonly vendorContactIds: ReadonlyArray<Scalars['ID']['input']>
  readonly vendorContractId: Scalars['String']['input']
}

export { RequiredFieldForForms }

export type ResendUserInviteInput = {
  readonly companyId: Scalars['ID']['input']
  readonly userId: Scalars['ID']['input']
}

export type ResetChangeOrderRequestToDraftInput = {
  readonly id: Scalars['ID']['input']
  readonly reason?: InputMaybe<Scalars['String']['input']>
  readonly retainAnnotations: Scalars['Boolean']['input']
}

export type ResetChangeOrderRequestWithContractRateTableFeesInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
}

export type ResetChangeOrderRequestWithContractRateTableInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
}

export type ResetPayAppRetentionHeldOverrideInput = {
  readonly payAppId: Scalars['ID']['input']
  /** If only clearing the retention override on an individual line item, provide the ID */
  readonly progressId?: InputMaybe<Scalars['ID']['input']>
}

export type ResetPayAppRetentionReleasedInput = {
  readonly payAppId: Scalars['ID']['input']
  /** If only clearing retention released on an individual line item, provide the ID */
  readonly progressId?: InputMaybe<Scalars['ID']['input']>
}

export type ResetPayAppToDraftInput = {
  readonly id: Scalars['ID']['input']
  readonly reason?: InputMaybe<Scalars['String']['input']>
  readonly retainAnnotations: Scalars['Boolean']['input']
}

export type ResetPayAppWithContractRateTableFeesInput = {
  readonly payAppId: Scalars['ID']['input']
}

export type ResetPayAppWithContractRateTableInput = {
  readonly payAppId: Scalars['ID']['input']
}

export type RetentionBillingResult = {
  readonly __typename: 'RetentionBillingResult'
  readonly retentionHeldCurrent: Scalars['Float']['output']
  readonly retentionHeldToDate: Scalars['Float']['output']
  readonly retentionReleased: Scalars['Float']['output']
}

export type RetentionChangelog = {
  readonly __typename: 'RetentionChangelog'
  /** Whether payAppChangedIn is retentionOnly, used for sorting the changelog. */
  readonly changedInRetentionOnlyPayApp: Scalars['Boolean']['output']
  /** Contract this retention changelog is for. */
  readonly contract: Contract
  readonly id: Scalars['ID']['output']
  /**
   * The line item for which this retention percent applies. If not specified, it impacts the entire
   * pay app.
   */
  readonly lineItemRetentionAdjustedOn: Maybe<SovLineItem>
  /** The billingEnd of the payAppChangedIn, used for sorting the changelog. */
  readonly monthChangedIn: Maybe<Scalars['DateTime']['output']>
  /** The pay app in which the user set the percent for the past pay app. */
  readonly payAppChangedIn: Maybe<PayApp>
  /**
   * The past pay app for which this retention percent applies. If not specified, it represents
   * retention from before onboarding to Siteline.
   */
  readonly payAppRetentionAdjustedOn: Maybe<PayApp>
  /** Whether this retention is from before the project was added to Siteline. */
  readonly preSiteline: Scalars['Boolean']['output']
  /** The progress retention on this progress or pay app not including the delta. */
  readonly progressRetentionAmount: Scalars['SafeInt']['output']
  /** The progress retention percent as a decimal (0-1). */
  readonly progressRetentionPercent: Scalars['Float']['output']
  /**
   * Amount of retention for this line item or pay app being billed for in the payAppChangedIn. Note:
   * this value is always 0 if the payAppChangedIn is the current pay app because you can't bill for
   * retention in the current month since it hasn't been held yet.
   */
  readonly retentionBilled: Scalars['SafeInt']['output']
  /** Fudge amount of retention on this progress or pay app. */
  readonly retentionDelta: Scalars['SafeInt']['output']
  /** The stored materials retention on this progress or pay app not including the delta. */
  readonly storedMaterialsRetentionAmount: Scalars['SafeInt']['output']
  /** The stored materials retention percent as a decimal (0-1). */
  readonly storedMaterialsRetentionPercent: Scalars['Float']['output']
}

export { RetentionTrackingLevel }

export { Sage100InvoiceTaxType }

export type SaveLowerTierLienWaiverFormValuesInput = {
  /** If the lien waiver has an amount field that's been entered, the amount should be saved */
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly token: Scalars['String']['input']
}

export type SavePayAppFormValuesInput = {
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly payAppId: Scalars['ID']['input']
}

export type SearchResult = {
  readonly __typename: 'SearchResult'
  readonly description: Scalars['String']['output']
  readonly id: Scalars['String']['output']
  readonly score: Scalars['Float']['output']
  readonly type: SearchResultType
}

export { SearchResultType }

export type SelectProjectFormsInput = {
  readonly contractId: Scalars['ID']['input']
  readonly copyFromContractId?: InputMaybe<Scalars['ID']['input']>
  /** Which types of forms to apply the settings below to */
  readonly formTypes: ReadonlyArray<ProjectOnboardingFormType>
  /** Should be provided if selecting a change order log form template */
  readonly includeChangeOrderLogOnPayApps?: InputMaybe<Scalars['Boolean']['input']>
  /** If use default forms is false and no contract id is provided, assume no forms are needed. */
  readonly useDefaultForms: Scalars['Boolean']['input']
}

export type SendLegalDocumentsInput = {
  readonly contractId: Scalars['ID']['input']
  readonly includeTableOfContents: Scalars['Boolean']['input']
  readonly legalDocumentIds: ReadonlyArray<Scalars['ID']['input']>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
}

export type SendLienWaiverRequestsInput = {
  readonly attachments?: InputMaybe<ReadonlyArray<LienWaiverRequestAttachment>>
  readonly defaultAmount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly defaultAmountText?: InputMaybe<Scalars['String']['input']>
  readonly defaultExceptionAmounts?: InputMaybe<Scalars['String']['input']>
  readonly defaultExceptionDates?: InputMaybe<Scalars['String']['input']>
  readonly isJointCheck: Scalars['Boolean']['input']
  readonly lienWaiverRequestIds: ReadonlyArray<Scalars['ID']['input']>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
}

export type SetAmountLineItemAmountInput = {
  readonly amount: Scalars['SafeInt']['input']
  readonly id: Scalars['ID']['input']
}

export type SetAmountLineItemDescriptionInput = {
  readonly description: Scalars['String']['input']
  readonly id: Scalars['ID']['input']
}

export type SetAmountLineItemFeePercentInput = {
  readonly feePercent: Scalars['Float']['input']
  readonly id: Scalars['ID']['input']
}

export type SetAmountLineItemTaxGroupInput = {
  readonly id: Scalars['ID']['input']
  /** Pass null to remove */
  readonly taxGroupId?: InputMaybe<Scalars['ID']['input']>
}

export type SetCurrentBilledInput = {
  readonly currentBilled: Scalars['Float']['input']
  readonly payAppRateTableItemId: Scalars['ID']['input']
}

export type SetCurrentPricingInput = {
  readonly changeOrderRequestRateTableItemId: Scalars['ID']['input']
  readonly currentPricing: Scalars['Float']['input']
}

export type SetIncludeChangeOrderLogInPayAppPackageInput = {
  readonly contractId: Scalars['ID']['input']
  readonly includeChangeOrderLogOnPayApps: Scalars['Boolean']['input']
}

export type SetIntegrationCustomerInput = {
  readonly integrationCustomerId: Scalars['String']['input']
  readonly integrationId: Scalars['ID']['input']
}

export type SetIntegrationTaxGroupInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly integrationTaxGroupId: Scalars['String']['input']
}

export type SetPayAppLineItemSortOrdersInput = {
  readonly orders: ReadonlyArray<LineItemSortOrderInput>
  readonly payAppId: Scalars['ID']['input']
}

export type SignChangeOrderRequestInput = {
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly id: Scalars['ID']['input']
  readonly signature: SignatureInput
}

export type SignLegalDocumentInput = {
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly legalRequirementId: Scalars['ID']['input']
  readonly periodEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly periodStart?: InputMaybe<Scalars['DateTime']['input']>
  readonly subcontractorSignature: SignatureInput
}

export type SignLowerTierLienWaiverInput = {
  /** If the lien waiver has an amount field, the amount should be passed whether signed or uploaded */
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  /** You must provide a filled out form template input OR a file to this request in order to sign */
  readonly formTemplateInput?: InputMaybe<LienWaiverFormTemplateInput>
  readonly token: Scalars['String']['input']
}

export type SignPayAppInput = {
  readonly formAnnotationValues: ReadonlyArray<FormTemplateAnnotationValueInput>
  readonly id: Scalars['ID']['input']
  readonly subcontractorSignature: SignatureInput
}

export type SignUnconditionalLienWaiverForPreSitelinePayAppInput = {
  readonly amount: Scalars['SafeInt']['input']
  readonly formTemplateInput: LienWaiverFormTemplateInput
  readonly payAppId: Scalars['ID']['input']
  readonly throughDate: Scalars['DateTime']['input']
  readonly type: LienWaiverType
}

export type SignUnconditionalLienWaiverInput = {
  /** If the lien waiver has an amount field, the amount should be passed whether signed or uploaded */
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly contractId: Scalars['ID']['input']
  readonly formTemplateInput: LienWaiverFormTemplateInput
  /** If the pay app isn't provided, the lien waiver is for an opted out month. */
  readonly payAppId?: InputMaybe<Scalars['ID']['input']>
  readonly throughDate: Scalars['DateTime']['input']
  readonly type: LienWaiverType
}

/**
 * When a user signs a document, they either use their name + font or they upload a file. Depending on
 * which is chosen, either the name + font are populated or the file name + url + type are populated.
 * There are 2 checks:
 * 1. Confirms that either the user or vendor is specified but not both.
 * 2. Confirms that either a manual signature is specified or a uploaded signature but not both.
 */
export type Signature = {
  readonly __typename: 'Signature'
  readonly createdAt: Scalars['DateTime']['output']
  readonly file: Maybe<StoredFile>
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly signatureFont: Maybe<SignatureFont>
  readonly signatureName: Maybe<Scalars['String']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly user: Maybe<User>
  readonly vendorContact: Maybe<VendorContact>
}

export { SignatureAnnotationType }

export { SignatureFont }

export type SignatureInput = {
  readonly digitalSignatureInput?: InputMaybe<DigitalSignatureInput>
  readonly signatureId?: InputMaybe<Scalars['ID']['input']>
  /**
   * The user will do one of the following for each signature annotation:
   * 1. Use an existing signature: provide a signatureId
   * 2. Create a new manual signature: provide a digitalSignatureInput
   * 3. Upload a signature file: provide an uploadSignatureInput
   *
   * All the annotations that were signed will be included in signedAnnotationIds. All required
   * signatures must be included here. Optional signatures may not be in the list if they were skipped.
   */
  readonly signedAnnotationIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly uploadSignatureInput?: InputMaybe<UploadSignatureInput>
}

export type SimilarTemplate = {
  readonly __typename: 'SimilarTemplate'
  /** Similarity score between the input file and returned template. Range: 0-1, higher is better. Score >= 0.8 is a strong match. */
  readonly score: Scalars['Float']['output']
  readonly template: FormTemplate
  readonly version: FormTemplateVersion
}

export type SkipLegalRequirementPeriodInput = {
  readonly contractId: Scalars['ID']['input']
  readonly legalRequirementId: Scalars['ID']['input']
  /** This is in YYYYMMDD format */
  readonly periodEnd: Scalars['String']['input']
  /** If this is a vendor legal requirement, provide the vendor contract ID */
  readonly vendorContractId?: InputMaybe<Scalars['ID']['input']>
}

/** Represents a full schedule of values for a given project and company. */
export type Sov = {
  readonly __typename: 'Sov'
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lineItemGroups: ReadonlyArray<SovLineItemGroup>
  readonly lineItems: ReadonlyArray<SovLineItem>
  /**
   * A simple helper to share this logic. Runs the formula: (totalBilled / totalValue) to at most 2
   * decimal places.
   */
  readonly progressComplete: Scalars['Float']['output']
  /**
   * This includes both retention and stored materials. Stored materials are only ever billed for once,
   * and then they're considered a part of totalBilled. It makes sense to split it out in
   * SovLineItemProgress as we need to capture that in the specific month.
   */
  readonly totalBilled: Scalars['SafeInt']['output']
  /** This is the total amount of retention that has been accumulated on the entire SOV to date. */
  readonly totalRetention: Scalars['SafeInt']['output']
  /** Sum of totalValue from every SovLineItem. Updated any time the SOV is modified. */
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type SovChangeSet = {
  readonly __typename: 'SovChangeSet'
  readonly additions: ReadonlyArray<LineItemAddition>
  readonly deletions: ReadonlyArray<LineItemDeletion>
  readonly groupAdditions: ReadonlyArray<LineItemGroupAddition>
  readonly groupDeletions: ReadonlyArray<LineItemGroupDeletion>
  readonly groupUpdates: ReadonlyArray<LineItemGroupUpdate>
  readonly updates: ReadonlyArray<LineItemUpdate>
}

export type SovChangeSetCreateLineItem = {
  readonly __typename: 'SovChangeSetCreateLineItem'
  readonly changeOrderApprovedDate: Maybe<Scalars['DateTime']['output']>
  readonly changeOrderEffectiveDate: Maybe<Scalars['DateTime']['output']>
  readonly code: Scalars['String']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  readonly isChangeOrder: Scalars['Boolean']['output']
  readonly latestTotalValue: Scalars['SafeInt']['output']
  readonly name: Scalars['String']['output']
  readonly originalTotalValue: Scalars['SafeInt']['output']
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  readonly previousBilled: Scalars['SafeInt']['output']
  readonly previousStoredMaterials: Maybe<Scalars['SafeInt']['output']>
  readonly sortOrder: Maybe<Scalars['SafeInt']['output']>
  readonly sovLineItemGroupId: Maybe<Scalars['String']['output']>
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
}

export type SovChangeSetCreateLineItemGroup = {
  readonly __typename: 'SovChangeSetCreateLineItemGroup'
  readonly code: Maybe<Scalars['String']['output']>
  readonly name: Scalars['String']['output']
}

export type SovChangeSetCreateLineItemGroupInput = {
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly name: Scalars['String']['input']
}

export type SovChangeSetCreateLineItemInput = {
  readonly changeOrderApprovedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly changeOrderEffectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly code: Scalars['String']['input']
  readonly costCode?: InputMaybe<Scalars['String']['input']>
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly isChangeOrder: Scalars['Boolean']['input']
  readonly latestTotalValue: Scalars['SafeInt']['input']
  readonly name: Scalars['String']['input']
  readonly originalTotalValue: Scalars['SafeInt']['input']
  readonly preSitelineRetentionHeldOverride?: InputMaybe<Scalars['SafeInt']['input']>
  readonly previousBilled: Scalars['SafeInt']['input']
  readonly previousStoredMaterials?: InputMaybe<Scalars['SafeInt']['input']>
  readonly sortOrder?: InputMaybe<Scalars['SafeInt']['input']>
  readonly sovLineItemGroupId?: InputMaybe<Scalars['String']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
}

export type SovChangeSetFromIntegrationInput = {
  readonly integrationId: Scalars['ID']['input']
  readonly onlyChangeOrders?: InputMaybe<Scalars['Boolean']['input']>
}

export type SovChangeSetUpdateLineItem = {
  readonly __typename: 'SovChangeSetUpdateLineItem'
  /** Undefined to ignore, null to remove */
  readonly changeOrderApprovedDate: Maybe<Scalars['DateTime']['output']>
  readonly changeOrderEffectiveDate: Maybe<Scalars['DateTime']['output']>
  readonly code: Maybe<Scalars['String']['output']>
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  readonly isChangeOrder: Maybe<Scalars['Boolean']['output']>
  readonly latestTotalValue: Maybe<Scalars['SafeInt']['output']>
  readonly name: Maybe<Scalars['String']['output']>
  readonly originalTotalValue: Maybe<Scalars['SafeInt']['output']>
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  readonly previousBilled: Maybe<Scalars['SafeInt']['output']>
  readonly previousStoredMaterials: Maybe<Scalars['SafeInt']['output']>
  readonly sortOrder: Maybe<Scalars['SafeInt']['output']>
  readonly sovLineItemGroupId: Maybe<Scalars['String']['output']>
  readonly unitName: Maybe<Scalars['String']['output']>
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
}

export type SovChangeSetUpdateLineItemGroup = {
  readonly __typename: 'SovChangeSetUpdateLineItemGroup'
  readonly code: Maybe<Scalars['String']['output']>
  readonly name: Maybe<Scalars['String']['output']>
}

export type SovChangeSetUpdateLineItemGroupInput = {
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
}

export type SovChangeSetUpdateLineItemInput = {
  readonly changeOrderApprovedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly changeOrderEffectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly costCode?: InputMaybe<Scalars['String']['input']>
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly isChangeOrder?: InputMaybe<Scalars['Boolean']['input']>
  readonly latestTotalValue?: InputMaybe<Scalars['SafeInt']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly originalTotalValue?: InputMaybe<Scalars['SafeInt']['input']>
  readonly preSitelineRetentionHeldOverride?: InputMaybe<Scalars['SafeInt']['input']>
  readonly previousBilled?: InputMaybe<Scalars['SafeInt']['input']>
  readonly previousStoredMaterials?: InputMaybe<Scalars['SafeInt']['input']>
  readonly sortOrder?: InputMaybe<Scalars['SafeInt']['input']>
  readonly sovLineItemGroupId?: InputMaybe<Scalars['String']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
}

/**
 * Represents a single line item from a single SOV.
 * Constraints:
 * - Ensure that a change order always has an approval date, and that a regular line item does not
 *   have an change-order approval date
 */
export type SovLineItem = {
  readonly __typename: 'SovLineItem'
  /**
   * Equivalent to `totalBilled`, except includes progress billed on draft and signed pay apps as well as
   * proposed and paid.
   */
  readonly billedToDate: Scalars['SafeInt']['output']
  /** If this is a change order, this is the date that this SovLineItem was approved. */
  readonly changeOrderApprovedDate: Maybe<Scalars['DateTime']['output']>
  /**
   * If this is a change order and it should start appearing on pay apps in a month other than the
   * month it was approved, this month will be used instead of the approval date. By default, the
   * change order is considered effective in the month it was approved.
   */
  readonly changeOrderEffectiveDate: Maybe<Scalars['DateTime']['output']>
  /**
   * Change order requests that are associated with this line item. All change order requests in
   * this list should be linked to each other as well.
   */
  readonly changeOrderRequests: ReadonlyArray<ChangeOrderRequest>
  readonly code: Scalars['String']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  /** Tracks what initial retention % to initialize progress at for the first pay app. Range 0-1. */
  readonly defaultRetentionPercent: Maybe<Scalars['Float']['output']>
  readonly id: Scalars['ID']['output']
  /** This is set to true if this line item is a part of a change order, false otherwise. */
  readonly isChangeOrder: Scalars['Boolean']['output']
  /** The latest total value for the line item */
  readonly latestTotalValue: Scalars['SafeInt']['output']
  readonly name: Scalars['String']['output']
  /** The original total value of the line item when it was added */
  readonly originalTotalValue: Scalars['SafeInt']['output']
  /** The computed total amount of retention held pre-siteline in cents. */
  readonly preSitelineRetention: Scalars['SafeInt']['output']
  /**
   * Pre-siteline retention override amount in cents.
   * This is required for pay-app/project tracking.
   */
  readonly preSitelineRetentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /**
   * This is the total amount previously billed for this line item if the project is being added to
   * Siteline part of the way through. For new projects this number is set to 0.
   */
  readonly previousBilled: Scalars['SafeInt']['output']
  /**
   * This is the total amount of previously billed stored materials for this line item if the project
   * is being added to Siteline part of the way through. This number is independent from previousBilled
   * and previousBilled includes both progress and stored materials. For new projects this number is
   * set to 0.
   */
  readonly previousStoredMaterials: Scalars['SafeInt']['output']
  readonly progress: ReadonlyArray<SovLineItemProgress>
  /**
   * A simple helper to share this logic. Runs the formula: (totalBilled / totalValue) to at most 2
   * decimal places.
   */
  readonly progressComplete: Scalars['Float']['output']
  /**
   * If defined, it will be assumed that all SovLineItem objects have the sortOrder defined and this
   * will be used to sort in order instead of the `code` field, which is the default sorting method.
   */
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly sov: Sov
  /** Optional group for this line item */
  readonly sovLineItemGroup: Maybe<SovLineItemGroup>
  /** Optional tax rate to apply to the line item. It must be a tax group from the subcontractor company. */
  readonly taxGroup: Maybe<TaxGroup>
  /**
   * This includes both retention and stored materials. Stored materials are only ever billed for once,
   * and then they're considered a part of totalBilled. It makes sense to split it out in
   * SovLineItemProgress as we need to capture that in the specific month. Includes only proposed/paid pay apps.
   */
  readonly totalBilled: Scalars['SafeInt']['output']
  /** This is the total amount of retention that has been accumulated on this line item to date. */
  readonly totalRetention: Scalars['SafeInt']['output']
  /** List of all total values for the line item */
  readonly totalValues: ReadonlyArray<SovLineItemTotalValue>
  /** The human-readable unit of measurement for each unit, e.g. LF, EA, HR for UNIT_PRICE contract. */
  readonly unitName: Maybe<Scalars['String']['output']>
  /** A price per unit, in cents. This is used for UNIT_PRICE contracts. */
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly worksheetLineItems: ReadonlyArray<WorksheetLineItem>
}

/** Represents a group of SovLineItems, denoted primarily by a name. */
export type SovLineItemGroup = {
  readonly __typename: 'SovLineItemGroup'
  readonly code: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lineItems: ReadonlyArray<SovLineItem>
  readonly name: Scalars['String']['output']
  readonly sov: Sov
  readonly updatedAt: Scalars['DateTime']['output']
}

export type SovLineItemInput = {
  readonly changeOrderApprovedDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly changeOrderEffectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly code: Scalars['String']['input']
  readonly costCode?: InputMaybe<Scalars['String']['input']>
  /** Only affects the first pay app containing this line item. Does not affect pre-siteline retention.  */
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly id?: InputMaybe<Scalars['String']['input']>
  readonly isChangeOrder: Scalars['Boolean']['input']
  readonly latestTotalValue?: InputMaybe<Scalars['SafeInt']['input']>
  readonly name: Scalars['String']['input']
  readonly originalTotalValue?: InputMaybe<Scalars['SafeInt']['input']>
  /** preSitelineRetentionHeldPercent will be inferred from the override */
  readonly preSitelineRetentionHeldOverride?: InputMaybe<Scalars['SafeInt']['input']>
  readonly previousBilled: Scalars['SafeInt']['input']
  readonly sortOrder: Scalars['SafeInt']['input']
  readonly sovLineItemGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly taxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
}

/**
 * Represents the progress for a single SOV line item, to be used in monthly pay apps.
 * Constraints:
 * - Ensure that there is only one progress per line-item/pay-app tuple.
 */
export type SovLineItemProgress = {
  readonly __typename: 'SovLineItemProgress'
  /** Amount due, post tax. This is amountDuePreTax + amountDueTaxAmount */
  readonly amountDuePostTax: Scalars['SafeInt']['output']
  /** Current billed - retention held + retention released. This is pre-tax. */
  readonly amountDuePreTax: Scalars['SafeInt']['output']
  /** Tax amount on the amount due. Tax rate is available on sovLineItem.taxGroup */
  readonly amountDueTaxAmount: Scalars['SafeInt']['output']
  /** Optional description of the PayAppAttachments associated with the SovLineItemProgress */
  readonly attachmentsDescription: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  /** Sum of progressBilled + storedMaterialBilled. This does not deduct retention. */
  readonly currentBilled: Scalars['SafeInt']['output']
  /** Amount of retention held just for this period, on currentBilled. This is pre-tax. */
  readonly currentRetention: Scalars['SafeInt']['output']
  /**
   * A list of events related to this progress, like `SET_PROGRESS_BILLED`.
   * Pay-app events, like submissions, are not reflected here. They are reflected on the pay app itself
   */
  readonly events: ReadonlyArray<SovLineItemProgressEvent>
  /** This is the total amount billed in all progress for this line item after this pay app. */
  readonly futureBilled: Scalars['SafeInt']['output']
  readonly id: Scalars['ID']['output']
  /** If a past progress had a retention override on this line item, it's returned here */
  readonly lastProgressWithRetentionHeldOverride: Maybe<SovLineItemProgress>
  /** Retention held percent on the stored materials billing */
  readonly materialsRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  readonly payApp: PayApp
  /** This is the total amount billed in all progress for this line item prior to this pay app. */
  readonly previousBilled: Scalars['SafeInt']['output']
  /** This is the total amount of retention in all progress for this line item prior to this pay app. */
  readonly previousRetention: Scalars['SafeInt']['output']
  /** The amount of previous retention billed for this period. */
  readonly previousRetentionBilled: Scalars['SafeInt']['output']
  /** Progress billed this period, pre-tax. */
  readonly progressBilled: Scalars['SafeInt']['output']
  /** Retention held percent on the progress billing */
  readonly progressRetentionHeldPercent: Maybe<Scalars['Float']['output']>
  /**
   * Retention override amount on the sov line item progress on both progress and material billing. Takes
   * precedence over the progress and materials percents if set.
   */
  readonly retentionHeldOverride: Maybe<Scalars['SafeInt']['output']>
  /** The overall retention held percent as of this progress */
  readonly retentionHeldPercent: Scalars['Float']['output']
  /** Total retention being released for this line item on this progress in cents */
  readonly retentionReleased: Maybe<Scalars['SafeInt']['output']>
  readonly sovLineItem: SovLineItem
  /** Stored materials billed this period, pre-tax. */
  readonly storedMaterialBilled: Scalars['SafeInt']['output']
  /** The line item's total value as of this progress */
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  /**
   * This is the subcontractor user that last modified this progress. Could be empty if this was last
   * modified by a backend system.
   */
  readonly updatedBy: Maybe<User>
  /** Progress for all worksheet line items associated with this progress's SOV line item */
  readonly worksheetLineItemProgress: ReadonlyArray<WorksheetLineItemProgress>
}

/** An event tied to a line-item progress. */
export type SovLineItemProgressEvent = {
  readonly __typename: 'SovLineItemProgressEvent'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  readonly isAdmin: Scalars['Boolean']['output']
  /** Values in this dictionary depend on the event type */
  readonly metadata: Scalars['JSONObject']['output']
  readonly sovLineItemProgress: SovLineItemProgress
  readonly type: SovLineItemProgressEventType
}

export { SovLineItemProgressEventType }

/** A log of the total value of an SOV Line Item at different dates in time */
export type SovLineItemTotalValue = {
  readonly __typename: 'SovLineItemTotalValue'
  /**
   * If set, the total value only applies starting the given approved date. If the
   *  total value changes in a pay app, use the pay app start as the approved date.
   * If it is not set, it is the total value from pre-siteline onboarding.
   */
  readonly approvedDate: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly lineItem: SovLineItem
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

/** Represents a file stored in Cloud Storage */
export type StoredFile = {
  readonly __typename: 'StoredFile'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly ipAddress: Maybe<Scalars['String']['output']>
  readonly name: Scalars['String']['output']
  readonly size: Scalars['SafeInt']['output']
  readonly type: StoredFileType
  readonly updatedAt: Scalars['DateTime']['output']
  readonly uploadedBy: Maybe<User>
  readonly url: Scalars['URL']['output']
}

export { StoredFileType }

/** A set of lien-waiver templates to use for a subcontractor */
export type SubcontractorLienWaiverTemplateSet = {
  readonly __typename: 'SubcontractorLienWaiverTemplateSet'
  readonly conditionalFinalVariant: Maybe<FormTemplateVariant>
  readonly conditionalProgressVariant: Maybe<FormTemplateVariant>
  readonly id: Scalars['ID']['output']
  readonly unconditionalFinalVariant: Maybe<FormTemplateVariant>
  readonly unconditionalProgressVariant: Maybe<FormTemplateVariant>
}

export type SubcontractorLienWaiverTemplateSetInput = {
  readonly conditionalFinalVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly conditionalProgressVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly unconditionalFinalVariantId?: InputMaybe<Scalars['ID']['input']>
  readonly unconditionalProgressVariantId?: InputMaybe<Scalars['ID']['input']>
}

export type SubmitBackupInput = {
  readonly filename?: InputMaybe<Scalars['String']['input']>
  readonly includeForms?: InputMaybe<SubmitPayAppIncludeForms>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly payAppId: Scalars['ID']['input']
  readonly subject: Scalars['String']['input']
}

export type SubmitChangeOrderRequestInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
}

export type SubmitDraftPayAppInput = {
  readonly filename?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['ID']['input']
  readonly includeForms?: InputMaybe<SubmitPayAppIncludeForms>
  readonly includeWatermarks?: InputMaybe<Scalars['Boolean']['input']>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
}

export type SubmitPayAppIncludeForms = {
  readonly allAdditionalBackup: Scalars['Boolean']['input']
  readonly allChangeOrders: Scalars['Boolean']['input']
  readonly allLegalRequirements: Scalars['Boolean']['input']
  readonly allLowerTierLienWaivers: Scalars['Boolean']['input']
  readonly allStoredMaterials: Scalars['Boolean']['input']
  readonly conditionalLienWaiver: Scalars['Boolean']['input']
}

export type SubmitPayAppInput = {
  readonly filename?: InputMaybe<Scalars['String']['input']>
  readonly includeForms?: InputMaybe<SubmitPayAppIncludeForms>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly payAppId: Scalars['ID']['input']
  readonly subject: Scalars['String']['input']
}

export type SubmitUnconditionalLienWaiverForPreSitelinePayAppInput = {
  readonly companyContactIds: ReadonlyArray<Scalars['ID']['input']>
  /** A file should be provided when submitting a notarized lien waiver */
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  readonly lienWaiverId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
}

export type SubmitUnconditionalLienWaiverInput = {
  readonly companyContactIds: ReadonlyArray<Scalars['ID']['input']>
  /** A file should be provided when submitting a notarized lien waiver */
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  readonly lienWaiverId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly paidAt: Scalars['DateTime']['input']
  readonly subject: Scalars['String']['input']
}

export type SubmitVendorLienWaiversInput = {
  readonly lienWaiverIds: ReadonlyArray<Scalars['ID']['input']>
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject: Scalars['String']['input']
}

export type Subscription = {
  readonly __typename: 'Subscription'
  /** Feed for change order request comments */
  readonly newChangeOrderRequestComment: ChangeOrderRequestComment
  /** Feed for change order request events. Returns a change order request because the individual event might be grouped. */
  readonly newChangeOrderRequestEvent: ChangeOrderRequest
  /** Feed for contract comments */
  readonly newContractComment: ContractComment
  /** Feed for contract events. Returns the contract because the individual event might be grouped. */
  readonly newContractEvent: Contract
  /** Feed for pay app comments */
  readonly newPayAppComment: PayAppComment
  /** Feed for pay app events. Returns a pay app because the individual event might be grouped. */
  readonly newPayAppEvent: PayApp
  /** Fired any time a project import operation is completed for a given company */
  readonly projectImportOperationCompleted: ProjectImportOperation
  /**
   * When a new notification is created for the passed in user, it is pushed to the UI. If the
   * notifications panel is open, it will show up at the top of the list.
   */
  readonly pushNotification: Notification
  /** Any time a pay app's status changes, push the data to the relevant pay app pages */
  readonly updatePayApp: PayApp
  /** When a legal document request status updates, the data is pushed to the web. */
  readonly updatedLegalDocumentRequestChanges: LegalDocumentRequest
  /** When a lower-tier lien waiver request status updates, the data is pushed to the status page. */
  readonly updatedLienWaiverRequestChanges: LienWaiverRequest
  /**
   * When an SovLineItemProgressEvent is created for a SovLineItemProgress, the data is pushed to
   * the invoice page.
   */
  readonly updatedSovLineItemProgress: SovLineItemProgressEvent
  /** Any time the given user is updated, the data is pushed to the client. */
  readonly updatedUser: User
  /** Verified a user's company email alias */
  readonly verifiedEmailAlias: CompanyUserEmailAlias
}

export type SubscriptionNewChangeOrderRequestCommentArgs = {
  changeOrderRequestId: Scalars['ID']['input']
}

export type SubscriptionNewChangeOrderRequestEventArgs = {
  changeOrderRequestId: Scalars['ID']['input']
}

export type SubscriptionNewContractCommentArgs = {
  contractId: Scalars['ID']['input']
}

export type SubscriptionNewContractEventArgs = {
  contractId: Scalars['ID']['input']
}

export type SubscriptionNewPayAppCommentArgs = {
  payAppId: Scalars['ID']['input']
}

export type SubscriptionNewPayAppEventArgs = {
  payAppId: Scalars['ID']['input']
}

export type SubscriptionProjectImportOperationCompletedArgs = {
  companyId: Scalars['ID']['input']
}

export type SubscriptionPushNotificationArgs = {
  userId: Scalars['ID']['input']
}

export type SubscriptionUpdatePayAppArgs = {
  id: Scalars['ID']['input']
}

export type SubscriptionUpdatedLegalDocumentRequestChangesArgs = {
  companyId: Scalars['ID']['input']
}

export type SubscriptionUpdatedLienWaiverRequestChangesArgs = {
  companyId: Scalars['ID']['input']
}

export type SubscriptionUpdatedSovLineItemProgressArgs = {
  id: Scalars['ID']['input']
}

export type SubscriptionUpdatedUserArgs = {
  id: Scalars['ID']['input']
}

export type SubscriptionVerifiedEmailAliasArgs = {
  companyUserEmailAliasId: Scalars['ID']['input']
}

export type SyncLineItemInput = {
  readonly integrationLineItemId: Scalars['ID']['input']
  /** Amount to bill for, in cents */
  readonly progressBilled: Scalars['SafeInt']['input']
  /** Amount of retention, in cents */
  readonly retention: Scalars['SafeInt']['input']
}

export type SyncRequestInput = {
  /** Must be provided for Spectrum PAY_APP_LINE_ITEMS syncs. */
  readonly batchNumber?: InputMaybe<Scalars['String']['input']>
  /** Must be provided for hh2-based Sage 300 PAY_APP syncs. */
  readonly draw?: InputMaybe<Scalars['String']['input']>
  /** Must be provided for hh2-based Sage 100 PAY_APP syncs. */
  readonly dueDate?: InputMaybe<Scalars['String']['input']>
  /** Must be provided for hh2-based Sage 100 PAY_APP syncs. */
  readonly integrationAccountId?: InputMaybe<Scalars['String']['input']>
  readonly invoiceCode?: InputMaybe<Scalars['String']['input']>
  readonly invoiceDate?: InputMaybe<Scalars['String']['input']>
  readonly legalDocumentIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly legalRequirementId?: InputMaybe<Scalars['ID']['input']>
  readonly lienWaiverId?: InputMaybe<Scalars['ID']['input']>
  readonly payAppId?: InputMaybe<Scalars['ID']['input']>
  /** Must be provided for hh2-based Sage 300 PAY_APP_LINE_ITEMS syncs. */
  readonly payAppLineItems?: InputMaybe<ReadonlyArray<SyncLineItemInput>>
  /** Optional for hh2-based Sage 100 PAY_APP syncs. */
  readonly taxes?: InputMaybe<SyncRequestTaxesInput>
  readonly type: IntegrationSyncRequestType
}

export type SyncRequestTaxesInput = {
  readonly amount: Scalars['SafeInt']['input']
  readonly integrationAccountId: Scalars['String']['input']
  readonly sitelineSovLineItemIds: ReadonlyArray<Scalars['ID']['input']>
}

export { TaxCalculationType }

/** A single tax rate belonging to a company. */
export type TaxGroup = {
  readonly __typename: 'TaxGroup'
  readonly company: Company
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** List of mappings to integration tax groups */
  readonly integrationMappings: Scalars['JSONObject']['output']
  readonly name: Scalars['String']['output']
  /** Tax percentage for this group, between 0 and 1 */
  readonly taxPercent: Scalars['Float']['output']
  readonly updatedAt: Scalars['DateTime']['output']
}

export type TaxGroupIntegrationMappingInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly integrationTaxGroupId: Scalars['String']['input']
}

export type TemplateBuildStats = {
  readonly __typename: 'TemplateBuildStats'
  readonly builders: ReadonlyArray<TemplateBuildStatsBuilder>
}

export type TemplateBuildStatsBuilder = {
  readonly __typename: 'TemplateBuildStatsBuilder'
  readonly user: User
  /** Number of templates that are waiting for this user */
  readonly waitingOnCount: Scalars['Int']['output']
}

export { TemplateListSortCriteria }

export { TemplateListStatusFilter }

export { TextAlignment }

export type TimeToValueData = {
  readonly __typename: 'TimeToValueData'
  readonly billingByMonth: ReadonlyArray<TimeToValueMonth>
  readonly company: Company
}

export type TimeToValueMonth = {
  readonly __typename: 'TimeToValueMonth'
  readonly cumulativeContractsBilled: Scalars['SafeInt']['output']
  /** Format is YYYY-MM */
  readonly month: Scalars['String']['output']
}

export { Tutorial }

export type UnlinkAmountLineItemFromAttachmentInput = {
  readonly attachmentId: Scalars['ID']['input']
  readonly lineItemId: Scalars['ID']['input']
}

export type UpdateChangeOrderInput = {
  readonly approvedDate: Scalars['DateTime']['input']
  readonly code: Scalars['String']['input']
  readonly effectiveDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly id: Scalars['ID']['input']
  readonly name: Scalars['String']['input']
  readonly payAppId: Scalars['ID']['input']
  readonly sovLineItemGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly taxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly totalValue: Scalars['SafeInt']['input']
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
}

export type UpdateChangeOrderRequestAttachmentInput = {
  readonly description?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['ID']['input']
  readonly isInternalOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateChangeOrderRequestInput = {
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly generalContractorNumber?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['ID']['input']
  readonly internalNumber?: InputMaybe<Scalars['String']['input']>
  readonly name: Scalars['String']['input']
  readonly proceedingWithWork?: InputMaybe<Scalars['Boolean']['input']>
  readonly reason?: InputMaybe<Scalars['String']['input']>
  readonly scheduleImpact?: InputMaybe<Scalars['Float']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitPrice?: InputMaybe<Scalars['Decimal']['input']>
  readonly visibleToGc?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateChangeOrderRequestLinkedChangeOrderRequestsInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly linkedChangeOrderRequestIds: ReadonlyArray<Scalars['ID']['input']>
}

export type UpdateChangeOrderRequestRateTableItemInput = {
  readonly changeOrderRequestRateTableItemId: Scalars['ID']['input']
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly description?: InputMaybe<Scalars['String']['input']>
  /** Null to move to uncategorized, undefined to leave alone */
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitRate?: InputMaybe<Scalars['SafeInt']['input']>
}

export type UpdateCompanyContactInput = {
  readonly id: Scalars['ID']['input']
  readonly removeJobTitle?: InputMaybe<Scalars['Boolean']['input']>
  /** If true, removes the contact's phone number if one is set */
  readonly removePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>
  readonly values: CompanyContactValuesInput
}

export type UpdateCompanyInput = {
  readonly agingIntervalType?: InputMaybe<AgingIntervalType>
  /** If undefined, will ignore. If null, will unset any previous setting. */
  readonly defaultDaysBeforePayAppDue?: InputMaybe<Scalars['Int']['input']>
  /** If undefined, will ignore. If null, will unset any previous setting. */
  readonly defaultPayAppDueOnDayOfMonth?: InputMaybe<Scalars['Int']['input']>
  /** If undefined, will ignore. If null, will unset any previous setting. */
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly defaultShowAuditItemOnSignedPackages?: InputMaybe<Scalars['Boolean']['input']>
  readonly id: Scalars['ID']['input']
  readonly monthlyCreatePayAppReminderDate?: InputMaybe<Scalars['Int']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly nickname?: InputMaybe<Scalars['String']['input']>
  readonly vendorLienWaiverReminderFrequency?: InputMaybe<VendorLienWaiverReminderFrequency>
}

export type UpdateCompanyIntegrationMetadataInput = {
  readonly companyIntegrationId: Scalars['ID']['input']
  readonly metadata: Scalars['JSONObject']['input']
}

export type UpdateCompanyUserInput = {
  readonly companyUser: CompanyUserInput
  readonly companyUserId: Scalars['ID']['input']
}

export type UpdateContractBillingForecastInput = {
  readonly curve?: InputMaybe<ForecastCurve>
  readonly forecastId: Scalars['ID']['input']
  readonly projectedAmountPerMonth?: InputMaybe<ReadonlyArray<Scalars['SafeInt']['input']>>
  /** Zero-indexed month, from 0 to 11 */
  readonly startingMonth: Scalars['SafeInt']['input']
  /** Year in YYYY format */
  readonly startingYear: Scalars['SafeInt']['input']
}

export type UpdateContractCostFromIntegrationInput = {
  readonly integrationId: Scalars['ID']['input']
}

export type UpdateContractCostInput = {
  readonly additionalCommittedCost: Scalars['SafeInt']['input']
  readonly contractId: Scalars['ID']['input']
  readonly costToDate: Scalars['SafeInt']['input']
  readonly totalEstimatedCost: Scalars['SafeInt']['input']
}

export type UpdateContractInput = {
  readonly appliesTaxToRetention?: InputMaybe<Scalars['Boolean']['input']>
  readonly contractDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly contractNumber?: InputMaybe<Scalars['String']['input']>
  readonly daysBeforePayAppDue?: InputMaybe<Scalars['Int']['input']>
  readonly defaultGcContactIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly id: Scalars['ID']['input']
  readonly internalNotes?: InputMaybe<Scalars['String']['input']>
  readonly internalProjectNumber?: InputMaybe<Scalars['String']['input']>
  readonly pastPayAppCount?: InputMaybe<Scalars['Int']['input']>
  readonly paymentTerms?: InputMaybe<Scalars['SafeInt']['input']>
  readonly paymentTermsType?: InputMaybe<ContractPaymentTermsType>
  readonly preSitelineBilled?: InputMaybe<Scalars['SafeInt']['input']>
  readonly selectedAddressId?: InputMaybe<Scalars['ID']['input']>
  readonly showAuditItemOnSignedPackages?: InputMaybe<Scalars['Boolean']['input']>
  readonly vendorNumber?: InputMaybe<Scalars['String']['input']>
  readonly vendorsInternalNotes?: InputMaybe<Scalars['String']['input']>
}

export type UpdateContractLienWaiverThroughDateInput = {
  readonly contractId: Scalars['ID']['input']
  /** Integer corresponding to nth of the month, up to 28. If null, will use last day of the month. */
  readonly throughDate?: InputMaybe<Scalars['SafeInt']['input']>
}

export type UpdateContractRateTableFeeInput = {
  readonly description?: InputMaybe<Scalars['String']['input']>
  readonly feeId: Scalars['ID']['input']
  /** Null to unset, undefined to leave unchanged */
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  /** Null to unset, undefined to leave unchanged */
  readonly overridesFeeId?: InputMaybe<Scalars['ID']['input']>
  readonly percent?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateContractRateTableInput = {
  readonly contractId: Scalars['ID']['input']
  readonly rateTableId: Scalars['ID']['input']
}

export type UpdateContractUsersInput = {
  readonly id: Scalars['ID']['input']
  readonly users: ReadonlyArray<ContractUserInput>
}

export type UpdateIntegrationMetadataInput = {
  readonly id: Scalars['ID']['input']
  /**
   * All keys passed in will be merged with existing metadata. To unset/remove a field, set it to
   *  `undefined`. Keys that aren't passed in will be untouched.
   */
  readonly metadata: Scalars['JSONObject']['input']
}

export type UpdateLegalDocumentInput = {
  readonly id: Scalars['ID']['input']
  readonly periodEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly periodStart?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateLegalRequirementInput = {
  readonly documentType?: InputMaybe<DocumentType>
  readonly formTemplate?: InputMaybe<Scalars['Upload']['input']>
  readonly frequency?: InputMaybe<DocumentExpirationFrequency>
  /**
   * If hasFormTemplate is true and no template is provided, keep the existing template.
   * If hasFormTemplate is true and a template is provided, replace the existing template.
   * If hasFormTemplate is false, delete the existing template if one exists.
   */
  readonly hasFormTemplate: Scalars['Boolean']['input']
  readonly legalRequirementId: Scalars['ID']['input']
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateLienWaiverInput = {
  readonly attachments?: InputMaybe<ReadonlyArray<LienWaiverRequestAttachment>>
  readonly defaultAmount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly defaultAmountText?: InputMaybe<Scalars['String']['input']>
  readonly defaultExceptionAmounts?: InputMaybe<Scalars['String']['input']>
  readonly defaultExceptionDates?: InputMaybe<Scalars['String']['input']>
  readonly defaultVendorContactIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly isJointCheck: Scalars['Boolean']['input']
  readonly lienWaiverId: Scalars['ID']['input']
  readonly message?: InputMaybe<Scalars['String']['input']>
  readonly subject?: InputMaybe<Scalars['String']['input']>
  readonly type: LienWaiverType
}

export type UpdateLienWaiverTemplatesInput = {
  readonly contractId: Scalars['ID']['input']
  readonly templates: SubcontractorLienWaiverTemplateSetInput
}

export type UpdateLocationInput = {
  readonly id: Scalars['ID']['input']
  readonly location: LocationInput
}

export type UpdatePayAppAttachmentInput = {
  readonly description?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['ID']['input']
  readonly isEmailAttachment?: InputMaybe<Scalars['Boolean']['input']>
  readonly isInternalOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly isRecurring?: InputMaybe<Scalars['Boolean']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly rateTableItemIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly sovLineItemProgressId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdatePayAppInput = {
  readonly billingEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly billingStart?: InputMaybe<Scalars['DateTime']['input']>
  readonly id: Scalars['ID']['input']
  readonly invoiceNumber?: InputMaybe<Scalars['String']['input']>
  readonly payAppDueDate?: InputMaybe<Scalars['DateTime']['input']>
  /** Undefined if not updating. Null to unset. */
  readonly predictedPaymentDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdatePayAppPaidAtInput = {
  readonly amountPaid?: InputMaybe<Scalars['SafeInt']['input']>
  readonly clearUnconditionalLienWaiver?: InputMaybe<Scalars['Boolean']['input']>
  readonly isBalanceManuallyClosed?: InputMaybe<Scalars['Boolean']['input']>
  readonly paidAt: Scalars['DateTime']['input']
  readonly payAppId: Scalars['ID']['input']
  /**
   * The user may also edit the submitted or synced date, since it affects aging. The backend will
   * determine whether the status log to update is a submitted or synced log based on the pay app
   * history.
   */
  readonly submittedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdatePayAppRateTableItemInput = {
  readonly code?: InputMaybe<Scalars['String']['input']>
  readonly description?: InputMaybe<Scalars['String']['input']>
  /** Null to move to uncategorized, undefined to leave alone */
  readonly groupId?: InputMaybe<Scalars['ID']['input']>
  readonly payAppRateTableItemId: Scalars['ID']['input']
  readonly unitName?: InputMaybe<Scalars['String']['input']>
  readonly unitRate?: InputMaybe<Scalars['SafeInt']['input']>
}

export type UpdatePayAppRetentionInput = {
  readonly isReleasingRetention?: InputMaybe<Scalars['Boolean']['input']>
  readonly overrideRetentionHeldAmount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly overrideRetentionReleasedAmount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly payAppId: Scalars['ID']['input']
  /** If only updating retention on an individual line item progress, provide the ID */
  readonly progressId?: InputMaybe<Scalars['ID']['input']>
  /** Specify all values that changed */
  readonly updatedRetentionPercent?: InputMaybe<Scalars['Float']['input']>
}

export type UpdatePayAppSubmittedAtInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly submittedAt: Scalars['DateTime']['input']
}

export type UpdatePayAppSwornStatementVendorsInput = {
  /** If this update is calculated from an integration, the through date for the invoices is provided */
  readonly integrationInvoicesThroughDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly payAppId: Scalars['ID']['input']
  /** If this update is calculated from an integration, the integration is provided */
  readonly updatedFromIntegrationType?: InputMaybe<IntegrationType>
  readonly vendors: ReadonlyArray<UpdateSwornStatementVendorAmountsInput>
}

export type UpdatePayAppSyncedAtInput = {
  readonly payAppId: Scalars['ID']['input']
  readonly syncedAt: Scalars['DateTime']['input']
}

export type UpdatePreSitelinePayAppInput = {
  readonly amountPaid?: InputMaybe<Scalars['SafeInt']['input']>
  readonly billingEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly billingStart?: InputMaybe<Scalars['DateTime']['input']>
  readonly currentBilled?: InputMaybe<Scalars['SafeInt']['input']>
  readonly id: Scalars['ID']['input']
  readonly isBalanceManuallyClosed?: InputMaybe<Scalars['Boolean']['input']>
  readonly isNotarizingUnconditional?: InputMaybe<Scalars['Boolean']['input']>
  readonly paidAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly payAppFile?: InputMaybe<Scalars['Upload']['input']>
  readonly paymentDue?: InputMaybe<Scalars['SafeInt']['input']>
  readonly retentionOnly?: InputMaybe<Scalars['Boolean']['input']>
  readonly status?: InputMaybe<PreSitelinePayAppStatus>
  readonly submittedAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly submittedUnconditionalOutsideOfSiteline?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateProjectInput = {
  readonly architect?: InputMaybe<LumpSumProjectCompanyInput>
  readonly companyId: Scalars['ID']['input']
  readonly generalContractor?: InputMaybe<LumpSumProjectCompanyInput>
  readonly id: Scalars['ID']['input']
  readonly location?: InputMaybe<LocationInput>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly owner?: InputMaybe<LumpSumProjectCompanyInput>
  readonly payAppDueOnDayOfMonth?: InputMaybe<Scalars['Int']['input']>
  readonly projectNumber?: InputMaybe<Scalars['String']['input']>
}

export type UpdateQuickBillingProjectInput = {
  readonly companyId: Scalars['ID']['input']
  readonly location: LocationInput
  readonly name: Scalars['String']['input']
  readonly projectId: Scalars['ID']['input']
  readonly projectNumber: Scalars['String']['input']
}

export type UpdateRateTableGroupInput = {
  readonly id: Scalars['ID']['input']
  readonly name: Scalars['String']['input']
  readonly type: LineItemType
}

export type UpdateRateTableInput = {
  /**
   * Any group that doesn't already exist in the database should be defined here, with a client-side
   * `linkGroupId` that is used by rate table items that belong to this group
   */
  readonly createGroups: ReadonlyArray<CreateRateTableGroupInput>
  /** If null, date will be cleared. If undefined, any existing date will be preserved. */
  readonly effectiveAt?: InputMaybe<Scalars['DateTime']['input']>
  /** If null, date will be cleared. If undefined, any existing date will be preserved. */
  readonly expiresAt?: InputMaybe<Scalars['DateTime']['input']>
  readonly id: Scalars['ID']['input']
  readonly items: ReadonlyArray<RateTableItemInput>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly updateGroups: ReadonlyArray<UpdateRateTableGroupInput>
}

export type UpdateRoundRetentionInput = {
  readonly contractId: Scalars['ID']['input']
  readonly roundRetention: Scalars['Boolean']['input']
}

export type UpdateSovInput = {
  /** Default retention percent to use for the first pay app only, and for new change orders. */
  readonly defaultRetentionPercent?: InputMaybe<Scalars['Float']['input']>
  readonly lineItems?: InputMaybe<ReadonlyArray<SovLineItemInput>>
  /** Contract-level pre-siteline retention override. Undefined to ignore, null to reset */
  readonly preSitelineRetentionHeldOverride?: InputMaybe<Scalars['SafeInt']['input']>
  readonly sovId: Scalars['ID']['input']
}

export type UpdateSovLineItemGroupInput = {
  readonly groupCode?: InputMaybe<Scalars['String']['input']>
  readonly groupName?: InputMaybe<Scalars['String']['input']>
  readonly id: Scalars['String']['input']
}

export type UpdateSovLineItemTotalValueInput = {
  readonly sovLineItemProgressId: Scalars['ID']['input']
  readonly totalValue: Scalars['SafeInt']['input']
}

export type UpdateSovWithChangeSetInput = {
  readonly additions: ReadonlyArray<LineItemAdditionInput>
  readonly deletions: ReadonlyArray<LineItemDeletionInput>
  readonly groupAdditions?: InputMaybe<ReadonlyArray<LineItemGroupAdditionInput>>
  readonly groupDeletions?: InputMaybe<ReadonlyArray<LineItemGroupDeletionInput>>
  readonly groupUpdates?: InputMaybe<ReadonlyArray<LineItemGroupUpdateInput>>
  readonly integrationId?: InputMaybe<Scalars['ID']['input']>
  readonly sovId: Scalars['String']['input']
  readonly updates: ReadonlyArray<LineItemUpdateInput>
}

export type UpdateSwornStatementVendorAmountsInput = {
  readonly amountOutstandingToDate?: InputMaybe<Scalars['SafeInt']['input']>
  readonly amountPaidToDate?: InputMaybe<Scalars['SafeInt']['input']>
  readonly vendorContractId: Scalars['ID']['input']
}

export type UpdateTaxCalculationTypeInput = {
  readonly contractId: Scalars['ID']['input']
  readonly defaultTaxGroupId?: InputMaybe<Scalars['ID']['input']>
  readonly taxCalculationType: TaxCalculationType
}

export type UpdateTaxGroupInput = {
  readonly integrationMappings?: InputMaybe<ReadonlyArray<TaxGroupIntegrationMappingInput>>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly taxGroupId: Scalars['ID']['input']
  readonly taxPercent?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateUploadedLegalDocumentInput = {
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  readonly legalDocumentId: Scalars['ID']['input']
  readonly periodEnd?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateUserEmailAliasInput = {
  readonly companyId: Scalars['ID']['input']
  /** If null, will remove an email alias if one exists */
  readonly emailAlias?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserEmailAliasesInput = {
  readonly emailAliases: ReadonlyArray<UpdateUserEmailAliasInput>
}

export type UpdateUserInput = {
  readonly firstName?: InputMaybe<Scalars['String']['input']>
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly lastName?: InputMaybe<Scalars['String']['input']>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  readonly policiesAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateUserOfficeLocationInput = {
  readonly companyId: Scalars['ID']['input']
  readonly locationId: Scalars['ID']['input']
}

export type UpdateVendorContactInput = {
  readonly email?: InputMaybe<Scalars['EmailAddress']['input']>
  readonly fullName?: InputMaybe<Scalars['String']['input']>
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  readonly vendorContactId: Scalars['ID']['input']
}

export type UpdateVendorContractCommitmentInput = {
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly commitmentType?: InputMaybe<VendorContractCommitmentType>
  readonly date?: InputMaybe<Scalars['DateTime']['input']>
  /**
   * If null is provided, the existing file will be removed.
   * If a file is provided and one already exists, it will replace the existing file.
   * If undefined, no changes will be made.
   */
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  readonly id: Scalars['ID']['input']
  readonly includeVendorInSwornStatement?: InputMaybe<Scalars['Boolean']['input']>
  readonly number?: InputMaybe<Scalars['String']['input']>
  readonly payWhenPaid?: InputMaybe<Scalars['Boolean']['input']>
  readonly paymentTerms?: InputMaybe<Scalars['SafeInt']['input']>
}

export type UpdateVendorContractCommitmentStatusInput = {
  readonly closed: Scalars['Boolean']['input']
  readonly id: Scalars['ID']['input']
}

export type UpdateVendorContractInput = {
  readonly legalRequirementIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>
  readonly vendorContractId: Scalars['ID']['input']
}

export type UpdateVendorContractPreliminaryNoticesInput = {
  /**
   * This is the full list of preliminary notices for the vendor contract, including both existing
   * and new notices. If an existing notice is not present in this list, it will be deleted.
   */
  readonly preliminaryNotices: ReadonlyArray<VendorContractPreliminaryNoticeInput>
  readonly vendorContractId: Scalars['ID']['input']
}

export type UpdateVendorInput = {
  readonly address?: InputMaybe<LocationInput>
  readonly integrationVendor?: InputMaybe<VendorIntegrationInput>
  readonly internalNotes?: InputMaybe<Scalars['String']['input']>
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly vendorId: Scalars['ID']['input']
}

export type UpdateVendorLegalRequirementInput = {
  readonly documentType?: InputMaybe<DocumentType>
  readonly frequency?: InputMaybe<DocumentExpirationFrequency>
  /**
   * If hasVendorTemplate is true and no template is provided, keep the existing template.
   * If hasVendorTemplate is true and a template is provided, replace the existing template.
   * If hasVendorTemplate is false, delete the existing template if one exists.
   */
  readonly hasVendorTemplate: Scalars['Boolean']['input']
  readonly legalRequirementId: Scalars['ID']['input']
  readonly name?: InputMaybe<Scalars['String']['input']>
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>
  readonly vendorTemplate?: InputMaybe<Scalars['Upload']['input']>
}

export type UpdateWorksheetLineItemsInput = {
  /** IDs of worksheet line items to be deleted */
  readonly deleteLineItems: ReadonlyArray<Scalars['ID']['input']>
  /** Worksheet line items to create or update */
  readonly lineItems: ReadonlyArray<WorksheetLineItemInput>
  readonly sovId: Scalars['ID']['input']
}

export type UpdateWorksheetProgressBilledInput = {
  readonly progressBilled: Scalars['SafeInt']['input']
  readonly worksheetLineItemProgressId: Scalars['ID']['input']
}

export type UploadChangeOrderScoFileInput = {
  readonly changeOrderRequestId: Scalars['ID']['input']
  readonly scoFile: Scalars['Upload']['input']
}

export type UploadFormTemplatesInput = {
  readonly contractId: Scalars['ID']['input']
  readonly formType: ProjectOnboardingFormType
  readonly formValuesNote?: InputMaybe<Scalars['String']['input']>
  readonly forms: ReadonlyArray<ContractPayAppFormInput>
  /** Should be provided if uploading a change order log form template */
  readonly includeChangeOrderLogOnPayApps?: InputMaybe<Scalars['Boolean']['input']>
  readonly note?: InputMaybe<Scalars['String']['input']>
  readonly repeatingValuesNote?: InputMaybe<Scalars['String']['input']>
  readonly signatureTypes?: InputMaybe<ReadonlyArray<FormTemplateOnboardingSignatureType>>
  readonly specialFormsNote?: InputMaybe<Scalars['String']['input']>
}

export type UploadLegalDocumentInput = {
  readonly file: Scalars['Upload']['input']
  readonly name: Scalars['String']['input']
  readonly periodEnd?: InputMaybe<Scalars['DateTime']['input']>
  readonly periodStart?: InputMaybe<Scalars['DateTime']['input']>
}

export type UploadLegalDocumentWithTokenInput = {
  readonly date?: InputMaybe<Scalars['DateTime']['input']>
  readonly file: Scalars['Upload']['input']
  readonly name: Scalars['String']['input']
  readonly token: Scalars['String']['input']
}

export type UploadLegalDocumentsInput = {
  readonly documents: ReadonlyArray<UploadLegalDocumentInput>
  readonly legalRequirementId: Scalars['ID']['input']
  /** For vendor document uploads, this id is optionally provided */
  readonly vendorContractId?: InputMaybe<Scalars['ID']['input']>
}

export type UploadLienWaiverInput = {
  /** If the lien waiver has an amount field, the user should enter an amount when uploading */
  readonly amount?: InputMaybe<Scalars['SafeInt']['input']>
  readonly file: Scalars['Upload']['input']
  readonly lienWaiverId: Scalars['ID']['input']
  readonly type?: InputMaybe<LienWaiverType>
}

export type UploadPayAppInput = {
  readonly file: Scalars['Upload']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type UploadPreSitelinePayAppFileInput = {
  readonly payAppFile: Scalars['Upload']['input']
  readonly payAppId: Scalars['ID']['input']
}

export type UploadSignatureInput = {
  /** Signature file information is used if the user uploads a new signature file */
  readonly signatureFile: Scalars['Upload']['input']
}

/** Represents a single person that works at a single company. */
export type User = {
  readonly __typename: 'User'
  /** A list of email notifications the user has unsubscribed from. */
  readonly blockedEmails: ReadonlyArray<EmailType>
  /** A list of notifications the user has unsubscribed from. */
  readonly blockedNotifications: ReadonlyArray<NotificationType>
  /**
   * User education campaigns are shown once per user and should be added to this array once shown so
   * they aren't triggered again. Unlike `tutorialsShown`, campaigns are dynamically created in admin and
   * are referenced by ID.
   */
  readonly campaignsShown: ReadonlyArray<UserEducationCampaign>
  readonly companies: ReadonlyArray<Company>
  readonly companyUsers: ReadonlyArray<CompanyUser>
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * When the first signature is used/uploaded, the default signature will be set to that, and then
   * every time a new signature is used or uploaded, it'll override the default.
   * Therefore, this field is essentially just the last used signature.
   */
  readonly defaultSignature: Maybe<Signature>
  readonly email: Scalars['EmailAddress']['output']
  readonly firstName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  /** Whether this user is a Siteline team member with admin dashboard access */
  readonly isSitelineTeamMember: Scalars['Boolean']['output']
  readonly jobTitle: Maybe<Scalars['String']['output']>
  readonly lastName: Scalars['String']['output']
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly policiesAcceptedAt: Maybe<Scalars['DateTime']['output']>
  readonly starredProjects: ReadonlyArray<Project>
  /**
   * Educational tooltips and tutorials that are shown only once per user should be added
   * to this array once seen or dismissed to track that they shouldn't be shown again
   */
  readonly tutorialsShown: ReadonlyArray<Tutorial>
  readonly updatedAt: Scalars['DateTime']['output']
}

/** A user education campaign represents a one-time popup in the app announcing a change to the user */
export type UserEducationCampaign = {
  readonly __typename: 'UserEducationCampaign'
  /** If undefined, will not show any button on the popup */
  readonly buttonLabel: Maybe<Scalars['String']['output']>
  /** If `buttonLabel` is defined but not `buttonLink`, the button will dismiss the popup */
  readonly buttonLink: Maybe<Scalars['String']['output']>
  /** The Siteline user that created this campaign */
  readonly createdBy: User
  /** The campaign will run only up to the end date */
  readonly endDate: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly message: Scalars['String']['output']
  /** An internal name for the campaign for tracking, not to be exposed to the user */
  readonly name: Scalars['String']['output']
  /** If true, a 'NEW' tag is shown at the top of the popup */
  readonly showNewTag: Scalars['Boolean']['output']
  /** The campaign will only be shown to users after the start date */
  readonly startDate: Scalars['DateTime']['output']
  /** If defined, the campaign will only be shown to a user with all given permissions */
  readonly targetPermissions: ReadonlyArray<Permission>
  readonly title: Scalars['String']['output']
}

export type UserInput = {
  readonly email: Scalars['String']['input']
  readonly firstName: Scalars['String']['input']
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>
  readonly lastName: Scalars['String']['input']
}

export { UserStatus }

export type Vendor = {
  readonly __typename: 'Vendor'
  readonly address: Maybe<Location>
  readonly company: Company
  /** The company integrations this vendor is linked to */
  readonly companyIntegrations: ReadonlyArray<CompanyIntegration>
  readonly complianceStatus: VendorComplianceStatus
  /** The sum of amounts on all conditional lien waivers requested for this vendor */
  readonly conditionalAmountWaived: Scalars['SafeInt']['output']
  readonly contacts: ReadonlyArray<VendorContact>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /** Mappings of Siteline entities to Integration entities. Fields depend on the integration type. */
  readonly integrationMappings: Scalars['JSONObject']['output']
  /** Internal notes and reminders about the vendor, added by users internally */
  readonly internalNotes: Maybe<Scalars['String']['output']>
  readonly name: Scalars['String']['output']
  readonly numContracts: Scalars['Int']['output']
  /** The sum of amounts on all unconditional lien waivers requested for this vendor */
  readonly unconditionalAmountWaived: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContracts: ReadonlyArray<VendorContract>
}

export { VendorComplianceStatus }

/** Represents a contact at a vendor or lower-tier contractor who receives lien waiver or document requests */
export type VendorContact = {
  readonly __typename: 'VendorContact'
  /** An archived vendor contact should not be surfaced to the user, except relating to past document requests */
  readonly archivedAt: Maybe<Scalars['DateTime']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  /**
   * When the first signature is used/uploaded, the default signature will be set to that, and then
   * every time a new signature is used or uploaded, it'll override the default.
   * Therefore, this field is essentially just the last used signature.
   */
  readonly defaultSignature: Maybe<Signature>
  readonly email: Scalars['EmailAddress']['output']
  readonly fullName: Scalars['String']['output']
  readonly id: Scalars['ID']['output']
  readonly jobTitle: Maybe<Scalars['String']['output']>
  /** When a contact is used on a sub-tier lien waiver, it shouldn't overwrite primary vendor contacts */
  readonly lowerTierTo: Maybe<Vendor>
  /**
   * Whether this vendor contact has opted out of digital signatures.
   * If yes, lien waiver requests must be sent manually, and subcontractors will upload lien waivers directly.
   */
  readonly optedOutOfDigitalSignaturesAt: Maybe<Scalars['DateTime']['output']>
  readonly phoneNumber: Maybe<Scalars['PhoneNumber']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendor: Vendor
}

/** Contract that tracks all the legal requirements that a vendor needs to provide. */
export type VendorContract = {
  readonly __typename: 'VendorContract'
  readonly commitments: ReadonlyArray<VendorContractCommitment>
  /** The sum of amounts on all conditional lien waivers requested for this vendor and contract */
  readonly conditionalAmountWaived: Scalars['SafeInt']['output']
  readonly contract: Contract
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  /**
   * If true, we will automatically create a `PayAppSwornStatementVendor` for every new pay app on this
   * contract. Flipping to true will also create a `PayAppSwornStatementVendor` for every draft pay app
   * on this contract. Flipping to false will remove the corresponding `PayAppSwornStatementVendor`
   * from every draft pay app on this contract. Defaults to false.
   */
  readonly includeInSwornStatements: Scalars['Boolean']['output']
  readonly legalDocumentRequests: ReadonlyArray<LegalDocumentRequest>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  /**
   * If this is a lower-lower-tier vendor, set to the `VendorContract` for the vendor who contracted them.
   * This `VendorContract` must be for another vendor on this project.
   */
  readonly lowerTierTo: Maybe<VendorContract>
  readonly payAppSwornStatements: ReadonlyArray<PayAppSwornStatementVendor>
  readonly preliminaryNotices: ReadonlyArray<VendorPreliminaryNotice>
  /** The sum of amounts on all unconditional lien waivers requested for this vendor and contract */
  readonly unconditionalAmountWaived: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendor: Vendor
  readonly vendorLegalRequirements: ReadonlyArray<VendorLegalRequirement>
}

export type VendorContractCommitment = {
  readonly __typename: 'VendorContractCommitment'
  readonly amount: Scalars['SafeInt']['output']
  readonly closedAt: Maybe<Scalars['DateTime']['output']>
  readonly commitmentType: VendorContractCommitmentType
  readonly createdAt: Scalars['DateTime']['output']
  readonly date: Scalars['DateTime']['output']
  readonly file: Maybe<StoredFile>
  readonly id: Scalars['ID']['output']
  readonly number: Scalars['String']['output']
  readonly payWhenPaid: Scalars['Boolean']['output']
  readonly paymentTerms: Maybe<Scalars['SafeInt']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export { VendorContractCommitmentType }

export type VendorContractCommitmentsInput = {
  readonly contractId: Scalars['ID']['input']
}

export type VendorContractPreliminaryNoticeInput = {
  readonly dateFiled: Scalars['DateTime']['input']
  /** If creating a new notice, provide the uploaded file. If editing an existing notice, leave out. */
  readonly file?: InputMaybe<Scalars['Upload']['input']>
  /** If editing an existing notice, provide the ID. If creating a new notice, omit this field. */
  readonly preliminaryNoticeId?: InputMaybe<Scalars['ID']['input']>
}

/** DEPRECATED */
export type VendorContractsByProject = {
  readonly __typename: 'VendorContractsByProject'
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly month: Scalars['SafeInt']['output']
  readonly projectId: Scalars['ID']['output']
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Scalars['String']['output']
  /** Checks if this month was skipped for the given project */
  readonly skippedMonth: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly vendorContractLienWaivers: ReadonlyArray<LienWaiversByVendorContract>
  readonly year: Scalars['SafeInt']['output']
}

/** DEPRECATED */
export type VendorContractsByProjectSummary = {
  readonly __typename: 'VendorContractsByProjectSummary'
  readonly aggregateConditionalStatus: AggregateLienWaiverStatus
  readonly aggregateUnconditionalStatus: AggregateLienWaiverStatus
  readonly internalProjectNumber: Maybe<Scalars['String']['output']>
  readonly month: Scalars['SafeInt']['output']
  /** Total number of condtional lien waivers */
  readonly numConditionals: Scalars['Int']['output']
  /** The number of conditional lien waivers that have not yet been signed */
  readonly numPendingConditionals: Scalars['Int']['output']
  /** The number of unconditional lien waivers that have not yet been signed */
  readonly numPendingUnconditionals: Scalars['Int']['output']
  /** Total number of uncondtional lien waivers */
  readonly numUnconditionals: Scalars['Int']['output']
  readonly projectId: Scalars['ID']['output']
  readonly projectName: Scalars['String']['output']
  readonly projectNumber: Scalars['String']['output']
  /** Checks if this month was skipped for the given project */
  readonly skippedMonth: Scalars['Boolean']['output']
  readonly timeZone: Scalars['String']['output']
  readonly year: Scalars['SafeInt']['output']
}

export type VendorDocumentsByProject = {
  readonly __typename: 'VendorDocumentsByProject'
  readonly periodStart: Maybe<Scalars['DateTime']['output']>
  readonly project: Project
  /** List of documents for different vendors of a project for a specific period. */
  readonly vendorDocuments: ReadonlyArray<VendorLegalDocument>
}

export type VendorIntegrationInput = {
  readonly companyIntegrationId?: InputMaybe<Scalars['ID']['input']>
  readonly integrationVendorId: Scalars['String']['input']
}

export type VendorLegalDocument = {
  readonly __typename: 'VendorLegalDocument'
  readonly document: Maybe<LegalDocument>
  readonly isSkippedPeriod: Scalars['Boolean']['output']
  readonly vendorRequirement: VendorLegalRequirement
}

/** Metadata about a legal requirement that is for a vendor. */
export type VendorLegalRequirement = {
  readonly __typename: 'VendorLegalRequirement'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly legalRequirement: LegalRequirement
  readonly skippedPeriods: ReadonlyArray<Scalars['String']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export type VendorLienWaiverDashboardSummary = {
  readonly __typename: 'VendorLienWaiverDashboardSummary'
  /** If empty, the company has never requested a lien waiver */
  readonly numOutstandingLienWaiversThisMonth: Maybe<Scalars['SafeInt']['output']>
  readonly outstandingLienWaiversByMonth: ReadonlyArray<OutstandingLienWaiversByMonth>
  readonly outstandingVendors: Scalars['SafeInt']['output']
}

export { VendorLienWaiverReminderFrequency }

/** DEPRECATED */
export type VendorLienWaiversByMonth = {
  readonly __typename: 'VendorLienWaiversByMonth'
  readonly lienWaiversByMonth: ReadonlyArray<LienWaiversByMonth>
  readonly vendor: Vendor
}

export type VendorListSort = {
  readonly criteria: VendorListSortCriteria
  readonly order?: InputMaybe<PaginatedListSortOrder>
}

export { VendorListSortCriteria }

export type VendorNumberForGeneralContractorInput = {
  readonly generalContractorId: Scalars['ID']['input']
  readonly subcontractorCompanyId: Scalars['ID']['input']
}

/** A preliminary notice for a vendor, filed on a certain project */
export type VendorPreliminaryNotice = {
  readonly __typename: 'VendorPreliminaryNotice'
  readonly createdAt: Scalars['DateTime']['output']
  /** The notice date or date filed for the preliminary notice */
  readonly date: Scalars['DateTime']['output']
  readonly file: StoredFile
  readonly id: Scalars['ID']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly vendorContract: VendorContract
}

export type VerifyEmailAliasInput = {
  readonly companyId: Scalars['ID']['input']
  readonly emailAlias: Scalars['String']['input']
  readonly verificationCode: Scalars['String']['input']
}

export type WorksheetLineItem = {
  readonly __typename: 'WorksheetLineItem'
  readonly billedToDate: Scalars['SafeInt']['output']
  readonly code: Scalars['String']['output']
  readonly costCode: Maybe<Scalars['String']['output']>
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly name: Scalars['String']['output']
  readonly previousBilled: Scalars['SafeInt']['output']
  readonly progress: ReadonlyArray<WorksheetLineItemProgress>
  /**
   * This is the order of the worksheet line item relative to other worksheet items on the same SOV
   * line item. Within a single SOV, there may be multiple worksheet line items with the same sort
   * order (e.g. there will likely be many worksheet line items with sort order 1), but every worksheet
   * item corresponding to the same SOV line item should have a unique sort order.
   */
  readonly sortOrder: Scalars['SafeInt']['output']
  readonly sovLineItem: SovLineItem
  readonly totalValue: Scalars['SafeInt']['output']
  readonly unitName: Maybe<Scalars['String']['output']>
  /** Unit price and name are inferred from the SOV line item, and cannot diverge */
  readonly unitPrice: Maybe<Scalars['Decimal']['output']>
  readonly updatedAt: Scalars['DateTime']['output']
}

export type WorksheetLineItemInput = {
  readonly code: Scalars['String']['input']
  readonly costCode?: InputMaybe<Scalars['String']['input']>
  readonly id?: InputMaybe<Scalars['String']['input']>
  readonly name: Scalars['String']['input']
  readonly previousBilled: Scalars['SafeInt']['input']
  readonly sortOrder: Scalars['SafeInt']['input']
  readonly sovLineItemId: Scalars['String']['input']
  readonly totalValue: Scalars['SafeInt']['input']
}

export type WorksheetLineItemProgress = {
  readonly __typename: 'WorksheetLineItemProgress'
  readonly createdAt: Scalars['DateTime']['output']
  readonly id: Scalars['ID']['output']
  readonly payApp: PayApp
  readonly previousBilled: Scalars['SafeInt']['output']
  readonly progressBilled: Scalars['SafeInt']['output']
  readonly totalValue: Scalars['SafeInt']['output']
  readonly updatedAt: Scalars['DateTime']['output']
  readonly updatedBy: Maybe<User>
  readonly worksheetLineItem: WorksheetLineItem
}

export type WriteSyncOperation = {
  readonly __typename: 'WriteSyncOperation'
  readonly createdAt: Scalars['DateTime']['output']
  readonly createdBy: User
  readonly id: Scalars['ID']['output']
  readonly integration: Integration
  readonly legalDocuments: ReadonlyArray<LegalDocument>
  readonly legalRequirement: Maybe<LegalRequirement>
  readonly lienWaivers: ReadonlyArray<LienWaiver>
  readonly payApp: Maybe<PayApp>
  readonly payload: Scalars['JSONObject']['output']
  readonly result: Maybe<Scalars['JSONObject']['output']>
  readonly status: WriteSyncOperationStatus
  readonly updatedAt: Scalars['DateTime']['output']
}

export { WriteSyncOperationStatus }

export type ProjectImportOperationCompletedSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type ProjectImportOperationCompletedSubscription = {
  readonly __typename: 'Subscription'
  readonly projectImportOperationCompleted: {
    readonly __typename: 'ProjectImportOperation'
    readonly id: ID['output']
    readonly status: ImportOperationStatus
    readonly error: String['output'] | null
    readonly integrationProjectNumber: String['output'] | null
    readonly integrationProjectName: String['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly shortName: String['output']
    } | null
    readonly group: {
      readonly __typename: 'ProjectImportOperationGroup'
      readonly status: ImportOperationStatus
      readonly numSuccessful: SafeInt['output']
      readonly numFailed: SafeInt['output']
      readonly totalOperations: SafeInt['output']
    }
  }
}

export type GetContractForManagingUsersQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetContractForManagingUsersQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly role: ContractUserRole
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly addedTeammates: Boolean['output']
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly role: CompanyUserRole
        readonly isSitelineAdmin: Boolean['output']
        readonly status: UserStatus
        readonly permissions: ReadonlyArray<Permission>
        readonly emailAlias: {
          readonly __typename: 'CompanyUserEmailAlias'
          readonly id: ID['output']
          readonly emailAddress: EmailAddress['output']
          readonly isVerified: Boolean['output']
          readonly verificationCodeExpiresAt: DateTime['output']
        } | null
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly jobTitle: String['output'] | null
          readonly email: EmailAddress['output']
          readonly policiesAcceptedAt: DateTime['output'] | null
        }
        readonly companyLocation: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly city: String['output']
        }
      }>
    }
  }
}

export type GetContractForFormsReviewQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type GetContractForFormsReviewQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly defaultFormsReviewContacts: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
}

export type CurrentUserForCompanyContextQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserForCompanyContextQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly companyUsers: ReadonlyArray<{
      readonly __typename: 'CompanyUser'
      readonly id: ID['output']
      readonly role: CompanyUserRole
      readonly permissions: ReadonlyArray<Permission>
      readonly status: UserStatus
      readonly emailAlias: {
        readonly __typename: 'CompanyUserEmailAlias'
        readonly id: ID['output']
        readonly emailAddress: EmailAddress['output']
        readonly isVerified: Boolean['output']
        readonly verificationCodeExpiresAt: DateTime['output']
      } | null
      readonly company: {
        readonly __typename: 'Company'
        readonly integrationMappings: JSONObject['output']
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly metadata: {
          readonly __typename: 'CompanyMetadata'
          readonly monthlyCreatePayAppReminderDate: Int['output'] | null
        }
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type GetContractForProjectContextQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForProjectContextQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly percentComplete: Float['output'] | null
    readonly timeZone: String['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
    } | null
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
    }
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly defaultTaxGroup: { readonly __typename: 'TaxGroup'; readonly id: ID['output'] } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type LocationProperties = {
  readonly __typename: 'Location'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly nickname: String['output'] | null
  readonly street1: String['output'] | null
  readonly street2: String['output'] | null
  readonly city: String['output']
  readonly county: String['output'] | null
  readonly state: String['output']
  readonly country: String['output']
  readonly postalCode: String['output'] | null
  readonly timeZone: String['output']
  readonly coordinates: LatLng['output']
}

export type DraftLocationProperties = {
  readonly __typename: 'DraftLocation'
  readonly street1: String['output'] | null
  readonly street2: String['output'] | null
  readonly city: String['output']
  readonly county: String['output'] | null
  readonly state: String['output']
  readonly country: String['output']
  readonly postalCode: String['output'] | null
  readonly coordinates: LatLng['output']
  readonly timeZone: String['output']
}

export type CompanyIntegrationProperties = {
  readonly __typename: 'CompanyIntegration'
  readonly id: ID['output']
  readonly type: IntegrationType
  readonly archivedAt: DateTime['output'] | null
  readonly shortName: String['output']
  readonly longName: String['output']
  readonly metadata: JSONObject['output']
}

export type CompanyProperties = {
  readonly __typename: 'Company'
  readonly id: ID['output']
  readonly name: String['output']
  readonly nickname: String['output'] | null
  readonly type: CompanyType
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
  readonly agingIntervalType: AgingIntervalType
  readonly defaultRetentionPercent: Float['output'] | null
  readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
  readonly defaultDaysBeforePayAppDue: Int['output'] | null
  readonly enableBillingWorksheets: Boolean['output']
  readonly allowLumpSumOverbilling: Boolean['output']
  readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
  readonly companyIntegrations: ReadonlyArray<{
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly archivedAt: DateTime['output'] | null
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly metadata: JSONObject['output']
  }>
  readonly picture: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
  readonly locations: ReadonlyArray<{
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }>
}

export type SignatureProperties = {
  readonly __typename: 'Signature'
  readonly id: ID['output']
  readonly signatureName: String['output'] | null
  readonly signatureFont: SignatureFont | null
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
  } | null
}

export type MinimalUserProperties = {
  readonly __typename: 'User'
  readonly id: ID['output']
  readonly firstName: String['output']
  readonly lastName: String['output']
  readonly jobTitle: String['output'] | null
  readonly email: EmailAddress['output']
  readonly policiesAcceptedAt: DateTime['output'] | null
}

export type MinimalContractUserProperties = {
  readonly __typename: 'ContractUser'
  readonly id: ID['output']
  readonly role: ContractUserRole
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
  }
}

export type UserProperties = {
  readonly __typename: 'User'
  readonly tutorialsShown: ReadonlyArray<Tutorial>
  readonly id: ID['output']
  readonly firstName: String['output']
  readonly lastName: String['output']
  readonly jobTitle: String['output'] | null
  readonly email: EmailAddress['output']
  readonly policiesAcceptedAt: DateTime['output'] | null
  readonly companies: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly agingIntervalType: AgingIntervalType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
    readonly defaultDaysBeforePayAppDue: Int['output'] | null
    readonly enableBillingWorksheets: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly archivedAt: DateTime['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly metadata: JSONObject['output']
    }>
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
  }>
  readonly defaultSignature: {
    readonly __typename: 'Signature'
    readonly id: ID['output']
    readonly signatureName: String['output'] | null
    readonly signatureFont: SignatureFont | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
  } | null
}

export type CompanyContactProperties = {
  readonly __typename: 'CompanyContact'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly fullName: String['output']
  readonly email: EmailAddress['output']
  readonly companyName: String['output']
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly jobTitle: String['output'] | null
}

export type WorksheetLineItemProperties = {
  readonly __typename: 'WorksheetLineItem'
  readonly id: ID['output']
  readonly code: String['output']
  readonly name: String['output']
  readonly costCode: String['output'] | null
  readonly totalValue: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly unitPrice: Decimal['output'] | null
  readonly unitName: String['output'] | null
  readonly billedToDate: SafeInt['output']
  readonly sortOrder: SafeInt['output']
  readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
}

export type TaxGroupProperties = {
  readonly __typename: 'TaxGroup'
  readonly id: ID['output']
  readonly name: String['output']
  readonly taxPercent: Float['output']
  readonly integrationMappings: JSONObject['output']
}

export type SovLineItemProperties = {
  readonly __typename: 'SovLineItem'
  readonly id: ID['output']
  readonly code: String['output']
  readonly sortOrder: SafeInt['output']
  readonly name: String['output']
  readonly costCode: String['output'] | null
  readonly isChangeOrder: Boolean['output']
  readonly changeOrderApprovedDate: DateTime['output'] | null
  readonly changeOrderEffectiveDate: DateTime['output'] | null
  readonly originalTotalValue: SafeInt['output']
  readonly latestTotalValue: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly billedToDate: SafeInt['output']
  readonly defaultRetentionPercent: Float['output'] | null
  readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
  readonly preSitelineRetention: SafeInt['output']
  readonly unitName: String['output'] | null
  readonly unitPrice: Decimal['output'] | null
  readonly changeOrderRequests: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
  }>
  readonly worksheetLineItems: ReadonlyArray<{
    readonly __typename: 'WorksheetLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly name: String['output']
    readonly costCode: String['output'] | null
    readonly totalValue: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly billedToDate: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
  }>
  readonly taxGroup: {
    readonly __typename: 'TaxGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
    readonly integrationMappings: JSONObject['output']
  } | null
}

export type SovLineItemWithTotalsProperties = {
  readonly __typename: 'SovLineItem'
  readonly billedToDate: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly preSitelineRetention: SafeInt['output']
  readonly id: ID['output']
  readonly code: String['output']
  readonly sortOrder: SafeInt['output']
  readonly name: String['output']
  readonly costCode: String['output'] | null
  readonly isChangeOrder: Boolean['output']
  readonly changeOrderApprovedDate: DateTime['output'] | null
  readonly changeOrderEffectiveDate: DateTime['output'] | null
  readonly originalTotalValue: SafeInt['output']
  readonly latestTotalValue: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly defaultRetentionPercent: Float['output'] | null
  readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
  readonly unitName: String['output'] | null
  readonly unitPrice: Decimal['output'] | null
  readonly changeOrderRequests: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
  }>
  readonly worksheetLineItems: ReadonlyArray<{
    readonly __typename: 'WorksheetLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly name: String['output']
    readonly costCode: String['output'] | null
    readonly totalValue: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly billedToDate: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
  }>
  readonly taxGroup: {
    readonly __typename: 'TaxGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
    readonly integrationMappings: JSONObject['output']
  } | null
}

export type MinimalSovProperties = {
  readonly __typename: 'Sov'
  readonly id: ID['output']
  readonly totalValue: SafeInt['output']
  readonly totalBilled: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly progressComplete: Float['output']
}

export type SovProperties = {
  readonly __typename: 'Sov'
  readonly id: ID['output']
  readonly totalValue: SafeInt['output']
  readonly totalBilled: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly progressComplete: Float['output']
  readonly lineItems: ReadonlyArray<{
    readonly __typename: 'SovLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly sortOrder: SafeInt['output']
    readonly name: String['output']
    readonly costCode: String['output'] | null
    readonly isChangeOrder: Boolean['output']
    readonly changeOrderApprovedDate: DateTime['output'] | null
    readonly changeOrderEffectiveDate: DateTime['output'] | null
    readonly originalTotalValue: SafeInt['output']
    readonly latestTotalValue: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly billedToDate: SafeInt['output']
    readonly defaultRetentionPercent: Float['output'] | null
    readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
    readonly preSitelineRetention: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly name: String['output']
      readonly internalNumber: String['output'] | null
    }>
    readonly worksheetLineItems: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly totalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly unitPrice: Decimal['output'] | null
      readonly unitName: String['output'] | null
      readonly billedToDate: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
    }>
    readonly taxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
  }>
}

export type SovLineItemGroupProperties = {
  readonly __typename: 'SovLineItemGroup'
  readonly id: ID['output']
  readonly name: String['output']
  readonly code: String['output'] | null
}

export type WorksheetLineItemProgressProperties = {
  readonly __typename: 'WorksheetLineItemProgress'
  readonly id: ID['output']
  readonly progressBilled: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly totalValue: SafeInt['output']
  readonly worksheetLineItem: {
    readonly __typename: 'WorksheetLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly name: String['output']
    readonly costCode: String['output'] | null
    readonly unitName: String['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
  }
}

export type SovLineItemProgressProperties = {
  readonly __typename: 'SovLineItemProgress'
  readonly id: ID['output']
  readonly progressBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly storedMaterialBilled: SafeInt['output']
  readonly currentBilled: SafeInt['output']
  readonly previousBilled: SafeInt['output']
  readonly futureBilled: SafeInt['output']
  readonly previousRetention: SafeInt['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly totalValue: SafeInt['output']
  readonly retentionHeldPercent: Float['output']
  readonly retentionHeldOverride: SafeInt['output'] | null
  readonly retentionReleased: SafeInt['output'] | null
  readonly amountDuePreTax: SafeInt['output']
  readonly amountDuePostTax: SafeInt['output']
  readonly amountDueTaxAmount: SafeInt['output']
  readonly lastProgressWithRetentionHeldOverride: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly payApp: {
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly payAppNumber: SafeInt['output']
    }
  } | null
  readonly sovLineItem: {
    readonly __typename: 'SovLineItem'
    readonly id: ID['output']
    readonly code: String['output']
    readonly sortOrder: SafeInt['output']
    readonly name: String['output']
    readonly costCode: String['output'] | null
    readonly isChangeOrder: Boolean['output']
    readonly changeOrderApprovedDate: DateTime['output'] | null
    readonly changeOrderEffectiveDate: DateTime['output'] | null
    readonly originalTotalValue: SafeInt['output']
    readonly latestTotalValue: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly billedToDate: SafeInt['output']
    readonly defaultRetentionPercent: Float['output'] | null
    readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
    readonly preSitelineRetention: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly sovLineItemGroup: {
      readonly __typename: 'SovLineItemGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output'] | null
    } | null
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly name: String['output']
      readonly internalNumber: String['output'] | null
    }>
    readonly worksheetLineItems: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly totalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly unitPrice: Decimal['output'] | null
      readonly unitName: String['output'] | null
      readonly billedToDate: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
    }>
    readonly taxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
  }
  readonly worksheetLineItemProgress: ReadonlyArray<{
    readonly __typename: 'WorksheetLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly worksheetLineItem: {
      readonly __typename: 'WorksheetLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
    }
  }>
}

export type FormTemplateAnnotationProperties = {
  readonly __typename: 'FormTemplateAnnotation'
  readonly id: ID['output']
  readonly userVisibleName: String['output']
  readonly type: FormTemplateAnnotationType
  readonly xStart: Float['output']
  readonly yStart: Float['output']
  readonly width: Float['output']
  readonly height: Float['output']
  readonly pageNumber: Int['output']
  readonly fontFamily: FormTemplateFont
  readonly fontColor: String['output']
  readonly textAlignment: TextAlignment
  readonly syncTag: String['output'] | null
  readonly isOptional: Boolean['output']
  readonly selectedKey: String['output'] | null
  readonly defaultValueKey: String['output'] | null
  readonly prefix: String['output'] | null
  readonly suffix: String['output'] | null
  readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
  readonly doNotRetainOnReset: Boolean['output']
  readonly dynamicFieldTag: String['output'] | null
  readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
  readonly imageType: FormTemplateAnnotationImageType | null
}

export type FormTemplateAnnotationValueProperties = {
  readonly __typename: 'FormTemplateAnnotationValue'
  readonly id: ID['output']
  readonly value: String['output']
  readonly metadata: JSONObject['output']
  readonly annotation: {
    readonly __typename: 'FormTemplateAnnotation'
    readonly id: ID['output']
    readonly userVisibleName: String['output']
    readonly type: FormTemplateAnnotationType
    readonly xStart: Float['output']
    readonly yStart: Float['output']
    readonly width: Float['output']
    readonly height: Float['output']
    readonly pageNumber: Int['output']
    readonly fontFamily: FormTemplateFont
    readonly fontColor: String['output']
    readonly textAlignment: TextAlignment
    readonly syncTag: String['output'] | null
    readonly isOptional: Boolean['output']
    readonly selectedKey: String['output'] | null
    readonly defaultValueKey: String['output'] | null
    readonly prefix: String['output'] | null
    readonly suffix: String['output'] | null
    readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    readonly doNotRetainOnReset: Boolean['output']
    readonly dynamicFieldTag: String['output'] | null
    readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
    readonly imageType: FormTemplateAnnotationImageType | null
  }
}

export type PayAppEventProperties = {
  readonly __typename: 'PayAppEvent'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly isAdmin: Boolean['output']
  readonly type: PayAppEventType
  readonly metadata: JSONObject['output']
  readonly createdBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type PayAppCommentProperties = {
  readonly __typename: 'PayAppComment'
  readonly id: ID['output']
  readonly message: String['output']
  readonly createdAt: DateTime['output']
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly isSitelineTeamMember: Boolean['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type ChangeOrderRequestEventProperties = {
  readonly __typename: 'ChangeOrderRequestEvent'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly type: ChangeOrderRequestEventType
  readonly metadata: JSONObject['output']
  readonly createdBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type ChangeOrderRequestCommentProperties = {
  readonly __typename: 'ChangeOrderRequestComment'
  readonly id: ID['output']
  readonly message: String['output'] | null
  readonly createdAt: DateTime['output']
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly isSitelineTeamMember: Boolean['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type ContractEventProperties = {
  readonly __typename: 'ContractEvent'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly isAdmin: Boolean['output']
  readonly type: ContractEventType
  readonly metadata: JSONObject['output']
  readonly createdBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type ContractCommentProperties = {
  readonly __typename: 'ContractComment'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly type: ContractCommentType
  readonly message: String['output']
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly isSitelineTeamMember: Boolean['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type RateTableGroupProperties = {
  readonly __typename: 'RateTableGroup'
  readonly id: ID['output']
  readonly name: String['output']
  readonly type: LineItemType
}

export type RateTableItemProperties = {
  readonly __typename: 'RateTableItem'
  readonly id: ID['output']
  readonly code: String['output'] | null
  readonly sortOrder: SafeInt['output']
  readonly description: String['output']
  readonly unitName: String['output'] | null
  readonly unitRate: SafeInt['output']
  readonly group: {
    readonly __typename: 'RateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly type: LineItemType
  }
}

export type RateTableFeeProperties = {
  readonly __typename: 'ContractRateTableFee'
  readonly id: ID['output']
  readonly description: String['output']
  readonly percent: Float['output']
  readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
  readonly overridesFee: {
    readonly __typename: 'ContractRateTableFee'
    readonly id: ID['output']
  } | null
}

export type RateTableProperties = {
  readonly __typename: 'RateTable'
  readonly id: ID['output']
  readonly name: String['output']
  readonly status: RateTableStatus
  readonly effectiveAt: DateTime['output'] | null
  readonly expiresAt: DateTime['output'] | null
  readonly lastEditedAt: DateTime['output']
  readonly lastEditedBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
  readonly groups: ReadonlyArray<{
    readonly __typename: 'RateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly type: LineItemType
  }>
  readonly items: ReadonlyArray<{
    readonly __typename: 'RateTableItem'
    readonly id: ID['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly description: String['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly group: {
      readonly __typename: 'RateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: LineItemType
    }
  }>
  readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly billingType: BillingType
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly hasContractRateTableChanged: Boolean['output']
    }>
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly hasContractRateTableChanged: Boolean['output']
    }>
  }>
  readonly associatedContracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly billingType: BillingType
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
    }
    readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly hasContractRateTableChanged: Boolean['output']
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
    }>
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly hasContractRateTableChanged: Boolean['output']
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
    }>
  }>
}

export type MinimalRateTableProperties = {
  readonly __typename: 'RateTable'
  readonly id: ID['output']
  readonly name: String['output']
  readonly status: RateTableStatus
  readonly effectiveAt: DateTime['output'] | null
  readonly expiresAt: DateTime['output'] | null
  readonly lastEditedAt: DateTime['output']
  readonly lastEditedBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
  readonly contracts: ReadonlyArray<{ readonly __typename: 'Contract'; readonly id: ID['output'] }>
  readonly associatedContracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
  }>
}

export type MinimalPayAppProperties = {
  readonly __typename: 'PayApp'
  readonly id: ID['output']
  readonly timeZone: String['output']
  readonly payAppDueDate: DateTime['output']
  readonly internalDueDate: DateTime['output']
  readonly billingStart: DateTime['output']
  readonly billingEnd: DateTime['output']
  readonly billingType: BillingType
  readonly status: PayAppStatus
  readonly invoiceReady: Boolean['output']
  readonly retentionOnly: Boolean['output']
  readonly currentBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly draftSubmittedAt: DateTime['output'] | null
}

export type MinimalPreSitelinePayAppProperties = {
  readonly __typename: 'PreSitelinePayApp'
  readonly id: ID['output']
  readonly billingEnd: DateTime['output'] | null
  readonly status: PreSitelinePayAppStatus | null
  readonly currentBilled: SafeInt['output'] | null
  readonly retentionOnly: Boolean['output']
}

export type MinimalWriteSyncOperationProperties = {
  readonly __typename: 'WriteSyncOperation'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly status: WriteSyncOperationStatus
  readonly payload: JSONObject['output']
  readonly result: JSONObject['output'] | null
  readonly integration: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
  }
}

export type PayAppNoProgressProperties = {
  readonly __typename: 'PayApp'
  readonly createdAt: DateTime['output']
  readonly statusChangedAt: DateTime['output']
  readonly amountPaid: SafeInt['output'] | null
  readonly isBalanceManuallyClosed: Boolean['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly billingType: BillingType
  readonly id: ID['output']
  readonly timeZone: String['output']
  readonly payAppDueDate: DateTime['output']
  readonly internalDueDate: DateTime['output']
  readonly billingStart: DateTime['output']
  readonly billingEnd: DateTime['output']
  readonly status: PayAppStatus
  readonly invoiceReady: Boolean['output']
  readonly retentionOnly: Boolean['output']
  readonly currentBilled: SafeInt['output']
  readonly draftSubmittedAt: DateTime['output'] | null
  readonly lastSync: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly uploadedFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly name: String['output']
  } | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly taxCalculationType: TaxCalculationType
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
    }
  }
  readonly emailedContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
}

export type PayAppRateTableFeeProperties = {
  readonly __typename: 'PayAppRateTableFee'
  readonly id: ID['output']
  readonly percent: Float['output']
  readonly description: String['output']
  readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] } | null
  readonly overridesFee: {
    readonly __typename: 'PayAppRateTableFee'
    readonly id: ID['output']
  } | null
}

export type PayAppRateTableGroupProperties = {
  readonly __typename: 'PayAppRateTableGroup'
  readonly id: ID['output']
  readonly name: String['output']
  readonly totalAmount: SafeInt['output']
  readonly subtotalAmount: SafeInt['output']
  readonly type: LineItemType
  readonly fees: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
    } | null
  }>
}

export type PayAppRateTableItemProperties = {
  readonly __typename: 'PayAppRateTableItem'
  readonly id: ID['output']
  readonly description: String['output']
  readonly code: String['output'] | null
  readonly sortOrder: SafeInt['output']
  readonly unitName: String['output'] | null
  readonly unitRate: SafeInt['output']
  readonly currentBilled: SafeInt['output']
  readonly currentUnitsBilled: Float['output']
  readonly isOneOffItem: Boolean['output']
  readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
  readonly attachments: ReadonlyArray<{
    readonly __typename: 'PayAppAttachment'
    readonly id: ID['output']
  }>
}

export type PayAppRateTableProperties = {
  readonly __typename: 'PayApp'
  readonly rateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
  } | null
  readonly rateTableFees: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
    } | null
  }>
  readonly rateTableGroups: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly totalAmount: SafeInt['output']
    readonly subtotalAmount: SafeInt['output']
    readonly type: LineItemType
    readonly fees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
  }>
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }>
}

export type StoredFileProperties = {
  readonly __typename: 'StoredFile'
  readonly id: ID['output']
  readonly type: StoredFileType
  readonly url: URL['output']
  readonly name: String['output']
  readonly size: SafeInt['output']
}

export type VendorContractCommitmentProperties = {
  readonly __typename: 'VendorContractCommitment'
  readonly id: ID['output']
  readonly commitmentType: VendorContractCommitmentType
  readonly amount: SafeInt['output']
  readonly date: DateTime['output']
  readonly number: String['output']
  readonly payWhenPaid: Boolean['output']
  readonly paymentTerms: SafeInt['output'] | null
  readonly closedAt: DateTime['output'] | null
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly payAppSwornStatements: ReadonlyArray<{
      readonly __typename: 'PayAppSwornStatementVendor'
      readonly id: ID['output']
      readonly totalCommitmentAmount: SafeInt['output']
    }>
  }
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  } | null
}

export type PayAppSwornStatementVendorUpdateProperties = {
  readonly __typename: 'PayAppSwornStatementVendorUpdate'
  readonly id: ID['output']
  readonly updatedAt: DateTime['output']
  readonly updatedFromIntegrationType: IntegrationType | null
  readonly integrationInvoicesThroughDate: DateTime['output'] | null
  readonly manuallyUpdatedBy: { readonly __typename: 'User'; readonly id: ID['output'] } | null
}

export type PayAppSwornStatementVendorProperties = {
  readonly __typename: 'PayAppSwornStatementVendor'
  readonly id: ID['output']
  readonly totalCommitmentAmount: SafeInt['output']
  readonly amountPaidToDate: SafeInt['output'] | null
  readonly amountOutstandingToDate: SafeInt['output'] | null
  readonly lastUpdate: {
    readonly __typename: 'PayAppSwornStatementVendorUpdate'
    readonly id: ID['output']
    readonly updatedAt: DateTime['output']
    readonly updatedFromIntegrationType: IntegrationType | null
    readonly integrationInvoicesThroughDate: DateTime['output'] | null
    readonly manuallyUpdatedBy: { readonly __typename: 'User'; readonly id: ID['output'] } | null
  } | null
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
}

export type PayAppProperties = {
  readonly __typename: 'PayApp'
  readonly payAppNumber: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly createdAt: DateTime['output']
  readonly statusChangedAt: DateTime['output']
  readonly amountPaid: SafeInt['output'] | null
  readonly isBalanceManuallyClosed: Boolean['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly billingType: BillingType
  readonly id: ID['output']
  readonly timeZone: String['output']
  readonly payAppDueDate: DateTime['output']
  readonly internalDueDate: DateTime['output']
  readonly billingStart: DateTime['output']
  readonly billingEnd: DateTime['output']
  readonly status: PayAppStatus
  readonly invoiceReady: Boolean['output']
  readonly retentionOnly: Boolean['output']
  readonly currentBilled: SafeInt['output']
  readonly draftSubmittedAt: DateTime['output'] | null
  readonly progress: ReadonlyArray<{
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }>
  readonly formValues: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotationValue'
    readonly id: ID['output']
    readonly value: String['output']
    readonly metadata: JSONObject['output']
    readonly annotation: {
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly syncTag: String['output'] | null
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
    }
  }>
  readonly events: ReadonlyArray<{
    readonly __typename: 'PayAppEvent'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly isAdmin: Boolean['output']
    readonly type: PayAppEventType
    readonly metadata: JSONObject['output']
    readonly createdBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }>
  readonly lastSync: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly uploadedFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly name: String['output']
  } | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly taxCalculationType: TaxCalculationType
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
    }
  }
  readonly emailedContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly rateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
  } | null
  readonly rateTableFees: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
    } | null
  }>
  readonly rateTableGroups: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly totalAmount: SafeInt['output']
    readonly subtotalAmount: SafeInt['output']
    readonly type: LineItemType
    readonly fees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
  }>
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }>
}

export type SovLineItemProgressEventProperties = {
  readonly __typename: 'SovLineItemProgressEvent'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly isAdmin: Boolean['output']
  readonly type: SovLineItemProgressEventType
  readonly metadata: JSONObject['output']
  readonly createdBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
  }
}

export type VendorContactProperties = {
  readonly __typename: 'VendorContact'
  readonly id: ID['output']
  readonly fullName: String['output']
  readonly email: EmailAddress['output']
  readonly phoneNumber: PhoneNumber['output'] | null
  readonly jobTitle: String['output'] | null
  readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
}

export type MinimalVendorContractProperties = {
  readonly __typename: 'VendorContract'
  readonly id: ID['output']
  readonly vendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
  }
  readonly lowerTierTo: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
  } | null
}

export type MinimalLegalDocumentProperties = {
  readonly __typename: 'LegalDocument'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly periodStart: DateTime['output'] | null
  readonly periodEnd: DateTime['output'] | null
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  } | null
}

export type LegalDocumentRequestProperties = {
  readonly __typename: 'LegalDocumentRequest'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly updatedAt: DateTime['output']
  readonly status: DocumentRequestStatus
  readonly actionTakenAt: DateTime['output'] | null
  readonly vendorContact: {
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }
}

export type LegalDocumentProperties = {
  readonly __typename: 'LegalDocument'
  readonly lastSendTime: DateTime['output'] | null
  readonly isUploaded: Boolean['output']
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly periodStart: DateTime['output'] | null
  readonly periodEnd: DateTime['output'] | null
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly uploadedBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly email: EmailAddress['output']
  } | null
  readonly legalDocumentRequests: ReadonlyArray<{
    readonly __typename: 'LegalDocumentRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  }>
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  } | null
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  } | null
}

export type MinimalLegalRequirementProperties = {
  readonly __typename: 'LegalRequirement'
  readonly id: ID['output']
  readonly name: String['output']
  readonly updatedAt: DateTime['output']
  readonly expirationFrequency: DocumentExpirationFrequency
  readonly startDate: DateTime['output'] | null
  readonly endDate: DateTime['output'] | null
  readonly skippedPeriods: ReadonlyArray<String['output']>
  readonly isVendorRequirement: Boolean['output']
  readonly type: DocumentType
  readonly timeZone: String['output']
}

export type MinimalComplianceLegalRequirementProperties = {
  readonly __typename: 'LegalRequirement'
  readonly id: ID['output']
  readonly name: String['output']
  readonly updatedAt: DateTime['output']
  readonly expirationFrequency: DocumentExpirationFrequency
  readonly startDate: DateTime['output'] | null
  readonly endDate: DateTime['output'] | null
  readonly skippedPeriods: ReadonlyArray<String['output']>
  readonly isVendorRequirement: Boolean['output']
  readonly type: DocumentType
  readonly timeZone: String['output']
  readonly documents: ReadonlyArray<{
    readonly __typename: 'LegalDocument'
    readonly id: ID['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: StoredFileType
    } | null
  }>
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly userVisibleName: String['output']
  } | null
}

export type LegalRequirementProperties = {
  readonly __typename: 'LegalRequirement'
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly id: ID['output']
  readonly name: String['output']
  readonly updatedAt: DateTime['output']
  readonly expirationFrequency: DocumentExpirationFrequency
  readonly startDate: DateTime['output'] | null
  readonly endDate: DateTime['output'] | null
  readonly skippedPeriods: ReadonlyArray<String['output']>
  readonly isVendorRequirement: Boolean['output']
  readonly type: DocumentType
  readonly timeZone: String['output']
  readonly formTemplate: {
    readonly __typename: 'FormTemplate'
    readonly id: String['output']
    readonly userVisibleName: String['output']
    readonly isCustomerReady: Boolean['output']
  } | null
  readonly documents: ReadonlyArray<{
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }>
}

export type VendorLegalRequirementProperties = {
  readonly __typename: 'VendorLegalRequirement'
  readonly id: ID['output']
  readonly skippedPeriods: ReadonlyArray<String['output']>
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
  readonly legalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly legalRequirementContacts: ReadonlyArray<{
        readonly __typename: 'CompanyContact'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly companyName: String['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
      }>
    }
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type VendorLegalRequirementForTrackerProperties = {
  readonly __typename: 'VendorLegalRequirement'
  readonly id: ID['output']
  readonly skippedPeriods: ReadonlyArray<String['output']>
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
  readonly legalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly legalRequirementContacts: ReadonlyArray<{
        readonly __typename: 'CompanyContact'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly companyName: String['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
      }>
    }
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type MinimalIntegrationProperties = {
  readonly __typename: 'Integration'
  readonly id: ID['output']
  readonly type: IntegrationType
  readonly metadata: JSONObject['output']
  readonly mappings: JSONObject['output']
  readonly shortName: String['output']
  readonly longName: String['output']
  readonly isActive: Boolean['output']
  readonly companyIntegration: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly metadata: JSONObject['output']
  }
}

export type MinimalContractProperties = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly role: CompanyProjectRole
  readonly status: ContractStatus
  readonly roundRetention: Boolean['output']
  readonly internalProjectNumber: String['output'] | null
  readonly retentionTrackingLevel: RetentionTrackingLevel
  readonly defaultRetentionPercent: Float['output'] | null
  readonly skippedPayAppMonths: ReadonlyArray<String['output']>
  readonly daysBeforePayAppDue: Int['output']
  readonly billingType: BillingType
  readonly pastPayAppCount: Int['output']
  readonly preSitelineBilled: SafeInt['output'] | null
  readonly vendorNumber: String['output'] | null
  readonly showAuditItemOnSignedPackages: Boolean['output']
  readonly paymentTermsType: ContractPaymentTermsType | null
  readonly paymentTerms: SafeInt['output'] | null
  readonly timeZone: String['output']
  readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
  readonly integrations: ReadonlyArray<{
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }>
}

export type OnboardedProjectContractStatusProperties = {
  readonly __typename: 'OnboardedProjectContractStatus'
  readonly selectedPayAppForms: Boolean['output']
  readonly onboardedPayAppForms: Boolean['output']
  readonly selectedPrimaryLienWaivers: Boolean['output']
  readonly onboardedPrimaryLienWaiverForms: Boolean['output']
  readonly selectedVendorLienWaivers: Boolean['output']
  readonly onboardedVendorLienWaiverForms: Boolean['output']
  readonly selectedChangeOrderRequestForms: Boolean['output']
  readonly onboardedChangeOrderRequestForms: Boolean['output']
  readonly selectedChangeOrderLogForms: Boolean['output']
  readonly onboardedChangeOrderLogForms: Boolean['output']
  readonly notifiedOnboardedForms: Boolean['output']
  readonly selectedRateTable: Boolean['output']
  readonly addedSov: Boolean['output']
  readonly addedTeammates: Boolean['output']
  readonly addedGcContacts: Boolean['output']
  readonly startedBilling: Boolean['output']
  readonly isComplete: Boolean['output']
  readonly isReadyForPayAppSubmit: Boolean['output']
}

export type OnboardedProjectVendorsStatusProperties = {
  readonly __typename: 'OnboardedProjectVendorsStatus'
  readonly addedVendorLienWaivers: Boolean['output']
  readonly addedLegalRequirements: Boolean['output']
  readonly addedVendors: Boolean['output']
  readonly addedGcRecipients: Boolean['output']
  readonly completedOnboarding: Boolean['output']
}

export type OnboardedProjectComplianceStatusProperties = {
  readonly __typename: 'OnboardedProjectComplianceStatus'
  readonly addedLegalRequirements: Boolean['output']
  readonly addedGcRecipients: Boolean['output']
  readonly completedOnboarding: Boolean['output']
}

export type ContractForComplianceProjectHome = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly internalProjectNumber: String['output'] | null
  readonly status: ContractStatus
  readonly billingType: BillingType
  readonly complianceStatus: ComplianceStatus | null
  readonly vendorNumber: String['output'] | null
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly projectNumber: String['output']
    readonly name: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly owner: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly architect: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
  }
  readonly defaultGcContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
  readonly legalRequirements: ReadonlyArray<{
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }>
  readonly legalRequirementContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly integrations: ReadonlyArray<{
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }>
  readonly onboardedProjectComplianceStatus: {
    readonly __typename: 'OnboardedProjectComplianceStatus'
    readonly addedLegalRequirements: Boolean['output']
    readonly addedGcRecipients: Boolean['output']
    readonly completedOnboarding: Boolean['output']
  }
}

export type MinimalProjectProperties = {
  readonly __typename: 'Project'
  readonly id: ID['output']
  readonly name: String['output']
  readonly projectNumber: String['output']
  readonly timeZone: String['output']
  readonly generalContractor: {
    readonly __typename: 'CompanyProjectMetadata'
    readonly companyName: String['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
  } | null
  readonly metadata: {
    readonly __typename: 'ProjectMetadata'
    readonly payAppDueOnDayOfMonth: Int['output']
  }
}

export type PayAppAttachmentForBackupProperties = {
  readonly __typename: 'PayAppAttachment'
  readonly id: ID['output']
  readonly description: String['output'] | null
  readonly name: String['output']
  readonly createdAt: DateTime['output']
  readonly uploadId: ID['output']
  readonly isEmailAttachment: Boolean['output']
  readonly isInternalOnly: Boolean['output']
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  }
  readonly sovLineItemProgress: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly progressBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly sortOrder: SafeInt['output']
      readonly code: String['output']
      readonly name: String['output']
      readonly isChangeOrder: Boolean['output']
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
    }
  } | null
  readonly contractPayAppAttachment: {
    readonly __typename: 'ContractPayAppAttachment'
    readonly id: ID['output']
  } | null
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }>
}

export type PayAppAttachmentProperties = {
  readonly __typename: 'PayAppAttachment'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly uploadId: ID['output']
  readonly description: String['output'] | null
  readonly isEmailAttachment: Boolean['output']
  readonly isInternalOnly: Boolean['output']
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  }
  readonly sovLineItemProgress: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  } | null
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }>
}

export type MinimalVendorProperties = {
  readonly __typename: 'Vendor'
  readonly id: ID['output']
  readonly name: String['output']
  readonly integrationMappings: JSONObject['output']
  readonly internalNotes: String['output'] | null
  readonly address: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  } | null
  readonly contacts: ReadonlyArray<{
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }>
  readonly companyIntegrations: ReadonlyArray<{
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
  }>
}

export type VendorProperties = {
  readonly __typename: 'Vendor'
  readonly id: ID['output']
  readonly name: String['output']
  readonly integrationMappings: JSONObject['output']
  readonly internalNotes: String['output'] | null
  readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
  readonly vendorContracts: ReadonlyArray<{
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
    }
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }>
  readonly address: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  } | null
  readonly contacts: ReadonlyArray<{
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }>
  readonly companyIntegrations: ReadonlyArray<{
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly label: String['output'] | null
  }>
}

export type VendorPreliminaryNoticeProperties = {
  readonly __typename: 'VendorPreliminaryNotice'
  readonly id: ID['output']
  readonly date: DateTime['output']
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  }
}

export type VendorContractProperties = {
  readonly __typename: 'VendorContract'
  readonly includeInSwornStatements: Boolean['output']
  readonly id: ID['output']
  readonly preliminaryNotices: ReadonlyArray<{
    readonly __typename: 'VendorPreliminaryNotice'
    readonly id: ID['output']
    readonly date: DateTime['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
  }>
  readonly vendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }
  readonly vendorLegalRequirements: ReadonlyArray<{
    readonly __typename: 'VendorLegalRequirement'
    readonly id: ID['output']
    readonly legalRequirement: {
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
    }
  }>
  readonly lowerTierTo: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
  } | null
}

export type LienWaiverRequestProperties = {
  readonly __typename: 'LienWaiverRequest'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly updatedAt: DateTime['output']
  readonly status: DocumentRequestStatus
  readonly actionTakenAt: DateTime['output'] | null
  readonly vendorContact: {
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }
}

export type LienWaiverProperties = {
  readonly __typename: 'LienWaiver'
  readonly id: ID['output']
  readonly type: LienWaiverType
  readonly timeZone: String['output']
  readonly claimant: String['output']
  readonly date: DateTime['output']
  readonly defaultAmount: SafeInt['output'] | null
  readonly defaultAmountText: String['output'] | null
  readonly amount: SafeInt['output'] | null
  readonly customer: String['output']
  readonly lastSendTime: DateTime['output'] | null
  readonly isJointCheck: Boolean['output']
  readonly subject: String['output'] | null
  readonly message: String['output'] | null
  readonly defaultExceptionAmounts: String['output'] | null
  readonly defaultExceptionDates: String['output'] | null
  readonly hasSelectedDefaultVendorContacts: Boolean['output']
  readonly owner: String['output']
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly uploadedFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
    readonly type: StoredFileType
    readonly name: String['output']
    readonly createdAt: DateTime['output']
    readonly size: SafeInt['output']
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
  } | null
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly integrationMappings: JSONObject['output']
      readonly internalNotes: String['output'] | null
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly address: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
      }>
    }
    readonly preliminaryNotices: ReadonlyArray<{
      readonly __typename: 'VendorPreliminaryNotice'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  } | null
  readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
  readonly lienWaiverRequests: ReadonlyArray<{
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequestStatusLog'
      readonly id: ID['output']
      readonly status: DocumentRequestStatus
      readonly statusUpdatedAt: DateTime['output']
    }>
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly defaultSignature: {
        readonly __typename: 'Signature'
        readonly id: ID['output']
        readonly signatureName: String['output'] | null
        readonly signatureFont: SignatureFont | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
      } | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  }>
  readonly jobLocation: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
  readonly formValues: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotationValue'
    readonly id: ID['output']
    readonly value: String['output']
    readonly metadata: JSONObject['output']
    readonly annotation: {
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly syncTag: String['output'] | null
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
    }
  }>
  readonly requestAttachments: ReadonlyArray<{
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  }>
  readonly defaultVendorContacts: ReadonlyArray<{
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }>
}

export type LienWaiverForTrackerProperties = {
  readonly __typename: 'LienWaiver'
  readonly id: ID['output']
  readonly type: LienWaiverType
  readonly date: DateTime['output']
  readonly defaultAmount: SafeInt['output'] | null
  readonly amount: SafeInt['output'] | null
  readonly lastSendTime: DateTime['output'] | null
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly uploadedFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
    readonly type: StoredFileType
    readonly name: String['output']
    readonly createdAt: DateTime['output']
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
  } | null
  readonly vendorContract: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  } | null
  readonly lienWaiverRequests: ReadonlyArray<{
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequestStatusLog'
      readonly id: ID['output']
      readonly status: DocumentRequestStatus
      readonly statusUpdatedAt: DateTime['output']
    }>
  }>
}

export type VendorContractForLienWaiversProperties = {
  readonly __typename: 'VendorContract'
  readonly includeInSwornStatements: Boolean['output']
  readonly id: ID['output']
  readonly vendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
  }
  readonly preliminaryNotices: ReadonlyArray<{
    readonly __typename: 'VendorPreliminaryNotice'
    readonly id: ID['output']
  }>
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
  }
  readonly lowerTierTo: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
  } | null
}

export type LienWaiverForTrackerCellProperties = {
  readonly __typename: 'LienWaiver'
  readonly id: ID['output']
  readonly type: LienWaiverType
  readonly date: DateTime['output']
  readonly defaultAmount: SafeInt['output'] | null
  readonly amount: SafeInt['output'] | null
  readonly lastSendTime: DateTime['output'] | null
  readonly timeZone: String['output']
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
  }
  readonly uploadedFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly url: URL['output']
    readonly type: StoredFileType
    readonly name: String['output']
    readonly createdAt: DateTime['output']
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
  } | null
  readonly lienWaiverRequests: ReadonlyArray<{
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequestStatusLog'
      readonly id: ID['output']
      readonly status: DocumentRequestStatus
      readonly statusUpdatedAt: DateTime['output']
    }>
  }>
}

export type NotificationProperties = {
  readonly __typename: 'Notification'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly type: NotificationType
  readonly message: String['output']
  readonly link: String['output']
  readonly read: Boolean['output']
  readonly createdByUserInitials: String['output'] | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly nickname: String['output'] | null
      readonly name: String['output']
    }
  } | null
}

export type AmountLineItemProperties = {
  readonly __typename: 'AmountLineItem'
  readonly id: ID['output']
  readonly description: String['output']
  readonly amount: SafeInt['output']
  readonly amountDuePreTax: SafeInt['output']
  readonly amountDueTaxAmount: SafeInt['output']
  readonly amountDuePostTax: SafeInt['output']
  readonly sortOrder: SafeInt['output']
  readonly taxGroup: {
    readonly __typename: 'TaxGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
    readonly integrationMappings: JSONObject['output']
  } | null
}

export type CompanyUserEmailAliasProperties = {
  readonly __typename: 'CompanyUserEmailAlias'
  readonly id: ID['output']
  readonly emailAddress: EmailAddress['output']
  readonly isVerified: Boolean['output']
  readonly verificationCodeExpiresAt: DateTime['output']
}

export type CompanyUserProperties = {
  readonly __typename: 'CompanyUser'
  readonly id: ID['output']
  readonly role: CompanyUserRole
  readonly isSitelineAdmin: Boolean['output']
  readonly status: UserStatus
  readonly permissions: ReadonlyArray<Permission>
  readonly emailAlias: {
    readonly __typename: 'CompanyUserEmailAlias'
    readonly id: ID['output']
    readonly emailAddress: EmailAddress['output']
    readonly isVerified: Boolean['output']
    readonly verificationCodeExpiresAt: DateTime['output']
  } | null
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
  }
  readonly companyLocation: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly city: String['output']
  }
}

export type MinimalFormTemplateProperties = {
  readonly __typename: 'FormTemplate'
  readonly id: String['output']
  readonly isCustomerReady: Boolean['output']
  readonly versions: ReadonlyArray<{
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly versionNumber: Int['output']
  }>
}

export type OnboardingFormTemplateProperties = {
  readonly __typename: 'FormTemplate'
  readonly id: String['output']
  readonly status: FormTemplateStatus
  readonly isCustomerReady: Boolean['output']
  readonly userVisibleName: String['output']
  readonly type: FormTemplateType
}

export type FormTemplateProperties = {
  readonly __typename: 'FormTemplate'
  readonly id: String['output']
  readonly isCustomerReady: Boolean['output']
  readonly versions: ReadonlyArray<{
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly versionNumber: Int['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
  }>
}

export type MinimalSubcontractorLienWaiverTemplateSetProperties = {
  readonly __typename: 'SubcontractorLienWaiverTemplateSet'
  readonly id: ID['output']
  readonly conditionalFinalVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    }
  } | null
  readonly conditionalProgressVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    }
  } | null
  readonly unconditionalFinalVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    }
  } | null
  readonly unconditionalProgressVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    }
  } | null
}

export type SubcontractorLienWaiverTemplateSetProperties = {
  readonly __typename: 'SubcontractorLienWaiverTemplateSet'
  readonly id: ID['output']
  readonly conditionalFinalVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
    }
  } | null
  readonly conditionalProgressVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
    }
  } | null
  readonly unconditionalFinalVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
    }
  } | null
  readonly unconditionalProgressVariant: {
    readonly __typename: 'FormTemplateVariant'
    readonly id: ID['output']
    readonly template: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
    }
  } | null
}

export type IntegrationProjectDetailsProperties = {
  readonly __typename: 'IntegrationProjectDetails'
  readonly integrationProjectId: String['output']
  readonly projectName: String['output']
  readonly projectNumber: String['output'] | null
  readonly internalProjectNumber: String['output'] | null
  readonly sitelineLeadPMIds: ReadonlyArray<String['output']> | null
  readonly projectAddress: {
    readonly __typename: 'DraftLocation'
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly coordinates: LatLng['output']
    readonly timeZone: String['output']
  } | null
}

export type IntegrationContractProperties = {
  readonly __typename: 'IntegrationContract'
  readonly integrationContractId: String['output'] | null
  readonly contractNumber: String['output'] | null
  readonly contractDate: DateTime['output'] | null
  readonly latestPayAppNumber: SafeInt['output'] | null
  readonly payAppDueDate: SafeInt['output'] | null
  readonly retentionPercent: Float['output'] | null
  readonly retentionTrackingLevel: RetentionTrackingLevel | null
  readonly roundRetention: Boolean['output'] | null
  readonly billingType: BillingType | null
  readonly paymentTermsType: ContractPaymentTermsType | null
  readonly paymentTerms: SafeInt['output'] | null
  readonly preSitelineBilled: SafeInt['output'] | null
}

export type IntegrationCompanyProperties = {
  readonly __typename: 'IntegrationCompany'
  readonly companyName: String['output'] | null
  readonly companyAddress: {
    readonly __typename: 'DraftLocation'
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly coordinates: LatLng['output']
    readonly timeZone: String['output']
  } | null
}

export type IntegrationTaxGroupProperties = {
  readonly __typename: 'IntegrationTaxGroup'
  readonly integrationTaxGroupId: String['output']
  readonly name: String['output']
  readonly taxPercent: Float['output']
}

export type ImportProjectOnboardingMetadataProperties = {
  readonly __typename: 'ImportProjectOnboardingMetadata'
  readonly type: IntegrationType
  readonly companyIntegrationId: ID['output']
  readonly integrationAssociatedCompanyId: String['output'] | null
  readonly taxCalculationType: TaxCalculationType
  readonly project: {
    readonly __typename: 'IntegrationProjectDetails'
    readonly integrationProjectId: String['output']
    readonly projectName: String['output']
    readonly projectNumber: String['output'] | null
    readonly internalProjectNumber: String['output'] | null
    readonly sitelineLeadPMIds: ReadonlyArray<String['output']> | null
    readonly projectAddress: {
      readonly __typename: 'DraftLocation'
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly coordinates: LatLng['output']
      readonly timeZone: String['output']
    } | null
  }
  readonly contract: {
    readonly __typename: 'IntegrationContract'
    readonly integrationContractId: String['output'] | null
    readonly contractNumber: String['output'] | null
    readonly contractDate: DateTime['output'] | null
    readonly latestPayAppNumber: SafeInt['output'] | null
    readonly payAppDueDate: SafeInt['output'] | null
    readonly retentionPercent: Float['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel | null
    readonly roundRetention: Boolean['output'] | null
    readonly billingType: BillingType | null
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly preSitelineBilled: SafeInt['output'] | null
  }
  readonly generalContractor: {
    readonly __typename: 'IntegrationCompany'
    readonly companyName: String['output'] | null
    readonly companyAddress: {
      readonly __typename: 'DraftLocation'
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly coordinates: LatLng['output']
      readonly timeZone: String['output']
    } | null
  } | null
  readonly owner: {
    readonly __typename: 'IntegrationCompany'
    readonly companyName: String['output'] | null
    readonly companyAddress: {
      readonly __typename: 'DraftLocation'
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly coordinates: LatLng['output']
      readonly timeZone: String['output']
    } | null
  } | null
  readonly architect: {
    readonly __typename: 'IntegrationCompany'
    readonly companyName: String['output'] | null
    readonly companyAddress: {
      readonly __typename: 'DraftLocation'
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly coordinates: LatLng['output']
      readonly timeZone: String['output']
    } | null
  } | null
  readonly taxGroup: {
    readonly __typename: 'IntegrationTaxGroup'
    readonly integrationTaxGroupId: String['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
  } | null
}

export type IntegrationProjectSummaryProperties = {
  readonly __typename: 'IntegrationProjectSummary'
  readonly integrationProjectId: String['output']
  readonly integrationContractId: String['output'] | null
  readonly integrationAssociatedCompanyId: String['output'] | null
  readonly projectName: String['output']
  readonly projectNumber: String['output'] | null
  readonly generalContractorName: String['output'] | null
  readonly contractName: String['output'] | null
  readonly sitelineProjectId: String['output'] | null
  readonly sitelineContractId: String['output'] | null
}

export type ChangeOrderRequestRateTableFeeProperties = {
  readonly __typename: 'ChangeOrderRequestRateTableFee'
  readonly id: ID['output']
  readonly percent: Float['output']
  readonly description: String['output']
  readonly group: {
    readonly __typename: 'ChangeOrderRequestRateTableGroup'
    readonly id: ID['output']
  } | null
  readonly overridesFee: {
    readonly __typename: 'ChangeOrderRequestRateTableFee'
    readonly id: ID['output']
  } | null
}

export type ChangeOrderRequestRateTableGroupProperties = {
  readonly __typename: 'ChangeOrderRequestRateTableGroup'
  readonly id: ID['output']
  readonly name: String['output']
  readonly totalAmount: SafeInt['output']
  readonly subtotalAmount: SafeInt['output']
  readonly type: LineItemType
  readonly fees: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
    } | null
  }>
}

export type ChangeOrderRequestRateTableItemProperties = {
  readonly __typename: 'ChangeOrderRequestRateTableItem'
  readonly id: ID['output']
  readonly description: String['output']
  readonly code: String['output'] | null
  readonly sortOrder: SafeInt['output']
  readonly unitName: String['output'] | null
  readonly unitRate: SafeInt['output']
  readonly currentPriced: SafeInt['output']
  readonly currentUnitsPriced: Float['output']
  readonly isOneOffItem: Boolean['output']
  readonly group: {
    readonly __typename: 'ChangeOrderRequestRateTableGroup'
    readonly id: ID['output']
  }
}

export type ChangeOrderRequestRateTableProperties = {
  readonly __typename: 'ChangeOrderRequest'
  readonly hasContractRateTableChanged: Boolean['output']
  readonly haveContractFeesChanged: Boolean['output']
  readonly rateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
  } | null
  readonly rateTableFees: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
    } | null
  }>
  readonly rateTableGroups: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly totalAmount: SafeInt['output']
    readonly subtotalAmount: SafeInt['output']
    readonly type: LineItemType
    readonly fees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
  }>
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentPriced: SafeInt['output']
    readonly currentUnitsPriced: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    }
  }>
}

export type ChangeOrderRequestAttachmentProperties = {
  readonly __typename: 'ChangeOrderRequestAttachment'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly uploadId: ID['output']
  readonly description: String['output'] | null
  readonly isInternalOnly: Boolean['output']
  readonly file: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  }
}

export type MinimalChangeOrderRequestProperties = {
  readonly __typename: 'ChangeOrderRequest'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly internalNumber: String['output'] | null
  readonly generalContractorNumber: String['output'] | null
  readonly amount: SafeInt['output'] | null
  readonly proposedAmount: SafeInt['output'] | null
  readonly unitPrice: Decimal['output'] | null
  readonly unitName: String['output'] | null
  readonly proposedAt: DateTime['output'] | null
  readonly scheduleImpact: Float['output'] | null
  readonly proceedingWithWork: Boolean['output']
  readonly status: ChangeOrderRequestStatus
  readonly statusChangedAt: DateTime['output']
  readonly reason: String['output'] | null
  readonly importedAt: DateTime['output'] | null
  readonly visibleToGc: Boolean['output']
  readonly emailedContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly linkedChangeOrderRequests: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly status: ChangeOrderRequestStatus
    readonly amount: SafeInt['output'] | null
    readonly name: String['output']
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
  }>
  readonly sovLineItems: ReadonlyArray<{
    readonly __typename: 'SovLineItem'
    readonly id: ID['output']
  }>
  readonly attachments: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestAttachment'
    readonly id: ID['output']
  }>
  readonly approvedScoFile: { readonly __typename: 'StoredFile'; readonly id: ID['output'] } | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
  }
}

export type ChangeOrderRequestProperties = {
  readonly __typename: 'ChangeOrderRequest'
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly name: String['output']
  readonly internalNumber: String['output'] | null
  readonly generalContractorNumber: String['output'] | null
  readonly amount: SafeInt['output'] | null
  readonly proposedAmount: SafeInt['output'] | null
  readonly unitPrice: Decimal['output'] | null
  readonly unitName: String['output'] | null
  readonly proposedAt: DateTime['output'] | null
  readonly scheduleImpact: Float['output'] | null
  readonly proceedingWithWork: Boolean['output']
  readonly status: ChangeOrderRequestStatus
  readonly statusChangedAt: DateTime['output']
  readonly reason: String['output'] | null
  readonly importedAt: DateTime['output'] | null
  readonly visibleToGc: Boolean['output']
  readonly hasContractRateTableChanged: Boolean['output']
  readonly haveContractFeesChanged: Boolean['output']
  readonly approvedScoFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  } | null
  readonly attachments: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestAttachment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly uploadId: ID['output']
    readonly description: String['output'] | null
    readonly isInternalOnly: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
  }>
  readonly sovLineItems: ReadonlyArray<{
    readonly __typename: 'SovLineItem'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output']
    readonly latestTotalValue: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitPrice: Decimal['output'] | null
  }>
  readonly emailedContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly linkedChangeOrderRequests: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly status: ChangeOrderRequestStatus
    readonly amount: SafeInt['output'] | null
    readonly name: String['output']
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
  }>
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
  }
  readonly rateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
  } | null
  readonly rateTableFees: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
    } | null
  }>
  readonly rateTableGroups: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly totalAmount: SafeInt['output']
    readonly subtotalAmount: SafeInt['output']
    readonly type: LineItemType
    readonly fees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
  }>
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequestRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentPriced: SafeInt['output']
    readonly currentUnitsPriced: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    }
  }>
}

export type IntegrationCustomerProperties = {
  readonly __typename: 'IntegrationCustomer'
  readonly integrationCustomerId: String['output']
  readonly name: String['output']
  readonly code: String['output'] | null
}

export type UserEducationCampaignProperties = {
  readonly __typename: 'UserEducationCampaign'
  readonly id: ID['output']
  readonly name: String['output']
  readonly showNewTag: Boolean['output']
  readonly title: String['output']
  readonly message: String['output']
  readonly buttonLabel: String['output'] | null
  readonly buttonLink: String['output'] | null
}

export type SovChangeSetProperties = {
  readonly __typename: 'SovChangeSet'
  readonly additions: ReadonlyArray<{
    readonly __typename: 'LineItemAddition'
    readonly new: {
      readonly __typename: 'SovChangeSetCreateLineItem'
      readonly code: String['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly isChangeOrder: Boolean['output']
      readonly sortOrder: SafeInt['output'] | null
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly sovLineItemGroupId: String['output'] | null
      readonly previousStoredMaterials: SafeInt['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly defaultRetentionPercent: Float['output'] | null
    }
  }>
  readonly updates: ReadonlyArray<{
    readonly __typename: 'LineItemUpdate'
    readonly oldId: ID['output']
    readonly new: {
      readonly __typename: 'SovChangeSetUpdateLineItem'
      readonly code: String['output'] | null
      readonly name: String['output'] | null
      readonly costCode: String['output'] | null
      readonly originalTotalValue: SafeInt['output'] | null
      readonly latestTotalValue: SafeInt['output'] | null
      readonly previousBilled: SafeInt['output'] | null
      readonly isChangeOrder: Boolean['output'] | null
      readonly sortOrder: SafeInt['output'] | null
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly sovLineItemGroupId: String['output'] | null
      readonly previousStoredMaterials: SafeInt['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly defaultRetentionPercent: Float['output'] | null
    }
  }>
  readonly deletions: ReadonlyArray<{
    readonly __typename: 'LineItemDeletion'
    readonly oldId: ID['output']
  }>
  readonly groupAdditions: ReadonlyArray<{
    readonly __typename: 'LineItemGroupAddition'
    readonly newId: ID['output']
    readonly new: {
      readonly __typename: 'SovChangeSetCreateLineItemGroup'
      readonly name: String['output']
      readonly code: String['output'] | null
    }
  }>
  readonly groupUpdates: ReadonlyArray<{
    readonly __typename: 'LineItemGroupUpdate'
    readonly oldId: ID['output']
    readonly new: {
      readonly __typename: 'SovChangeSetUpdateLineItemGroup'
      readonly name: String['output'] | null
      readonly code: String['output'] | null
    }
  }>
  readonly groupDeletions: ReadonlyArray<{
    readonly __typename: 'LineItemGroupDeletion'
    readonly oldId: ID['output']
  }>
}

export type BillingDashboardSummaryProperties = {
  readonly __typename: 'BillingDashboardSummary'
  readonly projectsBilledThisMonth: SafeInt['output']
  readonly totalProjectsThisMonth: SafeInt['output']
  readonly projectsBilledMonthOverMonthPercent: Float['output'] | null
  readonly amountBilledAndDraftThisMonth: SafeInt['output']
  readonly amountBilledAndDraftMonthOverMonthPercent: Float['output'] | null
  readonly amountBilledThisMonth: SafeInt['output']
  readonly amountBilledMonthOverMonthPercent: Float['output'] | null
  readonly amountInDraftThisMonth: SafeInt['output']
  readonly amountInDraftMonthOverMonthPercent: Float['output'] | null
  readonly retentionHeldThisMonth: SafeInt['output']
  readonly retentionHeldThruMonth: SafeInt['output']
  readonly retentionHeldMonthOverMonthPercent: Float['output'] | null
  readonly payAppStatusBreakdown: {
    readonly __typename: 'PayAppStatusBreakdown'
    readonly pastDue: SafeInt['output']
    readonly notBillingThisMonth: SafeInt['output']
    readonly draftOnTime: SafeInt['output']
    readonly submitted: SafeInt['output']
  }
  readonly monthlyBillingBreakdown: ReadonlyArray<{
    readonly __typename: 'MonthlyBillingBreakdown'
    readonly month: DateTime['output']
    readonly amountBilledAndDraftThisMonth: SafeInt['output']
    readonly amountBilledThisMonth: SafeInt['output']
  }>
}

export type ProjectMetricsProperties = {
  readonly __typename: 'ProjectMetrics'
  readonly amountBilledAndDraftThisMonth: SafeInt['output']
  readonly retentionHeldThisMonth: SafeInt['output'] | null
  readonly retentionHeldThruMonth: SafeInt['output'] | null
  readonly percentCompletedThruMonth: Float['output'] | null
  readonly balanceToFinish: SafeInt['output'] | null
  readonly completedThruMonth: SafeInt['output'] | null
  readonly totalValue: SafeInt['output'] | null
}

export type LineItemMetricsProperties = {
  readonly __typename: 'LineItemMetrics'
  readonly number: String['output']
  readonly name: String['output']
  readonly amountBilledAndDraftThisMonth: SafeInt['output']
  readonly percentCompletedThruMonth: Float['output'] | null
  readonly balanceToFinish: SafeInt['output'] | null
  readonly completedThruMonth: SafeInt['output'] | null
  readonly totalValue: SafeInt['output'] | null
}

export type ContractForBillingDashboardProperties = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly billingType: BillingType
  readonly internalProjectNumber: String['output'] | null
  readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
  readonly leadPMs: ReadonlyArray<{
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }>
}

export type BillingDashboardContractProperties = {
  readonly __typename: 'BillingDashboardContract'
  readonly billingStatus: DashboardBillingStatus
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly internalProjectNumber: String['output'] | null
    readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }>
  }
  readonly projectMetrics: {
    readonly __typename: 'ProjectMetrics'
    readonly amountBilledAndDraftThisMonth: SafeInt['output']
    readonly retentionHeldThisMonth: SafeInt['output'] | null
    readonly retentionHeldThruMonth: SafeInt['output'] | null
    readonly percentCompletedThruMonth: Float['output'] | null
    readonly balanceToFinish: SafeInt['output'] | null
    readonly completedThruMonth: SafeInt['output'] | null
    readonly totalValue: SafeInt['output'] | null
  }
}

export type BillingDashboardContractWithLineItemsProperties = {
  readonly __typename: 'BillingDashboardContractWithLineItems'
  readonly billingStatus: DashboardBillingStatus
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly internalProjectNumber: String['output'] | null
    readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }>
  }
  readonly projectMetrics: {
    readonly __typename: 'ProjectMetrics'
    readonly amountBilledAndDraftThisMonth: SafeInt['output']
    readonly retentionHeldThisMonth: SafeInt['output'] | null
    readonly retentionHeldThruMonth: SafeInt['output'] | null
    readonly percentCompletedThruMonth: Float['output'] | null
    readonly balanceToFinish: SafeInt['output'] | null
    readonly completedThruMonth: SafeInt['output'] | null
    readonly totalValue: SafeInt['output'] | null
  }
  readonly lineItems: ReadonlyArray<{
    readonly __typename: 'LineItemMetrics'
    readonly number: String['output']
    readonly name: String['output']
    readonly amountBilledAndDraftThisMonth: SafeInt['output']
    readonly percentCompletedThruMonth: Float['output'] | null
    readonly balanceToFinish: SafeInt['output'] | null
    readonly completedThruMonth: SafeInt['output'] | null
    readonly totalValue: SafeInt['output'] | null
  }>
}

export type BillingDashboardProperties = {
  readonly __typename: 'BillingDashboard'
  readonly billingSummary: {
    readonly __typename: 'BillingDashboardSummary'
    readonly projectsBilledThisMonth: SafeInt['output']
    readonly totalProjectsThisMonth: SafeInt['output']
    readonly projectsBilledMonthOverMonthPercent: Float['output'] | null
    readonly amountBilledAndDraftThisMonth: SafeInt['output']
    readonly amountBilledAndDraftMonthOverMonthPercent: Float['output'] | null
    readonly amountBilledThisMonth: SafeInt['output']
    readonly amountBilledMonthOverMonthPercent: Float['output'] | null
    readonly amountInDraftThisMonth: SafeInt['output']
    readonly amountInDraftMonthOverMonthPercent: Float['output'] | null
    readonly retentionHeldThisMonth: SafeInt['output']
    readonly retentionHeldThruMonth: SafeInt['output']
    readonly retentionHeldMonthOverMonthPercent: Float['output'] | null
    readonly payAppStatusBreakdown: {
      readonly __typename: 'PayAppStatusBreakdown'
      readonly pastDue: SafeInt['output']
      readonly notBillingThisMonth: SafeInt['output']
      readonly draftOnTime: SafeInt['output']
      readonly submitted: SafeInt['output']
    }
    readonly monthlyBillingBreakdown: ReadonlyArray<{
      readonly __typename: 'MonthlyBillingBreakdown'
      readonly month: DateTime['output']
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly amountBilledThisMonth: SafeInt['output']
    }>
  }
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'BillingDashboardContract'
    readonly billingStatus: DashboardBillingStatus
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly internalProjectNumber: String['output'] | null
      readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
    }
    readonly projectMetrics: {
      readonly __typename: 'ProjectMetrics'
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly retentionHeldThisMonth: SafeInt['output'] | null
      readonly retentionHeldThruMonth: SafeInt['output'] | null
      readonly percentCompletedThruMonth: Float['output'] | null
      readonly balanceToFinish: SafeInt['output'] | null
      readonly completedThruMonth: SafeInt['output'] | null
      readonly totalValue: SafeInt['output'] | null
    }
  }>
}

export type AgingBreakdownProperties = {
  readonly __typename: 'AgingBreakdown'
  readonly numCurrent: SafeInt['output']
  readonly numAged30Days: SafeInt['output']
  readonly numAged60Days: SafeInt['output']
  readonly numAged90Days: SafeInt['output']
  readonly numAged120Days: SafeInt['output']
  readonly amountAgedTotal: SafeInt['output']
  readonly amountAgedCurrent: SafeInt['output']
  readonly amountAged30Days: SafeInt['output']
  readonly amountAged60Days: SafeInt['output']
  readonly amountAged90Days: SafeInt['output']
  readonly amountAged120Days: SafeInt['output']
}

export type PayAppAgingDashboardSummaryProperties = {
  readonly __typename: 'PayAppAgingDashboardSummary'
  readonly amountOutstandingThisMonth: SafeInt['output']
  readonly amountOutstandingMonthOverMonthPercent: Float['output'] | null
  readonly amountAged30Days: SafeInt['output']
  readonly amountAged30DaysMonthOverMonthPercent: Float['output'] | null
  readonly amountAged60Days: SafeInt['output']
  readonly amountAged60DaysMonthOverMonthPercent: Float['output'] | null
  readonly amountAged90Days: SafeInt['output']
  readonly amountAged90DaysMonthOverMonthPercent: Float['output'] | null
  readonly amountAged120Days: SafeInt['output']
  readonly amountAged120DaysMonthOverMonthPercent: Float['output'] | null
  readonly averageDaysToPaid: Float['output']
  readonly averageDaysToPaidMonthOverMonthPercent: Float['output'] | null
  readonly payAppAgingBreakdown: {
    readonly __typename: 'AgingBreakdown'
    readonly numCurrent: SafeInt['output']
    readonly numAged30Days: SafeInt['output']
    readonly numAged60Days: SafeInt['output']
    readonly numAged90Days: SafeInt['output']
    readonly numAged120Days: SafeInt['output']
    readonly amountAgedTotal: SafeInt['output']
    readonly amountAgedCurrent: SafeInt['output']
    readonly amountAged30Days: SafeInt['output']
    readonly amountAged60Days: SafeInt['output']
    readonly amountAged90Days: SafeInt['output']
    readonly amountAged120Days: SafeInt['output']
  }
}

export type PayAppAgingDashboardContractProperties = {
  readonly __typename: 'PayAppAgingDashboardContract'
  readonly billingStatus: DashboardBillingStatus
  readonly numComments: SafeInt['output']
  readonly hasMissingPreSitelinePayApps: Boolean['output']
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly internalProjectNumber: String['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }>
  }
  readonly agingBreakdown: {
    readonly __typename: 'AgingBreakdown'
    readonly numCurrent: SafeInt['output']
    readonly numAged30Days: SafeInt['output']
    readonly numAged60Days: SafeInt['output']
    readonly numAged90Days: SafeInt['output']
    readonly numAged120Days: SafeInt['output']
    readonly amountAgedTotal: SafeInt['output']
    readonly amountAgedCurrent: SafeInt['output']
    readonly amountAged30Days: SafeInt['output']
    readonly amountAged60Days: SafeInt['output']
    readonly amountAged90Days: SafeInt['output']
    readonly amountAged120Days: SafeInt['output']
  }
}

export type AgingDashboardProperties = {
  readonly __typename: 'PayAppAgingDashboard'
  readonly payAppAgingSummary: {
    readonly __typename: 'PayAppAgingDashboardSummary'
    readonly amountOutstandingThisMonth: SafeInt['output']
    readonly amountOutstandingMonthOverMonthPercent: Float['output'] | null
    readonly amountAged30Days: SafeInt['output']
    readonly amountAged30DaysMonthOverMonthPercent: Float['output'] | null
    readonly amountAged60Days: SafeInt['output']
    readonly amountAged60DaysMonthOverMonthPercent: Float['output'] | null
    readonly amountAged90Days: SafeInt['output']
    readonly amountAged90DaysMonthOverMonthPercent: Float['output'] | null
    readonly amountAged120Days: SafeInt['output']
    readonly amountAged120DaysMonthOverMonthPercent: Float['output'] | null
    readonly averageDaysToPaid: Float['output']
    readonly averageDaysToPaidMonthOverMonthPercent: Float['output'] | null
    readonly payAppAgingBreakdown: {
      readonly __typename: 'AgingBreakdown'
      readonly numCurrent: SafeInt['output']
      readonly numAged30Days: SafeInt['output']
      readonly numAged60Days: SafeInt['output']
      readonly numAged90Days: SafeInt['output']
      readonly numAged120Days: SafeInt['output']
      readonly amountAgedTotal: SafeInt['output']
      readonly amountAgedCurrent: SafeInt['output']
      readonly amountAged30Days: SafeInt['output']
      readonly amountAged60Days: SafeInt['output']
      readonly amountAged90Days: SafeInt['output']
      readonly amountAged120Days: SafeInt['output']
    }
  }
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'PayAppAgingDashboardContract'
    readonly billingStatus: DashboardBillingStatus
    readonly numComments: SafeInt['output']
    readonly hasMissingPreSitelinePayApps: Boolean['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
    }
    readonly agingBreakdown: {
      readonly __typename: 'AgingBreakdown'
      readonly numCurrent: SafeInt['output']
      readonly numAged30Days: SafeInt['output']
      readonly numAged60Days: SafeInt['output']
      readonly numAged90Days: SafeInt['output']
      readonly numAged120Days: SafeInt['output']
      readonly amountAgedTotal: SafeInt['output']
      readonly amountAgedCurrent: SafeInt['output']
      readonly amountAged30Days: SafeInt['output']
      readonly amountAged60Days: SafeInt['output']
      readonly amountAged90Days: SafeInt['output']
      readonly amountAged120Days: SafeInt['output']
    }
  }>
}

export type VendorLienWaiverDashboardSummaryProperties = {
  readonly __typename: 'VendorLienWaiverDashboardSummary'
  readonly numOutstandingLienWaiversThisMonth: SafeInt['output'] | null
  readonly outstandingVendors: SafeInt['output']
  readonly outstandingLienWaiversByMonth: ReadonlyArray<{
    readonly __typename: 'OutstandingLienWaiversByMonth'
    readonly month: DateTime['output']
    readonly numOutstandingLienWaiversThisMonth: SafeInt['output']
  }>
}

export type OverviewDashboardProperties = {
  readonly __typename: 'OverviewDashboard'
  readonly billingSummary: {
    readonly __typename: 'BillingDashboardSummary'
    readonly projectsBilledThisMonth: SafeInt['output']
    readonly totalProjectsThisMonth: SafeInt['output']
    readonly projectsBilledMonthOverMonthPercent: Float['output'] | null
    readonly amountBilledAndDraftThisMonth: SafeInt['output']
    readonly amountBilledAndDraftMonthOverMonthPercent: Float['output'] | null
    readonly amountBilledThisMonth: SafeInt['output']
    readonly amountBilledMonthOverMonthPercent: Float['output'] | null
    readonly amountInDraftThisMonth: SafeInt['output']
    readonly amountInDraftMonthOverMonthPercent: Float['output'] | null
    readonly retentionHeldThisMonth: SafeInt['output']
    readonly retentionHeldThruMonth: SafeInt['output']
    readonly retentionHeldMonthOverMonthPercent: Float['output'] | null
    readonly payAppStatusBreakdown: {
      readonly __typename: 'PayAppStatusBreakdown'
      readonly pastDue: SafeInt['output']
      readonly notBillingThisMonth: SafeInt['output']
      readonly draftOnTime: SafeInt['output']
      readonly submitted: SafeInt['output']
    }
    readonly monthlyBillingBreakdown: ReadonlyArray<{
      readonly __typename: 'MonthlyBillingBreakdown'
      readonly month: DateTime['output']
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly amountBilledThisMonth: SafeInt['output']
    }>
  }
  readonly payAppAgingSummary: {
    readonly __typename: 'PayAppAgingDashboardSummary'
    readonly amountOutstandingThisMonth: SafeInt['output']
    readonly amountOutstandingMonthOverMonthPercent: Float['output'] | null
    readonly amountAged30Days: SafeInt['output']
    readonly amountAged30DaysMonthOverMonthPercent: Float['output'] | null
    readonly amountAged60Days: SafeInt['output']
    readonly amountAged60DaysMonthOverMonthPercent: Float['output'] | null
    readonly amountAged90Days: SafeInt['output']
    readonly amountAged90DaysMonthOverMonthPercent: Float['output'] | null
    readonly amountAged120Days: SafeInt['output']
    readonly amountAged120DaysMonthOverMonthPercent: Float['output'] | null
    readonly averageDaysToPaid: Float['output']
    readonly averageDaysToPaidMonthOverMonthPercent: Float['output'] | null
    readonly payAppAgingBreakdown: {
      readonly __typename: 'AgingBreakdown'
      readonly numCurrent: SafeInt['output']
      readonly numAged30Days: SafeInt['output']
      readonly numAged60Days: SafeInt['output']
      readonly numAged90Days: SafeInt['output']
      readonly numAged120Days: SafeInt['output']
      readonly amountAgedTotal: SafeInt['output']
      readonly amountAgedCurrent: SafeInt['output']
      readonly amountAged30Days: SafeInt['output']
      readonly amountAged60Days: SafeInt['output']
      readonly amountAged90Days: SafeInt['output']
      readonly amountAged120Days: SafeInt['output']
    }
  }
  readonly vendorSummary: {
    readonly __typename: 'VendorLienWaiverDashboardSummary'
    readonly numOutstandingLienWaiversThisMonth: SafeInt['output'] | null
    readonly outstandingVendors: SafeInt['output']
    readonly outstandingLienWaiversByMonth: ReadonlyArray<{
      readonly __typename: 'OutstandingLienWaiversByMonth'
      readonly month: DateTime['output']
      readonly numOutstandingLienWaiversThisMonth: SafeInt['output']
    }>
  }
}

export type ContractOptedOutMonthProperties = {
  readonly __typename: 'ContractOptedOutMonth'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly month: String['output']
  readonly reason: String['output'] | null
  readonly user: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  } | null
}

export type ContractForVendorsProjectHome = {
  readonly __typename: 'Contract'
  readonly missingRequiredVendorLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
  readonly vendorComplianceStatus: VendorComplianceStatus | null
  readonly vendorsInternalNotes: String['output'] | null
  readonly timeZone: String['output']
  readonly id: ID['output']
  readonly role: CompanyProjectRole
  readonly status: ContractStatus
  readonly roundRetention: Boolean['output']
  readonly internalProjectNumber: String['output'] | null
  readonly retentionTrackingLevel: RetentionTrackingLevel
  readonly defaultRetentionPercent: Float['output'] | null
  readonly skippedPayAppMonths: ReadonlyArray<String['output']>
  readonly daysBeforePayAppDue: Int['output']
  readonly billingType: BillingType
  readonly pastPayAppCount: Int['output']
  readonly preSitelineBilled: SafeInt['output'] | null
  readonly vendorNumber: String['output'] | null
  readonly showAuditItemOnSignedPackages: Boolean['output']
  readonly paymentTermsType: ContractPaymentTermsType | null
  readonly paymentTerms: SafeInt['output'] | null
  readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
  readonly legalRequirements: ReadonlyArray<{
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly vendorTemplate: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
      readonly url: URL['output']
    } | null
  }>
  readonly defaultGcContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly legalRequirementContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly vendorSubmitToContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly onboardedStatus: {
    readonly __typename: 'OnboardedProjectContractStatus'
    readonly selectedPayAppForms: Boolean['output']
    readonly onboardedPayAppForms: Boolean['output']
    readonly selectedPrimaryLienWaivers: Boolean['output']
    readonly onboardedPrimaryLienWaiverForms: Boolean['output']
    readonly selectedVendorLienWaivers: Boolean['output']
    readonly onboardedVendorLienWaiverForms: Boolean['output']
    readonly selectedChangeOrderRequestForms: Boolean['output']
    readonly onboardedChangeOrderRequestForms: Boolean['output']
    readonly selectedChangeOrderLogForms: Boolean['output']
    readonly onboardedChangeOrderLogForms: Boolean['output']
    readonly notifiedOnboardedForms: Boolean['output']
    readonly selectedRateTable: Boolean['output']
    readonly addedSov: Boolean['output']
    readonly addedTeammates: Boolean['output']
    readonly addedGcContacts: Boolean['output']
    readonly startedBilling: Boolean['output']
    readonly isComplete: Boolean['output']
    readonly isReadyForPayAppSubmit: Boolean['output']
  }
  readonly onboardedProjectVendorsStatus: {
    readonly __typename: 'OnboardedProjectVendorsStatus'
    readonly addedVendorLienWaivers: Boolean['output']
    readonly addedLegalRequirements: Boolean['output']
    readonly addedVendors: Boolean['output']
    readonly addedGcRecipients: Boolean['output']
    readonly completedOnboarding: Boolean['output']
  }
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly timeZone: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly owner: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly architect: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly metadata: {
      readonly __typename: 'ProjectMetadata'
      readonly payAppDueOnDayOfMonth: Int['output']
    }
  }
  readonly payApps: ReadonlyArray<{
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly billingEnd: DateTime['output']
  }>
  readonly preSitelinePayApps: ReadonlyArray<{
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly billingEnd: DateTime['output'] | null
    readonly payAppNumber: Int['output']
  }>
  readonly integrations: ReadonlyArray<{
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }>
  readonly lowerTierLienWaiverTemplates: {
    readonly __typename: 'SubcontractorLienWaiverTemplateSet'
    readonly id: ID['output']
    readonly conditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly versionNumber: Int['output']
        }>
      }
    } | null
    readonly conditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly versionNumber: Int['output']
        }>
      }
    } | null
    readonly unconditionalFinalVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly versionNumber: Int['output']
        }>
      }
    } | null
    readonly unconditionalProgressVariant: {
      readonly __typename: 'FormTemplateVariant'
      readonly id: ID['output']
      readonly template: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly versionNumber: Int['output']
        }>
      }
    } | null
  } | null
  readonly vendorContracts: ReadonlyArray<{
    readonly __typename: 'VendorContract'
    readonly includeInSwornStatements: Boolean['output']
    readonly id: ID['output']
    readonly preliminaryNotices: ReadonlyArray<{
      readonly __typename: 'VendorPreliminaryNotice'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly integrationMappings: JSONObject['output']
      readonly internalNotes: String['output'] | null
      readonly address: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
      }>
    }
    readonly vendorLegalRequirements: ReadonlyArray<{
      readonly __typename: 'VendorLegalRequirement'
      readonly id: ID['output']
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
    }>
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }>
}

export type PreSitelinePayAppForProjectHomeProperties = {
  readonly __typename: 'PreSitelinePayApp'
  readonly id: ID['output']
  readonly createdAt: DateTime['output']
  readonly timeZone: String['output']
  readonly retentionOnly: Boolean['output']
  readonly payAppNumber: Int['output']
  readonly billingStart: DateTime['output'] | null
  readonly billingEnd: DateTime['output'] | null
  readonly status: PreSitelinePayAppStatus | null
  readonly currentBilled: SafeInt['output'] | null
  readonly paymentDue: SafeInt['output'] | null
  readonly percentComplete: Float['output']
  readonly isFinal: Boolean['output']
  readonly paidAt: DateTime['output'] | null
  readonly amountPaid: SafeInt['output'] | null
  readonly isBalanceManuallyClosed: Boolean['output']
  readonly submittedUnconditionalOutsideOfSiteline: Boolean['output']
  readonly submittedAt: DateTime['output'] | null
  readonly isNotarizingUnconditional: Boolean['output']
  readonly payAppFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly type: StoredFileType
    readonly url: URL['output']
    readonly name: String['output']
    readonly size: SafeInt['output']
  } | null
  readonly unconditionalLienWaiver: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
  } | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly averageDaysToPaid: Float['output']
  }
}

export type ContractBillingForecastProperties = {
  readonly __typename: 'ContractBillingForecast'
  readonly id: ID['output']
  readonly startingMonth: DateTime['output']
  readonly firstEditableMonthWhenCreated: DateTime['output']
  readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
  readonly remainingAmount: SafeInt['output']
  readonly curve: ForecastCurve
  readonly lastEditedBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }
}

export type AggregateForecastMonthProperties = {
  readonly __typename: 'AggregateForecastMonth'
  readonly month: SafeInt['output']
  readonly year: SafeInt['output']
  readonly projectedAmount: SafeInt['output'] | null
  readonly amountBilled: SafeInt['output'] | null
}

export type ContractCostProperties = {
  readonly __typename: 'ContractCost'
  readonly id: ID['output']
  readonly updatedAt: DateTime['output']
  readonly updatedFromIntegrationType: IntegrationType | null
  readonly costToDate: SafeInt['output']
  readonly totalEstimatedCost: SafeInt['output']
  readonly additionalCommittedCost: SafeInt['output']
  readonly costPercentageCompleted: Float['output']
  readonly earnedRevenue: SafeInt['output']
  readonly contractTotalValue: SafeInt['output']
  readonly contractTotalBilled: SafeInt['output']
  readonly billingsInExcessOfCost: SafeInt['output']
  readonly manuallyUpdatedBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  } | null
}

export type ContractForCashForecastProperties = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly billingType: BillingType
  readonly internalProjectNumber: String['output'] | null
  readonly isJointCheck: Boolean['output']
  readonly invoiceAmountOutstanding: SafeInt['output']
  readonly outstandingVendorLienWaiverAmount: SafeInt['output']
  readonly timeZone: String['output']
  readonly leadPMs: ReadonlyArray<{ readonly __typename: 'User'; readonly id: ID['output'] }>
  readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
  readonly payApps: ReadonlyArray<{
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly payAppNumber: SafeInt['output']
    readonly predictedPaymentDate: DateTime['output'] | null
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly status: PayAppStatus
    readonly timeZone: String['output']
    readonly retentionOnly: Boolean['output']
    readonly billingEnd: DateTime['output']
  }>
  readonly preSitelinePayApps: ReadonlyArray<{
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly payAppNumber: Int['output']
    readonly status: PreSitelinePayAppStatus | null
    readonly paymentDue: SafeInt['output'] | null
    readonly retentionOnly: Boolean['output']
    readonly billingEnd: DateTime['output'] | null
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
  }>
}

export type PayAppForOverviewProperties = {
  readonly __typename: 'PayApp'
  readonly balanceToFinish: SafeInt['output']
  readonly automaticallyReadBilling: Boolean['output']
  readonly retentionHeldPercent: Float['output']
  readonly retentionHeldOverride: SafeInt['output'] | null
  readonly retentionReleased: SafeInt['output'] | null
  readonly amountDuePostTax: SafeInt['output']
  readonly amountDueTaxAmount: SafeInt['output']
  readonly integrationPaidAt: DateTime['output'] | null
  readonly canGenerateForms: Boolean['output']
  readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
  readonly predictedPaymentDate: DateTime['output'] | null
  readonly hasContractRateTableChanged: Boolean['output']
  readonly haveContractFeesChanged: Boolean['output']
  readonly payAppNumber: SafeInt['output']
  readonly totalRetention: SafeInt['output']
  readonly physicalSignatureRequired: PhysicalSignatureRequired
  readonly createdAt: DateTime['output']
  readonly statusChangedAt: DateTime['output']
  readonly amountPaid: SafeInt['output'] | null
  readonly isBalanceManuallyClosed: Boolean['output']
  readonly previousRetentionBilled: SafeInt['output']
  readonly currentRetention: SafeInt['output']
  readonly billingType: BillingType
  readonly id: ID['output']
  readonly timeZone: String['output']
  readonly payAppDueDate: DateTime['output']
  readonly internalDueDate: DateTime['output']
  readonly billingStart: DateTime['output']
  readonly billingEnd: DateTime['output']
  readonly status: PayAppStatus
  readonly invoiceReady: Boolean['output']
  readonly retentionOnly: Boolean['output']
  readonly currentBilled: SafeInt['output']
  readonly draftSubmittedAt: DateTime['output'] | null
  readonly lastSubmitted: {
    readonly __typename: 'PayAppStatusLog'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly statusUpdatedAt: DateTime['output']
  } | null
  readonly revertToDraftEvents: ReadonlyArray<{
    readonly __typename: 'PayAppEvent'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly metadata: JSONObject['output']
    readonly createdBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }>
  readonly attachments: ReadonlyArray<{
    readonly __typename: 'PayAppAttachment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly uploadId: ID['output']
    readonly description: String['output'] | null
    readonly isEmailAttachment: Boolean['output']
    readonly isInternalOnly: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
    readonly sovLineItemProgress: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    } | null
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }>
  readonly lienWaivers: ReadonlyArray<{
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }>
  readonly legalDocuments: ReadonlyArray<{
    readonly __typename: 'LegalDocument'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly legalRequirement: {
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
    }
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }>
  readonly rateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
  } | null
  readonly progress: ReadonlyArray<{
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }>
  readonly formValues: ReadonlyArray<{
    readonly __typename: 'FormTemplateAnnotationValue'
    readonly id: ID['output']
    readonly value: String['output']
    readonly metadata: JSONObject['output']
    readonly annotation: {
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly userVisibleName: String['output']
      readonly type: FormTemplateAnnotationType
      readonly xStart: Float['output']
      readonly yStart: Float['output']
      readonly width: Float['output']
      readonly height: Float['output']
      readonly pageNumber: Int['output']
      readonly fontFamily: FormTemplateFont
      readonly fontColor: String['output']
      readonly textAlignment: TextAlignment
      readonly syncTag: String['output'] | null
      readonly isOptional: Boolean['output']
      readonly selectedKey: String['output'] | null
      readonly defaultValueKey: String['output'] | null
      readonly prefix: String['output'] | null
      readonly suffix: String['output'] | null
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
      readonly doNotRetainOnReset: Boolean['output']
      readonly dynamicFieldTag: String['output'] | null
      readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
      readonly imageType: FormTemplateAnnotationImageType | null
    }
  }>
  readonly events: ReadonlyArray<{
    readonly __typename: 'PayAppEvent'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly isAdmin: Boolean['output']
    readonly type: PayAppEventType
    readonly metadata: JSONObject['output']
    readonly createdBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }>
  readonly lastSync: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly lastSyncIfSuccessful: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly status: WriteSyncOperationStatus
    readonly payload: JSONObject['output']
    readonly result: JSONObject['output'] | null
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
    }
  } | null
  readonly uploadedFile: {
    readonly __typename: 'StoredFile'
    readonly id: ID['output']
    readonly name: String['output']
  } | null
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly taxCalculationType: TaxCalculationType
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
    }
  }
  readonly emailedContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
  readonly rateTableFees: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableFee'
    readonly id: ID['output']
    readonly percent: Float['output']
    readonly description: String['output']
    readonly group: {
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
    } | null
    readonly overridesFee: {
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
    } | null
  }>
  readonly rateTableGroups: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly totalAmount: SafeInt['output']
    readonly subtotalAmount: SafeInt['output']
    readonly type: LineItemType
    readonly fees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
  }>
  readonly rateTableItems: ReadonlyArray<{
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }>
}

export type ContractForChangeOrderReportProperties = {
  readonly __typename: 'Contract'
  readonly id: ID['output']
  readonly internalProjectNumber: String['output'] | null
  readonly timeZone: String['output']
  readonly leadPMs: ReadonlyArray<{
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  }>
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly projectNumber: String['output']
    readonly name: String['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
}

export type AggregateChangeOrderDataProperties = {
  readonly __typename: 'AggregateChangeOrderData'
  readonly totalAmountUnapproved: SafeInt['output']
  readonly totalAmountAuthorizedToProceed: SafeInt['output']
  readonly totalAmountProceedingWithWork: SafeInt['output']
  readonly totalAmountApproved: SafeInt['output']
  readonly totalOriginalContractAmount: SafeInt['output']
  readonly totalRevisedContractAmount: SafeInt['output']
}

export type ChangeOrderSummaryReportProperties = {
  readonly __typename: 'ChangeOrderSummaryReport'
  readonly aggregateChangeOrderTotals: {
    readonly __typename: 'AggregateChangeOrderData'
    readonly totalAmountUnapproved: SafeInt['output']
    readonly totalAmountAuthorizedToProceed: SafeInt['output']
    readonly totalAmountProceedingWithWork: SafeInt['output']
    readonly totalAmountApproved: SafeInt['output']
    readonly totalOriginalContractAmount: SafeInt['output']
    readonly totalRevisedContractAmount: SafeInt['output']
  }
  readonly aggregateChangeOrderDataByContract: ReadonlyArray<{
    readonly __typename: 'AggregateChangeOrderDataByContract'
    readonly aggregateChangeOrderData: {
      readonly __typename: 'AggregateChangeOrderData'
      readonly totalAmountUnapproved: SafeInt['output']
      readonly totalAmountAuthorizedToProceed: SafeInt['output']
      readonly totalAmountProceedingWithWork: SafeInt['output']
      readonly totalAmountApproved: SafeInt['output']
      readonly totalOriginalContractAmount: SafeInt['output']
      readonly totalRevisedContractAmount: SafeInt['output']
    }
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly timeZone: String['output']
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
    }
  }>
}

export type PendingChangeOrderReportProperties = {
  readonly __typename: 'PendingChangeOrderReport'
  readonly totalAmountRequested: SafeInt['output']
  readonly totalAmountInDraft: SafeInt['output']
  readonly totalAmountApproved: SafeInt['output']
  readonly totalOriginalContractAmount: SafeInt['output']
  readonly totalRevisedContractAmount: SafeInt['output']
  readonly totalAmountAuthorizedToProceed: SafeInt['output']
  readonly totalAmountProceedingWithWork: SafeInt['output']
  readonly changeOrderRequests: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly timeZone: String['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
  }>
}

export type LienWaiverRequestForLowerTierSignatureProperties = {
  readonly __typename: 'LienWaiverRequest'
  readonly id: ID['output']
  readonly status: DocumentRequestStatus
  readonly vendorContact: {
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }
  readonly requestedBy: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
  } | null
  readonly lienWaiver: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly amount: SafeInt['output'] | null
    readonly date: DateTime['output']
    readonly claimant: String['output']
    readonly message: String['output'] | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly emailedContacts: ReadonlyArray<{
        readonly __typename: 'CompanyContact'
        readonly id: ID['output']
        readonly companyName: String['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
  }
}

export type SetProgressBilledMutationVariables = Exact<{
  sovLineItemProgressId: Scalars['ID']['input']
  progressBilled: Scalars['SafeInt']['input']
}>

export type SetProgressBilledMutation = {
  readonly __typename: 'Mutation'
  readonly setProgressBilled: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly billedToDate: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly payApp: {
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly totalRetention: SafeInt['output']
    }
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }
}

export type SetStoredMaterialBilledMutationVariables = Exact<{
  sovLineItemProgressId: Scalars['ID']['input']
  storedMaterialBilled: Scalars['SafeInt']['input']
}>

export type SetStoredMaterialBilledMutation = {
  readonly __typename: 'Mutation'
  readonly setStoredMaterialBilled: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly billedToDate: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly payApp: {
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly totalRetention: SafeInt['output']
    }
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type GetCurrentCompanyUserQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GetCurrentCompanyUserQuery = {
  readonly __typename: 'Query'
  readonly currentCompanyUser: {
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly permissions: ReadonlyArray<Permission>
    readonly emailAlias: {
      readonly __typename: 'CompanyUserEmailAlias'
      readonly id: ID['output']
      readonly emailAddress: EmailAddress['output']
      readonly isVerified: Boolean['output']
      readonly verificationCodeExpiresAt: DateTime['output']
    } | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly defaultQuickBillingTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
      } | null
    }
    readonly companyLocation: { readonly __typename: 'Location'; readonly id: ID['output'] }
  }
}

export type GetPayAppQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly balanceToFinish: SafeInt['output']
    readonly automaticallyReadBilling: Boolean['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly integrationPaidAt: DateTime['output'] | null
    readonly canGenerateForms: Boolean['output']
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly predictedPaymentDate: DateTime['output'] | null
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly revertToDraftEvents: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isEmailAttachment: Boolean['output']
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
      readonly sovLineItemProgress: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly currentRetention: SafeInt['output']
        readonly storedMaterialBilled: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly futureBilled: SafeInt['output']
        readonly previousRetention: SafeInt['output']
        readonly previousRetentionBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly retentionHeldPercent: Float['output']
        readonly retentionHeldOverride: SafeInt['output'] | null
        readonly retentionReleased: SafeInt['output'] | null
        readonly amountDuePreTax: SafeInt['output']
        readonly amountDuePostTax: SafeInt['output']
        readonly amountDueTaxAmount: SafeInt['output']
        readonly lastProgressWithRetentionHeldOverride: {
          readonly __typename: 'SovLineItemProgress'
          readonly id: ID['output']
          readonly payApp: {
            readonly __typename: 'PayApp'
            readonly id: ID['output']
            readonly payAppNumber: SafeInt['output']
          }
        } | null
        readonly sovLineItem: {
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly sortOrder: SafeInt['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly isChangeOrder: Boolean['output']
          readonly changeOrderApprovedDate: DateTime['output'] | null
          readonly changeOrderEffectiveDate: DateTime['output'] | null
          readonly originalTotalValue: SafeInt['output']
          readonly latestTotalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly billedToDate: SafeInt['output']
          readonly defaultRetentionPercent: Float['output'] | null
          readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
          readonly preSitelineRetention: SafeInt['output']
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
          readonly changeOrderRequests: ReadonlyArray<{
            readonly __typename: 'ChangeOrderRequest'
            readonly id: ID['output']
            readonly name: String['output']
            readonly internalNumber: String['output'] | null
          }>
          readonly worksheetLineItems: ReadonlyArray<{
            readonly __typename: 'WorksheetLineItem'
            readonly id: ID['output']
            readonly code: String['output']
            readonly name: String['output']
            readonly costCode: String['output'] | null
            readonly totalValue: SafeInt['output']
            readonly previousBilled: SafeInt['output']
            readonly unitPrice: Decimal['output'] | null
            readonly unitName: String['output'] | null
            readonly billedToDate: SafeInt['output']
            readonly sortOrder: SafeInt['output']
            readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
          }>
          readonly taxGroup: {
            readonly __typename: 'TaxGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly taxPercent: Float['output']
            readonly integrationMappings: JSONObject['output']
          } | null
        }
        readonly worksheetLineItemProgress: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItemProgress'
          readonly id: ID['output']
          readonly progressBilled: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly totalValue: SafeInt['output']
          readonly worksheetLineItem: {
            readonly __typename: 'WorksheetLineItem'
            readonly id: ID['output']
            readonly code: String['output']
            readonly name: String['output']
            readonly costCode: String['output'] | null
            readonly unitName: String['output'] | null
            readonly unitPrice: Decimal['output'] | null
            readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
          }
        }>
      } | null
      readonly rateTableItems: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableItem'
        readonly id: ID['output']
        readonly description: String['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly currentUnitsBilled: Float['output']
        readonly isOneOffItem: Boolean['output']
        readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
        readonly attachments: ReadonlyArray<{
          readonly __typename: 'PayAppAttachment'
          readonly id: ID['output']
        }>
      }>
    }>
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    }>
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type GetPayAppForLienWaiverQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppForLienWaiverQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly retentionOnly: Boolean['output']
    readonly status: PayAppStatus
    readonly payAppNumber: SafeInt['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type PayAppActivityQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type PayAppActivityQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'PayAppComment'
      readonly id: ID['output']
      readonly message: String['output']
      readonly createdAt: DateTime['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly isSitelineTeamMember: Boolean['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
  }
}

export type NewPayAppCommentSubscriptionVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type NewPayAppCommentSubscription = {
  readonly __typename: 'Subscription'
  readonly newPayAppComment: {
    readonly __typename: 'PayAppComment'
    readonly id: ID['output']
    readonly message: String['output']
    readonly createdAt: DateTime['output']
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly isSitelineTeamMember: Boolean['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type NewPayAppEventSubscriptionVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type NewPayAppEventSubscription = {
  readonly __typename: 'Subscription'
  readonly newPayAppEvent: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'PayAppComment'
      readonly id: ID['output']
      readonly message: String['output']
      readonly createdAt: DateTime['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly isSitelineTeamMember: Boolean['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
  }
}

export type ContractActivityQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type ContractActivityQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly events: ReadonlyArray<{
      readonly __typename: 'ContractEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: ContractEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'ContractComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: ContractCommentType
      readonly message: String['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly isSitelineTeamMember: Boolean['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
  }
}

export type NewContractCommentSubscriptionVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type NewContractCommentSubscription = {
  readonly __typename: 'Subscription'
  readonly newContractComment: {
    readonly __typename: 'ContractComment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: ContractCommentType
    readonly message: String['output']
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly isSitelineTeamMember: Boolean['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type NewContractEventSubscriptionVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type NewContractEventSubscription = {
  readonly __typename: 'Subscription'
  readonly newContractEvent: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly events: ReadonlyArray<{
      readonly __typename: 'ContractEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: ContractEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'ContractComment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: ContractCommentType
      readonly message: String['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly isSitelineTeamMember: Boolean['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
  }
}

export type VerifiedEmailAliasSubscriptionVariables = Exact<{
  companyUserEmailAliasId: Scalars['ID']['input']
}>

export type VerifiedEmailAliasSubscription = {
  readonly __typename: 'Subscription'
  readonly verifiedEmailAlias: {
    readonly __typename: 'CompanyUserEmailAlias'
    readonly id: ID['output']
    readonly emailAddress: EmailAddress['output']
    readonly isVerified: Boolean['output']
  }
}

export type GetLeadPMsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetLeadPMsQuery = {
  readonly __typename: 'Query'
  readonly leadPMs: ReadonlyArray<{
    readonly __typename: 'ContractUser'
    readonly id: ID['output']
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }
  }>
}

export type GetGeneralContractorsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetGeneralContractorsQuery = {
  readonly __typename: 'Query'
  readonly generalContractors: ReadonlyArray<{
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
  }>
}

export type UpdatePayAppMutationVariables = Exact<{
  input: UpdatePayAppInput
}>

export type UpdatePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayApp: {
    readonly __typename: 'PayApp'
    readonly predictedPaymentDate: DateTime['output'] | null
    readonly invoiceNumber: String['output'] | null
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly billingType: BillingType
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
  }
}

export type UpdateCompanyRequestMutationVariables = Exact<{
  input: UpdateCompanyInput
}>

export type UpdateCompanyRequestMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompany: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly name: String['output']
    readonly nickname: String['output'] | null
    readonly type: CompanyType
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
    readonly agingIntervalType: AgingIntervalType
    readonly defaultRetentionPercent: Float['output'] | null
    readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
    readonly defaultDaysBeforePayAppDue: Int['output'] | null
    readonly enableBillingWorksheets: Boolean['output']
    readonly allowLumpSumOverbilling: Boolean['output']
    readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
    readonly metadata: {
      readonly __typename: 'CompanyMetadata'
      readonly monthlyCreatePayAppReminderDate: Int['output'] | null
    }
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly archivedAt: DateTime['output'] | null
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly metadata: JSONObject['output']
    }>
    readonly picture: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
    } | null
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }>
  }
}

export type UpdateLocationRequestMutationVariables = Exact<{
  input: UpdateLocationInput
}>

export type UpdateLocationRequestMutation = {
  readonly __typename: 'Mutation'
  readonly updateLocation: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
}

export type CreateLocationRequestMutationVariables = Exact<{
  input: CreateLocationInput
}>

export type CreateLocationRequestMutation = {
  readonly __typename: 'Mutation'
  readonly createLocation: {
    readonly __typename: 'Location'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly nickname: String['output'] | null
    readonly street1: String['output'] | null
    readonly street2: String['output'] | null
    readonly city: String['output']
    readonly county: String['output'] | null
    readonly state: String['output']
    readonly country: String['output']
    readonly postalCode: String['output'] | null
    readonly timeZone: String['output']
    readonly coordinates: LatLng['output']
  }
}

export type DeleteOfficeLocationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteOfficeLocationMutation = {
  readonly __typename: 'Mutation'
  readonly deleteLocation: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly locations: ReadonlyArray<{
      readonly __typename: 'Location'
      readonly id: ID['output']
    }>
  }
}

export type GetCompanyContactsQueryVariables = Exact<{
  input: GetCompanyContactsInput
}>

export type GetCompanyContactsQuery = {
  readonly __typename: 'Query'
  readonly companyContacts: ReadonlyArray<{
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }>
}

export type CreateCompanyContactMutationVariables = Exact<{
  input: CreateCompanyContactInput
}>

export type CreateCompanyContactMutation = {
  readonly __typename: 'Mutation'
  readonly createCompanyContact: {
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }
}

export type UpdateCompanyContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
  values: CompanyContactValuesInput
  removePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>
  removeJobTitle?: InputMaybe<Scalars['Boolean']['input']>
}>

export type UpdateCompanyContactMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompanyContact: {
    readonly __typename: 'CompanyContact'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly companyName: String['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
  }
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  readonly __typename: 'Mutation'
  readonly updateUser: {
    readonly __typename: 'User'
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type AddUserTutorialShownMutationVariables = Exact<{
  input: AddUserTutorialShownInput
}>

export type AddUserTutorialShownMutation = {
  readonly __typename: 'Mutation'
  readonly addUserTutorialShown: {
    readonly __typename: 'User'
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type AddGeneralContractorContactToLegalRequirementContactsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
  companyContactId: Scalars['ID']['input']
}>

export type AddGeneralContractorContactToLegalRequirementContactsMutation = {
  readonly __typename: 'Mutation'
  readonly addGeneralContractorContactToLegalRequirementContacts: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly legalRequirementContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type DeleteVendorMutationVariables = Exact<{
  input: DeleteVendorInput
}>

export type DeleteVendorMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendor: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type DeleteGeneralContractorContactFromLegalRequirementContactsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
  companyContactId: Scalars['ID']['input']
}>

export type DeleteGeneralContractorContactFromLegalRequirementContactsMutation = {
  readonly __typename: 'Mutation'
  readonly deleteGeneralContractorContactFromLegalRequirementContacts: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly legalRequirementContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type AddGeneralContractorContactToVendorSubmitToContactsMutationVariables = Exact<{
  input: GeneralContractorVendorSubmitToContactInput
}>

export type AddGeneralContractorContactToVendorSubmitToContactsMutation = {
  readonly __typename: 'Mutation'
  readonly addGeneralContractorContactToVendorSubmitToContacts: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly vendorSubmitToContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type DeleteGeneralContractorContactFromVendorSubmitToContactsMutationVariables = Exact<{
  input: GeneralContractorVendorSubmitToContactInput
}>

export type DeleteGeneralContractorContactFromVendorSubmitToContactsMutation = {
  readonly __typename: 'Mutation'
  readonly deleteGeneralContractorContactFromVendorSubmitToContacts: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly vendorSubmitToContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type GetContractVendorSubmitToContactsQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractVendorSubmitToContactsQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly vendorSubmitToContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type VendorQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type VendorQuery = {
  readonly __typename: 'Query'
  readonly vendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }
}

export type GetVendorsQueryVariables = Exact<{
  input: GetVendorsInput
}>

export type GetVendorsQuery = {
  readonly __typename: 'Query'
  readonly vendors: ReadonlyArray<{
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }>
}

export type CreateVendorMutationVariables = Exact<{
  input: CreateVendorInput
}>

export type CreateVendorMutation = {
  readonly __typename: 'Mutation'
  readonly createVendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }
}

export type CreateVendorContactMutationVariables = Exact<{
  input: CreateVendorContactInput
}>

export type CreateVendorContactMutation = {
  readonly __typename: 'Mutation'
  readonly createVendorContact: {
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly vendor: { readonly __typename: 'Vendor'; readonly id: ID['output'] }
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }
}

export type LienWaiverRequestWithTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type LienWaiverRequestWithTokenQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverRequestWithToken: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly status: DocumentRequestStatus
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly defaultSignature: {
        readonly __typename: 'Signature'
        readonly id: ID['output']
        readonly signatureName: String['output'] | null
        readonly signatureFont: SignatureFont | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
      } | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
    readonly requestedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly lienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly amount: SafeInt['output'] | null
      readonly date: DateTime['output']
      readonly claimant: String['output']
      readonly message: String['output'] | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly emailedContacts: ReadonlyArray<{
          readonly __typename: 'CompanyContact'
          readonly id: ID['output']
          readonly companyName: String['output']
        }>
      }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
    }
  } | null
}

export type LienWaiverRequestIdFromTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type LienWaiverRequestIdFromTokenQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverRequestWithToken: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
  } | null
}

export type LienWaiverRequestsWithTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type LienWaiverRequestsWithTokenQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverRequestsWithToken: ReadonlyArray<{
    readonly __typename: 'LienWaiverRequestWithToken'
    readonly token: String['output']
    readonly lienWaiverRequest: {
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly status: DocumentRequestStatus
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
      readonly requestedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly lienWaiver: {
        readonly __typename: 'LienWaiver'
        readonly id: ID['output']
        readonly type: LienWaiverType
        readonly timeZone: String['output']
        readonly amount: SafeInt['output'] | null
        readonly date: DateTime['output']
        readonly claimant: String['output']
        readonly message: String['output'] | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly emailedContacts: ReadonlyArray<{
            readonly __typename: 'CompanyContact'
            readonly id: ID['output']
            readonly companyName: String['output']
          }>
        }>
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly name: String['output']
            readonly projectNumber: String['output']
          }
        }
        readonly formValues: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotationValue'
          readonly id: ID['output']
          readonly value: String['output']
          readonly metadata: JSONObject['output']
          readonly annotation: {
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly syncTag: String['output'] | null
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
          }
        }>
        readonly requestAttachments: ReadonlyArray<{
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
      }
    }
  }>
}

export type MarkLienWaiverRequestViewedMutationVariables = Exact<{
  input: MarkLienWaiverRequestViewedInput
}>

export type MarkLienWaiverRequestViewedMutation = {
  readonly __typename: 'Mutation'
  readonly markLienWaiverRequestViewed: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly status: DocumentRequestStatus
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly defaultSignature: {
        readonly __typename: 'Signature'
        readonly id: ID['output']
        readonly signatureName: String['output'] | null
        readonly signatureFont: SignatureFont | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
      } | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
    readonly requestedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    } | null
    readonly lienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly amount: SafeInt['output'] | null
      readonly date: DateTime['output']
      readonly claimant: String['output']
      readonly message: String['output'] | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly emailedContacts: ReadonlyArray<{
          readonly __typename: 'CompanyContact'
          readonly id: ID['output']
          readonly companyName: String['output']
        }>
      }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
    }
  }
}

export type LegalDocumentRequestWithTokenQueryVariables = Exact<{
  input: GetWithTokenInput
}>

export type LegalDocumentRequestWithTokenQuery = {
  readonly __typename: 'Query'
  readonly legalDocumentRequestWithToken: {
    readonly __typename: 'LegalDocumentRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly legalDocument: {
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly timeZone: String['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly name: String['output']
        readonly type: DocumentType
        readonly vendorTemplate: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly name: String['output']
          readonly url: URL['output']
        } | null
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly projectNumber: String['output']
            readonly name: String['output']
          }
        }
      }
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  } | null
}

export type UploadFormTemplatesMutationVariables = Exact<{
  input: UploadFormTemplatesInput
}>

export type UploadFormTemplatesMutation = {
  readonly __typename: 'Mutation'
  readonly uploadFormTemplates: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type UploadLegalDocumentWithTokenMutationVariables = Exact<{
  input: UploadLegalDocumentWithTokenInput
}>

export type UploadLegalDocumentWithTokenMutation = {
  readonly __typename: 'Mutation'
  readonly uploadLegalDocumentWithToken: {
    readonly __typename: 'LegalDocumentRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  }
}

export type LienWaiverRequestQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LienWaiverRequestQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverRequest: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly defaultSignature: {
        readonly __typename: 'Signature'
        readonly id: ID['output']
        readonly signatureName: String['output'] | null
        readonly signatureFont: SignatureFont | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
      } | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  }
}

export type GetLienWaiverWithTokenQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type GetLienWaiverWithTokenQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverWithToken: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type SignLowerTierLienWaiverMutationVariables = Exact<{
  input: SignLowerTierLienWaiverInput
}>

export type SignLowerTierLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly signLowerTierLienWaiver: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly defaultSignature: {
        readonly __typename: 'Signature'
        readonly id: ID['output']
        readonly signatureName: String['output'] | null
        readonly signatureFont: SignatureFont | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
      } | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  }
}

export type SaveLowerTierLienWaiverFormValuesMutationVariables = Exact<{
  input: SaveLowerTierLienWaiverFormValuesInput
}>

export type SaveLowerTierLienWaiverFormValuesMutation = {
  readonly __typename: 'Mutation'
  readonly saveLowerTierLienWaiverFormValues: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly updatedAt: DateTime['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly lienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly amount: SafeInt['output'] | null
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
    }
    readonly vendorContact: {
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly defaultSignature: {
        readonly __typename: 'Signature'
        readonly id: ID['output']
        readonly signatureName: String['output'] | null
        readonly signatureFont: SignatureFont | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
      } | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }
  }
}

export type BatchSendLienWaiverRequestsMutationVariables = Exact<{
  input: BatchSendLienWaiverRequestsInput
}>

export type BatchSendLienWaiverRequestsMutation = {
  readonly __typename: 'Mutation'
  readonly batchSendLienWaiverRequests: ReadonlyArray<{
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly date: DateTime['output']
    readonly timeZone: String['output']
    readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: { readonly __typename: 'Vendor'; readonly id: ID['output'] }
    } | null
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
  }>
}

export type DeleteVendorLienWaiverMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteVendorLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendorLienWaiver: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type SubmitVendorLienWaiversMutationVariables = Exact<{
  input: SubmitVendorLienWaiversInput
}>

export type SubmitVendorLienWaiversMutation = {
  readonly __typename: 'Mutation'
  readonly submitVendorLienWaivers: ReadonlyArray<{
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }>
}

export type SendCompleteLienWaiverRequestMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type SendCompleteLienWaiverRequestMutation = {
  readonly __typename: 'Mutation'
  readonly sendCompleteLienWaiverRequest: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
  }
}

export type LegalRequirementQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LegalRequirementQuery = {
  readonly __typename: 'Query'
  readonly legalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
    }
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type LegalDocumentQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type LegalDocumentQuery = {
  readonly __typename: 'Query'
  readonly legalDocument: {
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type UpdateLienWaiverMutationVariables = Exact<{
  input: UpdateLienWaiverInput
}>

export type UpdateLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly updateLienWaiver: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type CreateVendorLienWaiverMutationVariables = Exact<{
  input: CreateVendorLienWaiverInput
}>

export type CreateVendorLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly createVendorLienWaiver: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type UploadLienWaiverFileMutationVariables = Exact<{
  input: UploadLienWaiverInput
}>

export type UploadLienWaiverFileMutation = {
  readonly __typename: 'Mutation'
  readonly uploadLienWaiverFile: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type MarkNotificationsReadMutationVariables = Exact<{
  notificationIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type MarkNotificationsReadMutation = {
  readonly __typename: 'Mutation'
  readonly markNotificationsRead: ReadonlyArray<{
    readonly __typename: 'Notification'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: NotificationType
    readonly message: String['output']
    readonly link: String['output']
    readonly read: Boolean['output']
    readonly createdByUserInitials: String['output'] | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly nickname: String['output'] | null
        readonly name: String['output']
      }
    } | null
  }>
}

export type GetNotificationsQueryVariables = Exact<{
  limit: Scalars['Int']['input']
  cursor?: InputMaybe<Scalars['DateTime']['input']>
}>

export type GetNotificationsQuery = {
  readonly __typename: 'Query'
  readonly notifications: {
    readonly __typename: 'NotificationFeed'
    readonly cursor: DateTime['output']
    readonly hasNext: Boolean['output']
    readonly notifications: ReadonlyArray<{
      readonly __typename: 'Notification'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: NotificationType
      readonly message: String['output']
      readonly link: String['output']
      readonly read: Boolean['output']
      readonly createdByUserInitials: String['output'] | null
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly timeZone: String['output']
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly nickname: String['output'] | null
          readonly name: String['output']
        }
      } | null
    }>
  }
}

export type SendLegalDocumentsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
  legalDocumentIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
  includeTableOfContents: Scalars['Boolean']['input']
  subject: Scalars['String']['input']
  message?: InputMaybe<Scalars['String']['input']>
}>

export type SendLegalDocumentsMutation = {
  readonly __typename: 'Mutation'
  readonly sendLegalDocuments: ReadonlyArray<{
    readonly __typename: 'LegalDocument'
    readonly id: ID['output']
  }>
}

export type UploadLegalDocumentsMutationVariables = Exact<{
  input: UploadLegalDocumentsInput
}>

export type UploadLegalDocumentsMutation = {
  readonly __typename: 'Mutation'
  readonly uploadLegalDocuments: {
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type UpdateLegalDocumentMutationVariables = Exact<{
  input: UpdateLegalDocumentInput
}>

export type UpdateLegalDocumentMutation = {
  readonly __typename: 'Mutation'
  readonly updateLegalDocument: {
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type UpdateUploadedLegalDocumentMutationVariables = Exact<{
  input: UpdateUploadedLegalDocumentInput
}>

export type UpdateUploadedLegalDocumentMutation = {
  readonly __typename: 'Mutation'
  readonly updateUploadedLegalDocument: {
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type SignLegalDocumentMutationVariables = Exact<{
  input: SignLegalDocumentInput
}>

export type SignLegalDocumentMutation = {
  readonly __typename: 'Mutation'
  readonly signLegalDocument: {
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type DeleteLegalDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteLegalDocumentMutation = {
  readonly __typename: 'Mutation'
  readonly deleteLegalDocument: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type SkipLegalRequirementPeriodMutationVariables = Exact<{
  input: SkipLegalRequirementPeriodInput
}>

export type SkipLegalRequirementPeriodMutation = {
  readonly __typename: 'Mutation'
  readonly skipLegalRequirementPeriod: {
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type UnskipLegalRequirementPeriodMutationVariables = Exact<{
  input: SkipLegalRequirementPeriodInput
}>

export type UnskipLegalRequirementPeriodMutation = {
  readonly __typename: 'Mutation'
  readonly unskipLegalRequirementPeriod: {
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type SignUnconditionalLienWaiverMutationVariables = Exact<{
  input: SignUnconditionalLienWaiverInput
}>

export type SignUnconditionalLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly signUnconditionalLienWaiver: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type SubmitUnconditionalLienWaiverMutationVariables = Exact<{
  input: SubmitUnconditionalLienWaiverInput
}>

export type SubmitUnconditionalLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly submitUnconditionalLienWaiver: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type ClearUnconditionalLienWaiverMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ClearUnconditionalLienWaiverMutation = {
  readonly __typename: 'Mutation'
  readonly clearUnconditionalLienWaiver: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type ModifyNotificationPreferencesMutationVariables = Exact<{
  notificationTypes: ReadonlyArray<NotificationType> | NotificationType
  block: Scalars['Boolean']['input']
}>

export type ModifyNotificationPreferencesMutation = {
  readonly __typename: 'Mutation'
  readonly modifyNotificationPreferences: {
    readonly __typename: 'User'
    readonly blockedNotifications: ReadonlyArray<NotificationType>
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type ModifyEmailPreferencesMutationVariables = Exact<{
  emailTypes: ReadonlyArray<EmailType> | EmailType
  block: Scalars['Boolean']['input']
}>

export type ModifyEmailPreferencesMutation = {
  readonly __typename: 'Mutation'
  readonly modifyEmailPreferences: {
    readonly __typename: 'User'
    readonly blockedEmails: ReadonlyArray<EmailType>
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type UpdatePayAppInvoiceReadyMutationVariables = Exact<{
  input: PayAppInvoiceReadyInput
}>

export type UpdatePayAppInvoiceReadyMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppInvoiceReady: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type SavePayAppFormValuesMutationVariables = Exact<{
  input: SavePayAppFormValuesInput
}>

export type SavePayAppFormValuesMutation = {
  readonly __typename: 'Mutation'
  readonly savePayAppFormValues: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    }>
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type PayAppFormsReadyForReviewMutationVariables = Exact<{
  input: PayAppFormsReadyForReviewInput
}>

export type PayAppFormsReadyForReviewMutation = {
  readonly __typename: 'Mutation'
  readonly payAppFormsReadyForReview: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
    }>
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type ClearPayAppFormValuesMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type ClearPayAppFormValuesMutation = {
  readonly __typename: 'Mutation'
  readonly clearPayAppFormValues: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
      } | null
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
  }
}

export type MarkPayAppAsPaidMutationVariables = Exact<{
  input: MarkPayAppAsPaidInput
}>

export type MarkPayAppAsPaidMutation = {
  readonly __typename: 'Mutation'
  readonly markPayAppAsPaid: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
  }
}

export type MarkPayAppAsSubmittedMutationVariables = Exact<{
  input: MarkPayAppAsSubmittedInput
}>

export type MarkPayAppAsSubmittedMutation = {
  readonly __typename: 'Mutation'
  readonly markPayAppAsSubmitted: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
    } | null
  }
}

export type MarkPayAppAsSyncedMutationVariables = Exact<{
  input: MarkPayAppAsSyncedInput
}>

export type MarkPayAppAsSyncedMutation = {
  readonly __typename: 'Mutation'
  readonly markPayAppAsSynced: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly statusChangedAt: DateTime['output']
  }
}

export type CreateChangeOrderMutationVariables = Exact<{
  input: CreateChangeOrderInput
}>

export type CreateChangeOrderMutation = {
  readonly __typename: 'Mutation'
  readonly createChangeOrder: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }
}

export type UpdateChangeOrderMutationVariables = Exact<{
  input: UpdateChangeOrderInput
}>

export type UpdateChangeOrderMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrder: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }
}

export type DeleteChangeOrderMutationVariables = Exact<{
  input: DeleteChangeOrderInput
}>

export type DeleteChangeOrderMutation = {
  readonly __typename: 'Mutation'
  readonly deleteChangeOrder: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
  }
}

export type PushNotificationSubscriptionVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type PushNotificationSubscription = {
  readonly __typename: 'Subscription'
  readonly pushNotification: {
    readonly __typename: 'Notification'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: NotificationType
    readonly message: String['output']
    readonly link: String['output']
    readonly read: Boolean['output']
    readonly createdByUserInitials: String['output'] | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly nickname: String['output'] | null
        readonly name: String['output']
      }
    } | null
  }
}

export type UpdatedLienWaiverRequestChangesSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type UpdatedLienWaiverRequestChangesSubscription = {
  readonly __typename: 'Subscription'
  readonly updatedLienWaiverRequestChanges: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly actionTakenAt: DateTime['output'] | null
    readonly status: DocumentRequestStatus
    readonly statusLogs: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequestStatusLog'
      readonly id: ID['output']
      readonly status: DocumentRequestStatus
    }>
    readonly lienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly amount: SafeInt['output'] | null
      readonly type: LienWaiverType
      readonly date: DateTime['output']
      readonly timeZone: String['output']
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: { readonly __typename: 'Vendor'; readonly id: ID['output'] }
      } | null
      readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    }
  }
}

export type UpdatedLegalDocumentRequestChangesSubscriptionVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type UpdatedLegalDocumentRequestChangesSubscription = {
  readonly __typename: 'Subscription'
  readonly updatedLegalDocumentRequestChanges: {
    readonly __typename: 'LegalDocumentRequest'
    readonly id: ID['output']
    readonly actionTakenAt: DateTime['output'] | null
    readonly status: DocumentRequestStatus
    readonly legalDocument: {
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
    }
  }
}

export type OptOutOfBillingForMonthMutationVariables = Exact<{
  input: OptOutOfBillingInput
}>

export type OptOutOfBillingForMonthMutation = {
  readonly __typename: 'Mutation'
  readonly optOutOfBillingForMonth: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly optedOutMonths: ReadonlyArray<{
      readonly __typename: 'ContractOptedOutMonth'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly month: String['output']
      readonly reason: String['output'] | null
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    }>
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly billedToDate: SafeInt['output']
        readonly totalRetention: SafeInt['output']
      }>
    } | null
  }
}

export type OptInToBillingForMonthMutationVariables = Exact<{
  input: OptInToBillingInput
}>

export type OptInToBillingForMonthMutation = {
  readonly __typename: 'Mutation'
  readonly optInToBillingForMonth: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly optedOutMonths: ReadonlyArray<{
      readonly __typename: 'ContractOptedOutMonth'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly month: String['output']
      readonly reason: String['output'] | null
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    }>
  }
}

export type UpdatePayAppStatusMutationVariables = Exact<{
  input: PayAppStatusInput
}>

export type UpdatePayAppStatusMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppStatus: {
    readonly __typename: 'PayApp'
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type UpdatePayAppSubmittedAtMutationVariables = Exact<{
  input: UpdatePayAppSubmittedAtInput
}>

export type UpdatePayAppSubmittedAtMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppSubmittedAt: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly statusChangedAt: DateTime['output']
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
    } | null
  }
}

export type UpdatePayAppSyncedAtMutationVariables = Exact<{
  input: UpdatePayAppSyncedAtInput
}>

export type UpdatePayAppSyncedAtMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppSyncedAt: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly statusChangedAt: DateTime['output']
  }
}

export type UpdatePayAppPaidAtMutationVariables = Exact<{
  input: UpdatePayAppPaidAtInput
}>

export type UpdatePayAppPaidAtMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppPaidAt: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
    }>
  }
}

export type UpdateSovMutationVariables = Exact<{
  input: UpdateSovInput
}>

export type UpdateSovMutation = {
  readonly __typename: 'Mutation'
  readonly updateSov: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly preSitelineRetention: SafeInt['output']
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly preSitelineRetention: SafeInt['output']
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }>
    } | null
  }
}

export type UpdatedSovLineItemProgressSubscriptionVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UpdatedSovLineItemProgressSubscription = {
  readonly __typename: 'Subscription'
  readonly updatedSovLineItemProgress: {
    readonly __typename: 'SovLineItemProgressEvent'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly isAdmin: Boolean['output']
    readonly type: SovLineItemProgressEventType
    readonly metadata: JSONObject['output']
    readonly createdBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
    }
  }
}

export type AddPayAppCommentMutationVariables = Exact<{
  input: AddPayAppCommentInput
}>

export type AddPayAppCommentMutation = {
  readonly __typename: 'Mutation'
  readonly addPayAppComment: {
    readonly __typename: 'PayAppComment'
    readonly id: ID['output']
    readonly message: String['output']
    readonly createdAt: DateTime['output']
    readonly payApp: { readonly __typename: 'PayApp'; readonly id: ID['output'] }
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly isSitelineTeamMember: Boolean['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type AddContractCommentMutationVariables = Exact<{
  input: AddContractCommentInput
}>

export type AddContractCommentMutation = {
  readonly __typename: 'Mutation'
  readonly addContractComment: {
    readonly __typename: 'ContractComment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly type: ContractCommentType
    readonly message: String['output']
    readonly contract: { readonly __typename: 'Contract'; readonly id: ID['output'] }
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly isSitelineTeamMember: Boolean['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type UploadPayAppFileMutationVariables = Exact<{
  input: UploadPayAppInput
}>

export type UploadPayAppFileMutation = {
  readonly __typename: 'Mutation'
  readonly uploadPayAppFile: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type GetSovLineItemGroupsQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type GetSovLineItemGroupsQuery = {
  readonly __typename: 'Query'
  readonly sovLineItemGroups: ReadonlyArray<{
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }>
}

export type CreateSovLineItemGroupMutationVariables = Exact<{
  input: CreateSovLineItemGroupInput
}>

export type CreateSovLineItemGroupMutation = {
  readonly __typename: 'Mutation'
  readonly createSovLineItemGroup: {
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }
}

export type UpdateSovLineItemGroupMutationVariables = Exact<{
  input: UpdateSovLineItemGroupInput
}>

export type UpdateSovLineItemGroupMutation = {
  readonly __typename: 'Mutation'
  readonly updateSovLineItemGroup: {
    readonly __typename: 'SovLineItemGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }
}

export type DeleteSovLineItemGroupMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSovLineItemGroupMutation = {
  readonly __typename: 'Mutation'
  readonly deleteSovLineItemGroup: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type AddGeneralContractorContactToPayAppMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
  companyId: Scalars['ID']['input']
  companyContactId: Scalars['ID']['input']
}>

export type AddGeneralContractorContactToPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly addGeneralContractorContactToPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type DeleteGeneralContractorContactFromPayAppMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
  companyId: Scalars['ID']['input']
  companyContactId: Scalars['ID']['input']
}>

export type DeleteGeneralContractorContactFromPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly deleteGeneralContractorContactFromPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type RoundProgressBillingsMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type RoundProgressBillingsMutation = {
  readonly __typename: 'Mutation'
  readonly roundProgressBillings: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type CreateQuickBillingProjectMutationVariables = Exact<{
  input: CreateQuickBillingProjectInput
}>

export type CreateQuickBillingProjectMutation = {
  readonly __typename: 'Mutation'
  readonly createQuickBillingProject: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    }>
  }
}

export type UpdateQuickBillingProjectMutationVariables = Exact<{
  input: UpdateQuickBillingProjectInput
}>

export type UpdateQuickBillingProjectMutation = {
  readonly __typename: 'Mutation'
  readonly updateQuickBillingProject: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
  }
}

export type CreateAmountLineItemMutationVariables = Exact<{
  input: CreateAmountLineItemInput
}>

export type CreateAmountLineItemMutation = {
  readonly __typename: 'Mutation'
  readonly createAmountLineItem: {
    readonly __typename: 'AmountLineItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly amount: SafeInt['output']
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly taxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
  }
}

export type SetAmountLineItemAmountMutationVariables = Exact<{
  input: SetAmountLineItemAmountInput
}>

export type SetAmountLineItemAmountMutation = {
  readonly __typename: 'Mutation'
  readonly setAmountLineItemAmount: {
    readonly __typename: 'AmountLineItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly amount: SafeInt['output']
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly taxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
  }
}

export type SetAmountLineItemDescriptionMutationVariables = Exact<{
  input: SetAmountLineItemDescriptionInput
}>

export type SetAmountLineItemDescriptionMutation = {
  readonly __typename: 'Mutation'
  readonly setAmountLineItemDescription: {
    readonly __typename: 'AmountLineItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly amount: SafeInt['output']
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly taxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
  }
}

export type SetAmountLineItemTaxGroupMutationVariables = Exact<{
  input: SetAmountLineItemTaxGroupInput
}>

export type SetAmountLineItemTaxGroupMutation = {
  readonly __typename: 'Mutation'
  readonly setAmountLineItemTaxGroup: {
    readonly __typename: 'AmountLineItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly amount: SafeInt['output']
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly sortOrder: SafeInt['output']
    readonly taxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
  }
}

export type CreateTaxGroupMutationVariables = Exact<{
  input: CreateTaxGroupInput
}>

export type CreateTaxGroupMutation = {
  readonly __typename: 'Mutation'
  readonly createTaxGroup: {
    readonly __typename: 'TaxGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
    readonly integrationMappings: JSONObject['output']
  }
}

export type UpdateTaxGroupMutationVariables = Exact<{
  input: UpdateTaxGroupInput
}>

export type UpdateTaxGroupMutation = {
  readonly __typename: 'Mutation'
  readonly updateTaxGroup: {
    readonly __typename: 'TaxGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
    readonly integrationMappings: JSONObject['output']
  }
}

export type UpdateTaxCalculationTypeMutationVariables = Exact<{
  input: UpdateTaxCalculationTypeInput
}>

export type UpdateTaxCalculationTypeMutation = {
  readonly __typename: 'Mutation'
  readonly updateTaxCalculationType: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly taxCalculationType: TaxCalculationType
    readonly defaultTaxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
    }>
  }
}

export type SetPayAppLineItemSortOrdersMutationVariables = Exact<{
  input: SetPayAppLineItemSortOrdersInput
}>

export type SetPayAppLineItemSortOrdersMutation = {
  readonly __typename: 'Mutation'
  readonly setPayAppLineItemSortOrders: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly billingType: BillingType
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }>
  }
}

export type DeleteAmountLineItemMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteAmountLineItemMutation = {
  readonly __typename: 'Mutation'
  readonly deleteAmountLineItem: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CreatePayAppAttachmentMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
  input: PayAppAttachmentInput
}>

export type CreatePayAppAttachmentMutation = {
  readonly __typename: 'Mutation'
  readonly createPayAppAttachment: {
    readonly __typename: 'PayAppAttachment'
    readonly id: ID['output']
    readonly description: String['output'] | null
    readonly name: String['output']
    readonly createdAt: DateTime['output']
    readonly uploadId: ID['output']
    readonly isEmailAttachment: Boolean['output']
    readonly isInternalOnly: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
    readonly sovLineItemProgress: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly sortOrder: SafeInt['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
    } | null
    readonly contractPayAppAttachment: {
      readonly __typename: 'ContractPayAppAttachment'
      readonly id: ID['output']
    } | null
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type UpdatePayAppAttachmentMutationVariables = Exact<{
  input: UpdatePayAppAttachmentInput
}>

export type UpdatePayAppAttachmentMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppAttachment: {
    readonly __typename: 'PayAppAttachment'
    readonly id: ID['output']
    readonly description: String['output'] | null
    readonly name: String['output']
    readonly createdAt: DateTime['output']
    readonly uploadId: ID['output']
    readonly isEmailAttachment: Boolean['output']
    readonly isInternalOnly: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
    readonly sovLineItemProgress: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly sortOrder: SafeInt['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
    } | null
    readonly contractPayAppAttachment: {
      readonly __typename: 'ContractPayAppAttachment'
      readonly id: ID['output']
    } | null
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type DeletePayAppAttachmentMutationVariables = Exact<{
  input: DeletePayAppAttachmentInput
}>

export type DeletePayAppAttachmentMutation = {
  readonly __typename: 'Mutation'
  readonly deletePayAppAttachment: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type UpdatedPayAppSubscriptionVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UpdatedPayAppSubscription = {
  readonly __typename: 'Subscription'
  readonly updatePayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly statusChangedAt: DateTime['output']
    readonly invoiceReady: Boolean['output']
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
  }
}

export type CompanyUsersQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyUsersQuery = {
  readonly __typename: 'Query'
  readonly companyUsers: ReadonlyArray<{
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly isSitelineAdmin: Boolean['output']
    readonly status: UserStatus
    readonly permissions: ReadonlyArray<Permission>
    readonly emailAlias: {
      readonly __typename: 'CompanyUserEmailAlias'
      readonly id: ID['output']
      readonly emailAddress: EmailAddress['output']
      readonly isVerified: Boolean['output']
      readonly verificationCodeExpiresAt: DateTime['output']
    } | null
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }
    readonly companyLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly city: String['output']
    }
  }>
}

export type InviteCompanyUserMutationVariables = Exact<{
  input: InviteCompanyUserInput
}>

export type InviteCompanyUserMutation = {
  readonly __typename: 'Mutation'
  readonly inviteCompanyUser: {
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly isSitelineAdmin: Boolean['output']
    readonly status: UserStatus
    readonly permissions: ReadonlyArray<Permission>
    readonly emailAlias: {
      readonly __typename: 'CompanyUserEmailAlias'
      readonly id: ID['output']
      readonly emailAddress: EmailAddress['output']
      readonly isVerified: Boolean['output']
      readonly verificationCodeExpiresAt: DateTime['output']
    } | null
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }
    readonly companyLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly city: String['output']
    }
  }
}

export type UpdateCompanyUserMutationVariables = Exact<{
  input: UpdateCompanyUserInput
}>

export type UpdateCompanyUserMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompanyUser: {
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly isSitelineAdmin: Boolean['output']
    readonly status: UserStatus
    readonly permissions: ReadonlyArray<Permission>
    readonly emailAlias: {
      readonly __typename: 'CompanyUserEmailAlias'
      readonly id: ID['output']
      readonly emailAddress: EmailAddress['output']
      readonly isVerified: Boolean['output']
      readonly verificationCodeExpiresAt: DateTime['output']
    } | null
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }
    readonly companyLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly city: String['output']
    }
  }
}

export type UpdateUserOfficeLocationMutationVariables = Exact<{
  input: UpdateUserOfficeLocationInput
}>

export type UpdateUserOfficeLocationMutation = {
  readonly __typename: 'Mutation'
  readonly updateUserOfficeLocation: {
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly companyLocation: { readonly __typename: 'Location'; readonly id: ID['output'] }
  }
}

export type DeactivateUserMutationVariables = Exact<{
  input: DeactivateUserInput
}>

export type DeactivateUserMutation = {
  readonly __typename: 'Mutation'
  readonly deactivateUser: {
    readonly __typename: 'User'
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type ReactivateUserMutationVariables = Exact<{
  input: ReactivateUserInput
}>

export type ReactivateUserMutation = {
  readonly __typename: 'Mutation'
  readonly reactivateUser: {
    readonly __typename: 'User'
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type GetContractUsersQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractUsersQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly role: ContractUserRole
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
  }
}

export type GetContractsQueryVariables = Exact<{
  input: GetContractsInput
}>

export type GetContractsQuery = {
  readonly __typename: 'Query'
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly timeZone: String['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output']
    }>
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }>
}

export type GetCurrentVendorLienWaiverProjectsQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GetCurrentVendorLienWaiverProjectsQuery = {
  readonly __typename: 'Query'
  readonly currentVendorLienWaiverProjects: ReadonlyArray<{
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly timeZone: String['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
    readonly metadata: {
      readonly __typename: 'ProjectMetadata'
      readonly payAppDueOnDayOfMonth: Int['output']
    }
  }>
}

export type ArchiveContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArchiveContractMutation = {
  readonly __typename: 'Mutation'
  readonly archiveContract: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly status: ContractStatus
    }>
  }
}

export type UnarchiveContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UnarchiveContractMutation = {
  readonly __typename: 'Mutation'
  readonly unarchiveContract: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly status: ContractStatus
    }>
  }
}

export type DeleteContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteContractMutation = {
  readonly __typename: 'Mutation'
  readonly deleteContract: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type GetProjectLienWaiversByMonthQueryVariables = Exact<{
  input: GetProjectLienWaiversByMonthInput
}>

export type GetProjectLienWaiversByMonthQuery = {
  readonly __typename: 'Query'
  readonly getProjectLienWaiversByMonth: ReadonlyArray<{
    readonly __typename: 'ProjectLienWaiversByMonth'
    readonly requiresLowerTierLienWaivers: Boolean['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lienWaiversByMonth: ReadonlyArray<{
      readonly __typename: 'LienWaiversByMonth'
      readonly month: SafeInt['output']
      readonly year: SafeInt['output']
      readonly lienWaivers: ReadonlyArray<{
        readonly __typename: 'LienWaiver'
        readonly id: ID['output']
        readonly type: LienWaiverType
        readonly timeZone: String['output']
        readonly claimant: String['output']
        readonly date: DateTime['output']
        readonly defaultAmount: SafeInt['output'] | null
        readonly defaultAmountText: String['output'] | null
        readonly amount: SafeInt['output'] | null
        readonly customer: String['output']
        readonly lastSendTime: DateTime['output'] | null
        readonly isJointCheck: Boolean['output']
        readonly subject: String['output'] | null
        readonly message: String['output'] | null
        readonly defaultExceptionAmounts: String['output'] | null
        readonly defaultExceptionDates: String['output'] | null
        readonly hasSelectedDefaultVendorContacts: Boolean['output']
        readonly owner: String['output']
        readonly physicalSignatureRequired: PhysicalSignatureRequired
        readonly lienWaiverRequests: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'LienWaiverRequestStatusLog'
            readonly id: ID['output']
            readonly status: DocumentRequestStatus
            readonly statusUpdatedAt: DateTime['output']
          }>
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly defaultSignature: {
              readonly __typename: 'Signature'
              readonly id: ID['output']
              readonly signatureName: String['output'] | null
              readonly signatureFont: SignatureFont | null
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly url: URL['output']
              } | null
            } | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
          readonly type: StoredFileType
          readonly name: String['output']
          readonly createdAt: DateTime['output']
          readonly size: SafeInt['output']
          readonly uploadedBy: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          } | null
        } | null
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
            readonly integrationMappings: JSONObject['output']
            readonly internalNotes: String['output'] | null
            readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
            readonly address: {
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            } | null
            readonly contacts: ReadonlyArray<{
              readonly __typename: 'VendorContact'
              readonly id: ID['output']
              readonly fullName: String['output']
              readonly email: EmailAddress['output']
              readonly phoneNumber: PhoneNumber['output'] | null
              readonly jobTitle: String['output'] | null
              readonly lowerTierTo: {
                readonly __typename: 'Vendor'
                readonly id: ID['output']
              } | null
            }>
            readonly companyIntegrations: ReadonlyArray<{
              readonly __typename: 'CompanyIntegration'
              readonly id: ID['output']
              readonly type: IntegrationType
              readonly label: String['output'] | null
            }>
          }
          readonly preliminaryNotices: ReadonlyArray<{
            readonly __typename: 'VendorPreliminaryNotice'
            readonly id: ID['output']
            readonly date: DateTime['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            }
          }>
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
        }>
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly name: String['output']
            readonly projectNumber: String['output']
          }
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        }
        readonly jobLocation: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }
        readonly formValues: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotationValue'
          readonly id: ID['output']
          readonly value: String['output']
          readonly metadata: JSONObject['output']
          readonly annotation: {
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly syncTag: String['output'] | null
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
          }
        }>
        readonly requestAttachments: ReadonlyArray<{
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }>
        readonly defaultVendorContacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
      }>
    }>
  }>
}

export type GetVendorLienWaiversByMonthQueryVariables = Exact<{
  input: GetVendorLienWaiversByMonthInput
}>

export type GetVendorLienWaiversByMonthQuery = {
  readonly __typename: 'Query'
  readonly getVendorLienWaiversByMonth: ReadonlyArray<{
    readonly __typename: 'VendorLienWaiversByMonth'
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly lienWaiversByMonth: ReadonlyArray<{
      readonly __typename: 'LienWaiversByMonth'
      readonly month: SafeInt['output']
      readonly year: SafeInt['output']
      readonly lienWaivers: ReadonlyArray<{
        readonly __typename: 'LienWaiver'
        readonly id: ID['output']
        readonly type: LienWaiverType
        readonly timeZone: String['output']
        readonly claimant: String['output']
        readonly date: DateTime['output']
        readonly defaultAmount: SafeInt['output'] | null
        readonly defaultAmountText: String['output'] | null
        readonly amount: SafeInt['output'] | null
        readonly customer: String['output']
        readonly lastSendTime: DateTime['output'] | null
        readonly isJointCheck: Boolean['output']
        readonly subject: String['output'] | null
        readonly message: String['output'] | null
        readonly defaultExceptionAmounts: String['output'] | null
        readonly defaultExceptionDates: String['output'] | null
        readonly hasSelectedDefaultVendorContacts: Boolean['output']
        readonly owner: String['output']
        readonly physicalSignatureRequired: PhysicalSignatureRequired
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
          readonly type: StoredFileType
          readonly name: String['output']
          readonly createdAt: DateTime['output']
          readonly size: SafeInt['output']
          readonly uploadedBy: {
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
            readonly email: EmailAddress['output']
          } | null
        } | null
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
            readonly integrationMappings: JSONObject['output']
            readonly internalNotes: String['output'] | null
            readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
            readonly address: {
              readonly __typename: 'Location'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly nickname: String['output'] | null
              readonly street1: String['output'] | null
              readonly street2: String['output'] | null
              readonly city: String['output']
              readonly county: String['output'] | null
              readonly state: String['output']
              readonly country: String['output']
              readonly postalCode: String['output'] | null
              readonly timeZone: String['output']
              readonly coordinates: LatLng['output']
            } | null
            readonly contacts: ReadonlyArray<{
              readonly __typename: 'VendorContact'
              readonly id: ID['output']
              readonly fullName: String['output']
              readonly email: EmailAddress['output']
              readonly phoneNumber: PhoneNumber['output'] | null
              readonly jobTitle: String['output'] | null
              readonly lowerTierTo: {
                readonly __typename: 'Vendor'
                readonly id: ID['output']
              } | null
            }>
            readonly companyIntegrations: ReadonlyArray<{
              readonly __typename: 'CompanyIntegration'
              readonly id: ID['output']
              readonly type: IntegrationType
              readonly label: String['output'] | null
            }>
          }
          readonly preliminaryNotices: ReadonlyArray<{
            readonly __typename: 'VendorPreliminaryNotice'
            readonly id: ID['output']
            readonly date: DateTime['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            }
          }>
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
        }>
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly name: String['output']
            readonly projectNumber: String['output']
          }
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        }
        readonly lienWaiverRequests: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly statusLogs: ReadonlyArray<{
            readonly __typename: 'LienWaiverRequestStatusLog'
            readonly id: ID['output']
            readonly status: DocumentRequestStatus
            readonly statusUpdatedAt: DateTime['output']
          }>
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly defaultSignature: {
              readonly __typename: 'Signature'
              readonly id: ID['output']
              readonly signatureName: String['output'] | null
              readonly signatureFont: SignatureFont | null
              readonly file: {
                readonly __typename: 'StoredFile'
                readonly id: ID['output']
                readonly url: URL['output']
              } | null
            } | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly jobLocation: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }
        readonly formValues: ReadonlyArray<{
          readonly __typename: 'FormTemplateAnnotationValue'
          readonly id: ID['output']
          readonly value: String['output']
          readonly metadata: JSONObject['output']
          readonly annotation: {
            readonly __typename: 'FormTemplateAnnotation'
            readonly id: ID['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateAnnotationType
            readonly xStart: Float['output']
            readonly yStart: Float['output']
            readonly width: Float['output']
            readonly height: Float['output']
            readonly pageNumber: Int['output']
            readonly fontFamily: FormTemplateFont
            readonly fontColor: String['output']
            readonly textAlignment: TextAlignment
            readonly syncTag: String['output'] | null
            readonly isOptional: Boolean['output']
            readonly selectedKey: String['output'] | null
            readonly defaultValueKey: String['output'] | null
            readonly prefix: String['output'] | null
            readonly suffix: String['output'] | null
            readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            readonly doNotRetainOnReset: Boolean['output']
            readonly dynamicFieldTag: String['output'] | null
            readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
            readonly imageType: FormTemplateAnnotationImageType | null
          }
        }>
        readonly requestAttachments: ReadonlyArray<{
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }>
        readonly defaultVendorContacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
      }>
    }>
  }>
}

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput
}>

export type UpdateProjectMutation = {
  readonly __typename: 'Mutation'
  readonly updateProject: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly location: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly owner: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly architect: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly metadata: {
      readonly __typename: 'ProjectMetadata'
      readonly payAppDueOnDayOfMonth: Int['output']
    }
  }
}

export type UpdateContractMutationVariables = Exact<{
  input: UpdateContractInput
}>

export type UpdateContractMutation = {
  readonly __typename: 'Mutation'
  readonly updateContract: {
    readonly __typename: 'Contract'
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly appliesTaxToRetention: Boolean['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly timeZone: String['output']
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type UpdateContractUsersMutationVariables = Exact<{
  input: UpdateContractUsersInput
}>

export type UpdateContractUsersMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractUsers: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly role: ContractUserRole
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly addedTeammates: Boolean['output']
    }
  }
}

export type UpdateVendorMutationVariables = Exact<{
  input: UpdateVendorInput
}>

export type UpdateVendorMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }
}

export type UpdateVendorContactMutationVariables = Exact<{
  input: UpdateVendorContactInput
}>

export type UpdateVendorContactMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorContact: {
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }
}

export type DeleteVendorContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteVendorContactMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendorContact: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type GetVendorsForLienWaiverTrackerQueryVariables = Exact<{
  input: GetPaginatedVendorsInput
}>

export type GetVendorsForLienWaiverTrackerQuery = {
  readonly __typename: 'Query'
  readonly paginatedVendors: {
    readonly __typename: 'PaginatedVendorsList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly vendors: ReadonlyArray<{
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly conditionalAmountWaived: SafeInt['output']
      readonly unconditionalAmountWaived: SafeInt['output']
      readonly name: String['output']
    }>
  }
}

export type ContractForLienWaiverVendorContractsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForLienWaiverVendorContractsQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly includeInSwornStatements: Boolean['output']
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
      }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
  }
}

export type AddVendorsToProjectMutationVariables = Exact<{
  input: AddVendorsToProjectInput
}>

export type AddVendorsToProjectMutation = {
  readonly __typename: 'Mutation'
  readonly addVendorsToProject: ReadonlyArray<{
    readonly __typename: 'VendorContract'
    readonly includeInSwornStatements: Boolean['output']
    readonly id: ID['output']
    readonly preliminaryNotices: ReadonlyArray<{
      readonly __typename: 'VendorPreliminaryNotice'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly integrationMappings: JSONObject['output']
      readonly internalNotes: String['output'] | null
      readonly address: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
      }>
    }
    readonly vendorLegalRequirements: ReadonlyArray<{
      readonly __typename: 'VendorLegalRequirement'
      readonly id: ID['output']
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
    }>
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }>
}

export type UpdateVendorContractMutationVariables = Exact<{
  input: UpdateVendorContractInput
}>

export type UpdateVendorContractMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorContract: {
    readonly __typename: 'VendorContract'
    readonly includeInSwornStatements: Boolean['output']
    readonly id: ID['output']
    readonly preliminaryNotices: ReadonlyArray<{
      readonly __typename: 'VendorPreliminaryNotice'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly integrationMappings: JSONObject['output']
      readonly internalNotes: String['output'] | null
      readonly address: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
      }>
    }
    readonly vendorLegalRequirements: ReadonlyArray<{
      readonly __typename: 'VendorLegalRequirement'
      readonly id: ID['output']
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
    }>
    readonly lowerTierTo: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
}

export type DeleteVendorContractMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteVendorContractMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendorContract: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type AddVendorLegalRequirementMutationVariables = Exact<{
  input: AddVendorLegalRequirementInput
}>

export type AddVendorLegalRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly addVendorLegalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly legalRequirementContacts: ReadonlyArray<{
        readonly __typename: 'CompanyContact'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly companyName: String['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
      }>
    }
    readonly vendorTemplate: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
      readonly url: URL['output']
    } | null
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
      readonly isCustomerReady: Boolean['output']
    } | null
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly lastSendTime: DateTime['output'] | null
      readonly isUploaded: Boolean['output']
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
      readonly legalDocumentRequests: ReadonlyArray<{
        readonly __typename: 'LegalDocumentRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type UpdateVendorLegalRequirementMutationVariables = Exact<{
  input: UpdateVendorLegalRequirementInput
}>

export type UpdateVendorLegalRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorLegalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly vendorTemplate: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
      readonly url: URL['output']
    } | null
  }
}

export type DeleteVendorLegalRequirementMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteVendorLegalRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendorLegalRequirement: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type VendorLegalRequirementsQueryVariables = Exact<{
  vendorIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type VendorLegalRequirementsQuery = {
  readonly __typename: 'Query'
  readonly vendorLegalRequirements: ReadonlyArray<{
    readonly __typename: 'VendorLegalRequirement'
    readonly id: ID['output']
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly legalRequirement: {
      readonly __typename: 'LegalRequirement'
      readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly legalRequirementContacts: ReadonlyArray<{
          readonly __typename: 'CompanyContact'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly companyName: String['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
        }>
      }
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly lastSendTime: DateTime['output'] | null
        readonly isUploaded: Boolean['output']
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        } | null
      }>
    }
  }>
}

export type AddLegalRequirementMutationVariables = Exact<{
  input: AddLegalRequirementInput
}>

export type AddLegalRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly addLegalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: StoredFileType
      } | null
    }>
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type UpdateLegalRequirementMutationVariables = Exact<{
  input: UpdateLegalRequirementInput
}>

export type UpdateLegalRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly updateLegalRequirement: {
    readonly __typename: 'LegalRequirement'
    readonly id: ID['output']
    readonly name: String['output']
    readonly updatedAt: DateTime['output']
    readonly expirationFrequency: DocumentExpirationFrequency
    readonly startDate: DateTime['output'] | null
    readonly endDate: DateTime['output'] | null
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly isVendorRequirement: Boolean['output']
    readonly type: DocumentType
    readonly timeZone: String['output']
    readonly documents: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: StoredFileType
      } | null
    }>
    readonly formTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly userVisibleName: String['output']
    } | null
  }
}

export type DeleteLegalRequirementMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteLegalRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly deleteLegalRequirement: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CopyContractLegalRequirementsMutationVariables = Exact<{
  input: CopyContractLegalRequirementsInput
}>

export type CopyContractLegalRequirementsMutation = {
  readonly __typename: 'Mutation'
  readonly copyContractLegalRequirements: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
  }
}

export type ProjectLegalRequirementsQueryVariables = Exact<{
  input: GetProjectLegalRequirementsInput
}>

export type ProjectLegalRequirementsQuery = {
  readonly __typename: 'Query'
  readonly projectLegalRequirements: ReadonlyArray<{
    readonly __typename: 'VendorLegalRequirement'
    readonly id: ID['output']
    readonly skippedPeriods: ReadonlyArray<String['output']>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly legalRequirement: {
      readonly __typename: 'LegalRequirement'
      readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly legalRequirementContacts: ReadonlyArray<{
          readonly __typename: 'CompanyContact'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly companyName: String['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
        }>
      }
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly lastSendTime: DateTime['output'] | null
        readonly isUploaded: Boolean['output']
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        } | null
      }>
    }
  }>
}

export type RequestLegalDocumentFromVendorMutationVariables = Exact<{
  input: RequestLegalDocumentsFromVendorInput
}>

export type RequestLegalDocumentFromVendorMutation = {
  readonly __typename: 'Mutation'
  readonly requestLegalDocumentFromVendor: {
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type DocumentTypesQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type DocumentTypesQuery = {
  readonly __typename: 'Query'
  readonly documentTypes: ReadonlyArray<DocumentType>
}

export type RejectAndRequestLegalDocumentFromVendorMutationVariables = Exact<{
  input: RejectAndRequestLegalDocumentFromVendorInput
}>

export type RejectAndRequestLegalDocumentFromVendorMutation = {
  readonly __typename: 'Mutation'
  readonly rejectAndRequestLegalDocumentFromVendor: {
    readonly __typename: 'LegalDocument'
    readonly lastSendTime: DateTime['output'] | null
    readonly isUploaded: Boolean['output']
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly periodStart: DateTime['output'] | null
    readonly periodEnd: DateTime['output'] | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly email: EmailAddress['output']
    } | null
    readonly legalDocumentRequests: ReadonlyArray<{
      readonly __typename: 'LegalDocumentRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type GetLegalDocumentsByProjectQueryVariables = Exact<{
  input: GetLegalDocumentsByPeriodInput
}>

export type GetLegalDocumentsByProjectQuery = {
  readonly __typename: 'Query'
  readonly getLegalDocumentsByProject: ReadonlyArray<{
    readonly __typename: 'VendorDocumentsByProject'
    readonly periodStart: DateTime['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly vendorDocuments: ReadonlyArray<{
      readonly __typename: 'VendorLegalDocument'
      readonly isSkippedPeriod: Boolean['output']
      readonly vendorRequirement: {
        readonly __typename: 'VendorLegalRequirement'
        readonly id: ID['output']
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
        readonly legalRequirement: {
          readonly __typename: 'LegalRequirement'
          readonly id: ID['output']
          readonly name: String['output']
          readonly updatedAt: DateTime['output']
          readonly expirationFrequency: DocumentExpirationFrequency
          readonly startDate: DateTime['output'] | null
          readonly endDate: DateTime['output'] | null
          readonly skippedPeriods: ReadonlyArray<String['output']>
          readonly isVendorRequirement: Boolean['output']
          readonly type: DocumentType
          readonly timeZone: String['output']
          readonly contract: {
            readonly __typename: 'Contract'
            readonly id: ID['output']
            readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
            readonly project: {
              readonly __typename: 'Project'
              readonly id: ID['output']
              readonly name: String['output']
            }
            readonly legalRequirementContacts: ReadonlyArray<{
              readonly __typename: 'CompanyContact'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly fullName: String['output']
              readonly email: EmailAddress['output']
              readonly companyName: String['output']
              readonly phoneNumber: PhoneNumber['output'] | null
              readonly jobTitle: String['output'] | null
            }>
          }
          readonly documents: ReadonlyArray<{
            readonly __typename: 'LegalDocument'
            readonly lastSendTime: DateTime['output'] | null
            readonly isUploaded: Boolean['output']
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly name: String['output']
            readonly periodStart: DateTime['output'] | null
            readonly periodEnd: DateTime['output'] | null
            readonly lastSyncIfSuccessful: {
              readonly __typename: 'WriteSyncOperation'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly status: WriteSyncOperationStatus
              readonly payload: JSONObject['output']
              readonly result: JSONObject['output'] | null
              readonly integration: {
                readonly __typename: 'Integration'
                readonly id: ID['output']
                readonly type: IntegrationType
                readonly mappings: JSONObject['output']
                readonly shortName: String['output']
              }
            } | null
            readonly uploadedBy: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            } | null
            readonly legalDocumentRequests: ReadonlyArray<{
              readonly __typename: 'LegalDocumentRequest'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly status: DocumentRequestStatus
              readonly actionTakenAt: DateTime['output'] | null
              readonly vendorContact: {
                readonly __typename: 'VendorContact'
                readonly id: ID['output']
                readonly fullName: String['output']
                readonly email: EmailAddress['output']
                readonly phoneNumber: PhoneNumber['output'] | null
                readonly jobTitle: String['output'] | null
                readonly lowerTierTo: {
                  readonly __typename: 'Vendor'
                  readonly id: ID['output']
                } | null
              }
            }>
            readonly vendorContract: {
              readonly __typename: 'VendorContract'
              readonly id: ID['output']
              readonly vendor: {
                readonly __typename: 'Vendor'
                readonly id: ID['output']
                readonly name: String['output']
              }
              readonly lowerTierTo: {
                readonly __typename: 'VendorContract'
                readonly id: ID['output']
                readonly vendor: {
                  readonly __typename: 'Vendor'
                  readonly id: ID['output']
                  readonly name: String['output']
                }
              } | null
            } | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            } | null
          }>
        }
      }
      readonly document: {
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
        readonly lastSendTime: DateTime['output'] | null
        readonly createdAt: DateTime['output']
        readonly isUploaded: Boolean['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
      } | null
    }>
  }>
}

export type GetLegalDocumentsByVendorQueryVariables = Exact<{
  input: GetLegalDocumentsByPeriodInput
}>

export type GetLegalDocumentsByVendorQuery = {
  readonly __typename: 'Query'
  readonly getLegalDocumentsByVendor: ReadonlyArray<{
    readonly __typename: 'ProjectDocumentsByVendor'
    readonly periodStart: DateTime['output'] | null
    readonly vendor: {
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly integrationMappings: JSONObject['output']
      readonly internalNotes: String['output'] | null
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly vendorContracts: ReadonlyArray<{
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
        }
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }>
      readonly address: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
      }>
    }
    readonly vendorDocuments: ReadonlyArray<{
      readonly __typename: 'VendorLegalDocument'
      readonly isSkippedPeriod: Boolean['output']
      readonly vendorRequirement: {
        readonly __typename: 'VendorLegalRequirement'
        readonly id: ID['output']
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
        readonly legalRequirement: {
          readonly __typename: 'LegalRequirement'
          readonly id: ID['output']
          readonly name: String['output']
          readonly updatedAt: DateTime['output']
          readonly expirationFrequency: DocumentExpirationFrequency
          readonly startDate: DateTime['output'] | null
          readonly endDate: DateTime['output'] | null
          readonly skippedPeriods: ReadonlyArray<String['output']>
          readonly isVendorRequirement: Boolean['output']
          readonly type: DocumentType
          readonly timeZone: String['output']
          readonly contract: {
            readonly __typename: 'Contract'
            readonly id: ID['output']
            readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
            readonly project: {
              readonly __typename: 'Project'
              readonly id: ID['output']
              readonly name: String['output']
            }
            readonly legalRequirementContacts: ReadonlyArray<{
              readonly __typename: 'CompanyContact'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly fullName: String['output']
              readonly email: EmailAddress['output']
              readonly companyName: String['output']
              readonly phoneNumber: PhoneNumber['output'] | null
              readonly jobTitle: String['output'] | null
            }>
          }
          readonly documents: ReadonlyArray<{
            readonly __typename: 'LegalDocument'
            readonly lastSendTime: DateTime['output'] | null
            readonly isUploaded: Boolean['output']
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly name: String['output']
            readonly periodStart: DateTime['output'] | null
            readonly periodEnd: DateTime['output'] | null
            readonly lastSyncIfSuccessful: {
              readonly __typename: 'WriteSyncOperation'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly status: WriteSyncOperationStatus
              readonly payload: JSONObject['output']
              readonly result: JSONObject['output'] | null
              readonly integration: {
                readonly __typename: 'Integration'
                readonly id: ID['output']
                readonly type: IntegrationType
                readonly mappings: JSONObject['output']
                readonly shortName: String['output']
              }
            } | null
            readonly uploadedBy: {
              readonly __typename: 'User'
              readonly id: ID['output']
              readonly firstName: String['output']
              readonly lastName: String['output']
              readonly email: EmailAddress['output']
            } | null
            readonly legalDocumentRequests: ReadonlyArray<{
              readonly __typename: 'LegalDocumentRequest'
              readonly id: ID['output']
              readonly createdAt: DateTime['output']
              readonly updatedAt: DateTime['output']
              readonly status: DocumentRequestStatus
              readonly actionTakenAt: DateTime['output'] | null
              readonly vendorContact: {
                readonly __typename: 'VendorContact'
                readonly id: ID['output']
                readonly fullName: String['output']
                readonly email: EmailAddress['output']
                readonly phoneNumber: PhoneNumber['output'] | null
                readonly jobTitle: String['output'] | null
                readonly lowerTierTo: {
                  readonly __typename: 'Vendor'
                  readonly id: ID['output']
                } | null
              }
            }>
            readonly vendorContract: {
              readonly __typename: 'VendorContract'
              readonly id: ID['output']
              readonly vendor: {
                readonly __typename: 'Vendor'
                readonly id: ID['output']
                readonly name: String['output']
              }
              readonly lowerTierTo: {
                readonly __typename: 'VendorContract'
                readonly id: ID['output']
                readonly vendor: {
                  readonly __typename: 'Vendor'
                  readonly id: ID['output']
                  readonly name: String['output']
                }
              } | null
            } | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            } | null
          }>
        }
      }
      readonly document: {
        readonly __typename: 'LegalDocument'
        readonly id: ID['output']
        readonly lastSendTime: DateTime['output'] | null
        readonly createdAt: DateTime['output']
        readonly isUploaded: Boolean['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
      } | null
    }>
  }>
}

export type ProjectCompaniesForSubcontractorQueryVariables = Exact<{
  subcontractorCompanyId: Scalars['ID']['input']
}>

export type ProjectCompaniesForSubcontractorQuery = {
  readonly __typename: 'Query'
  readonly projectCompaniesForSubcontractor: {
    readonly __typename: 'ProjectCompanies'
    readonly generalContractors: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: CompanyType
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly owners: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: CompanyType
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly architects: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: CompanyType
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
  }
}

export type CreateMonthlyBillingProjectMutationVariables = Exact<{
  input: CreateMonthlyBillingProjectInput
}>

export type CreateMonthlyBillingProjectMutation = {
  readonly __typename: 'Mutation'
  readonly createMonthlyBillingProject: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly timeZone: String['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
    readonly metadata: {
      readonly __typename: 'ProjectMetadata'
      readonly payAppDueOnDayOfMonth: Int['output']
    }
  }
}

export type IntegrationProjectsForOnboardingQueryVariables = Exact<{
  companyIntegrationId: Scalars['ID']['input']
  includeInactive: Scalars['Boolean']['input']
}>

export type IntegrationProjectsForOnboardingQuery = {
  readonly __typename: 'Query'
  readonly integrationProjectsForOnboarding: ReadonlyArray<{
    readonly __typename: 'IntegrationProjectSummary'
    readonly integrationProjectId: String['output']
    readonly integrationContractId: String['output'] | null
    readonly integrationAssociatedCompanyId: String['output'] | null
    readonly projectName: String['output']
    readonly projectNumber: String['output'] | null
    readonly generalContractorName: String['output'] | null
    readonly contractName: String['output'] | null
    readonly sitelineProjectId: String['output'] | null
    readonly sitelineContractId: String['output'] | null
  }>
}

export type ReadIntegrationProjectQueryVariables = Exact<{
  input: ReadIntegrationProjectInput
}>

export type ReadIntegrationProjectQuery = {
  readonly __typename: 'Query'
  readonly readIntegrationProject: {
    readonly __typename: 'ImportProjectOnboardingMetadata'
    readonly type: IntegrationType
    readonly companyIntegrationId: ID['output']
    readonly integrationAssociatedCompanyId: String['output'] | null
    readonly taxCalculationType: TaxCalculationType
    readonly project: {
      readonly __typename: 'IntegrationProjectDetails'
      readonly integrationProjectId: String['output']
      readonly projectName: String['output']
      readonly projectNumber: String['output'] | null
      readonly internalProjectNumber: String['output'] | null
      readonly sitelineLeadPMIds: ReadonlyArray<String['output']> | null
      readonly projectAddress: {
        readonly __typename: 'DraftLocation'
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly coordinates: LatLng['output']
        readonly timeZone: String['output']
      } | null
    }
    readonly contract: {
      readonly __typename: 'IntegrationContract'
      readonly integrationContractId: String['output'] | null
      readonly contractNumber: String['output'] | null
      readonly contractDate: DateTime['output'] | null
      readonly latestPayAppNumber: SafeInt['output'] | null
      readonly payAppDueDate: SafeInt['output'] | null
      readonly retentionPercent: Float['output'] | null
      readonly retentionTrackingLevel: RetentionTrackingLevel | null
      readonly roundRetention: Boolean['output'] | null
      readonly billingType: BillingType | null
      readonly paymentTermsType: ContractPaymentTermsType | null
      readonly paymentTerms: SafeInt['output'] | null
      readonly preSitelineBilled: SafeInt['output'] | null
    }
    readonly generalContractor: {
      readonly __typename: 'IntegrationCompany'
      readonly companyName: String['output'] | null
      readonly companyAddress: {
        readonly __typename: 'DraftLocation'
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly coordinates: LatLng['output']
        readonly timeZone: String['output']
      } | null
    } | null
    readonly owner: {
      readonly __typename: 'IntegrationCompany'
      readonly companyName: String['output'] | null
      readonly companyAddress: {
        readonly __typename: 'DraftLocation'
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly coordinates: LatLng['output']
        readonly timeZone: String['output']
      } | null
    } | null
    readonly architect: {
      readonly __typename: 'IntegrationCompany'
      readonly companyName: String['output'] | null
      readonly companyAddress: {
        readonly __typename: 'DraftLocation'
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly coordinates: LatLng['output']
        readonly timeZone: String['output']
      } | null
    } | null
    readonly taxGroup: {
      readonly __typename: 'IntegrationTaxGroup'
      readonly integrationTaxGroupId: String['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
    } | null
  }
}

export type ImportSovFromIntegrationMutationVariables = Exact<{
  input: ImportSovInput
}>

export type ImportSovFromIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly importSovFromIntegration: {
    readonly __typename: 'Sov'
    readonly id: ID['output']
    readonly totalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly preSitelineRetention: SafeInt['output']
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }>
  }
}

export type RefreshIntegrationVendorsMutationVariables = Exact<{
  integrationId: Scalars['ID']['input']
}>

export type RefreshIntegrationVendorsMutation = {
  readonly __typename: 'Mutation'
  readonly refreshIntegrationVendors: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }
}

export type IntegrationVendorsQueryVariables = Exact<{
  companyIntegrationId: Scalars['ID']['input']
}>

export type IntegrationVendorsQuery = {
  readonly __typename: 'Query'
  readonly integrationVendors: ReadonlyArray<{
    readonly __typename: 'IntegrationVendor'
    readonly integrationVendorId: String['output']
    readonly name: String['output']
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'IntegrationVendorContact'
      readonly integrationVendorContactId: String['output']
      readonly name: String['output'] | null
      readonly title: String['output'] | null
    }>
  }>
}

export type ChangeOrderRequestsQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ChangeOrderRequestsQuery = {
  readonly __typename: 'Query'
  readonly changeOrderRequests: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }>
}

export type ChangeOrderRequestQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ChangeOrderRequestQuery = {
  readonly __typename: 'Query'
  readonly changeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly status: ContractStatus
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly rateTable: {
        readonly __typename: 'RateTable'
        readonly id: ID['output']
        readonly name: String['output']
        readonly status: RateTableStatus
        readonly effectiveAt: DateTime['output'] | null
        readonly expiresAt: DateTime['output'] | null
        readonly lastEditedAt: DateTime['output']
        readonly lastEditedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'Contract'
          readonly id: ID['output']
        }>
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'Contract'
          readonly id: ID['output']
        }>
      } | null
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
      }>
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly selectedChangeOrderLogForms: Boolean['output']
        readonly onboardedChangeOrderLogForms: Boolean['output']
        readonly notifiedOnboardedForms: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly addedSov: Boolean['output']
        readonly addedTeammates: Boolean['output']
        readonly addedGcContacts: Boolean['output']
        readonly startedBilling: Boolean['output']
        readonly isComplete: Boolean['output']
        readonly isReadyForPayAppSubmit: Boolean['output']
      }
    }
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  }
}

export type CreateChangeOrderRequestMutationVariables = Exact<{
  input: CreateChangeOrderRequestInput
}>

export type CreateChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly createChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type CreateChangeOrderRequestsFromLogMutationVariables = Exact<{
  input: CreateChangeOrderRequestsInput
}>

export type CreateChangeOrderRequestsFromLogMutation = {
  readonly __typename: 'Mutation'
  readonly createChangeOrderRequestsFromLog: ReadonlyArray<{
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }>
}

export type UpdateChangeOrderRequestMutationVariables = Exact<{
  input: UpdateChangeOrderRequestInput
}>

export type UpdateChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  }
}

export type DeleteChangeOrderRequestMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly deleteChangeOrderRequest: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CreateChangeOrderRequestAttachmentMutationVariables = Exact<{
  input: CreateChangeOrderRequestAttachmentInput
}>

export type CreateChangeOrderRequestAttachmentMutation = {
  readonly __typename: 'Mutation'
  readonly createChangeOrderRequestAttachment: {
    readonly __typename: 'ChangeOrderRequestAttachment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly uploadId: ID['output']
    readonly description: String['output'] | null
    readonly isInternalOnly: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
  }
}

export type UpdateChangeOrderRequestAttachmentMutationVariables = Exact<{
  input: UpdateChangeOrderRequestAttachmentInput
}>

export type UpdateChangeOrderRequestAttachmentMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrderRequestAttachment: {
    readonly __typename: 'ChangeOrderRequestAttachment'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly uploadId: ID['output']
    readonly description: String['output'] | null
    readonly isInternalOnly: Boolean['output']
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }
  }
}

export type DeleteChangeOrderRequestAttachmentMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteChangeOrderRequestAttachmentMutation = {
  readonly __typename: 'Mutation'
  readonly deleteChangeOrderRequestAttachment: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type ApproveChangeOrderRequestMutationVariables = Exact<{
  input: ApproveChangeOrderRequestInput
}>

export type ApproveChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly approveChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type MarkChangeOrderRequestSubmittedMutationVariables = Exact<{
  input: MarkChangeOrderRequestSubmittedInput
}>

export type MarkChangeOrderRequestSubmittedMutation = {
  readonly __typename: 'Mutation'
  readonly markChangeOrderRequestSubmitted: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type MarkChangeOrderRequestAuthorizedToProceedMutationVariables = Exact<{
  input: MarkChangeOrderRequestAuthorizedToProceedInput
}>

export type MarkChangeOrderRequestAuthorizedToProceedMutation = {
  readonly __typename: 'Mutation'
  readonly markChangeOrderRequestAuthorizedToProceed: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type LinkChangeOrderRequestToSovMutationVariables = Exact<{
  input: LinkChangeOrderRequestToSovInput
}>

export type LinkChangeOrderRequestToSovMutation = {
  readonly __typename: 'Mutation'
  readonly linkChangeOrderRequestToSov: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type RejectChangeOrderRequestMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RejectChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly rejectChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type ResetChangeOrderRequestToDraftMutationVariables = Exact<{
  input: ResetChangeOrderRequestToDraftInput
}>

export type ResetChangeOrderRequestToDraftMutation = {
  readonly __typename: 'Mutation'
  readonly resetChangeOrderRequestToDraft: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type AddChangeOrderRequestToSovMutationVariables = Exact<{
  input: AddChangeOrderRequestToSovInput
}>

export type AddChangeOrderRequestToSovMutation = {
  readonly __typename: 'Mutation'
  readonly addChangeOrderRequestToSov: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type ClearChangeOrderRequestPricingMutationVariables = Exact<{
  input: ClearChangeOrderRequestPricingInput
}>

export type ClearChangeOrderRequestPricingMutation = {
  readonly __typename: 'Mutation'
  readonly clearChangeOrderRequestPricing: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  }
}

export type SignChangeOrderRequestMutationVariables = Exact<{
  input: SignChangeOrderRequestInput
}>

export type SignChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly signChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type SubmitChangeOrderRequestMutationVariables = Exact<{
  input: SubmitChangeOrderRequestInput
}>

export type SubmitChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly submitChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type AddGeneralContractorContactToChangeOrderRequestMutationVariables = Exact<{
  input: GeneralContractorChangeOrderRequestContactInput
}>

export type AddGeneralContractorContactToChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly addGeneralContractorContactToChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type DeleteGeneralContractorContactFromChangeOrderRequestMutationVariables = Exact<{
  input: GeneralContractorChangeOrderRequestContactInput
}>

export type DeleteGeneralContractorContactFromChangeOrderRequestMutation = {
  readonly __typename: 'Mutation'
  readonly deleteGeneralContractorContactFromChangeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
  }
}

export type UploadChangeOrderScoFileMutationVariables = Exact<{
  input: UploadChangeOrderScoFileInput
}>

export type UploadChangeOrderScoFileMutation = {
  readonly __typename: 'Mutation'
  readonly uploadChangeOrderScoFile: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  }
}

export type RequestChangeOrderReviewMutationVariables = Exact<{
  input: RequestChangeOrderReviewInput
}>

export type RequestChangeOrderReviewMutation = {
  readonly __typename: 'Mutation'
  readonly requestChangeOrderReview: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  } | null
}

export type UserEducationCampaignQueryVariables = Exact<{ [key: string]: never }>

export type UserEducationCampaignQuery = {
  readonly __typename: 'Query'
  readonly userEducationCampaign: {
    readonly __typename: 'UserEducationCampaign'
    readonly id: ID['output']
    readonly name: String['output']
    readonly showNewTag: Boolean['output']
    readonly title: String['output']
    readonly message: String['output']
    readonly buttonLabel: String['output'] | null
    readonly buttonLink: String['output'] | null
  } | null
}

export type DismissUserEducationCampaignMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DismissUserEducationCampaignMutation = {
  readonly __typename: 'Mutation'
  readonly dismissUserEducationCampaign: {
    readonly __typename: 'UserEducationCampaign'
    readonly id: ID['output']
    readonly name: String['output']
    readonly showNewTag: Boolean['output']
    readonly title: String['output']
    readonly message: String['output']
    readonly buttonLabel: String['output'] | null
    readonly buttonLink: String['output'] | null
  }
}

export type UpdateVendorContractPreliminaryNoticesMutationVariables = Exact<{
  input: UpdateVendorContractPreliminaryNoticesInput
}>

export type UpdateVendorContractPreliminaryNoticesMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorContractPreliminaryNotices: {
    readonly __typename: 'VendorContract'
    readonly id: ID['output']
    readonly preliminaryNotices: ReadonlyArray<{
      readonly __typename: 'VendorPreliminaryNotice'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
  }
}

export type UpdateRoundRetentionMutationVariables = Exact<{
  input: UpdateRoundRetentionInput
}>

export type UpdateRoundRetentionMutation = {
  readonly __typename: 'Mutation'
  readonly updateRoundRetention: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly timeZone: String['output']
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type MarkVendorLienWaiverSubmittedMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MarkVendorLienWaiverSubmittedMutation = {
  readonly __typename: 'Mutation'
  readonly markVendorLienWaiverSubmitted: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type GetContractForEditingSovQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForEditingSovQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
    readonly preSitelineRetention: SafeInt['output']
    readonly roundRetention: Boolean['output']
    readonly timeZone: String['output']
    readonly taxCalculationType: TaxCalculationType
    readonly defaultTaxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly taxGroups: ReadonlyArray<{
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      }>
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly billedToDate: SafeInt['output']
        readonly totalRetention: SafeInt['output']
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }>
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly billingEnd: DateTime['output']
      readonly retentionOnly: Boolean['output']
      readonly progress: ReadonlyArray<{
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly progressRetentionHeldPercent: Float['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
}

export type GetContractForVendorsProjectHomeQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForVendorsProjectHomeQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly missingRequiredVendorLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
    readonly vendorComplianceStatus: VendorComplianceStatus | null
    readonly vendorsInternalNotes: String['output'] | null
    readonly timeZone: String['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly vendorTemplate: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly name: String['output']
        readonly url: URL['output']
      } | null
    }>
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly legalRequirementContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly vendorSubmitToContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly onboardedProjectVendorsStatus: {
      readonly __typename: 'OnboardedProjectVendorsStatus'
      readonly addedVendorLienWaivers: Boolean['output']
      readonly addedLegalRequirements: Boolean['output']
      readonly addedVendors: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly timeZone: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly architect: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly metadata: {
        readonly __typename: 'ProjectMetadata'
        readonly payAppDueOnDayOfMonth: Int['output']
      }
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output']
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output'] | null
      readonly payAppNumber: Int['output']
    }>
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly includeInSwornStatements: Boolean['output']
      readonly id: ID['output']
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly vendorLegalRequirements: ReadonlyArray<{
        readonly __typename: 'VendorLegalRequirement'
        readonly id: ID['output']
        readonly legalRequirement: {
          readonly __typename: 'LegalRequirement'
          readonly id: ID['output']
          readonly name: String['output']
          readonly updatedAt: DateTime['output']
          readonly expirationFrequency: DocumentExpirationFrequency
          readonly startDate: DateTime['output'] | null
          readonly endDate: DateTime['output'] | null
          readonly skippedPeriods: ReadonlyArray<String['output']>
          readonly isVendorRequirement: Boolean['output']
          readonly type: DocumentType
          readonly timeZone: String['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
  }
}

export type SovChangeSetFromIntegrationQueryVariables = Exact<{
  input: SovChangeSetFromIntegrationInput
}>

export type SovChangeSetFromIntegrationQuery = {
  readonly __typename: 'Query'
  readonly sovChangeSetFromIntegration: {
    readonly __typename: 'SovChangeSet'
    readonly additions: ReadonlyArray<{
      readonly __typename: 'LineItemAddition'
      readonly new: {
        readonly __typename: 'SovChangeSetCreateLineItem'
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly isChangeOrder: Boolean['output']
        readonly sortOrder: SafeInt['output'] | null
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly sovLineItemGroupId: String['output'] | null
        readonly previousStoredMaterials: SafeInt['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly defaultRetentionPercent: Float['output'] | null
      }
    }>
    readonly updates: ReadonlyArray<{
      readonly __typename: 'LineItemUpdate'
      readonly oldId: ID['output']
      readonly new: {
        readonly __typename: 'SovChangeSetUpdateLineItem'
        readonly code: String['output'] | null
        readonly name: String['output'] | null
        readonly costCode: String['output'] | null
        readonly originalTotalValue: SafeInt['output'] | null
        readonly latestTotalValue: SafeInt['output'] | null
        readonly previousBilled: SafeInt['output'] | null
        readonly isChangeOrder: Boolean['output'] | null
        readonly sortOrder: SafeInt['output'] | null
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly sovLineItemGroupId: String['output'] | null
        readonly previousStoredMaterials: SafeInt['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly defaultRetentionPercent: Float['output'] | null
      }
    }>
    readonly deletions: ReadonlyArray<{
      readonly __typename: 'LineItemDeletion'
      readonly oldId: ID['output']
    }>
    readonly groupAdditions: ReadonlyArray<{
      readonly __typename: 'LineItemGroupAddition'
      readonly newId: ID['output']
      readonly new: {
        readonly __typename: 'SovChangeSetCreateLineItemGroup'
        readonly name: String['output']
        readonly code: String['output'] | null
      }
    }>
    readonly groupUpdates: ReadonlyArray<{
      readonly __typename: 'LineItemGroupUpdate'
      readonly oldId: ID['output']
      readonly new: {
        readonly __typename: 'SovChangeSetUpdateLineItemGroup'
        readonly name: String['output'] | null
        readonly code: String['output'] | null
      }
    }>
    readonly groupDeletions: ReadonlyArray<{
      readonly __typename: 'LineItemGroupDeletion'
      readonly oldId: ID['output']
    }>
  } | null
}

export type UpdateSovWithChangeSetMutationVariables = Exact<{
  input: UpdateSovWithChangeSetInput
}>

export type UpdateSovWithChangeSetMutation = {
  readonly __typename: 'Mutation'
  readonly updateSovWithChangeSet: {
    readonly __typename: 'Sov'
    readonly id: ID['output']
    readonly totalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly preSitelineRetention: SafeInt['output']
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }>
  }
}

export type UpdateSovLineItemTotalValueMutationVariables = Exact<{
  input: UpdateSovLineItemTotalValueInput
}>

export type UpdateSovLineItemTotalValueMutation = {
  readonly __typename: 'Mutation'
  readonly updateSovLineItemTotalValue: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly storedMaterialBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly futureBilled: SafeInt['output']
    readonly previousRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePreTax: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastProgressWithRetentionHeldOverride: {
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly payApp: {
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppNumber: SafeInt['output']
      }
    } | null
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly preSitelineRetention: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItemGroup: {
        readonly __typename: 'SovLineItemGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output'] | null
      } | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }
    readonly worksheetLineItemProgress: ReadonlyArray<{
      readonly __typename: 'WorksheetLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly worksheetLineItem: {
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }
    }>
  }
}

export type UpdateContractLienWaiverThroughDateMutationVariables = Exact<{
  input: UpdateContractLienWaiverThroughDateInput
}>

export type UpdateContractLienWaiverThroughDateMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractLienWaiverThroughDate: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverThroughDate: SafeInt['output'] | null
  }
}

export type AgingDashboardQueryVariables = Exact<{
  input: DashboardInput
}>

export type AgingDashboardQuery = {
  readonly __typename: 'Query'
  readonly agingDashboard: {
    readonly __typename: 'PayAppAgingDashboard'
    readonly payAppAgingSummary: {
      readonly __typename: 'PayAppAgingDashboardSummary'
      readonly amountOutstandingThisMonth: SafeInt['output']
      readonly amountOutstandingMonthOverMonthPercent: Float['output'] | null
      readonly amountAged30Days: SafeInt['output']
      readonly amountAged30DaysMonthOverMonthPercent: Float['output'] | null
      readonly amountAged60Days: SafeInt['output']
      readonly amountAged60DaysMonthOverMonthPercent: Float['output'] | null
      readonly amountAged90Days: SafeInt['output']
      readonly amountAged90DaysMonthOverMonthPercent: Float['output'] | null
      readonly amountAged120Days: SafeInt['output']
      readonly amountAged120DaysMonthOverMonthPercent: Float['output'] | null
      readonly averageDaysToPaid: Float['output']
      readonly averageDaysToPaidMonthOverMonthPercent: Float['output'] | null
      readonly payAppAgingBreakdown: {
        readonly __typename: 'AgingBreakdown'
        readonly numCurrent: SafeInt['output']
        readonly numAged30Days: SafeInt['output']
        readonly numAged60Days: SafeInt['output']
        readonly numAged90Days: SafeInt['output']
        readonly numAged120Days: SafeInt['output']
        readonly amountAgedTotal: SafeInt['output']
        readonly amountAgedCurrent: SafeInt['output']
        readonly amountAged30Days: SafeInt['output']
        readonly amountAged60Days: SafeInt['output']
        readonly amountAged90Days: SafeInt['output']
        readonly amountAged120Days: SafeInt['output']
      }
    }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'PayAppAgingDashboardContract'
      readonly billingStatus: DashboardBillingStatus
      readonly numComments: SafeInt['output']
      readonly hasMissingPreSitelinePayApps: Boolean['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly billingType: BillingType
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly companyName: String['output'] | null
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly leadPMs: ReadonlyArray<{
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }>
      }
      readonly agingBreakdown: {
        readonly __typename: 'AgingBreakdown'
        readonly numCurrent: SafeInt['output']
        readonly numAged30Days: SafeInt['output']
        readonly numAged60Days: SafeInt['output']
        readonly numAged90Days: SafeInt['output']
        readonly numAged120Days: SafeInt['output']
        readonly amountAgedTotal: SafeInt['output']
        readonly amountAgedCurrent: SafeInt['output']
        readonly amountAged30Days: SafeInt['output']
        readonly amountAged60Days: SafeInt['output']
        readonly amountAged90Days: SafeInt['output']
        readonly amountAged120Days: SafeInt['output']
      }
    }>
  }
}

export type BillingDashboardQueryVariables = Exact<{
  input: DashboardInput
}>

export type BillingDashboardQuery = {
  readonly __typename: 'Query'
  readonly billingDashboard: {
    readonly __typename: 'BillingDashboard'
    readonly billingSummary: {
      readonly __typename: 'BillingDashboardSummary'
      readonly projectsBilledThisMonth: SafeInt['output']
      readonly totalProjectsThisMonth: SafeInt['output']
      readonly projectsBilledMonthOverMonthPercent: Float['output'] | null
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly amountBilledAndDraftMonthOverMonthPercent: Float['output'] | null
      readonly amountBilledThisMonth: SafeInt['output']
      readonly amountBilledMonthOverMonthPercent: Float['output'] | null
      readonly amountInDraftThisMonth: SafeInt['output']
      readonly amountInDraftMonthOverMonthPercent: Float['output'] | null
      readonly retentionHeldThisMonth: SafeInt['output']
      readonly retentionHeldThruMonth: SafeInt['output']
      readonly retentionHeldMonthOverMonthPercent: Float['output'] | null
      readonly payAppStatusBreakdown: {
        readonly __typename: 'PayAppStatusBreakdown'
        readonly pastDue: SafeInt['output']
        readonly notBillingThisMonth: SafeInt['output']
        readonly draftOnTime: SafeInt['output']
        readonly submitted: SafeInt['output']
      }
      readonly monthlyBillingBreakdown: ReadonlyArray<{
        readonly __typename: 'MonthlyBillingBreakdown'
        readonly month: DateTime['output']
        readonly amountBilledAndDraftThisMonth: SafeInt['output']
        readonly amountBilledThisMonth: SafeInt['output']
      }>
    }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'BillingDashboardContract'
      readonly billingStatus: DashboardBillingStatus
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly billingType: BillingType
        readonly internalProjectNumber: String['output'] | null
        readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly companyName: String['output'] | null
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
        readonly leadPMs: ReadonlyArray<{
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }>
      }
      readonly projectMetrics: {
        readonly __typename: 'ProjectMetrics'
        readonly amountBilledAndDraftThisMonth: SafeInt['output']
        readonly retentionHeldThisMonth: SafeInt['output'] | null
        readonly retentionHeldThruMonth: SafeInt['output'] | null
        readonly percentCompletedThruMonth: Float['output'] | null
        readonly balanceToFinish: SafeInt['output'] | null
        readonly completedThruMonth: SafeInt['output'] | null
        readonly totalValue: SafeInt['output'] | null
      }
    }>
  }
}

export type BillingLineItemReportQueryVariables = Exact<{
  input: DashboardInput
}>

export type BillingLineItemReportQuery = {
  readonly __typename: 'Query'
  readonly billingLineItemReport: ReadonlyArray<{
    readonly __typename: 'BillingDashboardContractWithLineItems'
    readonly billingStatus: DashboardBillingStatus
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly internalProjectNumber: String['output'] | null
      readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
    }
    readonly projectMetrics: {
      readonly __typename: 'ProjectMetrics'
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly retentionHeldThisMonth: SafeInt['output'] | null
      readonly retentionHeldThruMonth: SafeInt['output'] | null
      readonly percentCompletedThruMonth: Float['output'] | null
      readonly balanceToFinish: SafeInt['output'] | null
      readonly completedThruMonth: SafeInt['output'] | null
      readonly totalValue: SafeInt['output'] | null
    }
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'LineItemMetrics'
      readonly number: String['output']
      readonly name: String['output']
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly percentCompletedThruMonth: Float['output'] | null
      readonly balanceToFinish: SafeInt['output'] | null
      readonly completedThruMonth: SafeInt['output'] | null
      readonly totalValue: SafeInt['output'] | null
    }>
  }>
}

export type OverviewDashboardQueryVariables = Exact<{
  input: DashboardInput
}>

export type OverviewDashboardQuery = {
  readonly __typename: 'Query'
  readonly overviewDashboard: {
    readonly __typename: 'OverviewDashboard'
    readonly billingSummary: {
      readonly __typename: 'BillingDashboardSummary'
      readonly projectsBilledThisMonth: SafeInt['output']
      readonly totalProjectsThisMonth: SafeInt['output']
      readonly projectsBilledMonthOverMonthPercent: Float['output'] | null
      readonly amountBilledAndDraftThisMonth: SafeInt['output']
      readonly amountBilledAndDraftMonthOverMonthPercent: Float['output'] | null
      readonly amountBilledThisMonth: SafeInt['output']
      readonly amountBilledMonthOverMonthPercent: Float['output'] | null
      readonly amountInDraftThisMonth: SafeInt['output']
      readonly amountInDraftMonthOverMonthPercent: Float['output'] | null
      readonly retentionHeldThisMonth: SafeInt['output']
      readonly retentionHeldThruMonth: SafeInt['output']
      readonly retentionHeldMonthOverMonthPercent: Float['output'] | null
      readonly payAppStatusBreakdown: {
        readonly __typename: 'PayAppStatusBreakdown'
        readonly pastDue: SafeInt['output']
        readonly notBillingThisMonth: SafeInt['output']
        readonly draftOnTime: SafeInt['output']
        readonly submitted: SafeInt['output']
      }
      readonly monthlyBillingBreakdown: ReadonlyArray<{
        readonly __typename: 'MonthlyBillingBreakdown'
        readonly month: DateTime['output']
        readonly amountBilledAndDraftThisMonth: SafeInt['output']
        readonly amountBilledThisMonth: SafeInt['output']
      }>
    }
    readonly payAppAgingSummary: {
      readonly __typename: 'PayAppAgingDashboardSummary'
      readonly amountOutstandingThisMonth: SafeInt['output']
      readonly amountOutstandingMonthOverMonthPercent: Float['output'] | null
      readonly amountAged30Days: SafeInt['output']
      readonly amountAged30DaysMonthOverMonthPercent: Float['output'] | null
      readonly amountAged60Days: SafeInt['output']
      readonly amountAged60DaysMonthOverMonthPercent: Float['output'] | null
      readonly amountAged90Days: SafeInt['output']
      readonly amountAged90DaysMonthOverMonthPercent: Float['output'] | null
      readonly amountAged120Days: SafeInt['output']
      readonly amountAged120DaysMonthOverMonthPercent: Float['output'] | null
      readonly averageDaysToPaid: Float['output']
      readonly averageDaysToPaidMonthOverMonthPercent: Float['output'] | null
      readonly payAppAgingBreakdown: {
        readonly __typename: 'AgingBreakdown'
        readonly numCurrent: SafeInt['output']
        readonly numAged30Days: SafeInt['output']
        readonly numAged60Days: SafeInt['output']
        readonly numAged90Days: SafeInt['output']
        readonly numAged120Days: SafeInt['output']
        readonly amountAgedTotal: SafeInt['output']
        readonly amountAgedCurrent: SafeInt['output']
        readonly amountAged30Days: SafeInt['output']
        readonly amountAged60Days: SafeInt['output']
        readonly amountAged90Days: SafeInt['output']
        readonly amountAged120Days: SafeInt['output']
      }
    }
    readonly vendorSummary: {
      readonly __typename: 'VendorLienWaiverDashboardSummary'
      readonly numOutstandingLienWaiversThisMonth: SafeInt['output'] | null
      readonly outstandingVendors: SafeInt['output']
      readonly outstandingLienWaiversByMonth: ReadonlyArray<{
        readonly __typename: 'OutstandingLienWaiversByMonth'
        readonly month: DateTime['output']
        readonly numOutstandingLienWaiversThisMonth: SafeInt['output']
      }>
    }
  }
}

export type SelectProjectFormsMutationVariables = Exact<{
  input: SelectProjectFormsInput
}>

export type SelectProjectFormsMutation = {
  readonly __typename: 'Mutation'
  readonly selectProjectForms: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }>
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type UpdateOnboardedProjectVendorsStatusMutationVariables = Exact<{
  input: OnboardedProjectVendorsStatusInput
}>

export type UpdateOnboardedProjectVendorsStatusMutation = {
  readonly __typename: 'Mutation'
  readonly updateOnboardedProjectVendorsStatus: {
    readonly __typename: 'Contract'
    readonly missingRequiredVendorLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
    readonly vendorComplianceStatus: VendorComplianceStatus | null
    readonly vendorsInternalNotes: String['output'] | null
    readonly timeZone: String['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly vendorTemplate: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly name: String['output']
        readonly url: URL['output']
      } | null
    }>
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly legalRequirementContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly vendorSubmitToContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly onboardedProjectVendorsStatus: {
      readonly __typename: 'OnboardedProjectVendorsStatus'
      readonly addedVendorLienWaivers: Boolean['output']
      readonly addedLegalRequirements: Boolean['output']
      readonly addedVendors: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly timeZone: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly architect: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly metadata: {
        readonly __typename: 'ProjectMetadata'
        readonly payAppDueOnDayOfMonth: Int['output']
      }
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output']
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output'] | null
      readonly payAppNumber: Int['output']
    }>
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly includeInSwornStatements: Boolean['output']
      readonly id: ID['output']
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly vendorLegalRequirements: ReadonlyArray<{
        readonly __typename: 'VendorLegalRequirement'
        readonly id: ID['output']
        readonly legalRequirement: {
          readonly __typename: 'LegalRequirement'
          readonly id: ID['output']
          readonly name: String['output']
          readonly updatedAt: DateTime['output']
          readonly expirationFrequency: DocumentExpirationFrequency
          readonly startDate: DateTime['output'] | null
          readonly endDate: DateTime['output'] | null
          readonly skippedPeriods: ReadonlyArray<String['output']>
          readonly isVendorRequirement: Boolean['output']
          readonly type: DocumentType
          readonly timeZone: String['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
  }
}

export type UpdateOnboardedProjectComplianceStatusMutationVariables = Exact<{
  input: OnboardedProjectComplianceStatusInput
}>

export type UpdateOnboardedProjectComplianceStatusMutation = {
  readonly __typename: 'Mutation'
  readonly updateOnboardedProjectComplianceStatus: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly status: ContractStatus
    readonly billingType: BillingType
    readonly complianceStatus: ComplianceStatus | null
    readonly vendorNumber: String['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly architect: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly lastSendTime: DateTime['output'] | null
        readonly isUploaded: Boolean['output']
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        } | null
      }>
    }>
    readonly legalRequirementContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
    readonly onboardedProjectComplianceStatus: {
      readonly __typename: 'OnboardedProjectComplianceStatus'
      readonly addedLegalRequirements: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
  }
}

export type OnboardingContractsQueryVariables = Exact<{
  input: GetPaginatedContractsInput
  months: ReadonlyArray<Scalars['DateTime']['input']> | Scalars['DateTime']['input']
}>

export type OnboardingContractsQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly timeZone: String['output']
      readonly billingType: BillingType
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }>
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly metadata: JSONObject['output']
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly isActive: Boolean['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly metadata: JSONObject['output']
        }
      }>
      readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly metadata: {
          readonly __typename: 'ProjectMetadata'
          readonly payAppDueOnDayOfMonth: Int['output']
        }
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
      readonly associatedFormTemplates: ReadonlyArray<{
        readonly __typename: 'FormTemplateAssociatedContract'
        readonly id: String['output']
        readonly providedAsFormType: ProjectOnboardingFormType | null
        readonly formTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly status: FormTemplateStatus
          readonly isCustomerReady: Boolean['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateType
        }
      }>
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
          readonly templateVariant: {
            readonly __typename: 'FormTemplateVariant'
            readonly id: ID['output']
            readonly template: {
              readonly __typename: 'FormTemplate'
              readonly id: String['output']
              readonly status: FormTemplateStatus
              readonly isCustomerReady: Boolean['output']
              readonly userVisibleName: String['output']
              readonly type: FormTemplateType
            }
          } | null
        }>
      }>
      readonly lienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
      } | null
      readonly lowerTierLienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
        readonly conditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
        readonly conditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
        readonly unconditionalFinalVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
        readonly unconditionalProgressVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly status: FormTemplateStatus
            readonly isCustomerReady: Boolean['output']
            readonly userVisibleName: String['output']
            readonly type: FormTemplateType
          }
        } | null
      } | null
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly status: FormTemplateStatus
        readonly isCustomerReady: Boolean['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateType
      } | null
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly selectedChangeOrderLogForms: Boolean['output']
        readonly onboardedChangeOrderLogForms: Boolean['output']
        readonly notifiedOnboardedForms: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly addedSov: Boolean['output']
        readonly addedTeammates: Boolean['output']
        readonly addedGcContacts: Boolean['output']
        readonly startedBilling: Boolean['output']
        readonly isComplete: Boolean['output']
        readonly isReadyForPayAppSubmit: Boolean['output']
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly payAppDueDate: DateTime['output']
      }>
    }>
  }
}

export type FullyBilledContractsQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type FullyBilledContractsQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly timeZone: String['output']
      readonly billingType: BillingType
      readonly retentionHeldOnBilledPayApps: SafeInt['output']
      readonly retentionAmountOutstanding: SafeInt['output']
      readonly progressAmountOutstanding: SafeInt['output']
      readonly retentionTrackingLevel: RetentionTrackingLevel
      readonly percentComplete: Float['output'] | null
      readonly status: ContractStatus
      readonly lastEditedAt: DateTime['output'] | null
      readonly sov: {
        readonly __typename: 'Sov'
        readonly id: ID['output']
        readonly totalRetention: SafeInt['output']
      } | null
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }>
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly metadata: JSONObject['output']
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly isActive: Boolean['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly metadata: JSONObject['output']
        }
      }>
      readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
        readonly metadata: {
          readonly __typename: 'ProjectMetadata'
          readonly payAppDueOnDayOfMonth: Int['output']
        }
      }
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly status: PayAppStatus
        readonly retentionOnly: Boolean['output']
        readonly billingStart: DateTime['output']
        readonly billingEnd: DateTime['output']
        readonly payAppDueDate: DateTime['output']
        readonly payAppNumber: SafeInt['output']
      }>
    }>
  }
}

export type ContractForMissingFieldsQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type ContractForMissingFieldsQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
    }>
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
    }>
  }
}

export type UpdateProjectMissingFieldsMutationVariables = Exact<{
  input: UpdateProjectInput
}>

export type UpdateProjectMissingFieldsMutation = {
  readonly __typename: 'Mutation'
  readonly updateProject: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
    readonly owner: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly selectedAddress: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
    } | null
  }
}

export type CreateContractBillingForecastMutationVariables = Exact<{
  input: CreateContractBillingForecastInput
}>

export type CreateContractBillingForecastMutation = {
  readonly __typename: 'Mutation'
  readonly createContractBillingForecast: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingForecast: {
      readonly __typename: 'ContractBillingForecast'
      readonly id: ID['output']
      readonly startingMonth: DateTime['output']
      readonly firstEditableMonthWhenCreated: DateTime['output']
      readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
      readonly remainingAmount: SafeInt['output']
      readonly curve: ForecastCurve
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    } | null
  }
}

export type UpdateContractBillingForecastMutationVariables = Exact<{
  input: UpdateContractBillingForecastInput
}>

export type UpdateContractBillingForecastMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractBillingForecast: {
    readonly __typename: 'ContractBillingForecast'
    readonly id: ID['output']
    readonly startingMonth: DateTime['output']
    readonly firstEditableMonthWhenCreated: DateTime['output']
    readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
    readonly remainingAmount: SafeInt['output']
    readonly curve: ForecastCurve
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type DeleteContractBillingForecastMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteContractBillingForecastMutation = {
  readonly __typename: 'Mutation'
  readonly deleteContractBillingForecast: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type AggregateBillingForecastQueryVariables = Exact<{
  input: AggregateBillingForecastInput
}>

export type AggregateBillingForecastQuery = {
  readonly __typename: 'Query'
  readonly aggregateBillingForecast: {
    readonly __typename: 'AggregateBillingForecast'
    readonly numContractsWithForecast: SafeInt['output']
    readonly projectedAmountThisMonth: SafeInt['output']
    readonly forecastByMonth: ReadonlyArray<{
      readonly __typename: 'AggregateForecastMonth'
      readonly month: SafeInt['output']
      readonly year: SafeInt['output']
      readonly projectedAmount: SafeInt['output'] | null
      readonly amountBilled: SafeInt['output'] | null
    }>
  } | null
}

export type ContractsForReportingForecastQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsForReportingForecastQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly totalCount: Int['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly timeZone: String['output']
      readonly firstPayAppBillingEnd: DateTime['output'] | null
      readonly earliestPayAppBillingEnd: DateTime['output'] | null
      readonly hasStartedBilling: Boolean['output']
      readonly progressRemaining: SafeInt['output']
      readonly status: ContractStatus
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        } | null
      }
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly sov: {
        readonly __typename: 'Sov'
        readonly id: ID['output']
        readonly totalValue: SafeInt['output']
      } | null
      readonly billingForecast: {
        readonly __typename: 'ContractBillingForecast'
        readonly id: ID['output']
        readonly startingMonth: DateTime['output']
        readonly firstEditableMonthWhenCreated: DateTime['output']
        readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
        readonly remainingAmount: SafeInt['output']
        readonly curve: ForecastCurve
        readonly lastEditedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
      } | null
      readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
      readonly leadPMs: ReadonlyArray<{ readonly __typename: 'User'; readonly id: ID['output'] }>
    }>
  }
}

export type PaginatedContractsForReportingCashForecastQueryVariables = Exact<{
  input: GetPaginatedCashForecastContractsInput
}>

export type PaginatedContractsForReportingCashForecastQuery = {
  readonly __typename: 'Query'
  readonly paginatedCashForecastContracts: {
    readonly __typename: 'PaginatedCashForecastContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly totalCount: Int['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'CashForecastContract'
      readonly id: ID['output']
      readonly numComments: Int['output']
      readonly hasInvoicePredictedPaymentPassed: Boolean['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly billingType: BillingType
        readonly timeZone: String['output']
        readonly firstPayAppBillingEnd: DateTime['output'] | null
        readonly earliestPayAppBillingEnd: DateTime['output'] | null
        readonly hasStartedBilling: Boolean['output']
        readonly progressRemaining: SafeInt['output']
        readonly status: ContractStatus
        readonly invoiceAmountOutstanding: SafeInt['output']
        readonly outstandingVendorLienWaiverAmount: SafeInt['output']
        readonly isJointCheck: Boolean['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly companyName: String['output'] | null
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly sov: {
          readonly __typename: 'Sov'
          readonly id: ID['output']
          readonly totalValue: SafeInt['output']
        } | null
        readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
        readonly leadPMs: ReadonlyArray<{ readonly __typename: 'User'; readonly id: ID['output'] }>
      }
      readonly paymentPeriods: ReadonlyArray<{
        readonly __typename: 'ContractPaymentPeriod'
        readonly hasRetentionOnlyPayApps: Boolean['output']
        readonly predictedPaymentAmount: SafeInt['output']
        readonly dateRange: {
          readonly __typename: 'PaymentPeriodDateRange'
          readonly startDate: String['output']
          readonly endDate: String['output']
        } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
          readonly predictedPaymentDate: DateTime['output'] | null
          readonly currentBilled: SafeInt['output']
          readonly currentRetention: SafeInt['output']
          readonly previousRetentionBilled: SafeInt['output']
          readonly isBalanceManuallyClosed: Boolean['output']
          readonly amountPaid: SafeInt['output'] | null
          readonly status: PayAppStatus
          readonly timeZone: String['output']
          readonly retentionOnly: Boolean['output']
        }>
      }>
    }>
  }
}

export type GetContractForBillingForecastQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForBillingForecastQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly billingForecast: {
      readonly __typename: 'ContractBillingForecast'
      readonly id: ID['output']
      readonly startingMonth: DateTime['output']
      readonly firstEditableMonthWhenCreated: DateTime['output']
      readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
      readonly remainingAmount: SafeInt['output']
      readonly curve: ForecastCurve
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly payAppDueDate: DateTime['output']
      readonly internalDueDate: DateTime['output']
      readonly billingStart: DateTime['output']
      readonly billingEnd: DateTime['output']
      readonly billingType: BillingType
      readonly status: PayAppStatus
      readonly invoiceReady: Boolean['output']
      readonly retentionOnly: Boolean['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly draftSubmittedAt: DateTime['output'] | null
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output'] | null
      readonly status: PreSitelinePayAppStatus | null
      readonly currentBilled: SafeInt['output'] | null
      readonly retentionOnly: Boolean['output']
    }>
  }
}

export type CreateIntegrationMutationVariables = Exact<{
  input: CreateIntegrationInput
}>

export type CreateIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly createIntegration: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }
}

export type UpdateIntegrationMetadataMutationVariables = Exact<{
  input: UpdateIntegrationMetadataInput
}>

export type UpdateIntegrationMetadataMutation = {
  readonly __typename: 'Mutation'
  readonly updateIntegrationMetadata: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }
}

export type ContractForCreatingForecastQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type ContractForCreatingForecastQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly firstPayAppBillingEnd: DateTime['output'] | null
    readonly earliestPayAppBillingEnd: DateTime['output'] | null
    readonly hasStartedBilling: Boolean['output']
    readonly progressRemaining: SafeInt['output']
    readonly timeZone: String['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
    } | null
    readonly billingForecast: {
      readonly __typename: 'ContractBillingForecast'
      readonly id: ID['output']
      readonly startingMonth: DateTime['output']
      readonly firstEditableMonthWhenCreated: DateTime['output']
      readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
      readonly remainingAmount: SafeInt['output']
      readonly curve: ForecastCurve
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly payAppDueDate: DateTime['output']
      readonly internalDueDate: DateTime['output']
      readonly billingStart: DateTime['output']
      readonly billingEnd: DateTime['output']
      readonly billingType: BillingType
      readonly status: PayAppStatus
      readonly invoiceReady: Boolean['output']
      readonly retentionOnly: Boolean['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly draftSubmittedAt: DateTime['output'] | null
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output'] | null
      readonly status: PreSitelinePayAppStatus | null
      readonly currentBilled: SafeInt['output'] | null
      readonly retentionOnly: Boolean['output']
    }>
    readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type GetRateTablesQueryVariables = Exact<{
  input: RateTablesInput
}>

export type GetRateTablesQuery = {
  readonly __typename: 'Query'
  readonly rateTables: ReadonlyArray<{
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
    }>
  }>
}

export type GetRateTableQueryVariables = Exact<{
  rateTableId: Scalars['ID']['input']
}>

export type GetRateTableQuery = {
  readonly __typename: 'Query'
  readonly rateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly groups: ReadonlyArray<{
      readonly __typename: 'RateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: LineItemType
    }>
    readonly items: ReadonlyArray<{
      readonly __typename: 'RateTableItem'
      readonly id: ID['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly description: String['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly group: {
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
    }>
  }
}

export type CreateRateTableMutationVariables = Exact<{
  input: CreateRateTableInput
}>

export type CreateRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly createRateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly groups: ReadonlyArray<{
      readonly __typename: 'RateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: LineItemType
    }>
    readonly items: ReadonlyArray<{
      readonly __typename: 'RateTableItem'
      readonly id: ID['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly description: String['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly group: {
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
    }>
  }
}

export type UpdateRateTableMutationVariables = Exact<{
  input: UpdateRateTableInput
}>

export type UpdateRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly updateRateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly groups: ReadonlyArray<{
      readonly __typename: 'RateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: LineItemType
    }>
    readonly items: ReadonlyArray<{
      readonly __typename: 'RateTableItem'
      readonly id: ID['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly description: String['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly group: {
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
    }>
  }
}

export type DeleteRateTableMutationVariables = Exact<{
  rateTableId: Scalars['ID']['input']
}>

export type DeleteRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly deleteRateTable: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type ArchiveRateTableMutationVariables = Exact<{
  rateTableId: Scalars['ID']['input']
}>

export type ArchiveRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly archiveRateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly status: RateTableStatus
  }
}

export type UnarchiveRateTableMutationVariables = Exact<{
  rateTableId: Scalars['ID']['input']
}>

export type UnarchiveRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly unarchiveRateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly status: RateTableStatus
  }
}

export type DuplicateRateTableMutationVariables = Exact<{
  rateTableId: Scalars['ID']['input']
}>

export type DuplicateRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly duplicateRateTable: {
    readonly __typename: 'RateTable'
    readonly id: ID['output']
    readonly name: String['output']
    readonly status: RateTableStatus
    readonly effectiveAt: DateTime['output'] | null
    readonly expiresAt: DateTime['output'] | null
    readonly lastEditedAt: DateTime['output']
    readonly lastEditedBy: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
    readonly groups: ReadonlyArray<{
      readonly __typename: 'RateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly type: LineItemType
    }>
    readonly items: ReadonlyArray<{
      readonly __typename: 'RateTableItem'
      readonly id: ID['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly description: String['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly group: {
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
      }>
    }>
    readonly associatedContracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly hasContractRateTableChanged: Boolean['output']
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      }>
    }>
  }
}

export type CalculateRetentionChangeQueryVariables = Exact<{
  input: CalculateRetentionChangeInput
}>

export type CalculateRetentionChangeQuery = {
  readonly __typename: 'Query'
  readonly calculateRetentionChange: {
    readonly __typename: 'RetentionBillingResult'
    readonly retentionReleased: Float['output']
    readonly retentionHeldCurrent: Float['output']
    readonly retentionHeldToDate: Float['output']
  }
}

export type ResetPayAppRetentionReleasedMutationVariables = Exact<{
  input: ResetPayAppRetentionReleasedInput
}>

export type ResetPayAppRetentionReleasedMutation = {
  readonly __typename: 'Mutation'
  readonly resetPayAppRetentionReleased: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly canGenerateForms: Boolean['output']
    readonly retentionReleased: SafeInt['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
    }>
  }
}

export type ResetPayAppRetentionHeldOverrideMutationVariables = Exact<{
  input: ResetPayAppRetentionHeldOverrideInput
}>

export type ResetPayAppRetentionHeldOverrideMutation = {
  readonly __typename: 'Mutation'
  readonly resetPayAppRetentionHeldOverride: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly canGenerateForms: Boolean['output']
    readonly retentionReleased: SafeInt['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
    }>
  }
}

export type ContractCashForecastDataQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractCashForecastDataQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly internalProjectNumber: String['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly invoiceAmountOutstanding: SafeInt['output']
    readonly outstandingVendorLienWaiverAmount: SafeInt['output']
    readonly timeZone: String['output']
    readonly leadPMs: ReadonlyArray<{ readonly __typename: 'User'; readonly id: ID['output'] }>
    readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly payAppNumber: SafeInt['output']
      readonly predictedPaymentDate: DateTime['output'] | null
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly isBalanceManuallyClosed: Boolean['output']
      readonly amountPaid: SafeInt['output'] | null
      readonly status: PayAppStatus
      readonly timeZone: String['output']
      readonly retentionOnly: Boolean['output']
      readonly billingEnd: DateTime['output']
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly payAppNumber: Int['output']
      readonly status: PreSitelinePayAppStatus | null
      readonly paymentDue: SafeInt['output'] | null
      readonly retentionOnly: Boolean['output']
      readonly billingEnd: DateTime['output'] | null
      readonly amountPaid: SafeInt['output'] | null
      readonly isBalanceManuallyClosed: Boolean['output']
    }>
  }
}

export type ContractsCashForecastDataQueryVariables = Exact<{
  input: GetPaginatedCashForecastContractsInput
}>

export type ContractsCashForecastDataQuery = {
  readonly __typename: 'Query'
  readonly paginatedCashForecastContracts: {
    readonly __typename: 'PaginatedCashForecastContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'CashForecastContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly billingType: BillingType
        readonly internalProjectNumber: String['output'] | null
        readonly isJointCheck: Boolean['output']
        readonly invoiceAmountOutstanding: SafeInt['output']
        readonly outstandingVendorLienWaiverAmount: SafeInt['output']
        readonly timeZone: String['output']
        readonly leadPMs: ReadonlyArray<{ readonly __typename: 'User'; readonly id: ID['output'] }>
        readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly companyName: String['output'] | null
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
          readonly predictedPaymentDate: DateTime['output'] | null
          readonly currentBilled: SafeInt['output']
          readonly currentRetention: SafeInt['output']
          readonly previousRetentionBilled: SafeInt['output']
          readonly amountDuePostTax: SafeInt['output']
          readonly isBalanceManuallyClosed: Boolean['output']
          readonly amountPaid: SafeInt['output'] | null
          readonly status: PayAppStatus
          readonly timeZone: String['output']
          readonly retentionOnly: Boolean['output']
          readonly billingEnd: DateTime['output']
        }>
        readonly preSitelinePayApps: ReadonlyArray<{
          readonly __typename: 'PreSitelinePayApp'
          readonly id: ID['output']
          readonly payAppNumber: Int['output']
          readonly status: PreSitelinePayAppStatus | null
          readonly paymentDue: SafeInt['output'] | null
          readonly retentionOnly: Boolean['output']
          readonly billingEnd: DateTime['output'] | null
          readonly amountPaid: SafeInt['output'] | null
          readonly isBalanceManuallyClosed: Boolean['output']
        }>
      }
      readonly paymentPeriods: ReadonlyArray<{
        readonly __typename: 'ContractPaymentPeriod'
        readonly dateRange: {
          readonly __typename: 'PaymentPeriodDateRange'
          readonly startDate: String['output']
          readonly endDate: String['output']
        } | null
      }>
    }>
  }
}

export type UpdateContractRateTableMutationVariables = Exact<{
  input: UpdateContractRateTableInput
}>

export type UpdateContractRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractRateTable: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly groups: ReadonlyArray<{
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }>
      readonly items: ReadonlyArray<{
        readonly __typename: 'RateTableItem'
        readonly id: ID['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly description: String['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly group: {
          readonly __typename: 'RateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly type: LineItemType
        }
      }>
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
      }>
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
      readonly rateTableItems: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableItem'
        readonly id: ID['output']
      }>
      readonly rateTableGroups: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }>
    }>
  }
}

export type DeleteContractRateTableMutationVariables = Exact<{
  input: DeleteContractRateTableInput
}>

export type DeleteContractRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly deleteContractRateTable: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly groups: ReadonlyArray<{
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }>
      readonly items: ReadonlyArray<{
        readonly __typename: 'RateTableItem'
        readonly id: ID['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly description: String['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly group: {
          readonly __typename: 'RateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly type: LineItemType
        }
      }>
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ContractRateTableFee'
      readonly id: ID['output']
      readonly description: String['output']
      readonly percent: Float['output']
      readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
      readonly overridesFee: {
        readonly __typename: 'ContractRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type CreateContractRateTableFeeMutationVariables = Exact<{
  input: CreateContractRateTableFeeInput
}>

export type CreateContractRateTableFeeMutation = {
  readonly __typename: 'Mutation'
  readonly createContractRateTableFee: {
    readonly __typename: 'ContractRateTableFee'
    readonly id: ID['output']
    readonly description: String['output']
    readonly percent: Float['output']
    readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
    readonly overridesFee: {
      readonly __typename: 'ContractRateTableFee'
      readonly id: ID['output']
    } | null
  }
}

export type UpdateContractRateTableFeeMutationVariables = Exact<{
  input: UpdateContractRateTableFeeInput
}>

export type UpdateContractRateTableFeeMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractRateTableFee: {
    readonly __typename: 'ContractRateTableFee'
    readonly id: ID['output']
    readonly description: String['output']
    readonly percent: Float['output']
    readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
    readonly overridesFee: {
      readonly __typename: 'ContractRateTableFee'
      readonly id: ID['output']
    } | null
  }
}

export type DeleteContractRateTableFeeMutationVariables = Exact<{
  input: DeleteContractRateTableFeeInput
}>

export type DeleteContractRateTableFeeMutation = {
  readonly __typename: 'Mutation'
  readonly deleteContractRateTableFee: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type GetPayAppForProgressQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppForProgressQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly timeZone: String['output']
    readonly retentionOnly: Boolean['output']
    readonly payAppDueDate: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingType: BillingType
    readonly payAppNumber: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly invoiceReady: Boolean['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly lastPayAppWithRetentionHeldOverride: {
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly payAppNumber: SafeInt['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxGroups: ReadonlyArray<{
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
    }
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type CreatePayAppRateTableItemMutationVariables = Exact<{
  input: CreatePayAppRateTableItemInput
}>

export type CreatePayAppRateTableItemMutation = {
  readonly __typename: 'Mutation'
  readonly createPayAppRateTableItem: {
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }
}

export type UpdatePayAppRateTableItemMutationVariables = Exact<{
  input: UpdatePayAppRateTableItemInput
}>

export type UpdatePayAppRateTableItemMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppRateTableItem: {
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentUnitsBilled: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
    }>
  }
}

export type DeletePayAppRateTableItemMutationVariables = Exact<{
  input: DeletePayAppRateTableItemInput
}>

export type DeletePayAppRateTableItemMutation = {
  readonly __typename: 'Mutation'
  readonly deletePayAppRateTableItem: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CreateChangeOrderRequestRateTableItemMutationVariables = Exact<{
  input: CreateChangeOrderRequestRateTableItemInput
}>

export type CreateChangeOrderRequestRateTableItemMutation = {
  readonly __typename: 'Mutation'
  readonly createChangeOrderRequestRateTableItem: {
    readonly __typename: 'ChangeOrderRequestRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentPriced: SafeInt['output']
    readonly currentUnitsPriced: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    }
  }
}

export type UpdateChangeOrderRequestRateTableItemMutationVariables = Exact<{
  input: UpdateChangeOrderRequestRateTableItemInput
}>

export type UpdateChangeOrderRequestRateTableItemMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrderRequestRateTableItem: {
    readonly __typename: 'ChangeOrderRequestRateTableItem'
    readonly id: ID['output']
    readonly description: String['output']
    readonly code: String['output'] | null
    readonly sortOrder: SafeInt['output']
    readonly unitName: String['output'] | null
    readonly unitRate: SafeInt['output']
    readonly currentPriced: SafeInt['output']
    readonly currentUnitsPriced: Float['output']
    readonly isOneOffItem: Boolean['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
    }
  }
}

export type DeleteChangeOrderRequestRateTableItemMutationVariables = Exact<{
  input: DeleteChangeOrderRequestRateTableItemInput
}>

export type DeleteChangeOrderRequestRateTableItemMutation = {
  readonly __typename: 'Mutation'
  readonly deleteChangeOrderRequestRateTableItem: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type SetCurrentBilledMutationVariables = Exact<{
  input: SetCurrentBilledInput
}>

export type SetCurrentBilledMutation = {
  readonly __typename: 'Mutation'
  readonly setCurrentBilled: {
    readonly __typename: 'PayAppRateTableItem'
    readonly id: ID['output']
    readonly currentBilled: SafeInt['output']
    readonly group: {
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly subtotalAmount: SafeInt['output']
      readonly totalAmount: SafeInt['output']
    }
    readonly payApp: {
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
    }
  }
}

export type SetCurrentPricingMutationVariables = Exact<{
  input: SetCurrentPricingInput
}>

export type SetCurrentPricingMutation = {
  readonly __typename: 'Mutation'
  readonly setCurrentPricing: {
    readonly __typename: 'ChangeOrderRequestRateTableItem'
    readonly id: ID['output']
    readonly currentPriced: SafeInt['output']
    readonly group: {
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly subtotalAmount: SafeInt['output']
      readonly totalAmount: SafeInt['output']
    }
    readonly changeOrderRequest: {
      readonly __typename: 'ChangeOrderRequest'
      readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly proposedAmount: SafeInt['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly unitName: String['output'] | null
      readonly proposedAt: DateTime['output'] | null
      readonly scheduleImpact: Float['output'] | null
      readonly proceedingWithWork: Boolean['output']
      readonly status: ChangeOrderRequestStatus
      readonly statusChangedAt: DateTime['output']
      readonly reason: String['output'] | null
      readonly importedAt: DateTime['output'] | null
      readonly visibleToGc: Boolean['output']
      readonly hasContractRateTableChanged: Boolean['output']
      readonly haveContractFeesChanged: Boolean['output']
      readonly approvedScoFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestAttachment'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly uploadId: ID['output']
        readonly description: String['output'] | null
        readonly isInternalOnly: Boolean['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly name: String['output']
        readonly code: String['output']
        readonly latestTotalValue: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
      }>
      readonly emailedContacts: ReadonlyArray<{
        readonly __typename: 'CompanyContact'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly companyName: String['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
      }>
      readonly linkedChangeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly internalNumber: String['output'] | null
        readonly generalContractorNumber: String['output'] | null
        readonly status: ChangeOrderRequestStatus
        readonly amount: SafeInt['output'] | null
        readonly name: String['output']
        readonly sovLineItems: ReadonlyArray<{
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
        }>
      }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly changeOrderRequestTemplate: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        } | null
      }
      readonly rateTable: {
        readonly __typename: 'RateTable'
        readonly id: ID['output']
        readonly name: String['output']
        readonly status: RateTableStatus
        readonly effectiveAt: DateTime['output'] | null
        readonly expiresAt: DateTime['output'] | null
        readonly lastEditedAt: DateTime['output']
        readonly lastEditedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
        }
        readonly contracts: ReadonlyArray<{
          readonly __typename: 'Contract'
          readonly id: ID['output']
        }>
        readonly associatedContracts: ReadonlyArray<{
          readonly __typename: 'Contract'
          readonly id: ID['output']
        }>
      } | null
      readonly rateTableFees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
      readonly rateTableGroups: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly totalAmount: SafeInt['output']
        readonly subtotalAmount: SafeInt['output']
        readonly type: LineItemType
        readonly fees: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
          readonly percent: Float['output']
          readonly description: String['output']
          readonly group: {
            readonly __typename: 'ChangeOrderRequestRateTableGroup'
            readonly id: ID['output']
          } | null
          readonly overridesFee: {
            readonly __typename: 'ChangeOrderRequestRateTableFee'
            readonly id: ID['output']
          } | null
        }>
      }>
      readonly rateTableItems: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableItem'
        readonly id: ID['output']
        readonly description: String['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly currentPriced: SafeInt['output']
        readonly currentUnitsPriced: Float['output']
        readonly isOneOffItem: Boolean['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        }
      }>
    }
  }
}

export type ResetPayAppWithContractRateTableMutationVariables = Exact<{
  input: ResetPayAppWithContractRateTableInput
}>

export type ResetPayAppWithContractRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly resetPayAppWithContractRateTable: {
    readonly __typename: 'PayApp'
    readonly balanceToFinish: SafeInt['output']
    readonly automaticallyReadBilling: Boolean['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly integrationPaidAt: DateTime['output'] | null
    readonly canGenerateForms: Boolean['output']
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly predictedPaymentDate: DateTime['output'] | null
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly revertToDraftEvents: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isEmailAttachment: Boolean['output']
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
      readonly sovLineItemProgress: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly currentRetention: SafeInt['output']
        readonly storedMaterialBilled: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly futureBilled: SafeInt['output']
        readonly previousRetention: SafeInt['output']
        readonly previousRetentionBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly retentionHeldPercent: Float['output']
        readonly retentionHeldOverride: SafeInt['output'] | null
        readonly retentionReleased: SafeInt['output'] | null
        readonly amountDuePreTax: SafeInt['output']
        readonly amountDuePostTax: SafeInt['output']
        readonly amountDueTaxAmount: SafeInt['output']
        readonly lastProgressWithRetentionHeldOverride: {
          readonly __typename: 'SovLineItemProgress'
          readonly id: ID['output']
          readonly payApp: {
            readonly __typename: 'PayApp'
            readonly id: ID['output']
            readonly payAppNumber: SafeInt['output']
          }
        } | null
        readonly sovLineItem: {
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly sortOrder: SafeInt['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly isChangeOrder: Boolean['output']
          readonly changeOrderApprovedDate: DateTime['output'] | null
          readonly changeOrderEffectiveDate: DateTime['output'] | null
          readonly originalTotalValue: SafeInt['output']
          readonly latestTotalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly billedToDate: SafeInt['output']
          readonly defaultRetentionPercent: Float['output'] | null
          readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
          readonly preSitelineRetention: SafeInt['output']
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
          readonly changeOrderRequests: ReadonlyArray<{
            readonly __typename: 'ChangeOrderRequest'
            readonly id: ID['output']
            readonly name: String['output']
            readonly internalNumber: String['output'] | null
          }>
          readonly worksheetLineItems: ReadonlyArray<{
            readonly __typename: 'WorksheetLineItem'
            readonly id: ID['output']
            readonly code: String['output']
            readonly name: String['output']
            readonly costCode: String['output'] | null
            readonly totalValue: SafeInt['output']
            readonly previousBilled: SafeInt['output']
            readonly unitPrice: Decimal['output'] | null
            readonly unitName: String['output'] | null
            readonly billedToDate: SafeInt['output']
            readonly sortOrder: SafeInt['output']
            readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
          }>
          readonly taxGroup: {
            readonly __typename: 'TaxGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly taxPercent: Float['output']
            readonly integrationMappings: JSONObject['output']
          } | null
        }
        readonly worksheetLineItemProgress: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItemProgress'
          readonly id: ID['output']
          readonly progressBilled: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly totalValue: SafeInt['output']
          readonly worksheetLineItem: {
            readonly __typename: 'WorksheetLineItem'
            readonly id: ID['output']
            readonly code: String['output']
            readonly name: String['output']
            readonly costCode: String['output'] | null
            readonly unitName: String['output'] | null
            readonly unitPrice: Decimal['output'] | null
            readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
          }
        }>
      } | null
      readonly rateTableItems: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableItem'
        readonly id: ID['output']
        readonly description: String['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly currentUnitsBilled: Float['output']
        readonly isOneOffItem: Boolean['output']
        readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
        readonly attachments: ReadonlyArray<{
          readonly __typename: 'PayAppAttachment'
          readonly id: ID['output']
        }>
      }>
    }>
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    }>
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type ResetChangeOrderRequestWithContractRateTableMutationVariables = Exact<{
  input: ResetChangeOrderRequestWithContractRateTableInput
}>

export type ResetChangeOrderRequestWithContractRateTableMutation = {
  readonly __typename: 'Mutation'
  readonly resetChangeOrderRequestWithContractRateTable: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  }
}

export type ResetPayAppWithContractRateTableFeesMutationVariables = Exact<{
  input: ResetPayAppWithContractRateTableFeesInput
}>

export type ResetPayAppWithContractRateTableFeesMutation = {
  readonly __typename: 'Mutation'
  readonly resetPayAppWithContractRateTableFees: {
    readonly __typename: 'PayApp'
    readonly balanceToFinish: SafeInt['output']
    readonly automaticallyReadBilling: Boolean['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly integrationPaidAt: DateTime['output'] | null
    readonly canGenerateForms: Boolean['output']
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly predictedPaymentDate: DateTime['output'] | null
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly revertToDraftEvents: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isEmailAttachment: Boolean['output']
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
      readonly sovLineItemProgress: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly currentRetention: SafeInt['output']
        readonly storedMaterialBilled: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly futureBilled: SafeInt['output']
        readonly previousRetention: SafeInt['output']
        readonly previousRetentionBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly retentionHeldPercent: Float['output']
        readonly retentionHeldOverride: SafeInt['output'] | null
        readonly retentionReleased: SafeInt['output'] | null
        readonly amountDuePreTax: SafeInt['output']
        readonly amountDuePostTax: SafeInt['output']
        readonly amountDueTaxAmount: SafeInt['output']
        readonly lastProgressWithRetentionHeldOverride: {
          readonly __typename: 'SovLineItemProgress'
          readonly id: ID['output']
          readonly payApp: {
            readonly __typename: 'PayApp'
            readonly id: ID['output']
            readonly payAppNumber: SafeInt['output']
          }
        } | null
        readonly sovLineItem: {
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly sortOrder: SafeInt['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly isChangeOrder: Boolean['output']
          readonly changeOrderApprovedDate: DateTime['output'] | null
          readonly changeOrderEffectiveDate: DateTime['output'] | null
          readonly originalTotalValue: SafeInt['output']
          readonly latestTotalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly billedToDate: SafeInt['output']
          readonly defaultRetentionPercent: Float['output'] | null
          readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
          readonly preSitelineRetention: SafeInt['output']
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
          readonly changeOrderRequests: ReadonlyArray<{
            readonly __typename: 'ChangeOrderRequest'
            readonly id: ID['output']
            readonly name: String['output']
            readonly internalNumber: String['output'] | null
          }>
          readonly worksheetLineItems: ReadonlyArray<{
            readonly __typename: 'WorksheetLineItem'
            readonly id: ID['output']
            readonly code: String['output']
            readonly name: String['output']
            readonly costCode: String['output'] | null
            readonly totalValue: SafeInt['output']
            readonly previousBilled: SafeInt['output']
            readonly unitPrice: Decimal['output'] | null
            readonly unitName: String['output'] | null
            readonly billedToDate: SafeInt['output']
            readonly sortOrder: SafeInt['output']
            readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
          }>
          readonly taxGroup: {
            readonly __typename: 'TaxGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly taxPercent: Float['output']
            readonly integrationMappings: JSONObject['output']
          } | null
        }
        readonly worksheetLineItemProgress: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItemProgress'
          readonly id: ID['output']
          readonly progressBilled: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly totalValue: SafeInt['output']
          readonly worksheetLineItem: {
            readonly __typename: 'WorksheetLineItem'
            readonly id: ID['output']
            readonly code: String['output']
            readonly name: String['output']
            readonly costCode: String['output'] | null
            readonly unitName: String['output'] | null
            readonly unitPrice: Decimal['output'] | null
            readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
          }
        }>
      } | null
      readonly rateTableItems: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableItem'
        readonly id: ID['output']
        readonly description: String['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly currentUnitsBilled: Float['output']
        readonly isOneOffItem: Boolean['output']
        readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
        readonly attachments: ReadonlyArray<{
          readonly __typename: 'PayAppAttachment'
          readonly id: ID['output']
        }>
      }>
    }>
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    }>
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type ResetChangeOrderRequestWithContractRateTableFeesMutationVariables = Exact<{
  input: ResetChangeOrderRequestWithContractRateTableFeesInput
}>

export type ResetChangeOrderRequestWithContractRateTableFeesMutation = {
  readonly __typename: 'Mutation'
  readonly resetChangeOrderRequestWithContractRateTableFees: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly hasContractRateTableChanged: Boolean['output']
    readonly haveContractFeesChanged: Boolean['output']
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly uploadId: ID['output']
      readonly description: String['output'] | null
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly name: String['output']
      readonly code: String['output']
      readonly latestTotalValue: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequestRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'ChangeOrderRequestRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'ChangeOrderRequestRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentPriced: SafeInt['output']
      readonly currentUnitsPriced: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: {
        readonly __typename: 'ChangeOrderRequestRateTableGroup'
        readonly id: ID['output']
      }
    }>
  }
}

export type GenerateIntegrationInvoiceCodeQueryVariables = Exact<{
  input: GenerateIntegrationInvoiceCodeInput
}>

export type GenerateIntegrationInvoiceCodeQuery = {
  readonly __typename: 'Query'
  readonly generateIntegrationInvoiceCode: String['output'] | null
}

export type EstimatePayAppPaymentDateQueryVariables = Exact<{
  input: EstimatePayAppPaymentDateInput
}>

export type EstimatePayAppPaymentDateQuery = {
  readonly __typename: 'Query'
  readonly estimatePayAppPaymentDate: {
    readonly __typename: 'EstimatedPayAppPaymentDate'
    readonly paymentDate: DateTime['output']
    readonly estimationMethod: PaymentDateEstimationType
  }
}

export type AggregateCashForecastQueryVariables = Exact<{
  input: AggregateCashForecastInput
}>

export type AggregateCashForecastQuery = {
  readonly __typename: 'Query'
  readonly aggregateCashForecast: {
    readonly __typename: 'AggregateCashForecast'
    readonly amountProjectedThisPeriod: SafeInt['output']
    readonly totalOpenInvoiceAmount: SafeInt['output']
    readonly totalUnknownPredictedInvoiceAmount: SafeInt['output']
    readonly totalPayAppUnknownPredictedInvoiceAmount: SafeInt['output']
    readonly forecast: ReadonlyArray<{
      readonly __typename: 'CashForecastPeriod'
      readonly startDate: String['output']
      readonly period: CashForecastPeriodType
      readonly amountOutstanding: SafeInt['output']
    }>
  }
}

export type VendorNumberForGeneralContractorQueryVariables = Exact<{
  input: VendorNumberForGeneralContractorInput
}>

export type VendorNumberForGeneralContractorQuery = {
  readonly __typename: 'Query'
  readonly vendorNumberForGeneralContractor: String['output'] | null
}

export type ChangeOrderReportQueryVariables = Exact<{
  input: ChangeOrderReportInput
}>

export type ChangeOrderReportQuery = {
  readonly __typename: 'Query'
  readonly changeOrderReport: {
    readonly __typename: 'ChangeOrderReport'
    readonly changeOrderSummaryReport: {
      readonly __typename: 'ChangeOrderSummaryReport'
      readonly aggregateChangeOrderTotals: {
        readonly __typename: 'AggregateChangeOrderData'
        readonly totalAmountUnapproved: SafeInt['output']
        readonly totalAmountAuthorizedToProceed: SafeInt['output']
        readonly totalAmountProceedingWithWork: SafeInt['output']
        readonly totalAmountApproved: SafeInt['output']
        readonly totalOriginalContractAmount: SafeInt['output']
        readonly totalRevisedContractAmount: SafeInt['output']
      }
      readonly aggregateChangeOrderDataByContract: ReadonlyArray<{
        readonly __typename: 'AggregateChangeOrderDataByContract'
        readonly aggregateChangeOrderData: {
          readonly __typename: 'AggregateChangeOrderData'
          readonly totalAmountUnapproved: SafeInt['output']
          readonly totalAmountAuthorizedToProceed: SafeInt['output']
          readonly totalAmountProceedingWithWork: SafeInt['output']
          readonly totalAmountApproved: SafeInt['output']
          readonly totalOriginalContractAmount: SafeInt['output']
          readonly totalRevisedContractAmount: SafeInt['output']
        }
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly timeZone: String['output']
          readonly leadPMs: ReadonlyArray<{
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }>
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly projectNumber: String['output']
            readonly name: String['output']
            readonly generalContractor: {
              readonly __typename: 'CompanyProjectMetadata'
              readonly companyName: String['output'] | null
              readonly company: {
                readonly __typename: 'Company'
                readonly id: ID['output']
                readonly name: String['output']
              }
            } | null
          }
        }
      }>
    }
    readonly pendingChangeOrderReport: {
      readonly __typename: 'PendingChangeOrderReport'
      readonly totalAmountRequested: SafeInt['output']
      readonly totalAmountInDraft: SafeInt['output']
      readonly totalAmountApproved: SafeInt['output']
      readonly totalOriginalContractAmount: SafeInt['output']
      readonly totalRevisedContractAmount: SafeInt['output']
      readonly totalAmountAuthorizedToProceed: SafeInt['output']
      readonly totalAmountProceedingWithWork: SafeInt['output']
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
        readonly generalContractorNumber: String['output'] | null
        readonly amount: SafeInt['output'] | null
        readonly proposedAmount: SafeInt['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly proposedAt: DateTime['output'] | null
        readonly scheduleImpact: Float['output'] | null
        readonly proceedingWithWork: Boolean['output']
        readonly status: ChangeOrderRequestStatus
        readonly statusChangedAt: DateTime['output']
        readonly reason: String['output'] | null
        readonly importedAt: DateTime['output'] | null
        readonly visibleToGc: Boolean['output']
        readonly contract: {
          readonly __typename: 'Contract'
          readonly id: ID['output']
          readonly internalProjectNumber: String['output'] | null
          readonly timeZone: String['output']
          readonly changeOrderRequestTemplate: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
          } | null
          readonly leadPMs: ReadonlyArray<{
            readonly __typename: 'User'
            readonly id: ID['output']
            readonly firstName: String['output']
            readonly lastName: String['output']
          }>
          readonly project: {
            readonly __typename: 'Project'
            readonly id: ID['output']
            readonly projectNumber: String['output']
            readonly name: String['output']
            readonly generalContractor: {
              readonly __typename: 'CompanyProjectMetadata'
              readonly companyName: String['output'] | null
              readonly company: {
                readonly __typename: 'Company'
                readonly id: ID['output']
                readonly name: String['output']
              }
            } | null
          }
        }
        readonly emailedContacts: ReadonlyArray<{
          readonly __typename: 'CompanyContact'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly companyName: String['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
        }>
        readonly linkedChangeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly internalNumber: String['output'] | null
          readonly generalContractorNumber: String['output'] | null
          readonly status: ChangeOrderRequestStatus
          readonly amount: SafeInt['output'] | null
          readonly name: String['output']
          readonly sovLineItems: ReadonlyArray<{
            readonly __typename: 'SovLineItem'
            readonly id: ID['output']
          }>
        }>
        readonly sovLineItems: ReadonlyArray<{
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
        }>
        readonly attachments: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequestAttachment'
          readonly id: ID['output']
        }>
        readonly approvedScoFile: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
        } | null
      }>
    }
  }
}

export type UpdateWorksheetLineItemsMutationVariables = Exact<{
  input: UpdateWorksheetLineItemsInput
}>

export type UpdateWorksheetLineItemsMutation = {
  readonly __typename: 'Mutation'
  readonly updateWorksheetLineItems: {
    readonly __typename: 'Sov'
    readonly id: ID['output']
    readonly totalValue: SafeInt['output']
    readonly totalBilled: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progressComplete: Float['output']
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly preSitelineRetention: SafeInt['output']
      readonly id: ID['output']
      readonly code: String['output']
      readonly sortOrder: SafeInt['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly isChangeOrder: Boolean['output']
      readonly changeOrderApprovedDate: DateTime['output'] | null
      readonly changeOrderEffectiveDate: DateTime['output'] | null
      readonly originalTotalValue: SafeInt['output']
      readonly latestTotalValue: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly billedToDate: SafeInt['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly changeOrderRequests: ReadonlyArray<{
        readonly __typename: 'ChangeOrderRequest'
        readonly id: ID['output']
        readonly name: String['output']
        readonly internalNumber: String['output'] | null
      }>
      readonly worksheetLineItems: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly totalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly unitPrice: Decimal['output'] | null
        readonly unitName: String['output'] | null
        readonly billedToDate: SafeInt['output']
        readonly sortOrder: SafeInt['output']
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }>
  }
}

export type UpdateWorksheetProgressBilledMutationVariables = Exact<{
  input: UpdateWorksheetProgressBilledInput
}>

export type UpdateWorksheetProgressBilledMutation = {
  readonly __typename: 'Mutation'
  readonly updateWorksheetProgressBilled: {
    readonly __typename: 'WorksheetLineItemProgress'
    readonly id: ID['output']
    readonly progressBilled: SafeInt['output']
    readonly previousBilled: SafeInt['output']
    readonly totalValue: SafeInt['output']
    readonly worksheetLineItem: {
      readonly __typename: 'WorksheetLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly name: String['output']
      readonly costCode: String['output'] | null
      readonly unitName: String['output'] | null
      readonly unitPrice: Decimal['output'] | null
      readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
    }
  }
}

export type BulkAddPredictedPaymentDatesMutationVariables = Exact<{
  input: BulkAddPredictedPaymentDatesInput
}>

export type BulkAddPredictedPaymentDatesMutation = {
  readonly __typename: 'Mutation'
  readonly bulkAddPredictedPaymentDates: ReadonlyArray<{
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly predictedPaymentDate: DateTime['output'] | null
  }>
}

export type CompanyForVendorSettingsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyForVendorSettingsQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
  }
}

export type RemoveVendorIntegrationMutationVariables = Exact<{
  input: RemoveVendorIntegrationInput
}>

export type RemoveVendorIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly removeVendorIntegration: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }
}

export type RemoveVendorIntegrationsMutationVariables = Exact<{
  input: RemoveVendorIntegrationsInput
}>

export type RemoveVendorIntegrationsMutation = {
  readonly __typename: 'Mutation'
  readonly removeVendorIntegrations: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }
}

export type AddContractFilesMutationVariables = Exact<{
  input: AddContractFileInput
}>

export type AddContractFilesMutation = {
  readonly __typename: 'Mutation'
  readonly addContractFiles: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly files: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
  }
}

export type DeleteContractFileMutationVariables = Exact<{
  input: DeleteContractFileInput
}>

export type DeleteContractFileMutation = {
  readonly __typename: 'Mutation'
  readonly deleteContractFile: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly files: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
  }
}

export type UpdateUserEmailAliasesMutationVariables = Exact<{
  input: UpdateUserEmailAliasesInput
}>

export type UpdateUserEmailAliasesMutation = {
  readonly __typename: 'Mutation'
  readonly updateUserEmailAliases: ReadonlyArray<{
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly emailAlias: {
      readonly __typename: 'CompanyUserEmailAlias'
      readonly id: ID['output']
      readonly emailAddress: EmailAddress['output']
      readonly isVerified: Boolean['output']
      readonly verificationCodeExpiresAt: DateTime['output']
    } | null
  }>
}

export type VerifyEmailAliasMutationVariables = Exact<{
  input: VerifyEmailAliasInput
}>

export type VerifyEmailAliasMutation = {
  readonly __typename: 'Mutation'
  readonly verifyEmailAlias: {
    readonly __typename: 'CompanyUserEmailAlias'
    readonly id: ID['output']
    readonly emailAddress: EmailAddress['output']
    readonly isVerified: Boolean['output']
    readonly verificationCodeExpiresAt: DateTime['output']
  }
}

export type ResendEmailAliasVerificationMutationVariables = Exact<{
  companyUserEmailAliasId: Scalars['ID']['input']
}>

export type ResendEmailAliasVerificationMutation = {
  readonly __typename: 'Mutation'
  readonly resendEmailAliasVerification: {
    readonly __typename: 'CompanyUserEmailAlias'
    readonly id: ID['output']
    readonly emailAddress: EmailAddress['output']
    readonly isVerified: Boolean['output']
    readonly verificationCodeExpiresAt: DateTime['output']
  }
}

export type RemovePayAppRequirementMutationVariables = Exact<{
  input: RemovePayAppRequirementInput
}>

export type RemovePayAppRequirementMutation = {
  readonly __typename: 'Mutation'
  readonly removePayAppRequirement: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type UpdatePrimaryLienWaiverTemplatesMutationVariables = Exact<{
  input: UpdateLienWaiverTemplatesInput
}>

export type UpdatePrimaryLienWaiverTemplatesMutation = {
  readonly __typename: 'Mutation'
  readonly updatePrimaryLienWaiverTemplates: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type UpdateVendorLienWaiverTemplatesMutationVariables = Exact<{
  input: UpdateLienWaiverTemplatesInput
}>

export type UpdateVendorLienWaiverTemplatesMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorLienWaiverTemplates: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly onboardedProjectVendorsStatus: {
      readonly __typename: 'OnboardedProjectVendorsStatus'
      readonly addedVendorLienWaivers: Boolean['output']
      readonly addedLegalRequirements: Boolean['output']
      readonly addedVendors: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
  }
}

export type UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationVariables = Exact<{
  input: UpdateChangeOrderRequestLinkedChangeOrderRequestsInput
}>

export type UpdateChangeOrderRequestLinkedChangeOrderRequestsMutation = {
  readonly __typename: 'Mutation'
  readonly updateChangeOrderRequestLinkedChangeOrderRequests: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly name: String['output']
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
  }
}

export type SetIncludeChangeOrderLogIncludedInPayAppPackageMutationVariables = Exact<{
  input: SetIncludeChangeOrderLogInPayAppPackageInput
}>

export type SetIncludeChangeOrderLogIncludedInPayAppPackageMutation = {
  readonly __typename: 'Mutation'
  readonly setIncludeChangeOrderLogIncludedInPayAppPackage: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
  }
}

export type ChangeOrderRequestActivityQueryVariables = Exact<{
  changeOrderRequestId: Scalars['ID']['input']
}>

export type ChangeOrderRequestActivityQuery = {
  readonly __typename: 'Query'
  readonly changeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly events: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: ChangeOrderRequestEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestComment'
      readonly id: ID['output']
      readonly message: String['output'] | null
      readonly createdAt: DateTime['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly isSitelineTeamMember: Boolean['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
  }
}

export type NewChangeOrderRequestEventSubscriptionVariables = Exact<{
  changeOrderRequestId: Scalars['ID']['input']
}>

export type NewChangeOrderRequestEventSubscription = {
  readonly __typename: 'Subscription'
  readonly newChangeOrderRequestEvent: {
    readonly __typename: 'ChangeOrderRequest'
    readonly id: ID['output']
    readonly events: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly type: ChangeOrderRequestEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly comments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestComment'
      readonly id: ID['output']
      readonly message: String['output'] | null
      readonly createdAt: DateTime['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly isSitelineTeamMember: Boolean['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
  }
}

export type NewChangeOrderRequestCommentSubscriptionVariables = Exact<{
  changeOrderRequestId: Scalars['ID']['input']
}>

export type NewChangeOrderRequestCommentSubscription = {
  readonly __typename: 'Subscription'
  readonly newChangeOrderRequestComment: {
    readonly __typename: 'ChangeOrderRequestComment'
    readonly id: ID['output']
    readonly message: String['output'] | null
    readonly createdAt: DateTime['output']
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly isSitelineTeamMember: Boolean['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type AddChangeOrderRequestCommentMutationVariables = Exact<{
  input: AddChangeOrderRequestCommentInput
}>

export type AddChangeOrderRequestCommentMutation = {
  readonly __typename: 'Mutation'
  readonly addChangeOrderRequestComment: {
    readonly __typename: 'ChangeOrderRequestComment'
    readonly id: ID['output']
    readonly message: String['output'] | null
    readonly createdAt: DateTime['output']
    readonly changeOrderRequest: {
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
    }
    readonly user: {
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly isSitelineTeamMember: Boolean['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }
  }
}

export type GetContractsForVendorsQueryVariables = Exact<{
  input: GetContractsInput
}>

export type GetContractsForVendorsQuery = {
  readonly __typename: 'Query'
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly status: ContractStatus
    readonly internalProjectNumber: String['output'] | null
    readonly vendorComplianceStatus: VendorComplianceStatus | null
    readonly vendorNumPendingLegalDocuments: Int['output']
    readonly vendorNumPendingLienWaivers: Int['output']
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly shortName: String['output']
      readonly longName: String['output']
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly onboardedProjectVendorsStatus: {
      readonly __typename: 'OnboardedProjectVendorsStatus'
      readonly addedVendorLienWaivers: Boolean['output']
      readonly addedLegalRequirements: Boolean['output']
      readonly addedVendors: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
  }>
}

export type CopyContractVendorsMutationVariables = Exact<{
  input: CopyContractVendorsInput
}>

export type CopyContractVendorsMutation = {
  readonly __typename: 'Mutation'
  readonly copyContractVendors: { readonly __typename: 'Contract'; readonly id: ID['output'] }
}

export type GetContractsForLienWaiverTrackerQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type GetContractsForLienWaiverTrackerQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly lowerTierConditionalAmountWaived: SafeInt['output']
      readonly lowerTierUnconditionalAmountWaived: SafeInt['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
      readonly onboardedProjectVendorsStatus: {
        readonly __typename: 'OnboardedProjectVendorsStatus'
        readonly completedOnboarding: Boolean['output']
      }
    }>
  }
}

export type PaginatedVendorContractsQueryVariables = Exact<{
  input: PaginatedVendorContractsInput
}>

export type PaginatedVendorContractsQuery = {
  readonly __typename: 'Query'
  readonly paginatedVendorContracts: {
    readonly __typename: 'PaginatedVendorContractsList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly conditionalAmountWaived: SafeInt['output']
      readonly unconditionalAmountWaived: SafeInt['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly companyName: String['output'] | null
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
      }
    }>
  }
}

export type VendorForVendorContractsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type VendorForVendorContractsQuery = {
  readonly __typename: 'Query'
  readonly vendor: {
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly conditionalAmountWaived: SafeInt['output']
      readonly unconditionalAmountWaived: SafeInt['output']
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly companyName: String['output'] | null
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
      }
    }>
  }
}

export type LienWaiversMonthSummaryQueryVariables = Exact<{
  input: LienWaiversMonthSummaryInput
}>

export type LienWaiversMonthSummaryQuery = {
  readonly __typename: 'Query'
  readonly lienWaiversMonthSummary: {
    readonly __typename: 'LienWaiversMonthSummary'
    readonly numPendingLienWaivers: Int['output']
    readonly numLienWaivers: Int['output']
    readonly aggregateStatus: AggregateLienWaiverStatus
    readonly isMonthSkipped: Boolean['output']
  }
}

export type LienWaiversMonthQueryVariables = Exact<{
  input: LienWaiversMonthInput
}>

export type LienWaiversMonthQuery = {
  readonly __typename: 'Query'
  readonly lienWaiversMonth: {
    readonly __typename: 'LienWaiversMonth'
    readonly hasCollectedFinalLienWaiver: Boolean['output']
    readonly lienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly lastSendTime: DateTime['output'] | null
      readonly timeZone: String['output']
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
      }
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
      }>
    } | null
  }
}

export type VendorContractCommitmentsQueryVariables = Exact<{
  input: VendorContractCommitmentsInput
}>

export type VendorContractCommitmentsQuery = {
  readonly __typename: 'Query'
  readonly vendorContractCommitments: ReadonlyArray<{
    readonly __typename: 'VendorContractCommitment'
    readonly id: ID['output']
    readonly commitmentType: VendorContractCommitmentType
    readonly amount: SafeInt['output']
    readonly date: DateTime['output']
    readonly number: String['output']
    readonly payWhenPaid: Boolean['output']
    readonly paymentTerms: SafeInt['output'] | null
    readonly closedAt: DateTime['output'] | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly payAppSwornStatements: ReadonlyArray<{
        readonly __typename: 'PayAppSwornStatementVendor'
        readonly id: ID['output']
        readonly totalCommitmentAmount: SafeInt['output']
      }>
    }
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }>
}

export type CreateVendorContractCommitmentMutationVariables = Exact<{
  input: CreateVendorContractCommitmentInput
}>

export type CreateVendorContractCommitmentMutation = {
  readonly __typename: 'Mutation'
  readonly createVendorContractCommitment: {
    readonly __typename: 'VendorContractCommitment'
    readonly id: ID['output']
    readonly commitmentType: VendorContractCommitmentType
    readonly amount: SafeInt['output']
    readonly date: DateTime['output']
    readonly number: String['output']
    readonly payWhenPaid: Boolean['output']
    readonly paymentTerms: SafeInt['output'] | null
    readonly closedAt: DateTime['output'] | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly includeInSwornStatements: Boolean['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly payAppSwornStatements: ReadonlyArray<{
        readonly __typename: 'PayAppSwornStatementVendor'
        readonly id: ID['output']
        readonly totalCommitmentAmount: SafeInt['output']
      }>
      readonly commitments: ReadonlyArray<{
        readonly __typename: 'VendorContractCommitment'
        readonly id: ID['output']
      }>
    }
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type UpdateVendorContractCommitmentMutationVariables = Exact<{
  input: UpdateVendorContractCommitmentInput
}>

export type UpdateVendorContractCommitmentMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorContractCommitment: {
    readonly __typename: 'VendorContractCommitment'
    readonly id: ID['output']
    readonly commitmentType: VendorContractCommitmentType
    readonly amount: SafeInt['output']
    readonly date: DateTime['output']
    readonly number: String['output']
    readonly payWhenPaid: Boolean['output']
    readonly paymentTerms: SafeInt['output'] | null
    readonly closedAt: DateTime['output'] | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly includeInSwornStatements: Boolean['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly payAppSwornStatements: ReadonlyArray<{
        readonly __typename: 'PayAppSwornStatementVendor'
        readonly id: ID['output']
        readonly totalCommitmentAmount: SafeInt['output']
      }>
    }
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type UpdateVendorContractCommitmentStatusMutationVariables = Exact<{
  input: UpdateVendorContractCommitmentStatusInput
}>

export type UpdateVendorContractCommitmentStatusMutation = {
  readonly __typename: 'Mutation'
  readonly updateVendorContractCommitmentStatus: {
    readonly __typename: 'VendorContractCommitment'
    readonly id: ID['output']
    readonly commitmentType: VendorContractCommitmentType
    readonly amount: SafeInt['output']
    readonly date: DateTime['output']
    readonly number: String['output']
    readonly payWhenPaid: Boolean['output']
    readonly paymentTerms: SafeInt['output'] | null
    readonly closedAt: DateTime['output'] | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
      }
      readonly payAppSwornStatements: ReadonlyArray<{
        readonly __typename: 'PayAppSwornStatementVendor'
        readonly id: ID['output']
        readonly totalCommitmentAmount: SafeInt['output']
      }>
    }
    readonly file: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
  }
}

export type DeleteVendorContractCommitmentMutationVariables = Exact<{
  input: DeleteVendorContractCommitmentInput
}>

export type DeleteVendorContractCommitmentMutation = {
  readonly __typename: 'Mutation'
  readonly deleteVendorContractCommitment: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type GetIntegrationVendorInvoicesQueryVariables = Exact<{
  input: GetIntegrationVendorInvoicesInput
}>

export type GetIntegrationVendorInvoicesQuery = {
  readonly __typename: 'Query'
  readonly integrationVendorInvoices: ReadonlyArray<{
    readonly __typename: 'IntegrationVendorInvoice'
    readonly integrationInvoiceId: String['output']
    readonly integrationVendorId: String['output']
    readonly integrationVendorCode: String['output']
    readonly sitelineVendorId: ID['output'] | null
    readonly vendorName: String['output']
    readonly amount: Float['output']
    readonly retentionAmount: Float['output']
    readonly code: String['output']
    readonly description: String['output']
    readonly invoiceDate: String['output']
    readonly paymentDate: String['output'] | null
    readonly amountPaid: Float['output']
    readonly isPaid: Boolean['output']
  }>
}

export type AddIntegrationVendorsToContractMutationVariables = Exact<{
  input: AddIntegrationVendorsToContractInput
}>

export type AddIntegrationVendorsToContractMutation = {
  readonly __typename: 'Mutation'
  readonly addIntegrationVendorsToContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly includeInSwornStatements: Boolean['output']
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
      }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
          readonly generalContractor: {
            readonly __typename: 'CompanyProjectMetadata'
            readonly company: {
              readonly __typename: 'Company'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        }
      }
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
  }
}

export type AddIntegrationVendorsToSwornStatementContractMutationVariables = Exact<{
  input: AddIntegrationVendorsToContractInput
}>

export type AddIntegrationVendorsToSwornStatementContractMutation = {
  readonly __typename: 'Mutation'
  readonly addIntegrationVendorsToContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly includeInSwornStatements: Boolean['output']
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
      }
      readonly payAppSwornStatements: ReadonlyArray<{
        readonly __typename: 'PayAppSwornStatementVendor'
        readonly id: ID['output']
        readonly totalCommitmentAmount: SafeInt['output']
        readonly amountPaidToDate: SafeInt['output'] | null
        readonly amountOutstandingToDate: SafeInt['output'] | null
        readonly lastUpdate: {
          readonly __typename: 'PayAppSwornStatementVendorUpdate'
          readonly id: ID['output']
          readonly updatedAt: DateTime['output']
          readonly updatedFromIntegrationType: IntegrationType | null
          readonly integrationInvoicesThroughDate: DateTime['output'] | null
          readonly manuallyUpdatedBy: {
            readonly __typename: 'User'
            readonly id: ID['output']
          } | null
        } | null
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
  }
}

export type CompanyUserForProjectListFiltersQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type CompanyUserForProjectListFiltersQuery = {
  readonly __typename: 'Query'
  readonly currentCompanyUser: {
    readonly __typename: 'CompanyUser'
    readonly id: ID['output']
    readonly role: CompanyUserRole
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
      }>
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
    }
  }
}

export type GetPayAppForSwornStatementQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPayAppForSwornStatementQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly billingEnd: DateTime['output']
    readonly timeZone: String['output']
    readonly status: PayAppStatus
    readonly swornStatementVendors: ReadonlyArray<{
      readonly __typename: 'PayAppSwornStatementVendor'
      readonly id: ID['output']
      readonly totalCommitmentAmount: SafeInt['output']
      readonly amountPaidToDate: SafeInt['output'] | null
      readonly amountOutstandingToDate: SafeInt['output'] | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly commitments: ReadonlyArray<{
          readonly __typename: 'VendorContractCommitment'
          readonly id: ID['output']
          readonly commitmentType: VendorContractCommitmentType
          readonly amount: SafeInt['output']
          readonly date: DateTime['output']
          readonly number: String['output']
          readonly payWhenPaid: Boolean['output']
          readonly paymentTerms: SafeInt['output'] | null
          readonly closedAt: DateTime['output'] | null
          readonly vendorContract: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
            readonly payAppSwornStatements: ReadonlyArray<{
              readonly __typename: 'PayAppSwornStatementVendor'
              readonly id: ID['output']
              readonly totalCommitmentAmount: SafeInt['output']
            }>
          }
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          } | null
        }>
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly lastUpdate: {
        readonly __typename: 'PayAppSwornStatementVendorUpdate'
        readonly id: ID['output']
        readonly updatedAt: DateTime['output']
        readonly updatedFromIntegrationType: IntegrationType | null
        readonly integrationInvoicesThroughDate: DateTime['output'] | null
        readonly manuallyUpdatedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
        } | null
      } | null
    }> | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly vendorContracts: ReadonlyArray<{
        readonly __typename: 'VendorContract'
        readonly includeInSwornStatements: Boolean['output']
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }>
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly metadata: JSONObject['output']
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly isActive: Boolean['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly metadata: JSONObject['output']
        }
      }>
    }
  }
}

export type UpdatePayAppSwornStatementVendorsMutationVariables = Exact<{
  input: UpdatePayAppSwornStatementVendorsInput
}>

export type UpdatePayAppSwornStatementVendorsMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppSwornStatementVendors: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly swornStatementVendors: ReadonlyArray<{
      readonly __typename: 'PayAppSwornStatementVendor'
      readonly id: ID['output']
      readonly totalCommitmentAmount: SafeInt['output']
      readonly amountPaidToDate: SafeInt['output'] | null
      readonly amountOutstandingToDate: SafeInt['output'] | null
      readonly lastUpdate: {
        readonly __typename: 'PayAppSwornStatementVendorUpdate'
        readonly id: ID['output']
        readonly updatedAt: DateTime['output']
        readonly updatedFromIntegrationType: IntegrationType | null
        readonly integrationInvoicesThroughDate: DateTime['output'] | null
        readonly manuallyUpdatedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
    }> | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly vendorContracts: ReadonlyArray<{
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly includeInSwornStatements: Boolean['output']
      }>
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly swornStatementVendors: ReadonlyArray<{
          readonly __typename: 'PayAppSwornStatementVendor'
          readonly id: ID['output']
          readonly totalCommitmentAmount: SafeInt['output']
          readonly amountPaidToDate: SafeInt['output'] | null
          readonly amountOutstandingToDate: SafeInt['output'] | null
          readonly lastUpdate: {
            readonly __typename: 'PayAppSwornStatementVendorUpdate'
            readonly id: ID['output']
            readonly updatedAt: DateTime['output']
            readonly updatedFromIntegrationType: IntegrationType | null
            readonly integrationInvoicesThroughDate: DateTime['output'] | null
            readonly manuallyUpdatedBy: {
              readonly __typename: 'User'
              readonly id: ID['output']
            } | null
          } | null
          readonly vendorContract: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          }
        }> | null
      }>
    }
  }
}

export type DeletePayAppSwornStatementVendorMutationVariables = Exact<{
  input: DeletePayAppSwornStatementVendorInput
}>

export type DeletePayAppSwornStatementVendorMutation = {
  readonly __typename: 'Mutation'
  readonly deletePayAppSwornStatementVendor: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CreateWriteSyncOperationMutationVariables = Exact<{
  input: CreateWriteSyncOperationInput
}>

export type CreateWriteSyncOperationMutation = {
  readonly __typename: 'Mutation'
  readonly createWriteSyncOperation: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly status: WriteSyncOperationStatus
  }
}

export type WriteSyncOperationQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type WriteSyncOperationQuery = {
  readonly __typename: 'Query'
  readonly writeSyncOperation: {
    readonly __typename: 'WriteSyncOperation'
    readonly id: ID['output']
    readonly status: WriteSyncOperationStatus
    readonly result: JSONObject['output'] | null
    readonly payApp: {
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly retentionOnly: Boolean['output']
      readonly status: PayAppStatus
      readonly payAppNumber: SafeInt['output']
      readonly billingType: BillingType
    } | null
    readonly legalRequirement: {
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
    }>
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly integration: {
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }
  }
}

export type SendSigninLinkAfterInviteMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type SendSigninLinkAfterInviteMutation = {
  readonly __typename: 'Mutation'
  readonly sendSigninLinkAfterInvite: Boolean['output']
}

export type SendPasswordResetLinkEmailMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type SendPasswordResetLinkEmailMutation = {
  readonly __typename: 'Mutation'
  readonly sendPasswordResetLinkEmail: Boolean['output']
}

export type AuthProviderQueryVariables = Exact<{
  input: GetAuthProviderInput
}>

export type AuthProviderQuery = {
  readonly __typename: 'Query'
  readonly authProvider: {
    readonly __typename: 'AuthProvider'
    readonly type: AuthProviderType
    readonly samlProviderId: String['output'] | null
  }
}

export type UpdateUserAfterInviteMutationVariables = Exact<{
  id: Scalars['ID']['input']
  user: UpdateUserInput
}>

export type UpdateUserAfterInviteMutation = {
  readonly __typename: 'Mutation'
  readonly updateUser: {
    readonly __typename: 'User'
    readonly tutorialsShown: ReadonlyArray<Tutorial>
    readonly id: ID['output']
    readonly firstName: String['output']
    readonly lastName: String['output']
    readonly jobTitle: String['output'] | null
    readonly email: EmailAddress['output']
    readonly policiesAcceptedAt: DateTime['output'] | null
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
      readonly nickname: String['output'] | null
      readonly type: CompanyType
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
      readonly agingIntervalType: AgingIntervalType
      readonly defaultRetentionPercent: Float['output'] | null
      readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
      readonly defaultDaysBeforePayAppDue: Int['output'] | null
      readonly enableBillingWorksheets: Boolean['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly archivedAt: DateTime['output'] | null
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly metadata: JSONObject['output']
      }>
      readonly picture: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
      readonly locations: ReadonlyArray<{
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }>
    }>
    readonly defaultSignature: {
      readonly __typename: 'Signature'
      readonly id: ID['output']
      readonly signatureName: String['output'] | null
      readonly signatureFont: SignatureFont | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
      } | null
    } | null
  }
}

export type ZendeskSsoJwtQueryVariables = Exact<{ [key: string]: never }>

export type ZendeskSsoJwtQuery = {
  readonly __typename: 'Query'
  readonly zendeskSsoJwt: String['output']
}

export type GetProjectNameQueryVariables = Exact<{
  projectId: Scalars['ID']['input']
}>

export type GetProjectNameQuery = {
  readonly __typename: 'Query'
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
  }
}

export type CreatePayAppMutationVariables = Exact<{
  input: CreatePayAppInput
}>

export type CreatePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly createPayApp: {
    readonly __typename: 'PayApp'
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type ContractsForBulkOverUnderQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsForBulkOverUnderQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
    }>
  }
}

export type BulkUpdateContractCostsMutationVariables = Exact<{
  input: BulkUpdateContractCostsInput
}>

export type BulkUpdateContractCostsMutation = {
  readonly __typename: 'Mutation'
  readonly bulkUpdateContractCosts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly cost: {
      readonly __typename: 'ContractCost'
      readonly id: ID['output']
      readonly updatedAt: DateTime['output']
      readonly updatedFromIntegrationType: IntegrationType | null
      readonly costToDate: SafeInt['output']
      readonly totalEstimatedCost: SafeInt['output']
      readonly additionalCommittedCost: SafeInt['output']
      readonly costPercentageCompleted: Float['output']
      readonly earnedRevenue: SafeInt['output']
      readonly contractTotalValue: SafeInt['output']
      readonly contractTotalBilled: SafeInt['output']
      readonly billingsInExcessOfCost: SafeInt['output']
      readonly manuallyUpdatedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    } | null
  }>
}

export type GetContractForPayAppQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForPayAppQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly status: ContractStatus
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
    readonly roundRetention: Boolean['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly taxCalculationType: TaxCalculationType
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
    readonly hasSwornStatementForms: Boolean['output']
    readonly timeZone: String['output']
    readonly internalNotes: String['output'] | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly allowLumpSumOverbilling: Boolean['output']
      readonly taxGroups: ReadonlyArray<{
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      }>
    }
    readonly defaultInvoiceReviewContacts: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
    }>
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly preSitelineRetention: SafeInt['output']
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }>
    } | null
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly groups: ReadonlyArray<{
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }>
      readonly items: ReadonlyArray<{
        readonly __typename: 'RateTableItem'
        readonly id: ID['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly description: String['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly group: {
          readonly __typename: 'RateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly type: LineItemType
        }
      }>
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ContractRateTableFee'
      readonly id: ID['output']
      readonly description: String['output']
      readonly percent: Float['output']
      readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
      readonly overridesFee: {
        readonly __typename: 'ContractRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
    }>
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingStart: DateTime['output']
      readonly billingEnd: DateTime['output']
      readonly billingType: BillingType
      readonly payAppDueDate: DateTime['output']
      readonly retentionOnly: Boolean['output']
      readonly status: PayAppStatus
      readonly payAppNumber: SafeInt['output']
      readonly canGenerateForms: Boolean['output']
      readonly hasContractRateTableChanged: Boolean['output']
      readonly haveContractFeesChanged: Boolean['output']
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type GetContractForTopNavQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForTopNavQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly timeZone: String['output']
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly payAppNumber: SafeInt['output']
      readonly billingEnd: DateTime['output']
      readonly retentionOnly: Boolean['output']
      readonly status: PayAppStatus
    }>
  }
}

export type AddLegalDocumentsToPayAppMutationVariables = Exact<{
  input: AddLegalDocumentsToPayAppInput
}>

export type AddLegalDocumentsToPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly addLegalDocumentsToPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type AddVendorLienWaiversToPayAppMutationVariables = Exact<{
  input: AddVendorLienWaiversToPayAppInput
}>

export type AddVendorLienWaiversToPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly addVendorLienWaiversToPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly date: DateTime['output']
      readonly claimant: String['output']
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
      } | null
    }>
  }
}

export type RemoveLegalDocumentFromPayAppMutationVariables = Exact<{
  input: RemoveLegalDocumentFromPayAppInput
}>

export type RemoveLegalDocumentFromPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly removeLegalDocumentFromPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
  }
}

export type GetPayAppForBackupQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppForBackupQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly retentionOnly: Boolean['output']
    readonly status: PayAppStatus
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly billingType: BillingType
    readonly payAppNumber: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly progressBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly sortOrder: SafeInt['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly isChangeOrder: Boolean['output']
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
      }
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
      readonly description: String['output'] | null
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly uploadId: ID['output']
      readonly isEmailAttachment: Boolean['output']
      readonly isInternalOnly: Boolean['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
      readonly sovLineItemProgress: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly storedMaterialBilled: SafeInt['output']
        readonly progressBilled: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly retentionHeldPercent: Float['output']
        readonly sovLineItem: {
          readonly __typename: 'SovLineItem'
          readonly id: ID['output']
          readonly sortOrder: SafeInt['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly isChangeOrder: Boolean['output']
          readonly sovLineItemGroup: {
            readonly __typename: 'SovLineItemGroup'
            readonly id: ID['output']
            readonly name: String['output']
            readonly code: String['output'] | null
          } | null
        }
      } | null
      readonly contractPayAppAttachment: {
        readonly __typename: 'ContractPayAppAttachment'
        readonly id: ID['output']
      } | null
      readonly rateTableItems: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableItem'
        readonly id: ID['output']
        readonly description: String['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly currentBilled: SafeInt['output']
        readonly currentUnitsBilled: Float['output']
        readonly isOneOffItem: Boolean['output']
        readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
        readonly attachments: ReadonlyArray<{
          readonly __typename: 'PayAppAttachment'
          readonly id: ID['output']
        }>
      }>
    }>
    readonly legalDocuments: ReadonlyArray<{
      readonly __typename: 'LegalDocument'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly name: String['output']
      readonly periodStart: DateTime['output'] | null
      readonly periodEnd: DateTime['output'] | null
      readonly legalRequirement: {
        readonly __typename: 'LegalRequirement'
        readonly id: ID['output']
        readonly name: String['output']
        readonly updatedAt: DateTime['output']
        readonly expirationFrequency: DocumentExpirationFrequency
        readonly startDate: DateTime['output'] | null
        readonly endDate: DateTime['output'] | null
        readonly skippedPeriods: ReadonlyArray<String['output']>
        readonly isVendorRequirement: Boolean['output']
        readonly type: DocumentType
        readonly timeZone: String['output']
      }
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
    }>
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly date: DateTime['output']
      readonly claimant: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly taxGroups: ReadonlyArray<{
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        }>
      }
    }
  }
}

export type RemoveVendorLienWaiverFromPayAppMutationVariables = Exact<{
  input: RemoveVendorLienWaiverFromPayAppInput
}>

export type RemoveVendorLienWaiverFromPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly removeVendorLienWaiverFromPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
    }>
  }
}

export type GetContractForAddChangeOrderRequestToSovQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForAddChangeOrderRequestToSovQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly preSitelineRetention: SafeInt['output']
    readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
    readonly roundRetention: Boolean['output']
    readonly timeZone: String['output']
    readonly taxCalculationType: TaxCalculationType
    readonly defaultTaxGroup: { readonly __typename: 'TaxGroup'; readonly id: ID['output'] } | null
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly defaultRetentionPercent: Float['output'] | null
      readonly taxGroups: ReadonlyArray<{
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      }>
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalRetention: SafeInt['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly preSitelineRetention: SafeInt['output']
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }>
    } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly retentionOnly: Boolean['output']
      readonly status: PayAppStatus
      readonly billingType: BillingType
      readonly payAppNumber: SafeInt['output']
      readonly billingStart: DateTime['output']
      readonly billingEnd: DateTime['output']
      readonly payAppDueDate: DateTime['output']
      readonly progress: ReadonlyArray<{
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly progressRetentionHeldPercent: Float['output'] | null
        readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
      }>
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
}

export type ChangeOrderRequestForSigningQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ChangeOrderRequestForSigningQuery = {
  readonly __typename: 'Query'
  readonly changeOrderRequest: {
    readonly __typename: 'ChangeOrderRequest'
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly name: String['output']
    readonly internalNumber: String['output'] | null
    readonly generalContractorNumber: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly proposedAmount: SafeInt['output'] | null
    readonly unitPrice: Decimal['output'] | null
    readonly unitName: String['output'] | null
    readonly proposedAt: DateTime['output'] | null
    readonly scheduleImpact: Float['output'] | null
    readonly proceedingWithWork: Boolean['output']
    readonly status: ChangeOrderRequestStatus
    readonly statusChangedAt: DateTime['output']
    readonly reason: String['output'] | null
    readonly importedAt: DateTime['output'] | null
    readonly visibleToGc: Boolean['output']
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequestAttachment'
      readonly id: ID['output']
      readonly isInternalOnly: Boolean['output']
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
    }
    readonly linkedChangeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
      readonly internalNumber: String['output'] | null
      readonly generalContractorNumber: String['output'] | null
      readonly status: ChangeOrderRequestStatus
      readonly amount: SafeInt['output'] | null
      readonly name: String['output']
      readonly sovLineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
      }>
    }>
    readonly sovLineItems: ReadonlyArray<{
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
    }>
    readonly approvedScoFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
    } | null
  }
}

export type SignPayAppMutationVariables = Exact<{
  input: SignPayAppInput
}>

export type SignPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly signPayApp: {
    readonly __typename: 'PayApp'
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type GetContractForChangeOrderRequestReviewQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForChangeOrderRequestReviewQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly defaultChangeOrderRequestReviewContacts: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
}

export type SovForChangeOrdersQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type SovForChangeOrdersQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly isChangeOrder: Boolean['output']
        readonly latestTotalValue: SafeInt['output']
      }>
    } | null
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly groups: ReadonlyArray<{
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }>
      readonly items: ReadonlyArray<{
        readonly __typename: 'RateTableItem'
        readonly id: ID['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly description: String['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly group: {
          readonly __typename: 'RateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly type: LineItemType
        }
      }>
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
      }>
    } | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }>
  }
}

export type GetContractForProjectHomeQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForProjectHomeQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
    readonly hasSwornStatementForms: Boolean['output']
    readonly firstPayAppBillingEnd: DateTime['output'] | null
    readonly earliestPayAppBillingEnd: DateTime['output'] | null
    readonly hasStartedBilling: Boolean['output']
    readonly progressRemaining: SafeInt['output']
    readonly averageDaysToPaid: Float['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly preSitelineRetention: SafeInt['output']
    readonly taxCalculationType: TaxCalculationType
    readonly contractDate: DateTime['output'] | null
    readonly contractNumber: String['output'] | null
    readonly appliesTaxToRetention: Boolean['output']
    readonly timeZone: String['output']
    readonly percentComplete: Float['output'] | null
    readonly internalNotes: String['output'] | null
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
    }>
    readonly defaultTaxGroup: {
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    } | null
    readonly optedOutMonths: ReadonlyArray<{
      readonly __typename: 'ContractOptedOutMonth'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly month: String['output']
      readonly reason: String['output'] | null
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    }>
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly metadata: {
        readonly __typename: 'CompanyMetadata'
        readonly monthlyCreatePayAppReminderDate: Int['output'] | null
      }
      readonly taxGroups: ReadonlyArray<{
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      }>
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly preSitelineRetention: SafeInt['output']
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }>
    } | null
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly groups: ReadonlyArray<{
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }>
      readonly items: ReadonlyArray<{
        readonly __typename: 'RateTableItem'
        readonly id: ID['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly description: String['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly group: {
          readonly __typename: 'RateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly type: LineItemType
        }
      }>
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ContractRateTableFee'
      readonly id: ID['output']
      readonly description: String['output']
      readonly percent: Float['output']
      readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
      readonly overridesFee: {
        readonly __typename: 'ContractRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
    }>
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
    } | null
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly primaryLienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly type: LienWaiverType
    }>
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly billingForecast: {
      readonly __typename: 'ContractBillingForecast'
      readonly id: ID['output']
      readonly startingMonth: DateTime['output']
      readonly firstEditableMonthWhenCreated: DateTime['output']
      readonly projectedAmountPerMonth: ReadonlyArray<SafeInt['output']>
      readonly remainingAmount: SafeInt['output']
      readonly curve: ForecastCurve
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    } | null
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly billingEnd: DateTime['output']
      readonly billingStart: DateTime['output']
      readonly billingType: BillingType
      readonly payAppDueDate: DateTime['output']
      readonly statusChangedAt: DateTime['output']
      readonly retentionOnly: Boolean['output']
      readonly status: PayAppStatus
      readonly internalDueDate: DateTime['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly integrationPaidAt: DateTime['output'] | null
      readonly amountPaid: SafeInt['output'] | null
      readonly isBalanceManuallyClosed: Boolean['output']
      readonly invoiceReady: Boolean['output']
      readonly payAppNumber: SafeInt['output']
      readonly draftSubmittedAt: DateTime['output'] | null
      readonly balanceToFinish: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly hasContractRateTableChanged: Boolean['output']
      readonly haveContractFeesChanged: Boolean['output']
      readonly lastSubmitted: {
        readonly __typename: 'PayAppStatusLog'
        readonly id: ID['output']
        readonly status: PayAppStatus
        readonly statusUpdatedAt: DateTime['output']
      } | null
      readonly revertToDraftEvents: ReadonlyArray<{
        readonly __typename: 'PayAppEvent'
        readonly id: ID['output']
      }>
      readonly markedAsPaidIntegration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly shortName: String['output']
        readonly longName: String['output']
      } | null
      readonly lastSync: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly lienWaivers: ReadonlyArray<{
        readonly __typename: 'LienWaiver'
        readonly id: ID['output']
        readonly type: LienWaiverType
        readonly lastSendTime: DateTime['output'] | null
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly timeZone: String['output']
      readonly retentionOnly: Boolean['output']
      readonly payAppNumber: Int['output']
      readonly billingStart: DateTime['output'] | null
      readonly billingEnd: DateTime['output'] | null
      readonly status: PreSitelinePayAppStatus | null
      readonly currentBilled: SafeInt['output'] | null
      readonly paymentDue: SafeInt['output'] | null
      readonly percentComplete: Float['output']
      readonly isFinal: Boolean['output']
      readonly paidAt: DateTime['output'] | null
      readonly amountPaid: SafeInt['output'] | null
      readonly isBalanceManuallyClosed: Boolean['output']
      readonly submittedUnconditionalOutsideOfSiteline: Boolean['output']
      readonly submittedAt: DateTime['output'] | null
      readonly isNotarizingUnconditional: Boolean['output']
      readonly payAppFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      } | null
      readonly unconditionalLienWaiver: {
        readonly __typename: 'LienWaiver'
        readonly id: ID['output']
      } | null
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly averageDaysToPaid: Float['output']
      }
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly role: ContractUserRole
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly files: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly architect: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly metadata: {
        readonly __typename: 'ProjectMetadata'
        readonly payAppDueOnDayOfMonth: Int['output']
      }
    }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type UpdateContractForInternalNotesMutationVariables = Exact<{
  input: UpdateContractInput
}>

export type UpdateContractForInternalNotesMutation = {
  readonly __typename: 'Mutation'
  readonly updateContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalNotes: String['output'] | null
    readonly vendorsInternalNotes: String['output'] | null
  }
}

export type ContractsForRecentlyViewedProjectsQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsForRecentlyViewedProjectsQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly billingType: BillingType
      }>
    }>
  }
}

export type ContractForCostQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForCostQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly cost: {
      readonly __typename: 'ContractCost'
      readonly id: ID['output']
      readonly updatedAt: DateTime['output']
      readonly updatedFromIntegrationType: IntegrationType | null
      readonly costToDate: SafeInt['output']
      readonly totalEstimatedCost: SafeInt['output']
      readonly additionalCommittedCost: SafeInt['output']
      readonly costPercentageCompleted: Float['output']
      readonly earnedRevenue: SafeInt['output']
      readonly contractTotalValue: SafeInt['output']
      readonly contractTotalBilled: SafeInt['output']
      readonly billingsInExcessOfCost: SafeInt['output']
      readonly manuallyUpdatedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type UpdateContractCostFromIntegrationMutationVariables = Exact<{
  input: UpdateContractCostFromIntegrationInput
}>

export type UpdateContractCostFromIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractCostFromIntegration: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly cost: {
      readonly __typename: 'ContractCost'
      readonly id: ID['output']
      readonly updatedAt: DateTime['output']
      readonly updatedFromIntegrationType: IntegrationType | null
      readonly costToDate: SafeInt['output']
      readonly totalEstimatedCost: SafeInt['output']
      readonly additionalCommittedCost: SafeInt['output']
      readonly costPercentageCompleted: Float['output']
      readonly earnedRevenue: SafeInt['output']
      readonly contractTotalValue: SafeInt['output']
      readonly contractTotalBilled: SafeInt['output']
      readonly billingsInExcessOfCost: SafeInt['output']
      readonly manuallyUpdatedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    } | null
  }
}

export type ClearContractCostMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ClearContractCostMutation = {
  readonly __typename: 'Mutation'
  readonly clearContractCost: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly cost: {
      readonly __typename: 'ContractCost'
      readonly id: ID['output']
      readonly updatedAt: DateTime['output']
      readonly updatedFromIntegrationType: IntegrationType | null
      readonly costToDate: SafeInt['output']
      readonly totalEstimatedCost: SafeInt['output']
      readonly additionalCommittedCost: SafeInt['output']
      readonly costPercentageCompleted: Float['output']
      readonly earnedRevenue: SafeInt['output']
      readonly contractTotalValue: SafeInt['output']
      readonly contractTotalBilled: SafeInt['output']
      readonly billingsInExcessOfCost: SafeInt['output']
      readonly manuallyUpdatedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    } | null
  }
}

export type GetPayAppG702PreviewQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPayAppG702PreviewQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly g702Values: {
      readonly __typename: 'G702Values'
      readonly originalContractSum: SafeInt['output']
      readonly netChangeByChangeOrders: SafeInt['output']
      readonly contractSumToDate: SafeInt['output']
      readonly totalCompletedToDate: SafeInt['output']
      readonly progressRetentionPercent: Float['output']
      readonly progressRetentionAmount: SafeInt['output']
      readonly materialsRetentionPercent: Float['output']
      readonly materialsRetentionAmount: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly totalLessRetainage: SafeInt['output']
      readonly previousPayments: SafeInt['output']
      readonly currentPaymentDue: SafeInt['output']
      readonly balanceToFinish: SafeInt['output'] | null
      readonly balanceToFinishWithRetention: SafeInt['output'] | null
    }
  }
}

export type GetSovLineItemProgressQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetSovLineItemProgressQuery = {
  readonly __typename: 'Query'
  readonly sovLineItemProgress: {
    readonly __typename: 'SovLineItemProgress'
    readonly id: ID['output']
    readonly sovLineItem: {
      readonly __typename: 'SovLineItem'
      readonly id: ID['output']
      readonly code: String['output']
      readonly name: String['output']
    }
    readonly events: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgressEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: SovLineItemProgressEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
      }
    }>
  }
}

export type GetContractForInvoiceReviewQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type GetContractForInvoiceReviewQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly name: String['output']
    }
    readonly defaultInvoiceReviewContacts: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly email: EmailAddress['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }>
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
    }
  }
}

export type ResetPayAppToDraftMutationVariables = Exact<{
  input: ResetPayAppToDraftInput
}>

export type ResetPayAppToDraftMutation = {
  readonly __typename: 'Mutation'
  readonly resetPayAppToDraft: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly statusChangedAt: DateTime['output']
    readonly revertToDraftEvents: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
    }>
    readonly uploadedFile: { readonly __typename: 'StoredFile'; readonly id: ID['output'] } | null
    readonly lienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: { readonly __typename: 'Vendor'; readonly id: ID['output'] }
      } | null
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly lienWaiver: { readonly __typename: 'LienWaiver'; readonly id: ID['output'] }
      }>
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
  }
}

export type UpdateContractCostMutationVariables = Exact<{
  input: UpdateContractCostInput
}>

export type UpdateContractCostMutation = {
  readonly __typename: 'Mutation'
  readonly updateContractCost: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly cost: {
      readonly __typename: 'ContractCost'
      readonly id: ID['output']
      readonly updatedAt: DateTime['output']
      readonly updatedFromIntegrationType: IntegrationType | null
      readonly costToDate: SafeInt['output']
      readonly totalEstimatedCost: SafeInt['output']
      readonly additionalCommittedCost: SafeInt['output']
      readonly costPercentageCompleted: Float['output']
      readonly earnedRevenue: SafeInt['output']
      readonly contractTotalValue: SafeInt['output']
      readonly contractTotalBilled: SafeInt['output']
      readonly billingsInExcessOfCost: SafeInt['output']
      readonly manuallyUpdatedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    } | null
  }
}

export type ContractForErpCustomerNumberQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForErpCustomerNumberQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
      }>
    }
  }
}

export type ContractsForBulkExportQueryVariables = Exact<{
  input: GetPaginatedContractsInput
  months?: InputMaybe<ReadonlyArray<Scalars['DateTime']['input']> | Scalars['DateTime']['input']>
}>

export type ContractsForBulkExportQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly paymentTerms: SafeInt['output'] | null
      readonly paymentTermsType: ContractPaymentTermsType | null
      readonly billingType: BillingType
      readonly retentionTrackingLevel: RetentionTrackingLevel
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly billingEnd: DateTime['output']
        readonly timeZone: String['output']
        readonly currentBilled: SafeInt['output']
        readonly currentRetention: SafeInt['output']
        readonly previousRetentionBilled: SafeInt['output']
        readonly retentionHeldOverride: SafeInt['output'] | null
        readonly currentProgressAndRetentionBilled: SafeInt['output']
        readonly payAppNumber: SafeInt['output']
        readonly invoiceNumber: String['output'] | null
        readonly lastSubmitted: {
          readonly __typename: 'PayAppStatusLog'
          readonly id: ID['output']
          readonly status: PayAppStatus
          readonly statusUpdatedAt: DateTime['output']
        } | null
        readonly progress: ReadonlyArray<{
          readonly __typename: 'SovLineItemProgress'
          readonly id: ID['output']
          readonly currentBilled: SafeInt['output']
          readonly currentRetention: SafeInt['output']
          readonly sovLineItem: {
            readonly __typename: 'SovLineItem'
            readonly id: ID['output']
            readonly name: String['output']
            readonly sortOrder: SafeInt['output']
            readonly unitPrice: Decimal['output'] | null
          }
        }>
        readonly rateTableItems: ReadonlyArray<{
          readonly __typename: 'PayAppRateTableItem'
          readonly id: ID['output']
          readonly sortOrder: SafeInt['output']
          readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
        }>
        readonly rateTableGroups: ReadonlyArray<{
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly totalAmount: SafeInt['output']
        }>
        readonly amountLineItems: ReadonlyArray<{
          readonly __typename: 'AmountLineItem'
          readonly id: ID['output']
          readonly description: String['output']
          readonly amountDuePostTax: SafeInt['output']
          readonly sortOrder: SafeInt['output']
        }>
      }>
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly metadata: JSONObject['output']
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly isActive: Boolean['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly metadata: JSONObject['output']
        }
      }>
    }>
  }
}

export type UpdatePayAppRetentionMutationVariables = Exact<{
  input: UpdatePayAppRetentionInput
}>

export type UpdatePayAppRetentionMutation = {
  readonly __typename: 'Mutation'
  readonly updatePayAppRetention: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly retentionHeldPercent: Float['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly retentionReleased: SafeInt['output'] | null
    readonly canGenerateForms: Boolean['output']
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
    }>
  }
}

export type CompanyIntegrationTaxGroupsQueryVariables = Exact<{
  input: GetCompanyIntegrationTaxGroupsInput
}>

export type CompanyIntegrationTaxGroupsQuery = {
  readonly __typename: 'Query'
  readonly companyIntegrationTaxGroups: ReadonlyArray<{
    readonly __typename: 'IntegrationTaxGroup'
    readonly integrationTaxGroupId: String['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
  }>
}

export type GetContractsForBulkImportQueryVariables = Exact<{
  input: GetContractsInput
}>

export type GetContractsForBulkImportQuery = {
  readonly __typename: 'Query'
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
    }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }>
}

export type ClearChangeOrderLogFormsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ClearChangeOrderLogFormsMutation = {
  readonly __typename: 'Mutation'
  readonly clearChangeOrderLogForms: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type ClearChangeOrderRequestFormsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ClearChangeOrderRequestFormsMutation = {
  readonly __typename: 'Mutation'
  readonly clearChangeOrderRequestForms: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type GetContractsForFormsQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type GetContractsForFormsQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly internalProjectNumber: String['output'] | null
      readonly status: ContractStatus
      readonly payAppRequirementGroups: ReadonlyArray<{
        readonly __typename: 'PayAppRequirementGroup'
        readonly id: ID['output']
        readonly payAppRequirements: ReadonlyArray<{
          readonly __typename: 'PayAppRequirement'
          readonly id: ID['output']
        }>
      }>
      readonly lienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
      } | null
      readonly lowerTierLienWaiverTemplates: {
        readonly __typename: 'SubcontractorLienWaiverTemplateSet'
        readonly id: ID['output']
      } | null
      readonly changeOrderRequestTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly versionNumber: Int['output']
        }>
      } | null
      readonly changeOrderLogTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly versions: ReadonlyArray<{
          readonly __typename: 'FormTemplateVersion'
          readonly id: ID['output']
          readonly versionNumber: Int['output']
        }>
      } | null
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly selectedPayAppForms: Boolean['output']
        readonly onboardedPayAppForms: Boolean['output']
        readonly selectedPrimaryLienWaivers: Boolean['output']
        readonly onboardedPrimaryLienWaiverForms: Boolean['output']
        readonly selectedVendorLienWaivers: Boolean['output']
        readonly onboardedVendorLienWaiverForms: Boolean['output']
        readonly selectedChangeOrderRequestForms: Boolean['output']
        readonly onboardedChangeOrderRequestForms: Boolean['output']
        readonly selectedChangeOrderLogForms: Boolean['output']
        readonly onboardedChangeOrderLogForms: Boolean['output']
        readonly notifiedOnboardedForms: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly addedSov: Boolean['output']
        readonly addedTeammates: Boolean['output']
        readonly addedGcContacts: Boolean['output']
        readonly startedBilling: Boolean['output']
        readonly isComplete: Boolean['output']
        readonly isReadyForPayAppSubmit: Boolean['output']
      }
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
    }>
  }
}

export type GetContractForFormsQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForFormsQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      } | null
    }
  }
}

export type CompanyForCreateProjectDialogQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyForCreateProjectDialogQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
    readonly defaultQuickBillingTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
    } | null
  }
}

export type CreateProjectImportOperationsMutationVariables = Exact<{
  inputs: ReadonlyArray<ProjectImportOperationInput> | ProjectImportOperationInput
}>

export type CreateProjectImportOperationsMutation = {
  readonly __typename: 'Mutation'
  readonly createProjectImportOperations: ReadonlyArray<{
    readonly __typename: 'ProjectImportOperation'
    readonly id: ID['output']
  }>
}

export type UpdateContractForGcContactsMutationVariables = Exact<{
  input: UpdateContractInput
}>

export type UpdateContractForGcContactsMutation = {
  readonly __typename: 'Mutation'
  readonly updateContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type ImportIntegrationTaxGroupMutationVariables = Exact<{
  input: ImportIntegrationTaxGroupInput
}>

export type ImportIntegrationTaxGroupMutation = {
  readonly __typename: 'Mutation'
  readonly importIntegrationTaxGroup: {
    readonly __typename: 'TaxGroup'
    readonly id: ID['output']
    readonly name: String['output']
    readonly taxPercent: Float['output']
    readonly integrationMappings: JSONObject['output']
  } | null
}

export type GetCompanyForOnboardingQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetCompanyForOnboardingQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly defaultLumpSumRequirements: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }> | null
    readonly defaultUnitPriceRequirements: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }> | null
    readonly defaultTimeAndMaterialsRequirements: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }> | null
    readonly defaultPrimaryLienWaivers: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly defaultVendorLienWaivers: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly defaultChangeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly defaultChangeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
  }
}

export type GetContractForOnboardingQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type GetContractForOnboardingQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly includeChangeOrderLogInPayAppPackage: Boolean['output'] | null
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly internalProjectNumber: String['output'] | null
    readonly timeZone: String['output']
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
      readonly metadata: {
        readonly __typename: 'ProjectMetadata'
        readonly payAppDueOnDayOfMonth: Int['output']
      }
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly payAppNumber: SafeInt['output']
      readonly haveContractFeesChanged: Boolean['output']
      readonly hasContractRateTableChanged: Boolean['output']
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly payAppDueDate: DateTime['output']
      readonly internalDueDate: DateTime['output']
      readonly billingStart: DateTime['output']
      readonly billingEnd: DateTime['output']
      readonly billingType: BillingType
      readonly status: PayAppStatus
      readonly invoiceReady: Boolean['output']
      readonly retentionOnly: Boolean['output']
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly draftSubmittedAt: DateTime['output'] | null
    }>
    readonly changeOrderRequests: ReadonlyArray<{
      readonly __typename: 'ChangeOrderRequest'
      readonly id: ID['output']
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly sov: { readonly __typename: 'Sov'; readonly id: ID['output'] } | null
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly order: Int['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly groupOrder: Int['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }>
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly changeOrderRequestTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly changeOrderLogTemplate: {
      readonly __typename: 'FormTemplate'
      readonly id: String['output']
      readonly isCustomerReady: Boolean['output']
      readonly variants: ReadonlyArray<{
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly isDefaultVariant: Boolean['output']
      }>
      readonly versions: ReadonlyArray<{
        readonly __typename: 'FormTemplateVersion'
        readonly id: ID['output']
        readonly versionNumber: Int['output']
      }>
    } | null
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly role: ContractUserRole
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
    readonly leadPMs: ReadonlyArray<{
      readonly __typename: 'User'
      readonly id: ID['output']
      readonly firstName: String['output']
      readonly lastName: String['output']
      readonly jobTitle: String['output'] | null
      readonly email: EmailAddress['output']
      readonly policiesAcceptedAt: DateTime['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
      readonly lastEditedAt: DateTime['output']
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
      readonly groups: ReadonlyArray<{
        readonly __typename: 'RateTableGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly type: LineItemType
      }>
      readonly items: ReadonlyArray<{
        readonly __typename: 'RateTableItem'
        readonly id: ID['output']
        readonly code: String['output'] | null
        readonly sortOrder: SafeInt['output']
        readonly description: String['output']
        readonly unitName: String['output'] | null
        readonly unitRate: SafeInt['output']
        readonly group: {
          readonly __typename: 'RateTableGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly type: LineItemType
        }
      }>
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly contracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
        }>
      }>
      readonly associatedContracts: ReadonlyArray<{
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly billingType: BillingType
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly projectNumber: String['output']
          readonly name: String['output']
        }
        readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        readonly payApps: ReadonlyArray<{
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly hasContractRateTableChanged: Boolean['output']
          readonly rateTable: { readonly __typename: 'RateTable'; readonly id: ID['output'] } | null
        }>
      }>
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'ContractRateTableFee'
      readonly id: ID['output']
      readonly description: String['output']
      readonly percent: Float['output']
      readonly group: { readonly __typename: 'RateTableGroup'; readonly id: ID['output'] } | null
      readonly overridesFee: {
        readonly __typename: 'ContractRateTableFee'
        readonly id: ID['output']
      } | null
    }>
  }
}

export type ClearPayAppFormsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ClearPayAppFormsMutation = {
  readonly __typename: 'Mutation'
  readonly clearPayAppForms: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly payAppRequirementGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRequirementGroup'
      readonly id: ID['output']
      readonly payAppRequirements: ReadonlyArray<{
        readonly __typename: 'PayAppRequirement'
        readonly id: ID['output']
        readonly templateVariant: {
          readonly __typename: 'FormTemplateVariant'
          readonly id: ID['output']
          readonly template: {
            readonly __typename: 'FormTemplate'
            readonly id: String['output']
            readonly isCustomerReady: Boolean['output']
            readonly versions: ReadonlyArray<{
              readonly __typename: 'FormTemplateVersion'
              readonly id: ID['output']
              readonly versionNumber: Int['output']
            }>
          }
        } | null
      }>
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type ClearPrimaryLienWaiverFormsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ClearPrimaryLienWaiverFormsMutation = {
  readonly __typename: 'Mutation'
  readonly clearPrimaryLienWaiverForms: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type ClearVendorLienWaiverFormsMutationVariables = Exact<{
  contractId: Scalars['ID']['input']
}>

export type ClearVendorLienWaiverFormsMutation = {
  readonly __typename: 'Mutation'
  readonly clearVendorLienWaiverForms: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
          }>
        }
      } | null
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
  }
}

export type GetContractForUnconditionalQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForUnconditionalQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly primaryLienWaivers: ReadonlyArray<{
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly date: DateTime['output']
      readonly type: LienWaiverType
    }>
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly notarySignature: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
      } | null
    }
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly progressComplete: Float['output']
    } | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
    } | null
  }
}

export type GetPayAppForUnconditionalQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppForUnconditionalQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly retentionOnly: Boolean['output']
    readonly status: PayAppStatus
    readonly payAppNumber: SafeInt['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly totalRetention: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly billingType: BillingType
    readonly balanceToFinish: SafeInt['output']
    readonly integrationPaidAt: DateTime['output'] | null
    readonly amountPaid: SafeInt['output'] | null
    readonly missingRequiredUnconditionalLienWaiverFields: ReadonlyArray<RequiredFieldForForms>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
  }
}

export type LatestPayAppQueryVariables = Exact<{
  input: LatestPayAppInput
}>

export type LatestPayAppQuery = {
  readonly __typename: 'Query'
  readonly latestPayApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly totalRetention: SafeInt['output']
    readonly balanceToFinish: SafeInt['output']
  } | null
}

export type LienWaiverForMonthQueryVariables = Exact<{
  input: GetLienWaiverForMonthInput
}>

export type LienWaiverForMonthQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverForMonth: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  } | null
}

export type SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationVariables = Exact<{
  input: SubmitUnconditionalLienWaiverForPreSitelinePayAppInput
}>

export type SubmitUnconditionalLienWaiverForPreSitelinePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly submitUnconditionalLienWaiverForPreSitelinePayApp: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type GetPreSitelinePayAppForUnconditionalQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPreSitelinePayAppForUnconditionalQuery = {
  readonly __typename: 'Query'
  readonly preSitelinePayApp: {
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly payAppNumber: Int['output']
    readonly retentionOnly: Boolean['output']
    readonly paymentDue: SafeInt['output'] | null
    readonly timeZone: String['output']
    readonly billingStart: DateTime['output'] | null
    readonly billingEnd: DateTime['output'] | null
    readonly percentComplete: Float['output']
    readonly status: PreSitelinePayAppStatus | null
    readonly isFinal: Boolean['output']
    readonly paidAt: DateTime['output'] | null
    readonly unconditionalLienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly notarySignature: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
        } | null
      }
    }
  }
}

export type SignUnconditionalLienWaiverForPreSitelinePayAppMutationVariables = Exact<{
  input: SignUnconditionalLienWaiverForPreSitelinePayAppInput
}>

export type SignUnconditionalLienWaiverForPreSitelinePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly signUnconditionalLienWaiverForPreSitelinePayApp: {
    readonly __typename: 'LienWaiver'
    readonly id: ID['output']
    readonly type: LienWaiverType
    readonly timeZone: String['output']
    readonly claimant: String['output']
    readonly date: DateTime['output']
    readonly defaultAmount: SafeInt['output'] | null
    readonly defaultAmountText: String['output'] | null
    readonly amount: SafeInt['output'] | null
    readonly customer: String['output']
    readonly lastSendTime: DateTime['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly subject: String['output'] | null
    readonly message: String['output'] | null
    readonly defaultExceptionAmounts: String['output'] | null
    readonly defaultExceptionDates: String['output'] | null
    readonly hasSelectedDefaultVendorContacts: Boolean['output']
    readonly owner: String['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly url: URL['output']
      readonly type: StoredFileType
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly size: SafeInt['output']
      readonly uploadedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly email: EmailAddress['output']
      } | null
    } | null
    readonly vendorContract: {
      readonly __typename: 'VendorContract'
      readonly id: ID['output']
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    } | null
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    }
    readonly lienWaiverRequests: ReadonlyArray<{
      readonly __typename: 'LienWaiverRequest'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly updatedAt: DateTime['output']
      readonly status: DocumentRequestStatus
      readonly actionTakenAt: DateTime['output'] | null
      readonly statusLogs: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequestStatusLog'
        readonly id: ID['output']
        readonly status: DocumentRequestStatus
        readonly statusUpdatedAt: DateTime['output']
      }>
      readonly vendorContact: {
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly defaultSignature: {
          readonly __typename: 'Signature'
          readonly id: ID['output']
          readonly signatureName: String['output'] | null
          readonly signatureFont: SignatureFont | null
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly url: URL['output']
          } | null
        } | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }
    }>
    readonly jobLocation: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    }
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly requestAttachments: ReadonlyArray<{
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    }>
    readonly defaultVendorContacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
  }
}

export type ClearUnconditionalLienWaiverForPreSitelinePayAppMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type ClearUnconditionalLienWaiverForPreSitelinePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly clearUnconditionalLienWaiverForPreSitelinePayApp: {
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly unconditionalLienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly type: LienWaiverType
      readonly timeZone: String['output']
      readonly claimant: String['output']
      readonly date: DateTime['output']
      readonly defaultAmount: SafeInt['output'] | null
      readonly defaultAmountText: String['output'] | null
      readonly amount: SafeInt['output'] | null
      readonly customer: String['output']
      readonly lastSendTime: DateTime['output'] | null
      readonly isJointCheck: Boolean['output']
      readonly subject: String['output'] | null
      readonly message: String['output'] | null
      readonly defaultExceptionAmounts: String['output'] | null
      readonly defaultExceptionDates: String['output'] | null
      readonly hasSelectedDefaultVendorContacts: Boolean['output']
      readonly owner: String['output']
      readonly physicalSignatureRequired: PhysicalSignatureRequired
      readonly lastSyncIfSuccessful: {
        readonly __typename: 'WriteSyncOperation'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly status: WriteSyncOperationStatus
        readonly payload: JSONObject['output']
        readonly result: JSONObject['output'] | null
        readonly integration: {
          readonly __typename: 'Integration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly mappings: JSONObject['output']
          readonly shortName: String['output']
        }
      } | null
      readonly uploadedFile: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly url: URL['output']
        readonly type: StoredFileType
        readonly name: String['output']
        readonly createdAt: DateTime['output']
        readonly size: SafeInt['output']
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
      } | null
      readonly vendorContract: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
          readonly integrationMappings: JSONObject['output']
          readonly internalNotes: String['output'] | null
          readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
          readonly address: {
            readonly __typename: 'Location'
            readonly id: ID['output']
            readonly createdAt: DateTime['output']
            readonly nickname: String['output'] | null
            readonly street1: String['output'] | null
            readonly street2: String['output'] | null
            readonly city: String['output']
            readonly county: String['output'] | null
            readonly state: String['output']
            readonly country: String['output']
            readonly postalCode: String['output'] | null
            readonly timeZone: String['output']
            readonly coordinates: LatLng['output']
          } | null
          readonly contacts: ReadonlyArray<{
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }>
          readonly companyIntegrations: ReadonlyArray<{
            readonly __typename: 'CompanyIntegration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly label: String['output'] | null
          }>
        }
        readonly preliminaryNotices: ReadonlyArray<{
          readonly __typename: 'VendorPreliminaryNotice'
          readonly id: ID['output']
          readonly date: DateTime['output']
          readonly file: {
            readonly __typename: 'StoredFile'
            readonly id: ID['output']
            readonly type: StoredFileType
            readonly url: URL['output']
            readonly name: String['output']
            readonly size: SafeInt['output']
          }
        }>
        readonly lowerTierTo: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      } | null
      readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
      readonly contract: {
        readonly __typename: 'Contract'
        readonly id: ID['output']
        readonly internalProjectNumber: String['output'] | null
        readonly project: {
          readonly __typename: 'Project'
          readonly id: ID['output']
          readonly name: String['output']
          readonly projectNumber: String['output']
        }
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      }
      readonly lienWaiverRequests: ReadonlyArray<{
        readonly __typename: 'LienWaiverRequest'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly updatedAt: DateTime['output']
        readonly status: DocumentRequestStatus
        readonly actionTakenAt: DateTime['output'] | null
        readonly statusLogs: ReadonlyArray<{
          readonly __typename: 'LienWaiverRequestStatusLog'
          readonly id: ID['output']
          readonly status: DocumentRequestStatus
          readonly statusUpdatedAt: DateTime['output']
        }>
        readonly vendorContact: {
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly defaultSignature: {
            readonly __typename: 'Signature'
            readonly id: ID['output']
            readonly signatureName: String['output'] | null
            readonly signatureFont: SignatureFont | null
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly url: URL['output']
            } | null
          } | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }
      }>
      readonly jobLocation: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly formValues: ReadonlyArray<{
        readonly __typename: 'FormTemplateAnnotationValue'
        readonly id: ID['output']
        readonly value: String['output']
        readonly metadata: JSONObject['output']
        readonly annotation: {
          readonly __typename: 'FormTemplateAnnotation'
          readonly id: ID['output']
          readonly userVisibleName: String['output']
          readonly type: FormTemplateAnnotationType
          readonly xStart: Float['output']
          readonly yStart: Float['output']
          readonly width: Float['output']
          readonly height: Float['output']
          readonly pageNumber: Int['output']
          readonly fontFamily: FormTemplateFont
          readonly fontColor: String['output']
          readonly textAlignment: TextAlignment
          readonly syncTag: String['output'] | null
          readonly isOptional: Boolean['output']
          readonly selectedKey: String['output'] | null
          readonly defaultValueKey: String['output'] | null
          readonly prefix: String['output'] | null
          readonly suffix: String['output'] | null
          readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
          readonly doNotRetainOnReset: Boolean['output']
          readonly dynamicFieldTag: String['output'] | null
          readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
          readonly imageType: FormTemplateAnnotationImageType | null
        }
      }>
      readonly requestAttachments: ReadonlyArray<{
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }>
      readonly defaultVendorContacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
    } | null
  }
}

export type DeletePreSitelinePayAppMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeletePreSitelinePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly deletePreSitelinePayApp: {
    readonly __typename: 'DeletionResult'
    readonly id: ID['output']
  }
}

export type CreatePreSitelinePayAppMutationVariables = Exact<{
  input: CreatePreSitelinePayAppInput
}>

export type CreatePreSitelinePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly createPreSitelinePayApp: {
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly retentionOnly: Boolean['output']
    readonly payAppNumber: Int['output']
    readonly billingStart: DateTime['output'] | null
    readonly billingEnd: DateTime['output'] | null
    readonly status: PreSitelinePayAppStatus | null
    readonly currentBilled: SafeInt['output'] | null
    readonly paymentDue: SafeInt['output'] | null
    readonly percentComplete: Float['output']
    readonly isFinal: Boolean['output']
    readonly paidAt: DateTime['output'] | null
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly submittedUnconditionalOutsideOfSiteline: Boolean['output']
    readonly submittedAt: DateTime['output'] | null
    readonly isNotarizingUnconditional: Boolean['output']
    readonly payAppFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly unconditionalLienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly averageDaysToPaid: Float['output']
    }
  }
}

export type UpdatePreSitelinePayAppMutationVariables = Exact<{
  input: UpdatePreSitelinePayAppInput
}>

export type UpdatePreSitelinePayAppMutation = {
  readonly __typename: 'Mutation'
  readonly updatePreSitelinePayApp: {
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly createdAt: DateTime['output']
    readonly timeZone: String['output']
    readonly retentionOnly: Boolean['output']
    readonly payAppNumber: Int['output']
    readonly billingStart: DateTime['output'] | null
    readonly billingEnd: DateTime['output'] | null
    readonly status: PreSitelinePayAppStatus | null
    readonly currentBilled: SafeInt['output'] | null
    readonly paymentDue: SafeInt['output'] | null
    readonly percentComplete: Float['output']
    readonly isFinal: Boolean['output']
    readonly paidAt: DateTime['output'] | null
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly submittedUnconditionalOutsideOfSiteline: Boolean['output']
    readonly submittedAt: DateTime['output'] | null
    readonly isNotarizingUnconditional: Boolean['output']
    readonly payAppFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly type: StoredFileType
      readonly url: URL['output']
      readonly name: String['output']
      readonly size: SafeInt['output']
    } | null
    readonly unconditionalLienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly averageDaysToPaid: Float['output']
    }
  }
}

export type DeletePreSitelinePayAppFileMutationVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type DeletePreSitelinePayAppFileMutation = {
  readonly __typename: 'Mutation'
  readonly deletePreSitelinePayAppFile: {
    readonly __typename: 'PreSitelinePayApp'
    readonly id: ID['output']
    readonly payAppFile: { readonly __typename: 'StoredFile'; readonly id: ID['output'] } | null
  }
}

export type ContractsForQuickBillAutocompleteQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type ContractsForQuickBillAutocompleteQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly location: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }
      }
    }>
  }
}

export type GetPayAppForQuickBillQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type GetPayAppForQuickBillQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly status: PayAppStatus
    readonly timeZone: String['output']
    readonly billingType: BillingType
    readonly retentionOnly: Boolean['output']
    readonly payAppNumber: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly retentionHeldOverride: SafeInt['output'] | null
    readonly amountDuePostTax: SafeInt['output']
    readonly integrationPaidAt: DateTime['output'] | null
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly statusChangedAt: DateTime['output']
    readonly automaticallyReadBilling: Boolean['output']
    readonly balanceToFinish: SafeInt['output']
    readonly billingEnd: DateTime['output']
    readonly predictedPaymentDate: DateTime['output'] | null
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxGroups: ReadonlyArray<{
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        }>
      }
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly metadata: JSONObject['output']
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
        readonly longName: String['output']
        readonly isActive: Boolean['output']
        readonly companyIntegration: {
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly metadata: JSONObject['output']
        }
      }>
      readonly defaultTaxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
      }
    }
    readonly amountLineItems: ReadonlyArray<{
      readonly __typename: 'AmountLineItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly amount: SafeInt['output']
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly sortOrder: SafeInt['output']
      readonly taxGroup: {
        readonly __typename: 'TaxGroup'
        readonly id: ID['output']
        readonly name: String['output']
        readonly taxPercent: Float['output']
        readonly integrationMappings: JSONObject['output']
      } | null
    }>
    readonly attachments: ReadonlyArray<{
      readonly __typename: 'PayAppAttachment'
      readonly id: ID['output']
      readonly description: String['output'] | null
      readonly name: String['output']
      readonly createdAt: DateTime['output']
      readonly uploadId: ID['output']
      readonly file: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly type: StoredFileType
        readonly url: URL['output']
        readonly name: String['output']
        readonly size: SafeInt['output']
      }
    }>
  }
}

export type AddIntegrationToContractMutationVariables = Exact<{
  input: AddIntegrationToContractInput
}>

export type AddIntegrationToContractMutation = {
  readonly __typename: 'Mutation'
  readonly addIntegrationToContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly timeZone: String['output']
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type SetIntegrationTaxGroupMutationVariables = Exact<{
  input: SetIntegrationTaxGroupInput
}>

export type SetIntegrationTaxGroupMutation = {
  readonly __typename: 'Mutation'
  readonly setIntegrationTaxGroup: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }
}

export type IntegrationCustomersQueryVariables = Exact<{
  input: GetIntegrationCustomersInput
}>

export type IntegrationCustomersQuery = {
  readonly __typename: 'Query'
  readonly integrationCustomers: ReadonlyArray<{
    readonly __typename: 'IntegrationCustomer'
    readonly integrationCustomerId: String['output']
    readonly name: String['output']
    readonly code: String['output'] | null
  }>
}

export type SetIntegrationCustomerMutationVariables = Exact<{
  input: SetIntegrationCustomerInput
}>

export type SetIntegrationCustomerMutation = {
  readonly __typename: 'Mutation'
  readonly setIntegrationCustomer: {
    readonly __typename: 'Integration'
    readonly id: ID['output']
    readonly type: IntegrationType
    readonly metadata: JSONObject['output']
    readonly mappings: JSONObject['output']
    readonly shortName: String['output']
    readonly longName: String['output']
    readonly isActive: Boolean['output']
    readonly companyIntegration: {
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly metadata: JSONObject['output']
    }
  }
}

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteIntegrationMutation = {
  readonly __typename: 'Mutation'
  readonly deleteIntegration: { readonly __typename: 'DeletionResult'; readonly id: ID['output'] }
}

export type UpdateContractWithContactsMutationVariables = Exact<{
  input: UpdateContractInput
}>

export type UpdateContractWithContactsMutation = {
  readonly __typename: 'Mutation'
  readonly updateContract: {
    readonly __typename: 'Contract'
    readonly firstPayAppBillingEnd: DateTime['output'] | null
    readonly hasStartedBilling: Boolean['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly timeZone: String['output']
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly payAppNumber: SafeInt['output']
      readonly balanceToFinish: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly emailedContacts: ReadonlyArray<{
        readonly __typename: 'CompanyContact'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly companyName: String['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
      }>
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type UpdateContractForAuditItemMutationVariables = Exact<{
  input: UpdateContractInput
}>

export type UpdateContractForAuditItemMutation = {
  readonly __typename: 'Mutation'
  readonly updateContract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly showAuditItemOnSignedPackages: Boolean['output']
  }
}

export type PayAppForFormsQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type PayAppForFormsQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly canGenerateForms: Boolean['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
    readonly status: PayAppStatus
  }
}

export type SubmitDraftPayAppMutationVariables = Exact<{
  input: SubmitDraftPayAppInput
}>

export type SubmitDraftPayAppMutation = {
  readonly __typename: 'Mutation'
  readonly submitDraftPayApp: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type SubmitForFullReviewMutationVariables = Exact<{
  input: SubmitPayAppInput
}>

export type SubmitForFullReviewMutation = {
  readonly __typename: 'Mutation'
  readonly submitForFullReview: {
    readonly __typename: 'PayApp'
    readonly balanceToFinish: SafeInt['output']
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type SubmitBackupMutationVariables = Exact<{
  input: SubmitBackupInput
}>

export type SubmitBackupMutation = {
  readonly __typename: 'Mutation'
  readonly submitBackup: {
    readonly __typename: 'PayApp'
    readonly payAppNumber: SafeInt['output']
    readonly totalRetention: SafeInt['output']
    readonly physicalSignatureRequired: PhysicalSignatureRequired
    readonly createdAt: DateTime['output']
    readonly statusChangedAt: DateTime['output']
    readonly amountPaid: SafeInt['output'] | null
    readonly isBalanceManuallyClosed: Boolean['output']
    readonly previousRetentionBilled: SafeInt['output']
    readonly currentRetention: SafeInt['output']
    readonly billingType: BillingType
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payAppDueDate: DateTime['output']
    readonly internalDueDate: DateTime['output']
    readonly billingStart: DateTime['output']
    readonly billingEnd: DateTime['output']
    readonly status: PayAppStatus
    readonly invoiceReady: Boolean['output']
    readonly retentionOnly: Boolean['output']
    readonly currentBilled: SafeInt['output']
    readonly draftSubmittedAt: DateTime['output'] | null
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly progressBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly storedMaterialBilled: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly previousBilled: SafeInt['output']
      readonly futureBilled: SafeInt['output']
      readonly previousRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly totalValue: SafeInt['output']
      readonly retentionHeldPercent: Float['output']
      readonly retentionHeldOverride: SafeInt['output'] | null
      readonly retentionReleased: SafeInt['output'] | null
      readonly amountDuePreTax: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly amountDueTaxAmount: SafeInt['output']
      readonly lastProgressWithRetentionHeldOverride: {
        readonly __typename: 'SovLineItemProgress'
        readonly id: ID['output']
        readonly payApp: {
          readonly __typename: 'PayApp'
          readonly id: ID['output']
          readonly payAppNumber: SafeInt['output']
        }
      } | null
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly sortOrder: SafeInt['output']
        readonly name: String['output']
        readonly costCode: String['output'] | null
        readonly isChangeOrder: Boolean['output']
        readonly changeOrderApprovedDate: DateTime['output'] | null
        readonly changeOrderEffectiveDate: DateTime['output'] | null
        readonly originalTotalValue: SafeInt['output']
        readonly latestTotalValue: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly billedToDate: SafeInt['output']
        readonly defaultRetentionPercent: Float['output'] | null
        readonly preSitelineRetentionHeldOverride: SafeInt['output'] | null
        readonly preSitelineRetention: SafeInt['output']
        readonly unitName: String['output'] | null
        readonly unitPrice: Decimal['output'] | null
        readonly sovLineItemGroup: {
          readonly __typename: 'SovLineItemGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly code: String['output'] | null
        } | null
        readonly changeOrderRequests: ReadonlyArray<{
          readonly __typename: 'ChangeOrderRequest'
          readonly id: ID['output']
          readonly name: String['output']
          readonly internalNumber: String['output'] | null
        }>
        readonly worksheetLineItems: ReadonlyArray<{
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly totalValue: SafeInt['output']
          readonly previousBilled: SafeInt['output']
          readonly unitPrice: Decimal['output'] | null
          readonly unitName: String['output'] | null
          readonly billedToDate: SafeInt['output']
          readonly sortOrder: SafeInt['output']
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }>
        readonly taxGroup: {
          readonly __typename: 'TaxGroup'
          readonly id: ID['output']
          readonly name: String['output']
          readonly taxPercent: Float['output']
          readonly integrationMappings: JSONObject['output']
        } | null
      }
      readonly worksheetLineItemProgress: ReadonlyArray<{
        readonly __typename: 'WorksheetLineItemProgress'
        readonly id: ID['output']
        readonly progressBilled: SafeInt['output']
        readonly previousBilled: SafeInt['output']
        readonly totalValue: SafeInt['output']
        readonly worksheetLineItem: {
          readonly __typename: 'WorksheetLineItem'
          readonly id: ID['output']
          readonly code: String['output']
          readonly name: String['output']
          readonly costCode: String['output'] | null
          readonly unitName: String['output'] | null
          readonly unitPrice: Decimal['output'] | null
          readonly sovLineItem: { readonly __typename: 'SovLineItem'; readonly id: ID['output'] }
        }
      }>
    }>
    readonly formValues: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotationValue'
      readonly id: ID['output']
      readonly value: String['output']
      readonly metadata: JSONObject['output']
      readonly annotation: {
        readonly __typename: 'FormTemplateAnnotation'
        readonly id: ID['output']
        readonly userVisibleName: String['output']
        readonly type: FormTemplateAnnotationType
        readonly xStart: Float['output']
        readonly yStart: Float['output']
        readonly width: Float['output']
        readonly height: Float['output']
        readonly pageNumber: Int['output']
        readonly fontFamily: FormTemplateFont
        readonly fontColor: String['output']
        readonly textAlignment: TextAlignment
        readonly syncTag: String['output'] | null
        readonly isOptional: Boolean['output']
        readonly selectedKey: String['output'] | null
        readonly defaultValueKey: String['output'] | null
        readonly prefix: String['output'] | null
        readonly suffix: String['output'] | null
        readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
        readonly doNotRetainOnReset: Boolean['output']
        readonly dynamicFieldTag: String['output'] | null
        readonly copyDefaultValueFromPreviousAnnotationValue: Boolean['output']
        readonly imageType: FormTemplateAnnotationImageType | null
      }
    }>
    readonly events: ReadonlyArray<{
      readonly __typename: 'PayAppEvent'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly isAdmin: Boolean['output']
      readonly type: PayAppEventType
      readonly metadata: JSONObject['output']
      readonly createdBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }
    }>
    readonly lastSync: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly lastSyncIfSuccessful: {
      readonly __typename: 'WriteSyncOperation'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly status: WriteSyncOperationStatus
      readonly payload: JSONObject['output']
      readonly result: JSONObject['output'] | null
      readonly integration: {
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly mappings: JSONObject['output']
        readonly shortName: String['output']
      }
    } | null
    readonly uploadedFile: {
      readonly __typename: 'StoredFile'
      readonly id: ID['output']
      readonly name: String['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly roundRetention: Boolean['output']
      readonly internalProjectNumber: String['output'] | null
      readonly taxCalculationType: TaxCalculationType
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
        readonly nickname: String['output'] | null
        readonly type: CompanyType
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly defaultShowAuditItemOnSignedPackages: Boolean['output']
        readonly agingIntervalType: AgingIntervalType
        readonly defaultRetentionPercent: Float['output'] | null
        readonly defaultPayAppDueOnDayOfMonth: Int['output'] | null
        readonly defaultDaysBeforePayAppDue: Int['output'] | null
        readonly enableBillingWorksheets: Boolean['output']
        readonly allowLumpSumOverbilling: Boolean['output']
        readonly vendorLienWaiverReminderFrequency: VendorLienWaiverReminderFrequency
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly archivedAt: DateTime['output'] | null
          readonly shortName: String['output']
          readonly longName: String['output']
          readonly metadata: JSONObject['output']
        }>
        readonly picture: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly url: URL['output']
        } | null
        readonly locations: ReadonlyArray<{
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        }>
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly projectNumber: String['output']
        readonly name: String['output']
      }
    }
    readonly emailedContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly rateTable: {
      readonly __typename: 'RateTable'
      readonly id: ID['output']
      readonly name: String['output']
      readonly status: RateTableStatus
      readonly effectiveAt: DateTime['output'] | null
      readonly expiresAt: DateTime['output'] | null
    } | null
    readonly rateTableFees: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableFee'
      readonly id: ID['output']
      readonly percent: Float['output']
      readonly description: String['output']
      readonly group: {
        readonly __typename: 'PayAppRateTableGroup'
        readonly id: ID['output']
      } | null
      readonly overridesFee: {
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
      } | null
    }>
    readonly rateTableGroups: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly totalAmount: SafeInt['output']
      readonly subtotalAmount: SafeInt['output']
      readonly type: LineItemType
      readonly fees: ReadonlyArray<{
        readonly __typename: 'PayAppRateTableFee'
        readonly id: ID['output']
        readonly percent: Float['output']
        readonly description: String['output']
        readonly group: {
          readonly __typename: 'PayAppRateTableGroup'
          readonly id: ID['output']
        } | null
        readonly overridesFee: {
          readonly __typename: 'PayAppRateTableFee'
          readonly id: ID['output']
        } | null
      }>
    }>
    readonly rateTableItems: ReadonlyArray<{
      readonly __typename: 'PayAppRateTableItem'
      readonly id: ID['output']
      readonly description: String['output']
      readonly code: String['output'] | null
      readonly sortOrder: SafeInt['output']
      readonly unitName: String['output'] | null
      readonly unitRate: SafeInt['output']
      readonly currentBilled: SafeInt['output']
      readonly currentUnitsBilled: Float['output']
      readonly isOneOffItem: Boolean['output']
      readonly group: { readonly __typename: 'PayAppRateTableGroup'; readonly id: ID['output'] }
      readonly attachments: ReadonlyArray<{
        readonly __typename: 'PayAppAttachment'
        readonly id: ID['output']
      }>
    }>
  }
}

export type GetProjectForSubmitQueryVariables = Exact<{
  projectId: Scalars['ID']['input']
}>

export type GetProjectForSubmitQuery = {
  readonly __typename: 'Query'
  readonly project: {
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly generalContractor: {
      readonly __typename: 'CompanyProjectMetadata'
      readonly companyName: String['output'] | null
      readonly company: {
        readonly __typename: 'Company'
        readonly id: ID['output']
        readonly name: String['output']
      }
    } | null
  }
}

export type GetIntegrationIncomeTypeNumbersQueryVariables = Exact<{
  input: IntegrationIncomeTypeNumbersInput
}>

export type GetIntegrationIncomeTypeNumbersQuery = {
  readonly __typename: 'Query'
  readonly integrationIncomeTypeNumbers: ReadonlyArray<{
    readonly __typename: 'IntegrationIncomeTypeNumber'
    readonly integrationIncomeTypeNumberId: String['output']
    readonly incomeTypeNumber: String['output']
    readonly description: String['output']
  }>
}

export type ReadIntegrationSovQueryVariables = Exact<{
  input: ReadIntegrationSovInput
}>

export type ReadIntegrationSovQuery = {
  readonly __typename: 'Query'
  readonly readIntegrationSov: {
    readonly __typename: 'IntegrationSov'
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'IntegrationSovLineItem'
      readonly integrationLineItemId: String['output'] | null
      readonly code: String['output']
      readonly description: String['output']
      readonly scheduledValue: Float['output']
      readonly billedToDate: Float['output']
      readonly retentionToDate: Float['output']
      readonly unitPrice: Decimal['output']
    }>
  }
}

export type PayAppForSage100SyncQueryVariables = Exact<{
  payAppId: Scalars['ID']['input']
}>

export type PayAppForSage100SyncQuery = {
  readonly __typename: 'Query'
  readonly payApp: {
    readonly __typename: 'PayApp'
    readonly id: ID['output']
    readonly billingEnd: DateTime['output']
    readonly payAppNumber: SafeInt['output']
    readonly currentBilled: SafeInt['output']
    readonly amountDuePostTax: SafeInt['output']
    readonly amountDueTaxAmount: SafeInt['output']
    readonly lastSubmitted: {
      readonly __typename: 'PayAppStatusLog'
      readonly id: ID['output']
      readonly statusUpdatedAt: DateTime['output']
    } | null
    readonly contract: {
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly taxCalculationType: TaxCalculationType
      readonly appliesTaxToRetention: Boolean['output']
    }
    readonly progress: ReadonlyArray<{
      readonly __typename: 'SovLineItemProgress'
      readonly id: ID['output']
      readonly currentBilled: SafeInt['output']
      readonly sovLineItem: {
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly code: String['output']
        readonly name: String['output']
        readonly sortOrder: SafeInt['output']
      }
    }>
  }
}

export type GetIntegrationGeneralLedgerAccountsQueryVariables = Exact<{
  companyIntegrationId: Scalars['ID']['input']
}>

export type GetIntegrationGeneralLedgerAccountsQuery = {
  readonly __typename: 'Query'
  readonly integrationGeneralLedgerAccounts: ReadonlyArray<{
    readonly __typename: 'IntegrationGeneralLedgerAccount'
    readonly integrationAccountId: String['output']
    readonly parentName: String['output'] | null
    readonly parentCode: String['output'] | null
    readonly code: String['output']
    readonly name: String['output']
  }>
}

export type GetContractsForLegalRequirementQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type GetContractsForLegalRequirementQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly hasNext: Boolean['output']
    readonly cursor: ID['output'] | null
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly status: ContractStatus
      readonly billingType: BillingType
      readonly internalProjectNumber: String['output'] | null
      readonly complianceStatus: ComplianceStatus | null
      readonly timeZone: String['output']
      readonly onboardedProjectComplianceStatus: {
        readonly __typename: 'OnboardedProjectComplianceStatus'
        readonly completedOnboarding: Boolean['output']
      }
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly shortName: String['output']
        readonly longName: String['output']
      }>
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
      }
    }>
  }
}

export type LienWaiverRequestWithTokenForForwardingQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type LienWaiverRequestWithTokenForForwardingQuery = {
  readonly __typename: 'Query'
  readonly lienWaiverRequestWithToken: {
    readonly __typename: 'LienWaiverRequest'
    readonly id: ID['output']
    readonly status: DocumentRequestStatus
    readonly actionTakenAt: DateTime['output'] | null
    readonly lienWaiver: {
      readonly __typename: 'LienWaiver'
      readonly id: ID['output']
      readonly timeZone: String['output']
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly emailedContacts: ReadonlyArray<{
          readonly __typename: 'CompanyContact'
          readonly id: ID['output']
          readonly companyName: String['output']
        }>
      }>
    }
  } | null
}

export type OptOutVendorFromDigitalSignaturesMutationVariables = Exact<{
  input: OptOutVendorFromDigitalSignaturesInput
}>

export type OptOutVendorFromDigitalSignaturesMutation = {
  readonly __typename: 'Mutation'
  readonly optOutVendorFromDigitalSignatures: {
    readonly __typename: 'VendorContact'
    readonly id: ID['output']
    readonly fullName: String['output']
    readonly email: EmailAddress['output']
    readonly phoneNumber: PhoneNumber['output'] | null
    readonly jobTitle: String['output'] | null
    readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
  }
}

export type GetContractForLegalRequirementQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForLegalRequirementQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly status: ContractStatus
    readonly billingType: BillingType
    readonly complianceStatus: ComplianceStatus | null
    readonly vendorNumber: String['output'] | null
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly architect: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly lastSendTime: DateTime['output'] | null
        readonly isUploaded: Boolean['output']
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        } | null
      }>
    }>
    readonly legalRequirementContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
    readonly onboardedProjectComplianceStatus: {
      readonly __typename: 'OnboardedProjectComplianceStatus'
      readonly addedLegalRequirements: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
  }
}

export type GetContractsForComplianceQueryVariables = Exact<{
  input: GetContractsInput
}>

export type GetContractsForComplianceQuery = {
  readonly __typename: 'Query'
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly status: ContractStatus
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly onboardedProjectComplianceStatus: {
      readonly __typename: 'OnboardedProjectComplianceStatus'
      readonly addedLegalRequirements: Boolean['output']
      readonly addedGcRecipients: Boolean['output']
      readonly completedOnboarding: Boolean['output']
    }
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly missingRequiredFields: ReadonlyArray<RequiredFieldForForms>
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly formTemplate: {
        readonly __typename: 'FormTemplate'
        readonly id: String['output']
        readonly userVisibleName: String['output']
        readonly isCustomerReady: Boolean['output']
      } | null
      readonly documents: ReadonlyArray<{
        readonly __typename: 'LegalDocument'
        readonly lastSendTime: DateTime['output'] | null
        readonly isUploaded: Boolean['output']
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly name: String['output']
        readonly periodStart: DateTime['output'] | null
        readonly periodEnd: DateTime['output'] | null
        readonly lastSyncIfSuccessful: {
          readonly __typename: 'WriteSyncOperation'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly status: WriteSyncOperationStatus
          readonly payload: JSONObject['output']
          readonly result: JSONObject['output'] | null
          readonly integration: {
            readonly __typename: 'Integration'
            readonly id: ID['output']
            readonly type: IntegrationType
            readonly mappings: JSONObject['output']
            readonly shortName: String['output']
          }
        } | null
        readonly uploadedBy: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly email: EmailAddress['output']
        } | null
        readonly legalDocumentRequests: ReadonlyArray<{
          readonly __typename: 'LegalDocumentRequest'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly updatedAt: DateTime['output']
          readonly status: DocumentRequestStatus
          readonly actionTakenAt: DateTime['output'] | null
          readonly vendorContact: {
            readonly __typename: 'VendorContact'
            readonly id: ID['output']
            readonly fullName: String['output']
            readonly email: EmailAddress['output']
            readonly phoneNumber: PhoneNumber['output'] | null
            readonly jobTitle: String['output'] | null
            readonly lowerTierTo: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
            } | null
          }
        }>
        readonly vendorContract: {
          readonly __typename: 'VendorContract'
          readonly id: ID['output']
          readonly vendor: {
            readonly __typename: 'Vendor'
            readonly id: ID['output']
            readonly name: String['output']
          }
          readonly lowerTierTo: {
            readonly __typename: 'VendorContract'
            readonly id: ID['output']
            readonly vendor: {
              readonly __typename: 'Vendor'
              readonly id: ID['output']
              readonly name: String['output']
            }
          } | null
        } | null
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        } | null
      }>
    }>
  }>
}

export type GetContractsForTopNavQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type GetContractsForTopNavQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly hasNext: Boolean['output']
    readonly totalCount: Int['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly billingType: BillingType
      readonly status: ContractStatus
      readonly internalProjectNumber: String['output'] | null
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly billingType: BillingType
      }>
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
      }
    }>
  }
}

export type ContractForTopNavQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type ContractForTopNavQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly status: ContractStatus
    readonly internalProjectNumber: String['output'] | null
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingType: BillingType
    }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
    }
  }
}

export type ArchiveAllNotificationsMutationVariables = Exact<{ [key: string]: never }>

export type ArchiveAllNotificationsMutation = {
  readonly __typename: 'Mutation'
  readonly archiveAllNotifications: Boolean['output']
}

export type ArchiveNotificationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ArchiveNotificationMutation = {
  readonly __typename: 'Mutation'
  readonly archiveNotification: { readonly __typename: 'Notification'; readonly id: ID['output'] }
}

export type ContractForAdditionalOptionsMenuQueryVariables = Exact<{
  contractId: Scalars['ID']['input']
  months: ReadonlyArray<Scalars['DateTime']['input']> | Scalars['DateTime']['input']
}>

export type ContractForAdditionalOptionsMenuQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly timeZone: String['output']
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly billingEnd: DateTime['output']
      readonly retentionOnly: Boolean['output']
      readonly balanceToFinish: SafeInt['output']
      readonly totalRetention: SafeInt['output']
    }>
    readonly lienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
        }
      } | null
    } | null
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly totalBilled: SafeInt['output']
    } | null
  }
}

export type GetBulkCreatePayAppsContractsQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type GetBulkCreatePayAppsContractsQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly skippedPayAppMonths: ReadonlyArray<String['output']>
      readonly timeZone: String['output']
      readonly billingType: BillingType
      readonly percentComplete: Float['output'] | null
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly addedSov: Boolean['output']
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly metadata: {
          readonly __typename: 'ProjectMetadata'
          readonly payAppDueOnDayOfMonth: Int['output']
        }
      }
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly billingStart: DateTime['output']
        readonly billingEnd: DateTime['output']
        readonly payAppDueDate: DateTime['output']
        readonly retentionOnly: Boolean['output']
      }>
      readonly sov: { readonly __typename: 'Sov'; readonly id: ID['output'] } | null
    }>
  }
}

export type GetHomepageContractsQueryVariables = Exact<{
  input: GetPaginatedContractsInput
}>

export type GetHomepageContractsQuery = {
  readonly __typename: 'Query'
  readonly paginatedContracts: {
    readonly __typename: 'PaginatedContractList'
    readonly cursor: ID['output'] | null
    readonly hasNext: Boolean['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly role: CompanyProjectRole
      readonly status: ContractStatus
      readonly internalProjectNumber: String['output'] | null
      readonly retentionTrackingLevel: RetentionTrackingLevel
      readonly skippedPayAppMonths: ReadonlyArray<String['output']>
      readonly daysBeforePayAppDue: Int['output']
      readonly billingType: BillingType
      readonly timeZone: String['output']
      readonly nextPayAppNumber: Int['output']
      readonly percentComplete: Float['output'] | null
      readonly pastPayAppCount: Int['output']
      readonly lastEditedAt: DateTime['output'] | null
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly integrations: ReadonlyArray<{
        readonly __typename: 'Integration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly shortName: String['output']
        readonly longName: String['output']
      }>
      readonly sov: {
        readonly __typename: 'Sov'
        readonly id: ID['output']
        readonly totalRetention: SafeInt['output']
      } | null
      readonly onboardedStatus: {
        readonly __typename: 'OnboardedProjectContractStatus'
        readonly addedSov: Boolean['output']
        readonly selectedRateTable: Boolean['output']
        readonly isComplete: Boolean['output']
        readonly isReadyForPayAppSubmit: Boolean['output']
      }
      readonly project: {
        readonly __typename: 'Project'
        readonly id: ID['output']
        readonly name: String['output']
        readonly projectNumber: String['output']
        readonly generalContractor: {
          readonly __typename: 'CompanyProjectMetadata'
          readonly companyName: String['output'] | null
          readonly company: {
            readonly __typename: 'Company'
            readonly id: ID['output']
            readonly name: String['output']
          }
        } | null
        readonly metadata: {
          readonly __typename: 'ProjectMetadata'
          readonly payAppDueOnDayOfMonth: Int['output']
        }
      }
      readonly leadPMs: ReadonlyArray<{
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      }>
      readonly payApps: ReadonlyArray<{
        readonly __typename: 'PayApp'
        readonly id: ID['output']
        readonly timeZone: String['output']
        readonly billingStart: DateTime['output']
        readonly billingEnd: DateTime['output']
        readonly billingType: BillingType
        readonly status: PayAppStatus
        readonly retentionOnly: Boolean['output']
        readonly invoiceReady: Boolean['output']
        readonly currentBilled: SafeInt['output']
        readonly previousRetentionBilled: SafeInt['output']
        readonly amountDuePostTax: SafeInt['output']
        readonly payAppDueDate: DateTime['output']
        readonly internalDueDate: DateTime['output']
        readonly draftSubmittedAt: DateTime['output'] | null
        readonly payAppNumber: SafeInt['output']
        readonly currentRetention: SafeInt['output']
        readonly amountPaid: SafeInt['output'] | null
        readonly isBalanceManuallyClosed: Boolean['output']
      }>
      readonly lastEditedBy: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
      } | null
    }>
  }
}

export type ContractsOverviewQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type ContractsOverviewQuery = {
  readonly __typename: 'Query'
  readonly contractsOverview: {
    readonly __typename: 'ContractListOverview'
    readonly activeCount: Int['output']
    readonly archivedCount: Int['output']
    readonly onboardingCount: Int['output']
    readonly fullyBilledCount: Int['output']
  }
}

export type ContractForAgingSidesheetQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForAgingSidesheetQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly timeZone: String['output']
    readonly billingType: BillingType
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly preSitelineRetention: SafeInt['output']
    readonly averageDaysToPaid: Float['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly pastPayAppCount: Int['output']
    readonly defaultGcContacts: ReadonlyArray<{
      readonly __typename: 'CompanyContact'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly companyName: String['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
    }>
    readonly company: {
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly agingIntervalType: AgingIntervalType
    }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly projectNumber: String['output']
      readonly name: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly status: PayAppStatus
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly balanceToFinish: SafeInt['output']
      readonly totalRetention: SafeInt['output']
      readonly amountPaid: SafeInt['output'] | null
      readonly predictedPaymentDate: DateTime['output'] | null
      readonly billingEnd: DateTime['output']
      readonly retentionOnly: Boolean['output']
      readonly payAppNumber: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly isBalanceManuallyClosed: Boolean['output']
      readonly lastSubmitted: {
        readonly __typename: 'PayAppStatusLog'
        readonly id: ID['output']
        readonly statusUpdatedAt: DateTime['output']
      } | null
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly status: PreSitelinePayAppStatus | null
      readonly amountPaid: SafeInt['output'] | null
      readonly paymentDue: SafeInt['output'] | null
      readonly isBalanceManuallyClosed: Boolean['output']
      readonly billingEnd: DateTime['output'] | null
      readonly submittedAt: DateTime['output'] | null
      readonly payAppNumber: Int['output']
    }>
    readonly sov: {
      readonly __typename: 'Sov'
      readonly id: ID['output']
      readonly totalValue: SafeInt['output']
      readonly lineItems: ReadonlyArray<{
        readonly __typename: 'SovLineItem'
        readonly id: ID['output']
        readonly previousBilled: SafeInt['output']
        readonly preSitelineRetention: SafeInt['output']
      }>
    } | null
  }
}

export type ContractForCashFlowDetailsDialogQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForCashFlowDetailsDialogQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly billingType: BillingType
    readonly internalProjectNumber: String['output'] | null
    readonly isJointCheck: Boolean['output']
    readonly invoiceAmountOutstanding: SafeInt['output']
    readonly outstandingVendorLienWaiverAmount: SafeInt['output']
    readonly timeZone: String['output']
    readonly leadPMs: ReadonlyArray<{ readonly __typename: 'User'; readonly id: ID['output'] }>
    readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }
    readonly payApps: ReadonlyArray<{
      readonly __typename: 'PayApp'
      readonly id: ID['output']
      readonly payAppNumber: SafeInt['output']
      readonly predictedPaymentDate: DateTime['output'] | null
      readonly currentBilled: SafeInt['output']
      readonly currentRetention: SafeInt['output']
      readonly previousRetentionBilled: SafeInt['output']
      readonly amountDuePostTax: SafeInt['output']
      readonly isBalanceManuallyClosed: Boolean['output']
      readonly amountPaid: SafeInt['output'] | null
      readonly status: PayAppStatus
      readonly timeZone: String['output']
      readonly retentionOnly: Boolean['output']
      readonly billingEnd: DateTime['output']
    }>
    readonly preSitelinePayApps: ReadonlyArray<{
      readonly __typename: 'PreSitelinePayApp'
      readonly id: ID['output']
      readonly payAppNumber: Int['output']
      readonly status: PreSitelinePayAppStatus | null
      readonly paymentDue: SafeInt['output'] | null
      readonly retentionOnly: Boolean['output']
      readonly billingEnd: DateTime['output'] | null
      readonly amountPaid: SafeInt['output'] | null
      readonly isBalanceManuallyClosed: Boolean['output']
    }>
  }
}

export type UpdateCompanyIntegrationMetadataMutationVariables = Exact<{
  input: UpdateCompanyIntegrationMetadataInput
}>

export type UpdateCompanyIntegrationMetadataMutation = {
  readonly __typename: 'Mutation'
  readonly updateCompanyIntegrationMetadata: {
    readonly __typename: 'CompanyIntegration'
    readonly id: ID['output']
    readonly metadata: JSONObject['output']
  }
}

export type GetCompanyForTaxGroupsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetCompanyForTaxGroupsQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly taxGroups: ReadonlyArray<{
      readonly __typename: 'TaxGroup'
      readonly id: ID['output']
      readonly name: String['output']
      readonly taxPercent: Float['output']
      readonly integrationMappings: JSONObject['output']
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
    }>
  }
}

export type UserForMultiCompanyUsersQueryVariables = Exact<{ [key: string]: never }>

export type UserForMultiCompanyUsersQuery = {
  readonly __typename: 'Query'
  readonly currentUser: {
    readonly __typename: 'User'
    readonly id: ID['output']
    readonly companies: ReadonlyArray<{
      readonly __typename: 'Company'
      readonly id: ID['output']
      readonly users: ReadonlyArray<{
        readonly __typename: 'CompanyUser'
        readonly id: ID['output']
        readonly role: CompanyUserRole
        readonly isSitelineAdmin: Boolean['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly email: EmailAddress['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly jobTitle: String['output'] | null
        }
      }>
    }>
  }
}

export type CompanyProjectUsersProperties = {
  readonly __typename: 'Project'
  readonly id: ID['output']
  readonly name: String['output']
  readonly projectNumber: String['output']
  readonly contracts: ReadonlyArray<{
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly internalProjectNumber: String['output'] | null
    readonly billingType: BillingType
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
    readonly users: ReadonlyArray<{
      readonly __typename: 'ContractUser'
      readonly id: ID['output']
      readonly user: {
        readonly __typename: 'User'
        readonly id: ID['output']
        readonly firstName: String['output']
        readonly lastName: String['output']
        readonly jobTitle: String['output'] | null
        readonly email: EmailAddress['output']
        readonly policiesAcceptedAt: DateTime['output'] | null
      }
    }>
  }>
}

export type GetCompanyProjectsQueryVariables = Exact<{
  companyId: Scalars['ID']['input']
}>

export type GetCompanyProjectsQuery = {
  readonly __typename: 'Query'
  readonly companyProjects: ReadonlyArray<{
    readonly __typename: 'Project'
    readonly id: ID['output']
    readonly name: String['output']
    readonly projectNumber: String['output']
    readonly contracts: ReadonlyArray<{
      readonly __typename: 'Contract'
      readonly id: ID['output']
      readonly internalProjectNumber: String['output'] | null
      readonly billingType: BillingType
      readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
      readonly selectedAddress: { readonly __typename: 'Location'; readonly id: ID['output'] }
      readonly users: ReadonlyArray<{
        readonly __typename: 'ContractUser'
        readonly id: ID['output']
        readonly user: {
          readonly __typename: 'User'
          readonly id: ID['output']
          readonly firstName: String['output']
          readonly lastName: String['output']
          readonly jobTitle: String['output'] | null
          readonly email: EmailAddress['output']
          readonly policiesAcceptedAt: DateTime['output'] | null
        }
      }>
    }>
  }>
}

export type ResendUserInviteMutationVariables = Exact<{
  input: ResendUserInviteInput
}>

export type ResendUserInviteMutation = {
  readonly __typename: 'Mutation'
  readonly resendUserInvite: { readonly __typename: 'User'; readonly id: ID['output'] }
}

export type IntegrationVendorsForBulkImportQueryVariables = Exact<{
  companyIntegrationId: Scalars['ID']['input']
}>

export type IntegrationVendorsForBulkImportQuery = {
  readonly __typename: 'Query'
  readonly integrationVendors: ReadonlyArray<{
    readonly __typename: 'IntegrationVendor'
    readonly integrationVendorId: String['output']
    readonly name: String['output']
    readonly address: String['output'] | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'IntegrationVendorContact'
      readonly integrationVendorContactId: String['output']
      readonly name: String['output'] | null
      readonly title: String['output'] | null
      readonly email: EmailAddress['output'] | null
      readonly phoneNumber: PhoneNumber['output'] | null
    }>
  }>
}

export type CompanyForAddVendorsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyForAddVendorsQuery = {
  readonly __typename: 'Query'
  readonly company: {
    readonly __typename: 'Company'
    readonly id: ID['output']
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly archivedAt: DateTime['output'] | null
    }>
    readonly vendors: ReadonlyArray<{
      readonly __typename: 'Vendor'
      readonly id: ID['output']
      readonly name: String['output']
      readonly integrationMappings: JSONObject['output']
      readonly internalNotes: String['output'] | null
      readonly address: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      } | null
      readonly contacts: ReadonlyArray<{
        readonly __typename: 'VendorContact'
        readonly id: ID['output']
        readonly fullName: String['output']
        readonly email: EmailAddress['output']
        readonly phoneNumber: PhoneNumber['output'] | null
        readonly jobTitle: String['output'] | null
        readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
      }>
      readonly companyIntegrations: ReadonlyArray<{
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly type: IntegrationType
        readonly label: String['output'] | null
      }>
    }>
  }
}

export type CreateVendorsMutationVariables = Exact<{
  input: CreateVendorsInput
}>

export type CreateVendorsMutation = {
  readonly __typename: 'Mutation'
  readonly createVendors: ReadonlyArray<{
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
    readonly internalNotes: String['output'] | null
    readonly address: {
      readonly __typename: 'Location'
      readonly id: ID['output']
      readonly createdAt: DateTime['output']
      readonly nickname: String['output'] | null
      readonly street1: String['output'] | null
      readonly street2: String['output'] | null
      readonly city: String['output']
      readonly county: String['output'] | null
      readonly state: String['output']
      readonly country: String['output']
      readonly postalCode: String['output'] | null
      readonly timeZone: String['output']
      readonly coordinates: LatLng['output']
    } | null
    readonly contacts: ReadonlyArray<{
      readonly __typename: 'VendorContact'
      readonly id: ID['output']
      readonly fullName: String['output']
      readonly email: EmailAddress['output']
      readonly phoneNumber: PhoneNumber['output'] | null
      readonly jobTitle: String['output'] | null
      readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
    }>
    readonly companyIntegrations: ReadonlyArray<{
      readonly __typename: 'CompanyIntegration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly label: String['output'] | null
    }>
  }>
}

export type GetContractForProjectSettingsQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForProjectSettingsQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly lienWaiverThroughDate: SafeInt['output'] | null
    readonly timeZone: String['output']
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly legalRequirements: ReadonlyArray<{
      readonly __typename: 'LegalRequirement'
      readonly id: ID['output']
      readonly name: String['output']
      readonly updatedAt: DateTime['output']
      readonly expirationFrequency: DocumentExpirationFrequency
      readonly startDate: DateTime['output'] | null
      readonly endDate: DateTime['output'] | null
      readonly skippedPeriods: ReadonlyArray<String['output']>
      readonly isVendorRequirement: Boolean['output']
      readonly type: DocumentType
      readonly timeZone: String['output']
      readonly vendorTemplate: {
        readonly __typename: 'StoredFile'
        readonly id: ID['output']
        readonly name: String['output']
        readonly url: URL['output']
      } | null
    }>
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            }
          }>
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            }
          }>
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            }
          }>
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly id: String['output']
          readonly isCustomerReady: Boolean['output']
          readonly versions: ReadonlyArray<{
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly versionNumber: Int['output']
            readonly file: {
              readonly __typename: 'StoredFile'
              readonly id: ID['output']
              readonly type: StoredFileType
              readonly url: URL['output']
              readonly name: String['output']
              readonly size: SafeInt['output']
            }
          }>
        }
      } | null
    } | null
    readonly onboardedStatus: {
      readonly __typename: 'OnboardedProjectContractStatus'
      readonly selectedPayAppForms: Boolean['output']
      readonly onboardedPayAppForms: Boolean['output']
      readonly selectedPrimaryLienWaivers: Boolean['output']
      readonly onboardedPrimaryLienWaiverForms: Boolean['output']
      readonly selectedVendorLienWaivers: Boolean['output']
      readonly onboardedVendorLienWaiverForms: Boolean['output']
      readonly selectedChangeOrderRequestForms: Boolean['output']
      readonly onboardedChangeOrderRequestForms: Boolean['output']
      readonly selectedChangeOrderLogForms: Boolean['output']
      readonly onboardedChangeOrderLogForms: Boolean['output']
      readonly notifiedOnboardedForms: Boolean['output']
      readonly selectedRateTable: Boolean['output']
      readonly addedSov: Boolean['output']
      readonly addedTeammates: Boolean['output']
      readonly addedGcContacts: Boolean['output']
      readonly startedBilling: Boolean['output']
      readonly isComplete: Boolean['output']
      readonly isReadyForPayAppSubmit: Boolean['output']
    }
    readonly payApps: ReadonlyArray<{ readonly __typename: 'PayApp'; readonly id: ID['output'] }>
    readonly project: {
      readonly __typename: 'Project'
      readonly id: ID['output']
      readonly name: String['output']
      readonly projectNumber: String['output']
      readonly location: {
        readonly __typename: 'Location'
        readonly id: ID['output']
        readonly createdAt: DateTime['output']
        readonly nickname: String['output'] | null
        readonly street1: String['output'] | null
        readonly street2: String['output'] | null
        readonly city: String['output']
        readonly county: String['output'] | null
        readonly state: String['output']
        readonly country: String['output']
        readonly postalCode: String['output'] | null
        readonly timeZone: String['output']
        readonly coordinates: LatLng['output']
      }
      readonly generalContractor: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly owner: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
      readonly architect: {
        readonly __typename: 'CompanyProjectMetadata'
        readonly companyName: String['output'] | null
        readonly company: {
          readonly __typename: 'Company'
          readonly id: ID['output']
          readonly name: String['output']
        }
        readonly selectedAddress: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
      } | null
    }
    readonly vendorContracts: ReadonlyArray<{
      readonly __typename: 'VendorContract'
      readonly includeInSwornStatements: Boolean['output']
      readonly id: ID['output']
      readonly preliminaryNotices: ReadonlyArray<{
        readonly __typename: 'VendorPreliminaryNotice'
        readonly id: ID['output']
        readonly date: DateTime['output']
        readonly file: {
          readonly __typename: 'StoredFile'
          readonly id: ID['output']
          readonly type: StoredFileType
          readonly url: URL['output']
          readonly name: String['output']
          readonly size: SafeInt['output']
        }
      }>
      readonly vendor: {
        readonly __typename: 'Vendor'
        readonly id: ID['output']
        readonly name: String['output']
        readonly integrationMappings: JSONObject['output']
        readonly internalNotes: String['output'] | null
        readonly address: {
          readonly __typename: 'Location'
          readonly id: ID['output']
          readonly createdAt: DateTime['output']
          readonly nickname: String['output'] | null
          readonly street1: String['output'] | null
          readonly street2: String['output'] | null
          readonly city: String['output']
          readonly county: String['output'] | null
          readonly state: String['output']
          readonly country: String['output']
          readonly postalCode: String['output'] | null
          readonly timeZone: String['output']
          readonly coordinates: LatLng['output']
        } | null
        readonly contacts: ReadonlyArray<{
          readonly __typename: 'VendorContact'
          readonly id: ID['output']
          readonly fullName: String['output']
          readonly email: EmailAddress['output']
          readonly phoneNumber: PhoneNumber['output'] | null
          readonly jobTitle: String['output'] | null
          readonly lowerTierTo: { readonly __typename: 'Vendor'; readonly id: ID['output'] } | null
        }>
        readonly companyIntegrations: ReadonlyArray<{
          readonly __typename: 'CompanyIntegration'
          readonly id: ID['output']
          readonly type: IntegrationType
          readonly label: String['output'] | null
        }>
      }
      readonly vendorLegalRequirements: ReadonlyArray<{
        readonly __typename: 'VendorLegalRequirement'
        readonly id: ID['output']
        readonly legalRequirement: {
          readonly __typename: 'LegalRequirement'
          readonly id: ID['output']
          readonly name: String['output']
          readonly updatedAt: DateTime['output']
          readonly expirationFrequency: DocumentExpirationFrequency
          readonly startDate: DateTime['output'] | null
          readonly endDate: DateTime['output'] | null
          readonly skippedPeriods: ReadonlyArray<String['output']>
          readonly isVendorRequirement: Boolean['output']
          readonly type: DocumentType
          readonly timeZone: String['output']
        }
      }>
      readonly lowerTierTo: {
        readonly __typename: 'VendorContract'
        readonly id: ID['output']
        readonly vendor: {
          readonly __typename: 'Vendor'
          readonly id: ID['output']
          readonly name: String['output']
        }
      } | null
    }>
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type GetContractForVendorContactQueryVariables = Exact<{
  input: GetContractByProjectIdInput
}>

export type GetContractForVendorContactQuery = {
  readonly __typename: 'Query'
  readonly contractByProjectId: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly role: CompanyProjectRole
    readonly status: ContractStatus
    readonly roundRetention: Boolean['output']
    readonly internalProjectNumber: String['output'] | null
    readonly retentionTrackingLevel: RetentionTrackingLevel
    readonly defaultRetentionPercent: Float['output'] | null
    readonly skippedPayAppMonths: ReadonlyArray<String['output']>
    readonly daysBeforePayAppDue: Int['output']
    readonly billingType: BillingType
    readonly pastPayAppCount: Int['output']
    readonly preSitelineBilled: SafeInt['output'] | null
    readonly vendorNumber: String['output'] | null
    readonly showAuditItemOnSignedPackages: Boolean['output']
    readonly paymentTermsType: ContractPaymentTermsType | null
    readonly paymentTerms: SafeInt['output'] | null
    readonly timeZone: String['output']
    readonly project: { readonly __typename: 'Project'; readonly id: ID['output'] }
    readonly company: { readonly __typename: 'Company'; readonly id: ID['output'] }
    readonly integrations: ReadonlyArray<{
      readonly __typename: 'Integration'
      readonly id: ID['output']
      readonly type: IntegrationType
      readonly metadata: JSONObject['output']
      readonly mappings: JSONObject['output']
      readonly shortName: String['output']
      readonly longName: String['output']
      readonly isActive: Boolean['output']
      readonly companyIntegration: {
        readonly __typename: 'CompanyIntegration'
        readonly id: ID['output']
        readonly metadata: JSONObject['output']
      }
    }>
  }
}

export type LienWaiverFormTemplateProperties = {
  readonly __typename: 'FormTemplate'
  readonly id: String['output']
  readonly latestVersion: {
    readonly __typename: 'FormTemplateVersion'
    readonly id: ID['output']
    readonly annotations: ReadonlyArray<{
      readonly __typename: 'FormTemplateAnnotation'
      readonly id: ID['output']
      readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
    }>
  } | null
}

export type ContractForLowerTierTemplatesQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ContractForLowerTierTemplatesQuery = {
  readonly __typename: 'Query'
  readonly contract: {
    readonly __typename: 'Contract'
    readonly id: ID['output']
    readonly lowerTierLienWaiverTemplates: {
      readonly __typename: 'SubcontractorLienWaiverTemplateSet'
      readonly id: ID['output']
      readonly conditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly isCustomerReady: Boolean['output']
          readonly id: String['output']
          readonly latestVersion: {
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            }>
          } | null
        }
      } | null
      readonly conditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly isCustomerReady: Boolean['output']
          readonly id: String['output']
          readonly latestVersion: {
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            }>
          } | null
        }
      } | null
      readonly unconditionalFinalVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly isCustomerReady: Boolean['output']
          readonly id: String['output']
          readonly latestVersion: {
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            }>
          } | null
        }
      } | null
      readonly unconditionalProgressVariant: {
        readonly __typename: 'FormTemplateVariant'
        readonly id: ID['output']
        readonly template: {
          readonly __typename: 'FormTemplate'
          readonly isCustomerReady: Boolean['output']
          readonly id: String['output']
          readonly latestVersion: {
            readonly __typename: 'FormTemplateVersion'
            readonly id: ID['output']
            readonly annotations: ReadonlyArray<{
              readonly __typename: 'FormTemplateAnnotation'
              readonly id: ID['output']
              readonly fieldType: FormTemplateAnnotationMetadataFieldType | null
            }>
          } | null
        }
      } | null
    } | null
  }
}

export type VendorsForIntegrationLinkingQueryVariables = Exact<{
  input: GetVendorsInput
}>

export type VendorsForIntegrationLinkingQuery = {
  readonly __typename: 'Query'
  readonly vendors: ReadonlyArray<{
    readonly __typename: 'Vendor'
    readonly id: ID['output']
    readonly name: String['output']
    readonly integrationMappings: JSONObject['output']
  }>
}

export const MinimalUserPropertiesDoc = gql`
  fragment MinimalUserProperties on User {
    id
    firstName
    lastName
    jobTitle
    email
    policiesAcceptedAt
  }
`
export const MinimalContractUserPropertiesDoc = gql`
  fragment MinimalContractUserProperties on ContractUser {
    id
    role
    user {
      ...MinimalUserProperties
    }
  }
  ${MinimalUserPropertiesDoc}
`
export const CompanyIntegrationPropertiesDoc = gql`
  fragment CompanyIntegrationProperties on CompanyIntegration {
    id
    type
    archivedAt
    shortName
    longName
    metadata
  }
`
export const LocationPropertiesDoc = gql`
  fragment LocationProperties on Location {
    id
    createdAt
    nickname
    street1
    street2
    city
    county
    state
    country
    postalCode
    timeZone
    coordinates
  }
`
export const CompanyPropertiesDoc = gql`
  fragment CompanyProperties on Company {
    id
    name
    nickname
    type
    phoneNumber
    companyIntegrations {
      ...CompanyIntegrationProperties
    }
    defaultShowAuditItemOnSignedPackages
    agingIntervalType
    defaultRetentionPercent
    defaultPayAppDueOnDayOfMonth
    defaultDaysBeforePayAppDue
    enableBillingWorksheets
    allowLumpSumOverbilling
    vendorLienWaiverReminderFrequency
    picture {
      id
      url
    }
    locations {
      ...LocationProperties
    }
  }
  ${CompanyIntegrationPropertiesDoc}
  ${LocationPropertiesDoc}
`
export const SignaturePropertiesDoc = gql`
  fragment SignatureProperties on Signature {
    id
    signatureName
    signatureFont
    file {
      id
      url
    }
  }
`
export const UserPropertiesDoc = gql`
  fragment UserProperties on User {
    ...MinimalUserProperties
    companies {
      ...CompanyProperties
    }
    defaultSignature {
      ...SignatureProperties
    }
    tutorialsShown
  }
  ${MinimalUserPropertiesDoc}
  ${CompanyPropertiesDoc}
  ${SignaturePropertiesDoc}
`
export const WorksheetLineItemPropertiesDoc = gql`
  fragment WorksheetLineItemProperties on WorksheetLineItem {
    id
    code
    name
    costCode
    totalValue
    previousBilled
    unitPrice
    unitName
    billedToDate
    sortOrder
    sovLineItem {
      id
    }
  }
`
export const TaxGroupPropertiesDoc = gql`
  fragment TaxGroupProperties on TaxGroup {
    id
    name
    taxPercent
    integrationMappings
  }
`
export const SovLineItemPropertiesDoc = gql`
  fragment SovLineItemProperties on SovLineItem {
    id
    code
    sortOrder
    name
    costCode
    isChangeOrder
    changeOrderRequests {
      id
      name
      internalNumber
    }
    changeOrderApprovedDate
    changeOrderEffectiveDate
    originalTotalValue
    latestTotalValue
    previousBilled
    billedToDate
    defaultRetentionPercent
    preSitelineRetentionHeldOverride
    preSitelineRetention
    unitName
    unitPrice
    worksheetLineItems {
      ...WorksheetLineItemProperties
    }
    taxGroup {
      ...TaxGroupProperties
    }
  }
  ${WorksheetLineItemPropertiesDoc}
  ${TaxGroupPropertiesDoc}
`
export const SovLineItemWithTotalsPropertiesDoc = gql`
  fragment SovLineItemWithTotalsProperties on SovLineItem {
    ...SovLineItemProperties
    billedToDate
    totalRetention
    preSitelineRetention
  }
  ${SovLineItemPropertiesDoc}
`
export const MinimalSovPropertiesDoc = gql`
  fragment MinimalSovProperties on Sov {
    id
    totalValue
    totalBilled
    totalRetention
    progressComplete
  }
`
export const SovPropertiesDoc = gql`
  fragment SovProperties on Sov {
    ...MinimalSovProperties
    lineItems {
      ...SovLineItemProperties
    }
  }
  ${MinimalSovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
`
export const PayAppCommentPropertiesDoc = gql`
  fragment PayAppCommentProperties on PayAppComment {
    id
    message
    createdAt
    user {
      id
      isSitelineTeamMember
      firstName
      lastName
    }
  }
`
export const ChangeOrderRequestEventPropertiesDoc = gql`
  fragment ChangeOrderRequestEventProperties on ChangeOrderRequestEvent {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    type
    metadata
  }
`
export const ChangeOrderRequestCommentPropertiesDoc = gql`
  fragment ChangeOrderRequestCommentProperties on ChangeOrderRequestComment {
    id
    message
    createdAt
    user {
      id
      isSitelineTeamMember
      firstName
      lastName
    }
  }
`
export const ContractEventPropertiesDoc = gql`
  fragment ContractEventProperties on ContractEvent {
    id
    createdAt
    isAdmin
    type
    metadata
    createdBy {
      id
      firstName
      lastName
    }
  }
`
export const ContractCommentPropertiesDoc = gql`
  fragment ContractCommentProperties on ContractComment {
    id
    createdAt
    user {
      id
      isSitelineTeamMember
      firstName
      lastName
    }
    type
    message
  }
`
export const RateTableFeePropertiesDoc = gql`
  fragment RateTableFeeProperties on ContractRateTableFee {
    id
    description
    percent
    group {
      id
    }
    overridesFee {
      id
    }
  }
`
export const RateTableGroupPropertiesDoc = gql`
  fragment RateTableGroupProperties on RateTableGroup {
    id
    name
    type
  }
`
export const RateTableItemPropertiesDoc = gql`
  fragment RateTableItemProperties on RateTableItem {
    id
    code
    sortOrder
    description
    unitName
    unitRate
    group {
      id
      name
      type
    }
  }
`
export const RateTablePropertiesDoc = gql`
  fragment RateTableProperties on RateTable {
    id
    name
    status
    effectiveAt
    expiresAt
    lastEditedAt
    lastEditedBy {
      id
      firstName
      lastName
    }
    groups {
      ...RateTableGroupProperties
    }
    items {
      ...RateTableItemProperties
    }
    company {
      id
    }
    contracts {
      id
      internalProjectNumber
      billingType
      project {
        id
        projectNumber
        name
      }
      payApps {
        id
        hasContractRateTableChanged
      }
      changeOrderRequests {
        id
        hasContractRateTableChanged
      }
    }
    associatedContracts {
      id
      internalProjectNumber
      billingType
      project {
        id
        projectNumber
        name
      }
      rateTable {
        id
      }
      payApps {
        id
        hasContractRateTableChanged
        rateTable {
          id
        }
      }
      changeOrderRequests {
        id
        hasContractRateTableChanged
        rateTable {
          id
        }
      }
    }
  }
  ${RateTableGroupPropertiesDoc}
  ${RateTableItemPropertiesDoc}
`
export const MinimalPreSitelinePayAppPropertiesDoc = gql`
  fragment MinimalPreSitelinePayAppProperties on PreSitelinePayApp {
    id
    billingEnd
    status
    currentBilled
    retentionOnly
  }
`
export const StoredFilePropertiesDoc = gql`
  fragment StoredFileProperties on StoredFile {
    id
    type
    url
    name
    size
  }
`
export const VendorContractCommitmentPropertiesDoc = gql`
  fragment VendorContractCommitmentProperties on VendorContractCommitment {
    id
    vendorContract {
      id
      vendor {
        id
        name
      }
      payAppSwornStatements {
        id
        totalCommitmentAmount
      }
    }
    commitmentType
    amount
    date
    number
    payWhenPaid
    paymentTerms
    file {
      ...StoredFileProperties
    }
    closedAt
  }
  ${StoredFilePropertiesDoc}
`
export const PayAppSwornStatementVendorUpdatePropertiesDoc = gql`
  fragment PayAppSwornStatementVendorUpdateProperties on PayAppSwornStatementVendorUpdate {
    id
    updatedAt
    updatedFromIntegrationType
    integrationInvoicesThroughDate
    manuallyUpdatedBy {
      id
    }
  }
`
export const PayAppSwornStatementVendorPropertiesDoc = gql`
  fragment PayAppSwornStatementVendorProperties on PayAppSwornStatementVendor {
    id
    totalCommitmentAmount
    amountPaidToDate
    amountOutstandingToDate
    lastUpdate {
      ...PayAppSwornStatementVendorUpdateProperties
    }
    vendorContract {
      id
      vendor {
        id
        name
      }
    }
  }
  ${PayAppSwornStatementVendorUpdatePropertiesDoc}
`
export const SovLineItemProgressEventPropertiesDoc = gql`
  fragment SovLineItemProgressEventProperties on SovLineItemProgressEvent {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
      jobTitle
      email
    }
    isAdmin
    type
    metadata
  }
`
export const MinimalLegalRequirementPropertiesDoc = gql`
  fragment MinimalLegalRequirementProperties on LegalRequirement {
    id
    name
    updatedAt
    expirationFrequency
    startDate
    endDate
    skippedPeriods
    isVendorRequirement
    type
    timeZone
  }
`
export const MinimalComplianceLegalRequirementPropertiesDoc = gql`
  fragment MinimalComplianceLegalRequirementProperties on LegalRequirement {
    ...MinimalLegalRequirementProperties
    documents {
      id
      file {
        id
        name
        type
      }
    }
    formTemplate {
      id
      userVisibleName
    }
  }
  ${MinimalLegalRequirementPropertiesDoc}
`
export const MinimalVendorContractPropertiesDoc = gql`
  fragment MinimalVendorContractProperties on VendorContract {
    id
    vendor {
      id
      name
    }
    lowerTierTo {
      id
      vendor {
        id
        name
      }
    }
  }
`
export const MinimalLegalDocumentPropertiesDoc = gql`
  fragment MinimalLegalDocumentProperties on LegalDocument {
    id
    createdAt
    name
    periodStart
    periodEnd
    file {
      ...StoredFileProperties
    }
  }
  ${StoredFilePropertiesDoc}
`
export const MinimalWriteSyncOperationPropertiesDoc = gql`
  fragment MinimalWriteSyncOperationProperties on WriteSyncOperation {
    id
    createdAt
    status
    payload
    result
    integration {
      id
      type
      mappings
      shortName
    }
  }
`
export const VendorContactPropertiesDoc = gql`
  fragment VendorContactProperties on VendorContact {
    id
    fullName
    email
    phoneNumber
    jobTitle
    lowerTierTo {
      id
    }
  }
`
export const LegalDocumentRequestPropertiesDoc = gql`
  fragment LegalDocumentRequestProperties on LegalDocumentRequest {
    id
    createdAt
    updatedAt
    vendorContact {
      ...VendorContactProperties
    }
    status
    actionTakenAt
  }
  ${VendorContactPropertiesDoc}
`
export const LegalDocumentPropertiesDoc = gql`
  fragment LegalDocumentProperties on LegalDocument {
    ...MinimalLegalDocumentProperties
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    lastSendTime
    isUploaded
    uploadedBy {
      id
      firstName
      lastName
      email
    }
    legalDocumentRequests {
      ...LegalDocumentRequestProperties
    }
    vendorContract {
      ...MinimalVendorContractProperties
    }
  }
  ${MinimalLegalDocumentPropertiesDoc}
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${LegalDocumentRequestPropertiesDoc}
  ${MinimalVendorContractPropertiesDoc}
`
export const LegalRequirementPropertiesDoc = gql`
  fragment LegalRequirementProperties on LegalRequirement {
    ...MinimalLegalRequirementProperties
    missingRequiredFields
    formTemplate {
      id
      userVisibleName
      isCustomerReady
    }
    documents {
      ...LegalDocumentProperties
    }
  }
  ${MinimalLegalRequirementPropertiesDoc}
  ${LegalDocumentPropertiesDoc}
`
export const CompanyContactPropertiesDoc = gql`
  fragment CompanyContactProperties on CompanyContact {
    id
    createdAt
    fullName
    email
    companyName
    phoneNumber
    jobTitle
  }
`
export const VendorLegalRequirementPropertiesDoc = gql`
  fragment VendorLegalRequirementProperties on VendorLegalRequirement {
    id
    skippedPeriods
    vendorContract {
      ...MinimalVendorContractProperties
    }
    legalRequirement {
      ...LegalRequirementProperties
      contract {
        id
        company {
          id
        }
        project {
          id
          name
        }
        legalRequirementContacts {
          ...CompanyContactProperties
        }
      }
    }
  }
  ${MinimalVendorContractPropertiesDoc}
  ${LegalRequirementPropertiesDoc}
  ${CompanyContactPropertiesDoc}
`
export const VendorLegalRequirementForTrackerPropertiesDoc = gql`
  fragment VendorLegalRequirementForTrackerProperties on VendorLegalRequirement {
    id
    skippedPeriods
    vendorContract {
      ...MinimalVendorContractProperties
    }
    legalRequirement {
      ...MinimalLegalRequirementProperties
      contract {
        id
        company {
          id
        }
        project {
          id
          name
        }
        legalRequirementContacts {
          ...CompanyContactProperties
        }
      }
      documents {
        ...LegalDocumentProperties
      }
    }
  }
  ${MinimalVendorContractPropertiesDoc}
  ${MinimalLegalRequirementPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${LegalDocumentPropertiesDoc}
`
export const MinimalIntegrationPropertiesDoc = gql`
  fragment MinimalIntegrationProperties on Integration {
    id
    type
    metadata
    mappings
    shortName
    longName
    isActive
    companyIntegration {
      id
      metadata
    }
  }
`
export const OnboardedProjectComplianceStatusPropertiesDoc = gql`
  fragment OnboardedProjectComplianceStatusProperties on OnboardedProjectComplianceStatus {
    addedLegalRequirements
    addedGcRecipients
    completedOnboarding
  }
`
export const ContractForComplianceProjectHomeDoc = gql`
  fragment ContractForComplianceProjectHome on Contract {
    id
    internalProjectNumber
    status
    billingType
    complianceStatus
    vendorNumber
    project {
      id
      projectNumber
      name
      location {
        ...LocationProperties
      }
      generalContractor {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      owner {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      architect {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
    }
    defaultGcContacts {
      ...CompanyContactProperties
    }
    company {
      id
    }
    legalRequirements {
      ...LegalRequirementProperties
    }
    legalRequirementContacts {
      ...CompanyContactProperties
    }
    integrations {
      ...MinimalIntegrationProperties
    }
    onboardedProjectComplianceStatus {
      ...OnboardedProjectComplianceStatusProperties
    }
  }
  ${LocationPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${LegalRequirementPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
  ${OnboardedProjectComplianceStatusPropertiesDoc}
`
export const MinimalProjectPropertiesDoc = gql`
  fragment MinimalProjectProperties on Project {
    id
    name
    projectNumber
    timeZone
    generalContractor {
      companyName
      company {
        id
        name
      }
    }
    metadata {
      payAppDueOnDayOfMonth
    }
  }
`
export const SovLineItemGroupPropertiesDoc = gql`
  fragment SovLineItemGroupProperties on SovLineItemGroup {
    id
    name
    code
  }
`
export const PayAppRateTableItemPropertiesDoc = gql`
  fragment PayAppRateTableItemProperties on PayAppRateTableItem {
    id
    description
    code
    sortOrder
    unitName
    unitRate
    currentBilled
    currentUnitsBilled
    isOneOffItem
    group {
      id
    }
    attachments {
      id
    }
  }
`
export const PayAppAttachmentForBackupPropertiesDoc = gql`
  fragment PayAppAttachmentForBackupProperties on PayAppAttachment {
    id
    description
    name
    createdAt
    uploadId
    isEmailAttachment
    isInternalOnly
    file {
      ...StoredFileProperties
    }
    sovLineItemProgress {
      id
      storedMaterialBilled
      progressBilled
      currentBilled
      retentionHeldPercent
      sovLineItem {
        id
        sortOrder
        code
        name
        isChangeOrder
        sovLineItemGroup {
          ...SovLineItemGroupProperties
        }
      }
    }
    contractPayAppAttachment {
      id
    }
    rateTableItems {
      ...PayAppRateTableItemProperties
    }
  }
  ${StoredFilePropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
  ${PayAppRateTableItemPropertiesDoc}
`
export const MinimalVendorPropertiesDoc = gql`
  fragment MinimalVendorProperties on Vendor {
    id
    name
    integrationMappings
    internalNotes
    address {
      ...LocationProperties
    }
    contacts {
      ...VendorContactProperties
    }
    companyIntegrations {
      id
      type
      label
    }
  }
  ${LocationPropertiesDoc}
  ${VendorContactPropertiesDoc}
`
export const VendorPropertiesDoc = gql`
  fragment VendorProperties on Vendor {
    ...MinimalVendorProperties
    company {
      id
    }
    vendorContracts {
      ...MinimalVendorContractProperties
      contract {
        id
        project {
          id
        }
      }
    }
  }
  ${MinimalVendorPropertiesDoc}
  ${MinimalVendorContractPropertiesDoc}
`
export const LienWaiverForTrackerPropertiesDoc = gql`
  fragment LienWaiverForTrackerProperties on LienWaiver {
    id
    type
    date
    defaultAmount
    amount
    lastSendTime
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    uploadedFile {
      id
      url
      type
      name
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      createdAt
    }
    vendorContract {
      ...MinimalVendorContractProperties
      vendor {
        id
        name
      }
    }
    lienWaiverRequests {
      id
      createdAt
      updatedAt
      vendorContact {
        ...VendorContactProperties
      }
      status
      statusLogs {
        id
        status
        statusUpdatedAt
      }
      actionTakenAt
    }
  }
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${MinimalVendorContractPropertiesDoc}
  ${VendorContactPropertiesDoc}
`
export const VendorContractForLienWaiversPropertiesDoc = gql`
  fragment VendorContractForLienWaiversProperties on VendorContract {
    ...MinimalVendorContractProperties
    includeInSwornStatements
    vendor {
      id
      name
      integrationMappings
    }
    preliminaryNotices {
      id
    }
    contract {
      id
      internalProjectNumber
      project {
        id
        name
        projectNumber
        generalContractor {
          company {
            id
            name
          }
        }
      }
    }
  }
  ${MinimalVendorContractPropertiesDoc}
`
export const LienWaiverForTrackerCellPropertiesDoc = gql`
  fragment LienWaiverForTrackerCellProperties on LienWaiver {
    id
    type
    date
    defaultAmount
    amount
    lastSendTime
    timeZone
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    contract {
      id
      project {
        id
      }
    }
    uploadedFile {
      id
      url
      type
      name
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      createdAt
    }
    lienWaiverRequests {
      id
      createdAt
      updatedAt
      vendorContact {
        ...VendorContactProperties
      }
      status
      statusLogs {
        id
        status
        statusUpdatedAt
      }
      actionTakenAt
    }
  }
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${VendorContactPropertiesDoc}
`
export const NotificationPropertiesDoc = gql`
  fragment NotificationProperties on Notification {
    id
    createdAt
    type
    message
    link
    contract {
      id
      timeZone
      project {
        id
        name
      }
      company {
        id
        nickname
        name
      }
    }
    read
    createdByUserInitials
  }
`
export const AmountLineItemPropertiesDoc = gql`
  fragment AmountLineItemProperties on AmountLineItem {
    id
    description
    amount
    amountDuePreTax
    amountDueTaxAmount
    amountDuePostTax
    taxGroup {
      ...TaxGroupProperties
    }
    sortOrder
  }
  ${TaxGroupPropertiesDoc}
`
export const CompanyUserEmailAliasPropertiesDoc = gql`
  fragment CompanyUserEmailAliasProperties on CompanyUserEmailAlias {
    id
    emailAddress
    isVerified
    verificationCodeExpiresAt
  }
`
export const CompanyUserPropertiesDoc = gql`
  fragment CompanyUserProperties on CompanyUser {
    id
    role
    isSitelineAdmin
    status
    permissions
    emailAlias {
      ...CompanyUserEmailAliasProperties
    }
    user {
      ...MinimalUserProperties
    }
    companyLocation {
      id
      nickname
      street1
      city
    }
  }
  ${CompanyUserEmailAliasPropertiesDoc}
  ${MinimalUserPropertiesDoc}
`
export const OnboardingFormTemplatePropertiesDoc = gql`
  fragment OnboardingFormTemplateProperties on FormTemplate {
    id
    status
    isCustomerReady
    userVisibleName
    type
  }
`
export const FormTemplatePropertiesDoc = gql`
  fragment FormTemplateProperties on FormTemplate {
    id
    isCustomerReady
    versions {
      id
      versionNumber
      file {
        ...StoredFileProperties
      }
    }
  }
  ${StoredFilePropertiesDoc}
`
export const SubcontractorLienWaiverTemplateSetPropertiesDoc = gql`
  fragment SubcontractorLienWaiverTemplateSetProperties on SubcontractorLienWaiverTemplateSet {
    id
    conditionalFinalVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
    conditionalProgressVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
    unconditionalFinalVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
    unconditionalProgressVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
  }
  ${FormTemplatePropertiesDoc}
`
export const DraftLocationPropertiesDoc = gql`
  fragment DraftLocationProperties on DraftLocation {
    street1
    street2
    city
    county
    state
    country
    postalCode
    coordinates
    timeZone
  }
`
export const IntegrationProjectDetailsPropertiesDoc = gql`
  fragment IntegrationProjectDetailsProperties on IntegrationProjectDetails {
    integrationProjectId
    projectName
    projectAddress {
      ...DraftLocationProperties
    }
    projectNumber
    internalProjectNumber
    sitelineLeadPMIds
  }
  ${DraftLocationPropertiesDoc}
`
export const IntegrationContractPropertiesDoc = gql`
  fragment IntegrationContractProperties on IntegrationContract {
    integrationContractId
    contractNumber
    contractDate
    latestPayAppNumber
    payAppDueDate
    retentionPercent
    retentionTrackingLevel
    roundRetention
    billingType
    paymentTermsType
    paymentTerms
    preSitelineBilled
  }
`
export const IntegrationCompanyPropertiesDoc = gql`
  fragment IntegrationCompanyProperties on IntegrationCompany {
    companyName
    companyAddress {
      ...DraftLocationProperties
    }
  }
  ${DraftLocationPropertiesDoc}
`
export const IntegrationTaxGroupPropertiesDoc = gql`
  fragment IntegrationTaxGroupProperties on IntegrationTaxGroup {
    integrationTaxGroupId
    name
    taxPercent
  }
`
export const ImportProjectOnboardingMetadataPropertiesDoc = gql`
  fragment ImportProjectOnboardingMetadataProperties on ImportProjectOnboardingMetadata {
    type
    companyIntegrationId
    integrationAssociatedCompanyId
    project {
      ...IntegrationProjectDetailsProperties
    }
    contract {
      ...IntegrationContractProperties
    }
    generalContractor {
      ...IntegrationCompanyProperties
    }
    owner {
      ...IntegrationCompanyProperties
    }
    architect {
      ...IntegrationCompanyProperties
    }
    taxCalculationType
    taxGroup {
      ...IntegrationTaxGroupProperties
    }
  }
  ${IntegrationProjectDetailsPropertiesDoc}
  ${IntegrationContractPropertiesDoc}
  ${IntegrationCompanyPropertiesDoc}
  ${IntegrationTaxGroupPropertiesDoc}
`
export const IntegrationProjectSummaryPropertiesDoc = gql`
  fragment IntegrationProjectSummaryProperties on IntegrationProjectSummary {
    integrationProjectId
    integrationContractId
    integrationAssociatedCompanyId
    projectName
    projectNumber
    generalContractorName
    contractName
    sitelineProjectId
    sitelineContractId
  }
`
export const MinimalChangeOrderRequestPropertiesDoc = gql`
  fragment MinimalChangeOrderRequestProperties on ChangeOrderRequest {
    id
    createdAt
    name
    internalNumber
    generalContractorNumber
    amount
    proposedAmount
    unitPrice
    unitName
    proposedAt
    scheduleImpact
    proceedingWithWork
    status
    statusChangedAt
    reason
    importedAt
    visibleToGc
    emailedContacts {
      ...CompanyContactProperties
    }
    linkedChangeOrderRequests {
      id
      internalNumber
      generalContractorNumber
      status
      amount
      sovLineItems {
        id
      }
      name
    }
    sovLineItems {
      id
    }
    attachments {
      id
    }
    approvedScoFile {
      id
    }
    contract {
      id
      changeOrderRequestTemplate {
        id
        isCustomerReady
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export const MinimalRateTablePropertiesDoc = gql`
  fragment MinimalRateTableProperties on RateTable {
    id
    name
    status
    effectiveAt
    expiresAt
    lastEditedAt
    lastEditedBy {
      id
      firstName
      lastName
    }
    contracts {
      id
    }
    associatedContracts {
      id
    }
  }
`
export const ChangeOrderRequestRateTableFeePropertiesDoc = gql`
  fragment ChangeOrderRequestRateTableFeeProperties on ChangeOrderRequestRateTableFee {
    id
    percent
    description
    group {
      id
    }
    overridesFee {
      id
    }
  }
`
export const ChangeOrderRequestRateTableGroupPropertiesDoc = gql`
  fragment ChangeOrderRequestRateTableGroupProperties on ChangeOrderRequestRateTableGroup {
    id
    name
    totalAmount
    subtotalAmount
    type
    fees {
      ...ChangeOrderRequestRateTableFeeProperties
    }
  }
  ${ChangeOrderRequestRateTableFeePropertiesDoc}
`
export const ChangeOrderRequestRateTableItemPropertiesDoc = gql`
  fragment ChangeOrderRequestRateTableItemProperties on ChangeOrderRequestRateTableItem {
    id
    description
    code
    sortOrder
    unitName
    unitRate
    currentPriced
    currentUnitsPriced
    isOneOffItem
    group {
      id
    }
  }
`
export const ChangeOrderRequestRateTablePropertiesDoc = gql`
  fragment ChangeOrderRequestRateTableProperties on ChangeOrderRequest {
    hasContractRateTableChanged
    haveContractFeesChanged
    rateTable {
      ...MinimalRateTableProperties
    }
    rateTableFees {
      ...ChangeOrderRequestRateTableFeeProperties
    }
    rateTableGroups {
      ...ChangeOrderRequestRateTableGroupProperties
    }
    rateTableItems {
      ...ChangeOrderRequestRateTableItemProperties
    }
  }
  ${MinimalRateTablePropertiesDoc}
  ${ChangeOrderRequestRateTableFeePropertiesDoc}
  ${ChangeOrderRequestRateTableGroupPropertiesDoc}
  ${ChangeOrderRequestRateTableItemPropertiesDoc}
`
export const ChangeOrderRequestAttachmentPropertiesDoc = gql`
  fragment ChangeOrderRequestAttachmentProperties on ChangeOrderRequestAttachment {
    id
    createdAt
    name
    uploadId
    description
    isInternalOnly
    file {
      ...StoredFileProperties
    }
  }
  ${StoredFilePropertiesDoc}
`
export const ChangeOrderRequestPropertiesDoc = gql`
  fragment ChangeOrderRequestProperties on ChangeOrderRequest {
    ...MinimalChangeOrderRequestProperties
    ...ChangeOrderRequestRateTableProperties
    missingRequiredFields
    approvedScoFile {
      ...StoredFileProperties
    }
    attachments {
      ...ChangeOrderRequestAttachmentProperties
    }
    sovLineItems {
      id
      name
      code
      latestTotalValue
      unitName
      unitPrice
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
  ${ChangeOrderRequestRateTablePropertiesDoc}
  ${StoredFilePropertiesDoc}
  ${ChangeOrderRequestAttachmentPropertiesDoc}
`
export const IntegrationCustomerPropertiesDoc = gql`
  fragment IntegrationCustomerProperties on IntegrationCustomer {
    integrationCustomerId
    name
    code
  }
`
export const UserEducationCampaignPropertiesDoc = gql`
  fragment UserEducationCampaignProperties on UserEducationCampaign {
    id
    name
    showNewTag
    title
    message
    buttonLabel
    buttonLink
  }
`
export const SovChangeSetPropertiesDoc = gql`
  fragment SovChangeSetProperties on SovChangeSet {
    additions {
      new {
        code
        name
        costCode
        originalTotalValue
        latestTotalValue
        previousBilled
        isChangeOrder
        sortOrder
        changeOrderApprovedDate
        changeOrderEffectiveDate
        sovLineItemGroupId
        previousStoredMaterials
        unitName
        unitPrice
        preSitelineRetentionHeldOverride
        defaultRetentionPercent
      }
    }
    updates {
      oldId
      new {
        code
        name
        costCode
        originalTotalValue
        latestTotalValue
        previousBilled
        isChangeOrder
        sortOrder
        changeOrderApprovedDate
        changeOrderEffectiveDate
        sovLineItemGroupId
        previousStoredMaterials
        unitName
        unitPrice
        preSitelineRetentionHeldOverride
        defaultRetentionPercent
      }
    }
    deletions {
      oldId
    }
    groupAdditions {
      newId
      new {
        name
        code
      }
    }
    groupUpdates {
      oldId
      new {
        name
        code
      }
    }
    groupDeletions {
      oldId
    }
  }
`
export const ContractForBillingDashboardPropertiesDoc = gql`
  fragment ContractForBillingDashboardProperties on Contract {
    id
    billingType
    internalProjectNumber
    selectedAddress {
      id
    }
    project {
      id
      name
      projectNumber
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
    leadPMs {
      id
      firstName
      lastName
    }
  }
`
export const ProjectMetricsPropertiesDoc = gql`
  fragment ProjectMetricsProperties on ProjectMetrics {
    amountBilledAndDraftThisMonth
    retentionHeldThisMonth
    retentionHeldThruMonth
    percentCompletedThruMonth
    balanceToFinish
    completedThruMonth
    totalValue
  }
`
export const LineItemMetricsPropertiesDoc = gql`
  fragment LineItemMetricsProperties on LineItemMetrics {
    number
    name
    amountBilledAndDraftThisMonth
    percentCompletedThruMonth
    balanceToFinish
    completedThruMonth
    totalValue
  }
`
export const BillingDashboardContractWithLineItemsPropertiesDoc = gql`
  fragment BillingDashboardContractWithLineItemsProperties on BillingDashboardContractWithLineItems {
    contract {
      ...ContractForBillingDashboardProperties
    }
    projectMetrics {
      ...ProjectMetricsProperties
    }
    lineItems {
      ...LineItemMetricsProperties
    }
    billingStatus
  }
  ${ContractForBillingDashboardPropertiesDoc}
  ${ProjectMetricsPropertiesDoc}
  ${LineItemMetricsPropertiesDoc}
`
export const BillingDashboardSummaryPropertiesDoc = gql`
  fragment BillingDashboardSummaryProperties on BillingDashboardSummary {
    projectsBilledThisMonth
    totalProjectsThisMonth
    projectsBilledMonthOverMonthPercent
    payAppStatusBreakdown {
      pastDue
      notBillingThisMonth
      draftOnTime
      submitted
    }
    amountBilledAndDraftThisMonth
    amountBilledAndDraftMonthOverMonthPercent
    amountBilledThisMonth
    amountBilledMonthOverMonthPercent
    amountInDraftThisMonth
    amountInDraftMonthOverMonthPercent
    retentionHeldThisMonth
    retentionHeldThruMonth
    retentionHeldMonthOverMonthPercent
    monthlyBillingBreakdown {
      month
      amountBilledAndDraftThisMonth
      amountBilledThisMonth
    }
  }
`
export const BillingDashboardContractPropertiesDoc = gql`
  fragment BillingDashboardContractProperties on BillingDashboardContract {
    contract {
      ...ContractForBillingDashboardProperties
    }
    projectMetrics {
      ...ProjectMetricsProperties
    }
    billingStatus
  }
  ${ContractForBillingDashboardPropertiesDoc}
  ${ProjectMetricsPropertiesDoc}
`
export const BillingDashboardPropertiesDoc = gql`
  fragment BillingDashboardProperties on BillingDashboard {
    billingSummary {
      ...BillingDashboardSummaryProperties
    }
    contracts {
      ...BillingDashboardContractProperties
    }
  }
  ${BillingDashboardSummaryPropertiesDoc}
  ${BillingDashboardContractPropertiesDoc}
`
export const AgingBreakdownPropertiesDoc = gql`
  fragment AgingBreakdownProperties on AgingBreakdown {
    numCurrent
    numAged30Days
    numAged60Days
    numAged90Days
    numAged120Days
    amountAgedTotal
    amountAgedCurrent
    amountAged30Days
    amountAged60Days
    amountAged90Days
    amountAged120Days
  }
`
export const PayAppAgingDashboardSummaryPropertiesDoc = gql`
  fragment PayAppAgingDashboardSummaryProperties on PayAppAgingDashboardSummary {
    amountOutstandingThisMonth
    amountOutstandingMonthOverMonthPercent
    amountAged30Days
    amountAged30DaysMonthOverMonthPercent
    amountAged60Days
    amountAged60DaysMonthOverMonthPercent
    amountAged90Days
    amountAged90DaysMonthOverMonthPercent
    amountAged120Days
    amountAged120DaysMonthOverMonthPercent
    averageDaysToPaid
    averageDaysToPaidMonthOverMonthPercent
    payAppAgingBreakdown {
      ...AgingBreakdownProperties
    }
  }
  ${AgingBreakdownPropertiesDoc}
`
export const PayAppAgingDashboardContractPropertiesDoc = gql`
  fragment PayAppAgingDashboardContractProperties on PayAppAgingDashboardContract {
    contract {
      id
      billingType
      internalProjectNumber
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      company {
        id
      }
      leadPMs {
        id
        firstName
        lastName
      }
    }
    agingBreakdown {
      ...AgingBreakdownProperties
    }
    billingStatus
    numComments
    hasMissingPreSitelinePayApps
  }
  ${AgingBreakdownPropertiesDoc}
`
export const AgingDashboardPropertiesDoc = gql`
  fragment AgingDashboardProperties on PayAppAgingDashboard {
    payAppAgingSummary {
      ...PayAppAgingDashboardSummaryProperties
    }
    contracts {
      ...PayAppAgingDashboardContractProperties
    }
  }
  ${PayAppAgingDashboardSummaryPropertiesDoc}
  ${PayAppAgingDashboardContractPropertiesDoc}
`
export const VendorLienWaiverDashboardSummaryPropertiesDoc = gql`
  fragment VendorLienWaiverDashboardSummaryProperties on VendorLienWaiverDashboardSummary {
    numOutstandingLienWaiversThisMonth
    outstandingVendors
    outstandingLienWaiversByMonth {
      month
      numOutstandingLienWaiversThisMonth
    }
  }
`
export const OverviewDashboardPropertiesDoc = gql`
  fragment OverviewDashboardProperties on OverviewDashboard {
    billingSummary {
      ...BillingDashboardSummaryProperties
    }
    payAppAgingSummary {
      ...PayAppAgingDashboardSummaryProperties
    }
    vendorSummary {
      ...VendorLienWaiverDashboardSummaryProperties
    }
  }
  ${BillingDashboardSummaryPropertiesDoc}
  ${PayAppAgingDashboardSummaryPropertiesDoc}
  ${VendorLienWaiverDashboardSummaryPropertiesDoc}
`
export const ContractOptedOutMonthPropertiesDoc = gql`
  fragment ContractOptedOutMonthProperties on ContractOptedOutMonth {
    id
    createdAt
    month
    reason
    user {
      id
      firstName
      lastName
    }
  }
`
export const MinimalContractPropertiesDoc = gql`
  fragment MinimalContractProperties on Contract {
    id
    role
    status
    roundRetention
    internalProjectNumber
    retentionTrackingLevel
    defaultRetentionPercent
    skippedPayAppMonths
    daysBeforePayAppDue
    billingType
    pastPayAppCount
    preSitelineBilled
    vendorNumber
    showAuditItemOnSignedPackages
    paymentTermsType
    paymentTerms
    timeZone
    company {
      id
    }
    integrations {
      ...MinimalIntegrationProperties
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`
export const OnboardedProjectContractStatusPropertiesDoc = gql`
  fragment OnboardedProjectContractStatusProperties on OnboardedProjectContractStatus {
    selectedPayAppForms
    onboardedPayAppForms
    selectedPrimaryLienWaivers
    onboardedPrimaryLienWaiverForms
    selectedVendorLienWaivers
    onboardedVendorLienWaiverForms
    selectedChangeOrderRequestForms
    onboardedChangeOrderRequestForms
    selectedChangeOrderLogForms
    onboardedChangeOrderLogForms
    notifiedOnboardedForms
    selectedRateTable
    addedSov
    addedTeammates
    addedGcContacts
    startedBilling
    isComplete
    isReadyForPayAppSubmit
  }
`
export const OnboardedProjectVendorsStatusPropertiesDoc = gql`
  fragment OnboardedProjectVendorsStatusProperties on OnboardedProjectVendorsStatus {
    addedVendorLienWaivers
    addedLegalRequirements
    addedVendors
    addedGcRecipients
    completedOnboarding
  }
`
export const MinimalFormTemplatePropertiesDoc = gql`
  fragment MinimalFormTemplateProperties on FormTemplate {
    id
    isCustomerReady
    versions {
      id
      versionNumber
    }
  }
`
export const MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc = gql`
  fragment MinimalSubcontractorLienWaiverTemplateSetProperties on SubcontractorLienWaiverTemplateSet {
    id
    conditionalFinalVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
    conditionalProgressVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
    unconditionalFinalVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
    unconditionalProgressVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
  }
  ${MinimalFormTemplatePropertiesDoc}
`
export const VendorPreliminaryNoticePropertiesDoc = gql`
  fragment VendorPreliminaryNoticeProperties on VendorPreliminaryNotice {
    id
    date
    file {
      ...StoredFileProperties
    }
  }
  ${StoredFilePropertiesDoc}
`
export const VendorContractPropertiesDoc = gql`
  fragment VendorContractProperties on VendorContract {
    ...MinimalVendorContractProperties
    includeInSwornStatements
    preliminaryNotices {
      ...VendorPreliminaryNoticeProperties
    }
    vendor {
      ...MinimalVendorProperties
    }
    vendorLegalRequirements {
      id
      legalRequirement {
        ...MinimalLegalRequirementProperties
      }
    }
  }
  ${MinimalVendorContractPropertiesDoc}
  ${VendorPreliminaryNoticePropertiesDoc}
  ${MinimalVendorPropertiesDoc}
  ${MinimalLegalRequirementPropertiesDoc}
`
export const ContractForVendorsProjectHomeDoc = gql`
  fragment ContractForVendorsProjectHome on Contract {
    ...MinimalContractProperties
    missingRequiredVendorLienWaiverFields
    vendorComplianceStatus
    vendorsInternalNotes
    company {
      id
    }
    legalRequirements {
      ...MinimalLegalRequirementProperties
      vendorTemplate {
        id
        name
        url
      }
    }
    defaultGcContacts {
      ...CompanyContactProperties
    }
    legalRequirementContacts {
      ...CompanyContactProperties
    }
    vendorSubmitToContacts {
      ...CompanyContactProperties
    }
    onboardedStatus {
      ...OnboardedProjectContractStatusProperties
    }
    onboardedProjectVendorsStatus {
      ...OnboardedProjectVendorsStatusProperties
    }
    project {
      id
      name
      projectNumber
      timeZone
      location {
        ...LocationProperties
      }
      generalContractor {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      owner {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      architect {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      metadata {
        payAppDueOnDayOfMonth
      }
    }
    timeZone
    payApps {
      id
      billingEnd
    }
    preSitelinePayApps {
      id
      billingEnd
      payAppNumber
    }
    integrations {
      ...MinimalIntegrationProperties
    }
    lowerTierLienWaiverTemplates {
      ...MinimalSubcontractorLienWaiverTemplateSetProperties
    }
    vendorContracts {
      ...VendorContractProperties
    }
  }
  ${MinimalContractPropertiesDoc}
  ${MinimalLegalRequirementPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${OnboardedProjectVendorsStatusPropertiesDoc}
  ${LocationPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${VendorContractPropertiesDoc}
`
export const PreSitelinePayAppForProjectHomePropertiesDoc = gql`
  fragment PreSitelinePayAppForProjectHomeProperties on PreSitelinePayApp {
    id
    createdAt
    timeZone
    retentionOnly
    payAppNumber
    billingStart
    billingEnd
    status
    payAppFile {
      ...StoredFileProperties
    }
    currentBilled
    paymentDue
    percentComplete
    isFinal
    paidAt
    amountPaid
    isBalanceManuallyClosed
    submittedUnconditionalOutsideOfSiteline
    submittedAt
    isNotarizingUnconditional
    unconditionalLienWaiver {
      id
    }
    contract {
      id
      averageDaysToPaid
    }
  }
  ${StoredFilePropertiesDoc}
`
export const ContractBillingForecastPropertiesDoc = gql`
  fragment ContractBillingForecastProperties on ContractBillingForecast {
    id
    startingMonth
    firstEditableMonthWhenCreated
    projectedAmountPerMonth
    remainingAmount
    curve
    lastEditedBy {
      id
      firstName
      lastName
    }
  }
`
export const AggregateForecastMonthPropertiesDoc = gql`
  fragment AggregateForecastMonthProperties on AggregateForecastMonth {
    month
    year
    projectedAmount
    amountBilled
  }
`
export const ContractCostPropertiesDoc = gql`
  fragment ContractCostProperties on ContractCost {
    id
    updatedAt
    updatedFromIntegrationType
    costToDate
    totalEstimatedCost
    additionalCommittedCost
    costPercentageCompleted
    earnedRevenue
    contractTotalValue
    contractTotalBilled
    billingsInExcessOfCost
    manuallyUpdatedBy {
      id
      firstName
      lastName
    }
  }
`
export const ContractForCashForecastPropertiesDoc = gql`
  fragment ContractForCashForecastProperties on Contract {
    id
    billingType
    internalProjectNumber
    isJointCheck
    invoiceAmountOutstanding
    outstandingVendorLienWaiverAmount
    timeZone
    leadPMs {
      id
    }
    selectedAddress {
      id
    }
    project {
      id
      name
      projectNumber
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
    payApps {
      id
      payAppNumber
      predictedPaymentDate
      currentBilled
      currentRetention
      previousRetentionBilled
      amountDuePostTax
      isBalanceManuallyClosed
      amountPaid
      status
      timeZone
      retentionOnly
      billingEnd
    }
    preSitelinePayApps {
      id
      payAppNumber
      status
      paymentDue
      retentionOnly
      billingEnd
      amountPaid
      isBalanceManuallyClosed
    }
  }
`
export const MinimalPayAppPropertiesDoc = gql`
  fragment MinimalPayAppProperties on PayApp {
    id
    timeZone
    payAppDueDate
    internalDueDate
    billingStart
    billingEnd
    billingType
    status
    invoiceReady
    retentionOnly
    currentBilled
    currentRetention
    previousRetentionBilled
    draftSubmittedAt
  }
`
export const PayAppNoProgressPropertiesDoc = gql`
  fragment PayAppNoProgressProperties on PayApp {
    ...MinimalPayAppProperties
    createdAt
    statusChangedAt
    lastSync {
      ...MinimalWriteSyncOperationProperties
    }
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    amountPaid
    isBalanceManuallyClosed
    previousRetentionBilled
    currentRetention
    billingType
    uploadedFile {
      id
      name
    }
    contract {
      id
      roundRetention
      internalProjectNumber
      taxCalculationType
      company {
        ...CompanyProperties
      }
      project {
        id
        projectNumber
        name
      }
    }
    emailedContacts {
      ...CompanyContactProperties
    }
  }
  ${MinimalPayAppPropertiesDoc}
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${CompanyPropertiesDoc}
  ${CompanyContactPropertiesDoc}
`
export const PayAppRateTableFeePropertiesDoc = gql`
  fragment PayAppRateTableFeeProperties on PayAppRateTableFee {
    id
    percent
    description
    group {
      id
    }
    overridesFee {
      id
    }
  }
`
export const PayAppRateTableGroupPropertiesDoc = gql`
  fragment PayAppRateTableGroupProperties on PayAppRateTableGroup {
    id
    name
    totalAmount
    subtotalAmount
    type
    fees {
      ...PayAppRateTableFeeProperties
    }
  }
  ${PayAppRateTableFeePropertiesDoc}
`
export const PayAppRateTablePropertiesDoc = gql`
  fragment PayAppRateTableProperties on PayApp {
    rateTable {
      id
      name
      status
      effectiveAt
      expiresAt
    }
    rateTableFees {
      ...PayAppRateTableFeeProperties
    }
    rateTableGroups {
      ...PayAppRateTableGroupProperties
    }
    rateTableItems {
      ...PayAppRateTableItemProperties
    }
  }
  ${PayAppRateTableFeePropertiesDoc}
  ${PayAppRateTableGroupPropertiesDoc}
  ${PayAppRateTableItemPropertiesDoc}
`
export const WorksheetLineItemProgressPropertiesDoc = gql`
  fragment WorksheetLineItemProgressProperties on WorksheetLineItemProgress {
    id
    progressBilled
    previousBilled
    totalValue
    worksheetLineItem {
      id
      code
      name
      costCode
      unitName
      unitPrice
      sovLineItem {
        id
      }
    }
  }
`
export const SovLineItemProgressPropertiesDoc = gql`
  fragment SovLineItemProgressProperties on SovLineItemProgress {
    id
    progressBilled
    currentRetention
    storedMaterialBilled
    currentBilled
    previousBilled
    futureBilled
    previousRetention
    previousRetentionBilled
    totalValue
    retentionHeldPercent
    retentionHeldOverride
    retentionReleased
    amountDuePreTax
    amountDuePostTax
    amountDueTaxAmount
    lastProgressWithRetentionHeldOverride {
      id
      payApp {
        id
        payAppNumber
      }
    }
    sovLineItem {
      ...SovLineItemProperties
      sovLineItemGroup {
        ...SovLineItemGroupProperties
      }
    }
    worksheetLineItemProgress {
      ...WorksheetLineItemProgressProperties
    }
  }
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
  ${WorksheetLineItemProgressPropertiesDoc}
`
export const FormTemplateAnnotationPropertiesDoc = gql`
  fragment FormTemplateAnnotationProperties on FormTemplateAnnotation {
    id
    userVisibleName
    type
    xStart
    yStart
    width
    height
    pageNumber
    fontFamily
    fontColor
    textAlignment
    syncTag
    isOptional
    selectedKey
    defaultValueKey
    prefix
    suffix
    fieldType
    doNotRetainOnReset
    dynamicFieldTag
    copyDefaultValueFromPreviousAnnotationValue
    imageType
  }
`
export const FormTemplateAnnotationValuePropertiesDoc = gql`
  fragment FormTemplateAnnotationValueProperties on FormTemplateAnnotationValue {
    id
    annotation {
      ...FormTemplateAnnotationProperties
    }
    value
    metadata
  }
  ${FormTemplateAnnotationPropertiesDoc}
`
export const PayAppEventPropertiesDoc = gql`
  fragment PayAppEventProperties on PayAppEvent {
    id
    createdAt
    isAdmin
    createdBy {
      id
      firstName
      lastName
    }
    type
    metadata
  }
`
export const PayAppPropertiesDoc = gql`
  fragment PayAppProperties on PayApp {
    ...PayAppNoProgressProperties
    ...PayAppRateTableProperties
    payAppNumber
    totalRetention
    physicalSignatureRequired
    progress {
      ...SovLineItemProgressProperties
    }
    formValues {
      ...FormTemplateAnnotationValueProperties
    }
    events {
      ...PayAppEventProperties
    }
  }
  ${PayAppNoProgressPropertiesDoc}
  ${PayAppRateTablePropertiesDoc}
  ${SovLineItemProgressPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
  ${PayAppEventPropertiesDoc}
`
export const PayAppAttachmentPropertiesDoc = gql`
  fragment PayAppAttachmentProperties on PayAppAttachment {
    id
    createdAt
    name
    uploadId
    description
    isEmailAttachment
    isInternalOnly
    file {
      ...StoredFileProperties
    }
    sovLineItemProgress {
      ...SovLineItemProgressProperties
    }
    rateTableItems {
      ...PayAppRateTableItemProperties
    }
  }
  ${StoredFilePropertiesDoc}
  ${SovLineItemProgressPropertiesDoc}
  ${PayAppRateTableItemPropertiesDoc}
`
export const LienWaiverRequestPropertiesDoc = gql`
  fragment LienWaiverRequestProperties on LienWaiverRequest {
    id
    createdAt
    updatedAt
    vendorContact {
      ...VendorContactProperties
      defaultSignature {
        ...SignatureProperties
      }
    }
    status
    actionTakenAt
  }
  ${VendorContactPropertiesDoc}
  ${SignaturePropertiesDoc}
`
export const LienWaiverPropertiesDoc = gql`
  fragment LienWaiverProperties on LienWaiver {
    id
    type
    timeZone
    claimant
    date
    defaultAmount
    defaultAmountText
    amount
    customer
    lastSendTime
    isJointCheck
    subject
    message
    defaultExceptionAmounts
    defaultExceptionDates
    hasSelectedDefaultVendorContacts
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    uploadedFile {
      id
      url
      type
      name
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      createdAt
      size
    }
    vendorContract {
      ...MinimalVendorContractProperties
      vendor {
        ...MinimalVendorProperties
        company {
          id
        }
      }
      preliminaryNotices {
        id
        date
        file {
          ...StoredFileProperties
        }
      }
    }
    payApps {
      id
    }
    contract {
      id
      internalProjectNumber
      project {
        id
        name
        projectNumber
      }
      company {
        id
        name
      }
    }
    lienWaiverRequests {
      ...LienWaiverRequestProperties
      statusLogs {
        id
        status
        statusUpdatedAt
      }
    }
    jobLocation {
      ...LocationProperties
    }
    owner
    formValues {
      ...FormTemplateAnnotationValueProperties
    }
    physicalSignatureRequired
    requestAttachments {
      ...StoredFileProperties
    }
    defaultVendorContacts {
      ...VendorContactProperties
    }
  }
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${MinimalVendorContractPropertiesDoc}
  ${MinimalVendorPropertiesDoc}
  ${StoredFilePropertiesDoc}
  ${LienWaiverRequestPropertiesDoc}
  ${LocationPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
  ${VendorContactPropertiesDoc}
`
export const PayAppForOverviewPropertiesDoc = gql`
  fragment PayAppForOverviewProperties on PayApp {
    ...PayAppProperties
    balanceToFinish
    automaticallyReadBilling
    retentionHeldPercent
    retentionHeldOverride
    retentionReleased
    amountDuePostTax
    amountDueTaxAmount
    lastSubmitted {
      id
      status
      statusUpdatedAt
    }
    revertToDraftEvents {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      metadata
    }
    integrationPaidAt
    canGenerateForms
    missingRequiredFields
    predictedPaymentDate
    hasContractRateTableChanged
    haveContractFeesChanged
    attachments {
      ...PayAppAttachmentProperties
    }
    lienWaivers {
      ...LienWaiverProperties
      lienWaiverRequests {
        ...LienWaiverRequestProperties
      }
    }
    legalDocuments {
      ...MinimalLegalDocumentProperties
      legalRequirement {
        ...MinimalLegalRequirementProperties
      }
    }
    rateTable {
      ...MinimalRateTableProperties
    }
  }
  ${PayAppPropertiesDoc}
  ${PayAppAttachmentPropertiesDoc}
  ${LienWaiverPropertiesDoc}
  ${LienWaiverRequestPropertiesDoc}
  ${MinimalLegalDocumentPropertiesDoc}
  ${MinimalLegalRequirementPropertiesDoc}
  ${MinimalRateTablePropertiesDoc}
`
export const AggregateChangeOrderDataPropertiesDoc = gql`
  fragment AggregateChangeOrderDataProperties on AggregateChangeOrderData {
    totalAmountUnapproved
    totalAmountAuthorizedToProceed
    totalAmountProceedingWithWork
    totalAmountApproved
    totalOriginalContractAmount
    totalRevisedContractAmount
  }
`
export const ContractForChangeOrderReportPropertiesDoc = gql`
  fragment ContractForChangeOrderReportProperties on Contract {
    id
    internalProjectNumber
    timeZone
    leadPMs {
      id
      firstName
      lastName
    }
    project {
      id
      projectNumber
      name
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
  }
`
export const ChangeOrderSummaryReportPropertiesDoc = gql`
  fragment ChangeOrderSummaryReportProperties on ChangeOrderSummaryReport {
    aggregateChangeOrderTotals {
      ...AggregateChangeOrderDataProperties
    }
    aggregateChangeOrderDataByContract {
      aggregateChangeOrderData {
        ...AggregateChangeOrderDataProperties
      }
      contract {
        ...ContractForChangeOrderReportProperties
      }
    }
  }
  ${AggregateChangeOrderDataPropertiesDoc}
  ${ContractForChangeOrderReportPropertiesDoc}
`
export const PendingChangeOrderReportPropertiesDoc = gql`
  fragment PendingChangeOrderReportProperties on PendingChangeOrderReport {
    totalAmountRequested
    totalAmountInDraft
    totalAmountApproved
    totalOriginalContractAmount
    totalRevisedContractAmount
    totalAmountAuthorizedToProceed
    totalAmountProceedingWithWork
    changeOrderRequests {
      ...MinimalChangeOrderRequestProperties
      contract {
        ...ContractForChangeOrderReportProperties
      }
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
  ${ContractForChangeOrderReportPropertiesDoc}
`
export const LienWaiverRequestForLowerTierSignaturePropertiesDoc = gql`
  fragment LienWaiverRequestForLowerTierSignatureProperties on LienWaiverRequest {
    id
    status
    vendorContact {
      ...VendorContactProperties
      defaultSignature {
        ...SignatureProperties
      }
    }
    requestedBy {
      id
      firstName
      lastName
    }
    lienWaiver {
      id
      type
      timeZone
      amount
      date
      claimant
      message
      payApps {
        id
        emailedContacts {
          id
          companyName
        }
      }
      contract {
        id
        internalProjectNumber
        company {
          id
          name
        }
        project {
          id
          name
          projectNumber
        }
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
      requestAttachments {
        ...StoredFileProperties
      }
      vendorContract {
        id
        lowerTierTo {
          id
          vendor {
            id
            name
          }
        }
      }
    }
  }
  ${VendorContactPropertiesDoc}
  ${SignaturePropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
  ${StoredFilePropertiesDoc}
`
export const CompanyProjectUsersPropertiesDoc = gql`
  fragment CompanyProjectUsersProperties on Project {
    id
    name
    projectNumber
    contracts {
      id
      company {
        id
      }
      internalProjectNumber
      billingType
      selectedAddress {
        id
      }
      users {
        id
        user {
          ...MinimalUserProperties
        }
      }
    }
  }
  ${MinimalUserPropertiesDoc}
`
export const LienWaiverFormTemplatePropertiesDoc = gql`
  fragment LienWaiverFormTemplateProperties on FormTemplate {
    id
    latestVersion {
      id
      annotations {
        id
        fieldType
      }
    }
  }
`
export const ProjectImportOperationCompletedDocument = gql`
  subscription projectImportOperationCompleted($companyId: ID!) {
    projectImportOperationCompleted(companyId: $companyId) {
      id
      status
      error
      integrationProjectNumber
      integrationProjectName
      companyIntegration {
        id
        type
        shortName
      }
      group {
        status
        numSuccessful
        numFailed
        totalOperations
      }
    }
  }
`

/**
 * __useProjectImportOperationCompletedSubscription__
 *
 * To run a query within a React component, call `useProjectImportOperationCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectImportOperationCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectImportOperationCompletedSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useProjectImportOperationCompletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ProjectImportOperationCompletedSubscription,
    ProjectImportOperationCompletedSubscriptionVariables
  > &
    (
      | { variables: ProjectImportOperationCompletedSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ProjectImportOperationCompletedSubscription,
    ProjectImportOperationCompletedSubscriptionVariables
  >(ProjectImportOperationCompletedDocument, options)
}
export type ProjectImportOperationCompletedSubscriptionHookResult = ReturnType<
  typeof useProjectImportOperationCompletedSubscription
>
export type ProjectImportOperationCompletedSubscriptionResult =
  Apollo.SubscriptionResult<ProjectImportOperationCompletedSubscription>
export const GetContractForManagingUsersDocument = gql`
  query getContractForManagingUsers($id: ID!) {
    contract(id: $id) {
      id
      users {
        ...MinimalContractUserProperties
      }
      onboardedStatus {
        addedTeammates
      }
      company {
        id
        users {
          ...CompanyUserProperties
        }
      }
    }
  }
  ${MinimalContractUserPropertiesDoc}
  ${CompanyUserPropertiesDoc}
`

/**
 * __useGetContractForManagingUsersQuery__
 *
 * To run a query within a React component, call `useGetContractForManagingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForManagingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForManagingUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractForManagingUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForManagingUsersQuery,
    GetContractForManagingUsersQueryVariables
  > &
    ({ variables: GetContractForManagingUsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForManagingUsersQuery,
    GetContractForManagingUsersQueryVariables
  >(GetContractForManagingUsersDocument, options)
}
export function useGetContractForManagingUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForManagingUsersQuery,
    GetContractForManagingUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForManagingUsersQuery,
    GetContractForManagingUsersQueryVariables
  >(GetContractForManagingUsersDocument, options)
}
export function useGetContractForManagingUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForManagingUsersQuery,
        GetContractForManagingUsersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForManagingUsersQuery,
    GetContractForManagingUsersQueryVariables
  >(GetContractForManagingUsersDocument, options)
}
export type GetContractForManagingUsersQueryHookResult = ReturnType<
  typeof useGetContractForManagingUsersQuery
>
export type GetContractForManagingUsersLazyQueryHookResult = ReturnType<
  typeof useGetContractForManagingUsersLazyQuery
>
export type GetContractForManagingUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForManagingUsersSuspenseQuery
>
export type GetContractForManagingUsersQueryResult = Apollo.QueryResult<
  GetContractForManagingUsersQuery,
  GetContractForManagingUsersQueryVariables
>
export const GetContractForFormsReviewDocument = gql`
  query getContractForFormsReview($contractId: ID!) {
    contract(id: $contractId) {
      id
      company {
        id
        name
      }
      defaultFormsReviewContacts {
        id
        firstName
        lastName
        email
        policiesAcceptedAt
      }
      users {
        id
        user {
          id
          firstName
          lastName
          email
          policiesAcceptedAt
        }
      }
      project {
        id
        name
      }
    }
  }
`

/**
 * __useGetContractForFormsReviewQuery__
 *
 * To run a query within a React component, call `useGetContractForFormsReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForFormsReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForFormsReviewQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractForFormsReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForFormsReviewQuery,
    GetContractForFormsReviewQueryVariables
  > &
    ({ variables: GetContractForFormsReviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForFormsReviewQuery, GetContractForFormsReviewQueryVariables>(
    GetContractForFormsReviewDocument,
    options
  )
}
export function useGetContractForFormsReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForFormsReviewQuery,
    GetContractForFormsReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForFormsReviewQuery,
    GetContractForFormsReviewQueryVariables
  >(GetContractForFormsReviewDocument, options)
}
export function useGetContractForFormsReviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForFormsReviewQuery,
        GetContractForFormsReviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForFormsReviewQuery,
    GetContractForFormsReviewQueryVariables
  >(GetContractForFormsReviewDocument, options)
}
export type GetContractForFormsReviewQueryHookResult = ReturnType<
  typeof useGetContractForFormsReviewQuery
>
export type GetContractForFormsReviewLazyQueryHookResult = ReturnType<
  typeof useGetContractForFormsReviewLazyQuery
>
export type GetContractForFormsReviewSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForFormsReviewSuspenseQuery
>
export type GetContractForFormsReviewQueryResult = Apollo.QueryResult<
  GetContractForFormsReviewQuery,
  GetContractForFormsReviewQueryVariables
>
export const CurrentUserForCompanyContextDocument = gql`
  query currentUserForCompanyContext {
    currentUser {
      id
      firstName
      lastName
      jobTitle
      email
      policiesAcceptedAt
      blockedNotifications
      blockedEmails
      tutorialsShown
      companyUsers {
        id
        role
        permissions
        status
        emailAlias {
          ...CompanyUserEmailAliasProperties
        }
        company {
          ...CompanyProperties
          integrationMappings
          metadata {
            monthlyCreatePayAppReminderDate
          }
        }
      }
      defaultSignature {
        ...SignatureProperties
      }
    }
  }
  ${CompanyUserEmailAliasPropertiesDoc}
  ${CompanyPropertiesDoc}
  ${SignaturePropertiesDoc}
`

/**
 * __useCurrentUserForCompanyContextQuery__
 *
 * To run a query within a React component, call `useCurrentUserForCompanyContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserForCompanyContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserForCompanyContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserForCompanyContextQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserForCompanyContextQuery,
    CurrentUserForCompanyContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentUserForCompanyContextQuery,
    CurrentUserForCompanyContextQueryVariables
  >(CurrentUserForCompanyContextDocument, options)
}
export function useCurrentUserForCompanyContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserForCompanyContextQuery,
    CurrentUserForCompanyContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentUserForCompanyContextQuery,
    CurrentUserForCompanyContextQueryVariables
  >(CurrentUserForCompanyContextDocument, options)
}
export function useCurrentUserForCompanyContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CurrentUserForCompanyContextQuery,
        CurrentUserForCompanyContextQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CurrentUserForCompanyContextQuery,
    CurrentUserForCompanyContextQueryVariables
  >(CurrentUserForCompanyContextDocument, options)
}
export type CurrentUserForCompanyContextQueryHookResult = ReturnType<
  typeof useCurrentUserForCompanyContextQuery
>
export type CurrentUserForCompanyContextLazyQueryHookResult = ReturnType<
  typeof useCurrentUserForCompanyContextLazyQuery
>
export type CurrentUserForCompanyContextSuspenseQueryHookResult = ReturnType<
  typeof useCurrentUserForCompanyContextSuspenseQuery
>
export type CurrentUserForCompanyContextQueryResult = Apollo.QueryResult<
  CurrentUserForCompanyContextQuery,
  CurrentUserForCompanyContextQueryVariables
>
export const GetContractForProjectContextDocument = gql`
  query getContractForProjectContext($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...MinimalContractProperties
      percentComplete
      timeZone
      company {
        ...CompanyProperties
        metadata {
          monthlyCreatePayAppReminderDate
        }
      }
      lienWaiverTemplates {
        id
      }
      lowerTierLienWaiverTemplates {
        id
      }
      defaultGcContacts {
        ...CompanyContactProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      project {
        id
        name
        projectNumber
      }
      payApps {
        id
      }
      defaultTaxGroup {
        id
      }
    }
  }
  ${MinimalContractPropertiesDoc}
  ${CompanyPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`

/**
 * __useGetContractForProjectContextQuery__
 *
 * To run a query within a React component, call `useGetContractForProjectContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForProjectContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForProjectContextQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForProjectContextQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForProjectContextQuery,
    GetContractForProjectContextQueryVariables
  > &
    ({ variables: GetContractForProjectContextQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForProjectContextQuery,
    GetContractForProjectContextQueryVariables
  >(GetContractForProjectContextDocument, options)
}
export function useGetContractForProjectContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForProjectContextQuery,
    GetContractForProjectContextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForProjectContextQuery,
    GetContractForProjectContextQueryVariables
  >(GetContractForProjectContextDocument, options)
}
export function useGetContractForProjectContextSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForProjectContextQuery,
        GetContractForProjectContextQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForProjectContextQuery,
    GetContractForProjectContextQueryVariables
  >(GetContractForProjectContextDocument, options)
}
export type GetContractForProjectContextQueryHookResult = ReturnType<
  typeof useGetContractForProjectContextQuery
>
export type GetContractForProjectContextLazyQueryHookResult = ReturnType<
  typeof useGetContractForProjectContextLazyQuery
>
export type GetContractForProjectContextSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForProjectContextSuspenseQuery
>
export type GetContractForProjectContextQueryResult = Apollo.QueryResult<
  GetContractForProjectContextQuery,
  GetContractForProjectContextQueryVariables
>
export const SetProgressBilledDocument = gql`
  mutation setProgressBilled($sovLineItemProgressId: ID!, $progressBilled: SafeInt!) {
    setProgressBilled(
      sovLineItemProgressId: $sovLineItemProgressId
      progressBilled: $progressBilled
    ) {
      ...SovLineItemProgressProperties
      sovLineItem {
        ...SovLineItemProperties
        billedToDate
        totalRetention
        sovLineItemGroup {
          ...SovLineItemGroupProperties
        }
      }
      payApp {
        id
        currentBilled
        currentRetention
        totalRetention
      }
    }
  }
  ${SovLineItemProgressPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
`
export type SetProgressBilledMutationFn = Apollo.MutationFunction<
  SetProgressBilledMutation,
  SetProgressBilledMutationVariables
>

/**
 * __useSetProgressBilledMutation__
 *
 * To run a mutation, you first call `useSetProgressBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProgressBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProgressBilledMutation, { data, loading, error }] = useSetProgressBilledMutation({
 *   variables: {
 *      sovLineItemProgressId: // value for 'sovLineItemProgressId'
 *      progressBilled: // value for 'progressBilled'
 *   },
 * });
 */
export function useSetProgressBilledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetProgressBilledMutation,
    SetProgressBilledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetProgressBilledMutation, SetProgressBilledMutationVariables>(
    SetProgressBilledDocument,
    options
  )
}
export type SetProgressBilledMutationHookResult = ReturnType<typeof useSetProgressBilledMutation>
export type SetProgressBilledMutationResult = Apollo.MutationResult<SetProgressBilledMutation>
export type SetProgressBilledMutationOptions = Apollo.BaseMutationOptions<
  SetProgressBilledMutation,
  SetProgressBilledMutationVariables
>
export const SetStoredMaterialBilledDocument = gql`
  mutation setStoredMaterialBilled($sovLineItemProgressId: ID!, $storedMaterialBilled: SafeInt!) {
    setStoredMaterialBilled(
      sovLineItemProgressId: $sovLineItemProgressId
      storedMaterialBilled: $storedMaterialBilled
    ) {
      ...SovLineItemProgressProperties
      sovLineItem {
        ...SovLineItemProperties
        billedToDate
        totalRetention
        sovLineItemGroup {
          ...SovLineItemGroupProperties
        }
      }
      payApp {
        id
        currentBilled
        currentRetention
        totalRetention
      }
    }
  }
  ${SovLineItemProgressPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
`
export type SetStoredMaterialBilledMutationFn = Apollo.MutationFunction<
  SetStoredMaterialBilledMutation,
  SetStoredMaterialBilledMutationVariables
>

/**
 * __useSetStoredMaterialBilledMutation__
 *
 * To run a mutation, you first call `useSetStoredMaterialBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStoredMaterialBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStoredMaterialBilledMutation, { data, loading, error }] = useSetStoredMaterialBilledMutation({
 *   variables: {
 *      sovLineItemProgressId: // value for 'sovLineItemProgressId'
 *      storedMaterialBilled: // value for 'storedMaterialBilled'
 *   },
 * });
 */
export function useSetStoredMaterialBilledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetStoredMaterialBilledMutation,
    SetStoredMaterialBilledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetStoredMaterialBilledMutation,
    SetStoredMaterialBilledMutationVariables
  >(SetStoredMaterialBilledDocument, options)
}
export type SetStoredMaterialBilledMutationHookResult = ReturnType<
  typeof useSetStoredMaterialBilledMutation
>
export type SetStoredMaterialBilledMutationResult =
  Apollo.MutationResult<SetStoredMaterialBilledMutation>
export type SetStoredMaterialBilledMutationOptions = Apollo.BaseMutationOptions<
  SetStoredMaterialBilledMutation,
  SetStoredMaterialBilledMutationVariables
>
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    currentUser {
      ...UserProperties
      policiesAcceptedAt
      blockedNotifications
      blockedEmails
      companies {
        ...CompanyProperties
        metadata {
          monthlyCreatePayAppReminderDate
        }
      }
    }
  }
  ${UserPropertiesDoc}
  ${CompanyPropertiesDoc}
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const GetCurrentCompanyUserDocument = gql`
  query getCurrentCompanyUser($companyId: ID!) {
    currentCompanyUser(companyId: $companyId) {
      id
      role
      permissions
      emailAlias {
        ...CompanyUserEmailAliasProperties
      }
      company {
        id
        name
        defaultQuickBillingTemplate {
          id
        }
      }
      companyLocation {
        id
      }
    }
  }
  ${CompanyUserEmailAliasPropertiesDoc}
`

/**
 * __useGetCurrentCompanyUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyUserQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCurrentCompanyUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentCompanyUserQuery,
    GetCurrentCompanyUserQueryVariables
  > &
    ({ variables: GetCurrentCompanyUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>(
    GetCurrentCompanyUserDocument,
    options
  )
}
export function useGetCurrentCompanyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyUserQuery,
    GetCurrentCompanyUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>(
    GetCurrentCompanyUserDocument,
    options
  )
}
export function useGetCurrentCompanyUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentCompanyUserQuery,
        GetCurrentCompanyUserQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCurrentCompanyUserQuery, GetCurrentCompanyUserQueryVariables>(
    GetCurrentCompanyUserDocument,
    options
  )
}
export type GetCurrentCompanyUserQueryHookResult = ReturnType<typeof useGetCurrentCompanyUserQuery>
export type GetCurrentCompanyUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyUserLazyQuery
>
export type GetCurrentCompanyUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyUserSuspenseQuery
>
export type GetCurrentCompanyUserQueryResult = Apollo.QueryResult<
  GetCurrentCompanyUserQuery,
  GetCurrentCompanyUserQueryVariables
>
export const GetPayAppDocument = gql`
  query getPayApp($payAppId: ID!) {
    payApp(id: $payAppId) {
      ...PayAppForOverviewProperties
    }
  }
  ${PayAppForOverviewPropertiesDoc}
`

/**
 * __useGetPayAppQuery__
 *
 * To run a query within a React component, call `useGetPayAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppQuery(
  baseOptions: Apollo.QueryHookOptions<GetPayAppQuery, GetPayAppQueryVariables> &
    ({ variables: GetPayAppQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppQuery, GetPayAppQueryVariables>(GetPayAppDocument, options)
}
export function useGetPayAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPayAppQuery, GetPayAppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppQuery, GetPayAppQueryVariables>(GetPayAppDocument, options)
}
export function useGetPayAppSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPayAppQuery, GetPayAppQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppQuery, GetPayAppQueryVariables>(
    GetPayAppDocument,
    options
  )
}
export type GetPayAppQueryHookResult = ReturnType<typeof useGetPayAppQuery>
export type GetPayAppLazyQueryHookResult = ReturnType<typeof useGetPayAppLazyQuery>
export type GetPayAppSuspenseQueryHookResult = ReturnType<typeof useGetPayAppSuspenseQuery>
export type GetPayAppQueryResult = Apollo.QueryResult<GetPayAppQuery, GetPayAppQueryVariables>
export const GetPayAppForLienWaiverDocument = gql`
  query getPayAppForLienWaiver($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      retentionOnly
      status
      payAppNumber
      billingStart
      billingEnd
      contract {
        id
        company {
          id
          name
        }
      }
      progress {
        ...SovLineItemProgressProperties
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
      emailedContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${SovLineItemProgressPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
  ${CompanyContactPropertiesDoc}
`

/**
 * __useGetPayAppForLienWaiverQuery__
 *
 * To run a query within a React component, call `useGetPayAppForLienWaiverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForLienWaiverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForLienWaiverQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppForLienWaiverQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppForLienWaiverQuery,
    GetPayAppForLienWaiverQueryVariables
  > &
    ({ variables: GetPayAppForLienWaiverQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForLienWaiverQuery, GetPayAppForLienWaiverQueryVariables>(
    GetPayAppForLienWaiverDocument,
    options
  )
}
export function useGetPayAppForLienWaiverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForLienWaiverQuery,
    GetPayAppForLienWaiverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppForLienWaiverQuery, GetPayAppForLienWaiverQueryVariables>(
    GetPayAppForLienWaiverDocument,
    options
  )
}
export function useGetPayAppForLienWaiverSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPayAppForLienWaiverQuery,
        GetPayAppForLienWaiverQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppForLienWaiverQuery, GetPayAppForLienWaiverQueryVariables>(
    GetPayAppForLienWaiverDocument,
    options
  )
}
export type GetPayAppForLienWaiverQueryHookResult = ReturnType<
  typeof useGetPayAppForLienWaiverQuery
>
export type GetPayAppForLienWaiverLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForLienWaiverLazyQuery
>
export type GetPayAppForLienWaiverSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForLienWaiverSuspenseQuery
>
export type GetPayAppForLienWaiverQueryResult = Apollo.QueryResult<
  GetPayAppForLienWaiverQuery,
  GetPayAppForLienWaiverQueryVariables
>
export const PayAppActivityDocument = gql`
  query payAppActivity($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      events {
        ...PayAppEventProperties
      }
      comments {
        ...PayAppCommentProperties
      }
    }
  }
  ${PayAppEventPropertiesDoc}
  ${PayAppCommentPropertiesDoc}
`

/**
 * __usePayAppActivityQuery__
 *
 * To run a query within a React component, call `usePayAppActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayAppActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayAppActivityQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function usePayAppActivityQuery(
  baseOptions: Apollo.QueryHookOptions<PayAppActivityQuery, PayAppActivityQueryVariables> &
    ({ variables: PayAppActivityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayAppActivityQuery, PayAppActivityQueryVariables>(
    PayAppActivityDocument,
    options
  )
}
export function usePayAppActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayAppActivityQuery, PayAppActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PayAppActivityQuery, PayAppActivityQueryVariables>(
    PayAppActivityDocument,
    options
  )
}
export function usePayAppActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PayAppActivityQuery, PayAppActivityQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PayAppActivityQuery, PayAppActivityQueryVariables>(
    PayAppActivityDocument,
    options
  )
}
export type PayAppActivityQueryHookResult = ReturnType<typeof usePayAppActivityQuery>
export type PayAppActivityLazyQueryHookResult = ReturnType<typeof usePayAppActivityLazyQuery>
export type PayAppActivitySuspenseQueryHookResult = ReturnType<
  typeof usePayAppActivitySuspenseQuery
>
export type PayAppActivityQueryResult = Apollo.QueryResult<
  PayAppActivityQuery,
  PayAppActivityQueryVariables
>
export const NewPayAppCommentDocument = gql`
  subscription newPayAppComment($payAppId: ID!) {
    newPayAppComment(payAppId: $payAppId) {
      ...PayAppCommentProperties
    }
  }
  ${PayAppCommentPropertiesDoc}
`

/**
 * __useNewPayAppCommentSubscription__
 *
 * To run a query within a React component, call `useNewPayAppCommentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewPayAppCommentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPayAppCommentSubscription({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useNewPayAppCommentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewPayAppCommentSubscription,
    NewPayAppCommentSubscriptionVariables
  > &
    ({ variables: NewPayAppCommentSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    NewPayAppCommentSubscription,
    NewPayAppCommentSubscriptionVariables
  >(NewPayAppCommentDocument, options)
}
export type NewPayAppCommentSubscriptionHookResult = ReturnType<
  typeof useNewPayAppCommentSubscription
>
export type NewPayAppCommentSubscriptionResult =
  Apollo.SubscriptionResult<NewPayAppCommentSubscription>
export const NewPayAppEventDocument = gql`
  subscription newPayAppEvent($payAppId: ID!) {
    newPayAppEvent(payAppId: $payAppId) {
      id
      events {
        ...PayAppEventProperties
      }
      comments {
        ...PayAppCommentProperties
      }
    }
  }
  ${PayAppEventPropertiesDoc}
  ${PayAppCommentPropertiesDoc}
`

/**
 * __useNewPayAppEventSubscription__
 *
 * To run a query within a React component, call `useNewPayAppEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewPayAppEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPayAppEventSubscription({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useNewPayAppEventSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewPayAppEventSubscription,
    NewPayAppEventSubscriptionVariables
  > &
    ({ variables: NewPayAppEventSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<NewPayAppEventSubscription, NewPayAppEventSubscriptionVariables>(
    NewPayAppEventDocument,
    options
  )
}
export type NewPayAppEventSubscriptionHookResult = ReturnType<typeof useNewPayAppEventSubscription>
export type NewPayAppEventSubscriptionResult = Apollo.SubscriptionResult<NewPayAppEventSubscription>
export const ContractActivityDocument = gql`
  query contractActivity($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      events {
        ...ContractEventProperties
      }
      comments {
        ...ContractCommentProperties
      }
    }
  }
  ${ContractEventPropertiesDoc}
  ${ContractCommentPropertiesDoc}
`

/**
 * __useContractActivityQuery__
 *
 * To run a query within a React component, call `useContractActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractActivityQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractActivityQuery(
  baseOptions: Apollo.QueryHookOptions<ContractActivityQuery, ContractActivityQueryVariables> &
    ({ variables: ContractActivityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractActivityQuery, ContractActivityQueryVariables>(
    ContractActivityDocument,
    options
  )
}
export function useContractActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractActivityQuery, ContractActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractActivityQuery, ContractActivityQueryVariables>(
    ContractActivityDocument,
    options
  )
}
export function useContractActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractActivityQuery, ContractActivityQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractActivityQuery, ContractActivityQueryVariables>(
    ContractActivityDocument,
    options
  )
}
export type ContractActivityQueryHookResult = ReturnType<typeof useContractActivityQuery>
export type ContractActivityLazyQueryHookResult = ReturnType<typeof useContractActivityLazyQuery>
export type ContractActivitySuspenseQueryHookResult = ReturnType<
  typeof useContractActivitySuspenseQuery
>
export type ContractActivityQueryResult = Apollo.QueryResult<
  ContractActivityQuery,
  ContractActivityQueryVariables
>
export const NewContractCommentDocument = gql`
  subscription newContractComment($contractId: ID!) {
    newContractComment(contractId: $contractId) {
      ...ContractCommentProperties
    }
  }
  ${ContractCommentPropertiesDoc}
`

/**
 * __useNewContractCommentSubscription__
 *
 * To run a query within a React component, call `useNewContractCommentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewContractCommentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewContractCommentSubscription({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useNewContractCommentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewContractCommentSubscription,
    NewContractCommentSubscriptionVariables
  > &
    ({ variables: NewContractCommentSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    NewContractCommentSubscription,
    NewContractCommentSubscriptionVariables
  >(NewContractCommentDocument, options)
}
export type NewContractCommentSubscriptionHookResult = ReturnType<
  typeof useNewContractCommentSubscription
>
export type NewContractCommentSubscriptionResult =
  Apollo.SubscriptionResult<NewContractCommentSubscription>
export const NewContractEventDocument = gql`
  subscription newContractEvent($contractId: ID!) {
    newContractEvent(contractId: $contractId) {
      id
      events {
        ...ContractEventProperties
      }
      comments {
        ...ContractCommentProperties
      }
    }
  }
  ${ContractEventPropertiesDoc}
  ${ContractCommentPropertiesDoc}
`

/**
 * __useNewContractEventSubscription__
 *
 * To run a query within a React component, call `useNewContractEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewContractEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewContractEventSubscription({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useNewContractEventSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewContractEventSubscription,
    NewContractEventSubscriptionVariables
  > &
    ({ variables: NewContractEventSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    NewContractEventSubscription,
    NewContractEventSubscriptionVariables
  >(NewContractEventDocument, options)
}
export type NewContractEventSubscriptionHookResult = ReturnType<
  typeof useNewContractEventSubscription
>
export type NewContractEventSubscriptionResult =
  Apollo.SubscriptionResult<NewContractEventSubscription>
export const VerifiedEmailAliasDocument = gql`
  subscription verifiedEmailAlias($companyUserEmailAliasId: ID!) {
    verifiedEmailAlias(companyUserEmailAliasId: $companyUserEmailAliasId) {
      id
      emailAddress
      isVerified
    }
  }
`

/**
 * __useVerifiedEmailAliasSubscription__
 *
 * To run a query within a React component, call `useVerifiedEmailAliasSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVerifiedEmailAliasSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifiedEmailAliasSubscription({
 *   variables: {
 *      companyUserEmailAliasId: // value for 'companyUserEmailAliasId'
 *   },
 * });
 */
export function useVerifiedEmailAliasSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    VerifiedEmailAliasSubscription,
    VerifiedEmailAliasSubscriptionVariables
  > &
    ({ variables: VerifiedEmailAliasSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    VerifiedEmailAliasSubscription,
    VerifiedEmailAliasSubscriptionVariables
  >(VerifiedEmailAliasDocument, options)
}
export type VerifiedEmailAliasSubscriptionHookResult = ReturnType<
  typeof useVerifiedEmailAliasSubscription
>
export type VerifiedEmailAliasSubscriptionResult =
  Apollo.SubscriptionResult<VerifiedEmailAliasSubscription>
export const GetLeadPMsDocument = gql`
  query getLeadPMs($companyId: ID) {
    leadPMs(companyId: $companyId) {
      id
      user {
        ...MinimalUserProperties
      }
    }
  }
  ${MinimalUserPropertiesDoc}
`

/**
 * __useGetLeadPMsQuery__
 *
 * To run a query within a React component, call `useGetLeadPMsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadPMsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadPMsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetLeadPMsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLeadPMsQuery, GetLeadPMsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLeadPMsQuery, GetLeadPMsQueryVariables>(GetLeadPMsDocument, options)
}
export function useGetLeadPMsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLeadPMsQuery, GetLeadPMsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLeadPMsQuery, GetLeadPMsQueryVariables>(GetLeadPMsDocument, options)
}
export function useGetLeadPMsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLeadPMsQuery, GetLeadPMsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetLeadPMsQuery, GetLeadPMsQueryVariables>(
    GetLeadPMsDocument,
    options
  )
}
export type GetLeadPMsQueryHookResult = ReturnType<typeof useGetLeadPMsQuery>
export type GetLeadPMsLazyQueryHookResult = ReturnType<typeof useGetLeadPMsLazyQuery>
export type GetLeadPMsSuspenseQueryHookResult = ReturnType<typeof useGetLeadPMsSuspenseQuery>
export type GetLeadPMsQueryResult = Apollo.QueryResult<GetLeadPMsQuery, GetLeadPMsQueryVariables>
export const GetGeneralContractorsDocument = gql`
  query getGeneralContractors($companyId: ID) {
    generalContractors(companyId: $companyId) {
      id
      name
    }
  }
`

/**
 * __useGetGeneralContractorsQuery__
 *
 * To run a query within a React component, call `useGetGeneralContractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralContractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralContractorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGeneralContractorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGeneralContractorsQuery,
    GetGeneralContractorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGeneralContractorsQuery, GetGeneralContractorsQueryVariables>(
    GetGeneralContractorsDocument,
    options
  )
}
export function useGetGeneralContractorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGeneralContractorsQuery,
    GetGeneralContractorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetGeneralContractorsQuery, GetGeneralContractorsQueryVariables>(
    GetGeneralContractorsDocument,
    options
  )
}
export function useGetGeneralContractorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetGeneralContractorsQuery,
        GetGeneralContractorsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetGeneralContractorsQuery, GetGeneralContractorsQueryVariables>(
    GetGeneralContractorsDocument,
    options
  )
}
export type GetGeneralContractorsQueryHookResult = ReturnType<typeof useGetGeneralContractorsQuery>
export type GetGeneralContractorsLazyQueryHookResult = ReturnType<
  typeof useGetGeneralContractorsLazyQuery
>
export type GetGeneralContractorsSuspenseQueryHookResult = ReturnType<
  typeof useGetGeneralContractorsSuspenseQuery
>
export type GetGeneralContractorsQueryResult = Apollo.QueryResult<
  GetGeneralContractorsQuery,
  GetGeneralContractorsQueryVariables
>
export const UpdatePayAppDocument = gql`
  mutation updatePayApp($input: UpdatePayAppInput!) {
    updatePayApp(input: $input) {
      ...MinimalPayAppProperties
      predictedPaymentDate
      invoiceNumber
    }
  }
  ${MinimalPayAppPropertiesDoc}
`
export type UpdatePayAppMutationFn = Apollo.MutationFunction<
  UpdatePayAppMutation,
  UpdatePayAppMutationVariables
>

/**
 * __useUpdatePayAppMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppMutation, { data, loading, error }] = useUpdatePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePayAppMutation, UpdatePayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePayAppMutation, UpdatePayAppMutationVariables>(
    UpdatePayAppDocument,
    options
  )
}
export type UpdatePayAppMutationHookResult = ReturnType<typeof useUpdatePayAppMutation>
export type UpdatePayAppMutationResult = Apollo.MutationResult<UpdatePayAppMutation>
export type UpdatePayAppMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppMutation,
  UpdatePayAppMutationVariables
>
export const UpdateCompanyRequestDocument = gql`
  mutation updateCompanyRequest($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...CompanyProperties
      metadata {
        monthlyCreatePayAppReminderDate
      }
    }
  }
  ${CompanyPropertiesDoc}
`
export type UpdateCompanyRequestMutationFn = Apollo.MutationFunction<
  UpdateCompanyRequestMutation,
  UpdateCompanyRequestMutationVariables
>

/**
 * __useUpdateCompanyRequestMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyRequestMutation, { data, loading, error }] = useUpdateCompanyRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyRequestMutation,
    UpdateCompanyRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyRequestMutation, UpdateCompanyRequestMutationVariables>(
    UpdateCompanyRequestDocument,
    options
  )
}
export type UpdateCompanyRequestMutationHookResult = ReturnType<
  typeof useUpdateCompanyRequestMutation
>
export type UpdateCompanyRequestMutationResult = Apollo.MutationResult<UpdateCompanyRequestMutation>
export type UpdateCompanyRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyRequestMutation,
  UpdateCompanyRequestMutationVariables
>
export const UpdateLocationRequestDocument = gql`
  mutation updateLocationRequest($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...LocationProperties
    }
  }
  ${LocationPropertiesDoc}
`
export type UpdateLocationRequestMutationFn = Apollo.MutationFunction<
  UpdateLocationRequestMutation,
  UpdateLocationRequestMutationVariables
>

/**
 * __useUpdateLocationRequestMutation__
 *
 * To run a mutation, you first call `useUpdateLocationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationRequestMutation, { data, loading, error }] = useUpdateLocationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationRequestMutation,
    UpdateLocationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLocationRequestMutation, UpdateLocationRequestMutationVariables>(
    UpdateLocationRequestDocument,
    options
  )
}
export type UpdateLocationRequestMutationHookResult = ReturnType<
  typeof useUpdateLocationRequestMutation
>
export type UpdateLocationRequestMutationResult =
  Apollo.MutationResult<UpdateLocationRequestMutation>
export type UpdateLocationRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationRequestMutation,
  UpdateLocationRequestMutationVariables
>
export const CreateLocationRequestDocument = gql`
  mutation createLocationRequest($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationProperties
    }
  }
  ${LocationPropertiesDoc}
`
export type CreateLocationRequestMutationFn = Apollo.MutationFunction<
  CreateLocationRequestMutation,
  CreateLocationRequestMutationVariables
>

/**
 * __useCreateLocationRequestMutation__
 *
 * To run a mutation, you first call `useCreateLocationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationRequestMutation, { data, loading, error }] = useCreateLocationRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationRequestMutation,
    CreateLocationRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLocationRequestMutation, CreateLocationRequestMutationVariables>(
    CreateLocationRequestDocument,
    options
  )
}
export type CreateLocationRequestMutationHookResult = ReturnType<
  typeof useCreateLocationRequestMutation
>
export type CreateLocationRequestMutationResult =
  Apollo.MutationResult<CreateLocationRequestMutation>
export type CreateLocationRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationRequestMutation,
  CreateLocationRequestMutationVariables
>
export const DeleteOfficeLocationDocument = gql`
  mutation deleteOfficeLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
      locations {
        id
      }
    }
  }
`
export type DeleteOfficeLocationMutationFn = Apollo.MutationFunction<
  DeleteOfficeLocationMutation,
  DeleteOfficeLocationMutationVariables
>

/**
 * __useDeleteOfficeLocationMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeLocationMutation, { data, loading, error }] = useDeleteOfficeLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOfficeLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOfficeLocationMutation,
    DeleteOfficeLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOfficeLocationMutation, DeleteOfficeLocationMutationVariables>(
    DeleteOfficeLocationDocument,
    options
  )
}
export type DeleteOfficeLocationMutationHookResult = ReturnType<
  typeof useDeleteOfficeLocationMutation
>
export type DeleteOfficeLocationMutationResult = Apollo.MutationResult<DeleteOfficeLocationMutation>
export type DeleteOfficeLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfficeLocationMutation,
  DeleteOfficeLocationMutationVariables
>
export const GetCompanyContactsDocument = gql`
  query getCompanyContacts($input: GetCompanyContactsInput!) {
    companyContacts(input: $input) {
      ...CompanyContactProperties
    }
  }
  ${CompanyContactPropertiesDoc}
`

/**
 * __useGetCompanyContactsQuery__
 *
 * To run a query within a React component, call `useGetCompanyContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompanyContactsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyContactsQuery, GetCompanyContactsQueryVariables> &
    ({ variables: GetCompanyContactsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyContactsQuery, GetCompanyContactsQueryVariables>(
    GetCompanyContactsDocument,
    options
  )
}
export function useGetCompanyContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyContactsQuery,
    GetCompanyContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyContactsQuery, GetCompanyContactsQueryVariables>(
    GetCompanyContactsDocument,
    options
  )
}
export function useGetCompanyContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyContactsQuery, GetCompanyContactsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyContactsQuery, GetCompanyContactsQueryVariables>(
    GetCompanyContactsDocument,
    options
  )
}
export type GetCompanyContactsQueryHookResult = ReturnType<typeof useGetCompanyContactsQuery>
export type GetCompanyContactsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyContactsLazyQuery
>
export type GetCompanyContactsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyContactsSuspenseQuery
>
export type GetCompanyContactsQueryResult = Apollo.QueryResult<
  GetCompanyContactsQuery,
  GetCompanyContactsQueryVariables
>
export const CreateCompanyContactDocument = gql`
  mutation createCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      ...CompanyContactProperties
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type CreateCompanyContactMutationFn = Apollo.MutationFunction<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>

/**
 * __useCreateCompanyContactMutation__
 *
 * To run a mutation, you first call `useCreateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyContactMutation, { data, loading, error }] = useCreateCompanyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyContactMutation,
    CreateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyContactMutation, CreateCompanyContactMutationVariables>(
    CreateCompanyContactDocument,
    options
  )
}
export type CreateCompanyContactMutationHookResult = ReturnType<
  typeof useCreateCompanyContactMutation
>
export type CreateCompanyContactMutationResult = Apollo.MutationResult<CreateCompanyContactMutation>
export type CreateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyContactMutation,
  CreateCompanyContactMutationVariables
>
export const UpdateCompanyContactDocument = gql`
  mutation updateCompanyContact(
    $id: ID!
    $values: CompanyContactValuesInput!
    $removePhoneNumber: Boolean
    $removeJobTitle: Boolean
  ) {
    updateCompanyContact(
      input: {
        id: $id
        values: $values
        removePhoneNumber: $removePhoneNumber
        removeJobTitle: $removeJobTitle
      }
    ) {
      ...CompanyContactProperties
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type UpdateCompanyContactMutationFn = Apollo.MutationFunction<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>

/**
 * __useUpdateCompanyContactMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyContactMutation, { data, loading, error }] = useUpdateCompanyContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *      removePhoneNumber: // value for 'removePhoneNumber'
 *      removeJobTitle: // value for 'removeJobTitle'
 *   },
 * });
 */
export function useUpdateCompanyContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyContactMutation,
    UpdateCompanyContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyContactMutation, UpdateCompanyContactMutationVariables>(
    UpdateCompanyContactDocument,
    options
  )
}
export type UpdateCompanyContactMutationHookResult = ReturnType<
  typeof useUpdateCompanyContactMutation
>
export type UpdateCompanyContactMutationResult = Apollo.MutationResult<UpdateCompanyContactMutation>
export type UpdateCompanyContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyContactMutation,
  UpdateCompanyContactMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, user: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const AddUserTutorialShownDocument = gql`
  mutation addUserTutorialShown($input: AddUserTutorialShownInput!) {
    addUserTutorialShown(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type AddUserTutorialShownMutationFn = Apollo.MutationFunction<
  AddUserTutorialShownMutation,
  AddUserTutorialShownMutationVariables
>

/**
 * __useAddUserTutorialShownMutation__
 *
 * To run a mutation, you first call `useAddUserTutorialShownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserTutorialShownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserTutorialShownMutation, { data, loading, error }] = useAddUserTutorialShownMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserTutorialShownMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserTutorialShownMutation,
    AddUserTutorialShownMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddUserTutorialShownMutation, AddUserTutorialShownMutationVariables>(
    AddUserTutorialShownDocument,
    options
  )
}
export type AddUserTutorialShownMutationHookResult = ReturnType<
  typeof useAddUserTutorialShownMutation
>
export type AddUserTutorialShownMutationResult = Apollo.MutationResult<AddUserTutorialShownMutation>
export type AddUserTutorialShownMutationOptions = Apollo.BaseMutationOptions<
  AddUserTutorialShownMutation,
  AddUserTutorialShownMutationVariables
>
export const AddGeneralContractorContactToLegalRequirementContactsDocument = gql`
  mutation addGeneralContractorContactToLegalRequirementContacts(
    $contractId: ID!
    $companyContactId: ID!
  ) {
    addGeneralContractorContactToLegalRequirementContacts(
      input: { contractId: $contractId, companyContactId: $companyContactId }
    ) {
      id
      legalRequirementContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type AddGeneralContractorContactToLegalRequirementContactsMutationFn =
  Apollo.MutationFunction<
    AddGeneralContractorContactToLegalRequirementContactsMutation,
    AddGeneralContractorContactToLegalRequirementContactsMutationVariables
  >

/**
 * __useAddGeneralContractorContactToLegalRequirementContactsMutation__
 *
 * To run a mutation, you first call `useAddGeneralContractorContactToLegalRequirementContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGeneralContractorContactToLegalRequirementContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGeneralContractorContactToLegalRequirementContactsMutation, { data, loading, error }] = useAddGeneralContractorContactToLegalRequirementContactsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      companyContactId: // value for 'companyContactId'
 *   },
 * });
 */
export function useAddGeneralContractorContactToLegalRequirementContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGeneralContractorContactToLegalRequirementContactsMutation,
    AddGeneralContractorContactToLegalRequirementContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddGeneralContractorContactToLegalRequirementContactsMutation,
    AddGeneralContractorContactToLegalRequirementContactsMutationVariables
  >(AddGeneralContractorContactToLegalRequirementContactsDocument, options)
}
export type AddGeneralContractorContactToLegalRequirementContactsMutationHookResult = ReturnType<
  typeof useAddGeneralContractorContactToLegalRequirementContactsMutation
>
export type AddGeneralContractorContactToLegalRequirementContactsMutationResult =
  Apollo.MutationResult<AddGeneralContractorContactToLegalRequirementContactsMutation>
export type AddGeneralContractorContactToLegalRequirementContactsMutationOptions =
  Apollo.BaseMutationOptions<
    AddGeneralContractorContactToLegalRequirementContactsMutation,
    AddGeneralContractorContactToLegalRequirementContactsMutationVariables
  >
export const DeleteVendorDocument = gql`
  mutation deleteVendor($input: DeleteVendorInput!) {
    deleteVendor(input: $input) {
      id
    }
  }
`
export type DeleteVendorMutationFn = Apollo.MutationFunction<
  DeleteVendorMutation,
  DeleteVendorMutationVariables
>

/**
 * __useDeleteVendorMutation__
 *
 * To run a mutation, you first call `useDeleteVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorMutation, { data, loading, error }] = useDeleteVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVendorMutation, DeleteVendorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVendorMutation, DeleteVendorMutationVariables>(
    DeleteVendorDocument,
    options
  )
}
export type DeleteVendorMutationHookResult = ReturnType<typeof useDeleteVendorMutation>
export type DeleteVendorMutationResult = Apollo.MutationResult<DeleteVendorMutation>
export type DeleteVendorMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorMutation,
  DeleteVendorMutationVariables
>
export const DeleteGeneralContractorContactFromLegalRequirementContactsDocument = gql`
  mutation deleteGeneralContractorContactFromLegalRequirementContacts(
    $contractId: ID!
    $companyContactId: ID!
  ) {
    deleteGeneralContractorContactFromLegalRequirementContacts(
      input: { contractId: $contractId, companyContactId: $companyContactId }
    ) {
      id
      legalRequirementContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type DeleteGeneralContractorContactFromLegalRequirementContactsMutationFn =
  Apollo.MutationFunction<
    DeleteGeneralContractorContactFromLegalRequirementContactsMutation,
    DeleteGeneralContractorContactFromLegalRequirementContactsMutationVariables
  >

/**
 * __useDeleteGeneralContractorContactFromLegalRequirementContactsMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralContractorContactFromLegalRequirementContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralContractorContactFromLegalRequirementContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralContractorContactFromLegalRequirementContactsMutation, { data, loading, error }] = useDeleteGeneralContractorContactFromLegalRequirementContactsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      companyContactId: // value for 'companyContactId'
 *   },
 * });
 */
export function useDeleteGeneralContractorContactFromLegalRequirementContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGeneralContractorContactFromLegalRequirementContactsMutation,
    DeleteGeneralContractorContactFromLegalRequirementContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteGeneralContractorContactFromLegalRequirementContactsMutation,
    DeleteGeneralContractorContactFromLegalRequirementContactsMutationVariables
  >(DeleteGeneralContractorContactFromLegalRequirementContactsDocument, options)
}
export type DeleteGeneralContractorContactFromLegalRequirementContactsMutationHookResult =
  ReturnType<typeof useDeleteGeneralContractorContactFromLegalRequirementContactsMutation>
export type DeleteGeneralContractorContactFromLegalRequirementContactsMutationResult =
  Apollo.MutationResult<DeleteGeneralContractorContactFromLegalRequirementContactsMutation>
export type DeleteGeneralContractorContactFromLegalRequirementContactsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteGeneralContractorContactFromLegalRequirementContactsMutation,
    DeleteGeneralContractorContactFromLegalRequirementContactsMutationVariables
  >
export const AddGeneralContractorContactToVendorSubmitToContactsDocument = gql`
  mutation addGeneralContractorContactToVendorSubmitToContacts(
    $input: GeneralContractorVendorSubmitToContactInput!
  ) {
    addGeneralContractorContactToVendorSubmitToContacts(input: $input) {
      id
      vendorSubmitToContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type AddGeneralContractorContactToVendorSubmitToContactsMutationFn = Apollo.MutationFunction<
  AddGeneralContractorContactToVendorSubmitToContactsMutation,
  AddGeneralContractorContactToVendorSubmitToContactsMutationVariables
>

/**
 * __useAddGeneralContractorContactToVendorSubmitToContactsMutation__
 *
 * To run a mutation, you first call `useAddGeneralContractorContactToVendorSubmitToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGeneralContractorContactToVendorSubmitToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGeneralContractorContactToVendorSubmitToContactsMutation, { data, loading, error }] = useAddGeneralContractorContactToVendorSubmitToContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGeneralContractorContactToVendorSubmitToContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGeneralContractorContactToVendorSubmitToContactsMutation,
    AddGeneralContractorContactToVendorSubmitToContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddGeneralContractorContactToVendorSubmitToContactsMutation,
    AddGeneralContractorContactToVendorSubmitToContactsMutationVariables
  >(AddGeneralContractorContactToVendorSubmitToContactsDocument, options)
}
export type AddGeneralContractorContactToVendorSubmitToContactsMutationHookResult = ReturnType<
  typeof useAddGeneralContractorContactToVendorSubmitToContactsMutation
>
export type AddGeneralContractorContactToVendorSubmitToContactsMutationResult =
  Apollo.MutationResult<AddGeneralContractorContactToVendorSubmitToContactsMutation>
export type AddGeneralContractorContactToVendorSubmitToContactsMutationOptions =
  Apollo.BaseMutationOptions<
    AddGeneralContractorContactToVendorSubmitToContactsMutation,
    AddGeneralContractorContactToVendorSubmitToContactsMutationVariables
  >
export const DeleteGeneralContractorContactFromVendorSubmitToContactsDocument = gql`
  mutation deleteGeneralContractorContactFromVendorSubmitToContacts(
    $input: GeneralContractorVendorSubmitToContactInput!
  ) {
    deleteGeneralContractorContactFromVendorSubmitToContacts(input: $input) {
      id
      vendorSubmitToContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type DeleteGeneralContractorContactFromVendorSubmitToContactsMutationFn =
  Apollo.MutationFunction<
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutation,
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutationVariables
  >

/**
 * __useDeleteGeneralContractorContactFromVendorSubmitToContactsMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralContractorContactFromVendorSubmitToContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralContractorContactFromVendorSubmitToContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralContractorContactFromVendorSubmitToContactsMutation, { data, loading, error }] = useDeleteGeneralContractorContactFromVendorSubmitToContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGeneralContractorContactFromVendorSubmitToContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutation,
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutation,
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutationVariables
  >(DeleteGeneralContractorContactFromVendorSubmitToContactsDocument, options)
}
export type DeleteGeneralContractorContactFromVendorSubmitToContactsMutationHookResult = ReturnType<
  typeof useDeleteGeneralContractorContactFromVendorSubmitToContactsMutation
>
export type DeleteGeneralContractorContactFromVendorSubmitToContactsMutationResult =
  Apollo.MutationResult<DeleteGeneralContractorContactFromVendorSubmitToContactsMutation>
export type DeleteGeneralContractorContactFromVendorSubmitToContactsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutation,
    DeleteGeneralContractorContactFromVendorSubmitToContactsMutationVariables
  >
export const GetContractVendorSubmitToContactsDocument = gql`
  query getContractVendorSubmitToContacts($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      vendorSubmitToContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`

/**
 * __useGetContractVendorSubmitToContactsQuery__
 *
 * To run a query within a React component, call `useGetContractVendorSubmitToContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVendorSubmitToContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVendorSubmitToContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractVendorSubmitToContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractVendorSubmitToContactsQuery,
    GetContractVendorSubmitToContactsQueryVariables
  > &
    (
      | { variables: GetContractVendorSubmitToContactsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractVendorSubmitToContactsQuery,
    GetContractVendorSubmitToContactsQueryVariables
  >(GetContractVendorSubmitToContactsDocument, options)
}
export function useGetContractVendorSubmitToContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractVendorSubmitToContactsQuery,
    GetContractVendorSubmitToContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractVendorSubmitToContactsQuery,
    GetContractVendorSubmitToContactsQueryVariables
  >(GetContractVendorSubmitToContactsDocument, options)
}
export function useGetContractVendorSubmitToContactsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractVendorSubmitToContactsQuery,
        GetContractVendorSubmitToContactsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractVendorSubmitToContactsQuery,
    GetContractVendorSubmitToContactsQueryVariables
  >(GetContractVendorSubmitToContactsDocument, options)
}
export type GetContractVendorSubmitToContactsQueryHookResult = ReturnType<
  typeof useGetContractVendorSubmitToContactsQuery
>
export type GetContractVendorSubmitToContactsLazyQueryHookResult = ReturnType<
  typeof useGetContractVendorSubmitToContactsLazyQuery
>
export type GetContractVendorSubmitToContactsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractVendorSubmitToContactsSuspenseQuery
>
export type GetContractVendorSubmitToContactsQueryResult = Apollo.QueryResult<
  GetContractVendorSubmitToContactsQuery,
  GetContractVendorSubmitToContactsQueryVariables
>
export const VendorDocument = gql`
  query vendor($id: ID!) {
    vendor(id: $id) {
      ...VendorProperties
    }
  }
  ${VendorPropertiesDoc}
`

/**
 * __useVendorQuery__
 *
 * To run a query within a React component, call `useVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorQuery(
  baseOptions: Apollo.QueryHookOptions<VendorQuery, VendorQueryVariables> &
    ({ variables: VendorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorQuery, VendorQueryVariables>(VendorDocument, options)
}
export function useVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VendorQuery, VendorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VendorQuery, VendorQueryVariables>(VendorDocument, options)
}
export function useVendorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<VendorQuery, VendorQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<VendorQuery, VendorQueryVariables>(VendorDocument, options)
}
export type VendorQueryHookResult = ReturnType<typeof useVendorQuery>
export type VendorLazyQueryHookResult = ReturnType<typeof useVendorLazyQuery>
export type VendorSuspenseQueryHookResult = ReturnType<typeof useVendorSuspenseQuery>
export type VendorQueryResult = Apollo.QueryResult<VendorQuery, VendorQueryVariables>
export const GetVendorsDocument = gql`
  query getVendors($input: GetVendorsInput!) {
    vendors(input: $input) {
      ...VendorProperties
    }
  }
  ${VendorPropertiesDoc}
`

/**
 * __useGetVendorsQuery__
 *
 * To run a query within a React component, call `useGetVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables> &
    ({ variables: GetVendorsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options)
}
export function useGetVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetVendorsQuery, GetVendorsQueryVariables>(GetVendorsDocument, options)
}
export function useGetVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetVendorsQuery, GetVendorsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetVendorsQuery, GetVendorsQueryVariables>(
    GetVendorsDocument,
    options
  )
}
export type GetVendorsQueryHookResult = ReturnType<typeof useGetVendorsQuery>
export type GetVendorsLazyQueryHookResult = ReturnType<typeof useGetVendorsLazyQuery>
export type GetVendorsSuspenseQueryHookResult = ReturnType<typeof useGetVendorsSuspenseQuery>
export type GetVendorsQueryResult = Apollo.QueryResult<GetVendorsQuery, GetVendorsQueryVariables>
export const CreateVendorDocument = gql`
  mutation createVendor($input: CreateVendorInput!) {
    createVendor(input: $input) {
      ...VendorProperties
    }
  }
  ${VendorPropertiesDoc}
`
export type CreateVendorMutationFn = Apollo.MutationFunction<
  CreateVendorMutation,
  CreateVendorMutationVariables
>

/**
 * __useCreateVendorMutation__
 *
 * To run a mutation, you first call `useCreateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorMutation, { data, loading, error }] = useCreateVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVendorMutation, CreateVendorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateVendorMutation, CreateVendorMutationVariables>(
    CreateVendorDocument,
    options
  )
}
export type CreateVendorMutationHookResult = ReturnType<typeof useCreateVendorMutation>
export type CreateVendorMutationResult = Apollo.MutationResult<CreateVendorMutation>
export type CreateVendorMutationOptions = Apollo.BaseMutationOptions<
  CreateVendorMutation,
  CreateVendorMutationVariables
>
export const CreateVendorContactDocument = gql`
  mutation createVendorContact($input: CreateVendorContactInput!) {
    createVendorContact(input: $input) {
      ...VendorContactProperties
      vendor {
        id
      }
    }
  }
  ${VendorContactPropertiesDoc}
`
export type CreateVendorContactMutationFn = Apollo.MutationFunction<
  CreateVendorContactMutation,
  CreateVendorContactMutationVariables
>

/**
 * __useCreateVendorContactMutation__
 *
 * To run a mutation, you first call `useCreateVendorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorContactMutation, { data, loading, error }] = useCreateVendorContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVendorContactMutation,
    CreateVendorContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateVendorContactMutation, CreateVendorContactMutationVariables>(
    CreateVendorContactDocument,
    options
  )
}
export type CreateVendorContactMutationHookResult = ReturnType<
  typeof useCreateVendorContactMutation
>
export type CreateVendorContactMutationResult = Apollo.MutationResult<CreateVendorContactMutation>
export type CreateVendorContactMutationOptions = Apollo.BaseMutationOptions<
  CreateVendorContactMutation,
  CreateVendorContactMutationVariables
>
export const LienWaiverRequestWithTokenDocument = gql`
  query lienWaiverRequestWithToken($token: String!) {
    lienWaiverRequestWithToken(input: { token: $token }) {
      ...LienWaiverRequestForLowerTierSignatureProperties
    }
  }
  ${LienWaiverRequestForLowerTierSignaturePropertiesDoc}
`

/**
 * __useLienWaiverRequestWithTokenQuery__
 *
 * To run a query within a React component, call `useLienWaiverRequestWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiverRequestWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiverRequestWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLienWaiverRequestWithTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    LienWaiverRequestWithTokenQuery,
    LienWaiverRequestWithTokenQueryVariables
  > &
    ({ variables: LienWaiverRequestWithTokenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LienWaiverRequestWithTokenQuery, LienWaiverRequestWithTokenQueryVariables>(
    LienWaiverRequestWithTokenDocument,
    options
  )
}
export function useLienWaiverRequestWithTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiverRequestWithTokenQuery,
    LienWaiverRequestWithTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LienWaiverRequestWithTokenQuery,
    LienWaiverRequestWithTokenQueryVariables
  >(LienWaiverRequestWithTokenDocument, options)
}
export function useLienWaiverRequestWithTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LienWaiverRequestWithTokenQuery,
        LienWaiverRequestWithTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LienWaiverRequestWithTokenQuery,
    LienWaiverRequestWithTokenQueryVariables
  >(LienWaiverRequestWithTokenDocument, options)
}
export type LienWaiverRequestWithTokenQueryHookResult = ReturnType<
  typeof useLienWaiverRequestWithTokenQuery
>
export type LienWaiverRequestWithTokenLazyQueryHookResult = ReturnType<
  typeof useLienWaiverRequestWithTokenLazyQuery
>
export type LienWaiverRequestWithTokenSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiverRequestWithTokenSuspenseQuery
>
export type LienWaiverRequestWithTokenQueryResult = Apollo.QueryResult<
  LienWaiverRequestWithTokenQuery,
  LienWaiverRequestWithTokenQueryVariables
>
export const LienWaiverRequestIdFromTokenDocument = gql`
  query lienWaiverRequestIdFromToken($token: String!) {
    lienWaiverRequestWithToken(input: { token: $token }) {
      id
    }
  }
`

/**
 * __useLienWaiverRequestIdFromTokenQuery__
 *
 * To run a query within a React component, call `useLienWaiverRequestIdFromTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiverRequestIdFromTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiverRequestIdFromTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLienWaiverRequestIdFromTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    LienWaiverRequestIdFromTokenQuery,
    LienWaiverRequestIdFromTokenQueryVariables
  > &
    ({ variables: LienWaiverRequestIdFromTokenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LienWaiverRequestIdFromTokenQuery,
    LienWaiverRequestIdFromTokenQueryVariables
  >(LienWaiverRequestIdFromTokenDocument, options)
}
export function useLienWaiverRequestIdFromTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiverRequestIdFromTokenQuery,
    LienWaiverRequestIdFromTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LienWaiverRequestIdFromTokenQuery,
    LienWaiverRequestIdFromTokenQueryVariables
  >(LienWaiverRequestIdFromTokenDocument, options)
}
export function useLienWaiverRequestIdFromTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LienWaiverRequestIdFromTokenQuery,
        LienWaiverRequestIdFromTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LienWaiverRequestIdFromTokenQuery,
    LienWaiverRequestIdFromTokenQueryVariables
  >(LienWaiverRequestIdFromTokenDocument, options)
}
export type LienWaiverRequestIdFromTokenQueryHookResult = ReturnType<
  typeof useLienWaiverRequestIdFromTokenQuery
>
export type LienWaiverRequestIdFromTokenLazyQueryHookResult = ReturnType<
  typeof useLienWaiverRequestIdFromTokenLazyQuery
>
export type LienWaiverRequestIdFromTokenSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiverRequestIdFromTokenSuspenseQuery
>
export type LienWaiverRequestIdFromTokenQueryResult = Apollo.QueryResult<
  LienWaiverRequestIdFromTokenQuery,
  LienWaiverRequestIdFromTokenQueryVariables
>
export const LienWaiverRequestsWithTokenDocument = gql`
  query lienWaiverRequestsWithToken($token: String!) {
    lienWaiverRequestsWithToken(input: { token: $token }) {
      token
      lienWaiverRequest {
        ...LienWaiverRequestForLowerTierSignatureProperties
      }
    }
  }
  ${LienWaiverRequestForLowerTierSignaturePropertiesDoc}
`

/**
 * __useLienWaiverRequestsWithTokenQuery__
 *
 * To run a query within a React component, call `useLienWaiverRequestsWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiverRequestsWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiverRequestsWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLienWaiverRequestsWithTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    LienWaiverRequestsWithTokenQuery,
    LienWaiverRequestsWithTokenQueryVariables
  > &
    ({ variables: LienWaiverRequestsWithTokenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LienWaiverRequestsWithTokenQuery,
    LienWaiverRequestsWithTokenQueryVariables
  >(LienWaiverRequestsWithTokenDocument, options)
}
export function useLienWaiverRequestsWithTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiverRequestsWithTokenQuery,
    LienWaiverRequestsWithTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LienWaiverRequestsWithTokenQuery,
    LienWaiverRequestsWithTokenQueryVariables
  >(LienWaiverRequestsWithTokenDocument, options)
}
export function useLienWaiverRequestsWithTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LienWaiverRequestsWithTokenQuery,
        LienWaiverRequestsWithTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LienWaiverRequestsWithTokenQuery,
    LienWaiverRequestsWithTokenQueryVariables
  >(LienWaiverRequestsWithTokenDocument, options)
}
export type LienWaiverRequestsWithTokenQueryHookResult = ReturnType<
  typeof useLienWaiverRequestsWithTokenQuery
>
export type LienWaiverRequestsWithTokenLazyQueryHookResult = ReturnType<
  typeof useLienWaiverRequestsWithTokenLazyQuery
>
export type LienWaiverRequestsWithTokenSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiverRequestsWithTokenSuspenseQuery
>
export type LienWaiverRequestsWithTokenQueryResult = Apollo.QueryResult<
  LienWaiverRequestsWithTokenQuery,
  LienWaiverRequestsWithTokenQueryVariables
>
export const MarkLienWaiverRequestViewedDocument = gql`
  mutation markLienWaiverRequestViewed($input: MarkLienWaiverRequestViewedInput!) {
    markLienWaiverRequestViewed(input: $input) {
      ...LienWaiverRequestForLowerTierSignatureProperties
    }
  }
  ${LienWaiverRequestForLowerTierSignaturePropertiesDoc}
`
export type MarkLienWaiverRequestViewedMutationFn = Apollo.MutationFunction<
  MarkLienWaiverRequestViewedMutation,
  MarkLienWaiverRequestViewedMutationVariables
>

/**
 * __useMarkLienWaiverRequestViewedMutation__
 *
 * To run a mutation, you first call `useMarkLienWaiverRequestViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkLienWaiverRequestViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markLienWaiverRequestViewedMutation, { data, loading, error }] = useMarkLienWaiverRequestViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkLienWaiverRequestViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkLienWaiverRequestViewedMutation,
    MarkLienWaiverRequestViewedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkLienWaiverRequestViewedMutation,
    MarkLienWaiverRequestViewedMutationVariables
  >(MarkLienWaiverRequestViewedDocument, options)
}
export type MarkLienWaiverRequestViewedMutationHookResult = ReturnType<
  typeof useMarkLienWaiverRequestViewedMutation
>
export type MarkLienWaiverRequestViewedMutationResult =
  Apollo.MutationResult<MarkLienWaiverRequestViewedMutation>
export type MarkLienWaiverRequestViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkLienWaiverRequestViewedMutation,
  MarkLienWaiverRequestViewedMutationVariables
>
export const LegalDocumentRequestWithTokenDocument = gql`
  query legalDocumentRequestWithToken($input: GetWithTokenInput!) {
    legalDocumentRequestWithToken(input: $input) {
      ...LegalDocumentRequestProperties
      legalDocument {
        ...MinimalLegalDocumentProperties
        lastSendTime
        isUploaded
        vendorContract {
          id
          vendor {
            id
            name
          }
          lowerTierTo {
            id
            vendor {
              id
              name
            }
          }
        }
        legalRequirement {
          id
          timeZone
          expirationFrequency
          name
          type
          vendorTemplate {
            id
            name
            url
          }
          contract {
            id
            internalProjectNumber
            company {
              id
              name
            }
            project {
              id
              projectNumber
              name
            }
          }
        }
      }
    }
  }
  ${LegalDocumentRequestPropertiesDoc}
  ${MinimalLegalDocumentPropertiesDoc}
`

/**
 * __useLegalDocumentRequestWithTokenQuery__
 *
 * To run a query within a React component, call `useLegalDocumentRequestWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalDocumentRequestWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalDocumentRequestWithTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLegalDocumentRequestWithTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    LegalDocumentRequestWithTokenQuery,
    LegalDocumentRequestWithTokenQueryVariables
  > &
    ({ variables: LegalDocumentRequestWithTokenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LegalDocumentRequestWithTokenQuery,
    LegalDocumentRequestWithTokenQueryVariables
  >(LegalDocumentRequestWithTokenDocument, options)
}
export function useLegalDocumentRequestWithTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LegalDocumentRequestWithTokenQuery,
    LegalDocumentRequestWithTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LegalDocumentRequestWithTokenQuery,
    LegalDocumentRequestWithTokenQueryVariables
  >(LegalDocumentRequestWithTokenDocument, options)
}
export function useLegalDocumentRequestWithTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LegalDocumentRequestWithTokenQuery,
        LegalDocumentRequestWithTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LegalDocumentRequestWithTokenQuery,
    LegalDocumentRequestWithTokenQueryVariables
  >(LegalDocumentRequestWithTokenDocument, options)
}
export type LegalDocumentRequestWithTokenQueryHookResult = ReturnType<
  typeof useLegalDocumentRequestWithTokenQuery
>
export type LegalDocumentRequestWithTokenLazyQueryHookResult = ReturnType<
  typeof useLegalDocumentRequestWithTokenLazyQuery
>
export type LegalDocumentRequestWithTokenSuspenseQueryHookResult = ReturnType<
  typeof useLegalDocumentRequestWithTokenSuspenseQuery
>
export type LegalDocumentRequestWithTokenQueryResult = Apollo.QueryResult<
  LegalDocumentRequestWithTokenQuery,
  LegalDocumentRequestWithTokenQueryVariables
>
export const UploadFormTemplatesDocument = gql`
  mutation uploadFormTemplates($input: UploadFormTemplatesInput!) {
    uploadFormTemplates(input: $input) {
      id
      includeChangeOrderLogInPayAppPackage
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type UploadFormTemplatesMutationFn = Apollo.MutationFunction<
  UploadFormTemplatesMutation,
  UploadFormTemplatesMutationVariables
>

/**
 * __useUploadFormTemplatesMutation__
 *
 * To run a mutation, you first call `useUploadFormTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFormTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFormTemplatesMutation, { data, loading, error }] = useUploadFormTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFormTemplatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFormTemplatesMutation,
    UploadFormTemplatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadFormTemplatesMutation, UploadFormTemplatesMutationVariables>(
    UploadFormTemplatesDocument,
    options
  )
}
export type UploadFormTemplatesMutationHookResult = ReturnType<
  typeof useUploadFormTemplatesMutation
>
export type UploadFormTemplatesMutationResult = Apollo.MutationResult<UploadFormTemplatesMutation>
export type UploadFormTemplatesMutationOptions = Apollo.BaseMutationOptions<
  UploadFormTemplatesMutation,
  UploadFormTemplatesMutationVariables
>
export const UploadLegalDocumentWithTokenDocument = gql`
  mutation uploadLegalDocumentWithToken($input: UploadLegalDocumentWithTokenInput!) {
    uploadLegalDocumentWithToken(input: $input) {
      ...LegalDocumentRequestProperties
    }
  }
  ${LegalDocumentRequestPropertiesDoc}
`
export type UploadLegalDocumentWithTokenMutationFn = Apollo.MutationFunction<
  UploadLegalDocumentWithTokenMutation,
  UploadLegalDocumentWithTokenMutationVariables
>

/**
 * __useUploadLegalDocumentWithTokenMutation__
 *
 * To run a mutation, you first call `useUploadLegalDocumentWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLegalDocumentWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLegalDocumentWithTokenMutation, { data, loading, error }] = useUploadLegalDocumentWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadLegalDocumentWithTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadLegalDocumentWithTokenMutation,
    UploadLegalDocumentWithTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadLegalDocumentWithTokenMutation,
    UploadLegalDocumentWithTokenMutationVariables
  >(UploadLegalDocumentWithTokenDocument, options)
}
export type UploadLegalDocumentWithTokenMutationHookResult = ReturnType<
  typeof useUploadLegalDocumentWithTokenMutation
>
export type UploadLegalDocumentWithTokenMutationResult =
  Apollo.MutationResult<UploadLegalDocumentWithTokenMutation>
export type UploadLegalDocumentWithTokenMutationOptions = Apollo.BaseMutationOptions<
  UploadLegalDocumentWithTokenMutation,
  UploadLegalDocumentWithTokenMutationVariables
>
export const LienWaiverRequestDocument = gql`
  query lienWaiverRequest($id: ID!) {
    lienWaiverRequest(id: $id) {
      ...LienWaiverRequestProperties
    }
  }
  ${LienWaiverRequestPropertiesDoc}
`

/**
 * __useLienWaiverRequestQuery__
 *
 * To run a query within a React component, call `useLienWaiverRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiverRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiverRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLienWaiverRequestQuery(
  baseOptions: Apollo.QueryHookOptions<LienWaiverRequestQuery, LienWaiverRequestQueryVariables> &
    ({ variables: LienWaiverRequestQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LienWaiverRequestQuery, LienWaiverRequestQueryVariables>(
    LienWaiverRequestDocument,
    options
  )
}
export function useLienWaiverRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LienWaiverRequestQuery, LienWaiverRequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LienWaiverRequestQuery, LienWaiverRequestQueryVariables>(
    LienWaiverRequestDocument,
    options
  )
}
export function useLienWaiverRequestSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LienWaiverRequestQuery, LienWaiverRequestQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LienWaiverRequestQuery, LienWaiverRequestQueryVariables>(
    LienWaiverRequestDocument,
    options
  )
}
export type LienWaiverRequestQueryHookResult = ReturnType<typeof useLienWaiverRequestQuery>
export type LienWaiverRequestLazyQueryHookResult = ReturnType<typeof useLienWaiverRequestLazyQuery>
export type LienWaiverRequestSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiverRequestSuspenseQuery
>
export type LienWaiverRequestQueryResult = Apollo.QueryResult<
  LienWaiverRequestQuery,
  LienWaiverRequestQueryVariables
>
export const GetLienWaiverWithTokenDocument = gql`
  query getLienWaiverWithToken($token: String!) {
    lienWaiverWithToken(input: { token: $token }) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`

/**
 * __useGetLienWaiverWithTokenQuery__
 *
 * To run a query within a React component, call `useGetLienWaiverWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLienWaiverWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLienWaiverWithTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetLienWaiverWithTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLienWaiverWithTokenQuery,
    GetLienWaiverWithTokenQueryVariables
  > &
    ({ variables: GetLienWaiverWithTokenQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLienWaiverWithTokenQuery, GetLienWaiverWithTokenQueryVariables>(
    GetLienWaiverWithTokenDocument,
    options
  )
}
export function useGetLienWaiverWithTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLienWaiverWithTokenQuery,
    GetLienWaiverWithTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLienWaiverWithTokenQuery, GetLienWaiverWithTokenQueryVariables>(
    GetLienWaiverWithTokenDocument,
    options
  )
}
export function useGetLienWaiverWithTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLienWaiverWithTokenQuery,
        GetLienWaiverWithTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetLienWaiverWithTokenQuery, GetLienWaiverWithTokenQueryVariables>(
    GetLienWaiverWithTokenDocument,
    options
  )
}
export type GetLienWaiverWithTokenQueryHookResult = ReturnType<
  typeof useGetLienWaiverWithTokenQuery
>
export type GetLienWaiverWithTokenLazyQueryHookResult = ReturnType<
  typeof useGetLienWaiverWithTokenLazyQuery
>
export type GetLienWaiverWithTokenSuspenseQueryHookResult = ReturnType<
  typeof useGetLienWaiverWithTokenSuspenseQuery
>
export type GetLienWaiverWithTokenQueryResult = Apollo.QueryResult<
  GetLienWaiverWithTokenQuery,
  GetLienWaiverWithTokenQueryVariables
>
export const SignLowerTierLienWaiverDocument = gql`
  mutation signLowerTierLienWaiver($input: SignLowerTierLienWaiverInput!) {
    signLowerTierLienWaiver(input: $input) {
      ...LienWaiverRequestProperties
    }
  }
  ${LienWaiverRequestPropertiesDoc}
`
export type SignLowerTierLienWaiverMutationFn = Apollo.MutationFunction<
  SignLowerTierLienWaiverMutation,
  SignLowerTierLienWaiverMutationVariables
>

/**
 * __useSignLowerTierLienWaiverMutation__
 *
 * To run a mutation, you first call `useSignLowerTierLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignLowerTierLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signLowerTierLienWaiverMutation, { data, loading, error }] = useSignLowerTierLienWaiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignLowerTierLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignLowerTierLienWaiverMutation,
    SignLowerTierLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignLowerTierLienWaiverMutation,
    SignLowerTierLienWaiverMutationVariables
  >(SignLowerTierLienWaiverDocument, options)
}
export type SignLowerTierLienWaiverMutationHookResult = ReturnType<
  typeof useSignLowerTierLienWaiverMutation
>
export type SignLowerTierLienWaiverMutationResult =
  Apollo.MutationResult<SignLowerTierLienWaiverMutation>
export type SignLowerTierLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  SignLowerTierLienWaiverMutation,
  SignLowerTierLienWaiverMutationVariables
>
export const SaveLowerTierLienWaiverFormValuesDocument = gql`
  mutation saveLowerTierLienWaiverFormValues($input: SaveLowerTierLienWaiverFormValuesInput!) {
    saveLowerTierLienWaiverFormValues(input: $input) {
      ...LienWaiverRequestProperties
      lienWaiver {
        id
        amount
        formValues {
          ...FormTemplateAnnotationValueProperties
        }
      }
    }
  }
  ${LienWaiverRequestPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
`
export type SaveLowerTierLienWaiverFormValuesMutationFn = Apollo.MutationFunction<
  SaveLowerTierLienWaiverFormValuesMutation,
  SaveLowerTierLienWaiverFormValuesMutationVariables
>

/**
 * __useSaveLowerTierLienWaiverFormValuesMutation__
 *
 * To run a mutation, you first call `useSaveLowerTierLienWaiverFormValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLowerTierLienWaiverFormValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLowerTierLienWaiverFormValuesMutation, { data, loading, error }] = useSaveLowerTierLienWaiverFormValuesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLowerTierLienWaiverFormValuesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveLowerTierLienWaiverFormValuesMutation,
    SaveLowerTierLienWaiverFormValuesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveLowerTierLienWaiverFormValuesMutation,
    SaveLowerTierLienWaiverFormValuesMutationVariables
  >(SaveLowerTierLienWaiverFormValuesDocument, options)
}
export type SaveLowerTierLienWaiverFormValuesMutationHookResult = ReturnType<
  typeof useSaveLowerTierLienWaiverFormValuesMutation
>
export type SaveLowerTierLienWaiverFormValuesMutationResult =
  Apollo.MutationResult<SaveLowerTierLienWaiverFormValuesMutation>
export type SaveLowerTierLienWaiverFormValuesMutationOptions = Apollo.BaseMutationOptions<
  SaveLowerTierLienWaiverFormValuesMutation,
  SaveLowerTierLienWaiverFormValuesMutationVariables
>
export const BatchSendLienWaiverRequestsDocument = gql`
  mutation batchSendLienWaiverRequests($input: BatchSendLienWaiverRequestsInput!) {
    batchSendLienWaiverRequests(input: $input) {
      id
      type
      date
      timeZone
      contract {
        id
      }
      vendorContract {
        id
        vendor {
          id
        }
      }
      lienWaiverRequests {
        ...LienWaiverRequestProperties
        statusLogs {
          id
          status
        }
      }
    }
  }
  ${LienWaiverRequestPropertiesDoc}
`
export type BatchSendLienWaiverRequestsMutationFn = Apollo.MutationFunction<
  BatchSendLienWaiverRequestsMutation,
  BatchSendLienWaiverRequestsMutationVariables
>

/**
 * __useBatchSendLienWaiverRequestsMutation__
 *
 * To run a mutation, you first call `useBatchSendLienWaiverRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSendLienWaiverRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSendLienWaiverRequestsMutation, { data, loading, error }] = useBatchSendLienWaiverRequestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchSendLienWaiverRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchSendLienWaiverRequestsMutation,
    BatchSendLienWaiverRequestsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BatchSendLienWaiverRequestsMutation,
    BatchSendLienWaiverRequestsMutationVariables
  >(BatchSendLienWaiverRequestsDocument, options)
}
export type BatchSendLienWaiverRequestsMutationHookResult = ReturnType<
  typeof useBatchSendLienWaiverRequestsMutation
>
export type BatchSendLienWaiverRequestsMutationResult =
  Apollo.MutationResult<BatchSendLienWaiverRequestsMutation>
export type BatchSendLienWaiverRequestsMutationOptions = Apollo.BaseMutationOptions<
  BatchSendLienWaiverRequestsMutation,
  BatchSendLienWaiverRequestsMutationVariables
>
export const DeleteVendorLienWaiverDocument = gql`
  mutation deleteVendorLienWaiver($id: ID!) {
    deleteVendorLienWaiver(id: $id) {
      id
    }
  }
`
export type DeleteVendorLienWaiverMutationFn = Apollo.MutationFunction<
  DeleteVendorLienWaiverMutation,
  DeleteVendorLienWaiverMutationVariables
>

/**
 * __useDeleteVendorLienWaiverMutation__
 *
 * To run a mutation, you first call `useDeleteVendorLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorLienWaiverMutation, { data, loading, error }] = useDeleteVendorLienWaiverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVendorLienWaiverMutation,
    DeleteVendorLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteVendorLienWaiverMutation,
    DeleteVendorLienWaiverMutationVariables
  >(DeleteVendorLienWaiverDocument, options)
}
export type DeleteVendorLienWaiverMutationHookResult = ReturnType<
  typeof useDeleteVendorLienWaiverMutation
>
export type DeleteVendorLienWaiverMutationResult =
  Apollo.MutationResult<DeleteVendorLienWaiverMutation>
export type DeleteVendorLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorLienWaiverMutation,
  DeleteVendorLienWaiverMutationVariables
>
export const SubmitVendorLienWaiversDocument = gql`
  mutation submitVendorLienWaivers($input: SubmitVendorLienWaiversInput!) {
    submitVendorLienWaivers(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type SubmitVendorLienWaiversMutationFn = Apollo.MutationFunction<
  SubmitVendorLienWaiversMutation,
  SubmitVendorLienWaiversMutationVariables
>

/**
 * __useSubmitVendorLienWaiversMutation__
 *
 * To run a mutation, you first call `useSubmitVendorLienWaiversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVendorLienWaiversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVendorLienWaiversMutation, { data, loading, error }] = useSubmitVendorLienWaiversMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitVendorLienWaiversMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitVendorLienWaiversMutation,
    SubmitVendorLienWaiversMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitVendorLienWaiversMutation,
    SubmitVendorLienWaiversMutationVariables
  >(SubmitVendorLienWaiversDocument, options)
}
export type SubmitVendorLienWaiversMutationHookResult = ReturnType<
  typeof useSubmitVendorLienWaiversMutation
>
export type SubmitVendorLienWaiversMutationResult =
  Apollo.MutationResult<SubmitVendorLienWaiversMutation>
export type SubmitVendorLienWaiversMutationOptions = Apollo.BaseMutationOptions<
  SubmitVendorLienWaiversMutation,
  SubmitVendorLienWaiversMutationVariables
>
export const SendCompleteLienWaiverRequestDocument = gql`
  mutation sendCompleteLienWaiverRequest($token: String!) {
    sendCompleteLienWaiverRequest(input: { token: $token }) {
      id
      status
      actionTakenAt
    }
  }
`
export type SendCompleteLienWaiverRequestMutationFn = Apollo.MutationFunction<
  SendCompleteLienWaiverRequestMutation,
  SendCompleteLienWaiverRequestMutationVariables
>

/**
 * __useSendCompleteLienWaiverRequestMutation__
 *
 * To run a mutation, you first call `useSendCompleteLienWaiverRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCompleteLienWaiverRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCompleteLienWaiverRequestMutation, { data, loading, error }] = useSendCompleteLienWaiverRequestMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSendCompleteLienWaiverRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCompleteLienWaiverRequestMutation,
    SendCompleteLienWaiverRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendCompleteLienWaiverRequestMutation,
    SendCompleteLienWaiverRequestMutationVariables
  >(SendCompleteLienWaiverRequestDocument, options)
}
export type SendCompleteLienWaiverRequestMutationHookResult = ReturnType<
  typeof useSendCompleteLienWaiverRequestMutation
>
export type SendCompleteLienWaiverRequestMutationResult =
  Apollo.MutationResult<SendCompleteLienWaiverRequestMutation>
export type SendCompleteLienWaiverRequestMutationOptions = Apollo.BaseMutationOptions<
  SendCompleteLienWaiverRequestMutation,
  SendCompleteLienWaiverRequestMutationVariables
>
export const LegalRequirementDocument = gql`
  query legalRequirement($id: ID!) {
    legalRequirement(id: $id) {
      ...LegalRequirementProperties
      contract {
        id
        company {
          id
          name
        }
        project {
          id
        }
      }
    }
  }
  ${LegalRequirementPropertiesDoc}
`

/**
 * __useLegalRequirementQuery__
 *
 * To run a query within a React component, call `useLegalRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalRequirementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLegalRequirementQuery(
  baseOptions: Apollo.QueryHookOptions<LegalRequirementQuery, LegalRequirementQueryVariables> &
    ({ variables: LegalRequirementQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LegalRequirementQuery, LegalRequirementQueryVariables>(
    LegalRequirementDocument,
    options
  )
}
export function useLegalRequirementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegalRequirementQuery, LegalRequirementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LegalRequirementQuery, LegalRequirementQueryVariables>(
    LegalRequirementDocument,
    options
  )
}
export function useLegalRequirementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LegalRequirementQuery, LegalRequirementQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LegalRequirementQuery, LegalRequirementQueryVariables>(
    LegalRequirementDocument,
    options
  )
}
export type LegalRequirementQueryHookResult = ReturnType<typeof useLegalRequirementQuery>
export type LegalRequirementLazyQueryHookResult = ReturnType<typeof useLegalRequirementLazyQuery>
export type LegalRequirementSuspenseQueryHookResult = ReturnType<
  typeof useLegalRequirementSuspenseQuery
>
export type LegalRequirementQueryResult = Apollo.QueryResult<
  LegalRequirementQuery,
  LegalRequirementQueryVariables
>
export const LegalDocumentDocument = gql`
  query legalDocument($id: ID!) {
    legalDocument(id: $id) {
      ...LegalDocumentProperties
    }
  }
  ${LegalDocumentPropertiesDoc}
`

/**
 * __useLegalDocumentQuery__
 *
 * To run a query within a React component, call `useLegalDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLegalDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<LegalDocumentQuery, LegalDocumentQueryVariables> &
    ({ variables: LegalDocumentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LegalDocumentQuery, LegalDocumentQueryVariables>(
    LegalDocumentDocument,
    options
  )
}
export function useLegalDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegalDocumentQuery, LegalDocumentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LegalDocumentQuery, LegalDocumentQueryVariables>(
    LegalDocumentDocument,
    options
  )
}
export function useLegalDocumentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LegalDocumentQuery, LegalDocumentQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LegalDocumentQuery, LegalDocumentQueryVariables>(
    LegalDocumentDocument,
    options
  )
}
export type LegalDocumentQueryHookResult = ReturnType<typeof useLegalDocumentQuery>
export type LegalDocumentLazyQueryHookResult = ReturnType<typeof useLegalDocumentLazyQuery>
export type LegalDocumentSuspenseQueryHookResult = ReturnType<typeof useLegalDocumentSuspenseQuery>
export type LegalDocumentQueryResult = Apollo.QueryResult<
  LegalDocumentQuery,
  LegalDocumentQueryVariables
>
export const UpdateLienWaiverDocument = gql`
  mutation updateLienWaiver($input: UpdateLienWaiverInput!) {
    updateLienWaiver(input: $input) {
      id
      type
      timeZone
      defaultAmount
      defaultAmountText
      amount
      isJointCheck
      subject
      message
      defaultExceptionAmounts
      defaultExceptionDates
      hasSelectedDefaultVendorContacts
      requestAttachments {
        ...StoredFileProperties
      }
      defaultVendorContacts {
        ...VendorContactProperties
      }
    }
  }
  ${StoredFilePropertiesDoc}
  ${VendorContactPropertiesDoc}
`
export type UpdateLienWaiverMutationFn = Apollo.MutationFunction<
  UpdateLienWaiverMutation,
  UpdateLienWaiverMutationVariables
>

/**
 * __useUpdateLienWaiverMutation__
 *
 * To run a mutation, you first call `useUpdateLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLienWaiverMutation, { data, loading, error }] = useUpdateLienWaiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLienWaiverMutation,
    UpdateLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLienWaiverMutation, UpdateLienWaiverMutationVariables>(
    UpdateLienWaiverDocument,
    options
  )
}
export type UpdateLienWaiverMutationHookResult = ReturnType<typeof useUpdateLienWaiverMutation>
export type UpdateLienWaiverMutationResult = Apollo.MutationResult<UpdateLienWaiverMutation>
export type UpdateLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  UpdateLienWaiverMutation,
  UpdateLienWaiverMutationVariables
>
export const CreateVendorLienWaiverDocument = gql`
  mutation createVendorLienWaiver($input: CreateVendorLienWaiverInput!) {
    createVendorLienWaiver(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type CreateVendorLienWaiverMutationFn = Apollo.MutationFunction<
  CreateVendorLienWaiverMutation,
  CreateVendorLienWaiverMutationVariables
>

/**
 * __useCreateVendorLienWaiverMutation__
 *
 * To run a mutation, you first call `useCreateVendorLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorLienWaiverMutation, { data, loading, error }] = useCreateVendorLienWaiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVendorLienWaiverMutation,
    CreateVendorLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateVendorLienWaiverMutation,
    CreateVendorLienWaiverMutationVariables
  >(CreateVendorLienWaiverDocument, options)
}
export type CreateVendorLienWaiverMutationHookResult = ReturnType<
  typeof useCreateVendorLienWaiverMutation
>
export type CreateVendorLienWaiverMutationResult =
  Apollo.MutationResult<CreateVendorLienWaiverMutation>
export type CreateVendorLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  CreateVendorLienWaiverMutation,
  CreateVendorLienWaiverMutationVariables
>
export const UploadLienWaiverFileDocument = gql`
  mutation uploadLienWaiverFile($input: UploadLienWaiverInput!) {
    uploadLienWaiverFile(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type UploadLienWaiverFileMutationFn = Apollo.MutationFunction<
  UploadLienWaiverFileMutation,
  UploadLienWaiverFileMutationVariables
>

/**
 * __useUploadLienWaiverFileMutation__
 *
 * To run a mutation, you first call `useUploadLienWaiverFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLienWaiverFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLienWaiverFileMutation, { data, loading, error }] = useUploadLienWaiverFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadLienWaiverFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadLienWaiverFileMutation,
    UploadLienWaiverFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadLienWaiverFileMutation, UploadLienWaiverFileMutationVariables>(
    UploadLienWaiverFileDocument,
    options
  )
}
export type UploadLienWaiverFileMutationHookResult = ReturnType<
  typeof useUploadLienWaiverFileMutation
>
export type UploadLienWaiverFileMutationResult = Apollo.MutationResult<UploadLienWaiverFileMutation>
export type UploadLienWaiverFileMutationOptions = Apollo.BaseMutationOptions<
  UploadLienWaiverFileMutation,
  UploadLienWaiverFileMutationVariables
>
export const MarkNotificationsReadDocument = gql`
  mutation markNotificationsRead($notificationIds: [ID!]!) {
    markNotificationsRead(notificationIds: $notificationIds) {
      ...NotificationProperties
    }
  }
  ${NotificationPropertiesDoc}
`
export type MarkNotificationsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsReadMutation,
    MarkNotificationsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>(
    MarkNotificationsReadDocument,
    options
  )
}
export type MarkNotificationsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsReadMutation
>
export type MarkNotificationsReadMutationResult =
  Apollo.MutationResult<MarkNotificationsReadMutation>
export type MarkNotificationsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsReadMutation,
  MarkNotificationsReadMutationVariables
>
export const GetNotificationsDocument = gql`
  query getNotifications($limit: Int!, $cursor: DateTime) {
    notifications(input: { limit: $limit, cursor: $cursor }) {
      cursor
      hasNext
      notifications {
        ...NotificationProperties
      }
    }
  }
  ${NotificationPropertiesDoc}
`

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables> &
    ({ variables: GetNotificationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  )
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  )
}
export function useGetNotificationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  )
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>
export type GetNotificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetNotificationsSuspenseQuery
>
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>
export const SendLegalDocumentsDocument = gql`
  mutation sendLegalDocuments(
    $contractId: ID!
    $legalDocumentIds: [ID!]!
    $includeTableOfContents: Boolean!
    $subject: String!
    $message: String
  ) {
    sendLegalDocuments(
      input: {
        contractId: $contractId
        legalDocumentIds: $legalDocumentIds
        includeTableOfContents: $includeTableOfContents
        subject: $subject
        message: $message
      }
    ) {
      id
    }
  }
`
export type SendLegalDocumentsMutationFn = Apollo.MutationFunction<
  SendLegalDocumentsMutation,
  SendLegalDocumentsMutationVariables
>

/**
 * __useSendLegalDocumentsMutation__
 *
 * To run a mutation, you first call `useSendLegalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendLegalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendLegalDocumentsMutation, { data, loading, error }] = useSendLegalDocumentsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      legalDocumentIds: // value for 'legalDocumentIds'
 *      includeTableOfContents: // value for 'includeTableOfContents'
 *      subject: // value for 'subject'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendLegalDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendLegalDocumentsMutation,
    SendLegalDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendLegalDocumentsMutation, SendLegalDocumentsMutationVariables>(
    SendLegalDocumentsDocument,
    options
  )
}
export type SendLegalDocumentsMutationHookResult = ReturnType<typeof useSendLegalDocumentsMutation>
export type SendLegalDocumentsMutationResult = Apollo.MutationResult<SendLegalDocumentsMutation>
export type SendLegalDocumentsMutationOptions = Apollo.BaseMutationOptions<
  SendLegalDocumentsMutation,
  SendLegalDocumentsMutationVariables
>
export const UploadLegalDocumentsDocument = gql`
  mutation uploadLegalDocuments($input: UploadLegalDocumentsInput!) {
    uploadLegalDocuments(input: $input) {
      ...LegalRequirementProperties
    }
  }
  ${LegalRequirementPropertiesDoc}
`
export type UploadLegalDocumentsMutationFn = Apollo.MutationFunction<
  UploadLegalDocumentsMutation,
  UploadLegalDocumentsMutationVariables
>

/**
 * __useUploadLegalDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadLegalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLegalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLegalDocumentsMutation, { data, loading, error }] = useUploadLegalDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadLegalDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadLegalDocumentsMutation,
    UploadLegalDocumentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadLegalDocumentsMutation, UploadLegalDocumentsMutationVariables>(
    UploadLegalDocumentsDocument,
    options
  )
}
export type UploadLegalDocumentsMutationHookResult = ReturnType<
  typeof useUploadLegalDocumentsMutation
>
export type UploadLegalDocumentsMutationResult = Apollo.MutationResult<UploadLegalDocumentsMutation>
export type UploadLegalDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UploadLegalDocumentsMutation,
  UploadLegalDocumentsMutationVariables
>
export const UpdateLegalDocumentDocument = gql`
  mutation updateLegalDocument($input: UpdateLegalDocumentInput!) {
    updateLegalDocument(input: $input) {
      ...LegalDocumentProperties
    }
  }
  ${LegalDocumentPropertiesDoc}
`
export type UpdateLegalDocumentMutationFn = Apollo.MutationFunction<
  UpdateLegalDocumentMutation,
  UpdateLegalDocumentMutationVariables
>

/**
 * __useUpdateLegalDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateLegalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalDocumentMutation, { data, loading, error }] = useUpdateLegalDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLegalDocumentMutation,
    UpdateLegalDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLegalDocumentMutation, UpdateLegalDocumentMutationVariables>(
    UpdateLegalDocumentDocument,
    options
  )
}
export type UpdateLegalDocumentMutationHookResult = ReturnType<
  typeof useUpdateLegalDocumentMutation
>
export type UpdateLegalDocumentMutationResult = Apollo.MutationResult<UpdateLegalDocumentMutation>
export type UpdateLegalDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateLegalDocumentMutation,
  UpdateLegalDocumentMutationVariables
>
export const UpdateUploadedLegalDocumentDocument = gql`
  mutation updateUploadedLegalDocument($input: UpdateUploadedLegalDocumentInput!) {
    updateUploadedLegalDocument(input: $input) {
      ...LegalDocumentProperties
    }
  }
  ${LegalDocumentPropertiesDoc}
`
export type UpdateUploadedLegalDocumentMutationFn = Apollo.MutationFunction<
  UpdateUploadedLegalDocumentMutation,
  UpdateUploadedLegalDocumentMutationVariables
>

/**
 * __useUpdateUploadedLegalDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateUploadedLegalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUploadedLegalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUploadedLegalDocumentMutation, { data, loading, error }] = useUpdateUploadedLegalDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUploadedLegalDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUploadedLegalDocumentMutation,
    UpdateUploadedLegalDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUploadedLegalDocumentMutation,
    UpdateUploadedLegalDocumentMutationVariables
  >(UpdateUploadedLegalDocumentDocument, options)
}
export type UpdateUploadedLegalDocumentMutationHookResult = ReturnType<
  typeof useUpdateUploadedLegalDocumentMutation
>
export type UpdateUploadedLegalDocumentMutationResult =
  Apollo.MutationResult<UpdateUploadedLegalDocumentMutation>
export type UpdateUploadedLegalDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateUploadedLegalDocumentMutation,
  UpdateUploadedLegalDocumentMutationVariables
>
export const SignLegalDocumentDocument = gql`
  mutation signLegalDocument($input: SignLegalDocumentInput!) {
    signLegalDocument(input: $input) {
      ...LegalDocumentProperties
    }
  }
  ${LegalDocumentPropertiesDoc}
`
export type SignLegalDocumentMutationFn = Apollo.MutationFunction<
  SignLegalDocumentMutation,
  SignLegalDocumentMutationVariables
>

/**
 * __useSignLegalDocumentMutation__
 *
 * To run a mutation, you first call `useSignLegalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignLegalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signLegalDocumentMutation, { data, loading, error }] = useSignLegalDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignLegalDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignLegalDocumentMutation,
    SignLegalDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignLegalDocumentMutation, SignLegalDocumentMutationVariables>(
    SignLegalDocumentDocument,
    options
  )
}
export type SignLegalDocumentMutationHookResult = ReturnType<typeof useSignLegalDocumentMutation>
export type SignLegalDocumentMutationResult = Apollo.MutationResult<SignLegalDocumentMutation>
export type SignLegalDocumentMutationOptions = Apollo.BaseMutationOptions<
  SignLegalDocumentMutation,
  SignLegalDocumentMutationVariables
>
export const DeleteLegalDocumentDocument = gql`
  mutation deleteLegalDocument($id: ID!) {
    deleteLegalDocument(id: $id) {
      id
    }
  }
`
export type DeleteLegalDocumentMutationFn = Apollo.MutationFunction<
  DeleteLegalDocumentMutation,
  DeleteLegalDocumentMutationVariables
>

/**
 * __useDeleteLegalDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteLegalDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLegalDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLegalDocumentMutation, { data, loading, error }] = useDeleteLegalDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLegalDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLegalDocumentMutation,
    DeleteLegalDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteLegalDocumentMutation, DeleteLegalDocumentMutationVariables>(
    DeleteLegalDocumentDocument,
    options
  )
}
export type DeleteLegalDocumentMutationHookResult = ReturnType<
  typeof useDeleteLegalDocumentMutation
>
export type DeleteLegalDocumentMutationResult = Apollo.MutationResult<DeleteLegalDocumentMutation>
export type DeleteLegalDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteLegalDocumentMutation,
  DeleteLegalDocumentMutationVariables
>
export const SkipLegalRequirementPeriodDocument = gql`
  mutation skipLegalRequirementPeriod($input: SkipLegalRequirementPeriodInput!) {
    skipLegalRequirementPeriod(input: $input) {
      ...LegalRequirementProperties
    }
  }
  ${LegalRequirementPropertiesDoc}
`
export type SkipLegalRequirementPeriodMutationFn = Apollo.MutationFunction<
  SkipLegalRequirementPeriodMutation,
  SkipLegalRequirementPeriodMutationVariables
>

/**
 * __useSkipLegalRequirementPeriodMutation__
 *
 * To run a mutation, you first call `useSkipLegalRequirementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipLegalRequirementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipLegalRequirementPeriodMutation, { data, loading, error }] = useSkipLegalRequirementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSkipLegalRequirementPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SkipLegalRequirementPeriodMutation,
    SkipLegalRequirementPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SkipLegalRequirementPeriodMutation,
    SkipLegalRequirementPeriodMutationVariables
  >(SkipLegalRequirementPeriodDocument, options)
}
export type SkipLegalRequirementPeriodMutationHookResult = ReturnType<
  typeof useSkipLegalRequirementPeriodMutation
>
export type SkipLegalRequirementPeriodMutationResult =
  Apollo.MutationResult<SkipLegalRequirementPeriodMutation>
export type SkipLegalRequirementPeriodMutationOptions = Apollo.BaseMutationOptions<
  SkipLegalRequirementPeriodMutation,
  SkipLegalRequirementPeriodMutationVariables
>
export const UnskipLegalRequirementPeriodDocument = gql`
  mutation unskipLegalRequirementPeriod($input: SkipLegalRequirementPeriodInput!) {
    unskipLegalRequirementPeriod(input: $input) {
      ...LegalRequirementProperties
    }
  }
  ${LegalRequirementPropertiesDoc}
`
export type UnskipLegalRequirementPeriodMutationFn = Apollo.MutationFunction<
  UnskipLegalRequirementPeriodMutation,
  UnskipLegalRequirementPeriodMutationVariables
>

/**
 * __useUnskipLegalRequirementPeriodMutation__
 *
 * To run a mutation, you first call `useUnskipLegalRequirementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnskipLegalRequirementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unskipLegalRequirementPeriodMutation, { data, loading, error }] = useUnskipLegalRequirementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnskipLegalRequirementPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnskipLegalRequirementPeriodMutation,
    UnskipLegalRequirementPeriodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnskipLegalRequirementPeriodMutation,
    UnskipLegalRequirementPeriodMutationVariables
  >(UnskipLegalRequirementPeriodDocument, options)
}
export type UnskipLegalRequirementPeriodMutationHookResult = ReturnType<
  typeof useUnskipLegalRequirementPeriodMutation
>
export type UnskipLegalRequirementPeriodMutationResult =
  Apollo.MutationResult<UnskipLegalRequirementPeriodMutation>
export type UnskipLegalRequirementPeriodMutationOptions = Apollo.BaseMutationOptions<
  UnskipLegalRequirementPeriodMutation,
  UnskipLegalRequirementPeriodMutationVariables
>
export const SignUnconditionalLienWaiverDocument = gql`
  mutation signUnconditionalLienWaiver($input: SignUnconditionalLienWaiverInput!) {
    signUnconditionalLienWaiver(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type SignUnconditionalLienWaiverMutationFn = Apollo.MutationFunction<
  SignUnconditionalLienWaiverMutation,
  SignUnconditionalLienWaiverMutationVariables
>

/**
 * __useSignUnconditionalLienWaiverMutation__
 *
 * To run a mutation, you first call `useSignUnconditionalLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUnconditionalLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUnconditionalLienWaiverMutation, { data, loading, error }] = useSignUnconditionalLienWaiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUnconditionalLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUnconditionalLienWaiverMutation,
    SignUnconditionalLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignUnconditionalLienWaiverMutation,
    SignUnconditionalLienWaiverMutationVariables
  >(SignUnconditionalLienWaiverDocument, options)
}
export type SignUnconditionalLienWaiverMutationHookResult = ReturnType<
  typeof useSignUnconditionalLienWaiverMutation
>
export type SignUnconditionalLienWaiverMutationResult =
  Apollo.MutationResult<SignUnconditionalLienWaiverMutation>
export type SignUnconditionalLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  SignUnconditionalLienWaiverMutation,
  SignUnconditionalLienWaiverMutationVariables
>
export const SubmitUnconditionalLienWaiverDocument = gql`
  mutation submitUnconditionalLienWaiver($input: SubmitUnconditionalLienWaiverInput!) {
    submitUnconditionalLienWaiver(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type SubmitUnconditionalLienWaiverMutationFn = Apollo.MutationFunction<
  SubmitUnconditionalLienWaiverMutation,
  SubmitUnconditionalLienWaiverMutationVariables
>

/**
 * __useSubmitUnconditionalLienWaiverMutation__
 *
 * To run a mutation, you first call `useSubmitUnconditionalLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUnconditionalLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUnconditionalLienWaiverMutation, { data, loading, error }] = useSubmitUnconditionalLienWaiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitUnconditionalLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitUnconditionalLienWaiverMutation,
    SubmitUnconditionalLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitUnconditionalLienWaiverMutation,
    SubmitUnconditionalLienWaiverMutationVariables
  >(SubmitUnconditionalLienWaiverDocument, options)
}
export type SubmitUnconditionalLienWaiverMutationHookResult = ReturnType<
  typeof useSubmitUnconditionalLienWaiverMutation
>
export type SubmitUnconditionalLienWaiverMutationResult =
  Apollo.MutationResult<SubmitUnconditionalLienWaiverMutation>
export type SubmitUnconditionalLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  SubmitUnconditionalLienWaiverMutation,
  SubmitUnconditionalLienWaiverMutationVariables
>
export const ClearUnconditionalLienWaiverDocument = gql`
  mutation clearUnconditionalLienWaiver($id: ID!) {
    clearUnconditionalLienWaiver(id: $id) {
      id
    }
  }
`
export type ClearUnconditionalLienWaiverMutationFn = Apollo.MutationFunction<
  ClearUnconditionalLienWaiverMutation,
  ClearUnconditionalLienWaiverMutationVariables
>

/**
 * __useClearUnconditionalLienWaiverMutation__
 *
 * To run a mutation, you first call `useClearUnconditionalLienWaiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUnconditionalLienWaiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUnconditionalLienWaiverMutation, { data, loading, error }] = useClearUnconditionalLienWaiverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClearUnconditionalLienWaiverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearUnconditionalLienWaiverMutation,
    ClearUnconditionalLienWaiverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearUnconditionalLienWaiverMutation,
    ClearUnconditionalLienWaiverMutationVariables
  >(ClearUnconditionalLienWaiverDocument, options)
}
export type ClearUnconditionalLienWaiverMutationHookResult = ReturnType<
  typeof useClearUnconditionalLienWaiverMutation
>
export type ClearUnconditionalLienWaiverMutationResult =
  Apollo.MutationResult<ClearUnconditionalLienWaiverMutation>
export type ClearUnconditionalLienWaiverMutationOptions = Apollo.BaseMutationOptions<
  ClearUnconditionalLienWaiverMutation,
  ClearUnconditionalLienWaiverMutationVariables
>
export const ModifyNotificationPreferencesDocument = gql`
  mutation modifyNotificationPreferences(
    $notificationTypes: [NotificationType!]!
    $block: Boolean!
  ) {
    modifyNotificationPreferences(input: { notificationTypes: $notificationTypes, block: $block }) {
      ...UserProperties
      blockedNotifications
    }
  }
  ${UserPropertiesDoc}
`
export type ModifyNotificationPreferencesMutationFn = Apollo.MutationFunction<
  ModifyNotificationPreferencesMutation,
  ModifyNotificationPreferencesMutationVariables
>

/**
 * __useModifyNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useModifyNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyNotificationPreferencesMutation, { data, loading, error }] = useModifyNotificationPreferencesMutation({
 *   variables: {
 *      notificationTypes: // value for 'notificationTypes'
 *      block: // value for 'block'
 *   },
 * });
 */
export function useModifyNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyNotificationPreferencesMutation,
    ModifyNotificationPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ModifyNotificationPreferencesMutation,
    ModifyNotificationPreferencesMutationVariables
  >(ModifyNotificationPreferencesDocument, options)
}
export type ModifyNotificationPreferencesMutationHookResult = ReturnType<
  typeof useModifyNotificationPreferencesMutation
>
export type ModifyNotificationPreferencesMutationResult =
  Apollo.MutationResult<ModifyNotificationPreferencesMutation>
export type ModifyNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<
  ModifyNotificationPreferencesMutation,
  ModifyNotificationPreferencesMutationVariables
>
export const ModifyEmailPreferencesDocument = gql`
  mutation modifyEmailPreferences($emailTypes: [EmailType!]!, $block: Boolean!) {
    modifyEmailPreferences(input: { emailTypes: $emailTypes, block: $block }) {
      ...UserProperties
      blockedEmails
    }
  }
  ${UserPropertiesDoc}
`
export type ModifyEmailPreferencesMutationFn = Apollo.MutationFunction<
  ModifyEmailPreferencesMutation,
  ModifyEmailPreferencesMutationVariables
>

/**
 * __useModifyEmailPreferencesMutation__
 *
 * To run a mutation, you first call `useModifyEmailPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyEmailPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyEmailPreferencesMutation, { data, loading, error }] = useModifyEmailPreferencesMutation({
 *   variables: {
 *      emailTypes: // value for 'emailTypes'
 *      block: // value for 'block'
 *   },
 * });
 */
export function useModifyEmailPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyEmailPreferencesMutation,
    ModifyEmailPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ModifyEmailPreferencesMutation,
    ModifyEmailPreferencesMutationVariables
  >(ModifyEmailPreferencesDocument, options)
}
export type ModifyEmailPreferencesMutationHookResult = ReturnType<
  typeof useModifyEmailPreferencesMutation
>
export type ModifyEmailPreferencesMutationResult =
  Apollo.MutationResult<ModifyEmailPreferencesMutation>
export type ModifyEmailPreferencesMutationOptions = Apollo.BaseMutationOptions<
  ModifyEmailPreferencesMutation,
  ModifyEmailPreferencesMutationVariables
>
export const UpdatePayAppInvoiceReadyDocument = gql`
  mutation updatePayAppInvoiceReady($input: PayAppInvoiceReadyInput!) {
    updatePayAppInvoiceReady(input: $input) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type UpdatePayAppInvoiceReadyMutationFn = Apollo.MutationFunction<
  UpdatePayAppInvoiceReadyMutation,
  UpdatePayAppInvoiceReadyMutationVariables
>

/**
 * __useUpdatePayAppInvoiceReadyMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppInvoiceReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppInvoiceReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppInvoiceReadyMutation, { data, loading, error }] = useUpdatePayAppInvoiceReadyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppInvoiceReadyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppInvoiceReadyMutation,
    UpdatePayAppInvoiceReadyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppInvoiceReadyMutation,
    UpdatePayAppInvoiceReadyMutationVariables
  >(UpdatePayAppInvoiceReadyDocument, options)
}
export type UpdatePayAppInvoiceReadyMutationHookResult = ReturnType<
  typeof useUpdatePayAppInvoiceReadyMutation
>
export type UpdatePayAppInvoiceReadyMutationResult =
  Apollo.MutationResult<UpdatePayAppInvoiceReadyMutation>
export type UpdatePayAppInvoiceReadyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppInvoiceReadyMutation,
  UpdatePayAppInvoiceReadyMutationVariables
>
export const SavePayAppFormValuesDocument = gql`
  mutation savePayAppFormValues($input: SavePayAppFormValuesInput!) {
    savePayAppFormValues(input: $input) {
      ...PayAppProperties
      lienWaivers {
        ...LienWaiverProperties
        lienWaiverRequests {
          ...LienWaiverRequestProperties
        }
      }
    }
  }
  ${PayAppPropertiesDoc}
  ${LienWaiverPropertiesDoc}
  ${LienWaiverRequestPropertiesDoc}
`
export type SavePayAppFormValuesMutationFn = Apollo.MutationFunction<
  SavePayAppFormValuesMutation,
  SavePayAppFormValuesMutationVariables
>

/**
 * __useSavePayAppFormValuesMutation__
 *
 * To run a mutation, you first call `useSavePayAppFormValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePayAppFormValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePayAppFormValuesMutation, { data, loading, error }] = useSavePayAppFormValuesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePayAppFormValuesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePayAppFormValuesMutation,
    SavePayAppFormValuesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SavePayAppFormValuesMutation, SavePayAppFormValuesMutationVariables>(
    SavePayAppFormValuesDocument,
    options
  )
}
export type SavePayAppFormValuesMutationHookResult = ReturnType<
  typeof useSavePayAppFormValuesMutation
>
export type SavePayAppFormValuesMutationResult = Apollo.MutationResult<SavePayAppFormValuesMutation>
export type SavePayAppFormValuesMutationOptions = Apollo.BaseMutationOptions<
  SavePayAppFormValuesMutation,
  SavePayAppFormValuesMutationVariables
>
export const PayAppFormsReadyForReviewDocument = gql`
  mutation payAppFormsReadyForReview($input: PayAppFormsReadyForReviewInput!) {
    payAppFormsReadyForReview(input: $input) {
      ...PayAppProperties
      lienWaivers {
        id
        formValues {
          ...FormTemplateAnnotationValueProperties
        }
      }
    }
  }
  ${PayAppPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
`
export type PayAppFormsReadyForReviewMutationFn = Apollo.MutationFunction<
  PayAppFormsReadyForReviewMutation,
  PayAppFormsReadyForReviewMutationVariables
>

/**
 * __usePayAppFormsReadyForReviewMutation__
 *
 * To run a mutation, you first call `usePayAppFormsReadyForReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayAppFormsReadyForReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payAppFormsReadyForReviewMutation, { data, loading, error }] = usePayAppFormsReadyForReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayAppFormsReadyForReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayAppFormsReadyForReviewMutation,
    PayAppFormsReadyForReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PayAppFormsReadyForReviewMutation,
    PayAppFormsReadyForReviewMutationVariables
  >(PayAppFormsReadyForReviewDocument, options)
}
export type PayAppFormsReadyForReviewMutationHookResult = ReturnType<
  typeof usePayAppFormsReadyForReviewMutation
>
export type PayAppFormsReadyForReviewMutationResult =
  Apollo.MutationResult<PayAppFormsReadyForReviewMutation>
export type PayAppFormsReadyForReviewMutationOptions = Apollo.BaseMutationOptions<
  PayAppFormsReadyForReviewMutation,
  PayAppFormsReadyForReviewMutationVariables
>
export const ClearPayAppFormValuesDocument = gql`
  mutation clearPayAppFormValues($payAppId: ID!) {
    clearPayAppFormValues(payAppId: $payAppId) {
      id
      lienWaivers {
        id
        type
        vendorContract {
          id
        }
        formValues {
          ...FormTemplateAnnotationValueProperties
        }
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
    }
  }
  ${FormTemplateAnnotationValuePropertiesDoc}
`
export type ClearPayAppFormValuesMutationFn = Apollo.MutationFunction<
  ClearPayAppFormValuesMutation,
  ClearPayAppFormValuesMutationVariables
>

/**
 * __useClearPayAppFormValuesMutation__
 *
 * To run a mutation, you first call `useClearPayAppFormValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPayAppFormValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPayAppFormValuesMutation, { data, loading, error }] = useClearPayAppFormValuesMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useClearPayAppFormValuesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearPayAppFormValuesMutation,
    ClearPayAppFormValuesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearPayAppFormValuesMutation, ClearPayAppFormValuesMutationVariables>(
    ClearPayAppFormValuesDocument,
    options
  )
}
export type ClearPayAppFormValuesMutationHookResult = ReturnType<
  typeof useClearPayAppFormValuesMutation
>
export type ClearPayAppFormValuesMutationResult =
  Apollo.MutationResult<ClearPayAppFormValuesMutation>
export type ClearPayAppFormValuesMutationOptions = Apollo.BaseMutationOptions<
  ClearPayAppFormValuesMutation,
  ClearPayAppFormValuesMutationVariables
>
export const MarkPayAppAsPaidDocument = gql`
  mutation markPayAppAsPaid($input: MarkPayAppAsPaidInput!) {
    markPayAppAsPaid(input: $input) {
      id
      status
      statusChangedAt
      amountPaid
      isBalanceManuallyClosed
    }
  }
`
export type MarkPayAppAsPaidMutationFn = Apollo.MutationFunction<
  MarkPayAppAsPaidMutation,
  MarkPayAppAsPaidMutationVariables
>

/**
 * __useMarkPayAppAsPaidMutation__
 *
 * To run a mutation, you first call `useMarkPayAppAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPayAppAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPayAppAsPaidMutation, { data, loading, error }] = useMarkPayAppAsPaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkPayAppAsPaidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPayAppAsPaidMutation,
    MarkPayAppAsPaidMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkPayAppAsPaidMutation, MarkPayAppAsPaidMutationVariables>(
    MarkPayAppAsPaidDocument,
    options
  )
}
export type MarkPayAppAsPaidMutationHookResult = ReturnType<typeof useMarkPayAppAsPaidMutation>
export type MarkPayAppAsPaidMutationResult = Apollo.MutationResult<MarkPayAppAsPaidMutation>
export type MarkPayAppAsPaidMutationOptions = Apollo.BaseMutationOptions<
  MarkPayAppAsPaidMutation,
  MarkPayAppAsPaidMutationVariables
>
export const MarkPayAppAsSubmittedDocument = gql`
  mutation markPayAppAsSubmitted($input: MarkPayAppAsSubmittedInput!) {
    markPayAppAsSubmitted(input: $input) {
      id
      lastSubmitted {
        id
        statusUpdatedAt
      }
      status
    }
  }
`
export type MarkPayAppAsSubmittedMutationFn = Apollo.MutationFunction<
  MarkPayAppAsSubmittedMutation,
  MarkPayAppAsSubmittedMutationVariables
>

/**
 * __useMarkPayAppAsSubmittedMutation__
 *
 * To run a mutation, you first call `useMarkPayAppAsSubmittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPayAppAsSubmittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPayAppAsSubmittedMutation, { data, loading, error }] = useMarkPayAppAsSubmittedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkPayAppAsSubmittedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPayAppAsSubmittedMutation,
    MarkPayAppAsSubmittedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkPayAppAsSubmittedMutation, MarkPayAppAsSubmittedMutationVariables>(
    MarkPayAppAsSubmittedDocument,
    options
  )
}
export type MarkPayAppAsSubmittedMutationHookResult = ReturnType<
  typeof useMarkPayAppAsSubmittedMutation
>
export type MarkPayAppAsSubmittedMutationResult =
  Apollo.MutationResult<MarkPayAppAsSubmittedMutation>
export type MarkPayAppAsSubmittedMutationOptions = Apollo.BaseMutationOptions<
  MarkPayAppAsSubmittedMutation,
  MarkPayAppAsSubmittedMutationVariables
>
export const MarkPayAppAsSyncedDocument = gql`
  mutation markPayAppAsSynced($input: MarkPayAppAsSyncedInput!) {
    markPayAppAsSynced(input: $input) {
      id
      status
      statusChangedAt
    }
  }
`
export type MarkPayAppAsSyncedMutationFn = Apollo.MutationFunction<
  MarkPayAppAsSyncedMutation,
  MarkPayAppAsSyncedMutationVariables
>

/**
 * __useMarkPayAppAsSyncedMutation__
 *
 * To run a mutation, you first call `useMarkPayAppAsSyncedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPayAppAsSyncedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPayAppAsSyncedMutation, { data, loading, error }] = useMarkPayAppAsSyncedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkPayAppAsSyncedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPayAppAsSyncedMutation,
    MarkPayAppAsSyncedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkPayAppAsSyncedMutation, MarkPayAppAsSyncedMutationVariables>(
    MarkPayAppAsSyncedDocument,
    options
  )
}
export type MarkPayAppAsSyncedMutationHookResult = ReturnType<typeof useMarkPayAppAsSyncedMutation>
export type MarkPayAppAsSyncedMutationResult = Apollo.MutationResult<MarkPayAppAsSyncedMutation>
export type MarkPayAppAsSyncedMutationOptions = Apollo.BaseMutationOptions<
  MarkPayAppAsSyncedMutation,
  MarkPayAppAsSyncedMutationVariables
>
export const CreateChangeOrderDocument = gql`
  mutation createChangeOrder($input: CreateChangeOrderInput!) {
    createChangeOrder(input: $input) {
      ...SovLineItemProgressProperties
    }
  }
  ${SovLineItemProgressPropertiesDoc}
`
export type CreateChangeOrderMutationFn = Apollo.MutationFunction<
  CreateChangeOrderMutation,
  CreateChangeOrderMutationVariables
>

/**
 * __useCreateChangeOrderMutation__
 *
 * To run a mutation, you first call `useCreateChangeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeOrderMutation, { data, loading, error }] = useCreateChangeOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChangeOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChangeOrderMutation,
    CreateChangeOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChangeOrderMutation, CreateChangeOrderMutationVariables>(
    CreateChangeOrderDocument,
    options
  )
}
export type CreateChangeOrderMutationHookResult = ReturnType<typeof useCreateChangeOrderMutation>
export type CreateChangeOrderMutationResult = Apollo.MutationResult<CreateChangeOrderMutation>
export type CreateChangeOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateChangeOrderMutation,
  CreateChangeOrderMutationVariables
>
export const UpdateChangeOrderDocument = gql`
  mutation updateChangeOrder($input: UpdateChangeOrderInput!) {
    updateChangeOrder(input: $input) {
      ...SovLineItemProgressProperties
    }
  }
  ${SovLineItemProgressPropertiesDoc}
`
export type UpdateChangeOrderMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderMutation,
  UpdateChangeOrderMutationVariables
>

/**
 * __useUpdateChangeOrderMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderMutation, { data, loading, error }] = useUpdateChangeOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderMutation,
    UpdateChangeOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateChangeOrderMutation, UpdateChangeOrderMutationVariables>(
    UpdateChangeOrderDocument,
    options
  )
}
export type UpdateChangeOrderMutationHookResult = ReturnType<typeof useUpdateChangeOrderMutation>
export type UpdateChangeOrderMutationResult = Apollo.MutationResult<UpdateChangeOrderMutation>
export type UpdateChangeOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeOrderMutation,
  UpdateChangeOrderMutationVariables
>
export const DeleteChangeOrderDocument = gql`
  mutation deleteChangeOrder($input: DeleteChangeOrderInput!) {
    deleteChangeOrder(input: $input) {
      id
      previousRetentionBilled
      currentBilled
      currentRetention
      totalRetention
    }
  }
`
export type DeleteChangeOrderMutationFn = Apollo.MutationFunction<
  DeleteChangeOrderMutation,
  DeleteChangeOrderMutationVariables
>

/**
 * __useDeleteChangeOrderMutation__
 *
 * To run a mutation, you first call `useDeleteChangeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChangeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChangeOrderMutation, { data, loading, error }] = useDeleteChangeOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChangeOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChangeOrderMutation,
    DeleteChangeOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteChangeOrderMutation, DeleteChangeOrderMutationVariables>(
    DeleteChangeOrderDocument,
    options
  )
}
export type DeleteChangeOrderMutationHookResult = ReturnType<typeof useDeleteChangeOrderMutation>
export type DeleteChangeOrderMutationResult = Apollo.MutationResult<DeleteChangeOrderMutation>
export type DeleteChangeOrderMutationOptions = Apollo.BaseMutationOptions<
  DeleteChangeOrderMutation,
  DeleteChangeOrderMutationVariables
>
export const PushNotificationDocument = gql`
  subscription pushNotification($userId: ID!) {
    pushNotification(userId: $userId) {
      ...NotificationProperties
    }
  }
  ${NotificationPropertiesDoc}
`

/**
 * __usePushNotificationSubscription__
 *
 * To run a query within a React component, call `usePushNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePushNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushNotificationSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePushNotificationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PushNotificationSubscription,
    PushNotificationSubscriptionVariables
  > &
    ({ variables: PushNotificationSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    PushNotificationSubscription,
    PushNotificationSubscriptionVariables
  >(PushNotificationDocument, options)
}
export type PushNotificationSubscriptionHookResult = ReturnType<
  typeof usePushNotificationSubscription
>
export type PushNotificationSubscriptionResult =
  Apollo.SubscriptionResult<PushNotificationSubscription>
export const UpdatedLienWaiverRequestChangesDocument = gql`
  subscription updatedLienWaiverRequestChanges($companyId: ID!) {
    updatedLienWaiverRequestChanges(companyId: $companyId) {
      id
      actionTakenAt
      status
      statusLogs {
        id
        status
      }
      lienWaiver {
        id
        amount
        type
        date
        timeZone
        vendorContract {
          id
          vendor {
            id
          }
        }
        contract {
          id
        }
      }
    }
  }
`

/**
 * __useUpdatedLienWaiverRequestChangesSubscription__
 *
 * To run a query within a React component, call `useUpdatedLienWaiverRequestChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedLienWaiverRequestChangesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedLienWaiverRequestChangesSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdatedLienWaiverRequestChangesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdatedLienWaiverRequestChangesSubscription,
    UpdatedLienWaiverRequestChangesSubscriptionVariables
  > &
    (
      | { variables: UpdatedLienWaiverRequestChangesSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    UpdatedLienWaiverRequestChangesSubscription,
    UpdatedLienWaiverRequestChangesSubscriptionVariables
  >(UpdatedLienWaiverRequestChangesDocument, options)
}
export type UpdatedLienWaiverRequestChangesSubscriptionHookResult = ReturnType<
  typeof useUpdatedLienWaiverRequestChangesSubscription
>
export type UpdatedLienWaiverRequestChangesSubscriptionResult =
  Apollo.SubscriptionResult<UpdatedLienWaiverRequestChangesSubscription>
export const UpdatedLegalDocumentRequestChangesDocument = gql`
  subscription updatedLegalDocumentRequestChanges($companyId: ID!) {
    updatedLegalDocumentRequestChanges(companyId: $companyId) {
      id
      actionTakenAt
      status
      legalDocument {
        id
        periodStart
        periodEnd
      }
    }
  }
`

/**
 * __useUpdatedLegalDocumentRequestChangesSubscription__
 *
 * To run a query within a React component, call `useUpdatedLegalDocumentRequestChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedLegalDocumentRequestChangesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedLegalDocumentRequestChangesSubscription({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUpdatedLegalDocumentRequestChangesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdatedLegalDocumentRequestChangesSubscription,
    UpdatedLegalDocumentRequestChangesSubscriptionVariables
  > &
    (
      | { variables: UpdatedLegalDocumentRequestChangesSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    UpdatedLegalDocumentRequestChangesSubscription,
    UpdatedLegalDocumentRequestChangesSubscriptionVariables
  >(UpdatedLegalDocumentRequestChangesDocument, options)
}
export type UpdatedLegalDocumentRequestChangesSubscriptionHookResult = ReturnType<
  typeof useUpdatedLegalDocumentRequestChangesSubscription
>
export type UpdatedLegalDocumentRequestChangesSubscriptionResult =
  Apollo.SubscriptionResult<UpdatedLegalDocumentRequestChangesSubscription>
export const OptOutOfBillingForMonthDocument = gql`
  mutation optOutOfBillingForMonth($input: OptOutOfBillingInput!) {
    optOutOfBillingForMonth(input: $input) {
      id
      skippedPayAppMonths
      optedOutMonths {
        ...ContractOptedOutMonthProperties
      }
      sov {
        id
        lineItems {
          id
          billedToDate
          totalRetention
        }
      }
    }
  }
  ${ContractOptedOutMonthPropertiesDoc}
`
export type OptOutOfBillingForMonthMutationFn = Apollo.MutationFunction<
  OptOutOfBillingForMonthMutation,
  OptOutOfBillingForMonthMutationVariables
>

/**
 * __useOptOutOfBillingForMonthMutation__
 *
 * To run a mutation, you first call `useOptOutOfBillingForMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutOfBillingForMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutOfBillingForMonthMutation, { data, loading, error }] = useOptOutOfBillingForMonthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptOutOfBillingForMonthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OptOutOfBillingForMonthMutation,
    OptOutOfBillingForMonthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OptOutOfBillingForMonthMutation,
    OptOutOfBillingForMonthMutationVariables
  >(OptOutOfBillingForMonthDocument, options)
}
export type OptOutOfBillingForMonthMutationHookResult = ReturnType<
  typeof useOptOutOfBillingForMonthMutation
>
export type OptOutOfBillingForMonthMutationResult =
  Apollo.MutationResult<OptOutOfBillingForMonthMutation>
export type OptOutOfBillingForMonthMutationOptions = Apollo.BaseMutationOptions<
  OptOutOfBillingForMonthMutation,
  OptOutOfBillingForMonthMutationVariables
>
export const OptInToBillingForMonthDocument = gql`
  mutation optInToBillingForMonth($input: OptInToBillingInput!) {
    optInToBillingForMonth(input: $input) {
      id
      skippedPayAppMonths
      optedOutMonths {
        ...ContractOptedOutMonthProperties
      }
    }
  }
  ${ContractOptedOutMonthPropertiesDoc}
`
export type OptInToBillingForMonthMutationFn = Apollo.MutationFunction<
  OptInToBillingForMonthMutation,
  OptInToBillingForMonthMutationVariables
>

/**
 * __useOptInToBillingForMonthMutation__
 *
 * To run a mutation, you first call `useOptInToBillingForMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptInToBillingForMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optInToBillingForMonthMutation, { data, loading, error }] = useOptInToBillingForMonthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptInToBillingForMonthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OptInToBillingForMonthMutation,
    OptInToBillingForMonthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OptInToBillingForMonthMutation,
    OptInToBillingForMonthMutationVariables
  >(OptInToBillingForMonthDocument, options)
}
export type OptInToBillingForMonthMutationHookResult = ReturnType<
  typeof useOptInToBillingForMonthMutation
>
export type OptInToBillingForMonthMutationResult =
  Apollo.MutationResult<OptInToBillingForMonthMutation>
export type OptInToBillingForMonthMutationOptions = Apollo.BaseMutationOptions<
  OptInToBillingForMonthMutation,
  OptInToBillingForMonthMutationVariables
>
export const UpdatePayAppStatusDocument = gql`
  mutation updatePayAppStatus($input: PayAppStatusInput!) {
    updatePayAppStatus(input: $input) {
      ...PayAppNoProgressProperties
    }
  }
  ${PayAppNoProgressPropertiesDoc}
`
export type UpdatePayAppStatusMutationFn = Apollo.MutationFunction<
  UpdatePayAppStatusMutation,
  UpdatePayAppStatusMutationVariables
>

/**
 * __useUpdatePayAppStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppStatusMutation, { data, loading, error }] = useUpdatePayAppStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppStatusMutation,
    UpdatePayAppStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePayAppStatusMutation, UpdatePayAppStatusMutationVariables>(
    UpdatePayAppStatusDocument,
    options
  )
}
export type UpdatePayAppStatusMutationHookResult = ReturnType<typeof useUpdatePayAppStatusMutation>
export type UpdatePayAppStatusMutationResult = Apollo.MutationResult<UpdatePayAppStatusMutation>
export type UpdatePayAppStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppStatusMutation,
  UpdatePayAppStatusMutationVariables
>
export const UpdatePayAppSubmittedAtDocument = gql`
  mutation updatePayAppSubmittedAt($input: UpdatePayAppSubmittedAtInput!) {
    updatePayAppSubmittedAt(input: $input) {
      id
      lastSubmitted {
        id
        statusUpdatedAt
      }
      statusChangedAt
    }
  }
`
export type UpdatePayAppSubmittedAtMutationFn = Apollo.MutationFunction<
  UpdatePayAppSubmittedAtMutation,
  UpdatePayAppSubmittedAtMutationVariables
>

/**
 * __useUpdatePayAppSubmittedAtMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppSubmittedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppSubmittedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppSubmittedAtMutation, { data, loading, error }] = useUpdatePayAppSubmittedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppSubmittedAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppSubmittedAtMutation,
    UpdatePayAppSubmittedAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppSubmittedAtMutation,
    UpdatePayAppSubmittedAtMutationVariables
  >(UpdatePayAppSubmittedAtDocument, options)
}
export type UpdatePayAppSubmittedAtMutationHookResult = ReturnType<
  typeof useUpdatePayAppSubmittedAtMutation
>
export type UpdatePayAppSubmittedAtMutationResult =
  Apollo.MutationResult<UpdatePayAppSubmittedAtMutation>
export type UpdatePayAppSubmittedAtMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppSubmittedAtMutation,
  UpdatePayAppSubmittedAtMutationVariables
>
export const UpdatePayAppSyncedAtDocument = gql`
  mutation updatePayAppSyncedAt($input: UpdatePayAppSyncedAtInput!) {
    updatePayAppSyncedAt(input: $input) {
      id
      statusChangedAt
    }
  }
`
export type UpdatePayAppSyncedAtMutationFn = Apollo.MutationFunction<
  UpdatePayAppSyncedAtMutation,
  UpdatePayAppSyncedAtMutationVariables
>

/**
 * __useUpdatePayAppSyncedAtMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppSyncedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppSyncedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppSyncedAtMutation, { data, loading, error }] = useUpdatePayAppSyncedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppSyncedAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppSyncedAtMutation,
    UpdatePayAppSyncedAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePayAppSyncedAtMutation, UpdatePayAppSyncedAtMutationVariables>(
    UpdatePayAppSyncedAtDocument,
    options
  )
}
export type UpdatePayAppSyncedAtMutationHookResult = ReturnType<
  typeof useUpdatePayAppSyncedAtMutation
>
export type UpdatePayAppSyncedAtMutationResult = Apollo.MutationResult<UpdatePayAppSyncedAtMutation>
export type UpdatePayAppSyncedAtMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppSyncedAtMutation,
  UpdatePayAppSyncedAtMutationVariables
>
export const UpdatePayAppPaidAtDocument = gql`
  mutation updatePayAppPaidAt($input: UpdatePayAppPaidAtInput!) {
    updatePayAppPaidAt(input: $input) {
      id
      status
      statusChangedAt
      lastSubmitted {
        id
        statusUpdatedAt
      }
      amountPaid
      isBalanceManuallyClosed
      lienWaivers {
        id
      }
    }
  }
`
export type UpdatePayAppPaidAtMutationFn = Apollo.MutationFunction<
  UpdatePayAppPaidAtMutation,
  UpdatePayAppPaidAtMutationVariables
>

/**
 * __useUpdatePayAppPaidAtMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppPaidAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppPaidAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppPaidAtMutation, { data, loading, error }] = useUpdatePayAppPaidAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppPaidAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppPaidAtMutation,
    UpdatePayAppPaidAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePayAppPaidAtMutation, UpdatePayAppPaidAtMutationVariables>(
    UpdatePayAppPaidAtDocument,
    options
  )
}
export type UpdatePayAppPaidAtMutationHookResult = ReturnType<typeof useUpdatePayAppPaidAtMutation>
export type UpdatePayAppPaidAtMutationResult = Apollo.MutationResult<UpdatePayAppPaidAtMutation>
export type UpdatePayAppPaidAtMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppPaidAtMutation,
  UpdatePayAppPaidAtMutationVariables
>
export const UpdateSovDocument = gql`
  mutation updateSov($input: UpdateSovInput!) {
    updateSov(input: $input) {
      id
      preSitelineRetention
      retentionTrackingLevel
      defaultRetentionPercent
      sov {
        ...SovProperties
        lineItems {
          preSitelineRetention
          ...SovLineItemProperties
          sovLineItemGroup {
            ...SovLineItemGroupProperties
          }
        }
      }
    }
  }
  ${SovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
`
export type UpdateSovMutationFn = Apollo.MutationFunction<
  UpdateSovMutation,
  UpdateSovMutationVariables
>

/**
 * __useUpdateSovMutation__
 *
 * To run a mutation, you first call `useUpdateSovMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSovMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSovMutation, { data, loading, error }] = useUpdateSovMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSovMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSovMutation, UpdateSovMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSovMutation, UpdateSovMutationVariables>(
    UpdateSovDocument,
    options
  )
}
export type UpdateSovMutationHookResult = ReturnType<typeof useUpdateSovMutation>
export type UpdateSovMutationResult = Apollo.MutationResult<UpdateSovMutation>
export type UpdateSovMutationOptions = Apollo.BaseMutationOptions<
  UpdateSovMutation,
  UpdateSovMutationVariables
>
export const UpdatedSovLineItemProgressDocument = gql`
  subscription updatedSovLineItemProgress($id: ID!) {
    updatedSovLineItemProgress(id: $id) {
      ...SovLineItemProgressEventProperties
    }
  }
  ${SovLineItemProgressEventPropertiesDoc}
`

/**
 * __useUpdatedSovLineItemProgressSubscription__
 *
 * To run a query within a React component, call `useUpdatedSovLineItemProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedSovLineItemProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedSovLineItemProgressSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatedSovLineItemProgressSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdatedSovLineItemProgressSubscription,
    UpdatedSovLineItemProgressSubscriptionVariables
  > &
    (
      | { variables: UpdatedSovLineItemProgressSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    UpdatedSovLineItemProgressSubscription,
    UpdatedSovLineItemProgressSubscriptionVariables
  >(UpdatedSovLineItemProgressDocument, options)
}
export type UpdatedSovLineItemProgressSubscriptionHookResult = ReturnType<
  typeof useUpdatedSovLineItemProgressSubscription
>
export type UpdatedSovLineItemProgressSubscriptionResult =
  Apollo.SubscriptionResult<UpdatedSovLineItemProgressSubscription>
export const AddPayAppCommentDocument = gql`
  mutation addPayAppComment($input: AddPayAppCommentInput!) {
    addPayAppComment(input: $input) {
      ...PayAppCommentProperties
      payApp {
        id
      }
    }
  }
  ${PayAppCommentPropertiesDoc}
`
export type AddPayAppCommentMutationFn = Apollo.MutationFunction<
  AddPayAppCommentMutation,
  AddPayAppCommentMutationVariables
>

/**
 * __useAddPayAppCommentMutation__
 *
 * To run a mutation, you first call `useAddPayAppCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPayAppCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPayAppCommentMutation, { data, loading, error }] = useAddPayAppCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPayAppCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPayAppCommentMutation,
    AddPayAppCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddPayAppCommentMutation, AddPayAppCommentMutationVariables>(
    AddPayAppCommentDocument,
    options
  )
}
export type AddPayAppCommentMutationHookResult = ReturnType<typeof useAddPayAppCommentMutation>
export type AddPayAppCommentMutationResult = Apollo.MutationResult<AddPayAppCommentMutation>
export type AddPayAppCommentMutationOptions = Apollo.BaseMutationOptions<
  AddPayAppCommentMutation,
  AddPayAppCommentMutationVariables
>
export const AddContractCommentDocument = gql`
  mutation addContractComment($input: AddContractCommentInput!) {
    addContractComment(input: $input) {
      ...ContractCommentProperties
      contract {
        id
      }
    }
  }
  ${ContractCommentPropertiesDoc}
`
export type AddContractCommentMutationFn = Apollo.MutationFunction<
  AddContractCommentMutation,
  AddContractCommentMutationVariables
>

/**
 * __useAddContractCommentMutation__
 *
 * To run a mutation, you first call `useAddContractCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContractCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContractCommentMutation, { data, loading, error }] = useAddContractCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContractCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContractCommentMutation,
    AddContractCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddContractCommentMutation, AddContractCommentMutationVariables>(
    AddContractCommentDocument,
    options
  )
}
export type AddContractCommentMutationHookResult = ReturnType<typeof useAddContractCommentMutation>
export type AddContractCommentMutationResult = Apollo.MutationResult<AddContractCommentMutation>
export type AddContractCommentMutationOptions = Apollo.BaseMutationOptions<
  AddContractCommentMutation,
  AddContractCommentMutationVariables
>
export const UploadPayAppFileDocument = gql`
  mutation uploadPayAppFile($input: UploadPayAppInput!) {
    uploadPayAppFile(input: $input) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type UploadPayAppFileMutationFn = Apollo.MutationFunction<
  UploadPayAppFileMutation,
  UploadPayAppFileMutationVariables
>

/**
 * __useUploadPayAppFileMutation__
 *
 * To run a mutation, you first call `useUploadPayAppFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPayAppFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPayAppFileMutation, { data, loading, error }] = useUploadPayAppFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPayAppFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadPayAppFileMutation,
    UploadPayAppFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadPayAppFileMutation, UploadPayAppFileMutationVariables>(
    UploadPayAppFileDocument,
    options
  )
}
export type UploadPayAppFileMutationHookResult = ReturnType<typeof useUploadPayAppFileMutation>
export type UploadPayAppFileMutationResult = Apollo.MutationResult<UploadPayAppFileMutation>
export type UploadPayAppFileMutationOptions = Apollo.BaseMutationOptions<
  UploadPayAppFileMutation,
  UploadPayAppFileMutationVariables
>
export const GetSovLineItemGroupsDocument = gql`
  query getSovLineItemGroups($contractId: ID!) {
    sovLineItemGroups(contractId: $contractId) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`

/**
 * __useGetSovLineItemGroupsQuery__
 *
 * To run a query within a React component, call `useGetSovLineItemGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSovLineItemGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSovLineItemGroupsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetSovLineItemGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSovLineItemGroupsQuery,
    GetSovLineItemGroupsQueryVariables
  > &
    ({ variables: GetSovLineItemGroupsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSovLineItemGroupsQuery, GetSovLineItemGroupsQueryVariables>(
    GetSovLineItemGroupsDocument,
    options
  )
}
export function useGetSovLineItemGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSovLineItemGroupsQuery,
    GetSovLineItemGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSovLineItemGroupsQuery, GetSovLineItemGroupsQueryVariables>(
    GetSovLineItemGroupsDocument,
    options
  )
}
export function useGetSovLineItemGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetSovLineItemGroupsQuery, GetSovLineItemGroupsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSovLineItemGroupsQuery, GetSovLineItemGroupsQueryVariables>(
    GetSovLineItemGroupsDocument,
    options
  )
}
export type GetSovLineItemGroupsQueryHookResult = ReturnType<typeof useGetSovLineItemGroupsQuery>
export type GetSovLineItemGroupsLazyQueryHookResult = ReturnType<
  typeof useGetSovLineItemGroupsLazyQuery
>
export type GetSovLineItemGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetSovLineItemGroupsSuspenseQuery
>
export type GetSovLineItemGroupsQueryResult = Apollo.QueryResult<
  GetSovLineItemGroupsQuery,
  GetSovLineItemGroupsQueryVariables
>
export const CreateSovLineItemGroupDocument = gql`
  mutation createSovLineItemGroup($input: CreateSovLineItemGroupInput!) {
    createSovLineItemGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`
export type CreateSovLineItemGroupMutationFn = Apollo.MutationFunction<
  CreateSovLineItemGroupMutation,
  CreateSovLineItemGroupMutationVariables
>

/**
 * __useCreateSovLineItemGroupMutation__
 *
 * To run a mutation, you first call `useCreateSovLineItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSovLineItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSovLineItemGroupMutation, { data, loading, error }] = useCreateSovLineItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSovLineItemGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSovLineItemGroupMutation,
    CreateSovLineItemGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSovLineItemGroupMutation,
    CreateSovLineItemGroupMutationVariables
  >(CreateSovLineItemGroupDocument, options)
}
export type CreateSovLineItemGroupMutationHookResult = ReturnType<
  typeof useCreateSovLineItemGroupMutation
>
export type CreateSovLineItemGroupMutationResult =
  Apollo.MutationResult<CreateSovLineItemGroupMutation>
export type CreateSovLineItemGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateSovLineItemGroupMutation,
  CreateSovLineItemGroupMutationVariables
>
export const UpdateSovLineItemGroupDocument = gql`
  mutation updateSovLineItemGroup($input: UpdateSovLineItemGroupInput!) {
    updateSovLineItemGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${SovLineItemGroupPropertiesDoc}
`
export type UpdateSovLineItemGroupMutationFn = Apollo.MutationFunction<
  UpdateSovLineItemGroupMutation,
  UpdateSovLineItemGroupMutationVariables
>

/**
 * __useUpdateSovLineItemGroupMutation__
 *
 * To run a mutation, you first call `useUpdateSovLineItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSovLineItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSovLineItemGroupMutation, { data, loading, error }] = useUpdateSovLineItemGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSovLineItemGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSovLineItemGroupMutation,
    UpdateSovLineItemGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSovLineItemGroupMutation,
    UpdateSovLineItemGroupMutationVariables
  >(UpdateSovLineItemGroupDocument, options)
}
export type UpdateSovLineItemGroupMutationHookResult = ReturnType<
  typeof useUpdateSovLineItemGroupMutation
>
export type UpdateSovLineItemGroupMutationResult =
  Apollo.MutationResult<UpdateSovLineItemGroupMutation>
export type UpdateSovLineItemGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateSovLineItemGroupMutation,
  UpdateSovLineItemGroupMutationVariables
>
export const DeleteSovLineItemGroupDocument = gql`
  mutation deleteSovLineItemGroup($id: ID!) {
    deleteSovLineItemGroup(id: $id) {
      id
    }
  }
`
export type DeleteSovLineItemGroupMutationFn = Apollo.MutationFunction<
  DeleteSovLineItemGroupMutation,
  DeleteSovLineItemGroupMutationVariables
>

/**
 * __useDeleteSovLineItemGroupMutation__
 *
 * To run a mutation, you first call `useDeleteSovLineItemGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSovLineItemGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSovLineItemGroupMutation, { data, loading, error }] = useDeleteSovLineItemGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSovLineItemGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSovLineItemGroupMutation,
    DeleteSovLineItemGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteSovLineItemGroupMutation,
    DeleteSovLineItemGroupMutationVariables
  >(DeleteSovLineItemGroupDocument, options)
}
export type DeleteSovLineItemGroupMutationHookResult = ReturnType<
  typeof useDeleteSovLineItemGroupMutation
>
export type DeleteSovLineItemGroupMutationResult =
  Apollo.MutationResult<DeleteSovLineItemGroupMutation>
export type DeleteSovLineItemGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteSovLineItemGroupMutation,
  DeleteSovLineItemGroupMutationVariables
>
export const AddGeneralContractorContactToPayAppDocument = gql`
  mutation addGeneralContractorContactToPayApp(
    $payAppId: ID!
    $companyId: ID!
    $companyContactId: ID!
  ) {
    addGeneralContractorContactToPayApp(
      input: { payAppId: $payAppId, companyId: $companyId, companyContactId: $companyContactId }
    ) {
      id
      emailedContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type AddGeneralContractorContactToPayAppMutationFn = Apollo.MutationFunction<
  AddGeneralContractorContactToPayAppMutation,
  AddGeneralContractorContactToPayAppMutationVariables
>

/**
 * __useAddGeneralContractorContactToPayAppMutation__
 *
 * To run a mutation, you first call `useAddGeneralContractorContactToPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGeneralContractorContactToPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGeneralContractorContactToPayAppMutation, { data, loading, error }] = useAddGeneralContractorContactToPayAppMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *      companyId: // value for 'companyId'
 *      companyContactId: // value for 'companyContactId'
 *   },
 * });
 */
export function useAddGeneralContractorContactToPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGeneralContractorContactToPayAppMutation,
    AddGeneralContractorContactToPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddGeneralContractorContactToPayAppMutation,
    AddGeneralContractorContactToPayAppMutationVariables
  >(AddGeneralContractorContactToPayAppDocument, options)
}
export type AddGeneralContractorContactToPayAppMutationHookResult = ReturnType<
  typeof useAddGeneralContractorContactToPayAppMutation
>
export type AddGeneralContractorContactToPayAppMutationResult =
  Apollo.MutationResult<AddGeneralContractorContactToPayAppMutation>
export type AddGeneralContractorContactToPayAppMutationOptions = Apollo.BaseMutationOptions<
  AddGeneralContractorContactToPayAppMutation,
  AddGeneralContractorContactToPayAppMutationVariables
>
export const DeleteGeneralContractorContactFromPayAppDocument = gql`
  mutation deleteGeneralContractorContactFromPayApp(
    $payAppId: ID!
    $companyId: ID!
    $companyContactId: ID!
  ) {
    deleteGeneralContractorContactFromPayApp(
      input: { payAppId: $payAppId, companyId: $companyId, companyContactId: $companyContactId }
    ) {
      id
      emailedContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`
export type DeleteGeneralContractorContactFromPayAppMutationFn = Apollo.MutationFunction<
  DeleteGeneralContractorContactFromPayAppMutation,
  DeleteGeneralContractorContactFromPayAppMutationVariables
>

/**
 * __useDeleteGeneralContractorContactFromPayAppMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralContractorContactFromPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralContractorContactFromPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralContractorContactFromPayAppMutation, { data, loading, error }] = useDeleteGeneralContractorContactFromPayAppMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *      companyId: // value for 'companyId'
 *      companyContactId: // value for 'companyContactId'
 *   },
 * });
 */
export function useDeleteGeneralContractorContactFromPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGeneralContractorContactFromPayAppMutation,
    DeleteGeneralContractorContactFromPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteGeneralContractorContactFromPayAppMutation,
    DeleteGeneralContractorContactFromPayAppMutationVariables
  >(DeleteGeneralContractorContactFromPayAppDocument, options)
}
export type DeleteGeneralContractorContactFromPayAppMutationHookResult = ReturnType<
  typeof useDeleteGeneralContractorContactFromPayAppMutation
>
export type DeleteGeneralContractorContactFromPayAppMutationResult =
  Apollo.MutationResult<DeleteGeneralContractorContactFromPayAppMutation>
export type DeleteGeneralContractorContactFromPayAppMutationOptions = Apollo.BaseMutationOptions<
  DeleteGeneralContractorContactFromPayAppMutation,
  DeleteGeneralContractorContactFromPayAppMutationVariables
>
export const RoundProgressBillingsDocument = gql`
  mutation roundProgressBillings($payAppId: ID!) {
    roundProgressBillings(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type RoundProgressBillingsMutationFn = Apollo.MutationFunction<
  RoundProgressBillingsMutation,
  RoundProgressBillingsMutationVariables
>

/**
 * __useRoundProgressBillingsMutation__
 *
 * To run a mutation, you first call `useRoundProgressBillingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoundProgressBillingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roundProgressBillingsMutation, { data, loading, error }] = useRoundProgressBillingsMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useRoundProgressBillingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RoundProgressBillingsMutation,
    RoundProgressBillingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RoundProgressBillingsMutation, RoundProgressBillingsMutationVariables>(
    RoundProgressBillingsDocument,
    options
  )
}
export type RoundProgressBillingsMutationHookResult = ReturnType<
  typeof useRoundProgressBillingsMutation
>
export type RoundProgressBillingsMutationResult =
  Apollo.MutationResult<RoundProgressBillingsMutation>
export type RoundProgressBillingsMutationOptions = Apollo.BaseMutationOptions<
  RoundProgressBillingsMutation,
  RoundProgressBillingsMutationVariables
>
export const CreateQuickBillingProjectDocument = gql`
  mutation createQuickBillingProject($input: CreateQuickBillingProjectInput!) {
    createQuickBillingProject(input: $input) {
      id
      contracts {
        id
        payApps {
          id
        }
      }
    }
  }
`
export type CreateQuickBillingProjectMutationFn = Apollo.MutationFunction<
  CreateQuickBillingProjectMutation,
  CreateQuickBillingProjectMutationVariables
>

/**
 * __useCreateQuickBillingProjectMutation__
 *
 * To run a mutation, you first call `useCreateQuickBillingProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickBillingProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickBillingProjectMutation, { data, loading, error }] = useCreateQuickBillingProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuickBillingProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuickBillingProjectMutation,
    CreateQuickBillingProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateQuickBillingProjectMutation,
    CreateQuickBillingProjectMutationVariables
  >(CreateQuickBillingProjectDocument, options)
}
export type CreateQuickBillingProjectMutationHookResult = ReturnType<
  typeof useCreateQuickBillingProjectMutation
>
export type CreateQuickBillingProjectMutationResult =
  Apollo.MutationResult<CreateQuickBillingProjectMutation>
export type CreateQuickBillingProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateQuickBillingProjectMutation,
  CreateQuickBillingProjectMutationVariables
>
export const UpdateQuickBillingProjectDocument = gql`
  mutation updateQuickBillingProject($input: UpdateQuickBillingProjectInput!) {
    updateQuickBillingProject(input: $input) {
      id
      name
      projectNumber
      location {
        ...LocationProperties
      }
    }
  }
  ${LocationPropertiesDoc}
`
export type UpdateQuickBillingProjectMutationFn = Apollo.MutationFunction<
  UpdateQuickBillingProjectMutation,
  UpdateQuickBillingProjectMutationVariables
>

/**
 * __useUpdateQuickBillingProjectMutation__
 *
 * To run a mutation, you first call `useUpdateQuickBillingProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickBillingProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickBillingProjectMutation, { data, loading, error }] = useUpdateQuickBillingProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuickBillingProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuickBillingProjectMutation,
    UpdateQuickBillingProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateQuickBillingProjectMutation,
    UpdateQuickBillingProjectMutationVariables
  >(UpdateQuickBillingProjectDocument, options)
}
export type UpdateQuickBillingProjectMutationHookResult = ReturnType<
  typeof useUpdateQuickBillingProjectMutation
>
export type UpdateQuickBillingProjectMutationResult =
  Apollo.MutationResult<UpdateQuickBillingProjectMutation>
export type UpdateQuickBillingProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuickBillingProjectMutation,
  UpdateQuickBillingProjectMutationVariables
>
export const CreateAmountLineItemDocument = gql`
  mutation createAmountLineItem($input: CreateAmountLineItemInput!) {
    createAmountLineItem(input: $input) {
      ...AmountLineItemProperties
    }
  }
  ${AmountLineItemPropertiesDoc}
`
export type CreateAmountLineItemMutationFn = Apollo.MutationFunction<
  CreateAmountLineItemMutation,
  CreateAmountLineItemMutationVariables
>

/**
 * __useCreateAmountLineItemMutation__
 *
 * To run a mutation, you first call `useCreateAmountLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAmountLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAmountLineItemMutation, { data, loading, error }] = useCreateAmountLineItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAmountLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAmountLineItemMutation,
    CreateAmountLineItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAmountLineItemMutation, CreateAmountLineItemMutationVariables>(
    CreateAmountLineItemDocument,
    options
  )
}
export type CreateAmountLineItemMutationHookResult = ReturnType<
  typeof useCreateAmountLineItemMutation
>
export type CreateAmountLineItemMutationResult = Apollo.MutationResult<CreateAmountLineItemMutation>
export type CreateAmountLineItemMutationOptions = Apollo.BaseMutationOptions<
  CreateAmountLineItemMutation,
  CreateAmountLineItemMutationVariables
>
export const SetAmountLineItemAmountDocument = gql`
  mutation setAmountLineItemAmount($input: SetAmountLineItemAmountInput!) {
    setAmountLineItemAmount(input: $input) {
      ...AmountLineItemProperties
    }
  }
  ${AmountLineItemPropertiesDoc}
`
export type SetAmountLineItemAmountMutationFn = Apollo.MutationFunction<
  SetAmountLineItemAmountMutation,
  SetAmountLineItemAmountMutationVariables
>

/**
 * __useSetAmountLineItemAmountMutation__
 *
 * To run a mutation, you first call `useSetAmountLineItemAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAmountLineItemAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAmountLineItemAmountMutation, { data, loading, error }] = useSetAmountLineItemAmountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAmountLineItemAmountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAmountLineItemAmountMutation,
    SetAmountLineItemAmountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetAmountLineItemAmountMutation,
    SetAmountLineItemAmountMutationVariables
  >(SetAmountLineItemAmountDocument, options)
}
export type SetAmountLineItemAmountMutationHookResult = ReturnType<
  typeof useSetAmountLineItemAmountMutation
>
export type SetAmountLineItemAmountMutationResult =
  Apollo.MutationResult<SetAmountLineItemAmountMutation>
export type SetAmountLineItemAmountMutationOptions = Apollo.BaseMutationOptions<
  SetAmountLineItemAmountMutation,
  SetAmountLineItemAmountMutationVariables
>
export const SetAmountLineItemDescriptionDocument = gql`
  mutation setAmountLineItemDescription($input: SetAmountLineItemDescriptionInput!) {
    setAmountLineItemDescription(input: $input) {
      ...AmountLineItemProperties
    }
  }
  ${AmountLineItemPropertiesDoc}
`
export type SetAmountLineItemDescriptionMutationFn = Apollo.MutationFunction<
  SetAmountLineItemDescriptionMutation,
  SetAmountLineItemDescriptionMutationVariables
>

/**
 * __useSetAmountLineItemDescriptionMutation__
 *
 * To run a mutation, you first call `useSetAmountLineItemDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAmountLineItemDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAmountLineItemDescriptionMutation, { data, loading, error }] = useSetAmountLineItemDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAmountLineItemDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAmountLineItemDescriptionMutation,
    SetAmountLineItemDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetAmountLineItemDescriptionMutation,
    SetAmountLineItemDescriptionMutationVariables
  >(SetAmountLineItemDescriptionDocument, options)
}
export type SetAmountLineItemDescriptionMutationHookResult = ReturnType<
  typeof useSetAmountLineItemDescriptionMutation
>
export type SetAmountLineItemDescriptionMutationResult =
  Apollo.MutationResult<SetAmountLineItemDescriptionMutation>
export type SetAmountLineItemDescriptionMutationOptions = Apollo.BaseMutationOptions<
  SetAmountLineItemDescriptionMutation,
  SetAmountLineItemDescriptionMutationVariables
>
export const SetAmountLineItemTaxGroupDocument = gql`
  mutation setAmountLineItemTaxGroup($input: SetAmountLineItemTaxGroupInput!) {
    setAmountLineItemTaxGroup(input: $input) {
      ...AmountLineItemProperties
    }
  }
  ${AmountLineItemPropertiesDoc}
`
export type SetAmountLineItemTaxGroupMutationFn = Apollo.MutationFunction<
  SetAmountLineItemTaxGroupMutation,
  SetAmountLineItemTaxGroupMutationVariables
>

/**
 * __useSetAmountLineItemTaxGroupMutation__
 *
 * To run a mutation, you first call `useSetAmountLineItemTaxGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAmountLineItemTaxGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAmountLineItemTaxGroupMutation, { data, loading, error }] = useSetAmountLineItemTaxGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAmountLineItemTaxGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAmountLineItemTaxGroupMutation,
    SetAmountLineItemTaxGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetAmountLineItemTaxGroupMutation,
    SetAmountLineItemTaxGroupMutationVariables
  >(SetAmountLineItemTaxGroupDocument, options)
}
export type SetAmountLineItemTaxGroupMutationHookResult = ReturnType<
  typeof useSetAmountLineItemTaxGroupMutation
>
export type SetAmountLineItemTaxGroupMutationResult =
  Apollo.MutationResult<SetAmountLineItemTaxGroupMutation>
export type SetAmountLineItemTaxGroupMutationOptions = Apollo.BaseMutationOptions<
  SetAmountLineItemTaxGroupMutation,
  SetAmountLineItemTaxGroupMutationVariables
>
export const CreateTaxGroupDocument = gql`
  mutation createTaxGroup($input: CreateTaxGroupInput!) {
    createTaxGroup(input: $input) {
      ...TaxGroupProperties
    }
  }
  ${TaxGroupPropertiesDoc}
`
export type CreateTaxGroupMutationFn = Apollo.MutationFunction<
  CreateTaxGroupMutation,
  CreateTaxGroupMutationVariables
>

/**
 * __useCreateTaxGroupMutation__
 *
 * To run a mutation, you first call `useCreateTaxGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxGroupMutation, { data, loading, error }] = useCreateTaxGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaxGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaxGroupMutation, CreateTaxGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaxGroupMutation, CreateTaxGroupMutationVariables>(
    CreateTaxGroupDocument,
    options
  )
}
export type CreateTaxGroupMutationHookResult = ReturnType<typeof useCreateTaxGroupMutation>
export type CreateTaxGroupMutationResult = Apollo.MutationResult<CreateTaxGroupMutation>
export type CreateTaxGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateTaxGroupMutation,
  CreateTaxGroupMutationVariables
>
export const UpdateTaxGroupDocument = gql`
  mutation updateTaxGroup($input: UpdateTaxGroupInput!) {
    updateTaxGroup(input: $input) {
      ...TaxGroupProperties
    }
  }
  ${TaxGroupPropertiesDoc}
`
export type UpdateTaxGroupMutationFn = Apollo.MutationFunction<
  UpdateTaxGroupMutation,
  UpdateTaxGroupMutationVariables
>

/**
 * __useUpdateTaxGroupMutation__
 *
 * To run a mutation, you first call `useUpdateTaxGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxGroupMutation, { data, loading, error }] = useUpdateTaxGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaxGroupMutation, UpdateTaxGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaxGroupMutation, UpdateTaxGroupMutationVariables>(
    UpdateTaxGroupDocument,
    options
  )
}
export type UpdateTaxGroupMutationHookResult = ReturnType<typeof useUpdateTaxGroupMutation>
export type UpdateTaxGroupMutationResult = Apollo.MutationResult<UpdateTaxGroupMutation>
export type UpdateTaxGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaxGroupMutation,
  UpdateTaxGroupMutationVariables
>
export const UpdateTaxCalculationTypeDocument = gql`
  mutation updateTaxCalculationType($input: UpdateTaxCalculationTypeInput!) {
    updateTaxCalculationType(input: $input) {
      id
      taxCalculationType
      defaultTaxGroup {
        ...TaxGroupProperties
      }
      payApps {
        id
        amountDuePreTax
        amountDuePostTax
        amountDueTaxAmount
      }
    }
  }
  ${TaxGroupPropertiesDoc}
`
export type UpdateTaxCalculationTypeMutationFn = Apollo.MutationFunction<
  UpdateTaxCalculationTypeMutation,
  UpdateTaxCalculationTypeMutationVariables
>

/**
 * __useUpdateTaxCalculationTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTaxCalculationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxCalculationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaxCalculationTypeMutation, { data, loading, error }] = useUpdateTaxCalculationTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaxCalculationTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaxCalculationTypeMutation,
    UpdateTaxCalculationTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTaxCalculationTypeMutation,
    UpdateTaxCalculationTypeMutationVariables
  >(UpdateTaxCalculationTypeDocument, options)
}
export type UpdateTaxCalculationTypeMutationHookResult = ReturnType<
  typeof useUpdateTaxCalculationTypeMutation
>
export type UpdateTaxCalculationTypeMutationResult =
  Apollo.MutationResult<UpdateTaxCalculationTypeMutation>
export type UpdateTaxCalculationTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaxCalculationTypeMutation,
  UpdateTaxCalculationTypeMutationVariables
>
export const SetPayAppLineItemSortOrdersDocument = gql`
  mutation setPayAppLineItemSortOrders($input: SetPayAppLineItemSortOrdersInput!) {
    setPayAppLineItemSortOrders(input: $input) {
      id
      status
      billingType
      amountLineItems {
        ...AmountLineItemProperties
      }
    }
  }
  ${AmountLineItemPropertiesDoc}
`
export type SetPayAppLineItemSortOrdersMutationFn = Apollo.MutationFunction<
  SetPayAppLineItemSortOrdersMutation,
  SetPayAppLineItemSortOrdersMutationVariables
>

/**
 * __useSetPayAppLineItemSortOrdersMutation__
 *
 * To run a mutation, you first call `useSetPayAppLineItemSortOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPayAppLineItemSortOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPayAppLineItemSortOrdersMutation, { data, loading, error }] = useSetPayAppLineItemSortOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPayAppLineItemSortOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPayAppLineItemSortOrdersMutation,
    SetPayAppLineItemSortOrdersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetPayAppLineItemSortOrdersMutation,
    SetPayAppLineItemSortOrdersMutationVariables
  >(SetPayAppLineItemSortOrdersDocument, options)
}
export type SetPayAppLineItemSortOrdersMutationHookResult = ReturnType<
  typeof useSetPayAppLineItemSortOrdersMutation
>
export type SetPayAppLineItemSortOrdersMutationResult =
  Apollo.MutationResult<SetPayAppLineItemSortOrdersMutation>
export type SetPayAppLineItemSortOrdersMutationOptions = Apollo.BaseMutationOptions<
  SetPayAppLineItemSortOrdersMutation,
  SetPayAppLineItemSortOrdersMutationVariables
>
export const DeleteAmountLineItemDocument = gql`
  mutation deleteAmountLineItem($id: ID!) {
    deleteAmountLineItem(id: $id) {
      id
    }
  }
`
export type DeleteAmountLineItemMutationFn = Apollo.MutationFunction<
  DeleteAmountLineItemMutation,
  DeleteAmountLineItemMutationVariables
>

/**
 * __useDeleteAmountLineItemMutation__
 *
 * To run a mutation, you first call `useDeleteAmountLineItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAmountLineItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAmountLineItemMutation, { data, loading, error }] = useDeleteAmountLineItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAmountLineItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAmountLineItemMutation,
    DeleteAmountLineItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAmountLineItemMutation, DeleteAmountLineItemMutationVariables>(
    DeleteAmountLineItemDocument,
    options
  )
}
export type DeleteAmountLineItemMutationHookResult = ReturnType<
  typeof useDeleteAmountLineItemMutation
>
export type DeleteAmountLineItemMutationResult = Apollo.MutationResult<DeleteAmountLineItemMutation>
export type DeleteAmountLineItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteAmountLineItemMutation,
  DeleteAmountLineItemMutationVariables
>
export const CreatePayAppAttachmentDocument = gql`
  mutation createPayAppAttachment($payAppId: ID!, $input: PayAppAttachmentInput!) {
    createPayAppAttachment(payAppId: $payAppId, input: $input) {
      ...PayAppAttachmentForBackupProperties
    }
  }
  ${PayAppAttachmentForBackupPropertiesDoc}
`
export type CreatePayAppAttachmentMutationFn = Apollo.MutationFunction<
  CreatePayAppAttachmentMutation,
  CreatePayAppAttachmentMutationVariables
>

/**
 * __useCreatePayAppAttachmentMutation__
 *
 * To run a mutation, you first call `useCreatePayAppAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayAppAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayAppAttachmentMutation, { data, loading, error }] = useCreatePayAppAttachmentMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayAppAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayAppAttachmentMutation,
    CreatePayAppAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePayAppAttachmentMutation,
    CreatePayAppAttachmentMutationVariables
  >(CreatePayAppAttachmentDocument, options)
}
export type CreatePayAppAttachmentMutationHookResult = ReturnType<
  typeof useCreatePayAppAttachmentMutation
>
export type CreatePayAppAttachmentMutationResult =
  Apollo.MutationResult<CreatePayAppAttachmentMutation>
export type CreatePayAppAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreatePayAppAttachmentMutation,
  CreatePayAppAttachmentMutationVariables
>
export const UpdatePayAppAttachmentDocument = gql`
  mutation updatePayAppAttachment($input: UpdatePayAppAttachmentInput!) {
    updatePayAppAttachment(input: $input) {
      ...PayAppAttachmentForBackupProperties
    }
  }
  ${PayAppAttachmentForBackupPropertiesDoc}
`
export type UpdatePayAppAttachmentMutationFn = Apollo.MutationFunction<
  UpdatePayAppAttachmentMutation,
  UpdatePayAppAttachmentMutationVariables
>

/**
 * __useUpdatePayAppAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppAttachmentMutation, { data, loading, error }] = useUpdatePayAppAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppAttachmentMutation,
    UpdatePayAppAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppAttachmentMutation,
    UpdatePayAppAttachmentMutationVariables
  >(UpdatePayAppAttachmentDocument, options)
}
export type UpdatePayAppAttachmentMutationHookResult = ReturnType<
  typeof useUpdatePayAppAttachmentMutation
>
export type UpdatePayAppAttachmentMutationResult =
  Apollo.MutationResult<UpdatePayAppAttachmentMutation>
export type UpdatePayAppAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppAttachmentMutation,
  UpdatePayAppAttachmentMutationVariables
>
export const DeletePayAppAttachmentDocument = gql`
  mutation deletePayAppAttachment($input: DeletePayAppAttachmentInput!) {
    deletePayAppAttachment(input: $input) {
      id
    }
  }
`
export type DeletePayAppAttachmentMutationFn = Apollo.MutationFunction<
  DeletePayAppAttachmentMutation,
  DeletePayAppAttachmentMutationVariables
>

/**
 * __useDeletePayAppAttachmentMutation__
 *
 * To run a mutation, you first call `useDeletePayAppAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayAppAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayAppAttachmentMutation, { data, loading, error }] = useDeletePayAppAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePayAppAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePayAppAttachmentMutation,
    DeletePayAppAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePayAppAttachmentMutation,
    DeletePayAppAttachmentMutationVariables
  >(DeletePayAppAttachmentDocument, options)
}
export type DeletePayAppAttachmentMutationHookResult = ReturnType<
  typeof useDeletePayAppAttachmentMutation
>
export type DeletePayAppAttachmentMutationResult =
  Apollo.MutationResult<DeletePayAppAttachmentMutation>
export type DeletePayAppAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeletePayAppAttachmentMutation,
  DeletePayAppAttachmentMutationVariables
>
export const UpdatedPayAppDocument = gql`
  subscription updatedPayApp($id: ID!) {
    updatePayApp(id: $id) {
      id
      status
      draftSubmittedAt
      statusChangedAt
      invoiceReady
      lastSyncIfSuccessful {
        ...MinimalWriteSyncOperationProperties
      }
    }
  }
  ${MinimalWriteSyncOperationPropertiesDoc}
`

/**
 * __useUpdatedPayAppSubscription__
 *
 * To run a query within a React component, call `useUpdatedPayAppSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatedPayAppSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatedPayAppSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatedPayAppSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdatedPayAppSubscription,
    UpdatedPayAppSubscriptionVariables
  > &
    ({ variables: UpdatedPayAppSubscriptionVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<UpdatedPayAppSubscription, UpdatedPayAppSubscriptionVariables>(
    UpdatedPayAppDocument,
    options
  )
}
export type UpdatedPayAppSubscriptionHookResult = ReturnType<typeof useUpdatedPayAppSubscription>
export type UpdatedPayAppSubscriptionResult = Apollo.SubscriptionResult<UpdatedPayAppSubscription>
export const CompanyUsersDocument = gql`
  query companyUsers($id: ID!) {
    companyUsers(id: $id) {
      ...CompanyUserProperties
    }
  }
  ${CompanyUserPropertiesDoc}
`

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyUsersQuery(
  baseOptions: Apollo.QueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables> &
    ({ variables: CompanyUsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    options
  )
}
export function useCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    options
  )
}
export function useCompanyUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyUsersQuery, CompanyUsersQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    options
  )
}
export type CompanyUsersQueryHookResult = ReturnType<typeof useCompanyUsersQuery>
export type CompanyUsersLazyQueryHookResult = ReturnType<typeof useCompanyUsersLazyQuery>
export type CompanyUsersSuspenseQueryHookResult = ReturnType<typeof useCompanyUsersSuspenseQuery>
export type CompanyUsersQueryResult = Apollo.QueryResult<
  CompanyUsersQuery,
  CompanyUsersQueryVariables
>
export const InviteCompanyUserDocument = gql`
  mutation inviteCompanyUser($input: InviteCompanyUserInput!) {
    inviteCompanyUser(input: $input) {
      ...CompanyUserProperties
    }
  }
  ${CompanyUserPropertiesDoc}
`
export type InviteCompanyUserMutationFn = Apollo.MutationFunction<
  InviteCompanyUserMutation,
  InviteCompanyUserMutationVariables
>

/**
 * __useInviteCompanyUserMutation__
 *
 * To run a mutation, you first call `useInviteCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCompanyUserMutation, { data, loading, error }] = useInviteCompanyUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteCompanyUserMutation,
    InviteCompanyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InviteCompanyUserMutation, InviteCompanyUserMutationVariables>(
    InviteCompanyUserDocument,
    options
  )
}
export type InviteCompanyUserMutationHookResult = ReturnType<typeof useInviteCompanyUserMutation>
export type InviteCompanyUserMutationResult = Apollo.MutationResult<InviteCompanyUserMutation>
export type InviteCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  InviteCompanyUserMutation,
  InviteCompanyUserMutationVariables
>
export const UpdateCompanyUserDocument = gql`
  mutation updateCompanyUser($input: UpdateCompanyUserInput!) {
    updateCompanyUser(input: $input) {
      ...CompanyUserProperties
    }
  }
  ${CompanyUserPropertiesDoc}
`
export type UpdateCompanyUserMutationFn = Apollo.MutationFunction<
  UpdateCompanyUserMutation,
  UpdateCompanyUserMutationVariables
>

/**
 * __useUpdateCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserMutation, { data, loading, error }] = useUpdateCompanyUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyUserMutation,
    UpdateCompanyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>(
    UpdateCompanyUserDocument,
    options
  )
}
export type UpdateCompanyUserMutationHookResult = ReturnType<typeof useUpdateCompanyUserMutation>
export type UpdateCompanyUserMutationResult = Apollo.MutationResult<UpdateCompanyUserMutation>
export type UpdateCompanyUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyUserMutation,
  UpdateCompanyUserMutationVariables
>
export const UpdateUserOfficeLocationDocument = gql`
  mutation updateUserOfficeLocation($input: UpdateUserOfficeLocationInput!) {
    updateUserOfficeLocation(input: $input) {
      id
      companyLocation {
        id
      }
    }
  }
`
export type UpdateUserOfficeLocationMutationFn = Apollo.MutationFunction<
  UpdateUserOfficeLocationMutation,
  UpdateUserOfficeLocationMutationVariables
>

/**
 * __useUpdateUserOfficeLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserOfficeLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOfficeLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOfficeLocationMutation, { data, loading, error }] = useUpdateUserOfficeLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserOfficeLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOfficeLocationMutation,
    UpdateUserOfficeLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserOfficeLocationMutation,
    UpdateUserOfficeLocationMutationVariables
  >(UpdateUserOfficeLocationDocument, options)
}
export type UpdateUserOfficeLocationMutationHookResult = ReturnType<
  typeof useUpdateUserOfficeLocationMutation
>
export type UpdateUserOfficeLocationMutationResult =
  Apollo.MutationResult<UpdateUserOfficeLocationMutation>
export type UpdateUserOfficeLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOfficeLocationMutation,
  UpdateUserOfficeLocationMutationVariables
>
export const DeactivateUserDocument = gql`
  mutation deactivateUser($input: DeactivateUserInput!) {
    deactivateUser(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type DeactivateUserMutationFn = Apollo.MutationFunction<
  DeactivateUserMutation,
  DeactivateUserMutationVariables
>

/**
 * __useDeactivateUserMutation__
 *
 * To run a mutation, you first call `useDeactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserMutation, { data, loading, error }] = useDeactivateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateUserMutation, DeactivateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(
    DeactivateUserDocument,
    options
  )
}
export type DeactivateUserMutationHookResult = ReturnType<typeof useDeactivateUserMutation>
export type DeactivateUserMutationResult = Apollo.MutationResult<DeactivateUserMutation>
export type DeactivateUserMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserMutation,
  DeactivateUserMutationVariables
>
export const ReactivateUserDocument = gql`
  mutation reactivateUser($input: ReactivateUserInput!) {
    reactivateUser(input: $input) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type ReactivateUserMutationFn = Apollo.MutationFunction<
  ReactivateUserMutation,
  ReactivateUserMutationVariables
>

/**
 * __useReactivateUserMutation__
 *
 * To run a mutation, you first call `useReactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUserMutation, { data, loading, error }] = useReactivateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReactivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<ReactivateUserMutation, ReactivateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReactivateUserMutation, ReactivateUserMutationVariables>(
    ReactivateUserDocument,
    options
  )
}
export type ReactivateUserMutationHookResult = ReturnType<typeof useReactivateUserMutation>
export type ReactivateUserMutationResult = Apollo.MutationResult<ReactivateUserMutation>
export type ReactivateUserMutationOptions = Apollo.BaseMutationOptions<
  ReactivateUserMutation,
  ReactivateUserMutationVariables
>
export const GetContractUsersDocument = gql`
  query getContractUsers($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      users {
        ...MinimalContractUserProperties
      }
    }
  }
  ${MinimalContractUserPropertiesDoc}
`

/**
 * __useGetContractUsersQuery__
 *
 * To run a query within a React component, call `useGetContractUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractUsersQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractUsersQuery, GetContractUsersQueryVariables> &
    ({ variables: GetContractUsersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractUsersQuery, GetContractUsersQueryVariables>(
    GetContractUsersDocument,
    options
  )
}
export function useGetContractUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractUsersQuery, GetContractUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractUsersQuery, GetContractUsersQueryVariables>(
    GetContractUsersDocument,
    options
  )
}
export function useGetContractUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractUsersQuery, GetContractUsersQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractUsersQuery, GetContractUsersQueryVariables>(
    GetContractUsersDocument,
    options
  )
}
export type GetContractUsersQueryHookResult = ReturnType<typeof useGetContractUsersQuery>
export type GetContractUsersLazyQueryHookResult = ReturnType<typeof useGetContractUsersLazyQuery>
export type GetContractUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetContractUsersSuspenseQuery
>
export type GetContractUsersQueryResult = Apollo.QueryResult<
  GetContractUsersQuery,
  GetContractUsersQueryVariables
>
export const GetContractsDocument = gql`
  query getContracts($input: GetContractsInput!) {
    contracts(input: $input) {
      id
      billingType
      timeZone
      project {
        id
        name
      }
      payApps {
        id
        billingEnd
      }
      integrations {
        ...MinimalIntegrationProperties
      }
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables> &
    ({ variables: GetContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsQuery, GetContractsQueryVariables>(
    GetContractsDocument,
    options
  )
}
export function useGetContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(
    GetContractsDocument,
    options
  )
}
export function useGetContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractsQuery, GetContractsQueryVariables>(
    GetContractsDocument,
    options
  )
}
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>
export type GetContractsSuspenseQueryHookResult = ReturnType<typeof useGetContractsSuspenseQuery>
export type GetContractsQueryResult = Apollo.QueryResult<
  GetContractsQuery,
  GetContractsQueryVariables
>
export const GetCurrentVendorLienWaiverProjectsDocument = gql`
  query getCurrentVendorLienWaiverProjects($companyId: ID!) {
    currentVendorLienWaiverProjects(companyId: $companyId) {
      ...MinimalProjectProperties
    }
  }
  ${MinimalProjectPropertiesDoc}
`

/**
 * __useGetCurrentVendorLienWaiverProjectsQuery__
 *
 * To run a query within a React component, call `useGetCurrentVendorLienWaiverProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentVendorLienWaiverProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentVendorLienWaiverProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCurrentVendorLienWaiverProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentVendorLienWaiverProjectsQuery,
    GetCurrentVendorLienWaiverProjectsQueryVariables
  > &
    (
      | { variables: GetCurrentVendorLienWaiverProjectsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetCurrentVendorLienWaiverProjectsQuery,
    GetCurrentVendorLienWaiverProjectsQueryVariables
  >(GetCurrentVendorLienWaiverProjectsDocument, options)
}
export function useGetCurrentVendorLienWaiverProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentVendorLienWaiverProjectsQuery,
    GetCurrentVendorLienWaiverProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetCurrentVendorLienWaiverProjectsQuery,
    GetCurrentVendorLienWaiverProjectsQueryVariables
  >(GetCurrentVendorLienWaiverProjectsDocument, options)
}
export function useGetCurrentVendorLienWaiverProjectsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentVendorLienWaiverProjectsQuery,
        GetCurrentVendorLienWaiverProjectsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCurrentVendorLienWaiverProjectsQuery,
    GetCurrentVendorLienWaiverProjectsQueryVariables
  >(GetCurrentVendorLienWaiverProjectsDocument, options)
}
export type GetCurrentVendorLienWaiverProjectsQueryHookResult = ReturnType<
  typeof useGetCurrentVendorLienWaiverProjectsQuery
>
export type GetCurrentVendorLienWaiverProjectsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentVendorLienWaiverProjectsLazyQuery
>
export type GetCurrentVendorLienWaiverProjectsSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentVendorLienWaiverProjectsSuspenseQuery
>
export type GetCurrentVendorLienWaiverProjectsQueryResult = Apollo.QueryResult<
  GetCurrentVendorLienWaiverProjectsQuery,
  GetCurrentVendorLienWaiverProjectsQueryVariables
>
export const ArchiveContractDocument = gql`
  mutation archiveContract($id: ID!) {
    archiveContract(id: $id) {
      id
      contracts {
        id
        status
      }
    }
  }
`
export type ArchiveContractMutationFn = Apollo.MutationFunction<
  ArchiveContractMutation,
  ArchiveContractMutationVariables
>

/**
 * __useArchiveContractMutation__
 *
 * To run a mutation, you first call `useArchiveContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveContractMutation, { data, loading, error }] = useArchiveContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveContractMutation,
    ArchiveContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveContractMutation, ArchiveContractMutationVariables>(
    ArchiveContractDocument,
    options
  )
}
export type ArchiveContractMutationHookResult = ReturnType<typeof useArchiveContractMutation>
export type ArchiveContractMutationResult = Apollo.MutationResult<ArchiveContractMutation>
export type ArchiveContractMutationOptions = Apollo.BaseMutationOptions<
  ArchiveContractMutation,
  ArchiveContractMutationVariables
>
export const UnarchiveContractDocument = gql`
  mutation unarchiveContract($id: ID!) {
    unarchiveContract(id: $id) {
      id
      contracts {
        id
        status
      }
    }
  }
`
export type UnarchiveContractMutationFn = Apollo.MutationFunction<
  UnarchiveContractMutation,
  UnarchiveContractMutationVariables
>

/**
 * __useUnarchiveContractMutation__
 *
 * To run a mutation, you first call `useUnarchiveContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveContractMutation, { data, loading, error }] = useUnarchiveContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveContractMutation,
    UnarchiveContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnarchiveContractMutation, UnarchiveContractMutationVariables>(
    UnarchiveContractDocument,
    options
  )
}
export type UnarchiveContractMutationHookResult = ReturnType<typeof useUnarchiveContractMutation>
export type UnarchiveContractMutationResult = Apollo.MutationResult<UnarchiveContractMutation>
export type UnarchiveContractMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveContractMutation,
  UnarchiveContractMutationVariables
>
export const DeleteContractDocument = gql`
  mutation deleteContract($id: ID!) {
    deleteContract(id: $id) {
      id
    }
  }
`
export type DeleteContractMutationFn = Apollo.MutationFunction<
  DeleteContractMutation,
  DeleteContractMutationVariables
>

/**
 * __useDeleteContractMutation__
 *
 * To run a mutation, you first call `useDeleteContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractMutation, { data, loading, error }] = useDeleteContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContractMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContractMutation, DeleteContractMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteContractMutation, DeleteContractMutationVariables>(
    DeleteContractDocument,
    options
  )
}
export type DeleteContractMutationHookResult = ReturnType<typeof useDeleteContractMutation>
export type DeleteContractMutationResult = Apollo.MutationResult<DeleteContractMutation>
export type DeleteContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractMutation,
  DeleteContractMutationVariables
>
export const GetProjectLienWaiversByMonthDocument = gql`
  query getProjectLienWaiversByMonth($input: GetProjectLienWaiversByMonthInput!) {
    getProjectLienWaiversByMonth(input: $input) {
      project {
        id
        name
      }
      requiresLowerTierLienWaivers
      lienWaiversByMonth {
        month
        year
        lienWaivers {
          ...LienWaiverProperties
          lienWaiverRequests {
            ...LienWaiverRequestProperties
            statusLogs {
              id
              status
            }
          }
        }
      }
    }
  }
  ${LienWaiverPropertiesDoc}
  ${LienWaiverRequestPropertiesDoc}
`

/**
 * __useGetProjectLienWaiversByMonthQuery__
 *
 * To run a query within a React component, call `useGetProjectLienWaiversByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectLienWaiversByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectLienWaiversByMonthQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProjectLienWaiversByMonthQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectLienWaiversByMonthQuery,
    GetProjectLienWaiversByMonthQueryVariables
  > &
    ({ variables: GetProjectLienWaiversByMonthQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProjectLienWaiversByMonthQuery,
    GetProjectLienWaiversByMonthQueryVariables
  >(GetProjectLienWaiversByMonthDocument, options)
}
export function useGetProjectLienWaiversByMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectLienWaiversByMonthQuery,
    GetProjectLienWaiversByMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProjectLienWaiversByMonthQuery,
    GetProjectLienWaiversByMonthQueryVariables
  >(GetProjectLienWaiversByMonthDocument, options)
}
export function useGetProjectLienWaiversByMonthSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetProjectLienWaiversByMonthQuery,
        GetProjectLienWaiversByMonthQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetProjectLienWaiversByMonthQuery,
    GetProjectLienWaiversByMonthQueryVariables
  >(GetProjectLienWaiversByMonthDocument, options)
}
export type GetProjectLienWaiversByMonthQueryHookResult = ReturnType<
  typeof useGetProjectLienWaiversByMonthQuery
>
export type GetProjectLienWaiversByMonthLazyQueryHookResult = ReturnType<
  typeof useGetProjectLienWaiversByMonthLazyQuery
>
export type GetProjectLienWaiversByMonthSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectLienWaiversByMonthSuspenseQuery
>
export type GetProjectLienWaiversByMonthQueryResult = Apollo.QueryResult<
  GetProjectLienWaiversByMonthQuery,
  GetProjectLienWaiversByMonthQueryVariables
>
export const GetVendorLienWaiversByMonthDocument = gql`
  query getVendorLienWaiversByMonth($input: GetVendorLienWaiversByMonthInput!) {
    getVendorLienWaiversByMonth(input: $input) {
      vendor {
        id
        name
      }
      lienWaiversByMonth {
        month
        year
        lienWaivers {
          ...LienWaiverProperties
        }
      }
    }
  }
  ${LienWaiverPropertiesDoc}
`

/**
 * __useGetVendorLienWaiversByMonthQuery__
 *
 * To run a query within a React component, call `useGetVendorLienWaiversByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorLienWaiversByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorLienWaiversByMonthQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVendorLienWaiversByMonthQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorLienWaiversByMonthQuery,
    GetVendorLienWaiversByMonthQueryVariables
  > &
    ({ variables: GetVendorLienWaiversByMonthQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetVendorLienWaiversByMonthQuery,
    GetVendorLienWaiversByMonthQueryVariables
  >(GetVendorLienWaiversByMonthDocument, options)
}
export function useGetVendorLienWaiversByMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorLienWaiversByMonthQuery,
    GetVendorLienWaiversByMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetVendorLienWaiversByMonthQuery,
    GetVendorLienWaiversByMonthQueryVariables
  >(GetVendorLienWaiversByMonthDocument, options)
}
export function useGetVendorLienWaiversByMonthSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetVendorLienWaiversByMonthQuery,
        GetVendorLienWaiversByMonthQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetVendorLienWaiversByMonthQuery,
    GetVendorLienWaiversByMonthQueryVariables
  >(GetVendorLienWaiversByMonthDocument, options)
}
export type GetVendorLienWaiversByMonthQueryHookResult = ReturnType<
  typeof useGetVendorLienWaiversByMonthQuery
>
export type GetVendorLienWaiversByMonthLazyQueryHookResult = ReturnType<
  typeof useGetVendorLienWaiversByMonthLazyQuery
>
export type GetVendorLienWaiversByMonthSuspenseQueryHookResult = ReturnType<
  typeof useGetVendorLienWaiversByMonthSuspenseQuery
>
export type GetVendorLienWaiversByMonthQueryResult = Apollo.QueryResult<
  GetVendorLienWaiversByMonthQuery,
  GetVendorLienWaiversByMonthQueryVariables
>
export const UpdateProjectDocument = gql`
  mutation updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
      projectNumber
      location {
        ...LocationProperties
      }
      generalContractor {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      owner {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      architect {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      metadata {
        payAppDueOnDayOfMonth
      }
    }
  }
  ${LocationPropertiesDoc}
`
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options
  )
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>
export const UpdateContractDocument = gql`
  mutation updateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ...MinimalContractProperties
      contractDate
      contractNumber
      paymentTermsType
      paymentTerms
      appliesTaxToRetention
      defaultGcContacts {
        ...CompanyContactProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalContractPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type UpdateContractMutationFn = Apollo.MutationFunction<
  UpdateContractMutation,
  UpdateContractMutationVariables
>

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
    UpdateContractDocument,
    options
  )
}
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>
export type UpdateContractMutationResult = Apollo.MutationResult<UpdateContractMutation>
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>
export const UpdateContractUsersDocument = gql`
  mutation updateContractUsers($input: UpdateContractUsersInput!) {
    updateContractUsers(input: $input) {
      id
      users {
        ...MinimalContractUserProperties
      }
      leadPMs {
        ...MinimalUserProperties
      }
      onboardedStatus {
        addedTeammates
      }
    }
  }
  ${MinimalContractUserPropertiesDoc}
  ${MinimalUserPropertiesDoc}
`
export type UpdateContractUsersMutationFn = Apollo.MutationFunction<
  UpdateContractUsersMutation,
  UpdateContractUsersMutationVariables
>

/**
 * __useUpdateContractUsersMutation__
 *
 * To run a mutation, you first call `useUpdateContractUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractUsersMutation, { data, loading, error }] = useUpdateContractUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractUsersMutation,
    UpdateContractUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContractUsersMutation, UpdateContractUsersMutationVariables>(
    UpdateContractUsersDocument,
    options
  )
}
export type UpdateContractUsersMutationHookResult = ReturnType<
  typeof useUpdateContractUsersMutation
>
export type UpdateContractUsersMutationResult = Apollo.MutationResult<UpdateContractUsersMutation>
export type UpdateContractUsersMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractUsersMutation,
  UpdateContractUsersMutationVariables
>
export const UpdateVendorDocument = gql`
  mutation updateVendor($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      ...VendorProperties
    }
  }
  ${VendorPropertiesDoc}
`
export type UpdateVendorMutationFn = Apollo.MutationFunction<
  UpdateVendorMutation,
  UpdateVendorMutationVariables
>

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVendorMutation, UpdateVendorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateVendorMutation, UpdateVendorMutationVariables>(
    UpdateVendorDocument,
    options
  )
}
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>
export type UpdateVendorMutationResult = Apollo.MutationResult<UpdateVendorMutation>
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorMutation,
  UpdateVendorMutationVariables
>
export const UpdateVendorContactDocument = gql`
  mutation updateVendorContact($input: UpdateVendorContactInput!) {
    updateVendorContact(input: $input) {
      ...VendorContactProperties
    }
  }
  ${VendorContactPropertiesDoc}
`
export type UpdateVendorContactMutationFn = Apollo.MutationFunction<
  UpdateVendorContactMutation,
  UpdateVendorContactMutationVariables
>

/**
 * __useUpdateVendorContactMutation__
 *
 * To run a mutation, you first call `useUpdateVendorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorContactMutation, { data, loading, error }] = useUpdateVendorContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorContactMutation,
    UpdateVendorContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateVendorContactMutation, UpdateVendorContactMutationVariables>(
    UpdateVendorContactDocument,
    options
  )
}
export type UpdateVendorContactMutationHookResult = ReturnType<
  typeof useUpdateVendorContactMutation
>
export type UpdateVendorContactMutationResult = Apollo.MutationResult<UpdateVendorContactMutation>
export type UpdateVendorContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorContactMutation,
  UpdateVendorContactMutationVariables
>
export const DeleteVendorContactDocument = gql`
  mutation deleteVendorContact($id: ID!) {
    deleteVendorContact(id: $id) {
      id
    }
  }
`
export type DeleteVendorContactMutationFn = Apollo.MutationFunction<
  DeleteVendorContactMutation,
  DeleteVendorContactMutationVariables
>

/**
 * __useDeleteVendorContactMutation__
 *
 * To run a mutation, you first call `useDeleteVendorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorContactMutation, { data, loading, error }] = useDeleteVendorContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVendorContactMutation,
    DeleteVendorContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVendorContactMutation, DeleteVendorContactMutationVariables>(
    DeleteVendorContactDocument,
    options
  )
}
export type DeleteVendorContactMutationHookResult = ReturnType<
  typeof useDeleteVendorContactMutation
>
export type DeleteVendorContactMutationResult = Apollo.MutationResult<DeleteVendorContactMutation>
export type DeleteVendorContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorContactMutation,
  DeleteVendorContactMutationVariables
>
export const GetVendorsForLienWaiverTrackerDocument = gql`
  query getVendorsForLienWaiverTracker($input: GetPaginatedVendorsInput!) {
    paginatedVendors(input: $input) {
      cursor
      hasNext
      vendors {
        id
        conditionalAmountWaived
        unconditionalAmountWaived
        name
      }
    }
  }
`

/**
 * __useGetVendorsForLienWaiverTrackerQuery__
 *
 * To run a query within a React component, call `useGetVendorsForLienWaiverTrackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsForLienWaiverTrackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorsForLienWaiverTrackerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVendorsForLienWaiverTrackerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVendorsForLienWaiverTrackerQuery,
    GetVendorsForLienWaiverTrackerQueryVariables
  > &
    (
      | { variables: GetVendorsForLienWaiverTrackerQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetVendorsForLienWaiverTrackerQuery,
    GetVendorsForLienWaiverTrackerQueryVariables
  >(GetVendorsForLienWaiverTrackerDocument, options)
}
export function useGetVendorsForLienWaiverTrackerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVendorsForLienWaiverTrackerQuery,
    GetVendorsForLienWaiverTrackerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetVendorsForLienWaiverTrackerQuery,
    GetVendorsForLienWaiverTrackerQueryVariables
  >(GetVendorsForLienWaiverTrackerDocument, options)
}
export function useGetVendorsForLienWaiverTrackerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetVendorsForLienWaiverTrackerQuery,
        GetVendorsForLienWaiverTrackerQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetVendorsForLienWaiverTrackerQuery,
    GetVendorsForLienWaiverTrackerQueryVariables
  >(GetVendorsForLienWaiverTrackerDocument, options)
}
export type GetVendorsForLienWaiverTrackerQueryHookResult = ReturnType<
  typeof useGetVendorsForLienWaiverTrackerQuery
>
export type GetVendorsForLienWaiverTrackerLazyQueryHookResult = ReturnType<
  typeof useGetVendorsForLienWaiverTrackerLazyQuery
>
export type GetVendorsForLienWaiverTrackerSuspenseQueryHookResult = ReturnType<
  typeof useGetVendorsForLienWaiverTrackerSuspenseQuery
>
export type GetVendorsForLienWaiverTrackerQueryResult = Apollo.QueryResult<
  GetVendorsForLienWaiverTrackerQuery,
  GetVendorsForLienWaiverTrackerQueryVariables
>
export const ContractForLienWaiverVendorContractsDocument = gql`
  query contractForLienWaiverVendorContracts($id: ID!) {
    contract(id: $id) {
      id
      vendorContracts {
        ...VendorContractForLienWaiversProperties
      }
    }
  }
  ${VendorContractForLienWaiversPropertiesDoc}
`

/**
 * __useContractForLienWaiverVendorContractsQuery__
 *
 * To run a query within a React component, call `useContractForLienWaiverVendorContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForLienWaiverVendorContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForLienWaiverVendorContractsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForLienWaiverVendorContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForLienWaiverVendorContractsQuery,
    ContractForLienWaiverVendorContractsQueryVariables
  > &
    (
      | { variables: ContractForLienWaiverVendorContractsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractForLienWaiverVendorContractsQuery,
    ContractForLienWaiverVendorContractsQueryVariables
  >(ContractForLienWaiverVendorContractsDocument, options)
}
export function useContractForLienWaiverVendorContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForLienWaiverVendorContractsQuery,
    ContractForLienWaiverVendorContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForLienWaiverVendorContractsQuery,
    ContractForLienWaiverVendorContractsQueryVariables
  >(ContractForLienWaiverVendorContractsDocument, options)
}
export function useContractForLienWaiverVendorContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForLienWaiverVendorContractsQuery,
        ContractForLienWaiverVendorContractsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForLienWaiverVendorContractsQuery,
    ContractForLienWaiverVendorContractsQueryVariables
  >(ContractForLienWaiverVendorContractsDocument, options)
}
export type ContractForLienWaiverVendorContractsQueryHookResult = ReturnType<
  typeof useContractForLienWaiverVendorContractsQuery
>
export type ContractForLienWaiverVendorContractsLazyQueryHookResult = ReturnType<
  typeof useContractForLienWaiverVendorContractsLazyQuery
>
export type ContractForLienWaiverVendorContractsSuspenseQueryHookResult = ReturnType<
  typeof useContractForLienWaiverVendorContractsSuspenseQuery
>
export type ContractForLienWaiverVendorContractsQueryResult = Apollo.QueryResult<
  ContractForLienWaiverVendorContractsQuery,
  ContractForLienWaiverVendorContractsQueryVariables
>
export const AddVendorsToProjectDocument = gql`
  mutation addVendorsToProject($input: AddVendorsToProjectInput!) {
    addVendorsToProject(input: $input) {
      ...VendorContractProperties
    }
  }
  ${VendorContractPropertiesDoc}
`
export type AddVendorsToProjectMutationFn = Apollo.MutationFunction<
  AddVendorsToProjectMutation,
  AddVendorsToProjectMutationVariables
>

/**
 * __useAddVendorsToProjectMutation__
 *
 * To run a mutation, you first call `useAddVendorsToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorsToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorsToProjectMutation, { data, loading, error }] = useAddVendorsToProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorsToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVendorsToProjectMutation,
    AddVendorsToProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddVendorsToProjectMutation, AddVendorsToProjectMutationVariables>(
    AddVendorsToProjectDocument,
    options
  )
}
export type AddVendorsToProjectMutationHookResult = ReturnType<
  typeof useAddVendorsToProjectMutation
>
export type AddVendorsToProjectMutationResult = Apollo.MutationResult<AddVendorsToProjectMutation>
export type AddVendorsToProjectMutationOptions = Apollo.BaseMutationOptions<
  AddVendorsToProjectMutation,
  AddVendorsToProjectMutationVariables
>
export const UpdateVendorContractDocument = gql`
  mutation updateVendorContract($input: UpdateVendorContractInput!) {
    updateVendorContract(input: $input) {
      ...VendorContractProperties
    }
  }
  ${VendorContractPropertiesDoc}
`
export type UpdateVendorContractMutationFn = Apollo.MutationFunction<
  UpdateVendorContractMutation,
  UpdateVendorContractMutationVariables
>

/**
 * __useUpdateVendorContractMutation__
 *
 * To run a mutation, you first call `useUpdateVendorContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorContractMutation, { data, loading, error }] = useUpdateVendorContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorContractMutation,
    UpdateVendorContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateVendorContractMutation, UpdateVendorContractMutationVariables>(
    UpdateVendorContractDocument,
    options
  )
}
export type UpdateVendorContractMutationHookResult = ReturnType<
  typeof useUpdateVendorContractMutation
>
export type UpdateVendorContractMutationResult = Apollo.MutationResult<UpdateVendorContractMutation>
export type UpdateVendorContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorContractMutation,
  UpdateVendorContractMutationVariables
>
export const DeleteVendorContractDocument = gql`
  mutation deleteVendorContract($id: ID!) {
    deleteVendorContract(id: $id) {
      id
    }
  }
`
export type DeleteVendorContractMutationFn = Apollo.MutationFunction<
  DeleteVendorContractMutation,
  DeleteVendorContractMutationVariables
>

/**
 * __useDeleteVendorContractMutation__
 *
 * To run a mutation, you first call `useDeleteVendorContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorContractMutation, { data, loading, error }] = useDeleteVendorContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVendorContractMutation,
    DeleteVendorContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteVendorContractMutation, DeleteVendorContractMutationVariables>(
    DeleteVendorContractDocument,
    options
  )
}
export type DeleteVendorContractMutationHookResult = ReturnType<
  typeof useDeleteVendorContractMutation
>
export type DeleteVendorContractMutationResult = Apollo.MutationResult<DeleteVendorContractMutation>
export type DeleteVendorContractMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorContractMutation,
  DeleteVendorContractMutationVariables
>
export const AddVendorLegalRequirementDocument = gql`
  mutation addVendorLegalRequirement($input: AddVendorLegalRequirementInput!) {
    addVendorLegalRequirement(input: $input) {
      ...LegalRequirementProperties
      contract {
        id
        company {
          id
        }
        project {
          id
          name
        }
        legalRequirementContacts {
          ...CompanyContactProperties
        }
      }
      vendorTemplate {
        id
        name
        url
      }
    }
  }
  ${LegalRequirementPropertiesDoc}
  ${CompanyContactPropertiesDoc}
`
export type AddVendorLegalRequirementMutationFn = Apollo.MutationFunction<
  AddVendorLegalRequirementMutation,
  AddVendorLegalRequirementMutationVariables
>

/**
 * __useAddVendorLegalRequirementMutation__
 *
 * To run a mutation, you first call `useAddVendorLegalRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorLegalRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorLegalRequirementMutation, { data, loading, error }] = useAddVendorLegalRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorLegalRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVendorLegalRequirementMutation,
    AddVendorLegalRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddVendorLegalRequirementMutation,
    AddVendorLegalRequirementMutationVariables
  >(AddVendorLegalRequirementDocument, options)
}
export type AddVendorLegalRequirementMutationHookResult = ReturnType<
  typeof useAddVendorLegalRequirementMutation
>
export type AddVendorLegalRequirementMutationResult =
  Apollo.MutationResult<AddVendorLegalRequirementMutation>
export type AddVendorLegalRequirementMutationOptions = Apollo.BaseMutationOptions<
  AddVendorLegalRequirementMutation,
  AddVendorLegalRequirementMutationVariables
>
export const UpdateVendorLegalRequirementDocument = gql`
  mutation updateVendorLegalRequirement($input: UpdateVendorLegalRequirementInput!) {
    updateVendorLegalRequirement(input: $input) {
      ...MinimalLegalRequirementProperties
      vendorTemplate {
        id
        name
        url
      }
    }
  }
  ${MinimalLegalRequirementPropertiesDoc}
`
export type UpdateVendorLegalRequirementMutationFn = Apollo.MutationFunction<
  UpdateVendorLegalRequirementMutation,
  UpdateVendorLegalRequirementMutationVariables
>

/**
 * __useUpdateVendorLegalRequirementMutation__
 *
 * To run a mutation, you first call `useUpdateVendorLegalRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorLegalRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorLegalRequirementMutation, { data, loading, error }] = useUpdateVendorLegalRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorLegalRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorLegalRequirementMutation,
    UpdateVendorLegalRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVendorLegalRequirementMutation,
    UpdateVendorLegalRequirementMutationVariables
  >(UpdateVendorLegalRequirementDocument, options)
}
export type UpdateVendorLegalRequirementMutationHookResult = ReturnType<
  typeof useUpdateVendorLegalRequirementMutation
>
export type UpdateVendorLegalRequirementMutationResult =
  Apollo.MutationResult<UpdateVendorLegalRequirementMutation>
export type UpdateVendorLegalRequirementMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorLegalRequirementMutation,
  UpdateVendorLegalRequirementMutationVariables
>
export const DeleteVendorLegalRequirementDocument = gql`
  mutation deleteVendorLegalRequirement($id: ID!) {
    deleteVendorLegalRequirement(id: $id) {
      id
    }
  }
`
export type DeleteVendorLegalRequirementMutationFn = Apollo.MutationFunction<
  DeleteVendorLegalRequirementMutation,
  DeleteVendorLegalRequirementMutationVariables
>

/**
 * __useDeleteVendorLegalRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteVendorLegalRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorLegalRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorLegalRequirementMutation, { data, loading, error }] = useDeleteVendorLegalRequirementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVendorLegalRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVendorLegalRequirementMutation,
    DeleteVendorLegalRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteVendorLegalRequirementMutation,
    DeleteVendorLegalRequirementMutationVariables
  >(DeleteVendorLegalRequirementDocument, options)
}
export type DeleteVendorLegalRequirementMutationHookResult = ReturnType<
  typeof useDeleteVendorLegalRequirementMutation
>
export type DeleteVendorLegalRequirementMutationResult =
  Apollo.MutationResult<DeleteVendorLegalRequirementMutation>
export type DeleteVendorLegalRequirementMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorLegalRequirementMutation,
  DeleteVendorLegalRequirementMutationVariables
>
export const VendorLegalRequirementsDocument = gql`
  query vendorLegalRequirements($vendorIds: [ID!]!) {
    vendorLegalRequirements(vendorIds: $vendorIds) {
      ...VendorLegalRequirementProperties
    }
  }
  ${VendorLegalRequirementPropertiesDoc}
`

/**
 * __useVendorLegalRequirementsQuery__
 *
 * To run a query within a React component, call `useVendorLegalRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorLegalRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorLegalRequirementsQuery({
 *   variables: {
 *      vendorIds: // value for 'vendorIds'
 *   },
 * });
 */
export function useVendorLegalRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorLegalRequirementsQuery,
    VendorLegalRequirementsQueryVariables
  > &
    ({ variables: VendorLegalRequirementsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorLegalRequirementsQuery, VendorLegalRequirementsQueryVariables>(
    VendorLegalRequirementsDocument,
    options
  )
}
export function useVendorLegalRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorLegalRequirementsQuery,
    VendorLegalRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VendorLegalRequirementsQuery, VendorLegalRequirementsQueryVariables>(
    VendorLegalRequirementsDocument,
    options
  )
}
export function useVendorLegalRequirementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VendorLegalRequirementsQuery,
        VendorLegalRequirementsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VendorLegalRequirementsQuery,
    VendorLegalRequirementsQueryVariables
  >(VendorLegalRequirementsDocument, options)
}
export type VendorLegalRequirementsQueryHookResult = ReturnType<
  typeof useVendorLegalRequirementsQuery
>
export type VendorLegalRequirementsLazyQueryHookResult = ReturnType<
  typeof useVendorLegalRequirementsLazyQuery
>
export type VendorLegalRequirementsSuspenseQueryHookResult = ReturnType<
  typeof useVendorLegalRequirementsSuspenseQuery
>
export type VendorLegalRequirementsQueryResult = Apollo.QueryResult<
  VendorLegalRequirementsQuery,
  VendorLegalRequirementsQueryVariables
>
export const AddLegalRequirementDocument = gql`
  mutation addLegalRequirement($input: AddLegalRequirementInput!) {
    addLegalRequirement(input: $input) {
      ...MinimalComplianceLegalRequirementProperties
    }
  }
  ${MinimalComplianceLegalRequirementPropertiesDoc}
`
export type AddLegalRequirementMutationFn = Apollo.MutationFunction<
  AddLegalRequirementMutation,
  AddLegalRequirementMutationVariables
>

/**
 * __useAddLegalRequirementMutation__
 *
 * To run a mutation, you first call `useAddLegalRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLegalRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLegalRequirementMutation, { data, loading, error }] = useAddLegalRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLegalRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLegalRequirementMutation,
    AddLegalRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddLegalRequirementMutation, AddLegalRequirementMutationVariables>(
    AddLegalRequirementDocument,
    options
  )
}
export type AddLegalRequirementMutationHookResult = ReturnType<
  typeof useAddLegalRequirementMutation
>
export type AddLegalRequirementMutationResult = Apollo.MutationResult<AddLegalRequirementMutation>
export type AddLegalRequirementMutationOptions = Apollo.BaseMutationOptions<
  AddLegalRequirementMutation,
  AddLegalRequirementMutationVariables
>
export const UpdateLegalRequirementDocument = gql`
  mutation updateLegalRequirement($input: UpdateLegalRequirementInput!) {
    updateLegalRequirement(input: $input) {
      ...MinimalComplianceLegalRequirementProperties
    }
  }
  ${MinimalComplianceLegalRequirementPropertiesDoc}
`
export type UpdateLegalRequirementMutationFn = Apollo.MutationFunction<
  UpdateLegalRequirementMutation,
  UpdateLegalRequirementMutationVariables
>

/**
 * __useUpdateLegalRequirementMutation__
 *
 * To run a mutation, you first call `useUpdateLegalRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalRequirementMutation, { data, loading, error }] = useUpdateLegalRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLegalRequirementMutation,
    UpdateLegalRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLegalRequirementMutation,
    UpdateLegalRequirementMutationVariables
  >(UpdateLegalRequirementDocument, options)
}
export type UpdateLegalRequirementMutationHookResult = ReturnType<
  typeof useUpdateLegalRequirementMutation
>
export type UpdateLegalRequirementMutationResult =
  Apollo.MutationResult<UpdateLegalRequirementMutation>
export type UpdateLegalRequirementMutationOptions = Apollo.BaseMutationOptions<
  UpdateLegalRequirementMutation,
  UpdateLegalRequirementMutationVariables
>
export const DeleteLegalRequirementDocument = gql`
  mutation deleteLegalRequirement($id: ID!) {
    deleteLegalRequirement(id: $id) {
      id
    }
  }
`
export type DeleteLegalRequirementMutationFn = Apollo.MutationFunction<
  DeleteLegalRequirementMutation,
  DeleteLegalRequirementMutationVariables
>

/**
 * __useDeleteLegalRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteLegalRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLegalRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLegalRequirementMutation, { data, loading, error }] = useDeleteLegalRequirementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLegalRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLegalRequirementMutation,
    DeleteLegalRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteLegalRequirementMutation,
    DeleteLegalRequirementMutationVariables
  >(DeleteLegalRequirementDocument, options)
}
export type DeleteLegalRequirementMutationHookResult = ReturnType<
  typeof useDeleteLegalRequirementMutation
>
export type DeleteLegalRequirementMutationResult =
  Apollo.MutationResult<DeleteLegalRequirementMutation>
export type DeleteLegalRequirementMutationOptions = Apollo.BaseMutationOptions<
  DeleteLegalRequirementMutation,
  DeleteLegalRequirementMutationVariables
>
export const CopyContractLegalRequirementsDocument = gql`
  mutation copyContractLegalRequirements($input: CopyContractLegalRequirementsInput!) {
    copyContractLegalRequirements(input: $input) {
      id
    }
  }
`
export type CopyContractLegalRequirementsMutationFn = Apollo.MutationFunction<
  CopyContractLegalRequirementsMutation,
  CopyContractLegalRequirementsMutationVariables
>

/**
 * __useCopyContractLegalRequirementsMutation__
 *
 * To run a mutation, you first call `useCopyContractLegalRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyContractLegalRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyContractLegalRequirementsMutation, { data, loading, error }] = useCopyContractLegalRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyContractLegalRequirementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyContractLegalRequirementsMutation,
    CopyContractLegalRequirementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CopyContractLegalRequirementsMutation,
    CopyContractLegalRequirementsMutationVariables
  >(CopyContractLegalRequirementsDocument, options)
}
export type CopyContractLegalRequirementsMutationHookResult = ReturnType<
  typeof useCopyContractLegalRequirementsMutation
>
export type CopyContractLegalRequirementsMutationResult =
  Apollo.MutationResult<CopyContractLegalRequirementsMutation>
export type CopyContractLegalRequirementsMutationOptions = Apollo.BaseMutationOptions<
  CopyContractLegalRequirementsMutation,
  CopyContractLegalRequirementsMutationVariables
>
export const ProjectLegalRequirementsDocument = gql`
  query projectLegalRequirements($input: GetProjectLegalRequirementsInput!) {
    projectLegalRequirements(input: $input) {
      ...VendorLegalRequirementProperties
      vendorContract {
        id
        vendor {
          ...MinimalVendorProperties
        }
      }
    }
  }
  ${VendorLegalRequirementPropertiesDoc}
  ${MinimalVendorPropertiesDoc}
`

/**
 * __useProjectLegalRequirementsQuery__
 *
 * To run a query within a React component, call `useProjectLegalRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLegalRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLegalRequirementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectLegalRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectLegalRequirementsQuery,
    ProjectLegalRequirementsQueryVariables
  > &
    ({ variables: ProjectLegalRequirementsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectLegalRequirementsQuery, ProjectLegalRequirementsQueryVariables>(
    ProjectLegalRequirementsDocument,
    options
  )
}
export function useProjectLegalRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectLegalRequirementsQuery,
    ProjectLegalRequirementsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectLegalRequirementsQuery, ProjectLegalRequirementsQueryVariables>(
    ProjectLegalRequirementsDocument,
    options
  )
}
export function useProjectLegalRequirementsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectLegalRequirementsQuery,
        ProjectLegalRequirementsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ProjectLegalRequirementsQuery,
    ProjectLegalRequirementsQueryVariables
  >(ProjectLegalRequirementsDocument, options)
}
export type ProjectLegalRequirementsQueryHookResult = ReturnType<
  typeof useProjectLegalRequirementsQuery
>
export type ProjectLegalRequirementsLazyQueryHookResult = ReturnType<
  typeof useProjectLegalRequirementsLazyQuery
>
export type ProjectLegalRequirementsSuspenseQueryHookResult = ReturnType<
  typeof useProjectLegalRequirementsSuspenseQuery
>
export type ProjectLegalRequirementsQueryResult = Apollo.QueryResult<
  ProjectLegalRequirementsQuery,
  ProjectLegalRequirementsQueryVariables
>
export const RequestLegalDocumentFromVendorDocument = gql`
  mutation requestLegalDocumentFromVendor($input: RequestLegalDocumentsFromVendorInput!) {
    requestLegalDocumentFromVendor(input: $input) {
      ...LegalDocumentProperties
    }
  }
  ${LegalDocumentPropertiesDoc}
`
export type RequestLegalDocumentFromVendorMutationFn = Apollo.MutationFunction<
  RequestLegalDocumentFromVendorMutation,
  RequestLegalDocumentFromVendorMutationVariables
>

/**
 * __useRequestLegalDocumentFromVendorMutation__
 *
 * To run a mutation, you first call `useRequestLegalDocumentFromVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestLegalDocumentFromVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestLegalDocumentFromVendorMutation, { data, loading, error }] = useRequestLegalDocumentFromVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestLegalDocumentFromVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestLegalDocumentFromVendorMutation,
    RequestLegalDocumentFromVendorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestLegalDocumentFromVendorMutation,
    RequestLegalDocumentFromVendorMutationVariables
  >(RequestLegalDocumentFromVendorDocument, options)
}
export type RequestLegalDocumentFromVendorMutationHookResult = ReturnType<
  typeof useRequestLegalDocumentFromVendorMutation
>
export type RequestLegalDocumentFromVendorMutationResult =
  Apollo.MutationResult<RequestLegalDocumentFromVendorMutation>
export type RequestLegalDocumentFromVendorMutationOptions = Apollo.BaseMutationOptions<
  RequestLegalDocumentFromVendorMutation,
  RequestLegalDocumentFromVendorMutationVariables
>
export const DocumentTypesDocument = gql`
  query documentTypes($companyId: ID!) {
    documentTypes(companyId: $companyId)
  }
`

/**
 * __useDocumentTypesQuery__
 *
 * To run a query within a React component, call `useDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDocumentTypesQuery(
  baseOptions: Apollo.QueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables> &
    ({ variables: DocumentTypesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(
    DocumentTypesDocument,
    options
  )
}
export function useDocumentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(
    DocumentTypesDocument,
    options
  )
}
export function useDocumentTypesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(
    DocumentTypesDocument,
    options
  )
}
export type DocumentTypesQueryHookResult = ReturnType<typeof useDocumentTypesQuery>
export type DocumentTypesLazyQueryHookResult = ReturnType<typeof useDocumentTypesLazyQuery>
export type DocumentTypesSuspenseQueryHookResult = ReturnType<typeof useDocumentTypesSuspenseQuery>
export type DocumentTypesQueryResult = Apollo.QueryResult<
  DocumentTypesQuery,
  DocumentTypesQueryVariables
>
export const RejectAndRequestLegalDocumentFromVendorDocument = gql`
  mutation rejectAndRequestLegalDocumentFromVendor(
    $input: RejectAndRequestLegalDocumentFromVendorInput!
  ) {
    rejectAndRequestLegalDocumentFromVendor(input: $input) {
      ...LegalDocumentProperties
    }
  }
  ${LegalDocumentPropertiesDoc}
`
export type RejectAndRequestLegalDocumentFromVendorMutationFn = Apollo.MutationFunction<
  RejectAndRequestLegalDocumentFromVendorMutation,
  RejectAndRequestLegalDocumentFromVendorMutationVariables
>

/**
 * __useRejectAndRequestLegalDocumentFromVendorMutation__
 *
 * To run a mutation, you first call `useRejectAndRequestLegalDocumentFromVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAndRequestLegalDocumentFromVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAndRequestLegalDocumentFromVendorMutation, { data, loading, error }] = useRejectAndRequestLegalDocumentFromVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectAndRequestLegalDocumentFromVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectAndRequestLegalDocumentFromVendorMutation,
    RejectAndRequestLegalDocumentFromVendorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RejectAndRequestLegalDocumentFromVendorMutation,
    RejectAndRequestLegalDocumentFromVendorMutationVariables
  >(RejectAndRequestLegalDocumentFromVendorDocument, options)
}
export type RejectAndRequestLegalDocumentFromVendorMutationHookResult = ReturnType<
  typeof useRejectAndRequestLegalDocumentFromVendorMutation
>
export type RejectAndRequestLegalDocumentFromVendorMutationResult =
  Apollo.MutationResult<RejectAndRequestLegalDocumentFromVendorMutation>
export type RejectAndRequestLegalDocumentFromVendorMutationOptions = Apollo.BaseMutationOptions<
  RejectAndRequestLegalDocumentFromVendorMutation,
  RejectAndRequestLegalDocumentFromVendorMutationVariables
>
export const GetLegalDocumentsByProjectDocument = gql`
  query getLegalDocumentsByProject($input: GetLegalDocumentsByPeriodInput!) {
    getLegalDocumentsByProject(input: $input) {
      project {
        id
        name
      }
      periodStart
      vendorDocuments {
        isSkippedPeriod
        vendorRequirement {
          ...VendorLegalRequirementForTrackerProperties
        }
        document {
          id
          lastSendTime
          createdAt
          isUploaded
          periodStart
          periodEnd
          uploadedBy {
            id
            firstName
            lastName
            email
          }
          legalDocumentRequests {
            ...LegalDocumentRequestProperties
          }
          lastSyncIfSuccessful {
            ...MinimalWriteSyncOperationProperties
          }
        }
      }
    }
  }
  ${VendorLegalRequirementForTrackerPropertiesDoc}
  ${LegalDocumentRequestPropertiesDoc}
  ${MinimalWriteSyncOperationPropertiesDoc}
`

/**
 * __useGetLegalDocumentsByProjectQuery__
 *
 * To run a query within a React component, call `useGetLegalDocumentsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalDocumentsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalDocumentsByProjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLegalDocumentsByProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLegalDocumentsByProjectQuery,
    GetLegalDocumentsByProjectQueryVariables
  > &
    ({ variables: GetLegalDocumentsByProjectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLegalDocumentsByProjectQuery, GetLegalDocumentsByProjectQueryVariables>(
    GetLegalDocumentsByProjectDocument,
    options
  )
}
export function useGetLegalDocumentsByProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLegalDocumentsByProjectQuery,
    GetLegalDocumentsByProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLegalDocumentsByProjectQuery,
    GetLegalDocumentsByProjectQueryVariables
  >(GetLegalDocumentsByProjectDocument, options)
}
export function useGetLegalDocumentsByProjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLegalDocumentsByProjectQuery,
        GetLegalDocumentsByProjectQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetLegalDocumentsByProjectQuery,
    GetLegalDocumentsByProjectQueryVariables
  >(GetLegalDocumentsByProjectDocument, options)
}
export type GetLegalDocumentsByProjectQueryHookResult = ReturnType<
  typeof useGetLegalDocumentsByProjectQuery
>
export type GetLegalDocumentsByProjectLazyQueryHookResult = ReturnType<
  typeof useGetLegalDocumentsByProjectLazyQuery
>
export type GetLegalDocumentsByProjectSuspenseQueryHookResult = ReturnType<
  typeof useGetLegalDocumentsByProjectSuspenseQuery
>
export type GetLegalDocumentsByProjectQueryResult = Apollo.QueryResult<
  GetLegalDocumentsByProjectQuery,
  GetLegalDocumentsByProjectQueryVariables
>
export const GetLegalDocumentsByVendorDocument = gql`
  query getLegalDocumentsByVendor($input: GetLegalDocumentsByPeriodInput!) {
    getLegalDocumentsByVendor(input: $input) {
      vendor {
        ...VendorProperties
      }
      periodStart
      vendorDocuments {
        isSkippedPeriod
        vendorRequirement {
          ...VendorLegalRequirementForTrackerProperties
        }
        document {
          id
          lastSendTime
          createdAt
          isUploaded
          periodStart
          periodEnd
          uploadedBy {
            id
            firstName
            lastName
            email
          }
          legalDocumentRequests {
            ...LegalDocumentRequestProperties
          }
          lastSyncIfSuccessful {
            ...MinimalWriteSyncOperationProperties
          }
        }
      }
    }
  }
  ${VendorPropertiesDoc}
  ${VendorLegalRequirementForTrackerPropertiesDoc}
  ${LegalDocumentRequestPropertiesDoc}
  ${MinimalWriteSyncOperationPropertiesDoc}
`

/**
 * __useGetLegalDocumentsByVendorQuery__
 *
 * To run a query within a React component, call `useGetLegalDocumentsByVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegalDocumentsByVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegalDocumentsByVendorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLegalDocumentsByVendorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLegalDocumentsByVendorQuery,
    GetLegalDocumentsByVendorQueryVariables
  > &
    ({ variables: GetLegalDocumentsByVendorQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLegalDocumentsByVendorQuery, GetLegalDocumentsByVendorQueryVariables>(
    GetLegalDocumentsByVendorDocument,
    options
  )
}
export function useGetLegalDocumentsByVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLegalDocumentsByVendorQuery,
    GetLegalDocumentsByVendorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLegalDocumentsByVendorQuery,
    GetLegalDocumentsByVendorQueryVariables
  >(GetLegalDocumentsByVendorDocument, options)
}
export function useGetLegalDocumentsByVendorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLegalDocumentsByVendorQuery,
        GetLegalDocumentsByVendorQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetLegalDocumentsByVendorQuery,
    GetLegalDocumentsByVendorQueryVariables
  >(GetLegalDocumentsByVendorDocument, options)
}
export type GetLegalDocumentsByVendorQueryHookResult = ReturnType<
  typeof useGetLegalDocumentsByVendorQuery
>
export type GetLegalDocumentsByVendorLazyQueryHookResult = ReturnType<
  typeof useGetLegalDocumentsByVendorLazyQuery
>
export type GetLegalDocumentsByVendorSuspenseQueryHookResult = ReturnType<
  typeof useGetLegalDocumentsByVendorSuspenseQuery
>
export type GetLegalDocumentsByVendorQueryResult = Apollo.QueryResult<
  GetLegalDocumentsByVendorQuery,
  GetLegalDocumentsByVendorQueryVariables
>
export const ProjectCompaniesForSubcontractorDocument = gql`
  query projectCompaniesForSubcontractor($subcontractorCompanyId: ID!) {
    projectCompaniesForSubcontractor(subcontractorCompanyId: $subcontractorCompanyId) {
      generalContractors {
        id
        name
        type
        locations {
          ...LocationProperties
        }
      }
      owners {
        id
        name
        type
        locations {
          ...LocationProperties
        }
      }
      architects {
        id
        name
        type
        locations {
          ...LocationProperties
        }
      }
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useProjectCompaniesForSubcontractorQuery__
 *
 * To run a query within a React component, call `useProjectCompaniesForSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCompaniesForSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCompaniesForSubcontractorQuery({
 *   variables: {
 *      subcontractorCompanyId: // value for 'subcontractorCompanyId'
 *   },
 * });
 */
export function useProjectCompaniesForSubcontractorQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectCompaniesForSubcontractorQuery,
    ProjectCompaniesForSubcontractorQueryVariables
  > &
    (
      | { variables: ProjectCompaniesForSubcontractorQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ProjectCompaniesForSubcontractorQuery,
    ProjectCompaniesForSubcontractorQueryVariables
  >(ProjectCompaniesForSubcontractorDocument, options)
}
export function useProjectCompaniesForSubcontractorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectCompaniesForSubcontractorQuery,
    ProjectCompaniesForSubcontractorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ProjectCompaniesForSubcontractorQuery,
    ProjectCompaniesForSubcontractorQueryVariables
  >(ProjectCompaniesForSubcontractorDocument, options)
}
export function useProjectCompaniesForSubcontractorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ProjectCompaniesForSubcontractorQuery,
        ProjectCompaniesForSubcontractorQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ProjectCompaniesForSubcontractorQuery,
    ProjectCompaniesForSubcontractorQueryVariables
  >(ProjectCompaniesForSubcontractorDocument, options)
}
export type ProjectCompaniesForSubcontractorQueryHookResult = ReturnType<
  typeof useProjectCompaniesForSubcontractorQuery
>
export type ProjectCompaniesForSubcontractorLazyQueryHookResult = ReturnType<
  typeof useProjectCompaniesForSubcontractorLazyQuery
>
export type ProjectCompaniesForSubcontractorSuspenseQueryHookResult = ReturnType<
  typeof useProjectCompaniesForSubcontractorSuspenseQuery
>
export type ProjectCompaniesForSubcontractorQueryResult = Apollo.QueryResult<
  ProjectCompaniesForSubcontractorQuery,
  ProjectCompaniesForSubcontractorQueryVariables
>
export const CreateMonthlyBillingProjectDocument = gql`
  mutation createMonthlyBillingProject($input: CreateMonthlyBillingProjectInput!) {
    createMonthlyBillingProject(input: $input) {
      ...MinimalProjectProperties
    }
  }
  ${MinimalProjectPropertiesDoc}
`
export type CreateMonthlyBillingProjectMutationFn = Apollo.MutationFunction<
  CreateMonthlyBillingProjectMutation,
  CreateMonthlyBillingProjectMutationVariables
>

/**
 * __useCreateMonthlyBillingProjectMutation__
 *
 * To run a mutation, you first call `useCreateMonthlyBillingProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonthlyBillingProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonthlyBillingProjectMutation, { data, loading, error }] = useCreateMonthlyBillingProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMonthlyBillingProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMonthlyBillingProjectMutation,
    CreateMonthlyBillingProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMonthlyBillingProjectMutation,
    CreateMonthlyBillingProjectMutationVariables
  >(CreateMonthlyBillingProjectDocument, options)
}
export type CreateMonthlyBillingProjectMutationHookResult = ReturnType<
  typeof useCreateMonthlyBillingProjectMutation
>
export type CreateMonthlyBillingProjectMutationResult =
  Apollo.MutationResult<CreateMonthlyBillingProjectMutation>
export type CreateMonthlyBillingProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateMonthlyBillingProjectMutation,
  CreateMonthlyBillingProjectMutationVariables
>
export const IntegrationProjectsForOnboardingDocument = gql`
  query integrationProjectsForOnboarding($companyIntegrationId: ID!, $includeInactive: Boolean!) {
    integrationProjectsForOnboarding(
      companyIntegrationId: $companyIntegrationId
      includeInactive: $includeInactive
    ) {
      ...IntegrationProjectSummaryProperties
    }
  }
  ${IntegrationProjectSummaryPropertiesDoc}
`

/**
 * __useIntegrationProjectsForOnboardingQuery__
 *
 * To run a query within a React component, call `useIntegrationProjectsForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationProjectsForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationProjectsForOnboardingQuery({
 *   variables: {
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useIntegrationProjectsForOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationProjectsForOnboardingQuery,
    IntegrationProjectsForOnboardingQueryVariables
  > &
    (
      | { variables: IntegrationProjectsForOnboardingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IntegrationProjectsForOnboardingQuery,
    IntegrationProjectsForOnboardingQueryVariables
  >(IntegrationProjectsForOnboardingDocument, options)
}
export function useIntegrationProjectsForOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationProjectsForOnboardingQuery,
    IntegrationProjectsForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IntegrationProjectsForOnboardingQuery,
    IntegrationProjectsForOnboardingQueryVariables
  >(IntegrationProjectsForOnboardingDocument, options)
}
export function useIntegrationProjectsForOnboardingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IntegrationProjectsForOnboardingQuery,
        IntegrationProjectsForOnboardingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    IntegrationProjectsForOnboardingQuery,
    IntegrationProjectsForOnboardingQueryVariables
  >(IntegrationProjectsForOnboardingDocument, options)
}
export type IntegrationProjectsForOnboardingQueryHookResult = ReturnType<
  typeof useIntegrationProjectsForOnboardingQuery
>
export type IntegrationProjectsForOnboardingLazyQueryHookResult = ReturnType<
  typeof useIntegrationProjectsForOnboardingLazyQuery
>
export type IntegrationProjectsForOnboardingSuspenseQueryHookResult = ReturnType<
  typeof useIntegrationProjectsForOnboardingSuspenseQuery
>
export type IntegrationProjectsForOnboardingQueryResult = Apollo.QueryResult<
  IntegrationProjectsForOnboardingQuery,
  IntegrationProjectsForOnboardingQueryVariables
>
export const ReadIntegrationProjectDocument = gql`
  query readIntegrationProject($input: ReadIntegrationProjectInput!) {
    readIntegrationProject(input: $input) {
      ...ImportProjectOnboardingMetadataProperties
    }
  }
  ${ImportProjectOnboardingMetadataPropertiesDoc}
`

/**
 * __useReadIntegrationProjectQuery__
 *
 * To run a query within a React component, call `useReadIntegrationProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadIntegrationProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadIntegrationProjectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadIntegrationProjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadIntegrationProjectQuery,
    ReadIntegrationProjectQueryVariables
  > &
    ({ variables: ReadIntegrationProjectQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReadIntegrationProjectQuery, ReadIntegrationProjectQueryVariables>(
    ReadIntegrationProjectDocument,
    options
  )
}
export function useReadIntegrationProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadIntegrationProjectQuery,
    ReadIntegrationProjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReadIntegrationProjectQuery, ReadIntegrationProjectQueryVariables>(
    ReadIntegrationProjectDocument,
    options
  )
}
export function useReadIntegrationProjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReadIntegrationProjectQuery,
        ReadIntegrationProjectQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReadIntegrationProjectQuery, ReadIntegrationProjectQueryVariables>(
    ReadIntegrationProjectDocument,
    options
  )
}
export type ReadIntegrationProjectQueryHookResult = ReturnType<
  typeof useReadIntegrationProjectQuery
>
export type ReadIntegrationProjectLazyQueryHookResult = ReturnType<
  typeof useReadIntegrationProjectLazyQuery
>
export type ReadIntegrationProjectSuspenseQueryHookResult = ReturnType<
  typeof useReadIntegrationProjectSuspenseQuery
>
export type ReadIntegrationProjectQueryResult = Apollo.QueryResult<
  ReadIntegrationProjectQuery,
  ReadIntegrationProjectQueryVariables
>
export const ImportSovFromIntegrationDocument = gql`
  mutation importSovFromIntegration($input: ImportSovInput!) {
    importSovFromIntegration(input: $input) {
      ...SovProperties
      lineItems {
        ...SovLineItemProperties
        preSitelineRetention
        sovLineItemGroup {
          ...SovLineItemGroupProperties
        }
      }
    }
  }
  ${SovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
`
export type ImportSovFromIntegrationMutationFn = Apollo.MutationFunction<
  ImportSovFromIntegrationMutation,
  ImportSovFromIntegrationMutationVariables
>

/**
 * __useImportSovFromIntegrationMutation__
 *
 * To run a mutation, you first call `useImportSovFromIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSovFromIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSovFromIntegrationMutation, { data, loading, error }] = useImportSovFromIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSovFromIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportSovFromIntegrationMutation,
    ImportSovFromIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ImportSovFromIntegrationMutation,
    ImportSovFromIntegrationMutationVariables
  >(ImportSovFromIntegrationDocument, options)
}
export type ImportSovFromIntegrationMutationHookResult = ReturnType<
  typeof useImportSovFromIntegrationMutation
>
export type ImportSovFromIntegrationMutationResult =
  Apollo.MutationResult<ImportSovFromIntegrationMutation>
export type ImportSovFromIntegrationMutationOptions = Apollo.BaseMutationOptions<
  ImportSovFromIntegrationMutation,
  ImportSovFromIntegrationMutationVariables
>
export const RefreshIntegrationVendorsDocument = gql`
  mutation refreshIntegrationVendors($integrationId: ID!) {
    refreshIntegrationVendors(integrationId: $integrationId) {
      ...MinimalIntegrationProperties
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`
export type RefreshIntegrationVendorsMutationFn = Apollo.MutationFunction<
  RefreshIntegrationVendorsMutation,
  RefreshIntegrationVendorsMutationVariables
>

/**
 * __useRefreshIntegrationVendorsMutation__
 *
 * To run a mutation, you first call `useRefreshIntegrationVendorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshIntegrationVendorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshIntegrationVendorsMutation, { data, loading, error }] = useRefreshIntegrationVendorsMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useRefreshIntegrationVendorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshIntegrationVendorsMutation,
    RefreshIntegrationVendorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefreshIntegrationVendorsMutation,
    RefreshIntegrationVendorsMutationVariables
  >(RefreshIntegrationVendorsDocument, options)
}
export type RefreshIntegrationVendorsMutationHookResult = ReturnType<
  typeof useRefreshIntegrationVendorsMutation
>
export type RefreshIntegrationVendorsMutationResult =
  Apollo.MutationResult<RefreshIntegrationVendorsMutation>
export type RefreshIntegrationVendorsMutationOptions = Apollo.BaseMutationOptions<
  RefreshIntegrationVendorsMutation,
  RefreshIntegrationVendorsMutationVariables
>
export const IntegrationVendorsDocument = gql`
  query integrationVendors($companyIntegrationId: ID!) {
    integrationVendors(companyIntegrationId: $companyIntegrationId) {
      integrationVendorId
      name
      contacts {
        integrationVendorContactId
        name
        title
      }
    }
  }
`

/**
 * __useIntegrationVendorsQuery__
 *
 * To run a query within a React component, call `useIntegrationVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationVendorsQuery({
 *   variables: {
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *   },
 * });
 */
export function useIntegrationVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<IntegrationVendorsQuery, IntegrationVendorsQueryVariables> &
    ({ variables: IntegrationVendorsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IntegrationVendorsQuery, IntegrationVendorsQueryVariables>(
    IntegrationVendorsDocument,
    options
  )
}
export function useIntegrationVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationVendorsQuery,
    IntegrationVendorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IntegrationVendorsQuery, IntegrationVendorsQueryVariables>(
    IntegrationVendorsDocument,
    options
  )
}
export function useIntegrationVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IntegrationVendorsQuery, IntegrationVendorsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IntegrationVendorsQuery, IntegrationVendorsQueryVariables>(
    IntegrationVendorsDocument,
    options
  )
}
export type IntegrationVendorsQueryHookResult = ReturnType<typeof useIntegrationVendorsQuery>
export type IntegrationVendorsLazyQueryHookResult = ReturnType<
  typeof useIntegrationVendorsLazyQuery
>
export type IntegrationVendorsSuspenseQueryHookResult = ReturnType<
  typeof useIntegrationVendorsSuspenseQuery
>
export type IntegrationVendorsQueryResult = Apollo.QueryResult<
  IntegrationVendorsQuery,
  IntegrationVendorsQueryVariables
>
export const ChangeOrderRequestsDocument = gql`
  query changeOrderRequests($contractId: ID!) {
    changeOrderRequests(contractId: $contractId) {
      ...MinimalChangeOrderRequestProperties
      rateTableItems {
        ...ChangeOrderRequestRateTableItemProperties
      }
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
  ${ChangeOrderRequestRateTableItemPropertiesDoc}
`

/**
 * __useChangeOrderRequestsQuery__
 *
 * To run a query within a React component, call `useChangeOrderRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderRequestsQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useChangeOrderRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChangeOrderRequestsQuery,
    ChangeOrderRequestsQueryVariables
  > &
    ({ variables: ChangeOrderRequestsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChangeOrderRequestsQuery, ChangeOrderRequestsQueryVariables>(
    ChangeOrderRequestsDocument,
    options
  )
}
export function useChangeOrderRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChangeOrderRequestsQuery,
    ChangeOrderRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChangeOrderRequestsQuery, ChangeOrderRequestsQueryVariables>(
    ChangeOrderRequestsDocument,
    options
  )
}
export function useChangeOrderRequestsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChangeOrderRequestsQuery, ChangeOrderRequestsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ChangeOrderRequestsQuery, ChangeOrderRequestsQueryVariables>(
    ChangeOrderRequestsDocument,
    options
  )
}
export type ChangeOrderRequestsQueryHookResult = ReturnType<typeof useChangeOrderRequestsQuery>
export type ChangeOrderRequestsLazyQueryHookResult = ReturnType<
  typeof useChangeOrderRequestsLazyQuery
>
export type ChangeOrderRequestsSuspenseQueryHookResult = ReturnType<
  typeof useChangeOrderRequestsSuspenseQuery
>
export type ChangeOrderRequestsQueryResult = Apollo.QueryResult<
  ChangeOrderRequestsQuery,
  ChangeOrderRequestsQueryVariables
>
export const ChangeOrderRequestDocument = gql`
  query changeOrderRequest($id: ID!) {
    changeOrderRequest(id: $id) {
      ...ChangeOrderRequestProperties
      contract {
        id
        billingType
        status
        project {
          id
          name
        }
        changeOrderRequestTemplate {
          id
        }
        rateTable {
          ...MinimalRateTableProperties
        }
        integrations {
          id
          type
        }
        onboardedStatus {
          ...OnboardedProjectContractStatusProperties
        }
      }
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
  ${MinimalRateTablePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`

/**
 * __useChangeOrderRequestQuery__
 *
 * To run a query within a React component, call `useChangeOrderRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeOrderRequestQuery(
  baseOptions: Apollo.QueryHookOptions<ChangeOrderRequestQuery, ChangeOrderRequestQueryVariables> &
    ({ variables: ChangeOrderRequestQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChangeOrderRequestQuery, ChangeOrderRequestQueryVariables>(
    ChangeOrderRequestDocument,
    options
  )
}
export function useChangeOrderRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChangeOrderRequestQuery,
    ChangeOrderRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChangeOrderRequestQuery, ChangeOrderRequestQueryVariables>(
    ChangeOrderRequestDocument,
    options
  )
}
export function useChangeOrderRequestSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChangeOrderRequestQuery, ChangeOrderRequestQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ChangeOrderRequestQuery, ChangeOrderRequestQueryVariables>(
    ChangeOrderRequestDocument,
    options
  )
}
export type ChangeOrderRequestQueryHookResult = ReturnType<typeof useChangeOrderRequestQuery>
export type ChangeOrderRequestLazyQueryHookResult = ReturnType<
  typeof useChangeOrderRequestLazyQuery
>
export type ChangeOrderRequestSuspenseQueryHookResult = ReturnType<
  typeof useChangeOrderRequestSuspenseQuery
>
export type ChangeOrderRequestQueryResult = Apollo.QueryResult<
  ChangeOrderRequestQuery,
  ChangeOrderRequestQueryVariables
>
export const CreateChangeOrderRequestDocument = gql`
  mutation createChangeOrderRequest($input: CreateChangeOrderRequestInput!) {
    createChangeOrderRequest(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type CreateChangeOrderRequestMutationFn = Apollo.MutationFunction<
  CreateChangeOrderRequestMutation,
  CreateChangeOrderRequestMutationVariables
>

/**
 * __useCreateChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useCreateChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeOrderRequestMutation, { data, loading, error }] = useCreateChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChangeOrderRequestMutation,
    CreateChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChangeOrderRequestMutation,
    CreateChangeOrderRequestMutationVariables
  >(CreateChangeOrderRequestDocument, options)
}
export type CreateChangeOrderRequestMutationHookResult = ReturnType<
  typeof useCreateChangeOrderRequestMutation
>
export type CreateChangeOrderRequestMutationResult =
  Apollo.MutationResult<CreateChangeOrderRequestMutation>
export type CreateChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateChangeOrderRequestMutation,
  CreateChangeOrderRequestMutationVariables
>
export const CreateChangeOrderRequestsFromLogDocument = gql`
  mutation createChangeOrderRequestsFromLog($input: CreateChangeOrderRequestsInput!) {
    createChangeOrderRequestsFromLog(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type CreateChangeOrderRequestsFromLogMutationFn = Apollo.MutationFunction<
  CreateChangeOrderRequestsFromLogMutation,
  CreateChangeOrderRequestsFromLogMutationVariables
>

/**
 * __useCreateChangeOrderRequestsFromLogMutation__
 *
 * To run a mutation, you first call `useCreateChangeOrderRequestsFromLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeOrderRequestsFromLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeOrderRequestsFromLogMutation, { data, loading, error }] = useCreateChangeOrderRequestsFromLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChangeOrderRequestsFromLogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChangeOrderRequestsFromLogMutation,
    CreateChangeOrderRequestsFromLogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChangeOrderRequestsFromLogMutation,
    CreateChangeOrderRequestsFromLogMutationVariables
  >(CreateChangeOrderRequestsFromLogDocument, options)
}
export type CreateChangeOrderRequestsFromLogMutationHookResult = ReturnType<
  typeof useCreateChangeOrderRequestsFromLogMutation
>
export type CreateChangeOrderRequestsFromLogMutationResult =
  Apollo.MutationResult<CreateChangeOrderRequestsFromLogMutation>
export type CreateChangeOrderRequestsFromLogMutationOptions = Apollo.BaseMutationOptions<
  CreateChangeOrderRequestsFromLogMutation,
  CreateChangeOrderRequestsFromLogMutationVariables
>
export const UpdateChangeOrderRequestDocument = gql`
  mutation updateChangeOrderRequest($input: UpdateChangeOrderRequestInput!) {
    updateChangeOrderRequest(input: $input) {
      ...ChangeOrderRequestProperties
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type UpdateChangeOrderRequestMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderRequestMutation,
  UpdateChangeOrderRequestMutationVariables
>

/**
 * __useUpdateChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderRequestMutation, { data, loading, error }] = useUpdateChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderRequestMutation,
    UpdateChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateChangeOrderRequestMutation,
    UpdateChangeOrderRequestMutationVariables
  >(UpdateChangeOrderRequestDocument, options)
}
export type UpdateChangeOrderRequestMutationHookResult = ReturnType<
  typeof useUpdateChangeOrderRequestMutation
>
export type UpdateChangeOrderRequestMutationResult =
  Apollo.MutationResult<UpdateChangeOrderRequestMutation>
export type UpdateChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeOrderRequestMutation,
  UpdateChangeOrderRequestMutationVariables
>
export const DeleteChangeOrderRequestDocument = gql`
  mutation deleteChangeOrderRequest($id: ID!) {
    deleteChangeOrderRequest(id: $id) {
      id
    }
  }
`
export type DeleteChangeOrderRequestMutationFn = Apollo.MutationFunction<
  DeleteChangeOrderRequestMutation,
  DeleteChangeOrderRequestMutationVariables
>

/**
 * __useDeleteChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useDeleteChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChangeOrderRequestMutation, { data, loading, error }] = useDeleteChangeOrderRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChangeOrderRequestMutation,
    DeleteChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteChangeOrderRequestMutation,
    DeleteChangeOrderRequestMutationVariables
  >(DeleteChangeOrderRequestDocument, options)
}
export type DeleteChangeOrderRequestMutationHookResult = ReturnType<
  typeof useDeleteChangeOrderRequestMutation
>
export type DeleteChangeOrderRequestMutationResult =
  Apollo.MutationResult<DeleteChangeOrderRequestMutation>
export type DeleteChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  DeleteChangeOrderRequestMutation,
  DeleteChangeOrderRequestMutationVariables
>
export const CreateChangeOrderRequestAttachmentDocument = gql`
  mutation createChangeOrderRequestAttachment($input: CreateChangeOrderRequestAttachmentInput!) {
    createChangeOrderRequestAttachment(input: $input) {
      ...ChangeOrderRequestAttachmentProperties
    }
  }
  ${ChangeOrderRequestAttachmentPropertiesDoc}
`
export type CreateChangeOrderRequestAttachmentMutationFn = Apollo.MutationFunction<
  CreateChangeOrderRequestAttachmentMutation,
  CreateChangeOrderRequestAttachmentMutationVariables
>

/**
 * __useCreateChangeOrderRequestAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateChangeOrderRequestAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeOrderRequestAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeOrderRequestAttachmentMutation, { data, loading, error }] = useCreateChangeOrderRequestAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChangeOrderRequestAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChangeOrderRequestAttachmentMutation,
    CreateChangeOrderRequestAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChangeOrderRequestAttachmentMutation,
    CreateChangeOrderRequestAttachmentMutationVariables
  >(CreateChangeOrderRequestAttachmentDocument, options)
}
export type CreateChangeOrderRequestAttachmentMutationHookResult = ReturnType<
  typeof useCreateChangeOrderRequestAttachmentMutation
>
export type CreateChangeOrderRequestAttachmentMutationResult =
  Apollo.MutationResult<CreateChangeOrderRequestAttachmentMutation>
export type CreateChangeOrderRequestAttachmentMutationOptions = Apollo.BaseMutationOptions<
  CreateChangeOrderRequestAttachmentMutation,
  CreateChangeOrderRequestAttachmentMutationVariables
>
export const UpdateChangeOrderRequestAttachmentDocument = gql`
  mutation updateChangeOrderRequestAttachment($input: UpdateChangeOrderRequestAttachmentInput!) {
    updateChangeOrderRequestAttachment(input: $input) {
      ...ChangeOrderRequestAttachmentProperties
    }
  }
  ${ChangeOrderRequestAttachmentPropertiesDoc}
`
export type UpdateChangeOrderRequestAttachmentMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderRequestAttachmentMutation,
  UpdateChangeOrderRequestAttachmentMutationVariables
>

/**
 * __useUpdateChangeOrderRequestAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderRequestAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderRequestAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderRequestAttachmentMutation, { data, loading, error }] = useUpdateChangeOrderRequestAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderRequestAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderRequestAttachmentMutation,
    UpdateChangeOrderRequestAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateChangeOrderRequestAttachmentMutation,
    UpdateChangeOrderRequestAttachmentMutationVariables
  >(UpdateChangeOrderRequestAttachmentDocument, options)
}
export type UpdateChangeOrderRequestAttachmentMutationHookResult = ReturnType<
  typeof useUpdateChangeOrderRequestAttachmentMutation
>
export type UpdateChangeOrderRequestAttachmentMutationResult =
  Apollo.MutationResult<UpdateChangeOrderRequestAttachmentMutation>
export type UpdateChangeOrderRequestAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeOrderRequestAttachmentMutation,
  UpdateChangeOrderRequestAttachmentMutationVariables
>
export const DeleteChangeOrderRequestAttachmentDocument = gql`
  mutation deleteChangeOrderRequestAttachment($id: ID!) {
    deleteChangeOrderRequestAttachment(id: $id) {
      id
    }
  }
`
export type DeleteChangeOrderRequestAttachmentMutationFn = Apollo.MutationFunction<
  DeleteChangeOrderRequestAttachmentMutation,
  DeleteChangeOrderRequestAttachmentMutationVariables
>

/**
 * __useDeleteChangeOrderRequestAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteChangeOrderRequestAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChangeOrderRequestAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChangeOrderRequestAttachmentMutation, { data, loading, error }] = useDeleteChangeOrderRequestAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChangeOrderRequestAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChangeOrderRequestAttachmentMutation,
    DeleteChangeOrderRequestAttachmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteChangeOrderRequestAttachmentMutation,
    DeleteChangeOrderRequestAttachmentMutationVariables
  >(DeleteChangeOrderRequestAttachmentDocument, options)
}
export type DeleteChangeOrderRequestAttachmentMutationHookResult = ReturnType<
  typeof useDeleteChangeOrderRequestAttachmentMutation
>
export type DeleteChangeOrderRequestAttachmentMutationResult =
  Apollo.MutationResult<DeleteChangeOrderRequestAttachmentMutation>
export type DeleteChangeOrderRequestAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteChangeOrderRequestAttachmentMutation,
  DeleteChangeOrderRequestAttachmentMutationVariables
>
export const ApproveChangeOrderRequestDocument = gql`
  mutation approveChangeOrderRequest($input: ApproveChangeOrderRequestInput!) {
    approveChangeOrderRequest(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type ApproveChangeOrderRequestMutationFn = Apollo.MutationFunction<
  ApproveChangeOrderRequestMutation,
  ApproveChangeOrderRequestMutationVariables
>

/**
 * __useApproveChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useApproveChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveChangeOrderRequestMutation, { data, loading, error }] = useApproveChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveChangeOrderRequestMutation,
    ApproveChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApproveChangeOrderRequestMutation,
    ApproveChangeOrderRequestMutationVariables
  >(ApproveChangeOrderRequestDocument, options)
}
export type ApproveChangeOrderRequestMutationHookResult = ReturnType<
  typeof useApproveChangeOrderRequestMutation
>
export type ApproveChangeOrderRequestMutationResult =
  Apollo.MutationResult<ApproveChangeOrderRequestMutation>
export type ApproveChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveChangeOrderRequestMutation,
  ApproveChangeOrderRequestMutationVariables
>
export const MarkChangeOrderRequestSubmittedDocument = gql`
  mutation markChangeOrderRequestSubmitted($input: MarkChangeOrderRequestSubmittedInput!) {
    markChangeOrderRequestSubmitted(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type MarkChangeOrderRequestSubmittedMutationFn = Apollo.MutationFunction<
  MarkChangeOrderRequestSubmittedMutation,
  MarkChangeOrderRequestSubmittedMutationVariables
>

/**
 * __useMarkChangeOrderRequestSubmittedMutation__
 *
 * To run a mutation, you first call `useMarkChangeOrderRequestSubmittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChangeOrderRequestSubmittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChangeOrderRequestSubmittedMutation, { data, loading, error }] = useMarkChangeOrderRequestSubmittedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkChangeOrderRequestSubmittedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkChangeOrderRequestSubmittedMutation,
    MarkChangeOrderRequestSubmittedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkChangeOrderRequestSubmittedMutation,
    MarkChangeOrderRequestSubmittedMutationVariables
  >(MarkChangeOrderRequestSubmittedDocument, options)
}
export type MarkChangeOrderRequestSubmittedMutationHookResult = ReturnType<
  typeof useMarkChangeOrderRequestSubmittedMutation
>
export type MarkChangeOrderRequestSubmittedMutationResult =
  Apollo.MutationResult<MarkChangeOrderRequestSubmittedMutation>
export type MarkChangeOrderRequestSubmittedMutationOptions = Apollo.BaseMutationOptions<
  MarkChangeOrderRequestSubmittedMutation,
  MarkChangeOrderRequestSubmittedMutationVariables
>
export const MarkChangeOrderRequestAuthorizedToProceedDocument = gql`
  mutation markChangeOrderRequestAuthorizedToProceed(
    $input: MarkChangeOrderRequestAuthorizedToProceedInput!
  ) {
    markChangeOrderRequestAuthorizedToProceed(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type MarkChangeOrderRequestAuthorizedToProceedMutationFn = Apollo.MutationFunction<
  MarkChangeOrderRequestAuthorizedToProceedMutation,
  MarkChangeOrderRequestAuthorizedToProceedMutationVariables
>

/**
 * __useMarkChangeOrderRequestAuthorizedToProceedMutation__
 *
 * To run a mutation, you first call `useMarkChangeOrderRequestAuthorizedToProceedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkChangeOrderRequestAuthorizedToProceedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markChangeOrderRequestAuthorizedToProceedMutation, { data, loading, error }] = useMarkChangeOrderRequestAuthorizedToProceedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkChangeOrderRequestAuthorizedToProceedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkChangeOrderRequestAuthorizedToProceedMutation,
    MarkChangeOrderRequestAuthorizedToProceedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkChangeOrderRequestAuthorizedToProceedMutation,
    MarkChangeOrderRequestAuthorizedToProceedMutationVariables
  >(MarkChangeOrderRequestAuthorizedToProceedDocument, options)
}
export type MarkChangeOrderRequestAuthorizedToProceedMutationHookResult = ReturnType<
  typeof useMarkChangeOrderRequestAuthorizedToProceedMutation
>
export type MarkChangeOrderRequestAuthorizedToProceedMutationResult =
  Apollo.MutationResult<MarkChangeOrderRequestAuthorizedToProceedMutation>
export type MarkChangeOrderRequestAuthorizedToProceedMutationOptions = Apollo.BaseMutationOptions<
  MarkChangeOrderRequestAuthorizedToProceedMutation,
  MarkChangeOrderRequestAuthorizedToProceedMutationVariables
>
export const LinkChangeOrderRequestToSovDocument = gql`
  mutation linkChangeOrderRequestToSov($input: LinkChangeOrderRequestToSovInput!) {
    linkChangeOrderRequestToSov(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type LinkChangeOrderRequestToSovMutationFn = Apollo.MutationFunction<
  LinkChangeOrderRequestToSovMutation,
  LinkChangeOrderRequestToSovMutationVariables
>

/**
 * __useLinkChangeOrderRequestToSovMutation__
 *
 * To run a mutation, you first call `useLinkChangeOrderRequestToSovMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkChangeOrderRequestToSovMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkChangeOrderRequestToSovMutation, { data, loading, error }] = useLinkChangeOrderRequestToSovMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkChangeOrderRequestToSovMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkChangeOrderRequestToSovMutation,
    LinkChangeOrderRequestToSovMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkChangeOrderRequestToSovMutation,
    LinkChangeOrderRequestToSovMutationVariables
  >(LinkChangeOrderRequestToSovDocument, options)
}
export type LinkChangeOrderRequestToSovMutationHookResult = ReturnType<
  typeof useLinkChangeOrderRequestToSovMutation
>
export type LinkChangeOrderRequestToSovMutationResult =
  Apollo.MutationResult<LinkChangeOrderRequestToSovMutation>
export type LinkChangeOrderRequestToSovMutationOptions = Apollo.BaseMutationOptions<
  LinkChangeOrderRequestToSovMutation,
  LinkChangeOrderRequestToSovMutationVariables
>
export const RejectChangeOrderRequestDocument = gql`
  mutation rejectChangeOrderRequest($id: ID!) {
    rejectChangeOrderRequest(id: $id) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type RejectChangeOrderRequestMutationFn = Apollo.MutationFunction<
  RejectChangeOrderRequestMutation,
  RejectChangeOrderRequestMutationVariables
>

/**
 * __useRejectChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useRejectChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectChangeOrderRequestMutation, { data, loading, error }] = useRejectChangeOrderRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectChangeOrderRequestMutation,
    RejectChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RejectChangeOrderRequestMutation,
    RejectChangeOrderRequestMutationVariables
  >(RejectChangeOrderRequestDocument, options)
}
export type RejectChangeOrderRequestMutationHookResult = ReturnType<
  typeof useRejectChangeOrderRequestMutation
>
export type RejectChangeOrderRequestMutationResult =
  Apollo.MutationResult<RejectChangeOrderRequestMutation>
export type RejectChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  RejectChangeOrderRequestMutation,
  RejectChangeOrderRequestMutationVariables
>
export const ResetChangeOrderRequestToDraftDocument = gql`
  mutation resetChangeOrderRequestToDraft($input: ResetChangeOrderRequestToDraftInput!) {
    resetChangeOrderRequestToDraft(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type ResetChangeOrderRequestToDraftMutationFn = Apollo.MutationFunction<
  ResetChangeOrderRequestToDraftMutation,
  ResetChangeOrderRequestToDraftMutationVariables
>

/**
 * __useResetChangeOrderRequestToDraftMutation__
 *
 * To run a mutation, you first call `useResetChangeOrderRequestToDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChangeOrderRequestToDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChangeOrderRequestToDraftMutation, { data, loading, error }] = useResetChangeOrderRequestToDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetChangeOrderRequestToDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetChangeOrderRequestToDraftMutation,
    ResetChangeOrderRequestToDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetChangeOrderRequestToDraftMutation,
    ResetChangeOrderRequestToDraftMutationVariables
  >(ResetChangeOrderRequestToDraftDocument, options)
}
export type ResetChangeOrderRequestToDraftMutationHookResult = ReturnType<
  typeof useResetChangeOrderRequestToDraftMutation
>
export type ResetChangeOrderRequestToDraftMutationResult =
  Apollo.MutationResult<ResetChangeOrderRequestToDraftMutation>
export type ResetChangeOrderRequestToDraftMutationOptions = Apollo.BaseMutationOptions<
  ResetChangeOrderRequestToDraftMutation,
  ResetChangeOrderRequestToDraftMutationVariables
>
export const AddChangeOrderRequestToSovDocument = gql`
  mutation addChangeOrderRequestToSov($input: AddChangeOrderRequestToSovInput!) {
    addChangeOrderRequestToSov(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type AddChangeOrderRequestToSovMutationFn = Apollo.MutationFunction<
  AddChangeOrderRequestToSovMutation,
  AddChangeOrderRequestToSovMutationVariables
>

/**
 * __useAddChangeOrderRequestToSovMutation__
 *
 * To run a mutation, you first call `useAddChangeOrderRequestToSovMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChangeOrderRequestToSovMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChangeOrderRequestToSovMutation, { data, loading, error }] = useAddChangeOrderRequestToSovMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChangeOrderRequestToSovMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddChangeOrderRequestToSovMutation,
    AddChangeOrderRequestToSovMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddChangeOrderRequestToSovMutation,
    AddChangeOrderRequestToSovMutationVariables
  >(AddChangeOrderRequestToSovDocument, options)
}
export type AddChangeOrderRequestToSovMutationHookResult = ReturnType<
  typeof useAddChangeOrderRequestToSovMutation
>
export type AddChangeOrderRequestToSovMutationResult =
  Apollo.MutationResult<AddChangeOrderRequestToSovMutation>
export type AddChangeOrderRequestToSovMutationOptions = Apollo.BaseMutationOptions<
  AddChangeOrderRequestToSovMutation,
  AddChangeOrderRequestToSovMutationVariables
>
export const ClearChangeOrderRequestPricingDocument = gql`
  mutation clearChangeOrderRequestPricing($input: ClearChangeOrderRequestPricingInput!) {
    clearChangeOrderRequestPricing(input: $input) {
      ...ChangeOrderRequestProperties
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type ClearChangeOrderRequestPricingMutationFn = Apollo.MutationFunction<
  ClearChangeOrderRequestPricingMutation,
  ClearChangeOrderRequestPricingMutationVariables
>

/**
 * __useClearChangeOrderRequestPricingMutation__
 *
 * To run a mutation, you first call `useClearChangeOrderRequestPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearChangeOrderRequestPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearChangeOrderRequestPricingMutation, { data, loading, error }] = useClearChangeOrderRequestPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearChangeOrderRequestPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearChangeOrderRequestPricingMutation,
    ClearChangeOrderRequestPricingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearChangeOrderRequestPricingMutation,
    ClearChangeOrderRequestPricingMutationVariables
  >(ClearChangeOrderRequestPricingDocument, options)
}
export type ClearChangeOrderRequestPricingMutationHookResult = ReturnType<
  typeof useClearChangeOrderRequestPricingMutation
>
export type ClearChangeOrderRequestPricingMutationResult =
  Apollo.MutationResult<ClearChangeOrderRequestPricingMutation>
export type ClearChangeOrderRequestPricingMutationOptions = Apollo.BaseMutationOptions<
  ClearChangeOrderRequestPricingMutation,
  ClearChangeOrderRequestPricingMutationVariables
>
export const SignChangeOrderRequestDocument = gql`
  mutation signChangeOrderRequest($input: SignChangeOrderRequestInput!) {
    signChangeOrderRequest(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type SignChangeOrderRequestMutationFn = Apollo.MutationFunction<
  SignChangeOrderRequestMutation,
  SignChangeOrderRequestMutationVariables
>

/**
 * __useSignChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useSignChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signChangeOrderRequestMutation, { data, loading, error }] = useSignChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignChangeOrderRequestMutation,
    SignChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignChangeOrderRequestMutation,
    SignChangeOrderRequestMutationVariables
  >(SignChangeOrderRequestDocument, options)
}
export type SignChangeOrderRequestMutationHookResult = ReturnType<
  typeof useSignChangeOrderRequestMutation
>
export type SignChangeOrderRequestMutationResult =
  Apollo.MutationResult<SignChangeOrderRequestMutation>
export type SignChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  SignChangeOrderRequestMutation,
  SignChangeOrderRequestMutationVariables
>
export const SubmitChangeOrderRequestDocument = gql`
  mutation submitChangeOrderRequest($input: SubmitChangeOrderRequestInput!) {
    submitChangeOrderRequest(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type SubmitChangeOrderRequestMutationFn = Apollo.MutationFunction<
  SubmitChangeOrderRequestMutation,
  SubmitChangeOrderRequestMutationVariables
>

/**
 * __useSubmitChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useSubmitChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChangeOrderRequestMutation, { data, loading, error }] = useSubmitChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitChangeOrderRequestMutation,
    SubmitChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitChangeOrderRequestMutation,
    SubmitChangeOrderRequestMutationVariables
  >(SubmitChangeOrderRequestDocument, options)
}
export type SubmitChangeOrderRequestMutationHookResult = ReturnType<
  typeof useSubmitChangeOrderRequestMutation
>
export type SubmitChangeOrderRequestMutationResult =
  Apollo.MutationResult<SubmitChangeOrderRequestMutation>
export type SubmitChangeOrderRequestMutationOptions = Apollo.BaseMutationOptions<
  SubmitChangeOrderRequestMutation,
  SubmitChangeOrderRequestMutationVariables
>
export const AddGeneralContractorContactToChangeOrderRequestDocument = gql`
  mutation addGeneralContractorContactToChangeOrderRequest(
    $input: GeneralContractorChangeOrderRequestContactInput!
  ) {
    addGeneralContractorContactToChangeOrderRequest(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type AddGeneralContractorContactToChangeOrderRequestMutationFn = Apollo.MutationFunction<
  AddGeneralContractorContactToChangeOrderRequestMutation,
  AddGeneralContractorContactToChangeOrderRequestMutationVariables
>

/**
 * __useAddGeneralContractorContactToChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useAddGeneralContractorContactToChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGeneralContractorContactToChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGeneralContractorContactToChangeOrderRequestMutation, { data, loading, error }] = useAddGeneralContractorContactToChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGeneralContractorContactToChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGeneralContractorContactToChangeOrderRequestMutation,
    AddGeneralContractorContactToChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddGeneralContractorContactToChangeOrderRequestMutation,
    AddGeneralContractorContactToChangeOrderRequestMutationVariables
  >(AddGeneralContractorContactToChangeOrderRequestDocument, options)
}
export type AddGeneralContractorContactToChangeOrderRequestMutationHookResult = ReturnType<
  typeof useAddGeneralContractorContactToChangeOrderRequestMutation
>
export type AddGeneralContractorContactToChangeOrderRequestMutationResult =
  Apollo.MutationResult<AddGeneralContractorContactToChangeOrderRequestMutation>
export type AddGeneralContractorContactToChangeOrderRequestMutationOptions =
  Apollo.BaseMutationOptions<
    AddGeneralContractorContactToChangeOrderRequestMutation,
    AddGeneralContractorContactToChangeOrderRequestMutationVariables
  >
export const DeleteGeneralContractorContactFromChangeOrderRequestDocument = gql`
  mutation deleteGeneralContractorContactFromChangeOrderRequest(
    $input: GeneralContractorChangeOrderRequestContactInput!
  ) {
    deleteGeneralContractorContactFromChangeOrderRequest(input: $input) {
      ...MinimalChangeOrderRequestProperties
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
`
export type DeleteGeneralContractorContactFromChangeOrderRequestMutationFn =
  Apollo.MutationFunction<
    DeleteGeneralContractorContactFromChangeOrderRequestMutation,
    DeleteGeneralContractorContactFromChangeOrderRequestMutationVariables
  >

/**
 * __useDeleteGeneralContractorContactFromChangeOrderRequestMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralContractorContactFromChangeOrderRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralContractorContactFromChangeOrderRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralContractorContactFromChangeOrderRequestMutation, { data, loading, error }] = useDeleteGeneralContractorContactFromChangeOrderRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGeneralContractorContactFromChangeOrderRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGeneralContractorContactFromChangeOrderRequestMutation,
    DeleteGeneralContractorContactFromChangeOrderRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteGeneralContractorContactFromChangeOrderRequestMutation,
    DeleteGeneralContractorContactFromChangeOrderRequestMutationVariables
  >(DeleteGeneralContractorContactFromChangeOrderRequestDocument, options)
}
export type DeleteGeneralContractorContactFromChangeOrderRequestMutationHookResult = ReturnType<
  typeof useDeleteGeneralContractorContactFromChangeOrderRequestMutation
>
export type DeleteGeneralContractorContactFromChangeOrderRequestMutationResult =
  Apollo.MutationResult<DeleteGeneralContractorContactFromChangeOrderRequestMutation>
export type DeleteGeneralContractorContactFromChangeOrderRequestMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteGeneralContractorContactFromChangeOrderRequestMutation,
    DeleteGeneralContractorContactFromChangeOrderRequestMutationVariables
  >
export const UploadChangeOrderScoFileDocument = gql`
  mutation uploadChangeOrderScoFile($input: UploadChangeOrderScoFileInput!) {
    uploadChangeOrderScoFile(input: $input) {
      ...ChangeOrderRequestProperties
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type UploadChangeOrderScoFileMutationFn = Apollo.MutationFunction<
  UploadChangeOrderScoFileMutation,
  UploadChangeOrderScoFileMutationVariables
>

/**
 * __useUploadChangeOrderScoFileMutation__
 *
 * To run a mutation, you first call `useUploadChangeOrderScoFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadChangeOrderScoFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadChangeOrderScoFileMutation, { data, loading, error }] = useUploadChangeOrderScoFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadChangeOrderScoFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadChangeOrderScoFileMutation,
    UploadChangeOrderScoFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadChangeOrderScoFileMutation,
    UploadChangeOrderScoFileMutationVariables
  >(UploadChangeOrderScoFileDocument, options)
}
export type UploadChangeOrderScoFileMutationHookResult = ReturnType<
  typeof useUploadChangeOrderScoFileMutation
>
export type UploadChangeOrderScoFileMutationResult =
  Apollo.MutationResult<UploadChangeOrderScoFileMutation>
export type UploadChangeOrderScoFileMutationOptions = Apollo.BaseMutationOptions<
  UploadChangeOrderScoFileMutation,
  UploadChangeOrderScoFileMutationVariables
>
export const RequestChangeOrderReviewDocument = gql`
  mutation requestChangeOrderReview($input: RequestChangeOrderReviewInput!) {
    requestChangeOrderReview(input: $input) {
      ...ChangeOrderRequestProperties
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type RequestChangeOrderReviewMutationFn = Apollo.MutationFunction<
  RequestChangeOrderReviewMutation,
  RequestChangeOrderReviewMutationVariables
>

/**
 * __useRequestChangeOrderReviewMutation__
 *
 * To run a mutation, you first call `useRequestChangeOrderReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangeOrderReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangeOrderReviewMutation, { data, loading, error }] = useRequestChangeOrderReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestChangeOrderReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestChangeOrderReviewMutation,
    RequestChangeOrderReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestChangeOrderReviewMutation,
    RequestChangeOrderReviewMutationVariables
  >(RequestChangeOrderReviewDocument, options)
}
export type RequestChangeOrderReviewMutationHookResult = ReturnType<
  typeof useRequestChangeOrderReviewMutation
>
export type RequestChangeOrderReviewMutationResult =
  Apollo.MutationResult<RequestChangeOrderReviewMutation>
export type RequestChangeOrderReviewMutationOptions = Apollo.BaseMutationOptions<
  RequestChangeOrderReviewMutation,
  RequestChangeOrderReviewMutationVariables
>
export const UserEducationCampaignDocument = gql`
  query userEducationCampaign {
    userEducationCampaign {
      ...UserEducationCampaignProperties
    }
  }
  ${UserEducationCampaignPropertiesDoc}
`

/**
 * __useUserEducationCampaignQuery__
 *
 * To run a query within a React component, call `useUserEducationCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEducationCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEducationCampaignQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEducationCampaignQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserEducationCampaignQuery,
    UserEducationCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserEducationCampaignQuery, UserEducationCampaignQueryVariables>(
    UserEducationCampaignDocument,
    options
  )
}
export function useUserEducationCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserEducationCampaignQuery,
    UserEducationCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserEducationCampaignQuery, UserEducationCampaignQueryVariables>(
    UserEducationCampaignDocument,
    options
  )
}
export function useUserEducationCampaignSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserEducationCampaignQuery,
        UserEducationCampaignQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UserEducationCampaignQuery, UserEducationCampaignQueryVariables>(
    UserEducationCampaignDocument,
    options
  )
}
export type UserEducationCampaignQueryHookResult = ReturnType<typeof useUserEducationCampaignQuery>
export type UserEducationCampaignLazyQueryHookResult = ReturnType<
  typeof useUserEducationCampaignLazyQuery
>
export type UserEducationCampaignSuspenseQueryHookResult = ReturnType<
  typeof useUserEducationCampaignSuspenseQuery
>
export type UserEducationCampaignQueryResult = Apollo.QueryResult<
  UserEducationCampaignQuery,
  UserEducationCampaignQueryVariables
>
export const DismissUserEducationCampaignDocument = gql`
  mutation dismissUserEducationCampaign($id: ID!) {
    dismissUserEducationCampaign(id: $id) {
      ...UserEducationCampaignProperties
    }
  }
  ${UserEducationCampaignPropertiesDoc}
`
export type DismissUserEducationCampaignMutationFn = Apollo.MutationFunction<
  DismissUserEducationCampaignMutation,
  DismissUserEducationCampaignMutationVariables
>

/**
 * __useDismissUserEducationCampaignMutation__
 *
 * To run a mutation, you first call `useDismissUserEducationCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissUserEducationCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissUserEducationCampaignMutation, { data, loading, error }] = useDismissUserEducationCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissUserEducationCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissUserEducationCampaignMutation,
    DismissUserEducationCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DismissUserEducationCampaignMutation,
    DismissUserEducationCampaignMutationVariables
  >(DismissUserEducationCampaignDocument, options)
}
export type DismissUserEducationCampaignMutationHookResult = ReturnType<
  typeof useDismissUserEducationCampaignMutation
>
export type DismissUserEducationCampaignMutationResult =
  Apollo.MutationResult<DismissUserEducationCampaignMutation>
export type DismissUserEducationCampaignMutationOptions = Apollo.BaseMutationOptions<
  DismissUserEducationCampaignMutation,
  DismissUserEducationCampaignMutationVariables
>
export const UpdateVendorContractPreliminaryNoticesDocument = gql`
  mutation updateVendorContractPreliminaryNotices(
    $input: UpdateVendorContractPreliminaryNoticesInput!
  ) {
    updateVendorContractPreliminaryNotices(input: $input) {
      id
      preliminaryNotices {
        ...VendorPreliminaryNoticeProperties
      }
    }
  }
  ${VendorPreliminaryNoticePropertiesDoc}
`
export type UpdateVendorContractPreliminaryNoticesMutationFn = Apollo.MutationFunction<
  UpdateVendorContractPreliminaryNoticesMutation,
  UpdateVendorContractPreliminaryNoticesMutationVariables
>

/**
 * __useUpdateVendorContractPreliminaryNoticesMutation__
 *
 * To run a mutation, you first call `useUpdateVendorContractPreliminaryNoticesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorContractPreliminaryNoticesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorContractPreliminaryNoticesMutation, { data, loading, error }] = useUpdateVendorContractPreliminaryNoticesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorContractPreliminaryNoticesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorContractPreliminaryNoticesMutation,
    UpdateVendorContractPreliminaryNoticesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVendorContractPreliminaryNoticesMutation,
    UpdateVendorContractPreliminaryNoticesMutationVariables
  >(UpdateVendorContractPreliminaryNoticesDocument, options)
}
export type UpdateVendorContractPreliminaryNoticesMutationHookResult = ReturnType<
  typeof useUpdateVendorContractPreliminaryNoticesMutation
>
export type UpdateVendorContractPreliminaryNoticesMutationResult =
  Apollo.MutationResult<UpdateVendorContractPreliminaryNoticesMutation>
export type UpdateVendorContractPreliminaryNoticesMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorContractPreliminaryNoticesMutation,
  UpdateVendorContractPreliminaryNoticesMutationVariables
>
export const UpdateRoundRetentionDocument = gql`
  mutation updateRoundRetention($input: UpdateRoundRetentionInput!) {
    updateRoundRetention(input: $input) {
      ...MinimalContractProperties
    }
  }
  ${MinimalContractPropertiesDoc}
`
export type UpdateRoundRetentionMutationFn = Apollo.MutationFunction<
  UpdateRoundRetentionMutation,
  UpdateRoundRetentionMutationVariables
>

/**
 * __useUpdateRoundRetentionMutation__
 *
 * To run a mutation, you first call `useUpdateRoundRetentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoundRetentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoundRetentionMutation, { data, loading, error }] = useUpdateRoundRetentionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoundRetentionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoundRetentionMutation,
    UpdateRoundRetentionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRoundRetentionMutation, UpdateRoundRetentionMutationVariables>(
    UpdateRoundRetentionDocument,
    options
  )
}
export type UpdateRoundRetentionMutationHookResult = ReturnType<
  typeof useUpdateRoundRetentionMutation
>
export type UpdateRoundRetentionMutationResult = Apollo.MutationResult<UpdateRoundRetentionMutation>
export type UpdateRoundRetentionMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoundRetentionMutation,
  UpdateRoundRetentionMutationVariables
>
export const MarkVendorLienWaiverSubmittedDocument = gql`
  mutation markVendorLienWaiverSubmitted($id: ID!) {
    markVendorLienWaiverSubmitted(id: $id) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type MarkVendorLienWaiverSubmittedMutationFn = Apollo.MutationFunction<
  MarkVendorLienWaiverSubmittedMutation,
  MarkVendorLienWaiverSubmittedMutationVariables
>

/**
 * __useMarkVendorLienWaiverSubmittedMutation__
 *
 * To run a mutation, you first call `useMarkVendorLienWaiverSubmittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkVendorLienWaiverSubmittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markVendorLienWaiverSubmittedMutation, { data, loading, error }] = useMarkVendorLienWaiverSubmittedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkVendorLienWaiverSubmittedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkVendorLienWaiverSubmittedMutation,
    MarkVendorLienWaiverSubmittedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkVendorLienWaiverSubmittedMutation,
    MarkVendorLienWaiverSubmittedMutationVariables
  >(MarkVendorLienWaiverSubmittedDocument, options)
}
export type MarkVendorLienWaiverSubmittedMutationHookResult = ReturnType<
  typeof useMarkVendorLienWaiverSubmittedMutation
>
export type MarkVendorLienWaiverSubmittedMutationResult =
  Apollo.MutationResult<MarkVendorLienWaiverSubmittedMutation>
export type MarkVendorLienWaiverSubmittedMutationOptions = Apollo.BaseMutationOptions<
  MarkVendorLienWaiverSubmittedMutation,
  MarkVendorLienWaiverSubmittedMutationVariables
>
export const GetContractForEditingSovDocument = gql`
  query getContractForEditingSov($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      retentionTrackingLevel
      defaultRetentionPercent
      pastPayAppCount
      preSitelineRetentionHeldOverride
      preSitelineRetention
      roundRetention
      timeZone
      taxCalculationType
      defaultTaxGroup {
        ...TaxGroupProperties
      }
      company {
        id
        taxGroups {
          ...TaxGroupProperties
        }
      }
      sov {
        ...SovProperties
        lineItems {
          ...SovLineItemWithTotalsProperties
          sovLineItemGroup {
            ...SovLineItemGroupProperties
          }
          changeOrderRequests {
            id
            internalNumber
            name
          }
        }
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      integrations {
        ...MinimalIntegrationProperties
      }
      payApps {
        id
        status
        billingEnd
        retentionOnly
        progress {
          id
          progressRetentionHeldPercent
          sovLineItem {
            id
          }
        }
      }
      project {
        id
        name
      }
    }
  }
  ${TaxGroupPropertiesDoc}
  ${SovPropertiesDoc}
  ${SovLineItemWithTotalsPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useGetContractForEditingSovQuery__
 *
 * To run a query within a React component, call `useGetContractForEditingSovQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForEditingSovQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForEditingSovQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForEditingSovQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForEditingSovQuery,
    GetContractForEditingSovQueryVariables
  > &
    ({ variables: GetContractForEditingSovQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForEditingSovQuery, GetContractForEditingSovQueryVariables>(
    GetContractForEditingSovDocument,
    options
  )
}
export function useGetContractForEditingSovLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForEditingSovQuery,
    GetContractForEditingSovQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractForEditingSovQuery, GetContractForEditingSovQueryVariables>(
    GetContractForEditingSovDocument,
    options
  )
}
export function useGetContractForEditingSovSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForEditingSovQuery,
        GetContractForEditingSovQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForEditingSovQuery,
    GetContractForEditingSovQueryVariables
  >(GetContractForEditingSovDocument, options)
}
export type GetContractForEditingSovQueryHookResult = ReturnType<
  typeof useGetContractForEditingSovQuery
>
export type GetContractForEditingSovLazyQueryHookResult = ReturnType<
  typeof useGetContractForEditingSovLazyQuery
>
export type GetContractForEditingSovSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForEditingSovSuspenseQuery
>
export type GetContractForEditingSovQueryResult = Apollo.QueryResult<
  GetContractForEditingSovQuery,
  GetContractForEditingSovQueryVariables
>
export const GetContractForVendorsProjectHomeDocument = gql`
  query getContractForVendorsProjectHome($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...ContractForVendorsProjectHome
    }
  }
  ${ContractForVendorsProjectHomeDoc}
`

/**
 * __useGetContractForVendorsProjectHomeQuery__
 *
 * To run a query within a React component, call `useGetContractForVendorsProjectHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForVendorsProjectHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForVendorsProjectHomeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForVendorsProjectHomeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForVendorsProjectHomeQuery,
    GetContractForVendorsProjectHomeQueryVariables
  > &
    (
      | { variables: GetContractForVendorsProjectHomeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForVendorsProjectHomeQuery,
    GetContractForVendorsProjectHomeQueryVariables
  >(GetContractForVendorsProjectHomeDocument, options)
}
export function useGetContractForVendorsProjectHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForVendorsProjectHomeQuery,
    GetContractForVendorsProjectHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForVendorsProjectHomeQuery,
    GetContractForVendorsProjectHomeQueryVariables
  >(GetContractForVendorsProjectHomeDocument, options)
}
export function useGetContractForVendorsProjectHomeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForVendorsProjectHomeQuery,
        GetContractForVendorsProjectHomeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForVendorsProjectHomeQuery,
    GetContractForVendorsProjectHomeQueryVariables
  >(GetContractForVendorsProjectHomeDocument, options)
}
export type GetContractForVendorsProjectHomeQueryHookResult = ReturnType<
  typeof useGetContractForVendorsProjectHomeQuery
>
export type GetContractForVendorsProjectHomeLazyQueryHookResult = ReturnType<
  typeof useGetContractForVendorsProjectHomeLazyQuery
>
export type GetContractForVendorsProjectHomeSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForVendorsProjectHomeSuspenseQuery
>
export type GetContractForVendorsProjectHomeQueryResult = Apollo.QueryResult<
  GetContractForVendorsProjectHomeQuery,
  GetContractForVendorsProjectHomeQueryVariables
>
export const SovChangeSetFromIntegrationDocument = gql`
  query sovChangeSetFromIntegration($input: SovChangeSetFromIntegrationInput!) {
    sovChangeSetFromIntegration(input: $input) {
      ...SovChangeSetProperties
    }
  }
  ${SovChangeSetPropertiesDoc}
`

/**
 * __useSovChangeSetFromIntegrationQuery__
 *
 * To run a query within a React component, call `useSovChangeSetFromIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSovChangeSetFromIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSovChangeSetFromIntegrationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSovChangeSetFromIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SovChangeSetFromIntegrationQuery,
    SovChangeSetFromIntegrationQueryVariables
  > &
    ({ variables: SovChangeSetFromIntegrationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SovChangeSetFromIntegrationQuery,
    SovChangeSetFromIntegrationQueryVariables
  >(SovChangeSetFromIntegrationDocument, options)
}
export function useSovChangeSetFromIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SovChangeSetFromIntegrationQuery,
    SovChangeSetFromIntegrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SovChangeSetFromIntegrationQuery,
    SovChangeSetFromIntegrationQueryVariables
  >(SovChangeSetFromIntegrationDocument, options)
}
export function useSovChangeSetFromIntegrationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SovChangeSetFromIntegrationQuery,
        SovChangeSetFromIntegrationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SovChangeSetFromIntegrationQuery,
    SovChangeSetFromIntegrationQueryVariables
  >(SovChangeSetFromIntegrationDocument, options)
}
export type SovChangeSetFromIntegrationQueryHookResult = ReturnType<
  typeof useSovChangeSetFromIntegrationQuery
>
export type SovChangeSetFromIntegrationLazyQueryHookResult = ReturnType<
  typeof useSovChangeSetFromIntegrationLazyQuery
>
export type SovChangeSetFromIntegrationSuspenseQueryHookResult = ReturnType<
  typeof useSovChangeSetFromIntegrationSuspenseQuery
>
export type SovChangeSetFromIntegrationQueryResult = Apollo.QueryResult<
  SovChangeSetFromIntegrationQuery,
  SovChangeSetFromIntegrationQueryVariables
>
export const UpdateSovWithChangeSetDocument = gql`
  mutation updateSovWithChangeSet($input: UpdateSovWithChangeSetInput!) {
    updateSovWithChangeSet(input: $input) {
      ...SovProperties
      lineItems {
        ...SovLineItemProperties
        sovLineItemGroup {
          ...SovLineItemGroupProperties
        }
      }
    }
  }
  ${SovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
`
export type UpdateSovWithChangeSetMutationFn = Apollo.MutationFunction<
  UpdateSovWithChangeSetMutation,
  UpdateSovWithChangeSetMutationVariables
>

/**
 * __useUpdateSovWithChangeSetMutation__
 *
 * To run a mutation, you first call `useUpdateSovWithChangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSovWithChangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSovWithChangeSetMutation, { data, loading, error }] = useUpdateSovWithChangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSovWithChangeSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSovWithChangeSetMutation,
    UpdateSovWithChangeSetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSovWithChangeSetMutation,
    UpdateSovWithChangeSetMutationVariables
  >(UpdateSovWithChangeSetDocument, options)
}
export type UpdateSovWithChangeSetMutationHookResult = ReturnType<
  typeof useUpdateSovWithChangeSetMutation
>
export type UpdateSovWithChangeSetMutationResult =
  Apollo.MutationResult<UpdateSovWithChangeSetMutation>
export type UpdateSovWithChangeSetMutationOptions = Apollo.BaseMutationOptions<
  UpdateSovWithChangeSetMutation,
  UpdateSovWithChangeSetMutationVariables
>
export const UpdateSovLineItemTotalValueDocument = gql`
  mutation updateSovLineItemTotalValue($input: UpdateSovLineItemTotalValueInput!) {
    updateSovLineItemTotalValue(input: $input) {
      ...SovLineItemProgressProperties
    }
  }
  ${SovLineItemProgressPropertiesDoc}
`
export type UpdateSovLineItemTotalValueMutationFn = Apollo.MutationFunction<
  UpdateSovLineItemTotalValueMutation,
  UpdateSovLineItemTotalValueMutationVariables
>

/**
 * __useUpdateSovLineItemTotalValueMutation__
 *
 * To run a mutation, you first call `useUpdateSovLineItemTotalValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSovLineItemTotalValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSovLineItemTotalValueMutation, { data, loading, error }] = useUpdateSovLineItemTotalValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSovLineItemTotalValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSovLineItemTotalValueMutation,
    UpdateSovLineItemTotalValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSovLineItemTotalValueMutation,
    UpdateSovLineItemTotalValueMutationVariables
  >(UpdateSovLineItemTotalValueDocument, options)
}
export type UpdateSovLineItemTotalValueMutationHookResult = ReturnType<
  typeof useUpdateSovLineItemTotalValueMutation
>
export type UpdateSovLineItemTotalValueMutationResult =
  Apollo.MutationResult<UpdateSovLineItemTotalValueMutation>
export type UpdateSovLineItemTotalValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateSovLineItemTotalValueMutation,
  UpdateSovLineItemTotalValueMutationVariables
>
export const UpdateContractLienWaiverThroughDateDocument = gql`
  mutation updateContractLienWaiverThroughDate($input: UpdateContractLienWaiverThroughDateInput!) {
    updateContractLienWaiverThroughDate(input: $input) {
      id
      lienWaiverThroughDate
    }
  }
`
export type UpdateContractLienWaiverThroughDateMutationFn = Apollo.MutationFunction<
  UpdateContractLienWaiverThroughDateMutation,
  UpdateContractLienWaiverThroughDateMutationVariables
>

/**
 * __useUpdateContractLienWaiverThroughDateMutation__
 *
 * To run a mutation, you first call `useUpdateContractLienWaiverThroughDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractLienWaiverThroughDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractLienWaiverThroughDateMutation, { data, loading, error }] = useUpdateContractLienWaiverThroughDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractLienWaiverThroughDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractLienWaiverThroughDateMutation,
    UpdateContractLienWaiverThroughDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractLienWaiverThroughDateMutation,
    UpdateContractLienWaiverThroughDateMutationVariables
  >(UpdateContractLienWaiverThroughDateDocument, options)
}
export type UpdateContractLienWaiverThroughDateMutationHookResult = ReturnType<
  typeof useUpdateContractLienWaiverThroughDateMutation
>
export type UpdateContractLienWaiverThroughDateMutationResult =
  Apollo.MutationResult<UpdateContractLienWaiverThroughDateMutation>
export type UpdateContractLienWaiverThroughDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractLienWaiverThroughDateMutation,
  UpdateContractLienWaiverThroughDateMutationVariables
>
export const AgingDashboardDocument = gql`
  query agingDashboard($input: DashboardInput!) {
    agingDashboard(input: $input) {
      ...AgingDashboardProperties
    }
  }
  ${AgingDashboardPropertiesDoc}
`

/**
 * __useAgingDashboardQuery__
 *
 * To run a query within a React component, call `useAgingDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgingDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgingDashboardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgingDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<AgingDashboardQuery, AgingDashboardQueryVariables> &
    ({ variables: AgingDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AgingDashboardQuery, AgingDashboardQueryVariables>(
    AgingDashboardDocument,
    options
  )
}
export function useAgingDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgingDashboardQuery, AgingDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AgingDashboardQuery, AgingDashboardQueryVariables>(
    AgingDashboardDocument,
    options
  )
}
export function useAgingDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AgingDashboardQuery, AgingDashboardQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AgingDashboardQuery, AgingDashboardQueryVariables>(
    AgingDashboardDocument,
    options
  )
}
export type AgingDashboardQueryHookResult = ReturnType<typeof useAgingDashboardQuery>
export type AgingDashboardLazyQueryHookResult = ReturnType<typeof useAgingDashboardLazyQuery>
export type AgingDashboardSuspenseQueryHookResult = ReturnType<
  typeof useAgingDashboardSuspenseQuery
>
export type AgingDashboardQueryResult = Apollo.QueryResult<
  AgingDashboardQuery,
  AgingDashboardQueryVariables
>
export const BillingDashboardDocument = gql`
  query billingDashboard($input: DashboardInput!) {
    billingDashboard(input: $input) {
      ...BillingDashboardProperties
    }
  }
  ${BillingDashboardPropertiesDoc}
`

/**
 * __useBillingDashboardQuery__
 *
 * To run a query within a React component, call `useBillingDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDashboardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<BillingDashboardQuery, BillingDashboardQueryVariables> &
    ({ variables: BillingDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BillingDashboardQuery, BillingDashboardQueryVariables>(
    BillingDashboardDocument,
    options
  )
}
export function useBillingDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingDashboardQuery, BillingDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BillingDashboardQuery, BillingDashboardQueryVariables>(
    BillingDashboardDocument,
    options
  )
}
export function useBillingDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<BillingDashboardQuery, BillingDashboardQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BillingDashboardQuery, BillingDashboardQueryVariables>(
    BillingDashboardDocument,
    options
  )
}
export type BillingDashboardQueryHookResult = ReturnType<typeof useBillingDashboardQuery>
export type BillingDashboardLazyQueryHookResult = ReturnType<typeof useBillingDashboardLazyQuery>
export type BillingDashboardSuspenseQueryHookResult = ReturnType<
  typeof useBillingDashboardSuspenseQuery
>
export type BillingDashboardQueryResult = Apollo.QueryResult<
  BillingDashboardQuery,
  BillingDashboardQueryVariables
>
export const BillingLineItemReportDocument = gql`
  query billingLineItemReport($input: DashboardInput!) {
    billingLineItemReport(input: $input) {
      ...BillingDashboardContractWithLineItemsProperties
    }
  }
  ${BillingDashboardContractWithLineItemsPropertiesDoc}
`

/**
 * __useBillingLineItemReportQuery__
 *
 * To run a query within a React component, call `useBillingLineItemReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingLineItemReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingLineItemReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingLineItemReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingLineItemReportQuery,
    BillingLineItemReportQueryVariables
  > &
    ({ variables: BillingLineItemReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BillingLineItemReportQuery, BillingLineItemReportQueryVariables>(
    BillingLineItemReportDocument,
    options
  )
}
export function useBillingLineItemReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingLineItemReportQuery,
    BillingLineItemReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BillingLineItemReportQuery, BillingLineItemReportQueryVariables>(
    BillingLineItemReportDocument,
    options
  )
}
export function useBillingLineItemReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BillingLineItemReportQuery,
        BillingLineItemReportQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<BillingLineItemReportQuery, BillingLineItemReportQueryVariables>(
    BillingLineItemReportDocument,
    options
  )
}
export type BillingLineItemReportQueryHookResult = ReturnType<typeof useBillingLineItemReportQuery>
export type BillingLineItemReportLazyQueryHookResult = ReturnType<
  typeof useBillingLineItemReportLazyQuery
>
export type BillingLineItemReportSuspenseQueryHookResult = ReturnType<
  typeof useBillingLineItemReportSuspenseQuery
>
export type BillingLineItemReportQueryResult = Apollo.QueryResult<
  BillingLineItemReportQuery,
  BillingLineItemReportQueryVariables
>
export const OverviewDashboardDocument = gql`
  query overviewDashboard($input: DashboardInput!) {
    overviewDashboard(input: $input) {
      ...OverviewDashboardProperties
    }
  }
  ${OverviewDashboardPropertiesDoc}
`

/**
 * __useOverviewDashboardQuery__
 *
 * To run a query within a React component, call `useOverviewDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewDashboardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverviewDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<OverviewDashboardQuery, OverviewDashboardQueryVariables> &
    ({ variables: OverviewDashboardQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OverviewDashboardQuery, OverviewDashboardQueryVariables>(
    OverviewDashboardDocument,
    options
  )
}
export function useOverviewDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OverviewDashboardQuery, OverviewDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OverviewDashboardQuery, OverviewDashboardQueryVariables>(
    OverviewDashboardDocument,
    options
  )
}
export function useOverviewDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<OverviewDashboardQuery, OverviewDashboardQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OverviewDashboardQuery, OverviewDashboardQueryVariables>(
    OverviewDashboardDocument,
    options
  )
}
export type OverviewDashboardQueryHookResult = ReturnType<typeof useOverviewDashboardQuery>
export type OverviewDashboardLazyQueryHookResult = ReturnType<typeof useOverviewDashboardLazyQuery>
export type OverviewDashboardSuspenseQueryHookResult = ReturnType<
  typeof useOverviewDashboardSuspenseQuery
>
export type OverviewDashboardQueryResult = Apollo.QueryResult<
  OverviewDashboardQuery,
  OverviewDashboardQueryVariables
>
export const SelectProjectFormsDocument = gql`
  mutation selectProjectForms($input: SelectProjectFormsInput!) {
    selectProjectForms(input: $input) {
      id
      includeChangeOrderLogInPayAppPackage
      payAppRequirementGroups {
        id
        payAppRequirements {
          id
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      lienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      lowerTierLienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      changeOrderRequestTemplate {
        ...MinimalFormTemplateProperties
      }
      changeOrderLogTemplate {
        ...MinimalFormTemplateProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalFormTemplatePropertiesDoc}
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type SelectProjectFormsMutationFn = Apollo.MutationFunction<
  SelectProjectFormsMutation,
  SelectProjectFormsMutationVariables
>

/**
 * __useSelectProjectFormsMutation__
 *
 * To run a mutation, you first call `useSelectProjectFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectProjectFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectProjectFormsMutation, { data, loading, error }] = useSelectProjectFormsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectProjectFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectProjectFormsMutation,
    SelectProjectFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SelectProjectFormsMutation, SelectProjectFormsMutationVariables>(
    SelectProjectFormsDocument,
    options
  )
}
export type SelectProjectFormsMutationHookResult = ReturnType<typeof useSelectProjectFormsMutation>
export type SelectProjectFormsMutationResult = Apollo.MutationResult<SelectProjectFormsMutation>
export type SelectProjectFormsMutationOptions = Apollo.BaseMutationOptions<
  SelectProjectFormsMutation,
  SelectProjectFormsMutationVariables
>
export const UpdateOnboardedProjectVendorsStatusDocument = gql`
  mutation updateOnboardedProjectVendorsStatus($input: OnboardedProjectVendorsStatusInput!) {
    updateOnboardedProjectVendorsStatus(input: $input) {
      ...ContractForVendorsProjectHome
    }
  }
  ${ContractForVendorsProjectHomeDoc}
`
export type UpdateOnboardedProjectVendorsStatusMutationFn = Apollo.MutationFunction<
  UpdateOnboardedProjectVendorsStatusMutation,
  UpdateOnboardedProjectVendorsStatusMutationVariables
>

/**
 * __useUpdateOnboardedProjectVendorsStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardedProjectVendorsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardedProjectVendorsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardedProjectVendorsStatusMutation, { data, loading, error }] = useUpdateOnboardedProjectVendorsStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardedProjectVendorsStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardedProjectVendorsStatusMutation,
    UpdateOnboardedProjectVendorsStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOnboardedProjectVendorsStatusMutation,
    UpdateOnboardedProjectVendorsStatusMutationVariables
  >(UpdateOnboardedProjectVendorsStatusDocument, options)
}
export type UpdateOnboardedProjectVendorsStatusMutationHookResult = ReturnType<
  typeof useUpdateOnboardedProjectVendorsStatusMutation
>
export type UpdateOnboardedProjectVendorsStatusMutationResult =
  Apollo.MutationResult<UpdateOnboardedProjectVendorsStatusMutation>
export type UpdateOnboardedProjectVendorsStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardedProjectVendorsStatusMutation,
  UpdateOnboardedProjectVendorsStatusMutationVariables
>
export const UpdateOnboardedProjectComplianceStatusDocument = gql`
  mutation updateOnboardedProjectComplianceStatus($input: OnboardedProjectComplianceStatusInput!) {
    updateOnboardedProjectComplianceStatus(input: $input) {
      ...ContractForComplianceProjectHome
    }
  }
  ${ContractForComplianceProjectHomeDoc}
`
export type UpdateOnboardedProjectComplianceStatusMutationFn = Apollo.MutationFunction<
  UpdateOnboardedProjectComplianceStatusMutation,
  UpdateOnboardedProjectComplianceStatusMutationVariables
>

/**
 * __useUpdateOnboardedProjectComplianceStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardedProjectComplianceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardedProjectComplianceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardedProjectComplianceStatusMutation, { data, loading, error }] = useUpdateOnboardedProjectComplianceStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardedProjectComplianceStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOnboardedProjectComplianceStatusMutation,
    UpdateOnboardedProjectComplianceStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOnboardedProjectComplianceStatusMutation,
    UpdateOnboardedProjectComplianceStatusMutationVariables
  >(UpdateOnboardedProjectComplianceStatusDocument, options)
}
export type UpdateOnboardedProjectComplianceStatusMutationHookResult = ReturnType<
  typeof useUpdateOnboardedProjectComplianceStatusMutation
>
export type UpdateOnboardedProjectComplianceStatusMutationResult =
  Apollo.MutationResult<UpdateOnboardedProjectComplianceStatusMutation>
export type UpdateOnboardedProjectComplianceStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOnboardedProjectComplianceStatusMutation,
  UpdateOnboardedProjectComplianceStatusMutationVariables
>
export const OnboardingContractsDocument = gql`
  query onboardingContracts($input: GetPaginatedContractsInput!, $months: [DateTime!]!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      contracts {
        id
        internalProjectNumber
        timeZone
        billingType
        leadPMs {
          ...MinimalUserProperties
        }
        integrations {
          ...MinimalIntegrationProperties
        }
        selectedAddress {
          id
        }
        project {
          id
          name
          projectNumber
          metadata {
            payAppDueOnDayOfMonth
          }
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
        associatedFormTemplates {
          id
          providedAsFormType
          formTemplate {
            ...OnboardingFormTemplateProperties
          }
        }
        payAppRequirementGroups {
          id
          payAppRequirements {
            id
            templateVariant {
              id
              template {
                ...OnboardingFormTemplateProperties
              }
            }
          }
        }
        lienWaiverTemplates {
          id
          conditionalFinalVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
          conditionalProgressVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
          unconditionalFinalVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
          unconditionalProgressVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
        }
        lowerTierLienWaiverTemplates {
          id
          conditionalFinalVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
          conditionalProgressVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
          unconditionalFinalVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
          unconditionalProgressVariant {
            id
            template {
              ...OnboardingFormTemplateProperties
            }
          }
        }
        changeOrderRequestTemplate {
          ...OnboardingFormTemplateProperties
        }
        onboardedStatus {
          ...OnboardedProjectContractStatusProperties
        }
        payApps(months: $months) {
          id
          payAppDueDate
        }
      }
    }
  }
  ${MinimalUserPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
  ${OnboardingFormTemplatePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`

/**
 * __useOnboardingContractsQuery__
 *
 * To run a query within a React component, call `useOnboardingContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      months: // value for 'months'
 *   },
 * });
 */
export function useOnboardingContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingContractsQuery,
    OnboardingContractsQueryVariables
  > &
    ({ variables: OnboardingContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OnboardingContractsQuery, OnboardingContractsQueryVariables>(
    OnboardingContractsDocument,
    options
  )
}
export function useOnboardingContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingContractsQuery,
    OnboardingContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OnboardingContractsQuery, OnboardingContractsQueryVariables>(
    OnboardingContractsDocument,
    options
  )
}
export function useOnboardingContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<OnboardingContractsQuery, OnboardingContractsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OnboardingContractsQuery, OnboardingContractsQueryVariables>(
    OnboardingContractsDocument,
    options
  )
}
export type OnboardingContractsQueryHookResult = ReturnType<typeof useOnboardingContractsQuery>
export type OnboardingContractsLazyQueryHookResult = ReturnType<
  typeof useOnboardingContractsLazyQuery
>
export type OnboardingContractsSuspenseQueryHookResult = ReturnType<
  typeof useOnboardingContractsSuspenseQuery
>
export type OnboardingContractsQueryResult = Apollo.QueryResult<
  OnboardingContractsQuery,
  OnboardingContractsQueryVariables
>
export const FullyBilledContractsDocument = gql`
  query fullyBilledContracts($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      contracts {
        id
        internalProjectNumber
        timeZone
        billingType
        retentionHeldOnBilledPayApps
        retentionAmountOutstanding
        progressAmountOutstanding
        retentionTrackingLevel
        percentComplete
        sov {
          id
          totalRetention
        }
        status
        leadPMs {
          ...MinimalUserProperties
        }
        integrations {
          ...MinimalIntegrationProperties
        }
        selectedAddress {
          id
        }
        company {
          id
        }
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
          metadata {
            payAppDueOnDayOfMonth
          }
        }
        lastEditedAt
        lastEditedBy {
          id
          firstName
          lastName
        }
        payApps {
          id
          status
          retentionOnly
          billingStart
          billingEnd
          payAppDueDate
          payAppNumber
        }
      }
    }
  }
  ${MinimalUserPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useFullyBilledContractsQuery__
 *
 * To run a query within a React component, call `useFullyBilledContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullyBilledContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullyBilledContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFullyBilledContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FullyBilledContractsQuery,
    FullyBilledContractsQueryVariables
  > &
    ({ variables: FullyBilledContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>(
    FullyBilledContractsDocument,
    options
  )
}
export function useFullyBilledContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullyBilledContractsQuery,
    FullyBilledContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>(
    FullyBilledContractsDocument,
    options
  )
}
export function useFullyBilledContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FullyBilledContractsQuery, FullyBilledContractsQueryVariables>(
    FullyBilledContractsDocument,
    options
  )
}
export type FullyBilledContractsQueryHookResult = ReturnType<typeof useFullyBilledContractsQuery>
export type FullyBilledContractsLazyQueryHookResult = ReturnType<
  typeof useFullyBilledContractsLazyQuery
>
export type FullyBilledContractsSuspenseQueryHookResult = ReturnType<
  typeof useFullyBilledContractsSuspenseQuery
>
export type FullyBilledContractsQueryResult = Apollo.QueryResult<
  FullyBilledContractsQuery,
  FullyBilledContractsQueryVariables
>
export const ContractForMissingFieldsDocument = gql`
  query contractForMissingFields($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      company {
        id
      }
      project {
        id
        generalContractor {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
        owner {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
      }
      payApps {
        id
      }
      legalRequirements {
        id
      }
      changeOrderRequests {
        id
      }
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useContractForMissingFieldsQuery__
 *
 * To run a query within a React component, call `useContractForMissingFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForMissingFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForMissingFieldsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractForMissingFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForMissingFieldsQuery,
    ContractForMissingFieldsQueryVariables
  > &
    ({ variables: ContractForMissingFieldsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractForMissingFieldsQuery, ContractForMissingFieldsQueryVariables>(
    ContractForMissingFieldsDocument,
    options
  )
}
export function useContractForMissingFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForMissingFieldsQuery,
    ContractForMissingFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractForMissingFieldsQuery, ContractForMissingFieldsQueryVariables>(
    ContractForMissingFieldsDocument,
    options
  )
}
export function useContractForMissingFieldsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForMissingFieldsQuery,
        ContractForMissingFieldsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForMissingFieldsQuery,
    ContractForMissingFieldsQueryVariables
  >(ContractForMissingFieldsDocument, options)
}
export type ContractForMissingFieldsQueryHookResult = ReturnType<
  typeof useContractForMissingFieldsQuery
>
export type ContractForMissingFieldsLazyQueryHookResult = ReturnType<
  typeof useContractForMissingFieldsLazyQuery
>
export type ContractForMissingFieldsSuspenseQueryHookResult = ReturnType<
  typeof useContractForMissingFieldsSuspenseQuery
>
export type ContractForMissingFieldsQueryResult = Apollo.QueryResult<
  ContractForMissingFieldsQuery,
  ContractForMissingFieldsQueryVariables
>
export const UpdateProjectMissingFieldsDocument = gql`
  mutation updateProjectMissingFields($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      generalContractor {
        selectedAddress {
          ...LocationProperties
        }
      }
      owner {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
    }
  }
  ${LocationPropertiesDoc}
`
export type UpdateProjectMissingFieldsMutationFn = Apollo.MutationFunction<
  UpdateProjectMissingFieldsMutation,
  UpdateProjectMissingFieldsMutationVariables
>

/**
 * __useUpdateProjectMissingFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMissingFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMissingFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMissingFieldsMutation, { data, loading, error }] = useUpdateProjectMissingFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMissingFieldsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectMissingFieldsMutation,
    UpdateProjectMissingFieldsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateProjectMissingFieldsMutation,
    UpdateProjectMissingFieldsMutationVariables
  >(UpdateProjectMissingFieldsDocument, options)
}
export type UpdateProjectMissingFieldsMutationHookResult = ReturnType<
  typeof useUpdateProjectMissingFieldsMutation
>
export type UpdateProjectMissingFieldsMutationResult =
  Apollo.MutationResult<UpdateProjectMissingFieldsMutation>
export type UpdateProjectMissingFieldsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMissingFieldsMutation,
  UpdateProjectMissingFieldsMutationVariables
>
export const CreateContractBillingForecastDocument = gql`
  mutation createContractBillingForecast($input: CreateContractBillingForecastInput!) {
    createContractBillingForecast(input: $input) {
      id
      billingForecast {
        ...ContractBillingForecastProperties
      }
    }
  }
  ${ContractBillingForecastPropertiesDoc}
`
export type CreateContractBillingForecastMutationFn = Apollo.MutationFunction<
  CreateContractBillingForecastMutation,
  CreateContractBillingForecastMutationVariables
>

/**
 * __useCreateContractBillingForecastMutation__
 *
 * To run a mutation, you first call `useCreateContractBillingForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractBillingForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractBillingForecastMutation, { data, loading, error }] = useCreateContractBillingForecastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractBillingForecastMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractBillingForecastMutation,
    CreateContractBillingForecastMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateContractBillingForecastMutation,
    CreateContractBillingForecastMutationVariables
  >(CreateContractBillingForecastDocument, options)
}
export type CreateContractBillingForecastMutationHookResult = ReturnType<
  typeof useCreateContractBillingForecastMutation
>
export type CreateContractBillingForecastMutationResult =
  Apollo.MutationResult<CreateContractBillingForecastMutation>
export type CreateContractBillingForecastMutationOptions = Apollo.BaseMutationOptions<
  CreateContractBillingForecastMutation,
  CreateContractBillingForecastMutationVariables
>
export const UpdateContractBillingForecastDocument = gql`
  mutation updateContractBillingForecast($input: UpdateContractBillingForecastInput!) {
    updateContractBillingForecast(input: $input) {
      ...ContractBillingForecastProperties
    }
  }
  ${ContractBillingForecastPropertiesDoc}
`
export type UpdateContractBillingForecastMutationFn = Apollo.MutationFunction<
  UpdateContractBillingForecastMutation,
  UpdateContractBillingForecastMutationVariables
>

/**
 * __useUpdateContractBillingForecastMutation__
 *
 * To run a mutation, you first call `useUpdateContractBillingForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractBillingForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractBillingForecastMutation, { data, loading, error }] = useUpdateContractBillingForecastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractBillingForecastMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractBillingForecastMutation,
    UpdateContractBillingForecastMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractBillingForecastMutation,
    UpdateContractBillingForecastMutationVariables
  >(UpdateContractBillingForecastDocument, options)
}
export type UpdateContractBillingForecastMutationHookResult = ReturnType<
  typeof useUpdateContractBillingForecastMutation
>
export type UpdateContractBillingForecastMutationResult =
  Apollo.MutationResult<UpdateContractBillingForecastMutation>
export type UpdateContractBillingForecastMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractBillingForecastMutation,
  UpdateContractBillingForecastMutationVariables
>
export const DeleteContractBillingForecastDocument = gql`
  mutation deleteContractBillingForecast($id: ID!) {
    deleteContractBillingForecast(id: $id) {
      id
    }
  }
`
export type DeleteContractBillingForecastMutationFn = Apollo.MutationFunction<
  DeleteContractBillingForecastMutation,
  DeleteContractBillingForecastMutationVariables
>

/**
 * __useDeleteContractBillingForecastMutation__
 *
 * To run a mutation, you first call `useDeleteContractBillingForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractBillingForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractBillingForecastMutation, { data, loading, error }] = useDeleteContractBillingForecastMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContractBillingForecastMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContractBillingForecastMutation,
    DeleteContractBillingForecastMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteContractBillingForecastMutation,
    DeleteContractBillingForecastMutationVariables
  >(DeleteContractBillingForecastDocument, options)
}
export type DeleteContractBillingForecastMutationHookResult = ReturnType<
  typeof useDeleteContractBillingForecastMutation
>
export type DeleteContractBillingForecastMutationResult =
  Apollo.MutationResult<DeleteContractBillingForecastMutation>
export type DeleteContractBillingForecastMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractBillingForecastMutation,
  DeleteContractBillingForecastMutationVariables
>
export const AggregateBillingForecastDocument = gql`
  query aggregateBillingForecast($input: AggregateBillingForecastInput!) {
    aggregateBillingForecast(input: $input) {
      numContractsWithForecast
      projectedAmountThisMonth
      forecastByMonth {
        ...AggregateForecastMonthProperties
      }
    }
  }
  ${AggregateForecastMonthPropertiesDoc}
`

/**
 * __useAggregateBillingForecastQuery__
 *
 * To run a query within a React component, call `useAggregateBillingForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateBillingForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateBillingForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAggregateBillingForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    AggregateBillingForecastQuery,
    AggregateBillingForecastQueryVariables
  > &
    ({ variables: AggregateBillingForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AggregateBillingForecastQuery, AggregateBillingForecastQueryVariables>(
    AggregateBillingForecastDocument,
    options
  )
}
export function useAggregateBillingForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AggregateBillingForecastQuery,
    AggregateBillingForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AggregateBillingForecastQuery, AggregateBillingForecastQueryVariables>(
    AggregateBillingForecastDocument,
    options
  )
}
export function useAggregateBillingForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AggregateBillingForecastQuery,
        AggregateBillingForecastQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AggregateBillingForecastQuery,
    AggregateBillingForecastQueryVariables
  >(AggregateBillingForecastDocument, options)
}
export type AggregateBillingForecastQueryHookResult = ReturnType<
  typeof useAggregateBillingForecastQuery
>
export type AggregateBillingForecastLazyQueryHookResult = ReturnType<
  typeof useAggregateBillingForecastLazyQuery
>
export type AggregateBillingForecastSuspenseQueryHookResult = ReturnType<
  typeof useAggregateBillingForecastSuspenseQuery
>
export type AggregateBillingForecastQueryResult = Apollo.QueryResult<
  AggregateBillingForecastQuery,
  AggregateBillingForecastQueryVariables
>
export const ContractsForReportingForecastDocument = gql`
  query contractsForReportingForecast($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      totalCount
      contracts {
        id
        internalProjectNumber
        billingType
        timeZone
        firstPayAppBillingEnd
        earliestPayAppBillingEnd
        hasStartedBilling
        progressRemaining
        status
        project {
          id
          name
          projectNumber
          generalContractor {
            company {
              id
            }
          }
        }
        company {
          id
        }
        sov {
          id
          totalValue
        }
        billingForecast {
          ...ContractBillingForecastProperties
        }
        selectedAddress {
          id
        }
        leadPMs {
          id
        }
      }
    }
  }
  ${ContractBillingForecastPropertiesDoc}
`

/**
 * __useContractsForReportingForecastQuery__
 *
 * To run a query within a React component, call `useContractsForReportingForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForReportingForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForReportingForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsForReportingForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForReportingForecastQuery,
    ContractsForReportingForecastQueryVariables
  > &
    ({ variables: ContractsForReportingForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractsForReportingForecastQuery,
    ContractsForReportingForecastQueryVariables
  >(ContractsForReportingForecastDocument, options)
}
export function useContractsForReportingForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForReportingForecastQuery,
    ContractsForReportingForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractsForReportingForecastQuery,
    ContractsForReportingForecastQueryVariables
  >(ContractsForReportingForecastDocument, options)
}
export function useContractsForReportingForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForReportingForecastQuery,
        ContractsForReportingForecastQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractsForReportingForecastQuery,
    ContractsForReportingForecastQueryVariables
  >(ContractsForReportingForecastDocument, options)
}
export type ContractsForReportingForecastQueryHookResult = ReturnType<
  typeof useContractsForReportingForecastQuery
>
export type ContractsForReportingForecastLazyQueryHookResult = ReturnType<
  typeof useContractsForReportingForecastLazyQuery
>
export type ContractsForReportingForecastSuspenseQueryHookResult = ReturnType<
  typeof useContractsForReportingForecastSuspenseQuery
>
export type ContractsForReportingForecastQueryResult = Apollo.QueryResult<
  ContractsForReportingForecastQuery,
  ContractsForReportingForecastQueryVariables
>
export const PaginatedContractsForReportingCashForecastDocument = gql`
  query paginatedContractsForReportingCashForecast(
    $input: GetPaginatedCashForecastContractsInput!
  ) {
    paginatedCashForecastContracts(input: $input) {
      cursor
      hasNext
      totalCount
      contracts {
        id
        numComments
        hasInvoicePredictedPaymentPassed
        contract {
          id
          billingType
          timeZone
          firstPayAppBillingEnd
          earliestPayAppBillingEnd
          hasStartedBilling
          progressRemaining
          status
          invoiceAmountOutstanding
          outstandingVendorLienWaiverAmount
          isJointCheck
          internalProjectNumber
          project {
            id
            projectNumber
            name
            generalContractor {
              companyName
              company {
                id
                name
              }
            }
          }
          company {
            id
          }
          sov {
            id
            totalValue
          }
          selectedAddress {
            id
          }
          leadPMs {
            id
          }
        }
        paymentPeriods {
          dateRange {
            startDate
            endDate
          }
          hasRetentionOnlyPayApps
          payApps {
            id
            payAppNumber
            predictedPaymentDate
            currentBilled
            currentRetention
            previousRetentionBilled
            isBalanceManuallyClosed
            amountPaid
            status
            timeZone
            retentionOnly
          }
          predictedPaymentAmount
        }
      }
    }
  }
`

/**
 * __usePaginatedContractsForReportingCashForecastQuery__
 *
 * To run a query within a React component, call `usePaginatedContractsForReportingCashForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedContractsForReportingCashForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedContractsForReportingCashForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedContractsForReportingCashForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedContractsForReportingCashForecastQuery,
    PaginatedContractsForReportingCashForecastQueryVariables
  > &
    (
      | { variables: PaginatedContractsForReportingCashForecastQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PaginatedContractsForReportingCashForecastQuery,
    PaginatedContractsForReportingCashForecastQueryVariables
  >(PaginatedContractsForReportingCashForecastDocument, options)
}
export function usePaginatedContractsForReportingCashForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedContractsForReportingCashForecastQuery,
    PaginatedContractsForReportingCashForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PaginatedContractsForReportingCashForecastQuery,
    PaginatedContractsForReportingCashForecastQueryVariables
  >(PaginatedContractsForReportingCashForecastDocument, options)
}
export function usePaginatedContractsForReportingCashForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedContractsForReportingCashForecastQuery,
        PaginatedContractsForReportingCashForecastQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PaginatedContractsForReportingCashForecastQuery,
    PaginatedContractsForReportingCashForecastQueryVariables
  >(PaginatedContractsForReportingCashForecastDocument, options)
}
export type PaginatedContractsForReportingCashForecastQueryHookResult = ReturnType<
  typeof usePaginatedContractsForReportingCashForecastQuery
>
export type PaginatedContractsForReportingCashForecastLazyQueryHookResult = ReturnType<
  typeof usePaginatedContractsForReportingCashForecastLazyQuery
>
export type PaginatedContractsForReportingCashForecastSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedContractsForReportingCashForecastSuspenseQuery
>
export type PaginatedContractsForReportingCashForecastQueryResult = Apollo.QueryResult<
  PaginatedContractsForReportingCashForecastQuery,
  PaginatedContractsForReportingCashForecastQueryVariables
>
export const GetContractForBillingForecastDocument = gql`
  query getContractForBillingForecast($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      timeZone
      billingForecast {
        ...ContractBillingForecastProperties
      }
      payApps {
        ...MinimalPayAppProperties
      }
      preSitelinePayApps {
        ...MinimalPreSitelinePayAppProperties
      }
    }
  }
  ${ContractBillingForecastPropertiesDoc}
  ${MinimalPayAppPropertiesDoc}
  ${MinimalPreSitelinePayAppPropertiesDoc}
`

/**
 * __useGetContractForBillingForecastQuery__
 *
 * To run a query within a React component, call `useGetContractForBillingForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForBillingForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForBillingForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForBillingForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForBillingForecastQuery,
    GetContractForBillingForecastQueryVariables
  > &
    ({ variables: GetContractForBillingForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForBillingForecastQuery,
    GetContractForBillingForecastQueryVariables
  >(GetContractForBillingForecastDocument, options)
}
export function useGetContractForBillingForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForBillingForecastQuery,
    GetContractForBillingForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForBillingForecastQuery,
    GetContractForBillingForecastQueryVariables
  >(GetContractForBillingForecastDocument, options)
}
export function useGetContractForBillingForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForBillingForecastQuery,
        GetContractForBillingForecastQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForBillingForecastQuery,
    GetContractForBillingForecastQueryVariables
  >(GetContractForBillingForecastDocument, options)
}
export type GetContractForBillingForecastQueryHookResult = ReturnType<
  typeof useGetContractForBillingForecastQuery
>
export type GetContractForBillingForecastLazyQueryHookResult = ReturnType<
  typeof useGetContractForBillingForecastLazyQuery
>
export type GetContractForBillingForecastSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForBillingForecastSuspenseQuery
>
export type GetContractForBillingForecastQueryResult = Apollo.QueryResult<
  GetContractForBillingForecastQuery,
  GetContractForBillingForecastQueryVariables
>
export const CreateIntegrationDocument = gql`
  mutation createIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`
export type CreateIntegrationMutationFn = Apollo.MutationFunction<
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables
>

/**
 * __useCreateIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationMutation, { data, loading, error }] = useCreateIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIntegrationMutation,
    CreateIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateIntegrationMutation, CreateIntegrationMutationVariables>(
    CreateIntegrationDocument,
    options
  )
}
export type CreateIntegrationMutationHookResult = ReturnType<typeof useCreateIntegrationMutation>
export type CreateIntegrationMutationResult = Apollo.MutationResult<CreateIntegrationMutation>
export type CreateIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables
>
export const UpdateIntegrationMetadataDocument = gql`
  mutation updateIntegrationMetadata($input: UpdateIntegrationMetadataInput!) {
    updateIntegrationMetadata(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`
export type UpdateIntegrationMetadataMutationFn = Apollo.MutationFunction<
  UpdateIntegrationMetadataMutation,
  UpdateIntegrationMetadataMutationVariables
>

/**
 * __useUpdateIntegrationMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationMetadataMutation, { data, loading, error }] = useUpdateIntegrationMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIntegrationMetadataMutation,
    UpdateIntegrationMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateIntegrationMetadataMutation,
    UpdateIntegrationMetadataMutationVariables
  >(UpdateIntegrationMetadataDocument, options)
}
export type UpdateIntegrationMetadataMutationHookResult = ReturnType<
  typeof useUpdateIntegrationMetadataMutation
>
export type UpdateIntegrationMetadataMutationResult =
  Apollo.MutationResult<UpdateIntegrationMetadataMutation>
export type UpdateIntegrationMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateIntegrationMetadataMutation,
  UpdateIntegrationMetadataMutationVariables
>
export const ContractForCreatingForecastDocument = gql`
  query contractForCreatingForecast($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...MinimalContractProperties
      firstPayAppBillingEnd
      earliestPayAppBillingEnd
      hasStartedBilling
      progressRemaining
      timeZone
      sov {
        id
        totalValue
      }
      billingForecast {
        ...ContractBillingForecastProperties
      }
      payApps {
        ...MinimalPayAppProperties
      }
      preSitelinePayApps {
        ...MinimalPreSitelinePayAppProperties
      }
      project {
        id
      }
    }
  }
  ${MinimalContractPropertiesDoc}
  ${ContractBillingForecastPropertiesDoc}
  ${MinimalPayAppPropertiesDoc}
  ${MinimalPreSitelinePayAppPropertiesDoc}
`

/**
 * __useContractForCreatingForecastQuery__
 *
 * To run a query within a React component, call `useContractForCreatingForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForCreatingForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForCreatingForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractForCreatingForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForCreatingForecastQuery,
    ContractForCreatingForecastQueryVariables
  > &
    ({ variables: ContractForCreatingForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractForCreatingForecastQuery,
    ContractForCreatingForecastQueryVariables
  >(ContractForCreatingForecastDocument, options)
}
export function useContractForCreatingForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForCreatingForecastQuery,
    ContractForCreatingForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForCreatingForecastQuery,
    ContractForCreatingForecastQueryVariables
  >(ContractForCreatingForecastDocument, options)
}
export function useContractForCreatingForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForCreatingForecastQuery,
        ContractForCreatingForecastQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForCreatingForecastQuery,
    ContractForCreatingForecastQueryVariables
  >(ContractForCreatingForecastDocument, options)
}
export type ContractForCreatingForecastQueryHookResult = ReturnType<
  typeof useContractForCreatingForecastQuery
>
export type ContractForCreatingForecastLazyQueryHookResult = ReturnType<
  typeof useContractForCreatingForecastLazyQuery
>
export type ContractForCreatingForecastSuspenseQueryHookResult = ReturnType<
  typeof useContractForCreatingForecastSuspenseQuery
>
export type ContractForCreatingForecastQueryResult = Apollo.QueryResult<
  ContractForCreatingForecastQuery,
  ContractForCreatingForecastQueryVariables
>
export const GetRateTablesDocument = gql`
  query getRateTables($input: RateTablesInput!) {
    rateTables(input: $input) {
      ...MinimalRateTableProperties
    }
  }
  ${MinimalRateTablePropertiesDoc}
`

/**
 * __useGetRateTablesQuery__
 *
 * To run a query within a React component, call `useGetRateTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateTablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRateTablesQuery(
  baseOptions: Apollo.QueryHookOptions<GetRateTablesQuery, GetRateTablesQueryVariables> &
    ({ variables: GetRateTablesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRateTablesQuery, GetRateTablesQueryVariables>(
    GetRateTablesDocument,
    options
  )
}
export function useGetRateTablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRateTablesQuery, GetRateTablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRateTablesQuery, GetRateTablesQueryVariables>(
    GetRateTablesDocument,
    options
  )
}
export function useGetRateTablesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRateTablesQuery, GetRateTablesQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRateTablesQuery, GetRateTablesQueryVariables>(
    GetRateTablesDocument,
    options
  )
}
export type GetRateTablesQueryHookResult = ReturnType<typeof useGetRateTablesQuery>
export type GetRateTablesLazyQueryHookResult = ReturnType<typeof useGetRateTablesLazyQuery>
export type GetRateTablesSuspenseQueryHookResult = ReturnType<typeof useGetRateTablesSuspenseQuery>
export type GetRateTablesQueryResult = Apollo.QueryResult<
  GetRateTablesQuery,
  GetRateTablesQueryVariables
>
export const GetRateTableDocument = gql`
  query getRateTable($rateTableId: ID!) {
    rateTable(id: $rateTableId) {
      ...RateTableProperties
    }
  }
  ${RateTablePropertiesDoc}
`

/**
 * __useGetRateTableQuery__
 *
 * To run a query within a React component, call `useGetRateTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateTableQuery({
 *   variables: {
 *      rateTableId: // value for 'rateTableId'
 *   },
 * });
 */
export function useGetRateTableQuery(
  baseOptions: Apollo.QueryHookOptions<GetRateTableQuery, GetRateTableQueryVariables> &
    ({ variables: GetRateTableQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetRateTableQuery, GetRateTableQueryVariables>(
    GetRateTableDocument,
    options
  )
}
export function useGetRateTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRateTableQuery, GetRateTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetRateTableQuery, GetRateTableQueryVariables>(
    GetRateTableDocument,
    options
  )
}
export function useGetRateTableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRateTableQuery, GetRateTableQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetRateTableQuery, GetRateTableQueryVariables>(
    GetRateTableDocument,
    options
  )
}
export type GetRateTableQueryHookResult = ReturnType<typeof useGetRateTableQuery>
export type GetRateTableLazyQueryHookResult = ReturnType<typeof useGetRateTableLazyQuery>
export type GetRateTableSuspenseQueryHookResult = ReturnType<typeof useGetRateTableSuspenseQuery>
export type GetRateTableQueryResult = Apollo.QueryResult<
  GetRateTableQuery,
  GetRateTableQueryVariables
>
export const CreateRateTableDocument = gql`
  mutation createRateTable($input: CreateRateTableInput!) {
    createRateTable(input: $input) {
      ...RateTableProperties
    }
  }
  ${RateTablePropertiesDoc}
`
export type CreateRateTableMutationFn = Apollo.MutationFunction<
  CreateRateTableMutation,
  CreateRateTableMutationVariables
>

/**
 * __useCreateRateTableMutation__
 *
 * To run a mutation, you first call `useCreateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateTableMutation, { data, loading, error }] = useCreateRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRateTableMutation,
    CreateRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateRateTableMutation, CreateRateTableMutationVariables>(
    CreateRateTableDocument,
    options
  )
}
export type CreateRateTableMutationHookResult = ReturnType<typeof useCreateRateTableMutation>
export type CreateRateTableMutationResult = Apollo.MutationResult<CreateRateTableMutation>
export type CreateRateTableMutationOptions = Apollo.BaseMutationOptions<
  CreateRateTableMutation,
  CreateRateTableMutationVariables
>
export const UpdateRateTableDocument = gql`
  mutation updateRateTable($input: UpdateRateTableInput!) {
    updateRateTable(input: $input) {
      ...RateTableProperties
    }
  }
  ${RateTablePropertiesDoc}
`
export type UpdateRateTableMutationFn = Apollo.MutationFunction<
  UpdateRateTableMutation,
  UpdateRateTableMutationVariables
>

/**
 * __useUpdateRateTableMutation__
 *
 * To run a mutation, you first call `useUpdateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRateTableMutation, { data, loading, error }] = useUpdateRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRateTableMutation,
    UpdateRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateRateTableMutation, UpdateRateTableMutationVariables>(
    UpdateRateTableDocument,
    options
  )
}
export type UpdateRateTableMutationHookResult = ReturnType<typeof useUpdateRateTableMutation>
export type UpdateRateTableMutationResult = Apollo.MutationResult<UpdateRateTableMutation>
export type UpdateRateTableMutationOptions = Apollo.BaseMutationOptions<
  UpdateRateTableMutation,
  UpdateRateTableMutationVariables
>
export const DeleteRateTableDocument = gql`
  mutation deleteRateTable($rateTableId: ID!) {
    deleteRateTable(id: $rateTableId) {
      id
    }
  }
`
export type DeleteRateTableMutationFn = Apollo.MutationFunction<
  DeleteRateTableMutation,
  DeleteRateTableMutationVariables
>

/**
 * __useDeleteRateTableMutation__
 *
 * To run a mutation, you first call `useDeleteRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRateTableMutation, { data, loading, error }] = useDeleteRateTableMutation({
 *   variables: {
 *      rateTableId: // value for 'rateTableId'
 *   },
 * });
 */
export function useDeleteRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRateTableMutation,
    DeleteRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteRateTableMutation, DeleteRateTableMutationVariables>(
    DeleteRateTableDocument,
    options
  )
}
export type DeleteRateTableMutationHookResult = ReturnType<typeof useDeleteRateTableMutation>
export type DeleteRateTableMutationResult = Apollo.MutationResult<DeleteRateTableMutation>
export type DeleteRateTableMutationOptions = Apollo.BaseMutationOptions<
  DeleteRateTableMutation,
  DeleteRateTableMutationVariables
>
export const ArchiveRateTableDocument = gql`
  mutation archiveRateTable($rateTableId: ID!) {
    archiveRateTable(id: $rateTableId) {
      id
      status
    }
  }
`
export type ArchiveRateTableMutationFn = Apollo.MutationFunction<
  ArchiveRateTableMutation,
  ArchiveRateTableMutationVariables
>

/**
 * __useArchiveRateTableMutation__
 *
 * To run a mutation, you first call `useArchiveRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveRateTableMutation, { data, loading, error }] = useArchiveRateTableMutation({
 *   variables: {
 *      rateTableId: // value for 'rateTableId'
 *   },
 * });
 */
export function useArchiveRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveRateTableMutation,
    ArchiveRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveRateTableMutation, ArchiveRateTableMutationVariables>(
    ArchiveRateTableDocument,
    options
  )
}
export type ArchiveRateTableMutationHookResult = ReturnType<typeof useArchiveRateTableMutation>
export type ArchiveRateTableMutationResult = Apollo.MutationResult<ArchiveRateTableMutation>
export type ArchiveRateTableMutationOptions = Apollo.BaseMutationOptions<
  ArchiveRateTableMutation,
  ArchiveRateTableMutationVariables
>
export const UnarchiveRateTableDocument = gql`
  mutation unarchiveRateTable($rateTableId: ID!) {
    unarchiveRateTable(id: $rateTableId) {
      id
      status
    }
  }
`
export type UnarchiveRateTableMutationFn = Apollo.MutationFunction<
  UnarchiveRateTableMutation,
  UnarchiveRateTableMutationVariables
>

/**
 * __useUnarchiveRateTableMutation__
 *
 * To run a mutation, you first call `useUnarchiveRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveRateTableMutation, { data, loading, error }] = useUnarchiveRateTableMutation({
 *   variables: {
 *      rateTableId: // value for 'rateTableId'
 *   },
 * });
 */
export function useUnarchiveRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveRateTableMutation,
    UnarchiveRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnarchiveRateTableMutation, UnarchiveRateTableMutationVariables>(
    UnarchiveRateTableDocument,
    options
  )
}
export type UnarchiveRateTableMutationHookResult = ReturnType<typeof useUnarchiveRateTableMutation>
export type UnarchiveRateTableMutationResult = Apollo.MutationResult<UnarchiveRateTableMutation>
export type UnarchiveRateTableMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveRateTableMutation,
  UnarchiveRateTableMutationVariables
>
export const DuplicateRateTableDocument = gql`
  mutation duplicateRateTable($rateTableId: ID!) {
    duplicateRateTable(id: $rateTableId) {
      ...RateTableProperties
    }
  }
  ${RateTablePropertiesDoc}
`
export type DuplicateRateTableMutationFn = Apollo.MutationFunction<
  DuplicateRateTableMutation,
  DuplicateRateTableMutationVariables
>

/**
 * __useDuplicateRateTableMutation__
 *
 * To run a mutation, you first call `useDuplicateRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateRateTableMutation, { data, loading, error }] = useDuplicateRateTableMutation({
 *   variables: {
 *      rateTableId: // value for 'rateTableId'
 *   },
 * });
 */
export function useDuplicateRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateRateTableMutation,
    DuplicateRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DuplicateRateTableMutation, DuplicateRateTableMutationVariables>(
    DuplicateRateTableDocument,
    options
  )
}
export type DuplicateRateTableMutationHookResult = ReturnType<typeof useDuplicateRateTableMutation>
export type DuplicateRateTableMutationResult = Apollo.MutationResult<DuplicateRateTableMutation>
export type DuplicateRateTableMutationOptions = Apollo.BaseMutationOptions<
  DuplicateRateTableMutation,
  DuplicateRateTableMutationVariables
>
export const CalculateRetentionChangeDocument = gql`
  query calculateRetentionChange($input: CalculateRetentionChangeInput!) {
    calculateRetentionChange(input: $input) {
      retentionReleased
      retentionHeldCurrent
      retentionHeldToDate
    }
  }
`

/**
 * __useCalculateRetentionChangeQuery__
 *
 * To run a query within a React component, call `useCalculateRetentionChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateRetentionChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateRetentionChangeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateRetentionChangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateRetentionChangeQuery,
    CalculateRetentionChangeQueryVariables
  > &
    ({ variables: CalculateRetentionChangeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CalculateRetentionChangeQuery, CalculateRetentionChangeQueryVariables>(
    CalculateRetentionChangeDocument,
    options
  )
}
export function useCalculateRetentionChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateRetentionChangeQuery,
    CalculateRetentionChangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CalculateRetentionChangeQuery, CalculateRetentionChangeQueryVariables>(
    CalculateRetentionChangeDocument,
    options
  )
}
export function useCalculateRetentionChangeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CalculateRetentionChangeQuery,
        CalculateRetentionChangeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CalculateRetentionChangeQuery,
    CalculateRetentionChangeQueryVariables
  >(CalculateRetentionChangeDocument, options)
}
export type CalculateRetentionChangeQueryHookResult = ReturnType<
  typeof useCalculateRetentionChangeQuery
>
export type CalculateRetentionChangeLazyQueryHookResult = ReturnType<
  typeof useCalculateRetentionChangeLazyQuery
>
export type CalculateRetentionChangeSuspenseQueryHookResult = ReturnType<
  typeof useCalculateRetentionChangeSuspenseQuery
>
export type CalculateRetentionChangeQueryResult = Apollo.QueryResult<
  CalculateRetentionChangeQuery,
  CalculateRetentionChangeQueryVariables
>
export const ResetPayAppRetentionReleasedDocument = gql`
  mutation resetPayAppRetentionReleased($input: ResetPayAppRetentionReleasedInput!) {
    resetPayAppRetentionReleased(input: $input) {
      id
      previousRetentionBilled
      currentRetention
      totalRetention
      retentionHeldPercent
      retentionHeldOverride
      canGenerateForms
      retentionReleased
      progress {
        id
        currentRetention
        previousRetention
        previousRetentionBilled
        retentionHeldPercent
        retentionHeldOverride
        retentionReleased
        amountDuePreTax
        amountDuePostTax
        amountDueTaxAmount
      }
    }
  }
`
export type ResetPayAppRetentionReleasedMutationFn = Apollo.MutationFunction<
  ResetPayAppRetentionReleasedMutation,
  ResetPayAppRetentionReleasedMutationVariables
>

/**
 * __useResetPayAppRetentionReleasedMutation__
 *
 * To run a mutation, you first call `useResetPayAppRetentionReleasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayAppRetentionReleasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayAppRetentionReleasedMutation, { data, loading, error }] = useResetPayAppRetentionReleasedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPayAppRetentionReleasedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPayAppRetentionReleasedMutation,
    ResetPayAppRetentionReleasedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPayAppRetentionReleasedMutation,
    ResetPayAppRetentionReleasedMutationVariables
  >(ResetPayAppRetentionReleasedDocument, options)
}
export type ResetPayAppRetentionReleasedMutationHookResult = ReturnType<
  typeof useResetPayAppRetentionReleasedMutation
>
export type ResetPayAppRetentionReleasedMutationResult =
  Apollo.MutationResult<ResetPayAppRetentionReleasedMutation>
export type ResetPayAppRetentionReleasedMutationOptions = Apollo.BaseMutationOptions<
  ResetPayAppRetentionReleasedMutation,
  ResetPayAppRetentionReleasedMutationVariables
>
export const ResetPayAppRetentionHeldOverrideDocument = gql`
  mutation resetPayAppRetentionHeldOverride($input: ResetPayAppRetentionHeldOverrideInput!) {
    resetPayAppRetentionHeldOverride(input: $input) {
      id
      previousRetentionBilled
      currentRetention
      totalRetention
      retentionHeldPercent
      retentionHeldOverride
      canGenerateForms
      retentionReleased
      progress {
        id
        currentRetention
        previousRetention
        previousRetentionBilled
        retentionHeldPercent
        retentionHeldOverride
        retentionReleased
        amountDuePreTax
        amountDuePostTax
        amountDueTaxAmount
      }
    }
  }
`
export type ResetPayAppRetentionHeldOverrideMutationFn = Apollo.MutationFunction<
  ResetPayAppRetentionHeldOverrideMutation,
  ResetPayAppRetentionHeldOverrideMutationVariables
>

/**
 * __useResetPayAppRetentionHeldOverrideMutation__
 *
 * To run a mutation, you first call `useResetPayAppRetentionHeldOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayAppRetentionHeldOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayAppRetentionHeldOverrideMutation, { data, loading, error }] = useResetPayAppRetentionHeldOverrideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPayAppRetentionHeldOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPayAppRetentionHeldOverrideMutation,
    ResetPayAppRetentionHeldOverrideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPayAppRetentionHeldOverrideMutation,
    ResetPayAppRetentionHeldOverrideMutationVariables
  >(ResetPayAppRetentionHeldOverrideDocument, options)
}
export type ResetPayAppRetentionHeldOverrideMutationHookResult = ReturnType<
  typeof useResetPayAppRetentionHeldOverrideMutation
>
export type ResetPayAppRetentionHeldOverrideMutationResult =
  Apollo.MutationResult<ResetPayAppRetentionHeldOverrideMutation>
export type ResetPayAppRetentionHeldOverrideMutationOptions = Apollo.BaseMutationOptions<
  ResetPayAppRetentionHeldOverrideMutation,
  ResetPayAppRetentionHeldOverrideMutationVariables
>
export const ContractCashForecastDataDocument = gql`
  query contractCashForecastData($id: ID!) {
    contract(id: $id) {
      ...ContractForCashForecastProperties
    }
  }
  ${ContractForCashForecastPropertiesDoc}
`

/**
 * __useContractCashForecastDataQuery__
 *
 * To run a query within a React component, call `useContractCashForecastDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractCashForecastDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractCashForecastDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractCashForecastDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractCashForecastDataQuery,
    ContractCashForecastDataQueryVariables
  > &
    ({ variables: ContractCashForecastDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractCashForecastDataQuery, ContractCashForecastDataQueryVariables>(
    ContractCashForecastDataDocument,
    options
  )
}
export function useContractCashForecastDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractCashForecastDataQuery,
    ContractCashForecastDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractCashForecastDataQuery, ContractCashForecastDataQueryVariables>(
    ContractCashForecastDataDocument,
    options
  )
}
export function useContractCashForecastDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractCashForecastDataQuery,
        ContractCashForecastDataQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractCashForecastDataQuery,
    ContractCashForecastDataQueryVariables
  >(ContractCashForecastDataDocument, options)
}
export type ContractCashForecastDataQueryHookResult = ReturnType<
  typeof useContractCashForecastDataQuery
>
export type ContractCashForecastDataLazyQueryHookResult = ReturnType<
  typeof useContractCashForecastDataLazyQuery
>
export type ContractCashForecastDataSuspenseQueryHookResult = ReturnType<
  typeof useContractCashForecastDataSuspenseQuery
>
export type ContractCashForecastDataQueryResult = Apollo.QueryResult<
  ContractCashForecastDataQuery,
  ContractCashForecastDataQueryVariables
>
export const ContractsCashForecastDataDocument = gql`
  query contractsCashForecastData($input: GetPaginatedCashForecastContractsInput!) {
    paginatedCashForecastContracts(input: $input) {
      contracts {
        id
        contract {
          ...ContractForCashForecastProperties
        }
        paymentPeriods {
          dateRange {
            startDate
            endDate
          }
        }
      }
    }
  }
  ${ContractForCashForecastPropertiesDoc}
`

/**
 * __useContractsCashForecastDataQuery__
 *
 * To run a query within a React component, call `useContractsCashForecastDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsCashForecastDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsCashForecastDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsCashForecastDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsCashForecastDataQuery,
    ContractsCashForecastDataQueryVariables
  > &
    ({ variables: ContractsCashForecastDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsCashForecastDataQuery, ContractsCashForecastDataQueryVariables>(
    ContractsCashForecastDataDocument,
    options
  )
}
export function useContractsCashForecastDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsCashForecastDataQuery,
    ContractsCashForecastDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractsCashForecastDataQuery,
    ContractsCashForecastDataQueryVariables
  >(ContractsCashForecastDataDocument, options)
}
export function useContractsCashForecastDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsCashForecastDataQuery,
        ContractsCashForecastDataQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractsCashForecastDataQuery,
    ContractsCashForecastDataQueryVariables
  >(ContractsCashForecastDataDocument, options)
}
export type ContractsCashForecastDataQueryHookResult = ReturnType<
  typeof useContractsCashForecastDataQuery
>
export type ContractsCashForecastDataLazyQueryHookResult = ReturnType<
  typeof useContractsCashForecastDataLazyQuery
>
export type ContractsCashForecastDataSuspenseQueryHookResult = ReturnType<
  typeof useContractsCashForecastDataSuspenseQuery
>
export type ContractsCashForecastDataQueryResult = Apollo.QueryResult<
  ContractsCashForecastDataQuery,
  ContractsCashForecastDataQueryVariables
>
export const UpdateContractRateTableDocument = gql`
  mutation updateContractRateTable($input: UpdateContractRateTableInput!) {
    updateContractRateTable(input: $input) {
      id
      rateTable {
        ...RateTableProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      changeOrderRequests {
        id
        rateTable {
          id
        }
        rateTableItems {
          id
        }
        rateTableGroups {
          id
        }
      }
    }
  }
  ${RateTablePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type UpdateContractRateTableMutationFn = Apollo.MutationFunction<
  UpdateContractRateTableMutation,
  UpdateContractRateTableMutationVariables
>

/**
 * __useUpdateContractRateTableMutation__
 *
 * To run a mutation, you first call `useUpdateContractRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractRateTableMutation, { data, loading, error }] = useUpdateContractRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractRateTableMutation,
    UpdateContractRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractRateTableMutation,
    UpdateContractRateTableMutationVariables
  >(UpdateContractRateTableDocument, options)
}
export type UpdateContractRateTableMutationHookResult = ReturnType<
  typeof useUpdateContractRateTableMutation
>
export type UpdateContractRateTableMutationResult =
  Apollo.MutationResult<UpdateContractRateTableMutation>
export type UpdateContractRateTableMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractRateTableMutation,
  UpdateContractRateTableMutationVariables
>
export const DeleteContractRateTableDocument = gql`
  mutation deleteContractRateTable($input: DeleteContractRateTableInput!) {
    deleteContractRateTable(input: $input) {
      id
      rateTable {
        ...RateTableProperties
      }
      rateTableFees {
        ...RateTableFeeProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${RateTablePropertiesDoc}
  ${RateTableFeePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type DeleteContractRateTableMutationFn = Apollo.MutationFunction<
  DeleteContractRateTableMutation,
  DeleteContractRateTableMutationVariables
>

/**
 * __useDeleteContractRateTableMutation__
 *
 * To run a mutation, you first call `useDeleteContractRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractRateTableMutation, { data, loading, error }] = useDeleteContractRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContractRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContractRateTableMutation,
    DeleteContractRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteContractRateTableMutation,
    DeleteContractRateTableMutationVariables
  >(DeleteContractRateTableDocument, options)
}
export type DeleteContractRateTableMutationHookResult = ReturnType<
  typeof useDeleteContractRateTableMutation
>
export type DeleteContractRateTableMutationResult =
  Apollo.MutationResult<DeleteContractRateTableMutation>
export type DeleteContractRateTableMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractRateTableMutation,
  DeleteContractRateTableMutationVariables
>
export const CreateContractRateTableFeeDocument = gql`
  mutation createContractRateTableFee($input: CreateContractRateTableFeeInput!) {
    createContractRateTableFee(input: $input) {
      ...RateTableFeeProperties
    }
  }
  ${RateTableFeePropertiesDoc}
`
export type CreateContractRateTableFeeMutationFn = Apollo.MutationFunction<
  CreateContractRateTableFeeMutation,
  CreateContractRateTableFeeMutationVariables
>

/**
 * __useCreateContractRateTableFeeMutation__
 *
 * To run a mutation, you first call `useCreateContractRateTableFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractRateTableFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractRateTableFeeMutation, { data, loading, error }] = useCreateContractRateTableFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractRateTableFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractRateTableFeeMutation,
    CreateContractRateTableFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateContractRateTableFeeMutation,
    CreateContractRateTableFeeMutationVariables
  >(CreateContractRateTableFeeDocument, options)
}
export type CreateContractRateTableFeeMutationHookResult = ReturnType<
  typeof useCreateContractRateTableFeeMutation
>
export type CreateContractRateTableFeeMutationResult =
  Apollo.MutationResult<CreateContractRateTableFeeMutation>
export type CreateContractRateTableFeeMutationOptions = Apollo.BaseMutationOptions<
  CreateContractRateTableFeeMutation,
  CreateContractRateTableFeeMutationVariables
>
export const UpdateContractRateTableFeeDocument = gql`
  mutation updateContractRateTableFee($input: UpdateContractRateTableFeeInput!) {
    updateContractRateTableFee(input: $input) {
      ...RateTableFeeProperties
    }
  }
  ${RateTableFeePropertiesDoc}
`
export type UpdateContractRateTableFeeMutationFn = Apollo.MutationFunction<
  UpdateContractRateTableFeeMutation,
  UpdateContractRateTableFeeMutationVariables
>

/**
 * __useUpdateContractRateTableFeeMutation__
 *
 * To run a mutation, you first call `useUpdateContractRateTableFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractRateTableFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractRateTableFeeMutation, { data, loading, error }] = useUpdateContractRateTableFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractRateTableFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractRateTableFeeMutation,
    UpdateContractRateTableFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractRateTableFeeMutation,
    UpdateContractRateTableFeeMutationVariables
  >(UpdateContractRateTableFeeDocument, options)
}
export type UpdateContractRateTableFeeMutationHookResult = ReturnType<
  typeof useUpdateContractRateTableFeeMutation
>
export type UpdateContractRateTableFeeMutationResult =
  Apollo.MutationResult<UpdateContractRateTableFeeMutation>
export type UpdateContractRateTableFeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractRateTableFeeMutation,
  UpdateContractRateTableFeeMutationVariables
>
export const DeleteContractRateTableFeeDocument = gql`
  mutation deleteContractRateTableFee($input: DeleteContractRateTableFeeInput!) {
    deleteContractRateTableFee(input: $input) {
      id
    }
  }
`
export type DeleteContractRateTableFeeMutationFn = Apollo.MutationFunction<
  DeleteContractRateTableFeeMutation,
  DeleteContractRateTableFeeMutationVariables
>

/**
 * __useDeleteContractRateTableFeeMutation__
 *
 * To run a mutation, you first call `useDeleteContractRateTableFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractRateTableFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractRateTableFeeMutation, { data, loading, error }] = useDeleteContractRateTableFeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContractRateTableFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContractRateTableFeeMutation,
    DeleteContractRateTableFeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteContractRateTableFeeMutation,
    DeleteContractRateTableFeeMutationVariables
  >(DeleteContractRateTableFeeDocument, options)
}
export type DeleteContractRateTableFeeMutationHookResult = ReturnType<
  typeof useDeleteContractRateTableFeeMutation
>
export type DeleteContractRateTableFeeMutationResult =
  Apollo.MutationResult<DeleteContractRateTableFeeMutation>
export type DeleteContractRateTableFeeMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractRateTableFeeMutation,
  DeleteContractRateTableFeeMutationVariables
>
export const GetPayAppForProgressDocument = gql`
  query getPayAppForProgress($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      status
      timeZone
      retentionOnly
      payAppDueDate
      billingEnd
      billingStart
      billingType
      payAppNumber
      previousRetentionBilled
      currentBilled
      currentRetention
      amountDueTaxAmount
      amountDuePostTax
      totalRetention
      invoiceReady
      lastSubmitted {
        id
        status
        statusUpdatedAt
      }
      draftSubmittedAt
      retentionHeldPercent
      retentionHeldOverride
      lastPayAppWithRetentionHeldOverride {
        id
        payAppNumber
      }
      retentionReleased
      contract {
        id
        roundRetention
        internalProjectNumber
        taxCalculationType
        company {
          id
          name
          taxGroups {
            ...TaxGroupProperties
          }
        }
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
      }
      progress {
        ...SovLineItemProgressProperties
      }
      ...PayAppRateTableProperties
    }
  }
  ${TaxGroupPropertiesDoc}
  ${SovLineItemProgressPropertiesDoc}
  ${PayAppRateTablePropertiesDoc}
`

/**
 * __useGetPayAppForProgressQuery__
 *
 * To run a query within a React component, call `useGetPayAppForProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForProgressQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppForProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppForProgressQuery,
    GetPayAppForProgressQueryVariables
  > &
    ({ variables: GetPayAppForProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForProgressQuery, GetPayAppForProgressQueryVariables>(
    GetPayAppForProgressDocument,
    options
  )
}
export function useGetPayAppForProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForProgressQuery,
    GetPayAppForProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppForProgressQuery, GetPayAppForProgressQueryVariables>(
    GetPayAppForProgressDocument,
    options
  )
}
export function useGetPayAppForProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPayAppForProgressQuery, GetPayAppForProgressQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppForProgressQuery, GetPayAppForProgressQueryVariables>(
    GetPayAppForProgressDocument,
    options
  )
}
export type GetPayAppForProgressQueryHookResult = ReturnType<typeof useGetPayAppForProgressQuery>
export type GetPayAppForProgressLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForProgressLazyQuery
>
export type GetPayAppForProgressSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForProgressSuspenseQuery
>
export type GetPayAppForProgressQueryResult = Apollo.QueryResult<
  GetPayAppForProgressQuery,
  GetPayAppForProgressQueryVariables
>
export const CreatePayAppRateTableItemDocument = gql`
  mutation createPayAppRateTableItem($input: CreatePayAppRateTableItemInput!) {
    createPayAppRateTableItem(input: $input) {
      ...PayAppRateTableItemProperties
    }
  }
  ${PayAppRateTableItemPropertiesDoc}
`
export type CreatePayAppRateTableItemMutationFn = Apollo.MutationFunction<
  CreatePayAppRateTableItemMutation,
  CreatePayAppRateTableItemMutationVariables
>

/**
 * __useCreatePayAppRateTableItemMutation__
 *
 * To run a mutation, you first call `useCreatePayAppRateTableItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayAppRateTableItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayAppRateTableItemMutation, { data, loading, error }] = useCreatePayAppRateTableItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayAppRateTableItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePayAppRateTableItemMutation,
    CreatePayAppRateTableItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePayAppRateTableItemMutation,
    CreatePayAppRateTableItemMutationVariables
  >(CreatePayAppRateTableItemDocument, options)
}
export type CreatePayAppRateTableItemMutationHookResult = ReturnType<
  typeof useCreatePayAppRateTableItemMutation
>
export type CreatePayAppRateTableItemMutationResult =
  Apollo.MutationResult<CreatePayAppRateTableItemMutation>
export type CreatePayAppRateTableItemMutationOptions = Apollo.BaseMutationOptions<
  CreatePayAppRateTableItemMutation,
  CreatePayAppRateTableItemMutationVariables
>
export const UpdatePayAppRateTableItemDocument = gql`
  mutation updatePayAppRateTableItem($input: UpdatePayAppRateTableItemInput!) {
    updatePayAppRateTableItem(input: $input) {
      ...PayAppRateTableItemProperties
    }
  }
  ${PayAppRateTableItemPropertiesDoc}
`
export type UpdatePayAppRateTableItemMutationFn = Apollo.MutationFunction<
  UpdatePayAppRateTableItemMutation,
  UpdatePayAppRateTableItemMutationVariables
>

/**
 * __useUpdatePayAppRateTableItemMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppRateTableItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppRateTableItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppRateTableItemMutation, { data, loading, error }] = useUpdatePayAppRateTableItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppRateTableItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppRateTableItemMutation,
    UpdatePayAppRateTableItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppRateTableItemMutation,
    UpdatePayAppRateTableItemMutationVariables
  >(UpdatePayAppRateTableItemDocument, options)
}
export type UpdatePayAppRateTableItemMutationHookResult = ReturnType<
  typeof useUpdatePayAppRateTableItemMutation
>
export type UpdatePayAppRateTableItemMutationResult =
  Apollo.MutationResult<UpdatePayAppRateTableItemMutation>
export type UpdatePayAppRateTableItemMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppRateTableItemMutation,
  UpdatePayAppRateTableItemMutationVariables
>
export const DeletePayAppRateTableItemDocument = gql`
  mutation deletePayAppRateTableItem($input: DeletePayAppRateTableItemInput!) {
    deletePayAppRateTableItem(input: $input) {
      id
    }
  }
`
export type DeletePayAppRateTableItemMutationFn = Apollo.MutationFunction<
  DeletePayAppRateTableItemMutation,
  DeletePayAppRateTableItemMutationVariables
>

/**
 * __useDeletePayAppRateTableItemMutation__
 *
 * To run a mutation, you first call `useDeletePayAppRateTableItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayAppRateTableItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayAppRateTableItemMutation, { data, loading, error }] = useDeletePayAppRateTableItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePayAppRateTableItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePayAppRateTableItemMutation,
    DeletePayAppRateTableItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePayAppRateTableItemMutation,
    DeletePayAppRateTableItemMutationVariables
  >(DeletePayAppRateTableItemDocument, options)
}
export type DeletePayAppRateTableItemMutationHookResult = ReturnType<
  typeof useDeletePayAppRateTableItemMutation
>
export type DeletePayAppRateTableItemMutationResult =
  Apollo.MutationResult<DeletePayAppRateTableItemMutation>
export type DeletePayAppRateTableItemMutationOptions = Apollo.BaseMutationOptions<
  DeletePayAppRateTableItemMutation,
  DeletePayAppRateTableItemMutationVariables
>
export const CreateChangeOrderRequestRateTableItemDocument = gql`
  mutation createChangeOrderRequestRateTableItem(
    $input: CreateChangeOrderRequestRateTableItemInput!
  ) {
    createChangeOrderRequestRateTableItem(input: $input) {
      ...ChangeOrderRequestRateTableItemProperties
    }
  }
  ${ChangeOrderRequestRateTableItemPropertiesDoc}
`
export type CreateChangeOrderRequestRateTableItemMutationFn = Apollo.MutationFunction<
  CreateChangeOrderRequestRateTableItemMutation,
  CreateChangeOrderRequestRateTableItemMutationVariables
>

/**
 * __useCreateChangeOrderRequestRateTableItemMutation__
 *
 * To run a mutation, you first call `useCreateChangeOrderRequestRateTableItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeOrderRequestRateTableItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeOrderRequestRateTableItemMutation, { data, loading, error }] = useCreateChangeOrderRequestRateTableItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChangeOrderRequestRateTableItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChangeOrderRequestRateTableItemMutation,
    CreateChangeOrderRequestRateTableItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateChangeOrderRequestRateTableItemMutation,
    CreateChangeOrderRequestRateTableItemMutationVariables
  >(CreateChangeOrderRequestRateTableItemDocument, options)
}
export type CreateChangeOrderRequestRateTableItemMutationHookResult = ReturnType<
  typeof useCreateChangeOrderRequestRateTableItemMutation
>
export type CreateChangeOrderRequestRateTableItemMutationResult =
  Apollo.MutationResult<CreateChangeOrderRequestRateTableItemMutation>
export type CreateChangeOrderRequestRateTableItemMutationOptions = Apollo.BaseMutationOptions<
  CreateChangeOrderRequestRateTableItemMutation,
  CreateChangeOrderRequestRateTableItemMutationVariables
>
export const UpdateChangeOrderRequestRateTableItemDocument = gql`
  mutation updateChangeOrderRequestRateTableItem(
    $input: UpdateChangeOrderRequestRateTableItemInput!
  ) {
    updateChangeOrderRequestRateTableItem(input: $input) {
      ...ChangeOrderRequestRateTableItemProperties
    }
  }
  ${ChangeOrderRequestRateTableItemPropertiesDoc}
`
export type UpdateChangeOrderRequestRateTableItemMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderRequestRateTableItemMutation,
  UpdateChangeOrderRequestRateTableItemMutationVariables
>

/**
 * __useUpdateChangeOrderRequestRateTableItemMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderRequestRateTableItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderRequestRateTableItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderRequestRateTableItemMutation, { data, loading, error }] = useUpdateChangeOrderRequestRateTableItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderRequestRateTableItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderRequestRateTableItemMutation,
    UpdateChangeOrderRequestRateTableItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateChangeOrderRequestRateTableItemMutation,
    UpdateChangeOrderRequestRateTableItemMutationVariables
  >(UpdateChangeOrderRequestRateTableItemDocument, options)
}
export type UpdateChangeOrderRequestRateTableItemMutationHookResult = ReturnType<
  typeof useUpdateChangeOrderRequestRateTableItemMutation
>
export type UpdateChangeOrderRequestRateTableItemMutationResult =
  Apollo.MutationResult<UpdateChangeOrderRequestRateTableItemMutation>
export type UpdateChangeOrderRequestRateTableItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateChangeOrderRequestRateTableItemMutation,
  UpdateChangeOrderRequestRateTableItemMutationVariables
>
export const DeleteChangeOrderRequestRateTableItemDocument = gql`
  mutation deleteChangeOrderRequestRateTableItem(
    $input: DeleteChangeOrderRequestRateTableItemInput!
  ) {
    deleteChangeOrderRequestRateTableItem(input: $input) {
      id
    }
  }
`
export type DeleteChangeOrderRequestRateTableItemMutationFn = Apollo.MutationFunction<
  DeleteChangeOrderRequestRateTableItemMutation,
  DeleteChangeOrderRequestRateTableItemMutationVariables
>

/**
 * __useDeleteChangeOrderRequestRateTableItemMutation__
 *
 * To run a mutation, you first call `useDeleteChangeOrderRequestRateTableItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChangeOrderRequestRateTableItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChangeOrderRequestRateTableItemMutation, { data, loading, error }] = useDeleteChangeOrderRequestRateTableItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChangeOrderRequestRateTableItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChangeOrderRequestRateTableItemMutation,
    DeleteChangeOrderRequestRateTableItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteChangeOrderRequestRateTableItemMutation,
    DeleteChangeOrderRequestRateTableItemMutationVariables
  >(DeleteChangeOrderRequestRateTableItemDocument, options)
}
export type DeleteChangeOrderRequestRateTableItemMutationHookResult = ReturnType<
  typeof useDeleteChangeOrderRequestRateTableItemMutation
>
export type DeleteChangeOrderRequestRateTableItemMutationResult =
  Apollo.MutationResult<DeleteChangeOrderRequestRateTableItemMutation>
export type DeleteChangeOrderRequestRateTableItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteChangeOrderRequestRateTableItemMutation,
  DeleteChangeOrderRequestRateTableItemMutationVariables
>
export const SetCurrentBilledDocument = gql`
  mutation setCurrentBilled($input: SetCurrentBilledInput!) {
    setCurrentBilled(input: $input) {
      id
      currentBilled
      group {
        id
        subtotalAmount
        totalAmount
      }
      payApp {
        id
        currentBilled
      }
    }
  }
`
export type SetCurrentBilledMutationFn = Apollo.MutationFunction<
  SetCurrentBilledMutation,
  SetCurrentBilledMutationVariables
>

/**
 * __useSetCurrentBilledMutation__
 *
 * To run a mutation, you first call `useSetCurrentBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentBilledMutation, { data, loading, error }] = useSetCurrentBilledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCurrentBilledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCurrentBilledMutation,
    SetCurrentBilledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetCurrentBilledMutation, SetCurrentBilledMutationVariables>(
    SetCurrentBilledDocument,
    options
  )
}
export type SetCurrentBilledMutationHookResult = ReturnType<typeof useSetCurrentBilledMutation>
export type SetCurrentBilledMutationResult = Apollo.MutationResult<SetCurrentBilledMutation>
export type SetCurrentBilledMutationOptions = Apollo.BaseMutationOptions<
  SetCurrentBilledMutation,
  SetCurrentBilledMutationVariables
>
export const SetCurrentPricingDocument = gql`
  mutation setCurrentPricing($input: SetCurrentPricingInput!) {
    setCurrentPricing(input: $input) {
      id
      currentPriced
      group {
        id
        subtotalAmount
        totalAmount
      }
      changeOrderRequest {
        ...ChangeOrderRequestProperties
      }
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type SetCurrentPricingMutationFn = Apollo.MutationFunction<
  SetCurrentPricingMutation,
  SetCurrentPricingMutationVariables
>

/**
 * __useSetCurrentPricingMutation__
 *
 * To run a mutation, you first call `useSetCurrentPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentPricingMutation, { data, loading, error }] = useSetCurrentPricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCurrentPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCurrentPricingMutation,
    SetCurrentPricingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetCurrentPricingMutation, SetCurrentPricingMutationVariables>(
    SetCurrentPricingDocument,
    options
  )
}
export type SetCurrentPricingMutationHookResult = ReturnType<typeof useSetCurrentPricingMutation>
export type SetCurrentPricingMutationResult = Apollo.MutationResult<SetCurrentPricingMutation>
export type SetCurrentPricingMutationOptions = Apollo.BaseMutationOptions<
  SetCurrentPricingMutation,
  SetCurrentPricingMutationVariables
>
export const ResetPayAppWithContractRateTableDocument = gql`
  mutation resetPayAppWithContractRateTable($input: ResetPayAppWithContractRateTableInput!) {
    resetPayAppWithContractRateTable(input: $input) {
      ...PayAppForOverviewProperties
    }
  }
  ${PayAppForOverviewPropertiesDoc}
`
export type ResetPayAppWithContractRateTableMutationFn = Apollo.MutationFunction<
  ResetPayAppWithContractRateTableMutation,
  ResetPayAppWithContractRateTableMutationVariables
>

/**
 * __useResetPayAppWithContractRateTableMutation__
 *
 * To run a mutation, you first call `useResetPayAppWithContractRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayAppWithContractRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayAppWithContractRateTableMutation, { data, loading, error }] = useResetPayAppWithContractRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPayAppWithContractRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPayAppWithContractRateTableMutation,
    ResetPayAppWithContractRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPayAppWithContractRateTableMutation,
    ResetPayAppWithContractRateTableMutationVariables
  >(ResetPayAppWithContractRateTableDocument, options)
}
export type ResetPayAppWithContractRateTableMutationHookResult = ReturnType<
  typeof useResetPayAppWithContractRateTableMutation
>
export type ResetPayAppWithContractRateTableMutationResult =
  Apollo.MutationResult<ResetPayAppWithContractRateTableMutation>
export type ResetPayAppWithContractRateTableMutationOptions = Apollo.BaseMutationOptions<
  ResetPayAppWithContractRateTableMutation,
  ResetPayAppWithContractRateTableMutationVariables
>
export const ResetChangeOrderRequestWithContractRateTableDocument = gql`
  mutation resetChangeOrderRequestWithContractRateTable(
    $input: ResetChangeOrderRequestWithContractRateTableInput!
  ) {
    resetChangeOrderRequestWithContractRateTable(input: $input) {
      ...ChangeOrderRequestProperties
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type ResetChangeOrderRequestWithContractRateTableMutationFn = Apollo.MutationFunction<
  ResetChangeOrderRequestWithContractRateTableMutation,
  ResetChangeOrderRequestWithContractRateTableMutationVariables
>

/**
 * __useResetChangeOrderRequestWithContractRateTableMutation__
 *
 * To run a mutation, you first call `useResetChangeOrderRequestWithContractRateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChangeOrderRequestWithContractRateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChangeOrderRequestWithContractRateTableMutation, { data, loading, error }] = useResetChangeOrderRequestWithContractRateTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetChangeOrderRequestWithContractRateTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetChangeOrderRequestWithContractRateTableMutation,
    ResetChangeOrderRequestWithContractRateTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetChangeOrderRequestWithContractRateTableMutation,
    ResetChangeOrderRequestWithContractRateTableMutationVariables
  >(ResetChangeOrderRequestWithContractRateTableDocument, options)
}
export type ResetChangeOrderRequestWithContractRateTableMutationHookResult = ReturnType<
  typeof useResetChangeOrderRequestWithContractRateTableMutation
>
export type ResetChangeOrderRequestWithContractRateTableMutationResult =
  Apollo.MutationResult<ResetChangeOrderRequestWithContractRateTableMutation>
export type ResetChangeOrderRequestWithContractRateTableMutationOptions =
  Apollo.BaseMutationOptions<
    ResetChangeOrderRequestWithContractRateTableMutation,
    ResetChangeOrderRequestWithContractRateTableMutationVariables
  >
export const ResetPayAppWithContractRateTableFeesDocument = gql`
  mutation resetPayAppWithContractRateTableFees(
    $input: ResetPayAppWithContractRateTableFeesInput!
  ) {
    resetPayAppWithContractRateTableFees(input: $input) {
      ...PayAppForOverviewProperties
    }
  }
  ${PayAppForOverviewPropertiesDoc}
`
export type ResetPayAppWithContractRateTableFeesMutationFn = Apollo.MutationFunction<
  ResetPayAppWithContractRateTableFeesMutation,
  ResetPayAppWithContractRateTableFeesMutationVariables
>

/**
 * __useResetPayAppWithContractRateTableFeesMutation__
 *
 * To run a mutation, you first call `useResetPayAppWithContractRateTableFeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayAppWithContractRateTableFeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayAppWithContractRateTableFeesMutation, { data, loading, error }] = useResetPayAppWithContractRateTableFeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPayAppWithContractRateTableFeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPayAppWithContractRateTableFeesMutation,
    ResetPayAppWithContractRateTableFeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPayAppWithContractRateTableFeesMutation,
    ResetPayAppWithContractRateTableFeesMutationVariables
  >(ResetPayAppWithContractRateTableFeesDocument, options)
}
export type ResetPayAppWithContractRateTableFeesMutationHookResult = ReturnType<
  typeof useResetPayAppWithContractRateTableFeesMutation
>
export type ResetPayAppWithContractRateTableFeesMutationResult =
  Apollo.MutationResult<ResetPayAppWithContractRateTableFeesMutation>
export type ResetPayAppWithContractRateTableFeesMutationOptions = Apollo.BaseMutationOptions<
  ResetPayAppWithContractRateTableFeesMutation,
  ResetPayAppWithContractRateTableFeesMutationVariables
>
export const ResetChangeOrderRequestWithContractRateTableFeesDocument = gql`
  mutation resetChangeOrderRequestWithContractRateTableFees(
    $input: ResetChangeOrderRequestWithContractRateTableFeesInput!
  ) {
    resetChangeOrderRequestWithContractRateTableFees(input: $input) {
      ...ChangeOrderRequestProperties
    }
  }
  ${ChangeOrderRequestPropertiesDoc}
`
export type ResetChangeOrderRequestWithContractRateTableFeesMutationFn = Apollo.MutationFunction<
  ResetChangeOrderRequestWithContractRateTableFeesMutation,
  ResetChangeOrderRequestWithContractRateTableFeesMutationVariables
>

/**
 * __useResetChangeOrderRequestWithContractRateTableFeesMutation__
 *
 * To run a mutation, you first call `useResetChangeOrderRequestWithContractRateTableFeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChangeOrderRequestWithContractRateTableFeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChangeOrderRequestWithContractRateTableFeesMutation, { data, loading, error }] = useResetChangeOrderRequestWithContractRateTableFeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetChangeOrderRequestWithContractRateTableFeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetChangeOrderRequestWithContractRateTableFeesMutation,
    ResetChangeOrderRequestWithContractRateTableFeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetChangeOrderRequestWithContractRateTableFeesMutation,
    ResetChangeOrderRequestWithContractRateTableFeesMutationVariables
  >(ResetChangeOrderRequestWithContractRateTableFeesDocument, options)
}
export type ResetChangeOrderRequestWithContractRateTableFeesMutationHookResult = ReturnType<
  typeof useResetChangeOrderRequestWithContractRateTableFeesMutation
>
export type ResetChangeOrderRequestWithContractRateTableFeesMutationResult =
  Apollo.MutationResult<ResetChangeOrderRequestWithContractRateTableFeesMutation>
export type ResetChangeOrderRequestWithContractRateTableFeesMutationOptions =
  Apollo.BaseMutationOptions<
    ResetChangeOrderRequestWithContractRateTableFeesMutation,
    ResetChangeOrderRequestWithContractRateTableFeesMutationVariables
  >
export const GenerateIntegrationInvoiceCodeDocument = gql`
  query generateIntegrationInvoiceCode($input: GenerateIntegrationInvoiceCodeInput!) {
    generateIntegrationInvoiceCode(input: $input)
  }
`

/**
 * __useGenerateIntegrationInvoiceCodeQuery__
 *
 * To run a query within a React component, call `useGenerateIntegrationInvoiceCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateIntegrationInvoiceCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateIntegrationInvoiceCodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateIntegrationInvoiceCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GenerateIntegrationInvoiceCodeQuery,
    GenerateIntegrationInvoiceCodeQueryVariables
  > &
    (
      | { variables: GenerateIntegrationInvoiceCodeQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GenerateIntegrationInvoiceCodeQuery,
    GenerateIntegrationInvoiceCodeQueryVariables
  >(GenerateIntegrationInvoiceCodeDocument, options)
}
export function useGenerateIntegrationInvoiceCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenerateIntegrationInvoiceCodeQuery,
    GenerateIntegrationInvoiceCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GenerateIntegrationInvoiceCodeQuery,
    GenerateIntegrationInvoiceCodeQueryVariables
  >(GenerateIntegrationInvoiceCodeDocument, options)
}
export function useGenerateIntegrationInvoiceCodeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GenerateIntegrationInvoiceCodeQuery,
        GenerateIntegrationInvoiceCodeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GenerateIntegrationInvoiceCodeQuery,
    GenerateIntegrationInvoiceCodeQueryVariables
  >(GenerateIntegrationInvoiceCodeDocument, options)
}
export type GenerateIntegrationInvoiceCodeQueryHookResult = ReturnType<
  typeof useGenerateIntegrationInvoiceCodeQuery
>
export type GenerateIntegrationInvoiceCodeLazyQueryHookResult = ReturnType<
  typeof useGenerateIntegrationInvoiceCodeLazyQuery
>
export type GenerateIntegrationInvoiceCodeSuspenseQueryHookResult = ReturnType<
  typeof useGenerateIntegrationInvoiceCodeSuspenseQuery
>
export type GenerateIntegrationInvoiceCodeQueryResult = Apollo.QueryResult<
  GenerateIntegrationInvoiceCodeQuery,
  GenerateIntegrationInvoiceCodeQueryVariables
>
export const EstimatePayAppPaymentDateDocument = gql`
  query estimatePayAppPaymentDate($input: EstimatePayAppPaymentDateInput!) {
    estimatePayAppPaymentDate(input: $input) {
      paymentDate
      estimationMethod
    }
  }
`

/**
 * __useEstimatePayAppPaymentDateQuery__
 *
 * To run a query within a React component, call `useEstimatePayAppPaymentDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatePayAppPaymentDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatePayAppPaymentDateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimatePayAppPaymentDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimatePayAppPaymentDateQuery,
    EstimatePayAppPaymentDateQueryVariables
  > &
    ({ variables: EstimatePayAppPaymentDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EstimatePayAppPaymentDateQuery, EstimatePayAppPaymentDateQueryVariables>(
    EstimatePayAppPaymentDateDocument,
    options
  )
}
export function useEstimatePayAppPaymentDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatePayAppPaymentDateQuery,
    EstimatePayAppPaymentDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EstimatePayAppPaymentDateQuery,
    EstimatePayAppPaymentDateQueryVariables
  >(EstimatePayAppPaymentDateDocument, options)
}
export function useEstimatePayAppPaymentDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EstimatePayAppPaymentDateQuery,
        EstimatePayAppPaymentDateQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    EstimatePayAppPaymentDateQuery,
    EstimatePayAppPaymentDateQueryVariables
  >(EstimatePayAppPaymentDateDocument, options)
}
export type EstimatePayAppPaymentDateQueryHookResult = ReturnType<
  typeof useEstimatePayAppPaymentDateQuery
>
export type EstimatePayAppPaymentDateLazyQueryHookResult = ReturnType<
  typeof useEstimatePayAppPaymentDateLazyQuery
>
export type EstimatePayAppPaymentDateSuspenseQueryHookResult = ReturnType<
  typeof useEstimatePayAppPaymentDateSuspenseQuery
>
export type EstimatePayAppPaymentDateQueryResult = Apollo.QueryResult<
  EstimatePayAppPaymentDateQuery,
  EstimatePayAppPaymentDateQueryVariables
>
export const AggregateCashForecastDocument = gql`
  query aggregateCashForecast($input: AggregateCashForecastInput!) {
    aggregateCashForecast(input: $input) {
      amountProjectedThisPeriod
      totalOpenInvoiceAmount
      totalUnknownPredictedInvoiceAmount
      totalPayAppUnknownPredictedInvoiceAmount
      forecast {
        startDate
        period
        amountOutstanding
      }
    }
  }
`

/**
 * __useAggregateCashForecastQuery__
 *
 * To run a query within a React component, call `useAggregateCashForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCashForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCashForecastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAggregateCashForecastQuery(
  baseOptions: Apollo.QueryHookOptions<
    AggregateCashForecastQuery,
    AggregateCashForecastQueryVariables
  > &
    ({ variables: AggregateCashForecastQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AggregateCashForecastQuery, AggregateCashForecastQueryVariables>(
    AggregateCashForecastDocument,
    options
  )
}
export function useAggregateCashForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AggregateCashForecastQuery,
    AggregateCashForecastQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AggregateCashForecastQuery, AggregateCashForecastQueryVariables>(
    AggregateCashForecastDocument,
    options
  )
}
export function useAggregateCashForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AggregateCashForecastQuery,
        AggregateCashForecastQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AggregateCashForecastQuery, AggregateCashForecastQueryVariables>(
    AggregateCashForecastDocument,
    options
  )
}
export type AggregateCashForecastQueryHookResult = ReturnType<typeof useAggregateCashForecastQuery>
export type AggregateCashForecastLazyQueryHookResult = ReturnType<
  typeof useAggregateCashForecastLazyQuery
>
export type AggregateCashForecastSuspenseQueryHookResult = ReturnType<
  typeof useAggregateCashForecastSuspenseQuery
>
export type AggregateCashForecastQueryResult = Apollo.QueryResult<
  AggregateCashForecastQuery,
  AggregateCashForecastQueryVariables
>
export const VendorNumberForGeneralContractorDocument = gql`
  query vendorNumberForGeneralContractor($input: VendorNumberForGeneralContractorInput!) {
    vendorNumberForGeneralContractor(input: $input)
  }
`

/**
 * __useVendorNumberForGeneralContractorQuery__
 *
 * To run a query within a React component, call `useVendorNumberForGeneralContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorNumberForGeneralContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorNumberForGeneralContractorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorNumberForGeneralContractorQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorNumberForGeneralContractorQuery,
    VendorNumberForGeneralContractorQueryVariables
  > &
    (
      | { variables: VendorNumberForGeneralContractorQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VendorNumberForGeneralContractorQuery,
    VendorNumberForGeneralContractorQueryVariables
  >(VendorNumberForGeneralContractorDocument, options)
}
export function useVendorNumberForGeneralContractorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorNumberForGeneralContractorQuery,
    VendorNumberForGeneralContractorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VendorNumberForGeneralContractorQuery,
    VendorNumberForGeneralContractorQueryVariables
  >(VendorNumberForGeneralContractorDocument, options)
}
export function useVendorNumberForGeneralContractorSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VendorNumberForGeneralContractorQuery,
        VendorNumberForGeneralContractorQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VendorNumberForGeneralContractorQuery,
    VendorNumberForGeneralContractorQueryVariables
  >(VendorNumberForGeneralContractorDocument, options)
}
export type VendorNumberForGeneralContractorQueryHookResult = ReturnType<
  typeof useVendorNumberForGeneralContractorQuery
>
export type VendorNumberForGeneralContractorLazyQueryHookResult = ReturnType<
  typeof useVendorNumberForGeneralContractorLazyQuery
>
export type VendorNumberForGeneralContractorSuspenseQueryHookResult = ReturnType<
  typeof useVendorNumberForGeneralContractorSuspenseQuery
>
export type VendorNumberForGeneralContractorQueryResult = Apollo.QueryResult<
  VendorNumberForGeneralContractorQuery,
  VendorNumberForGeneralContractorQueryVariables
>
export const ChangeOrderReportDocument = gql`
  query changeOrderReport($input: ChangeOrderReportInput!) {
    changeOrderReport(input: $input) {
      changeOrderSummaryReport {
        ...ChangeOrderSummaryReportProperties
      }
      pendingChangeOrderReport {
        ...PendingChangeOrderReportProperties
      }
    }
  }
  ${ChangeOrderSummaryReportPropertiesDoc}
  ${PendingChangeOrderReportPropertiesDoc}
`

/**
 * __useChangeOrderReportQuery__
 *
 * To run a query within a React component, call `useChangeOrderReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOrderReportQuery(
  baseOptions: Apollo.QueryHookOptions<ChangeOrderReportQuery, ChangeOrderReportQueryVariables> &
    ({ variables: ChangeOrderReportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChangeOrderReportQuery, ChangeOrderReportQueryVariables>(
    ChangeOrderReportDocument,
    options
  )
}
export function useChangeOrderReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChangeOrderReportQuery, ChangeOrderReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ChangeOrderReportQuery, ChangeOrderReportQueryVariables>(
    ChangeOrderReportDocument,
    options
  )
}
export function useChangeOrderReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ChangeOrderReportQuery, ChangeOrderReportQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ChangeOrderReportQuery, ChangeOrderReportQueryVariables>(
    ChangeOrderReportDocument,
    options
  )
}
export type ChangeOrderReportQueryHookResult = ReturnType<typeof useChangeOrderReportQuery>
export type ChangeOrderReportLazyQueryHookResult = ReturnType<typeof useChangeOrderReportLazyQuery>
export type ChangeOrderReportSuspenseQueryHookResult = ReturnType<
  typeof useChangeOrderReportSuspenseQuery
>
export type ChangeOrderReportQueryResult = Apollo.QueryResult<
  ChangeOrderReportQuery,
  ChangeOrderReportQueryVariables
>
export const UpdateWorksheetLineItemsDocument = gql`
  mutation updateWorksheetLineItems($input: UpdateWorksheetLineItemsInput!) {
    updateWorksheetLineItems(input: $input) {
      ...SovProperties
      lineItems {
        ...SovLineItemProperties
        preSitelineRetention
      }
    }
  }
  ${SovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
`
export type UpdateWorksheetLineItemsMutationFn = Apollo.MutationFunction<
  UpdateWorksheetLineItemsMutation,
  UpdateWorksheetLineItemsMutationVariables
>

/**
 * __useUpdateWorksheetLineItemsMutation__
 *
 * To run a mutation, you first call `useUpdateWorksheetLineItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorksheetLineItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorksheetLineItemsMutation, { data, loading, error }] = useUpdateWorksheetLineItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorksheetLineItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorksheetLineItemsMutation,
    UpdateWorksheetLineItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateWorksheetLineItemsMutation,
    UpdateWorksheetLineItemsMutationVariables
  >(UpdateWorksheetLineItemsDocument, options)
}
export type UpdateWorksheetLineItemsMutationHookResult = ReturnType<
  typeof useUpdateWorksheetLineItemsMutation
>
export type UpdateWorksheetLineItemsMutationResult =
  Apollo.MutationResult<UpdateWorksheetLineItemsMutation>
export type UpdateWorksheetLineItemsMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorksheetLineItemsMutation,
  UpdateWorksheetLineItemsMutationVariables
>
export const UpdateWorksheetProgressBilledDocument = gql`
  mutation updateWorksheetProgressBilled($input: UpdateWorksheetProgressBilledInput!) {
    updateWorksheetProgressBilled(input: $input) {
      ...WorksheetLineItemProgressProperties
    }
  }
  ${WorksheetLineItemProgressPropertiesDoc}
`
export type UpdateWorksheetProgressBilledMutationFn = Apollo.MutationFunction<
  UpdateWorksheetProgressBilledMutation,
  UpdateWorksheetProgressBilledMutationVariables
>

/**
 * __useUpdateWorksheetProgressBilledMutation__
 *
 * To run a mutation, you first call `useUpdateWorksheetProgressBilledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorksheetProgressBilledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorksheetProgressBilledMutation, { data, loading, error }] = useUpdateWorksheetProgressBilledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorksheetProgressBilledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorksheetProgressBilledMutation,
    UpdateWorksheetProgressBilledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateWorksheetProgressBilledMutation,
    UpdateWorksheetProgressBilledMutationVariables
  >(UpdateWorksheetProgressBilledDocument, options)
}
export type UpdateWorksheetProgressBilledMutationHookResult = ReturnType<
  typeof useUpdateWorksheetProgressBilledMutation
>
export type UpdateWorksheetProgressBilledMutationResult =
  Apollo.MutationResult<UpdateWorksheetProgressBilledMutation>
export type UpdateWorksheetProgressBilledMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorksheetProgressBilledMutation,
  UpdateWorksheetProgressBilledMutationVariables
>
export const BulkAddPredictedPaymentDatesDocument = gql`
  mutation bulkAddPredictedPaymentDates($input: BulkAddPredictedPaymentDatesInput!) {
    bulkAddPredictedPaymentDates(input: $input) {
      id
      predictedPaymentDate
    }
  }
`
export type BulkAddPredictedPaymentDatesMutationFn = Apollo.MutationFunction<
  BulkAddPredictedPaymentDatesMutation,
  BulkAddPredictedPaymentDatesMutationVariables
>

/**
 * __useBulkAddPredictedPaymentDatesMutation__
 *
 * To run a mutation, you first call `useBulkAddPredictedPaymentDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddPredictedPaymentDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddPredictedPaymentDatesMutation, { data, loading, error }] = useBulkAddPredictedPaymentDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkAddPredictedPaymentDatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkAddPredictedPaymentDatesMutation,
    BulkAddPredictedPaymentDatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BulkAddPredictedPaymentDatesMutation,
    BulkAddPredictedPaymentDatesMutationVariables
  >(BulkAddPredictedPaymentDatesDocument, options)
}
export type BulkAddPredictedPaymentDatesMutationHookResult = ReturnType<
  typeof useBulkAddPredictedPaymentDatesMutation
>
export type BulkAddPredictedPaymentDatesMutationResult =
  Apollo.MutationResult<BulkAddPredictedPaymentDatesMutation>
export type BulkAddPredictedPaymentDatesMutationOptions = Apollo.BaseMutationOptions<
  BulkAddPredictedPaymentDatesMutation,
  BulkAddPredictedPaymentDatesMutationVariables
>
export const CompanyForVendorSettingsDocument = gql`
  query companyForVendorSettings($id: ID!) {
    company(id: $id) {
      id
      companyIntegrations {
        id
        type
        shortName
        longName
        archivedAt
      }
    }
  }
`

/**
 * __useCompanyForVendorSettingsQuery__
 *
 * To run a query within a React component, call `useCompanyForVendorSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForVendorSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForVendorSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyForVendorSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyForVendorSettingsQuery,
    CompanyForVendorSettingsQueryVariables
  > &
    ({ variables: CompanyForVendorSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyForVendorSettingsQuery, CompanyForVendorSettingsQueryVariables>(
    CompanyForVendorSettingsDocument,
    options
  )
}
export function useCompanyForVendorSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyForVendorSettingsQuery,
    CompanyForVendorSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyForVendorSettingsQuery, CompanyForVendorSettingsQueryVariables>(
    CompanyForVendorSettingsDocument,
    options
  )
}
export function useCompanyForVendorSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyForVendorSettingsQuery,
        CompanyForVendorSettingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyForVendorSettingsQuery,
    CompanyForVendorSettingsQueryVariables
  >(CompanyForVendorSettingsDocument, options)
}
export type CompanyForVendorSettingsQueryHookResult = ReturnType<
  typeof useCompanyForVendorSettingsQuery
>
export type CompanyForVendorSettingsLazyQueryHookResult = ReturnType<
  typeof useCompanyForVendorSettingsLazyQuery
>
export type CompanyForVendorSettingsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyForVendorSettingsSuspenseQuery
>
export type CompanyForVendorSettingsQueryResult = Apollo.QueryResult<
  CompanyForVendorSettingsQuery,
  CompanyForVendorSettingsQueryVariables
>
export const RemoveVendorIntegrationDocument = gql`
  mutation removeVendorIntegration($input: RemoveVendorIntegrationInput!) {
    removeVendorIntegration(input: $input) {
      ...VendorProperties
    }
  }
  ${VendorPropertiesDoc}
`
export type RemoveVendorIntegrationMutationFn = Apollo.MutationFunction<
  RemoveVendorIntegrationMutation,
  RemoveVendorIntegrationMutationVariables
>

/**
 * __useRemoveVendorIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveVendorIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVendorIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVendorIntegrationMutation, { data, loading, error }] = useRemoveVendorIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVendorIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVendorIntegrationMutation,
    RemoveVendorIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveVendorIntegrationMutation,
    RemoveVendorIntegrationMutationVariables
  >(RemoveVendorIntegrationDocument, options)
}
export type RemoveVendorIntegrationMutationHookResult = ReturnType<
  typeof useRemoveVendorIntegrationMutation
>
export type RemoveVendorIntegrationMutationResult =
  Apollo.MutationResult<RemoveVendorIntegrationMutation>
export type RemoveVendorIntegrationMutationOptions = Apollo.BaseMutationOptions<
  RemoveVendorIntegrationMutation,
  RemoveVendorIntegrationMutationVariables
>
export const RemoveVendorIntegrationsDocument = gql`
  mutation removeVendorIntegrations($input: RemoveVendorIntegrationsInput!) {
    removeVendorIntegrations(input: $input) {
      ...VendorProperties
    }
  }
  ${VendorPropertiesDoc}
`
export type RemoveVendorIntegrationsMutationFn = Apollo.MutationFunction<
  RemoveVendorIntegrationsMutation,
  RemoveVendorIntegrationsMutationVariables
>

/**
 * __useRemoveVendorIntegrationsMutation__
 *
 * To run a mutation, you first call `useRemoveVendorIntegrationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVendorIntegrationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVendorIntegrationsMutation, { data, loading, error }] = useRemoveVendorIntegrationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVendorIntegrationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVendorIntegrationsMutation,
    RemoveVendorIntegrationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveVendorIntegrationsMutation,
    RemoveVendorIntegrationsMutationVariables
  >(RemoveVendorIntegrationsDocument, options)
}
export type RemoveVendorIntegrationsMutationHookResult = ReturnType<
  typeof useRemoveVendorIntegrationsMutation
>
export type RemoveVendorIntegrationsMutationResult =
  Apollo.MutationResult<RemoveVendorIntegrationsMutation>
export type RemoveVendorIntegrationsMutationOptions = Apollo.BaseMutationOptions<
  RemoveVendorIntegrationsMutation,
  RemoveVendorIntegrationsMutationVariables
>
export const AddContractFilesDocument = gql`
  mutation addContractFiles($input: AddContractFileInput!) {
    addContractFiles(input: $input) {
      id
      files {
        ...StoredFileProperties
      }
    }
  }
  ${StoredFilePropertiesDoc}
`
export type AddContractFilesMutationFn = Apollo.MutationFunction<
  AddContractFilesMutation,
  AddContractFilesMutationVariables
>

/**
 * __useAddContractFilesMutation__
 *
 * To run a mutation, you first call `useAddContractFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContractFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContractFilesMutation, { data, loading, error }] = useAddContractFilesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContractFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddContractFilesMutation,
    AddContractFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddContractFilesMutation, AddContractFilesMutationVariables>(
    AddContractFilesDocument,
    options
  )
}
export type AddContractFilesMutationHookResult = ReturnType<typeof useAddContractFilesMutation>
export type AddContractFilesMutationResult = Apollo.MutationResult<AddContractFilesMutation>
export type AddContractFilesMutationOptions = Apollo.BaseMutationOptions<
  AddContractFilesMutation,
  AddContractFilesMutationVariables
>
export const DeleteContractFileDocument = gql`
  mutation deleteContractFile($input: DeleteContractFileInput!) {
    deleteContractFile(input: $input) {
      id
      files {
        ...StoredFileProperties
      }
    }
  }
  ${StoredFilePropertiesDoc}
`
export type DeleteContractFileMutationFn = Apollo.MutationFunction<
  DeleteContractFileMutation,
  DeleteContractFileMutationVariables
>

/**
 * __useDeleteContractFileMutation__
 *
 * To run a mutation, you first call `useDeleteContractFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractFileMutation, { data, loading, error }] = useDeleteContractFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContractFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContractFileMutation,
    DeleteContractFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteContractFileMutation, DeleteContractFileMutationVariables>(
    DeleteContractFileDocument,
    options
  )
}
export type DeleteContractFileMutationHookResult = ReturnType<typeof useDeleteContractFileMutation>
export type DeleteContractFileMutationResult = Apollo.MutationResult<DeleteContractFileMutation>
export type DeleteContractFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteContractFileMutation,
  DeleteContractFileMutationVariables
>
export const UpdateUserEmailAliasesDocument = gql`
  mutation updateUserEmailAliases($input: UpdateUserEmailAliasesInput!) {
    updateUserEmailAliases(input: $input) {
      id
      emailAlias {
        ...CompanyUserEmailAliasProperties
      }
    }
  }
  ${CompanyUserEmailAliasPropertiesDoc}
`
export type UpdateUserEmailAliasesMutationFn = Apollo.MutationFunction<
  UpdateUserEmailAliasesMutation,
  UpdateUserEmailAliasesMutationVariables
>

/**
 * __useUpdateUserEmailAliasesMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailAliasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailAliasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailAliasesMutation, { data, loading, error }] = useUpdateUserEmailAliasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEmailAliasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailAliasesMutation,
    UpdateUserEmailAliasesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserEmailAliasesMutation,
    UpdateUserEmailAliasesMutationVariables
  >(UpdateUserEmailAliasesDocument, options)
}
export type UpdateUserEmailAliasesMutationHookResult = ReturnType<
  typeof useUpdateUserEmailAliasesMutation
>
export type UpdateUserEmailAliasesMutationResult =
  Apollo.MutationResult<UpdateUserEmailAliasesMutation>
export type UpdateUserEmailAliasesMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailAliasesMutation,
  UpdateUserEmailAliasesMutationVariables
>
export const VerifyEmailAliasDocument = gql`
  mutation verifyEmailAlias($input: VerifyEmailAliasInput!) {
    verifyEmailAlias(input: $input) {
      ...CompanyUserEmailAliasProperties
    }
  }
  ${CompanyUserEmailAliasPropertiesDoc}
`
export type VerifyEmailAliasMutationFn = Apollo.MutationFunction<
  VerifyEmailAliasMutation,
  VerifyEmailAliasMutationVariables
>

/**
 * __useVerifyEmailAliasMutation__
 *
 * To run a mutation, you first call `useVerifyEmailAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailAliasMutation, { data, loading, error }] = useVerifyEmailAliasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailAliasMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailAliasMutation,
    VerifyEmailAliasMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyEmailAliasMutation, VerifyEmailAliasMutationVariables>(
    VerifyEmailAliasDocument,
    options
  )
}
export type VerifyEmailAliasMutationHookResult = ReturnType<typeof useVerifyEmailAliasMutation>
export type VerifyEmailAliasMutationResult = Apollo.MutationResult<VerifyEmailAliasMutation>
export type VerifyEmailAliasMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailAliasMutation,
  VerifyEmailAliasMutationVariables
>
export const ResendEmailAliasVerificationDocument = gql`
  mutation resendEmailAliasVerification($companyUserEmailAliasId: ID!) {
    resendEmailAliasVerification(companyUserEmailAliasId: $companyUserEmailAliasId) {
      ...CompanyUserEmailAliasProperties
    }
  }
  ${CompanyUserEmailAliasPropertiesDoc}
`
export type ResendEmailAliasVerificationMutationFn = Apollo.MutationFunction<
  ResendEmailAliasVerificationMutation,
  ResendEmailAliasVerificationMutationVariables
>

/**
 * __useResendEmailAliasVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailAliasVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailAliasVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailAliasVerificationMutation, { data, loading, error }] = useResendEmailAliasVerificationMutation({
 *   variables: {
 *      companyUserEmailAliasId: // value for 'companyUserEmailAliasId'
 *   },
 * });
 */
export function useResendEmailAliasVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailAliasVerificationMutation,
    ResendEmailAliasVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResendEmailAliasVerificationMutation,
    ResendEmailAliasVerificationMutationVariables
  >(ResendEmailAliasVerificationDocument, options)
}
export type ResendEmailAliasVerificationMutationHookResult = ReturnType<
  typeof useResendEmailAliasVerificationMutation
>
export type ResendEmailAliasVerificationMutationResult =
  Apollo.MutationResult<ResendEmailAliasVerificationMutation>
export type ResendEmailAliasVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailAliasVerificationMutation,
  ResendEmailAliasVerificationMutationVariables
>
export const RemovePayAppRequirementDocument = gql`
  mutation removePayAppRequirement($input: RemovePayAppRequirementInput!) {
    removePayAppRequirement(input: $input) {
      id
      payAppRequirementGroups {
        id
        payAppRequirements {
          id
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalFormTemplatePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type RemovePayAppRequirementMutationFn = Apollo.MutationFunction<
  RemovePayAppRequirementMutation,
  RemovePayAppRequirementMutationVariables
>

/**
 * __useRemovePayAppRequirementMutation__
 *
 * To run a mutation, you first call `useRemovePayAppRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePayAppRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePayAppRequirementMutation, { data, loading, error }] = useRemovePayAppRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePayAppRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePayAppRequirementMutation,
    RemovePayAppRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemovePayAppRequirementMutation,
    RemovePayAppRequirementMutationVariables
  >(RemovePayAppRequirementDocument, options)
}
export type RemovePayAppRequirementMutationHookResult = ReturnType<
  typeof useRemovePayAppRequirementMutation
>
export type RemovePayAppRequirementMutationResult =
  Apollo.MutationResult<RemovePayAppRequirementMutation>
export type RemovePayAppRequirementMutationOptions = Apollo.BaseMutationOptions<
  RemovePayAppRequirementMutation,
  RemovePayAppRequirementMutationVariables
>
export const UpdatePrimaryLienWaiverTemplatesDocument = gql`
  mutation updatePrimaryLienWaiverTemplates($input: UpdateLienWaiverTemplatesInput!) {
    updatePrimaryLienWaiverTemplates(input: $input) {
      id
      lienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type UpdatePrimaryLienWaiverTemplatesMutationFn = Apollo.MutationFunction<
  UpdatePrimaryLienWaiverTemplatesMutation,
  UpdatePrimaryLienWaiverTemplatesMutationVariables
>

/**
 * __useUpdatePrimaryLienWaiverTemplatesMutation__
 *
 * To run a mutation, you first call `useUpdatePrimaryLienWaiverTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrimaryLienWaiverTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrimaryLienWaiverTemplatesMutation, { data, loading, error }] = useUpdatePrimaryLienWaiverTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrimaryLienWaiverTemplatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrimaryLienWaiverTemplatesMutation,
    UpdatePrimaryLienWaiverTemplatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePrimaryLienWaiverTemplatesMutation,
    UpdatePrimaryLienWaiverTemplatesMutationVariables
  >(UpdatePrimaryLienWaiverTemplatesDocument, options)
}
export type UpdatePrimaryLienWaiverTemplatesMutationHookResult = ReturnType<
  typeof useUpdatePrimaryLienWaiverTemplatesMutation
>
export type UpdatePrimaryLienWaiverTemplatesMutationResult =
  Apollo.MutationResult<UpdatePrimaryLienWaiverTemplatesMutation>
export type UpdatePrimaryLienWaiverTemplatesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrimaryLienWaiverTemplatesMutation,
  UpdatePrimaryLienWaiverTemplatesMutationVariables
>
export const UpdateVendorLienWaiverTemplatesDocument = gql`
  mutation updateVendorLienWaiverTemplates($input: UpdateLienWaiverTemplatesInput!) {
    updateVendorLienWaiverTemplates(input: $input) {
      id
      lowerTierLienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      onboardedProjectVendorsStatus {
        ...OnboardedProjectVendorsStatusProperties
      }
    }
  }
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${OnboardedProjectVendorsStatusPropertiesDoc}
`
export type UpdateVendorLienWaiverTemplatesMutationFn = Apollo.MutationFunction<
  UpdateVendorLienWaiverTemplatesMutation,
  UpdateVendorLienWaiverTemplatesMutationVariables
>

/**
 * __useUpdateVendorLienWaiverTemplatesMutation__
 *
 * To run a mutation, you first call `useUpdateVendorLienWaiverTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorLienWaiverTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorLienWaiverTemplatesMutation, { data, loading, error }] = useUpdateVendorLienWaiverTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorLienWaiverTemplatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorLienWaiverTemplatesMutation,
    UpdateVendorLienWaiverTemplatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVendorLienWaiverTemplatesMutation,
    UpdateVendorLienWaiverTemplatesMutationVariables
  >(UpdateVendorLienWaiverTemplatesDocument, options)
}
export type UpdateVendorLienWaiverTemplatesMutationHookResult = ReturnType<
  typeof useUpdateVendorLienWaiverTemplatesMutation
>
export type UpdateVendorLienWaiverTemplatesMutationResult =
  Apollo.MutationResult<UpdateVendorLienWaiverTemplatesMutation>
export type UpdateVendorLienWaiverTemplatesMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorLienWaiverTemplatesMutation,
  UpdateVendorLienWaiverTemplatesMutationVariables
>
export const UpdateChangeOrderRequestLinkedChangeOrderRequestsDocument = gql`
  mutation updateChangeOrderRequestLinkedChangeOrderRequests(
    $input: UpdateChangeOrderRequestLinkedChangeOrderRequestsInput!
  ) {
    updateChangeOrderRequestLinkedChangeOrderRequests(input: $input) {
      id
      linkedChangeOrderRequests {
        id
        internalNumber
        generalContractorNumber
        name
        status
        amount
        sovLineItems {
          id
        }
      }
    }
  }
`
export type UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationFn = Apollo.MutationFunction<
  UpdateChangeOrderRequestLinkedChangeOrderRequestsMutation,
  UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationVariables
>

/**
 * __useUpdateChangeOrderRequestLinkedChangeOrderRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateChangeOrderRequestLinkedChangeOrderRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeOrderRequestLinkedChangeOrderRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeOrderRequestLinkedChangeOrderRequestsMutation, { data, loading, error }] = useUpdateChangeOrderRequestLinkedChangeOrderRequestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeOrderRequestLinkedChangeOrderRequestsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChangeOrderRequestLinkedChangeOrderRequestsMutation,
    UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateChangeOrderRequestLinkedChangeOrderRequestsMutation,
    UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationVariables
  >(UpdateChangeOrderRequestLinkedChangeOrderRequestsDocument, options)
}
export type UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationHookResult = ReturnType<
  typeof useUpdateChangeOrderRequestLinkedChangeOrderRequestsMutation
>
export type UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationResult =
  Apollo.MutationResult<UpdateChangeOrderRequestLinkedChangeOrderRequestsMutation>
export type UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateChangeOrderRequestLinkedChangeOrderRequestsMutation,
    UpdateChangeOrderRequestLinkedChangeOrderRequestsMutationVariables
  >
export const SetIncludeChangeOrderLogIncludedInPayAppPackageDocument = gql`
  mutation setIncludeChangeOrderLogIncludedInPayAppPackage(
    $input: SetIncludeChangeOrderLogInPayAppPackageInput!
  ) {
    setIncludeChangeOrderLogIncludedInPayAppPackage(input: $input) {
      id
      includeChangeOrderLogInPayAppPackage
    }
  }
`
export type SetIncludeChangeOrderLogIncludedInPayAppPackageMutationFn = Apollo.MutationFunction<
  SetIncludeChangeOrderLogIncludedInPayAppPackageMutation,
  SetIncludeChangeOrderLogIncludedInPayAppPackageMutationVariables
>

/**
 * __useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation__
 *
 * To run a mutation, you first call `useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIncludeChangeOrderLogIncludedInPayAppPackageMutation, { data, loading, error }] = useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIncludeChangeOrderLogIncludedInPayAppPackageMutation,
    SetIncludeChangeOrderLogIncludedInPayAppPackageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetIncludeChangeOrderLogIncludedInPayAppPackageMutation,
    SetIncludeChangeOrderLogIncludedInPayAppPackageMutationVariables
  >(SetIncludeChangeOrderLogIncludedInPayAppPackageDocument, options)
}
export type SetIncludeChangeOrderLogIncludedInPayAppPackageMutationHookResult = ReturnType<
  typeof useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation
>
export type SetIncludeChangeOrderLogIncludedInPayAppPackageMutationResult =
  Apollo.MutationResult<SetIncludeChangeOrderLogIncludedInPayAppPackageMutation>
export type SetIncludeChangeOrderLogIncludedInPayAppPackageMutationOptions =
  Apollo.BaseMutationOptions<
    SetIncludeChangeOrderLogIncludedInPayAppPackageMutation,
    SetIncludeChangeOrderLogIncludedInPayAppPackageMutationVariables
  >
export const ChangeOrderRequestActivityDocument = gql`
  query changeOrderRequestActivity($changeOrderRequestId: ID!) {
    changeOrderRequest(id: $changeOrderRequestId) {
      id
      events {
        ...ChangeOrderRequestEventProperties
      }
      comments {
        ...ChangeOrderRequestCommentProperties
      }
    }
  }
  ${ChangeOrderRequestEventPropertiesDoc}
  ${ChangeOrderRequestCommentPropertiesDoc}
`

/**
 * __useChangeOrderRequestActivityQuery__
 *
 * To run a query within a React component, call `useChangeOrderRequestActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderRequestActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderRequestActivityQuery({
 *   variables: {
 *      changeOrderRequestId: // value for 'changeOrderRequestId'
 *   },
 * });
 */
export function useChangeOrderRequestActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChangeOrderRequestActivityQuery,
    ChangeOrderRequestActivityQueryVariables
  > &
    ({ variables: ChangeOrderRequestActivityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ChangeOrderRequestActivityQuery, ChangeOrderRequestActivityQueryVariables>(
    ChangeOrderRequestActivityDocument,
    options
  )
}
export function useChangeOrderRequestActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChangeOrderRequestActivityQuery,
    ChangeOrderRequestActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChangeOrderRequestActivityQuery,
    ChangeOrderRequestActivityQueryVariables
  >(ChangeOrderRequestActivityDocument, options)
}
export function useChangeOrderRequestActivitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChangeOrderRequestActivityQuery,
        ChangeOrderRequestActivityQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ChangeOrderRequestActivityQuery,
    ChangeOrderRequestActivityQueryVariables
  >(ChangeOrderRequestActivityDocument, options)
}
export type ChangeOrderRequestActivityQueryHookResult = ReturnType<
  typeof useChangeOrderRequestActivityQuery
>
export type ChangeOrderRequestActivityLazyQueryHookResult = ReturnType<
  typeof useChangeOrderRequestActivityLazyQuery
>
export type ChangeOrderRequestActivitySuspenseQueryHookResult = ReturnType<
  typeof useChangeOrderRequestActivitySuspenseQuery
>
export type ChangeOrderRequestActivityQueryResult = Apollo.QueryResult<
  ChangeOrderRequestActivityQuery,
  ChangeOrderRequestActivityQueryVariables
>
export const NewChangeOrderRequestEventDocument = gql`
  subscription newChangeOrderRequestEvent($changeOrderRequestId: ID!) {
    newChangeOrderRequestEvent(changeOrderRequestId: $changeOrderRequestId) {
      id
      events {
        ...ChangeOrderRequestEventProperties
      }
      comments {
        ...ChangeOrderRequestCommentProperties
      }
    }
  }
  ${ChangeOrderRequestEventPropertiesDoc}
  ${ChangeOrderRequestCommentPropertiesDoc}
`

/**
 * __useNewChangeOrderRequestEventSubscription__
 *
 * To run a query within a React component, call `useNewChangeOrderRequestEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChangeOrderRequestEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChangeOrderRequestEventSubscription({
 *   variables: {
 *      changeOrderRequestId: // value for 'changeOrderRequestId'
 *   },
 * });
 */
export function useNewChangeOrderRequestEventSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewChangeOrderRequestEventSubscription,
    NewChangeOrderRequestEventSubscriptionVariables
  > &
    (
      | { variables: NewChangeOrderRequestEventSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    NewChangeOrderRequestEventSubscription,
    NewChangeOrderRequestEventSubscriptionVariables
  >(NewChangeOrderRequestEventDocument, options)
}
export type NewChangeOrderRequestEventSubscriptionHookResult = ReturnType<
  typeof useNewChangeOrderRequestEventSubscription
>
export type NewChangeOrderRequestEventSubscriptionResult =
  Apollo.SubscriptionResult<NewChangeOrderRequestEventSubscription>
export const NewChangeOrderRequestCommentDocument = gql`
  subscription newChangeOrderRequestComment($changeOrderRequestId: ID!) {
    newChangeOrderRequestComment(changeOrderRequestId: $changeOrderRequestId) {
      ...ChangeOrderRequestCommentProperties
    }
  }
  ${ChangeOrderRequestCommentPropertiesDoc}
`

/**
 * __useNewChangeOrderRequestCommentSubscription__
 *
 * To run a query within a React component, call `useNewChangeOrderRequestCommentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChangeOrderRequestCommentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChangeOrderRequestCommentSubscription({
 *   variables: {
 *      changeOrderRequestId: // value for 'changeOrderRequestId'
 *   },
 * });
 */
export function useNewChangeOrderRequestCommentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewChangeOrderRequestCommentSubscription,
    NewChangeOrderRequestCommentSubscriptionVariables
  > &
    (
      | { variables: NewChangeOrderRequestCommentSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    NewChangeOrderRequestCommentSubscription,
    NewChangeOrderRequestCommentSubscriptionVariables
  >(NewChangeOrderRequestCommentDocument, options)
}
export type NewChangeOrderRequestCommentSubscriptionHookResult = ReturnType<
  typeof useNewChangeOrderRequestCommentSubscription
>
export type NewChangeOrderRequestCommentSubscriptionResult =
  Apollo.SubscriptionResult<NewChangeOrderRequestCommentSubscription>
export const AddChangeOrderRequestCommentDocument = gql`
  mutation addChangeOrderRequestComment($input: AddChangeOrderRequestCommentInput!) {
    addChangeOrderRequestComment(input: $input) {
      ...ChangeOrderRequestCommentProperties
      changeOrderRequest {
        id
      }
    }
  }
  ${ChangeOrderRequestCommentPropertiesDoc}
`
export type AddChangeOrderRequestCommentMutationFn = Apollo.MutationFunction<
  AddChangeOrderRequestCommentMutation,
  AddChangeOrderRequestCommentMutationVariables
>

/**
 * __useAddChangeOrderRequestCommentMutation__
 *
 * To run a mutation, you first call `useAddChangeOrderRequestCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChangeOrderRequestCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChangeOrderRequestCommentMutation, { data, loading, error }] = useAddChangeOrderRequestCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChangeOrderRequestCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddChangeOrderRequestCommentMutation,
    AddChangeOrderRequestCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddChangeOrderRequestCommentMutation,
    AddChangeOrderRequestCommentMutationVariables
  >(AddChangeOrderRequestCommentDocument, options)
}
export type AddChangeOrderRequestCommentMutationHookResult = ReturnType<
  typeof useAddChangeOrderRequestCommentMutation
>
export type AddChangeOrderRequestCommentMutationResult =
  Apollo.MutationResult<AddChangeOrderRequestCommentMutation>
export type AddChangeOrderRequestCommentMutationOptions = Apollo.BaseMutationOptions<
  AddChangeOrderRequestCommentMutation,
  AddChangeOrderRequestCommentMutationVariables
>
export const GetContractsForVendorsDocument = gql`
  query getContractsForVendors($input: GetContractsInput!) {
    contracts(input: $input) {
      id
      billingType
      status
      internalProjectNumber
      vendorComplianceStatus
      vendorNumPendingLegalDocuments
      vendorNumPendingLienWaivers
      integrations {
        id
        type
        shortName
        longName
      }
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      vendorContracts {
        ...MinimalVendorContractProperties
        contract {
          id
          project {
            id
          }
        }
        vendor {
          id
          name
          address {
            ...LocationProperties
          }
        }
      }
      onboardedProjectVendorsStatus {
        ...OnboardedProjectVendorsStatusProperties
      }
    }
  }
  ${MinimalVendorContractPropertiesDoc}
  ${LocationPropertiesDoc}
  ${OnboardedProjectVendorsStatusPropertiesDoc}
`

/**
 * __useGetContractsForVendorsQuery__
 *
 * To run a query within a React component, call `useGetContractsForVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForVendorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForVendorsQuery,
    GetContractsForVendorsQueryVariables
  > &
    ({ variables: GetContractsForVendorsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsForVendorsQuery, GetContractsForVendorsQueryVariables>(
    GetContractsForVendorsDocument,
    options
  )
}
export function useGetContractsForVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForVendorsQuery,
    GetContractsForVendorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractsForVendorsQuery, GetContractsForVendorsQueryVariables>(
    GetContractsForVendorsDocument,
    options
  )
}
export function useGetContractsForVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractsForVendorsQuery,
        GetContractsForVendorsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractsForVendorsQuery, GetContractsForVendorsQueryVariables>(
    GetContractsForVendorsDocument,
    options
  )
}
export type GetContractsForVendorsQueryHookResult = ReturnType<
  typeof useGetContractsForVendorsQuery
>
export type GetContractsForVendorsLazyQueryHookResult = ReturnType<
  typeof useGetContractsForVendorsLazyQuery
>
export type GetContractsForVendorsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForVendorsSuspenseQuery
>
export type GetContractsForVendorsQueryResult = Apollo.QueryResult<
  GetContractsForVendorsQuery,
  GetContractsForVendorsQueryVariables
>
export const CopyContractVendorsDocument = gql`
  mutation copyContractVendors($input: CopyContractVendorsInput!) {
    copyContractVendors(input: $input) {
      id
    }
  }
`
export type CopyContractVendorsMutationFn = Apollo.MutationFunction<
  CopyContractVendorsMutation,
  CopyContractVendorsMutationVariables
>

/**
 * __useCopyContractVendorsMutation__
 *
 * To run a mutation, you first call `useCopyContractVendorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyContractVendorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyContractVendorsMutation, { data, loading, error }] = useCopyContractVendorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyContractVendorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CopyContractVendorsMutation,
    CopyContractVendorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CopyContractVendorsMutation, CopyContractVendorsMutationVariables>(
    CopyContractVendorsDocument,
    options
  )
}
export type CopyContractVendorsMutationHookResult = ReturnType<
  typeof useCopyContractVendorsMutation
>
export type CopyContractVendorsMutationResult = Apollo.MutationResult<CopyContractVendorsMutation>
export type CopyContractVendorsMutationOptions = Apollo.BaseMutationOptions<
  CopyContractVendorsMutation,
  CopyContractVendorsMutationVariables
>
export const GetContractsForLienWaiverTrackerDocument = gql`
  query getContractsForLienWaiverTracker($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      contracts {
        id
        lowerTierConditionalAmountWaived
        lowerTierUnconditionalAmountWaived
        internalProjectNumber
        billingType
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
        onboardedProjectVendorsStatus {
          completedOnboarding
        }
      }
    }
  }
`

/**
 * __useGetContractsForLienWaiverTrackerQuery__
 *
 * To run a query within a React component, call `useGetContractsForLienWaiverTrackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForLienWaiverTrackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForLienWaiverTrackerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForLienWaiverTrackerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForLienWaiverTrackerQuery,
    GetContractsForLienWaiverTrackerQueryVariables
  > &
    (
      | { variables: GetContractsForLienWaiverTrackerQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractsForLienWaiverTrackerQuery,
    GetContractsForLienWaiverTrackerQueryVariables
  >(GetContractsForLienWaiverTrackerDocument, options)
}
export function useGetContractsForLienWaiverTrackerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForLienWaiverTrackerQuery,
    GetContractsForLienWaiverTrackerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractsForLienWaiverTrackerQuery,
    GetContractsForLienWaiverTrackerQueryVariables
  >(GetContractsForLienWaiverTrackerDocument, options)
}
export function useGetContractsForLienWaiverTrackerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractsForLienWaiverTrackerQuery,
        GetContractsForLienWaiverTrackerQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractsForLienWaiverTrackerQuery,
    GetContractsForLienWaiverTrackerQueryVariables
  >(GetContractsForLienWaiverTrackerDocument, options)
}
export type GetContractsForLienWaiverTrackerQueryHookResult = ReturnType<
  typeof useGetContractsForLienWaiverTrackerQuery
>
export type GetContractsForLienWaiverTrackerLazyQueryHookResult = ReturnType<
  typeof useGetContractsForLienWaiverTrackerLazyQuery
>
export type GetContractsForLienWaiverTrackerSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForLienWaiverTrackerSuspenseQuery
>
export type GetContractsForLienWaiverTrackerQueryResult = Apollo.QueryResult<
  GetContractsForLienWaiverTrackerQuery,
  GetContractsForLienWaiverTrackerQueryVariables
>
export const PaginatedVendorContractsDocument = gql`
  query paginatedVendorContracts($input: PaginatedVendorContractsInput!) {
    paginatedVendorContracts(input: $input) {
      cursor
      hasNext
      vendorContracts {
        id
        conditionalAmountWaived
        unconditionalAmountWaived
        vendor {
          id
          name
        }
        lowerTierTo {
          id
          vendor {
            id
            name
          }
        }
        contract {
          id
          internalProjectNumber
          project {
            id
            name
            projectNumber
            generalContractor {
              companyName
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __usePaginatedVendorContractsQuery__
 *
 * To run a query within a React component, call `usePaginatedVendorContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedVendorContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedVendorContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedVendorContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedVendorContractsQuery,
    PaginatedVendorContractsQueryVariables
  > &
    ({ variables: PaginatedVendorContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PaginatedVendorContractsQuery, PaginatedVendorContractsQueryVariables>(
    PaginatedVendorContractsDocument,
    options
  )
}
export function usePaginatedVendorContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedVendorContractsQuery,
    PaginatedVendorContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PaginatedVendorContractsQuery, PaginatedVendorContractsQueryVariables>(
    PaginatedVendorContractsDocument,
    options
  )
}
export function usePaginatedVendorContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedVendorContractsQuery,
        PaginatedVendorContractsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PaginatedVendorContractsQuery,
    PaginatedVendorContractsQueryVariables
  >(PaginatedVendorContractsDocument, options)
}
export type PaginatedVendorContractsQueryHookResult = ReturnType<
  typeof usePaginatedVendorContractsQuery
>
export type PaginatedVendorContractsLazyQueryHookResult = ReturnType<
  typeof usePaginatedVendorContractsLazyQuery
>
export type PaginatedVendorContractsSuspenseQueryHookResult = ReturnType<
  typeof usePaginatedVendorContractsSuspenseQuery
>
export type PaginatedVendorContractsQueryResult = Apollo.QueryResult<
  PaginatedVendorContractsQuery,
  PaginatedVendorContractsQueryVariables
>
export const VendorForVendorContractsDocument = gql`
  query vendorForVendorContracts($id: ID!) {
    vendor(id: $id) {
      id
      vendorContracts {
        id
        conditionalAmountWaived
        unconditionalAmountWaived
        contract {
          id
          internalProjectNumber
          project {
            id
            name
            projectNumber
            generalContractor {
              companyName
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useVendorForVendorContractsQuery__
 *
 * To run a query within a React component, call `useVendorForVendorContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorForVendorContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorForVendorContractsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorForVendorContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorForVendorContractsQuery,
    VendorForVendorContractsQueryVariables
  > &
    ({ variables: VendorForVendorContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorForVendorContractsQuery, VendorForVendorContractsQueryVariables>(
    VendorForVendorContractsDocument,
    options
  )
}
export function useVendorForVendorContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorForVendorContractsQuery,
    VendorForVendorContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VendorForVendorContractsQuery, VendorForVendorContractsQueryVariables>(
    VendorForVendorContractsDocument,
    options
  )
}
export function useVendorForVendorContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VendorForVendorContractsQuery,
        VendorForVendorContractsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VendorForVendorContractsQuery,
    VendorForVendorContractsQueryVariables
  >(VendorForVendorContractsDocument, options)
}
export type VendorForVendorContractsQueryHookResult = ReturnType<
  typeof useVendorForVendorContractsQuery
>
export type VendorForVendorContractsLazyQueryHookResult = ReturnType<
  typeof useVendorForVendorContractsLazyQuery
>
export type VendorForVendorContractsSuspenseQueryHookResult = ReturnType<
  typeof useVendorForVendorContractsSuspenseQuery
>
export type VendorForVendorContractsQueryResult = Apollo.QueryResult<
  VendorForVendorContractsQuery,
  VendorForVendorContractsQueryVariables
>
export const LienWaiversMonthSummaryDocument = gql`
  query lienWaiversMonthSummary($input: LienWaiversMonthSummaryInput!) {
    lienWaiversMonthSummary(input: $input) {
      numPendingLienWaivers
      numLienWaivers
      aggregateStatus
      isMonthSkipped
    }
  }
`

/**
 * __useLienWaiversMonthSummaryQuery__
 *
 * To run a query within a React component, call `useLienWaiversMonthSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiversMonthSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiversMonthSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLienWaiversMonthSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LienWaiversMonthSummaryQuery,
    LienWaiversMonthSummaryQueryVariables
  > &
    ({ variables: LienWaiversMonthSummaryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LienWaiversMonthSummaryQuery, LienWaiversMonthSummaryQueryVariables>(
    LienWaiversMonthSummaryDocument,
    options
  )
}
export function useLienWaiversMonthSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiversMonthSummaryQuery,
    LienWaiversMonthSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LienWaiversMonthSummaryQuery, LienWaiversMonthSummaryQueryVariables>(
    LienWaiversMonthSummaryDocument,
    options
  )
}
export function useLienWaiversMonthSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LienWaiversMonthSummaryQuery,
        LienWaiversMonthSummaryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LienWaiversMonthSummaryQuery,
    LienWaiversMonthSummaryQueryVariables
  >(LienWaiversMonthSummaryDocument, options)
}
export type LienWaiversMonthSummaryQueryHookResult = ReturnType<
  typeof useLienWaiversMonthSummaryQuery
>
export type LienWaiversMonthSummaryLazyQueryHookResult = ReturnType<
  typeof useLienWaiversMonthSummaryLazyQuery
>
export type LienWaiversMonthSummarySuspenseQueryHookResult = ReturnType<
  typeof useLienWaiversMonthSummarySuspenseQuery
>
export type LienWaiversMonthSummaryQueryResult = Apollo.QueryResult<
  LienWaiversMonthSummaryQuery,
  LienWaiversMonthSummaryQueryVariables
>
export const LienWaiversMonthDocument = gql`
  query lienWaiversMonth($input: LienWaiversMonthInput!) {
    lienWaiversMonth(input: $input) {
      hasCollectedFinalLienWaiver
      lienWaiver {
        ...LienWaiverForTrackerCellProperties
      }
    }
  }
  ${LienWaiverForTrackerCellPropertiesDoc}
`

/**
 * __useLienWaiversMonthQuery__
 *
 * To run a query within a React component, call `useLienWaiversMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiversMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiversMonthQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLienWaiversMonthQuery(
  baseOptions: Apollo.QueryHookOptions<LienWaiversMonthQuery, LienWaiversMonthQueryVariables> &
    ({ variables: LienWaiversMonthQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LienWaiversMonthQuery, LienWaiversMonthQueryVariables>(
    LienWaiversMonthDocument,
    options
  )
}
export function useLienWaiversMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LienWaiversMonthQuery, LienWaiversMonthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LienWaiversMonthQuery, LienWaiversMonthQueryVariables>(
    LienWaiversMonthDocument,
    options
  )
}
export function useLienWaiversMonthSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LienWaiversMonthQuery, LienWaiversMonthQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LienWaiversMonthQuery, LienWaiversMonthQueryVariables>(
    LienWaiversMonthDocument,
    options
  )
}
export type LienWaiversMonthQueryHookResult = ReturnType<typeof useLienWaiversMonthQuery>
export type LienWaiversMonthLazyQueryHookResult = ReturnType<typeof useLienWaiversMonthLazyQuery>
export type LienWaiversMonthSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiversMonthSuspenseQuery
>
export type LienWaiversMonthQueryResult = Apollo.QueryResult<
  LienWaiversMonthQuery,
  LienWaiversMonthQueryVariables
>
export const VendorContractCommitmentsDocument = gql`
  query vendorContractCommitments($input: VendorContractCommitmentsInput!) {
    vendorContractCommitments(input: $input) {
      ...VendorContractCommitmentProperties
    }
  }
  ${VendorContractCommitmentPropertiesDoc}
`

/**
 * __useVendorContractCommitmentsQuery__
 *
 * To run a query within a React component, call `useVendorContractCommitmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorContractCommitmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorContractCommitmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorContractCommitmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorContractCommitmentsQuery,
    VendorContractCommitmentsQueryVariables
  > &
    ({ variables: VendorContractCommitmentsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VendorContractCommitmentsQuery, VendorContractCommitmentsQueryVariables>(
    VendorContractCommitmentsDocument,
    options
  )
}
export function useVendorContractCommitmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorContractCommitmentsQuery,
    VendorContractCommitmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VendorContractCommitmentsQuery,
    VendorContractCommitmentsQueryVariables
  >(VendorContractCommitmentsDocument, options)
}
export function useVendorContractCommitmentsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VendorContractCommitmentsQuery,
        VendorContractCommitmentsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VendorContractCommitmentsQuery,
    VendorContractCommitmentsQueryVariables
  >(VendorContractCommitmentsDocument, options)
}
export type VendorContractCommitmentsQueryHookResult = ReturnType<
  typeof useVendorContractCommitmentsQuery
>
export type VendorContractCommitmentsLazyQueryHookResult = ReturnType<
  typeof useVendorContractCommitmentsLazyQuery
>
export type VendorContractCommitmentsSuspenseQueryHookResult = ReturnType<
  typeof useVendorContractCommitmentsSuspenseQuery
>
export type VendorContractCommitmentsQueryResult = Apollo.QueryResult<
  VendorContractCommitmentsQuery,
  VendorContractCommitmentsQueryVariables
>
export const CreateVendorContractCommitmentDocument = gql`
  mutation createVendorContractCommitment($input: CreateVendorContractCommitmentInput!) {
    createVendorContractCommitment(input: $input) {
      ...VendorContractCommitmentProperties
      vendorContract {
        id
        includeInSwornStatements
        vendor {
          id
          name
        }
        payAppSwornStatements {
          id
          totalCommitmentAmount
        }
        commitments {
          id
        }
      }
    }
  }
  ${VendorContractCommitmentPropertiesDoc}
`
export type CreateVendorContractCommitmentMutationFn = Apollo.MutationFunction<
  CreateVendorContractCommitmentMutation,
  CreateVendorContractCommitmentMutationVariables
>

/**
 * __useCreateVendorContractCommitmentMutation__
 *
 * To run a mutation, you first call `useCreateVendorContractCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorContractCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorContractCommitmentMutation, { data, loading, error }] = useCreateVendorContractCommitmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorContractCommitmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVendorContractCommitmentMutation,
    CreateVendorContractCommitmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateVendorContractCommitmentMutation,
    CreateVendorContractCommitmentMutationVariables
  >(CreateVendorContractCommitmentDocument, options)
}
export type CreateVendorContractCommitmentMutationHookResult = ReturnType<
  typeof useCreateVendorContractCommitmentMutation
>
export type CreateVendorContractCommitmentMutationResult =
  Apollo.MutationResult<CreateVendorContractCommitmentMutation>
export type CreateVendorContractCommitmentMutationOptions = Apollo.BaseMutationOptions<
  CreateVendorContractCommitmentMutation,
  CreateVendorContractCommitmentMutationVariables
>
export const UpdateVendorContractCommitmentDocument = gql`
  mutation updateVendorContractCommitment($input: UpdateVendorContractCommitmentInput!) {
    updateVendorContractCommitment(input: $input) {
      ...VendorContractCommitmentProperties
      vendorContract {
        id
        includeInSwornStatements
        vendor {
          id
          name
        }
        payAppSwornStatements {
          id
          totalCommitmentAmount
        }
      }
    }
  }
  ${VendorContractCommitmentPropertiesDoc}
`
export type UpdateVendorContractCommitmentMutationFn = Apollo.MutationFunction<
  UpdateVendorContractCommitmentMutation,
  UpdateVendorContractCommitmentMutationVariables
>

/**
 * __useUpdateVendorContractCommitmentMutation__
 *
 * To run a mutation, you first call `useUpdateVendorContractCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorContractCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorContractCommitmentMutation, { data, loading, error }] = useUpdateVendorContractCommitmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorContractCommitmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorContractCommitmentMutation,
    UpdateVendorContractCommitmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVendorContractCommitmentMutation,
    UpdateVendorContractCommitmentMutationVariables
  >(UpdateVendorContractCommitmentDocument, options)
}
export type UpdateVendorContractCommitmentMutationHookResult = ReturnType<
  typeof useUpdateVendorContractCommitmentMutation
>
export type UpdateVendorContractCommitmentMutationResult =
  Apollo.MutationResult<UpdateVendorContractCommitmentMutation>
export type UpdateVendorContractCommitmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorContractCommitmentMutation,
  UpdateVendorContractCommitmentMutationVariables
>
export const UpdateVendorContractCommitmentStatusDocument = gql`
  mutation updateVendorContractCommitmentStatus(
    $input: UpdateVendorContractCommitmentStatusInput!
  ) {
    updateVendorContractCommitmentStatus(input: $input) {
      ...VendorContractCommitmentProperties
    }
  }
  ${VendorContractCommitmentPropertiesDoc}
`
export type UpdateVendorContractCommitmentStatusMutationFn = Apollo.MutationFunction<
  UpdateVendorContractCommitmentStatusMutation,
  UpdateVendorContractCommitmentStatusMutationVariables
>

/**
 * __useUpdateVendorContractCommitmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateVendorContractCommitmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorContractCommitmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorContractCommitmentStatusMutation, { data, loading, error }] = useUpdateVendorContractCommitmentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorContractCommitmentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVendorContractCommitmentStatusMutation,
    UpdateVendorContractCommitmentStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateVendorContractCommitmentStatusMutation,
    UpdateVendorContractCommitmentStatusMutationVariables
  >(UpdateVendorContractCommitmentStatusDocument, options)
}
export type UpdateVendorContractCommitmentStatusMutationHookResult = ReturnType<
  typeof useUpdateVendorContractCommitmentStatusMutation
>
export type UpdateVendorContractCommitmentStatusMutationResult =
  Apollo.MutationResult<UpdateVendorContractCommitmentStatusMutation>
export type UpdateVendorContractCommitmentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateVendorContractCommitmentStatusMutation,
  UpdateVendorContractCommitmentStatusMutationVariables
>
export const DeleteVendorContractCommitmentDocument = gql`
  mutation deleteVendorContractCommitment($input: DeleteVendorContractCommitmentInput!) {
    deleteVendorContractCommitment(input: $input) {
      id
    }
  }
`
export type DeleteVendorContractCommitmentMutationFn = Apollo.MutationFunction<
  DeleteVendorContractCommitmentMutation,
  DeleteVendorContractCommitmentMutationVariables
>

/**
 * __useDeleteVendorContractCommitmentMutation__
 *
 * To run a mutation, you first call `useDeleteVendorContractCommitmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorContractCommitmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorContractCommitmentMutation, { data, loading, error }] = useDeleteVendorContractCommitmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVendorContractCommitmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVendorContractCommitmentMutation,
    DeleteVendorContractCommitmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteVendorContractCommitmentMutation,
    DeleteVendorContractCommitmentMutationVariables
  >(DeleteVendorContractCommitmentDocument, options)
}
export type DeleteVendorContractCommitmentMutationHookResult = ReturnType<
  typeof useDeleteVendorContractCommitmentMutation
>
export type DeleteVendorContractCommitmentMutationResult =
  Apollo.MutationResult<DeleteVendorContractCommitmentMutation>
export type DeleteVendorContractCommitmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteVendorContractCommitmentMutation,
  DeleteVendorContractCommitmentMutationVariables
>
export const GetIntegrationVendorInvoicesDocument = gql`
  query getIntegrationVendorInvoices($input: GetIntegrationVendorInvoicesInput!) {
    integrationVendorInvoices(input: $input) {
      integrationInvoiceId
      integrationVendorId
      integrationVendorCode
      sitelineVendorId
      vendorName
      amount
      retentionAmount
      code
      description
      invoiceDate
      paymentDate
      amountPaid
      isPaid
    }
  }
`

/**
 * __useGetIntegrationVendorInvoicesQuery__
 *
 * To run a query within a React component, call `useGetIntegrationVendorInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationVendorInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationVendorInvoicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntegrationVendorInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationVendorInvoicesQuery,
    GetIntegrationVendorInvoicesQueryVariables
  > &
    ({ variables: GetIntegrationVendorInvoicesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetIntegrationVendorInvoicesQuery,
    GetIntegrationVendorInvoicesQueryVariables
  >(GetIntegrationVendorInvoicesDocument, options)
}
export function useGetIntegrationVendorInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationVendorInvoicesQuery,
    GetIntegrationVendorInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetIntegrationVendorInvoicesQuery,
    GetIntegrationVendorInvoicesQueryVariables
  >(GetIntegrationVendorInvoicesDocument, options)
}
export function useGetIntegrationVendorInvoicesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetIntegrationVendorInvoicesQuery,
        GetIntegrationVendorInvoicesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetIntegrationVendorInvoicesQuery,
    GetIntegrationVendorInvoicesQueryVariables
  >(GetIntegrationVendorInvoicesDocument, options)
}
export type GetIntegrationVendorInvoicesQueryHookResult = ReturnType<
  typeof useGetIntegrationVendorInvoicesQuery
>
export type GetIntegrationVendorInvoicesLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationVendorInvoicesLazyQuery
>
export type GetIntegrationVendorInvoicesSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationVendorInvoicesSuspenseQuery
>
export type GetIntegrationVendorInvoicesQueryResult = Apollo.QueryResult<
  GetIntegrationVendorInvoicesQuery,
  GetIntegrationVendorInvoicesQueryVariables
>
export const AddIntegrationVendorsToContractDocument = gql`
  mutation addIntegrationVendorsToContract($input: AddIntegrationVendorsToContractInput!) {
    addIntegrationVendorsToContract(input: $input) {
      id
      vendorContracts {
        ...VendorContractForLienWaiversProperties
      }
    }
  }
  ${VendorContractForLienWaiversPropertiesDoc}
`
export type AddIntegrationVendorsToContractMutationFn = Apollo.MutationFunction<
  AddIntegrationVendorsToContractMutation,
  AddIntegrationVendorsToContractMutationVariables
>

/**
 * __useAddIntegrationVendorsToContractMutation__
 *
 * To run a mutation, you first call `useAddIntegrationVendorsToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationVendorsToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationVendorsToContractMutation, { data, loading, error }] = useAddIntegrationVendorsToContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIntegrationVendorsToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIntegrationVendorsToContractMutation,
    AddIntegrationVendorsToContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddIntegrationVendorsToContractMutation,
    AddIntegrationVendorsToContractMutationVariables
  >(AddIntegrationVendorsToContractDocument, options)
}
export type AddIntegrationVendorsToContractMutationHookResult = ReturnType<
  typeof useAddIntegrationVendorsToContractMutation
>
export type AddIntegrationVendorsToContractMutationResult =
  Apollo.MutationResult<AddIntegrationVendorsToContractMutation>
export type AddIntegrationVendorsToContractMutationOptions = Apollo.BaseMutationOptions<
  AddIntegrationVendorsToContractMutation,
  AddIntegrationVendorsToContractMutationVariables
>
export const AddIntegrationVendorsToSwornStatementContractDocument = gql`
  mutation addIntegrationVendorsToSwornStatementContract(
    $input: AddIntegrationVendorsToContractInput!
  ) {
    addIntegrationVendorsToContract(input: $input) {
      id
      vendorContracts {
        ...MinimalVendorContractProperties
        includeInSwornStatements
        vendor {
          id
          name
          integrationMappings
        }
        payAppSwornStatements {
          ...PayAppSwornStatementVendorProperties
        }
      }
    }
  }
  ${MinimalVendorContractPropertiesDoc}
  ${PayAppSwornStatementVendorPropertiesDoc}
`
export type AddIntegrationVendorsToSwornStatementContractMutationFn = Apollo.MutationFunction<
  AddIntegrationVendorsToSwornStatementContractMutation,
  AddIntegrationVendorsToSwornStatementContractMutationVariables
>

/**
 * __useAddIntegrationVendorsToSwornStatementContractMutation__
 *
 * To run a mutation, you first call `useAddIntegrationVendorsToSwornStatementContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationVendorsToSwornStatementContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationVendorsToSwornStatementContractMutation, { data, loading, error }] = useAddIntegrationVendorsToSwornStatementContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIntegrationVendorsToSwornStatementContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIntegrationVendorsToSwornStatementContractMutation,
    AddIntegrationVendorsToSwornStatementContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddIntegrationVendorsToSwornStatementContractMutation,
    AddIntegrationVendorsToSwornStatementContractMutationVariables
  >(AddIntegrationVendorsToSwornStatementContractDocument, options)
}
export type AddIntegrationVendorsToSwornStatementContractMutationHookResult = ReturnType<
  typeof useAddIntegrationVendorsToSwornStatementContractMutation
>
export type AddIntegrationVendorsToSwornStatementContractMutationResult =
  Apollo.MutationResult<AddIntegrationVendorsToSwornStatementContractMutation>
export type AddIntegrationVendorsToSwornStatementContractMutationOptions =
  Apollo.BaseMutationOptions<
    AddIntegrationVendorsToSwornStatementContractMutation,
    AddIntegrationVendorsToSwornStatementContractMutationVariables
  >
export const CompanyUserForProjectListFiltersDocument = gql`
  query companyUserForProjectListFilters($companyId: ID!) {
    currentCompanyUser(companyId: $companyId) {
      id
      role
      company {
        id
        companyIntegrations {
          id
          type
          archivedAt
        }
        locations {
          ...LocationProperties
        }
        leadPMs {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useCompanyUserForProjectListFiltersQuery__
 *
 * To run a query within a React component, call `useCompanyUserForProjectListFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserForProjectListFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUserForProjectListFiltersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyUserForProjectListFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyUserForProjectListFiltersQuery,
    CompanyUserForProjectListFiltersQueryVariables
  > &
    (
      | { variables: CompanyUserForProjectListFiltersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyUserForProjectListFiltersQuery,
    CompanyUserForProjectListFiltersQueryVariables
  >(CompanyUserForProjectListFiltersDocument, options)
}
export function useCompanyUserForProjectListFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyUserForProjectListFiltersQuery,
    CompanyUserForProjectListFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyUserForProjectListFiltersQuery,
    CompanyUserForProjectListFiltersQueryVariables
  >(CompanyUserForProjectListFiltersDocument, options)
}
export function useCompanyUserForProjectListFiltersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyUserForProjectListFiltersQuery,
        CompanyUserForProjectListFiltersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyUserForProjectListFiltersQuery,
    CompanyUserForProjectListFiltersQueryVariables
  >(CompanyUserForProjectListFiltersDocument, options)
}
export type CompanyUserForProjectListFiltersQueryHookResult = ReturnType<
  typeof useCompanyUserForProjectListFiltersQuery
>
export type CompanyUserForProjectListFiltersLazyQueryHookResult = ReturnType<
  typeof useCompanyUserForProjectListFiltersLazyQuery
>
export type CompanyUserForProjectListFiltersSuspenseQueryHookResult = ReturnType<
  typeof useCompanyUserForProjectListFiltersSuspenseQuery
>
export type CompanyUserForProjectListFiltersQueryResult = Apollo.QueryResult<
  CompanyUserForProjectListFiltersQuery,
  CompanyUserForProjectListFiltersQueryVariables
>
export const GetPayAppForSwornStatementDocument = gql`
  query getPayAppForSwornStatement($id: ID!) {
    payApp(id: $id) {
      id
      billingEnd
      timeZone
      status
      swornStatementVendors {
        ...PayAppSwornStatementVendorProperties
        vendorContract {
          id
          commitments {
            ...VendorContractCommitmentProperties
          }
          vendor {
            id
            name
          }
        }
      }
      contract {
        id
        vendorContracts {
          ...MinimalVendorContractProperties
          includeInSwornStatements
        }
        integrations {
          ...MinimalIntegrationProperties
        }
      }
    }
  }
  ${PayAppSwornStatementVendorPropertiesDoc}
  ${VendorContractCommitmentPropertiesDoc}
  ${MinimalVendorContractPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useGetPayAppForSwornStatementQuery__
 *
 * To run a query within a React component, call `useGetPayAppForSwornStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForSwornStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForSwornStatementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPayAppForSwornStatementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppForSwornStatementQuery,
    GetPayAppForSwornStatementQueryVariables
  > &
    ({ variables: GetPayAppForSwornStatementQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForSwornStatementQuery, GetPayAppForSwornStatementQueryVariables>(
    GetPayAppForSwornStatementDocument,
    options
  )
}
export function useGetPayAppForSwornStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForSwornStatementQuery,
    GetPayAppForSwornStatementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPayAppForSwornStatementQuery,
    GetPayAppForSwornStatementQueryVariables
  >(GetPayAppForSwornStatementDocument, options)
}
export function useGetPayAppForSwornStatementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPayAppForSwornStatementQuery,
        GetPayAppForSwornStatementQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPayAppForSwornStatementQuery,
    GetPayAppForSwornStatementQueryVariables
  >(GetPayAppForSwornStatementDocument, options)
}
export type GetPayAppForSwornStatementQueryHookResult = ReturnType<
  typeof useGetPayAppForSwornStatementQuery
>
export type GetPayAppForSwornStatementLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForSwornStatementLazyQuery
>
export type GetPayAppForSwornStatementSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForSwornStatementSuspenseQuery
>
export type GetPayAppForSwornStatementQueryResult = Apollo.QueryResult<
  GetPayAppForSwornStatementQuery,
  GetPayAppForSwornStatementQueryVariables
>
export const UpdatePayAppSwornStatementVendorsDocument = gql`
  mutation updatePayAppSwornStatementVendors($input: UpdatePayAppSwornStatementVendorsInput!) {
    updatePayAppSwornStatementVendors(input: $input) {
      id
      swornStatementVendors {
        ...PayAppSwornStatementVendorProperties
      }
      contract {
        id
        vendorContracts {
          id
          includeInSwornStatements
        }
        payApps {
          id
          swornStatementVendors {
            ...PayAppSwornStatementVendorProperties
          }
        }
      }
    }
  }
  ${PayAppSwornStatementVendorPropertiesDoc}
`
export type UpdatePayAppSwornStatementVendorsMutationFn = Apollo.MutationFunction<
  UpdatePayAppSwornStatementVendorsMutation,
  UpdatePayAppSwornStatementVendorsMutationVariables
>

/**
 * __useUpdatePayAppSwornStatementVendorsMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppSwornStatementVendorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppSwornStatementVendorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppSwornStatementVendorsMutation, { data, loading, error }] = useUpdatePayAppSwornStatementVendorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppSwornStatementVendorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppSwornStatementVendorsMutation,
    UpdatePayAppSwornStatementVendorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePayAppSwornStatementVendorsMutation,
    UpdatePayAppSwornStatementVendorsMutationVariables
  >(UpdatePayAppSwornStatementVendorsDocument, options)
}
export type UpdatePayAppSwornStatementVendorsMutationHookResult = ReturnType<
  typeof useUpdatePayAppSwornStatementVendorsMutation
>
export type UpdatePayAppSwornStatementVendorsMutationResult =
  Apollo.MutationResult<UpdatePayAppSwornStatementVendorsMutation>
export type UpdatePayAppSwornStatementVendorsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppSwornStatementVendorsMutation,
  UpdatePayAppSwornStatementVendorsMutationVariables
>
export const DeletePayAppSwornStatementVendorDocument = gql`
  mutation deletePayAppSwornStatementVendor($input: DeletePayAppSwornStatementVendorInput!) {
    deletePayAppSwornStatementVendor(input: $input) {
      id
    }
  }
`
export type DeletePayAppSwornStatementVendorMutationFn = Apollo.MutationFunction<
  DeletePayAppSwornStatementVendorMutation,
  DeletePayAppSwornStatementVendorMutationVariables
>

/**
 * __useDeletePayAppSwornStatementVendorMutation__
 *
 * To run a mutation, you first call `useDeletePayAppSwornStatementVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayAppSwornStatementVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayAppSwornStatementVendorMutation, { data, loading, error }] = useDeletePayAppSwornStatementVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePayAppSwornStatementVendorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePayAppSwornStatementVendorMutation,
    DeletePayAppSwornStatementVendorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePayAppSwornStatementVendorMutation,
    DeletePayAppSwornStatementVendorMutationVariables
  >(DeletePayAppSwornStatementVendorDocument, options)
}
export type DeletePayAppSwornStatementVendorMutationHookResult = ReturnType<
  typeof useDeletePayAppSwornStatementVendorMutation
>
export type DeletePayAppSwornStatementVendorMutationResult =
  Apollo.MutationResult<DeletePayAppSwornStatementVendorMutation>
export type DeletePayAppSwornStatementVendorMutationOptions = Apollo.BaseMutationOptions<
  DeletePayAppSwornStatementVendorMutation,
  DeletePayAppSwornStatementVendorMutationVariables
>
export const CreateWriteSyncOperationDocument = gql`
  mutation createWriteSyncOperation($input: CreateWriteSyncOperationInput!) {
    createWriteSyncOperation(input: $input) {
      id
      status
    }
  }
`
export type CreateWriteSyncOperationMutationFn = Apollo.MutationFunction<
  CreateWriteSyncOperationMutation,
  CreateWriteSyncOperationMutationVariables
>

/**
 * __useCreateWriteSyncOperationMutation__
 *
 * To run a mutation, you first call `useCreateWriteSyncOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWriteSyncOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWriteSyncOperationMutation, { data, loading, error }] = useCreateWriteSyncOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWriteSyncOperationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWriteSyncOperationMutation,
    CreateWriteSyncOperationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateWriteSyncOperationMutation,
    CreateWriteSyncOperationMutationVariables
  >(CreateWriteSyncOperationDocument, options)
}
export type CreateWriteSyncOperationMutationHookResult = ReturnType<
  typeof useCreateWriteSyncOperationMutation
>
export type CreateWriteSyncOperationMutationResult =
  Apollo.MutationResult<CreateWriteSyncOperationMutation>
export type CreateWriteSyncOperationMutationOptions = Apollo.BaseMutationOptions<
  CreateWriteSyncOperationMutation,
  CreateWriteSyncOperationMutationVariables
>
export const WriteSyncOperationDocument = gql`
  query writeSyncOperation($id: ID!) {
    writeSyncOperation(id: $id) {
      id
      status
      result
      payApp {
        id
        retentionOnly
        status
        payAppNumber
        billingType
      }
      legalRequirement {
        id
        name
      }
      legalDocuments {
        id
      }
      lienWaivers {
        id
        vendorContract {
          id
          vendor {
            id
            name
          }
        }
      }
      integration {
        id
        companyIntegration {
          id
          metadata
        }
      }
    }
  }
`

/**
 * __useWriteSyncOperationQuery__
 *
 * To run a query within a React component, call `useWriteSyncOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWriteSyncOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWriteSyncOperationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWriteSyncOperationQuery(
  baseOptions: Apollo.QueryHookOptions<WriteSyncOperationQuery, WriteSyncOperationQueryVariables> &
    ({ variables: WriteSyncOperationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WriteSyncOperationQuery, WriteSyncOperationQueryVariables>(
    WriteSyncOperationDocument,
    options
  )
}
export function useWriteSyncOperationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WriteSyncOperationQuery,
    WriteSyncOperationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WriteSyncOperationQuery, WriteSyncOperationQueryVariables>(
    WriteSyncOperationDocument,
    options
  )
}
export function useWriteSyncOperationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WriteSyncOperationQuery, WriteSyncOperationQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<WriteSyncOperationQuery, WriteSyncOperationQueryVariables>(
    WriteSyncOperationDocument,
    options
  )
}
export type WriteSyncOperationQueryHookResult = ReturnType<typeof useWriteSyncOperationQuery>
export type WriteSyncOperationLazyQueryHookResult = ReturnType<
  typeof useWriteSyncOperationLazyQuery
>
export type WriteSyncOperationSuspenseQueryHookResult = ReturnType<
  typeof useWriteSyncOperationSuspenseQuery
>
export type WriteSyncOperationQueryResult = Apollo.QueryResult<
  WriteSyncOperationQuery,
  WriteSyncOperationQueryVariables
>
export const SendSigninLinkAfterInviteDocument = gql`
  mutation sendSigninLinkAfterInvite($email: String!) {
    sendSigninLinkAfterInvite(email: $email)
  }
`
export type SendSigninLinkAfterInviteMutationFn = Apollo.MutationFunction<
  SendSigninLinkAfterInviteMutation,
  SendSigninLinkAfterInviteMutationVariables
>

/**
 * __useSendSigninLinkAfterInviteMutation__
 *
 * To run a mutation, you first call `useSendSigninLinkAfterInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSigninLinkAfterInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSigninLinkAfterInviteMutation, { data, loading, error }] = useSendSigninLinkAfterInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendSigninLinkAfterInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSigninLinkAfterInviteMutation,
    SendSigninLinkAfterInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendSigninLinkAfterInviteMutation,
    SendSigninLinkAfterInviteMutationVariables
  >(SendSigninLinkAfterInviteDocument, options)
}
export type SendSigninLinkAfterInviteMutationHookResult = ReturnType<
  typeof useSendSigninLinkAfterInviteMutation
>
export type SendSigninLinkAfterInviteMutationResult =
  Apollo.MutationResult<SendSigninLinkAfterInviteMutation>
export type SendSigninLinkAfterInviteMutationOptions = Apollo.BaseMutationOptions<
  SendSigninLinkAfterInviteMutation,
  SendSigninLinkAfterInviteMutationVariables
>
export const SendPasswordResetLinkEmailDocument = gql`
  mutation sendPasswordResetLinkEmail($email: String!) {
    sendPasswordResetLinkEmail(email: $email)
  }
`
export type SendPasswordResetLinkEmailMutationFn = Apollo.MutationFunction<
  SendPasswordResetLinkEmailMutation,
  SendPasswordResetLinkEmailMutationVariables
>

/**
 * __useSendPasswordResetLinkEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetLinkEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetLinkEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetLinkEmailMutation, { data, loading, error }] = useSendPasswordResetLinkEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetLinkEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPasswordResetLinkEmailMutation,
    SendPasswordResetLinkEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendPasswordResetLinkEmailMutation,
    SendPasswordResetLinkEmailMutationVariables
  >(SendPasswordResetLinkEmailDocument, options)
}
export type SendPasswordResetLinkEmailMutationHookResult = ReturnType<
  typeof useSendPasswordResetLinkEmailMutation
>
export type SendPasswordResetLinkEmailMutationResult =
  Apollo.MutationResult<SendPasswordResetLinkEmailMutation>
export type SendPasswordResetLinkEmailMutationOptions = Apollo.BaseMutationOptions<
  SendPasswordResetLinkEmailMutation,
  SendPasswordResetLinkEmailMutationVariables
>
export const AuthProviderDocument = gql`
  query authProvider($input: GetAuthProviderInput!) {
    authProvider(input: $input) {
      type
      samlProviderId
    }
  }
`

/**
 * __useAuthProviderQuery__
 *
 * To run a query within a React component, call `useAuthProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthProviderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthProviderQuery(
  baseOptions: Apollo.QueryHookOptions<AuthProviderQuery, AuthProviderQueryVariables> &
    ({ variables: AuthProviderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthProviderQuery, AuthProviderQueryVariables>(
    AuthProviderDocument,
    options
  )
}
export function useAuthProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthProviderQuery, AuthProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthProviderQuery, AuthProviderQueryVariables>(
    AuthProviderDocument,
    options
  )
}
export function useAuthProviderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AuthProviderQuery, AuthProviderQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AuthProviderQuery, AuthProviderQueryVariables>(
    AuthProviderDocument,
    options
  )
}
export type AuthProviderQueryHookResult = ReturnType<typeof useAuthProviderQuery>
export type AuthProviderLazyQueryHookResult = ReturnType<typeof useAuthProviderLazyQuery>
export type AuthProviderSuspenseQueryHookResult = ReturnType<typeof useAuthProviderSuspenseQuery>
export type AuthProviderQueryResult = Apollo.QueryResult<
  AuthProviderQuery,
  AuthProviderQueryVariables
>
export const UpdateUserAfterInviteDocument = gql`
  mutation updateUserAfterInvite($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      ...UserProperties
    }
  }
  ${UserPropertiesDoc}
`
export type UpdateUserAfterInviteMutationFn = Apollo.MutationFunction<
  UpdateUserAfterInviteMutation,
  UpdateUserAfterInviteMutationVariables
>

/**
 * __useUpdateUserAfterInviteMutation__
 *
 * To run a mutation, you first call `useUpdateUserAfterInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAfterInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAfterInviteMutation, { data, loading, error }] = useUpdateUserAfterInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserAfterInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAfterInviteMutation,
    UpdateUserAfterInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserAfterInviteMutation, UpdateUserAfterInviteMutationVariables>(
    UpdateUserAfterInviteDocument,
    options
  )
}
export type UpdateUserAfterInviteMutationHookResult = ReturnType<
  typeof useUpdateUserAfterInviteMutation
>
export type UpdateUserAfterInviteMutationResult =
  Apollo.MutationResult<UpdateUserAfterInviteMutation>
export type UpdateUserAfterInviteMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAfterInviteMutation,
  UpdateUserAfterInviteMutationVariables
>
export const ZendeskSsoJwtDocument = gql`
  query zendeskSsoJwt {
    zendeskSsoJwt
  }
`

/**
 * __useZendeskSsoJwtQuery__
 *
 * To run a query within a React component, call `useZendeskSsoJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useZendeskSsoJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZendeskSsoJwtQuery({
 *   variables: {
 *   },
 * });
 */
export function useZendeskSsoJwtQuery(
  baseOptions?: Apollo.QueryHookOptions<ZendeskSsoJwtQuery, ZendeskSsoJwtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ZendeskSsoJwtQuery, ZendeskSsoJwtQueryVariables>(
    ZendeskSsoJwtDocument,
    options
  )
}
export function useZendeskSsoJwtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZendeskSsoJwtQuery, ZendeskSsoJwtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ZendeskSsoJwtQuery, ZendeskSsoJwtQueryVariables>(
    ZendeskSsoJwtDocument,
    options
  )
}
export function useZendeskSsoJwtSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ZendeskSsoJwtQuery, ZendeskSsoJwtQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ZendeskSsoJwtQuery, ZendeskSsoJwtQueryVariables>(
    ZendeskSsoJwtDocument,
    options
  )
}
export type ZendeskSsoJwtQueryHookResult = ReturnType<typeof useZendeskSsoJwtQuery>
export type ZendeskSsoJwtLazyQueryHookResult = ReturnType<typeof useZendeskSsoJwtLazyQuery>
export type ZendeskSsoJwtSuspenseQueryHookResult = ReturnType<typeof useZendeskSsoJwtSuspenseQuery>
export type ZendeskSsoJwtQueryResult = Apollo.QueryResult<
  ZendeskSsoJwtQuery,
  ZendeskSsoJwtQueryVariables
>
export const GetProjectNameDocument = gql`
  query getProjectName($projectId: ID!) {
    project(id: $projectId) {
      id
      name
    }
  }
`

/**
 * __useGetProjectNameQuery__
 *
 * To run a query within a React component, call `useGetProjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectNameQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables> &
    ({ variables: GetProjectNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(
    GetProjectNameDocument,
    options
  )
}
export function useGetProjectNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(
    GetProjectNameDocument,
    options
  )
}
export function useGetProjectNameSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(
    GetProjectNameDocument,
    options
  )
}
export type GetProjectNameQueryHookResult = ReturnType<typeof useGetProjectNameQuery>
export type GetProjectNameLazyQueryHookResult = ReturnType<typeof useGetProjectNameLazyQuery>
export type GetProjectNameSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectNameSuspenseQuery
>
export type GetProjectNameQueryResult = Apollo.QueryResult<
  GetProjectNameQuery,
  GetProjectNameQueryVariables
>
export const CreatePayAppDocument = gql`
  mutation createPayApp($input: CreatePayAppInput!) {
    createPayApp(input: $input) {
      currentBilled
      currentRetention
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type CreatePayAppMutationFn = Apollo.MutationFunction<
  CreatePayAppMutation,
  CreatePayAppMutationVariables
>

/**
 * __useCreatePayAppMutation__
 *
 * To run a mutation, you first call `useCreatePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayAppMutation, { data, loading, error }] = useCreatePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePayAppMutation, CreatePayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePayAppMutation, CreatePayAppMutationVariables>(
    CreatePayAppDocument,
    options
  )
}
export type CreatePayAppMutationHookResult = ReturnType<typeof useCreatePayAppMutation>
export type CreatePayAppMutationResult = Apollo.MutationResult<CreatePayAppMutation>
export type CreatePayAppMutationOptions = Apollo.BaseMutationOptions<
  CreatePayAppMutation,
  CreatePayAppMutationVariables
>
export const ContractsForBulkOverUnderDocument = gql`
  query contractsForBulkOverUnder($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      contracts {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
    }
  }
`

/**
 * __useContractsForBulkOverUnderQuery__
 *
 * To run a query within a React component, call `useContractsForBulkOverUnderQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForBulkOverUnderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForBulkOverUnderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsForBulkOverUnderQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForBulkOverUnderQuery,
    ContractsForBulkOverUnderQueryVariables
  > &
    ({ variables: ContractsForBulkOverUnderQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsForBulkOverUnderQuery, ContractsForBulkOverUnderQueryVariables>(
    ContractsForBulkOverUnderDocument,
    options
  )
}
export function useContractsForBulkOverUnderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForBulkOverUnderQuery,
    ContractsForBulkOverUnderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractsForBulkOverUnderQuery,
    ContractsForBulkOverUnderQueryVariables
  >(ContractsForBulkOverUnderDocument, options)
}
export function useContractsForBulkOverUnderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForBulkOverUnderQuery,
        ContractsForBulkOverUnderQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractsForBulkOverUnderQuery,
    ContractsForBulkOverUnderQueryVariables
  >(ContractsForBulkOverUnderDocument, options)
}
export type ContractsForBulkOverUnderQueryHookResult = ReturnType<
  typeof useContractsForBulkOverUnderQuery
>
export type ContractsForBulkOverUnderLazyQueryHookResult = ReturnType<
  typeof useContractsForBulkOverUnderLazyQuery
>
export type ContractsForBulkOverUnderSuspenseQueryHookResult = ReturnType<
  typeof useContractsForBulkOverUnderSuspenseQuery
>
export type ContractsForBulkOverUnderQueryResult = Apollo.QueryResult<
  ContractsForBulkOverUnderQuery,
  ContractsForBulkOverUnderQueryVariables
>
export const BulkUpdateContractCostsDocument = gql`
  mutation bulkUpdateContractCosts($input: BulkUpdateContractCostsInput!) {
    bulkUpdateContractCosts(input: $input) {
      id
      cost {
        ...ContractCostProperties
      }
    }
  }
  ${ContractCostPropertiesDoc}
`
export type BulkUpdateContractCostsMutationFn = Apollo.MutationFunction<
  BulkUpdateContractCostsMutation,
  BulkUpdateContractCostsMutationVariables
>

/**
 * __useBulkUpdateContractCostsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateContractCostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateContractCostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateContractCostsMutation, { data, loading, error }] = useBulkUpdateContractCostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateContractCostsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateContractCostsMutation,
    BulkUpdateContractCostsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BulkUpdateContractCostsMutation,
    BulkUpdateContractCostsMutationVariables
  >(BulkUpdateContractCostsDocument, options)
}
export type BulkUpdateContractCostsMutationHookResult = ReturnType<
  typeof useBulkUpdateContractCostsMutation
>
export type BulkUpdateContractCostsMutationResult =
  Apollo.MutationResult<BulkUpdateContractCostsMutation>
export type BulkUpdateContractCostsMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateContractCostsMutation,
  BulkUpdateContractCostsMutationVariables
>
export const GetContractForPayAppDocument = gql`
  query getContractForPayApp($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      status
      skippedPayAppMonths
      retentionTrackingLevel
      preSitelineRetentionHeldOverride
      roundRetention
      billingType
      pastPayAppCount
      taxCalculationType
      includeChangeOrderLogInPayAppPackage
      hasSwornStatementForms
      company {
        id
        allowLumpSumOverbilling
        taxGroups {
          ...TaxGroupProperties
        }
      }
      timeZone
      defaultInvoiceReviewContacts {
        id
        email
      }
      sov {
        ...SovProperties
        lineItems {
          ...SovLineItemProperties
          sovLineItemGroup {
            ...SovLineItemGroupProperties
          }
        }
      }
      rateTable {
        ...RateTableProperties
      }
      rateTableFees {
        ...RateTableFeeProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      payAppRequirementGroups {
        id
      }
      lienWaiverTemplates {
        id
        unconditionalProgressVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
        unconditionalFinalVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
      }
      payApps {
        id
        billingStart
        billingEnd
        billingType
        payAppDueDate
        retentionOnly
        status
        payAppNumber
        canGenerateForms
        hasContractRateTableChanged
        haveContractFeesChanged
      }
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      integrations {
        ...MinimalIntegrationProperties
      }
      internalNotes
    }
  }
  ${TaxGroupPropertiesDoc}
  ${SovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
  ${RateTablePropertiesDoc}
  ${RateTableFeePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useGetContractForPayAppQuery__
 *
 * To run a query within a React component, call `useGetContractForPayAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForPayAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForPayAppQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForPayAppQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForPayAppQuery,
    GetContractForPayAppQueryVariables
  > &
    ({ variables: GetContractForPayAppQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForPayAppQuery, GetContractForPayAppQueryVariables>(
    GetContractForPayAppDocument,
    options
  )
}
export function useGetContractForPayAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForPayAppQuery,
    GetContractForPayAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractForPayAppQuery, GetContractForPayAppQueryVariables>(
    GetContractForPayAppDocument,
    options
  )
}
export function useGetContractForPayAppSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractForPayAppQuery, GetContractForPayAppQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractForPayAppQuery, GetContractForPayAppQueryVariables>(
    GetContractForPayAppDocument,
    options
  )
}
export type GetContractForPayAppQueryHookResult = ReturnType<typeof useGetContractForPayAppQuery>
export type GetContractForPayAppLazyQueryHookResult = ReturnType<
  typeof useGetContractForPayAppLazyQuery
>
export type GetContractForPayAppSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForPayAppSuspenseQuery
>
export type GetContractForPayAppQueryResult = Apollo.QueryResult<
  GetContractForPayAppQuery,
  GetContractForPayAppQueryVariables
>
export const GetContractForTopNavDocument = gql`
  query getContractForTopNav($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      payApps {
        id
        billingType
        payAppNumber
        billingEnd
        retentionOnly
        status
      }
      timeZone
    }
  }
`

/**
 * __useGetContractForTopNavQuery__
 *
 * To run a query within a React component, call `useGetContractForTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForTopNavQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForTopNavQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForTopNavQuery,
    GetContractForTopNavQueryVariables
  > &
    ({ variables: GetContractForTopNavQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForTopNavQuery, GetContractForTopNavQueryVariables>(
    GetContractForTopNavDocument,
    options
  )
}
export function useGetContractForTopNavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForTopNavQuery,
    GetContractForTopNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractForTopNavQuery, GetContractForTopNavQueryVariables>(
    GetContractForTopNavDocument,
    options
  )
}
export function useGetContractForTopNavSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractForTopNavQuery, GetContractForTopNavQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractForTopNavQuery, GetContractForTopNavQueryVariables>(
    GetContractForTopNavDocument,
    options
  )
}
export type GetContractForTopNavQueryHookResult = ReturnType<typeof useGetContractForTopNavQuery>
export type GetContractForTopNavLazyQueryHookResult = ReturnType<
  typeof useGetContractForTopNavLazyQuery
>
export type GetContractForTopNavSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForTopNavSuspenseQuery
>
export type GetContractForTopNavQueryResult = Apollo.QueryResult<
  GetContractForTopNavQuery,
  GetContractForTopNavQueryVariables
>
export const AddLegalDocumentsToPayAppDocument = gql`
  mutation addLegalDocumentsToPayApp($input: AddLegalDocumentsToPayAppInput!) {
    addLegalDocumentsToPayApp(input: $input) {
      id
      legalDocuments {
        ...MinimalLegalDocumentProperties
      }
    }
  }
  ${MinimalLegalDocumentPropertiesDoc}
`
export type AddLegalDocumentsToPayAppMutationFn = Apollo.MutationFunction<
  AddLegalDocumentsToPayAppMutation,
  AddLegalDocumentsToPayAppMutationVariables
>

/**
 * __useAddLegalDocumentsToPayAppMutation__
 *
 * To run a mutation, you first call `useAddLegalDocumentsToPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLegalDocumentsToPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLegalDocumentsToPayAppMutation, { data, loading, error }] = useAddLegalDocumentsToPayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLegalDocumentsToPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLegalDocumentsToPayAppMutation,
    AddLegalDocumentsToPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddLegalDocumentsToPayAppMutation,
    AddLegalDocumentsToPayAppMutationVariables
  >(AddLegalDocumentsToPayAppDocument, options)
}
export type AddLegalDocumentsToPayAppMutationHookResult = ReturnType<
  typeof useAddLegalDocumentsToPayAppMutation
>
export type AddLegalDocumentsToPayAppMutationResult =
  Apollo.MutationResult<AddLegalDocumentsToPayAppMutation>
export type AddLegalDocumentsToPayAppMutationOptions = Apollo.BaseMutationOptions<
  AddLegalDocumentsToPayAppMutation,
  AddLegalDocumentsToPayAppMutationVariables
>
export const AddVendorLienWaiversToPayAppDocument = gql`
  mutation addVendorLienWaiversToPayApp($input: AddVendorLienWaiversToPayAppInput!) {
    addVendorLienWaiversToPayApp(input: $input) {
      id
      lienWaivers {
        id
        type
        date
        claimant
        vendorContract {
          id
          vendor {
            id
            name
          }
        }
        uploadedFile {
          id
          type
          url
          name
        }
      }
    }
  }
`
export type AddVendorLienWaiversToPayAppMutationFn = Apollo.MutationFunction<
  AddVendorLienWaiversToPayAppMutation,
  AddVendorLienWaiversToPayAppMutationVariables
>

/**
 * __useAddVendorLienWaiversToPayAppMutation__
 *
 * To run a mutation, you first call `useAddVendorLienWaiversToPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorLienWaiversToPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorLienWaiversToPayAppMutation, { data, loading, error }] = useAddVendorLienWaiversToPayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorLienWaiversToPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVendorLienWaiversToPayAppMutation,
    AddVendorLienWaiversToPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddVendorLienWaiversToPayAppMutation,
    AddVendorLienWaiversToPayAppMutationVariables
  >(AddVendorLienWaiversToPayAppDocument, options)
}
export type AddVendorLienWaiversToPayAppMutationHookResult = ReturnType<
  typeof useAddVendorLienWaiversToPayAppMutation
>
export type AddVendorLienWaiversToPayAppMutationResult =
  Apollo.MutationResult<AddVendorLienWaiversToPayAppMutation>
export type AddVendorLienWaiversToPayAppMutationOptions = Apollo.BaseMutationOptions<
  AddVendorLienWaiversToPayAppMutation,
  AddVendorLienWaiversToPayAppMutationVariables
>
export const RemoveLegalDocumentFromPayAppDocument = gql`
  mutation removeLegalDocumentFromPayApp($input: RemoveLegalDocumentFromPayAppInput!) {
    removeLegalDocumentFromPayApp(input: $input) {
      id
      legalDocuments {
        ...MinimalLegalDocumentProperties
      }
    }
  }
  ${MinimalLegalDocumentPropertiesDoc}
`
export type RemoveLegalDocumentFromPayAppMutationFn = Apollo.MutationFunction<
  RemoveLegalDocumentFromPayAppMutation,
  RemoveLegalDocumentFromPayAppMutationVariables
>

/**
 * __useRemoveLegalDocumentFromPayAppMutation__
 *
 * To run a mutation, you first call `useRemoveLegalDocumentFromPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLegalDocumentFromPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLegalDocumentFromPayAppMutation, { data, loading, error }] = useRemoveLegalDocumentFromPayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLegalDocumentFromPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLegalDocumentFromPayAppMutation,
    RemoveLegalDocumentFromPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveLegalDocumentFromPayAppMutation,
    RemoveLegalDocumentFromPayAppMutationVariables
  >(RemoveLegalDocumentFromPayAppDocument, options)
}
export type RemoveLegalDocumentFromPayAppMutationHookResult = ReturnType<
  typeof useRemoveLegalDocumentFromPayAppMutation
>
export type RemoveLegalDocumentFromPayAppMutationResult =
  Apollo.MutationResult<RemoveLegalDocumentFromPayAppMutation>
export type RemoveLegalDocumentFromPayAppMutationOptions = Apollo.BaseMutationOptions<
  RemoveLegalDocumentFromPayAppMutation,
  RemoveLegalDocumentFromPayAppMutationVariables
>
export const GetPayAppForBackupDocument = gql`
  query getPayAppForBackup($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      retentionOnly
      status
      billingStart
      billingEnd
      billingType
      payAppNumber
      currentRetention
      totalRetention
      progress {
        id
        storedMaterialBilled
        progressBilled
        currentBilled
        retentionHeldPercent
        sovLineItem {
          id
          sortOrder
          code
          name
          isChangeOrder
          sovLineItemGroup {
            ...SovLineItemGroupProperties
          }
        }
      }
      rateTableItems {
        ...PayAppRateTableItemProperties
      }
      attachments {
        ...PayAppAttachmentForBackupProperties
      }
      legalDocuments {
        ...MinimalLegalDocumentProperties
        legalRequirement {
          ...MinimalLegalRequirementProperties
        }
      }
      lienWaivers {
        id
        type
        date
        claimant
        lastSyncIfSuccessful {
          ...MinimalWriteSyncOperationProperties
        }
        lastSendTime
        vendorContract {
          id
          lowerTierTo {
            id
            vendor {
              id
              name
            }
          }
          vendor {
            id
            name
          }
        }
        uploadedFile {
          id
          type
          url
          name
          size
        }
        lienWaiverRequests {
          id
          status
          actionTakenAt
          vendorContact {
            ...VendorContactProperties
          }
        }
      }
      contract {
        id
        taxCalculationType
        company {
          id
          taxGroups {
            ...TaxGroupProperties
          }
        }
      }
    }
  }
  ${SovLineItemGroupPropertiesDoc}
  ${PayAppRateTableItemPropertiesDoc}
  ${PayAppAttachmentForBackupPropertiesDoc}
  ${MinimalLegalDocumentPropertiesDoc}
  ${MinimalLegalRequirementPropertiesDoc}
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${VendorContactPropertiesDoc}
  ${TaxGroupPropertiesDoc}
`

/**
 * __useGetPayAppForBackupQuery__
 *
 * To run a query within a React component, call `useGetPayAppForBackupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForBackupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForBackupQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppForBackupQuery(
  baseOptions: Apollo.QueryHookOptions<GetPayAppForBackupQuery, GetPayAppForBackupQueryVariables> &
    ({ variables: GetPayAppForBackupQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForBackupQuery, GetPayAppForBackupQueryVariables>(
    GetPayAppForBackupDocument,
    options
  )
}
export function useGetPayAppForBackupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForBackupQuery,
    GetPayAppForBackupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppForBackupQuery, GetPayAppForBackupQueryVariables>(
    GetPayAppForBackupDocument,
    options
  )
}
export function useGetPayAppForBackupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPayAppForBackupQuery, GetPayAppForBackupQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppForBackupQuery, GetPayAppForBackupQueryVariables>(
    GetPayAppForBackupDocument,
    options
  )
}
export type GetPayAppForBackupQueryHookResult = ReturnType<typeof useGetPayAppForBackupQuery>
export type GetPayAppForBackupLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForBackupLazyQuery
>
export type GetPayAppForBackupSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForBackupSuspenseQuery
>
export type GetPayAppForBackupQueryResult = Apollo.QueryResult<
  GetPayAppForBackupQuery,
  GetPayAppForBackupQueryVariables
>
export const RemoveVendorLienWaiverFromPayAppDocument = gql`
  mutation removeVendorLienWaiverFromPayApp($input: RemoveVendorLienWaiverFromPayAppInput!) {
    removeVendorLienWaiverFromPayApp(input: $input) {
      id
      lienWaivers {
        id
      }
    }
  }
`
export type RemoveVendorLienWaiverFromPayAppMutationFn = Apollo.MutationFunction<
  RemoveVendorLienWaiverFromPayAppMutation,
  RemoveVendorLienWaiverFromPayAppMutationVariables
>

/**
 * __useRemoveVendorLienWaiverFromPayAppMutation__
 *
 * To run a mutation, you first call `useRemoveVendorLienWaiverFromPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVendorLienWaiverFromPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVendorLienWaiverFromPayAppMutation, { data, loading, error }] = useRemoveVendorLienWaiverFromPayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVendorLienWaiverFromPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVendorLienWaiverFromPayAppMutation,
    RemoveVendorLienWaiverFromPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveVendorLienWaiverFromPayAppMutation,
    RemoveVendorLienWaiverFromPayAppMutationVariables
  >(RemoveVendorLienWaiverFromPayAppDocument, options)
}
export type RemoveVendorLienWaiverFromPayAppMutationHookResult = ReturnType<
  typeof useRemoveVendorLienWaiverFromPayAppMutation
>
export type RemoveVendorLienWaiverFromPayAppMutationResult =
  Apollo.MutationResult<RemoveVendorLienWaiverFromPayAppMutation>
export type RemoveVendorLienWaiverFromPayAppMutationOptions = Apollo.BaseMutationOptions<
  RemoveVendorLienWaiverFromPayAppMutation,
  RemoveVendorLienWaiverFromPayAppMutationVariables
>
export const GetContractForAddChangeOrderRequestToSovDocument = gql`
  query getContractForAddChangeOrderRequestToSov($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      retentionTrackingLevel
      defaultRetentionPercent
      preSitelineRetention
      preSitelineRetentionHeldOverride
      roundRetention
      timeZone
      taxCalculationType
      defaultTaxGroup {
        id
      }
      company {
        id
        defaultRetentionPercent
        taxGroups {
          ...TaxGroupProperties
        }
      }
      sov {
        id
        lineItems {
          preSitelineRetention
          ...SovLineItemProperties
          sovLineItemGroup {
            ...SovLineItemGroupProperties
          }
          changeOrderRequests {
            id
            internalNumber
            name
          }
        }
        totalRetention
      }
      payApps {
        id
        retentionOnly
        status
        billingType
        payAppNumber
        billingStart
        billingEnd
        payAppDueDate
        progress {
          id
          progressRetentionHeldPercent
          sovLineItem {
            id
          }
        }
      }
      project {
        id
        name
      }
    }
  }
  ${TaxGroupPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${SovLineItemGroupPropertiesDoc}
`

/**
 * __useGetContractForAddChangeOrderRequestToSovQuery__
 *
 * To run a query within a React component, call `useGetContractForAddChangeOrderRequestToSovQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForAddChangeOrderRequestToSovQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForAddChangeOrderRequestToSovQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForAddChangeOrderRequestToSovQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForAddChangeOrderRequestToSovQuery,
    GetContractForAddChangeOrderRequestToSovQueryVariables
  > &
    (
      | { variables: GetContractForAddChangeOrderRequestToSovQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForAddChangeOrderRequestToSovQuery,
    GetContractForAddChangeOrderRequestToSovQueryVariables
  >(GetContractForAddChangeOrderRequestToSovDocument, options)
}
export function useGetContractForAddChangeOrderRequestToSovLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForAddChangeOrderRequestToSovQuery,
    GetContractForAddChangeOrderRequestToSovQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForAddChangeOrderRequestToSovQuery,
    GetContractForAddChangeOrderRequestToSovQueryVariables
  >(GetContractForAddChangeOrderRequestToSovDocument, options)
}
export function useGetContractForAddChangeOrderRequestToSovSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForAddChangeOrderRequestToSovQuery,
        GetContractForAddChangeOrderRequestToSovQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForAddChangeOrderRequestToSovQuery,
    GetContractForAddChangeOrderRequestToSovQueryVariables
  >(GetContractForAddChangeOrderRequestToSovDocument, options)
}
export type GetContractForAddChangeOrderRequestToSovQueryHookResult = ReturnType<
  typeof useGetContractForAddChangeOrderRequestToSovQuery
>
export type GetContractForAddChangeOrderRequestToSovLazyQueryHookResult = ReturnType<
  typeof useGetContractForAddChangeOrderRequestToSovLazyQuery
>
export type GetContractForAddChangeOrderRequestToSovSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForAddChangeOrderRequestToSovSuspenseQuery
>
export type GetContractForAddChangeOrderRequestToSovQueryResult = Apollo.QueryResult<
  GetContractForAddChangeOrderRequestToSovQuery,
  GetContractForAddChangeOrderRequestToSovQueryVariables
>
export const ChangeOrderRequestForSigningDocument = gql`
  query changeOrderRequestForSigning($id: ID!) {
    changeOrderRequest(id: $id) {
      ...MinimalChangeOrderRequestProperties
      attachments {
        id
        isInternalOnly
      }
      missingRequiredFields
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
      emailedContacts {
        ...CompanyContactProperties
      }
      contract {
        id
        changeOrderRequestTemplate {
          id
        }
      }
    }
  }
  ${MinimalChangeOrderRequestPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
  ${CompanyContactPropertiesDoc}
`

/**
 * __useChangeOrderRequestForSigningQuery__
 *
 * To run a query within a React component, call `useChangeOrderRequestForSigningQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderRequestForSigningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOrderRequestForSigningQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeOrderRequestForSigningQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChangeOrderRequestForSigningQuery,
    ChangeOrderRequestForSigningQueryVariables
  > &
    ({ variables: ChangeOrderRequestForSigningQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChangeOrderRequestForSigningQuery,
    ChangeOrderRequestForSigningQueryVariables
  >(ChangeOrderRequestForSigningDocument, options)
}
export function useChangeOrderRequestForSigningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChangeOrderRequestForSigningQuery,
    ChangeOrderRequestForSigningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChangeOrderRequestForSigningQuery,
    ChangeOrderRequestForSigningQueryVariables
  >(ChangeOrderRequestForSigningDocument, options)
}
export function useChangeOrderRequestForSigningSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ChangeOrderRequestForSigningQuery,
        ChangeOrderRequestForSigningQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ChangeOrderRequestForSigningQuery,
    ChangeOrderRequestForSigningQueryVariables
  >(ChangeOrderRequestForSigningDocument, options)
}
export type ChangeOrderRequestForSigningQueryHookResult = ReturnType<
  typeof useChangeOrderRequestForSigningQuery
>
export type ChangeOrderRequestForSigningLazyQueryHookResult = ReturnType<
  typeof useChangeOrderRequestForSigningLazyQuery
>
export type ChangeOrderRequestForSigningSuspenseQueryHookResult = ReturnType<
  typeof useChangeOrderRequestForSigningSuspenseQuery
>
export type ChangeOrderRequestForSigningQueryResult = Apollo.QueryResult<
  ChangeOrderRequestForSigningQuery,
  ChangeOrderRequestForSigningQueryVariables
>
export const SignPayAppDocument = gql`
  mutation signPayApp($input: SignPayAppInput!) {
    signPayApp(input: $input) {
      ...PayAppNoProgressProperties
      lienWaivers {
        ...LienWaiverProperties
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
    }
  }
  ${PayAppNoProgressPropertiesDoc}
  ${LienWaiverPropertiesDoc}
  ${FormTemplateAnnotationValuePropertiesDoc}
`
export type SignPayAppMutationFn = Apollo.MutationFunction<
  SignPayAppMutation,
  SignPayAppMutationVariables
>

/**
 * __useSignPayAppMutation__
 *
 * To run a mutation, you first call `useSignPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPayAppMutation, { data, loading, error }] = useSignPayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<SignPayAppMutation, SignPayAppMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignPayAppMutation, SignPayAppMutationVariables>(
    SignPayAppDocument,
    options
  )
}
export type SignPayAppMutationHookResult = ReturnType<typeof useSignPayAppMutation>
export type SignPayAppMutationResult = Apollo.MutationResult<SignPayAppMutation>
export type SignPayAppMutationOptions = Apollo.BaseMutationOptions<
  SignPayAppMutation,
  SignPayAppMutationVariables
>
export const GetContractForChangeOrderRequestReviewDocument = gql`
  query getContractForChangeOrderRequestReview($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      company {
        id
        name
      }
      defaultChangeOrderRequestReviewContacts {
        id
        email
        firstName
        lastName
        policiesAcceptedAt
      }
      users {
        id
        user {
          id
          firstName
          lastName
          email
          policiesAcceptedAt
        }
      }
      project {
        id
        name
      }
    }
  }
`

/**
 * __useGetContractForChangeOrderRequestReviewQuery__
 *
 * To run a query within a React component, call `useGetContractForChangeOrderRequestReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForChangeOrderRequestReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForChangeOrderRequestReviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForChangeOrderRequestReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForChangeOrderRequestReviewQuery,
    GetContractForChangeOrderRequestReviewQueryVariables
  > &
    (
      | { variables: GetContractForChangeOrderRequestReviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForChangeOrderRequestReviewQuery,
    GetContractForChangeOrderRequestReviewQueryVariables
  >(GetContractForChangeOrderRequestReviewDocument, options)
}
export function useGetContractForChangeOrderRequestReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForChangeOrderRequestReviewQuery,
    GetContractForChangeOrderRequestReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForChangeOrderRequestReviewQuery,
    GetContractForChangeOrderRequestReviewQueryVariables
  >(GetContractForChangeOrderRequestReviewDocument, options)
}
export function useGetContractForChangeOrderRequestReviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForChangeOrderRequestReviewQuery,
        GetContractForChangeOrderRequestReviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForChangeOrderRequestReviewQuery,
    GetContractForChangeOrderRequestReviewQueryVariables
  >(GetContractForChangeOrderRequestReviewDocument, options)
}
export type GetContractForChangeOrderRequestReviewQueryHookResult = ReturnType<
  typeof useGetContractForChangeOrderRequestReviewQuery
>
export type GetContractForChangeOrderRequestReviewLazyQueryHookResult = ReturnType<
  typeof useGetContractForChangeOrderRequestReviewLazyQuery
>
export type GetContractForChangeOrderRequestReviewSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForChangeOrderRequestReviewSuspenseQuery
>
export type GetContractForChangeOrderRequestReviewQueryResult = Apollo.QueryResult<
  GetContractForChangeOrderRequestReviewQuery,
  GetContractForChangeOrderRequestReviewQueryVariables
>
export const SovForChangeOrdersDocument = gql`
  query sovForChangeOrders($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      company {
        id
      }
      sov {
        id
        lineItems {
          id
          isChangeOrder
          latestTotalValue
        }
      }
      rateTable {
        ...RateTableProperties
      }
      project {
        id
        name
        generalContractor {
          company {
            id
            name
          }
        }
      }
      leadPMs {
        id
        firstName
        lastName
      }
    }
  }
  ${RateTablePropertiesDoc}
`

/**
 * __useSovForChangeOrdersQuery__
 *
 * To run a query within a React component, call `useSovForChangeOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSovForChangeOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSovForChangeOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSovForChangeOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<SovForChangeOrdersQuery, SovForChangeOrdersQueryVariables> &
    ({ variables: SovForChangeOrdersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SovForChangeOrdersQuery, SovForChangeOrdersQueryVariables>(
    SovForChangeOrdersDocument,
    options
  )
}
export function useSovForChangeOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SovForChangeOrdersQuery,
    SovForChangeOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SovForChangeOrdersQuery, SovForChangeOrdersQueryVariables>(
    SovForChangeOrdersDocument,
    options
  )
}
export function useSovForChangeOrdersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SovForChangeOrdersQuery, SovForChangeOrdersQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SovForChangeOrdersQuery, SovForChangeOrdersQueryVariables>(
    SovForChangeOrdersDocument,
    options
  )
}
export type SovForChangeOrdersQueryHookResult = ReturnType<typeof useSovForChangeOrdersQuery>
export type SovForChangeOrdersLazyQueryHookResult = ReturnType<
  typeof useSovForChangeOrdersLazyQuery
>
export type SovForChangeOrdersSuspenseQueryHookResult = ReturnType<
  typeof useSovForChangeOrdersSuspenseQuery
>
export type SovForChangeOrdersQueryResult = Apollo.QueryResult<
  SovForChangeOrdersQuery,
  SovForChangeOrdersQueryVariables
>
export const GetContractForProjectHomeDocument = gql`
  query getContractForProjectHome($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...MinimalContractProperties
      includeChangeOrderLogInPayAppPackage
      hasSwornStatementForms
      firstPayAppBillingEnd
      earliestPayAppBillingEnd
      hasStartedBilling
      progressRemaining
      averageDaysToPaid
      preSitelineBilled
      preSitelineRetention
      taxCalculationType
      contractDate
      contractNumber
      changeOrderRequests {
        id
      }
      appliesTaxToRetention
      defaultTaxGroup {
        ...TaxGroupProperties
      }
      optedOutMonths {
        ...ContractOptedOutMonthProperties
      }
      company {
        id
        metadata {
          monthlyCreatePayAppReminderDate
        }
        taxGroups {
          ...TaxGroupProperties
        }
      }
      sov {
        ...MinimalSovProperties
        lineItems {
          ...SovLineItemProperties
          preSitelineRetention
        }
      }
      rateTable {
        ...RateTableProperties
      }
      rateTableFees {
        ...RateTableFeeProperties
      }
      timeZone
      selectedAddress {
        id
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      payAppRequirementGroups {
        id
      }
      lienWaiverTemplates {
        id
        unconditionalProgressVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
        unconditionalFinalVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
      }
      lowerTierLienWaiverTemplates {
        id
      }
      changeOrderRequestTemplate {
        ...MinimalFormTemplateProperties
        variants {
          id
          isDefaultVariant
        }
      }
      changeOrderLogTemplate {
        ...MinimalFormTemplateProperties
        variants {
          id
          isDefaultVariant
        }
      }
      primaryLienWaivers {
        id
        date
        type
      }
      percentComplete
      defaultGcContacts {
        ...CompanyContactProperties
      }
      billingForecast {
        ...ContractBillingForecastProperties
      }
      payApps {
        id
        timeZone
        billingEnd
        billingStart
        billingType
        payAppDueDate
        statusChangedAt
        lastSubmitted {
          id
          status
          statusUpdatedAt
        }
        retentionOnly
        status
        internalDueDate
        currentBilled
        currentRetention
        previousRetentionBilled
        amountDuePostTax
        integrationPaidAt
        revertToDraftEvents {
          id
        }
        markedAsPaidIntegration {
          id
          type
          shortName
          longName
        }
        amountPaid
        isBalanceManuallyClosed
        invoiceReady
        payAppNumber
        draftSubmittedAt
        balanceToFinish
        totalRetention
        totalValue
        lastSync {
          ...MinimalWriteSyncOperationProperties
        }
        lastSyncIfSuccessful {
          ...MinimalWriteSyncOperationProperties
        }
        lienWaivers {
          id
          type
          lastSendTime
          vendorContract {
            id
          }
        }
        hasContractRateTableChanged
        haveContractFeesChanged
      }
      preSitelinePayApps {
        ...PreSitelinePayAppForProjectHomeProperties
      }
      users {
        ...MinimalContractUserProperties
      }
      files {
        ...StoredFileProperties
      }
      leadPMs {
        id
        firstName
        lastName
      }
      project {
        id
        name
        projectNumber
        location {
          ...LocationProperties
        }
        generalContractor {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
        owner {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
        architect {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
        metadata {
          payAppDueOnDayOfMonth
        }
      }
      internalNotes
    }
  }
  ${MinimalContractPropertiesDoc}
  ${TaxGroupPropertiesDoc}
  ${ContractOptedOutMonthPropertiesDoc}
  ${MinimalSovPropertiesDoc}
  ${SovLineItemPropertiesDoc}
  ${RateTablePropertiesDoc}
  ${RateTableFeePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${MinimalFormTemplatePropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${ContractBillingForecastPropertiesDoc}
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${PreSitelinePayAppForProjectHomePropertiesDoc}
  ${MinimalContractUserPropertiesDoc}
  ${StoredFilePropertiesDoc}
  ${LocationPropertiesDoc}
`

/**
 * __useGetContractForProjectHomeQuery__
 *
 * To run a query within a React component, call `useGetContractForProjectHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForProjectHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForProjectHomeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForProjectHomeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForProjectHomeQuery,
    GetContractForProjectHomeQueryVariables
  > &
    ({ variables: GetContractForProjectHomeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForProjectHomeQuery, GetContractForProjectHomeQueryVariables>(
    GetContractForProjectHomeDocument,
    options
  )
}
export function useGetContractForProjectHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForProjectHomeQuery,
    GetContractForProjectHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForProjectHomeQuery,
    GetContractForProjectHomeQueryVariables
  >(GetContractForProjectHomeDocument, options)
}
export function useGetContractForProjectHomeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForProjectHomeQuery,
        GetContractForProjectHomeQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForProjectHomeQuery,
    GetContractForProjectHomeQueryVariables
  >(GetContractForProjectHomeDocument, options)
}
export type GetContractForProjectHomeQueryHookResult = ReturnType<
  typeof useGetContractForProjectHomeQuery
>
export type GetContractForProjectHomeLazyQueryHookResult = ReturnType<
  typeof useGetContractForProjectHomeLazyQuery
>
export type GetContractForProjectHomeSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForProjectHomeSuspenseQuery
>
export type GetContractForProjectHomeQueryResult = Apollo.QueryResult<
  GetContractForProjectHomeQuery,
  GetContractForProjectHomeQueryVariables
>
export const UpdateContractForInternalNotesDocument = gql`
  mutation updateContractForInternalNotes($input: UpdateContractInput!) {
    updateContract(input: $input) {
      id
      internalNotes
      vendorsInternalNotes
    }
  }
`
export type UpdateContractForInternalNotesMutationFn = Apollo.MutationFunction<
  UpdateContractForInternalNotesMutation,
  UpdateContractForInternalNotesMutationVariables
>

/**
 * __useUpdateContractForInternalNotesMutation__
 *
 * To run a mutation, you first call `useUpdateContractForInternalNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractForInternalNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractForInternalNotesMutation, { data, loading, error }] = useUpdateContractForInternalNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractForInternalNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractForInternalNotesMutation,
    UpdateContractForInternalNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractForInternalNotesMutation,
    UpdateContractForInternalNotesMutationVariables
  >(UpdateContractForInternalNotesDocument, options)
}
export type UpdateContractForInternalNotesMutationHookResult = ReturnType<
  typeof useUpdateContractForInternalNotesMutation
>
export type UpdateContractForInternalNotesMutationResult =
  Apollo.MutationResult<UpdateContractForInternalNotesMutation>
export type UpdateContractForInternalNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractForInternalNotesMutation,
  UpdateContractForInternalNotesMutationVariables
>
export const ContractsForRecentlyViewedProjectsDocument = gql`
  query contractsForRecentlyViewedProjects($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      contracts {
        id
        internalProjectNumber
        project {
          id
          projectNumber
          name
        }
        payApps {
          id
          billingType
        }
      }
    }
  }
`

/**
 * __useContractsForRecentlyViewedProjectsQuery__
 *
 * To run a query within a React component, call `useContractsForRecentlyViewedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForRecentlyViewedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForRecentlyViewedProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsForRecentlyViewedProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForRecentlyViewedProjectsQuery,
    ContractsForRecentlyViewedProjectsQueryVariables
  > &
    (
      | { variables: ContractsForRecentlyViewedProjectsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractsForRecentlyViewedProjectsQuery,
    ContractsForRecentlyViewedProjectsQueryVariables
  >(ContractsForRecentlyViewedProjectsDocument, options)
}
export function useContractsForRecentlyViewedProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForRecentlyViewedProjectsQuery,
    ContractsForRecentlyViewedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractsForRecentlyViewedProjectsQuery,
    ContractsForRecentlyViewedProjectsQueryVariables
  >(ContractsForRecentlyViewedProjectsDocument, options)
}
export function useContractsForRecentlyViewedProjectsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForRecentlyViewedProjectsQuery,
        ContractsForRecentlyViewedProjectsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractsForRecentlyViewedProjectsQuery,
    ContractsForRecentlyViewedProjectsQueryVariables
  >(ContractsForRecentlyViewedProjectsDocument, options)
}
export type ContractsForRecentlyViewedProjectsQueryHookResult = ReturnType<
  typeof useContractsForRecentlyViewedProjectsQuery
>
export type ContractsForRecentlyViewedProjectsLazyQueryHookResult = ReturnType<
  typeof useContractsForRecentlyViewedProjectsLazyQuery
>
export type ContractsForRecentlyViewedProjectsSuspenseQueryHookResult = ReturnType<
  typeof useContractsForRecentlyViewedProjectsSuspenseQuery
>
export type ContractsForRecentlyViewedProjectsQueryResult = Apollo.QueryResult<
  ContractsForRecentlyViewedProjectsQuery,
  ContractsForRecentlyViewedProjectsQueryVariables
>
export const ContractForCostDocument = gql`
  query contractForCost($id: ID!) {
    contract(id: $id) {
      id
      timeZone
      project {
        id
        name
      }
      cost {
        ...ContractCostProperties
      }
      integrations {
        ...MinimalIntegrationProperties
      }
    }
  }
  ${ContractCostPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useContractForCostQuery__
 *
 * To run a query within a React component, call `useContractForCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForCostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForCostQuery(
  baseOptions: Apollo.QueryHookOptions<ContractForCostQuery, ContractForCostQueryVariables> &
    ({ variables: ContractForCostQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractForCostQuery, ContractForCostQueryVariables>(
    ContractForCostDocument,
    options
  )
}
export function useContractForCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractForCostQuery, ContractForCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractForCostQuery, ContractForCostQueryVariables>(
    ContractForCostDocument,
    options
  )
}
export function useContractForCostSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractForCostQuery, ContractForCostQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractForCostQuery, ContractForCostQueryVariables>(
    ContractForCostDocument,
    options
  )
}
export type ContractForCostQueryHookResult = ReturnType<typeof useContractForCostQuery>
export type ContractForCostLazyQueryHookResult = ReturnType<typeof useContractForCostLazyQuery>
export type ContractForCostSuspenseQueryHookResult = ReturnType<
  typeof useContractForCostSuspenseQuery
>
export type ContractForCostQueryResult = Apollo.QueryResult<
  ContractForCostQuery,
  ContractForCostQueryVariables
>
export const UpdateContractCostFromIntegrationDocument = gql`
  mutation updateContractCostFromIntegration($input: UpdateContractCostFromIntegrationInput!) {
    updateContractCostFromIntegration(input: $input) {
      id
      cost {
        ...ContractCostProperties
      }
    }
  }
  ${ContractCostPropertiesDoc}
`
export type UpdateContractCostFromIntegrationMutationFn = Apollo.MutationFunction<
  UpdateContractCostFromIntegrationMutation,
  UpdateContractCostFromIntegrationMutationVariables
>

/**
 * __useUpdateContractCostFromIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateContractCostFromIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractCostFromIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractCostFromIntegrationMutation, { data, loading, error }] = useUpdateContractCostFromIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractCostFromIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractCostFromIntegrationMutation,
    UpdateContractCostFromIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractCostFromIntegrationMutation,
    UpdateContractCostFromIntegrationMutationVariables
  >(UpdateContractCostFromIntegrationDocument, options)
}
export type UpdateContractCostFromIntegrationMutationHookResult = ReturnType<
  typeof useUpdateContractCostFromIntegrationMutation
>
export type UpdateContractCostFromIntegrationMutationResult =
  Apollo.MutationResult<UpdateContractCostFromIntegrationMutation>
export type UpdateContractCostFromIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractCostFromIntegrationMutation,
  UpdateContractCostFromIntegrationMutationVariables
>
export const ClearContractCostDocument = gql`
  mutation clearContractCost($contractId: ID!) {
    clearContractCost(contractId: $contractId) {
      id
      cost {
        ...ContractCostProperties
      }
    }
  }
  ${ContractCostPropertiesDoc}
`
export type ClearContractCostMutationFn = Apollo.MutationFunction<
  ClearContractCostMutation,
  ClearContractCostMutationVariables
>

/**
 * __useClearContractCostMutation__
 *
 * To run a mutation, you first call `useClearContractCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearContractCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearContractCostMutation, { data, loading, error }] = useClearContractCostMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useClearContractCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearContractCostMutation,
    ClearContractCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearContractCostMutation, ClearContractCostMutationVariables>(
    ClearContractCostDocument,
    options
  )
}
export type ClearContractCostMutationHookResult = ReturnType<typeof useClearContractCostMutation>
export type ClearContractCostMutationResult = Apollo.MutationResult<ClearContractCostMutation>
export type ClearContractCostMutationOptions = Apollo.BaseMutationOptions<
  ClearContractCostMutation,
  ClearContractCostMutationVariables
>
export const GetPayAppG702PreviewDocument = gql`
  query getPayAppG702Preview($id: ID!) {
    payApp(id: $id) {
      id
      g702Values {
        originalContractSum
        netChangeByChangeOrders
        contractSumToDate
        totalCompletedToDate
        progressRetentionPercent
        progressRetentionAmount
        materialsRetentionPercent
        materialsRetentionAmount
        totalRetention
        totalLessRetainage
        previousPayments
        currentPaymentDue
        balanceToFinish
        balanceToFinishWithRetention
      }
    }
  }
`

/**
 * __useGetPayAppG702PreviewQuery__
 *
 * To run a query within a React component, call `useGetPayAppG702PreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppG702PreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppG702PreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPayAppG702PreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppG702PreviewQuery,
    GetPayAppG702PreviewQueryVariables
  > &
    ({ variables: GetPayAppG702PreviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppG702PreviewQuery, GetPayAppG702PreviewQueryVariables>(
    GetPayAppG702PreviewDocument,
    options
  )
}
export function useGetPayAppG702PreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppG702PreviewQuery,
    GetPayAppG702PreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppG702PreviewQuery, GetPayAppG702PreviewQueryVariables>(
    GetPayAppG702PreviewDocument,
    options
  )
}
export function useGetPayAppG702PreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPayAppG702PreviewQuery, GetPayAppG702PreviewQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppG702PreviewQuery, GetPayAppG702PreviewQueryVariables>(
    GetPayAppG702PreviewDocument,
    options
  )
}
export type GetPayAppG702PreviewQueryHookResult = ReturnType<typeof useGetPayAppG702PreviewQuery>
export type GetPayAppG702PreviewLazyQueryHookResult = ReturnType<
  typeof useGetPayAppG702PreviewLazyQuery
>
export type GetPayAppG702PreviewSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppG702PreviewSuspenseQuery
>
export type GetPayAppG702PreviewQueryResult = Apollo.QueryResult<
  GetPayAppG702PreviewQuery,
  GetPayAppG702PreviewQueryVariables
>
export const GetSovLineItemProgressDocument = gql`
  query getSovLineItemProgress($id: ID!) {
    sovLineItemProgress(id: $id) {
      id
      sovLineItem {
        id
        code
        name
      }
      events {
        ...SovLineItemProgressEventProperties
      }
    }
  }
  ${SovLineItemProgressEventPropertiesDoc}
`

/**
 * __useGetSovLineItemProgressQuery__
 *
 * To run a query within a React component, call `useGetSovLineItemProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSovLineItemProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSovLineItemProgressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSovLineItemProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSovLineItemProgressQuery,
    GetSovLineItemProgressQueryVariables
  > &
    ({ variables: GetSovLineItemProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSovLineItemProgressQuery, GetSovLineItemProgressQueryVariables>(
    GetSovLineItemProgressDocument,
    options
  )
}
export function useGetSovLineItemProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSovLineItemProgressQuery,
    GetSovLineItemProgressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSovLineItemProgressQuery, GetSovLineItemProgressQueryVariables>(
    GetSovLineItemProgressDocument,
    options
  )
}
export function useGetSovLineItemProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetSovLineItemProgressQuery,
        GetSovLineItemProgressQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSovLineItemProgressQuery, GetSovLineItemProgressQueryVariables>(
    GetSovLineItemProgressDocument,
    options
  )
}
export type GetSovLineItemProgressQueryHookResult = ReturnType<
  typeof useGetSovLineItemProgressQuery
>
export type GetSovLineItemProgressLazyQueryHookResult = ReturnType<
  typeof useGetSovLineItemProgressLazyQuery
>
export type GetSovLineItemProgressSuspenseQueryHookResult = ReturnType<
  typeof useGetSovLineItemProgressSuspenseQuery
>
export type GetSovLineItemProgressQueryResult = Apollo.QueryResult<
  GetSovLineItemProgressQuery,
  GetSovLineItemProgressQueryVariables
>
export const GetContractForInvoiceReviewDocument = gql`
  query getContractForInvoiceReview($contractId: ID!) {
    contract(id: $contractId) {
      id
      company {
        id
        name
      }
      defaultInvoiceReviewContacts {
        id
        email
        firstName
        lastName
        policiesAcceptedAt
      }
      users {
        id
        user {
          id
          firstName
          lastName
          email
          policiesAcceptedAt
        }
      }
      project {
        id
        name
      }
    }
  }
`

/**
 * __useGetContractForInvoiceReviewQuery__
 *
 * To run a query within a React component, call `useGetContractForInvoiceReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForInvoiceReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForInvoiceReviewQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractForInvoiceReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForInvoiceReviewQuery,
    GetContractForInvoiceReviewQueryVariables
  > &
    ({ variables: GetContractForInvoiceReviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForInvoiceReviewQuery,
    GetContractForInvoiceReviewQueryVariables
  >(GetContractForInvoiceReviewDocument, options)
}
export function useGetContractForInvoiceReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForInvoiceReviewQuery,
    GetContractForInvoiceReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForInvoiceReviewQuery,
    GetContractForInvoiceReviewQueryVariables
  >(GetContractForInvoiceReviewDocument, options)
}
export function useGetContractForInvoiceReviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForInvoiceReviewQuery,
        GetContractForInvoiceReviewQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForInvoiceReviewQuery,
    GetContractForInvoiceReviewQueryVariables
  >(GetContractForInvoiceReviewDocument, options)
}
export type GetContractForInvoiceReviewQueryHookResult = ReturnType<
  typeof useGetContractForInvoiceReviewQuery
>
export type GetContractForInvoiceReviewLazyQueryHookResult = ReturnType<
  typeof useGetContractForInvoiceReviewLazyQuery
>
export type GetContractForInvoiceReviewSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForInvoiceReviewSuspenseQuery
>
export type GetContractForInvoiceReviewQueryResult = Apollo.QueryResult<
  GetContractForInvoiceReviewQuery,
  GetContractForInvoiceReviewQueryVariables
>
export const ResetPayAppToDraftDocument = gql`
  mutation resetPayAppToDraft($input: ResetPayAppToDraftInput!) {
    resetPayAppToDraft(input: $input) {
      id
      status
      statusChangedAt
      revertToDraftEvents {
        id
      }
      uploadedFile {
        id
      }
      lienWaivers {
        id
        type
        vendorContract {
          id
          vendor {
            id
          }
        }
        lienWaiverRequests {
          id
          lienWaiver {
            id
          }
        }
        formValues {
          ...FormTemplateAnnotationValueProperties
        }
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
    }
  }
  ${FormTemplateAnnotationValuePropertiesDoc}
`
export type ResetPayAppToDraftMutationFn = Apollo.MutationFunction<
  ResetPayAppToDraftMutation,
  ResetPayAppToDraftMutationVariables
>

/**
 * __useResetPayAppToDraftMutation__
 *
 * To run a mutation, you first call `useResetPayAppToDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPayAppToDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPayAppToDraftMutation, { data, loading, error }] = useResetPayAppToDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPayAppToDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPayAppToDraftMutation,
    ResetPayAppToDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPayAppToDraftMutation, ResetPayAppToDraftMutationVariables>(
    ResetPayAppToDraftDocument,
    options
  )
}
export type ResetPayAppToDraftMutationHookResult = ReturnType<typeof useResetPayAppToDraftMutation>
export type ResetPayAppToDraftMutationResult = Apollo.MutationResult<ResetPayAppToDraftMutation>
export type ResetPayAppToDraftMutationOptions = Apollo.BaseMutationOptions<
  ResetPayAppToDraftMutation,
  ResetPayAppToDraftMutationVariables
>
export const UpdateContractCostDocument = gql`
  mutation updateContractCost($input: UpdateContractCostInput!) {
    updateContractCost(input: $input) {
      id
      cost {
        ...ContractCostProperties
      }
    }
  }
  ${ContractCostPropertiesDoc}
`
export type UpdateContractCostMutationFn = Apollo.MutationFunction<
  UpdateContractCostMutation,
  UpdateContractCostMutationVariables
>

/**
 * __useUpdateContractCostMutation__
 *
 * To run a mutation, you first call `useUpdateContractCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractCostMutation, { data, loading, error }] = useUpdateContractCostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractCostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractCostMutation,
    UpdateContractCostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContractCostMutation, UpdateContractCostMutationVariables>(
    UpdateContractCostDocument,
    options
  )
}
export type UpdateContractCostMutationHookResult = ReturnType<typeof useUpdateContractCostMutation>
export type UpdateContractCostMutationResult = Apollo.MutationResult<UpdateContractCostMutation>
export type UpdateContractCostMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractCostMutation,
  UpdateContractCostMutationVariables
>
export const ContractForErpCustomerNumberDocument = gql`
  query contractForErpCustomerNumber($id: ID!) {
    contract(id: $id) {
      id
      company {
        id
        companyIntegrations {
          id
          type
        }
      }
    }
  }
`

/**
 * __useContractForErpCustomerNumberQuery__
 *
 * To run a query within a React component, call `useContractForErpCustomerNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForErpCustomerNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForErpCustomerNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForErpCustomerNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForErpCustomerNumberQuery,
    ContractForErpCustomerNumberQueryVariables
  > &
    ({ variables: ContractForErpCustomerNumberQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractForErpCustomerNumberQuery,
    ContractForErpCustomerNumberQueryVariables
  >(ContractForErpCustomerNumberDocument, options)
}
export function useContractForErpCustomerNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForErpCustomerNumberQuery,
    ContractForErpCustomerNumberQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForErpCustomerNumberQuery,
    ContractForErpCustomerNumberQueryVariables
  >(ContractForErpCustomerNumberDocument, options)
}
export function useContractForErpCustomerNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForErpCustomerNumberQuery,
        ContractForErpCustomerNumberQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForErpCustomerNumberQuery,
    ContractForErpCustomerNumberQueryVariables
  >(ContractForErpCustomerNumberDocument, options)
}
export type ContractForErpCustomerNumberQueryHookResult = ReturnType<
  typeof useContractForErpCustomerNumberQuery
>
export type ContractForErpCustomerNumberLazyQueryHookResult = ReturnType<
  typeof useContractForErpCustomerNumberLazyQuery
>
export type ContractForErpCustomerNumberSuspenseQueryHookResult = ReturnType<
  typeof useContractForErpCustomerNumberSuspenseQuery
>
export type ContractForErpCustomerNumberQueryResult = Apollo.QueryResult<
  ContractForErpCustomerNumberQuery,
  ContractForErpCustomerNumberQueryVariables
>
export const ContractsForBulkExportDocument = gql`
  query contractsForBulkExport($input: GetPaginatedContractsInput!, $months: [DateTime!]) {
    paginatedContracts(input: $input) {
      contracts {
        id
        internalProjectNumber
        paymentTerms
        paymentTermsType
        billingType
        retentionTrackingLevel
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
        payApps(months: $months) {
          id
          billingEnd
          timeZone
          currentBilled
          currentRetention
          previousRetentionBilled
          retentionHeldOverride
          currentProgressAndRetentionBilled
          payAppNumber
          invoiceNumber
          lastSubmitted {
            id
            status
            statusUpdatedAt
          }
          progress {
            id
            currentBilled
            currentRetention
            sovLineItem {
              id
              name
              sortOrder
              unitPrice
            }
          }
          rateTableItems {
            id
            sortOrder
            group {
              id
            }
          }
          rateTableGroups {
            id
            name
            totalAmount
          }
          amountLineItems {
            id
            description
            amountDuePostTax
            sortOrder
          }
        }
        integrations {
          ...MinimalIntegrationProperties
        }
      }
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useContractsForBulkExportQuery__
 *
 * To run a query within a React component, call `useContractsForBulkExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForBulkExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForBulkExportQuery({
 *   variables: {
 *      input: // value for 'input'
 *      months: // value for 'months'
 *   },
 * });
 */
export function useContractsForBulkExportQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForBulkExportQuery,
    ContractsForBulkExportQueryVariables
  > &
    ({ variables: ContractsForBulkExportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsForBulkExportQuery, ContractsForBulkExportQueryVariables>(
    ContractsForBulkExportDocument,
    options
  )
}
export function useContractsForBulkExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForBulkExportQuery,
    ContractsForBulkExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractsForBulkExportQuery, ContractsForBulkExportQueryVariables>(
    ContractsForBulkExportDocument,
    options
  )
}
export function useContractsForBulkExportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForBulkExportQuery,
        ContractsForBulkExportQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractsForBulkExportQuery, ContractsForBulkExportQueryVariables>(
    ContractsForBulkExportDocument,
    options
  )
}
export type ContractsForBulkExportQueryHookResult = ReturnType<
  typeof useContractsForBulkExportQuery
>
export type ContractsForBulkExportLazyQueryHookResult = ReturnType<
  typeof useContractsForBulkExportLazyQuery
>
export type ContractsForBulkExportSuspenseQueryHookResult = ReturnType<
  typeof useContractsForBulkExportSuspenseQuery
>
export type ContractsForBulkExportQueryResult = Apollo.QueryResult<
  ContractsForBulkExportQuery,
  ContractsForBulkExportQueryVariables
>
export const UpdatePayAppRetentionDocument = gql`
  mutation updatePayAppRetention($input: UpdatePayAppRetentionInput!) {
    updatePayAppRetention(input: $input) {
      id
      previousRetentionBilled
      currentRetention
      totalRetention
      retentionHeldPercent
      retentionHeldOverride
      retentionReleased
      canGenerateForms
      progress {
        id
        currentRetention
        previousRetention
        previousRetentionBilled
        retentionHeldPercent
        retentionHeldOverride
        retentionReleased
        amountDuePreTax
        amountDuePostTax
        amountDueTaxAmount
      }
    }
  }
`
export type UpdatePayAppRetentionMutationFn = Apollo.MutationFunction<
  UpdatePayAppRetentionMutation,
  UpdatePayAppRetentionMutationVariables
>

/**
 * __useUpdatePayAppRetentionMutation__
 *
 * To run a mutation, you first call `useUpdatePayAppRetentionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayAppRetentionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayAppRetentionMutation, { data, loading, error }] = useUpdatePayAppRetentionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePayAppRetentionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePayAppRetentionMutation,
    UpdatePayAppRetentionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePayAppRetentionMutation, UpdatePayAppRetentionMutationVariables>(
    UpdatePayAppRetentionDocument,
    options
  )
}
export type UpdatePayAppRetentionMutationHookResult = ReturnType<
  typeof useUpdatePayAppRetentionMutation
>
export type UpdatePayAppRetentionMutationResult =
  Apollo.MutationResult<UpdatePayAppRetentionMutation>
export type UpdatePayAppRetentionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePayAppRetentionMutation,
  UpdatePayAppRetentionMutationVariables
>
export const CompanyIntegrationTaxGroupsDocument = gql`
  query companyIntegrationTaxGroups($input: GetCompanyIntegrationTaxGroupsInput!) {
    companyIntegrationTaxGroups(input: $input) {
      integrationTaxGroupId
      name
      taxPercent
    }
  }
`

/**
 * __useCompanyIntegrationTaxGroupsQuery__
 *
 * To run a query within a React component, call `useCompanyIntegrationTaxGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIntegrationTaxGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyIntegrationTaxGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyIntegrationTaxGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyIntegrationTaxGroupsQuery,
    CompanyIntegrationTaxGroupsQueryVariables
  > &
    ({ variables: CompanyIntegrationTaxGroupsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyIntegrationTaxGroupsQuery,
    CompanyIntegrationTaxGroupsQueryVariables
  >(CompanyIntegrationTaxGroupsDocument, options)
}
export function useCompanyIntegrationTaxGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyIntegrationTaxGroupsQuery,
    CompanyIntegrationTaxGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyIntegrationTaxGroupsQuery,
    CompanyIntegrationTaxGroupsQueryVariables
  >(CompanyIntegrationTaxGroupsDocument, options)
}
export function useCompanyIntegrationTaxGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyIntegrationTaxGroupsQuery,
        CompanyIntegrationTaxGroupsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyIntegrationTaxGroupsQuery,
    CompanyIntegrationTaxGroupsQueryVariables
  >(CompanyIntegrationTaxGroupsDocument, options)
}
export type CompanyIntegrationTaxGroupsQueryHookResult = ReturnType<
  typeof useCompanyIntegrationTaxGroupsQuery
>
export type CompanyIntegrationTaxGroupsLazyQueryHookResult = ReturnType<
  typeof useCompanyIntegrationTaxGroupsLazyQuery
>
export type CompanyIntegrationTaxGroupsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyIntegrationTaxGroupsSuspenseQuery
>
export type CompanyIntegrationTaxGroupsQueryResult = Apollo.QueryResult<
  CompanyIntegrationTaxGroupsQuery,
  CompanyIntegrationTaxGroupsQueryVariables
>
export const GetContractsForBulkImportDocument = gql`
  query getContractsForBulkImport($input: GetContractsInput!) {
    contracts(input: $input) {
      id
      internalProjectNumber
      project {
        id
        name
        projectNumber
      }
      integrations {
        ...MinimalIntegrationProperties
      }
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`

/**
 * __useGetContractsForBulkImportQuery__
 *
 * To run a query within a React component, call `useGetContractsForBulkImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForBulkImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForBulkImportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForBulkImportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForBulkImportQuery,
    GetContractsForBulkImportQueryVariables
  > &
    ({ variables: GetContractsForBulkImportQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsForBulkImportQuery, GetContractsForBulkImportQueryVariables>(
    GetContractsForBulkImportDocument,
    options
  )
}
export function useGetContractsForBulkImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForBulkImportQuery,
    GetContractsForBulkImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractsForBulkImportQuery,
    GetContractsForBulkImportQueryVariables
  >(GetContractsForBulkImportDocument, options)
}
export function useGetContractsForBulkImportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractsForBulkImportQuery,
        GetContractsForBulkImportQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractsForBulkImportQuery,
    GetContractsForBulkImportQueryVariables
  >(GetContractsForBulkImportDocument, options)
}
export type GetContractsForBulkImportQueryHookResult = ReturnType<
  typeof useGetContractsForBulkImportQuery
>
export type GetContractsForBulkImportLazyQueryHookResult = ReturnType<
  typeof useGetContractsForBulkImportLazyQuery
>
export type GetContractsForBulkImportSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForBulkImportSuspenseQuery
>
export type GetContractsForBulkImportQueryResult = Apollo.QueryResult<
  GetContractsForBulkImportQuery,
  GetContractsForBulkImportQueryVariables
>
export const ClearChangeOrderLogFormsDocument = gql`
  mutation clearChangeOrderLogForms($contractId: ID!) {
    clearChangeOrderLogForms(contractId: $contractId) {
      id
      changeOrderLogTemplate {
        id
      }
      includeChangeOrderLogInPayAppPackage
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type ClearChangeOrderLogFormsMutationFn = Apollo.MutationFunction<
  ClearChangeOrderLogFormsMutation,
  ClearChangeOrderLogFormsMutationVariables
>

/**
 * __useClearChangeOrderLogFormsMutation__
 *
 * To run a mutation, you first call `useClearChangeOrderLogFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearChangeOrderLogFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearChangeOrderLogFormsMutation, { data, loading, error }] = useClearChangeOrderLogFormsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useClearChangeOrderLogFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearChangeOrderLogFormsMutation,
    ClearChangeOrderLogFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearChangeOrderLogFormsMutation,
    ClearChangeOrderLogFormsMutationVariables
  >(ClearChangeOrderLogFormsDocument, options)
}
export type ClearChangeOrderLogFormsMutationHookResult = ReturnType<
  typeof useClearChangeOrderLogFormsMutation
>
export type ClearChangeOrderLogFormsMutationResult =
  Apollo.MutationResult<ClearChangeOrderLogFormsMutation>
export type ClearChangeOrderLogFormsMutationOptions = Apollo.BaseMutationOptions<
  ClearChangeOrderLogFormsMutation,
  ClearChangeOrderLogFormsMutationVariables
>
export const ClearChangeOrderRequestFormsDocument = gql`
  mutation clearChangeOrderRequestForms($contractId: ID!) {
    clearChangeOrderRequestForms(contractId: $contractId) {
      id
      changeOrderRequestTemplate {
        id
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type ClearChangeOrderRequestFormsMutationFn = Apollo.MutationFunction<
  ClearChangeOrderRequestFormsMutation,
  ClearChangeOrderRequestFormsMutationVariables
>

/**
 * __useClearChangeOrderRequestFormsMutation__
 *
 * To run a mutation, you first call `useClearChangeOrderRequestFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearChangeOrderRequestFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearChangeOrderRequestFormsMutation, { data, loading, error }] = useClearChangeOrderRequestFormsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useClearChangeOrderRequestFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearChangeOrderRequestFormsMutation,
    ClearChangeOrderRequestFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearChangeOrderRequestFormsMutation,
    ClearChangeOrderRequestFormsMutationVariables
  >(ClearChangeOrderRequestFormsDocument, options)
}
export type ClearChangeOrderRequestFormsMutationHookResult = ReturnType<
  typeof useClearChangeOrderRequestFormsMutation
>
export type ClearChangeOrderRequestFormsMutationResult =
  Apollo.MutationResult<ClearChangeOrderRequestFormsMutation>
export type ClearChangeOrderRequestFormsMutationOptions = Apollo.BaseMutationOptions<
  ClearChangeOrderRequestFormsMutation,
  ClearChangeOrderRequestFormsMutationVariables
>
export const GetContractsForFormsDocument = gql`
  query getContractsForForms($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      contracts {
        id
        billingType
        internalProjectNumber
        status
        payAppRequirementGroups {
          id
          payAppRequirements {
            id
          }
        }
        lienWaiverTemplates {
          id
        }
        lowerTierLienWaiverTemplates {
          id
        }
        changeOrderRequestTemplate {
          id
          versions {
            id
            versionNumber
          }
        }
        changeOrderLogTemplate {
          id
          versions {
            id
            versionNumber
          }
        }
        onboardedStatus {
          ...OnboardedProjectContractStatusProperties
        }
        company {
          id
        }
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
      }
    }
  }
  ${OnboardedProjectContractStatusPropertiesDoc}
`

/**
 * __useGetContractsForFormsQuery__
 *
 * To run a query within a React component, call `useGetContractsForFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForFormsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForFormsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForFormsQuery,
    GetContractsForFormsQueryVariables
  > &
    ({ variables: GetContractsForFormsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsForFormsQuery, GetContractsForFormsQueryVariables>(
    GetContractsForFormsDocument,
    options
  )
}
export function useGetContractsForFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForFormsQuery,
    GetContractsForFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractsForFormsQuery, GetContractsForFormsQueryVariables>(
    GetContractsForFormsDocument,
    options
  )
}
export function useGetContractsForFormsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractsForFormsQuery, GetContractsForFormsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractsForFormsQuery, GetContractsForFormsQueryVariables>(
    GetContractsForFormsDocument,
    options
  )
}
export type GetContractsForFormsQueryHookResult = ReturnType<typeof useGetContractsForFormsQuery>
export type GetContractsForFormsLazyQueryHookResult = ReturnType<
  typeof useGetContractsForFormsLazyQuery
>
export type GetContractsForFormsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForFormsSuspenseQuery
>
export type GetContractsForFormsQueryResult = Apollo.QueryResult<
  GetContractsForFormsQuery,
  GetContractsForFormsQueryVariables
>
export const GetContractForFormsDocument = gql`
  query getContractForForms($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      project {
        id
        generalContractor {
          company {
            id
          }
        }
      }
    }
  }
`

/**
 * __useGetContractForFormsQuery__
 *
 * To run a query within a React component, call `useGetContractForFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForFormsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForFormsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForFormsQuery,
    GetContractForFormsQueryVariables
  > &
    ({ variables: GetContractForFormsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForFormsQuery, GetContractForFormsQueryVariables>(
    GetContractForFormsDocument,
    options
  )
}
export function useGetContractForFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForFormsQuery,
    GetContractForFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractForFormsQuery, GetContractForFormsQueryVariables>(
    GetContractForFormsDocument,
    options
  )
}
export function useGetContractForFormsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetContractForFormsQuery, GetContractForFormsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractForFormsQuery, GetContractForFormsQueryVariables>(
    GetContractForFormsDocument,
    options
  )
}
export type GetContractForFormsQueryHookResult = ReturnType<typeof useGetContractForFormsQuery>
export type GetContractForFormsLazyQueryHookResult = ReturnType<
  typeof useGetContractForFormsLazyQuery
>
export type GetContractForFormsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForFormsSuspenseQuery
>
export type GetContractForFormsQueryResult = Apollo.QueryResult<
  GetContractForFormsQuery,
  GetContractForFormsQueryVariables
>
export const CompanyForCreateProjectDialogDocument = gql`
  query companyForCreateProjectDialog($id: ID!) {
    company(id: $id) {
      id
      companyIntegrations {
        id
        type
        shortName
        longName
        archivedAt
      }
      defaultQuickBillingTemplate {
        id
      }
    }
  }
`

/**
 * __useCompanyForCreateProjectDialogQuery__
 *
 * To run a query within a React component, call `useCompanyForCreateProjectDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForCreateProjectDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForCreateProjectDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyForCreateProjectDialogQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyForCreateProjectDialogQuery,
    CompanyForCreateProjectDialogQueryVariables
  > &
    ({ variables: CompanyForCreateProjectDialogQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CompanyForCreateProjectDialogQuery,
    CompanyForCreateProjectDialogQueryVariables
  >(CompanyForCreateProjectDialogDocument, options)
}
export function useCompanyForCreateProjectDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyForCreateProjectDialogQuery,
    CompanyForCreateProjectDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CompanyForCreateProjectDialogQuery,
    CompanyForCreateProjectDialogQueryVariables
  >(CompanyForCreateProjectDialogDocument, options)
}
export function useCompanyForCreateProjectDialogSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CompanyForCreateProjectDialogQuery,
        CompanyForCreateProjectDialogQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CompanyForCreateProjectDialogQuery,
    CompanyForCreateProjectDialogQueryVariables
  >(CompanyForCreateProjectDialogDocument, options)
}
export type CompanyForCreateProjectDialogQueryHookResult = ReturnType<
  typeof useCompanyForCreateProjectDialogQuery
>
export type CompanyForCreateProjectDialogLazyQueryHookResult = ReturnType<
  typeof useCompanyForCreateProjectDialogLazyQuery
>
export type CompanyForCreateProjectDialogSuspenseQueryHookResult = ReturnType<
  typeof useCompanyForCreateProjectDialogSuspenseQuery
>
export type CompanyForCreateProjectDialogQueryResult = Apollo.QueryResult<
  CompanyForCreateProjectDialogQuery,
  CompanyForCreateProjectDialogQueryVariables
>
export const CreateProjectImportOperationsDocument = gql`
  mutation createProjectImportOperations($inputs: [ProjectImportOperationInput!]!) {
    createProjectImportOperations(inputs: $inputs) {
      id
    }
  }
`
export type CreateProjectImportOperationsMutationFn = Apollo.MutationFunction<
  CreateProjectImportOperationsMutation,
  CreateProjectImportOperationsMutationVariables
>

/**
 * __useCreateProjectImportOperationsMutation__
 *
 * To run a mutation, you first call `useCreateProjectImportOperationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectImportOperationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectImportOperationsMutation, { data, loading, error }] = useCreateProjectImportOperationsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateProjectImportOperationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProjectImportOperationsMutation,
    CreateProjectImportOperationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProjectImportOperationsMutation,
    CreateProjectImportOperationsMutationVariables
  >(CreateProjectImportOperationsDocument, options)
}
export type CreateProjectImportOperationsMutationHookResult = ReturnType<
  typeof useCreateProjectImportOperationsMutation
>
export type CreateProjectImportOperationsMutationResult =
  Apollo.MutationResult<CreateProjectImportOperationsMutation>
export type CreateProjectImportOperationsMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectImportOperationsMutation,
  CreateProjectImportOperationsMutationVariables
>
export const UpdateContractForGcContactsDocument = gql`
  mutation updateContractForGcContacts($input: UpdateContractInput!) {
    updateContract(input: $input) {
      id
      defaultGcContacts {
        ...CompanyContactProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type UpdateContractForGcContactsMutationFn = Apollo.MutationFunction<
  UpdateContractForGcContactsMutation,
  UpdateContractForGcContactsMutationVariables
>

/**
 * __useUpdateContractForGcContactsMutation__
 *
 * To run a mutation, you first call `useUpdateContractForGcContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractForGcContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractForGcContactsMutation, { data, loading, error }] = useUpdateContractForGcContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractForGcContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractForGcContactsMutation,
    UpdateContractForGcContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractForGcContactsMutation,
    UpdateContractForGcContactsMutationVariables
  >(UpdateContractForGcContactsDocument, options)
}
export type UpdateContractForGcContactsMutationHookResult = ReturnType<
  typeof useUpdateContractForGcContactsMutation
>
export type UpdateContractForGcContactsMutationResult =
  Apollo.MutationResult<UpdateContractForGcContactsMutation>
export type UpdateContractForGcContactsMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractForGcContactsMutation,
  UpdateContractForGcContactsMutationVariables
>
export const ImportIntegrationTaxGroupDocument = gql`
  mutation importIntegrationTaxGroup($input: ImportIntegrationTaxGroupInput!) {
    importIntegrationTaxGroup(input: $input) {
      ...TaxGroupProperties
    }
  }
  ${TaxGroupPropertiesDoc}
`
export type ImportIntegrationTaxGroupMutationFn = Apollo.MutationFunction<
  ImportIntegrationTaxGroupMutation,
  ImportIntegrationTaxGroupMutationVariables
>

/**
 * __useImportIntegrationTaxGroupMutation__
 *
 * To run a mutation, you first call `useImportIntegrationTaxGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportIntegrationTaxGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importIntegrationTaxGroupMutation, { data, loading, error }] = useImportIntegrationTaxGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportIntegrationTaxGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportIntegrationTaxGroupMutation,
    ImportIntegrationTaxGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ImportIntegrationTaxGroupMutation,
    ImportIntegrationTaxGroupMutationVariables
  >(ImportIntegrationTaxGroupDocument, options)
}
export type ImportIntegrationTaxGroupMutationHookResult = ReturnType<
  typeof useImportIntegrationTaxGroupMutation
>
export type ImportIntegrationTaxGroupMutationResult =
  Apollo.MutationResult<ImportIntegrationTaxGroupMutation>
export type ImportIntegrationTaxGroupMutationOptions = Apollo.BaseMutationOptions<
  ImportIntegrationTaxGroupMutation,
  ImportIntegrationTaxGroupMutationVariables
>
export const GetCompanyForOnboardingDocument = gql`
  query getCompanyForOnboarding($id: ID!) {
    company(id: $id) {
      id
      defaultLumpSumRequirements {
        id
        order
        payAppRequirements {
          id
          groupOrder
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      defaultUnitPriceRequirements {
        id
        order
        payAppRequirements {
          id
          groupOrder
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      defaultTimeAndMaterialsRequirements {
        id
        order
        payAppRequirements {
          id
          groupOrder
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      defaultPrimaryLienWaivers {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      defaultVendorLienWaivers {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      defaultChangeOrderRequestTemplate {
        ...MinimalFormTemplateProperties
        variants {
          id
          isDefaultVariant
        }
      }
      defaultChangeOrderLogTemplate {
        ...MinimalFormTemplateProperties
        variants {
          id
          isDefaultVariant
        }
      }
    }
  }
  ${MinimalFormTemplatePropertiesDoc}
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
`

/**
 * __useGetCompanyForOnboardingQuery__
 *
 * To run a query within a React component, call `useGetCompanyForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForOnboardingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyForOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForOnboardingQuery,
    GetCompanyForOnboardingQueryVariables
  > &
    ({ variables: GetCompanyForOnboardingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyForOnboardingQuery, GetCompanyForOnboardingQueryVariables>(
    GetCompanyForOnboardingDocument,
    options
  )
}
export function useGetCompanyForOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForOnboardingQuery,
    GetCompanyForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyForOnboardingQuery, GetCompanyForOnboardingQueryVariables>(
    GetCompanyForOnboardingDocument,
    options
  )
}
export function useGetCompanyForOnboardingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCompanyForOnboardingQuery,
        GetCompanyForOnboardingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetCompanyForOnboardingQuery,
    GetCompanyForOnboardingQueryVariables
  >(GetCompanyForOnboardingDocument, options)
}
export type GetCompanyForOnboardingQueryHookResult = ReturnType<
  typeof useGetCompanyForOnboardingQuery
>
export type GetCompanyForOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForOnboardingLazyQuery
>
export type GetCompanyForOnboardingSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyForOnboardingSuspenseQuery
>
export type GetCompanyForOnboardingQueryResult = Apollo.QueryResult<
  GetCompanyForOnboardingQuery,
  GetCompanyForOnboardingQueryVariables
>
export const GetContractForOnboardingDocument = gql`
  query getContractForOnboarding($contractId: ID!) {
    contract(id: $contractId) {
      includeChangeOrderLogInPayAppPackage
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
        metadata {
          payAppDueOnDayOfMonth
        }
      }
      payApps {
        ...MinimalPayAppProperties
        payAppNumber
        haveContractFeesChanged
        hasContractRateTableChanged
      }
      changeOrderRequests {
        id
      }
      id
      billingType
      pastPayAppCount
      preSitelineBilled
      retentionTrackingLevel
      internalProjectNumber
      timeZone
      company {
        id
      }
      sov {
        id
      }
      integrations {
        id
        type
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      defaultGcContacts {
        ...CompanyContactProperties
      }
      payAppRequirementGroups {
        id
        order
        payAppRequirements {
          id
          groupOrder
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      lienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      lowerTierLienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      changeOrderRequestTemplate {
        ...MinimalFormTemplateProperties
        variants {
          id
          isDefaultVariant
        }
      }
      changeOrderLogTemplate {
        ...MinimalFormTemplateProperties
        variants {
          id
          isDefaultVariant
        }
      }
      users {
        ...MinimalContractUserProperties
      }
      leadPMs {
        ...MinimalUserProperties
      }
      rateTable {
        ...RateTableProperties
      }
      rateTableFees {
        ...RateTableFeeProperties
      }
    }
  }
  ${MinimalPayAppPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${MinimalFormTemplatePropertiesDoc}
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${MinimalContractUserPropertiesDoc}
  ${MinimalUserPropertiesDoc}
  ${RateTablePropertiesDoc}
  ${RateTableFeePropertiesDoc}
`

/**
 * __useGetContractForOnboardingQuery__
 *
 * To run a query within a React component, call `useGetContractForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForOnboardingQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractForOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForOnboardingQuery,
    GetContractForOnboardingQueryVariables
  > &
    ({ variables: GetContractForOnboardingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractForOnboardingQuery, GetContractForOnboardingQueryVariables>(
    GetContractForOnboardingDocument,
    options
  )
}
export function useGetContractForOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForOnboardingQuery,
    GetContractForOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractForOnboardingQuery, GetContractForOnboardingQueryVariables>(
    GetContractForOnboardingDocument,
    options
  )
}
export function useGetContractForOnboardingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForOnboardingQuery,
        GetContractForOnboardingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForOnboardingQuery,
    GetContractForOnboardingQueryVariables
  >(GetContractForOnboardingDocument, options)
}
export type GetContractForOnboardingQueryHookResult = ReturnType<
  typeof useGetContractForOnboardingQuery
>
export type GetContractForOnboardingLazyQueryHookResult = ReturnType<
  typeof useGetContractForOnboardingLazyQuery
>
export type GetContractForOnboardingSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForOnboardingSuspenseQuery
>
export type GetContractForOnboardingQueryResult = Apollo.QueryResult<
  GetContractForOnboardingQuery,
  GetContractForOnboardingQueryVariables
>
export const ClearPayAppFormsDocument = gql`
  mutation clearPayAppForms($contractId: ID!) {
    clearPayAppForms(contractId: $contractId) {
      id
      payAppRequirementGroups {
        id
        payAppRequirements {
          id
          templateVariant {
            id
            template {
              ...MinimalFormTemplateProperties
            }
          }
        }
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalFormTemplatePropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type ClearPayAppFormsMutationFn = Apollo.MutationFunction<
  ClearPayAppFormsMutation,
  ClearPayAppFormsMutationVariables
>

/**
 * __useClearPayAppFormsMutation__
 *
 * To run a mutation, you first call `useClearPayAppFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPayAppFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPayAppFormsMutation, { data, loading, error }] = useClearPayAppFormsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useClearPayAppFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearPayAppFormsMutation,
    ClearPayAppFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ClearPayAppFormsMutation, ClearPayAppFormsMutationVariables>(
    ClearPayAppFormsDocument,
    options
  )
}
export type ClearPayAppFormsMutationHookResult = ReturnType<typeof useClearPayAppFormsMutation>
export type ClearPayAppFormsMutationResult = Apollo.MutationResult<ClearPayAppFormsMutation>
export type ClearPayAppFormsMutationOptions = Apollo.BaseMutationOptions<
  ClearPayAppFormsMutation,
  ClearPayAppFormsMutationVariables
>
export const ClearPrimaryLienWaiverFormsDocument = gql`
  mutation clearPrimaryLienWaiverForms($contractId: ID!) {
    clearPrimaryLienWaiverForms(contractId: $contractId) {
      id
      lienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type ClearPrimaryLienWaiverFormsMutationFn = Apollo.MutationFunction<
  ClearPrimaryLienWaiverFormsMutation,
  ClearPrimaryLienWaiverFormsMutationVariables
>

/**
 * __useClearPrimaryLienWaiverFormsMutation__
 *
 * To run a mutation, you first call `useClearPrimaryLienWaiverFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPrimaryLienWaiverFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPrimaryLienWaiverFormsMutation, { data, loading, error }] = useClearPrimaryLienWaiverFormsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useClearPrimaryLienWaiverFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearPrimaryLienWaiverFormsMutation,
    ClearPrimaryLienWaiverFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearPrimaryLienWaiverFormsMutation,
    ClearPrimaryLienWaiverFormsMutationVariables
  >(ClearPrimaryLienWaiverFormsDocument, options)
}
export type ClearPrimaryLienWaiverFormsMutationHookResult = ReturnType<
  typeof useClearPrimaryLienWaiverFormsMutation
>
export type ClearPrimaryLienWaiverFormsMutationResult =
  Apollo.MutationResult<ClearPrimaryLienWaiverFormsMutation>
export type ClearPrimaryLienWaiverFormsMutationOptions = Apollo.BaseMutationOptions<
  ClearPrimaryLienWaiverFormsMutation,
  ClearPrimaryLienWaiverFormsMutationVariables
>
export const ClearVendorLienWaiverFormsDocument = gql`
  mutation clearVendorLienWaiverForms($contractId: ID!) {
    clearVendorLienWaiverForms(contractId: $contractId) {
      id
      lowerTierLienWaiverTemplates {
        ...MinimalSubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
    }
  }
  ${MinimalSubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type ClearVendorLienWaiverFormsMutationFn = Apollo.MutationFunction<
  ClearVendorLienWaiverFormsMutation,
  ClearVendorLienWaiverFormsMutationVariables
>

/**
 * __useClearVendorLienWaiverFormsMutation__
 *
 * To run a mutation, you first call `useClearVendorLienWaiverFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearVendorLienWaiverFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearVendorLienWaiverFormsMutation, { data, loading, error }] = useClearVendorLienWaiverFormsMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useClearVendorLienWaiverFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearVendorLienWaiverFormsMutation,
    ClearVendorLienWaiverFormsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearVendorLienWaiverFormsMutation,
    ClearVendorLienWaiverFormsMutationVariables
  >(ClearVendorLienWaiverFormsDocument, options)
}
export type ClearVendorLienWaiverFormsMutationHookResult = ReturnType<
  typeof useClearVendorLienWaiverFormsMutation
>
export type ClearVendorLienWaiverFormsMutationResult =
  Apollo.MutationResult<ClearVendorLienWaiverFormsMutation>
export type ClearVendorLienWaiverFormsMutationOptions = Apollo.BaseMutationOptions<
  ClearVendorLienWaiverFormsMutation,
  ClearVendorLienWaiverFormsMutationVariables
>
export const GetContractForUnconditionalDocument = gql`
  query getContractForUnconditional($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      primaryLienWaivers {
        id
        date
        type
      }
      company {
        id
        notarySignature {
          id
        }
      }
      sov {
        ...MinimalSovProperties
      }
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      lienWaiverTemplates {
        id
        unconditionalProgressVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
        unconditionalFinalVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
      }
    }
  }
  ${MinimalSovPropertiesDoc}
`

/**
 * __useGetContractForUnconditionalQuery__
 *
 * To run a query within a React component, call `useGetContractForUnconditionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForUnconditionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForUnconditionalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForUnconditionalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForUnconditionalQuery,
    GetContractForUnconditionalQueryVariables
  > &
    ({ variables: GetContractForUnconditionalQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForUnconditionalQuery,
    GetContractForUnconditionalQueryVariables
  >(GetContractForUnconditionalDocument, options)
}
export function useGetContractForUnconditionalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForUnconditionalQuery,
    GetContractForUnconditionalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForUnconditionalQuery,
    GetContractForUnconditionalQueryVariables
  >(GetContractForUnconditionalDocument, options)
}
export function useGetContractForUnconditionalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForUnconditionalQuery,
        GetContractForUnconditionalQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForUnconditionalQuery,
    GetContractForUnconditionalQueryVariables
  >(GetContractForUnconditionalDocument, options)
}
export type GetContractForUnconditionalQueryHookResult = ReturnType<
  typeof useGetContractForUnconditionalQuery
>
export type GetContractForUnconditionalLazyQueryHookResult = ReturnType<
  typeof useGetContractForUnconditionalLazyQuery
>
export type GetContractForUnconditionalSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForUnconditionalSuspenseQuery
>
export type GetContractForUnconditionalQueryResult = Apollo.QueryResult<
  GetContractForUnconditionalQuery,
  GetContractForUnconditionalQueryVariables
>
export const GetPayAppForUnconditionalDocument = gql`
  query getPayAppForUnconditional($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      retentionOnly
      status
      payAppNumber
      billingStart
      billingEnd
      totalRetention
      amountDuePostTax
      billingType
      balanceToFinish
      integrationPaidAt
      amountPaid
      missingRequiredUnconditionalLienWaiverFields
      contract {
        id
        company {
          id
          name
        }
      }
      emailedContacts {
        ...CompanyContactProperties
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`

/**
 * __useGetPayAppForUnconditionalQuery__
 *
 * To run a query within a React component, call `useGetPayAppForUnconditionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForUnconditionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForUnconditionalQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppForUnconditionalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppForUnconditionalQuery,
    GetPayAppForUnconditionalQueryVariables
  > &
    ({ variables: GetPayAppForUnconditionalQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForUnconditionalQuery, GetPayAppForUnconditionalQueryVariables>(
    GetPayAppForUnconditionalDocument,
    options
  )
}
export function useGetPayAppForUnconditionalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForUnconditionalQuery,
    GetPayAppForUnconditionalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPayAppForUnconditionalQuery,
    GetPayAppForUnconditionalQueryVariables
  >(GetPayAppForUnconditionalDocument, options)
}
export function useGetPayAppForUnconditionalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPayAppForUnconditionalQuery,
        GetPayAppForUnconditionalQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPayAppForUnconditionalQuery,
    GetPayAppForUnconditionalQueryVariables
  >(GetPayAppForUnconditionalDocument, options)
}
export type GetPayAppForUnconditionalQueryHookResult = ReturnType<
  typeof useGetPayAppForUnconditionalQuery
>
export type GetPayAppForUnconditionalLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForUnconditionalLazyQuery
>
export type GetPayAppForUnconditionalSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForUnconditionalSuspenseQuery
>
export type GetPayAppForUnconditionalQueryResult = Apollo.QueryResult<
  GetPayAppForUnconditionalQuery,
  GetPayAppForUnconditionalQueryVariables
>
export const LatestPayAppDocument = gql`
  query latestPayApp($input: LatestPayAppInput!) {
    latestPayApp(input: $input) {
      id
      totalRetention
      balanceToFinish
    }
  }
`

/**
 * __useLatestPayAppQuery__
 *
 * To run a query within a React component, call `useLatestPayAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestPayAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestPayAppQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLatestPayAppQuery(
  baseOptions: Apollo.QueryHookOptions<LatestPayAppQuery, LatestPayAppQueryVariables> &
    ({ variables: LatestPayAppQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LatestPayAppQuery, LatestPayAppQueryVariables>(
    LatestPayAppDocument,
    options
  )
}
export function useLatestPayAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestPayAppQuery, LatestPayAppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LatestPayAppQuery, LatestPayAppQueryVariables>(
    LatestPayAppDocument,
    options
  )
}
export function useLatestPayAppSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LatestPayAppQuery, LatestPayAppQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LatestPayAppQuery, LatestPayAppQueryVariables>(
    LatestPayAppDocument,
    options
  )
}
export type LatestPayAppQueryHookResult = ReturnType<typeof useLatestPayAppQuery>
export type LatestPayAppLazyQueryHookResult = ReturnType<typeof useLatestPayAppLazyQuery>
export type LatestPayAppSuspenseQueryHookResult = ReturnType<typeof useLatestPayAppSuspenseQuery>
export type LatestPayAppQueryResult = Apollo.QueryResult<
  LatestPayAppQuery,
  LatestPayAppQueryVariables
>
export const LienWaiverForMonthDocument = gql`
  query lienWaiverForMonth($input: GetLienWaiverForMonthInput!) {
    lienWaiverForMonth(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`

/**
 * __useLienWaiverForMonthQuery__
 *
 * To run a query within a React component, call `useLienWaiverForMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiverForMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiverForMonthQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLienWaiverForMonthQuery(
  baseOptions: Apollo.QueryHookOptions<LienWaiverForMonthQuery, LienWaiverForMonthQueryVariables> &
    ({ variables: LienWaiverForMonthQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LienWaiverForMonthQuery, LienWaiverForMonthQueryVariables>(
    LienWaiverForMonthDocument,
    options
  )
}
export function useLienWaiverForMonthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiverForMonthQuery,
    LienWaiverForMonthQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LienWaiverForMonthQuery, LienWaiverForMonthQueryVariables>(
    LienWaiverForMonthDocument,
    options
  )
}
export function useLienWaiverForMonthSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<LienWaiverForMonthQuery, LienWaiverForMonthQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LienWaiverForMonthQuery, LienWaiverForMonthQueryVariables>(
    LienWaiverForMonthDocument,
    options
  )
}
export type LienWaiverForMonthQueryHookResult = ReturnType<typeof useLienWaiverForMonthQuery>
export type LienWaiverForMonthLazyQueryHookResult = ReturnType<
  typeof useLienWaiverForMonthLazyQuery
>
export type LienWaiverForMonthSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiverForMonthSuspenseQuery
>
export type LienWaiverForMonthQueryResult = Apollo.QueryResult<
  LienWaiverForMonthQuery,
  LienWaiverForMonthQueryVariables
>
export const SubmitUnconditionalLienWaiverForPreSitelinePayAppDocument = gql`
  mutation submitUnconditionalLienWaiverForPreSitelinePayApp(
    $input: SubmitUnconditionalLienWaiverForPreSitelinePayAppInput!
  ) {
    submitUnconditionalLienWaiverForPreSitelinePayApp(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationFn = Apollo.MutationFunction<
  SubmitUnconditionalLienWaiverForPreSitelinePayAppMutation,
  SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
>

/**
 * __useSubmitUnconditionalLienWaiverForPreSitelinePayAppMutation__
 *
 * To run a mutation, you first call `useSubmitUnconditionalLienWaiverForPreSitelinePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUnconditionalLienWaiverForPreSitelinePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUnconditionalLienWaiverForPreSitelinePayAppMutation, { data, loading, error }] = useSubmitUnconditionalLienWaiverForPreSitelinePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitUnconditionalLienWaiverForPreSitelinePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitUnconditionalLienWaiverForPreSitelinePayAppMutation,
    SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubmitUnconditionalLienWaiverForPreSitelinePayAppMutation,
    SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >(SubmitUnconditionalLienWaiverForPreSitelinePayAppDocument, options)
}
export type SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationHookResult = ReturnType<
  typeof useSubmitUnconditionalLienWaiverForPreSitelinePayAppMutation
>
export type SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationResult =
  Apollo.MutationResult<SubmitUnconditionalLienWaiverForPreSitelinePayAppMutation>
export type SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationOptions =
  Apollo.BaseMutationOptions<
    SubmitUnconditionalLienWaiverForPreSitelinePayAppMutation,
    SubmitUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >
export const GetPreSitelinePayAppForUnconditionalDocument = gql`
  query getPreSitelinePayAppForUnconditional($id: ID!) {
    preSitelinePayApp(id: $id) {
      id
      payAppNumber
      retentionOnly
      paymentDue
      timeZone
      billingStart
      billingEnd
      percentComplete
      status
      isFinal
      paidAt
      unconditionalLienWaiver {
        ...LienWaiverProperties
      }
      contract {
        id
        billingType
        project {
          id
          name
          projectNumber
        }
        company {
          id
          notarySignature {
            id
          }
        }
      }
    }
  }
  ${LienWaiverPropertiesDoc}
`

/**
 * __useGetPreSitelinePayAppForUnconditionalQuery__
 *
 * To run a query within a React component, call `useGetPreSitelinePayAppForUnconditionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreSitelinePayAppForUnconditionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreSitelinePayAppForUnconditionalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPreSitelinePayAppForUnconditionalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPreSitelinePayAppForUnconditionalQuery,
    GetPreSitelinePayAppForUnconditionalQueryVariables
  > &
    (
      | { variables: GetPreSitelinePayAppForUnconditionalQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPreSitelinePayAppForUnconditionalQuery,
    GetPreSitelinePayAppForUnconditionalQueryVariables
  >(GetPreSitelinePayAppForUnconditionalDocument, options)
}
export function useGetPreSitelinePayAppForUnconditionalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreSitelinePayAppForUnconditionalQuery,
    GetPreSitelinePayAppForUnconditionalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPreSitelinePayAppForUnconditionalQuery,
    GetPreSitelinePayAppForUnconditionalQueryVariables
  >(GetPreSitelinePayAppForUnconditionalDocument, options)
}
export function useGetPreSitelinePayAppForUnconditionalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPreSitelinePayAppForUnconditionalQuery,
        GetPreSitelinePayAppForUnconditionalQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPreSitelinePayAppForUnconditionalQuery,
    GetPreSitelinePayAppForUnconditionalQueryVariables
  >(GetPreSitelinePayAppForUnconditionalDocument, options)
}
export type GetPreSitelinePayAppForUnconditionalQueryHookResult = ReturnType<
  typeof useGetPreSitelinePayAppForUnconditionalQuery
>
export type GetPreSitelinePayAppForUnconditionalLazyQueryHookResult = ReturnType<
  typeof useGetPreSitelinePayAppForUnconditionalLazyQuery
>
export type GetPreSitelinePayAppForUnconditionalSuspenseQueryHookResult = ReturnType<
  typeof useGetPreSitelinePayAppForUnconditionalSuspenseQuery
>
export type GetPreSitelinePayAppForUnconditionalQueryResult = Apollo.QueryResult<
  GetPreSitelinePayAppForUnconditionalQuery,
  GetPreSitelinePayAppForUnconditionalQueryVariables
>
export const SignUnconditionalLienWaiverForPreSitelinePayAppDocument = gql`
  mutation signUnconditionalLienWaiverForPreSitelinePayApp(
    $input: SignUnconditionalLienWaiverForPreSitelinePayAppInput!
  ) {
    signUnconditionalLienWaiverForPreSitelinePayApp(input: $input) {
      ...LienWaiverProperties
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type SignUnconditionalLienWaiverForPreSitelinePayAppMutationFn = Apollo.MutationFunction<
  SignUnconditionalLienWaiverForPreSitelinePayAppMutation,
  SignUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
>

/**
 * __useSignUnconditionalLienWaiverForPreSitelinePayAppMutation__
 *
 * To run a mutation, you first call `useSignUnconditionalLienWaiverForPreSitelinePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUnconditionalLienWaiverForPreSitelinePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUnconditionalLienWaiverForPreSitelinePayAppMutation, { data, loading, error }] = useSignUnconditionalLienWaiverForPreSitelinePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUnconditionalLienWaiverForPreSitelinePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUnconditionalLienWaiverForPreSitelinePayAppMutation,
    SignUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SignUnconditionalLienWaiverForPreSitelinePayAppMutation,
    SignUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >(SignUnconditionalLienWaiverForPreSitelinePayAppDocument, options)
}
export type SignUnconditionalLienWaiverForPreSitelinePayAppMutationHookResult = ReturnType<
  typeof useSignUnconditionalLienWaiverForPreSitelinePayAppMutation
>
export type SignUnconditionalLienWaiverForPreSitelinePayAppMutationResult =
  Apollo.MutationResult<SignUnconditionalLienWaiverForPreSitelinePayAppMutation>
export type SignUnconditionalLienWaiverForPreSitelinePayAppMutationOptions =
  Apollo.BaseMutationOptions<
    SignUnconditionalLienWaiverForPreSitelinePayAppMutation,
    SignUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >
export const ClearUnconditionalLienWaiverForPreSitelinePayAppDocument = gql`
  mutation clearUnconditionalLienWaiverForPreSitelinePayApp($payAppId: ID!) {
    clearUnconditionalLienWaiverForPreSitelinePayApp(payAppId: $payAppId) {
      id
      unconditionalLienWaiver {
        ...LienWaiverProperties
      }
    }
  }
  ${LienWaiverPropertiesDoc}
`
export type ClearUnconditionalLienWaiverForPreSitelinePayAppMutationFn = Apollo.MutationFunction<
  ClearUnconditionalLienWaiverForPreSitelinePayAppMutation,
  ClearUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
>

/**
 * __useClearUnconditionalLienWaiverForPreSitelinePayAppMutation__
 *
 * To run a mutation, you first call `useClearUnconditionalLienWaiverForPreSitelinePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUnconditionalLienWaiverForPreSitelinePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUnconditionalLienWaiverForPreSitelinePayAppMutation, { data, loading, error }] = useClearUnconditionalLienWaiverForPreSitelinePayAppMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useClearUnconditionalLienWaiverForPreSitelinePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearUnconditionalLienWaiverForPreSitelinePayAppMutation,
    ClearUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearUnconditionalLienWaiverForPreSitelinePayAppMutation,
    ClearUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >(ClearUnconditionalLienWaiverForPreSitelinePayAppDocument, options)
}
export type ClearUnconditionalLienWaiverForPreSitelinePayAppMutationHookResult = ReturnType<
  typeof useClearUnconditionalLienWaiverForPreSitelinePayAppMutation
>
export type ClearUnconditionalLienWaiverForPreSitelinePayAppMutationResult =
  Apollo.MutationResult<ClearUnconditionalLienWaiverForPreSitelinePayAppMutation>
export type ClearUnconditionalLienWaiverForPreSitelinePayAppMutationOptions =
  Apollo.BaseMutationOptions<
    ClearUnconditionalLienWaiverForPreSitelinePayAppMutation,
    ClearUnconditionalLienWaiverForPreSitelinePayAppMutationVariables
  >
export const DeletePreSitelinePayAppDocument = gql`
  mutation deletePreSitelinePayApp($id: ID!) {
    deletePreSitelinePayApp(id: $id) {
      id
    }
  }
`
export type DeletePreSitelinePayAppMutationFn = Apollo.MutationFunction<
  DeletePreSitelinePayAppMutation,
  DeletePreSitelinePayAppMutationVariables
>

/**
 * __useDeletePreSitelinePayAppMutation__
 *
 * To run a mutation, you first call `useDeletePreSitelinePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreSitelinePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreSitelinePayAppMutation, { data, loading, error }] = useDeletePreSitelinePayAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePreSitelinePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePreSitelinePayAppMutation,
    DeletePreSitelinePayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePreSitelinePayAppMutation,
    DeletePreSitelinePayAppMutationVariables
  >(DeletePreSitelinePayAppDocument, options)
}
export type DeletePreSitelinePayAppMutationHookResult = ReturnType<
  typeof useDeletePreSitelinePayAppMutation
>
export type DeletePreSitelinePayAppMutationResult =
  Apollo.MutationResult<DeletePreSitelinePayAppMutation>
export type DeletePreSitelinePayAppMutationOptions = Apollo.BaseMutationOptions<
  DeletePreSitelinePayAppMutation,
  DeletePreSitelinePayAppMutationVariables
>
export const CreatePreSitelinePayAppDocument = gql`
  mutation createPreSitelinePayApp($input: CreatePreSitelinePayAppInput!) {
    createPreSitelinePayApp(input: $input) {
      ...PreSitelinePayAppForProjectHomeProperties
    }
  }
  ${PreSitelinePayAppForProjectHomePropertiesDoc}
`
export type CreatePreSitelinePayAppMutationFn = Apollo.MutationFunction<
  CreatePreSitelinePayAppMutation,
  CreatePreSitelinePayAppMutationVariables
>

/**
 * __useCreatePreSitelinePayAppMutation__
 *
 * To run a mutation, you first call `useCreatePreSitelinePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreSitelinePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreSitelinePayAppMutation, { data, loading, error }] = useCreatePreSitelinePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreSitelinePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePreSitelinePayAppMutation,
    CreatePreSitelinePayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePreSitelinePayAppMutation,
    CreatePreSitelinePayAppMutationVariables
  >(CreatePreSitelinePayAppDocument, options)
}
export type CreatePreSitelinePayAppMutationHookResult = ReturnType<
  typeof useCreatePreSitelinePayAppMutation
>
export type CreatePreSitelinePayAppMutationResult =
  Apollo.MutationResult<CreatePreSitelinePayAppMutation>
export type CreatePreSitelinePayAppMutationOptions = Apollo.BaseMutationOptions<
  CreatePreSitelinePayAppMutation,
  CreatePreSitelinePayAppMutationVariables
>
export const UpdatePreSitelinePayAppDocument = gql`
  mutation updatePreSitelinePayApp($input: UpdatePreSitelinePayAppInput!) {
    updatePreSitelinePayApp(input: $input) {
      ...PreSitelinePayAppForProjectHomeProperties
    }
  }
  ${PreSitelinePayAppForProjectHomePropertiesDoc}
`
export type UpdatePreSitelinePayAppMutationFn = Apollo.MutationFunction<
  UpdatePreSitelinePayAppMutation,
  UpdatePreSitelinePayAppMutationVariables
>

/**
 * __useUpdatePreSitelinePayAppMutation__
 *
 * To run a mutation, you first call `useUpdatePreSitelinePayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreSitelinePayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreSitelinePayAppMutation, { data, loading, error }] = useUpdatePreSitelinePayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreSitelinePayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreSitelinePayAppMutation,
    UpdatePreSitelinePayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePreSitelinePayAppMutation,
    UpdatePreSitelinePayAppMutationVariables
  >(UpdatePreSitelinePayAppDocument, options)
}
export type UpdatePreSitelinePayAppMutationHookResult = ReturnType<
  typeof useUpdatePreSitelinePayAppMutation
>
export type UpdatePreSitelinePayAppMutationResult =
  Apollo.MutationResult<UpdatePreSitelinePayAppMutation>
export type UpdatePreSitelinePayAppMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreSitelinePayAppMutation,
  UpdatePreSitelinePayAppMutationVariables
>
export const DeletePreSitelinePayAppFileDocument = gql`
  mutation deletePreSitelinePayAppFile($payAppId: ID!) {
    deletePreSitelinePayAppFile(payAppId: $payAppId) {
      id
      payAppFile {
        id
      }
    }
  }
`
export type DeletePreSitelinePayAppFileMutationFn = Apollo.MutationFunction<
  DeletePreSitelinePayAppFileMutation,
  DeletePreSitelinePayAppFileMutationVariables
>

/**
 * __useDeletePreSitelinePayAppFileMutation__
 *
 * To run a mutation, you first call `useDeletePreSitelinePayAppFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreSitelinePayAppFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreSitelinePayAppFileMutation, { data, loading, error }] = useDeletePreSitelinePayAppFileMutation({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useDeletePreSitelinePayAppFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePreSitelinePayAppFileMutation,
    DeletePreSitelinePayAppFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePreSitelinePayAppFileMutation,
    DeletePreSitelinePayAppFileMutationVariables
  >(DeletePreSitelinePayAppFileDocument, options)
}
export type DeletePreSitelinePayAppFileMutationHookResult = ReturnType<
  typeof useDeletePreSitelinePayAppFileMutation
>
export type DeletePreSitelinePayAppFileMutationResult =
  Apollo.MutationResult<DeletePreSitelinePayAppFileMutation>
export type DeletePreSitelinePayAppFileMutationOptions = Apollo.BaseMutationOptions<
  DeletePreSitelinePayAppFileMutation,
  DeletePreSitelinePayAppFileMutationVariables
>
export const ContractsForQuickBillAutocompleteDocument = gql`
  query contractsForQuickBillAutocomplete($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      contracts {
        id
        project {
          id
          name
          projectNumber
          location {
            ...LocationProperties
          }
        }
      }
    }
  }
  ${LocationPropertiesDoc}
`

/**
 * __useContractsForQuickBillAutocompleteQuery__
 *
 * To run a query within a React component, call `useContractsForQuickBillAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsForQuickBillAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsForQuickBillAutocompleteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractsForQuickBillAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractsForQuickBillAutocompleteQuery,
    ContractsForQuickBillAutocompleteQueryVariables
  > &
    (
      | { variables: ContractsForQuickBillAutocompleteQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractsForQuickBillAutocompleteQuery,
    ContractsForQuickBillAutocompleteQueryVariables
  >(ContractsForQuickBillAutocompleteDocument, options)
}
export function useContractsForQuickBillAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractsForQuickBillAutocompleteQuery,
    ContractsForQuickBillAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractsForQuickBillAutocompleteQuery,
    ContractsForQuickBillAutocompleteQueryVariables
  >(ContractsForQuickBillAutocompleteDocument, options)
}
export function useContractsForQuickBillAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractsForQuickBillAutocompleteQuery,
        ContractsForQuickBillAutocompleteQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractsForQuickBillAutocompleteQuery,
    ContractsForQuickBillAutocompleteQueryVariables
  >(ContractsForQuickBillAutocompleteDocument, options)
}
export type ContractsForQuickBillAutocompleteQueryHookResult = ReturnType<
  typeof useContractsForQuickBillAutocompleteQuery
>
export type ContractsForQuickBillAutocompleteLazyQueryHookResult = ReturnType<
  typeof useContractsForQuickBillAutocompleteLazyQuery
>
export type ContractsForQuickBillAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useContractsForQuickBillAutocompleteSuspenseQuery
>
export type ContractsForQuickBillAutocompleteQueryResult = Apollo.QueryResult<
  ContractsForQuickBillAutocompleteQuery,
  ContractsForQuickBillAutocompleteQueryVariables
>
export const GetPayAppForQuickBillDocument = gql`
  query getPayAppForQuickBill($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      status
      timeZone
      billingType
      retentionOnly
      payAppNumber
      currentBilled
      currentRetention
      previousRetentionBilled
      retentionHeldOverride
      amountDuePostTax
      integrationPaidAt
      amountPaid
      isBalanceManuallyClosed
      statusChangedAt
      lastSubmitted {
        id
        status
        statusUpdatedAt
      }
      automaticallyReadBilling
      balanceToFinish
      lastSync {
        ...MinimalWriteSyncOperationProperties
      }
      billingEnd
      predictedPaymentDate
      contract {
        id
        internalProjectNumber
        taxCalculationType
        company {
          id
          name
          taxGroups {
            ...TaxGroupProperties
          }
        }
        integrations {
          ...MinimalIntegrationProperties
        }
        defaultTaxGroup {
          ...TaxGroupProperties
        }
        project {
          id
          projectNumber
        }
      }
      amountLineItems {
        ...AmountLineItemProperties
      }
      attachments {
        id
        description
        name
        createdAt
        uploadId
        file {
          ...StoredFileProperties
        }
      }
    }
  }
  ${MinimalWriteSyncOperationPropertiesDoc}
  ${TaxGroupPropertiesDoc}
  ${MinimalIntegrationPropertiesDoc}
  ${AmountLineItemPropertiesDoc}
  ${StoredFilePropertiesDoc}
`

/**
 * __useGetPayAppForQuickBillQuery__
 *
 * To run a query within a React component, call `useGetPayAppForQuickBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAppForQuickBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAppForQuickBillQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function useGetPayAppForQuickBillQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayAppForQuickBillQuery,
    GetPayAppForQuickBillQueryVariables
  > &
    ({ variables: GetPayAppForQuickBillQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPayAppForQuickBillQuery, GetPayAppForQuickBillQueryVariables>(
    GetPayAppForQuickBillDocument,
    options
  )
}
export function useGetPayAppForQuickBillLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayAppForQuickBillQuery,
    GetPayAppForQuickBillQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPayAppForQuickBillQuery, GetPayAppForQuickBillQueryVariables>(
    GetPayAppForQuickBillDocument,
    options
  )
}
export function useGetPayAppForQuickBillSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPayAppForQuickBillQuery,
        GetPayAppForQuickBillQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPayAppForQuickBillQuery, GetPayAppForQuickBillQueryVariables>(
    GetPayAppForQuickBillDocument,
    options
  )
}
export type GetPayAppForQuickBillQueryHookResult = ReturnType<typeof useGetPayAppForQuickBillQuery>
export type GetPayAppForQuickBillLazyQueryHookResult = ReturnType<
  typeof useGetPayAppForQuickBillLazyQuery
>
export type GetPayAppForQuickBillSuspenseQueryHookResult = ReturnType<
  typeof useGetPayAppForQuickBillSuspenseQuery
>
export type GetPayAppForQuickBillQueryResult = Apollo.QueryResult<
  GetPayAppForQuickBillQuery,
  GetPayAppForQuickBillQueryVariables
>
export const AddIntegrationToContractDocument = gql`
  mutation addIntegrationToContract($input: AddIntegrationToContractInput!) {
    addIntegrationToContract(input: $input) {
      ...MinimalContractProperties
    }
  }
  ${MinimalContractPropertiesDoc}
`
export type AddIntegrationToContractMutationFn = Apollo.MutationFunction<
  AddIntegrationToContractMutation,
  AddIntegrationToContractMutationVariables
>

/**
 * __useAddIntegrationToContractMutation__
 *
 * To run a mutation, you first call `useAddIntegrationToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationToContractMutation, { data, loading, error }] = useAddIntegrationToContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIntegrationToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddIntegrationToContractMutation,
    AddIntegrationToContractMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddIntegrationToContractMutation,
    AddIntegrationToContractMutationVariables
  >(AddIntegrationToContractDocument, options)
}
export type AddIntegrationToContractMutationHookResult = ReturnType<
  typeof useAddIntegrationToContractMutation
>
export type AddIntegrationToContractMutationResult =
  Apollo.MutationResult<AddIntegrationToContractMutation>
export type AddIntegrationToContractMutationOptions = Apollo.BaseMutationOptions<
  AddIntegrationToContractMutation,
  AddIntegrationToContractMutationVariables
>
export const SetIntegrationTaxGroupDocument = gql`
  mutation setIntegrationTaxGroup($input: SetIntegrationTaxGroupInput!) {
    setIntegrationTaxGroup(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`
export type SetIntegrationTaxGroupMutationFn = Apollo.MutationFunction<
  SetIntegrationTaxGroupMutation,
  SetIntegrationTaxGroupMutationVariables
>

/**
 * __useSetIntegrationTaxGroupMutation__
 *
 * To run a mutation, you first call `useSetIntegrationTaxGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIntegrationTaxGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIntegrationTaxGroupMutation, { data, loading, error }] = useSetIntegrationTaxGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIntegrationTaxGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIntegrationTaxGroupMutation,
    SetIntegrationTaxGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetIntegrationTaxGroupMutation,
    SetIntegrationTaxGroupMutationVariables
  >(SetIntegrationTaxGroupDocument, options)
}
export type SetIntegrationTaxGroupMutationHookResult = ReturnType<
  typeof useSetIntegrationTaxGroupMutation
>
export type SetIntegrationTaxGroupMutationResult =
  Apollo.MutationResult<SetIntegrationTaxGroupMutation>
export type SetIntegrationTaxGroupMutationOptions = Apollo.BaseMutationOptions<
  SetIntegrationTaxGroupMutation,
  SetIntegrationTaxGroupMutationVariables
>
export const IntegrationCustomersDocument = gql`
  query integrationCustomers($input: GetIntegrationCustomersInput!) {
    integrationCustomers(input: $input) {
      ...IntegrationCustomerProperties
    }
  }
  ${IntegrationCustomerPropertiesDoc}
`

/**
 * __useIntegrationCustomersQuery__
 *
 * To run a query within a React component, call `useIntegrationCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationCustomersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIntegrationCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationCustomersQuery,
    IntegrationCustomersQueryVariables
  > &
    ({ variables: IntegrationCustomersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IntegrationCustomersQuery, IntegrationCustomersQueryVariables>(
    IntegrationCustomersDocument,
    options
  )
}
export function useIntegrationCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationCustomersQuery,
    IntegrationCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IntegrationCustomersQuery, IntegrationCustomersQueryVariables>(
    IntegrationCustomersDocument,
    options
  )
}
export function useIntegrationCustomersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<IntegrationCustomersQuery, IntegrationCustomersQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IntegrationCustomersQuery, IntegrationCustomersQueryVariables>(
    IntegrationCustomersDocument,
    options
  )
}
export type IntegrationCustomersQueryHookResult = ReturnType<typeof useIntegrationCustomersQuery>
export type IntegrationCustomersLazyQueryHookResult = ReturnType<
  typeof useIntegrationCustomersLazyQuery
>
export type IntegrationCustomersSuspenseQueryHookResult = ReturnType<
  typeof useIntegrationCustomersSuspenseQuery
>
export type IntegrationCustomersQueryResult = Apollo.QueryResult<
  IntegrationCustomersQuery,
  IntegrationCustomersQueryVariables
>
export const SetIntegrationCustomerDocument = gql`
  mutation setIntegrationCustomer($input: SetIntegrationCustomerInput!) {
    setIntegrationCustomer(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${MinimalIntegrationPropertiesDoc}
`
export type SetIntegrationCustomerMutationFn = Apollo.MutationFunction<
  SetIntegrationCustomerMutation,
  SetIntegrationCustomerMutationVariables
>

/**
 * __useSetIntegrationCustomerMutation__
 *
 * To run a mutation, you first call `useSetIntegrationCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIntegrationCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIntegrationCustomerMutation, { data, loading, error }] = useSetIntegrationCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIntegrationCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIntegrationCustomerMutation,
    SetIntegrationCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetIntegrationCustomerMutation,
    SetIntegrationCustomerMutationVariables
  >(SetIntegrationCustomerDocument, options)
}
export type SetIntegrationCustomerMutationHookResult = ReturnType<
  typeof useSetIntegrationCustomerMutation
>
export type SetIntegrationCustomerMutationResult =
  Apollo.MutationResult<SetIntegrationCustomerMutation>
export type SetIntegrationCustomerMutationOptions = Apollo.BaseMutationOptions<
  SetIntegrationCustomerMutation,
  SetIntegrationCustomerMutationVariables
>
export const DeleteIntegrationDocument = gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIntegrationMutation,
    DeleteIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(
    DeleteIntegrationDocument,
    options
  )
}
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables
>
export const UpdateContractWithContactsDocument = gql`
  mutation updateContractWithContacts($input: UpdateContractInput!) {
    updateContract(input: $input) {
      ...MinimalContractProperties
      firstPayAppBillingEnd
      hasStartedBilling
      defaultGcContacts {
        ...CompanyContactProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      project {
        id
      }
      payApps {
        id
        payAppNumber
        balanceToFinish
        totalRetention
        emailedContacts {
          ...CompanyContactProperties
        }
      }
    }
  }
  ${MinimalContractPropertiesDoc}
  ${CompanyContactPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
`
export type UpdateContractWithContactsMutationFn = Apollo.MutationFunction<
  UpdateContractWithContactsMutation,
  UpdateContractWithContactsMutationVariables
>

/**
 * __useUpdateContractWithContactsMutation__
 *
 * To run a mutation, you first call `useUpdateContractWithContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractWithContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractWithContactsMutation, { data, loading, error }] = useUpdateContractWithContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractWithContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractWithContactsMutation,
    UpdateContractWithContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractWithContactsMutation,
    UpdateContractWithContactsMutationVariables
  >(UpdateContractWithContactsDocument, options)
}
export type UpdateContractWithContactsMutationHookResult = ReturnType<
  typeof useUpdateContractWithContactsMutation
>
export type UpdateContractWithContactsMutationResult =
  Apollo.MutationResult<UpdateContractWithContactsMutation>
export type UpdateContractWithContactsMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractWithContactsMutation,
  UpdateContractWithContactsMutationVariables
>
export const UpdateContractForAuditItemDocument = gql`
  mutation updateContractForAuditItem($input: UpdateContractInput!) {
    updateContract(input: $input) {
      id
      showAuditItemOnSignedPackages
    }
  }
`
export type UpdateContractForAuditItemMutationFn = Apollo.MutationFunction<
  UpdateContractForAuditItemMutation,
  UpdateContractForAuditItemMutationVariables
>

/**
 * __useUpdateContractForAuditItemMutation__
 *
 * To run a mutation, you first call `useUpdateContractForAuditItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractForAuditItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractForAuditItemMutation, { data, loading, error }] = useUpdateContractForAuditItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractForAuditItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractForAuditItemMutation,
    UpdateContractForAuditItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateContractForAuditItemMutation,
    UpdateContractForAuditItemMutationVariables
  >(UpdateContractForAuditItemDocument, options)
}
export type UpdateContractForAuditItemMutationHookResult = ReturnType<
  typeof useUpdateContractForAuditItemMutation
>
export type UpdateContractForAuditItemMutationResult =
  Apollo.MutationResult<UpdateContractForAuditItemMutation>
export type UpdateContractForAuditItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractForAuditItemMutation,
  UpdateContractForAuditItemMutationVariables
>
export const PayAppForFormsDocument = gql`
  query payAppForForms($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      canGenerateForms
      physicalSignatureRequired
      missingRequiredFields
      status
    }
  }
`

/**
 * __usePayAppForFormsQuery__
 *
 * To run a query within a React component, call `usePayAppForFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayAppForFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayAppForFormsQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function usePayAppForFormsQuery(
  baseOptions: Apollo.QueryHookOptions<PayAppForFormsQuery, PayAppForFormsQueryVariables> &
    ({ variables: PayAppForFormsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayAppForFormsQuery, PayAppForFormsQueryVariables>(
    PayAppForFormsDocument,
    options
  )
}
export function usePayAppForFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PayAppForFormsQuery, PayAppForFormsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PayAppForFormsQuery, PayAppForFormsQueryVariables>(
    PayAppForFormsDocument,
    options
  )
}
export function usePayAppForFormsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PayAppForFormsQuery, PayAppForFormsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PayAppForFormsQuery, PayAppForFormsQueryVariables>(
    PayAppForFormsDocument,
    options
  )
}
export type PayAppForFormsQueryHookResult = ReturnType<typeof usePayAppForFormsQuery>
export type PayAppForFormsLazyQueryHookResult = ReturnType<typeof usePayAppForFormsLazyQuery>
export type PayAppForFormsSuspenseQueryHookResult = ReturnType<
  typeof usePayAppForFormsSuspenseQuery
>
export type PayAppForFormsQueryResult = Apollo.QueryResult<
  PayAppForFormsQuery,
  PayAppForFormsQueryVariables
>
export const SubmitDraftPayAppDocument = gql`
  mutation submitDraftPayApp($input: SubmitDraftPayAppInput!) {
    submitDraftPayApp(input: $input) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type SubmitDraftPayAppMutationFn = Apollo.MutationFunction<
  SubmitDraftPayAppMutation,
  SubmitDraftPayAppMutationVariables
>

/**
 * __useSubmitDraftPayAppMutation__
 *
 * To run a mutation, you first call `useSubmitDraftPayAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDraftPayAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDraftPayAppMutation, { data, loading, error }] = useSubmitDraftPayAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitDraftPayAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitDraftPayAppMutation,
    SubmitDraftPayAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitDraftPayAppMutation, SubmitDraftPayAppMutationVariables>(
    SubmitDraftPayAppDocument,
    options
  )
}
export type SubmitDraftPayAppMutationHookResult = ReturnType<typeof useSubmitDraftPayAppMutation>
export type SubmitDraftPayAppMutationResult = Apollo.MutationResult<SubmitDraftPayAppMutation>
export type SubmitDraftPayAppMutationOptions = Apollo.BaseMutationOptions<
  SubmitDraftPayAppMutation,
  SubmitDraftPayAppMutationVariables
>
export const SubmitForFullReviewDocument = gql`
  mutation submitForFullReview($input: SubmitPayAppInput!) {
    submitForFullReview(input: $input) {
      ...PayAppProperties
      balanceToFinish
    }
  }
  ${PayAppPropertiesDoc}
`
export type SubmitForFullReviewMutationFn = Apollo.MutationFunction<
  SubmitForFullReviewMutation,
  SubmitForFullReviewMutationVariables
>

/**
 * __useSubmitForFullReviewMutation__
 *
 * To run a mutation, you first call `useSubmitForFullReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitForFullReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitForFullReviewMutation, { data, loading, error }] = useSubmitForFullReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitForFullReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitForFullReviewMutation,
    SubmitForFullReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitForFullReviewMutation, SubmitForFullReviewMutationVariables>(
    SubmitForFullReviewDocument,
    options
  )
}
export type SubmitForFullReviewMutationHookResult = ReturnType<
  typeof useSubmitForFullReviewMutation
>
export type SubmitForFullReviewMutationResult = Apollo.MutationResult<SubmitForFullReviewMutation>
export type SubmitForFullReviewMutationOptions = Apollo.BaseMutationOptions<
  SubmitForFullReviewMutation,
  SubmitForFullReviewMutationVariables
>
export const SubmitBackupDocument = gql`
  mutation submitBackup($input: SubmitBackupInput!) {
    submitBackup(input: $input) {
      ...PayAppProperties
    }
  }
  ${PayAppPropertiesDoc}
`
export type SubmitBackupMutationFn = Apollo.MutationFunction<
  SubmitBackupMutation,
  SubmitBackupMutationVariables
>

/**
 * __useSubmitBackupMutation__
 *
 * To run a mutation, you first call `useSubmitBackupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBackupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBackupMutation, { data, loading, error }] = useSubmitBackupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitBackupMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitBackupMutation, SubmitBackupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitBackupMutation, SubmitBackupMutationVariables>(
    SubmitBackupDocument,
    options
  )
}
export type SubmitBackupMutationHookResult = ReturnType<typeof useSubmitBackupMutation>
export type SubmitBackupMutationResult = Apollo.MutationResult<SubmitBackupMutation>
export type SubmitBackupMutationOptions = Apollo.BaseMutationOptions<
  SubmitBackupMutation,
  SubmitBackupMutationVariables
>
export const GetProjectForSubmitDocument = gql`
  query getProjectForSubmit($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGetProjectForSubmitQuery__
 *
 * To run a query within a React component, call `useGetProjectForSubmitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectForSubmitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectForSubmitQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectForSubmitQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectForSubmitQuery,
    GetProjectForSubmitQueryVariables
  > &
    ({ variables: GetProjectForSubmitQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectForSubmitQuery, GetProjectForSubmitQueryVariables>(
    GetProjectForSubmitDocument,
    options
  )
}
export function useGetProjectForSubmitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectForSubmitQuery,
    GetProjectForSubmitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetProjectForSubmitQuery, GetProjectForSubmitQueryVariables>(
    GetProjectForSubmitDocument,
    options
  )
}
export function useGetProjectForSubmitSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetProjectForSubmitQuery, GetProjectForSubmitQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetProjectForSubmitQuery, GetProjectForSubmitQueryVariables>(
    GetProjectForSubmitDocument,
    options
  )
}
export type GetProjectForSubmitQueryHookResult = ReturnType<typeof useGetProjectForSubmitQuery>
export type GetProjectForSubmitLazyQueryHookResult = ReturnType<
  typeof useGetProjectForSubmitLazyQuery
>
export type GetProjectForSubmitSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectForSubmitSuspenseQuery
>
export type GetProjectForSubmitQueryResult = Apollo.QueryResult<
  GetProjectForSubmitQuery,
  GetProjectForSubmitQueryVariables
>
export const GetIntegrationIncomeTypeNumbersDocument = gql`
  query getIntegrationIncomeTypeNumbers($input: IntegrationIncomeTypeNumbersInput!) {
    integrationIncomeTypeNumbers(input: $input) {
      integrationIncomeTypeNumberId
      incomeTypeNumber
      description
    }
  }
`

/**
 * __useGetIntegrationIncomeTypeNumbersQuery__
 *
 * To run a query within a React component, call `useGetIntegrationIncomeTypeNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationIncomeTypeNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationIncomeTypeNumbersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetIntegrationIncomeTypeNumbersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationIncomeTypeNumbersQuery,
    GetIntegrationIncomeTypeNumbersQueryVariables
  > &
    (
      | { variables: GetIntegrationIncomeTypeNumbersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetIntegrationIncomeTypeNumbersQuery,
    GetIntegrationIncomeTypeNumbersQueryVariables
  >(GetIntegrationIncomeTypeNumbersDocument, options)
}
export function useGetIntegrationIncomeTypeNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationIncomeTypeNumbersQuery,
    GetIntegrationIncomeTypeNumbersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetIntegrationIncomeTypeNumbersQuery,
    GetIntegrationIncomeTypeNumbersQueryVariables
  >(GetIntegrationIncomeTypeNumbersDocument, options)
}
export function useGetIntegrationIncomeTypeNumbersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetIntegrationIncomeTypeNumbersQuery,
        GetIntegrationIncomeTypeNumbersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetIntegrationIncomeTypeNumbersQuery,
    GetIntegrationIncomeTypeNumbersQueryVariables
  >(GetIntegrationIncomeTypeNumbersDocument, options)
}
export type GetIntegrationIncomeTypeNumbersQueryHookResult = ReturnType<
  typeof useGetIntegrationIncomeTypeNumbersQuery
>
export type GetIntegrationIncomeTypeNumbersLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationIncomeTypeNumbersLazyQuery
>
export type GetIntegrationIncomeTypeNumbersSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationIncomeTypeNumbersSuspenseQuery
>
export type GetIntegrationIncomeTypeNumbersQueryResult = Apollo.QueryResult<
  GetIntegrationIncomeTypeNumbersQuery,
  GetIntegrationIncomeTypeNumbersQueryVariables
>
export const ReadIntegrationSovDocument = gql`
  query readIntegrationSov($input: ReadIntegrationSovInput!) {
    readIntegrationSov(input: $input) {
      lineItems {
        integrationLineItemId
        code
        description
        scheduledValue
        billedToDate
        retentionToDate
        unitPrice
      }
    }
  }
`

/**
 * __useReadIntegrationSovQuery__
 *
 * To run a query within a React component, call `useReadIntegrationSovQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadIntegrationSovQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadIntegrationSovQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadIntegrationSovQuery(
  baseOptions: Apollo.QueryHookOptions<ReadIntegrationSovQuery, ReadIntegrationSovQueryVariables> &
    ({ variables: ReadIntegrationSovQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReadIntegrationSovQuery, ReadIntegrationSovQueryVariables>(
    ReadIntegrationSovDocument,
    options
  )
}
export function useReadIntegrationSovLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadIntegrationSovQuery,
    ReadIntegrationSovQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReadIntegrationSovQuery, ReadIntegrationSovQueryVariables>(
    ReadIntegrationSovDocument,
    options
  )
}
export function useReadIntegrationSovSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ReadIntegrationSovQuery, ReadIntegrationSovQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReadIntegrationSovQuery, ReadIntegrationSovQueryVariables>(
    ReadIntegrationSovDocument,
    options
  )
}
export type ReadIntegrationSovQueryHookResult = ReturnType<typeof useReadIntegrationSovQuery>
export type ReadIntegrationSovLazyQueryHookResult = ReturnType<
  typeof useReadIntegrationSovLazyQuery
>
export type ReadIntegrationSovSuspenseQueryHookResult = ReturnType<
  typeof useReadIntegrationSovSuspenseQuery
>
export type ReadIntegrationSovQueryResult = Apollo.QueryResult<
  ReadIntegrationSovQuery,
  ReadIntegrationSovQueryVariables
>
export const PayAppForSage100SyncDocument = gql`
  query payAppForSage100Sync($payAppId: ID!) {
    payApp(id: $payAppId) {
      id
      billingEnd
      payAppNumber
      currentBilled
      amountDuePostTax
      amountDueTaxAmount
      lastSubmitted {
        id
        statusUpdatedAt
      }
      contract {
        id
        taxCalculationType
        appliesTaxToRetention
      }
      progress {
        id
        currentBilled
        sovLineItem {
          id
          code
          name
          sortOrder
        }
      }
    }
  }
`

/**
 * __usePayAppForSage100SyncQuery__
 *
 * To run a query within a React component, call `usePayAppForSage100SyncQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayAppForSage100SyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayAppForSage100SyncQuery({
 *   variables: {
 *      payAppId: // value for 'payAppId'
 *   },
 * });
 */
export function usePayAppForSage100SyncQuery(
  baseOptions: Apollo.QueryHookOptions<
    PayAppForSage100SyncQuery,
    PayAppForSage100SyncQueryVariables
  > &
    ({ variables: PayAppForSage100SyncQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PayAppForSage100SyncQuery, PayAppForSage100SyncQueryVariables>(
    PayAppForSage100SyncDocument,
    options
  )
}
export function usePayAppForSage100SyncLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PayAppForSage100SyncQuery,
    PayAppForSage100SyncQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PayAppForSage100SyncQuery, PayAppForSage100SyncQueryVariables>(
    PayAppForSage100SyncDocument,
    options
  )
}
export function usePayAppForSage100SyncSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PayAppForSage100SyncQuery, PayAppForSage100SyncQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PayAppForSage100SyncQuery, PayAppForSage100SyncQueryVariables>(
    PayAppForSage100SyncDocument,
    options
  )
}
export type PayAppForSage100SyncQueryHookResult = ReturnType<typeof usePayAppForSage100SyncQuery>
export type PayAppForSage100SyncLazyQueryHookResult = ReturnType<
  typeof usePayAppForSage100SyncLazyQuery
>
export type PayAppForSage100SyncSuspenseQueryHookResult = ReturnType<
  typeof usePayAppForSage100SyncSuspenseQuery
>
export type PayAppForSage100SyncQueryResult = Apollo.QueryResult<
  PayAppForSage100SyncQuery,
  PayAppForSage100SyncQueryVariables
>
export const GetIntegrationGeneralLedgerAccountsDocument = gql`
  query getIntegrationGeneralLedgerAccounts($companyIntegrationId: ID!) {
    integrationGeneralLedgerAccounts(companyIntegrationId: $companyIntegrationId) {
      integrationAccountId
      parentName
      parentCode
      code
      name
    }
  }
`

/**
 * __useGetIntegrationGeneralLedgerAccountsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationGeneralLedgerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationGeneralLedgerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationGeneralLedgerAccountsQuery({
 *   variables: {
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *   },
 * });
 */
export function useGetIntegrationGeneralLedgerAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationGeneralLedgerAccountsQuery,
    GetIntegrationGeneralLedgerAccountsQueryVariables
  > &
    (
      | { variables: GetIntegrationGeneralLedgerAccountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetIntegrationGeneralLedgerAccountsQuery,
    GetIntegrationGeneralLedgerAccountsQueryVariables
  >(GetIntegrationGeneralLedgerAccountsDocument, options)
}
export function useGetIntegrationGeneralLedgerAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationGeneralLedgerAccountsQuery,
    GetIntegrationGeneralLedgerAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetIntegrationGeneralLedgerAccountsQuery,
    GetIntegrationGeneralLedgerAccountsQueryVariables
  >(GetIntegrationGeneralLedgerAccountsDocument, options)
}
export function useGetIntegrationGeneralLedgerAccountsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetIntegrationGeneralLedgerAccountsQuery,
        GetIntegrationGeneralLedgerAccountsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetIntegrationGeneralLedgerAccountsQuery,
    GetIntegrationGeneralLedgerAccountsQueryVariables
  >(GetIntegrationGeneralLedgerAccountsDocument, options)
}
export type GetIntegrationGeneralLedgerAccountsQueryHookResult = ReturnType<
  typeof useGetIntegrationGeneralLedgerAccountsQuery
>
export type GetIntegrationGeneralLedgerAccountsLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationGeneralLedgerAccountsLazyQuery
>
export type GetIntegrationGeneralLedgerAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationGeneralLedgerAccountsSuspenseQuery
>
export type GetIntegrationGeneralLedgerAccountsQueryResult = Apollo.QueryResult<
  GetIntegrationGeneralLedgerAccountsQuery,
  GetIntegrationGeneralLedgerAccountsQueryVariables
>
export const GetContractsForLegalRequirementDocument = gql`
  query getContractsForLegalRequirement($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      hasNext
      cursor
      contracts {
        id
        status
        billingType
        internalProjectNumber
        complianceStatus
        onboardedProjectComplianceStatus {
          completedOnboarding
        }
        company {
          id
        }
        integrations {
          id
          type
          shortName
          longName
        }
        timeZone
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetContractsForLegalRequirementQuery__
 *
 * To run a query within a React component, call `useGetContractsForLegalRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForLegalRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForLegalRequirementQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForLegalRequirementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForLegalRequirementQuery,
    GetContractsForLegalRequirementQueryVariables
  > &
    (
      | { variables: GetContractsForLegalRequirementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractsForLegalRequirementQuery,
    GetContractsForLegalRequirementQueryVariables
  >(GetContractsForLegalRequirementDocument, options)
}
export function useGetContractsForLegalRequirementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForLegalRequirementQuery,
    GetContractsForLegalRequirementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractsForLegalRequirementQuery,
    GetContractsForLegalRequirementQueryVariables
  >(GetContractsForLegalRequirementDocument, options)
}
export function useGetContractsForLegalRequirementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractsForLegalRequirementQuery,
        GetContractsForLegalRequirementQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractsForLegalRequirementQuery,
    GetContractsForLegalRequirementQueryVariables
  >(GetContractsForLegalRequirementDocument, options)
}
export type GetContractsForLegalRequirementQueryHookResult = ReturnType<
  typeof useGetContractsForLegalRequirementQuery
>
export type GetContractsForLegalRequirementLazyQueryHookResult = ReturnType<
  typeof useGetContractsForLegalRequirementLazyQuery
>
export type GetContractsForLegalRequirementSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForLegalRequirementSuspenseQuery
>
export type GetContractsForLegalRequirementQueryResult = Apollo.QueryResult<
  GetContractsForLegalRequirementQuery,
  GetContractsForLegalRequirementQueryVariables
>
export const LienWaiverRequestWithTokenForForwardingDocument = gql`
  query lienWaiverRequestWithTokenForForwarding($token: String!) {
    lienWaiverRequestWithToken(input: { token: $token }) {
      id
      status
      actionTakenAt
      lienWaiver {
        id
        timeZone
        payApps {
          id
          emailedContacts {
            id
            companyName
          }
        }
      }
    }
  }
`

/**
 * __useLienWaiverRequestWithTokenForForwardingQuery__
 *
 * To run a query within a React component, call `useLienWaiverRequestWithTokenForForwardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLienWaiverRequestWithTokenForForwardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLienWaiverRequestWithTokenForForwardingQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLienWaiverRequestWithTokenForForwardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    LienWaiverRequestWithTokenForForwardingQuery,
    LienWaiverRequestWithTokenForForwardingQueryVariables
  > &
    (
      | { variables: LienWaiverRequestWithTokenForForwardingQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LienWaiverRequestWithTokenForForwardingQuery,
    LienWaiverRequestWithTokenForForwardingQueryVariables
  >(LienWaiverRequestWithTokenForForwardingDocument, options)
}
export function useLienWaiverRequestWithTokenForForwardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LienWaiverRequestWithTokenForForwardingQuery,
    LienWaiverRequestWithTokenForForwardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LienWaiverRequestWithTokenForForwardingQuery,
    LienWaiverRequestWithTokenForForwardingQueryVariables
  >(LienWaiverRequestWithTokenForForwardingDocument, options)
}
export function useLienWaiverRequestWithTokenForForwardingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LienWaiverRequestWithTokenForForwardingQuery,
        LienWaiverRequestWithTokenForForwardingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    LienWaiverRequestWithTokenForForwardingQuery,
    LienWaiverRequestWithTokenForForwardingQueryVariables
  >(LienWaiverRequestWithTokenForForwardingDocument, options)
}
export type LienWaiverRequestWithTokenForForwardingQueryHookResult = ReturnType<
  typeof useLienWaiverRequestWithTokenForForwardingQuery
>
export type LienWaiverRequestWithTokenForForwardingLazyQueryHookResult = ReturnType<
  typeof useLienWaiverRequestWithTokenForForwardingLazyQuery
>
export type LienWaiverRequestWithTokenForForwardingSuspenseQueryHookResult = ReturnType<
  typeof useLienWaiverRequestWithTokenForForwardingSuspenseQuery
>
export type LienWaiverRequestWithTokenForForwardingQueryResult = Apollo.QueryResult<
  LienWaiverRequestWithTokenForForwardingQuery,
  LienWaiverRequestWithTokenForForwardingQueryVariables
>
export const OptOutVendorFromDigitalSignaturesDocument = gql`
  mutation optOutVendorFromDigitalSignatures($input: OptOutVendorFromDigitalSignaturesInput!) {
    optOutVendorFromDigitalSignatures(input: $input) {
      ...VendorContactProperties
    }
  }
  ${VendorContactPropertiesDoc}
`
export type OptOutVendorFromDigitalSignaturesMutationFn = Apollo.MutationFunction<
  OptOutVendorFromDigitalSignaturesMutation,
  OptOutVendorFromDigitalSignaturesMutationVariables
>

/**
 * __useOptOutVendorFromDigitalSignaturesMutation__
 *
 * To run a mutation, you first call `useOptOutVendorFromDigitalSignaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutVendorFromDigitalSignaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutVendorFromDigitalSignaturesMutation, { data, loading, error }] = useOptOutVendorFromDigitalSignaturesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptOutVendorFromDigitalSignaturesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OptOutVendorFromDigitalSignaturesMutation,
    OptOutVendorFromDigitalSignaturesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OptOutVendorFromDigitalSignaturesMutation,
    OptOutVendorFromDigitalSignaturesMutationVariables
  >(OptOutVendorFromDigitalSignaturesDocument, options)
}
export type OptOutVendorFromDigitalSignaturesMutationHookResult = ReturnType<
  typeof useOptOutVendorFromDigitalSignaturesMutation
>
export type OptOutVendorFromDigitalSignaturesMutationResult =
  Apollo.MutationResult<OptOutVendorFromDigitalSignaturesMutation>
export type OptOutVendorFromDigitalSignaturesMutationOptions = Apollo.BaseMutationOptions<
  OptOutVendorFromDigitalSignaturesMutation,
  OptOutVendorFromDigitalSignaturesMutationVariables
>
export const GetContractForLegalRequirementDocument = gql`
  query getContractForLegalRequirement($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...ContractForComplianceProjectHome
    }
  }
  ${ContractForComplianceProjectHomeDoc}
`

/**
 * __useGetContractForLegalRequirementQuery__
 *
 * To run a query within a React component, call `useGetContractForLegalRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForLegalRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForLegalRequirementQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForLegalRequirementQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForLegalRequirementQuery,
    GetContractForLegalRequirementQueryVariables
  > &
    (
      | { variables: GetContractForLegalRequirementQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForLegalRequirementQuery,
    GetContractForLegalRequirementQueryVariables
  >(GetContractForLegalRequirementDocument, options)
}
export function useGetContractForLegalRequirementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForLegalRequirementQuery,
    GetContractForLegalRequirementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForLegalRequirementQuery,
    GetContractForLegalRequirementQueryVariables
  >(GetContractForLegalRequirementDocument, options)
}
export function useGetContractForLegalRequirementSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForLegalRequirementQuery,
        GetContractForLegalRequirementQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForLegalRequirementQuery,
    GetContractForLegalRequirementQueryVariables
  >(GetContractForLegalRequirementDocument, options)
}
export type GetContractForLegalRequirementQueryHookResult = ReturnType<
  typeof useGetContractForLegalRequirementQuery
>
export type GetContractForLegalRequirementLazyQueryHookResult = ReturnType<
  typeof useGetContractForLegalRequirementLazyQuery
>
export type GetContractForLegalRequirementSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForLegalRequirementSuspenseQuery
>
export type GetContractForLegalRequirementQueryResult = Apollo.QueryResult<
  GetContractForLegalRequirementQuery,
  GetContractForLegalRequirementQueryVariables
>
export const GetContractsForComplianceDocument = gql`
  query getContractsForCompliance($input: GetContractsInput!) {
    contracts(input: $input) {
      id
      internalProjectNumber
      status
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      onboardedProjectComplianceStatus {
        ...OnboardedProjectComplianceStatusProperties
      }
      legalRequirements {
        ...LegalRequirementProperties
      }
    }
  }
  ${OnboardedProjectComplianceStatusPropertiesDoc}
  ${LegalRequirementPropertiesDoc}
`

/**
 * __useGetContractsForComplianceQuery__
 *
 * To run a query within a React component, call `useGetContractsForComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForComplianceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForComplianceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForComplianceQuery,
    GetContractsForComplianceQueryVariables
  > &
    ({ variables: GetContractsForComplianceQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsForComplianceQuery, GetContractsForComplianceQueryVariables>(
    GetContractsForComplianceDocument,
    options
  )
}
export function useGetContractsForComplianceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForComplianceQuery,
    GetContractsForComplianceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractsForComplianceQuery,
    GetContractsForComplianceQueryVariables
  >(GetContractsForComplianceDocument, options)
}
export function useGetContractsForComplianceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractsForComplianceQuery,
        GetContractsForComplianceQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractsForComplianceQuery,
    GetContractsForComplianceQueryVariables
  >(GetContractsForComplianceDocument, options)
}
export type GetContractsForComplianceQueryHookResult = ReturnType<
  typeof useGetContractsForComplianceQuery
>
export type GetContractsForComplianceLazyQueryHookResult = ReturnType<
  typeof useGetContractsForComplianceLazyQuery
>
export type GetContractsForComplianceSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForComplianceSuspenseQuery
>
export type GetContractsForComplianceQueryResult = Apollo.QueryResult<
  GetContractsForComplianceQuery,
  GetContractsForComplianceQueryVariables
>
export const GetContractsForTopNavDocument = gql`
  query getContractsForTopNav($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      hasNext
      totalCount
      contracts {
        id
        billingType
        status
        internalProjectNumber
        company {
          id
        }
        payApps {
          id
          billingType
        }
        project {
          id
          name
          projectNumber
        }
      }
    }
  }
`

/**
 * __useGetContractsForTopNavQuery__
 *
 * To run a query within a React component, call `useGetContractsForTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsForTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsForTopNavQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractsForTopNavQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractsForTopNavQuery,
    GetContractsForTopNavQueryVariables
  > &
    ({ variables: GetContractsForTopNavQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetContractsForTopNavQuery, GetContractsForTopNavQueryVariables>(
    GetContractsForTopNavDocument,
    options
  )
}
export function useGetContractsForTopNavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractsForTopNavQuery,
    GetContractsForTopNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetContractsForTopNavQuery, GetContractsForTopNavQueryVariables>(
    GetContractsForTopNavDocument,
    options
  )
}
export function useGetContractsForTopNavSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractsForTopNavQuery,
        GetContractsForTopNavQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetContractsForTopNavQuery, GetContractsForTopNavQueryVariables>(
    GetContractsForTopNavDocument,
    options
  )
}
export type GetContractsForTopNavQueryHookResult = ReturnType<typeof useGetContractsForTopNavQuery>
export type GetContractsForTopNavLazyQueryHookResult = ReturnType<
  typeof useGetContractsForTopNavLazyQuery
>
export type GetContractsForTopNavSuspenseQueryHookResult = ReturnType<
  typeof useGetContractsForTopNavSuspenseQuery
>
export type GetContractsForTopNavQueryResult = Apollo.QueryResult<
  GetContractsForTopNavQuery,
  GetContractsForTopNavQueryVariables
>
export const ContractForTopNavDocument = gql`
  query contractForTopNav($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      status
      internalProjectNumber
      company {
        id
      }
      payApps {
        id
        billingType
      }
      project {
        id
        name
        projectNumber
      }
    }
  }
`

/**
 * __useContractForTopNavQuery__
 *
 * To run a query within a React component, call `useContractForTopNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForTopNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForTopNavQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractForTopNavQuery(
  baseOptions: Apollo.QueryHookOptions<ContractForTopNavQuery, ContractForTopNavQueryVariables> &
    ({ variables: ContractForTopNavQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractForTopNavQuery, ContractForTopNavQueryVariables>(
    ContractForTopNavDocument,
    options
  )
}
export function useContractForTopNavLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractForTopNavQuery, ContractForTopNavQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractForTopNavQuery, ContractForTopNavQueryVariables>(
    ContractForTopNavDocument,
    options
  )
}
export function useContractForTopNavSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractForTopNavQuery, ContractForTopNavQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractForTopNavQuery, ContractForTopNavQueryVariables>(
    ContractForTopNavDocument,
    options
  )
}
export type ContractForTopNavQueryHookResult = ReturnType<typeof useContractForTopNavQuery>
export type ContractForTopNavLazyQueryHookResult = ReturnType<typeof useContractForTopNavLazyQuery>
export type ContractForTopNavSuspenseQueryHookResult = ReturnType<
  typeof useContractForTopNavSuspenseQuery
>
export type ContractForTopNavQueryResult = Apollo.QueryResult<
  ContractForTopNavQuery,
  ContractForTopNavQueryVariables
>
export const ArchiveAllNotificationsDocument = gql`
  mutation archiveAllNotifications {
    archiveAllNotifications
  }
`
export type ArchiveAllNotificationsMutationFn = Apollo.MutationFunction<
  ArchiveAllNotificationsMutation,
  ArchiveAllNotificationsMutationVariables
>

/**
 * __useArchiveAllNotificationsMutation__
 *
 * To run a mutation, you first call `useArchiveAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAllNotificationsMutation, { data, loading, error }] = useArchiveAllNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useArchiveAllNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveAllNotificationsMutation,
    ArchiveAllNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveAllNotificationsMutation,
    ArchiveAllNotificationsMutationVariables
  >(ArchiveAllNotificationsDocument, options)
}
export type ArchiveAllNotificationsMutationHookResult = ReturnType<
  typeof useArchiveAllNotificationsMutation
>
export type ArchiveAllNotificationsMutationResult =
  Apollo.MutationResult<ArchiveAllNotificationsMutation>
export type ArchiveAllNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveAllNotificationsMutation,
  ArchiveAllNotificationsMutationVariables
>
export const ArchiveNotificationDocument = gql`
  mutation archiveNotification($id: ID!) {
    archiveNotification(id: $id) {
      id
    }
  }
`
export type ArchiveNotificationMutationFn = Apollo.MutationFunction<
  ArchiveNotificationMutation,
  ArchiveNotificationMutationVariables
>

/**
 * __useArchiveNotificationMutation__
 *
 * To run a mutation, you first call `useArchiveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNotificationMutation, { data, loading, error }] = useArchiveNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveNotificationMutation,
    ArchiveNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveNotificationMutation, ArchiveNotificationMutationVariables>(
    ArchiveNotificationDocument,
    options
  )
}
export type ArchiveNotificationMutationHookResult = ReturnType<
  typeof useArchiveNotificationMutation
>
export type ArchiveNotificationMutationResult = Apollo.MutationResult<ArchiveNotificationMutation>
export type ArchiveNotificationMutationOptions = Apollo.BaseMutationOptions<
  ArchiveNotificationMutation,
  ArchiveNotificationMutationVariables
>
export const ContractForAdditionalOptionsMenuDocument = gql`
  query contractForAdditionalOptionsMenu($contractId: ID!, $months: [DateTime!]!) {
    contract(id: $contractId) {
      id
      timeZone
      payApps(months: $months) {
        id
        billingEnd
        retentionOnly
        balanceToFinish
        totalRetention
      }
      lienWaiverTemplates {
        id
        unconditionalProgressVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
        unconditionalFinalVariant {
          id
          template {
            id
            isCustomerReady
          }
        }
      }
      sov {
        id
        totalValue
        totalBilled
      }
    }
  }
`

/**
 * __useContractForAdditionalOptionsMenuQuery__
 *
 * To run a query within a React component, call `useContractForAdditionalOptionsMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForAdditionalOptionsMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForAdditionalOptionsMenuQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      months: // value for 'months'
 *   },
 * });
 */
export function useContractForAdditionalOptionsMenuQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForAdditionalOptionsMenuQuery,
    ContractForAdditionalOptionsMenuQueryVariables
  > &
    (
      | { variables: ContractForAdditionalOptionsMenuQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractForAdditionalOptionsMenuQuery,
    ContractForAdditionalOptionsMenuQueryVariables
  >(ContractForAdditionalOptionsMenuDocument, options)
}
export function useContractForAdditionalOptionsMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForAdditionalOptionsMenuQuery,
    ContractForAdditionalOptionsMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForAdditionalOptionsMenuQuery,
    ContractForAdditionalOptionsMenuQueryVariables
  >(ContractForAdditionalOptionsMenuDocument, options)
}
export function useContractForAdditionalOptionsMenuSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForAdditionalOptionsMenuQuery,
        ContractForAdditionalOptionsMenuQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForAdditionalOptionsMenuQuery,
    ContractForAdditionalOptionsMenuQueryVariables
  >(ContractForAdditionalOptionsMenuDocument, options)
}
export type ContractForAdditionalOptionsMenuQueryHookResult = ReturnType<
  typeof useContractForAdditionalOptionsMenuQuery
>
export type ContractForAdditionalOptionsMenuLazyQueryHookResult = ReturnType<
  typeof useContractForAdditionalOptionsMenuLazyQuery
>
export type ContractForAdditionalOptionsMenuSuspenseQueryHookResult = ReturnType<
  typeof useContractForAdditionalOptionsMenuSuspenseQuery
>
export type ContractForAdditionalOptionsMenuQueryResult = Apollo.QueryResult<
  ContractForAdditionalOptionsMenuQuery,
  ContractForAdditionalOptionsMenuQueryVariables
>
export const GetBulkCreatePayAppsContractsDocument = gql`
  query getBulkCreatePayAppsContracts($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      contracts {
        id
        internalProjectNumber
        skippedPayAppMonths
        timeZone
        billingType
        percentComplete
        onboardedStatus {
          addedSov
        }
        project {
          id
          name
          projectNumber
          metadata {
            payAppDueOnDayOfMonth
          }
        }
        payApps {
          id
          billingStart
          billingEnd
          payAppDueDate
          retentionOnly
        }
        sov {
          id
        }
      }
    }
  }
`

/**
 * __useGetBulkCreatePayAppsContractsQuery__
 *
 * To run a query within a React component, call `useGetBulkCreatePayAppsContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkCreatePayAppsContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkCreatePayAppsContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBulkCreatePayAppsContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBulkCreatePayAppsContractsQuery,
    GetBulkCreatePayAppsContractsQueryVariables
  > &
    ({ variables: GetBulkCreatePayAppsContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBulkCreatePayAppsContractsQuery,
    GetBulkCreatePayAppsContractsQueryVariables
  >(GetBulkCreatePayAppsContractsDocument, options)
}
export function useGetBulkCreatePayAppsContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBulkCreatePayAppsContractsQuery,
    GetBulkCreatePayAppsContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBulkCreatePayAppsContractsQuery,
    GetBulkCreatePayAppsContractsQueryVariables
  >(GetBulkCreatePayAppsContractsDocument, options)
}
export function useGetBulkCreatePayAppsContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetBulkCreatePayAppsContractsQuery,
        GetBulkCreatePayAppsContractsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetBulkCreatePayAppsContractsQuery,
    GetBulkCreatePayAppsContractsQueryVariables
  >(GetBulkCreatePayAppsContractsDocument, options)
}
export type GetBulkCreatePayAppsContractsQueryHookResult = ReturnType<
  typeof useGetBulkCreatePayAppsContractsQuery
>
export type GetBulkCreatePayAppsContractsLazyQueryHookResult = ReturnType<
  typeof useGetBulkCreatePayAppsContractsLazyQuery
>
export type GetBulkCreatePayAppsContractsSuspenseQueryHookResult = ReturnType<
  typeof useGetBulkCreatePayAppsContractsSuspenseQuery
>
export type GetBulkCreatePayAppsContractsQueryResult = Apollo.QueryResult<
  GetBulkCreatePayAppsContractsQuery,
  GetBulkCreatePayAppsContractsQueryVariables
>
export const GetHomepageContractsDocument = gql`
  query getHomepageContracts($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      contracts {
        id
        role
        status
        internalProjectNumber
        retentionTrackingLevel
        skippedPayAppMonths
        daysBeforePayAppDue
        billingType
        timeZone
        nextPayAppNumber
        percentComplete
        pastPayAppCount
        company {
          id
        }
        integrations {
          id
          type
          shortName
          longName
        }
        sov {
          id
          totalRetention
        }
        onboardedStatus {
          addedSov
          selectedRateTable
          isComplete
          isReadyForPayAppSubmit
        }
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
          metadata {
            payAppDueOnDayOfMonth
          }
        }
        leadPMs {
          id
          firstName
          lastName
        }
        payApps {
          id
          timeZone
          billingStart
          billingEnd
          billingType
          status
          retentionOnly
          invoiceReady
          currentBilled
          previousRetentionBilled
          amountDuePostTax
          payAppDueDate
          internalDueDate
          draftSubmittedAt
          payAppNumber
          currentRetention
          amountPaid
          isBalanceManuallyClosed
        }
        lastEditedAt
        lastEditedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`

/**
 * __useGetHomepageContractsQuery__
 *
 * To run a query within a React component, call `useGetHomepageContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomepageContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomepageContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHomepageContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHomepageContractsQuery,
    GetHomepageContractsQueryVariables
  > &
    ({ variables: GetHomepageContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetHomepageContractsQuery, GetHomepageContractsQueryVariables>(
    GetHomepageContractsDocument,
    options
  )
}
export function useGetHomepageContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHomepageContractsQuery,
    GetHomepageContractsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetHomepageContractsQuery, GetHomepageContractsQueryVariables>(
    GetHomepageContractsDocument,
    options
  )
}
export function useGetHomepageContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetHomepageContractsQuery, GetHomepageContractsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetHomepageContractsQuery, GetHomepageContractsQueryVariables>(
    GetHomepageContractsDocument,
    options
  )
}
export type GetHomepageContractsQueryHookResult = ReturnType<typeof useGetHomepageContractsQuery>
export type GetHomepageContractsLazyQueryHookResult = ReturnType<
  typeof useGetHomepageContractsLazyQuery
>
export type GetHomepageContractsSuspenseQueryHookResult = ReturnType<
  typeof useGetHomepageContractsSuspenseQuery
>
export type GetHomepageContractsQueryResult = Apollo.QueryResult<
  GetHomepageContractsQuery,
  GetHomepageContractsQueryVariables
>
export const ContractsOverviewDocument = gql`
  query contractsOverview($companyId: ID!) {
    contractsOverview(companyId: $companyId) {
      activeCount
      archivedCount
      onboardingCount
      fullyBilledCount
    }
  }
`

/**
 * __useContractsOverviewQuery__
 *
 * To run a query within a React component, call `useContractsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsOverviewQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useContractsOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<ContractsOverviewQuery, ContractsOverviewQueryVariables> &
    ({ variables: ContractsOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractsOverviewQuery, ContractsOverviewQueryVariables>(
    ContractsOverviewDocument,
    options
  )
}
export function useContractsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractsOverviewQuery, ContractsOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContractsOverviewQuery, ContractsOverviewQueryVariables>(
    ContractsOverviewDocument,
    options
  )
}
export function useContractsOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractsOverviewQuery, ContractsOverviewQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContractsOverviewQuery, ContractsOverviewQueryVariables>(
    ContractsOverviewDocument,
    options
  )
}
export type ContractsOverviewQueryHookResult = ReturnType<typeof useContractsOverviewQuery>
export type ContractsOverviewLazyQueryHookResult = ReturnType<typeof useContractsOverviewLazyQuery>
export type ContractsOverviewSuspenseQueryHookResult = ReturnType<
  typeof useContractsOverviewSuspenseQuery
>
export type ContractsOverviewQueryResult = Apollo.QueryResult<
  ContractsOverviewQuery,
  ContractsOverviewQueryVariables
>
export const ContractForAgingSidesheetDocument = gql`
  query contractForAgingSidesheet($id: ID!) {
    contract(id: $id) {
      id
      internalProjectNumber
      timeZone
      billingType
      preSitelineBilled
      preSitelineRetention
      averageDaysToPaid
      paymentTermsType
      paymentTerms
      pastPayAppCount
      defaultGcContacts {
        ...CompanyContactProperties
      }
      company {
        id
        agingIntervalType
      }
      project {
        id
        projectNumber
        name
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      payApps {
        id
        status
        currentBilled
        currentRetention
        balanceToFinish
        totalRetention
        amountPaid
        predictedPaymentDate
        billingEnd
        retentionOnly
        payAppNumber
        amountDuePostTax
        isBalanceManuallyClosed
        lastSubmitted {
          id
          statusUpdatedAt
        }
      }
      preSitelinePayApps {
        id
        status
        amountPaid
        paymentDue
        isBalanceManuallyClosed
        billingEnd
        submittedAt
        payAppNumber
      }
      sov {
        id
        totalValue
        lineItems {
          id
          previousBilled
          preSitelineRetention
        }
      }
    }
  }
  ${CompanyContactPropertiesDoc}
`

/**
 * __useContractForAgingSidesheetQuery__
 *
 * To run a query within a React component, call `useContractForAgingSidesheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForAgingSidesheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForAgingSidesheetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForAgingSidesheetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForAgingSidesheetQuery,
    ContractForAgingSidesheetQueryVariables
  > &
    ({ variables: ContractForAgingSidesheetQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContractForAgingSidesheetQuery, ContractForAgingSidesheetQueryVariables>(
    ContractForAgingSidesheetDocument,
    options
  )
}
export function useContractForAgingSidesheetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForAgingSidesheetQuery,
    ContractForAgingSidesheetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForAgingSidesheetQuery,
    ContractForAgingSidesheetQueryVariables
  >(ContractForAgingSidesheetDocument, options)
}
export function useContractForAgingSidesheetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForAgingSidesheetQuery,
        ContractForAgingSidesheetQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForAgingSidesheetQuery,
    ContractForAgingSidesheetQueryVariables
  >(ContractForAgingSidesheetDocument, options)
}
export type ContractForAgingSidesheetQueryHookResult = ReturnType<
  typeof useContractForAgingSidesheetQuery
>
export type ContractForAgingSidesheetLazyQueryHookResult = ReturnType<
  typeof useContractForAgingSidesheetLazyQuery
>
export type ContractForAgingSidesheetSuspenseQueryHookResult = ReturnType<
  typeof useContractForAgingSidesheetSuspenseQuery
>
export type ContractForAgingSidesheetQueryResult = Apollo.QueryResult<
  ContractForAgingSidesheetQuery,
  ContractForAgingSidesheetQueryVariables
>
export const ContractForCashFlowDetailsDialogDocument = gql`
  query contractForCashFlowDetailsDialog($id: ID!) {
    contract(id: $id) {
      ...ContractForCashForecastProperties
    }
  }
  ${ContractForCashForecastPropertiesDoc}
`

/**
 * __useContractForCashFlowDetailsDialogQuery__
 *
 * To run a query within a React component, call `useContractForCashFlowDetailsDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForCashFlowDetailsDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForCashFlowDetailsDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForCashFlowDetailsDialogQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForCashFlowDetailsDialogQuery,
    ContractForCashFlowDetailsDialogQueryVariables
  > &
    (
      | { variables: ContractForCashFlowDetailsDialogQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractForCashFlowDetailsDialogQuery,
    ContractForCashFlowDetailsDialogQueryVariables
  >(ContractForCashFlowDetailsDialogDocument, options)
}
export function useContractForCashFlowDetailsDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForCashFlowDetailsDialogQuery,
    ContractForCashFlowDetailsDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForCashFlowDetailsDialogQuery,
    ContractForCashFlowDetailsDialogQueryVariables
  >(ContractForCashFlowDetailsDialogDocument, options)
}
export function useContractForCashFlowDetailsDialogSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForCashFlowDetailsDialogQuery,
        ContractForCashFlowDetailsDialogQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForCashFlowDetailsDialogQuery,
    ContractForCashFlowDetailsDialogQueryVariables
  >(ContractForCashFlowDetailsDialogDocument, options)
}
export type ContractForCashFlowDetailsDialogQueryHookResult = ReturnType<
  typeof useContractForCashFlowDetailsDialogQuery
>
export type ContractForCashFlowDetailsDialogLazyQueryHookResult = ReturnType<
  typeof useContractForCashFlowDetailsDialogLazyQuery
>
export type ContractForCashFlowDetailsDialogSuspenseQueryHookResult = ReturnType<
  typeof useContractForCashFlowDetailsDialogSuspenseQuery
>
export type ContractForCashFlowDetailsDialogQueryResult = Apollo.QueryResult<
  ContractForCashFlowDetailsDialogQuery,
  ContractForCashFlowDetailsDialogQueryVariables
>
export const UpdateCompanyIntegrationMetadataDocument = gql`
  mutation updateCompanyIntegrationMetadata($input: UpdateCompanyIntegrationMetadataInput!) {
    updateCompanyIntegrationMetadata(input: $input) {
      id
      metadata
    }
  }
`
export type UpdateCompanyIntegrationMetadataMutationFn = Apollo.MutationFunction<
  UpdateCompanyIntegrationMetadataMutation,
  UpdateCompanyIntegrationMetadataMutationVariables
>

/**
 * __useUpdateCompanyIntegrationMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyIntegrationMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyIntegrationMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyIntegrationMetadataMutation, { data, loading, error }] = useUpdateCompanyIntegrationMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyIntegrationMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyIntegrationMetadataMutation,
    UpdateCompanyIntegrationMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCompanyIntegrationMetadataMutation,
    UpdateCompanyIntegrationMetadataMutationVariables
  >(UpdateCompanyIntegrationMetadataDocument, options)
}
export type UpdateCompanyIntegrationMetadataMutationHookResult = ReturnType<
  typeof useUpdateCompanyIntegrationMetadataMutation
>
export type UpdateCompanyIntegrationMetadataMutationResult =
  Apollo.MutationResult<UpdateCompanyIntegrationMetadataMutation>
export type UpdateCompanyIntegrationMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyIntegrationMetadataMutation,
  UpdateCompanyIntegrationMetadataMutationVariables
>
export const GetCompanyForTaxGroupsDocument = gql`
  query getCompanyForTaxGroups($id: ID!) {
    company(id: $id) {
      id
      taxGroups {
        ...TaxGroupProperties
      }
      companyIntegrations {
        id
        type
      }
    }
  }
  ${TaxGroupPropertiesDoc}
`

/**
 * __useGetCompanyForTaxGroupsQuery__
 *
 * To run a query within a React component, call `useGetCompanyForTaxGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyForTaxGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyForTaxGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyForTaxGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyForTaxGroupsQuery,
    GetCompanyForTaxGroupsQueryVariables
  > &
    ({ variables: GetCompanyForTaxGroupsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyForTaxGroupsQuery, GetCompanyForTaxGroupsQueryVariables>(
    GetCompanyForTaxGroupsDocument,
    options
  )
}
export function useGetCompanyForTaxGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyForTaxGroupsQuery,
    GetCompanyForTaxGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyForTaxGroupsQuery, GetCompanyForTaxGroupsQueryVariables>(
    GetCompanyForTaxGroupsDocument,
    options
  )
}
export function useGetCompanyForTaxGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCompanyForTaxGroupsQuery,
        GetCompanyForTaxGroupsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyForTaxGroupsQuery, GetCompanyForTaxGroupsQueryVariables>(
    GetCompanyForTaxGroupsDocument,
    options
  )
}
export type GetCompanyForTaxGroupsQueryHookResult = ReturnType<
  typeof useGetCompanyForTaxGroupsQuery
>
export type GetCompanyForTaxGroupsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyForTaxGroupsLazyQuery
>
export type GetCompanyForTaxGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyForTaxGroupsSuspenseQuery
>
export type GetCompanyForTaxGroupsQueryResult = Apollo.QueryResult<
  GetCompanyForTaxGroupsQuery,
  GetCompanyForTaxGroupsQueryVariables
>
export const UserForMultiCompanyUsersDocument = gql`
  query userForMultiCompanyUsers {
    currentUser {
      id
      companies {
        id
        users {
          id
          role
          isSitelineAdmin
          user {
            id
            email
            firstName
            lastName
            jobTitle
          }
        }
      }
    }
  }
`

/**
 * __useUserForMultiCompanyUsersQuery__
 *
 * To run a query within a React component, call `useUserForMultiCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserForMultiCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserForMultiCompanyUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserForMultiCompanyUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserForMultiCompanyUsersQuery,
    UserForMultiCompanyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserForMultiCompanyUsersQuery, UserForMultiCompanyUsersQueryVariables>(
    UserForMultiCompanyUsersDocument,
    options
  )
}
export function useUserForMultiCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserForMultiCompanyUsersQuery,
    UserForMultiCompanyUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserForMultiCompanyUsersQuery, UserForMultiCompanyUsersQueryVariables>(
    UserForMultiCompanyUsersDocument,
    options
  )
}
export function useUserForMultiCompanyUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserForMultiCompanyUsersQuery,
        UserForMultiCompanyUsersQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UserForMultiCompanyUsersQuery,
    UserForMultiCompanyUsersQueryVariables
  >(UserForMultiCompanyUsersDocument, options)
}
export type UserForMultiCompanyUsersQueryHookResult = ReturnType<
  typeof useUserForMultiCompanyUsersQuery
>
export type UserForMultiCompanyUsersLazyQueryHookResult = ReturnType<
  typeof useUserForMultiCompanyUsersLazyQuery
>
export type UserForMultiCompanyUsersSuspenseQueryHookResult = ReturnType<
  typeof useUserForMultiCompanyUsersSuspenseQuery
>
export type UserForMultiCompanyUsersQueryResult = Apollo.QueryResult<
  UserForMultiCompanyUsersQuery,
  UserForMultiCompanyUsersQueryVariables
>
export const GetCompanyProjectsDocument = gql`
  query getCompanyProjects($companyId: ID!) {
    companyProjects(companyId: $companyId) {
      ...CompanyProjectUsersProperties
    }
  }
  ${CompanyProjectUsersPropertiesDoc}
`

/**
 * __useGetCompanyProjectsQuery__
 *
 * To run a query within a React component, call `useGetCompanyProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyProjectsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyProjectsQuery, GetCompanyProjectsQueryVariables> &
    ({ variables: GetCompanyProjectsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCompanyProjectsQuery, GetCompanyProjectsQueryVariables>(
    GetCompanyProjectsDocument,
    options
  )
}
export function useGetCompanyProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyProjectsQuery,
    GetCompanyProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCompanyProjectsQuery, GetCompanyProjectsQueryVariables>(
    GetCompanyProjectsDocument,
    options
  )
}
export function useGetCompanyProjectsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCompanyProjectsQuery, GetCompanyProjectsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCompanyProjectsQuery, GetCompanyProjectsQueryVariables>(
    GetCompanyProjectsDocument,
    options
  )
}
export type GetCompanyProjectsQueryHookResult = ReturnType<typeof useGetCompanyProjectsQuery>
export type GetCompanyProjectsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyProjectsLazyQuery
>
export type GetCompanyProjectsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyProjectsSuspenseQuery
>
export type GetCompanyProjectsQueryResult = Apollo.QueryResult<
  GetCompanyProjectsQuery,
  GetCompanyProjectsQueryVariables
>
export const ResendUserInviteDocument = gql`
  mutation resendUserInvite($input: ResendUserInviteInput!) {
    resendUserInvite(input: $input) {
      id
    }
  }
`
export type ResendUserInviteMutationFn = Apollo.MutationFunction<
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables
>

/**
 * __useResendUserInviteMutation__
 *
 * To run a mutation, you first call `useResendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendUserInviteMutation,
    ResendUserInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendUserInviteMutation, ResendUserInviteMutationVariables>(
    ResendUserInviteDocument,
    options
  )
}
export type ResendUserInviteMutationHookResult = ReturnType<typeof useResendUserInviteMutation>
export type ResendUserInviteMutationResult = Apollo.MutationResult<ResendUserInviteMutation>
export type ResendUserInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables
>
export const IntegrationVendorsForBulkImportDocument = gql`
  query integrationVendorsForBulkImport($companyIntegrationId: ID!) {
    integrationVendors(companyIntegrationId: $companyIntegrationId) {
      integrationVendorId
      name
      address
      contacts {
        integrationVendorContactId
        name
        title
        email
        phoneNumber
      }
    }
  }
`

/**
 * __useIntegrationVendorsForBulkImportQuery__
 *
 * To run a query within a React component, call `useIntegrationVendorsForBulkImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationVendorsForBulkImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationVendorsForBulkImportQuery({
 *   variables: {
 *      companyIntegrationId: // value for 'companyIntegrationId'
 *   },
 * });
 */
export function useIntegrationVendorsForBulkImportQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationVendorsForBulkImportQuery,
    IntegrationVendorsForBulkImportQueryVariables
  > &
    (
      | { variables: IntegrationVendorsForBulkImportQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IntegrationVendorsForBulkImportQuery,
    IntegrationVendorsForBulkImportQueryVariables
  >(IntegrationVendorsForBulkImportDocument, options)
}
export function useIntegrationVendorsForBulkImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationVendorsForBulkImportQuery,
    IntegrationVendorsForBulkImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IntegrationVendorsForBulkImportQuery,
    IntegrationVendorsForBulkImportQueryVariables
  >(IntegrationVendorsForBulkImportDocument, options)
}
export function useIntegrationVendorsForBulkImportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        IntegrationVendorsForBulkImportQuery,
        IntegrationVendorsForBulkImportQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    IntegrationVendorsForBulkImportQuery,
    IntegrationVendorsForBulkImportQueryVariables
  >(IntegrationVendorsForBulkImportDocument, options)
}
export type IntegrationVendorsForBulkImportQueryHookResult = ReturnType<
  typeof useIntegrationVendorsForBulkImportQuery
>
export type IntegrationVendorsForBulkImportLazyQueryHookResult = ReturnType<
  typeof useIntegrationVendorsForBulkImportLazyQuery
>
export type IntegrationVendorsForBulkImportSuspenseQueryHookResult = ReturnType<
  typeof useIntegrationVendorsForBulkImportSuspenseQuery
>
export type IntegrationVendorsForBulkImportQueryResult = Apollo.QueryResult<
  IntegrationVendorsForBulkImportQuery,
  IntegrationVendorsForBulkImportQueryVariables
>
export const CompanyForAddVendorsDocument = gql`
  query companyForAddVendors($id: ID!) {
    company(id: $id) {
      id
      companyIntegrations {
        id
        type
        shortName
        longName
        archivedAt
      }
      vendors {
        ...MinimalVendorProperties
      }
    }
  }
  ${MinimalVendorPropertiesDoc}
`

/**
 * __useCompanyForAddVendorsQuery__
 *
 * To run a query within a React component, call `useCompanyForAddVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyForAddVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyForAddVendorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyForAddVendorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyForAddVendorsQuery,
    CompanyForAddVendorsQueryVariables
  > &
    ({ variables: CompanyForAddVendorsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CompanyForAddVendorsQuery, CompanyForAddVendorsQueryVariables>(
    CompanyForAddVendorsDocument,
    options
  )
}
export function useCompanyForAddVendorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyForAddVendorsQuery,
    CompanyForAddVendorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CompanyForAddVendorsQuery, CompanyForAddVendorsQueryVariables>(
    CompanyForAddVendorsDocument,
    options
  )
}
export function useCompanyForAddVendorsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompanyForAddVendorsQuery, CompanyForAddVendorsQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CompanyForAddVendorsQuery, CompanyForAddVendorsQueryVariables>(
    CompanyForAddVendorsDocument,
    options
  )
}
export type CompanyForAddVendorsQueryHookResult = ReturnType<typeof useCompanyForAddVendorsQuery>
export type CompanyForAddVendorsLazyQueryHookResult = ReturnType<
  typeof useCompanyForAddVendorsLazyQuery
>
export type CompanyForAddVendorsSuspenseQueryHookResult = ReturnType<
  typeof useCompanyForAddVendorsSuspenseQuery
>
export type CompanyForAddVendorsQueryResult = Apollo.QueryResult<
  CompanyForAddVendorsQuery,
  CompanyForAddVendorsQueryVariables
>
export const CreateVendorsDocument = gql`
  mutation createVendors($input: CreateVendorsInput!) {
    createVendors(input: $input) {
      ...MinimalVendorProperties
    }
  }
  ${MinimalVendorPropertiesDoc}
`
export type CreateVendorsMutationFn = Apollo.MutationFunction<
  CreateVendorsMutation,
  CreateVendorsMutationVariables
>

/**
 * __useCreateVendorsMutation__
 *
 * To run a mutation, you first call `useCreateVendorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorsMutation, { data, loading, error }] = useCreateVendorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVendorsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVendorsMutation, CreateVendorsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateVendorsMutation, CreateVendorsMutationVariables>(
    CreateVendorsDocument,
    options
  )
}
export type CreateVendorsMutationHookResult = ReturnType<typeof useCreateVendorsMutation>
export type CreateVendorsMutationResult = Apollo.MutationResult<CreateVendorsMutation>
export type CreateVendorsMutationOptions = Apollo.BaseMutationOptions<
  CreateVendorsMutation,
  CreateVendorsMutationVariables
>
export const GetContractForProjectSettingsDocument = gql`
  query getContractForProjectSettings($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...MinimalContractProperties
      lienWaiverThroughDate
      legalRequirements {
        ...MinimalLegalRequirementProperties
        vendorTemplate {
          id
          name
          url
        }
      }
      lowerTierLienWaiverTemplates {
        ...SubcontractorLienWaiverTemplateSetProperties
      }
      onboardedStatus {
        ...OnboardedProjectContractStatusProperties
      }
      payApps {
        id
      }
      project {
        id
        name
        projectNumber
        location {
          ...LocationProperties
        }
        generalContractor {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
        owner {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
        architect {
          companyName
          company {
            id
            name
          }
          selectedAddress {
            ...LocationProperties
          }
        }
      }
      timeZone
      vendorContracts {
        ...VendorContractProperties
      }
    }
  }
  ${MinimalContractPropertiesDoc}
  ${MinimalLegalRequirementPropertiesDoc}
  ${SubcontractorLienWaiverTemplateSetPropertiesDoc}
  ${OnboardedProjectContractStatusPropertiesDoc}
  ${LocationPropertiesDoc}
  ${VendorContractPropertiesDoc}
`

/**
 * __useGetContractForProjectSettingsQuery__
 *
 * To run a query within a React component, call `useGetContractForProjectSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForProjectSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForProjectSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForProjectSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForProjectSettingsQuery,
    GetContractForProjectSettingsQueryVariables
  > &
    ({ variables: GetContractForProjectSettingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForProjectSettingsQuery,
    GetContractForProjectSettingsQueryVariables
  >(GetContractForProjectSettingsDocument, options)
}
export function useGetContractForProjectSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForProjectSettingsQuery,
    GetContractForProjectSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForProjectSettingsQuery,
    GetContractForProjectSettingsQueryVariables
  >(GetContractForProjectSettingsDocument, options)
}
export function useGetContractForProjectSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForProjectSettingsQuery,
        GetContractForProjectSettingsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForProjectSettingsQuery,
    GetContractForProjectSettingsQueryVariables
  >(GetContractForProjectSettingsDocument, options)
}
export type GetContractForProjectSettingsQueryHookResult = ReturnType<
  typeof useGetContractForProjectSettingsQuery
>
export type GetContractForProjectSettingsLazyQueryHookResult = ReturnType<
  typeof useGetContractForProjectSettingsLazyQuery
>
export type GetContractForProjectSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForProjectSettingsSuspenseQuery
>
export type GetContractForProjectSettingsQueryResult = Apollo.QueryResult<
  GetContractForProjectSettingsQuery,
  GetContractForProjectSettingsQueryVariables
>
export const GetContractForVendorContactDocument = gql`
  query getContractForVendorContact($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      ...MinimalContractProperties
      project {
        id
      }
    }
  }
  ${MinimalContractPropertiesDoc}
`

/**
 * __useGetContractForVendorContactQuery__
 *
 * To run a query within a React component, call `useGetContractForVendorContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractForVendorContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractForVendorContactQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractForVendorContactQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractForVendorContactQuery,
    GetContractForVendorContactQueryVariables
  > &
    ({ variables: GetContractForVendorContactQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetContractForVendorContactQuery,
    GetContractForVendorContactQueryVariables
  >(GetContractForVendorContactDocument, options)
}
export function useGetContractForVendorContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractForVendorContactQuery,
    GetContractForVendorContactQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetContractForVendorContactQuery,
    GetContractForVendorContactQueryVariables
  >(GetContractForVendorContactDocument, options)
}
export function useGetContractForVendorContactSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContractForVendorContactQuery,
        GetContractForVendorContactQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetContractForVendorContactQuery,
    GetContractForVendorContactQueryVariables
  >(GetContractForVendorContactDocument, options)
}
export type GetContractForVendorContactQueryHookResult = ReturnType<
  typeof useGetContractForVendorContactQuery
>
export type GetContractForVendorContactLazyQueryHookResult = ReturnType<
  typeof useGetContractForVendorContactLazyQuery
>
export type GetContractForVendorContactSuspenseQueryHookResult = ReturnType<
  typeof useGetContractForVendorContactSuspenseQuery
>
export type GetContractForVendorContactQueryResult = Apollo.QueryResult<
  GetContractForVendorContactQuery,
  GetContractForVendorContactQueryVariables
>
export const ContractForLowerTierTemplatesDocument = gql`
  query contractForLowerTierTemplates($id: ID!) {
    contract(id: $id) {
      id
      lowerTierLienWaiverTemplates {
        id
        conditionalFinalVariant {
          id
          template {
            ...LienWaiverFormTemplateProperties
            isCustomerReady
          }
        }
        conditionalProgressVariant {
          id
          template {
            ...LienWaiverFormTemplateProperties
            isCustomerReady
          }
        }
        unconditionalFinalVariant {
          id
          template {
            ...LienWaiverFormTemplateProperties
            isCustomerReady
          }
        }
        unconditionalProgressVariant {
          id
          template {
            ...LienWaiverFormTemplateProperties
            isCustomerReady
          }
        }
      }
    }
  }
  ${LienWaiverFormTemplatePropertiesDoc}
`

/**
 * __useContractForLowerTierTemplatesQuery__
 *
 * To run a query within a React component, call `useContractForLowerTierTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractForLowerTierTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractForLowerTierTemplatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractForLowerTierTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContractForLowerTierTemplatesQuery,
    ContractForLowerTierTemplatesQueryVariables
  > &
    ({ variables: ContractForLowerTierTemplatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ContractForLowerTierTemplatesQuery,
    ContractForLowerTierTemplatesQueryVariables
  >(ContractForLowerTierTemplatesDocument, options)
}
export function useContractForLowerTierTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractForLowerTierTemplatesQuery,
    ContractForLowerTierTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ContractForLowerTierTemplatesQuery,
    ContractForLowerTierTemplatesQueryVariables
  >(ContractForLowerTierTemplatesDocument, options)
}
export function useContractForLowerTierTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContractForLowerTierTemplatesQuery,
        ContractForLowerTierTemplatesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ContractForLowerTierTemplatesQuery,
    ContractForLowerTierTemplatesQueryVariables
  >(ContractForLowerTierTemplatesDocument, options)
}
export type ContractForLowerTierTemplatesQueryHookResult = ReturnType<
  typeof useContractForLowerTierTemplatesQuery
>
export type ContractForLowerTierTemplatesLazyQueryHookResult = ReturnType<
  typeof useContractForLowerTierTemplatesLazyQuery
>
export type ContractForLowerTierTemplatesSuspenseQueryHookResult = ReturnType<
  typeof useContractForLowerTierTemplatesSuspenseQuery
>
export type ContractForLowerTierTemplatesQueryResult = Apollo.QueryResult<
  ContractForLowerTierTemplatesQuery,
  ContractForLowerTierTemplatesQueryVariables
>
export const VendorsForIntegrationLinkingDocument = gql`
  query vendorsForIntegrationLinking($input: GetVendorsInput!) {
    vendors(input: $input) {
      id
      name
      integrationMappings
    }
  }
`

/**
 * __useVendorsForIntegrationLinkingQuery__
 *
 * To run a query within a React component, call `useVendorsForIntegrationLinkingQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsForIntegrationLinkingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsForIntegrationLinkingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorsForIntegrationLinkingQuery(
  baseOptions: Apollo.QueryHookOptions<
    VendorsForIntegrationLinkingQuery,
    VendorsForIntegrationLinkingQueryVariables
  > &
    ({ variables: VendorsForIntegrationLinkingQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VendorsForIntegrationLinkingQuery,
    VendorsForIntegrationLinkingQueryVariables
  >(VendorsForIntegrationLinkingDocument, options)
}
export function useVendorsForIntegrationLinkingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VendorsForIntegrationLinkingQuery,
    VendorsForIntegrationLinkingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VendorsForIntegrationLinkingQuery,
    VendorsForIntegrationLinkingQueryVariables
  >(VendorsForIntegrationLinkingDocument, options)
}
export function useVendorsForIntegrationLinkingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VendorsForIntegrationLinkingQuery,
        VendorsForIntegrationLinkingQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VendorsForIntegrationLinkingQuery,
    VendorsForIntegrationLinkingQueryVariables
  >(VendorsForIntegrationLinkingDocument, options)
}
export type VendorsForIntegrationLinkingQueryHookResult = ReturnType<
  typeof useVendorsForIntegrationLinkingQuery
>
export type VendorsForIntegrationLinkingLazyQueryHookResult = ReturnType<
  typeof useVendorsForIntegrationLinkingLazyQuery
>
export type VendorsForIntegrationLinkingSuspenseQueryHookResult = ReturnType<
  typeof useVendorsForIntegrationLinkingSuspenseQuery
>
export type VendorsForIntegrationLinkingQueryResult = Apollo.QueryResult<
  VendorsForIntegrationLinkingQuery,
  VendorsForIntegrationLinkingQueryVariables
>
