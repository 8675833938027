import AddIcon from '@mui/icons-material/Add'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import EditIcon from '@mui/icons-material/Edit'
import { Button, Collapse, TextField, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import _ from 'lodash'
import { ChangeEvent, PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { ContactChip } from './ContactChip'
import { Contact } from './NewContactForm'
import { SendEmailDialogRow, SendEmailDialogRowProps } from './SendEmailDialogRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '&.defaultSize': {
      '& .MuiDialog-paper': {
        maxWidth: 720,
      },
    },
    '& .rows': {
      margin: 0,
      '& .to': {
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        '& .contacts': {
          display: 'flex',
          flexDirection: 'column',
          '&.editable': {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(-1),
          },
        },
        '& .addRecipient': {
          // Match height of contact chips so the modal doesn't shift when a contact
          // is removed
          height: 42,
          whiteSpace: 'nowrap',
          flexShrink: 0,
        },
      },
      '& .subjectRow': {
        margin: theme.spacing(2, 0),
      },
      '& .noSubject.MuiTextField-root .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.red30,
        },
      },
      '& .noSubject.MuiTextField-root:hover .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors.red50,
        },
      },
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
        color: colors.grey50,
      },
      '& .attachmentName': {
        border: '1px solid',
        borderColor: colors.grey30,
        borderRadius: 4,
        padding: theme.spacing(1),
        backgroundColor: colors.white,
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .filename': {
          marginLeft: theme.spacing(1),
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      },
      '& .attachmentDescription': {
        marginTop: theme.spacing(1),
      },
      '& .noAttachment': {
        marginTop: theme.spacing(-2),
      },
    },
    '& .warning': {
      '&.MuiAlert-standardWarning': {
        backgroundColor: colors.yellow10,
        border: '1px solid',
        borderColor: colors.yellow30,
        '& .MuiAlert-message': {
          fontWeight: 'normal',
        },
      },
      '&.MuiAlert-standardError': {
        border: '1px solid',
        borderColor: colors.red30,
        '& .MuiAlert-message': {
          color: colors.red70,
        },
      },
      '&:first-child': {
        marginTop: theme.spacing(4),
      },
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    '& .actions': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 5, 4),
      '& .buttons': {
        display: 'flex',
      },
    },
  },
  noteRow: {
    margin: theme.spacing(2, 0),
    width: '100%',
    '&:not(.isNoteExpanded)': {
      margin: 0,
    },
  },
  noteField: {
    '& .MuiOutlinedInput-root': {
      // Override the !important padding on the input, since we want it to be clear when there is
      // more in the note than is currently visible. Instead of padding on the input, which causes
      // the text to be cut off before reaching the border while scrolling, we put padding on the
      // inner textarea, which preserves the padding at the top and bottom but allows the text to
      // scroll right to the border.
      paddingTop: `0px !important`,
      paddingBottom: `0px !important`,
      '& textarea': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
  },
  attachmentTooltip: {
    maxWidth: 'none',
  },
  divider: {
    width: '100%',
    backgroundColor: colors.grey30,
    height: 1,
    margin: theme.spacing(3, 0, 3),
  },
}))

type BaseEmailContact = Pick<Contact, 'id' | 'email' | 'fullName'>
export type EmailContact =
  | BaseEmailContact
  | ((BaseEmailContact & Pick<Contact, 'phoneNumber'>) & Pick<Contact, 'jobTitle'>)

interface SendEmailDialogFormProps {
  defaultSubject: string
  subject: string
  onSubjectChange: (subject: string) => void
  showSubjectWarning?: boolean
  showSubjectRedOutline?: boolean
  message: string
  onMessageChange: (message: string) => void

  contacts: EmailContact[]
  onRemoveContact?: (id: string) => void
  onCreateContact?: () => void
  onSelectContacts?: () => void

  attachmentName?: string
  onAttachmentNameChange?: (update: string) => void
  /** Only referenced if also passing in `onAttachmentNameChange` */
  defaultAttachmentName?: string

  attachmentDescription?: string

  labelSize?: SendEmailDialogRowProps['width']

  /**
   * If true, show the whole note text field. If false, show a button for
   * the user to click to show the text field. Defaults to true.
   */
  isNoteExpanded?: boolean

  additionalStartRows?: ReactNode
  additionalEndRows?: ReactNode
  submitting?: boolean
  /** Determines whether the subject is editable. If not editable, defaultSubject will be used. */
  canEditSubject?: boolean
}

const i18nBase = 'projects.subcontractors.pay_app.submit.email_dialog'

/** A dialog with options to customize the email sent for submitting a document */
export function SendEmailDialogForm({
  defaultSubject,
  subject,
  onSubjectChange,
  showSubjectWarning,
  showSubjectRedOutline,
  message,
  onMessageChange,
  contacts,
  onRemoveContact,
  onCreateContact,
  onSelectContacts,
  defaultAttachmentName,
  attachmentName,
  onAttachmentNameChange,
  attachmentDescription,
  additionalStartRows,
  additionalEndRows,
  isNoteExpanded = true,
  labelSize = 'small',
  submitting,
  canEditSubject = true,
}: PropsWithChildren<SendEmailDialogFormProps>) {
  const classes = useStyles()
  const { t } = useTranslation()
  const sortedContacts = _.orderBy(contacts, (contact) => contact.fullName, 'asc')
  const isNoteInitiallyExpanded = useRef<boolean>(isNoteExpanded)

  // If the form ever re-renders with the note *not* expanded, reset the ref for the
  // note being initially collapsed
  useEffect(() => {
    if (isNoteExpanded === false) {
      isNoteInitiallyExpanded.current = false
    }
  }, [isNoteExpanded])

  const handleAttachmentNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onAttachmentNameChange === undefined) {
        return
      }
      onAttachmentNameChange(event.target.value)
    },
    [onAttachmentNameChange]
  )

  const isEditableAttachmentName = onAttachmentNameChange !== undefined
  const shouldShowAttachmentName = attachmentName !== undefined

  return (
    <div className={classes.root}>
      <div className="rows">
        {additionalStartRows && (
          <>
            {additionalStartRows}
            <div className={classes.divider} />
          </>
        )}
        <SendEmailDialogRow
          label={t(`${i18nBase}.to`)}
          alignLabel={contacts.length <= 1 ? 'center' : 'start'}
          width={labelSize}
        >
          <div className="to">
            {sortedContacts.length > 0 && (
              <div className={clsx('contacts', { editable: onRemoveContact })}>
                {sortedContacts.map((contact) => (
                  <ContactChip
                    key={contact.id}
                    contact={contact}
                    disabled={submitting}
                    onRemoveContact={
                      onRemoveContact ? () => onRemoveContact(contact.id) : undefined
                    }
                  />
                ))}
              </div>
            )}
            {onCreateContact && (
              <Button
                variant="text"
                startIcon={<AddIcon fontSize="small" />}
                onClick={onCreateContact}
                className="addRecipient"
                disabled={submitting}
              >
                <SitelineText variant="secondary" bold color="grey50">
                  {t(`${i18nBase}.add_recipient`)}
                </SitelineText>
              </Button>
            )}
            {onSelectContacts && (
              <Button
                variant="text"
                startIcon={<EditIcon fontSize="small" />}
                onClick={onSelectContacts}
                className="addRecipient"
              >
                <SitelineText variant="secondary" bold color="grey50">
                  {t(`${i18nBase}.select_recipients`)}
                </SitelineText>
              </Button>
            )}
          </div>
        </SendEmailDialogRow>
        {canEditSubject && (
          <SendEmailDialogRow
            label={t(`${i18nBase}.subject`)}
            alignLabel="center"
            width={labelSize}
            className="subjectRow"
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder={defaultSubject}
              value={subject}
              onChange={(ev) => {
                const toSubject = ev.target.value
                onSubjectChange(toSubject)
              }}
              className={clsx({ noSubject: showSubjectWarning || showSubjectRedOutline })}
              disabled={submitting}
            />
          </SendEmailDialogRow>
        )}
        <Collapse
          in={isNoteExpanded}
          className={clsx(classes.noteRow, { isNoteExpanded })}
          exit={false}
        >
          <SendEmailDialogRow label={isNoteExpanded ? t(`${i18nBase}.note`) : ''} width={labelSize}>
            {/* Only mount when it becomes visible, so it focuses if not initially shown */}
            {isNoteExpanded && (
              <TextField
                fullWidth
                multiline
                rows={2}
                value={message}
                autoFocus={!isNoteInitiallyExpanded.current && message === ''}
                placeholder={isNoteInitiallyExpanded.current ? t(`${i18nBase}.optional`) : ''}
                onChange={(ev) => {
                  const toMessage = ev.target.value
                  onMessageChange(toMessage)
                }}
                disabled={submitting}
                className={classes.noteField}
              />
            )}
          </SendEmailDialogRow>
        </Collapse>
        {(shouldShowAttachmentName || !!attachmentDescription) && (
          <SendEmailDialogRow
            label={
              isEditableAttachmentName && shouldShowAttachmentName
                ? t(`${i18nBase}.attachment`)
                : ''
            }
            width={labelSize}
            className={clsx({ noAttachment: attachmentName === undefined })}
          >
            <>
              {shouldShowAttachmentName && !isEditableAttachmentName && (
                <Tooltip
                  title={attachmentName}
                  placement="bottom-start"
                  classes={{ tooltip: classes.attachmentTooltip }}
                >
                  <div className="attachmentName">
                    {/* Don't use startIcon for this, as it interferes with truncating the text */}
                    <AttachFileIcon />
                    <SitelineText variant="h4" bold color="grey50" className="filename">
                      {attachmentName}
                    </SitelineText>
                  </div>
                </Tooltip>
              )}
              {shouldShowAttachmentName && isEditableAttachmentName && (
                <TextField
                  value={attachmentName}
                  onChange={handleAttachmentNameChange}
                  placeholder={defaultAttachmentName}
                  fullWidth
                  InputProps={{
                    startAdornment: <AttachFileIcon />,
                  }}
                />
              )}
              {attachmentDescription && (
                <SitelineText
                  variant="smallText"
                  color="grey50"
                  className={clsx({ attachmentDescription: shouldShowAttachmentName })}
                >
                  {attachmentDescription}
                </SitelineText>
              )}
            </>
          </SendEmailDialogRow>
        )}
        {additionalEndRows}
      </div>
      <div>
        {showSubjectWarning && (
          <SitelineAlert severity="error" className="warning">
            {t(`${i18nBase}.no_subject`)}
          </SitelineAlert>
        )}
      </div>
    </div>
  )
}
