import HelpIcon from '@mui/icons-material/Help'
import { Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { clsx } from 'clsx'
import { PropsWithChildren } from 'react'
import { SitelineText, makeStylesFast } from 'siteline-common-web'

// Used by callers to target styles on the onboarding form row input
export const INPUT_CLASS = 'onboardingFormRowInput'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 40,
    overflow: 'hidden',
    '& .labelContainer': {
      display: 'flex',
      height: 40,
      alignItems: 'center',
      '& .label': {
        minWidth: 180,
        maxWidth: 180,
        marginRight: theme.spacing(5),
      },
    },
    '& .helpIcon': {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  input: {
    flexGrow: 1,
    overflow: 'hidden',
  },
}))

interface OnboardingFormRowProps {
  label: string
  info?: string
  className?: string
}

/** Displays a single row on the project settings page. */
export function OnboardingFormRow({
  label,
  info,
  className,
  children,
}: PropsWithChildren<OnboardingFormRowProps>) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <div className="labelContainer">
        <SitelineText
          variant="body1"
          color="grey50"
          className="label"
          endIcon={
            info ? (
              <Tooltip title={info} placement="right" disableInteractive>
                <HelpIcon className="helpIcon" />
              </Tooltip>
            ) : undefined
          }
        >
          {label}
        </SitelineText>
      </div>
      <div className={clsx(classes.input, INPUT_CLASS)}>{children}</div>
    </div>
  )
}
